import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { MinimalCampaignFile } from "src/api/types";
import { useDeletePrompt } from "src/hooks/promt";
import CheckBox from "../../general_components/CheckBox";

export const FilesInnerToolTip = (props: {
  files: MinimalCampaignFile[];
  openFile: (uuid: string) => void;
  openUploadFile: any;
  onDelete: (files: string[]) => void;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [checked, setChecked] = useState([] as string[]);

  const deletePrompt = useDeletePrompt();

  const toggleEditMode = () => setEditMode((prev) => !prev);

  const onToggleRow = (uuid: string) => {
    setChecked((prev) => [...prev.addOrRemove(uuid)]);
  };

  const onDeleteFiles = async () => {
    if (checked?.length > 0) {
      const answer = await deletePrompt.askToDelete({
        text: "Are you sure you want to delete those files?",
      });
      if (answer) {
        props.onDelete(checked);
      }
    }
  };

  return (
    <div>
      <div className="upload-files-title">
        Contracts and documents
        {editMode ? (
          <div onClick={toggleEditMode}>
            <span style={{ color: "#249ff0", textDecoration: "underline" }}>
              Cancel
            </span>
          </div>
        ) : (
          <div onClick={toggleEditMode}>
            Edit <i className="fas fa-pen-to-square" />
          </div>
        )}
      </div>
      <div className="upload-files-container">
        {props.files?.map((x) => (
          <div key={x.uuid} className="file-name-row">
            {editMode ? (
              <CheckBox
                checked={checked.includes(x.uuid)}
                onChange={() => onToggleRow(x.uuid as string)}
              />
            ) : null}
            <i className="fas fa-file-lines" />
            <div
              aria-label={x.file_name}
              className="link"
              onClick={() => props.openFile(x.uuid)}
            >
              {x.file_name}
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          padding: "8px",
        }}
      >
        <Button
          onClick={editMode ? onDeleteFiles : props.openUploadFile}
          color={editMode ? "#F56565" : "#249ff0"}
          style={{
            borderRadius: "6px",
            background: "rgba(255, 255, 255, 0.00)",
            boxShadow: "0px 1.33469px 2.33571px 0px rgba(0, 0, 0, 0.17)",
          }}
          className={editMode && checked?.length === 0 ? "disabled-row" : ""}
        >
          {editMode ? (
            <i className={`fas fa-trash-can`} />
          ) : (
            <i className="fa-light fa-arrow-up-from-bracket" />
          )}
        </Button>
      </div>
    </div>
  );
};
