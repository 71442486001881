// TODO - lint
/* eslint-disable class-methods-use-this, class-methods-use-this, camelcase, camelcase, camelcase, camelcase, camelcase, import/no-default-export */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { uploadFilesMedia } from "src/actions/mediaActions";
import { attachFileToInfluencer } from "src/api/actions/auctionActions";
import { FileTypes, FileUpload } from "../../../../ui/FileUpload";
import { FormFooterButtonGroup } from "../../../../ui/FormFooterButtonGroup";

interface UploadFileDialogProps {
  auctionId: string | number;
  influencerId: string | number;
  onClose: () => void;
  onFileUploaded: () => void;
}

export const UploadFileDialog = (props: UploadFileDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileUpload = (fileUrl: string) => {
    const { auctionId, influencerId } = props;
    setLoading(true);
    attachFileToInfluencer(Number(influencerId), Number(auctionId), fileUrl, {
      data: {},
    })
      .then(() => {
        props.onFileUploaded();
        props.onClose();
      })
      .catch(() => {
        toast.error("Failed to attach the file to this influencer");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={true} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload file to influencer</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={6}>
          {!loading && (
            <div className="input-row">
              <FileUpload
                fileType={[FileTypes.image, FileTypes.document]}
                uploadPath={"invoice"}
                shareMode={true}
                onFileUploaded={handleFileUpload}
                externalUploadMedia={(
                  media,
                  type,
                  uploadType,
                  progress,
                  callback,
                ) => {
                  uploadFilesMedia(
                    props.influencerId,
                    props.auctionId,
                    media,
                    type,
                    uploadType,
                    progress,
                    callback,
                  );
                }}
              />
            </div>
          )}

          <div className="clearfix" />
          {loading && <div>Uploading....</div>}
          <FormFooterButtonGroup py={3}>
            <Button type="button" onClick={props.onClose}>
              Cancel
            </Button>
          </FormFooterButtonGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
