import { Accordion, Box, Flex } from "@chakra-ui/react";
import { AuctionPaymentsData } from "src/api/types";
import PaymentsInfluencerLine from "./PaymentsInfluencerLine";

const PaymentsTable = ({
  month,
  isCurrentMonth,
  getPrice,
  monthChargeStatus,
}: {
  month: AuctionPaymentsData["months"][0];
  isCurrentMonth: boolean;
  getPrice: (price: number) => string;
  monthChargeStatus?: string;
}) => {
  return (
    <Box>
      <Flex
        gap={isCurrentMonth ? "34px" : "28px"}
        textTransform={"uppercase"}
        fontWeight="500"
        fontSize={12}
        color={"#808080"}
        paddingBottom={1}
        paddingRight={"32px"}
      >
        <Box flex={2} minWidth={100}></Box>

        <Box width={"80px"} textAlign={"center"} marginRight={"40px"}>
          Influencer fee
        </Box>

        {/* <Box minWidth={"100px"} marginLeft={isCurrentMonth ? "16px" : "28px"}>
          {isCurrentMonth ? "Est. Bill" : "Billed"}
        </Box> */}
        <Box width={"80px"} textAlign={"center"}>
          Influencer Invoices
        </Box>
      </Flex>
      <Accordion variant={"paymentsInfluencer"} allowMultiple>
        {month?.influencers.map((influ: any, i: number) => {
          const monthTotal = influ.payments.reduce(
            (acc: number, payment: any) =>
              acc +
              (payment.only_commission ? 0 : payment.price) +
              payment.commission,
            0,
          );
          const mediaTotal = influ.payments.reduce(
            (acc: number, payment: any) => acc + payment.price,
            0,
          );
          const paidAmount = influ.payments.reduce(
            (acc: number, payment: any) => acc + payment.paid_amount,
            0,
          );
          const chargedTotal = influ.payments.reduce(
            (acc: number, payment: any) => {
              if (payment.charged) {
                return (
                  acc +
                  (payment.only_commission ? 0 : payment.price) +
                  payment.commission
                );
              }
              return acc;
            },
            0,
          );

          return (
            <PaymentsInfluencerLine
              influ={influ}
              key={i}
              month={month.month}
              month_total={monthTotal}
              paid_amount={paidAmount}
              charged_total={chargedTotal}
              media_total={mediaTotal}
              getPrice={getPrice}
              isCurrentMonth={isCurrentMonth}
              monthChargeStatus={monthChargeStatus}
            />
          );
        })}
      </Accordion>
    </Box>
  );
};

export default PaymentsTable;
