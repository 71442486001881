import { Box, Button, Flex, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { getDashboardTopInfluencers } from "src/api/actions/dashboardActions";
import {
  GetMoiHomepage_dashboardTop_influencersQueryParams,
  InfluencerCampaignPictureMetric,
} from "src/api/types";
import HumanzButtonGroup from "src/ui/humanz/HumanzButtonGroup";
import NoImage from "../../../../images/svgs/placeholder.svg";
import { formatBigNumbers } from "../../utilities/general";
import DashboardPaper from "./DashboardPaper";

type Props = { selectedMonth: string; selectedBrandId?: number };

type TopInfluencer = {
  id: number;
  picture?: string;
  name: string;
  value: number;
  suffix?: string | null;
};

const enum Mode {
  "sales",
  "engagement",
}

const parseData = (data?: InfluencerCampaignPictureMetric[]) => {
  const parsedData: TopInfluencer[] = data?.map((i) => {
    return {
      id: i.id,
      picture: i.picture_url,
      name: i.name,
      value: i.metric || 0,
    };
  });
  return parsedData || [];
};

const DashboardTopInfluencers = (props: Props) => {
  const [topInfluencers, setTopInfluencers] = useState<{
    sales?: TopInfluencer[];
    engagement?: TopInfluencer[];
  }>({});
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<Mode>(Mode.sales);

  const initialData = async () => {
    if (!props.selectedMonth) {
      return;
    }
    const filters: Partial<GetMoiHomepage_dashboardTop_influencersQueryParams> =
      {
        month: props.selectedMonth,
        brand_id: props.selectedBrandId,
      };
    const results = await Promise.all(
      ["sales", "engagement"].map((p) =>
        getDashboardTopInfluencers({
          ...filters,
          metric:
            p as GetMoiHomepage_dashboardTop_influencersQueryParams["metric"],
        } as GetMoiHomepage_dashboardTop_influencersQueryParams).catch(
          () => null,
        ),
      ),
    );

    const sales = parseData(results[0]?.data);
    const engagement = parseData(results[1]?.data);

    setTopInfluencers({ sales, engagement });

    setMode(sales?.length > 0 ? Mode.sales : Mode.engagement);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setTopInfluencers({});
    initialData();
  }, [props.selectedMonth, props.selectedBrandId]);

  return (
    <DashboardPaper width={"100%"}>
      {loading ? (
        <>
          <Box gap={10} padding={10}>
            <Flex gap={10} padding={6}>
              <Skeleton width={"70%"} height={10} />
              <Skeleton width={"20%"} height={10} />
            </Flex>
            <Flex gap={10} padding={6}>
              <Skeleton width={"70%"} height={10} />
              <Skeleton width={"20%"} height={10} />
            </Flex>
            <Flex gap={10} padding={6}>
              <Skeleton width={"70%"} height={10} />
              <Skeleton width={"20%"} height={10} />
            </Flex>
          </Box>
        </>
      ) : (
        <>
          <Flex justifyContent={"space-between"} p={3}>
            <Box>
              <Text p={4} fontWeight={600} color={"#434D56"} fontSize={16}>
                Top Influencers
              </Text>
            </Box>
            <Box>
              <HumanzButtonGroup
                containerProps={{ className: "top-view-buttons-container" }}
              >
                <Button
                  borderRadius={24}
                  variant={"small-action"}
                  isActive={mode === Mode.sales}
                  style={{
                    background: "white",
                    borderRadius: 100,
                    padding: "2px 10px",
                    height: "25px",
                    paddingTop: 2,
                    color: mode !== Mode.sales ? "GrayText" : undefined,
                    justifyContent: "space-between",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => {
                    setMode(Mode.sales);
                  }}
                >
                  Sales
                </Button>
                <Button
                  variant={"small-action"}
                  borderRadius={24}
                  style={{
                    background: "white",
                    borderRadius: 100,
                    padding: "2px 10px",
                    height: "25px",
                    paddingTop: 2,
                    color: mode !== Mode.engagement ? "GrayText" : undefined,
                    justifyContent: "space-between",
                    whiteSpace: "nowrap",
                  }}
                  isActive={mode === Mode.engagement}
                  onClick={() => {
                    setMode(Mode.engagement);
                  }}
                >
                  Engagements
                </Button>
              </HumanzButtonGroup>
            </Box>
          </Flex>
          {topInfluencers[mode === Mode.sales ? "sales" : "engagement"]?.map(
            (influ, i) => {
              return (
                <Box width={"100%"} key={influ.id}>
                  <Box
                    width={"100%"}
                    p={2}
                    height={78}
                    backgroundColor={i % 2 === 0 ? "#F1F1F1" : "white"}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignSelf={"center"}
                      height="100%"
                    >
                      <Flex alignSelf={"center"} gap={3} ml={2} height={"100%"}>
                        <Box alignSelf={"center"}>
                          {i < 3 ? (
                            <img src={`../../../../images/medal${i + 1}.svg`} />
                          ) : null}
                        </Box>
                        <Box
                          style={{
                            width: 40,
                            height: 40,
                            paddingTop: 1,
                            display: "block",
                          }}
                        >
                          <img
                            className="avatar"
                            style={{
                              width: 40,
                              height: 40,
                            }}
                            src={influ.picture ? influ.picture : NoImage}
                          />
                        </Box>
                        <Box alignSelf={"center"}>
                          <Text fontWeight={600} ml={2}>
                            {influ.name}
                          </Text>
                        </Box>
                      </Flex>
                      <Box alignSelf={"center"} mr={2}>
                        <Text fontWeight={600} ml={2}>
                          <CountUp
                            decimalPlaces={2}
                            formattingFn={(v) =>
                              `${formatBigNumbers(v)}${influ?.suffix || ""}`
                            }
                            end={Math.abs(influ.value)}
                          />
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              );
            },
          )}
        </>
      )}
    </DashboardPaper>
  );
};

export default DashboardTopInfluencers;
