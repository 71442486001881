// TODO - lint
/* eslint-disable func-style */
export function getCampaignJson(isPremium = false) {
  return {
    overview: {
      title: "",
      brand: {
        brand_name: "",
        image_id: "",
        show_advertiser_info: true,
      },
      description: "",
      raw_description: "",
      key_info: "",
      images_ids: [],
      target_audience: {
        tuning: null,
        age: [],
        location: [],
        categories: [],
        gender: [],
      },
      categories: [],
      auction_link: {
        short_link: "",
        long_link: "",
      },
      ribbon: null,
    },
    payments: {
      currency: "",
      media_budget: 0,
      allow_bids: isPremium
        ? null
        : {
            gender: [],
            followers: {
              min: 0,
              max: 0,
            },
            price: {
              min: 0,
              max: 0,
            },
            special_prices: [],
            max_participants: null,
            age_restriction: {
              min_age: null,
              max_age: null,
            },
            social_networks: ["all"],
            restricted_list: null,
            api_restricted: false,
          },
      payments_days_delay: 45,
      commission: 0,
      net_sales_percentage: 100,
      sales_end_time: null,
      monthly_flat_fee: false,
    },
    products: [],
    questions: [],
    tasks: {
      campaign_dates: {
        start: null,
        end: null,
      },
      description: "",
      task_stages: [],
      tasks_payments_instructions: {
        split_payments: false,
        tasks_renews: false,
      },
      one_month_campaign: true,
    },
    instructions: {
      dos: [],
      donts: [],
    },
    sales: {
      currency: "",
      coupons: [],
      skus: [],
      suggestion_links: [],
      allow_influencers_links: false,
      allow_influencers_create_links: false,
      commission_percentage: 0,
      net_sales_percentage: 100,
      sales_enabled: false,
      pixel_app_id: null,
      sales_start_time: null,
      sales_end_time: null,
      link_google_utms: null,
      utm_codes: {
        utm_source: "humanz",
        utm_medium: "influencer",
      },
      brands_included: [],
      skus_included: [],
      skus_excluded: [],
      sales_timezone: "",
    },
  };
}
