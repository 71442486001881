// TODO - lint
import DOMPurify from "dompurify";
import moment from "moment";
/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/naming-convention, func-style, func-style, consistent-return, func-style, no-param-reassign, no-param-reassign, @typescript-eslint/no-use-before-define, func-style, func-style, no-param-reassign, func-style, @typescript-eslint/naming-convention, func-style, func-style, no-param-reassign, no-param-reassign, consistent-return, func-style, func-style, consistent-return, consistent-return, consistent-return, consistent-return, consistent-return, func-style, func-style, no-plusplus, no-restricted-syntax, no-prototype-builtins, @typescript-eslint/naming-convention, @typescript-eslint/naming-convention, func-style, func-style, no-param-reassign, prefer-regex-literals, func-style, func-style, @typescript-eslint/naming-convention, func-style */
// @ts-ignore
import numeral from "numeral";
import validator from "validator";
import { tagColors as tagColorsConfig } from "../../../actions/commonActionsConf";
import { UserReducer } from "../../../reducers/userReducer";

export const campaign_status = [
  "wait_proposal",
  "wait_approval",
  "accepted_proposal",
  "post_linked",
];

export function numberWithCommas(x?: string | number) {
  if (x === null || x === undefined) return 0;
  if (typeof x === "string") {
    x = Number(x);
  }
  x = roundTwoDigitsAfterDot(x);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function roundTwoDigitsAfterDot(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100; // round 2 digits
}

export function formatBigNumbers(
  num: number,
  options?: {
    format?: string;
    lowFormat?: string;
    minForFormat?: number;
  },
) {
  const {
    format = "0.00a",
    lowFormat = "0,0",
    minForFormat = 10000,
  } = options ?? {};

  return num >= minForFormat
    ? numeral(num).format(format)
    : numeral(num).format(lowFormat);
}

export const numberWithCurrency = (
  price: number,
  currency: string,
  full?: boolean,
) => {
  return `${window.translate("", `money_symbol_${currency}`)} ${
    full ? numberWithCommas(price.toFixed(2)) : formatBigNumbers(price)
  }`;
};

export const HoursToAlertSalesEnds = 168;

export function campaignHoursLeftMesg(hours: number) {
  if (HoursToAlertSalesEnds > 168) {
    return "";
  }
  if (hours <= 0) {
    return "Sales tracking time has ended";
  }
  return `Sales tracking ends in ${hours} hours`;
}

const notificationsType = {
  1: "notif_new_auction_label",
  2: "notif_new_bid_label",
  3: "notif_new_proposal_from_inf",
  4: "notif_positive_feedback",
  5: "notif_feedback_with_remark",
  6: "notif_bid_deleted",
  7: "notif_selected_for_campaign",
  8: "notif_link_added",
  9: "notif_new_message_arrived",
  10: "notif_selected_for_campaign_express",
  11: "notif_invited_for_campaign",
  12: "notif_accepted_campaign_invite",
  13: "notif_rejected_campaign_invite",
  14: "notif_negotiation_campaign_invite",
  15: "notif_accepted_campaign_negotiation",
  16: "notif_rejected_campaign_negotiation",
  17: "notif_finished_work_on_campaign",
  18: "notif_task_changed",
  19: "notif_invited_for_task",
  20: "notif_accepted_task_invite",
  21: "notif_declined_task_invite",
  22: "notif_negotiation_task_invite",
  23: "notif_rejected_influencer_shared_list",
  24: "notif_accepted_influencer_shared_list",
  25: "notif_rejected_proposal_shared_campaign",
  26: "notif_accepted_proposal_shared_campaign",
  27: "notif_collaborate_new_match",
  28: "notif_collaborate_someone_liked_you",
  29: "notif_deleted_message",
  30: "notif_feed_like_on_my_post",
  31: "notif_feed_comment_on_my_post",
  32: "notif_feed_like_on_my_comment",
  33: "notif_feed_comment_on_my_comment",
  34: "notif_feed_mentioned_you_on_a_comment",
  35: "notif_feed_your_post_rejected",
  36: "notif_feed_your_post_approved",
  37: "notif_user_endorsed_your_profile",
  38: "notif_users_watched_your_profile",
  39: "notif_feed_mentioned_you_on_a_post",
  40: "notif_waiting_multiple_notifications",
  41: "notif_notif_task_reminder",
  42: "notif_message_share",
  43: "notif_academy_finish",
  44: "notif_general_notification",
  45: "notif_instagram_account_attached",
  46: "notif_extra_payout_added",
  47: "notif_affiliation_rules_changed",
  48: "notif_support_ticket_updated",
  51: "ACCEPTED_TASKS_OFFER",
  52: "DECLINED_TASKS_OFFER",
};

export function transalteNotifData(notif: any) {
  if (!notif || !notif.notification_data) return;

  let idx: keyof typeof notificationsType =
    notif.notification_data.notification_type;
  if (!idx) {
    idx = notif.notification_data.id;
  }
  const label = notificationsType[idx];
  const { parameters } = notif.notification_data;
  notif.data_text = window.translate("", label).format(...parameters);
  const date = new Date(notif.creation_time);
  notif.time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} ${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  return notif;
}

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getAgeGroup(ageGroup: any) {
  if (!ageGroup) return;
  switch (ageGroup) {
    case "elderly":
      return "Gen X";
    case "adult":
      return "Gen Y";
    case "child":
      return "Children";
    case "teen":
      return "Gen Z";
    default:
      return ageGroup;
  }
}

export function getCostString(costs: any) {
  if (costs.high === costs.low) return formatBigNumbers(costs.high);

  return `${numeral(costs.low).format("0.0a")}-${numeral(costs.high).format(
    "0.0a",
  )}`;
}

export function clone(obj: any): any {
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (obj == null || typeof obj !== "object") return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

export const Currencies = [
  { value: "NIS", label: "Shekel" },
  { value: "EUR", label: "Euro" },
  { value: "USD", label: "USD" },
  { value: "ZAR", label: "South African Rand" },
  { value: "GBP", label: "Pound Sterling" },
  { value: "TRY", label: "Turkish Lira" },
  { value: "AUD", label: "Australian Dollars" },
  { value: "NZD", label: "New Zealand Dollars" },
  { value: "NGN", label: "Nigerian naira" },
  { value: "BRL", label: "Brazilian real" },
];

export const ExtendedCurrencies = [
  { value: "KES", label: "Kenyan Shilling" },
  { value: "INR", label: "Indian Rupee" },
  { value: "UL", label: "Uni-Moolah" },
];

export const CURRENCY_TO_COUNTRY = {
  Israel: "NIS",
  "United States": "USD",
  "South Africa": "ZAR",
  "United Kingdom": "GBP",
  Turkey: "TRY",
  Australia: "AUD",
  "New Zealand": "NZD",
  Nigeria: "NGN",
  Brazil: "BRL",
  Kenya: "KES",
  India: "INR",
};

type getYouTubeIdType = (url: string) => string | null;
type getYouTubeIdGroupsType = { id: string; domain: string };

export const getYouTubeId: getYouTubeIdType = (url) => {
  if (!url) return null;
  const shortMatch = /.*(?<domain>youtu\.be)\/(?<id>[^?&/]{11,})/g.exec(url);
  if (shortMatch && shortMatch.groups) {
    const { id } = shortMatch.groups as getYouTubeIdGroupsType;
    return id.length === 11 ? id : "";
  }
  const longMatch =
    /.*(?<domain>youtube\.com\/)(embed\/|watch\?v=|v\/|shorts\/)(?<id>[^?&/]{11,})/g.exec(
      url,
    );
  if (longMatch && longMatch.groups) {
    const { id } = longMatch.groups as getYouTubeIdGroupsType;
    return id.length === 11 ? id : "";
  }
  return "";
};

export function isValidEmail(email: any) {
  if (!email) return null;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function formattedDate(d: Date) {
  return moment(d).format(window.time.short_date);
}

export const OnlyNumbersRegex = new RegExp(/^[0-9]*$/);

export const SubTypes = [
  { value: "post", label: "Post" },
  { value: "reel", label: "Reel" },
  { value: "igtv", label: "IG TV" },
];

export const tagColors = tagColorsConfig;

export const chartColors = [
  "#1c4586",
  "#099ac0",
  "#32b09b",
  "#f57e20",
  "#9655a2",
  "#eb417a",
  "#44a8df",
  "#61bd4f",
  "#f2d600",
  "#ff9f1a",
  "#eb5a46",
  "#c377e0",
  "#0079bf",
  "#00c2e0",
  "#51e898",
  "#ff78cb",
  "#344563",
  "#b3bac5",
];

export function getDefaultShortLink(user: UserReducer) {
  let prefix = "s";
  if (user && user.userDetails && user.userDetails.company_details) {
    const { country } = user.userDetails.company_details;
    if (country === "South Africa") {
      prefix = "z";
    } else if (country === "United States") {
      prefix = "us";
    } else if (country === "Brazil") {
      prefix = "b";
    }
  }
  return `${prefix}.humanz.ai/`;
}

export const POST_TYPE_TO_SOCIAL: any = {
  ig_post: "instagram",
  ig_story: "instagram",
  tw_tweet: "twitter",
  yt_video: "youtube",
  tk_video: "tiktok",
  influencer_content: "content",
};

export function getUserCurrencies(extras?: string[]) {
  const all = [...Currencies];
  if (extras?.length) {
    extras.forEach((c) => {
      const row = ExtendedCurrencies.find((x) => x.value === c);
      if (row) all.push(row);
    });
  }
  return all;
}

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export type SOCIAL_NETWORK =
  | "instagram"
  | "twitter"
  | "youtube"
  | "tiktok"
  | "only_humanz";

export function getSocialNetworkUsernameUrl(
  social_network: SOCIAL_NETWORK,
  username: string,
) {
  switch (social_network) {
    case "instagram":
      return `https://www.instagram.com/${username}/`;
    case "twitter":
      return `https://twitter.com/${username}`;
    case "youtube":
      return `https://www.youtube.com/channel/${username}`;
    case "tiktok":
      return `https://www.tiktok.com/@${username}`;
    default:
      return "";
  }
}

export const usStates = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "Armed Forces Americas",
    value: "AA",
  },
  {
    label: "Armed Forces Europe, Canada, Africa and Middle East",
    value: "AE",
  },
  {
    label: "Armed Forces Pacific",
    value: "AP",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District of Columbia",
    value: "DC",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Micronesia",
    value: "FM",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

export function isValidUrl(str: string) {
  if (!str) return true;
  return validator.isURL(str, {
    protocols: ["http", "https"],
    require_tld: true,
    require_protocol: true,
    require_host: true,
    require_port: false,
    require_valid_protocol: true,
    allow_underscores: false,
    allow_trailing_dot: false,
    allow_protocol_relative_urls: false,
    allow_fragments: true,
    allow_query_components: true,
    disallow_auth: false,
  });
}
export function sanitizeHtml(rawHtml: string) {
  return DOMPurify.sanitize(rawHtml, {
    ALLOWED_TAGS: [
      "p",
      "span",
      "div",
      "strong",
      "em",
      "del",
      "ul",
      "li",
      "ol",
      "ins",
      "a",
    ],
    ALLOWED_ATTR: ["style", "href", "target"],
  });
}

export function parseDate(input: string, format?: string) {
  format = format ?? "yyyy-mm-dd"; // default format
  const parts = input.match(/(\d+)/g);
  let i = 0;
  const fmt = {};
  // extract date-part indexes from the format
  // @ts-ignore
  format.replace(/(yyyy|dd|mm)/g, (part) => {
    // @ts-ignore
    fmt[part] = i++;
  });
  // @ts-ignore
  return new Date(parts[fmt.yyyy], parts[fmt.mm] - 1, parts[fmt.dd]);
}

export const TikTokVideoPrefix = "http://m.tiktok.com/v/";

export const maxWidthTextStyle: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "200px",
  height: "35px",
};

export function removeDuplicates(arr: any[], prop: string) {
  const new_arr = [];
  const lookup: { [key: string]: any } = {};

  for (const i of arr) {
    lookup[i[prop]] = i;
  }

  // eslint-disable-next-line guard-for-in
  for (const i in lookup) {
    new_arr.push(lookup[i]);
  }

  return new_arr;
}

export const digitsRegExp = /^$|^[0-9\b]+$/;

export type ExtractRequestBodyType<T> = T extends (
  requestBody: infer U,
  ...args: any[]
) => any
  ? U
  : never;
