import { Component } from "react";
import { connect } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ToggleButton from "react-toggle-button";
import {
  updateAuctionMiniUsers,
  updateReportMiniUsers,
} from "../../../actions/enterpriseActions";
import NoImage from "../../../images/svgs/placeholder.svg";
import { UserReducer } from "../../../reducers/userReducer";

interface OwnProps {
  campaign: any;
  all_users: any;
  handleClose: any;
  all_sub_users_allowed: false;
  mini_users: [];
  user: UserReducer;
  is_report?: boolean;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{
  loading: boolean;
  all_sub_users_allowed: any;
  mini_users: any;
}>;

class EnterpriseSelectCampaignUsersInner extends Component<Props, State> {
  readonly state: State = {} as any;

  init() {
    this.setState({
      loading: false,
      all_sub_users_allowed: this.props.campaign
        ? this.props.campaign.all_sub_users_allowed
        : false,
      mini_users: this.props.campaign
        ? this.props.campaign.sub_users_allowed
        : [],
    });
  }

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate(prev: any): void {
    if (prev.campaign.id !== this.props.campaign.id) this.init();
  }

  selectUser = (user: any) => {
    this.state.mini_users.addOrRemove(user.id);
    this.setState({}, () => {
      this.changeAuctionMiniUsers(this.props.campaign);
    });
  };

  renderRow = (user: any) => {
    const originalCreator = this.props.campaign.original_creator_id === user.id;
    const checked =
      (this.state.mini_users && this.state.mini_users.includes(user.id)) ||
      originalCreator;

    return (
      <div
        className={`import-list-row sub-users-list ${checked}`}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        disabled={originalCreator}
        key={`sub-users-list${user.id}`}
        onClick={() => {
          this.selectUser(user);
        }}
      >
        <div style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }}>
          <img className="blurable" src={user.image ? user.image : NoImage} />
        </div>
        <div
          className={`import-list-row-details import-user-row-details ${checked}`}
        >
          <strong className="invite-user-name blurable-large" title={user.name}>
            {user.name}
          </strong>
        </div>
        <div
          className={"checkbox"}
          style={{ padding: 0, paddingTop: 0, marginLeft: -10 }}
        >
          <label>
            <input type="checkbox" checked={checked} />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </div>
    );
  };

  changeAuctionMiniUsers = (c: any) => {
    this.setState({ loading: true });
    if (this.props.is_report) {
      this.props.updateReportMiniUsers(
        c.id,
        {
          all_mini_allowed: this.state.all_sub_users_allowed,
          allowed_mini_users: this.state.mini_users,
        },
        () => {
          this.setState({ loading: false });
        },
      );
    } else {
      this.props.updateAuctionMiniUsers(
        c.id,
        {
          all_mini_allowed: this.state.all_sub_users_allowed,
          allowed_mini_users: this.state.mini_users,
        },
        () => {
          this.setState({ loading: false });
        },
      );
    }
  };

  render() {
    const c = this.props.campaign;
    if (!c) return null;
    const allUsers = this.props.all_users ? this.props.all_users : [];
    const miniUsersChecked = allUsers.filter((user: any) =>
      this.state.mini_users?.includes(user.id),
    );

    return (
      <div
        style={{
          width: 750,
          marginLeft: "calc(100% - 970px)",
          position: "fixed",
          top: 0,
        }}
        className="import-from-list-container animated fadeInRight faster"
        id="enterprise_select_campaign_users_menu"
      >
        {this.state.loading ? (
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        ) : (
          <div className="import-from-list-inner-container">
            <div className="header">
              <div style={{ fontSize: 22 }}>
                Edit users for campaign {c.name}
              </div>
              <div
                className="import-list-close-button"
                onClick={() => this.props.handleClose(miniUsersChecked)}
              >
                <span className="moi-ximage" />
              </div>
            </div>
            <div className="content" style={{ marginTop: 20 }}>
              <div style={{ marginBottom: 20 }}>
                <label htmlFor="">Open for all users</label>
                <ToggleButton
                  value={this.state.all_sub_users_allowed}
                  onToggle={() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line react/no-direct-mutation-state
                    this.state.all_sub_users_allowed =
                      !this.state.all_sub_users_allowed;
                    this.changeAuctionMiniUsers(c);
                  }}
                  inactiveLabel={"No"}
                  activeLabel={"Yes"}
                />
              </div>

              {allUsers &&
                allUsers.map((u: any) => {
                  return this.renderRow(u);
                })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateAuctionMiniUsers: (id: bigint, data: any, callback: any) => {
      dispatch(updateAuctionMiniUsers(id, data, callback));
    },
    updateReportMiniUsers: (id: bigint, data: any, callback: any) => {
      dispatch(updateReportMiniUsers(id, data, callback));
    },
  };
};

export const EnterpriseSelectCampaignUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterpriseSelectCampaignUsersInner);
