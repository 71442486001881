import React, { useMemo } from "react";
import { RequireOnlyOne } from "src/declarations/UtilityTypes";
import { usePrompt } from "./usePromt";
import { ContinuePrompt } from "./ContinuePrompt";

type Params = RequireOnlyOne<
  {
    title?: React.ReactNode;
    titleIconClassName?: string;
    overlay?: boolean;
    text?: React.ReactNode;
    yesText?: string;
  },
  "title" | "titleIconClassName"
>;

export const useContinuePrompt = () => {
  const prompt = usePrompt();

  return useMemo(
    () => ({
      askToContinue: (params: Params) => {
        return new Promise<boolean>((res) => {
          const close = prompt.open({
            onClose: () => res(false),
            title: params.title,
            titleIconClassName: params.titleIconClassName as any,
            body: (
              <ContinuePrompt
                onYes={() => {
                  close();
                  res(true);
                }}
                onNo={() => {
                  close();
                  res(false);
                }}
                text={params.text}
                yesText={params.yesText}
              />
            ),
            overlay: params.overlay,
          });
        });
      },
    }),
    [prompt],
  );
};
