import { Box, Flex, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { InfluencerInvoiceDetails } from "src/api/types";
import ConfirmActionDialog from "../../dialogs/ConfirmActionDialog";

const InvoicesList = (props: any) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [inVoiceIdToDelete, setIdToDelete] = useState(-1);
  console.log(
    "props.influencerData?.payment_details",
    props.influencerData?.payment_details,
  );
  return props.invoices?.length ? (
    <>
      {showConfirmDelete && (
        <ConfirmActionDialog
          actionTitle="Delete this invoice"
          handleClose={() => setShowConfirmDelete(false)}
          actionDescription="Are you sure you want to delete it?"
          confirm={() => {
            props.handleDeleteClick(props.auction_id, inVoiceIdToDelete);
            setShowConfirmDelete(false);
          }}
        />
      )}

      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
        {props.invoices?.map(
          (invoice: InfluencerInvoiceDetails, index: number) => {
            const fileName = invoice?.url.substring(
              // eslint-disable-next-line
            invoice.url?.lastIndexOf("/") + 1,
            );
            const truncatedFileName =
              fileName.length > 16
                ? `${fileName.slice(0, 16)}\u2026`
                : fileName;
            return (
              <Box
                key={index}
                sx={{
                  border: "1px solid #EAE8E8",
                  background: "white",
                  borderRadius: "md",
                  padding: "14px",
                  height: "70px",
                  minHeight: "20px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxWidth: "600px",
                  margin: "5px",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Flex alignItems="center">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tooltip
                      label={fileName}
                      hasArrow
                      css={{
                        maxWidth: "250px",
                        whiteSpace: "pre-wrap", // This allows line breaks
                        overflowWrap: "break-word", // This breaks words if needed
                        display: "flex",
                      }}
                    >
                      <>
                        <a
                          href={!invoice.by_influencer ? invoice.url : null}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "200px",
                          }}
                        >
                          <i
                            className="fa-duotone fa-file-lines"
                            style={{ marginRight: "5px" }}
                          ></i>
                          <Box>
                            <Box>{truncatedFileName}</Box>
                            {invoice.month_relevance ? (
                              <Box color="gray.400" display="inline-flex">
                                {moment(invoice.month_relevance)
                                  .utc()
                                  .format(window.time.short_date)}
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>

                          {props.paymentTime ? (
                            <>
                              : {props.paymentTime.getDate()}-$
                              {props.paymentTime.getMonth() + 1}-
                              {props.paymentTime.getFullYear()}
                            </>
                          ) : (
                            ""
                          )}
                        </a>
                      </>
                    </Tooltip>
                    {!invoice.by_influencer ? (
                      <i
                        className="fal fa-trash"
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={() => {
                          setShowConfirmDelete(true);
                          setIdToDelete(invoice.id);
                        }}
                      ></i>
                    ) : (
                      <Tooltip
                        label="Uploded by influencer and can't be deleted "
                        placement="top"
                        w="160px"
                      >
                        <i
                          className="fa-solid fa-info-circle"
                          style={{ color: "grey" }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              </Box>
            );
          },
        )}
      </Box>
    </>
  ) : null;
};

export default InvoicesList;
