import { Box, Input } from "@chakra-ui/react";
import { CSSProperties, useRef, useState } from "react";

type Props = {
  onChange: (e: any) => void;
  value: string;
  style?: CSSProperties;
};

const InputDatePicker = ({ onChange, value, style }: Props) => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
    onChange(e);
  };

  const handleBoxClick = (e: any) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    try {
      (e.target as any)?.showPicker();
    } catch (_e) {
      /**/
    }
  };

  return (
    <Box
      style={{
        width: 100,
        height: 15,
        fontSize: 12,
        marginLeft: 10,
        textAlign: "end",
        cursor: "pointer",
        ...style,
      }}
      onClick={handleBoxClick}
    >
      <Box>{value || selectedDate}</Box>
      <Input
        ref={inputRef}
        placeholder="Select Date and Time"
        size="xs"
        type="date"
        data-date-format="DD MMMM"
        onFocus={handleBoxClick}
        sx={{
          width: 0,
          height: 0,
          position: "absolute",
          opacity: 0,
        }}
        onChange={handleInputChange}
      />
    </Box>
  );
};

export default InputDatePicker;
