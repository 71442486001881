// TODO - lint
/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/ban-ts-comment, @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unused-vars, func-style, no-param-reassign, no-param-reassign, @typescript-eslint/ban-ts-comment, class-methods-use-this, default-case, class-methods-use-this, @typescript-eslint/ban-ts-comment, @typescript-eslint/ban-ts-comment, class-methods-use-this, class-methods-use-this, @typescript-eslint/no-unused-vars, consistent-return, class-methods-use-this, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars, import/no-default-export */
import moment from "moment";
import Tooltip from "rc-tooltip";
import React from "react";
import Timeline, {
  CursorMarker,
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
// @ts-ignore
import { connect } from "react-redux";
import { TwitterTweetEmbed } from "react-twitter-embed";
// @ts-ignore
import YouTube from "react-youtube";
import { getTrackerShare } from "../../../actions/shareActions";
import CountTo from "../general_components/counter";

interface OwnProps {
  id: string;
  getTrackerShare: any;
  loader: any;
}

type State = Readonly<{
  events: any[];
  groups: any[];
  data: any;
  minZoom: any;
  maxZoom: any;
}>;

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

class ShareTracker extends React.Component<OwnProps, State> {
  // @ts-ignore
  state: State = {
    events: [],
    groups: [],
    minZoom: null,
    maxZoom: null,
    data: {
      events: null,
      kpi: null,
      summary: null,
      top_content: null,
    },
  };

  componentDidMount() {
    if (this.props.id) {
      this.props.getTrackerShare(this.props.id, (data: any) => {
        if (data) {
          console.log(data);
          const { events } = data;
          const groups: { id: number; title: string; stackItems: boolean }[] =
            [];
          events.forEach((_: any, i: number) => {
            events[i] = this.convertEventToTimelineItem(events[i], i);
            groups.push({ id: i, title: "", stackItems: true });
          });
          const minZoom = moment(events[0].start_time).add(-1, "weeks");
          const maxZoom = moment(events[events.length - 1].end_time).add(
            1,
            "weeks",
          );
          this.setState({
            data,
            events,
            groups,
            minZoom,
            maxZoom,
          });
        }
      });
    }
  }

  convertEventToTimelineItem = (event: any, index: number) => {
    // docs:
    // https://github.com/namespace-ee/react-calendar-timeline
    return {
      id: event.id,
      group: index,
      title: event.text,
      start_time: moment(event.start_time).startOf("day"),
      end_time: moment(event.end_time).endOf("day"),
      itemProps: {
        style: {
          background: event.color,
        },
      },
    };
  };

  changeTime = (time: string) => {
    if (!this.state.events || !this.state.events.length) {
      return;
    }
    switch (time) {
      case "day":
        this.setState({
          minZoom: moment(this.state.events[0].start_time).add(-1, "days"),
          maxZoom: moment(
            this.state.events[this.state.events.length - 1].end_time,
          ).add(1, "days"),
        });
        break;
      case "week":
        this.setState({
          minZoom: moment(this.state.events[0].start_time).add(-1, "weeks"),
          maxZoom: moment(
            this.state.events[this.state.events.length - 1].end_time,
          ).add(1, "weeks"),
        });
        break;
      case "month":
        this.setState({
          minZoom: moment(this.state.events[0].start_time).add(-1, "months"),
          maxZoom: moment(
            this.state.events[this.state.events.length - 1].end_time,
          ).add(1, "months"),
        });
        break;
    }
  };

  renderBigBox(num1: number, num2: number, text: string) {
    // @ts-ignore
    let numbers = (
      <>
        {num1 ? <CountTo to={num1} speed={getRandomInt(1000, 2000)} /> : "?"}
        <span className={"small-text"}> of </span>
        {num2 ? <CountTo to={num2} speed={getRandomInt(1000, 2000)} /> : "?"}
      </>
    );
    if (!num2 || num2 === 0) {
      // @ts-ignore
      numbers = <CountTo to={num1} speed={getRandomInt(1000, 2000)} />;
    }
    return (
      <div className={"box big-box"}>
        <div className="numbers">{numbers}</div>
        <div className="description">{text}</div>
      </div>
    );
  }

  renderSmallBox(num1: number, _: number, text: string) {
    return (
      <div className={"box small-box"}>
        <div className="numbers">
          {num1 ? <CountTo to={num1} speed={getRandomInt(1000, 2000)} /> : "?"}
        </div>
        <div className="description">{text}</div>
      </div>
    );
  }

  renderCubes = (data: any) => {
    if (!data || !data.summary || !data.kpi) return "";

    return (
      <>
        <div className="flex">
          <div style={{ flex: 2 }}>
            <div className="flex">
              {this.renderBigBox(
                data.summary.chosen.current,
                data.summary.chosen.target,
                "Chosen influencers",
              )}
              {this.renderBigBox(
                data.summary.content_approved.current,
                data.summary.content_approved.target,
                "Contents approved",
              )}
              {this.renderBigBox(
                data.summary.content_published.current,
                data.summary.content_published.target,
                "Contents uploaded",
              )}
            </div>
          </div>
          <div
            className="flex"
            style={{ flex: 1, flexFlow: "wrap", alignItems: "flex-start" }}
          >
            {data.kpi?.impressions?.current &&
              this.renderSmallBox(
                data.kpi.impressions.current,
                data.kpi.impressions.target,
                "Impressions",
              )}
            {data.kpi?.engagement?.current &&
              this.renderSmallBox(
                data.kpi.engagements.current,
                data.kpi.engagements.target,
                "Engagements",
              )}
            {data.kpi?.conversions?.current &&
              this.renderSmallBox(
                data.kpi.conversions.current,
                data.kpi.conversions.target,
                "Conversions",
              )}
          </div>
        </div>
      </>
    );
  };

  renderPost = (selectedSocial: string, post: string) => {
    if (selectedSocial === "instagram") {
      return (
        <div
          className="blurable-frame"
          style={{
            display: "inline-block",
            width: 290,
            height: 535,
            overflow: "hidden",
            marginTop: 30,
          }}
          key={post}
        >
          <iframe
            src={`https://www.instagram.com/p/${post}/embed/captioned`}
            width="288"
            height="535"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      );
    }
    if (selectedSocial === "twitter") {
      return (
        <div
          className="blurable-frame"
          style={{
            display: "inline-block",
            width: 290,
            height: 535,
            overflow: "hidden",
            marginTop: 30,
          }}
          key={post}
        >
          <TwitterTweetEmbed tweetId={post} />
        </div>
      );
    }
    if (selectedSocial === "youtube") {
      return (
        <div
          className="blurable-frame"
          style={{
            display: "inline-block",
            width: 350,
            height: 250,
            overflow: "hidden",
            marginTop: 30,
          }}
          key={post}
        >
          <YouTube videoId={post} opts={{ width: "350", height: "250" }} />
        </div>
      );
    }
    if (selectedSocial === "tiktok") {
      return (
        <div style={{ overflow: "hidden", marginTop: -100, marginLeft: -15 }}>
          <iframe
            width={"340"}
            height={"700"}
            src={`https://www.tiktok.com/embed/${post}`}
            frameBorder={"0"}
            allow={
              "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            }
          />
        </div>
      );
    }
  };

  itemRenderer = ({ item, itemContext, getItemProps }: any) => {
    return (
      <div {...getItemProps(item.itemProps)} title={""}>
        <Tooltip
          placement={"bottom"}
          overlay={`${moment(item.start_time).format("LL")} - ${moment(
            item.end_time,
          ).format("LL")}`}
        >
          <div>
            {/* {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''} */}
            <div
              className="rct-item-content"
              style={{ maxHeight: `${itemContext.dimensions.height}` }}
            >
              {itemContext.title}
            </div>
            {/* {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''} */}
          </div>
        </Tooltip>
      </div>
    );
  };

  render() {
    return this.state.data.summary ? (
      <>
        <div
          className={""}
          style={{ marginTop: 65, maxWidth: "100%", marginLeft: 10 }}
        >
          <div className="campaign-details">
            <div className="campaign-title flex">
              <div className="campaign-image">
                <img
                  className={"top-user-pic"}
                  src={this.state.data?.summary?.image}
                  alt=""
                />
              </div>
              <span>{this.state.data?.summary?.title}</span>
            </div>
          </div>
          <div className="top-boxes">{this.renderCubes(this.state.data)}</div>
          {this.state.events &&
          this.state.events.length &&
          this.state.minZoom &&
          this.state.maxZoom ? (
            <div style={{ padding: 20 }}>
              <div className="campaigns-title">
                <span>Campaign timeline</span>
              </div>
              <div className="timeline-controls">
                <button
                  className="time-trigger"
                  onClick={() => this.changeTime("day")}
                >
                  DAY
                </button>
                <button
                  className="time-trigger"
                  onClick={() => this.changeTime("week")}
                >
                  WEEK
                </button>
                <button
                  className="time-trigger"
                  onClick={() => this.changeTime("month")}
                >
                  MONTH
                </button>
              </div>
              <Timeline
                groups={this.state.groups}
                items={this.state.events}
                sidebarWidth={0}
                lineHeight={46}
                canMove={true}
                canResize={false}
                canChangeGroup={false}
                stackItems={true}
                minResizeWidth={100}
                defaultTimeStart={this.state.minZoom}
                defaultTimeEnd={this.state.maxZoom}
                visibleTimeStart={moment(this.state.minZoom).toDate().getTime()}
                visibleTimeEnd={moment(this.state.maxZoom).toDate().getTime()}
                itemRenderer={this.itemRenderer}
              >
                <TimelineMarkers>
                  <CursorMarker />
                  <TodayMarker date={undefined}>
                    {({ styles }: any) => (
                      // date is value of current date. Use this to render special styles for the marker
                      // or any other custom logic based on date:
                      // e.g. styles = {...styles, backgroundColor: isDateInAfternoon(date) ? 'red' : 'limegreen'}
                      <div
                        style={{
                          ...styles,
                          backgroundColor: "var(--main-baby-blue-color)",
                        }}
                      />
                    )}
                  </TodayMarker>
                </TimelineMarkers>
              </Timeline>
            </div>
          ) : (
            ""
          )}
          {this.state.data && this.state.data.top_content && (
            <div style={{ padding: 20 }}>
              <div className="campaigns-title">
                <span>Top content</span>
              </div>
              {this.state.data.top_content.map((post: any) =>
                this.renderPost(post.social_network, post.linked_media),
              )}
            </div>
          )}
        </div>
      </>
    ) : (
      ""
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getTrackerShare: (id: string, callback: any) =>
    dispatch(getTrackerShare(id, callback)),
});

export default connect(null, mapDispatchToProps)(ShareTracker);
