import React from "react";
import { MixedTasks } from "src/api/ExtendedTypes";
import { InfluencerInManageAuctionData } from "src/api/types";

export type EditTasksContextType = {
  mixedTasks: MixedTasks;
  setMixedTasks: (mixedTasks: MixedTasks) => void;
  influencer:
    | InfluencerInManageAuctionData
    | InfluencerInManageAuctionData[]
    | any;
};
export const EditTasksContext = React.createContext<EditTasksContextType>({
  mixedTasks: {
    bundles: [],
    single: [],
  },
  influencer: null,
  setMixedTasks: () => {},
});
