import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { CampaignTag } from "src/api/types";

interface OwnProps {
  tags: CampaignTag[];
}

const MAX_TAGS_STR_LENGTH = 50;

export const CampaignInfluencerTagsList = (props: OwnProps) => {
  const [selfTags, setSelfTags] = useState<CampaignTag[]>([]);
  const [hiddenTagsLength, setHiddenTagsLength] = useState(0);

  useEffect(() => {
    let currentLength = 0;
    const newTags: any = [];
    props.tags.forEach((tag) => {
      currentLength += tag.label.length;
      if (currentLength <= MAX_TAGS_STR_LENGTH) {
        newTags.push(tag);
      }
    });
    setSelfTags(newTags);
    setHiddenTagsLength(props.tags.length - newTags.length);
  }, [props.tags]);

  const renderTagOnRow = (t: CampaignTag, idx: number) => {
    return (
      <div style={{ background: t.color }} key={idx} dir="auto">
        {t.label}
      </div>
    );
  };

  const renderTagsPopup = () => {
    return (
      <>
        <div className="edit-tags-popup-title">
          <span>All Tags</span>
        </div>
        <div style={{ padding: "5px 10px", width: 280 }}>
          {props.tags.map((tag, i) => (
            <div key={i} className="inf-tags-container">
              <div style={{ background: tag.color }}>
                <div className="campaign-tag" dir="auto">
                  {tag.label}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      {selfTags.map((tag, i) => renderTagOnRow(tag, i))}
      {hiddenTagsLength ? (
        <Tooltip
          trigger={["hover"]}
          placement="top"
          overlayClassName={"edit-popup"}
          overlay={renderTagsPopup()}
        >
          <div className="campaign-tag">+ {hiddenTagsLength}</div>
        </Tooltip>
      ) : null}
    </>
  );
};
