import {
  Box,
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getShareKeys, setShareKey } from "src/actions/shareActions";
import { getShareInvoice } from "src/api/actions/shareActions/auctionActions";
import { PayoutInvoiceData } from "src/api/types";
import logoBlue from "../../../../images/svgs/logo-blue.svg";
import UploadInvoiceDialog from "../../campaign_components/dialogs/UploadInvoiceDialog";
import DataTable from "../../general_components/DataTable";
import { InfiniteLoader } from "../../general_components/InfiniteLoader";
import { maxWidthTextStyle, numberWithCommas } from "../../utilities/general";
import { InvoicePreview } from "./InvoicePreview";

const InvoiceModal = ({
  url,
  onUploadInvoice,
  onClose,
}: {
  url?: string;
  onClose(): void;
  onUploadInvoice?(): void;
}) => {
  return useMemo(() => {
    if (url) {
      return (
        <Modal isOpen={true} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={"700px"} maxHeight={"700px"}>
            <ModalHeader>Invoice Preview</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <InvoicePreview
                className={"share-invoice-preview"}
                height={"100%"}
                width={"100%"}
                url={url}
              />
              <ModalFooter style={{ justifyContent: "space-between" }}>
                <Button colorScheme="blue" width={"45%"} onClick={onClose}>
                  סגירה
                </Button>
                <Button
                  colorScheme="blue"
                  width={"45%"}
                  onClick={() => {
                    onUploadInvoice();
                    onClose();
                  }}
                >
                  חשבונית חדשה
                </Button>
              </ModalFooter>
            </ModalBody>
          </ModalContent>
        </Modal>
      );
    }
    return null;
  }, [url]);
};

const PasswordModal = ({
  authKey,
  onSubmit,
}: {
  authKey: string;
  onSubmit(): void;
}) => {
  const handleSubmit = () => {
    const value = (
      document.getElementById("pass-input") as HTMLInputElement
    )?.value?.trim();
    if (value) {
      setShareKey(authKey, value);
      onSubmit();
    }
  };
  return (
    <Modal isOpen={true} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent mt={"100px"}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <FormControl>
            <Input
              id="pass-input"
              textAlign={"center"}
              placeholder="מספר ח.פ של החברה"
            />
          </FormControl>
          <ModalFooter style={{ justifyContent: "center" }}>
            <Button colorScheme="blue" onClick={handleSubmit}>
              כניסה
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface Filters {
  auction_id: string;
  auction_title: string;
  name: string;
  waiting_for_me: string;
  price: string;
  paid: string;
  current_invoice: string;
  paid_vat: string;
}

const filteredData = (data: PayoutInvoiceData[], filters: Filters) => {
  let newData = data;
  if (filters.auction_id) {
    newData = newData.filter((x) =>
      x.auction_id.toString().startsWith(filters.auction_id),
    );
  }
  if (filters.auction_title) {
    newData = newData.filter((x) =>
      x.auction_title
        .toLowerCase()
        .includes(filters.auction_title.toLowerCase()),
    );
  }
  if (filters.name) {
    newData = newData.filter((x) =>
      x.name.toLowerCase().includes(filters.name.toLowerCase()),
    );
  }
  if (filters.price) {
    newData = newData.filter((x) =>
      x.price.toString().startsWith(filters.price),
    );
  }
  if (filters.paid) {
    newData = newData.filter((x) => x.paid.toString().startsWith(filters.paid));
  }
  if (filters.paid_vat) {
    newData = newData.filter((x) =>
      (x.paid * 1.17).toString().startsWith(filters.paid_vat),
    );
  }
  if (filters.current_invoice) {
    const value = filters.current_invoice === "true";
    newData = newData.filter(
      (x) => (x.waiting_for_me ? false : !!x.current_invoice) === value,
    );
  }
  if (filters.waiting_for_me) {
    const value = filters.waiting_for_me === "true";
    newData = newData.filter((x) => x.waiting_for_me === value);
  }

  return newData;
};

const AgencyInvoices = () => {
  const [passwordMode, setPasswordMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [openInvoice, setOpenInvoice] = useState(
    null as { url: string; onUploadInvoice(): void } | null,
  );
  const [uploadInvoiceId, setUploadInvoiceId] = useState(
    null as { influencer_id: number; auction_id: number; month: string } | null,
  );
  const [filters, setFilters] = useState({} as Filters);
  const params = useParams();

  useEffect(() => {
    if (params.auth && sessionStorage.getItem(params.auth)) {
      setShareKey(params.auth, sessionStorage.getItem(params.auth));
      setPasswordMode(false);
    }
  }, [params.auth]);

  useEffect(() => {
    if (!passwordMode) {
      setIsLoading(true);
      getShareInvoice()
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
          const keys = getShareKeys();
          sessionStorage.setItem(keys["auth-key"], keys["auth-key-password"]);
        })
        .catch(() => {
          setData(null);
          setIsLoading(false);
          setPasswordMode(true);
          if (params.auth) {
            sessionStorage.removeItem(params.auth);
          }
          toast.error("פרטים לא נכונים", { position: "top-center" });
        });
    }
  }, [passwordMode, params.auth]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<PayoutInvoiceData>();
    return [
      columnHelper.accessor("name", {
        cell: (info) => (
          <div
            title={info.getValue()}
            style={{
              ...maxWidthTextStyle,
              whiteSpace: "pre-wrap",
              maxWidth: 150,
              height: "auto",
            }}
          >
            {info.getValue()}
          </div>
        ),
        header: () => "שם המשפיען",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, name: value });
          },
        },
      }),
      columnHelper.accessor("auction_id", {
        cell: (info) => info.getValue(),
        header: "מס׳ קמפיין",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, auction_id: value });
          },
        },
      }),
      columnHelper.accessor("auction_title", {
        cell: (info) => (
          <div
            title={info.getValue()}
            style={{
              ...maxWidthTextStyle,
              whiteSpace: "pre-wrap",
              maxWidth: 200,
              height: "auto",
            }}
          >
            {info.getValue()}
          </div>
        ),
        header: "שם קמפיין",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, auction_title: value });
          },
        },
      }),
      columnHelper.accessor("month", {
        cell: (info) => {
          const value = info.getValue()
            ? moment(info.getValue()).format("YYYY-MM")
            : "";
          return (
            <div
              title={value}
              style={{
                ...maxWidthTextStyle,
                whiteSpace: "pre-wrap",
                maxWidth: 180,
                height: "auto",
              }}
            >
              {value}
            </div>
          );
        },
        header: "חודש הקמפיין",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, auction_title: value });
          },
        },
      }),
      columnHelper.accessor("price", {
        cell: (info) =>
          `${numberWithCommas(info.getValue())} ${window.translate(
            "",
            `money_symbol_${"NIS"}`,
          )}`,
        header: "סכום לתשלום",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, price: value });
          },
        },
      }),
      columnHelper.accessor("paid", {
        cell: (info) =>
          `${numberWithCommas(info.getValue())} ${window.translate(
            "",
            `money_symbol_${"NIS"}`,
          )}`,
        header: "שולם",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, paid: value });
          },
        },
      }),
      columnHelper.accessor("paid", {
        cell: (info) => {
          const value =
            info.row.original.price.toFixed(0) ===
            info.row.original.paid.toFixed(0)
              ? info.getValue() * 1.17
              : 0;
          return `${numberWithCommas(value)} ${window.translate(
            "",
            `money_symbol_${"NIS"}`,
          )}`;
        },
        header: "שולם כולל מע״מ",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, paid_vat: value });
          },
        },
      }),

      columnHelper.accessor("current_invoice", {
        cell: (info) => {
          const invoice = info.getValue();
          const needToUpload = !invoice || info.row.original.waiting_for_me;
          return (
            <Button
              onClick={() => {
                const func = () =>
                  setUploadInvoiceId({
                    influencer_id: info.row.original.influencer_id,
                    auction_id: info.row.original.auction_id,
                    month: info.row.original.month,
                  });
                if (needToUpload) {
                  func();
                } else {
                  setOpenInvoice({
                    url: invoice,
                    onUploadInvoice: func,
                  });
                }
              }}
              colorScheme={needToUpload ? "brand" : "green"}
              width={140}
            >
              {needToUpload ? "העלאת חשבונית" : "צפייה בחשבונית"}
            </Button>
          );
        },
        header: "מסמך",
        meta: {
          selectInputData: {
            onSelectChange: (value: string) => {
              setFilters({ ...filters, current_invoice: value });
            },
            placeHolder: "",
            options: [
              { label: "הכל", value: "" },
              { label: "קיים", value: true },
              { label: "לא קיים", value: false },
            ],
          },
        },
      }),
      columnHelper.accessor("waiting_for_me", {
        cell: (info) => {
          const waiting = info.getValue();
          return (
            <Text fontWeight={"bold"} color={waiting ? "red.500" : "gray.500"}>
              {waiting ? "ממתין לך" : "בתהליך קליטה"}
            </Text>
          );
        },
        header: "סטטוס",
        meta: {
          selectInputData: {
            onSelectChange: (value: string) => {
              setFilters({ ...filters, waiting_for_me: value });
            },
            placeHolder: "",
            options: [
              { label: "הכל", value: "" },
              { label: "ממתין", value: true },
              { label: "בתהליך", value: false },
            ],
          },
        },
      }),
    ];
  }, [filters]);

  const onCloseInvoice = () => {
    setOpenInvoice(null);
  };

  const handleUploadCallback = (_: number, fileURL: string) => {
    const newData = [...data];
    if (fileURL) {
      const index = newData.findIndex(
        (x) =>
          x.auction_id === uploadInvoiceId.auction_id &&
          x.influencer_id === uploadInvoiceId.influencer_id,
      );
      if (index > -1) {
        newData[index].waiting_for_me = false;
        newData[index].current_invoice = fileURL;
      }
      setData(newData);
    }
    setUploadInvoiceId(null);
  };

  const dataToDisplay = useMemo(
    () => (data ? filteredData(data, filters) : null),
    [data, filters],
  );

  if (isLoading) {
    return (
      <div>
        <InfiniteLoader />
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex wizard-header">
        <div className="logo-blue flex1" style={{ textAlign: "center" }}>
          <img src={logoBlue} alt="" />
        </div>
      </div>
      {passwordMode ? (
        <PasswordModal
          authKey={params.auth}
          onSubmit={() => setPasswordMode(false)}
        />
      ) : null}
      {dataToDisplay ? (
        <div>
          <Box textAlign={"center"}>
            {/* <h3>ברוך הבא Agency</h3> */}
            <h4>החשבוניות שלך </h4>
          </Box>
          <Box>
            <DataTable
              showInputFilter={true}
              containerClassName="invoice-table"
              columns={columns}
              data={dataToDisplay}
              containerProps={{
                dir: "auto",
                bg: "white",
                maxWidth: 1300,
                margin: "20px auto",
                border: "1px solid #ccc",
                borderRadius: "10px",
                width: "fit-content",
              }}
              pageSizeAmount={10000}
              defaultSorting={[{ id: "waiting_for_me", desc: true }]}
            />
          </Box>

          <InvoiceModal {...(openInvoice || {})} onClose={onCloseInvoice} />
          {uploadInvoiceId ? (
            <UploadInvoiceDialog
              isOpen={true}
              onClose={() => setUploadInvoiceId(null)}
              callback={handleUploadCallback}
              {...uploadInvoiceId}
              shareMode={true}
            />
          ) : null}
        </div>
      ) : (
        <Box mt={10} textAlign={"center"}>
          <h4>לא נמצאו פרטים</h4>
        </Box>
      )}
      <ToastContainer pauseOnFocusLoss={false} />
    </div>
  );
};

export default AgencyInvoices;
