import { Alert, AlertIcon, Box, Button, Center, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { StagesAccordion } from "../StagesAccordion";

interface Props {
  isLoading: boolean;
  nextStep: any;
  platform: any;
  appId: number;
}

const magentoSteps: any = (setCurrentStep: any, props: Props) => [
  {
    id: 0,
    title: "Download Humanz Pixel Plugin",
    body: (
      <>
        <Box className="small-step">
          <p>
            Download the extension and extract the folder from the zip file to
            your computer
            <br />
            <Center>
              <Link
                href="https://assets.humanz.com/plugins/humanz-pixel-magento-latest.zip"
                isExternal
                download={
                  "https://assets.humanz.com/plugins/humanz-pixel-magento-latest.zip"
                }
              >
                <Button
                  m={5}
                  size="lg"
                  colorScheme={"green"}
                  onClick={() => setCurrentStep(1)}
                >
                  Download&nbsp;
                  <i className="far fa-download" />
                </Button>
              </Link>
            </Center>
          </p>
        </Box>
      </>
    ),
  },
  {
    id: 1,
    title: "Install The plugin on your server",
    body: (
      <>
        <Box className="small-step">
          <p>
            Connect to your Magento server (with FTP/SCP) and upload the pixel
            extension to your root magento folder. The path to the plugin should
            be
            <code>[magento_path]/app/code/Humanz</code> If you already have the{" "}
            <strong>“code”</strong> folder, just drop the{" "}
            <strong>“Humanz”</strong> folder into it.
          </p>
        </Box>
      </>
    ),
  },
  {
    id: 2,
    title: "Install the plugin on Magento system",
    body: (
      <>
        <Box className="small-step">
          <p>
            This could vary depending on your installation or development
            practices. <br />
            The most basic and straightforward way to install this extension is
            to:
            <ul>
              <ol style={{ paddingLeft: 0 }}>1. SSH to your server</ol>
              <ol style={{ paddingLeft: 0 }}>
                2. Run the command - <code>php bin/magento setup:upgrade</code>
              </ol>
              <ol style={{ paddingLeft: 0 }}>
                3. If the following command won’t execute properly, you are not
                privileged to run it from the current user, use{" "}
                <strong>sudo</strong>
              </ol>
            </ul>
            <br />
            If you will use sudo, you will also need to give the appropriate
            permissions to generated files and directories, i.e.: <br />
            <code>chmod -R 777 var pub/static chmod -R 777 generated</code>
            <br />
            <strong style={{ color: "red" }}>
              (777 permission is dangerous and only being used as an example,
              check for magento guidelines for files permissions)
            </strong>
          </p>
        </Box>
      </>
    ),
  },
  {
    id: 3,
    title: "Go to the Humanz Pixel Plugin settings page",
    body: (
      <>
        <Box className="small-step">
          <p>
            Go to your admin panel <i className="fa fa-arrow-right" /> marketing{" "}
            <i className="fa fa-arrow-right" /> Humanz pixel{" "}
            <i className="fa fa-arrow-right" /> click on General settings.
          </p>
        </Box>
        <div className="small-step">
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/magento1.png"
            alt=""
            width="600"
          />
        </div>
      </>
    ),
  },
  {
    id: 4,
    title: "Finish the installation",
    body: (
      <>
        <Alert status="info">
          <AlertIcon />
          <span>
            If you are not being redirected to the Humanz pixel settings - click
            on “Humanz pixel <i className="fa fa-arrow-right" /> General
            settings” at the bottom left.
          </span>
        </Alert>
        <Box className="small-step">
          <p>
            Now fill your advertiser ID which is <code>{props?.appId}</code>.
            Hit “Save config” and you are done!
          </p>
        </Box>
        <div className="small-step">
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/magento2.png"
            alt=""
            width="800"
          />
        </div>
      </>
    ),
  },
];

const woocommerceSteps: any = (setCurrentStep: any, props: Props) => [
  {
    id: 0,
    title: "Download Humanz Pixel Plugin",
    body: (
      <>
        <Box className="small-step">
          <p>
            Download the extension and extract the folder from the zip file to
            your computer
            <br />
            <Center>
              <Link
                href="https://assets.humanz.com/plugins/humanz_pixel_woocommerce_latest.zip"
                isExternal
                download={
                  "https://assets.humanz.com/plugins/humanz_pixel_woocommerce_latest.zip"
                }
              >
                <Button
                  m={5}
                  size="lg"
                  colorScheme={"green"}
                  onClick={() => setCurrentStep(1)}
                >
                  Download&nbsp;
                  <i className="far fa-download" />
                </Button>
              </Link>
            </Center>
          </p>
        </Box>
      </>
    ),
  },
  {
    id: 1,
    title: "Go to plugins section",
    body: (
      <>
        <Box className="small-step">
          <p>
            On your admin panel, go to Plugins{" "}
            <i className="fa fa-arrow-right" /> Add new
          </p>
        </Box>
        <div className="small-step">
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/woo1.png"
            alt=""
            width="200"
          />
        </div>
      </>
    ),
  },
  {
    id: 2,
    title: "Install the plugin on Wordpress system",
    body: (
      <>
        <Box className="small-step">
          <p>
            Click on “Upload plugin” and choose the file that you’ve downloaded.
            Click Install now.
          </p>
        </Box>
        <div className="small-step">
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/woo2.png"
            alt=""
            width="700"
          />
        </div>
      </>
    ),
  },
  {
    id: 3,
    title: "Go to the Humanz Pixel Plugin settings page",
    body: (
      <>
        <Box className="small-step">
          <p>
            After the plugin is installed, on your admin panel go to settings{" "}
            <i className="fa fa-arrow-right" /> Humanz Pixel
          </p>
        </Box>
        <div className="small-step">
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/woo3.png"
            alt=""
            width="300"
          />
        </div>
      </>
    ),
  },
  {
    id: 4,
    title: "Finish the installation",
    body: (
      <>
        <Alert status="info">
          <AlertIcon />
          <span>
            You must change the Humanz ID Field in order to the Humanz Pixel to
            work on your website.
          </span>
        </Alert>
        <Box className="small-step">
          <p>
            Now fill your Humanz ID which is <code>{props?.appId}</code>. Hit
            “Save Changes” and you are done!
          </p>
        </Box>
        <div className="small-step">
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/woo4.png"
            alt=""
            width="500"
          />
        </div>
      </>
    ),
  },
];

export const Plugin = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps(
      props.platform.name === "Magento"
        ? magentoSteps(setCurrentStep, props)
        : woocommerceSteps(setCurrentStep, props),
    );
  }, [props]);

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <StagesAccordion
      currentStep={currentStep}
      isLoading={props.isLoading}
      nextStep={nextStep}
      prevStep={prevStep}
      completeStage={props.nextStep}
      steps={steps}
      description={null}
      eventValidation={true}
      allowNextSteps={true}
      setStep={(step: any) => () => setCurrentStep(step.id)}
    />
  );
};
