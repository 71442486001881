import { Box, Text } from "@chakra-ui/react";
import { CSSProperties, useState } from "react";
import { EditorConvertToHtml } from "src/components/feed_components/general_components/MarkDownEditor";

type Props = {
  header: string;
  value: string;
  setValue: (newValue: string) => void;
  isShare?: boolean;
  placeholder: string;
  style?: CSSProperties;
};

const IndicatorDescription = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            background: isFocused ? "#249FF0" : "#EBEBEB",
            height: "auto",
            marginRight: "8px",
            width: "1px",
          }}
        ></Box>
        <Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
          >
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "auto",
                padding: 0,
                color: "#222325",
              }}
            >
              {props.header}
            </Text>
          </Box>
          <div style={{ ...props.style }}>
            <EditorConvertToHtml
              value={props.value}
              setValue={props.setValue}
              isShare={props.isShare}
              placeholder={
                " Describe additional details about this campaign, and your vision for the influencers"
              }
              disabled={props.isShare}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default IndicatorDescription;
