// Bug with axios on CI, import AxiosRequestConfig
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from "axios";
import { toast } from "react-toastify";
import { listsActions } from "src/reducers/listsSlice";
import { getBackendUri, getSavedJwtToken } from "./commonActionsConf";
import { renewRefreshToken, setTokens } from "./userActions";

const auctionsUrlBase = () => `${getBackendUri()}/moi/auctions`;
const listUrlBase = () => `${getBackendUri()}/moi/premium/list`;
const enterpriseBase = () => `${getBackendUri()}/moi/enterprise`;
const enterpriseInviteBase = () => `${getBackendUri()}/enterprise_invite`;

export interface MiniUsers {
  allowed_mini_users: number[];
  all_mini_allowed: boolean;
}

export const updateAuctionMiniUsers = (
  auction_id: bigint,
  mini_users_data: MiniUsers,
  callback: any,
) => {
  return () => {
    axios
      .put(`${auctionsUrlBase()}/${auction_id}/mini_users`, mini_users_data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then(() => {
        toast.success(
          window.translate(
            "Users updated",
            "system_notifications_success_users_updated",
          ),
        );
        callback(true);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to update users",
            "system_notifications_failure_auction_update_users",
          ),
        );
      });
  };
};
export const updateReportMiniUsers = (
  report_id: bigint,
  mini_users_data: MiniUsers,
  callback: any,
) => {
  return () => {
    axios
      .put(
        `${getBackendUri()}/moi/insights/${report_id}/mini_users`,
        mini_users_data,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        },
      )
      .then(() => {
        toast.success(
          window.translate(
            "Users updated",
            "system_notifications_success_users_updated",
          ),
        );
        callback(true);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to update users",
            "system_notifications_failure_auction_update_users",
          ),
        );
      });
  };
};

export const updateListMiniUsers = (
  list_id: bigint,
  mini_users_data: MiniUsers,
  callback: any,
) => {
  return (dispatch: any) => {
    axios
      .put(`${listUrlBase()}/${list_id}/mini_users`, mini_users_data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then(() => {
        toast.success(
          window.translate(
            "Users updated",
            "system_notifications_success_users_updated",
          ),
        );
        dispatch(
          listsActions.updateListMiniUsers({ id: list_id, mini_users_data }),
        );
        callback(true);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to update users",
            "system_notifications_failure_auction_update_users",
          ),
        );
      });
  };
};

export const createEnterpriseMiniUser = (
  mini_users_data: any,
  callback: any,
) => {
  return () => {
    axios
      .post(`${enterpriseBase()}/users/add_user`, mini_users_data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then((r) => {
        toast.success(
          window.translate(
            "Users updated",
            "system_notifications_success_user_created",
          ),
        );

        callback(r.data);
      })
      .catch((e) => {
        callback(false);
        if (e.response && e.response.data && e.response.data.error) {
          toast.error(
            window.translate(
              e.response.data.error.replace(/_/g, " "),
              "system_notifications_failure_create_miniuser",
            ),
          );
        } else {
          toast.error(
            window.translate(
              "Failed to create user",
              "system_notifications_failure_create_miniuser",
            ),
          );
        }
      });
  };
};

export const editEnterpriseMiniUser = (mini_users_data: any, callback: any) => {
  return () => {
    axios
      .put(`${enterpriseBase()}/users/edit_user`, mini_users_data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then((r) => {
        toast.success(
          window.translate(
            "Users updated",
            "system_notifications_success_user_created",
          ),
        );
        callback(r.data);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to create user",
            "system_notifications_failure_create_miniuser",
          ),
        );
      });
  };
};

export const deleteEnterpriseMiniUser = (
  mini_users_data: any,
  callback: any,
) => {
  return () => {
    axios
      .delete(`${enterpriseBase()}/users/remove_user`, {
        data: mini_users_data,
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then(() => {
        toast.success(
          window.translate(
            "User removed",
            "system_notifications_success_user_deleted",
          ),
        );
        callback(true);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to remove user",
            "system_notifications_failure_auction_remove_user",
          ),
        );
      });
  };
};

export const getMiniUsers = (callback: any) => {
  return () => {
    axios
      .get(`${getBackendUri()}/moi/enterprise/users`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then((r) => {
        callback(r.data);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to get users",
            "system_notifications_failure_auction_update_users",
          ),
        );
      });
  };
};

export const getEnterpriseInvite = (invite_id: string, callback: any) => {
  return () => {
    axios
      .get(`${enterpriseInviteBase()}/${invite_id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then((r) => {
        callback(r.data);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to get enterprise invite",
            "system_notifications_failure_enterprise_invite",
          ),
        );
      });
  };
};

export const acceptEnterpriseInvite = (invite_id: string, callback: any) => {
  return () => {
    axios
      .get(`${enterpriseInviteBase()}/${invite_id}/accept`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then(async (r) => {
        const success = await renewRefreshToken();
        const jwtTok = success.headers["x-moibackendauthorization"];
        const jwtRefreshTok = success.headers["x-moibackendrefreshtoken"];
        await setTokens(jwtTok, jwtRefreshTok);
        callback(r.data);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to accept enterprise invite",
            "system_notifications_failure_accept_enterprise_invite",
          ),
        );
      });
  };
};

export const declineEnterpriseInvite = (invite_id: string, callback: any) => {
  return () => {
    axios
      .get(`${enterpriseInviteBase()}/${invite_id}/decline`, {})
      .then((r) => {
        callback(r.data);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to decline enterprise invite",
            "system_notifications_failure_decline_enterprise_invite",
          ),
        );
      });
  };
};

export const getEnterpriseActionLog = (
  last_seen_time: string,
  filters: any,
  callback: any,
) => {
  return () => {
    let url = `${enterpriseBase()}/users_logs?last_seen_time=${last_seen_time}`;
    if (filters && filters.sub_user_id) {
      url += `&sub_user_id=${filters.sub_user_id}`;
    }
    if (filters && filters.action_name) {
      url += `&action_name=${filters.action_name}`;
    }
    axios
      .get(url, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
      })
      .then((r) => {
        callback(r.data);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to get logs",
            "system_notifications_failure_enterprise_log",
          ),
        );
      });
  };
};
