// TODO - lint
/* eslint-disable default-param-last, default-case, no-param-reassign, eqeqeq, no-param-reassign, no-param-reassign, vars-on-top, no-var, vars-on-top, no-var, vars-on-top, no-var, no-param-reassign, import/no-default-export */
const searchReducer = (
  state = {
    influencers: [],
    nextPageId: null,
    time: null,
    guid: null,
    lastParams: null,
  },
  action,
) => {
  switch (action.type) {
    case "SEARCH_INFLUENCERS_FULFILLED":
      if (state.guid && state.guid === action.payload.guid) {
        state = {
          ...action.payload,
        };
        if (action.payload.callback) {
          action.payload.callback();
        }
      }
      break;
    case "GET_MORE_SEARCH_RESULTS":
      if (state.guid && state.guid === action.payload.guid) {
        const newInfluencers = state.influencers.concat(
          action.payload.influencers,
        );
        newInfluencers.filter(function (item, pos) {
          return newInfluencers.indexOf(item) == pos;
        });
        state = {
          ...action.payload,
          influencers: newInfluencers,
        };
        if (action.payload.callback) {
          action.payload.callback();
        }
      }
      break;
    case "SET_SEARCH_GUID":
      state = {
        ...state,
        guid: action.payload,
      };
      break;
    case "USER_SEARCH_CHANGED_ID":
      var oldId = action.payload.old;
      var newId = action.payload.new;
      var influencer = state.influencers.find((x) => x.id === oldId);
      if (influencer) {
        influencer.id = newId;
      }
      break;
    case "SET_SEARCH_PARAMETERS":
      state = {
        ...state,
        lastParams: action.payload,
      };
      break;
  }
  return state;
};
export default searchReducer;
