import { ChakraTheme, extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { AccordionTheme as Accordion } from "./Accordion";
import { ButtonTheme as Button } from "./Button";
import { CheckboxTheme as Checkbox } from "./Checkbox";
import { colors } from "./colors";
import { ContainerTheme as Container } from "./Container";
import { DividerTheme as Divider } from "./Divider";
import { FileUploadTheme as FileUpload } from "./FileUploadTheme";
import { FormTheme as Form } from "./Form";
import { HeadingTheme as Heading } from "./Heading";
import { LinkTheme as Link } from "./Link";
import { MenuTheme as Menu } from "./Menu";
import { ModalTheme as Modal } from "./Modal";
import { PopoverTheme as Popover } from "./Popover";
import { TabsTheme as Tabs } from "./Tabs";
import { TextTheme as Text } from "./Text";
import { TooltipTheme as Tooltip } from "./Tooltip";

export const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
  styles: {
    global: (props) => ({
      // ".chakra-accordion__item, :not(.chakra-dont-set-collapse) > .chakra-collapse":
      //   {
      //     overflow: "initial !important",
      //   },
      ".chakra-input": {
        border: 0,
        "&:disabled": {
          opacity: "0.7 !important",
        },
      },
      ".chakra-button__group": {
        maxW: "100%",
      },
      body: {
        backgroundColor: mode("#e9ecef", "black")(props),
        color: null,
        fontFamily: null,
        lineHeight: null,
      },
    }),
  },
  fonts: {},
  colors,
  components: {
    Button,
    Modal,
    Tabs,
    Popover,
    Container,
    Form,
    Divider,
    Text,
    Heading,
    Menu,
    Tooltip,
    Checkbox,
    Link,
    Accordion,
    FileUpload,
  },
} as Partial<ChakraTheme>);
