// TODO - lint
/* eslint-disable no-cond-assign, react/no-direct-mutation-state, import/no-default-export */
import Dialog from "rc-dialog";
import React from "react";
import Rating from "react-rating";
import { connect } from "react-redux";
import { reviewInfluencerInCampaign } from "../../../../actions/auctionsActions";

class RateInfluencerDialog extends React.PureComponent {
  state = {
    influencer: null,
    showMore: false,
    overall: 0,
    professional: 0,
    results: 0,
    content: 0,
    campaignId: -1,
    description: "",
    editMode: false,
  };

  show = (influencer, campaignId) => {
    let newState = {};
    let review = null;
    if ((review = influencer.review)) {
      newState = {
        overall: review.stars,
        professional: review.professional_stars ? review.professional_stars : 0,
        results: review.results_stars ? review.results_stars : 0,
        content: review.content_stars ? review.content_stars : 0,
        description: review.description,
        editMode: true,
      };
      newState.showMore =
        newState.professional || newState.results || newState.content;
    } else {
      newState = {
        overall: 0,
        professional: 0,
        results: 0,
        content: 0,
        description: "",
        editMode: false,
        showMore: false,
      };
    }
    newState.influencer = influencer;
    newState.campaignId = campaignId;
    this.setState(newState);
  };

  hide = () => {
    this.setState({ influencer: null, campaignId: -1 });
  };

  save = () => {
    if (!this.state.influencer) return;
    const { campaignId, overall, professional, results, content, description } =
      this.state;

    if (!overall) return;

    const data = {
      stars: overall,
      content_stars: content || null,
      professional_stars: professional || null,
      results_stars: results || null,
      description: description || null,
    };

    this.props.reviewInfluencerInCampaign(
      campaignId,
      this.state.influencer.influencer_id,
      data,
    );
    this.state.influencer.review = {
      stars: this.state.overall,
      professional_stars: this.state.professional,
      results_stars: this.state.results,
      content_stars: this.state.content,
      description: this.state.description,
    };
    this.hide();
  };

  render() {
    if (!this.state.influencer) return null;
    const { influencer } = this.state;
    return (
      <Dialog
        className={"rating-dialog "}
        title={""}
        onClose={this.hide}
        visible={true}
        bodyStyle={{ height: "100%", overflowY: "hidden", overflowX: "hidden" }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="rating-title">Rate the Influencer</div>

          <div
            style={{
              textAlign: "center",
              marginTop: 10,
              borderBottom: "1px solid #eee",
            }}
          >
            <img
              rol={"presentation"}
              style={{ width: 50, height: 50, borderRadius: "50%" }}
              src={influencer.picture_url}
            />
            <br />
            <strong>{influencer.name}</strong>
          </div>

          <div className="stars-container">
            <div style={{ marginBottom: 10 }}>
              <strong>OVERALL *</strong>
            </div>
            <Rating
              emptySymbol="fa fa-star-o"
              fullSymbol="fa fa-star"
              initialRating={this.state.overall}
              onChange={(overall) => this.setState({ overall })}
            />
          </div>

          {this.state.showMore ? (
            <>
              <div className="stars-container">
                <div style={{ marginBottom: 10 }}>
                  <strong>CONTENT QUALITY</strong>
                </div>
                <Rating
                  emptySymbol="fa fa-star-o"
                  fullSymbol="fa fa-star"
                  initialRating={this.state.content}
                  onChange={(content) => this.setState({ content })}
                />
              </div>
              <div className="stars-container">
                <div style={{ marginBottom: 10 }}>
                  <strong>RESULTS</strong>
                </div>
                <Rating
                  emptySymbol="fa fa-star-o"
                  fullSymbol="fa fa-star"
                  initialRating={this.state.results}
                  onChange={(results) => this.setState({ results })}
                />
              </div>
              <div className="stars-container">
                <div style={{ marginBottom: 10 }}>
                  <strong>PROFESSIONALISM</strong>
                </div>
                <Rating
                  emptySymbol="fa fa-star-o"
                  fullSymbol="fa fa-star"
                  initialRating={this.state.professional}
                  onChange={(professional) => this.setState({ professional })}
                />
              </div>
            </>
          ) : (
            <div style={{ padding: "10px 20px" }}>
              <div
                onClick={() =>
                  this.setState({ showMore: !this.state.showMore })
                }
                style={{ cursor: "pointer" }}
              >
                <strong style={{ fontSize: 16, color: "#249ff0" }}>
                  {this.state.showMore ? "Less -" : "More +"}
                </strong>
              </div>
            </div>
          )}
          <div className={"rating-remarks"}>
            <div style={{ marginBottom: 10 }}>
              <strong>REMARKS</strong>
            </div>
            <textarea
              value={this.state.description}
              placeholder="Tell us more..."
              rows={3}
              onChange={(e) => this.setState({ description: e.target.value })}
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <button
              className={`btn btn-primary${
                this.state.overall > 0 ? "" : " disabled"
              }`}
              style={{
                height: 30,
                paddingTop: 7,
                background: "var(--main-baby-blue-color)",
                border: "none",
              }}
              onClick={this.save}
            >
              {this.state.editMode ? "UPDATE" : "SEND"}
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reviewInfluencerInCampaign: (
      campaignId,
      influencerId,
      reviewData,
      callback,
    ) => {
      dispatch(
        reviewInfluencerInCampaign(
          campaignId,
          influencerId,
          reviewData,
          callback,
        ),
      );
    },
  };
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  RateInfluencerDialog,
);
