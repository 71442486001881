// TODO - lint
/* eslint-disable camelcase, consistent-return, default-case */
export const get_post_type_icon = (type) => {
  switch (type) {
    case "ig_post":
      return "fa fa-instagram";
    case "ig_story":
      return "fa moi-stories";
    case "tw_tweet":
      return "fa fa-twitter";
    case "yt_video":
      return "fa fa-youtube-play";
    case "tk_video":
      return "fa moi-tik-tok";
    case "influencer_content":
      return "far fa-images";
  }
};

export const get_post_type_label = (type) => {
  switch (type) {
    case "ig_post":
      return {
        value: "ig_post",
        label: "Instagram Post",
        icon: "fa fa-instagram",
      };
    case "ig_story":
      return {
        value: "ig_story",
        label: "Instagram Story",
        icon: "fa moi-stories",
      };
    case "tw_tweet":
      return {
        value: "tw_tweet",
        label: "Twitter Post",
        icon: "fa fa-twitter",
      };
    case "yt_video":
      return {
        value: "yt_video",
        label: "YouTube Video",
        icon: "fa fa-youtube-play",
      };
    case "tk_video":
      return { value: "tk_video", label: "Tiktok Video", icon: "moi-tik-tok" };
    case "influencer_content":
      return {
        value: "influencer_content",
        label: "Content only",
        icon: "fas fa-photo-video",
      };
  }
};

export const TaskTypes = [
  { value: "ig_post", label: "Instagram Post", icon: "fa fa-instagram" },
  { value: "ig_story", label: "Instagram Story", icon: "fa moi-stories" },
  { value: "tw_tweet", label: "Twitter Post", icon: "fa fa-twitter" },
  { value: "yt_video", label: "YouTube Video", icon: "fa fa-youtube-play" },
  { value: "tk_video", label: "Tiktok Video", icon: "moi-tik-tok" },
  {
    value: "influencer_content",
    label: "Content only",
    icon: "fas fa-photo-video",
  },
];
export const getLabelFromValue = (value) => {
  for (let i = 0; i < TaskTypes.length; i++) {
    if (TaskTypes[i].value === value) {
      return TaskTypes[i].label;
    }
  }
  return "Label not found";
};
