import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const CheckboxTheme: ComponentMultiStyleConfig = {
  parts: ["label", "container", "control"],
  variants: {
    "inside-button": {
      control: {
        position: "absolute",
        visibility: "hidden",
      },
      container: {
        m: 0,
      },
      label: {
        m: 0,
        fontSize: "12px",
      },
    },
  },
};
