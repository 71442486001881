// TODO - lint
/* eslint-disable react/no-deprecated, no-unused-vars, camelcase, camelcase, no-unused-vars, no-unused-vars, camelcase, camelcase, class-methods-use-this, consistent-return, consistent-return, camelcase, consistent-return, camelcase, camelcase, no-unused-vars, no-unused-vars, no-unused-vars, no-unused-vars, camelcase, camelcase, import/no-default-export */
import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Rnd } from "react-rnd";
import Infinite from "react-infinite";
import TabContent from "rc-tabs/lib/TabContent";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import Tabs, { TabPane } from "rc-tabs";
import ChatSection from "./ChatSection";
import {
  getAvailableChats,
  getAvailableInfluencersChats,
  getChatMessages,
  openNewChat,
} from "../../../actions/inboxActions";
import NOIMAGE from "../../../images/svgs/placeholder.svg";

let campaignSearchTimeout = null;
let influencerSearchTimeout = null;
class ChatsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectedChatCampaignId: null,
      selectedChatUserId: null,
      selectedChat: {},
      chatLoading: false,
      selectedChatTitle: null,
      innerChat: {},
      propsData: {},
      textAreaHeight: 32,
      height: window.innerHeight >= 800 ? 800 : window.innerHeight - 30,
      width: 900,
      selectedColumn: "",
      isPagingLoading: false,
      searchInfluencer: "",
      is_starred: false,
    };
    this.system_message_to_label = {
      1: "system_message_bid_on_auction_",
      2: "system_message_selected_for_campaign_",
      3: "system_message_selected_for_express_campaign_",
      4: "system_message_proposal_created_",
      5: "system_message_proposal_approved_",
      6: "system_message_proposal_declined_",
      7: "system_message_post_linked_",
    };

    this.columns = [
      { label: "BIDS", name: "bids" },
      { label: "INVITES", name: "wait_influencer" },
      { label: "APPROVAL", name: "wait_approval" },
      { label: "PUBLISH", name: "done" },
      { label: "trash", name: "trash" },
    ];
  }

  componentWillMount() {
    this.props.getAvailableChats(true, null, null, null, (res) => {});
    this.openChatFromProps();
  }

  componentDidUpdate() {
    if (
      this.props.data.campaign_id !== this.state.propsData.campaign_id ||
      this.props.data.user_id !== this.state.propsData.user_id
    ) {
      this.openChatFromProps();
    }
  }

  moveToInboxScreen = () => {
    this.props.handleClose(
      true,
      this.state.selectedChatCampaignId,
      this.state.selectedChatUserId,
    );
  };

  moveToCampaign = () => {
    this.props.navigate(
      `/campaign/${this.state.selectedChatCampaignId}/manage`,
    );
  };

  selectCampaign(campaign_id) {
    this.setState({ selectedChatCampaignId: campaign_id });
  }

  openChatFromProps() {
    const { data } = this.props;
    this.setState({ propsData: data });
    if (data.user_id) {
      const chat = { user_id: data.user_id };
      if (data.new_chat) {
        this.setState({ chatLoading: true, selectedChatUserId: chat.user_id });
        openNewChat(chat.user_id, (success) => {
          if (success) {
            this.openChat(chat);
          } else {
            this.setState({ chatLoading: false, selectedChatUserId: null });
          }
        });
      } else if (data.campaign_id) {
        this.setState({ selectedChatCampaignId: data.campaign_id }, () =>
          this.openChat(chat),
        );
      } else {
        this.openChat(chat);
      }
    }
  }

  openChatSegmentations = (id) => {
    if (id) this.props.openInfluencerSegmentations(id);
  };

  openChat(chat) {
    const campaignId =
      this.state.selectedChatCampaignId === -1
        ? null
        : this.state.selectedChatCampaignId;
    this.setState({
      selectedChatUserId: chat.user_id,
      chatLoading: true,
      innerChat: {
        name: chat.name,
        picture_url: chat.picture_url,
        column: chat.campaign_column,
        starred: chat.is_starred,
      },
      openSegmentatios: () => this.openChatSegmentations(chat.influencer_id),
    });
    this.props.getChatMessages(
      chat.user_id,
      campaignId,
      null,
      null,
      10,
      null,
      "desc",
      () => this.openChatCallback(),
    );
  }

  openChatCallback = () => {
    this.setState({ chatLoading: false });
    window.setTimeout(() => {
      const objDiv = document.getElementById("chat_container");
      if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
    }, 1);
  };

  showChat(chat) {
    // hide influencers
    if (chat.campaign_id === this.state.selectedChatCampaignId) {
      this.setState(
        {
          selectedChatTitle: "",
          selectedChatUserId: null,
          selectedChatCampaignId: null,
          isStarred: false,
        },
        () => {
          this.getCampaignInfluencersChats(
            this.state.selectedChatCampaignId,
            true,
            (res) => {
              this.setState({ influencersEndReached: false });
            },
          );
        },
      );
      // show influencers
    } else {
      this.setState(
        {
          selectedChatTitle: chat.title,
          selectedChatUserId: null,
          selectedChatCampaignId: chat.campaign_id,
        },
        () => {
          this.getCampaignInfluencersChats(
            this.state.selectedChatCampaignId,
            true,
            (res) => {
              this.setState({ influencersEndReached: false });
            },
          );
        },
      );
    }
  }

  renderChatRow = (chat, index) => {
    const unseen = this.props.inbox.new_chats[chat.campaign_id];
    const isOpen = chat.campaign_id === this.state.selectedChatCampaignId;
    return (
      <div
        className={`chat-row ${isOpen}`}
        key={`chat${chat.campaign_id}_${index}`}
        onClick={() => this.showChat(chat)}
      >
        <div>
          <img className="avatar" src={chat.image ? chat.image : NOIMAGE} />
          <span className="campaign-title" title={chat.title}>
            {chat.title}
          </span>
        </div>
        {unseen && (
          <div className="notification-chat-badge">{unseen.length}</div>
        )}
      </div>
    );
  };

  renderUserChatRow = (chat, i) => {
    let current = false;
    if (this.state.selectedChatUserId) {
      current = this.state.selectedChatUserId === chat.user_id;
    }
    const auction_id = chat.auction_id ? chat.auction_id : 0;
    const unseen = this.props.inbox.new_chats[auction_id]?.includes(
      chat.user_id,
    );
    return (
      <div
        className={`chat-row ${current}`}
        key={`chat${chat.user_id}_${i}`}
        onClick={() => this.openChat(chat)}
      >
        <div>
          <img
            className="avatar"
            src={
              chat.picture_url && chat.picture_url.replace(".jpg", "_small.jpg")
            }
          />
          <span title={chat.name}>{chat.name}</span>
        </div>
        <div>
          {unseen ? (
            <div className="notification-chat-badge">{unseen}</div>
          ) : null}
          {chat.is_starred ? <i className={"fas fa-star"} /> : null}
        </div>
      </div>
    );
  };

  searchInfluencer = (e) => {
    const searchInfluencer = e.target.value.toLowerCase();
    if (influencerSearchTimeout) clearTimeout(influencerSearchTimeout);
    influencerSearchTimeout = setTimeout(() => {
      if (searchInfluencer !== this.state.searchInfluencer) {
        this.setState({ isInfluencerPagingLoading: true });
        this.props.getAvailableInfluencersChats(
          true,
          false,
          null,
          searchInfluencer,
          this.state.selectedChatCampaignId,
          null,
          (res) => {
            this.setState(
              {
                isInfluencerPagingLoading: false,
                InfluencersEndReached: false,
                searchInfluencer,
              },
              () => {
                if (res.length > 10) {
                  this.handleInfluencerInfiniteLoad();
                }
              },
            );
          },
        );
      }
    }, 250);
  };

  render() {
    if (this.props.floating) return this.renderFloatingContainer();
    return this.renderPageContainer();
  }

  elementInfiniteLoad = (loader) => {
    if (!loader) return;
    return (
      <div className="infinite-list-item">
        <div
          className="spinner small"
          style={{ marginTop: 10, marginBottom: 0 }}
        >
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      </div>
    );
  };

  handleInfiniteLoad = () => {
    if (this.state.campaignsEndReached) return;
    this.setState({ isPagingLoading: true }, () => {
      const { chats } = this.props.inbox;
      if (!chats || !chats.length) {
        this.setState({ isPagingLoading: false });
        return false;
      }
      const last_created_at = chats[chats.length - 1].created_at;
      this.props.getAvailableChats(
        false,
        null,
        last_created_at,
        this.state.search,
        (res) => {
          this.setState({
            chatsLoaded: true,
            campaignsEndReached: !res || res.length === 0,
            isPagingLoading: false,
            influencersEndReached: false,
          });
        },
      );
    });
  };

  handleInfluencerInfiniteLoad = () => {
    if (this.state.influencersEndReached) return;
    this.setState({ isInfluencerPagingLoading: true });
    const chats = this.props.inbox.users_chats.influencers;
    if (!chats || !chats.length || this.state.is_starred) {
      this.setState({ isInfluencerPagingLoading: false });
      return false;
    }
    const { last_message_time } = chats[chats.length - 1];
    this.props.getAvailableInfluencersChats(
      false,
      false,
      last_message_time,
      this.state.searchInfluencer,
      this.state.selectedChatCampaignId,
      this.state.is_starred,
      (res) => {
        this.setState({
          influencerChatsLoaded: false,
          influencersEndReached:
            !res || !res.influencers || res.influencers.length === 0,
          isInfluencerPagingLoading: false,
        });
      },
    );
  };

  getCampaignInfluencersChats = (campaign_id, cleanStars, callback) => {
    this.setState({ isInfluencerPagingLoading: true }, () => {
      this.props.getAvailableInfluencersChats(
        true,
        cleanStars,
        null,
        null,
        campaign_id,
        this.state.is_starred,
        (res) => {
          callback(res);
          this.setState({
            isInfluencerPagingLoading: false,
            InfluencersEndReached: false,
          });
        },
      );
    });
  };

  searchCampaign = (e) => {
    const search = e.target.value.toLowerCase();
    if (campaignSearchTimeout) clearTimeout(campaignSearchTimeout);
    campaignSearchTimeout = setTimeout(() => {
      if (search !== this.state.search) {
        this.setState({ isPagingLoading: true });
        this.props.getAvailableChats(true, null, null, search, (res) => {
          this.setState(
            {
              isPagingLoading: false,
              campaignsEndReached: false,
              search,
            },
            () => {
              if (res.length > 10) {
                this.handleInfiniteLoad();
              }
            },
          );
        });
      }
    }, 250);
  };

  renderInfluencersChatContainer(key, usersChats, starredChats) {
    return (
      <Infinite
        elementHeight={46}
        containerHeight={(this.state.height - 140) * 0.6 - 50}
        className="influencers-search-infinite-categories"
        timeScrollStateLastsForAfterUserScrolls={0}
        useWindowAsScrollContainer={false}
        infiniteLoadBeginEdgeOffset={0}
        preloadBatchSize={0.5}
        onInfiniteLoad={this.handleInfluencerInfiniteLoad}
        isInfiniteLoading={this.state.isInfluencerPagingLoading}
      >
        {key === "all"
          ? usersChats.map(this.renderUserChatRow)
          : starredChats.map(this.renderUserChatRow)}
      </Infinite>
    );
  }

  onResize = (e, direction, ref, delta, position) => {
    if (direction === "top") {
      this.setState({
        height: ref.style.height.replace("px", ""),
      });
    } else {
      this.setState({
        width: ref.style.width.replace("px", ""),
        height: ref.style.height.replace("px", ""),
      });
    }
  };

  switchTabs = (key) => {
    if (key === "starred") {
      this.setState({ is_starred: true }, () => {
        this.getCampaignInfluencersChats(
          this.state.selectedChatCampaignId,
          false,
          (res) => {
            this.setState({ influencersEndReached: false });
          },
        );
      });
    } else {
      this.setState({ is_starred: false }, () => {
        this.getCampaignInfluencersChats(
          this.state.selectedChatCampaignId,
          false,
          (res) => {
            this.setState({ influencersEndReached: false });
          },
        );
      });
    }
  };

  renderInboxContainer = () => {
    const { search } = this.state;
    let usersChats = null;
    let starredChats = [];
    if (this.state.selectedChatCampaignId) {
      usersChats = this.props.inbox.users_chats.influencers;
      if (usersChats) {
        usersChats = usersChats.filter(
          (user) => user.name && user.name.toLowerCase().includes(search),
        );
        starredChats = usersChats.filter((x) => x.is_starred);
      }
    }
    const height = this.state.height - 140;

    return (
      <div
        className="inbox-container"
        style={{ width: "100%", height: "100%" }}
      >
        <div
          className="inbox-left-side"
          style={{ width: 320, height: this.state.height }}
        >
          <div
            style={{
              paddingTop: 15,
              paddingBottom: 10,
              borderBottom: "1px solid #ebebeb",
            }}
            className="inner-toolbar-search-input"
          >
            <div className="moi-search-bold" />
            <input
              onChange={this.searchCampaign}
              placeholder={"Search by campaign"}
              className="clickable form-control"
            />
          </div>
          <div
            className="chats-container"
            style={{
              height: this.state.selectedChatCampaignId ? height * 0.4 : height,
            }}
          >
            <div
              className={`chat-row ${this.state.selectedChatCampaignId === -1}`}
              key={"chat-all-influencers"}
              onClick={() => this.showChat({ title: "ALL", campaign_id: -1 })}
            >
              {/* <img className="avatar" src={chat.image && chat.image.replace('.jpg', '_small.jpg')} /> */}
              <div>
                <i className="avatar moi-influencer-circles" />
                <span className="campaign-title" title={"All Influencers"}>
                  {"All Influencers"}
                </span>
              </div>
              {this.props.inbox.new_chats && this.props.inbox.new_chats[0] && (
                <div
                  className="notification-chat-badge all-influencers"
                  style={{ paddingTop: 0, marginTop: 0 }}
                >
                  {this.props.inbox.new_chats[0].length}
                </div>
              )}
            </div>

            <Infinite
              elementHeight={46}
              containerHeight={
                this.state.selectedChatCampaignId
                  ? height * 0.4 - 50
                  : height - 50
              }
              className="influencers-search-infinite-categories"
              timeScrollStateLastsForAfterUserScrolls={0}
              useWindowAsScrollContainer={false}
              infiniteLoadBeginEdgeOffset={0}
              preloadBatchSize={0.5}
              onInfiniteLoad={this.handleInfiniteLoad}
              loadingSpinnerDelegate={this.elementInfiniteLoad(
                this.state.isPagingLoading,
              )}
              isInfiniteLoading={this.state.isPagingLoading}
            >
              {this.props.chatsLoaded &&
                this.props.inbox.chats.map(this.renderChatRow)}
            </Infinite>
          </div>
          <div className="chats-seperator" />
          {usersChats && (
            <div className={"influencersChats"}>
              <div
                style={{
                  marginTop: 15,
                  paddingBottom: 10,
                  borderBottom: "1px solid #ebebeb",
                }}
                className="inner-toolbar-search-input"
              >
                <div className="moi-search-bold" />
                <input
                  placeholder={"Search by influencer"}
                  onChange={this.searchInfluencer}
                  className="clickable form-control"
                />
              </div>
              <Tabs
                renderTabBar={() => <InkTabBar />}
                renderTabContent={() => <TabContent />}
                onChange={(key) => this.switchTabs(key)}
                style={{ height: height * 0.6 }}
              >
                <TabPane tab={`ALL`} key="all">
                  {this.renderInfluencersChatContainer(
                    "all",
                    usersChats,
                    starredChats,
                  )}
                  <div className="loader-absolute">
                    {this.elementInfiniteLoad(
                      this.state.isInfluencerPagingLoading,
                    )}
                  </div>
                </TabPane>
                <TabPane
                  tab={`STARRED (${
                    this.props.inbox.users_chats.starred_count || 0
                  })`}
                  key="starred"
                >
                  {this.renderInfluencersChatContainer(
                    "starred",
                    usersChats,
                    starredChats,
                  )}
                  <div className="loader-absolute">
                    {this.elementInfiniteLoad(
                      this.state.isInfluencerPagingLoading,
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>

        {this.state.selectedChatUserId &&
          (this.state.chatLoading ? (
            <div
              className="spinner"
              style={{
                height: 350,
                marginTop: 0,
                paddingTop: "calc(50vh - 150px)",
                marginLeft: "calc(50% + 100px)",
              }}
            >
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          ) : (
            <ChatSection
              {...this.state}
              containerWidth={"calc(100% - 320px)"}
              chatContainerHeight={height + 40}
            />
          ))}
      </div>
    );
  };

  renderPageContainer() {
    return (
      <div style={{ paddingTop: 60, minWidth: "var(----main-inner-size)" }}>
        <div
          style={{
            padding: 0,
            height: this.state.height,
            overflowY: "hidden",
            overflowX: "hidden",
          }}
        >
          {this.renderInboxContainer()}
        </div>
      </div>
    );
  }

  renderFloatingContainer() {
    const { width, selectedChatCampaignId } = this.state;
    return (
      <Rnd
        default={{
          x: window.innerWidth / 2 - width / 2,
          y: window.scrollY + 40,
        }}
        minHeight={this.state.height}
        minWidth={width}
        className="chat-draggable"
        cancel={".clickable"}
        onResize={this.onResize}
        bounds={"parent"}
        enableResizing={false}
      >
        <div
          className="far fa-times"
          onClick={() => this.props.handleClose()}
          style={{ marginLeft: width - 30 }}
        />
        <div
          className="far fa-expand-arrows clickable"
          onClick={this.moveToInboxScreen}
          style={{ marginLeft: width - 60 }}
        />
        {selectedChatCampaignId && selectedChatCampaignId > -1 && (
          <div
            className="moi-megaphone clickable"
            onClick={this.moveToCampaign}
            style={{ marginLeft: width - 90 }}
          />
        )}

        <div
          style={{
            padding: 0,
            height: "100%",
            overflowY: "hidden",
            overflowX: "hidden",
            width,
          }}
        >
          {this.renderInboxContainer()}
        </div>
      </Rnd>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inbox: state.inboxReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChatMessages: (
      id,
      campaignId,
      from,
      to,
      amount,
      offset,
      order,
      callback,
    ) => {
      dispatch(
        getChatMessages(
          id,
          campaignId,
          from,
          to,
          amount,
          offset,
          order,
          callback,
        ),
      );
    },
    getAvailableChats: (
      cleanMessages,
      type,
      max_seen_time,
      nameFilter,
      callBack,
    ) => {
      dispatch(
        getAvailableChats(
          cleanMessages,
          type,
          max_seen_time,
          nameFilter,
          callBack,
        ),
      );
    },
    getAvailableInfluencersChats: (
      cleanMessages,
      cleanStars,
      max_seen_time,
      nameFilter,
      auctionId,
      starred,
      callBack,
    ) => {
      dispatch(
        getAvailableInfluencersChats(
          cleanMessages,
          cleanStars,
          max_seen_time,
          nameFilter,
          auctionId,
          starred,
          callBack,
        ),
      );
    },
  };
};

const ChatsSectionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatsSection);

export default (props) => {
  const navigate = useNavigate();

  return <ChatsSectionConnected {...props} navigate={navigate} />;
};
