import { Box } from "@chakra-ui/react";
import produce from "immer";
import moment from "moment";
import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DatePicker from "react-datepicker";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import CheckBox from "src/components/feed_components/general_components/CheckBox";
import { assertUnreachable } from "src/declarations/UtilityTypes";
import { CheckboxButton } from "src/ui/CheckboxButton";
import { CheckboxButtonGroup } from "src/ui/CheckboxButtonGroup";
import HumanzRangePicker from "src/ui/humanz/HumanzRangePicker";
import { CampaignReportFilterParameters } from "../../../../../models/Api";

enum DataRangeTypes {
  lastWeek = "lastWeek",
  lastMonth = "lastMonth",
  custom = "custom",
}

const getFilterDataObject = (
  influencers?: any,
  hashtags?: any,
  tag_ids?: any,
  mentions?: any,
  time_filter?: any,
  skus?: any,
  coupons?: any,
  phases?: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _?: boolean,
) => {
  return {
    influencers: influencers || [],
    hashtags: hashtags || [],
    tag_ids: tag_ids || [],
    mentions: mentions || [],
    time_filter: time_filter || {
      from: null,
      to: null,
      type: undefined,
      amount: 1,
      offset: 0,
    },
    pruchase_filter: {
      skus: skus || [],
      coupons: coupons || [],
      phases: phases || [],
      only_sales_influencers: false,
    },
  };
};

const campaignFilterMap = [
  {
    type: "date-range",
    label: "Date",
    isOpen: true,
    filteredData: "dateTime",
  },
  {
    type: "influencers",
    label: "Influencers",
    isOpen: true,
  },
  {
    type: "tags",
    label: "Campaign Tags",
    isOpen: true,
  },
  {
    type: "sku",
    label: "SKU",
    isOpen: true,
  },
  {
    type: "coupons",
    label: "Coupons",
    isOpen: true,
  },
  {
    type: "phases",
    label: "Affiliation Rules",
    isOpen: true,
  },
  {
    type: "only_sales_influencers",
    label: "Only influencers with sales",
    isOpen: true,
  },
];

const dataRangeOrder = [
  DataRangeTypes.lastWeek,
  DataRangeTypes.lastMonth,
  DataRangeTypes.custom,
];

interface Props {
  filters?: Array<any>;
  updateState(obj: any): void;
  changeVisible(visible: boolean): void;
  visible: boolean;
  pixelEnabled: boolean;
  loading: boolean;
}

interface State {
  filters: any;
  influencers: Array<any>;
  filteredData: CampaignReportFilterParameters | any;
  showFilters: boolean;
  filterMap?: any;
  updated?: boolean;
  showDatePicker?: boolean;
}

export class ReportFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.initState();
  }

  initState() {
    const newState: State = {
      showFilters: false,
      filters: this.props.filters ? this.props.filters : getFilterDataObject(),
      influencers: [],
      showDatePicker: false,
      filteredData: getFilterDataObject(),
      filterMap: campaignFilterMap,
    };
    return newState;
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.loading && prevProps.loading !== this.props.loading) {
      this.setState({ filters: this.props.filters });
    }
  }

  triggerRoiUpdateState() {
    this.props.updateState({ filters: this.state.filteredData });
  }

  toggleFilterMenu = (close?: any) => {
    if (this.state.showFilters || close) {
      const element = document.getElementById("list-filters");
      element?.classList.add("fadeOutRight");
      window.setTimeout(() => {
        this.setState({ showFilters: false });
      }, 500);
    } else {
      this.setState({ showFilters: true });
      if (this.props.changeVisible) this.props.changeVisible(true);
    }
  };

  handleTagsChange = (_item: any, tags: Array<any>) => {
    const newState = { ...this.state };
    tags?.forEach((t, i) => {
      // remove # and @ from hashtags and mentions
      // eslint-disable-next-line no-param-reassign
      tags[i] = t.replace(/#/g, "").replace(/@/g, "");
    });
    newState.filteredData.tag_ids = tags;
    this.setState(newState, () => this.triggerRoiUpdateState());
  };

  renderFiltersMenu() {
    return (
      <div
        className="influencer-search-filters animated fadeInRight faster"
        id="report-filters"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Filter</div>
          <div className="filter-close-button" onClick={this.toggleFilterMenu}>
            <span className="moi-ximage" />
          </div>
        </div>
        <div
          className="filter-refine-tab-spacer"
          style={{ marginBottom: 20 }}
        />
        <button
          onClick={() => {
            this.setState({ filteredData: getFilterDataObject() }, () =>
              this.triggerRoiUpdateState(),
            );
          }}
          className="btn clear-btn btn-primary"
        >
          <strong>CLEAR FILTERS</strong>
        </button>
        <div
          className="filter-refine-tab-spacer"
          style={{ marginBottom: 20 }}
        />
        <div className="clearfix"></div>
        {this.state.filterMap.map(this.renderFilterItem)}
      </div>
    );
  }

  handleDataRangeCheckboxGroupChange: NonNullable<
    React.ComponentProps<typeof CheckboxButtonGroup>["onChange"]
  > = (newTypes: DataRangeTypes[]) => {
    const newType = newTypes[newTypes.length - 1];

    this.setState(
      produce((draftState) => {
        draftState.filteredData.time_filter.type = newType;
        switch (newType) {
          case DataRangeTypes.lastWeek:
            draftState.filteredData.time_filter.from = moment()
              .day(0)
              .startOf("day");
            draftState.filteredData.time_filter.to = moment().endOf("day");
            break;
          case DataRangeTypes.lastMonth:
            draftState.filteredData.time_filter.from =
              moment().startOf("month");
            draftState.filteredData.time_filter.to = moment().endOf("day");
            break;
          case DataRangeTypes.custom:
            break;
          case undefined:
            draftState.filteredData.time_filter.from = null;
            draftState.filteredData.time_filter.to = null;
            break;
          default:
            assertUnreachable(newType);
            break;
        }
      }),
      () => {
        if (newType !== DataRangeTypes.custom) {
          this.triggerRoiUpdateState();
        }
      },
    );
  };

  renderFilterItem = (item: any, j: number) => {
    if (item.type === "sku" && !this.props.pixelEnabled) {
      return null;
    }
    if (item.type === "coupons" && !this.state.filters.coupons?.length)
      return null;
    return (
      <div key={j}>
        <div className="small-title">
          <strong className="upper-case">{item.label}</strong>
          <div
            onClick={() => {
              // eslint-disable-next-line no-param-reassign
              item.isOpen = !item.isOpen;
              this.setState({ updated: true });
            }}
            className={`row-collapse ${item.isOpen ? "moi-minus" : "moi-plus"}`}
          />
        </div>
        {item.isOpen && (
          <div className="inner-filter-content">
            {item.type === "influencers" && (
              <>
                <div className="selected_list react-tagsinput">
                  {this.state.filteredData.influencers.map(
                    (id: number, i: number) => (
                      <span className="react-tagsinput-tag" key={i}>
                        {
                          this.state.filters.influencers.find(
                            (inf: any) => inf.id === id,
                          )?.name
                        }
                        <a
                          className="react-tagsinput-remove"
                          onClick={() => {
                            this.state.filteredData.influencers?.addOrRemove(
                              id,
                            );
                            this.setState({}, () =>
                              this.triggerRoiUpdateState(),
                            );
                          }}
                        />
                      </span>
                    ),
                  )}
                </div>
                {this.state.filters.influencers ? (
                  <Select
                    className="searchable-input"
                    // eslint-disable-next-line react/no-string-refs
                    ref="ambs_usrnm"
                    multi={true}
                    options={this.state.filters.influencers
                      .map((i: any) => {
                        return { value: i.id, label: i.name };
                      })
                      .filter(
                        (i: any) =>
                          !this.state.filteredData.influencers.includes(
                            i.value,
                          ),
                      )}
                    clearable={true}
                    onChange={(e: any) => {
                      if (
                        this.state.filteredData.influencers.indexOf(
                          e[0].value,
                        ) === -1
                      )
                        this.state.filteredData.influencers.push(e[0].value);
                      this.setState({}, () => this.triggerRoiUpdateState());
                    }}
                    searchable={true}
                  />
                ) : null}
              </>
            )}
            {item.type === "sku" ? (
              <div
                className="custom-height"
                style={{ borderBottom: "1px solid #bbbbbb" }}
              >
                <TagsInput
                  value={this.state.filteredData.pruchase_filter.skus || []}
                  onChange={(tags) => {
                    if (!tags.length || tags[tags.length - 1]) {
                      this.setState(
                        {
                          filteredData: {
                            ...this.state.filteredData,
                            pruchase_filter: {
                              ...this.state.filteredData.pruchase_filter,
                              skus: tags.filter((tag) => tag),
                            },
                          },
                        },
                        () => this.triggerRoiUpdateState(),
                      );
                    }
                  }}
                  addOnBlur={true}
                  inputProps={{
                    placeholder: "Add sku and press enter",
                    className: "tasks-tags-input main-tags",
                  }}
                />
              </div>
            ) : null}

            {item.type === "coupons" ? (
              <>
                <div className="selected_list react-tagsinput">
                  {this.state.filteredData.pruchase_filter.coupons.map(
                    (name: string, i: number) => (
                      <span className="react-tagsinput-tag" key={i}>
                        {name}
                        <a
                          className="react-tagsinput-remove"
                          onClick={() => {
                            this.state.filteredData.pruchase_filter?.coupons?.addOrRemove(
                              name,
                            );
                            this.setState({}, () =>
                              this.triggerRoiUpdateState(),
                            );
                          }}
                        />
                      </span>
                    ),
                  )}
                </div>
                <Select
                  className="searchable-input"
                  // eslint-disable-next-line react/no-string-refs
                  ref="product_coupons"
                  multi={true}
                  options={this.state.filters.coupons.map((i: any) => {
                    return { value: i.name, label: i.name };
                  })}
                  clearable={true}
                  onChange={(e: any) => {
                    if (
                      this.state.filteredData.pruchase_filter.coupons.indexOf(
                        e[0].value,
                      ) === -1
                    )
                      this.state.filteredData.pruchase_filter.coupons.push(
                        e[0].value,
                      );
                    this.setState({}, () => this.triggerRoiUpdateState());
                  }}
                  name="product_coupons"
                  searchable={true}
                />
              </>
            ) : null}
            {item.type === "tags" && (
              <div>
                <div className="selected_list react-tagsinput">
                  {this.state.filteredData.tag_ids.map(
                    (id: number, i: number) => (
                      <span
                        className="react-tagsinput-tag"
                        key={i}
                        style={{
                          backgroundColor: this.state.filters.tags?.find(
                            (tag: any) => tag.id === id,
                          )?.color,
                        }}
                      >
                        {
                          this.state.filters.tags.find(
                            (tag: any) => tag.id === id,
                          )?.name
                        }
                        <a
                          className="react-tagsinput-remove"
                          onClick={() => {
                            this.state.filteredData.tag_ids?.addOrRemove(id);
                            this.setState({}, () =>
                              this.triggerRoiUpdateState(),
                            );
                          }}
                        />
                      </span>
                    ),
                  )}
                </div>
                <div>
                  <Select
                    className="searchable-input"
                    multi={true}
                    options={this.state.filters?.tags
                      ?.map((i: any) => {
                        return { value: i.id, label: i.name };
                      })
                      ?.filter(
                        (i: any) =>
                          !this.state.filteredData?.tag_ids?.includes(i?.value),
                      )}
                    clearable={true}
                    onChange={(e: any) => {
                      const newState = { ...this.state };
                      if (!newState.filteredData.tag_ids?.length) {
                        newState.filteredData.tag_ids = e?.map(
                          (i: any) => i.value,
                        );
                      } else {
                        newState.filteredData.tag_ids.addOrRemove(e[0].value);
                      }
                      this.setState(newState);
                      this.triggerRoiUpdateState();
                    }}
                    searchable={true}
                  />
                </div>
              </div>
            )}
            {item.type === "date-range" && (
              <div>
                <Box py="3">
                  <CheckboxButtonGroup
                    value={
                      this.state.filteredData.time_filter.type
                        ? [this.state.filteredData.time_filter.type]
                        : []
                    }
                    onChange={this.handleDataRangeCheckboxGroupChange}
                  >
                    {dataRangeOrder.map((x) => (
                      <CheckboxButton
                        style={{ fontSize: "12px" }}
                        key={x}
                        value={x}
                        isChecked={
                          x === this.state.filteredData.time_filter.type
                        }
                      >
                        {
                          {
                            [DataRangeTypes.lastWeek]: "This week",
                            [DataRangeTypes.lastMonth]: "This month",
                            [DataRangeTypes.custom]: "Custom",
                          }[x]
                        }
                      </CheckboxButton>
                    ))}
                  </CheckboxButtonGroup>
                </Box>
                {this.state.filteredData.time_filter.type ===
                DataRangeTypes.custom ? (
                  <div className={"flex"}>
                    <div
                      className="searchable-input"
                      onClick={() => this.setState({ showDatePicker: true })}
                    >
                      <DatePicker
                        readOnly={true}
                        dateFormat={window.time.short_date}
                        className="input-line"
                        isClearable={false}
                        placeholderText="from"
                        maxDate={
                          this.state.filteredData.time_filter.to ??
                          moment().endOf("day")
                        }
                        selected={
                          this.state.filteredData.time_filter.from
                            ? moment(this.state.filteredData.time_filter.from)
                            : null
                        }
                        onChange={(date: any) => {
                          const newState = { ...this.state };
                          date.startOf("day");
                          newState.filteredData.time_filter.from = date;
                          this.setState(newState, () =>
                            this.triggerRoiUpdateState(),
                          );
                        }}
                      />
                      <i className="far fa-calendar" />
                    </div>
                    <span
                      style={{
                        marginTop: 7,
                        fontSize: 12,
                        padding: "2px 10px",
                        color: "#555",
                      }}
                    >
                      to
                    </span>
                    <div
                      className="searchable-input"
                      onClick={() => this.setState({ showDatePicker: true })}
                    >
                      <DatePicker
                        readOnly={true}
                        dateFormat={window.time.short_date}
                        className="input-line"
                        isClearable={false}
                        placeholderText="to"
                        minDate={this.state.filteredData.time_filter.from}
                        maxDate={moment().endOf("day")}
                        selected={
                          this.state.filteredData.time_filter.to
                            ? moment(this.state.filteredData.time_filter.to)
                            : null
                        }
                        onChange={(date: any) => {
                          const newState = { ...this.state };
                          date.endOf("day");
                          newState.filteredData.time_filter.to = date;
                          this.setState(newState, () =>
                            this.triggerRoiUpdateState(),
                          );
                        }}
                      />
                      <i className="far fa-calendar" />
                    </div>
                    <HumanzRangePicker
                      buttonStyle={{
                        placeSelf: "center",
                        marginLeft: 10,
                      }}
                      showButton={false}
                      open={this.state.showDatePicker}
                      onClose={() => {
                        this.setState({ showDatePicker: false });
                      }}
                      startDate={moment(
                        this.state.filteredData.time_filter.from || Date.now(),
                      ).toDate()}
                      endDate={moment(
                        this.state.filteredData.time_filter.to || Date.now(),
                      ).toDate()}
                      onChange={(res: any) => {
                        if (res && res.startDate && res.endDate) {
                          const newState = { ...this.state };
                          newState.filteredData.time_filter.from = moment(
                            res.startDate,
                          ).startOf("day");
                          newState.filteredData.time_filter.to = moment(
                            res.endDate,
                          ).endOf("day");
                          this.setState(newState, this.triggerRoiUpdateState);
                        }
                        this.setState({ showDatePicker: false });
                      }}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
        {this.props.pixelEnabled ? (
          <>
            {this.state.filters?.phases && item.type === "phases" ? (
              <>
                <div className="selected_list react-tagsinput">
                  {this.state.filters?.phases?.map(
                    (phase: any, i: number) =>
                      this.state.filteredData?.pruchase_filter?.phases &&
                      this.state.filteredData.pruchase_filter.phases.includes(
                        phase.id,
                      ) && (
                        <span className="react-tagsinput-tag" key={i}>
                          {phase.title}
                          <a
                            className="react-tagsinput-remove"
                            onClick={() => {
                              this.state.filteredData.pruchase_filter?.phases?.addOrRemove(
                                phase.id,
                              );
                              this.setState({}, () =>
                                this.triggerRoiUpdateState(),
                              );
                            }}
                          />
                        </span>
                      ),
                  )}
                </div>
                <Select
                  className="searchable-input"
                  multi={true}
                  options={this.state.filters?.phases
                    ?.map((i: any) => {
                      return { value: i.id, label: i.title };
                    })
                    .filter(
                      (i: any) =>
                        !this.state.filteredData?.pruchase_filter?.phases?.includes(
                          i.value,
                        ),
                    )}
                  clearable={true}
                  onChange={(e: any) => {
                    if (
                      this.state.filteredData?.pruchase_filter?.phases?.indexOf(
                        e[0].value,
                      ) === -1
                    )
                      this.state.filteredData?.pruchase_filter?.phases?.push(
                        e[0].value,
                      );
                    this.setState({}, () => this.triggerRoiUpdateState());
                  }}
                  name="phases"
                  searchable={true}
                />
              </>
            ) : null}
            {item.type === "only_sales_influencers" && (
              <CheckBox
                style={{ marginTop: 5 }}
                checked={
                  this.state.filteredData.pruchase_filter.only_sales_influencers
                }
                onChange={() => {
                  this.setState(
                    {
                      filteredData: {
                        ...this.state.filteredData,
                        pruchase_filter: {
                          ...this.state.filteredData.pruchase_filter,
                          only_sales_influencers:
                            !this.state.filteredData.pruchase_filter
                              .only_sales_influencers,
                        },
                      },
                    },
                    () => this.triggerRoiUpdateState(),
                  );
                }}
                label={item.label}
              />
            )}
          </>
        ) : null}
        <div
          className="filter-refine-tab-spacer"
          style={{ marginBottom: 20 }}
        />
      </div>
    );
  };

  render() {
    return this.state.showFilters ? (
      <div className="ambassador-filters">{this.renderFiltersMenu()}</div>
    ) : null;
  }
}
