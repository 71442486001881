/* eslint-disable no-nested-ternary */
import { Box, Tag } from "@chakra-ui/react";
import moment from "moment";
import { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonSingleAction,
  HumanzActionsMenu,
} from "src/ui/humanz/HumanzActionsMenu";

type Props = {
  allMonths?: boolean;
  onChange?: any;
  months: Array<string>;
  style?: CSSProperties;
  boardId?: string | number;
};

const CampaignMonthSelector = (props: Props) => {
  const current = moment().format("YYYY-MM-DD");
  const next = moment().add(1, "month").format("YYYY-MM-DD");
  const board: any = useSelector((store) => store.boardReducer);
  const dispatch = useDispatch();
  const [currentManageMonth, setCurrentManageMonth] = useState<string | null>(
    board.month || board?.board?.current_manage_month,
  );
  const [monthList, setMonthList] = useState<Array<ButtonSingleAction>>([]);
  // if (!board) return null;
  const isCurrentMonth =
    moment(board?.current_manage_month).month() ===
      moment(currentManageMonth).month() &&
    moment(board?.current_manage_month).year() ===
      moment(currentManageMonth).year();

  const generateMonth = (month: string) => {
    return {
      action: () => {
        setCurrentManageMonth(moment(month).format("YYYY-MM-DD"));
        if (props.onChange) props.onChange(month);
      },
      text: `${moment(month).format("MMMM YYYY")}`,
    };
  };

  const allMonthsList = [
    {
      action: () => {
        setCurrentManageMonth(current);
        if (props.onChange) props.onChange(current);
      },
      text: `Current month`,
    },
    {
      action: () => {
        setCurrentManageMonth(next);
        props.onChange(next);
      },
      text: `Next month`,
    },
  ];

  const init = () => {
    let tempMonthList: Array<ButtonSingleAction> = [];
    if (props.allMonths) {
      setCurrentManageMonth(board?.month || board?.board?.current_manage_month);
      props?.months?.forEach((m: string) => {
        tempMonthList.push(generateMonth(m));
      });
    } else {
      tempMonthList = allMonthsList;
    }

    setMonthList(tempMonthList);
  };

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    init();
  }, [props.months, props.boardId]);

  useEffect(() => {
    if (currentManageMonth) {
      dispatch({ type: "UPDATE_BOARD_MONTH", payload: currentManageMonth });
    }
  }, [currentManageMonth]);

  return (
    <HumanzActionsMenu
      buttontext={
        props.allMonths ? (
          <Box display="inline-flex">
            {moment(currentManageMonth).format("MMMM YYYY")}
            {isCurrentMonth && (
              <Tag colorScheme={"teal"} variant={"subtle"} ml={2}>
                Current Month
              </Tag>
            )}
          </Box>
        ) : isCurrentMonth ? (
          "Current month"
        ) : (
          "Next Month"
        )
      }
      style={{
        border: "5px solid #E9ECEF",
        background: "white",
        borderRadius: 30,
        color: "#808080",
        fontSize: 14,
        fontWeight: 500,
        padding: "5px 11px",
        height: "auto",
        ...props?.style,
      }}
      buttontype={"button"}
      leftIcon={<i className="fad fa-calendar-days" />}
      actions={monthList}
    />
  );
};

export default CampaignMonthSelector;
