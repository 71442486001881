/* eslint-disable no-nested-ternary */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Tag,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  finishPixelwizard,
  getWizardData,
  testPixelEvent,
  updatePlatform,
} from "src/actions/pixelWizardActions";
import uuid from "uuid";
import logoBlue from "../../../images/svgs/logo-blue.svg";
import gtmLogo from "./assets/images/gtm-logo.png";
import magentoLogo from "./assets/images/magento-logo.svg";
import shopifyLogo from "./assets/images/shopify-logo.png";
import wixLogo from "./assets/images/wix-logo.png";
import woocommerceLogo from "./assets/images/woocommerce-logo.png";
import "./assets/wizard.css";
import { GTM } from "./flows/GTM";
import { Lite } from "./flows/Lite";
import { Manual } from "./flows/Manual";
import { Plugin } from "./flows/Plugin";
import { ShopifyWizard } from "./flows/ShopifyWizard";
import { InitScreen } from "./screens/InitScreen";
import { PurchaseEventScreen } from "./screens/PurchaseEventScreen";

const platforms = [
  {
    id: 0,
    name: "Google Tag Manager",
    logo: gtmLogo,
    flow: "GTM",
    label:
      "Best way to use Humanz pixel through Google Tag Manager Simple installation guide",
    tag: "Recommended",
  },
  {
    id: 1,
    name: "Wix",
    logo: wixLogo,
    flow: "lite",
    label: "Install Humanz Pixel from the Wix App Market",
  },
  {
    id: 2,
    name: "Shopify",
    logo: shopifyLogo,
    flow: "shopify",
    label: "Install Humanz Pixel from the  Shopify App Store or manually",
  },
  {
    id: 3,
    name: "Magento",
    logo: magentoLogo,
    flow: "plugin",
    label: "A Plugin for Magento that does all the work for you",
  },
  {
    id: 4,
    name: "Woocommerce",
    logo: woocommerceLogo,
    flow: "plugin",
    label: "A Plugin for Wordpress that does all the work for you",
  },
  {
    id: 5,
    name: "Custom",
    flow: "manual",
    label: "Advanced but simple flow for a manual implementation",
  },
  // {
  //   id: 6,
  //   name: "Konimbo",
  //   logo: konimboLogo,
  //   flow: "manual",
  // },
];

const randUtmStr: string = uuid();

export const Wizard = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [purchaseEvent, setPurchaseEvent] = useState(null);
  const [campaignEvent, setCampaignEvent] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordRequired, setPasswordRequired] = useState(null);
  const [finished, setFinished] = useState(false);

  const [wizardData, setWizardData] = useState(null);
  const { guid, step } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getWizardData(guid, password, (data: any) => {
        setIsLoading(false);
        // setWizardData({});
        // return;
        if (!data.status) {
          let error =
            "There was an error loading this link. Please contact us.";
          if (data?.response?.status === 410) {
            error = "This link is no longer valid.";
          }
          if (data?.response?.status === 403) {
            error = "This wizard is password protected";
            setPasswordRequired(true);
          }
          setWizardData({ error });
          return;
        }
        if (data?.data) setWizardData(data?.data);
      }),
    );
  }, [dispatch, guid]);

  const initAfterPassword = () => {
    setIsLoading(true);
    dispatch(
      getWizardData(guid, password, (data: any) => {
        setIsLoading(false);
        if (!data.status) {
          let error =
            "There was an error loading this link. Please contact us.";
          if (data?.response?.status === 410) {
            error = "This link is no longer valid.";
          }
          if (data?.response?.status === 403) {
            error = "Password is wrong. Please try again";
            setPasswordRequired(true);
          }
          setWizardData({ error });
          return;
        }
        if (data?.data) {
          setWizardData(data?.data);
          setPasswordRequired(false);
        }
      }),
    );
  };

  //   useEffect(() => {
  //     // setIsLoading(true);
  //     navigate(`/pixel_wizard/${guid}/${currentStep}`);
  //   }, [guid, navigate, currentStep]);

  useEffect(() => {
    const s = Number(step);
    if (s >= 0 && s < 4) setCurrentStep(s);
  }, [step]);

  const selectPlatform: any = (platformId: number) => {
    setSelectedPlatform(platformId);
    setCurrentStep(1);
    setPrevDisabled(false);
  };

  const checkEvent = (type: string, extra_data: any, callback: any) => {
    setIsLoading(true);
    const err: any = { is_valid: false, details: null };
    const pixelEventData: any = {
      pixel_event_name: type,
      search_uuid: randUtmStr,
    };
    if (extra_data?.transaction_id) {
      pixelEventData.transaction_id = extra_data.transaction_id;
    }
    if (extra_data?.coupon_used) {
      pixelEventData.coupon_used = extra_data.coupon_used;
    }
    if (extra_data?.search_uuid) {
      pixelEventData.search_uuid = extra_data.search_uuid;
    }
    if ([1, 2].includes(selectedPlatform) && type === "campaign") {
      setTimeout(() => {
        dispatch(
          updatePlatform(
            guid,
            { search_uuid: pixelEventData.search_uuid },
            password,
            (data: any) => {
              setIsLoading(false);
              if (data.status === 201) {
                setCampaignEvent({ is_valid: true });
              } else {
                setCampaignEvent(err);
              }
            },
          ),
        );
      }, 2500);
    } else {
      dispatch(
        testPixelEvent(guid, pixelEventData, password, (data: any) => {
          setIsLoading(false);
          if (data.status === 200 || data?.data) {
            switch (type) {
              case "initial":
                break;
              case "campaign":
                setCampaignEvent(data?.data);
                break;
              case "purchase":
                setPurchaseEvent(data?.data);
                break;
              default:
                break;
            }
            callback(data?.data);
          } else {
            switch (type) {
              case "initial":
                break;
              case "campaign":
                setCampaignEvent(err);
                break;
              case "purchase":
                setPurchaseEvent(err);
                break;
              default:
                break;
            }
          }
        }),
      );
    }
  };

  const finishInstallation = () => {
    setIsLoading(true);
    dispatch(
      finishPixelwizard(guid, password, () => {
        // eslint-disable-next-line no-alert
        setFinished(true);
        setPrevDisabled(true);
        setIsLoading(false);
      }),
    );
  };

  const completePlatformStep = () => {
    setCurrentStep(2);
  };

  const generalError = () => {
    return (
      <>
        <Alert status={"error"}>
          <AlertIcon />
          <span>{wizardData?.error}</span>
        </Alert>
        {passwordRequired ? (
          <FormControl m={3}>
            <FormLabel>Enter Password</FormLabel>
            <Input
              w={200}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              value={password}
              name="password"
            ></Input>
            <Button
              onClick={initAfterPassword}
              isLoading={isLoading}
              isDisabled={isLoading}
              ml={2}
            >
              Submit
            </Button>
          </FormControl>
        ) : null}
      </>
    );
  };

  const steps = [
    {
      id: 0,
      title: "Humanz Pixel Installation",
      completed: true,
      body: wizardData?.error ? (
        <>{generalError()}</>
      ) : (
        <div>
          {isLoading ? (
            <Spinner size={"lg"} margin={"0 auto"} />
          ) : (
            <>
              <aside className="preview-side">
                <Flex justifyContent={"center"}>
                  <div className="preview-description">
                    Welcome to the Humanz Pixel Installation Wizard! <br />
                    This wizard will guide you through the installation of the
                    Humanz Pixel in your website.
                    <br />
                    <br />
                    <h4>
                      {wizardData?.name} - {wizardData?.website_url}
                    </h4>
                  </div>
                </Flex>
              </aside>
              <Flex className="pixel-install-form" flex={2}>
                <FormControl>
                  <div style={{ textAlign: "center" }}>
                    <strong>Select your website platform:</strong>
                  </div>
                  <div className={"platformWrapper"}>
                    <>
                      {platforms.map((platform: any, i: number) => {
                        return (
                          <div
                            className="platformButton"
                            onClick={() => selectPlatform(platform.id)}
                            key={i}
                          >
                            {platform.tag && (
                              <Tag className="platform-tag-top">
                                {platform.tag}
                              </Tag>
                            )}
                            <div className="imagewrapper">
                              {platform.logo ? (
                                <img src={platform.logo} />
                              ) : (
                                <strong>{"<script>"}</strong>
                              )}
                            </div>
                            <strong className="platform-name">
                              {platform.name}
                            </strong>
                            {platform.label && (
                              <Tag className="platform-tag">
                                {platform.label}
                              </Tag>
                            )}
                          </div>
                        );
                      })}
                    </>
                  </div>
                </FormControl>
              </Flex>
            </>
          )}
        </div>
      ),
    },
    {
      id: 1,
      title: "Platform implementation",
      completed: false,
      body: wizardData?.error ? (
        generalError()
      ) : (
        <>
          {[0].includes(selectedPlatform) && ( // GTM
            <GTM
              isLoading={isLoading}
              nextStep={() => completePlatformStep()}
              platform={platforms[selectedPlatform]}
              appId={wizardData?.pixel_app_id}
            />
          )}
          {[1].includes(selectedPlatform) && ( // wix
            <Lite
              isLoading={isLoading}
              nextStep={() => completePlatformStep()}
              platform={platforms[selectedPlatform]}
            />
          )}
          {[2].includes(selectedPlatform) && ( // Shopify
            <ShopifyWizard
              isLoading={isLoading}
              appId={wizardData?.pixel_app_id}
              nextStep={() => completePlatformStep()}
              platform={platforms[selectedPlatform]}
            />
          )}
          {[3, 4].includes(selectedPlatform) && ( // Magento or woocommerce
            <Plugin
              isLoading={isLoading}
              nextStep={() => completePlatformStep()}
              platform={platforms[selectedPlatform]}
              appId={wizardData?.pixel_app_id}
            />
          )}
          {[5].includes(selectedPlatform) && ( // Manual
            <Manual
              isLoading={isLoading}
              nextStep={() => completePlatformStep()}
              platform={platforms[selectedPlatform]}
              appId={wizardData?.pixel_app_id}
            />
          )}
        </>
      ),
    },
    {
      id: 2,
      title: "Test the Pixel initialization",
      completed: false,
      body: wizardData?.error ? (
        generalError()
      ) : (
        <InitScreen
          isLoading={isLoading}
          nextStep={() => {
            setCurrentStep(3);
            setPrevDisabled(false);
          }}
          platform={platforms[selectedPlatform]}
          website={wizardData?.website_url}
          checkEvent={checkEvent}
          campaignEvent={campaignEvent}
          randomUtmStr={randUtmStr}
        />
      ),
    },
    {
      id: 3,
      title: "Test for Pixel events",
      completed: false,
      body: wizardData?.error ? (
        generalError()
      ) : (
        <PurchaseEventScreen
          isLoading={isLoading}
          nextStep={() => {
            setCurrentStep(4);
            setFinished(true);
          }}
          platform={platforms[selectedPlatform]}
          purchaseEvent={purchaseEvent}
          website={wizardData?.website_url}
          checkEvent={checkEvent}
        />
      ),
    },
    {
      id: 4,
      title: "You are done!",
      completed: false,
      body: wizardData?.error ? (
        generalError()
      ) : (
        <aside className="preview-side">
          <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Awesome Job!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              You have succesfully installed the Humanz pixel. <br />
              Finishing the installation will terminate the access to this page.{" "}
              <br />
            </AlertDescription>
          </Alert>

          {finished ? (
            <p style={{ margin: "10px" }}>
              <strong>Done! you can now close this page</strong>
            </p>
          ) : (
            <Button
              onClick={finishInstallation}
              isLoading={isLoading}
              m={5}
              size="lg"
              colorScheme={"green"}
            >
              Finish the installation
            </Button>
          )}
        </aside>
      ),
    },
  ];

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setPrevDisabled(false);
    } else {
      setPrevDisabled(true);
    }
  };

  const renderStep = (_step: any) => {
    return (
      <div
        key={_step.id}
        className={`step ${currentStep === _step.id ? "active" : ""}`}
      >
        <div className="step-id">0{_step.id + 1}</div>
      </div>
    );
  };

  return (
    <div className="pixel-wizard container">
      <div className="flex wizard-header">
        <div className="logo-blue flex1">
          <img src={logoBlue} alt="" />
        </div>
        <div>
          <div className="steps-bar">{steps.map(renderStep)}</div>
          <div className="steps-bar-bg"></div>
        </div>
      </div>
      <div className="wizard-body">
        <div className="step-content">
          <div className="step-title">{steps[currentStep].title}</div>
          <div className="step-body">{steps[currentStep].body}</div>
          {currentStep > 0 && (
            <div className="step-footer flex">
              <div className="flex1">
                <Flex justifyContent={"space-between"} mt={4}>
                  <Button
                    onClick={prevStep}
                    isDisabled={prevDisabled || isLoading}
                    colorScheme={"gray"}
                  >
                    Go Back
                  </Button>
                  {/* {currentStep > 1 && (
                    <Button
                      colorScheme={"green"}
                      disabled={nextDisabled || isLoading}
                      onClick={() => setCurrentStep(3)}
                    >
                      Next
                    </Button>
                  )} */}
                </Flex>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
