import moment from "moment";
import React, { useEffect, useState } from "react";
import { AttachLinksDialogTabPostsControlled } from "./AttachLinksDialogTabPostsControlled";
import { useFbPosts } from "./IgPostHooks";

export const AttachLinksDialogTabPosts = ({
  fbApi,
  data,
  influencer,
  selectPost,
  tasks,
}: Exclude<
  React.ComponentProps<typeof AttachLinksDialogTabPostsControlled>,
  "linked"
> & {
  fbApi?: {
    influencerId: number;
    onMissingPermission: () => void;
  };
}) => {
  const [range, setRange] = useState({
    since: moment().startOf("day").add(-7, "days"),
    until: moment().endOf("day"),
  });

  const res = useFbPosts(
    {
      influencerId: fbApi?.influencerId ?? 1,
      since: range.since,
      until: range.until,
    },
    {
      enabled: !!fbApi,
    },
  );

  useEffect(() => {
    if (fbApi && res.isMissingPermissions) {
      fbApi.onMissingPermission();
    }
  }, [fbApi, res.isMissingPermissions, tasks]);

  return (
    <AttachLinksDialogTabPostsControlled
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      linked={
        fbApi
          ? {
              range,
              onRangeChange: setRange,
              onRequestRefresh: res.refresh,
              paginatedPosts: {
                data:
                  res.data?.map((x) => ({
                    id: x.fb_media_id,
                    data: x,
                  })) ?? [],
                isFetching: res.isLoading,
                fetchMore: res.fetchNextPage,
                hasMore: res.hasNextPage ?? false,
                isRefreshing: res.isRefreshing,
              },
            }
          : undefined
      }
      influencer={influencer}
      data={data}
      selectPost={selectPost}
      tasks={tasks}
    />
  );
};
