// TODO - lint
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Dialog from "rc-dialog";
import { useNavigate } from "react-router-dom";

export const FreeTrialDialog = (props) => {
  const { user } = props;
  const navigate = useNavigate();

  return (
    <Dialog
      onClose={props.handleClose}
      className="free-trial-dialog"
      visible={true}
      style={{ width: "700px", margin: "100px auto" }}
      animation="slide-fade"
      maskAnimation="fade"
    >
      <div className="free-trial-dialog-inner">
        <div>Just a minute...</div>
        <div>
          Welcome to Humanz free trial mode - A sneak peak to our capabilities.
          <br />
          Contact us in any time - info@humanz.ai
        </div>

        <div>Free trial mode</div>
        <div className="free-trial-cubes">
          <div>
            <div>
              <span
                style={{ fontSize: 20, color: "var(--main-baby-blue-color)" }}
              >
                {user.max_number_of_searches}
              </span>
              <br />
              {user.max_number_of_searches} Searches within our discovery engine
            </div>
          </div>
          <div>
            <div>
              <span
                style={{ fontSize: 20, color: "var(--main-baby-blue-color)" }}
              >
                {user.max_number_of_segmentation_views}
              </span>
              <br />
              {user.max_number_of_segmentation_views} Influencers' reports views
            </div>
          </div>
          <div>
            <div>
              <span
                style={{ fontSize: 20, color: "var(--main-baby-blue-color)" }}
              >
                {user.max_number_of_lists}
              </span>
              <br />
              {user.max_number_of_lists} Influencers list and objectives
            </div>
          </div>
          <div>
            <div>
              <span
                style={{ fontSize: 20, color: "var(--main-baby-blue-color)" }}
              >
                {user.can_open_campaigns ? "0" : 0}
              </span>
              <br />
              {user.can_open_campaigns
                ? "Unlimited premium campaigns"
                : "In order to start a new campaign we need your payment information"}
            </div>
          </div>
          <div>
            <div>
              <span
                style={{ fontSize: 20, color: "var(--main-baby-blue-color)" }}
              >
                Unlimited
              </span>
              <br />
              Unlimited marketplace campaigns
            </div>
          </div>
          <div
            style={{
              borderColor: "rgb(37, 189, 126)",
              borderWidth: 2,
              cursor: "pointer",
            }}
          >
            <div>
              <span style={{ fontSize: 20, color: "rgb(37, 189, 126)" }}>
                Premium
              </span>
              <br />
              Upgrade to premium account
            </div>
          </div>
        </div>
        <div
          className="preview-footer-buttons"
          style={{ marginTop: 0, justifyContent: "center" }}
        >
          <button
            className={"btn btn-pirmary "}
            style={{ width: "auto" }}
            onClick={() => {
              navigate("/edit-details");
              props.handleClose();
            }}
          >
            I WANT TO UPGRADE
          </button>
          <button
            className={"btn btn-pirmary cancel "}
            style={{ width: "auto" }}
            onClick={() => props.handleClose()}
          >
            FREE TRIAL
          </button>
        </div>
      </div>
    </Dialog>
  );
};
