import { Input } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

const TaskTitle = (props: {
  taskData: { title?: string };
  setTaskData: (arg0: any) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { title } = props.taskData;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = (event: any) => {
    if (event.target.value.length <= 16) {
      props.setTaskData({
        ...props.taskData,
        title: event.target.value,
      });
    }
  };
  return (
    <Input
      ref={inputRef}
      sx={{
        border: "none",
        padding: "0",
        borderRadius: "0",
        "&:focus": {
          boxShadow: "none",
        },
      }}
      placeholder="Title"
      onChange={handleInputChange}
      // disableUnderline
      value={title}
    />
  );
};

export default TaskTitle;
