import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Infinite from "react-infinite";
import { connect } from "react-redux";
import { getEnterpriseActionLog } from "../../../actions/enterpriseActions";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{
  logs: any;
  filters: any;
  isPagingLoading: boolean;
  scrollEnd: boolean;
  height: number;
}>;

class EnterpriseActivityLogInner extends Component<Props, State> {
  readonly state: State = {
    logs: [],
    filters: null,
    isPagingLoading: true,
    scrollEnd: false,
    height: window.innerHeight >= 800 ? 800 : window.innerHeight - 30,
  };

  componentDidMount(): void {
    this.props.getEnterpriseActionLog("", this.state.filters, (logs: any) => {
      this.setState({ logs, isPagingLoading: false });
    });
  }

  updateFilter = (filter: any) => {
    this.setState({ isPagingLoading: true });
    const filters = { ...this.state.filters };
    if (filter?.action_name) {
      filters.action_name = filter.action_name;
    }
    if (filter?.sub_user_id) {
      filters.sub_user_id = filter.sub_user_id;
    }
    this.setState({ filters }, () => {
      this.props.getEnterpriseActionLog("", this.state.filters, (logs: any) => {
        this.setState({ isPagingLoading: false, scrollEnd: false, logs });
      });
    });
  };

  // eslint-disable-next-line class-methods-use-this
  humanizeString = (str: string) => {
    if (!str || str === "") return "";
    return str.charAt(0).toUpperCase() + str.slice(1).replace(/_/g, " ");
  };

  handleInfiniteLoad = () => {
    if (
      this.state.scrollEnd ||
      this.state.isPagingLoading ||
      this.state.logs.length === 0
    ) {
      return;
    }

    this.setState({ isPagingLoading: true }, () => {
      const lastTime =
        this.state.logs && this.state.logs[this.state.logs.length - 1]
          ? this.state.logs[this.state.logs.length - 1].action_created_at
          : "";
      this.props.getEnterpriseActionLog(
        lastTime,
        this.state.filters,
        (logs: any) => {
          if (logs) {
            let counter = 0;
            for (let i = 0; i < logs.length; i += 1) {
              // if (!ids.includes(logs[i].item_id)) {
              this.state.logs.push(logs[i]);
              // }
              counter += 1;
            }

            this.setState({ isPagingLoading: false, scrollEnd: counter === 0 });
          } else {
            this.setState({ isPagingLoading: false, scrollEnd: true });
          }
        },
      );
    });
  };

  findUser = (id: number) => {
    const logline = this.state.logs.find((l: any) => l.sub_user_id === id);
    if (logline) {
      return logline.sub_user_name;
    }
    return id;
  };

  removeFilter = (filter: string) => {
    if (this.state.filters[filter]) {
      delete this.state.filters[filter];
    }
    this.updateFilter({});
  };

  render() {
    return (
      <>
        <div className="payments-overview-section">
          <div
            className="campaigns-title flex"
            style={{ placeContent: "space-between", paddingBottom: 20 }}
          >
            <div>
              <span>Users activity log</span>
            </div>
            <div className={"flex"}>
              {this.state.filters && this.state.filters.sub_user_id && (
                <div>
                  <div
                    className="tag filter-closeable"
                    onClick={() => {
                      this.removeFilter("sub_user_id");
                    }}
                  >
                    {" "}
                    {this.findUser(this.state.filters.sub_user_id)}{" "}
                    <i
                      style={{ fontSize: 18, marginLeft: 4 }}
                      className="fa fa-times-circle-o"
                    />
                  </div>
                </div>
              )}
              {this.state.filters && this.state.filters.action_name && (
                <div>
                  <div
                    className="tag filter-closeable"
                    onClick={() => {
                      this.removeFilter("action_name");
                    }}
                  >
                    {" "}
                    {this.humanizeString(this.state.filters.action_name)}{" "}
                    <i
                      style={{ fontSize: 18, marginLeft: 4 }}
                      className="fa fa-times-circle-o"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Enable for search bar */}
            {/* <div className="inner-toolbar-search-input"> */}
            {/*    <div className="moi-search-bold" style={{fontSize: 12, marginTop: 4}}/> */}
            {/*    <input className={"form-control"} onChange={(e) => { */}
            {/*        this.updateFilter({search:e.target.value}) */}
            {/*    }}/> */}
            {/* </div> */}
          </div>
        </div>
        <div className="activity-log-top-bar">
          <div style={{ flex: 2 }}>Action time</div>
          <div>Sub user</div>
          <div style={{ width: 170, flex: "none" }}>Action</div>
          <div>Item</div>
          <div style={{ flex: 3 }}>Description</div>
        </div>
        <div className="sub-users">
          <Infinite
            elementHeight={65}
            containerHeight={this.state.height - 270}
            className=""
            timeScrollStateLastsForAfterUserScrolls={0}
            useWindowAsScrollContainer={false}
            infiniteLoadBeginEdgeOffset={0}
            preloadBatchSize={0.5}
            onInfiniteLoad={this.handleInfiniteLoad}
            isInfiniteLoading={this.state.isPagingLoading}
          >
            {this.state.logs.length ? this.state.logs.map(this.renderRow) : ""}
          </Infinite>
          {this.state.isPagingLoading && (
            <div
              className="spinner small"
              style={{ marginTop: 10, marginBottom: 0 }}
            >
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          )}
        </div>
      </>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getItemLink = (row: any) => {
    let link = "";
    switch (row.action_name) {
      case "new_list":
        link = `/list/${row.item_id}`;
        break;
      case "share_list":
        link = `/list/${row.item_id}`;
        break;
      case "new_campaign":
        link = `/campaign/${row.item_id}`;
        break;
      case "share_campaign":
        link = `/campaign/${row.item_id}`;
        break;
      case "approved_influencer":
        link = `/campaign/${row.item_id}`;
        break;
      default:
    }
    return link;
  };

  renderRow = (row: any, index: number) => {
    return (
      <div className="invoice-row" key={`${row.item_id}_${index}`}>
        <div className={"invoice-row-details sub-users-row"}>
          <div style={{ flex: 2 }}>
            {moment(row.action_created_at).format(
              `${window.time.short_date} HH:mm`,
            )}
          </div>
          <Tooltip title={"Filter by user"}>
            <div
              className={"baby-blue clickable"}
              onClick={() =>
                this.updateFilter({ sub_user_id: row.sub_user_id })
              }
            >
              {this.state.filters &&
              this.state.filters.sub_user_id === row.sub_user_id ? (
                <i className="fas fa-filter" />
              ) : (
                <i className="far fa-filter" />
              )}{" "}
              <strong>{row.sub_user_name}</strong>
            </div>
          </Tooltip>
          <Tooltip title={"Filter by action"}>
            <div
              className={"baby-blue clickable"}
              onClick={() =>
                this.updateFilter({ action_name: row.action_name })
              }
              style={{ width: 170, flex: "none" }}
            >
              {this.state.filters &&
              this.state.filters.action_name === row.action_name ? (
                <i className="fa fa-filter" />
              ) : (
                <i className="far fa-filter" />
              )}{" "}
              <strong>{this.humanizeString(row.action_name)}</strong>
            </div>
          </Tooltip>
          <div>
            <strong>
              <a
                href={this.getItemLink(row)}
                className={"clickable"}
                target={"_blank"}
                rel="noreferrer"
              >
                {row.item_title}
              </a>
            </strong>
          </div>
          <div style={{ flex: 3 }}>{row.action_description}</div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => ({
  getEnterpriseActionLog: (time: string, filters: any, callback: any) =>
    dispatch(getEnterpriseActionLog(time, filters, callback)),
});

export const EnterpriseActivityLog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterpriseActivityLogInner);
