import moment from "moment";
import { useEffect, useState } from "react";
import {
  addFeatureFeedback,
  checkIfUserFeedback,
} from "src/api/actions/userActions";
import FeedbackModal from "../../dialogs/FeedbackModal";

const CampaignManagerFeedbackModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const FORM_NAME = "manage-v2-fb-v2";

  useEffect(() => {
    const now = moment();
    if (now < moment("2023-07-15T16:31:02.349Z")) {
      const local = localStorage.getItem(FORM_NAME);
      if (!local) {
        checkIfUserFeedback({ form_name: FORM_NAME })
          .then((res) => {
            if (res.data === false) {
              localStorage.setItem(FORM_NAME, "0");
            } else if (res.data === true) {
              localStorage.setItem(FORM_NAME, "true");
            }
          })
          .catch(() => {});
      } else if (local !== "true") {
        setOpen(true);
      }
    }
  }, []);

  const onSubmit = (rate: number, comment: string) => {
    setOpen(false);
    const numberOfTries = Number(localStorage.getItem(FORM_NAME));
    if (numberOfTries < 5 && rate < 0) {
      localStorage.setItem(FORM_NAME, (numberOfTries + 1).toString());
    } else {
      localStorage.setItem(FORM_NAME, "true");
      addFeatureFeedback({ form_name: FORM_NAME, comment, rate }).catch(
        () => {},
      );
    }
  };

  if (!open) {
    return null;
  }

  return (
    <FeedbackModal
      description="What do you think of our recent changes to the management page?"
      onSubmit={onSubmit}
    />
  );
};

export default CampaignManagerFeedbackModal;
