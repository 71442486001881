import { Textarea } from "@chakra-ui/react";
import Tooltip from "rc-tooltip";
import Rating from "react-rating";
import Select from "react-select";
import { AuctionQuestionWithSelected } from "src/api/ExtendedTypes";

interface OwnProps {
  filters: any;
  list: any;
  lists: any;
  questions: any;
  setQuestions: any;
  searchInfluencers: any;
  searchLists: any;
  setFilters: any;
  setTagsFilter: any;
  tags: any;
  tagsFilter: any;
  callback: any;
}

export const CampaignManagerFilters = ({
  filters,
  list,
  lists,
  questions,
  setQuestions,
  searchInfluencers,
  searchLists,
  setFilters,
  setTagsFilter,
  tags,
  tagsFilter,
  callback,
}: OwnProps) => {
  const renderTagsFilter = () => {
    if (tags) {
      return (
        <div>
          <strong
            className="small-title"
            style={{ marginLeft: 0, marginBottom: 10 }}
          >
            TAGS
          </strong>
          <Select
            className="searchable-input"
            options={tags}
            placeholder={""}
            clearable={false}
            value={tagsFilter as any}
            onChange={(e: any) => {
              const tmpTagsFilter = [...tagsFilter];
              tmpTagsFilter.addOrRemove(e.label);
              setTagsFilter(tmpTagsFilter);
              setFilters([...filters]);
            }}
            name="selected-state"
            searchable={true}
          />
          <div className="filter-multi-cubes">
            {tagsFilter?.map((t: any, idx: number) => (
              <div key={`${t}_${idx}`}>
                <span dir="auto">{t}</span>{" "}
                <a
                  className="far fa-times"
                  onClick={() => {
                    const tmpTagsFilter = [...tagsFilter];
                    tmpTagsFilter.addOrRemove(t);
                    setTagsFilter(tmpTagsFilter);
                    setFilters([...filters]);
                  }}
                ></a>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderQuestionsFilter = () => {
    if (questions && questions.length > 0) {
      return (
        <div>
          <strong
            style={{ marginLeft: 0, marginBottom: 5 }}
            className="small-title"
          >
            QUESTIONS
          </strong>
          <div
            style={{
              border: "1px solid #bbb",
              borderRadius: 10,
              padding: 10,
              paddingTop: 0,
            }}
          >
            {questions.map((question: AuctionQuestionWithSelected) => {
              if (question.question_type === "select") {
                const { selected } = question;
                return (
                  <div key={question.id}>
                    <strong
                      className="small-title"
                      style={{ marginLeft: 0, marginBottom: 5, marginTop: 5 }}
                    >
                      {question.question}
                    </strong>
                    <Select
                      className="searchable-input"
                      options={question.options as any}
                      placeholder={""}
                      value={selected}
                      onChange={(e: any) => {
                        // eslint-disable-next-line no-param-reassign
                        question.selected = e ? e.label : "";
                        setQuestions([...questions]);
                        setFilters([...filters]);
                        searchInfluencers();
                      }}
                      name="selected-state"
                      multi={false}
                    />
                  </div>
                );
              }
              if (question.question_type === "open") {
                return (
                  <div key={question.id}>
                    <strong
                      className="small-title"
                      style={{ marginLeft: 0, marginBottom: 5, marginTop: 5 }}
                    >
                      {question.question}
                    </strong>
                    <div>
                      <Textarea
                        defaultValue={question.selected}
                        onChange={(e) => {
                          const { value } = e.target;
                          const newQuestions = [...questions];
                          newQuestions[
                            questions.findIndex(
                              (q: any) => q.id === question.id,
                            )
                          ].selected = e.target.value;

                          setTimeout(() => {
                            if (question.selected === value) {
                              setQuestions(newQuestions);
                              setFilters([...filters]);
                              searchInfluencers();
                            }
                            callback();
                          }, 1000);
                        }}
                        className="input-line"
                      />
                    </div>
                  </div>
                );
              } // Checkbox type
              return null;
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderListsFilter = () => {
    if (!lists) return null;
    const filteredLists = lists?.map((l: any) => {
      return {
        label: `${(l.is_favorite ? "⭐ " : "") + l.title} (${
          l.influencer_ids.length
        })`,
        value: l.id,
      };
    });
    if (lists && lists.length > 0) {
      return (
        <div>
          <strong
            style={{ marginLeft: 0, marginBottom: 5 }}
            className="small-title"
          >
            LIST
          </strong>
          <div>
            <Select
              className="searchable-input"
              value={list?.id}
              options={filteredLists}
              placeholder={""}
              onChange={(e: any) => {
                searchLists(e);
                setFilters([...filters]);
              }}
              name="selected-state"
              multi={false}
            />
            {list?.id}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderInfluencerRatingFilter = () => {
    const updatedFilters = [...filters];
    const avgReview = updatedFilters.find((f: any) => f.name === "avg_review");
    return (
      <div>
        <strong
          style={{ marginLeft: 0, marginBottom: 5 }}
          className="small-title"
        >
          {avgReview.label}
          {avgReview.label_tooltip ? (
            <Tooltip
              overlay={avgReview.label_tooltip}
              trigger={["hover"]}
              placement={"top"}
            >
              <i
                style={{ marginLeft: 5, fontSize: 14 }}
                className="fa-solid fa-info-circle"
              />
            </Tooltip>
          ) : null}
        </strong>
        <div className="stars-small-container">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Rating
            emptySymbol="fa fa-star-o"
            fullSymbol="fa fa-star"
            className="seg-stars"
            fractions={2}
            quiet={false}
            initialRating={avgReview.selected}
            readonly={false}
            onChange={(stars) => {
              avgReview.selected = stars;
              setFilters(updatedFilters);
            }}
          />{" "}
          {avgReview && avgReview.selected && `${avgReview.selected} and up`}{" "}
          {avgReview && (
            <div
              onClick={() => {
                avgReview.selected = null;
                setFilters(updatedFilters);
              }}
              className={"btn btn-default btn-sm"}
            >
              Clear
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      {renderTagsFilter()}
      {renderQuestionsFilter()}
      {renderListsFilter()}
      {renderInfluencerRatingFilter()}
    </>
  );
};
