// TODO - lint
/* eslint-disable no-unused-vars, react/no-deprecated, camelcase, class-methods-use-this, consistent-return, class-methods-use-this, no-param-reassign, consistent-return, consistent-return, import/no-default-export */
// General Imports
import React from "react";
import { connect } from "react-redux";
import Textarea from "react-textarea-autosize";
// Actions imports
import {
  getAvailableChats,
  sendFileMessage,
  sendFileMessageMultipleMessage,
  sendMultipleMessage,
} from "../../../actions/inboxActions";
import { setToasterNotificaion } from "../../../actions/notificationsActions";
// Components Import
import NoImage from "../../../images/svgs/placeholder.svg";

class SendMultiMessageSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      message: "",
      selectedLists: [],
      sending: false,
      columns: [
        { label: "bids", name: "bids", collapse: true },
        { label: "invites", name: "invite", collapse: true },
        { label: "approval / publish", name: "approval", collapse: true },
      ],
      file: null,
    };
  }

  componentWillMount() {
    const selectedLists = [];
    if (this.props.influencersSelected.length > 0) {
      this.props.influencers.forEach((x) => {
        if (
          x.humanz_fk &&
          this.props.influencersSelected.includes(x.influencer_id)
        ) {
          selectedLists.push(x.humanz_fk);
        }
      });
    }
    this.setState({ selectedLists });
  }

  onListSelect(id) {
    const { selectedLists } = this.state;
    selectedLists.addOrRemove(id);
    this.setState({ selectedLists });
  }

  sendMessage() {
    const { message, file } = this.state;
    const counters = this.state.selectedLists.length;

    if ((!message && !file) || this.state.sending || counters.length === 0) {
      return;
    }

    const campaign_id = this.props.board.board.board_id;
    this.setState({ sending: true });
    if (file) {
      const fileType = file.type.includes("image") ? "image" : "pdf";

      this.props.sendFileMessageMultipleMessage(
        this.state.selectedLists,
        campaign_id,
        file,
        fileType,
        () => {
          this.setState({ sending: false });
          this.props.setToasterNotificaion("Messages sent successfully");
          this.props.getAvaliableChats();
          this.props.handleClose();
        },
      );
    } else {
      this.props.sendMultipleMessage(
        this.state.selectedLists,
        campaign_id,
        message,
        () => {
          this.setState({ sending: false });
          this.props.setToasterNotificaion("Messages sent successfully");
          this.props.getAvaliableChats();
          this.props.handleClose();
        },
      );
    }
  }

  renderRow(data, idx) {
    return (
      <div
        className="import-list-row"
        key={idx}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <div style={{ width: 45, height: 45 }}>
          <img
            className="blurable"
            style={{ width: 45, height: 45 }}
            src={data.picture_url ? data.picture_url : NoImage}
          />
        </div>
        <div className={"import-list-row-details "} style={{ display: "flex" }}>
          <strong
            style={{ width: 350 }}
            onClick={() => this.onListSelect(data.humanz_fk)}
            className="import-list-name blurable-large"
            title={data.name}
          >
            {data.name}
          </strong>
        </div>
        <div
          className="checkbox"
          style={{ padding: 0, paddingTop: 0, marginLeft: -10 }}
        >
          <label>
            <input
              type="checkbox"
              checked={this.state.selectedLists.includes(data.humanz_fk)}
              onChange={() => this.onListSelect(data.humanz_fk)}
            />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </div>
    );
  }

  getConcatColumn(data, selectedColumn) {
    const column = { notifications: 0, influencers: [] };
    const columndata = data
      ? data.states[this.state.columns[selectedColumn].name]
      : null;
    if (columndata) {
      column.influencers = columndata.influencers;
    }

    if (selectedColumn === 3) {
      column.influencers = column.influencers.concat(
        data && data.states[this.state.columns[2].name]
          ? data.states[this.state.columns[2].name].influencers
          : [],
      );
    }
    // apply sorting..
    if (
      this.state.sortKey &&
      column.influencers &&
      column.influencers.length > 0
    ) {
      this.sortInfluencers(column.influencers, this.state.sortKey);
    }
    column.influencers = column.influencers.filter((x) => x.user_registered);
    return column;
  }

  arrayInArray(arr1, arr2) {
    if (!arr1 || arr1.length === 0 || !arr2 || arr2.length === 0) return;
    let flag = true;
    arr1.forEach((x) => {
      if (flag) {
        flag = arr2.includes(x);
      }
    });
    return flag;
  }

  chcekAll(checked, ids) {
    if (ids.length === 0) return;
    const selected = this.state.selectedLists;
    if (checked) {
      ids.forEach((x) => {
        if (selected.includes(x)) {
          selected.addOrRemove(x);
        }
      });
    } else {
      ids.forEach((x) => {
        if (!selected.includes(x)) {
          selected.addOrRemove(x);
        }
      });
    }

    this.setState({ selectedLists: selected });
  }

  openFilePicker = () => {
    document.getElementById("upload-file").click();
  };

  addFile = (files) => {
    this.setState({ file: files[0] });
  };

  renderSection(index) {
    const column = this.state.columns[index];
    const data = this.props.board.board;
    let { influencers } = this.getConcatColumn(data, index);
    influencers = influencers.filter((x) => {
      x.name = x.name ? x.name : "";
      return x.name.toLowerCase().includes(this.state.search.toLowerCase());
    });
    if (influencers.length === 0) return;

    const ids = influencers.map((x) => x.humanz_fk);
    const checked = this.arrayInArray(ids, this.state.selectedLists);
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 20,
            marginBottom: 10,
            marginTop: 15,
          }}
        >
          <strong
            className="label-title"
            style={{
              fontSize: 15,
              marginBottom: -5,
              paddingTop: 5,
              display: "block",
            }}
          >
            {column.label}
            <span
              className={`collapse-input ${column.collapse}`}
              onClick={() => {
                column.collapse = !column.collapse;
                this.setState({});
              }}
            />
          </strong>
          <div
            className="checkbox"
            style={{ padding: 0, paddingTop: 0, margin: 0 }}
          >
            <label>
              <input
                type="checkbox"
                checked={checked}
                onChange={() => this.chcekAll(checked, ids)}
              />
              <span className="checkbox-material">
                <span className="check"></span>
              </span>
            </label>
          </div>
        </div>
        {!column.collapse &&
          influencers.map((x, idx) => this.renderRow(x, idx))}
      </div>
    );
  }

  render() {
    if (!this.props.board.board) return;

    return (
      <div
        className="import-from-list-container animated fadeInRight faster"
        id="multi_messages_side"
      >
        <div>
          <div style={{ fontSize: 22 }}>New Message</div>
          <div
            className="import-list-close-button"
            onClick={() => this.props.handleClose()}
          >
            <span className="moi-ximage" />
          </div>
          <div
            className="import-from-list-spacer"
            style={{ marginTop: 20, marginBottom: 20 }}
          />
          <div>
            <div className="blurable-large" style={{ marginBottom: 20 }}>
              {this.state.file ? (
                <div className="file-pre-send">
                  {this.state.file.type.includes("image") ? (
                    <i className="far fa-image"></i>
                  ) : (
                    <i className="far fa-file"></i>
                  )}
                  <div>{this.state.file.name}</div>
                  <i
                    style={{ cursor: "pointer" }}
                    className="far fa-times"
                    onClick={() => this.setState({ file: null })}
                  ></i>
                </div>
              ) : (
                <div>
                  <Textarea
                    className={"input-line"}
                    style={{ border: "1px solid #aaaaaa", paddingRight: 20 }}
                    defaultValue={this.state.message}
                    onChange={(e) => {
                      this.setState({ message: e.target.value });
                    }}
                    minRows={5}
                    maxRows={5}
                    placeholder={"Write your message here"}
                  />
                  <div
                    className="far fa-paperclip"
                    style={{
                      position: "absolute",
                      fontSize: 18,
                      marginTop: -25,
                      marginLeft: 492,
                      cursor: "pointer",
                    }}
                    onClick={this.openFilePicker}
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="upload-file"
                    onChange={(e) => this.addFile(e.target.files)}
                  />
                </div>
              )}
            </div>
            <div className="import-list-influencers-row">
              <div className="inner-toolbar-search-input">
                <div
                  className="moi-search-bold"
                  style={{ fontSize: 12, marginTop: 4 }}
                />
                <input
                  onChange={(e) =>
                    this.setState({ search: e.target.value.toLowerCase() })
                  }
                  style={{ height: 20, width: 150 }}
                  className="form-control"
                />
              </div>
            </div>
            <div
              className="import-list-section no-infinite"
              style={{ marginTop: 10, height: "calc(100vh - 480px)" }}
            >
              {this.renderSection(0)}
              {this.renderSection(1)}
              {this.renderSection(2)}
            </div>
          </div>

          <div className="import-from-list-buttons-row">
            <button
              className="btn btn-primry"
              disabled={
                (!this.state.message && !this.state.file) ||
                this.state.sending ||
                this.state.selectedLists.length === 0
              }
              onClick={() => this.sendMessage()}
            >
              Send
            </button>
            <button
              className="btn btn-primry"
              onClick={() => this.props.handleClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    board: state.boardReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMultipleMessage: (ids, campaignId, message, callback) => {
      dispatch(sendMultipleMessage(ids, campaignId, message, callback));
    },
    setToasterNotificaion: (message) => {
      dispatch(setToasterNotificaion(message));
    },
    sendFileMessage: (
      chatId,
      campaignId,
      uri,
      name,
      mime,
      attachmentType,
      messageId,
      callback,
    ) => {
      dispatch(
        sendFileMessage(
          chatId,
          campaignId,
          uri,
          name,
          mime,
          attachmentType,
          messageId,
          callback,
        ),
      );
    },
    getAvaliableChats: () => {
      dispatch(getAvailableChats());
    },
    sendFileMessageMultipleMessage: (
      ids,
      campaignId,
      file,
      attachmentType,
      callback,
    ) => {
      dispatch(
        sendFileMessageMultipleMessage(
          ids,
          campaignId,
          file,
          attachmentType,
          callback,
        ),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendMultiMessageSide);
