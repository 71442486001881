import React from "react";
import { Trans, TranslationProps, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AcademyActions } from "../../../actions/academyActions";
import { CourseSummary } from "../../../models/Api";

interface OwnProps {
  getCourses: any;
  getQuizQuestions: any;
  chapter_id: any;
  current_chapter: any;
  current_course: any;
  courses: Array<CourseSummary>;
  quiz_id: string;
  navigateToCourse: any;
  sendQuiz: any;
  navigateToLesson: any;
}

type Props = OwnProps;

type State = Readonly<{
  userAnswers: Array<any>;
  questions: Array<any>;
  currentQuestion: any;
  showTab: string;
  results: any;
}>;

class AcademyQuizInner extends React.Component<Props, State, TranslationProps> {
  readonly state: State = {
    userAnswers: [],
    questions: [],
    currentQuestion: null,
    showTab: "quiz",
    results: null,
  };

  componentDidMount(): void {
    this.getQuizQuestions();
  }

  getQuizQuestions = () => {
    this.props.getQuizQuestions(this.props.quiz_id, (data: any) => {
      this.setState({ questions: data.data }, () => {
        // console.log("Question:",this.state.questions)
        this.setCurrentQuestion(0);
      });
    });
  };

  setCurrentQuestion = (i: number) => {
    if (!this.state.questions) return;
    this.setState({ currentQuestion: this.state.questions[i] });
  };

  setAnswer = (question_id: number, answer: number, callback: any) => {
    const newState = { ...this.state };
    const currentQuestion = newState.userAnswers.find(
      (a) => a.id === question_id,
    );
    if (currentQuestion) {
      currentQuestion.chosen_answer = answer;
    } else {
      newState.userAnswers.push({ id: question_id, chosen_answer: answer });
    }
    this.setState(newState, () => {
      if (callback) callback();
    });
  };

  private sendTest = () => {
    this.props.sendQuiz(
      this.props.quiz_id,
      { answers: this.state.userAnswers },
      (data: any) => {
        if (data.passed) {
          this.setState({ showTab: "success", results: data });
          this.props.getCourses(true);
        } else {
          this.setState({ showTab: "failed", results: data });
        }
      },
    );
  };

  private answerQuestion(_: any, i: number) {
    this.setAnswer(this.state.currentQuestion.id, i, () => {
      // console.log(a,i,this.state.questions,this.state.userAnswers)
      this.setState({}, () => {
        if (this.state.questions?.length === this.state.userAnswers.length) {
          this.sendTest();
        }
      });
    });
    if (this.state.questions?.length === this.state.userAnswers.length) return;
    this.setCurrentQuestion(this.state.currentQuestion.num);
  }

  renderChapterBox = () => {
    if (!this.props.current_course || !this.props.current_course.all_chapters)
      return null;
    const currentChapter = this.props.current_course?.current_chapter;
    const chap = this.props.current_course.all_chapters.find(
      (c: any) => c.id === currentChapter.id,
    );
    const isAvailable = currentChapter?.ordinal_num >= chap.ordinal_number;
    const currentChapterStatus =
      this.props.current_course?.current_chapter_status;
    return (
      <div className={"chapter-box-wrapper"} key={chap.id}>
        <div
          className={`chapter-box${chap.is_completed ? " completed" : ""}${
            isAvailable ? " available" : ""
          }`}
        >
          <div className="top-content">
            <div className="chapterNum">
              <span>
                <Trans>CHAPTER</Trans> 0{currentChapter.ordinal_num}
              </span>
              <span>QUIZ</span>
            </div>
            <div className="horiz-spacer" />
            <div className="lesson-status">
              {chap.is_completed ? (
                <div className="completed">
                  <i className="fas fa-check" />
                </div>
              ) : (
                <>
                  <div
                    onClick={() =>
                      this.props.navigateToLesson(
                        currentChapter.id,
                        currentChapterStatus.lesson_number,
                      )
                    }
                  >
                    <i className="fas fa-laptop-code" />
                    <div className="current-lesson-state">
                      <span>
                        <Trans>LESSON</Trans> {chap.number_of_lessons || 0}/
                        {chap.number_of_lessons}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  resetCourse = () => {
    this.setState({
      showTab: "quiz",
      currentQuestion: this.state.questions[0],
      userAnswers: [],
    });
  };

  // correct_ids: []
  // failed_ids: (3) [443, 444, 445]
  // passed: false
  // score: 0

  renderResults = () => {
    return (
      <div className="results">
        <hr />
        <div className="quiz-wrapper">
          {this.state.results &&
            this.state.questions.map((currentQuestion: any, i: number) => (
              <div key={i}>
                <div key={i} className="question-title relative">
                  <div className="title-text">
                    <strong>
                      <Trans>Question </Trans>
                      {i + 1} <Trans>Of </Trans>
                      {this.state.questions.length}{" "}
                    </strong>
                  </div>
                  <div className="question-result">
                    {this.state.results.failed_ids?.indexOf(
                      currentQuestion.id,
                    ) > -1 && <i className={"failed fa fa-times"} />}
                    {this.state.results.correct_ids?.indexOf(
                      currentQuestion.id,
                    ) > -1 && <i className={"pass fa fa-check"} />}
                  </div>
                </div>
                <div className="question">
                  <div className="question-text">
                    <strong>{currentQuestion.question}</strong>
                  </div>
                </div>
                <div className="answers">
                  {currentQuestion.answer_options.map((a: any, j: number) => (
                    <div
                      key={j + 1}
                      className={`answer ${
                        this.state.userAnswers?.length &&
                        this.state.userAnswers[i].chosen_answer === j + 1
                          ? "active"
                          : ""
                      }`}
                    >
                      <div className="inner-answer">
                        <div className="answer_num">0{j + 1}</div>
                        <div className="answer">{a}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  render() {
    const { currentQuestion, questions } = this.state;
    if (!currentQuestion || !currentQuestion.answer_options) return <></>;

    switch (this.state.showTab) {
      case "success":
        return (
          <div className="courses">
            <div className="quiz single-course-container single-lesson">
              <div className="jumbotron text-center alert-success">
                <div className="notice-text">
                  <strong>
                    <Trans>
                      Congratulations! You have finished the chapter
                      successfully.
                    </Trans>
                  </strong>
                </div>
                <div>
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      this.props.navigateToCourse(this.props.current_course.id)
                    }
                  >
                    <Trans>Go back to course page</Trans>
                  </button>
                </div>
              </div>
              {this.renderResults()}
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="courses">
            <div className="quiz single-course-container single-lesson">
              <div className="jumbotron alert-danger text-center">
                <div className="notice-text">
                  <strong>
                    <Trans>
                      Sorry, you have failed the quiz. Please try again
                    </Trans>
                  </strong>
                </div>
                <div>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      this.resetCourse();
                    }}
                  >
                    <Trans>Try again</Trans>
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.props.navigateToCourse(this.props.current_course.id)
                    }
                  >
                    <Trans>Go back to course page</Trans>
                  </button>
                </div>
              </div>
              {this.renderResults()}
            </div>
          </div>
        );
      case "quiz":
      default:
        return (
          <>
            <Link
              className={"top-link"}
              to={`/academy/${this.props.current_course.id}`}
            >
              <i className="fa fa-chevron-left" /> chapters
            </Link>
            <div
              className={`courses ${
                ["he", "ar"].indexOf(
                  this.props.current_course?.subscription_status?.language,
                ) !== -1
                  ? "rtl"
                  : ""
              }`}
            >
              <div className="quiz single-course-container single-lesson">
                {this.renderChapterBox()}
                <hr />
                <div className="quiz-wrapper">
                  <div className="quiz-title">
                    <strong>
                      <Trans>Question</Trans> {currentQuestion.num}{" "}
                      <Trans>Of </Trans>
                      {questions.length}
                    </strong>
                  </div>
                  <div className="question">
                    <div className="question-text">
                      <strong>{currentQuestion.question}</strong>
                    </div>
                  </div>
                  <div className="answers">
                    {currentQuestion.answer_options.map((a: any, i: number) => (
                      <div
                        key={i + 1}
                        className="answer hoverable clickable"
                        onClick={() => {
                          this.answerQuestion(a, i + 1);
                        }}
                      >
                        <div className="inner-answer">
                          <div className="answer_num">0{i + 1}</div>
                          <div className="answer">{a}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="bottom-buttons text-center">
                    {this.state.currentQuestion.num > 1 && (
                      <button
                        className="back btn btn-primary"
                        onClick={() => {
                          this.setCurrentQuestion(
                            this.state.currentQuestion.num - 2,
                          );
                        }}
                      >
                        <Trans>BACK</Trans>
                      </button>
                    )}
                    {/* {this.state.currentQuestion.num <= this.state.userAnswers && <button className="next btn btn-primary" onClick={()=>{ */}
                    {/*    this.setCurrentQuestion(this.state.currentQuestion.num);} */}
                    {/* }><Trans>NEXT</Trans></button>} */}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
  }
}
const mapStateToProps = (state: any) => {
  return {
    current_course: state.academyReducer.current_course,
    courses: state.academyReducer.courses,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getQuizQuestions: (quiz_id: number, callback: any) => {
      dispatch(AcademyActions.getQuizQuestions(quiz_id, callback));
    },
    sendQuiz: (quiz_id: number, data: any, callback: any) => {
      dispatch(AcademyActions.sendQuiz(quiz_id, data, callback));
    },
  };
};
export const AcademyQuiz = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AcademyQuizInner));
