import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { numberWithCurrency } from "../../utilities/general";
import CampaignInfoBox from "./CampaignInfoBox";
import {
  CampaignPaymentsContext,
  CampaignPaymentsContextType,
} from "./CampaignPaymentsContext";
import ChangedPaymentsList from "./Cycles/ChangedPaymentsList";

const CampaignPaymentsHeader = () => {
  const context = React.useContext<CampaignPaymentsContextType | any>(
    CampaignPaymentsContext,
  );

  const board: any = useSelector((store) => store.boardReducer.board);
  const getPrice = useCallback(
    (price: number) => {
      if (!board) {
        return null;
      }
      return numberWithCurrency(price, board.summary.currency);
    },
    [board],
  );

  const [showPaymentsChange, setShowPaymentsChange] = useState(false);
  const showError = context?.payments?.summary?.payment_method !== "humanz";

  const months = context?.payments?.months;
  const { influencers } = months?.length ? months[0] : [];
  const relevantInfluencers = influencers?.filter((influencer: any) => {
    return (
      influencer.payments?.length &&
      influencer.payments[0].payment_type === "recurring_payment" &&
      influencer.recurring_price !== influencer.payments[0].price
    );
  });

  return (
    <>
      <Flex mt={5} mb={5} justifyContent="space-between" alignItems={"center"}>
        <Text fontSize={"3xl"}>Campaign Payments</Text>
        <Flex>
          {showError ? (
            <CampaignInfoBox
              box={{
                variant: "warning",
                title: "Not paying through Humanz",
                info: "Not paying thorugh Humanz. The advertiser is paying the influencers directly on this campaign",
              }}
            />
          ) : null}
          <ChangedPaymentsList
            isOpen={showPaymentsChange}
            onClose={() => setShowPaymentsChange(false)}
            relevantInfluencers={relevantInfluencers}
            getPrice={getPrice}
          />
          {relevantInfluencers?.length > 0 && (
            <Flex
              style={{
                borderRadius: "12px",
                border: "1px solid rgba(36, 159, 240, 0.10)",
                background: "#E9F5FD",
                padding: "8px",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => {
                setShowPaymentsChange(true);
              }}
            >
              <Text
                style={{
                  color: "#249FF0",
                  fontSize: "15px",
                  fontWeight: 500,
                  marginRight: "15px",
                }}
              >
                Flat fees changes
              </Text>
              <Box
                style={{
                  background: "#50B2F3",
                  borderRadius: "9px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: "4px",
                  paddingLeft: "6px",
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: "26px",
                }}
              >
                <i
                  className="fa-regular fa-money-check-dollar-pen"
                  style={{ color: "white", fontSize: "18px" }}
                ></i>
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default CampaignPaymentsHeader;
