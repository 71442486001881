import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const FileUploadTheme: ComponentMultiStyleConfig = {
  parts: [],
  baseStyle: {
    container: {
      display: "flex",
      flex: 1,
    },
    previewContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      flex: 1,
      cursor: "pointer",
      position: "relative",
      __readonly: {
        cursor: "initial",
      },
    },
    mediaContainer: {
      position: "absolute",
      inset: "0px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    imageMedia: {
      objectFit: "cover",
      width: "auto",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    guideContainer: {
      display: "none",
      position: "absolute",
      inset: "0px",
      bg: "blackAlpha.600",
      alignItems: "center",
      justifyContent: "center",
      pointerEvents: "none",
      textAlign: "center",
      color: "white",
      "&[data-hover], &[data-drag-accept], &[data-drag-reject]": {
        display: "flex",
      },
      "&[data-drag-accept]": {
        bgColor: "rgba(104, 211, 145, 0.6)",
      },
      "&[data-drag-reject]": {
        bgColor: "rgba(252, 129, 129, 0.6)",
      },
    },
    uploadContainer: {
      flex: 1,
      cursor: "pointer",
      p: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDir: "column",
      borderRadius: "xl",
      borderStyle: "solid",
      borderWidth: "3px",
      background: "white",
      borderColor: "#EAE8E8",
      minW: 44,
      "&[data-drag-accept]": {
        bgColor: "rgba(104, 211, 145, 0.6)",
      },
      "&[data-drag-reject]": {
        bgColor: "rgba(252, 129, 129, 0.6)",
      },
    },
    uploadIcon: {
      background: "white",
      width: "50px",
      borderRadius: 12.5,
      height: "50px",
      paddingTop: 2,
      color: "brand.500",
      boxShadow: "0px 2.7806px 4.86606px 0px rgba(0, 0, 0, 0.17)",
      textAlign: "center",
      marginBottom: 2,
      fontSize: 20,
    },
    uploadText: {
      pt: 3,
      color: "#808080",
      fontSize: "12px",
      textAlign: "center",
    },
  },
  variants: {
    round: {
      uploadContainer: {
        borderRadius: "full",
      },
      previewContainer: {
        borderRadius: "full",
        overflow: "hidden",
      },
      imageMedia: {
        objectFit: "cover",
        width: "100%",
        height: "auto",
      },
    },
  },
};
