import { FileTypes, FileUpload } from "src/ui/FileUpload";

const TaskReferences = (props: any) => {
  if (!props.references) return null;
  const handleFileUpload = (index: number, image_url: string) => {
    if (index < props.references.length) {
      props.deleteReference(index);
    }

    props.setState(
      {
        newReference: image_url,
      },
      () => {
        // This callback will be executed after the state has been updated
        props.addReference();
      },
    );
  };

  const renderFileUpload = (index: number) => (
    <div
      key={index}
      style={{
        display: "flex",
        flex: "0 0 62px",
        height: "62px",
      }}
    >
      {props.references.length + 1 > index && !props.disabled ? (
        <FileUpload
          fileType={FileTypes.image}
          previewFileUrl={props.references[index]}
          onFileUploaded={(image_url) => handleFileUpload(index, image_url)}
          iconSize={"24"}
          showText={false}
          styleImg={{
            objectFit: "cover",
            height: "100%",
            borderRadius: "6px",
          }}
          disabled={props.isShare}
          deleteFile={() => {
            if (!props.isShare) props.deleteReference(index);
          }}
          circle
          references
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1.5px solid #EBEBEB",
            borderRadius: "6px",
            width: "62px",
            background: "#EBEBEB",
          }}
        >
          <i
            className="fa-regular fa-image"
            style={{ fontSize: "24px", color: "#cfcfcf" }}
          ></i>
        </div>
      )}
    </div>
  );

  return (
    <div style={{ width: "202px", padding: 3 }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 5,
          marginBottom: "5px",
        }}
      >
        {[0, 1, 2].map((index) => renderFileUpload(index))}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
        {[3, 4, 5].map((index) => renderFileUpload(index))}
      </div>
    </div>
  );
};

export default TaskReferences;
