import AddButton from "../FormUI/AddButton";
import FormCard from "../FormUI/FormCard";

type Props = {
  renderNotesBtn: any;
  fields: any;
  onToggleProduct: any;
  renderProductRow: any;
  isShare: boolean;
  children?: any;
};

const BriefProduct = ({
  renderNotesBtn,
  fields,
  onToggleProduct,
  renderProductRow,
  isShare,
  children,
}: Props) => {
  return (
    <FormCard>
      <div className="products-overview-section">
        <div className="create-campaign-title">
          <span>Products</span>
          {renderNotesBtn(3)}
        </div>
        {fields.products.length === 0 && (
          <div
            style={{
              fontSize: "14px",
              color: "#95979D",
              fontWeight: 400,
              width: "600px",
              lineHeight: "24px",
              marginTop: "20px",
            }}
          >
            Some campaigns contain products. If this campaign contains a product
            add and describe the product down below
          </div>
        )}
        <div>
          {fields.products &&
            fields.products.length > 0 &&
            fields.products.map((x: any, index: number) =>
              renderProductRow(x, index),
            )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: fields.products.length === 0 ? "50px" : "",
          }}
        >
          {!isShare && (
            <AddButton
              size={fields.products.length === 0 ? "lg" : "sm"}
              type="product"
              onClick={() => onToggleProduct(null)}
            />
          )}
        </div>
      </div>
      {children}
    </FormCard>
  );
};

export default BriefProduct;
