import axios from "axios";
import { toast } from "react-toastify";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";
import { getSock } from "./moiSockJs";

const getInboxBaseUrl = () => `${getBackendUri()}/moi/inbox`;
const getCampaignsChatsUrl = () => `${getInboxBaseUrl()}/campaigns`;
const getInfluencersChats = () => `${getInboxBaseUrl()}/influencers`;
const getNewChatsUrl = () => `${getInboxBaseUrl()}/new_chats`;

const valueOrEmpty = (val) => {
  if (val && val !== undefined && val !== null) return val;
  return "";
};
export const getAvailableChats = (
  cleanMessages,
  type,
  maxSeenTime,
  nameFilter,
  callBack,
) => {
  return (dispatch) => {
    axios
      .get(
        `${getCampaignsChatsUrl()}?max_seen_time=${
          !cleanMessages ? valueOrEmpty(maxSeenTime) : ""
        }&nameFilter=${
          valueOrEmpty(nameFilter)
            ? encodeURIComponent(valueOrEmpty(nameFilter))
            : "" || ""
        }`,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        const array = [];
        array.chat_id = -1;
        if (cleanMessages) {
          dispatch({
            type: "GET_AVALIABLECHATS_FULFILLED",
            payload: response.data,
          });
          dispatch({ type: "GET_CHAT_MESSAGES_FULFILLED", payload: array });
        } else {
          dispatch({
            type: "GET_MORE_AVALIABLECHATS_FULFILLED",
            payload: response.data,
          });
        }
        if (callBack !== undefined) {
          callBack(response.data);
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_AVALIABLECHATS_REJECTED", payload: err });
        if (callBack !== undefined) {
          callBack();
        }
        if (cleanMessages) {
          dispatch({ type: "GET_CHAT_MESSAGES_FULFILLED", payload: [] });
        }
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get chats",
          "system_notifications_failure_get_chats",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getAvailableInfluencersChats = (
  cleanInfluencers,
  cleanStars,
  maxSeenTime,
  nameFilter,
  auctionId,
  starred,
  callBack,
) => {
  return (dispatch) => {
    if (cleanInfluencers) {
      dispatch({ type: "RESET_INFLU_CHAT", payload: cleanStars });
    }
    const url = `${getInfluencersChats()}?max_seen_time=${
      !cleanInfluencers ? maxSeenTime : ""
    }&nameFilter=${
      valueOrEmpty(nameFilter)
        ? encodeURIComponent(valueOrEmpty(nameFilter))
        : "" || ""
    }&auctionId=${auctionId && auctionId > -1 ? auctionId : ""}&starred=${
      valueOrEmpty(starred) || ""
    }`;
    axios
      .get(url, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (cleanInfluencers) {
          dispatch({
            type: "GET_AVALIABLE_INFLUENCERS_CHATS_FULFILLED",
            payload: response.data,
          });
        } else {
          dispatch({
            type: "GET_MORE_AVALIABLE_INFLUENCERS_CHATS_FULFILLED",
            payload: response.data,
          });
        }
        if (callBack) {
          callBack(response.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_AVALIABLE_INFLUENCERS_CHATS_REJECTED",
          payload: err,
        });
        if (callBack) {
          callBack(false);
        }
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get chats",
          "system_notifications_failure_get_chats",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getNewChatsCount = () => {
  return (dispatch) => {
    axios
      .get(getNewChatsUrl(), {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "GET_NEW_CHATS_COUNT_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: "GET_NEW_CHATS_COUNT_REJECTED", payload: err });
        console.error(err);
      });
  };
};

export const getChatMessages = (
  id,
  campaignId,
  from,
  to,
  amount,
  offset,
  order,
  callback,
) => {
  return (dispatch) => {
    const toAdd =
      campaignId && campaignId !== -1 ? `?campaign_id=${campaignId}` : "";
    dispatch({ type: "SHOW_CHAT_LOADER", payload: true });
    axios
      .post(
        `${getBackendUri()}/moi/inbox_with_first_time/${id}${toAdd}`,
        { from, to, amount, offset, order },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        const array = response.data.messages;
        array.chat_id = id;
        array.campaign_id = campaignId;
        array.first_message_time = response.data.first_message_time;
        dispatch({ type: "GET_CHAT_MESSAGES_FULFILLED", payload: array });
        // dispatch(getAvailableChats());
        dispatch(getNewChatsCount());
        dispatch({ type: "SHOW_CHAT_LOADER", payload: false });
        if (callback) callback();
      })
      .catch((err) => {
        dispatch({ type: "SHOW_CHAT_LOADER", payload: false });
        dispatch({ type: "GET_CHAT_MESSAGES_REJECTED", payload: err });
        notificationOpts.title = "Failed";
        notificationOpts.message = "Failed to get chat messages";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const updateChatMessages = (
  id,
  campaignId,
  from,
  to,
  amount,
  offset,
  order,
  callback,
) => {
  return (dispatch) => {
    const toAdd =
      campaignId && campaignId !== -1 ? `?campaign_id=${campaignId}` : "";
    dispatch({ type: "UPDATE_CHAT_LOADER", payload: true });
    axios
      .post(
        `${getBackendUri()}/moi/inbox_with_first_time/${id}${toAdd}`,
        { from, to, amount, offset, order },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async (response) => {
        const array = response.data;
        array.chat_id = id;
        array.campaign_id = campaignId;
        array.unread_count = response.data.unread_count;
        await dispatch({
          type: "UPDATE_CHAT_MESSAGES_FULFILLED",
          payload: array,
        });
        dispatch({ type: "UPDATE_CHAT_LOADER", payload: false });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_CHAT_LOADER", payload: false });
        dispatch({ type: "UPDATE_CHAT_MESSAGES_REJECTED", payload: err });
        notificationOpts.title = "Failed";
        notificationOpts.message = "Failed to get chat messages";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

// export const sendNewMessage=(id,message,callback)=>{
//   return const(dispatch=)=>{
//     axios.put(inboxBaseUrl + "/"+id,{"message":message},{withCredentials : true,headers: {"X-MoiBackendAuthorization" : getSavedJwtToken() }}).then((response)=>{
//       dispatch({type:"SEND_NEW_MESSAGE_FULFILLED",payload:response.data});
//       callback();
//     }).catch((err)=>{
//       dispatch({type:"SEND_NEW_MESSAGE_REJECTED",payload:err})
//       notificationOpts["title"] = "Failed"
//       notificationOpts["message"] = "Failed to send message"
//       toast.error(notificationOpts["message"]);
//       console.error(err);
//     })
//   };
// }

export const sendNewMessage = (
  id,
  campaignId,
  message,
  type,
  eventGuid,
  replyToId,
  replyObjectType,
  replyToContent,
  replyMessageTime,
  callback,
) => {
  const currentSocket = getSock();
  currentSocket.send(
    JSON.stringify({
      message_type: "message",
      user_id: id,
      campaign_id: campaignId,
      message,
      inbox_type: type,
      event_guid: eventGuid,
      reply_to_id: replyToId,
      reply_object_type: replyObjectType,
      reply_to_content: replyToContent,
      reply_message_time: replyMessageTime,
    }),
  );
  if (callback) {
    callback();
  }
};

export const sendFileMessage = (
  chatId,
  campaignId,
  file,
  attachmentType,
  messageId,
  inboxType,
  callback,
) => {
  const formData = new FormData();
  formData.append("message_id", messageId);
  formData.append("attachment_type", attachmentType);
  formData.append("inbox_type", inboxType);
  formData.append("file", file);

  let url = `${getInboxBaseUrl()}/${chatId}/attachment`;
  if (campaignId && campaignId !== -1) {
    url += `?campaign_id=${campaignId}`;
  }
  axios
    .post(url, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-MoiBackendAuthorization": getSavedJwtToken(),
      },
    })
    .then((response) => {
      if (callback) {
        // eslint-disable-next-line no-underscore-dangle
        const resUrl = response._bodyText ? JSON.parse(response._bodyText) : "";
        callback(resUrl.url);
      }
    })
    .catch(() => {
      if (callback) callback(false);
    });
};

export const sendMultipleMessage = (ids, campaignId, message, callback) => {
  return () => {
    axios
      .post(
        `${getInboxBaseUrl()}/${campaignId}/multi_message`,
        { message, influencers: ids },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async () => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        notificationOpts.title = "Failed";
        notificationOpts.message = "Failed to get chat messages";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const sendFileMessageMultipleMessage = (
  ids,
  campaignId,
  file,
  attachmentType,
  callback,
) => {
  const formData = new FormData();
  formData.append("influencers", ids.join(","));
  formData.append("attachment_type", attachmentType);
  formData.append("file", file);

  const getUrl = () =>
    `${getInboxBaseUrl()}/${campaignId}/multi_message_with_attachment`;

  return () => {
    axios
      .post(getUrl(), formData, {
        withCredentials: true,
        headers: {
          "X-MoiBackendAuthorization": getSavedJwtToken(),
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        callback();
      })
      .catch((err) => {
        callback();
        console.log("Err");
        console.log(err);
      });
  };
};

export const retrySendMessage = (id, data, callback) => {
  const url = `${getInboxBaseUrl()}/${id}/resend_messages`;
  return (dispatch) => {
    axios
      .put(url, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        dispatch({ type: "RESEND_MESSAGE_FULLFILLED", payload: data });
        if (callback) callback(true);
      })
      .catch((err) => {
        dispatch({ type: "RESEND_MESSAGE_REJECTED", payload: err });
        if (callback) callback(false);
        console.log(err);
      });
  };
};

export const exitChat = (id, campaignId) => {
  return (dispatch) => {
    dispatch({ type: "EXIT_CHAT", payload: { id, campaign_id: campaignId } });
  };
};

export const changeStarChat = (
  userId,
  campaignId,
  starred,
  callback = () => {},
) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_CHAT_STARRED",
      payload: { userId, campaignId, star: !starred },
    });
    const url = `${getInboxBaseUrl()}/${userId}/star${
      campaignId && campaignId > -1 ? `?campaign_id=${campaignId}` : ""
    }`;
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios({
      url,
      method: starred ? "delete" : "post",
      data: {},
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
      .then(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        dispatch({
          type: "CHANGE_CHAT_STARRED",
          payload: { userId, campaignId, star: starred },
        });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(false);
      });
  };
};

export const getInfluencerChat = (userId, campaignId, callback = () => {}) => {
  if (!userId) {
    callback(false);
    return;
  }
  const url = `${getInfluencersChats()}/${userId}?auctionId=${
    campaignId && campaignId > 0 ? campaignId : ""
  }`;
  axios
    .get(url, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      callback(r.data);
    });
};

export const openNewChat = (id, callback = () => {}) => {
  axios
    .get(`${getBackendUri()}/moi/premium/open_chat/${id}`)
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};
