type Props = {
  title: any;
  children: any;
  visible: boolean;
  setVisible: any;
};

export const HumanzSideMenu = ({
  title,
  children,
  visible,
  setVisible,
}: Props) => {
  return visible ? (
    <div className="side-menu animated fadeInRight faster">
      <div>
        <div style={{ fontSize: 22 }}>{title}</div>
        <div
          className="side-menu-close-button"
          onClick={() => setVisible(false)}
        >
          <span className="moi-ximage" />
        </div>
        <div
          className="side-menu-spacer"
          style={{ marginTop: 10, marginBottom: 10 }}
        />
        <div className="side-menu-inner-content">{children}</div>
      </div>
    </div>
  ) : null;
};
