import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Dispatch } from "redux";
import { autocompleteSales } from "src/actions/affiliateActions";

// eslint-disable-next-line func-style
function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

interface Props {
  appId: string;
  handleChange: any;
  autocompleteSales: any;
  selectedValues: any;
  options: any;
  type: string;
  disabled?: boolean;
}

const SelectDebounced = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [results, setResults] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(
      props?.selectedValues && props?.selectedValues.length
        ? props?.selectedValues?.map((x: string) => {
            return typeof x === "object" ? x : { value: x, label: x };
          })
        : [],
    );
  }, [props.selectedValues]);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        props.autocompleteSales(
          props.type,
          props.appId,
          searchTerm,
          (res: any) => {
            setIsSearching(false);
            if (res && res.length) {
              setResults(
                res.map((r: any) => {
                  return { label: r.result, value: r.result };
                }),
              );
            } else {
              setResults([]);
            }
          },
          () => {
            setIsSearching(false);
          },
        );
      }
    },

    [debouncedSearchTerm], // Only call effect if debounced search term changes
  );

  return (
    <Select
      removeSelected={true}
      multi={true}
      style={{ marginTop: -6 }}
      className="no-overflow"
      options={results}
      isLoading={isSearching}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onInputChange={(inputValue: string) => {
        if (inputValue && inputValue.length) {
          setIsSearching(true);
          setSearchTerm(inputValue);
        } else {
          setIsSearching(false);
          setSearchTerm(null);
        }
      }}
      onChange={(v: any) => {
        props.handleChange(v);
      }}
      simpleValue={false}
      clearable={true}
      onSelectResetsInput={true}
      placeholder="Type to Search..."
      value={selected}
      searchPromptText={"Searching..."}
      noResultsText={isSearching ? "Searching..." : "No items found"}
      disabled={props.disabled}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch | any) => {
  return {
    autocompleteSales: (
      search_type: string,
      app_id: number,
      query: string,
      successCallBack?: any,
      failCallBack?: any,
    ) => {
      dispatch(
        autocompleteSales(
          search_type,
          app_id,
          query,
          successCallBack,
          failCallBack,
        ),
      );
    },
  };
};
// eslint-disable-next-line import/no-default-export
export default connect(null, mapDispatchToProps)(SelectDebounced);
