import { Box, Text } from "@chakra-ui/react";
import { CSSProperties, ReactNode, useState } from "react";

// type Props = {
//   header: string;
//   value: string;
//   onChange: (newValue: string) => void;
//   onBlur?: () => void;
//   placeholder: string;
//   description?: string;
//   required?: true;
//   width?: string;
//   height?: string;
//   disabled?: boolean;
//   defaultValue?: string;
//   type?: string;
// };

interface IndicatorProps {
  children: ReactNode;
  style?: CSSProperties;
  header?: string | any;
  description?: string;
  optional?: boolean;
  disabled?: boolean;
  full?: boolean;
}

const Indicator = ({
  children,
  style,
  header,
  description,
  optional,
  disabled,
  full,
}: IndicatorProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", opacity: disabled ? 0.5 : 1 }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ ...style }}
        className="indicator"
      >
        <Box
          sx={{
            background: isFocused ? "#249FF0" : "#EBEBEB",
            height: "auto",
            marginRight: "8px",
            width: "1px",
          }}
        ></Box>
        <Box style={full ? { flex: 1 } : null}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "5px",
            }}
          >
            <Box>
              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  width: "auto",
                  padding: 0,
                  color: "#222325",
                }}
              >
                {header}
                {optional && (
                  <Text
                    as="span"
                    sx={{
                      fontSize: "12px",
                      marginLeft: "8px",
                      fontWeight: 300,
                      color: "#AAAAAA",
                      fontStyle: "italic",
                    }}
                  >
                    Optional
                  </Text>
                )}
              </Text>
            </Box>
            <Box>
              {description && (
                <Text
                  sx={{
                    fontSize: "12px",
                    marginLeft: "8px",
                    fontWeight: 400,
                    color: "#AAAAAA",
                  }}
                >
                  {description}
                </Text>
              )}
            </Box>
          </Box>
          {children}
        </Box>
      </Box>
      {/* if we want to add an error massage replace false with "error" */}
      {false && !isFocused && (
        <div>
          <i className="error-mark" />
          <span className="error-text">
            Whoops! This field can not be empty
          </span>
        </div>
      )}
    </>
  );
};

export default Indicator;
