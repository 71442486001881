import React, { Component } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "rc-dialog";

class BrandReportDescDialogInner extends Component {
  openCreate = () => {
    this.props.navigate("/edit-brand-report");
  };

  render() {
    return (
      <Dialog
        className="payments-dialog"
        visible={true}
        style={{}}
        animation="slide-fade"
        onClose={this.props.handleClose}
        maskAnimation="fade"
      >
        <div style={{ marginTop: 10, marginBottom: -20 }}>
          {this.renderUpgradeToPremium()}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            background: "white",
            borderTop: "1px solid #aaa",
            marginTop: 20,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <button
            onClick={this.openCreate}
            className="btn btn-primary"
            style={{
              background: "rgb(37, 189, 126)",
              marginLeft: 10,
              border: "none",
              padding: "10px 40px",
              fontSize: 20,
              height: 45,
            }}
          >
            Create your first brand report now
          </button>
        </div>
      </Dialog>
    );
  }

  renderUpgradeToPremium() {
    const { currency } = this.props.user.userDetails;
    const price = this.props.user.userDetails.sidebar_data.brand_report_price;
    return (
      <div className="create-tab-content">
        <div className="payments-method-section">
          <div className="campaigns-title">
            <span style={{ color: "var(--main-baby-blue-color)" }}>
              Subscribe to{" "}
              <strong
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                brand scouting reports!
              </strong>
            </span>
          </div>
          <div style={{ fontSize: 18, fontWeight: 300, marginTop: 15 }}>
            Brand new feature now available on Humanz from today! Track your
            brand or your competitors’ Instagram pages.
          </div>
        </div>
        <div className="upgrade-pre-box">
          <div>
            <strong>Get an overview of new followers</strong>
            <div style={{ marginTop: 15 }}>
              <span>
                Get information about the page’s new followers; their
                demographic profiles, location, interests and trust score.
              </span>
            </div>
          </div>
          <div>
            <i className="fa fa-check" />
          </div>
        </div>
        <div className="upgrade-pre-box">
          <div>
            <strong>
              Review the most influential followers for this account{" "}
            </strong>
            <div style={{ marginTop: 15 }}>
              <span>
                See new influencers who started following the account this
                month, ranked by audience size.
              </span>
            </div>
          </div>
          <div>
            <i className="fa fa-check" />
          </div>
        </div>
        <div className="upgrade-pre-box" style={{ marginTop: 25 }}>
          <div>
            <strong>
              Explore what influencers who follow this account are talking about
            </strong>
            <div style={{ marginTop: 15 }}>
              <span>
                Discover the top keywords used by these influencers and the
                associated content.
              </span>
            </div>
          </div>
          <div>
            <i className="fa fa-check" />
          </div>
        </div>

        <div
          style={{
            marginTop: 25,
            background: "var(--main-baby-blue-color)",
            borderRadius: 6,
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 40px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: 30, fontWeight: 300 }}>
              {window.translate("", `money_symbol_${currency}`)}
              {price}
            </div>
            <div style={{ fontSize: 18 }}>PRICE PER MONTH</div>
          </div>
        </div>
        {/* <div style={{ color: 'var(--main-baby-blue-color)', marginTop: 25, fontSize: 13 }}>
                    <div>* The price not include VAT </div>
                </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const BrandReportDescDialogConnected = connect(
  mapStateToProps,
  null,
)(BrandReportDescDialogInner);

export const BrandReportDescDialog = (props) => {
  const navigate = useNavigate();

  return <BrandReportDescDialogConnected {...props} navigate={navigate} />;
};
