interface OwnProps {
  postLink: string;
  height?: number;
  width?: number;
}

export const InstagramPostEmbedJs = (props: OwnProps) => {
  return (
    <iframe
      src={`${props.postLink}embed/captioned`}
      width={props.width || 230}
      height={props.width || 420}
      frameBorder="0"
      scrolling="no"
    />
  );
};
