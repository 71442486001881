import { CSSProperties, ReactNode } from "react";

type Props = { children: ReactNode; style?: CSSProperties };

const FormCard = ({ children, style }: Props) => {
  return (
    <div
      style={{
        marginTop: "40px",
        padding: "30px",
        marginBottom: "50px",
        background: "white",
        boxShadow:
          "0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 11px 25px 0px rgba(0, 0, 0, 0.06), 0px 45px 45px 0px rgba(0, 0, 0, 0.05), 0px 101px 61px 0px rgba(0, 0, 0, 0.03), 0px 180px 72px 0px rgba(0, 0, 0, 0.01), 0px 281px 79px 0px rgba(0, 0, 0, 0.00)",
        borderRadius: "24px",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default FormCard;
