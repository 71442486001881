import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import Tooltip from "rc-tooltip";
// import { Input } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  creatTodoItem,
  deleteTodoItem,
  editReorder,
  editTodoItem,
  saveToTemplate,
  setTodoItemCompleted,
} from "src/api/actions/auctionActions";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import AddArrow from "../../UI/AddArrow";
import AllDoneGoodJob from "../../UI/AllDoneGoodJob";
import AddTask from "./AddTask/AddTask";
import AddTaskButton from "./AddTask/AddTaskButton";
import Tasks from "./Tasks";

const CreateTodoList = (props: any) => {
  const [todoState, setTodoState] = useState("ToDo");
  const [typeTasksFilter, setTypeTasksFilter] = useState("All Types");
  const [showModal, setShowModal] = useState(false);
  const [showAddTask, setShowAdd] = useState(false);
  const [templateSaved, setTemplateSaved] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const params = useParams();
  const campaignId = params?.id;

  const ToDoActions = [
    {
      action: () => {
        setTodoState("All");
      },
      text: "All",
    },
    {
      action: () => {
        setTodoState("ToDo");
      },
      text: "ToDo",
    },
    {
      action: () => {
        setTodoState("Done");
      },
      text: "Done",
    },
  ];
  const TypesActions = [
    {
      action: () => {
        setTypeTasksFilter("All Types");
      },
      text: "All Types",
    },
    {
      action: () => {
        setTypeTasksFilter("General");
      },
      text: "General",
    },
    {
      action: () => {
        setTypeTasksFilter("Brief");
      },
      text: "Brief",
    },
    {
      action: () => {
        setTypeTasksFilter("List");
      },
      text: "List",
    },
    {
      action: () => {
        setTypeTasksFilter("Manage");
      },
      text: "Manage",
    },

    {
      action: () => {
        setTypeTasksFilter("ROI");
      },
      text: "ROI",
    },
  ];
  const handleAddTask = () => {
    setShowAdd(true);
  };

  const handleSaveAddTask = (taskData: AuctionToDoItem) => {
    if (campaignId) {
      const taskDataToSend = { ...taskData };
      if (taskDataToSend.due_date) {
        taskDataToSend.due_date = new Date(
          taskDataToSend.due_date,
        ).toISOString();
      }

      creatTodoItem(Number(campaignId), taskData)
        .then((res) => {
          const newTask = res.data;
          const updatedTasks = props.tasks.map((task: AuctionToDoItem) => {
            // Increment the order of tasks with order greater than or equal to the new task's order
            if (task.order >= newTask.order) {
              return { ...task, order: task.order + 1 };
            }
            return task;
          });
          props.setTasks([newTask, ...updatedTasks]);
        })
        .catch(() => {});
    }
  };

  // implement remove from backend
  const handleDeleteTask = (taskId: number) => {
    if (campaignId) {
      deleteTodoItem(Number(campaignId), taskId)
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleCheckboxChange = async (taskId: number) => {
    const updatedTasks = props.tasks.map((task: AuctionToDoItem) => {
      if (task.id === taskId) {
        setTodoItemCompleted(Number(campaignId), taskId, {
          completed: !task.completed,
        });
        return { ...task, completed: !task.completed };
      }
      return task;
    });
    props.setTasks(updatedTasks);
    // props.setStep("create-todo");
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const resetTasks = (tasks: AuctionToDoItem[]) => {
    const tasksForTemplate = tasks.map((task: AuctionToDoItem) => {
      // const { due_date: string, ...rest } = task;
      const updatedTask: AuctionToDoItem = {
        ...task,
        completed: false,
        due_date: null,
      };
      return updatedTask;
    });
    return tasksForTemplate;
  };
  const handleAddTemplate = (templatName: string) => {
    const taskToTemplate = resetTasks(props.tasks);
    const template = {
      title: templatName,
      description: "",
      is_global: true,
      tasks: taskToTemplate,
    };
    saveToTemplate(template)
      .then(() => {
        toast.success("Template was created successfully");
      })
      .catch(() => {
        toast.error("Error couldn't add template");
      });
  };

  const filteredTasks = useMemo(() => {
    if (todoState === "All")
      if (typeTasksFilter === "All Types") {
        return props.tasks;
      } else {
        return props.tasks.filter(
          (task: AuctionToDoItem) => task.tag === typeTasksFilter,
        );
      }

    const completed = todoState === "Done";
    if (typeTasksFilter === "All Types") {
      return props.tasks.filter(
        (task: AuctionToDoItem) => task.completed === completed,
      );
    }

    return props.tasks.filter(
      (task: AuctionToDoItem) =>
        task.tag === typeTasksFilter && task.completed === completed,
    );
  }, [props.tasks, typeTasksFilter, todoState]);

  const handleNext = () => {
    handleAddTemplate(templateName);
    setShowModal(false);
    setTemplateSaved(true);
    // show a message template added
  };
  const handleTemplateNameChange = (event: any) => {
    setTemplateName(event.target.value);
  };

  const handleSaveAsTemplate = () => {
    if (props.tasks.length > 0) setShowModal(true);
  };

  const handleEditTask = (taskId: number, taskData: AuctionToDoItem) => {
    editTodoItem(Number(campaignId), taskId, taskData);
    const updatedTasks = props.tasks.map((task: AuctionToDoItem) => {
      if (task.id === taskId) {
        // If the task id matches, update the taskData
        return taskData;
      }
      return task; // For other tasks, return as-is
    });
    props.setTasks(updatedTasks);
  };

  useEffect(() => {
    const completeTasks = props.tasks.filter(
      (task: AuctionToDoItem) => task.completed,
    );
    props.handleUpdateTodoButton(completeTasks, props.tasks);
  }, [props.tasks]);

  const OnDragEnd = (result: { destination: any; source: any }) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    const newFilteredTasks = Array.from(filteredTasks) as AuctionToDoItem[];
    const sourceTask = filteredTasks[source.index];
    const destTask = filteredTasks[destination.index];

    const sourceIndex = newFilteredTasks.findIndex(
      (t) => t.id === sourceTask.id,
    );
    const destIndex = newFilteredTasks.findIndex((t) => t.id === destTask.id);
    const originalIndexes = newFilteredTasks.map((x) => x.order);
    if (sourceIndex < destIndex) {
      let fromIndex = sourceIndex + 1;

      while (fromIndex <= destIndex) {
        newFilteredTasks[fromIndex].order = originalIndexes[fromIndex - 1];
        fromIndex += 1;
      }
    } else {
      let fromIndex = destIndex;
      while (fromIndex < sourceIndex) {
        newFilteredTasks[fromIndex].order = originalIndexes[fromIndex + 1];
        fromIndex += 1;
      }
    }
    newFilteredTasks[sourceIndex].order = originalIndexes[destIndex];

    const filteredTaskIds = filteredTasks.map((x: any) => x.id);
    let updatedTasks = Array.from(props.tasks) as AuctionToDoItem[];
    updatedTasks = updatedTasks.map((t) => {
      if (filteredTaskIds.includes(t.id)) {
        return newFilteredTasks.filter((x) => x.id === t.id)[0];
      }

      return t;
    });
    updatedTasks.sort((a, b) => b.order - a.order);
    props.setTasks(updatedTasks);

    const reorderTasks = updatedTasks.map((task) => {
      return {
        id: task.id,
        order: task.order,
      };
    });

    editReorder(Number(campaignId), reorderTasks);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <HumanzActionsMenu
            buttontype="button"
            actions={ToDoActions}
            // buttontext={todoState}
            buttontext={<span style={{ color: "#249FF0" }}>{todoState}</span>}
          />
          <HumanzActionsMenu
            buttontype="button"
            actions={TypesActions}
            buttontext={
              <span style={{ color: "#808080" }}>{typeTasksFilter}</span>
            }
          />
        </Box>

        <Button
          onClick={handleSaveAsTemplate}
          variant="unstyled"
          marginTop="5px"
        >
          <Tooltip trigger={["hover"]} overlay={"Save as template"}>
            {!templateSaved || props.tasks.length === 0 ? (
              <i className="fa-thin fa-circle-bookmark fa-2xl"></i>
            ) : (
              props.tasks.length > 0 && (
                <i
                  className="fa-duotone fa-circle-bookmark fa-2xl"
                  style={{ color: "#58a0fe" }}
                ></i>
              )
            )}
          </Tooltip>
        </Button>

        {showModal && props.tasks.length > 0 && (
          <Modal
            size="sm"
            isOpen={true}
            onClose={() => {
              setShowModal(false);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Template Name</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                display="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
              >
                <Input
                  onChange={handleTemplateNameChange}
                  sx={{
                    border: "none",
                    padding: "0",
                    borderBottom: "1px solid gray",
                    borderRadius: "0",
                    "&:focus": {
                      boxShadow: "none",
                    },
                  }}
                  placeholder={
                    templateName === "" ? "Template Name" : templateName
                  }
                />
                <Button
                  mt={4}
                  onClick={handleNext}
                  isDisabled={templateName?.trim() === ""}
                >
                  Next
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </Box>

      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EBEBEB",
          marginTop: "5px",
          marginBottom: "10px",
        }}
      ></div>

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <AddTaskButton onClick={handleAddTask} />
        {showAddTask && (
          <AddTask
            setShowAdd={setShowAdd}
            tasks={props.tasks}
            setTasks={props.setTasks}
            callBack={handleSaveAddTask}
          />
        )}

        <DragDropContext onDragEnd={OnDragEnd}>
          <Tasks
            displayTasks={filteredTasks}
            setTasks={props.setTasks}
            deleteTask={handleDeleteTask}
            setCompleted={handleCheckboxChange}
            tasks={props.tasks}
            campaignId={campaignId}
            dragDropEnabled={true}
            handleEditTask={handleEditTask}
            todoState={todoState}
            disableCheckBox={false}
            maxHeight={"calc(100vh - 270px)"}
          />
        </DragDropContext>

        <AddArrow showAddTask={showAddTask} tasksLength={props.tasks.length} />
        <AllDoneGoodJob
          showAddTask={showAddTask}
          todoState={todoState}
          tasks={props.tasks}
        />
      </Box>
    </>
  );
};

export default CreateTodoList;
