// TODO - lint
/* eslint-disable import/no-default-export, react/no-direct-mutation-state, react/no-direct-mutation-state, no-return-assign, no-underscore-dangle */
import React from "react";
import uuidv4 from "uuid/v4";
import { attachContentToInfInList } from "../../../actions/premiumActions";
import { getMediaId } from "../../../actions/utilsActions";
import { MessageDialog } from "../dialogs/MessageDialog";

export default class AddPostDialog extends React.PureComponent {
  state = { saving: false, error: false, success: false };

  _onSave = () => {
    const post = this.postInput.value.trim();
    if (!post || this.state.saving) return;

    this.setState({ saving: true, error: false, success: false });

    getMediaId(post, null, (data) => {
      if (data) {
        let { posts } = this.props.influencer;
        const id = uuidv4();

        posts.push({ ...data, id });
        posts = posts.map((p, idx) => {
          return { ...p, order: idx, is_deleted: false };
        });

        attachContentToInfInList(
          this.props.listId,
          this.props.influencer.id,
          posts,
          (success) => {
            if (success) {
              this.state.success = true;
              this.postInput.value = "";
            } else {
              const index = this.props.influencer.posts.findIndex(
                (x) => x.id === id,
              );
              if (index > -1) {
                this.props.influencer.posts.splice(index, 1);
              }
              this.state.error = true;
            }
            this.setState({ saving: false });
          },
        );
      } else {
        this.failCallback();
      }
    });
  };

  failCallback() {
    this.setState({ saving: false, error: true });
  }

  render() {
    return (
      <MessageDialog
        handleClose={this.props.handleClose}
        icon="far fa-portrait"
      >
        <div style={{ textAlign: "center", padding: 20, paddingTop: 5 }}>
          <h3 style={{ marginBottom: 20 }}>ADD REFERENCE POST</h3>
          <div
            className={this.state.saving ? "disabled" : ""}
            style={{ marginTop: 10, marginBottom: 15 }}
          >
            <strong className="label-title">Post URL</strong>
            <div>
              <input
                dir="auto"
                ref={(r) => (this.postInput = r)}
                className="input-line"
              />
            </div>
          </div>
          <div style={{ margin: 10 }}>
            {this.state.success && (
              <strong style={{ color: "#22b573", fontSize: 16 }}>
                Success!
              </strong>
            )}
            {this.state.error && (
              <label style={{ color: "#f15b23" }}>
                Something went wrong... Please check the link and try again
              </label>
            )}
          </div>
          <button className="btn btn-primary" onClick={this._onSave}>
            Save
          </button>
        </div>
      </MessageDialog>
    );
  }
}
