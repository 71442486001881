import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { ReactNode } from "react";
// import ViewTasksModal from "./ViewTasksModal";

interface CustomAccordionProps {
  children: ReactNode;
  header: string;
  avatar?: string;
}

const InfluencerAttachAccordion = ({
  children,
  header,
  avatar,
}: CustomAccordionProps) => {
  return (
    // </Accordion>
    <AccordionItem
      style={{
        overflow: "visible",
        boxShadow: "0px 0px 17px 0px rgba(0, 0, 0, 0.10)",
        background: "#FAFAFA",
        borderRadius: 0,
        margin: 0,
      }}
      onClick={(event) => {
        const accordionItem = event.currentTarget;
        setTimeout(() => {
          accordionItem.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 400);
      }}
    >
      {({ isExpanded }) => (
        <div style={{ color: "#808080" }}>
          <>
            <AccordionButton
              sx={{
                display: "flex",
                borderRadius: 0,
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "10px" }}>
                  <i
                    className={`fa-regular ${
                      isExpanded ? "fa-chevron-up" : "fa-chevron-down"
                    } fa-lg`}
                  />
                </div>
                <img
                  className="avatar"
                  style={{
                    height: "30px",
                    width: "30px",
                    margin: 0,
                    marginRight: "10px",
                  }}
                  src={avatar}
                />
                <span
                  style={{
                    fontSize: "14px",
                    paddingTop: "2px",
                    color: "#333",
                    fontWeight: 500,
                  }}
                >
                  {header}
                </span>
              </div>
            </AccordionButton>
          </>
          <AccordionPanel
            sx={{
              background: "#FAFAFA",
              color: "black",
            }}
          >
            {children}
          </AccordionPanel>
        </div>
      )}
    </AccordionItem>
  );
};

export default InfluencerAttachAccordion;
