import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  usePrevious,
} from "@chakra-ui/react";
import produce from "immer";
import _ from "lodash";
import moment from "moment";
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import {
  ArrayElement,
  assertUnreachable,
  CallbackOfType,
} from "src/declarations/UtilityTypes";
import { useBoolean } from "src/hooks/coreHooks";
import { Carousel } from "src/ui/Carousel";
import { FileTypes, FileUpload, UploadNumber } from "src/ui/FileUpload";
import { Form } from "src/ui/Form";
import { FormFooterButtonGroup } from "src/ui/FormFooterButtonGroup";
import { RatioMedia } from "src/ui/RatioMedia";
import uuidv4 from "uuid/v4";
import Indicator from "../../CreateCampaignSection/FormUI/Indicator";
import InputDatePicker from "../../CreateCampaignSection/FormUI/InputDatePicker";
import { StoryData } from "./AttachLinksHelpers";

const ratioOfStories = 9 / 16;
const storiesPerRow = 4;
const paddingBetweenStories = 4;

const widthOfStories = `${
  (196 - paddingBetweenStories * (storiesPerRow - 1)) / storiesPerRow / 4
}rem`; // 5 in row
// const widthOfStories = 44; // 4 in row

export enum StoryType {
  manual,
  linked,
}

export enum TaskStatuses {
  inProgress,
  completed,
}

type TaskStory = {
  notDeletable: boolean;
} & (
  | {
      type: StoryType.manual;
      id: string;
      manualData: StoryData;
    }
  | {
      type: StoryType.linked;
      linkedStoryId: string;
    }
);

type Props = {
  selectedStories: {
    id: string;
    data: StoryData;
  }[];
  linked?: {
    range: {
      since: moment.Moment;
      until: moment.Moment;
      caption: string | null;
    };
    onRangeChange: (newRange: {
      since: moment.Moment;
      until: moment.Moment;
      caption: string | null;
    }) => void;
    paginatedStories: {
      data: {
        id: string;
        data: StoryData;
      }[];
      hasMore: boolean;
      fetchMore: () => void;
      isFetching: boolean;
      isRefreshing: boolean;
    };
    onRequestRefresh: () => void;
  };
  data: {
    tasks: {
      id: string;
      description?: string;
      status: TaskStatuses;
      due_date?: string;
      stories: TaskStory[];
    }[];
  };
  onDataChange: (newData: Props["data"]) => void;
};

const handleStartUpload = () => {
  document.getElementById("save-stories-attach")?.classList.add("disabled");
};

const handleFinishUpload = () => {
  document.getElementById("save-stories-attach")?.classList.remove("disabled");
};

const StoryItem = ({
  data,
  selected,
  modify,
  soundVideo,
}: {
  data: StoryData;
  modify?: {
    overlayButtons: boolean;
    onDataChange: (newData: StoryData) => void;
    onDelete?: () => void;
  };
  selected?: {
    value: boolean;
    toggle?: () => void;
  };
  soundVideo?: boolean;
}) => {
  const [disabled, setDisabled] = React.useState(false);
  const handleCheckClick = useCallback<CallbackOfType<typeof Text, "onClick">>(
    (e) => {
      e.stopPropagation();
      if (selected?.toggle) {
        selected.toggle();
      }
    },
    [selected],
  );
  const [editModeEnabled, setEditModeEnabled] = useBoolean(false);
  const handleEditClick = useCallback<CallbackOfType<typeof Text, "onClick">>(
    (e) => {
      e.stopPropagation();
      setEditModeEnabled.on();
    },
    [setEditModeEnabled],
  );
  const {
    isOpen: isFullScreenOpen,
    onOpen: onFullScreenOpen,
    onClose: onFullScreenClose,
  } = useDisclosure();
  const handleFullScreenClick = useCallback<
    CallbackOfType<typeof Text, "onClick">
  >(
    (e) => {
      e.stopPropagation();
      onFullScreenOpen();
    },
    [onFullScreenOpen],
  );
  const [localData, setLocalData] = useState(data);
  const setData = useCallback<(newData: typeof data) => void>(
    (newData) => {
      if (modify) {
        setLocalData(newData);
        if (!modify.overlayButtons) {
          modify.onDataChange(newData);
        }
      }
    },
    [modify],
  );
  const handleCancelClick = useCallback<
    CallbackOfType<typeof Button, "onClick">
  >(() => {
    setLocalData(data);
    setEditModeEnabled.off();
  }, [setEditModeEnabled, data]);
  const handleFormSubmit = useCallback<
    CallbackOfType<typeof Form, "onSubmit">
  >(() => {
    setEditModeEnabled.off();
    if (modify) {
      modify.onDataChange(localData);
    }
  }, [localData, modify, setEditModeEnabled]);
  const handleViewsChange = useCallback<
    CallbackOfType<typeof Input, "onChange">
  >(
    (e) => {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) {
        return;
      }
      const numValue = Math.floor(Number(value));
      if (numValue < 0) {
        return;
      }

      setData(
        produce(localData, (draftData) => {
          draftData.views = numValue;
        }),
      );
    },
    [localData, setData],
  );
  const handleEngagementsChange = useCallback<
    CallbackOfType<typeof Input, "onChange">
  >(
    (e) => {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) {
        return;
      }
      const numValue = Math.floor(Number(value));
      if (numValue < 0) {
        return;
      }

      setData(
        produce(localData, (draftData) => {
          draftData.engagements = numValue;
        }),
      );
    },
    [localData, setData],
  );
  const handleReachChange = useCallback<
    CallbackOfType<typeof Input, "onChange">
  >(
    (e) => {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) {
        return;
      }
      const numValue = Math.floor(Number(value));
      if (numValue < 0) {
        return;
      }

      setData(
        produce(localData, (draftData) => {
          draftData.reach = numValue;
        }),
      );
    },
    [localData, setData],
  );
  const handleTapBackChange = useCallback<
    CallbackOfType<typeof Input, "onChange">
  >(
    (e) => {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) {
        return;
      }
      const numValue = Math.floor(Number(value));
      if (numValue < 0) {
        return;
      }

      setData(
        produce(localData, (draftData) => {
          draftData.taps_back = numValue;
        }),
      );
    },
    [localData, setData],
  );
  const handleTapForwardChange = useCallback<
    CallbackOfType<typeof Input, "onChange">
  >(
    (e) => {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) {
        return;
      }
      const numValue = Math.floor(Number(value));
      if (numValue < 0) {
        return;
      }

      setData(
        produce(localData, (draftData) => {
          draftData.taps_forward = numValue;
        }),
      );
    },
    [localData, setData],
  );
  const handleSwipesForwardChange = useCallback<
    CallbackOfType<typeof Input, "onChange">
  >(
    (e) => {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) {
        return;
      }
      const numValue = Math.floor(Number(value));
      if (numValue < 0) {
        return;
      }

      setData(
        produce(localData, (draftData) => {
          draftData.swipes_forward = numValue;
        }),
      );
    },
    [localData, setData],
  );

  const handleUploadDateChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event: any) => {
        const date = event.target.value;
        setData(
          produce(localData, (draftData) => {
            draftData.media_creation_time = moment(date)
              .utc(true)
              .hours(12)
              .toISOString();
          }),
        );
      },
      [localData, setData],
    );

  const handleFileUploaded = useCallback<
    CallbackOfType<typeof FileUpload, "onFileUploaded">
  >(
    (mediaUrl, mediaType) => {
      if (mediaType === undefined || mediaType === FileTypes.document) {
        toast.error("Failed to upload, bad file type");
        return;
      }
      setData(
        produce(localData, (draftData) => {
          draftData.mediaType = {
            [FileTypes.image]: "image",
            [FileTypes.video]: "video",
          }[mediaType] as "image" | "video";
          draftData.mediaUrl = mediaUrl;
        }),
      );
      handleFinishUpload();
    },
    [localData, setData],
  );

  const handleDeleteClick = () => {
    setDisabled(true);
    if (modify?.onDelete) modify.onDelete();
  };

  const isEditMode = modify?.overlayButtons === false || editModeEnabled;

  const image = (
    <Box w={isEditMode ? "100%" : undefined}>
      {isEditMode ? (
        <FileUpload
          // style={{ height: "50%" }}
          fileType={[FileTypes.image, FileTypes.video]}
          preview={
            <RatioMedia
              type={localData.mediaType}
              w="100%"
              // h="50%"
              ratio={ratioOfStories}
              src={localData.mediaUrl}
            />
          }
          onFileUploaded={handleFileUploaded}
          onStartUpload={handleStartUpload}
        />
      ) : (
        <RatioMedia
          type={localData.mediaType}
          w={widthOfStories}
          ratio={ratioOfStories}
          src={localData.mediaUrl}
          soundVideo={soundVideo}
        />
      )}
    </Box>
  );

  const needToEnterViews =
    !isEditMode && modify?.overlayButtons && !localData.views;

  let borderColor = undefined as string | undefined;
  let borderWidth = "0";

  if (isEditMode) {
    borderColor = "gray.300";
    borderWidth = "thin";
  } else if (needToEnterViews) {
    borderColor = "red.600";
    borderWidth = "medium";
  }

  return (
    <Box
      display="inline-flex"
      borderRadius="xl"
      // overflow="hidden"
      pos="relative"
      borderStyle="solid"
      borderColor={borderColor}
      borderWidth={borderWidth}
      onClick={selected?.toggle ? handleCheckClick : undefined}
      cursor={selected?.toggle ? "pointer" : undefined}
    >
      {isEditMode ? (
        <Box>
          <AspectRatio w={widthOfStories} ratio={ratioOfStories}>
            <Box />
          </AspectRatio>
          <Form onSubmit={handleFormSubmit}>
            <Flex
              position="absolute"
              inset={0}
              flexDir="column"
              // p="2"
              alignItems="stretch"
            >
              <Flex flexDir="column" p="2">
                <Flex alignItems="baseline">
                  <Text fontSize="sm">Views:</Text>
                  <Spacer />
                  <Input
                    variant="flushed"
                    value={localData.views ?? 0}
                    onChange={handleViewsChange}
                    h="initial"
                    w="50%"
                    textAlign="end"
                  />
                </Flex>
                <Flex alignItems="baseline">
                  <Text fontSize="sm">Engagements:</Text>
                  <Spacer />
                  <Input
                    variant="flushed"
                    value={localData.engagements ?? 0}
                    onChange={handleEngagementsChange}
                    h="initial"
                    w="50%"
                    textAlign="end"
                  />
                </Flex>
                <Accordion
                  allowToggle
                  defaultIndex={
                    localData.swipes_forward ||
                    localData.taps_forward ||
                    localData.taps_back ||
                    localData.reach ||
                    localData.media_creation_time
                      ? [0]
                      : []
                  }
                  reduceMotion
                >
                  <AccordionItem
                    sx={{
                      borderRadius: "12px",
                      overflow: "none",
                      boxShadow: "none",
                      background: "none",
                    }}
                    style={{ marginBottom: 0 }}
                  >
                    {({ isExpanded }) => (
                      <div>
                        <AccordionButton
                          sx={{
                            display: "flex",
                            justifyContent: "space-between", // Align elements to the left and right
                            borderRadius: "12px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ marginRight: "10px" }}>
                              {isExpanded ? (
                                <i className="fa-regular fa-arrow-down"></i>
                              ) : (
                                <i className="fa-regular fa-arrow-up"></i>
                              )}
                            </div>
                            <span
                              style={{ fontSize: "12px", paddingTop: "2px" }}
                            >
                              Advanced
                            </span>
                          </div>
                        </AccordionButton>

                        <AccordionPanel
                          sx={{
                            background: "#FAFAFA",
                            color: "black",
                            padding: 0,
                          }}
                        >
                          <Flex alignItems="baseline">
                            <Text fontSize="sm">Reach:</Text>
                            <Spacer />
                            <Input
                              variant="flushed"
                              value={localData.reach ?? 0}
                              onChange={handleReachChange}
                              h="initial"
                              w="50%"
                              textAlign="end"
                            />
                          </Flex>
                          <Flex alignItems="baseline">
                            <Text fontSize="sm" whiteSpace="nowrap">
                              Tap Back:
                            </Text>
                            <Spacer />
                            <Input
                              variant="flushed"
                              value={localData.taps_back ?? 0}
                              onChange={handleTapBackChange}
                              h="initial"
                              w="50%"
                              textAlign="end"
                            />
                          </Flex>
                          <Flex alignItems="baseline">
                            <Text fontSize="sm" whiteSpace="nowrap">
                              Tap Forward:
                            </Text>
                            <Spacer />
                            <Input
                              variant="flushed"
                              value={localData.taps_forward ?? 0}
                              onChange={handleTapForwardChange}
                              h="initial"
                              w="40%"
                              textAlign="end"
                            />
                          </Flex>
                          <Flex alignItems="baseline">
                            <Text fontSize="sm" whiteSpace="nowrap">
                              Swipes Forward:
                            </Text>
                            <Spacer />
                            <Input
                              variant="flushed"
                              value={localData.swipes_forward ?? 0}
                              onChange={handleSwipesForwardChange}
                              h="initial"
                              w="40%"
                              textAlign="end"
                            />
                          </Flex>
                          <Flex alignItems="baseline">
                            <Text fontSize="sm" whiteSpace="nowrap">
                              Creation:
                            </Text>
                            <Spacer />
                            <InputDatePicker
                              onChange={handleUploadDateChange}
                              value={
                                localData.media_creation_time
                                  ? moment(
                                      localData.media_creation_time,
                                    ).format(window.time.short_date)
                                  : null
                              }
                            />
                          </Flex>
                        </AccordionPanel>
                      </div>
                    )}
                  </AccordionItem>
                </Accordion>
              </Flex>
              <Flex
                // py="1"
                flex="1"
                justifyContent="center"
                alignItems="flex-end" // Change this line
                style={{ overflow: "hidden" }}
              >
                {image}
              </Flex>

              {(modify?.overlayButtons || modify?.onDelete) && (
                <Flex
                  flexDirection="row"
                  className={disabled ? "disabled" : ""}
                >
                  {modify?.overlayButtons ? (
                    <ButtonGroup isAttached style={{ flex: 1 }}>
                      <IconButton
                        sx={{ borderRadius: "0px 0px 6px 6px" }}
                        flex="1"
                        aria-label="Save"
                        type="submit"
                        icon={<i className={"fa fa-check"} />}
                        h="5"
                      />
                      <IconButton
                        flex="1"
                        aria-label="Cancel"
                        type="button"
                        colorScheme="gray"
                        icon={<i className={"fa fa-undo"} />}
                        onClick={handleCancelClick}
                        h="5"
                      />
                      {modify?.onDelete && (
                        <IconButton
                          flex="1"
                          sx={{ borderRadius: "0px 0px 6px 6px" }}
                          aria-label="Delete"
                          type="button"
                          colorScheme="red"
                          icon={<i className={"far fa-trash"} />}
                          onClick={handleDeleteClick}
                          h="5"
                        />
                      )}
                    </ButtonGroup>
                  ) : (
                    <Button
                      flex="1"
                      borderRadius={"0px 0px 6px 6px"}
                      type="button"
                      colorScheme="red"
                      leftIcon={<i className={"far fa-trash"} />}
                      onClick={modify?.onDelete}
                      h="5"
                    >
                      Delete
                    </Button>
                  )}
                </Flex>
              )}
            </Flex>
          </Form>
        </Box>
      ) : (
        image
      )}
      <Flex
        pointerEvents="none"
        pos="absolute"
        inset="0"
        justifyContent="center"
        alignItems="end"
        p="3"
      >
        <Flex pointerEvents="initial" gap="2">
          {selected && (
            <Text
              fontSize="lg"
              boxSize="9"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
              borderColor="white"
              borderWidth={2}
              borderStyle="solid"
              color="white"
              backgroundColor={selected.value ? "teal.500" : "gray.400"}
            >
              <i className="fa fa-check" aria-hidden="true" />
            </Text>
          )}

          {!isEditMode && modify?.overlayButtons && (
            <Text
              cursor="pointer"
              onClick={handleFullScreenClick}
              fontSize="lg"
              boxSize="9"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
              borderColor="white"
              borderWidth={2}
              borderStyle="solid"
              color="white"
              backgroundColor="gray.600"
            >
              <i className="fas fa-expand" aria-hidden="true" />
            </Text>
          )}
          {!isEditMode && modify?.overlayButtons && (
            <Text
              cursor="pointer"
              onClick={handleEditClick}
              fontSize="lg"
              boxSize="9"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
              borderColor="white"
              borderWidth={2}
              borderStyle="solid"
              color="white"
              backgroundColor="gray.600"
            >
              <i className="fa fa-pencil" aria-hidden="true" />
            </Text>
          )}
          {needToEnterViews && (
            <Tooltip label="Need to enter story views">
              <Text
                fontSize="lg"
                boxSize="9"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="full"
                borderColor="white"
                borderWidth={2}
                borderStyle="solid"
                color="white"
                backgroundColor="red.600"
              >
                <i className="fa fa-exclamation" aria-hidden="true" />
              </Text>
            </Tooltip>
          )}
        </Flex>
      </Flex>
      <Modal
        size="4xl"
        isOpen={isFullScreenOpen}
        onClose={onFullScreenClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fullscreen media</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column">
            {isFullScreenOpen && (
              <>
                <RatioMedia
                  autoAspectRatio={true}
                  flex="1"
                  type={localData.mediaType}
                  src={localData.mediaUrl}
                  contain
                />
                <FormFooterButtonGroup py="3">
                  <Button autoFocus type="button" onClick={onFullScreenClose}>
                    Close
                  </Button>
                </FormFooterButtonGroup>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const TaskRow = ({
  data: externalData,
  onDataChange,
  linked,
  selectedStories,
}: {
  num: number;
  linked: Props["linked"];
  selectedStories: Props["selectedStories"];
  data: ArrayElement<Props["data"]["tasks"]>;
  onDataChange: (newData: ArrayElement<Props["data"]["tasks"]>) => void;
  expanded: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setDataLocally] = useState(externalData);
  useEffect(() => {
    if (!isOpen) {
      setDataLocally(externalData);
    }
  }, [externalData, isOpen]);

  const [toPropagateData, setToPropagateData] = useBoolean(false);
  const prevToPropagateData = usePrevious(toPropagateData);
  const setData = useCallback<typeof setDataLocally>(
    (newData, propagation = false) => {
      setDataLocally(newData);
      setToPropagateData.set(propagation);
    },
    [setToPropagateData],
  );
  useEffect(() => {
    if (toPropagateData && toPropagateData !== prevToPropagateData) {
      onDataChange(data);
      setToPropagateData.off();
    }
  }, [
    data,
    onDataChange,
    prevToPropagateData,
    setToPropagateData,
    toPropagateData,
  ]);

  const handleSubmit = useCallback(() => {
    document.getElementById("save-stories-attach")?.classList.add("disabled");
    console.log(data);
    onDataChange(data);
    onClose();
  }, [data, onClose, onDataChange]);

  const handleClose = useCallback(() => {
    setData(externalData);
    onClose();
  }, [externalData, onClose, setData]);

  const handleStartDateChange = useCallback<
    CallbackOfType<typeof ReactDatePicker, "onChange">
  >(
    (d) => {
      if (linked) {
        linked.onRangeChange(
          produce(linked.range, (draftRange) => {
            draftRange.since = d!.startOf("day");
          }),
        );
      }
    },
    [linked],
  );

  const handleEndDateChange = useCallback<
    CallbackOfType<typeof ReactDatePicker, "onChange">
  >(
    (d) => {
      if (linked) {
        linked.onRangeChange(
          produce(linked.range, (draftRange) => {
            draftRange.until = d!.endOf("day");
          }),
        );
      }
    },
    [linked],
  );

  const handleCaptionChange = useCallback<any>(
    (d: any) => {
      if (linked) {
        linked.onRangeChange(
          produce(linked.range, (draftRange) => {
            draftRange.caption = d.target.value;
          }),
        );
      }
    },
    [linked],
  );

  const manualStoriesToShow = data.stories
    .filter((x) => x.type === StoryType.manual)
    .map((x: any, index: number) => {
      return {
        id: x.id,
        notDeletable: x.notDeletable,
        data: x.manualData,
      };
    });

  const handleFileUpload = useCallback<
    NonNullable<React.ComponentProps<typeof FileUpload>["onFileUploaded"]>
  >(
    (mediaUrl, mediaType) => {
      if (mediaType === undefined || mediaType === FileTypes.document) {
        toast.error("Failed to upload, bad file type");
        return;
      }
      const newStoryData: StoryData = {
        mediaUrl,
        mediaType: {
          [FileTypes.image]: "image",
          [FileTypes.video]: "video",
        }[mediaType] as "image" | "video",
        views: 0,
        engagements: 0,
        saved: 0,
        shares: 0,
        reach: 0,
        taps_back: 0,
        taps_forward: 0,
        swipes_forward: 0,
        media_creation_time: null,
      };
      setData(
        produce((draftData) => {
          draftData.stories.push({
            id: uuidv4(),
            type: StoryType.manual,
            manualData: newStoryData,
            notDeletable: false,
          });
        }),
      );
      handleFinishUpload();
    },
    [setData],
  );

  const isStoriesDisabled = linked === undefined;
  const [tabIndex, setTabIndex] = useState(isStoriesDisabled ? 1 : 0);
  useEffect(() => {
    if (isStoriesDisabled) {
      setTabIndex(1);
    }
  }, [isStoriesDisabled]);

  const handleLoadMore = useCallback(() => {
    if (!isStoriesDisabled && !linked.paginatedStories.isFetching) {
      linked.paginatedStories.fetchMore();
    }
  }, [isStoriesDisabled, linked?.paginatedStories]);

  const handleSelectedToggle = useCallback(
    (linkedStoryId: string) => {
      const selectedIndex = data.stories.findIndex(
        (s) => s.type === StoryType.linked && s.linkedStoryId === linkedStoryId,
      );

      if (selectedIndex !== -1) {
        setData(
          produce(data, (draftData) => {
            _.remove(draftData.stories, {
              linkedStoryId,
            });
          }),
        );
      } else {
        setData(
          produce(data, (draftData) => {
            draftData.stories.push({
              type: StoryType.linked,
              linkedStoryId,
              notDeletable: false,
            });
          }),
        );
      }
    },
    [data, setData],
  );

  const handleStoryDelete = useCallback(
    (manualTaskStoryId: string, propagation: boolean) => {
      setData(
        produce(data, (draftData) => {
          const manualStoryIndex = draftData.stories.findIndex(
            (x) => x.type === StoryType.manual && x.id === manualTaskStoryId,
          );
          if (manualStoryIndex !== -1) {
            draftData.stories.splice(manualStoryIndex, 1);
          }
        }),
      );
      setToPropagateData.set(propagation);
    },
    [data, setData, setToPropagateData],
  );
  const handleStoryDataChange = useCallback(
    (
      manualTaskStoryId: string,
      newStoryData: StoryData,
      propagation: boolean,
    ): void => {
      setData(
        produce(data, (draftData) => {
          const manualStory = draftData.stories.find(
            (x) => x.type === StoryType.manual && x.id === manualTaskStoryId,
          );
          if (manualStory && manualStory.type === StoryType.manual) {
            manualStory.manualData = newStoryData;
          }
        }),
      );
      setToPropagateData.set(propagation);
    },
    [data, setData, setToPropagateData],
  );

  return (
    <>
      <Flex
        alignItems="center"
        flex="1"
        justifyContent="flex-start"
        overflow="hidden"
      >
        {data?.description ? (
          <Indicator header={"Task Description"}>
            <Text className="ellipsis" textAlign="start">
              <Tooltip label={data.description} placement="bottom-start">
                {data.description}
              </Tooltip>
            </Text>
          </Indicator>
        ) : null}
      </Flex>
      <Box py={4}>
        {
          <Carousel slidesPerView={storiesPerRow - 1}>
            <Box
              onClick={onOpen}
              borderRadius="xl"
              overflow="hidden"
              pos="relative"
              display="inline-block"
              cursor="pointer"
            >
              <AspectRatio
                w={widthOfStories}
                ratio={ratioOfStories}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDir: "column",
                  borderRadius: "xl",
                  borderStyle: "dashed",
                  borderWidth: "2px",
                  backgroundColor: "#F3F6F9",
                  borderColor: "#D6D6D6",
                  transition: "all 0.3s",
                  corsur: "pointer",
                }}
                _hover={{
                  backgroundColor: "#D6D6D6",
                  borderColor: "#F3F6F9",
                }}
              >
                <Box display="flex" flexDir="column">
                  <Box
                    color="brand.500"
                    fontSize="3xl"
                    display="inline-flex"
                    p={7}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDir: "column",
                      background: "brand.500",
                      borderRadius: "20px",
                      color: "white",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                      marginBottom: "10px",
                    }}
                  >
                    <i
                      className="fad fa-up-from-bracket"
                      style={{
                        display: "inline-flex",
                      }}
                    />
                  </Box>
                  <Text color="gray" fontSize="lg">
                    Attach media
                  </Text>
                </Box>
              </AspectRatio>
            </Box>
            {externalData.stories.map((s) => {
              let id: string;
              let storyData: StoryData;
              switch (s.type) {
                case StoryType.linked: {
                  const linkedStory = selectedStories.find(
                    (ls) => ls.id === s.linkedStoryId,
                  )!;
                  id = linkedStory.id;
                  storyData = linkedStory.data;
                  break;
                }
                case StoryType.manual: {
                  id = s.id;
                  storyData = s.manualData;
                  break;
                }
                default:
                  assertUnreachable(s);
                  break;
              }

              return (
                <StoryItem
                  key={id}
                  data={storyData}
                  soundVideo={true}
                  modify={
                    s.type === StoryType.manual
                      ? {
                          overlayButtons: true,
                          onDelete: s.notDeletable
                            ? undefined
                            : () => {
                                handleStoryDelete(id, true);
                              },
                          onDataChange: (newStoryData) => {
                            console.log(id, newStoryData);
                            handleStoryDataChange(id, newStoryData, true);
                          },
                        }
                      : undefined
                  }
                />
              );
            })}
          </Carousel>
        }
      </Box>
      <Modal
        size="4xl"
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload media</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="hidden" display="flex">
            <Flex flexDir="column" flex="1">
              <Flex maxH={"70vh"} minH={"300px"} overflow="hidden">
                <Tabs isFitted index={tabIndex} onChange={setTabIndex}>
                  <TabList>
                    <Tab isDisabled={isStoriesDisabled}>
                      <Text>
                        Choose User&#39;s stories
                        {isStoriesDisabled ? ` - Disabled` : ""}
                      </Text>
                      {isStoriesDisabled ? (
                        <Text fontSize="xs">
                          (The influencer didn&#39;t connect his Facebook
                          business account)
                        </Text>
                      ) : null}
                    </Tab>
                    <Tab>
                      <Text>Upload from your computer</Text>
                    </Tab>
                  </TabList>
                  <TabPanels style={{ overflowY: "auto" }}>
                    <TabPanel>
                      {linked ? (
                        <Flex flexDir="column">
                          <Flex pb="2">
                            <Flex flexDir="column">
                              <Text>From</Text>
                              <ReactDatePicker
                                dateFormat={window.time.short_date}
                                className="input-line"
                                selected={linked.range.since}
                                onChange={handleStartDateChange}
                                maxDate={linked.range.until}
                              />
                            </Flex>
                            <Flex flexDir="column" px={3}>
                              <Text>To</Text>
                              <ReactDatePicker
                                dateFormat={window.time.short_date}
                                className="input-line"
                                selected={linked.range.until}
                                onChange={handleEndDateChange}
                                minDate={linked.range.since}
                                maxDate={moment()}
                              />
                            </Flex>
                            <Flex flexDir="column" px={3}>
                              <Text>Caption</Text>
                              <input
                                value={linked.range.caption}
                                className="input-line"
                                onChange={handleCaptionChange}
                              />
                            </Flex>
                          </Flex>
                          <Flex
                            flex={1}
                            overflow="hidden"
                            flexDir="column"
                            alignItems="strech"
                          >
                            <Box flex={1}>
                              <InfiniteScroll
                                loadMore={handleLoadMore}
                                hasMore={linked.paginatedStories.hasMore}
                                useWindow={false}
                              >
                                {linked.paginatedStories.data.map(
                                  (linkedStory) => {
                                    const selected = data.stories.find(
                                      (s) =>
                                        s.type === StoryType.linked &&
                                        s.linkedStoryId === linkedStory.id,
                                    );

                                    return (
                                      <Box
                                        key={linkedStory.id}
                                        display="inline-flex"
                                        pe={paddingBetweenStories}
                                        pb={paddingBetweenStories}
                                      >
                                        <StoryItem
                                          data={linkedStory.data}
                                          selected={{
                                            value: selected !== undefined,
                                            toggle:
                                              selected === undefined ||
                                              !selected.notDeletable
                                                ? () =>
                                                    handleSelectedToggle(
                                                      linkedStory.id,
                                                    )
                                                : undefined,
                                          }}
                                        />
                                      </Box>
                                    );
                                  },
                                )}
                              </InfiniteScroll>
                            </Box>
                          </Flex>
                          {linked.paginatedStories.data.length === 0 &&
                          !linked.paginatedStories.isFetching ? (
                            <Text pt="2">
                              No stories were found in the selected dates, try
                              different dates
                            </Text>
                          ) : null}
                          <Flex alignItems="baseline" py="2">
                            <Heading size="sm">
                              Can’t find influencer’s latest stories?
                            </Heading>
                            <Button
                              variant="link"
                              onClick={linked.onRequestRefresh}
                              isLoading={linked.paginatedStories.isRefreshing}
                            >
                              Refresh now
                            </Button>
                          </Flex>
                        </Flex>
                      ) : null}
                    </TabPanel>
                    <TabPanel>
                      <Box flex={1}>
                        <SimpleGrid
                          columns={storiesPerRow}
                          spacing={paddingBetweenStories}
                        >
                          <Flex
                            flex="1"
                            flexDirection="column"
                            overflow="hidden"
                            height="290px"
                          >
                            <FileUpload
                              onFileUploaded={handleFileUpload}
                              fileType={[FileTypes.image, FileTypes.video]}
                              uploadNumber={UploadNumber.multiple}
                              onStartUpload={handleStartUpload}
                            />
                          </Flex>
                          {manualStoriesToShow.map((manualTaskStory) => {
                            return (
                              <StoryItem
                                key={manualTaskStory.id}
                                data={manualTaskStory.data}
                                modify={{
                                  overlayButtons: false,
                                  onDelete: manualTaskStory.notDeletable
                                    ? undefined
                                    : () => {
                                        handleStoryDelete(
                                          manualTaskStory.id,
                                          false,
                                        );
                                      },
                                  onDataChange: (newStoryData) => {
                                    handleStoryDataChange(
                                      manualTaskStory.id,
                                      newStoryData,
                                      false,
                                    );
                                  },
                                }}
                              />
                            );
                          })}
                        </SimpleGrid>
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
              <Form onSubmit={handleSubmit} pb="3" pt={"3"}>
                <FormFooterButtonGroup>
                  <Button type="submit" id="save-stories-attach">
                    Save
                  </Button>
                  <Button type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                </FormFooterButtonGroup>
              </Form>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const AttachLinksDialogTabStoriesControlled = ({
  linked,
  selectedStories,
  data,
  onDataChange,
}: Props) => {
  const [selectedRow] = useState<number>(-1);
  return (
    <div>
      {data.tasks.map((task, taskIndex) => {
        return (
          <TaskRow
            expanded={selectedRow === taskIndex}
            key={task.id}
            num={taskIndex + 1}
            data={task}
            linked={linked}
            selectedStories={selectedStories}
            onDataChange={(newTask) => {
              const newData = produce(data, (draftData) => {
                draftData.tasks[taskIndex] = newTask;
              });
              onDataChange(newData);
            }}
          />
        );
      })}
    </div>
  );
};
