// TODO - lint
/* eslint-disable no-unused-vars, no-unused-vars, react/no-unescaped-entities, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, no-return-assign, react/no-direct-mutation-state, camelcase, camelcase, camelcase, camelcase, no-unused-vars, import/no-default-export */
// General Imports
import React from "react";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import ToggleButton from "react-toggle-button";
import NoImage from "../../../images/svgs/placeholder.svg";

// Actions imports
import { formatBigNumbers } from "../utilities/general";

// Css's import

class TasksPricesSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      influData: {},
      prices: {
        instagramPrice: 0,
        instagramStoryPrice: 0,
        instagramReelPrice: 0,
        twitterPrice: 0,
        youtubePrice: 0,
        tiktokPrice: 0,
      },
      amount: {
        ig_post: 0,
        ig_story: 0,
        ig_reel: 0,
        tw_tweet: 0,
        yt_video: 0,
        tk_video: 0,
      },
      set_as_default: false,
    };
  }

  linkInflueContainerRef() {
    const { influeContainerRef } = this;
    this.setState({ influeContainerRef });
  }

  componentDidMount() {
    this.linkInflueContainerRef();
  }

  currencyLabel() {
    const { list } = this.props.listReducer;
    if (!list) {
      return null;
    }
    return `money_symbol_${list.list_summary_data.currency}`;
  }

  renderInfluencer(data) {
    const instagramData =
      data.instagram_data && data.instagram_data.username
        ? data.instagram_data
        : null;
    const twitterData =
      data.twitter_data && data.twitter_data.username
        ? data.twitter_data
        : null;
    const youtubeData =
      data.youtube_data && data.youtube_data.username
        ? data.youtube_data
        : null;
    const tiktokData =
      data.tiktok_data && data.tiktok_data.username ? data.tiktok_data : null;
    const tasksAmount = data.tasks_amount;
    return (
      <>
        <div className="import-list-row tasks-prices-row">
          <div style={{ width: 40, height: 40, paddingTop: 1 }}>
            <img
              className="avatar"
              src={
                data.picture_url
                  ? data.picture_url.replace(".jpg", "_small.jpg")
                  : NoImage
              }
            />
          </div>
          <div className="import-list-row-details" style={{ flex: 1 }}>
            <strong
              className="import-list-influencer-name"
              style={{ width: "100%" }}
              title={data.name}
            >
              {data.name}'s Tasks prices and amount
            </strong>
          </div>
        </div>
        <div className="tasks-prices-bottom list-socials editable">
          <div className="edit-popup edit-prices">
            <div className="edit-title">Tasks prices</div>
            <div className="edit-popup-inputs">
              <div className={instagramData ? "input_row" : "disabled"}>
                <div className="fa fa-instagram" id="instagramPrice" />
                <input
                  disabled={!(instagramData && instagramData.cost_per_post)}
                  defaultValue={
                    instagramData && instagramData.cost_per_post
                      ? instagramData.cost_per_post.high
                      : null
                  }
                  ref={(e) => (this.state.prices.instagramPrice = e)}
                  type="number"
                />
                {window.translate("", this.currencyLabel())}
              </div>
              <div className={instagramData ? "input_row" : "disabled"}>
                <div className="fa moi-reels" id="instagramReelPrice" />
                <input
                  disabled={!(instagramData && instagramData.cost_per_reel)}
                  defaultValue={
                    instagramData && instagramData.cost_per_reel
                      ? instagramData.cost_per_reel.high
                      : null
                  }
                  ref={(e) => (this.state.prices.instagramReelPrice = e)}
                  type="number"
                />
                {window.translate("", this.currencyLabel())}
              </div>
              <div className={instagramData ? "input_row" : "disabled"}>
                <div className="fa moi-stories" id="instagramStoryPrice" />
                <input
                  disabled={!(instagramData && instagramData.cost_per_story)}
                  defaultValue={
                    instagramData && instagramData.cost_per_story
                      ? instagramData.cost_per_story.high
                      : null
                  }
                  ref={(e) => (this.state.prices.instagramStoryPrice = e)}
                  type="number"
                />
                {window.translate("", this.currencyLabel())}
              </div>
              <div className={twitterData ? "input_row" : "disabled"}>
                <div className="fa fa-twitter" id="twitterPrice" />
                <input
                  disabled={!(twitterData && twitterData.cost_per_post)}
                  defaultValue={
                    twitterData && twitterData.cost_per_post
                      ? twitterData.cost_per_post.high
                      : null
                  }
                  ref={(e) => (this.state.prices.twitterPrice = e)}
                  type="number"
                />
                {window.translate("", this.currencyLabel())}
              </div>
              <div className={youtubeData ? "input_row" : "disabled"}>
                <div className="fa fa-youtube-play" id="youtubePrice" />
                <input
                  disabled={!(youtubeData && youtubeData.cost_per_post)}
                  defaultValue={
                    youtubeData && youtubeData.cost_per_post
                      ? youtubeData.cost_per_post.high
                      : null
                  }
                  ref={(e) => (this.state.prices.youtubePrice = e)}
                  type="number"
                />
                {window.translate("", this.currencyLabel())}
              </div>
              <div className={tiktokData ? "input_row" : "disabled"}>
                <div className="fa-brands fa-tiktok" id="tiktokPrice" />
                <input
                  disabled={!(tiktokData && tiktokData.cost_per_post)}
                  defaultValue={
                    tiktokData && tiktokData.cost_per_post
                      ? tiktokData.cost_per_post.high
                      : null
                  }
                  ref={(e) => (this.state.prices.tiktokPrice = e)}
                  type="number"
                />
                {window.translate("", this.currencyLabel())}
              </div>
            </div>
          </div>
          {!this.props.campaign && (
            <div className="edit-popup edit-amount">
              <div className="edit-title">Tasks amount</div>
              <div className="edit-popup-inputs">
                <div
                  className={
                    instagramData && tasksAmount ? "input_row" : "disabled"
                  }
                >
                  <div className="fa fa-instagram" id="instagramAmount" />
                  <input
                    disabled={!tasksAmount}
                    defaultValue={tasksAmount?.ig_post || 0}
                    ref={(e) => (this.state.amount.ig_post = e)}
                    type="number"
                  />
                </div>
                <div
                  className={
                    instagramData && tasksAmount ? "input_row" : "disabled"
                  }
                >
                  <div className="fa moi-reels" id="instagramReelAmount" />
                  <input
                    disabled={!tasksAmount}
                    defaultValue={tasksAmount?.ig_reel || 0}
                    ref={(e) => (this.state.amount.ig_reel = e)}
                    type="number"
                  />
                </div>
                <div
                  className={
                    instagramData && tasksAmount ? "input_row" : "disabled"
                  }
                >
                  <div className="fa moi-stories" id="instagramStoryAmount" />
                  <input
                    disabled={!tasksAmount}
                    defaultValue={tasksAmount?.ig_story || 0}
                    ref={(e) => (this.state.amount.ig_story = e)}
                    type="number"
                  />
                </div>
                <div
                  className={
                    twitterData && tasksAmount ? "input_row" : "disabled"
                  }
                >
                  <div className="fa fa-twitter" id="twitterAmount" />
                  <input
                    disabled={!tasksAmount}
                    defaultValue={tasksAmount?.tw_tweet || 0}
                    ref={(e) => (this.state.amount.tw_tweet = e)}
                    type="number"
                  />
                </div>
                <div
                  className={
                    youtubeData && tasksAmount ? "input_row" : "disabled"
                  }
                >
                  <div className="fa fa-youtube-play" id="youtubeAmount" />
                  <input
                    disabled={!tasksAmount}
                    defaultValue={tasksAmount?.yt_video || 0}
                    ref={(e) => (this.state.amount.yt_video = e)}
                    type="number"
                  />
                </div>
                <div
                  className={
                    tiktokData && tasksAmount ? "input_row" : "disabled"
                  }
                >
                  <div className="fa-brands fa-tiktok" id="tiktokAmount" />
                  <input
                    disabled={!tasksAmount}
                    defaultValue={tasksAmount?.tk_video || 0}
                    ref={(e) => (this.state.amount.tk_video = e)}
                    type="number"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="save-as-default">
          <label>Save as default for this influencer</label>
          <ToggleButton
            value={this.state.set_as_default}
            onToggle={() => {
              const temp_state = { ...this.state };
              temp_state.set_as_default = temp_state.set_as_default
                ? false
                : !temp_state.set_as_default;
              this.setState(temp_state);
            }}
            inactiveLabel={"No"}
            activeLabel={"Yes"}
          />
        </div>
        <div className="save-small-buttons-row tasks-prices-bottom-buttons">
          <button
            className="btn btn-primry"
            onClick={() =>
              this.props.save(
                this.state.prices,
                this.state.amount,
                this.state.set_as_default,
              )
            }
            id="savePriceButton"
          >
            Save
          </button>
          <button className="btn btn-primry" onClick={this.props.closeCallback}>
            Cancel
          </button>
        </div>
      </>
    );
  }

  render() {
    return (
      <div
        className="import-from-list-container animated fadeInRight faster"
        id="tasks-prices-side"
      >
        {this.renderInfluencer(this.props.data)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listReducer: state.listsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksPricesSide);
