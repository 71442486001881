/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import Dialog from "rc-dialog";
import Tooltip from "rc-tooltip";
import React, { useEffect, useMemo, useState } from "react";
import ReactAutocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Toggle from "react-toggle";
import {
  createPixelPhase,
  deletePixelPhase,
  editPixelPhase,
  getPixelPhases,
} from "src/actions/affiliateActions";
import { AuctionPhase } from "src/api/types";
import NoImage from "../../../../images/svgs/placeholder.svg";
import CheckBox from "../../general_components/CheckBox";
import { tagColors } from "../../utilities/general";
import { PhaseAlert } from "./PhaseAlert";
import "./PhasesStyle.css";
import { PhaseTimeline } from "./PhasesTimeline";
import SelectDebounced from "./SelectDebounced";

const mainCampaignColor = "#0998C2";
interface OwnProps {
  handleClose: any;
  auctionId: number;
  getPixelPhases?: any;
  editPixelPhase?: any;
  deletePixelPhase?: any;
  createPixelPhase?: any;
  openInfluencerSegmentations: any;
  campaignData: any;
  autocompleteSales?: any;
}

const VALIDATIONS = {
  RULE_NAME_EMPTY: "Rule name can't be empty",
  COMMISSION_EMPTY: "Commission can't be empty",
  START_DATE_EMPTY: "Start date can't be empty",
  END_DATE_EMPTY: "End date can't be empty",
  START_DATE_AFTER_END: "Start date can't be bigger than end date",
  CANT_START_BEFORE_TODAY: "Rule can't be started before today",
  DATE_COLLISION: "Rules timeframe can't overlap",
  INFLUENCERS_COLLISION: "Influencers timeframe can't overlap",
  INFLUENCERS_EMPTY: "You must select influencers",
  EDIT_ENDED_PHASE: "Can't edit rule that has been ended",
};

export const matchWebsite = (website1: string, website2: string) => {
  const regx = /^(https?:\/\/)?(www\.)?([^/]+)/;
  const w1 = website1?.match(regx);
  const w2 = website2?.match(regx);
  return w1 && w2 && w1[3] === w2[3];
};

export const PhasesDialog: React.FC<OwnProps> = (props: OwnProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [phases, setPhases] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [isBrandsIncluded] = useState<boolean>(true);
  const [brandsSelected, setBrandsSelected] = useState<any[]>([]);
  const [skus, setSkus] = useState<any[]>([]);
  const [isSkusIncluded, setIsSkusIncluded] = useState<boolean>(true);
  const [skusSelected, setSkusSelected] = useState<any[]>([]);
  const [influencers, setInfluencers] = useState<any[]>([]);
  const [errors, setErrors] = useState<any[]>([]);
  const [influencersAutocompleteValue, setInfluencersAutocompleteValue] =
    useState<string>("");
  const [phaseEdit, setPhaseEdit] = useState<any>(null);
  const [alertData, setAlertData]: any = useState(null);
  const [appId, setAppId]: any = useState(null);
  const dispatch = useDispatch();
  let trashInfluencers: any = [];

  const sortedPhases: any = useMemo(() => {
    const activePhases = phases.filter(
      (rule) =>
        rule.title !== "Main" && !moment().isAfter(moment(rule.end_time)),
    );
    const endedPhases = phases.filter((rule) =>
      moment().isAfter(moment(rule.end_time)),
    );
    activePhases.sort(
      (a, b) => moment(b.start_time).valueOf() - moment(a.start_time).valueOf(),
    );
    endedPhases.sort(
      (a, b) => moment(b.start_time).valueOf() - moment(a.start_time).valueOf(),
    );

    return [...activePhases, ...endedPhases];
  }, [phases]);

  const mainPhase = useMemo(() => {
    return phases.find((x) => x.title === "Main");
  }, [phases]);
  console.log("main", mainPhase);

  const blankPhase: AuctionPhase | any = () => {
    return {
      id: null,
      end_time: moment().add(1, "week"),
      influencers: [],
      start_time: Date.now(),
      title: "",
      brands_excluded: [],
      brands_included:
        phases.length && mainPhase?.brands_included
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            [...mainPhase?.brands_included]
          : [],
      commission: props.campaignData.commission || null,
      skus_exceluded:
        phases.length && mainPhase?.skus_exceluded
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            [...mainPhase?.skus_exceluded]
          : [],
      skus_included:
        phases.length && mainPhase?.skus_included
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            [...mainPhase?.skus_included]
          : [],
      color: "#249ff0",
    } as unknown as AuctionPhase;
  };
  const { summary } = props.campaignData;
  const navigate = useNavigate();

  const getPhases = () => {
    dispatch(
      getPixelPhases(
        props.auctionId,
        (data: any) => {
          if (data?.app_id) {
            setAppId(data.app_id);
          }
          if (
            data?.phases?.length &&
            data.mainPhase &&
            data.mainPhase.brands_included
          ) {
            setBrandsSelected(
              data.mainPhase?.brands_included?.length
                ? [...data.mainPhase.brands_included].map((r: any) => {
                    return { label: r, value: r };
                  })
                : [],
            );
          }
          if (data.mainPhase && data.mainPhase.brands_excluded) {
            setBrandsSelected(
              data.mainPhase?.brands_excluded?.length
                ? [...data.mainPhase.brands_excluded].map((r: any) => {
                    return { label: r, value: r };
                  })
                : [],
            );
          }
          if (data.mainPhase && data.mainPhase.skus_included) {
            setSkusSelected(
              data.mainPhase?.skus_included?.length
                ? [...data.mainPhase.skus_included].map((r: any) => {
                    return { label: r, value: r };
                  })
                : [],
            );
            if (data.mainPhase?.skus_included?.length) setIsSkusIncluded(true);
          }
          if (data.mainPhase && data.mainPhase.skus_exceluded) {
            setSkusSelected(
              data.mainPhase?.skus_exceluded?.length
                ? [...data.mainPhase.skus_exceluded].map((r: any) => {
                    return { label: r, value: r };
                  })
                : [],
            );
            if (data.mainPhase && data.mainPhase.skus_exceluded)
              setIsSkusIncluded(false);
          }

          setInfluencers(
            props.campaignData?.states?.approval?.influencers || [],
          );
          // setInfluencers(data?.influencers);
          setPhases(data?.phases);
          setLoading(false);
        },
        () => {},
      ),
    );
  };

  useEffect(() => {
    setLoading(true);
    getPhases();
  }, []);

  const resetPhases = (pull?: boolean) => {
    if (pull) {
      getPhases();
    }
    setErrors([]);
    setPhaseEdit(null);
  };

  const prepareRule = (rule: AuctionPhase | any) => {
    const tmpRule = { ...rule };
    if (rule.influencers) {
      const newInfluencers = [...tmpRule.influencers];
      tmpRule.influencers.forEach((iid: any) => {
        const inf = influencers.find((i: any) => i.id === iid);
        if (inf) newInfluencers.push(inf);
      });
      tmpRule.influencers = newInfluencers;
    }
    return rule;
  };

  const handleBrandsChange = (newValue: any) => {
    const newRule = { ...phaseEdit };
    const brandsIncOrEx = isBrandsIncluded
      ? "brands_included"
      : "brands_excluded";
    if (newValue) {
      if (typeof newValue === "string") {
        newRule[brandsIncOrEx] = newValue.split(",");
      } else {
        newRule[brandsIncOrEx] = newValue;
      }
      setBrandsSelected(newValue);
    } else {
      newRule[brandsIncOrEx] = [];
    }
    setPhaseEdit(newRule);
    setErrors([]);
  };

  const handleSkusChange = (newValue: any) => {
    const newRule = { ...phaseEdit };
    const skusIncOrEx = isSkusIncluded ? "skus_included" : "skus_exceluded";
    if (newValue) {
      if (typeof newValue === "string") {
        newRule[skusIncOrEx] = newValue.split(",");
      } else {
        newRule[skusIncOrEx] = newValue;
      }
      setSkusSelected(newValue);
    } else {
      newRule[skusIncOrEx] = [];
    }
    setPhaseEdit(newRule);
    setErrors([]);
  };

  const prepareSinglePhase = (rule: AuctionPhase | any) => {
    const newRule = prepareRule(rule);
    let newBrandsSelected = [];
    // let newSkusSelected = [];
    if (rule.brands_included && rule.brands_included.length) {
      newBrandsSelected = rule.brands_included;
    }
    if (rule.skus_exceluded && rule.skus_exceluded.length) {
      newRule.skus_exceluded = rule.skus_exceluded || [];
      setSkusSelected(newRule.skus_exceluded);

      newRule.skus_included = null;
      setIsSkusIncluded(false);
    } else {
      newRule.skus_included = rule.skus_included || [];
      setSkusSelected(newRule.skus_included);
      newRule.skus_exceluded = null;
      setIsSkusIncluded(true);
    }
    const skusIncOrEx = isSkusIncluded
      ? newRule.skus_included
      : newRule.skus_exceluded;
    if (skusIncOrEx && skusIncOrEx.length) {
      // newSkusSelected = skusIncOrEx;
    }
    setBrandsSelected(newBrandsSelected);
    setPhaseEdit(newRule);
  };

  const askDeleteInfluencer = (inf: any, msg: string) => {
    setAlertData({
      alertTitle: "Delete influencer",
      alertMessage:
        msg ||
        `Important! The influencer will be removed from this rule and will be added to the main campaign rule with ${mainPhase?.commission}% commission`,
      handleAccept: () => {
        setAlertData(null);
        const phaseData = { ...phaseEdit };
        phaseData.influencers = phaseData.influencers.filter((i: any) => {
          return i !== inf;
        });
        setPhaseEdit(phaseData);
      },
      handleClose: () => setAlertData(null),
      buttonAccept: "Yes, delete",
      buttonCancel: "No, go back",
    });
  };

  const checkOverlapingDatesBrandsAndInfluencers = (phase: AuctionPhase) => {
    const overlaps: any = [];
    // Loop all the phases
    phases.forEach((p: AuctionPhase, i: number) => {
      // Check if the id is different and it's not the first phase (the main campaign)
      if (p.id !== phase.id && i !== 0) {
        let datesOverlaps: string | null = null;
        let brandsOverlaps: string | null = null;
        let skusOverlaps: string | null = null;
        let influencersOverlaps: number | null = null;
        // Check overlaping dates
        if (
          moment(p.start_time).isBetween(
            phase.start_time,
            phase.end_time,
            "minutes",
          ) ||
          moment(p.end_time).isBetween(
            phase.start_time,
            phase.end_time,
            "minutes",
          )
        ) {
          datesOverlaps = phase.title;
          // We have overlaping dates, let's look at the influencers:
          phase.influencers.forEach((pinflu: number) => {
            if (p.influencers?.includes(pinflu)) {
              influencersOverlaps = pinflu;
              if (phase.brands_included && phase.brands_included.length) {
                // Influencer overlapping, let's check if the brand overlaps:
                phase.brands_included.forEach((brand: string) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  if (
                    // Check brands not empty
                    (!p.brands_included || !p.brands_included.length) &&
                    (!phase.brands_included || !phase.brands_included.length)
                    // Or - check if it's identical
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                  ) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const brandToTest = null;
                    brands.forEach((b: any) => {
                      if (
                        typeof b !== "string" &&
                        b.value &&
                        b.value === brand
                      ) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        brandsOverlaps = brand;
                      }
                    });
                  }
                });
                if (phase.skus_included && phase.skus_included.length) {
                  // Check SKUs overlaps:
                  phase?.skus_included?.forEach((sku: string) => {
                    if (typeof phase.skus_included[0] !== "string") {
                      phase.skus_included = phase.skus_included.map(
                        (a: any) => {
                          return a.value;
                        },
                      );
                    }
                    if (
                      // Check skus not empty
                      ((!p.skus_included || !p.skus_included.length) &&
                        (!phase.skus_included ||
                          !phase.skus_included.length)) ||
                      // Or - check if it's identical
                      (p.skus_included && p.skus_included.includes(sku)) ||
                      // or - check if is in excluded
                      (p.skus_exceluded && p.skus_exceluded.includes(sku))
                    ) {
                      skusOverlaps = sku;
                    }
                  });
                  // Check SKUs excluded overlaps:
                  phase.skus_exceluded.forEach((sku: string) => {
                    if (
                      // Check skus not empty
                      ((!p.skus_exceluded || !p.skus_exceluded.length) &&
                        (!phase.skus_exceluded ||
                          !phase.skus_exceluded.length)) ||
                      // or - check if is in included
                      p.skus_included.includes(sku)
                    ) {
                      skusOverlaps = sku;
                    }
                  });
                }
              }
            }
          });
        }

        // Check conditions for overlapping
        if (
          brandsOverlaps ||
          skusOverlaps ||
          (!brandsOverlaps && !skusOverlaps ? false : datesOverlaps)
        ) {
          overlaps.push({
            title: datesOverlaps,
            brand: brandsOverlaps,
            sku: skusOverlaps,
            influencer: influencers.find(
              (_inf: any) => _inf.influencer_id === influencersOverlaps,
            ),
          });
        }
      }
    });
    return overlaps;
  };

  const objectsArrayToStringsArray = (objArray: any) => {
    if (!objArray) return [];
    if (typeof objArray === "string") return objArray.split(",");
    return objArray.map((o: any) => {
      if (typeof o === "string") return o;
      return o.value;
    });
  };

  const handleUpdate = () => {
    if (!phaseEdit) return;
    const phaseToSend = { ...phaseEdit };
    const isNew = !phaseEdit.id;
    const isMain = phaseEdit.title === "Main";
    // Check Validations
    const tempErrors = [];

    if (moment().isAfter(moment(phaseEdit.end_time))) {
      tempErrors.push(VALIDATIONS.EDIT_ENDED_PHASE);
    }

    if (
      (!phaseEdit.title || phaseEdit.title?.trim().length === 0) &&
      !isMain &&
      phaseEdit.title.toLowerCase().trim() === "main"
    ) {
      tempErrors.push(VALIDATIONS.RULE_NAME_EMPTY);
    }
    if (!phaseEdit.commission || phaseEdit.commission <= 0) {
      tempErrors.push(VALIDATIONS.COMMISSION_EMPTY);
    }
    if (!phaseEdit.start_time) {
      tempErrors.push(VALIDATIONS.START_DATE_EMPTY);
    }
    if (!phaseEdit.end_time) {
      tempErrors.push(VALIDATIONS.END_DATE_EMPTY);
    }
    if (moment(phaseEdit.start_time).isAfter(moment(phaseEdit.end_time))) {
      tempErrors.push(VALIDATIONS.START_DATE_AFTER_END);
    }
    if (!isMain) {
      if (!phaseEdit.influencers || !phaseEdit.influencers.length) {
        tempErrors.push(VALIDATIONS.INFLUENCERS_EMPTY);
      }
      const overlaps = checkOverlapingDatesBrandsAndInfluencers(phaseEdit);
      if (overlaps.length) {
        overlaps.forEach((overlap: any) => {
          if (overlap.influencer || overlap.sku || overlap.brand) {
            tempErrors.push(
              `Rule time Overlap: *${
                overlap?.title
              }* rule is overlapping with influencer ${
                overlap?.influencer?.name
              } ${overlap?.brand ? `and brand ${overlap?.brand}` : ""} ${
                overlap?.sku ? `and SKU ${overlap?.sku}` : ""
              }`,
            );
          }
        });
      }
    } else {
      phaseToSend.influencers = [];
    }
    if (tempErrors.length) {
      setErrors(tempErrors);
      return;
    }
    setLoading(true);
    phaseToSend.brands_included = objectsArrayToStringsArray(brandsSelected);
    if (isSkusIncluded) {
      phaseToSend.skus_included = objectsArrayToStringsArray(skusSelected);
      phaseToSend.skus_exceluded = null;
    } else {
      phaseToSend.skus_included = null;
      phaseToSend.skus_exceluded = objectsArrayToStringsArray(skusSelected);
    }
    if (!phaseEdit.main_override) {
      phaseToSend.main_override = false;
    }
    setPhaseEdit(phaseToSend);

    // New rule
    if (isNew || isMain) {
      dispatch(
        createPixelPhase(
          props.auctionId,
          phaseToSend,
          isMain,
          () => {
            resetPhases(true);
            setLoading(false);
          },
          () => {
            setLoading(false);
          },
        ),
      );
    } else {
      // existing rule - edit
      dispatch(
        editPixelPhase(
          props.auctionId,
          phaseToSend,
          phaseToSend.id,
          () => {
            setLoading(false);
            resetPhases(true);
          },
          () => {
            setLoading(false);
          },
        ),
      );
    }
  };

  const getMinDate = () => {
    if (!mainPhase) return Date.now();
    const startOfMonth = moment().startOf("month");
    return startOfMonth.isAfter(moment(mainPhase.start_time))
      ? startOfMonth
      : mainPhase.start_time;
  };

  const getMaxDate = () => {
    if (!mainPhase) return moment(Date.now()).add("1 year");
    return mainPhase.end_time;
  };

  const addRemoveInfluencerToPhase = (inf: any) => {
    if (phaseEdit) {
      const newPhaseEdit = { ...phaseEdit };
      if (!newPhaseEdit.influencers) {
        newPhaseEdit.influencers = [];
      }
      newPhaseEdit?.influencers?.addOrRemove(inf.influencer_id);
      setPhaseEdit(newPhaseEdit);
    }
  };

  const askClose = () => {
    setAlertData({
      alertTitle: "Exit",
      alertMessage:
        "Are you sure you want to exit? Any unsaved changes will be discarded.",
      handleAccept: () => {
        setAlertData(null);
        resetPhases();
        props.handleClose();
      },
      handleClose: () => setAlertData(null),
      buttonAccept: "Yes, exit",
      buttonCancel: "No, go back",
    });
  };

  const askDeleteRule = () => {
    setAlertData({
      alertTitle: "Delete rule",
      alertMessage: "Are you sure you want to delete this rule?",
      handleAccept: () => {
        dispatch(
          deletePixelPhase(
            props.auctionId,
            phaseEdit.id,
            () => {
              setLoading(false);
              resetPhases(true);
            },
            () => {
              setLoading(false);
            },
          ),
        );
        setAlertData(null);
      },
      handleClose: () => setAlertData(null),
      buttonAccept: "Yes, delete",
      buttonCancel: "No, go back",
    });
  };

  const askBackToList = () => {
    setAlertData({
      alertTitle: "Exit",
      alertMessage:
        "Are you sure you want to exit? Any unsaved changes will be discarded.",
      handleClose: () => {
        setAlertData(null);
      },
      handleAccept: () => {
        setAlertData(null);
        resetPhases();
      },
      buttonAccept: "Yes, exit",
      buttonCancel: "No, go back",
    });
  };

  const checkClose = () => {
    if (phaseEdit) {
      setAlertData({
        alertTitle: "Exit",
        alertMessage:
          "Are you sure you want to exit? Any unsaved changes will be discarded.",
        handleClose: () => {
          setAlertData(null);
        },
        handleAccept: () => {
          setAlertData(null);
          resetPhases();
          props.handleClose();
        },
        buttonAccept: "Yes, exit",
        buttonCancel: "No, go back",
      });
    } else {
      props.handleClose();
    }
  };

  const prepareBlankPhase = () => {
    const skusIncEx = isSkusIncluded ? "skus_included" : "skus_exceluded";
    const brandsIncEx = isBrandsIncluded
      ? "brands_included"
      : "brands_excluded";
    const labeledBrands =
      // eslint-disable-next-line no-unsafe-optional-chaining
      brandsIncEx?.length && mainPhase && mainPhase[brandsIncEx]
        ? [...mainPhase[brandsIncEx]].map((r: any) => {
            return { label: r, value: r };
          })
        : [];
    const labeledSkus =
      // eslint-disable-next-line no-unsafe-optional-chaining
      skusIncEx?.length && mainPhase && mainPhase[skusIncEx]
        ? [...mainPhase[skusIncEx]].map((r: any) => {
            return { label: r, value: r };
          })
        : [];
    setBrands(labeledBrands);
    setBrandsSelected(
      mainPhase && mainPhase[brandsIncEx] ? mainPhase[brandsIncEx] : [],
    );
    setSkus(labeledSkus);
    setSkusSelected(
      mainPhase && mainPhase[skusIncEx] ? mainPhase[skusIncEx] : [],
    );
    setPhaseEdit(blankPhase());
  };
  const renderInfluencers = () => {
    const isNew = phaseEdit && !phaseEdit?.id;
    return (
      <>
        <div className="influencers">
          <div className="influencers-table-header">
            <div>Influencers in rule</div>
          </div>
          {phaseEdit?.influencers &&
            phaseEdit?.influencers?.map((inf_id: number, i: number) => {
              const inf = influencers.find(
                (_inf: any) => _inf.influencer_id === inf_id,
              );
              return (
                inf && (
                  <div
                    key={i}
                    className="influencer-row-small"
                    style={{ flex: 1 }}
                  >
                    <div className="flex influencer-name-box">
                      <div className="influencer-image">
                        {inf?.picture_url && (
                          <img src={inf?.picture_url} alt={inf?.name} />
                        )}
                      </div>
                      <div className="influencer-name">{inf?.name}</div>
                    </div>
                    <div className="actions-column">
                      {!phaseEdit?.id && (
                        <div
                          className="circle-btn delete-influ"
                          onClick={() =>
                            askDeleteInfluencer(
                              inf_id,
                              isNew
                                ? "Are you sure you want to delete this influencer?"
                                : null,
                            )
                          }
                        >
                          <i className="far fa-trash" />
                        </div>
                      )}
                    </div>
                  </div>
                )
              );
            })}
          {trashInfluencers?.map((inf: any, i: number) => (
            <div key={i} className="influencer-row-small" style={{ flex: 1 }}>
              <div className="flex influencer-name-box">
                <div className="influencer-image">
                  {inf?.picture_url && (
                    <img src={inf?.picture_url} alt={inf?.name} />
                  )}
                </div>
                <div
                  className="influencer-name"
                  style={{ color: "#AAA", marginRight: 10 }}
                >
                  {inf?.name}
                </div>
                <div className="trashed">
                  <div style={{ color: "#434D56", fontSize: 12 }}>Trashed</div>
                  <i
                    className="fa-regular fa-trash"
                    style={{
                      color: "#434D56",
                      alignContent: "center",
                      fontSize: 10,
                    }}
                  ></i>
                </div>
              </div>
              <div className="actions-column"></div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderTagColorPicker = (rule: AuctionPhase | any) => {
    return (
      <div className="tag-color-pick">
        {tagColors.map((x) => (
          <div
            key={x}
            style={{ background: x }}
            onClick={() => {
              const newRule = { ...rule };
              newRule.color = x;
              setPhaseEdit(newRule);
            }}
          >
            {rule.color === x && <i className="fas fa-check" />}
          </div>
        ))}
      </div>
    );
  };

  const renderSinglePhase = (rule: AuctionPhase, isMainCampaign?: boolean) => {
    const ruleName = isMainCampaign ? summary.name : rule.title;
    const isEnded = moment().isAfter(moment(rule.end_time));
    const influencersAndRuleInfluencers = influencers?.filter(
      (influencer: any) =>
        rule?.influencers?.some(
          (ruleInfluencerId: any) =>
            ruleInfluencerId === influencer.influencer_id,
        ),
    );
    const trashInfluencersDisplay =
      props.campaignData?.states?.trash?.influencers.filter((influencer: any) =>
        rule?.influencers?.some(
          (ruleInfluencerId: any) =>
            ruleInfluencerId === influencer.influencer_id,
        ),
      );
    trashInfluencers = trashInfluencersDisplay;
    return (
      <div key={rule?.id || 0}>
        <div
          className={`influencer-row ${
            isMainCampaign ? " main-campaign" : null
          }`}
        >
          <div className="flex affiliate-row" onClick={() => {}}>
            <div className="influencer-image">
              {isMainCampaign ? (
                <img src={summary?.image || NoImage} alt={ruleName} />
              ) : (
                <i
                  style={
                    isMainCampaign || rule.color
                      ? {
                          color: isMainCampaign
                            ? mainCampaignColor
                            : rule.color,
                        }
                      : undefined
                  }
                  className="far fa-calendar"
                />
              )}
            </div>
            <div
              className="table-column"
              style={{ flex: isMainCampaign ? 1 : 2 }}
            >
              {isMainCampaign && (
                <div className="table-title-row">Main Campaign Rule</div>
              )}
              <div
                className="table-value-row"
                style={
                  isMainCampaign || rule.color
                    ? { color: isMainCampaign ? mainCampaignColor : rule.color }
                    : undefined
                }
              >
                {isEnded ? <strong>[Finished] </strong> : null} {ruleName}
              </div>
            </div>
            {isMainCampaign ? (
              <>
                {" "}
                <div className="table-column">
                  <div className="table-title-row">Start date</div>
                  <div
                    className="table-value-row"
                    style={{ color: mainCampaignColor }}
                  >
                    {moment(rule.start_time).format(window.time.date_with_hour)}{" "}
                    <i className="far fa-calendar" />
                  </div>
                </div>
                <div className="table-column">
                  <div className="table-title-row">End date</div>
                  <div
                    className="table-value-row"
                    style={{ color: mainCampaignColor }}
                  >
                    {moment(rule.end_time).format(window.time.date_with_hour)}{" "}
                    <i className="far fa-calendar" />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="table-column" style={{ flex: 2 }}>
                  <div
                    className="table-value-row"
                    style={
                      isMainCampaign || rule.color
                        ? {
                            color: isMainCampaign
                              ? mainCampaignColor
                              : rule.color,
                          }
                        : undefined
                    }
                  >
                    {moment(rule.start_time).format(window.time.date_with_hour)}{" "}
                    - {moment(rule.end_time).format(window.time.date_with_hour)}{" "}
                    <i className="far fa-calendar" />
                  </div>
                  <div className="table-title-row">
                    <div className="inline-columns">
                      <div className="table-title-row">
                        {rule?.influencers?.length
                          ? `${rule.influencers.length}  Total influencers`
                          : "All influencers"}
                      </div>
                      {!isEnded ? (
                        <div className="table-title-row">
                          {influencersAndRuleInfluencers?.length || 0} Active
                          influencers
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="table-column">
              <div className="table-title-row">Commission</div>
              <div className="table-value-row">
                <span
                  className="tag"
                  style={
                    isMainCampaign || rule.color
                      ? {
                          color: isMainCampaign ? "#249ff0" : "white",
                          background: isMainCampaign ? "white" : rule.color,
                        }
                      : undefined
                  }
                >
                  {rule.commission}%
                </span>
              </div>
            </div>

            <div className="actions-column" style={{ position: "relative" }}>
              {phaseEdit ? (
                <>
                  <Tooltip
                    overlayStyle={{ background: "white" }}
                    overlay={renderTagColorPicker(rule)}
                    placement={"bottomLeft"}
                  >
                    <i
                      style={{
                        marginLeft: 8,
                        position: "relative",
                        fontSize: 16,
                        background: rule.color || mainCampaignColor,
                      }}
                      className="far fa-eye-dropper colorpicker"
                    />
                  </Tooltip>
                </>
              ) : null}
            </div>
            {!phaseEdit ? (
              <div
                className={`actions-column ${
                  isMainCampaign ? "main-campaign" : ""
                }`}
              >
                <div
                  className={isMainCampaign ? "" : "icon-btn"}
                  onClick={() => prepareSinglePhase(rule)}
                >
                  <i className="fa fa-pencil" />
                  {isMainCampaign ? "Edit Main Rule" : ""}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderMainPhase = () => {
    return phases && phases.length ? renderSinglePhase(mainPhase, true) : null;
  };

  const renderPhases = () => {
    return (
      <>
        <div className="influencer-row-container">
          {sortedPhases.map((rule: AuctionPhase, index: number) => (
            <div key={index}>{renderSinglePhase(rule)}</div>
          ))}
        </div>
        {mainPhase?.commission > 0 && (
          <button
            className="btn btn-add-rule"
            onClick={() => {
              prepareBlankPhase();
            }}
          >
            <i className="far fa-plus" /> Add Rule
          </button>
        )}
      </>
    );
  };

  const renderInfluencersAutocomplete = (isEnded: boolean) => {
    return (
      <div className="influencers-autocomplete">
        <ReactAutocomplete
          items={influencers}
          shouldItemRender={(item, value) =>
            !phaseEdit?.influencers?.includes(item?.influencer_id) &&
            item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
          }
          renderItem={(inf, highlighted) => (
            <div
              key={inf.influencer_id}
              className={`flex influencer-name-box${
                highlighted ? " highlighted" : ""
              }`}
            >
              {/* <CheckBox checked={checked} onChange={() => {}} /> */}
              <div className="influencer-image">
                {inf?.picture_url && (
                  <img src={inf?.picture_url} alt={inf.name} />
                )}
              </div>
              <div className="influencer-name">{inf.name}</div>
            </div>
          )}
          getItemValue={(item) => item.name}
          onChange={(e) => {
            setInfluencersAutocompleteValue(e.currentTarget.value);
            setErrors([]);
          }}
          onSelect={(_, item) => {
            setErrors([]);
            addRemoveInfluencerToPhase(item);
            setInfluencersAutocompleteValue("");
          }}
          value={influencersAutocompleteValue}
          renderInput={(_props) => (
            <input
              {..._props}
              disabled={isEnded}
              className="input-line"
              style={{ flex: 1 }}
              placeholder="Search and select influencers to add"
            />
          )}
        />
        <i className="far fa-search"></i>
      </div>
    );
  };

  const renderValidations = () => {
    if (errors && errors.length) {
      return (
        <>
          {errors.map((e, i) => (
            <div key={i} className="alert-danger error">
              {e}
            </div>
          ))}
        </>
      );
    }
    return null;
  };

  const renderPhaseEdit = (rule: AuctionPhase) => {
    const isNew = phaseEdit && !phaseEdit?.id;
    const isMain = phaseEdit.title === "Main";
    // Can delete a rule if start date is after today
    const canDelete =
      !isMain && moment(rule.start_time).isAfter(moment(Date.now()));
    // Is rule ended?
    const isEnded = !isNew && moment().isAfter(moment(phaseEdit.end_time));

    return (
      phaseEdit && (
        <div className="phaseEdit">
          {renderSinglePhase(rule)}
          <div className="alerts">{renderValidations()}</div>
          <div className="flex affiliate-row">
            <div className="table-column">
              <div className="table-title-row">
                Rule name <span className={"required"}>*</span>
              </div>
              <div className="table-value-row">
                <input
                  onChange={(e) => {
                    const newRule = { ...rule };
                    newRule.title = e.target.value;
                    setPhaseEdit(newRule);
                    setErrors([]);
                  }}
                  className="input-line"
                  defaultValue={rule.title}
                  type="text"
                  maxLength={40}
                  disabled={isEnded || isMain}
                />
              </div>
            </div>
            {!isMain && (
              <div
                className="table-column"
                style={{
                  flex: 0.5,
                  paddingLeft: 50,
                  marginRight: 0,
                  marginLeft: -20,
                }}
              >
                <div className="table-title-row">
                  Ignore Main <span className={"required"}>*</span>{" "}
                  <Tooltip
                    overlay={
                      "Enable this if you want to ignore the main rule during the time period this rule is active."
                    }
                    trigger={["hover"]}
                    placement={"top"}
                  >
                    <i
                      style={{ marginLeft: 5, fontSize: 14 }}
                      className="fa-solid fa-info-circle"
                    />
                  </Tooltip>
                </div>
                <div className="table-value-row">
                  <CheckBox
                    disabled={isEnded}
                    checked={rule.main_override}
                    label="Ignore main rule"
                    onChange={() => {
                      const newRule = { ...rule };
                      newRule.main_override = !rule.main_override;
                      setPhaseEdit(newRule);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="table-column" style={{ flex: 0.5 }}>
              <div className="table-title-row">
                Commission <span className={"required"}>*</span>
              </div>
              <div className="table-value-row">
                <input
                  className={`input-line ${isEnded ? "disabled" : ""}`}
                  style={{
                    display: "inline-block",
                    width: "calc(100% - 12px)",
                  }}
                  defaultValue={rule.commission}
                  type="number"
                  min={0}
                  max={99.9}
                  disabled={isEnded}
                  onChange={(e: any) => {
                    const newRule = { ...rule };
                    newRule.commission = Number(e.target.value);
                    setPhaseEdit(newRule);
                    setErrors([]);
                  }}
                />
                <span>%</span>
              </div>
            </div>
          </div>
          <div className="flex affiliate-row">
            <div className="table-column">
              <div className="table-title-row">
                Start date <span className={"required"}>*</span>
              </div>
              <div className="table-value-row">
                <DatePicker
                  isClearable={false}
                  placeholderText="Start Date"
                  allowSameDay={true}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat={window.time.date_with_hour}
                  minDate={getMinDate()}
                  maxDate={getMaxDate()}
                  autoComplete="off"
                  onChange={(d: any) => {
                    const newRule = { ...rule };
                    newRule.start_time = new Date(d).toISOString();
                    setPhaseEdit(newRule);
                    setErrors([]);
                  }}
                  selected={moment(isMain ? undefined : rule.start_time)}
                  className={`input-line ${
                    !isNew && !canDelete ? "disabled" : ""
                  }`}
                  disabled={!isNew && !canDelete}
                />
              </div>
            </div>
            <div className="table-column">
              <div className="table-title-row">
                End date <span className={"required"}>*</span>
              </div>
              <div className="table-value-row">
                <DatePicker
                  placeholderText="End Date"
                  isClearable={false}
                  allowSameDay={true}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat={window.time.date_with_hour}
                  minDate={getMinDate()}
                  maxDate={isMain ? undefined : getMaxDate()}
                  autoComplete="off"
                  onChange={(d: any) => {
                    const newRule = { ...rule };
                    newRule.end_time = new Date(d).toISOString();
                    setPhaseEdit(newRule);
                    setErrors([]);
                  }}
                  selected={moment(rule.end_time)}
                  className={`input-line ${isEnded ? "disabled" : null}`}
                  disabled={isEnded}
                />
              </div>
            </div>
          </div>
          <div className="flex affiliate-row">
            <div className="table-column">
              <div className="table-title-row">
                Brands {isBrandsIncluded ? "Included" : "Excluded"}
              </div>
              <div className="table-value-row">
                <SelectDebounced
                  handleChange={handleBrandsChange}
                  selectedValues={brandsSelected}
                  appId={appId}
                  options={brands}
                  type="brand"
                  disabled={isEnded || skusSelected?.length > 0}
                />
              </div>
            </div>

            <div className="table-column" style={{ flex: "initial" }}>
              <div className="table-title-row">Skus</div>
              <div className="table-value-row">
                <div className="toggle-input brands">
                  <label>
                    <div className="toggle-label">
                      <span>Exclude</span>
                      <span>Include</span>
                    </div>
                    <Toggle
                      checked={isSkusIncluded}
                      icons={false}
                      disabled={isEnded || brandsSelected?.length > 0}
                      onChange={() => {
                        setIsSkusIncluded(!isSkusIncluded);
                        handleSkusChange([]);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="table-column">
              <div className="table-title-row">
                SKUs {isSkusIncluded ? "Included" : "Excluded"}
              </div>
              <div className="table-value-row">
                <SelectDebounced
                  handleChange={handleSkusChange}
                  selectedValues={skusSelected}
                  appId={appId}
                  options={skus}
                  type="sku"
                  disabled={isEnded || brandsSelected?.length > 0}
                />
              </div>
            </div>
          </div>
          {!isMain && (
            <div className="flex affiliate-row">
              <div className="table-column">
                <div className="table-title-row">
                  Influencers <span className={"required"}>*</span>{" "}
                  <Tooltip
                    overlay={
                      "Influencers must be on approval stage to be available for selection"
                    }
                    trigger={["hover"]}
                    placement={"top"}
                  >
                    <i
                      style={{ marginLeft: 5, fontSize: 14 }}
                      className="fa-solid fa-info-circle"
                    />
                  </Tooltip>
                </div>
                <div className="table-value-row">
                  {renderInfluencersAutocomplete(isEnded)}
                </div>
              </div>
            </div>
          )}
          <div className="flex affiliate-row">
            <div className="table-column">
              <div className="table-value-row">
                {phaseEdit?.influencers?.length > 0
                  ? renderInfluencers()
                  : null}
              </div>
            </div>
          </div>
          <div className="footer-buttons center">
            {!isEnded && (
              <button
                style={{ marginLeft: 10 }}
                disabled={(errors !== null && errors?.length > 0) || loading}
                className={`attach-links-btn share-save-btn btn btn-primary ${
                  loading && "disabled"
                }`}
                onClick={handleUpdate}
              >
                <span>Save</span>
              </button>
            )}
            {!isMain && !isNew && canDelete ? (
              <button
                style={{ marginLeft: 10 }}
                className={`attach-links-btn share-cancel-btn btn delete ${
                  loading && "disabled"
                }`}
                onClick={() => askDeleteRule()}
              >
                <span>Delete rule</span>
              </button>
            ) : null}
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
                loading && "disabled"
              }`}
              onClick={() => askClose()}
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      )
    );
  };

  const renderLoader = () => {
    return (
      <div className="logo-loader no-margin">
        <div className="loader" />
        <img src={"/images/humanz-logo.png"} />
      </div>
    );
  };

  const renderTimeline = (): any => {
    return (
      <PhaseTimeline
        auctionColor={mainCampaignColor}
        auction={props.campaignData}
        data={phases}
      />
    );
  };

  const renderPhasesList = () => {
    return (
      <>
        {renderMainPhase()}
        {renderTimeline()}
        {renderPhases()}
      </>
    );
  };

  return (
    <>
      <Dialog
        maskClosable={false}
        onClose={checkClose}
        className="phases-dialog"
        visible={true}
        style={{ width: "1020px", height: "90vh", margin: "65px auto" }}
        animation="slide-fade"
        maskAnimation="fade"
        keyboard={false}
      >
        {alertData ? (
          <PhaseAlert
            alertTitle={alertData?.alertTitle}
            alertMessage={alertData?.alertMessage}
            handleClose={alertData?.handleClose}
            handleAccept={alertData?.handleAccept}
            buttonAccept={alertData?.buttonAccept}
            buttonCancel={alertData?.buttonCancel}
            isLoading={alertData?.isLoading}
          />
        ) : null}
        <div className="dialog-inner">
          <div className="dialog-header">
            {phaseEdit ? (
              <>
                <button
                  className="btn btn-cancel back-to-list"
                  onClick={() => {
                    askBackToList();
                  }}
                >
                  <i className="far fa-chevron-left" /> Back to list
                </button>
                {phaseEdit?.id ? (
                  <span>Edit affiliation rule - {phaseEdit.title}</span>
                ) : (
                  <span>New affiliation rule</span>
                )}
                <div style={{ width: 106 }}></div>
              </>
            ) : (
              <>
                <span></span>
                <span>
                  Affiliation rules{" "}
                  <Tooltip
                    overlay={
                      "In affiliation rules you can define unique timelines for influencers, brands and a fixed commission percentage."
                    }
                    trigger={["hover"]}
                    placement={"top"}
                  >
                    <i
                      style={{ marginLeft: 5, fontSize: 14 }}
                      className="fa-solid fa-info-circle"
                    />
                  </Tooltip>
                </span>
                <span></span>
              </>
            )}
          </div>
          {loading ? (
            renderLoader()
          ) : (
            <>
              {phaseEdit ? (
                renderPhaseEdit(phaseEdit)
              ) : (
                <div style={{ fontSize: 16, margin: "20px 0" }}>
                  {renderPhasesList()}
                </div>
              )}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginBottom: 15 }}></div>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};
