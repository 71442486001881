// import { Input } from "@material-ui/core";
import { Input } from "@chakra-ui/react";
import { useState } from "react";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";

const DisplayTaskTitle = (props: {
  disable: boolean;
  task: AuctionToDoItem;
  campaignId: string;
  handleEditTask: (taskId: number, taskData: AuctionToDoItem) => void;
}) => {
  const titleStyle = {
    color: "var(--black-1, #333)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "17.143px",
    padding: 0,
    margin: 0,
  };
  const [title, setTitle] = useState(props.task.title);
  const handleTitleChange = (event: any) => {
    if (event.target.value.length < 16) {
      setTitle(event.target.value);
    }
  };
  const handleTitleBlur = () => {
    if (!props.disable) {
      props.handleEditTask(props.task.id, {
        ...props.task,
        title,
      });
    }

    // editTodoItem(Number(props.campaignId), props.task.id, taskData);
  };
  return (
    <>
      <Input
        readOnly={props.disable}
        sx={{
          border: "none",
          padding: "0",
          borderRadius: "0",
          "&:focus": {
            boxShadow: "none",
          },
        }}
        style={titleStyle}
        placeholder="Title"
        onChange={handleTitleChange}
        value={title}
        onBlur={handleTitleBlur}
      />
    </>
  );
};

export default DisplayTaskTitle;
