/* eslint-disable react/react-in-jsx-scope */
// TODO - lint
/* eslint-disable no-unused-vars, react/no-deprecated, no-unused-vars, camelcase, camelcase, camelcase, no-nested-ternary, no-unused-vars, no-unused-vars, import/no-default-export */
import Dropzone from "@humanz/react-dropzone";
import { Component } from "react";
import { connect } from "react-redux";
import { addPicture } from "../../../actions/mediaActions";
import ProgressRing from "./ProgressRing";

class SingleImageDropZone extends Component {
  /** ********************
    
        ========
         props:
        ========
        upload_title
        uploaded_image
        callback(image_url)
    
     ********************* */

  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      mime_type: null,
      image_uploaded: this.props ? this.props.image_uploaded : null,
      disabled: this.props ? this.props.disabled : false,
      imageType:
        this.props && this.props.imageType ? this.props.imageType : "image",
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.image_uploaded &&
      this.state.image_uploaded !== nextProps.image_uploaded
    ) {
      this.setState({ image_uploaded: nextProps.image_uploaded });
    }
    if (this.state.disabled !== nextProps.disabled) {
      this.setState({ disabled: nextProps.disabled });
    }
  }

  render() {
    return (
      <div
        className="small-upload-image"
        style={{ paddingTop: this.props.paddingTop || 0, position: "relative" }}
      >
        <strong
          className="label-title"
          style={{
            marginBottom: 20,
            justifyContent: this.props.centerTitle ? "center" : undefined,
          }}
        >
          {this.props.upload_title}
        </strong>
        <Dropzone
          accept={this.props.accept || { "image/jpeg": [], "image/png": [] }}
          disabled={this.state.disabled}
          multiple={false}
          onDropAccepted={(accepted_file) =>
            addPicture(
              accepted_file,
              this.state.imageType,
              (file_url, type) => {
                this.setState({
                  image_uploaded: file_url,
                  mime_type: type,
                  progress: 0,
                });
                this.props.callback(file_url, type);
              },
              (progress) => this.setState({ progress }),
            )
          }
        >
          {({ getRootProps, getInputProps }) => (
            <div className="small-upload-image-dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {this.state.progress ? (
                <ProgressRing
                  color={"#249ff0"}
                  radius={50}
                  stroke={4}
                  progress={this.state.progress}
                />
              ) : this.state.image_uploaded ? (
                <div>
                  <img src={this.state.image_uploaded} />
                </div>
              ) : (
                <div className="inner-small-upload-dropzone">
                  <div className="moi-right-arrow" />
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SingleImageDropZone);
