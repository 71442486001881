import axios from "axios";
import { toast } from "react-toastify";
import { getUserSideDetails } from "./userActions";
import { getBackendUri, jwtToken, notificationOpts } from "./commonActionsConf";

const getPaymentReuquestUrl = () =>
  `${getBackendUri()}/moi/payment/payment_from_advertiser`;
const getPaymentReuquestUrlWithUser = () =>
  `${getBackendUri()}/moi/payment/payment_from_user`;
const getTokenizeCreditCardRequestUrl = () =>
  `${getPaymentReuquestUrl()}/tokenize_credit_card`;

export const YAAD_COINS = { NIS: "1", USD: "2", EUR: "3", GBP: "4" };
const YAAD_TOKENIZATION_START_STATUS = "700";

const peachTokenize = (
  userDetails,
  creditCardDetails,
  billingAddress,
  callback,
) => {
  // using peach payments - billing to ZA company
  // todo: check currency with Peach
  const expiry = creditCardDetails.expiry.split("/");
  const cardHolderName =
    "name" in creditCardDetails && creditCardDetails.name
      ? creditCardDetails.name
      : userDetails.company_details.name ||
        userDetails.name ||
        userDetails.user_email;
  const data = {
    "card.number": creditCardDetails.number,
    "card.holder": cardHolderName,
    "card.expiryMonth": expiry[0],
    "card.expiryYear": `20${expiry[1]}`,
    "card.cvv": creditCardDetails.cvc,
    advertiserId: userDetails.user_id,
    screenColorDepth: parseInt(window.screen.colorDepth, 16).toString(),
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timezoneOffset: new Date().getTimezoneOffset().toString(),
  };

  return (dispatch) => {
    dispatch({
      type: "ACTIVE_GENERAL_LOADER",
      payload: true,
    });
    axios
      .post("/peach/init", data, {
        withCredentials: true,
      })
      .then((response) => {
        if (
          response.status === 200 &&
          "redirect" in response.data &&
          undefined !== response.data.redirect
        ) {
          callback(response.data);
        } else {
          console.log(`error!${JSON.stringify(response)}`);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });

        if (callback) {
          callback(false);
        }
      });
  };
};

export const getPaymentsMethods = (callback) => {
  return (dispatch) => {
    axios
      .get(`${getPaymentReuquestUrl()}/methods`, {
        withCredentials: true,
        headers: {
          "X-MoiBackendAuthorization": jwtToken,
        },
      })
      .then((response) => {
        callback(response.data);
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: "FAILED_TO_GET_PAYMENT_METHOD_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get payment methods",
          "system_notifications_failure_get_payment_methods",
        );
        toast.error(notificationOpts.message);
        callback(null);
        console.error(err);
      });
  };
};

const sendPaymentProviderResponseToBE = (
  paymentProvider,
  initResponse,
  tokenizationResponse,
  callback,
) => {
  const formattedResponses = [];
  for (let i = 0; i < 2; i += 1) {
    const response = [initResponse, tokenizationResponse][i];
    if (!response) {
      formattedResponses.push(undefined);
      // eslint-disable-next-line no-continue
      continue;
    }

    let headers = [];
    Object.keys(response.headers).forEach((headerKey) => {
      headers.push({
        header_key: headerKey.toString(),
        header_value: response.headers[headerKey].toString(),
      });
    });
    if (!headers.length) {
      headers = null;
    }
    formattedResponses.push({
      status: response.status,
      data: response.data,
      headers,
    });
  }

  const body = {
    init: {
      yaad_soft_response_headers: formattedResponses[0].headers,
      yaad_soft_response_data: formattedResponses[0].data,
      yaad_soft_status: formattedResponses[0].status,
    },
    provider: paymentProvider,
  };
  if (formattedResponses[1]) {
    body.token = {
      yaad_token_response_headers: formattedResponses[1].headers,
      yaad_token_response_data: formattedResponses[1].data,
      yaad_token_status: formattedResponses[1].status,
    };
  } else {
    body.token = null;
  }

  return (dispatch) => {
    axios
      .put(getTokenizeCreditCardRequestUrl(), body, {
        withCredentials: true,
        headers: {
          "X-MoiBackendAuthorization": jwtToken,
        },
      })
      .then(() => {
        // todo: log success?
        dispatch(getPaymentsMethods(callback));
        dispatch(getUserSideDetails());
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to add credit card",
          "system_notifications_failure_cc",
        );
        toast.error(notificationOpts.message);
        callback(false);
        console.error(err);
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
      });
  };
};

const createTokenizedCardDetailsFromResponse = (
  initResponse,
  yaadTransactionId,
  advertiserId,
  callback,
) => {
  const yaadRequest = {
    TransId: yaadTransactionId,
    Fild1: `Yaad:T${advertiserId}`,
  };
  return (dispatch) => {
    axios
      .post("/yaad/token", yaadRequest, {
        withCredentials: true,
      })
      .then((response) => {
        dispatch(
          sendPaymentProviderResponseToBE(
            "yaad",
            initResponse,
            response.data,
            callback,
          ),
        );
      })
      .catch((err) => {
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
        callback(false);
        console.error(err); // todo: handle error
      });
  };
};

export const tokenizeCreditCard = (
  userDetails,
  creditCardDetails,
  billingAddress,
  callback,
) => {
  if (billingAddress.country === "South Africa") {
    return peachTokenize(
      userDetails,
      creditCardDetails,
      billingAddress,
      callback,
    );
  }
  // else - use yaad - billing in Israeli company
  const advId = userDetails.user_id;
  const advEmail = userDetails.user_email;
  const last4digits = creditCardDetails.number.slice(-4);
  const clientName = encodeURIComponent(
    userDetails.company_details.name ||
      userDetails.name ||
      userDetails.user_email,
  );
  const cardHolderName =
    "name" in creditCardDetails && creditCardDetails.name
      ? encodeURIComponent(creditCardDetails.name)
      : clientName;
  const answerToBE = `Yaad:H${advId}:L4:${last4digits}:${cardHolderName}`;
  const expiry = creditCardDetails.expiry.split("/");
  let rawCurrency = creditCardDetails.currency.toUpperCase();
  if (!Object.keys(YAAD_COINS).includes(rawCurrency)) {
    // todo: handle Australia, NZ and Turkey differently once we have a payment solution there
    rawCurrency = "USD";
  }
  const currency = YAAD_COINS[rawCurrency];
  // var phoneNumber = 'complete this'; //todo: complete this
  // var street = 'complete this';
  // var city = 'complete this';
  // var zipCode = 'complete this';
  const yaadRequest = {
    Amount: "1",
    J5: "True",
    Coin: currency,
    Info: answerToBE,
    Order: answerToBE,
    CC: creditCardDetails.number,
    Tmonth: expiry[0],
    Tyear: expiry[1],
    cvv: creditCardDetails.cvc,
    UserId: `L${advId}`,
    ClientName: clientName,
  };
  if (advEmail) {
    yaadRequest.email = advEmail;
  }

  return (dispatch) => {
    dispatch({
      type: "ACTIVE_GENERAL_LOADER",
      payload: true,
    });
    axios
      .post("/yaad/init", yaadRequest, {
        withCredentials: true,
      })
      .then((response) => {
        const responseToHandle = response.data;
        const splitResponse = responseToHandle.data.split("&");
        const responseMap = {};
        for (let index = 0; index < splitResponse.length; index += 1) {
          const keyValuePair = splitResponse[index].split("=");
          const [key, value] = keyValuePair;
          responseMap[key] = value;
        }

        if (
          "CCode" in responseMap &&
          YAAD_TOKENIZATION_START_STATUS === responseMap.CCode
        ) {
          const yaadTransactionId = responseMap.Id;
          dispatch(
            createTokenizedCardDetailsFromResponse(
              responseToHandle,
              yaadTransactionId,
              advId,
              callback,
            ),
          );
        } else {
          dispatch(
            sendPaymentProviderResponseToBE(
              "yaad",
              responseToHandle,
              undefined,
              callback,
            ),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });

        if (callback) {
          callback(false);
        }

        // notificationOpts["title"] = window.translate("Failed", "system_notifications_failure");
        // notificationOpts["message"] = window.translate("Failed to get chats", "system_notifications_failure_get_chats");
        // toast.error(notificationOpts["message"]);
        console.error(err);
      });
  };
};

export const tokenizeYaadCreditCard = (redirectUrl, advId, callback) => {
  if (!redirectUrl || !advId) return;
  // eslint-disable-next-line consistent-return
  return (dispatch) => {
    dispatch({
      type: "ACTIVE_GENERAL_LOADER",
      payload: true,
    });
    let responseToHandle = redirectUrl.split("?")[1];
    const splitResponse = responseToHandle.split("&");
    const responseMap = {};
    for (let index = 0; index < splitResponse.length; index += 1) {
      const keyValuePair = splitResponse[index].split("=");
      const [key, value] = keyValuePair;
      responseMap[key] = value;
    }
    if (
      "CCode" in responseMap &&
      YAAD_TOKENIZATION_START_STATUS === responseMap.CCode
    ) {
      const yaadTransactionId = responseMap.Id;
      // Added info param (lowercase) which is required by backend
      responseToHandle = `${responseToHandle}&info=Yaad:H${advId}:L4:${responseMap.L4digit}:${responseMap.Fild1}`;
      // UserId must start with "L"
      responseToHandle = responseToHandle.replace(
        `UserId=${responseMap.UserId}`,
        `UserId=L${advId}`,
      );
      // a workaround to follow the guidelines of the backend old flow - simulate a response object
      const softReq = {
        headers: { icom: "web4" },
        data: responseToHandle,
        status: 200,
      };
      dispatch(
        createTokenizedCardDetailsFromResponse(
          softReq,
          yaadTransactionId,
          advId,
          callback,
        ),
      );
    } else {
      dispatch(
        sendPaymentProviderResponseToBE(
          "yaad",
          responseToHandle,
          undefined,
          callback,
        ),
      );
    }
  };
};

export const finalizePeachTokenize = (
  humanzTransactionId,
  peachId,
  callback,
) => {
  return (dispatch) => {
    dispatch({
      type: "ACTIVE_GENERAL_LOADER",
      payload: true,
    });
    axios
      .post(
        "/peach/token",
        {
          transactionId: humanzTransactionId,
          peachId,
        },
        {
          withCredentials: true,
        },
      )
      .then((response) => {
        if (response.data) {
          dispatch(
            sendPaymentProviderResponseToBE(
              "peach",
              response.data.init,
              response.data.token,
              callback,
            ),
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
        callback(false);
        console.error(err); // todo: handle error
      });
  };
};

export const deletePaymentMethod = (id, callback) => {
  return (dispatch) => {
    dispatch({
      type: "ACTIVE_GENERAL_LOADER",
      payload: true,
    });
    axios
      .delete(`${getPaymentReuquestUrl()}/${id}`, {
        withCredentials: true,
        headers: {
          "X-MoiBackendAuthorization": jwtToken,
        },
      })
      .then(() => {
        dispatch(getUserSideDetails());
        callback(true);
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: "FAILED_TO_GET_PAYMENT_METHOD_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to delete payment methods, try again later",
          "system_notifications_failure_delete_payment_methods",
        );
        toast.error(notificationOpts.message);
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
        callback(false);
        console.error(err);
      });
  };
};

export const getInvoices = (callback) => {
  return (dispatch) => {
    axios
      .get(`${getPaymentReuquestUrl()}/invoices`, {
        withCredentials: true,
        headers: {
          "X-MoiBackendAuthorization": jwtToken,
        },
      })
      .then((response) => {
        callback(response.data);
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: "FAILED_TO_GET_PAYMENT_METHOD_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get invoices",
          "system_notifications_failure_get_invoices",
        );
        toast.error(notificationOpts.message);
        callback(null);
        console.error(err);
      });
  };
};

export const getDisputes = (callback) => {
  return (dispatch) => {
    axios
      .get(`${getPaymentReuquestUrl()}/disputes`, {
        withCredentials: true,
        headers: {
          "X-MoiBackendAuthorization": jwtToken,
        },
      })
      .then((response) => {
        callback(response.data);
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: "FAILED_TO_GET_PAYMENT_METHOD_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get disputes",
          "system_notifications_failure_get_invoices",
        );
        toast.error(notificationOpts.message);
        callback(null);
        console.error(err);
      });
  };
};

export const upgradeToPremium = (paymentMethodId, callback) => {
  const url = `${getPaymentReuquestUrl()}/upgrade_to_premium?payment_method_id=${paymentMethodId}`;
  return (dispatch) => {
    axios
      .put(
        url,
        {},
        {
          withCredentials: true,
          headers: {
            "X-MoiBackendAuthorization": jwtToken,
          },
        },
      )
      .then(() => {
        dispatch(getUserSideDetails());
        callback(true);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error === "Already premium"
        ) {
          callback(true);
          return;
        }
        dispatch({
          type: "FAILED_TO_GET_PAYMENT_METHOD_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to upgrade to premium",
          "system_notifications_failure_upgrade_premium",
        );
        toast.error(notificationOpts.message);
        callback(false);
        console.error(err);
      });
  };
};

export const getNextBillingPeriod = (callback) => {
  return () => {
    axios
      .get(`${getPaymentReuquestUrl()}/next_billing_period`, {
        withCredentials: true,
        headers: {
          "X-MoiBackendAuthorization": jwtToken,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "next_billing_period_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get next billing period",
          "next_billing_period_failure",
        );
        toast.error(notificationOpts.message);
        callback(null);
        console.error(err);
      });
  };
};

export const getInsightsPackages = (callback) => {
  return () => {
    axios
      .get(`${getPaymentReuquestUrlWithUser()}/purchase_insights`, {
        withCredentials: true,
        headers: {
          "X-MoiBackendAuthorization": jwtToken,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get insights packages",
          "system_notifications_failure_get_invoices",
        );
        toast.error(notificationOpts.message);
        callback(null);
        console.error(err);
      });
  };
};

export const purchaseInsightsPackage = (
  paymentMethodId,
  postsAmount,
  currency,
  callback,
) => {
  const url = `${getPaymentReuquestUrlWithUser()}/purchase_insights?payment_method_id=${paymentMethodId}`;
  return (dispatch) => {
    dispatch({
      type: "ACTIVE_GENERAL_LOADER",
      payload: true,
    });
    axios
      .put(
        url,
        {
          posts_amount: postsAmount,
          currency,
        },
        {
          withCredentials: true,
          headers: {
            "X-MoiBackendAuthorization": jwtToken,
          },
        },
      )
      .then(() => {
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
        dispatch(getUserSideDetails());
        callback(true);
      })
      .catch((err) => {
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });

        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error === "Already premium"
        ) {
          callback(true);
          return;
        }
        dispatch({
          type: "FAILED_TO_GET_PAYMENT_METHOD_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to buy insights package",
          "system_notifications_failure_upgrade_premium",
        );
        toast.error(notificationOpts.message);
        callback(false);
        console.error(err);
      });
  };
};

export const createDispute = (pbiFk, message, callback) => {
  const url = `${getPaymentReuquestUrl()}/dispute`;
  return (dispatch) => {
    dispatch({
      type: "ACTIVE_GENERAL_LOADER",
      payload: true,
    });
    axios
      .put(
        url,
        {
          pbi_fk: pbiFk,
          custom_message: message,
        },
        {
          withCredentials: true,
          headers: {
            "X-MoiBackendAuthorization": jwtToken,
          },
        },
      )
      .then(() => {
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: true,
        });
        callback(true);
      })
      .catch((err) => {
        dispatch({
          type: "DEACTIVE_GENERAL_LOADER",
          payload: false,
        });
        callback(false);
        console.error(err);
      });
  };
};
