import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import uuidv4 from "uuid/v4";
import {
  createStandaloneReport,
  editStandaloneReport,
  getStandaloneReport,
  getUserProfile,
} from "../../../actions/brandReportsActions";
import PaymentsDialog from "../payments_components/PaymentsDialog";
import { formatBigNumbers } from "../utilities/general";

const newReport = {
  brand_report_title: "",
  emails: [{ email: "", id: uuidv4() }],
  enabled: true,
  instagram_page: "",
};

const defaultProfileSearch = {
  username: "",
  not_real_user: true,
};

class EditBrandInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      saving: false,
      loading: true,
      searchLoading: false,
      mode: "edit",
      errorFields: [],
      profileSearch: { data: defaultProfileSearch },
      showPaymentsDialog: false,
      showPrivateError: false,
    };
    this.timeout = null;
  }

  componentDidMount() {
    this.getReport();
    let paymentsPage = sessionStorage.getItem("PAYMENTS_PAGE");
    if (paymentsPage) {
      paymentsPage = JSON.parse(paymentsPage);
      if (
        paymentsPage.state &&
        paymentsPage.page === window.location.pathname
      ) {
        this.setState({ ...paymentsPage.state });
      }
      sessionStorage.removeItem("PAYMENTS_PAGE");
    }
  }

  getReport() {
    if (!this.props.id) {
      this.setState({
        report: { ...newReport },
        loading: false,
        mode: "create",
      });
    } else {
      this.props.getStandaloneReport(this.props.id, (res) => {
        this.setState({ loading: false, report: res, mode: "edit" });
      });
    }
  }

  addRow = () => {
    this.state.report.emails.push({ email: "", id: uuidv4() });
    this.setState({ updated: true });
  };

  deleteRow(id) {
    const emails = [...this.state.report.emails];
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.report.emails = emails.filter((email) => email.id !== id);
    this.setState({ updated: true });
  }

  onSave = () => {
    if (!this.validate()) {
      return;
    }

    const { report } = this.state;
    if (this.state.mode === "edit") {
      this.props.editStandaloneReport(report.id, report, () => {
        this.setState({ saving: false });
      });
    } else if (!this.props.user.userDetails.sidebar_data.brand_report_price) {
      this.createReport();
    } else {
      this.setState({ showPaymentsDialog: true });
    }
  };

  backToList() {
    this.setState({ report: { ...newReport } });
    this.props.navigate("/brand-report");
  }

  createReport = () => {
    this.setState({ saving: true });
    this.props.createStandaloneReport(this.state.report, () => {
      this.setState({ saving: false });
      this.backToList();
    });
  };

  handleEmailListChange(index, event) {
    const emails = this.state.report.emails.slice();
    emails[index] = { id: emails[index].id, email: event.target.value };
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.report.emails = emails;
    this.setState({ updated: true });
  }

  addOrRemoveError(name, type) {
    const { errorFields } = this.state;
    if (type === "add") {
      if (!errorFields.includes(name)) errorFields.push(name);
    } else if (errorFields.includes(name)) {
      errorFields.addOrRemove(name);
    }
    this.setState({ errorFields });
  }

  validate() {
    const { report } = this.state;
    let isValid = true;
    // update fields to new value
    this.setState({ updated: true });
    report.emails.forEach((email, i) => {
      if (
        !email.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      ) {
        this.addOrRemoveError(i, "add");
        isValid = false;
      }
    });
    if (this.state.mode === "create") {
      if (
        !report.instagram_page ||
        this.state.profileSearch.data.not_real_user
      ) {
        this.addOrRemoveError("instagram_page", "add");
        isValid = false;
      }
      if (!report.brand_report_title) {
        this.addOrRemoveError("brand_report_title", "add");
        isValid = false;
      }
    }
    return isValid;
  }

  onTitleChange(e) {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.report.brand_report_title = e?.target?.value?.trim();
  }

  searchProfile = (e) => {
    let profileName = e?.target?.value?.toLowerCase()?.trim();
    const instalink = profileName.match(
      /(https?:\/\/(www\.)?instagram\.com)\/(.*)/,
    );
    if (instalink && instalink[3]) {
      profileName = instalink[3].replace(/\//g, "");
      this.instagram_page = profileName;
      // eslint-disable-next-line no-param-reassign
      e.target.value = profileName;
    }
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.report.instagram_page = profileName;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.setState(
      {
        profileSearch: { data: defaultProfileSearch },
        updated: true,
        searchLoading: true,
        showPrivateError: false,
      },
      () => {
        this.timeout = setTimeout(() => {
          const state = { ...this.state };
          if (!profileName) {
            this.setState({
              profileSearch: { data: defaultProfileSearch },
              searchLoading: false,
            });
            return;
          }
          this.props.getUserProfile(profileName, (res) => {
            if (res.data) {
              if (res.data.is_private) {
                state.profileSearch.data = defaultProfileSearch;
                state.showPrivateError = true;
              } else {
                state.profileSearch.data = res;
                state.showPrivateError = false;
              }
            } else {
              this.addOrRemoveError("instagram_page", "add");
              state.profileSearch.data = defaultProfileSearch;
            }
            state.searchLoading = false;
            this.setState(state);
          });
        }, 1000);
      },
    );
  };

  profileBox(data) {
    // eslint-disable-next-line no-param-reassign
    if (data.data) data = data.data;
    return data ? (
      <div
        className={`profilebox ${
          this.state.searchLoading ? "search_loading" : ""
        }`}
      >
        <div className="col1">
          {this.state.searchLoading ? (
            <div className="mini-loader rotating">
              <img src="/images/humanz-logo.png" alt="" />
            </div>
          ) : (
            ""
          )}
          {!data.not_real_user && data.profile_picture ? (
            <img className="blurable" src={data.profile_picture} />
          ) : (
            <div className="far fa-user-circle no-profile-pic"></div>
          )}
        </div>
        <div className="col2">
          {data.username && <div className="userName">{data.username}</div>}
          {data.full_name && (
            <div className="userfullname">{data.full_name}</div>
          )}
        </div>
        {data.counts ? (
          <div className="col3">
            <div className="countline">
              <div className="usercount">
                {formatBigNumbers(data.counts.followers)}
                <strong>Followers</strong>
              </div>
              <div className="usercount">
                {formatBigNumbers(data.counts.follows)}
                <strong>Following</strong>
              </div>
              <div className="usercount">
                {formatBigNumbers(data.counts.media)}
                <strong>Media</strong>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      ""
    );
  }

  emailList = () => {
    const { report, errorFields } = this.state;
    return (
      <>
        {report.emails.map((email, i) => (
          <div key={email.id} className="input-row flex" id={email.id}>
            <input
              required
              type="email"
              className={`input-line ${
                errorFields.includes(i) && "input-error"
              }`}
              defaultValue={email.email}
              onChange={(e) => {
                this.handleEmailListChange(i, e);
                this.addOrRemoveError(i, "remove");
              }}
            />
            <div
              className="btn moi-trash"
              onClick={() => this.deleteRow(email.id)}
            ></div>
            {errorFields.includes(i) && (
              <div>
                <i className="error-mark" />
                <span className="error-text">
                  Whoops! Email isn&#39;t valid
                </span>
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  render() {
    const { report, loading } = this.state;

    return !loading ? (
      <div className="brand-report-section-main">
        <div className="section-middle">
          <div style={{ width: "var(--main-inner-size)", margin: "0 auto" }}>
            <div className="toolbar" style={{ marginTop: 10 }}>
              <div className="brand-report-title">
                {this.state.mode === "edit"
                  ? report.brand_report_title
                  : "INSTAGRAM BRAND SCOUTING REPORT"}
              </div>
            </div>
            {this.state.mode === "create" && this.renderCreateFields()}
            <div className="brand-report-details-container campaigns-rows">
              <div
                className="create-description-form"
                style={{ marginTop: 30 }}
              >
                <strong className="label-title">Email list</strong>
                {report.emails && report.emails.length ? this.emailList() : ""}
                <div
                  className="moi-plus"
                  style={{
                    color: "var(--main-baby-blue-color)",
                    marginLeft: 22,
                    marginTop: 12,
                    fontSize: 17,
                  }}
                  onClick={this.addRow}
                >
                  &nbsp; ADD Email
                </div>
              </div>
              <div className="create-buttons-row" style={{ paddingTop: 20 }}>
                <Link to="/brand-report">
                  <button className="btn btn-primry">BACK</button>
                </Link>
                <button
                  onClick={this.onSave}
                  className={`btn btn-primry ${
                    this.state.saving ? "disabled" : ""
                  }`}
                >
                  {this.state.mode === "create" ? "CREATE" : "SAVE"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showPaymentsDialog && (
          <PaymentsDialog
            onBuy={this.createReport}
            state={this.state}
            handleClose={() => this.setState({ showPaymentsDialog: false })}
            renderPaymentsSummary={this.renderPaymentsSummary}
          />
        )}
      </div>
    ) : (
      <></>
    );
  }

  renderCreateFields = () => {
    const { errorFields, report, profileSearch } = this.state;
    return (
      <div className="flex">
        <div className="createFields">
          <div className="input-row" style={{ marginTop: 10 }}>
            <strong className="label-title">Report name</strong>
            <input
              required
              className={`input-line ${
                errorFields.includes("brand_report_title") && "input-error"
              }`}
              defaultValue={report.brand_report_title}
              onChange={(e) => {
                this.onTitleChange(e);
                this.addOrRemoveError("brand_report_title", "remove");
                this.setState({ update: 1 });
              }}
            />
            {errorFields.includes("brand_report_title") && (
              <div>
                <i className="error-mark" />
                <span className="error-text">
                  Whoops! A valid report name is required
                </span>
              </div>
            )}
          </div>
          <div className="input-row" style={{ marginTop: 10 }}>
            <div className="">
              <div>
                <strong className="label-title">Profile username</strong>
                <input
                  required
                  className={`input-line ${
                    errorFields.includes("instagram_page") && "input-error"
                  }`}
                  defaultValue={report.instagram_page}
                  ref={(link) => {
                    this.instagram_page = link;
                  }}
                  onChange={(e) => {
                    this.addOrRemoveError("instagram_page", "remove");
                    this.searchProfile(e);
                  }}
                />
                {errorFields.includes("instagram_page") && (
                  <div>
                    <i className="error-mark" />
                    <span className="error-text">
                      Whoops! We didn&#39;t find that Instagram page. Please
                      check and try again.
                    </span>
                  </div>
                )}
                {this.state.showPrivateError && (
                  <div>
                    <i className="error-mark" />
                    <span className="error-text">
                      Whoops! The user is private. Please change the user to
                      public or search for another user.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.profileBox(profileSearch.data)}
      </div>
    );
  };

  renderPaymentsSummary = () => {
    const { currency } = this.props.user.userDetails;
    const price = this.props.user.userDetails.sidebar_data.brand_report_price;
    const { vat_percentage: vatPercentage } = this.props.user.userDetails;
    let total = vatPercentage * 0.01 * price + price;
    // eslint-disable-next-line no-multi-assign
    const vat = (total -= vatPercentage * 0.01 * total);
    return (
      <>
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            padding: 10,
            borderBottom: "1px dashed #bbb",
          }}
        >
          <div>Brand Report (x1)</div>
          <div>
            {window.translate("", `money_symbol_${currency}`)}
            {price} PER MONTH
          </div>
        </div>
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            padding: 10,
            borderBottom: "1px solid #bbb",
          }}
        >
          <div>Subtotal</div>
          <div>
            {window.translate("", `money_symbol_${currency}`)}
            {price} PER MONTH
          </div>
        </div>
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            padding: 10,
            borderBottom: "1px solid #bbb",
          }}
        >
          <div>VAT ({vatPercentage}%)</div>
          <div>
            {window.translate("", `money_symbol_${currency}`)}
            {vat}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            padding: 10,
            borderBottom: "1px solid #bbb",
          }}
        >
          <div>Total</div>
          <div>
            {window.translate("", `money_symbol_${currency}`)}
            {total} PER MONTH
          </div>
        </div>
        <div style={{ fontSize: 10, marginLeft: 10, marginTop: 5 }}>
          * Not include VAT
        </div>
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editStandaloneReport: (id, data, callback) => {
      dispatch(editStandaloneReport(id, data, callback));
    },
    getStandaloneReport: (id, callback) => {
      dispatch(getStandaloneReport(id, callback));
    },
    createStandaloneReport: (data, callback) => {
      dispatch(createStandaloneReport(data, callback));
    },
    getUserProfile: (userName, callback) => {
      dispatch(getUserProfile(userName, "instagram", callback));
    },
  };
};

const EditBrandConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditBrandInner);

export const EditBrand = (props) => {
  const navigate = useNavigate();

  return <EditBrandConnected {...props} navigate={navigate} />;
};
