import {
  Box,
  Button,
  Center,
  Link,
  ListItem,
  OrderedList,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Clipboard from "react-clipboard.js";

import { StagesAccordion } from "../StagesAccordion";

interface Props {
  isLoading: boolean;
  nextStep: any;
  appId: string;
}
const shopifyTag = (id: string) => {
  return `function lHzpAsy(u, c) {
  var d = document,
    t = "script",
    o = d.createElement(t),
    s = d.getElementsByTagName(t)[0];
  o.src = u;
  o.onload = function (e) {
    c(null, e);
  };
  s.parentNode.insertBefore(o, s);
}

lHzpAsy("https://assets.humanz.com/${id}/humanz-shopifyv2.js", function () {
  try {
    var hmz = new window.hzi("${id}");
    analytics.subscribe("page_viewed", (event) => {
      hmz.track("page_viewed", event);
    });
    analytics.subscribe("product_viewed", (event) => {
      hmz.track("product_viewed", event);
    });
    analytics.subscribe("product_added_to_cart", (event) => {
      hmz.shopifyAddToCart(event);
    });
    analytics.subscribe("checkout_started", (event) => {
      hmz.shopifyCheckoutStarted(event);
    });
    analytics.subscribe("checkout_completed", (event) => {
      hmz.shopifyPurchase(event);
    });
  } catch (e) {}
});
`;
};

const shopifySteps: any = (setCurrentStep: any, id: string, toast: any) => [
  {
    id: 0,
    title: "Login to your store",
    body: (
      <>
        <Box className="small-step">
          <p>
            Open your web browser and go to Shopify. <br />
            Log in to your Shopify account using your credentials.
            <Center>
              <Link
                href="https://www.shopify.com/store-login"
                target={"_blank"}
                onClick={() => setCurrentStep(1)}
              >
                <Button m={5} size="lg" colorScheme={"green"}>
                  Login to your store&nbsp;
                  <i className="far fa-external-link" />
                </Button>
              </Link>
            </Center>
          </p>
        </Box>
      </>
    ),
  },
  {
    id: 1,
    title: "Go to settings",
    body: (
      <>
        <Box className="small-step">
          On the left-hand side of your Shopify dashboard, click on Settings.
          <br />
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/shopifyv2/shopify01.png"
            alt=""
            width="100%"
          />
        </Box>
      </>
    ),
  },
  {
    id: 2,
    title: "Navigate to Customer events",
    body: (
      <>
        <Box className="small-step">
          In the Settings menu, find and click on Customer Events.
          <br />
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/shopifyv2/shopify02.png"
            alt=""
            width="100%"
          />
        </Box>
      </>
    ),
  },
  {
    id: 3,
    title: "Name the Script",
    body: (
      <>
        <Box className="small-step">
          <OrderedList>
            <ListItem>
              You will see an option to add a new script. Click on it.
            </ListItem>
            <ListItem>
              A dialog box will appear asking for a name for your script.
            </ListItem>
            <ListItem>
              Name the script as Humanz Pixel or any other name that you like.
            </ListItem>
          </OrderedList>
          <br />
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/shopifyv2/shopify03.png"
            alt=""
            width="100%"
          />
        </Box>
      </>
    ),
  },
  {
    id: 4,
    title: "Paste the Provided Script and save",
    body: (
      <>
        <Box className="small-step">
          <OrderedList>
            <ListItem>
              Below the script name, there will be a text box or an editor area.
            </ListItem>
            <ListItem>
              Copy the script:
              <br />
              <p>
                <pre style={{ position: "relative" }}>
                  {" "}
                  <Clipboard
                    style={{
                      background: "transparent",
                      border: "none",
                      right: 0,
                      top: 5,
                      position: "absolute",
                    }}
                    data-clipboard-text={shopifyTag(id)}
                    onClick={() =>
                      toast({
                        title: "Code copied to your clipboard",
                        status: "success",
                        duration: 1000,
                        isClosable: false,
                      })
                    }
                  >
                    <Tooltip hasArrow label="Copy to clipboard">
                      <i className="far fa-copy copy-btn" />
                    </Tooltip>
                  </Clipboard>
                  {shopifyTag(id)}
                </pre>
              </p>
            </ListItem>
            <ListItem>
              Paste the script into the text box or editor area instead of the
              default content that is there.
            </ListItem>
            <ListItem>
              After pasting the script, look for a Save button (usually at the
              bottom or top-right corner of the page).
            </ListItem>
            <ListItem>Click &quot;Save&quot;</ListItem>
          </OrderedList>
          <br />
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/shopifyv2/shopify04.png"
            alt=""
            width="100%"
          />
        </Box>
      </>
    ),
  },
];

export const Shopify = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);

  const toast = useToast();
  useEffect(() => {
    setSteps(shopifySteps(setCurrentStep, props.appId, toast));
  }, [props]);

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <StagesAccordion
      currentStep={currentStep}
      isLoading={props.isLoading}
      nextStep={nextStep}
      prevStep={prevStep}
      completeStage={props.nextStep}
      steps={steps}
      description={null}
      eventValidation={true}
      allowNextSteps={true}
      setStep={(step: any) => () => setCurrentStep(step.id)}
    />
  );
};
