/* eslint-disable react/no-string-refs */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Image, Input } from "@chakra-ui/react";
import { useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Toggle from "react-toggle";
import AccordionUI from "../../FormUI/AccordionUI";
import FormCard from "../../FormUI/FormCard";
import Indicator from "../../FormUI/Indicator";
import IndicatorDescription from "../../FormUI/IndicatorDescription";
import IndicatorImage from "../../FormUI/IndicatorImage";
import IndicatorInput from "../../FormUI/IndicatorInput";
import IndicatorSelect from "../../FormUI/IndicatorSelect";
import IndicatorTextarea from "../../FormUI/IndicatorTextarea";
import IndicatorToggle from "../../FormUI/IndicatorToggle";
import AddBrandModal from "./AddBrandModal";
import EditBrandModal from "./EditBrandModal";

type Props = {
  state: any;
  setState: any;
  errorFields: any;
  overview: any;
  categories: any;
  user: any;
  CAMPAIGN_TYPES: { value: string; label: string; description: string }[];
  shortLinkLoading: any;
  shortLink: any;
  DEFAULT_LINK: any;
  moodImages: any;
  changeShortLink: any;
  renderAudienceOptins: any;
  addPicture: any;
  renderMoodImage: any;
  removeImage: any;
  addOrRemoveError: any;
  createUnfinishedIfNeeded: any;
  renderNotesBtn: any;
  isShare: any;
  editMode: any;
  children?: any;
  handleAddBrand: any;
  handleEditBrand: any;
  onChangeTabShowErrors: any;
};

const BriefOverview = ({
  state,
  setState,
  errorFields,
  overview,
  categories,
  user,
  renderNotesBtn,
  CAMPAIGN_TYPES,
  shortLinkLoading,
  shortLink,
  DEFAULT_LINK,
  moodImages,
  changeShortLink,
  renderAudienceOptins,
  addPicture,
  removeImage,
  addOrRemoveError,
  createUnfinishedIfNeeded,
  isShare,
  editMode,
  children,
  handleAddBrand,
  handleEditBrand,
  onChangeTabShowErrors,
}: Props) => {
  const [showWelcome, setShowWelcome] = useState(overview.welcome_message);
  const handleOnChangeWelcome = (value: any) => {
    overview.welcome_message = value;
  };

  return (
    <FormCard>
      <div className="brief-overview-section">
        <div className="create-campaign-title">
          <span>Overview</span>
          {renderNotesBtn(1)}
        </div>
        <AccordionUI header="General" open style={{ marginTop: "34px" }}>
          <div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "60%",
                  float: "left",
                  marginRight: "10%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 24,
                  marginBottom: 24,
                }}
              >
                <div>
                  <div className="blurable-large">
                    <IndicatorInput
                      header="What's the campaign name?"
                      onChange={(value) => {
                        overview.title = value;
                        addOrRemoveError("campaign_name", "remove");
                        setState({ updated: true });
                      }}
                      onBlur={() => {
                        createUnfinishedIfNeeded();
                        setState({ updated: true });
                      }}
                      value={overview.title}
                      placeholder="Campaign name"
                      disabled={isShare}
                      width="416px"
                    />
                    {errorFields.includes("campaign_name") && (
                      <div>
                        <i className="error-mark" />
                        <span className="error-text">
                          Whoops! This field can not be empty
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {user &&
                user.userDetails &&
                user.userDetails.sidebar_data &&
                user.userDetails.sidebar_data.allowed_campaign_as_brand ? (
                  <div>
                    <IndicatorToggle
                      header="Show publisher detail "
                      description="(Your Details And The Brand Details)"
                      iconOpen="fa-light fa-eye"
                      iconClose="fa-light fa-eye-slash"
                      onChange={() => {
                        overview.brand.show_advertiser_info =
                          !overview.brand.show_advertiser_info;
                        setState({ updated: true });
                      }}
                    />
                  </div>
                ) : null}

                <div>
                  <div className="blurable-large">
                    {!isShare ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IndicatorSelect
                          header="What's the brand name?"
                          options={state.brandNames}
                          placeholder={"Brand"}
                          defaultValue={overview?.brand.brand_id}
                          clearable={true}
                          labelKey={"key"}
                          valueKey={"value"}
                          onChange={(item) => {
                            overview.brand.brand_name = item?.key;
                            overview.brand.image_url =
                              state.brandImages[item?.key];
                            overview.brand.brand_id = item?.value;
                            setState({ updated: true });
                            addOrRemoveError("brand_name", "remove");
                          }}
                          name="brand"
                          searchable={true}
                        />
                        {overview?.brand.brand_name && (
                          <div
                            style={{
                              borderRadius: "4px",
                              background: "white",
                              color: "grey",
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              borderColor: "var(--chakra-colors-gray-300)",
                              marginTop: "29px",
                              width: "34px",
                            }}
                            onClick={() => setState({ editBrandModal: true })}
                          >
                            <i className="fa-thin fa-pen-to-square"></i>
                          </div>
                        )}
                        <div
                          style={{
                            borderRadius: "4px",
                            background: "#249ff0",
                            color: "white",
                            padding: "17px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            marginTop: "29px",
                            height: "34px",
                            width: "34px",
                          }}
                          onClick={() => setState({ addBrandModal: true })}
                        >
                          {state.addBrandModal && (
                            <AddBrandModal
                              onClose={() =>
                                setState({
                                  addBrandModal: false,
                                })
                              }
                              handleAddBrand={handleAddBrand}
                            ></AddBrandModal>
                          )}
                          <i
                            className="fa-regular fa-plus fa-sm"
                            style={{ fontSize: "14px" }}
                          ></i>
                        </div>
                      </div>
                    ) : (
                      <Indicator header="What's the brand name?">
                        <input
                          defaultValue={overview?.brand.brand_name}
                          disabled={true}
                          className="input-box"
                          style={{
                            width: "416px",
                            paddingLeft: "10px",
                            height: "35px",
                            color: "#333333",
                          }}
                        />
                      </Indicator>
                    )}

                    {state.editBrandModal && (
                      <EditBrandModal
                        onClose={() =>
                          setState({
                            editBrandModal: false,
                          })
                        }
                        brand={overview?.brand}
                        handleEditBrand={handleEditBrand}
                      ></EditBrandModal>
                    )}
                  </div>
                  {errorFields.includes("brand_name") && (
                    <div>
                      <i className="error-mark" />
                      <span className="error-text">
                        Whoops! You must select a brand
                      </span>
                    </div>
                  )}
                </div>

                <div>
                  <div className="blurable-large">
                    <IndicatorSelect
                      header="Choose campaign category"
                      placeholder="Category"
                      options={categories}
                      onChange={(item) => {
                        overview.categories = [item?.value];
                        setState({ updated: true }, () => {
                          addOrRemoveError("campaign_category", "remove");
                        });
                      }}
                      name="category"
                      searchable={true}
                      labelKey={"key"}
                      valueKey={"value"}
                      clearable={true}
                      defaultValue={
                        overview.categories ? overview.categories[0] : null
                      }
                    />
                  </div>
                  {errorFields.includes("campaign_category") && (
                    <div>
                      <i className="error-mark" />
                      <span className="error-text">
                        Whoops! You must select a category
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "0 0 160px",
                  height: "160px",
                }}
                className="uploadContainer"
              >
                {overview.brand.image_url ? (
                  <Image
                    src={overview.brand.image_url}
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa-regular fa-image"
                      style={{ fontSize: "57px", color: "#CFCFCF" }}
                    ></i>
                    <span
                      style={{
                        marginTop: "5px",
                        color: "#808080",
                        fontSize: "11px",
                        fontWeight: 400,
                      }}
                    >
                      Brand logo
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginBottom: 30 }}>
              <div style={{ width: "100%" }}>
                <div className="blurable-large">
                  <IndicatorDescription
                    style={{ width: "700px" }}
                    header="Campaign description"
                    value={overview.raw_description ?? overview.description}
                    setValue={(text) => {
                      overview.raw_description = text;
                      overview.description = text;
                      addOrRemoveError("campaign_description", "remove");
                      setState({ updated: true });
                    }}
                    isShare={isShare}
                    placeholder={
                      " Describe additional details about this campaign, and your vision for the influencers"
                    }
                  />
                </div>
              </div>
              {errorFields.includes("campaign_description") && (
                <div>
                  <i className="error-mark" />
                  <span className="error-text">
                    Whoops! This field can not be Empty
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* End Description Section */}

          {/* Image Section */}
          <div className="create-images-section">
            <IndicatorImage
              placeholder="Image"
              header="Add cover image"
              description="Help describing your campaign by adding reference images / mood board"
              moodImages_id={overview.images_ids}
              moodImages_urls={moodImages}
              addPicture={addPicture}
              createUnfinishedIfNeeded={createUnfinishedIfNeeded}
              deleteFile={removeImage}
              isShare={isShare}
            />
          </div>
          {/* End Image Section */}
        </AccordionUI>
        <AccordionUI header="Advanced">
          {/* Links section */}
          <div>
            {isShare ||
            (user.userDetails && user.userDetails.sidebar_data.is_premium) ? (
              <div style={{ marginBottom: 25 }}>
                <div className="blurable-large">
                  <IndicatorSelect
                    header="Specify campaign"
                    options={CAMPAIGN_TYPES}
                    onChange={(value) => {
                      overview.ribbon = value.value;
                      setState({ updated: true });
                    }}
                    clearable={false}
                    disabled={isShare || editMode}
                    style={{
                      background: "#E7F3FB",
                      color: "#31708f",
                      marginBottom: "8px",
                    }}
                    className="Select-value-label campaign-type"
                    defaultValue={overview.ribbon || "standard"}
                    coloredBlue
                  />
                  {CAMPAIGN_TYPES.find(
                    (x: {
                      value: string;
                      label: string;
                      description: string;
                    }) => x.value === overview.ribbon,
                  )?.description ? (
                    <div style={{ color: "#249FF0", fontSize: "12px" }}>
                      <i className="fa fa-info-circle" />{" "}
                      {
                        CAMPAIGN_TYPES.find(
                          (x: {
                            value: string;
                            label: string;
                            description: string;
                          }) => x.value === overview.ribbon,
                        ).description
                      }
                    </div>
                  ) : (
                    <div style={{ color: "#249FF0", fontSize: "12px" }}>
                      <i className="fa fa-info-circle" />{" "}
                      {CAMPAIGN_TYPES[0].description}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <Indicator style={{ marginBottom: 20 }}>
              <div>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#222325",
                  }}
                >
                  Links{" "}
                  <span className="optional-title" style={{ fontWeight: 400 }}>
                    (Optional, both fields should be filled if used)
                  </span>
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Short link
                </span>
                <div className="blurable-large" style={{ width: "416px" }}>
                  {shortLinkLoading ? (
                    <div className="loading-input-field"></div>
                  ) : (
                    <Input
                      value={shortLink}
                      placeholder={`${DEFAULT_LINK}campaign_name`}
                      focusBorderColor="#249FF0"
                      type="text"
                      onFocus={() => {
                        setState({
                          shortLink: shortLink || DEFAULT_LINK,
                        });
                      }}
                      onChange={(e) => {
                        const { value } = e.target;
                        const newValue = value.trim().toLowerCase();
                        if (
                          !newValue.includes(" ") &&
                          newValue.startsWith(DEFAULT_LINK)
                        ) {
                          setState({
                            shortLink: newValue,
                          });
                        }
                      }}
                      onBlur={(e) => changeShortLink(e)}
                      disabled={isShare}
                      style={{ marginTop: 3, paddingLeft: 5 }}
                      sx={{
                        "::placeholder": { color: "#95979D" },
                        background: "white",
                      }}
                    />
                  )}
                  {errorFields.includes("short_link") && (
                    <div>
                      <i className="error-mark" />
                      <span className="error-text">
                        This short link not avaliable try something else
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div style={{ marginTop: 15 }}>
                <span
                  style={{
                    color: "#808080",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Redirect to
                </span>
                <div className="blurable-large">
                  <Input
                    focusBorderColor="#249FF0"
                    defaultValue={overview.auction_link.long_link}
                    onChange={(e) => {
                      overview.auction_link.long_link = e.target.value;

                      onChangeTabShowErrors("overview_tab");
                      setState({ updated: true });
                    }}
                    placeholder="Your website url"
                    disabled={isShare}
                    style={{ marginTop: 3, paddingLeft: 5 }}
                    sx={{
                      "::placeholder": { color: "#95979D" },
                      background: "white",
                    }}
                  />
                  {errorFields.includes("auction_link") && (
                    <div>
                      <i className="error-mark" />
                      <span className="error-text">
                        Whoops! The link is invalid
                      </span>
                    </div>
                  )}
                  {(errorFields.includes("match_website_sales") ||
                    errorFields.includes("match_website")) && (
                    <div>
                      <i className="error-mark" />
                      <span className="error-text">
                        Whoops! This url domain must match the one on the{" "}
                        <span
                          className="link"
                          onClick={() => {
                            setState({
                              currentTab: "sales_tab",
                            });
                          }}
                        >
                          sales tab
                        </span>
                        .
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Indicator>
          </div>
          {/* End Links section */}

          {/* Influencers Tuning */}
          {/* End Influencers Tuning */}

          {/* Target audience section */}
          <Indicator style={{ marginBottom: "24px" }}>
            <span
              style={{
                fontSize: 14,
                fontWeight: 600,
                color: "#555555",
              }}
            >
              Target Audience
            </span>

            {state.audienceFields.map((x: any, i: number) =>
              renderAudienceOptins(x, i),
            )}
          </Indicator>
          <Indicator
            style={{ marginBottom: "24px" }}
            header="Add welcome message"
            optional
            description="A message will be sent to influencers when their bid/invitation is approved."
          >
            <div
              className="toggle-input"
              style={{ display: "flex", marginTop: "15px" }}
            >
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={showWelcome}
                  icons={false}
                  onChange={() => {
                    if (isShare) return;
                    setShowWelcome(!showWelcome);
                    handleOnChangeWelcome("");
                    setState({ updated: true });
                  }}
                  disabled={isShare}
                />
              </label>
            </div>
          </Indicator>
          {showWelcome && (
            <IndicatorTextarea
              value={overview.welcome_message}
              placeholder="welcome message"
              onChange={(value) => {
                handleOnChangeWelcome(value);
                setState({ updated: true });
              }}
              disabled={isShare}
            />
          )}

          {/* End Target audience section */}
        </AccordionUI>
      </div>
      {children}
    </FormCard>
  );
};

export default BriefOverview;
