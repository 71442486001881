import { Box } from "@chakra-ui/react";
import moment from "moment";
import React, { forwardRef, useState } from "react";
// import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import DateIcon from "../../../Icons/DateIcon";

const TaskDate = (props: {
  taskData: { due_date?: string };
  setTaskData: (arg0: any) => void;
}) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  // const [selectedDateString, setSelectedDateString] = useState("Date");

  const handleDateChange = (date: moment.Moment | null) => {
    setSelectedDate(date);

    if (date) {
      const formattedDateForStorage = date.toISOString();
      props.setTaskData({
        ...props.taskData,
        due_date: formattedDateForStorage,
      });
    }
  };
  const CustomInput = forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (props: any, ref: React.Ref<HTMLInputElement>) => (
      <input
        {...props}
        ref={ref}
        placeholder="Date"
        style={{
          borderRadius: "20px",
          paddingLeft: "5px",
          background: "rgba(235, 235, 235, 0.30)",
          border: "none",
          cursor: "pointer",
          width: "120px",
        }}
      />
    ),
  );

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: "3px",
        }}
      >
        <DateIcon />
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          customInput={<CustomInput />}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TaskDate;
