/* eslint-disable */
//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

export interface AcademyQuizQuestion {
  answer_options?: string[];
  description?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  num?: number;
  question?: string;
}

/**
 *
 * AdditionalPriceDetails
 *
 */

export interface AdditionalPrice {
  /**
   *
   * AdditionalPriceCalculatedFields
   *
   */
  calculated_fields: {
    /**
     *
     * - Format: double
     */
    commission_sum: number;
    /**
     *
     * - Format: double
     */
    minimum_usage_fee: number;
    /**
     *
     * - Format: double
     */
    total: number;
    /**
     *
     * - Format: double
     */
    total_tax_free: number;
    /**
     *
     * - Format: double
     */
    upfront_payment_sum: number;
    /**
     *
     * - Format: double
     */
    vat_sum: number;
  };
  /**
   *
   * - Format: double
   */
  commission: number;
  currency: Currency;
  /**
   *
   * AdditionalPriceExtraPayouts
   *
   */
  extra_payouts: {
    display_status: "use_list" | "use_total" | "dont_use";
    /**
     *
     * - Format: double
     */
    total: number;
    extra_payouts_list?: ExtraPayoutDetailsWithId[];
  };
  /**
   *
   * bid or invite price
   * - Format: double
   */
  price: number;
  /**
   *
   * - Format: double
   */
  vat: number;
  /**
   *
   * - Format: double
   */
  current_month_completed_price?: number;
  /**
   *
   * - Format: double
   */
  current_month_price?: number;
  /**
   *
   * - Format: double
   */
  current_month_recurring_price?: number;
  /**
   *
   * - Format: double
   */
  recurring_price?: number;
  recurring_price_id?: string;
}

export interface AdvertiserData {
  about?: string;
  address?: string;
  company_name?: string;
  facebook?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  image?: string;
  instagram?: string;
  name?: string;
}

export interface AdvertiserDisputeDetailsExtended {
  /**
   *
   * - Format: double
   */
  amount?: number;
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  custom_message?: string;
  decision?: "trash" | "resolve" | "price_change";
  /**
   *
   * - Format: int64
   */
  dispute_id?: number;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  /**
   *
   * - Format: date
   */
  month_relevance?: string;
  name?: string;
  /**
   *
   * - Format: int64
   */
  pbi_fk?: number;
  response?: string;
  /**
   *
   * - Format: date-time
   */
  response_time?: string;
}

export interface AffiliateTimeline {
  /**
   *
   * - Format: date-time
   */
  end_date?: string;
  /**
   *
   * - Format: date-time
   */
  start_date?: string;
  timeline?: {
    /**
     *
     * - Format: date-time
     */
    key?: string;
    /**
     *
     * - Format: float
     */
    value?: number;
  }[];
  /**
   *
   * - Format: float
   */
  total?: number;
}

export interface AgeSegmentationData {
  /**
   *
   * - Format: double
   */
  adults?: number;
  /**
   *
   * - Format: double
   */
  children?: number;
  /**
   *
   * - Format: double
   */
  elderly?: number;
  groups?: {
    end_range?: number;
    /**
     *
     * - Format: double
     */
    percentage?: number;
    start_range?: number;
  }[];
  /**
   *
   * - Format: double
   */
  teens?: number;
}

export interface AgentExternalInfluencerInviteDetails {
  agent_company_name?: string;
  /**
   *
   * - Format: int64
   */
  agent_fk?: number;
  agent_name?: string;
}

export interface AgentsDashboard {
  active_influencers?: HomePageDashboardLongValue;
  campaigns_total?: HomePageDashboardLongValue;
  currency?: Currency;
  money_received?: DashboardFloatValue;
  total_bids?: HomePageDashboardLongValue;
  total_comission?: DashboardFloatValue;
  total_engagement?: HomePageDashboardLongValue;
  total_influencers?: HomePageDashboardLongValue;
  total_invites?: HomePageDashboardLongValue;
  total_purchases?: HomePageDashboardLongValue;
  total_sales?: DashboardFloatValue;
}

export interface AgentsDashboardTopCampaignsInfluencers {
  top_campaigns_engagement?: InfluencerCampaignPictureEngagement[];
  top_campaigns_sales?: InfluencerCampaignPictureSales[];
  top_influencers_engagement?: InfluencerCampaignPictureEngagement[];
  top_influencers_sales?: InfluencerCampaignPictureSales[];
}

export interface AllAdvertiserDetails {
  about?: string;
  advertiser_type?: "brand" | "agency";
  brand_list?: EditBrandInfo[];
  can_open_auctions?: boolean;
  categories?: string[];
  child_users?: Owner[];
  /**
   *
   * AllAdvertiserDetailsCompanyDetails
   *
   */
  company_details?: {
    address?: string;
    country?: string;
    facebook_page?: string;
    instagram_page?: string;
    invoice_email?: string;
    name?: string;
    twitter_page?: string;
    youtube_page?: string;
  };
  currency?: Currency;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
  full_name?: string;
  /**
   *
   * - Format: double
   */
  media_commission?: number;
  phone?: string;
  picture_url?: string;
  pixel_websites?: {
    app_id?: string;
    website_name?: string;
    website_url?: string;
  }[];
  sidebar_data?: SideBarData;
  sub_user_permission?: boolean;
  /**
   *
   * - Format: int64
   */
  user_id?: number;
  using_password_to_login?: boolean;
  /**
   *
   * - Format: double
   */
  vat_percentage?: number;
  verified?: boolean;
  waiting_enterprise_invite?: string;
}

export interface AllAgentDetails {
  /**
   *
   * AllAgentDetailsCompanyDetails
   *
   */
  company_details?: { country?: string; name?: string };
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
  finished_basic_details?: boolean;
  full_name?: string;
  humanz_verified_agent?: boolean;
  /**
   *
   * - Format: uuid
   */
  invite_uuid?: string;
  phone?: string;
  tax_id?: string;
  /**
   *
   * - Format: int64
   */
  user_id?: number;
  using_password_to_login?: boolean;
  verified_email?: boolean;
}

export interface AllUserTags {
  auction_tags?: MetaTag[];
  lists_tags?: MetaTag[];
}

export interface AllowedForUSBankAccount {
  allowed?: boolean;
}

export enum AllowedImageUploadTypes {
  "auction" = "auction",
  "task" = "task",
  "proposal" = "proposal",
  "instruction" = "instruction",
  "brand_logo" = "brand_logo",
  "product" = "product",
  "feed" = "feed",
  "widget" = "widget",
  "image" = "image",
  "invoice" = "invoice",
  "references" = "references",
}

export type AppUserReport = any;

export interface AuctionAndPercentage {
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  /**
   *
   * - Format: double
   */
  percentage?: number;
}

export interface AuctionData {
  /**
   *
   * - Format: int64
   */
  active_bids_count?: number;
  /**
   *
   * - Format: int64
   */
  id?: number;
  instructions?: DosAndDonts;
  is_active?: boolean;
  overview?: EditAuctionOverview;
  payments?: AuctionPayment;
  products?: EditAuctionProduct[];
  questions?: AuctionQuestions;
  sales?: AuctionSales;
  sales_enabled?: boolean;
  /**
   *
   * AuctionTasks
   *
   */
  tasks?: {
    /**
     *
     * AuctionCampaignDates
     *
     */
    campaign_dates?: {
      /**
       *
       * - Format: date-time
       */
      end?: string;
      /**
       *
       * - Format: date-time
       */
      start?: string;
    };
    description?: string;
    one_month_campaign?: boolean;
    task_stages?: TaskWithId[];
    tasks_payments_instructions?: TasksPaymentsInstructions;
  };
}

export interface AuctionDataForAgentExtended {
  advertiser_info?: AdvertiserData;
  allow_to_add_links?: boolean;
  allow_to_create_links?: boolean;
  api_restricted?: boolean;
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  auction_tasks?: TaskWithId[];
  auction_type?: AuctionTypes;
  bidding_gender?: string;
  brand_info?: EditBrandInfo;
  currency?: Currency;
  description?: string;
  /**
   *
   * - Format: date-time
   */
  end_time?: string;
  images?: string[];
  influencer_on_agent_list?: boolean;
  /**
   *
   * - pattern: ^[1-9][0-9]+$
   */
  influencers_state?: { [x: string]: AuctionInfluencerStatusForAgent };
  instructions?: DosAndDonts;
  key_info?: string;
  link?: AuctionLink;
  /**
   *
   * - Format: int64
   */
  max_followers?: number;
  /**
   *
   * - Format: int64
   */
  min_followers?: number;
  one_month_campaign?: boolean;
  participants_status?: ParticipantsStatus;
  /**
   *
   * - Format: date-time
   */
  payments_date?: string;
  payments_days_delay?: number;
  pixel_affiliator?: string;
  pixel_enabled?: boolean;
  products?: EditAuctionProduct[];
  questions?: AuctionQuestions;
  raw_description?: string;
  ribbon?: string;
  social_networks?: InfluencerAuctionsSocialNetworks[];
  /**
   *
   * - Format: date-time
   */
  start_time?: string;
  title?: string;
  use_site_link?: boolean;
}

export interface AuctionDataForInfluencer {
  advertiser_info?: AdvertiserData;
  /**
   *
   * - Format: date-time
   */
  approved_at?: string;
  auction_type?: AuctionTypes;
  bidding_gender?: string;
  brand_info?: EditBrandInfo;
  currency?: Currency;
  description?: string;
  early_payout?: AuctionDataForInfluencerEarlyPayout;
  /**
   *
   * - Format: date-time
   */
  end_time?: string;
  has_tasks_invites?: boolean;
  has_upfront_payment?: boolean;
  /**
   *
   * - Format: int64
   */
  id?: number;
  images?: string[];
  instructions?: FlatDosAndDonts;
  is_allow_bid?: boolean;
  key_info?: string;
  /**
   *
   * - Format: date-time
   */
  last_activity_time?: string;
  linked_media?: LinkedMediaForInfluencer[];
  /**
   *
   * - Format: int64
   */
  max_followers?: number;
  /**
   *
   * - Format: double
   */
  max_price?: number;
  /**
   *
   * - Format: int64
   */
  min_followers?: number;
  /**
   *
   * - Format: date-time
   */
  payments_date?: string;
  payments_days_delay?: number;
  products?: EditAuctionProduct[];
  progress?: InProgressAuctionForInfluencer;
  raw_description?: string;
  ribbon?: string;
  social_networks?: InfluencerAuctionsSocialNetworks[];
  /**
   *
   * - Format: date-time
   */
  start_time?: string;
  tasks?: TaskWithId[];
  title?: string;
}

/**
 *
 * AuctionDataForInfluencerEarlyPayout
 *
 */

export interface AuctionDataForInfluencerEarlyPayout {
  available_deals?: EarlyPayoutDeal[];
  /**
   *
   * AuctionDataForInfluencerEarlyPayoutSelectedDeal
   *
   */
  selected_deal?: {
    /**
     *
     * - Format: date-time
     */
    payment_due_date?: string;
    /**
     *
     * - Format: double
     */
    payment_final_price?: number;
  };
  status?:
    | "no_longer_available"
    | "no_yet_available"
    | "show_deals"
    | "show_selected_deal";
}

export type AuctionDataForInfluencerExtended = AuctionDataForInfluencer & {
  allow_analytics?: boolean;
  allow_phases_analytics?: boolean;
  allow_to_add_links?: boolean;
  allow_to_create_links?: boolean;
  api_restricted?: boolean;
  are_all_tasks_completed?: boolean;
  bundles_attributes?: BundleAttributes[];
  /**
   *
   * - Format: date-time
   */
  commission_end_time?: string;
  /**
   *
   * - Format: double
   */
  commission_percentage?: number;
  coupons?: CouponInSales[];
  extra_payouts?: ExtraPayoutDetailsWithId[];
  invoice?: string;
  invoices_urls?: InfluencerInvoiceDetails[];
  /**
   *
   * - Format: date-time
   */
  last_task_approval_date?: string;
  link?: AuctionLink;
  links?: AuctionDataForInfluencerLink[];
  offer_details?: OfferDetails;
  one_month_campaign?: boolean;
  participants_status?: ParticipantsStatus;
  pixel_affiliator?: string;
  questions?: AuctionQuestionWithAnswer[];
  special_program?: SpecialProgramDetails;
  /**
   *
   * - Format: date
   */
  start_from?: string;
  suggestions_links?: AuctionSuggestionLinks;
  tasks?: TaskManageData[];
  tasks_invites?: TaskWithPrice[];
  use_site_link?: boolean;
};

export interface AuctionDataForInfluencerLink {
  created_by_influencer?: boolean;
  description?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  long_link?: string;
  short_link?: string;
}

export interface AuctionInMultiReportData {
  /**
   *
   * - Format: int64
   */
  id?: number;
  image?: string;
  title?: string;
}

export interface AuctionInfluencerStatusForAgent {
  allow_analytics?: boolean;
  allow_phases_analytics?: boolean;
  /**
   *
   * - Format: date-time
   */
  approved_at?: string;
  are_all_tasks_completed?: boolean;
  /**
   *
   * - Format: date-time
   */
  commission_end_time?: string;
  /**
   *
   * - Format: double
   */
  commission_percentage?: number;
  community_badge?: CommunityBadge;
  coupons?: CouponInSales[];
  early_payout?: AuctionDataForInfluencerEarlyPayout;
  extra_payouts?: ExtraPayoutDetailsWithId[];
  has_tasks_invites?: boolean;
  has_upfront_payment?: boolean;
  /**
   *
   * - Format: int64
   */
  humanz_fk?: number;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  influencer_tasks?: TaskManageData[];
  invoice?: string;
  invoices?: string[];
  is_allow_bid?: boolean;
  /**
   *
   * - Format: date-time
   */
  last_activity_time?: string;
  /**
   *
   * - Format: date-time
   */
  last_task_approval_date?: string;
  links?: AuctionDataForInfluencerLink[];
  /**
   *
   * - Format: double
   */
  max_price?: number;
  name?: string;
  /**
   *
   * - Format: int64
   */
  pbi_id?: number;
  picture_url?: string;
  progress?: InProgressAuctionForInfluencer;
  questions?: AuctionQuestionWithAnswer[];
  social_network_data?: InfluencerInManageAuctionDataSocialNetworkData;
  suggestions_links?: AuctionSuggestionLinks;
  tasks_invites?: TaskWithPrice[];
  user_registered?: boolean;
}

export interface AuctionInstruction {
  description?: string;
  image?: string;
}

export interface AuctionLink {
  long_link?: string;
  short_link?: string;
}

/**
 *
 * auctionOverview
 *
 */

export interface AuctionOverview {
  auction_link?: AuctionLink;
  brand?: BrandInfo;
  categories?: string[];
  description?: string;
  image_urls?: string[];
  images_ids?: string[];
  key_info?: string;
  raw_description?: string;
  ribbon?:
    | "default"
    | "express"
    | "survey"
    | "deal"
    | "special"
    | "limited"
    | "open";
  target_audience?: AuctionTargetAudience;
  title?: string;
  welcome_message?: string;
}

export type AuctionPayment = EditAuctionPayments & { currency?: Currency };

export interface AuctionPaymentsData {
  months?: {
    /**
     *
     * - Format: double
     */
    full_price_to_bill?: number;
    influencers?: {
      community_badge?: CommunityBadge;
      /**
       *
       * - Format: double
       */
      community_fee_percentage?: number;
      dispute_details?: AdvertiserDisputeDetailsExtended;
      image_url?: string;
      /**
       *
       * - Format: int64
       */
      influencer_id?: number;
      invoices?: InfluencerInvoiceDetails[];
      name?: string;
      on_dispute?: boolean;
      payments?: {
        agent_details?: {
          /**
           *
           * - Format: int64
           */
          id?: number;
          name?: string;
        };
        based_on_task_ids?: number[];
        based_on_task_type?: PostTypes;
        can_be_removed?: boolean;
        charged?: boolean;
        comment?: string;
        /**
         *
         * - Format: double
         */
        commission?: number;
        complete_at_end_of_month?: boolean;
        /**
         *
         * - Format: date-time
         */
        due_date?: string;
        /**
         *
         * - Format: int64
         */
        extra_payment_id?: number;
        id?: string;
        is_completed?: boolean;
        ongoing_price?: boolean;
        only_commission?: boolean;
        /**
         *
         * - Format: double
         */
        paid_amount?: number;
        payment_type?:
          | "affiliation"
          | "extra"
          | "base"
          | "tasks"
          | "extra_task"
          | "recurring_payment"
          | "target_bonus";
        /**
         *
         * - Format: double
         */
        price?: number;
        stopped_next_month?: boolean;
        /**
         *
         * - Format: int64
         */
        target_step_id?: number;
        /**
         *
         * UpfrontPaymentDetails
         *
         */
        upfront_details?: {
          /**
           *
           * - Format: int64
           */
          id?: number;
          paid?: boolean;
          /**
           *
           * - Format: double
           */
          price?: number;
        };
        /**
         *
         * - Format: double
         */
        vat?: number;
      }[];
      /**
       *
       * - Format: double
       */
      recurring_price?: number;
      stop_next_month?: boolean;
      trashed?: boolean;
    }[];
    /**
     *
     * - Format: date
     */
    month?: string;
  }[];
  summary?: {
    affiliation_manual_approved?: boolean;
    /**
     *
     * - Format: double
     */
    all_time_influencers_total?: number;
    /**
     *
     * - Format: double
     */
    all_time_total?: number;
    payment_method?: string;
    /**
     *
     * - Format: double
     */
    total_charged?: number;
    /**
     *
     * - Format: double
     */
    total_not_charged?: number;
    /**
     *
     * - Format: double
     */
    total_not_paid?: number;
    /**
     *
     * - Format: double
     */
    total_paid?: number;
  };
}

export interface AuctionPhase {
  /**
   *
   * - Format: date-time
   */
  end_time: string;
  influencers: number[];
  /**
   *
   * - Format: date-time
   */
  start_time: string;
  title: string;
  brands_excluded?: string[];
  brands_included?: string[];
  color?: string;
  /**
   *
   * - Format: float
   */
  commission?: number;
  /**
   *
   * - Format: int64
   */
  id?: number;
  main_override?: boolean;
  skus_exceluded?: string[];
  skus_included?: string[];
}

export interface AuctionProduct {
  description?: string;
  gift?: boolean;
  image?: string;
  /**
   *
   * - Format: uuid
   */
  image_id?: string;
  image_url?: string;
  name?: string;
  shipping?: boolean;
  url?: string;
  /**
   *
   * - Format: float
   */
  value?: number;
}

export interface AuctionQuestion {
  id?: string;
  min_letters?: number;
  options?: string[];
  question?: string;
  question_type?: QuestionTypes;
}

export type AuctionQuestionWithAnswer = AuctionQuestion & {
  answer?: string;
  numeric_answer?: number;
};

export type AuctionQuestions = AuctionQuestion[];

export interface AuctionSales {
  allow_influencers_create_links?: boolean;
  allow_influencers_links?: boolean;
  brands_included?: string[];
  /**
   *
   * - Format: double
   */
  commission_percentage?: number;
  coupons?: CouponInSales[];
  currency?: Currency;
  link_google_utms?: boolean;
  /**
   *
   * - Format: double
   */
  net_sales_percentage?: number;
  pixel_app_id?: string;
  sales_enabled?: boolean;
  /**
   *
   * - Format: date-time
   */
  sales_end_time?: string;
  /**
   *
   * - Format: date-time
   */
  sales_start_time?: string;
  /**
   *
   * timezone from allowed list
   * - maxLength: 64
   */
  sales_timezone?: string;
  skus?: {
    is_deleted?: boolean;
    name?: string;
    sku_id?: string;
    uuid?: string;
  }[];
  skus_excluded?: string[];
  skus_included?: string[];
  suggestion_links?: AuctionSuggestionLinks;
  utm_codes?: UtmCodes;
}

export type AuctionSuggestionLinks = {
  description?: string;
  is_deleted?: boolean;
  link?: string;
  uuid?: string;
}[];

/**
 *
 * AuctionTargetAudience
 *
 */

export interface AuctionTargetAudience {
  /**
   *
   * MultiSelectAge
   *
   */
  age?: "child" | "teen" | "adult" | "elderlies"[];
  /**
   *
   * MultiSelectGender
   *
   */
  gender?: "female" | "male"[];
  location?: { country?: string; districts?: string[] }[];
  /**
   *
   * TargetAudienceInfluencerTuning
   *
   */
  tuning?: {
    /**
     *
     * - Format: float
     */
    a?: number;
    /**
     *
     * - Format: float
     */
    b?: number;
    /**
     *
     * - Format: float
     */
    micro?: number;
  };
}

export interface AuctionToDoItem {
  completed?: boolean;
  description?: string;
  /**
   *
   * - Format: date-time
   */
  due_date?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  order?: number;
  tag?: string;
  title?: string;
}

export enum AuctionTypes {
  "unfinished" = "unfinished",
  "widget" = "widget",
  "premium" = "premium",
  "normal" = "normal",
  "barter" = "barter",
  "express" = "express",
  "survey" = "survey",
  "auto_approve_bids" = "auto_approve_bids",
}

export interface AvgViewsAndBasedAmount {
  amount_based?: number;
  /**
   *
   * - Format: double
   */
  avg_views?: number;
}

export interface BankAccountDetails {
  /**
   *
   * - minLength: 2
   */
  account_number?: string;
  account_type?: BankAccountType;
  /**
   *
   * - Format: int32
   */
  bank_number?: number;
  branch_number?: string;
  /**
   *
   * - minLength: 2
   */
  full_name?: string;
  /**
   *
   * - minLength: 2
   */
  identity_number?: string;
  tax_number?: string;
}

/**
 *
 * relevant for Brazil only atm
 */

export enum BankAccountType {
  "checking" = "checking",
  "savings" = "savings",
}

export interface BidStatus {
  agent_name?: string;
  approved?: boolean;
  bid?: number;
  /**
   *
   * - Format: date-time
   */
  bid_at?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  managed_by_agent?: boolean;
}

export interface BillingAddress {
  address?: string;
  address_line_2?: string;
  city?: string;
  country?: string;
  phone?: string;
  /**
   *
   * US state, ISO-3166-2, or Canada province, or Mexico state
   * - pattern: ^$|^((AL)|(AK)|(AZ)|(AR)|(CA)|(CO)|(CT)|(DE)|(FL)|(GA)|(HI)|(ID)|(IL)|(IN)|(IA)|(KS)|(KY)|(LA)|(ME)|(MD)|(MA)|(MI)|(MN)|(MS)|(MO)|(MT)|(NE)|(NV)|(NH)|(NJ)|(NM)|(NY)|(NC)|(ND)|(OH)|(OK)|(OR)|(PA)|(RI)|(SC)|(SD)|(TN)|(TX)|(UT)|(VT)|(VA)|(WA)|(WV)|(WI)|(WY)|(DC)|(AS)|(GU)|(MP)|(PR)|(VI)|(AB)|(BC)|(MB)|(NB)|(NL)|(NS)|(ON)|(PE)|(QC)|(SK)|(NT)|(NU)|(YT)|(AGU)|(BCN)|(BCS)|(CAM)|(CHP)|(CHH)|(CMX)|(COA)|(COL)|(DUR)|(GUA)|(GRO)|(HID)|(JAL)|(MIC)|(MOR)|(MEX)|(NAY)|(NLE)|(OAX)|(PUE)|(QUE)|(ROO)|(SLP)|(SIN)|(SON)|(TAB)|(TAM)|(TLA)|(VER)|(YUC)|(ZAC))$
   */
  state?: string;
  tax_number?: string;
  /**
   *
   * - pattern: (^[0-9\./-]+$)|(^([A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}|GIR ?0A{2})$)|(^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$)
   */
  zip?: string;
}

/**
 *
 * BrandInfo
 *
 */

export interface BrandInfo {
  /**
   *
   * - Format: int64
   */
  brand_id?: number;
  brand_name?: string;
  /**
   *
   * - Format: uuid
   */
  image_id?: string;
  image_url?: string;
  show_advertiser_info?: boolean;
}

export type BundleAttributes = any;

export interface Campaign {
  acquiring_product_instructions?: string;
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  /**
   *
   * - Format: int64
   */
  bidder_id?: number;
  bidder_username?: string;
  /**
   *
   * - Format: int64
   */
  campaign_id?: number;
  campaign_type?: string;
  contact_details?: string;
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  description?: string;
  image_uri?: string;
  last_instructions?: string;
  last_remark?: string;
  /**
   *
   * CampaignLinkedPost
   *
   */
  linked_post?: {
    linked_post_uri?: string;
    /**
     *
     * - Format: int64
     */
    proposal_id?: number;
  };
  post_comments?: number;
  post_likes?: number;
  state?: string;
  tags?: string[];
}

export interface CampaignFile {
  file_name?: string;
  file_url?: string;
}

export interface CampaignFileIdentifier {
  url?: string;
  /**
   *
   * - Format: uuid
   */
  uuid?: string;
}

/**
 *
 * CampaignReportFilterParameters
 *
 */

export interface CampaignReportFilterParameters {
  influencers?: number[];
  /**
   *
   * PixelPurchaseFilter
   *
   */
  pruchase_filter?: {
    coupons?: string[];
    only_sales_influencers?: boolean;
    phases?: number[];
    skus?: string[];
  };
  tag_ids?: number[];
  time_filter?: TimeFilterWithoutTimeZone;
}

export interface CampaignShareParameters {
  /**
   *
   * DataForCampaignSharing
   *
   */
  campaign?: {
    client_approves_content?: boolean;
    link?: string;
    share_all_content?: boolean;
    share_brief?: boolean;
    share_campaign_tags?: boolean;
    share_content?: boolean;
    share_cost_per_influencer?: boolean;
    share_enabled?: boolean;
    share_tracker?: boolean;
    /**
     *
     * - Format: date-time
     */
    sharing_end_time?: string;
  };
  list?: ShareListParameters;
  password_protected?: string;
  roi?: ShareReportParameters;
  /**
   *
   * CampaignShareParametersTracker
   *
   */
  tracker?: { events?: TrackerEvent[]; guid?: string; share_enabled?: boolean };
}

export type CampaignShareParametersWithOwnerDetails =
  CampaignShareParameters & { owner_details?: ShareOwnerDetails };

export interface CampaignSummary {
  /**
   *
   * - Format: int64
   */
  active_bids_count?: number;
  all_tasks?: number;
  /**
   *
   * - Format: int64
   */
  bid_max_price?: number;
  /**
   *
   * - Format: int64
   */
  campaign_list_id?: number;
  campaign_type?: AuctionTypes;
  categories?: string[];
  completed_tasks?: number;
  currency?: Currency;
  /**
   *
   * - Format: int64
   */
  id?: number;
  image?: string;
  long_link?: string;
  name?: string;
  pixel_enabled?: boolean;
  shared_campaign?: boolean;
  short_link?: string;
  /**
   *
   * CampaignSummarySocialNetworks
   *
   */
  social_networks?: {
    instagram?: SocialNetworkCampaignSummary;
    tiktok?: SocialNetworkCampaignSummary;
    twitter?: SocialNetworkCampaignSummary;
    youtube?: SocialNetworkCampaignSummary;
  };
  /**
   *
   * - Format: double
   */
  total_cost?: number;
  waiting_approval?: boolean;
}

export interface CampaignTag {
  color?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  influencers?: number[];
  label?: string;
}

export interface CartItem {
  brand?: string;
  coupon_used?: string;
  item_name?: string;
  item_quantity?: number;
  /**
   *
   * - Format: double
   */
  price?: number;
  sku?: string;
}

export type CategoriesAgainstAvg = {
  /**
   *
   * - Format: double
   */
  avg_percentage?: number;
  category?: string;
  /**
   *
   * - Format: double
   */
  percentage?: number;
}[];

export interface ChallengeDetails {
  challenge_id?: string;
  challenge_type?: "phone" | "email";
  code?: string;
}

export type Chats = {
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  campaign_column?: string;
  /**
   *
   * - Format: date-time
   */
  first_message_time?: string;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  influencer_social_network_data?: InfluencerInManageAuctionDataSocialNetworkData;
  is_starred?: boolean;
  /**
   *
   * - Format: date-time
   */
  last_campaign_time?: string;
  last_message?: string;
  last_message_content_type?: string;
  last_message_mine?: boolean;
  /**
   *
   * - Format: date-time
   */
  last_message_time?: string;
  /**
   *
   * - Format: date-time
   */
  last_unread_message_time?: string;
  mark_deleted?: boolean;
  name?: string;
  picture_url?: string;
  /**
   *
   * - Format: int64
   */
  unseen_messages_count?: number;
  /**
   *
   * - Format: int64
   */
  user_id?: number;
}[];

export interface CollaborateInfluencerProfile {
  about?: string;
  age?: number;
  /**
   *
   * - Format: double
   */
  avg_engagement?: number;
  categories?: string[];
  gender?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  image?: string;
  instagram_followers?: number;
  /**
   *
   * - Format: int64
   */
  instagram_id?: number;
  instagram_username?: string;
  last_instagram_posts_ids?: string[];
  name?: string;
  username?: string;
}

export interface CollaborateMessagesReceived {
  got_more?: boolean;
  /**
   *
   * MessagesArray
   *
   */
  messages?: Message[];
}

export interface CommunityBadge {
  /**
   *
   * - Format: date-time
   */
  join_time?: string;
  name?: string;
  picture?: string;
}

export interface CommunityFeedComment {
  commenter?: UserOnFeed;
  comments?: number;
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  liked_by_me?: boolean;
  likes?: number;
  text?: string;
  text_format?: string;
}

export interface CommunityFeedItem {
  /**
   *
   * FeedItemApprovalStatus
   *
   */
  approval_status?: {
    current_remark?: string;
    is_approved?: boolean;
    is_waiting_approval?: boolean;
    last_remark?: string;
    number_of_attempts?: number;
  };
  /**
   *
   * - Format: int64
   */
  comments?: number;
  content?: string;
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  liked?: boolean;
  /**
   *
   * - Format: int64
   */
  likes?: number;
  link?: string;
  link_title?: string;
  medias?: { media_type?: "image" | "video"; media_url?: string }[];
  pinned?: boolean;
  /**
   *
   * FeedPollDetails
   *
   */
  poll_details?: {
    already_voted?: boolean;
    /**
     *
     * - Format: date-time
     */
    end_time?: string;
    options_and_votes?: {
      id?: number;
      option?: string;
      voted_for_it?: boolean;
      votes?: number;
    }[];
    total_votes?: number;
  };
  postType?: string;
  /**
   *
   * - Format: double
   */
  post_aspect_ratio?: number;
  text_format?: string;
  /**
   *
   * CommunityFeedItemUserDetails
   *
   */
  user_details?: {
    avatar?: string;
    /**
     *
     * - Format: int64
     */
    user_id?: number;
    username?: string;
  };
  /**
   *
   * - Format: int64
   */
  video_views?: number;
}

export interface CommunitySuggestion {
  content: string;
  /**
   *
   * - Format: date-time
   */
  created_at: string;
  is_read: boolean;
  profile_picture: string;
  social_media_username: string;
  /**
   *
   * - Format: int64
   */
  suggestion_id: number;
  /**
   *
   * - Format: int64
   */
  user_id: number;
}

export interface ContentSearchParameters {
  caption?: string[];
  /**
   *
   * - Format: date-time
   */
  end_time?: string;
  hashtag?: string[];
  location_name?: string;
  mention?: string[];
  min_comments?: number;
  min_likes?: number;
  post_type?: PostTypes;
  social_account?: "youtube" | "twitter" | "instagram" | "tiktok";
  specific_influencers?: number[];
  /**
   *
   * - Format: date-time
   */
  start_time?: string;
}

export type CountryToDistrictsAndCities = any;

export interface CouponInSales {
  /**
   *
   * - minLength: 1
   */
  coupon_id?: string;
  influencers?: number[];
  is_deleted?: boolean;
  name?: string;
  uuid?: string;
}

export interface Course {
  all_chapters?: {
    description?: string;
    /**
     *
     * - Format: int64
     */
    id?: number;
    is_completed?: boolean;
    number_of_lessons?: number;
    ordinal_number?: number;
    test_id?: string;
    title?: string;
    video_url?: string;
  }[];
  /**
   *
   * ChapterData
   *
   */
  current_chapter?: {
    description?: string;
    /**
     *
     * - Format: int64
     */
    id?: number;
    number_of_lessons?: number;
    ordinal_num?: number;
    short_description?: string;
    test_id?: string;
    title?: string;
    video_url?: string;
  };
  /**
   *
   * ChatperStatus
   *
   */
  current_chapter_status?: {
    completed?: boolean;
    completed_lessons?: boolean;
    completed_test?: boolean;
    /**
     *
     * - Format: int64
     */
    current_lesson_id?: number;
    lesson_number?: number;
    watched_video?: boolean;
  };
  description?: string;
  image?: string;
  owner?: Owner;
  subscription_status?: CourseSubscriptionStatus;
  title?: string;
}

export interface CourseSubscriptionStatus {
  chapters_completed?: number;
  is_completed?: boolean;
  language?: string;
  /**
   *
   * - Format: date-time
   */
  subscribed_at?: string;
  /**
   *
   * - Format: int64
   */
  translation_id?: number;
}

export interface CourseSummary {
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  eng_name?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  image?: string;
  owner?: Owner;
  subscription_status?: CourseSubscriptionStatus;
  summary_per_language?: {
    description?: string;
    /**
     *
     * - Format: int64
     */
    id?: number;
    language?: string;
    name?: string;
    num_chapters?: number;
  }[];
}

export interface CreateCommunityFeedItem {
  content?: string;
  country?: string;
  link?: string;
  link_title?: string;
  medias?: { media_type?: "image" | "video"; media_url?: string }[];
  mentions?: string[];
  /**
   *
   * CreationFeedPollDetails
   *
   */
  poll_details?: {
    /**
     *
     * - Format: date-time
     */
    end_time?: string;
    options?: string[];
  };
  postType?: string;
  /**
   *
   * - Format: double
   */
  post_aspect_ratio?: number;
  raw_content?: string;
  tags?: string[];
}

export enum CreditCardIssuer {
  "IsraCard" = "IsraCard",
  "VisaCAL" = "VisaCAL",
  "Diners" = "Diners",
  "AMEX" = "AMEX",
  "JCB" = "JCB",
  "LeumiCard" = "LeumiCard",
}

export enum Currency {
  "USD" = "USD",
  "NIS" = "NIS",
  "ZAR" = "ZAR",
  "EUR" = "EUR",
  "GBP" = "GBP",
  "AUD" = "AUD",
  "NZD" = "NZD",
  "TRY" = "TRY",
  "NGN" = "NGN",
  "BRL" = "BRL",
  "KES" = "KES",
  "INR" = "INR",
  "UL" = "UL",
}

export interface CurrentAndLimit {
  current?: number;
  limit?: number;
}

export interface DashboardDailySales {
  /**
   *
   * - Format: date-time
   */
  date?: string;
  /**
   *
   * - Format: float
   */
  sales_value?: number;
}

export interface DashboardFloatValue {
  /**
   *
   * - Format: float
   */
  value?: number;
}

export interface DateAndCount {
  /**
   *
   * - Format: int64
   */
  count?: number;
  /**
   *
   * - Format: date-time
   */
  date?: string;
}

export interface DeleteAgentInvoicesQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf - optional or see all
   * - Format: int64
   */
  influencer_id?: number;
}

export interface DeleteMoiAgentAgent_idBankIdQueryParams {
  /**
   *
   * The user_agent id to query
   * - Format: int64
   */
  user_agent_id?: number;
}

export interface DeleteMoiAuctionAuction_idTargetsDeleteQueryParams {
  /**
   *
   * The id of the target
   * - Format: int64
   */
  target_id: number;
}

export interface DeleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfrontQueryParams {
  /**
   *
   * The id of the payment to remove upfront from
   */
  id: string;
}

export interface DeleteMoiAuctionsAuction_idUpload_invoiceQueryParams {
  /**
   *
   * invoice ids to delete
   * - Format: int64
   */
  invoice_id?: number;
}

export interface DeleteMoiAuctionsRoiSlidesQueryParams {
  /**
   *
   * specific report id to soft delete
   * - Format: uuid
   */
  report_id?: string;
}

export interface DeleteMoiInboxIdStarQueryParams {
  /**
   *
   * The campaign related to the chat
   * - Format: int64
   */
  campaign_id?: number;
}

export interface DeleteShopifyRoiFilterQueryParams {
  /**
   *
   * the filter id
   * - Format: int64
   */
  filter_id: number;
}

export interface DeleteWixRoiFilterQueryParams {
  /**
   *
   * the filter id
   * - Format: int64
   */
  filter_id: number;
}

export interface DeviceInfo {
  app_version?: string;
  brand?: string;
  country?: string;
  model?: string;
  operation_system?: string;
  operation_version?: string;
  phone_id?: string;
  /**
   *
   * - Format: int64
   */
  user_id?: number;
}

/**
 *
 * DosAndDonts
 *
 */

export interface DosAndDonts {
  donts?: AuctionInstruction[];
  dos?: AuctionInstruction[];
}

/**
 *
 * EarlyPayoutDeal
 *
 */

export interface EarlyPayoutDeal {
  /**
   *
   * - Format: double
   * - minimum: 1
   */
  commission_percent?: number;
  /**
   *
   * - Format: uuid
   */
  deal_uid?: string;
  /**
   *
   * - Format: double
   * - minimum: 1
   */
  final_price?: number;
  /**
   *
   * - Format: double
   * - minimum: 1
   */
  gross_price?: number;
  /**
   *
   * - minimum: 1
   */
  max_days?: number;
  /**
   *
   * - minimum: 1
   */
  min_days?: number;
  /**
   *
   * - Format: date-time
   */
  payment_due_date?: string;
}

export interface EditAgentDetails {
  company_country: string;
  company_name: string;
  full_name: string;
  phone: string;
  currency?: Currency;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
  /**
   *
   * - Format: uuid
   */
  invite_uuid?: string;
  new_password?: string;
  old_password?: string;
  tax_id?: string;
}

export type EditAuctionOverview = AuctionOverview & {
  brand?: EditBrandInfo;
  images_ids?: string[];
};

/**
 *
 * NewAuctionDataPayments
 *
 */

export interface EditAuctionPayments {
  /**
   *
   * AuctionAllowBids
   *
   */
  allow_bids?: {
    /**
     *
     * AgeRange
     *
     */
    age_restriction?: { max_age?: number; min_age?: number };
    api_restricted?: boolean;
    country?: string;
    followers?: FollowersData;
    /**
     *
     * MultiSelectGender
     *
     */
    gender?: "female" | "male"[];
    max_participants?: number;
    /**
     *
     * PriceData
     *
     */
    price?: {
      /**
       *
       * - Format: double
       */
      max?: number;
      /**
       *
       * - Format: double
       */
      min?: number;
    };
    /**
     *
     * - Format: int64
     */
    restricted_list?: number;
    social_networks?: InfluencerAuctionsSocialNetworks[];
    special_prices?: {
      max_bid?: number;
      /**
       *
       * - Format: int64
       */
      min_followers?: number;
    }[];
  };
  influencers_amount_target?: number;
  /**
   *
   * - Format: float
   */
  media_budget?: number;
  monthly_flat_fee?: boolean;
  /**
   *
   * - Format: date-time
   */
  payments_date?: string;
  payments_days_delay?: number;
}

export type EditAuctionProduct = AuctionProduct & {
  /**
   *
   * - Format: int64
   */
  id?: number;
};

/**
 *
 * EditAuctionProducts
 *
 */

export type EditAuctionProducts = EditAuctionProduct[];

/**
 *
 * EditBrandInfo
 *
 */

export interface EditBrandInfo {
  /**
   *
   * - Format: int32
   */
  brand_id?: number;
  brand_name?: string;
  image_id?: string;
  image_url?: string;
  show_advertiser_info?: boolean;
}

export interface EditedKPIS {
  /**
   *
   * - Format: float
   */
  budget?: number;
  /**
   *
   * - Format: long
   */
  conversions?: number;
  currency?: Currency;
  /**
   *
   * - Format: long
   */
  engagement?: number;
  /**
   *
   * - Format: long
   */
  impressions?: number;
}

export enum EmbeddingSearchType {
  "content" = "content",
  "performance" = "performance",
  "words" = "words",
  "all" = "all",
}

export interface EndorseBody {
  category?: InfluencerCategory;
}

/**
 *
 * EnterpriseUser
 *
 */

export interface EnterpriseUser {
  email?: string;
  id?: number;
  image?: string;
  name?: string;
}

export type ExistingUSAgentBankAccount = InitUSAgentBankAccountSuccess &
  RegisteredUSAgentBankAccount;

export type ExtendedAuctionData = AuctionData & {
  /**
   *
   * - Format: double
   */
  price_offered?: number;
  price_offered_state?:
    | "wait_influencer"
    | "wait_advertiser"
    | "influencer_accepted"
    | "influencer_rejected";
};

export interface ExternalInfluencerInviteDetails {
  advertiser_logo?: string;
  company_name?: string;
  connected_username?: string;
  /**
   *
   * - Format: date-time
   */
  expiration_date?: string;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  influencer_name?: string;
  invited_username?: string;
  personal_message?: string;
  provider?: "youtube" | "instagram" | "twitter" | "tiktok";
  shared_uuid?: string;
}

export interface ExternalInvoiceData {
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  file_url?: string;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  /**
   *
   * - Format: date
   */
  month?: string;
}

export interface ExternalPageSettings {
  buttons?: {
    /**
     *
     * - Format: int64
     */
    id?: number;
    label?: string;
    link?: string;
    position?: number;
  }[];
  disable_page?: boolean;
  primary_color?: string;
  secondary_color?: string;
  video_first?: boolean;
}

/**
 *
 * ExtraDataFilter
 *
 */

export interface ExtraDataFilter {
  audience_categories?: string[];
  /**
   *
   * PostsFilter
   *
   */
  posts_filter?: {
    caption?: string;
    hashtag?: string[];
    mention?: string[];
    post_city?: string;
  };
  social_accounts?: "youtube" | "twitter" | "instagram" | "tiktok" | "all"[];
}

export interface ExtraPayoutDetails {
  /**
   *
   * - Format: double
   * - minimum: 1
   */
  amount: number;
  comment: string;
  /**
   *
   * - Format: date-time
   */
  month_relevance?: string;
}

export type ExtraPayoutDetailsWithId = ExtraPayoutDetails & {
  /**
   *
   * - Format: int64
   */
  id?: number;
};

export interface FacebookApiDetails {
  /**
   *
   * FacebookApiDetailsCapabilities
   *
   */
  capabilities?: { media_insights?: boolean; story_selection?: boolean };
  permissions?:
    | "pages_show_list"
    | "instagram_basic"
    | "instagram_manage_insights"
    | "pages_read_engagement"
    | "pages_manage_metadata"
    | "public_profile"
    | "email"
    | "business_management"[];
  /**
   *
   * - Format: date-time
   */
  sign_up_date?: string;
  /**
   *
   * - Format: date-time
   */
  token_expiration_date?: string;
}

export interface FileToUploadDetails {
  file_name?: string;
  file_type?: "image" | "video" | "pdf" | "doc" | "audio";
  upload_type?: AllowedImageUploadTypes;
}

export interface FiltersAndScrollProducts {
  filters?: CampaignReportFilterParameters;
  /**
   *
   * ScrollProducts
   *
   */
  scroll?: {
    amount_to_fetch?: number;
    /**
     *
     * - Format: int64
     */
    last_amount_seen?: number;
    /**
     *
     * - Format: double
     */
    last_price_seen?: number;
  };
}

export interface FlatDosAndDonts {
  donts?: string[];
  dos?: string[];
}

export interface FollowersData {
  /**
   *
   * - Format: int64
   */
  max?: number;
  /**
   *
   * - Format: int64
   */
  min?: number;
}

export interface FollowersDistribution {
  /**
   *
   * - Format: double
   */
  fraud_percentage?: number;
  /**
   *
   * - Format: double
   */
  influencers_percentage?: number;
  /**
   *
   * - Format: double
   */
  mass_following_percentage?: number;
  /**
   *
   * - Format: double
   */
  real_percentage?: number;
}

export interface Frequencies {
  /**
   *
   * - Format: int64
   */
  once?: number;
  /**
   *
   * - Format: int64
   */
  three_times_or_more?: number;
  /**
   *
   * - Format: int64
   */
  total_followers?: number;
  /**
   *
   * - Format: int64
   */
  twice?: number;
  /**
   *
   * - Format: int64
   */
  unique_followers?: number;
}

export interface GeneralError {
  debugStackTrace?: string;
  error?: string;
  extra_error_data?: { [x in string | number]: any };
  inner_error_code?: number;
}

export interface GetAcademyCoursesQueryParams {
  /**
   *
   * Filter for course title
   */
  filter?: string;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
}

export interface GetAgentAuctionsIdQueryParams {
  /**
   *
   * specific influencer id to filter, makes links and coupons return as well
   * - Format: int64
   */
  influencer_id?: number;
}

export interface GetAgentAuctionsQueryParams {
  /**
   *
   * Feed type to query
   */
  feed_type: "explore" | "in_progress" | "history" | "archive";
  /**
   *
   * Filter for titles and description
   */
  filter?: string;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  /**
   *
   * Filter for campaigns with pixel
   */
  with_pixel?: boolean;
}

export interface GetAgentInfluencerInfluencer_idQueryParams {
  segmentation?: boolean;
}

export interface GetAgentInfluencerStatusQueryParams {
  include_history?: boolean;
}

export interface GetAgentInfluencersQueryParams {
  isArchive?: boolean;
  segmentation?: boolean;
}

export interface GetAgentInvoicesQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf - optional or see all you influencers invoices
   * - Format: int64
   */
  influencer_id?: number;
}

export interface GetCollaborateGet_matchesQueryParams {
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  /**
   *
   * Filter specific chats
   */
  name_filter?: string;
}

export interface GetCollaborateInboxMatch_idQueryParams {
  /**
   *
   * query messages after time , support null ,cant be together with messages_after_time
   * - Format: date-time
   */
  messages_after_time?: string;
  /**
   *
   * query messages before time , supports null , cant be together with messages_after_time
   * - Format: date-time
   */
  messages_before_time?: string;
}

export interface GetForgot_passwordQueryParams {
  /**
   *
   * the user email address to create a password recovery process
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  user_email_address: string;
  captcha?: string;
  user_type?: "influencer" | "advertiser" | "agent";
}

export interface GetForgot_passwordValidateQueryParams {
  /**
   *
   * the user email address to validate with token
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email: string;
  /**
   *
   * the user token to validate
   */
  token: string;
}

export interface GetForgot_passwordValidate_influencerQueryParams {
  /**
   *
   * the user email address to validate with token
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email: string;
  /**
   *
   * the user token to validate
   */
  token: string;
}

export interface GetLogoutQueryParams {
  /**
   *
   * if logout from mobile device
   */
  isMobile?: boolean;
  /**
   *
   * if logout from mobile device send the phone id
   */
  phone_id?: string;
}

export interface GetMoiAgentAgent_idBankIdQueryParams {
  /**
   *
   * if true, will return bank account number (instead of display number)
   */
  show_account?: boolean;
  /**
   *
   * The user agent id to query
   * - Format: int64
   */
  user_agent_id?: number;
}

export interface GetMoiAgentAgent_idBankQueryParams {
  /**
   *
   * The user agent id to query
   * - Format: int64
   */
  user_agent_id?: number;
}

export interface GetMoiAgentAgent_idBankUsQueryParams {
  /**
   *
   * The user agent id to query
   * - Format: int64
   */
  user_agent_id?: number;
}

export interface GetMoiAgentQueryParams {
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  max_seen_time?: string;
  /**
   *
   * filter by name parameter
   */
  nameFilter?: string;
}

export interface GetMoiAgents_dashboardQueryParams {
  /**
   *
   * month to load dashbord for
   * - Format: date-time
   */
  month: string;
  /**
   *
   * filter for one influencer_id
   * - Format: int64
   */
  influencer_id?: number;
}

export interface GetMoiAgents_dashboardSales_graphQueryParams {
  /**
   *
   * month to load dashbord for
   * - Format: date-time
   */
  month: string;
  /**
   *
   * influencer_id to filter data for
   * - Format: int64
   */
  influencer_id?: number;
}

export interface GetMoiAgents_dashboardTop_influencers_campaginsQueryParams {
  /**
   *
   * month to load dashbord for
   * - Format: date-time
   */
  month: string;
  /**
   *
   * influencer_id to filter data for
   * - Format: int64
   */
  influencer_id?: number;
  /**
   *
   * show all infs/campaigns or only top
   */
  show_all?: boolean;
}

export interface GetMoiAuctionAuction_idTargetsQueryParams {
  /**
   *
   * The id of the influencer
   * - Format: int64
   */
  influencer_id?: number;
  /**
   *
   * The month to show targets for
   * - Format: date
   */
  month_relevance?: string;
}

export interface GetMoiAuctionManageAuction_idFilesInfluencer_idQueryParams {
  /**
   *
   * File Name with extension which will be implemented in the URL
   * - pattern: ^(.*?)(?i)(\.doc|\.docx|\.pdf|\.png|\.jpeg|\.jpg)$
   */
  file_name_with_extension: string;
}

export interface GetMoiAuctionManageAuction_idInfluencer_paymentsQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
}

export interface GetMoiAuctionManageAuction_idQueryParams {
  /**
   *
   * The month of the auction you want to see - for history.
   * - Format: date
   */
  month?: string;
}

export interface GetMoiAuctionsAuction_idInfluencer_pixel_analyticsQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
  /**
   *
   * from date to filter by
   * - Format: date-time
   */
  from?: string;
  /**
   *
   * if times are given, whether to ignore the time zone taking only date part
   */
  ignore_tz?: boolean;
  /**
   *
   * to date to filter by
   * - Format: date-time
   */
  to?: string;
}

export interface GetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdownQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
  /**
   *
   * from date to filter by
   * - Format: date-time
   */
  from?: string;
  /**
   *
   * if times are given, whether to ignore the time zone taking only date part
   */
  ignore_tz?: boolean;
  /**
   *
   * to date to filter by
   * - Format: date-time
   */
  to?: string;
}

export interface GetMoiAuctionsIdBidsQueryParams {
  /**
   *
   * filter
   */
  marketFilter?:
    | "teens"
    | "children"
    | "elderlies"
    | "adults"
    | "males"
    | "females";
  /**
   *
   * if you want to filter hidden or not hidden
   */
  show_hidden?: boolean;
}

export interface GetMoiAuctionsIdRoiQueryParams {
  /**
   *
   * Get if full roi if not - story only
   */
  full_roi?: boolean;
}

export interface GetMoiAuctionsInfluencerQueryParams {
  /**
   *
   * Feed type to query
   */
  feed_type: "explore" | "in_progress" | "history";
  /**
   *
   * Filter for titles and description
   */
  filter?: string;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
}

export interface GetMoiAuctionsLiteInfluencerQueryParams {
  /**
   *
   * Feed type to query
   */
  feed_type: "explore" | "in_progress" | "history" | "archive";
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
  /**
   *
   * Filter for titles and description
   */
  filter?: string;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  /**
   *
   * Filter for campaigns with pixel
   */
  with_pixel?: boolean;
}

export interface GetMoiAuctionsRoiSlidesQueryParams {
  /**
   *
   * specific auction id to query
   * - Format: int64
   */
  auction_id?: number;
  /**
   *
   * specific report id to query
   * - Format: uuid
   */
  report_id?: string;
}

export interface GetMoiCampaignsQueryParams {
  /**
   *
   * if you want to get the archived campaigns
   */
  archived_campaigns?: boolean;
  /**
   *
   * Filter for child account
   * - Format: int64
   */
  child_id?: number;
  /**
   *
   * Filter for title
   */
  filter?: string;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  /**
   *
   * if you want to get only campaigns from report stage
   */
  only_report_stage?: boolean;
  tags?: number[];
}

export interface GetMoiCommunity_feedApprovalQueryParams {
  /**
   *
   * Filter by country
   */
  feed_country?: string;
  /**
   *
   * - Format: date-time
   */
  last_seen_time?: string;
  order?: "ASC" | "DESC";
  /**
   *
   * Filter by feed text
   */
  text_filter?: string;
}

export interface GetMoiCommunity_feedPost_idCommentsQueryParams {
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  /**
   *
   * reply to comment id
   * - Format: int64
   */
  to_comment_id?: number;
}

export interface GetMoiCommunity_feedPost_idLikeQueryParams {
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  /**
   *
   * likes of comment id
   * - Format: int64
   */
  to_comment_id?: number;
}

export interface GetMoiCommunity_feedQueryParams {
  /**
   *
   * Feed country to query
   */
  feed_country: string;
  /**
   *
   * Filter by hashtag
   */
  hashtags_filter?: string;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  /**
   *
   * Filter by feed text
   */
  text_filter?: string;
  /**
   *
   * Filter By UserName
   */
  username_filter?: string;
}

export interface GetMoiCommunity_feedSuggestionAllQueryParams {
  /**
   *
   * flag for read suggestions
   */
  is_read?: boolean;
  /**
   *
   * last seen for paging
   * - Format: date-time
   */
  last_seen?: string;
  /**
   *
   * Time limit for community suggestions,since certain date,optional
   * - Format: date-time
   */
  since?: string;
  /**
   *
   * Time limit for fetch community suggestions,until certain date,optional
   * - Format: date-time
   */
  until?: string;
}

export interface GetMoiEdit_detailsInfluencerBankUsQueryParams {
  override_country?: "United States" | "Canada" | "Mexico";
}

export interface GetMoiEnterpriseUsers_logsQueryParams {
  /**
   *
   * Filter for actions
   */
  action_name?: string;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  /**
   *
   * Filter for sub_user
   * - Format: int64
   */
  sub_user_id?: number;
}

export interface GetMoiFollowers_segmentationHumanz_fkQueryParams {
  /**
   *
   * social network to get segmentation
   */
  social_network?: "instagram" | "twitter";
}

export interface GetMoiHomepage_dashboardQueryParams {
  /**
   *
   * month to load dashbord for
   * - Format: date-time
   */
  month: string;
  /**
   *
   * brand_id to filter campaigns for
   * - Format: int64
   */
  brand_id?: number;
}

export interface GetMoiHomepage_dashboardTop_influencersQueryParams {
  metric: "sales" | "engagement";
  /**
   *
   * month to load dashboard for
   * - Format: date-time
   */
  month: string;
  /**
   *
   * brand_id to filter campaigns for
   * - Format: int64
   */
  brand_id?: number;
}

export interface GetMoiInboxCampaignsQueryParams {
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  max_seen_time?: string;
  /**
   *
   * filter by name parameter
   */
  nameFilter?: string;
}

export interface GetMoiInboxInfluencersInfluencer_idQueryParams {
  /**
   *
   * get influencer related to auction id
   * - Format: int64
   */
  auctionId?: number;
}

export interface GetMoiInboxInfluencersQueryParams {
  /**
   *
   * get influencers related to auction id
   * - Format: int64
   */
  auctionId?: number;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  max_seen_time?: string;
  /**
   *
   * filter by name parameter
   */
  nameFilter?: string;
  /**
   *
   * filter by starred messages
   */
  starred?: boolean;
}

export interface GetMoiInfluencer_profileViewed_my_profileQueryParams {
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
}

export interface GetMoiInsightsQueryParams {
  /**
   *
   * Filter for child account
   * - Format: int64
   */
  child_id?: number;
}

export interface GetMoiInvitesExternalQueryParams {
  /**
   *
   * - Format: date-time
   */
  last_seen?: string;
  /**
   *
   * - Format: date-time
   */
  since?: string;
  /**
   *
   * - Format: date-time
   */
  until?: string;
}

export interface GetMoiPaymentPayment_from_advertiserDisputeQueryParams {
  /**
   *
   * - Format: int64
   */
  dispute_id: number;
}

export interface GetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_idQueryParams {
  /**
   *
   * last seen for paging
   * - Format: date-time
   */
  last_seen?: string;
  /**
   *
   * Time limit for fetch stories,since certain date,optional
   * - Format: date-time
   */
  since?: string;
  /**
   *
   * Time limit for fetch stories,until certain date,optional
   * - Format: date-time
   */
  until?: string;
}

export interface GetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_idQueryParams {
  /**
   *
   * caption to search in stories captions, optional
   */
  caption?: string;
  /**
   *
   * last seen for paging
   * - Format: date-time
   */
  last_seen?: string;
  /**
   *
   * Decides if to fetch from HFBI or from DB
   */
  refresh?: boolean;
  /**
   *
   * Time limit for fetch stories,since certain date,optional
   * - Format: date-time
   */
  since?: string;
  /**
   *
   * Time limit for fetch stories,until certain date,optional
   * - Format: date-time
   */
  until?: string;
}

export interface GetMoiPremiumInfluencer_storiesQueryParams {
  /**
   *
   * last seen for paging
   * - Format: date-time
   */
  last_seen?: string;
  /**
   *
   * Decides if to fetch from HFBI or from DB
   */
  refresh?: boolean;
  /**
   *
   * Time limit for fetch stories,since certain date,optional
   * - Format: date-time
   */
  since?: string;
  /**
   *
   * Time limit for fetch stories,until certain date,optional
   * - Format: date-time
   */
  until?: string;
}

export interface GetMoiPremiumListsQueryParams {
  archived_lists?: boolean;
}

export interface GetMoiPremiumListsUsers_mappingQueryParams {
  archived_lists?: boolean;
}

export interface GetMoiPremiumLists_with_scrollingQueryParams {
  archived_lists?: boolean;
  /**
   *
   * Filter for child account
   * - Format: int64
   */
  child_id?: number;
  /**
   *
   * Filter for title
   */
  filter?: string;
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
  only_favorite_lists?: boolean;
  tags?: number[];
}

export interface GetMoiReviewsInfluencerIdQueryParams {
  /**
   *
   * For paging, set max time seen of yet
   * - Format: date-time
   */
  last_seen_time?: string;
}

export interface GetMoiSupport_centerQueryParams {
  /**
   *
   * ticket status
   */
  status: "open" | "closed" | "archived"[];
  /**
   *
   * text filter to search in tickets, optional
   */
  filter?: string;
  /**
   *
   * Time limit for fetch tickets, since certain time, optional
   * - Format: date-time
   */
  since?: string;
  /**
   *
   * Time limit for fetch tickets, until certain time, optional
   * - Format: date-time
   */
  until?: string;
}

export interface GetMoiSupport_centerTicket_idQueryParams {
  /**
   *
   * Time limit for fetch ticket articles, since certain time, optional
   * - Format: date-time
   */
  since?: string;
  /**
   *
   * Time limit for fetch ticket articles, until certain time, optional
   * - Format: date-time
   */
  until?: string;
}

export interface GetMoiUpdate_centerQueryParams {
  /**
   *
   * The id of the last update shown
   * - Format: int64
   */
  from_update?: number;
}

export interface GetMoiUser_feature_feedbackQueryParams {
  /**
   *
   * form name
   */
  form_name: string;
}

export interface GetSharesBrand_reportTop_wordsQueryParams {
  compare_by?: string;
}

export interface GetSharesCampaignManageQueryParams {
  /**
   *
   * The month of the auction you want to see - for history.
   * - Format: date
   */
  month?: string;
}

export interface GetShopifyRoiFilterQueryParams {
  /**
   *
   * the filter id
   * - Format: int64
   */
  filter_id: number;
}

export interface GetUtilsSend_support_notificationUser_idQueryParams {
  /**
   *
   * related ticket_id
   * - Format: int64
   */
  ticket_id: number;
}

export interface GetUtilsTipaltiRequest_idQueryParams {
  /**
   *
   * the request type - could be app or website, app is default
   */
  type?: string;
}

export interface GetVerify_emailQueryParams {
  /**
   *
   * the user verification key
   */
  verification_key: string;
}

export interface GetWixRoiFilterQueryParams {
  /**
   *
   * the filter id
   * - Format: int64
   */
  filter_id: number;
}

export interface HomePageDashboardLongValue {
  /**
   *
   * - Format: int64
   */
  value?: number;
}

export interface HomepageDashboard {
  active_campaigns?: HomePageDashboardLongValue;
  content_tasks?: HomePageDashboardLongValue;
  influencers?: HomePageDashboardLongValue;
  /**
   *
   * - Format: date-time
   */
  last_update?: string;
  sales_daily?: DashboardDailySales[];
  total_bids?: HomePageDashboardLongValue;
  total_clicks?: HomePageDashboardLongValue;
  total_engagement?: HomePageDashboardLongValue;
  total_impressions?: HomePageDashboardLongValue;
  total_influencers_invited?: HomePageDashboardLongValue;
  total_influencers_selected?: HomePageDashboardLongValue;
  total_sale_orders?: HomePageDashboardLongValue;
  /**
   *
   * HomepageDashboardTotalSales
   *
   */
  total_sales?: {
    /**
     *
     * - Format: float
     */
    value?: number;
  };
  total_story_views?: HomePageDashboardLongValue;
  total_tasks?: HomePageDashboardLongValue;
  total_tasks_ig?: HomePageDashboardLongValue;
  total_tasks_story?: HomePageDashboardLongValue;
  total_tasks_tk?: HomePageDashboardLongValue;
  total_tasks_tw?: HomePageDashboardLongValue;
  total_tasks_yt?: HomePageDashboardLongValue;
  total_video_views?: HomePageDashboardLongValue;
}

export type ILBankAccount = BankAccountDetails & {
  israeli_tax_type?: "limited" | "authorized" | "exempt" | "nothing";
};

export type IgVideoViews = any;

export interface InProgressAuctionForInfluencer {
  /**
   *
   * - Format: double
   */
  total_price: number;
  bid_status?: BidStatus;
  current_column?: string;
  invite_status?: InviteStatus;
  status?: "chosen" | "approved" | "rejected" | "publish";
}

export type IndianBankAccount = BankAccountDetails & {
  ifsc?: string;
  upi?: string;
};

export interface InfluencerActivityData {
  locations?: {
    /**
     *
     * - Format: double
     */
    lat?: number;
    /**
     *
     * - Format: double
     */
    lon?: number;
  }[];
  monthly_activity?: {
    /**
     *
     * - Format: double
     */
    comments?: number;
    /**
     *
     * - Format: date-time
     */
    day?: string;
    /**
     *
     * - Format: double
     */
    dislikes?: number;
    /**
     *
     * - Format: double
     */
    likes?: number;
    /**
     *
     * - Format: int64
     */
    posts?: number;
    /**
     *
     * - Format: double
     */
    retweets?: number;
    /**
     *
     * - Format: double
     */
    shares?: number;
    /**
     *
     * - Format: double
     */
    views?: number;
  }[];
  top_cities?: {
    /**
     *
     * - Format: int64
     */
    count?: number;
    eng_name?: string;
    heb_name?: string;
  }[];
}

export interface InfluencerAgent {
  agent_id?: string;
  agent_name?: string;
}

export interface InfluencerAgentEntity {
  agents?: InfluencerAgent[];
  influencer_id?: string;
}

export interface InfluencerAgentInfo {
  added_by_you?: boolean;
  /**
   *
   * - Format: date-time
   */
  added_on?: string;
  /**
   *
   * - Format: int64
   */
  agent_id?: number;
  allowed_to_edit_agent?: boolean;
  campaigns_to_influencers?: {
    /**
     *
     * - Format: int64
     */
    campaign_id?: number;
    campaign_title?: string;
    influencers?: number[];
  }[];
  company_id?: string;
  email?: string;
  is_global_agent?: boolean;
  name?: string;
  phone?: string;
}

export interface InfluencerAgentInviteResponse {
  /**
   *
   * - Format: int64
   */
  agent_user_id: number;
  response: InfluencerAgentInviteStatus;
}

export enum InfluencerAgentInviteStatus {
  "accepted" = "accepted",
  "declined" = "declined",
  "pending" = "pending",
}

export interface InfluencerAllSocialData {
  activity_data?: InfluencerActivityData;
  additonal_audience_categories?: CategoriesAgainstAvg;
  general_data?: SocialNetworkGeneralData;
  request_data_info?: RequestDataInfluencerInfo;
  segmentation?: InfluencerFollowersSegmentation;
  similar_search_enabled?: boolean;
}

export interface InfluencerAuctionTag {
  color?: string;
  /**
   *
   * - Format: int32
   */
  id?: number;
  name?: string;
}

export enum InfluencerAuctionsSocialNetworks {
  "youtube" = "youtube",
  "twitter" = "twitter",
  "instagram" = "instagram",
  "tiktok" = "tiktok",
  "all" = "all",
}

export interface InfluencerCampaignPicture {
  /**
   *
   * - Format: int64
   */
  id?: number;
  name?: string;
  picture_url?: string;
}

export type InfluencerCampaignPictureEngagement = InfluencerCampaignPicture & {
  /**
   *
   * - Format: int64
   */
  engagement?: number;
};

export type InfluencerCampaignPictureMetric = InfluencerCampaignPicture & {
  /**
   *
   * - Format: float
   */
  metric?: number;
};

export type InfluencerCampaignPictureSales = InfluencerCampaignPicture & {
  /**
   *
   * - Format: float
   */
  sales?: number;
};

export interface InfluencerCardData {
  /**
   *
   * - Format: int64
   */
  humanz_fk?: number;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  influencer_info?: InfluencerExtendedData;
  influencer_shallow_info?: InfluencerShallowData;
  reviews_statistics?: ReviewsStatistics;
}

export type InfluencerCategories = InfluencerCategory[];

export enum InfluencerCategory {
  "home" = "home",
  "news" = "news",
  "science" = "science",
  "coaching" = "coaching",
  "gaming" = "gaming",
  "funny" = "funny",
  "sports" = "sports",
  "finance" = "finance",
  "art" = "art",
  "beauty" = "beauty",
  "bloggers" = "bloggers",
  "books" = "books",
  "family" = "family",
  "fashion" = "fashion",
  "fitness" = "fitness",
  "food" = "food",
  "health" = "health",
  "lifestyle" = "lifestyle",
  "motherhood" = "motherhood",
  "music" = "music",
  "nightlife" = "nightlife",
  "pets" = "pets",
  "photography" = "photography",
  "soldiers" = "soldiers",
  "tech" = "tech",
  "travel" = "travel",
  "vegan" = "vegan",
}

export type InfluencerChangePassword = any;

export interface InfluencerCollaborateFilterParameters {
  categories?: InfluencerCategories;
  countries?: string[];
  gender?: "male" | "female";
  /**
   *
   * - Format: int64
   */
  maximum_followers?: number;
  /**
   *
   * - Format: int64
   */
  minimum_followers?: number;
}

export interface InfluencerContactDetails {
  address?: string;
  email?: string;
  phone?: string;
}

export interface InfluencerCustomLink {
  created_by_influencer?: boolean;
  description?: string;
  /**
   *
   * - Format: int32
   */
  extra_link_number?: number;
  /**
   *
   * - Format: int64
   */
  link_id?: number;
  long_link?: string;
  short_link?: string;
}

export interface InfluencerDashboardSocialData {
  activity_data?: InfluencerActivityData;
  /**
   *
   * - Format: int32
   */
  score?: number;
  score_breakdown?: {
    name?: string;
    /**
     *
     * - Format: double
     */
    score?: number;
    /**
     *
     * - Format: double
     */
    value?: number;
  }[];
}

export interface InfluencerDetails {
  about?: string;
  apple_id?: string;
  bank_account_attached?: boolean;
  /**
   *
   * - Format: date-time
   */
  birth_date?: string;
  categories?: InfluencerCategories;
  collaboration_enabled?: boolean;
  community_badge?: CommunityBadge;
  /**
   *
   * InfluencerDetailsConnectedUserAgentDetails
   *
   */
  connected_user_agent_details?: {
    /**
     *
     * - Format: int64
     */
    connected_user_agent_fk?: number;
    display_name?: string;
  };
  contact_details?: string;
  /**
   *
   * - Format: date-time
   */
  deletion_requested_at?: string;
  eligble_early_payouts?: boolean;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
  email_verified?: boolean;
  facebook_id?: string;
  /**
   *
   * FeedPermissions
   *
   */
  feed_permissions?: {
    approver?: boolean;
    feed_allowed_type?: string[];
    need_approval?: boolean;
  };
  first_name?: string;
  full_name?: string;
  gender?: string;
  google_id?: string;
  image?: string;
  /**
   *
   * innerInfluencerDetails
   *
   */
  influencer_details?: {
    country?: string;
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
  };
  intro_thumbnail?: string;
  intro_video?: string;
  /**
   *
   * - Format: float
   */
  intro_video_ratio?: number;
  /**
   *
   * - Format: date-time
   */
  last_data_update_request_at?: string;
  last_name?: string;
  /**
   *
   * InfluencerLocation
   *
   */
  location?: {
    address?: string;
    /**
     *
     * InfluencerAddressPoint
     *
     */
    address_point?: {
      /**
       *
       * - Format: double
       */
      latitude?: number;
      /**
       *
       * - Format: double
       */
      longitude?: number;
    };
    city?: string;
    country?: string;
  };
  login_with_password_available?: boolean;
  need_to_rate_app?: boolean;
  /**
   *
   * - Format: date-time
   */
  next_data_update_request_at?: string;
  phone?: string;
  should_update_toc?: boolean;
  social_data?: InfluencerInManageAuctionDataSocialNetworkData;
  tax_number?: string;
  /**
   *
   * - Format: int64
   */
  user_id?: number;
  username?: string;
}

export type InfluencerDetailsEdit = InfluencerDetailsRegsitration & {
  about?: string;
  image?: UploadedImage;
};

export interface InfluencerDetailsRegsitration {
  address?: string;
  /**
   *
   * InfluencerAddressPoint
   *
   */
  address_point?: {
    /**
     *
     * - Format: double
     */
    latitude?: number;
    /**
     *
     * - Format: double
     */
    longitude?: number;
  };
  /**
   *
   * - Format: date-time
   */
  birth_date?: string;
  categories?: InfluencerCategories;
  challenge_id?: string;
  city?: string;
  contact_details?: string;
  country?: string;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
  first_name?: string;
  full_name?: string;
  gender?: "female" | "male";
  last_name?: string;
  phone_id?: string;
  /**
   *
   * - pattern: ^[0-9]+$
   */
  zip_code?: string;
}

export interface InfluencerExtendedData {
  academy_badge?: boolean;
  agent_details?: InfluencerAgent;
  community_badge?: CommunityBadge;
  contact_details?: InfluencerContactDetails;
  has_chat_open?: boolean;
  has_page_content?: boolean;
  /**
   *
   * - Format: int64
   */
  humanz_fk?: number;
  influencer_id?: string;
  instagram?: InfluencerAllSocialData;
  is_registered?: boolean;
  note?: string;
  rate_card?: InfluencerRateCard;
  reviews?: UserAvgReviewsData;
  tiktok?: InfluencerAllSocialData;
  twitter?: InfluencerAllSocialData;
  user_name?: string;
  youtube?: InfluencerAllSocialData;
}

/**
 *
 * InfluencerFollowersSegmentation
 *
 */

export interface InfluencerFollowersSegmentation {
  /**
   *
   * AgeByGenderSegmentationData
   *
   */
  age_by_gender_segmentation?: {
    females?: AgeSegmentationData;
    males?: AgeSegmentationData;
  };
  age_segmentation?: AgeSegmentationData;
  cities_segmentation?: {
    /**
     *
     * - Format: int64
     */
    amount?: number;
    eng_name?: string;
    heb_name?: string;
    /**
     *
     * - Format: double
     */
    percentage?: number;
  }[];
  countries_segmentation?: {
    /**
     *
     * - Format: int64
     */
    amount?: number;
    eng_name?: string;
    heb_name?: string;
    /**
     *
     * - Format: double
     */
    percentage?: number;
  }[];
  followers_distribution?: FollowersDistribution;
  fraud_examples?: { pic_url?: string; username?: string }[];
  /**
   *
   * - Format: double
   */
  fraud_percentage?: number;
  /**
   *
   * GenderSegmentationData
   *
   */
  gender_segmentation?: {
    /**
     *
     * - Format: double
     */
    female?: number;
    /**
     *
     * - Format: double
     */
    male?: number;
  };
  /**
   *
   * - Format: double
   */
  not_active_percentage?: number;
  top_categories?: {
    category_name?: string;
    /**
     *
     * - Format: double
     */
    percentage?: number;
  }[];
  verified_segmentation?: boolean;
}

export interface InfluencerIds {
  influencers_ids?: number[];
}

export type InfluencerInListData = InfluencerShallowData & {
  column_in_campaign?: string;
  currency?: Currency;
  hidden?: boolean;
  posts?: PorfileMediaAttached[];
  questions?: AuctionQuestionWithAnswer[];
  shadow_influencer?: boolean;
  shared_response_approved?: boolean;
  shared_response_note?: string;
  tasks_amount?: TasksAmounts;
  /**
   *
   * TasksDetails
   *
   */
  tasks_details?: {
    bundles_attributes?: BundleAttributes[];
    tasks?: TaskWithAdditonalPrice[];
  };
};

export interface InfluencerInManageAuctionData {
  /**
   *
   * - Format: int64
   */
  influencer_id: number;
  name: string;
  payment_status: "paid" | "upfront_paid" | "none_paid" | "early_payout";
  price_details: AdditionalPrice;
  academy_badge?: boolean;
  /**
   *
   * - Format: date-time
   */
  approved_at?: string;
  /**
   *
   * - Format: double
   */
  avg_review?: number;
  bid_status?: BidStatus;
  /**
   *
   * ChatWithInfluencerStatus
   *
   */
  chat_status?: {
    last_message_mine?: boolean;
    last_message_seen?: boolean;
    no_messages?: boolean;
  };
  commission_is_minimum?: boolean;
  community_badge?: CommunityBadge;
  dispute?: AdvertiserDisputeDetailsExtended;
  edited_next_month?: boolean;
  files_attached?: MinimalCampaignFile[];
  gender?: string;
  general_tasks_payments_instructions?: TasksPaymentsInstructions;
  has_notification?: boolean;
  /**
   *
   * - Format: int64
   */
  humanz_fk?: number;
  influencer_agent?: InfluencerAgentInfo;
  influencer_invoice_exists?: boolean;
  invite_status?: InviteStatus;
  invoices_urls?: InfluencerInvoiceDetails[];
  /**
   *
   * InfluencerInManageAuctionDataMediaPaid
   *
   */
  media_paid?: {
    /**
     *
     * - Format: int64
     */
    charge_id?: number;
    /**
     *
     * - Format: date-time
     */
    paid_on?: string;
  };
  /**
   *
   * - Format: double
   */
  minimum_usage_fee?: number;
  note?: string;
  offer_details?: OfferDetails;
  passed_max_bid?: boolean;
  /**
   *
   * InfluencerInManageAuctionDataPaymentDetails
   *
   */
  payment_details?: {
    invoice_by_influencer?: boolean;
    invoice_url?: string;
    /**
     *
     * - Format: date-time
     */
    paid_on?: string;
  };
  /**
   *
   * - Format: date-time
   */
  payment_due_date?: string;
  /**
   *
   * - Format: int64
   */
  pbi_id?: number;
  picture_url?: string;
  questions?: AuctionQuestionWithAnswer[];
  review?: NewReviewData;
  shadow_influencer?: boolean;
  social_network_data?: InfluencerInManageAuctionDataSocialNetworkData;
  /**
   *
   * - Format: date
   */
  start_from?: string;
  stop_next_month?: boolean;
  tags?: string[];
  /**
   *
   * TasksDetailsManageData
   *
   */
  tasks_details?: {
    bundles_attributes?: BundleAttributes[];
    tasks?: TaskManageData[];
  };
  tasks_invites?: TaskWithPrice[];
  /**
   *
   * InfluencerInManageAuctionDataUpfrontPayment
   *
   */
  upfront_payment?: {
    /**
     *
     * - Format: double
     */
    amount?: number;
    invoice_url?: string;
    /**
     *
     * - Format: date-time
     */
    paid_on?: string;
  };
  user_registered?: boolean;
}

/**
 *
 * InfluencerInManageAuctionDataSocialNetworkData
 *
 */

export interface InfluencerInManageAuctionDataSocialNetworkData {
  instagram?: MinimalSocialData;
  tiktok?: MinimalSocialData;
  twitter?: MinimalSocialData;
  youtube?: MinimalSocialData;
}

export interface InfluencerInstagramPost {
  embed_link: string;
  fb_media_id: string;
  /**
   *
   * - Format: int64
   */
  influencer_id: number;
  /**
   *
   * - Format: date-time
   */
  media_creation_time: string;
  media_type: "IMAGE" | "VIDEO" | "CAROUSEL_ALBUM";
}

export interface InfluencerInstagramStories {
  image_url: string;
  media_id: string;
  /**
   *
   * - Format: date-time
   */
  media_post_time: string;
  story_status?: StoryStatusEnum;
  video_url?: string;
}

export interface InfluencerInvite {
  /**
   *
   * - Format: int64
   */
  agent_id?: number;
  from_next_month?: boolean;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  /**
   *
   * - Format: double
   */
  price?: number;
  send_social_invite?: boolean;
  tasks_modifications?: TaskModification;
  /**
   *
   * - Format: double
   */
  upfront_payment?: number;
}

export type InfluencerInviteLink = any;

export interface InfluencerInvoiceDetails {
  by_influencer?: boolean;
  /**
   *
   * - Format: int64
   */
  id?: number;
  /**
   *
   * - Format: date
   */
  month_relevance?: string;
  url?: string;
}

/**
 *
 * InfluencerLinkSummary
 *
 */

export interface InfluencerLinkSummary {
  clicks_per_auction?: {
    /**
     *
     * - Format: int32
     */
    clicks?: number;
    name?: string;
  }[];
  clicks_per_day?: {
    /**
     *
     * - Format: int32
     */
    clicks?: number;
    name?: string;
  }[];
  clicks_per_extra?: {
    /**
     *
     * - Format: int32
     */
    clicks?: number;
    name?: string;
  }[];
  countries_segmentation?: {
    /**
     *
     * - Format: int32
     */
    clicks?: number;
    name?: string;
  }[];
  devices?: {
    /**
     *
     * - Format: int32
     */
    clicks?: number;
    name?: string;
  }[];
}

export interface InfluencerPageLink {
  /**
   *
   * - Format: uuid
   */
  uuid?: string;
  /**
   *
   * - Format: date-time
   */
  valid_until?: string;
}

export interface InfluencerProfilePage {
  about?: string;
  academy_badge?: boolean;
  categories?: {
    category?: InfluencerCategory;
    endorsment_by_me?: boolean;
    endorsments?: number;
  }[];
  /**
   *
   * CollaborationStatus
   *
   */
  collaboration_status?: {
    is_enabled_collaboration?: boolean;
    /**
     *
     * - Format: int64
     */
    match_id?: number;
    matched?: boolean;
    swiped?: "not" | "left" | "right";
  };
  community_badge?: CommunityBadge;
  external_page_settings?: ExternalPageSettings;
  image?: string;
  intro_thumbnail?: string;
  intro_video?: string;
  /**
   *
   * - Format: float
   */
  intro_video_ratio?: number;
  last_instagram_posts_ids?: string[];
  /**
   *
   * - Format: date-time
   */
  last_username_changed_at?: string;
  linked_posts?: PorfileMediaAttached[];
  name?: string;
  /**
   *
   * - Format: date-time
   */
  next_username_avaliable_at?: string;
  provider?:
    | "local"
    | "email"
    | "instagram"
    | "google"
    | "facebook"
    | "twitter"
    | "youtube"
    | "apple";
  reviews?: UserAvgReviewsData;
  social_data?: InfluencerInManageAuctionDataSocialNetworkData;
  /**
   *
   * InfluencerProfileStats
   *
   */
  stats?: {
    advertiser_ranking?: number;
    /**
     *
     * - Format: int64
     */
    collaborations?: number;
    /**
     *
     * - Format: int64
     */
    likes?: number;
    posts_count?: number;
    /**
     *
     * - Format: int64
     */
    stars?: number;
  };
  /**
   *
   * - Format: int64
   */
  user_id?: number;
  username?: string;
}

export interface InfluencerRateCard {
  currency: Currency;
  ig_post: SocialMediaRateCard;
  ig_reel: SocialMediaRateCard;
  ig_story: SocialMediaRateCard;
  tiktok: SocialMediaRateCard;
  twitter: SocialMediaRateCard;
  youtube: SocialMediaRateCard;
}

export interface InfluencerSearchData {
  about_me?: string;
  address?: string;
  /**
   *
   * - Format: int64
   */
  age?: number;
  /**
   *
   * AgeByGenderSegmentationData
   *
   */
  age_by_gender_segmentation?: {
    females?: AgeSegmentationData;
    males?: AgeSegmentationData;
  };
  age_group?: string;
  age_segmentation?: AgeSegmentationData;
  /**
   *
   * - Format: double
   */
  average_likes?: number;
  /**
   *
   * - Format: double
   */
  avg_engagement_percetnage?: number;
  bio?: string;
  categories?: string[];
  county?: string;
  email?: string;
  estimated_reach?: number;
  /**
   *
   * - Format: int64
   */
  followers?: number;
  gender?: "male" | "female" | "unknown";
  /**
   *
   * GenderSegmentationData
   *
   */
  gender_segmentation?: {
    /**
     *
     * - Format: double
     */
    female?: number;
    /**
     *
     * - Format: double
     */
    male?: number;
  };
  /**
   *
   * - Format: int32
   */
  moi_grade?: number;
  name?: string;
  phone?: string;
  picture_url?: string;
  reviews_statistics?: ReviewsStatistics;
  suggested_price?: number;
  /**
   *
   * TwitterData
   *
   */
  twitter_data?: {
    /**
     *
     * - Format: int64
     */
    followers?: number;
    name?: string;
    twitter_id?: string;
    username?: string;
  };
  /**
   *
   * - Format: int64
   */
  user_id?: number;
  user_registered?: boolean;
  username?: string;
  /**
   *
   * YoutuberData
   *
   */
  youtube_data?: {
    /**
     *
     * - Format: double
     */
    avg_comments?: number;
    /**
     *
     * - Format: double
     */
    avg_likes?: number;
    /**
     *
     * - Format: double
     */
    avg_views?: number;
    bio?: string;
    categories?: string[];
    channel_id?: string;
    name?: string;
    subscribers?: number;
    /**
     *
     * - Format: int64
     */
    total_views?: number;
    videos?: number;
  };
}

export interface InfluencerSearchParameters {
  /**
   *
   * AudienceDetailsParameters
   *
   */
  audience_details?: {
    age_ranges?: "child" | "teen" | "adult" | "elderlies"[];
    categories?:
      | "home"
      | "news"
      | "science"
      | "coaching"
      | "gaming"
      | "funny"
      | "sports"
      | "finance"
      | "art"
      | "beauty"
      | "bloggers"
      | "books"
      | "family"
      | "fashion"
      | "fitness"
      | "food"
      | "health"
      | "lifestyle"
      | "motherhood"
      | "music"
      | "nightlife"
      | "pets"
      | "photography"
      | "soldiers"
      | "tech"
      | "travel"
      | "vegan"[];
    location?: { country?: string; districts?: string[] }[];
    /**
     *
     * - max: 100
     */
    max_suspicious_followers_rate?: number;
    preferred_gender?: "female" | "male";
  };
  /**
   *
   * InfluencerDetailsParameters
   *
   */
  influencer_details?: {
    age_ranges?: "child" | "teen" | "adult" | "elderlies" | "unknown"[];
    api_connected?: boolean;
    bio_text?: string;
    caption?: string;
    categories?: string[];
    /**
     *
     * EmbeddingSearch
     *
     */
    embedding_search?: {
      search_text?: string;
      search_type?: EmbeddingSearchType;
    };
    gender?: "female" | "male" | "unknown";
    has_contact_details?: boolean;
    hashtag?: string[];
    highlight?: string;
    location?: CountryToDistrictsAndCities[];
    /**
     *
     * - Format: int64
     */
    max_followers?: number;
    /**
     *
     * - Format: int64
     */
    max_views?: number;
    mention?: string[];
    min_engagement_label?: "low" | "good" | "high" | "very high";
    /**
     *
     * - Format: int64
     */
    min_followers?: number;
    /**
     *
     * - Format: int64
     */
    min_likes?: number;
    /**
     *
     * - Format: int64
     */
    min_score?: number;
    /**
     *
     * - Format: int64
     */
    min_views?: number;
    only_registered?: boolean;
    order_by_followers?: boolean;
    post_city?: string;
    show_pages?: boolean;
    social_accounts?:
      | "youtube"
      | "twitter"
      | "instagram"
      | "tiktok"
      | "all"
      | "only_humanz"[];
    username?: string;
    website?: string;
  };
  influencer_ids?: number[];
  /**
   *
   * InfluencerSearchParametersSortBy
   *
   */
  sort_by?: {
    category?: InfluencerCategory;
    /**
     *
     * all is just for followers
     */
    social_network?: "youtube" | "twitter" | "instagram" | "tiktok" | "all";
    sort_by?:
      | "followers"
      | "engagements"
      | "audience_category"
      | "audience_male"
      | "audience_female"
      | "score"
      | "query_score";
  };
}

export type InfluencerSearchParametersWithQuestions =
  InfluencerSearchParameters & {
    questions?: InfluencerSearchParametersWithQuestionsQuestions[];
  };

/**
 *
 * InfluencerSearchParametersWithQuestionsQuestions
 *
 */

export interface InfluencerSearchParametersWithQuestionsQuestions {
  answer?: string;
  numeric_answer?: number;
  question_id?: string;
}

export interface InfluencerShallowData {
  /**
   *
   * - Format: int64
   */
  age?: number;
  age_group?: string;
  categories?: string[];
  country?: string;
  gender?: string;
  id?: string;
  instagram_data?: SocialShallowData;
  name?: string;
  note?: string;
  picture_url?: string;
  /**
   *
   * - Format: int32
   */
  score?: number;
  tiktok_data?: SocialShallowData;
  twitter_data?: SocialShallowData;
  user_registered?: boolean;
  worked_with?: boolean;
  youtube_data?: SocialShallowData;
}

export interface InfluencerShortLinkUpdateData {
  description?: string;
  /**
   *
   * - Format: int64
   */
  extra_id?: number;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  short_link?: ReportLink;
}

export interface InfluencerStatusInAgentList {
  community_badge?: CommunityBadge;
  counts?: InfluencerStatusInAgentListCounts;
  history?: {
    /**
     *
     * - Format: date-time
     */
    end_time?: string;
    /**
     *
     * - Format: date-time
     */
    start_time?: string;
  }[];
  /**
   *
   * - Format: int64
   */
  humanz_fk?: number;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  influencer_info?: InfluencerExtendedData;
  influencer_shallow_info?: InfluencerShallowData;
  influencer_type?: "temp" | "exclusive";
  invite_status?: InfluencerAgentInviteStatus;
  reviews_statistics?: ReviewsStatistics;
}

export interface InfluencerStatusInAgentListCounts {
  /**
   *
   * - Format: int64
   */
  active_campaigns?: number;
  /**
   *
   * - Format: int64
   */
  bids?: number;
  /**
   *
   * - Format: int64
   */
  invites?: number;
  /**
   *
   * - Format: int64
   */
  past_campaigns?: number;
}

export interface InfluencerTaskProposalData {
  post_type?: PostTypes;
  product_fk?: number;
  text?: string;
  uploads?: {
    /**
     *
     * - Format: uuid
     */
    upload_id?: string;
    upload_type?: "image" | "video" | "external_website";
    upload_url?: string;
  }[];
}

export interface InfluencerUserDetails {
  bio?: string;
  counts?: InfluencerUserDetailsCounts;
  full_name?: string;
  id?: string;
  is_private?: boolean;
  profile_picture?: string;
  username?: string;
}

export interface InfluencerUserDetailsCounts {
  /**
   *
   * - Format: int64
   */
  followers?: number;
  /**
   *
   * - Format: int64
   */
  follows?: number;
  /**
   *
   * - Format: int64
   */
  media?: number;
}

export interface InfluencerWithMedias {
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  medias?: {
    internal_media_id?: string;
    media_id?: string;
    media_url?: string;
    post_type?: PostTypes;
    video_url?: string;
  }[];
}

export type InitUSAgentBankAccountSuccess = InitUSBankAccountSuccess & {
  /**
   *
   * - Format: date-time
   */
  expiration_time?: string;
};

export type InitUSBankAccount = any;

export interface InitUSBankAccountSuccess {
  /**
   *
   * - Format: uuid
   */
  id?: string;
  redirect_to?: string;
}

export interface InsightsReportShallowInfo {
  all_sub_users_allowed?: boolean;
  currency?: Currency;
  /**
   *
   * InsightsReportShallowCounts
   *
   */
  current_counts?: {
    /**
     *
     * - Format: int64
     */
    engagements?: number;
    /**
     *
     * - Format: int64
     */
    impressions?: number;
  };
  /**
   *
   * - Format: int64
   */
  id?: number;
  image?: string;
  /**
   *
   * - Format: int64
   */
  influencers_count?: number;
  insight_type?:
    | "standalone_roi"
    | "ambassador_report"
    | "ambassador_report_unfinished"
    | "multi_report";
  /**
   *
   * - Format: int64
   */
  media_count?: number;
  /**
   *
   * - maxLength: 64
   */
  name?: string;
  owner?: Owner;
  /**
   *
   * - Format: date-time
   */
  stopped_at?: string;
  sub_users_allowed?: number[];
}

export type InstagramListSocialSummary = ListSocialSummary & {
  estimated_story_views?: AvgViewsAndBasedAmount;
  video_views?: IgVideoViews;
};

/**
 *
 * InstructionIdsToDelete
 *
 */

export interface InstructionIndicesToDelete {
  donts?: number[];
  dos?: number[];
}

export interface InviteResponse {
  counter_offer?: number;
  question_answers?: QuestionAnswer[];
  response_type?: "accept" | "decline" | "negotiate";
}

export interface InviteStatus {
  agent_name?: string;
  /**
   *
   * - Format: date-time
   */
  invited_at?: string;
  managed_by_agent?: boolean;
  /**
   *
   * - Format: double
   */
  price_offered?: number;
  price_offered_state?:
    | "wait_influencer"
    | "wait_advertiser"
    | "influencer_accepted"
    | "influencer_rejected"
    | "advertiser_rejected";
  /**
   *
   * - Format: double
   */
  upfront_payment?: number;
}

export interface KPI {
  /**
   *
   * - Format: double
   */
  cost_by_budget?: number;
  /**
   *
   * - Format: double
   */
  cost_by_money_spent?: number;
  /**
   *
   * - Format: int64
   */
  current?: number;
  /**
   *
   * - Format: int64
   */
  target?: number;
  /**
   *
   * - Format: int64
   */
  verified?: number;
}

export interface Lesson {
  /**
   *
   * - Format: int64
   */
  course_id?: number;
  is_completed?: boolean;
  markdown_text?: string;
  title?: string;
}

export interface LinkAvailableCheck {
  link_type?: "campaign" | "wix";
  short_link?: string;
  /**
   *
   * - Format: int64
   */
  unfinished_campaign_id?: number;
}

export interface LinkedMediaEngagements {
  /**
   *
   * - Format: int64
   */
  boosted_impressions?: number;
  comments?: number;
  /**
   *
   * - Format: int64
   */
  impressions?: number;
  /**
   *
   * - Format: int64
   */
  likes?: number;
  /**
   *
   * - Format: date-time
   */
  manual_post_stats_last_update_time?: string;
  /**
   *
   * - Format: date-time
   */
  media_creation_time?: string;
  /**
   *
   * - Format: int64
   */
  reach?: number;
  retweets?: number;
  /**
   *
   * - Format: int64
   */
  saved?: number;
  /**
   *
   * - Format: int64
   */
  shares?: number;
  /**
   *
   * - Format: int64
   */
  story_engagements?: number;
  /**
   *
   * - Format: int64
   */
  story_exits?: number;
  /**
   *
   * - Format: date-time
   */
  story_media_creation_time?: string;
  /**
   *
   * - Format: int64
   */
  story_reach?: number;
  /**
   *
   * - Format: int64
   */
  story_swipes_forward?: number;
  /**
   *
   * - Format: int64
   */
  story_taps_back?: number;
  /**
   *
   * - Format: int64
   */
  story_taps_forward?: number;
  /**
   *
   * - Format: int64
   */
  views?: number;
}

export interface LinkedMediaForInfluencer {
  linked_media?: string;
  mime_type?: string;
  post_type?: PostTypes;
  social_network?: "youtube" | "twitter" | "instagram" | "tiktok";
  /**
   *
   * - Format: int64
   */
  task_id?: number;
}

export interface LinkedTaskPost {
  /**
   *
   * - Format: int64
   */
  boosted_impressions?: number;
  internal_media_id?: string;
  is_subtask?: boolean;
  manual_post_stats?: ManualPostStats;
  media_uid?: string;
  post_type?: PostTypes;
  post_uri?: string;
  /**
   *
   * - Format: int64
   */
  task_id?: number;
}

export interface ListData {
  dont_show_frequencies?: boolean;
  influencers_data?: InfluencerInListData[];
  list_summary_data?: ListSummary;
  tags?: CampaignTag[];
  tasks_amount?: ListTasksAmountAndPercentages;
}

export interface ListExtendedData {
  /**
   *
   * ActivityAndSegmentationData
   *
   */
  instagram?: {
    activity_data?: InfluencerActivityData;
    segmentation?: InfluencerFollowersSegmentation;
    /**
     *
     * SegmentationStatus
     *
     */
    segmentation_status?: {
      is_partial?: boolean;
      number_of_users?: number;
      number_of_users_missing?: number;
    };
  };
  /**
   *
   * ActivityAndSegmentationData
   *
   */
  tiktok?: {
    activity_data?: InfluencerActivityData;
    segmentation?: InfluencerFollowersSegmentation;
    /**
     *
     * SegmentationStatus
     *
     */
    segmentation_status?: {
      is_partial?: boolean;
      number_of_users?: number;
      number_of_users_missing?: number;
    };
  };
  /**
   *
   * ActivityAndSegmentationData
   *
   */
  twitter?: {
    activity_data?: InfluencerActivityData;
    segmentation?: InfluencerFollowersSegmentation;
    /**
     *
     * SegmentationStatus
     *
     */
    segmentation_status?: {
      is_partial?: boolean;
      number_of_users?: number;
      number_of_users_missing?: number;
    };
  };
  /**
   *
   * ActivityAndSegmentationData
   *
   */
  youtube?: {
    activity_data?: InfluencerActivityData;
    segmentation?: InfluencerFollowersSegmentation;
    /**
     *
     * SegmentationStatus
     *
     */
    segmentation_status?: {
      is_partial?: boolean;
      number_of_users?: number;
      number_of_users_missing?: number;
    };
  };
}

export interface ListFormAnswer {
  /**
   *
   * - Format: date-time
   */
  answer_time?: string;
  email?: string;
  /**
   *
   * ListFormAnswerInfluencer
   *
   */
  influencer?: {
    /**
     *
     * - Format: int64
     */
    id?: number;
    image?: string;
    name?: string;
    social_network_data?: InfluencerInManageAuctionDataSocialNetworkData;
  };
  phone?: string;
  question_answers?: QuestionAnswer[];
  uuid?: string;
}

export interface ListSocialSummary {
  /**
   *
   * - Format: double
   */
  engagement_ratio?: number;
  engagements_based_on?: number;
  /**
   *
   * - Format: double
   */
  est_cpe?: number;
  impressions_based_on?: number;
  /**
   *
   * - Format: int64
   */
  influencers_count?: number;
  /**
   *
   * - Format: int64
   */
  quality_impressions?: number;
  /**
   *
   * - Format: int64
   */
  sum_followers?: number;
  /**
   *
   * - Format: int64
   */
  sum_impressions?: number;
  /**
   *
   * - Format: int64
   */
  total_engagement?: number;
  /**
   *
   * - Format: int64
   */
  total_est_price?: number;
}

export interface ListSummary {
  all_sub_users_allowed?: boolean;
  currency?: Currency;
  description?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  image_url?: string;
  influencer_ids?: number[];
  /**
   *
   * - Format: int64
   */
  influencers_count?: number;
  instagram?: InstagramListSocialSummary;
  is_favorite?: boolean;
  /**
   *
   * - Format: int64
   */
  linked_auction_id?: number;
  linked_form_uuid?: string;
  list_type?: "normal" | "campaign" | "agent" | "list_fee";
  /**
   *
   * - Format: int64
   */
  owner?: number;
  owner_name?: string;
  /**
   *
   * SharedLink
   *
   */
  shared_data?: {
    /**
     *
     * - Format: date-time
     */
    end_at?: string;
    share_notes?: boolean;
    shared?: boolean;
    shared_by?: string;
    shared_link?: string;
  };
  sub_users_allowed?: number[];
  tiktok?: ListSocialSummary;
  title?: string;
  twitter?: ListSocialSummary;
  youtube?: ListSocialSummary;
}

export interface ListTasksAmountAndPercentages {
  ig_post?: TasksAmountAndPercentages;
  ig_reel?: TasksAmountAndPercentages;
  ig_story?: TasksAmountAndPercentages;
  tk_video?: TasksAmountAndPercentages;
  tw_tweet?: TasksAmountAndPercentages;
  yt_video?: TasksAmountAndPercentages;
}

export interface LoginData {
  captcha?: string;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
  is_influencer?: boolean;
  /**
   *
   * - Format: password
   */
  password?: string;
  user_type?: UserType;
}

/**
 *
 * ManageAuctionData
 *
 */

export interface ManageAuctionData {
  auction_questions?: AuctionQuestions;
  available_months?: string[];
  /**
   *
   * - Format: date-time
   */
  campaign_end_time?: string;
  /**
   *
   * - Format: date
   */
  current_manage_month?: string;
  one_month_campaign?: boolean;
  /**
   *
   * - Format: date-time
   */
  sales_end_time?: string;
  /**
   *
   * - Format: date-time
   */
  sales_start_time?: string;
  share_guid?: string;
  /**
   *
   * ManageAuctionStates
   *
   */
  states?: {
    approval?: ManageAuctionState;
    bids?: ManageAuctionState;
    invite?: ManageAuctionState;
    publish?: ManageAuctionState;
    /**
     *
     * TasksInvitesStats
     *
     */
    tasks_invites?: { count?: number; notifications?: number };
    trash?: ManageAuctionState;
  };
  summary?: CampaignSummary;
  tags?: CampaignTag[];
}

export interface ManageAuctionState {
  count?: number;
  influencers?: InfluencerInManageAuctionData[];
  notifications?: number;
}

export interface ManageCampaignRoiWithParameters {
  allow_filters?: boolean;
  roi?: ManagedCampaignROI;
  shared_parameters?: ShareReportParameters;
}

export interface ManagedCampaignROI {
  /**
   *
   * AuctionsDistribution
   *
   */
  auctions_distribution?: {
    conversions?: AuctionAndPercentage[];
    engagements?: AuctionAndPercentage[];
    impressions?: AuctionAndPercentage[];
  };
  /**
   *
   * ManagedCampaignROICampaignDetails
   *
   */
  campaign_details?: {
    /**
     *
     * - Format: date-time
     */
    affiliation_added_at?: string;
    /**
     *
     * - Format: int64
     */
    auction_id?: number;
    /**
     *
     * - Format: double
     */
    budget?: number;
    currency?: Currency;
    image?: string;
    pixel_enabled?: boolean;
    pixel_phases?: AuctionPhase[];
    shared_guid?: string;
    title?: string;
    verified_posts_counts?: VerifiedPostsCount;
  };
  influencers_for_frequency?: number[];
  kpi?: ManagedCampaignROIKpi;
  lookalikes?: {
    /**
     *
     * - Format: double
     */
    avg_likes?: number;
    /**
     *
     * - Format: int64
     */
    followers?: number;
    image?: string;
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    /**
     *
     * - Format: int64
     */
    instagram_id?: number;
    instagram_username?: string;
    is_registered?: boolean;
    /**
     *
     * - Format: int32
     */
    moi_grade?: number;
    top_categories?: {
      category_name?: string;
      /**
       *
       * - Format: double
       */
      percentage?: number;
    }[];
  }[];
  multi_campaigns_data?: AuctionInMultiReportData[];
  per_influencer?: {
    /**
     *
     * - Format: double
     */
    completed_price?: number;
    contents?: RoiContent[];
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    /**
     *
     * ManagedCampaignROIInsights
     *
     */
    insights?: {
      conversions?: InfluencerLinkSummary;
      /**
       *
       * ManagedCampaignROIInsightsEngagementSegmentations
       *
       */
      engagement_segmentations?: {
        instagram?: InfluencerFollowersSegmentation;
        twitter?: InfluencerFollowersSegmentation;
        youtube?: InfluencerFollowersSegmentation;
      };
      pixel_conversions?: PixelConversions;
      youtube_original_age_insights?: SocialNetworkRoiSegmentationsYoutubeOriginalAgeInsights;
    };
    name?: string;
    picture_url?: string;
    /**
     *
     * - Format: double
     */
    price?: number;
    /**
     *
     * ManagedCampaignROISocialNetworkData
     *
     */
    social_network_data?: {
      instagram?: RoiMinimalSocialData;
      tiktok?: RoiMinimalSocialData;
      twitter?: RoiMinimalSocialData;
      youtube?: RoiMinimalSocialData;
    };
    /**
     *
     * TasksStatus
     *
     */
    tasks_status?: { completed?: number; total?: number };
    user_registered?: boolean;
  }[];
  pixel_conversions?: PixelConversions;
  /**
   *
   * ManagedCampaignROISocialNetworkRoi
   *
   */
  social_network_roi?: {
    instagram?: SocialNetworkRoi;
    tiktok?: SocialNetworkRoi;
    twitter?: SocialNetworkRoi;
    youtube?: SocialNetworkRoi;
  };
  tags?: InfluencerAuctionTag[];
}

export interface ManagedCampaignROIKpi {
  conversions?: KPI;
  engagements?: KPI;
  impressions?: KPI;
}

export interface ManualPostStats {
  /**
   *
   * - Format: int64
   */
  comments?: number;
  /**
   *
   * - Format: int64
   */
  impressions?: number;
  /**
   *
   * - Format: int64
   */
  likes?: number;
  /**
   *
   * - Format: date-time
   */
  media_creation_time?: string;
  /**
   *
   * ManualPostStatsNetworkSpecific
   *
   */
  network_specific?: {
    /**
     *
     * ManualPostStatsNetworkSpecificTwitter
     *
     */
    twitter?: {
      /**
       *
       * - Format: int64
       */
      retweets?: number;
    };
    /**
     *
     * ManualPostStatsNetworkSpecificYoutube
     *
     */
    youtube?: {
      /**
       *
       * - Format: int64
       */
      dislikes?: number;
    };
  };
  /**
   *
   * - Format: int64
   */
  reach?: number;
  /**
   *
   * - Format: int64
   */
  saved?: number;
  /**
   *
   * - Format: int64
   */
  shares?: number;
  /**
   *
   * - Format: int64
   */
  views?: number;
}

export interface Message {
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  is_system_message?: boolean;
  mark_deleted?: boolean;
  message?: string;
  message_content_type?: string;
  message_guid?: string;
  mine?: boolean;
  /**
   *
   * - Format: date-time
   */
  reply_message_time?: string;
  reply_to_content?: string;
  reply_to_id?: string;
  reply_to_type?: "message" | "task_upload";
  seen?: boolean;
  /**
   *
   * - Format: date-time
   */
  seen_at?: string;
  /**
   *
   * - Format: int64
   */
  system_message_auction_id?: number;
  system_message_auction_title?: string;
  /**
   *
   * - Format: int64
   */
  system_message_type?: number;
}

export interface MetaTag {
  color?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  ids?: number[];
  label?: string;
  tag_type?: "auction" | "list";
}

/**
 *
 * MiniUsersIds
 *
 */

export interface MiniUsersIds {
  all_mini_allowed?: boolean;
  allowed_mini_users?: number[];
}

export interface MinimalAuctionDataForAgent {
  campaign_info?: MinimalAuctionDataForInfluencer;
  counts?: InfluencerStatusInAgentListCounts;
}

export interface MinimalAuctionDataForInfluencer {
  advertiser_info?: AdvertiserData;
  allow_analytics?: boolean;
  allow_phases_analytics?: boolean;
  api_restricted?: boolean;
  /**
   *
   * - Format: date-time
   */
  approved_at?: string;
  auction_type?: AuctionTypes;
  bidding_gender?: string;
  brand_info?: EditBrandInfo;
  categories?: string[];
  currency?: Currency;
  description?: string;
  early_payout_available?: boolean;
  /**
   *
   * - Format: date-time
   */
  end_time?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  images?: string[];
  is_allow_bid?: boolean;
  key_info?: string;
  /**
   *
   * - Format: date-time
   */
  last_activity_time?: string;
  /**
   *
   * - Format: int64
   */
  max_followers?: number;
  /**
   *
   * - Format: double
   */
  max_price?: number;
  /**
   *
   * - Format: int64
   */
  min_followers?: number;
  one_month_campaign?: boolean;
  participants_status?: ParticipantsStatus;
  /**
   *
   * - Format: date-time
   */
  payments_date?: string;
  payments_days_delay?: number;
  pixel_affiliator?: string;
  pixel_enabled?: boolean;
  /**
   *
   * - Format: double
   */
  products_total_price?: number;
  progress?: InProgressAuctionForInfluencer;
  raw_description?: string;
  ribbon?: string;
  /**
   *
   * - Format: date-time
   */
  sales_end_time?: string;
  social_networks?: InfluencerAuctionsSocialNetworks[];
  special_program?: SpecialProgramDetails;
  /**
   *
   * - Format: date-time
   */
  start_time?: string;
  title?: string;
}

export interface MinimalCampaignFile {
  file_name?: string;
  /**
   *
   * - Format: uuid
   */
  uuid?: string;
}

export interface MinimalListSummary {
  all_sub_users_allowed?: boolean;
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  description?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  image_url?: string;
  /**
   *
   * - Format: int64
   */
  influencers_count?: number;
  /**
   *
   * - Format: int64
   */
  instagram_count?: number;
  is_favorite?: boolean;
  /**
   *
   * - Format: int64
   */
  linked_auction_id?: number;
  linked_form_uuid?: string;
  list_type?: "normal" | "campaign";
  /**
   *
   * - Format: int64
   */
  original_creator_id?: number;
  owner?: Owner;
  /**
   *
   * SharedLink
   *
   */
  shared_data?: {
    /**
     *
     * - Format: date-time
     */
    end_at?: string;
    share_notes?: boolean;
    shared?: boolean;
    shared_by?: string;
    shared_link?: string;
  };
  sub_users_allowed?: number[];
  /**
   *
   * - Format: int64
   */
  tiktok_count?: number;
  title?: string;
  /**
   *
   * - Format: int64
   */
  twitter_count?: number;
  /**
   *
   * - Format: int64
   */
  youtube_count?: number;
}

export interface MinimalSocialData {
  username: string;
  verification_type: SocialVerificationType;
  api_details?: FacebookApiDetails;
  /**
   *
   * - Format: int64
   */
  followers?: number;
  image_url?: string;
  moi_grade?: number;
}

export interface MultiReport {
  auction_ids?: number[];
  auctions_data?: AuctionInMultiReportData[];
  /**
   *
   * - Format: int64
   */
  id?: number;
  image_url?: string;
  is_eligble_edit?: boolean;
  kpi?: EditedKPIS;
  target_audience?: AuctionTargetAudience;
  title?: string;
}

/**
 *
 * MultiReportFilterParameters
 *
 */

export interface MultiReportFilterParameters {
  auctions?: number[];
  influencers?: number[];
  time_filter?: TimeFilter;
}

export interface MultiReportShareParameters {
  budget?: "real" | "media";
  comments?: ShareReportParametersComments;
  custom_title?: string;
  filter?: MultiReportFilterParameters;
  influencers_price_percentage?: number;
  kpis?: EditedKPIS;
  link?: string;
  password?: string;
  share_cost_per_influencer?: boolean;
  share_enabled?: boolean;
  shared_guid?: string;
  /**
   *
   * - Format: date-time
   */
  sharing_end_time?: string;
}

export interface NewAgentInfo {
  company_id?: string;
  email?: string;
  is_global_agent?: boolean;
  name?: string;
  phone?: string;
}

/**
 *
 * NewAuctionData
 *
 */

export interface NewAuctionData {
  instructions?: DosAndDonts;
  overview?: AuctionOverview;
  payments?: AuctionPayment;
  products?: AuctionProduct[];
  questions?: AuctionQuestions;
  sales?: AuctionSales;
  /**
   *
   * Tasks
   *
   */
  tasks?: {
    /**
     *
     * AuctionCampaignDates
     *
     */
    campaign_dates?: {
      /**
       *
       * - Format: date-time
       */
      end?: string;
      /**
       *
       * - Format: date-time
       */
      start?: string;
    };
    description?: string;
    one_month_campaign?: boolean;
    task_stages?: Task[];
    tasks_payments_instructions?: TasksPaymentsInstructions;
  };
}

export interface NewBidForAuctionData {
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  auction_type?: AuctionTypes;
  /**
   *
   * - Format: double
   */
  bid?: number;
  /**
   *
   * - Format: int64
   */
  bidder_id?: number;
  question_answers?: QuestionAnswer[];
}

export interface NewReviewData {
  /**
   *
   * - Format: int64
   */
  campaign_id?: number;
  /**
   *
   * - Format: int64
   */
  content_stars?: number;
  description?: string;
  /**
   *
   * - Format: int64
   */
  professional_stars?: number;
  /**
   *
   * - Format: int64
   */
  results_stars?: number;
  /**
   *
   * - Format: int64
   */
  stars?: number;
}

export interface NewSupportTicketArticle {
  attachments?: SupportArticleAttachment[];
  /**
   *
   * - maxLength: 65536
   */
  content?: string;
}

export type NoteMessage = Message & { from_name?: string };

export interface NoteToAdd {
  from_name?: string;
  message?: string;
  message_content_type?: string;
  /**
   *
   * - Format: uuid
   */
  message_guid?: string;
}

export interface NotificationData {
  /**
   *
   * - Format: int32
   */
  id?: number;
  parameters?: string[];
}

export interface OfferDetails {
  /**
   *
   * - Format: int64
   */
  offer_id?: number;
  offer_status?: "wait_influencer" | "influencer_declined";
}

export type OrderedTimeFilter = TimeFilter & { order?: "asc" | "desc" };

export interface Owner {
  email?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  image?: string;
  name?: string;
}

export interface ParticipantsStatus {
  max_participants?: number;
  participants_left?: number;
}

export interface PayoutInvoiceData {
  additional_invoices?: string[];
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  auction_title?: string;
  completed_tasks?: boolean;
  currency?: Currency;
  current_invoice?: string;
  have_response_from_accounting?: boolean;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  /**
   *
   * - Format: date-time
   */
  month?: string;
  name?: string;
  /**
   *
   * - Format: double
   */
  paid?: number;
  /**
   *
   * - Format: date-time
   */
  payment_due?: string;
  /**
   *
   * - Format: double
   */
  price?: number;
  waiting_for_me?: boolean;
}

export interface PixelConversions {
  add_to_cart_per_day?: DateAndCount[];
  checkout_visits_per_day?: DateAndCount[];
  coupons?: PurchaseClicksType[];
  phases?: {
    name?: string;
    /**
     *
     * - Format: int64
     */
    phase_id?: number;
    /**
     *
     * - Format: int32
     */
    purchases?: number;
    /**
     *
     * - Format: double
     */
    sales?: number;
    /**
     *
     * - Format: double
     */
    sales_commission?: number;
    /**
     *
     * - Format: double
     */
    sales_net?: number;
  }[];
  products?: PurchaseClicksType[];
  purchases_per_day?: PurchaseClicksType[];
  sales_user_data?: AuctionSales;
  /**
   *
   * - Format: int64
   */
  total_added_to_cart?: number;
  /**
   *
   * - Format: int64
   */
  total_checkouts_visits?: number;
  /**
   *
   * - Format: double
   */
  total_influencer_commission?: number;
  /**
   *
   * - Format: int64
   */
  total_purchases?: number;
}

export interface PixelEventData {
  coupon_used_array?: string[];
  pixel_event_name?: "campaign" | "purchase";
  /**
   *
   * - Format: uuid
   */
  search_uuid?: string;
  transaction_id?: string;
}

export interface PixelEventsCheckResponse {
  is_valid: boolean;
  /**
   *
   * PixelEventsCheckResponseCart
   *
   */
  cart?: {
    /**
     *
     * - Format: double
     */
    cart_total_price?: number;
    purchased_items?: CartItem[];
    transaction_id?: string;
  };
  event_id?: string;
  source_ip?: string;
  source_origin?: string;
  source_url?: string;
}

export interface PixelWizardDetails {
  country: string;
  name: string;
  platform: string;
  website_url: string;
  is_special_index?: boolean;
  password?: string;
  pixel_app_id?: string;
  /**
   *
   * - Format: int64
   */
  shared_by?: number;
}

export interface PorfileMediaAttached {
  caption?: string;
  /**
   *
   * - Format: uuid
   */
  id?: string;
  media_id?: string;
  order?: number;
  original_url?: string;
  post_type?: PostTypes;
  real_content_url?: string;
  thumbnail?: string;
}

export type PorfileMediaAttachedInsert = PorfileMediaAttached & {
  is_deleted?: boolean;
  /**
   *
   * AttachMediaValidation
   *
   */
  validation?: { validation?: string; validation_type?: "username" | "id" };
};

export interface PostAgentInvoicesQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf - optional or see all
   * - Format: int64
   */
  influencer_id?: number;
}

export interface PostForgot_passwordCreate_email_verificationQueryParams {
  /**
   *
   * the user email address to create a password recovery process
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  user_email_address: string;
  /**
   *
   * the user id
   * - Format: int64
   */
  user_id: number;
}

export interface PostMoiAuctionManageAuction_idCustom_linkQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
}

export interface PostMoiAuctionManageAuction_idProposal_uploadTask_idQueryParams {
  /**
   *
   * The proposal id to add proposal uploads to
   * - Format: int64
   */
  proposal_id?: number;
}

export interface PostMoiAuctionManageAuction_idProposals_historyInfluencer_idQueryParams {
  /**
   *
   * The month of the auction you want to see - for history.
   * - Format: date
   */
  month?: string;
}

export interface PostMoiAuctionManageAuction_idProposals_historyQueryParams {
  /**
   *
   * The month of the auction you want to see - for history.
   * - Format: date
   */
  month?: string;
  task_id?: number;
}

export interface PostMoiAuctionsAuction_idPixel_phasesQueryParams {
  /**
   *
   * is splitting the main phase
   */
  main_split?: boolean;
}

export interface PostMoiAuctionsIdProducts_sales_roiQueryParams {
  /**
   *
   * sales breakdown
   */
  breakdown?: "brand" | "sku" | "link";
  /**
   *
   * optional - if you want to see the products for specific influencer_id
   * - Format: int64
   */
  influencer_id?: number;
}

export interface PostMoiAuctionsIdRoi_filteredQueryParams {
  /**
   *
   * Get if full roi if not - story only
   */
  full_roi?: boolean;
}

export interface PostMoiAuctionsIdSales_roi_breakdownBreakdownQueryParams {
  /**
   *
   * optional - if you want to see the products for specific influencer_id
   * - Format: int64
   */
  influencer_id?: number;
}

export interface PostMoiAuctionsQueryParams {
  related_unfinished_id: number;
}

export interface PostMoiCommunity_feedPost_idCommentsQueryParams {
  /**
   *
   * reply to comment id
   * - Format: int64
   */
  to_comment_id?: number;
}

export interface PostMoiInboxIdAttachmentQueryParams {
  /**
   *
   * The campaign related to the chat
   * - Format: int64
   */
  campaign_id?: number;
}

export interface PostMoiInboxIdQueryParams {
  /**
   *
   * The campaign related to the chat
   * - Format: int64
   */
  campaign_id?: number;
}

export interface PostMoiInboxIdStarQueryParams {
  /**
   *
   * The campaign related to the chat
   * - Format: int64
   */
  campaign_id?: number;
}

export interface PostMoiInbox_with_first_timeIdQueryParams {
  /**
   *
   * The campaign related to the chat
   * - Format: int64
   */
  campaign_id?: number;
}

export interface PostMoiPremiumAuctionAuction_idTodoTo_do_idCompleteQueryParams {
  /**
   *
   * if the item is completed
   */
  completed: boolean;
}

export interface PostMoiPremiumAuctionAuction_idTodo_from_templateQueryParams {
  /**
   *
   * Template Id to import todo items from
   * - Format: int64
   */
  templateId?: number;
}

export interface PostMoiPremiumListsList_idInfluencer_idPriceQueryParams {
  /**
   *
   * should replace default for the list
   */
  replace_default?: boolean;
}

export interface PostMoiPremiumSearch_influencersQueryParams {
  /**
   *
   * mark if it is search without parameters
   */
  default_search?: boolean;
  /**
   *
   * The scroll id header
   */
  scroll_id?: string;
}

export interface PostMoiSupport_centerTicket_idQueryParams {
  /**
   *
   * new status of ticket, if empty means no change
   */
  new_status?: "open" | "closed" | "archive";
}

export interface PostMoiUpload_imageUpload_typeQueryParams {
  related_auction_id?: number;
}

export interface PostSharesCampaignProposals_historyInfluencer_idQueryParams {
  /**
   *
   * The month of the auction you want to see - for history.
   * - Format: date
   */
  month?: string;
}

export interface PostSharesRoiProducts_sales_roiQueryParams {
  /**
   *
   * sales breakdown
   */
  breakdown?: "brand" | "sku" | "link";
  /**
   *
   * optional - if you want to see the products for specific influencer_id
   * - Format: int64
   */
  influencer_id?: number;
}

export interface PostShopifySearch_influencersQueryParams {
  /**
   *
   * The scroll id header
   */
  scroll_id?: string;
}

export interface PostSocialLoginAgentInvite_influencerAgent_uuidQueryParams {
  /**
   *
   * social network name
   */
  social_network: "instagram" | "youtube" | "tiktok" | "twitter";
}

export interface PostSocialLoginExternal_registerBusiness_discoveryQueryParams {
  /**
   *
   * the agent fk for agent invites
   * - Format: int64
   */
  agent_fk?: number;
}

export interface PostSocialLoginExternal_registerLink_apiQueryParams {
  /**
   *
   * social network name
   */
  social_network: "instagram" | "youtube" | "tiktok" | "twitter";
}

export enum PostSubTypes {
  "post" = "post",
  "reel" = "reel",
  "igtv" = "igtv",
  "yt_short" = "yt_short",
}

export enum PostTypes {
  "ig_post" = "ig_post",
  "ig_story" = "ig_story",
  "yt_video" = "yt_video",
  "tw_tweet" = "tw_tweet",
  "influencer_content" = "influencer_content",
  "tk_video" = "tk_video",
}

export interface PostWixSearch_influencersQueryParams {
  /**
   *
   * The scroll id header
   */
  scroll_id?: string;
}

export type PremiumListSharedLink = any;

export interface ProductDetails {
  currency?: string;
  description?: string;
  fullUrl?: string;
  imageUrl?: string;
  name?: string;
  /**
   *
   * - Format: double
   */
  price?: number;
  url?: string;
  /**
   *
   * - Format: int32
   */
  url_count?: number;
}

/**
 *
 * ProductIdsToDelete
 *
 */

export type ProductIdsToDelete = number[];

export interface ProposalData {
  approved?: boolean;
  by_advertiser?: boolean;
  /**
   *
   * - Format: int64
   */
  id?: number;
  /**
   *
   * - Format: date-time
   */
  response_at?: string;
  share_note?: string;
  shared?: boolean;
  shared_approved?: boolean;
  /**
   *
   * SharedRejectionData
   *
   */
  shared_rejection?: {
    text_comments?: string;
    uploads_comments?: string;
    uploads_responses?: { approved?: boolean; upload_id?: string }[];
  };
  text?: string;
  text_comments?: string;
  /**
   *
   * - Format: date-time
   */
  upload_at?: string;
  uploads?: {
    approved?: boolean;
    upload_id?: string;
    upload_shared?: boolean;
    upload_type?: "image" | "video" | "external_website";
    upload_url?: string;
  }[];
  uploads_comment?: string;
}

export interface PurchaseClicksType {
  external_name?: string;
  image_url?: string;
  link?: string;
  name?: string;
  product_link_description?: string;
  product_long_link?: string;
  product_name?: string;
  /**
   *
   * - Format: int32
   */
  purchases?: number;
  /**
   *
   * - Format: double
   */
  total_price?: number;
  /**
   *
   * - Format: int64
   */
  units?: number;
}

export interface PutMoiAgentAgent_idBankQueryParams {
  /**
   *
   * The user agent id to query
   * - Format: int64
   */
  user_agent_id?: number;
}

export interface PutMoiAgentAgent_idBankTurkeyQueryParams {
  /**
   *
   * The agent id to query
   * - Format: int64
   */
  user_agent_id?: number;
}

export interface PutMoiAgentAgent_idBankUsQueryParams {
  country?: "United States" | "Canada" | "Mexico";
  overrideExisting?: boolean;
  /**
   *
   * - Format: int64
   */
  user_agent_id?: number;
}

export interface PutMoiAuctionManageAuction_idCustom_linkQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
}

export interface PutMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams {
  /**
   *
   * the influencer id of which you want to invite response on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
}

export interface PutMoiAuctionsAuction_idBidsEdit_bidQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
}

export interface PutMoiAuctionsIdBid_invite_as_agentQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
}

export interface PutMoiAuctionsIdBidsQueryParams {
  /**
   *
   * the influencer id of which you want to view on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
}

export interface PutMoiEdit_detailsAdvertiserQueryParams {
  /**
   *
   * boolean flag
   */
  disconnect_all_devices?: boolean;
  /**
   *
   * if true send welcome email
   */
  finished_signup?: boolean;
}

export interface PutMoiEdit_detailsAgentQueryParams {
  /**
   *
   * boolean flag
   */
  disconnect_all_devices?: boolean;
  /**
   *
   * if true send welcome email
   */
  finished_signup?: boolean;
}

export interface PutMoiEdit_detailsInfluencerBankUsQueryParams {
  country?: "United States" | "Canada" | "Mexico";
}

export interface PutMoiEdit_detailsInfluencerPasswordQueryParams {
  /**
   *
   * boolean flag
   */
  disconnect_all_devices?: boolean;
}

export interface PutMoiInboxIdQueryParams {
  /**
   *
   * The campaign related to the chat
   * - Format: int64
   */
  campaign_id?: number;
}

export interface PutMoiPaymentPayment_from_advertiserUpgrade_to_premiumQueryParams {
  /**
   *
   * The payment method id to use
   * - Format: int64
   */
  payment_method_id?: number;
}

export interface PutMoiPremiumListsList_idQueryParams {
  /**
   *
   * import all content releated to influencers on this list
   * - Format: int64
   */
  import_content_from_list_id?: number;
  /**
   *
   * import tags notes and comments
   * - Format: int64
   */
  import_extra_from_list?: number;
}

export interface PutShopifyRoiFilterQueryParams {
  /**
   *
   * the filter name
   * - minLength: 2
   */
  filter_name: string;
}

export interface PutUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams {
  /**
   *
   * the influencer id of which you want to invite response on behalf. used for agents.
   * - Format: int64
   */
  behalf_influencer_id?: number;
}

export interface PutWixRoiFilterQueryParams {
  /**
   *
   * the filter name
   * - minLength: 2
   */
  filter_name: string;
}

export interface QuestionAnswer {
  answer?: string;
  id?: string;
  numeric_answer?: number;
}

/**
 *
 * QuestionIdsToDelete
 *
 */

export type QuestionIdsToDelete = string[];

export enum QuestionTypes {
  "checkbox" = "checkbox",
  "open" = "open",
  "select" = "select",
  "numeric" = "numeric",
  "verification" = "verification",
}

export interface QuizResult {
  correct_ids?: number[];
  failed_ids?: number[];
  passed?: boolean;
  score?: number;
}

export interface ROISlidesInfo {
  /**
   *
   * - Format: int64
   */
  auction_id: number;
  /**
   *
   * - minLength: 3
   * - pattern: ^{.+
   */
  payload: string;
  /**
   *
   * - maxLength: 64
   * - minLength: 1
   */
  title: string;
  /**
   *
   * - minLength: 1
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
}

export interface ROISlidesMetadata {
  /**
   *
   * - Format: uuid
   */
  id?: string;
}

export type ROISlidesReport = ROISlidesMetadata &
  ROISlidesInfo & {
    /**
     *
     * - Format: date-time
     */
    completed_at?: string;
    /**
     *
     * - Format: date-time
     */
    created_at?: string;
    generation_status?: "enqueued" | "started" | "error" | "completed";
    /**
     *
     * ROISlidesURLs
     *
     */
    urls?: { google_slides_url?: string; pdf_url?: string; pptx_url?: string };
  };

export interface ROISlidesReportStatus {
  reports?: ROISlidesReport[];
}

export interface ReachAndFrequency {
  /**
   *
   * - Format: double
   */
  frequency?: number;
  is_partial?: boolean;
  /**
   *
   * - Format: int64
   */
  unique_reach?: number;
}

export type ReadReviewData = NewReviewData & {
  /**
   *
   * - Format: date-time
   */
  review_time?: string;
  reviewer_image?: string;
  reviewer_name?: string;
};

export interface RegisteredBankAccount {
  account_type?: BankAccountType;
  /**
   *
   * - minLength: 2
   */
  bank_account_number?: string;
  bank_branch?: string;
  /**
   *
   * - Format: int32
   */
  bank_number?: number;
  /**
   *
   * - minLength: 2
   */
  full_name?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  /**
   *
   * RegisteredBankAccountIndia
   *
   */
  india?: { ifsc?: string; upi?: string };
  /**
   *
   * RegisteredBankAccountIsrael
   *
   */
  israel?: { vat_type?: string };
  tax_number?: string;
  /**
   *
   * RegisteredBankAccountTurkey
   *
   */
  turkey?: { iban?: string };
}

export type RegisteredUKAgentBankAccount = RegisteredBankAccount & {
  address?: BillingAddress;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
};

export type RegisteredUSAgentBankAccount = RegisteredUKAgentBankAccount & {
  status?: "link_created" | "link_expired" | "filled";
};

export interface RemoveSocialConnection {
  social_network?: "youtube" | "twitter" | "instagram" | "tiktok";
}

/**
 *
 * ReportLink
 *
 */

export interface ReportLink {
  id?: string;
  long_link?: string;
  short_link?: string;
}

/**
 *
 * the auction phase parameters
 */

export type RequestBodyAuctionPhase = AuctionPhase;

/**
 *
 * Todo Item
 */

export type RequestBodyAuctionToDoItem = AuctionToDoItem;

export type RequestBodyBankAccountDetails = BankAccountDetails;

/**
 *
 * the details you want to update
 */

export type RequestBodyBillingAddress = BillingAddress;

/**
 *
 * filters of report
 */

export type RequestBodyCampaignReportFilterParameters =
  CampaignReportFilterParameters;

/**
 *
 * Edit campaign tag data
 */

export type RequestBodyCampaignTag = CampaignTag;

/**
 *
 * create campaign tag
 */

export type RequestBodyCampaignTag2 = CampaignTag;

/**
 *
 * users challenge details
 */

export type RequestBodyChallengeDetails = ChallengeDetails;

/**
 *
 * Influencer Search Parameters
 */

export type RequestBodyContentSearchParameters = ContentSearchParameters;

/**
 *
 * all details needed to upload feed post
 */

export type RequestBodyCreateCommunityFeedItem = CreateCommunityFeedItem;

/**
 *
 * endore body
 */

export type RequestBodyEndorseBody = EndorseBody;

/**
 *
 * The invoice data
 */

export type RequestBodyExternalInvoiceData = ExternalInvoiceData;

/**
 *
 * the posts filter if used in search
 */

export type RequestBodyExtraDataFilter = ExtraDataFilter;

/**
 *
 * New file to upload details
 */

export type RequestBodyFileToUploadDetails = FileToUploadDetails;

/**
 *
 * filters of campaign report
 */

export type RequestBodyFiltersAndScrollProducts = FiltersAndScrollProducts;

export type RequestBodyInfluencerCustomLink = InfluencerCustomLink;

/**
 *
 * The id of the influencers to calc on
 */

export type RequestBodyInfluencerIds = InfluencerIds;

/**
 *
 * Influencer Search Parameters
 */

export type RequestBodyInfluencerSearchParameters = InfluencerSearchParameters;

/**
 *
 * influencer short link to delete
 */

export type RequestBodyInfluencerShortLinkUpdateData =
  InfluencerShortLinkUpdateData;

/**
 *
 * influencer short link update data
 */

export type RequestBodyInfluencerShortLinkUpdateData2 =
  InfluencerShortLinkUpdateData;

/**
 *
 * Influencer's propsal
 */

export type RequestBodyInfluencerTaskProposalData = InfluencerTaskProposalData;

/**
 *
 * Influencer response
 */

export type RequestBodyInviteResponse = InviteResponse;

/**
 *
 * The advertiser response for the negotiation
 */

export type RequestBodyInviteResponse2 = InviteResponse;

export type RequestBodyLinkAvailableCheck = LinkAvailableCheck;

/**
 *
 * Login data
 */

export type RequestBodyLoginData = LoginData;

/**
 *
 * Mini users array
 */

export type RequestBodyMiniUsersIds = MiniUsersIds;

/**
 *
 * details of multi report
 */

export type RequestBodyMultiReport = MultiReport;

/**
 *
 * share parameters of report
 */

export type RequestBodyMultiReportShareParameters = MultiReportShareParameters;

/**
 *
 * agent to be added details
 */

export type RequestBodyNewAgentInfo = NewAgentInfo;

/**
 *
 * New bid data
 */

export type RequestBodyNewBidForAuctionData = NewBidForAuctionData;

/**
 *
 * the note
 */

export type RequestBodyNoteToAdd = NoteToAdd;

/**
 *
 * from and to messages creation time , stating null meaning no limit
 */

export type RequestBodyOrderedTimeFilter = OrderedTimeFilter;

/**
 *
 * Influencer's post data
 */

export type RequestBodyPorfileMediaAttachedInsertArray =
  PorfileMediaAttachedInsert[];

/**
 *
 * The connection change
 */

export type RequestBodyRemoveSocialConnection = RemoveSocialConnection;

/**
 *
 * Login data
 */

export type RequestBodySocialRegisteredLinkData = SocialRegisteredLinkData;

/**
 *
 * all details needed for support
 */

export type RequestBodySupportRequestDetails = SupportRequestDetails;

/**
 *
 * task modifications
 */

export type RequestBodyTaskModification = TaskModification;

/**
 *
 * Advertiser's feedback for influencer's proposal
 */

export type RequestBodyTaskProposalFeedbackData = TaskProposalFeedbackData;

export type RequestBodyTurkishBankAccount = TurkishBankAccount;

export type RequestBodyUKBankAccount = UKBankAccount;

/**
 *
 * Influencer details
 */

export type RequestBodyUsersToOperate = UsersToOperate;

/**
 *
 * the new settings
 */

export type RequestBodyWixUserSettings = WixUserSettings;

/**
 *
 * IgUsernameToDiscover
 *
 * search using username
 */

export interface RequestBodyregisterExternalInfluencerBusinessDiscoveryUsername {
  username?: string;
}

/**
 *
 * Todo Item
 */

export type RequestBodyreorderToDoItemsTodoandorder = {
  /**
   *
   * - Format: int64
   */
  id?: number;
  order?: number;
}[];

/**
 *
 * TaskOfferResponse
 *
 * the response
 */

export interface RequestBodyresponseToTaskOfferResponse {
  response?: "accept" | "decline";
}

export interface RequestDataInfluencerInfo {
  error?: boolean;
  /**
   *
   * - Format: date-time
   */
  error_at?: string;
  error_string?: string;
  /**
   *
   * - Format: date-time
   */
  last_request_time?: string;
  request_in_progress?: boolean;
}

export type RequestDataInfluencersList = {
  error?: boolean;
  /**
   *
   * - Format: date-time
   */
  error_at?: string;
  error_string?: string;
  in_progress?: boolean;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  /**
   *
   * - Format: date-time
   */
  requsted_at?: string;
  social_network?: string;
  socialnetwork_id?: string;
  socialnetwork_profile_pic?: string;
  socialnetwork_username?: string;
}[];

export type RequestDataSummary = any;

/**
 *
 * ReviewsStatistics
 *
 */

export interface ReviewsStatistics {
  /**
   *
   * - Format: double
   */
  average_stars?: number;
  /**
   *
   * - Format: int64
   */
  count?: number;
  /**
   *
   * - Format: int64
   */
  count_with_description?: number;
}

export type RoiContent = RoiSingleContent & { story_status?: StoryStatusEnum };

export type RoiMinimalSocialData = MinimalSocialData & {
  reach_and_frequency?: ReachAndFrequency;
  /**
   *
   * - Format: int64
   */
  total_boosted_impressions?: number;
  /**
   *
   * - Format: int64
   */
  total_engagements?: number;
  /**
   *
   * - Format: int64
   */
  total_impressions?: number;
  /**
   *
   * - Format: int64
   */
  total_views?: number;
};

export interface RoiSingleContent {
  /**
   *
   * - Format: int64
   */
  boosted_impressions?: number;
  engagemnts?: LinkedMediaEngagements;
  linked_media?: string;
  linked_media_img_url?: string;
  /**
   *
   * - Format: date-time
   */
  media_creation_time?: string;
  media_id?: string;
  /**
   *
   * - Format: date-time
   */
  media_linked_at?: string;
  mime_type?: string;
  post_type?: PostTypes;
}

export interface ShadowInfluencer {
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  image?: string;
  /**
   *
   * - Format: date-time
   */
  last_update_time?: string;
  name?: string;
  note?: string;
}

export type ShadowInfluencerWithId = {
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
} & ShadowInfluencer;

export interface ShareListParameters {
  general_comment?: string;
  link?: string;
  select_social_networks?: "instagram" | "twitter" | "youtube" | "tiktok"[];
  share_comments?: boolean;
  share_contact_details?: boolean;
  share_enabled?: boolean;
  share_engagments_rate?: boolean;
  share_segmentation?: boolean;
  share_tags?: boolean;
  shared_data?:
    | "unique_followers_dist"
    | "engagement"
    | "impressions"
    | "followers"
    | "influencers"
    | "est_budget"
    | "cpe"
    | "quality_impressions"
    | "views"[];
  shared_from_campaign?: boolean;
  /**
   *
   * - Format: date-time
   */
  sharing_end_time?: string;
  show_segementation?: boolean;
  show_segementation_options?: "gender" | "age" | "fraud" | "country"[];
}

export interface ShareNotesSummary {
  not_read_amount?: number;
  type_and_notes_summary?: {
    note_type?: string;
    notes_summary?: {
      amount_notes?: number;
      /**
       *
       * - Format: int64
       */
      item_id?: number;
      seen?: boolean;
    }[];
  }[];
}

export interface ShareOwnerDetails {
  image?: string;
  name?: string;
}

export interface ShareReportParameters {
  budget?: "real" | "media";
  comments?: ShareReportParametersComments;
  filters?: CampaignReportFilterParameters;
  hide_impressions?: boolean;
  influencers_price_percentage?: number;
  link?: string;
  share_content_dates?: boolean;
  share_cost_per_influencer?: boolean;
  share_enabled?: boolean;
  /**
   *
   * - Format: date-time
   */
  sharing_end_time?: string;
}

export interface ShareReportParametersComments {
  funnel?: string;
  kpi?: string;
  segmentation?: string;
  tasks?: string;
}

export interface SideBarData {
  academy_editor?: boolean;
  account_type?: string;
  allowed_ads_tasks?: boolean;
  allowed_ambassador_type?: boolean;
  allowed_blur?: boolean;
  allowed_brand_report?: boolean;
  allowed_campaign_as_brand?: boolean;
  /**
   *
   * Stable payments details and global trigger allowed
   */
  allowed_early_payout?: boolean;
  allowed_extended_support?: boolean;
  allowed_general_multi_message?: boolean;
  allowed_has_contact_details?: boolean;
  allowed_influencer_agent?: boolean;
  allowed_insights_report?: boolean;
  allowed_merger_injection?: boolean;
  allowed_open_auto_approval_campaigns?: boolean;
  allowed_reach_frequency?: boolean;
  allowed_search_registerd?: boolean;
  allowed_shadow_influencers?: boolean;
  allowed_share_contact_details?: boolean;
  allowed_skip_social_invite?: boolean;
  allowed_upfront_payment_invites?: boolean;
  allowed_upload_content?: boolean;
  /**
   *
   * - Format: double
   */
  ambassador_report_price?: number;
  /**
   *
   * - Format: double
   */
  brand_report_price?: number;
  can_open_barter?: boolean;
  can_open_campaigns?: boolean;
  can_share_lists?: boolean;
  can_share_roi?: boolean;
  can_view_tiktok?: boolean;
  /**
   *
   * - Format: int64
   */
  closed_auctions?: number;
  /**
   *
   * - Format: int64
   */
  connected_user_agent_fk?: number;
  /**
   *
   * - Format: int64
   */
  current_number_of_reports?: number;
  /**
   *
   * - Format: int64
   */
  current_number_of_reports_posts?: number;
  enterprises?: EnterpriseUser[];
  extra_currencies?: Currency[];
  finished_basic_details?: boolean;
  global_agents_allowed_countires?: string[];
  has_credit_card?: boolean;
  is_enterprise?: boolean;
  is_premium?: boolean;
  is_sub_user?: boolean;
  /**
   *
   * - Format: int64
   */
  max_number_of_lists?: number;
  /**
   *
   * - Format: int64
   */
  max_number_of_reports?: number;
  /**
   *
   * - Format: int64
   */
  max_number_of_reports_posts?: number;
  /**
   *
   * - Format: int64
   */
  max_number_of_searches?: number;
  /**
   *
   * - Format: int64
   */
  max_number_of_segmentation_views?: number;
  need_campaign_approval?: boolean;
  /**
   *
   * - Format: int64
   */
  number_of_lists?: number;
  /**
   *
   * - Format: int64
   */
  number_of_searches?: number;
  /**
   *
   * - Format: int64
   */
  number_of_segmentation_views?: number;
  /**
   *
   * - Format: int64
   */
  open_auctions?: number;
  pay_per_ambassador_report?: boolean;
  pay_per_bid?: boolean;
  pay_per_brand_report?: boolean;
  reviews_statistics?: ReviewsStatistics;
  should_update_toc?: boolean;
  show_bids_notifications?: boolean;
  sub_users?: SubUser[];
  /**
   *
   * - Format: int32
   */
  tokens_limit?: number;
  /**
   *
   * - Format: date-time
   */
  tokens_next_reset?: string;
  /**
   *
   * - Format: int32
   */
  tokens_used?: number;
  /**
   *
   * - Format: int64
   */
  total_earned?: number;
  trial_on_searches?: boolean;
  verified_advertiser?: boolean;
  vip_user?: boolean;
}

export interface SimilarListInfluencers {
  error?: string;
  influencers?: InfluencerShallowData[];
}

export interface SocialLoginDataExtracted {
  about?: string;
  /**
   *
   * - Format: date-time
   */
  birth_date?: string;
  country?: string;
  email?: string;
  first_name?: string;
  full_name?: string;
  image_url?: string;
  last_name?: string;
}

export enum SocialLoginProvider {
  "google" = "google",
  "facebook" = "facebook",
  "apple" = "apple",
}

export interface SocialMediaRateCard {
  max: number;
  min: number;
}

export interface SocialNetworkCampaignSummary {
  /**
   *
   * - Format: int64
   */
  clicks?: number;
  /**
   *
   * - Format: double
   */
  engagement_ratio?: number;
  /**
   *
   * - Format: int64
   */
  followers?: number;
  /**
   *
   * - Format: int64
   */
  impressions?: number;
  influencers?: number;
  /**
   *
   * - Format: int64
   */
  total_likes?: number;
}

export interface SocialNetworkGeneralData {
  /**
   *
   * - Format: int64
   */
  age?: number;
  age_group?: string;
  api_details?: FacebookApiDetails;
  /**
   *
   * - Format: double
   */
  avg_comments?: number;
  /**
   *
   * - Format: double
   */
  avg_engagement_percetnage?: number;
  /**
   *
   * - Format: double
   */
  avg_engagement_percetnage_by_impressions?: number;
  /**
   *
   * - Format: double
   */
  avg_likes?: number;
  /**
   *
   * - Format: double
   */
  avg_views?: number;
  bio?: string;
  categories?: string[];
  city?: string;
  describing_words?: string[];
  district?: string;
  /**
   *
   * - Format: int64
   */
  followers?: number;
  full_name?: string;
  gender?: string;
  id?: string;
  ig_avg_views?: IgVideoViews;
  image?: string;
  /**
   *
   * - Format: int64
   */
  impressions?: number;
  is_page?: boolean;
  is_private?: boolean;
  /**
   *
   * - Format: double
   */
  median_views?: number;
  score?: number;
  score_api?: number;
  score_breakdown?: {
    name?: string;
    /**
     *
     * - Format: double
     */
    score?: number;
    /**
     *
     * - Format: double
     */
    value?: number;
  }[];
  text_description?: string;
  username?: string;
  verification_type?: SocialVerificationType;
  website?: string;
}

export enum SocialNetworkOptions {
  "youtube" = "youtube",
  "twitter" = "twitter",
  "instagram" = "instagram",
  "tiktok" = "tiktok",
}

export interface SocialNetworkRoi {
  /**
   *
   * SocialNetworkRoiFunnel
   *
   */
  funnel?: {
    followers?: Frequencies;
    /**
     *
     * SocialNetworkRoiFunnelStories
     *
     */
    stories?: {
      quantity?: number;
      /**
       *
       * - Format: int64
       */
      total_views?: number;
    };
    /**
     *
     * SocialNetworkRoiFunnelTotal
     *
     */
    total?: {
      /**
       *
       * - Format: int64
       */
      boosted_impressions?: number;
      /**
       *
       * - Format: int64
       */
      conversions?: number;
      /**
       *
       * - Format: int64
       */
      engagement?: number;
      /**
       *
       * - Format: int64
       */
      impressions?: number;
      /**
       *
       * - Format: int64
       */
      quality_engagement?: number;
      /**
       *
       * - Format: int64
       */
      quality_impressions?: number;
      reach_and_frequency?: ReachAndFrequency;
      /**
       *
       * - Format: int64
       */
      verified_impressions?: number;
    };
  };
  /**
   *
   * SocialNetworkRoiSegmentations
   *
   */
  segmentations?: {
    conversions?: InfluencerLinkSummary;
    engagement?: InfluencerFollowersSegmentation;
    /**
     *
     * EngagersInsights
     *
     */
    engagers_insights?: {
      /**
       *
       * CommentsInsights
       *
       */
      comments?: {
        top_commenters?: {
          comment?: string;
          /**
           *
           * - Format: int64
           */
          commenter_id?: number;
          /**
           *
           * - Format: int64
           */
          different_posts?: number;
          image_url?: string;
          username?: string;
        }[];
        /**
         *
         * - Format: int64
         */
        unique_users?: number;
      };
      /**
       *
       * - Format: double
       */
      frauds_percentage?: number;
      top_likers?: {
        /**
         *
         * - Format: int64
         */
        different_posts?: number;
        image_url?: string;
        /**
         *
         * - Format: int64
         */
        liker_id?: number;
        username?: string;
      }[];
    };
    impressions?: InfluencerFollowersSegmentation;
    youtube_original_age_insights?: SocialNetworkRoiSegmentationsYoutubeOriginalAgeInsights;
  };
}

export interface SocialNetworkRoiSegmentationsYoutubeOriginalAgeInsights {
  females?: {
    age_group?: string;
    from_age?: number;
    /**
     *
     * - Format: double
     */
    percentage?: number;
    to_age?: number;
  }[];
  males?: {
    age_group?: string;
    from_age?: number;
    /**
     *
     * - Format: double
     */
    percentage?: number;
    to_age?: number;
  }[];
}

export interface SocialRegisteredLinkData {
  /**
   *
   * - Format: password
   */
  access_token?: string;
  /**
   *
   * - Format: password
   */
  access_token_secret?: string;
  profile_id?: string;
  provider?: "youtube" | "instagram" | "twitter" | "tiktok";
  social_challenge_id?: string;
}

export interface SocialShallowData {
  api_details?: FacebookApiDetails;
  /**
   *
   * - Format: double
   */
  avg_engagement_percentage_by_impressions?: number;
  /**
   *
   * - Format: double
   */
  avg_engagement_percetnage?: number;
  categories?: string[];
  cost_per_post?: SocialShallowDataCostPerPost;
  cost_per_reel?: SocialShallowDataCostPerPost;
  cost_per_story?: SocialShallowDataCostPerPost;
  describing_words?: string[];
  engagement_label?: string;
  /**
   *
   * - Format: int64
   */
  followers?: number;
  is_private?: boolean;
  /**
   *
   * - Format: int32
   */
  score?: number;
  /**
   *
   * - Format: int32
   */
  score_api?: number;
  social_id?: string;
  text_description?: string;
  username?: string;
  verification_type?: SocialVerificationType;
}

/**
 *
 * SocialShallowDataCostPerPost
 *
 */

export interface SocialShallowDataCostPerPost {
  /**
   *
   * - Format: double
   */
  high?: number;
  /**
   *
   * - Format: double
   */
  low?: number;
}

export enum SocialVerificationType {
  "api" = "api",
  "comment" = "comment",
  "api_incomplete" = "api_incomplete",
}

export interface SpecialProgramDetails {
  /**
   *
   * - Format: double
   */
  fee?: number;
  /**
   *
   * - Format: date-time
   */
  fee_until?: string;
  name?: string;
}

export enum StoryStatusEnum {
  "ongoing" = "ongoing",
  "deleted" = "deleted",
  "valid" = "valid",
  "api_not_connected" = "api_not_connected",
}

/**
 *
 * StoryView
 *
 */

export interface StoryView {
  /**
   *
   * - Format: int64
   */
  count?: number;
  /**
   *
   * - Format: int64
   */
  engagements?: number;
  /**
   *
   * - Format: int64
   */
  exits?: number;
  fb_id?: string;
  /**
   *
   * - Format: uuid
   */
  id?: string;
  image_url?: string;
  /**
   *
   * - Format: int64
   */
  influencer_fk?: number;
  /**
   *
   * - Format: date-time
   */
  media_creation_time?: string;
  /**
   *
   * - Format: int64
   */
  reach?: number;
  /**
   *
   * - Format: int64
   */
  sub_task_of?: number;
  /**
   *
   * - Format: int64
   */
  swipes_forward?: number;
  /**
   *
   * - Format: int64
   */
  taps_back?: number;
  /**
   *
   * - Format: int64
   */
  taps_forward?: number;
  /**
   *
   * - Format: int64
   */
  task_id?: number;
}

/**
 *
 * StoryViewUpload
 *
 */

export type StoryViewUpload = StoryView & {
  is_subtask?: boolean;
  upload_image?: UploadedImage;
};

/**
 *
 * StoriesViews
 *
 */

export type StoryViews = StoryView[];

export interface StoryViewsUpload {
  views?: StoryViewUpload[];
}

/**
 *
 * SubUser
 *
 */

export interface SubUser {
  /**
   *
   * - Format: date-time
   */
  created?: string;
  email?: string;
  id?: number;
  /**
   *
   * - Format: date-time
   */
  last_update?: string;
  name?: string;
}

export interface SuccessfulCharge {
  /**
   *
   * - Format: int64
   */
  charge_id?: number;
  invoice_url?: string;
}

export interface SuccessfulCreditCardTokenization {
  billing_address?: BillingAddress;
  /**
   *
   * - Format: int64
   */
  new_payment_method_id?: number;
}

export interface SupportArticleAttachment {
  content_type?: "image" | "video" | "pdf" | "doc" | "audio";
  filename?: string;
  link?: string;
}

export interface SupportRequestDetails {
  attachments?: string[];
  /**
   *
   * - Format: int64
   */
  campaign_id?: number;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  contact_email?: string;
  /**
   *
   * - Format: int64
   */
  list_id?: number;
  message?: string;
  name?: string;
}

export type SupportRequestDetailsExtended = SupportRequestDetails & {
  list_segmentation?: SupportRequestDetailsExtendedListSegmentation;
  notification_request?: SupportRequestDetailsExtendedNotificationRequest;
};

/**
 *
 * SupportRequestDetailsExtendedListSegmentation
 *
 */

export interface SupportRequestDetailsExtendedListSegmentation {
  /**
   *
   * - Format: int64
   */
  list_id?: number;
}

/**
 *
 * SupportRequestDetailsExtendedNotificationRequest
 *
 */

export interface SupportRequestDetailsExtendedNotificationRequest {
  gender?: "male" | "female" | "both";
  /**
   *
   * - Format: int64
   */
  min_followers?: number;
  /**
   *
   * - maxLength: 256
   */
  notification_text?: string;
}

export interface SupportTicket {
  /**
   *
   * - Format: date-time
   */
  archive_time?: string;
  /**
   *
   * - Format: date-time
   */
  creation_time?: string;
  /**
   *
   * - Format: int64
   */
  display_ticket_id?: number;
  has_updates?: boolean;
  /**
   *
   * - Format: int64
   */
  linked_auction_id?: number;
  /**
   *
   * - Format: int64
   */
  linked_list_id?: number;
  opening_issue_article?: string;
  /**
   *
   * SupportTicketOriginalCreator
   *
   */
  original_creator?: {
    creator_display_name?: string;
    /**
     *
     * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
     */
    creator_email?: string;
    /**
     *
     * - Format: int64
     */
    creator_id?: number;
  };
  /**
   *
   * - Format: date-time
   */
  pin_time?: string;
  status?: "open" | "closed" | "archived";
  /**
   *
   * - Format: int64
   */
  ticket_id?: number;
  waiting_for_client?: boolean;
}

export type SupportTicketArticle = NewSupportTicketArticle & {
  /**
   *
   * - Format: date-time
   */
  article_time?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  is_client?: boolean;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  poster_email?: string;
  /**
   *
   * - Format: int64
   */
  poster_user_id?: number;
  poster_username?: string;
};

export interface SwipeLimits {
  /**
   *
   * - Format: date-time
   */
  current_window_start?: string;
  /**
   *
   * - Format: date-time
   */
  current_windows_ends?: string;
  swipes_left?: number;
  swipes_limit?: number;
}

export interface Target {
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  /**
   *
   * - Format: float
   */
  current_metric_value?: number;
  /**
   *
   * - Format: date
   */
  end_time?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  renew?: boolean;
  steps?: TargetStep[];
  target_metric?: TargetMetrics;
  target_type?: TargetTypes;
}

export type TargetEdit = Target & {
  add_steps?: TargetStep[];
  delete_steps?: number[];
  edit_steps?: TargetStep[];
};

export enum TargetMetrics {
  "impressions" = "impressions",
  "engagements" = "engagements",
  "video_views" = "video_views",
  "clicks" = "clicks",
  "sales_value" = "sales_value",
  "story_views" = "story_views",
}

export interface TargetNotification {
  /**
   *
   * - Format: int64
   */
  advertiser_user_Id?: number;
  /**
   *
   * - Format: int64
   */
  auction_id?: number;
  /**
   *
   * - Format: int64
   */
  influencer_user_id?: number;
}

export interface TargetStep {
  /**
   *
   * - Format: date-time
   */
  completed_at?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  /**
   *
   * - Format: date
   */
  month_relevance?: string;
  /**
   *
   * - Format: int64
   */
  reward?: number;
  /**
   *
   * - Format: int64
   */
  target_value?: number;
}

export enum TargetTypes {
  "one_time" = "one_time",
  "monthly" = "monthly",
}

/**
 *
 * Task
 *
 */

export interface Task {
  ads_long_link?: string;
  bundle_id?: string;
  complete_at_end_of_month?: boolean;
  /**
   *
   * - Format: date-time
   */
  content_due_date?: string;
  description?: string;
  /**
   *
   * - Format: date-time
   */
  due_date?: string;
  for_ads?: boolean;
  mentions?: string[];
  /**
   *
   * - Format: date
   */
  month_relevance?: string;
  not_removable_reason?: string;
  post_sub_type?: PostSubTypes;
  post_type?: PostTypes;
  references?: string[];
  removable?: boolean;
  renews?: boolean;
  tags?: string[];
}

export interface TaskManageData {
  /**
   *
   * - Format: double
   */
  additonal_price?: number;
  ads_long_link?: string;
  all_task_content?: string[];
  bundle_id?: string;
  /**
   *
   * - Format: double
   */
  bundle_price?: number;
  can_change_price?: boolean;
  complete_at_end_of_month?: boolean;
  completed?: boolean;
  /**
   *
   * - Format: date-time
   */
  completed_at?: string;
  /**
   *
   * - Format: date-time
   */
  content_due_date?: string;
  description?: string;
  /**
   *
   * - Format: date-time
   */
  due_date?: string;
  engagemnts?: LinkedMediaEngagements;
  fb_id?: string;
  for_ads?: boolean;
  /**
   *
   * - Format: int64
   */
  id?: number;
  linked_media?: string;
  linked_media_id?: string;
  linked_media_video_url?: string;
  mentions?: string[];
  mime_type?: string;
  /**
   *
   * - Format: date
   */
  month_relevance?: string;
  not_removable_reason?: TaskNotRemovableReason;
  post_sub_type?: PostSubTypes;
  post_type?: PostTypes;
  proposal?: ProposalData;
  references?: string[];
  reminder_data?: TaskReminderData;
  removable?: boolean;
  renews?: boolean;
  /**
   *
   * - Format: int64
   */
  subtask_of?: number;
  tags?: string[];
}

/**
 *
 * TasksModification
 *
 */

export interface TaskModification {
  bundles_attributes?: BundleAttributes[];
  excluded?: number[];
  /**
   *
   * - Format: date
   */
  month_relevance?: string;
  new?: TaskWithAdditonalPrice[];
}

export enum TaskNotRemovableReason {
  "charged" = "charged",
  "paid" = "paid",
  "completed" = "completed",
  "on_contract_task" = "on_contract_task",
}

export interface TaskProposalFeedbackData {
  accepted?: boolean;
  image_remark?: string;
  images_feedback?: {
    feeback?: boolean;
    /**
     *
     * - Format: uuid
     */
    upload_id?: string;
  }[];
  text_remark?: string;
}

export interface TaskReminderData {
  extra_reminder_text?: string;
  is_reminded?: boolean;
  /**
   *
   * - Format: date-time
   */
  reminde_at?: string;
}

export type TaskWithAdditonalPrice = TaskWithId & {
  /**
   *
   * - Format: int64
   */
  additonal_price?: number;
};

export type TaskWithId = Task & {
  /**
   *
   * - Format: int64
   */
  id?: number;
};

export interface TaskWithPost {
  linked_post?: LinkedTaskPost;
  tasks_modifications?: TaskModification;
  views?: StoryViewsUpload;
}

export type TaskWithPrice = TaskWithId & {
  /**
   *
   * - Format: int64
   */
  price?: number;
  price_offered_state?:
    | "wait_influencer"
    | "wait_advertiser"
    | "influencer_accepted"
    | "influencer_rejected"
    | "advertiser_rejected";
  seen?: boolean;
};

export interface TasksAmountAndPercentages {
  amount?: number;
  engagement_percentage?: number;
  views_percentage?: number;
}

export interface TasksAmounts {
  ig_post?: number;
  ig_reel?: number;
  ig_story?: number;
  tk_video?: number;
  tw_tweet?: number;
  yt_video?: number;
}

export interface TasksPaymentsInstructions {
  complete_at_end_of_month?: boolean;
  split_payments?: boolean;
  tasks_renews?: boolean;
}

export interface TimeFilter {
  /**
   *
   * - Format: int32
   */
  amount?: number;
  /**
   *
   * - Format: date-time
   */
  from?: string;
  /**
   *
   * - Format: int32
   */
  offset?: number;
  /**
   *
   * - Format: date-time
   */
  to?: string;
}

export type TimeFilterWithoutTimeZone = TimeFilter & {
  /**
   *
   * - Format: date-time
   */
  from_without_timezone?: string;
  /**
   *
   * - Format: date-time
   */
  to_without_timezone?: string;
};

export interface TodoTemplate {
  /**
   *
   * - Format: int64
   */
  created_by?: number;
  description?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  is_global?: boolean;
  tasks?: AuctionToDoItem[];
  title?: string;
}

export interface TrackerEvent {
  color?: string;
  /**
   *
   * - Format: date-time
   */
  end_time?: string;
  id?: string;
  is_completed?: boolean;
  is_deleted?: boolean;
  /**
   *
   * - Format: date-time
   */
  start_time?: string;
  text?: string;
  type?: string;
}

export type TurkishBankAccount = BankAccountDetails & { iban?: string };

export type UKBankAccount = BankAccountDetails & {
  address?: BillingAddress;
  /**
   *
   * - Format: date-time
   */
  birth_date?: string;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  email?: string;
};

export type UnfinishedCampaign = UnfinishedCampaignShallow & {
  json_data?: string;
  uploads?: { upload_url?: string; uuid?: string }[];
};

/**
 *
 * UnfinishedCampaignShallow
 *
 */

export interface UnfinishedCampaignShallow {
  /**
   *
   * - Format: int64
   */
  id?: number;
  title?: string;
  /**
   *
   * - Format: date-time
   */
  update_time?: string;
}

export interface UpdatesAvailable {
  updates_available?: boolean;
}

export interface UploadedImage {
  /**
   *
   * - Format: byte
   */
  data?: string;
  mime_type?: "image/bmp" | "image/jpeg" | "image/png" | "image/jpg";
  url?: string;
}

export type UserAvgReviewsData = NewReviewData & {
  /**
   *
   * - Format: int64
   */
  content_stars_amount?: number;
  /**
   *
   * - Format: int64
   */
  number_of_text_reviews?: number;
  /**
   *
   * - Format: int64
   */
  professional_stars_amount?: number;
  /**
   *
   * - Format: int64
   */
  results_stars_amount?: number;
  /**
   *
   * - Format: int64
   */
  stars_amount?: number;
};

export interface UserFeatureFeedback {
  comment?: string;
  form_name?: string;
  /**
   *
   * - Format: int32
   * - minimum: -1
   */
  rate?: number;
}

export interface UserInBoard {
  bid?: number;
  bid_id?: number;
  campaign_data?: Campaign;
  /**
   *
   * ChatWithInfluencerStatus
   *
   */
  chat_status?: {
    last_message_mine?: boolean;
    last_message_seen?: boolean;
    no_messages?: boolean;
  };
  column?: string;
  influencer_data?: InfluencerSearchData;
  note?: string;
  position?: number;
  /**
   *
   * - Format: double
   */
  price_offered?: number;
  price_offered_state?:
    | "wait_influencer"
    | "wait_advertiser"
    | "influencer_accepted"
    | "influencer_rejected"
    | "advertiser_rejected";
  status?: string;
  story_views?: StoryViews;
  /**
   *
   * - Format: int64
   */
  system_user_id?: number;
}

export interface UserOnFeed {
  /**
   *
   * - Format: int64
   */
  id?: number;
  image?: string;
  name?: string;
  username?: string;
}

export type UserOnFeedAndActionTime = UserOnFeed & {
  /**
   *
   * - Format: date-time
   */
  action_time?: string;
};

export interface UserSocialLoginSuccess {
  about?: string;
  /**
   *
   * - Format: double
   */
  average_likes?: number;
  bank_account_attached?: boolean;
  /**
   *
   * - Format: date-time
   */
  birth_date?: string;
  can_open_auctions?: boolean;
  categories?: string[];
  /**
   *
   * UserSocialLoginSuccessCompanyDetails
   *
   */
  company_details?: {
    address?: string;
    country?: string;
    facebook_page?: string;
    instagram_page?: string;
    name?: string;
  };
  contact_details?: string;
  /**
   *
   * - Format: int64
   */
  followers_count?: number;
  /**
   *
   * UserSocialLoginSuccessInfluencerDetails
   *
   */
  influencer_details?: {
    address?: string;
    can_bid?: boolean;
    country?: string;
    instagram_name?: string;
  };
  picture_url?: string;
  profile_id?: string;
  provider?:
    | "local"
    | "email"
    | "instagram"
    | "google"
    | "facebook"
    | "twitter"
    | "youtube"
    | "apple";
  sidebar_data?: SideBarData;
  /**
   *
   * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
   */
  user_email?: string;
  /**
   *
   * - Format: int64
   */
  user_id?: number;
  user_kind?: "influencer" | "advertiser";
  user_name?: string;
}

export enum UserType {
  "advertiser" = "advertiser",
  "influencer" = "influencer",
  "agent" = "agent",
}

/**
 *
 * InfluencersToOperate
 *
 */

export type UsersToOperate = {
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  influencer_registered?: boolean;
}[];

export interface ValidPasswordResetToken {
  token?: string;
}

export interface VerifiedPostsCount {
  /**
   *
   * - Format: int64
   */
  instagram?: number;
  /**
   *
   * - Format: int64
   */
  tiktok?: number;
  /**
   *
   * - Format: int64
   */
  twitter?: number;
  /**
   *
   * - Format: int64
   */
  youtube?: number;
}

export interface VersionUpdate {
  /**
   *
   * - Format: date-time
   */
  created_at?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  markdown?: string;
}

export interface WixInfluencerData {
  activity_data?: InfluencerActivityData;
  bio?: string;
  contact_details?: InfluencerContactDetails;
  content_examples?: {
    media_id?: string;
    post_type?: PostTypes;
    /**
     *
     * - Format: date-time
     */
    posted_at?: string;
  }[];
}

export type WixInfluencerManage = InfluencerShallowData & {
  links?: WixInfluencerShortLink[];
  posts?: WixInfluencerMedia[];
  /**
   *
   * - Format: int64
   */
  price?: number;
  stories?: WixStoryView[];
};

export interface WixInfluencerMedia {
  linked_media?: string;
  media_url?: string;
  post_type?: PostTypes;
  /**
   *
   * - Format: int64
   */
  price?: number;
  social_network?: "instagram";
}

export interface WixInfluencerShortLink {
  description?: string;
  /**
   *
   * - Format: int32
   */
  extra_link_number?: number;
  /**
   *
   * - Format: int64
   */
  influencer_id?: number;
  long_link?: string;
  short_link?: string;
}

export interface WixSavedRoiFilterData {
  /**
   *
   * - Format: date-time
   */
  created_at?: string;
  /**
   *
   * - Format: int64
   */
  id?: number;
  /**
   *
   * - minLength: 2
   */
  name?: string;
}

export type WixStoryView = StoryView & {
  /**
   *
   * - Format: int64
   */
  price?: number;
};

export interface WixUserSettings {
  currency?: Currency;
  /**
   *
   * wixSearchFilter
   *
   */
  default_search_filter?: {
    categories?: InfluencerCategories;
    country?: string;
    followers_range?: FollowersData;
    gender?: "male" | "female" | "both";
  };
  /**
   *
   * WixPlanStatus
   *
   */
  plan_status?: {
    /**
     *
     * WixUserLimit
     *
     * the user limit
     */
    limits?: {
      influencers?: CurrentAndLimit;
      linked_content?: CurrentAndLimit;
      /**
       *
       * - Format: date-time
       */
      next_reset_time?: string;
      searches?: CurrentAndLimit;
    };
    plan_id?: string;
    plan_name?: string;
  };
  short_link_prefix?: string;
}

export interface UtmCodes {
  utm_medium?: string;
  utm_source?: string;
}
