import { useCallback } from "react";
import { AuctionDataExtended } from "src/api/ExtendedTypes";
import { InfluencerInManageAuctionData, TaskManageData } from "src/api/types";
import { getSocialMediaLinkByContentType } from "src/helpers/SocialNetworksHelper";
import { Columns } from "./CampaignManagerInterfaces";

interface OwnProps {
  action: "downloadBoardCsv" | "downloadAnswersCSV";
  board: AuctionDataExtended;
  getConcatColumn: any;
  influencersSelected: any;
  questions: any;
  setCsvData: any;
}

export const DownloadExcelButton = ({
  action,
  board,
  getConcatColumn,
  influencersSelected,
  setCsvData,
}: OwnProps) => {
  const downloadBoardCsv = useCallback(() => {
    let { influencers } = getConcatColumn(board, Columns.approval, true);
    if (influencersSelected && influencersSelected.length > 0) {
      influencers = influencers.filter((x: any) =>
        influencersSelected.includes(x.influencer_id),
      );
    }
    let header = [
      "Name",
      "Instagram Username",
      "TWitter Username",
      "YouTube Channel",
      "TikTok Username",
      "Comments",
      "Tags",
    ];
    let shortLink: string | null = null;
    // eslint-disable-next-line no-cond-assign
    if ((shortLink = board.summary.short_link)) {
      header.push("Link");
    }

    const tasksIdx = header.length - 1;

    let tasks: Array<TaskManageData> = [];
    influencers.forEach((inf: InfluencerInManageAuctionData) => {
      inf?.tasks_details?.tasks.forEach((t: TaskManageData) => {
        if (!t.subtask_of && !tasks.find((x) => x.id === t.id)) {
          tasks.push(t);
        }
      });
    });
    tasks = tasks.sort(function (a, b) {
      return a.id - b.id;
    });
    header = header.concat(
      tasks.map((t, idx) => {
        let type = "";
        if (t.post_type === "ig_post") {
          type = "Instagram Post";
        } else if (t.post_type === "ig_story") {
          type = "Instagram Story";
        } else if (t.post_type === "tw_tweet") {
          type = "Twitter Tweet";
        } else if (t.post_type === "yt_video") {
          type = "YouTube video";
        } else if (t.post_type === "tk_video") {
          type = "TikTok video";
        } else if (t.post_type === "influencer_content") {
          type = "Content only";
        }
        return `Task ${idx + 1} - ${type}`;
      }),
    );
    const table = [header];

    influencers.forEach((inf: InfluencerInManageAuctionData) => {
      const row = new Array(header.length);
      row[0] = inf.name;
      if (inf.social_network_data) {
        row[1] = inf.social_network_data.instagram
          ? inf.social_network_data.instagram.username
          : "";
        row[2] = inf.social_network_data.twitter
          ? inf.social_network_data.twitter.username
          : "";
        row[3] = inf.social_network_data.youtube
          ? inf.social_network_data.youtube.username
          : "";
        row[4] = inf.social_network_data.tiktok
          ? inf.social_network_data.tiktok.username
          : "";
      }
      row[5] = inf.note ? inf.note : "";
      if (board.tags) {
        row[6] = board.tags
          .filter((x: any) => x.influencers.includes(inf.influencer_id))
          .map((x: any) => x.label)
          .join(" , ");
      }
      if (shortLink) {
        row[7] = `https://${shortLink}/${inf.influencer_id}`;
      }
      inf?.tasks_details?.tasks.forEach((t: TaskManageData) => {
        let tIdx;
        if (
          // eslint-disable-next-line no-cond-assign
          (tIdx = tasks.findIndex(
            (a) => a.id === t.id || a.id === t.subtask_of,
          )) > -1
        ) {
          let status = "";
          if (t.subtask_of) {
            status = ` , ${t.linked_media}`;
          } else if (t.completed) {
            status = "Completed ";
            status += getSocialMediaLinkByContentType(
              t.post_type,
              t.linked_media,
            );
          } else if (t.proposal) {
            if (t.proposal.approved === true) {
              status = "Approved";
            } else if (t.proposal.approved === false) {
              status = "Not Approved";
            } else {
              status = "Submitted to approval";
            }
          } else {
            status = "Awaiting Task";
          }
          if (!row[tasksIdx + tIdx]) {
            row[tasksIdx + tIdx] = "";
          }
          row[tasksIdx + tIdx] += status;
        }
      });
      table.push(row);
    });
    setCsvData(table);

    setTimeout(() => {
      document.getElementById("csv-test").click();
    }, 1000);
  }, [board, getConcatColumn, influencersSelected, setCsvData]);

  const downloadAnswersCSV = useCallback(() => {
    let { influencers } = getConcatColumn(board, Columns.publish, true);
    if (influencersSelected && influencersSelected.length > 0) {
      influencers = influencers.filter((x: any) =>
        influencersSelected.includes(x.influencer_id),
      );
    }

    const header = [
      "Name",
      "Instagram Username",
      "TWitter Username",
      "YouTube Channel",
      "TikTok Username",
    ];

    influencers.forEach((inf: InfluencerInManageAuctionData) => {
      inf.questions.forEach((q) => {
        if (!header.includes(q.question)) {
          header.push(q.question);
        }
      });
    });
    const table = [header];

    influencers.forEach((inf: InfluencerInManageAuctionData) => {
      const row = new Array(header.length);
      row[0] = inf.name;
      if (inf.social_network_data) {
        row[1] = inf.social_network_data.instagram
          ? inf.social_network_data.instagram.username
          : "";
        row[2] = inf.social_network_data.twitter
          ? inf.social_network_data.twitter.username
          : "";
        row[3] = inf.social_network_data.youtube
          ? inf.social_network_data.youtube.username
          : "";
        row[4] = inf.social_network_data.tiktok
          ? inf.social_network_data.tiktok.username
          : "";
      }

      inf.questions.forEach((q) => {
        const idx = header.findIndex((x) => x === q.question);
        if (idx > -1) {
          row[idx] = q.answer;
        }
      });
      table.push(row);
    });
    setCsvData(table);

    setTimeout(() => {
      document.getElementById("csv-test").click();
    }, 1000);
  }, [board, getConcatColumn, influencersSelected, setCsvData]);

  if (action === "downloadAnswersCSV") {
    return (
      <div onClick={downloadAnswersCSV}>
        <i className="far fa-file-excel" />{" "}
        <span>Download Q&A as spreadsheet (Excel)</span>
      </div>
    );
  }
  return (
    <div onClick={downloadBoardCsv}>
      <i className="far fa-file-excel" />{" "}
      <span>Download influencers as spreadsheet (Excel)</span>
    </div>
  );
};
