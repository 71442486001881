import axios from "axios";
import { toast } from "react-toastify";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";

const getNotificationBaseUrl = () => `${getBackendUri()}/moi/notifications`;
const getNewNotificationsBaseUrl = () =>
  `${getBackendUri()}/moi/notifications/new_notifications`;

export const getNewNotificationsCount = () => {
  return (dispatch) => {
    axios
      .get(getNewNotificationsBaseUrl(), {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "GET_NEW_NOTIFICATIONS_COUNT_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_NEW_NOTIFICATIONS_COUNT_REJECTED",
          payload: err,
        });
        console.error(err);
      });
  };
};

export const getNotifications = (amount, callback) => {
  return (dispatch) => {
    axios
      .post(
        getNotificationBaseUrl(),
        { from: null, to: null, amount, offset: null },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        // eslint-disable-next-line no-param-reassign
        response.data.hasMore = response.data.length === amount;
        dispatch({
          type: "GET_NOTIFICATIONS_FULFILLED",
          payload: response.data,
        });
        dispatch(getNewNotificationsCount());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_NOTIFICATIONS_REJECTED", payload: err });
        console.error(err);
      });
  };
};

export const getMoreNotifications = (to, amount, callback) => {
  return (dispatch) => {
    axios
      .post(
        getNotificationBaseUrl(),
        { from: null, to, amount, offset: null },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        // eslint-disable-next-line no-param-reassign
        response.data.hasMore = response.data.length === amount;
        dispatch({
          type: "UPDATE_NOTIFICATIONS_FULFILLED",
          payload: response.data,
        });
        dispatch(getNewNotificationsCount());
        if (callback) callback();
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_NOTIFICATIONS_REJECTED", payload: err });
        console.error(err);
      });
  };
};

export const markNotifAsSeen = (notifId) => {
  return (dispatch) => {
    axios
      .put(
        `${getNotificationBaseUrl()}/${notifId}`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        dispatch(getNewNotificationsCount());
        dispatch({
          type: "MARK_NOTIFICATION_SEEN_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: "MARK_NOTIFICATION_SEEN_REJECTED", payload: err });
        console.error(err);
      });
  };
};

export const deleteNotification = (notifId, callback) => {
  return (dispatch) => {
    axios
      .delete(
        `${getNotificationBaseUrl()}/${notifId}`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response);
        dispatch({ type: "NOTIFICATION_DELETED_FULFILLED", payload: notifId });
      })
      .catch((err) => {
        dispatch({ type: "NOTIFICATION_DELETED_REJECTED", payload: err });
        console.error(err);
      });
  };
};
export const deleteAllNotifications = (callback) => {
  return (dispatch) => {
    axios
      .delete(
        `${getNotificationBaseUrl()}/`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response);
        dispatch({
          type: "ALL_NOTIFICATIONS_DELETED_FULFILLED",
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({ type: "ALL_NOTIFICATIONS_DELETED_REJECTED", payload: err });
        console.error(err);
      });
  };
};

export const setToasterNotificaion = (message) => {
  return () => {
    notificationOpts.title = window.translate(
      "Success!",
      "system_notifications_success",
    );
    notificationOpts.message = message;
    toast.success(notificationOpts.message);
  };
};
