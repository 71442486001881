import axios from "axios";
import { toast } from "react-toastify";
import { getUploadAuctionFilesUrl } from "../api/actions/auctionActions";
import { getUploadShareInvoices } from "../api/actions/shareActions/auctionActions";
import { getBackendUri, getSavedJwtToken } from "./commonActionsConf";

const headers = {
  withCredentials: true,
  headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
};

export const UploadMediaToAWS = (
  s3url,
  file,
  mimeType,
  uri,
  onProgress,
  onFinish,
) => {
  const xhr = new XMLHttpRequest();
  xhr.upload.addEventListener(
    "progress",
    (e) => {
      onProgress(((e.loaded / e.total) * 100).toFixed(0));
    },
    false,
  );
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        // Successfully uploaded the file.
        onFinish(encodeURI(file.media_url));
        // onSuccess(file.uri);
      } else {
        // The file could not be uploaded.
        onFinish(false);
      }
    }
  };
  xhr.open("PUT", s3url);
  // xhr.setRequestHeader('X-Amz-ACL', 'public-read');
  // for text file: text/plain, for binary file: application/octet-stream
  xhr.setRequestHeader("Content-Type", file.type);
  xhr.send(file);
};

export const getUploadPath = (fileName, fileType, uploadType, callback) => {
  const body = {
    file_name: fileName,
    file_type: fileType,
    upload_type: uploadType,
  };
  axios
    .post(`${getBackendUri()}/moi/generate_upload_path`, body, headers)
    .then((response) => {
      callback(response.data);
    })
    .catch((e) => {
      callback(false);
      console.log(e);
    });
};

export const uploadMedia = (media, type, uploadType, progress, callback) => {
  // eslint-disable-next-line no-param-reassign
  if (!uploadType) uploadType = "brand_logo";
  const name = `web_${media.name.replace("jfif", "jpg")}`;

  const generateCallback = (response) => {
    if (response) {
      // eslint-disable-next-line no-param-reassign
      media.media_url = response.result_path;
      UploadMediaToAWS(
        response.upload_path,
        media,
        media.mime,
        media.path,
        progress,
        (filePath) => callback(media.id, media.type, filePath),
      );
    } else {
      toast.error("Failed to upload file");
      callback(media.id, type, false);
    }
  };
  if (uploadType === "share-invoice") {
    getUploadShareInvoices({
      file_name: name,
      file_type: type,
      upload_type: "invoice",
    })
      .then((res) => {
        generateCallback(res.data);
      })
      .catch(() => generateCallback());
  } else {
    getUploadPath(name, type, uploadType, generateCallback);
  }
};

export const uploadFilesMedia = (
  influencerId,
  auctionId,
  media,
  type,
  uploadType,
  progress,
  callback,
) => {
  // eslint-disable-next-line no-param-reassign
  const name = `web_${media.name.replace("jfif", "jpg")}`;

  getUploadAuctionFilesUrl(influencerId, auctionId, {
    file_name_with_extension: name,
  }).then((response) => {
    if (response) {
      // eslint-disable-next-line no-param-reassign
      media.media_url = response.data.uuid;
      UploadMediaToAWS(
        response.data.url,
        media,
        media.mime,
        media.path,
        progress,
        () => callback(media.id, media.type, response.data.uuid),
      );
    } else {
      toast.error("Failed to upload file");
      callback(media.id, type, false);
    }
  });
};

export const updateUnfinishedCampaignImage = (
  unfinishedCampaignId,
  imageId,
  imageUrl,
  callback,
) => {
  axios
    .post(
      `${getBackendUri()}/moi/unfinished_campaigns/${unfinishedCampaignId}/image_uploaded`,
      { image_id: imageId, image_url: imageUrl },
      headers,
    )
    .then((response) => {
      if (callback) {
        callback(response.data);
      }
    })
    .catch((e) => {
      if (callback) {
        callback(false);
      }
      console.log(e);
    });
};

export const addPicture = (
  acceptedFile,
  uploadType,
  callback,
  progress,
  mediaType = "image",
) => {
  const reader = new FileReader();
  const file = acceptedFile[0];
  reader.readAsDataURL(file);
  reader.onload = () => {
    uploadMedia(file, mediaType, uploadType, progress, (res, type, fileUrl) => {
      if (callback) {
        callback(fileUrl, type);
      }
    });
  };
  reader.onerror = (error) => {
    console.log("Error: ", error);
  };
};
