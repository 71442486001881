import { InfluencerInManageAuctionData } from "src/api/types";
import {
  getProfileLinkBySocialNetwork,
  SocialIconsT,
  SocialNetworkEnum,
} from "src/helpers/SocialNetworksHelper";
import { formatBigNumbers } from "../../utilities/general";

const allwaysConectedSocials = [
  SocialNetworkEnum.twitter,
  SocialNetworkEnum.youtube,
];
interface OwnProps {
  influencer: InfluencerInManageAuctionData;
}
export const CampaignInfluencerFollowersNumbers = ({
  influencer,
}: OwnProps) => {
  const socialNetworks: any = influencer?.social_network_data;
  let counter = 0;
  Object.keys(socialNetworks).forEach((x) => {
    if (socialNetworks[x] && socialNetworks[x].followers) {
      counter++;
    }
  });
  // eslint-disable-next-line no-nested-ternary
  const format = counter > 2 ? (counter > 3 ? "0a" : "0.0a") : "0.00a";
  const renderSocialBadge = (socialNetwork: SocialNetworkEnum, key: any) => {
    const link = getProfileLinkBySocialNetwork(
      socialNetwork,
      influencer?.social_network_data[socialNetwork]?.username,
    );
    const icon = SocialIconsT[socialNetwork];
    const connected =
      influencer?.social_network_data[socialNetwork]?.verification_type ===
        "api" || allwaysConectedSocials.includes(socialNetwork);
    return (
      influencer?.social_network_data[socialNetwork] &&
      icon && (
        <div
          className={`${connected ? "connected" : ""} influencer-followers`}
          key={key}
        >
          <a
            aria-label={`${connected ? "(Connected)" : "(Not connected)"} ${
              influencer?.social_network_data[socialNetwork]?.username
            }`}
            href={link}
            target="_blank"
            style={{ color: "#333" }}
            rel="noreferrer"
            className={`${connected ? "verified-social" : "unverified-social"}`}
          >
            <span style={{ position: "relative" }}>
              <li className={icon} />{" "}
              {formatBigNumbers(
                influencer?.social_network_data[socialNetwork]?.followers,
                {
                  format,
                  minForFormat: 1000,
                },
              )}
            </span>
            <div className="api-indicator" />
          </a>
        </div>
      )
    );
  };

  return (
    <div style={{ display: "flex", marginRight: -5, marginTop: 3 }}>
      {Object.keys(socialNetworks).map((socialNetwork, key) => {
        return (
          influencer?.social_network_data &&
          renderSocialBadge(socialNetwork as SocialNetworkEnum, key)
        );
      })}
    </div>
  );
};
