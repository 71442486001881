import { Heading } from "@chakra-ui/react";
import Dialog from "rc-dialog";
import React from "react";

interface Props {
  icon?: string;
  handleClose(): void;
  style?: React.CSSProperties;
  children: React.ReactElement<any>;
  image?: string;
  disableClose?: boolean;
  title?: string;
}

export const MessageDialog = (props: Props) => {
  const icon = props.icon ? props.icon : "moi-comments-full";
  return (
    <Dialog
      onClose={props.handleClose}
      className={`message-dialog ${props.disableClose ? "disable-close" : ""}`}
      visible={true}
      style={
        props.style ? props.style : { width: "400px", margin: "100px auto" }
      }
      animation="slide-fade"
      maskAnimation="fade"
    >
      <div
        style={{
          height: props?.title ? 140 : 120,
          paddingTop: 20,
          background: "var(--main-baby-blue-color)",
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
        }}
      >
        {props.image ? (
          <img
            className={"message-dialog-icon"}
            src={props.image}
            style={{ paddingTop: 0 }}
            alt=""
          />
        ) : (
          <div className={`message-dialog-icon ${icon}`} />
        )}
        {props.title && (
          <Heading
            style={{
              color: "white",
              margin: 5,
              textAlign: "center",
              fontSize: 18,
            }}
          >
            {props.title}
          </Heading>
        )}
      </div>
      {props.children}
    </Dialog>
  );
};
