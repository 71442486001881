import { Avatar, Box, Button, Card } from "@chakra-ui/react";
import { InfluencerShallowData } from "src/api/types";
import {
  SocialIcon,
  SocialNetworkEnum,
} from "src/helpers/SocialNetworksHelper";
import { formatBigNumbers } from "../../utilities/general";

type Props = {
  influencer: InfluencerShallowData;
  openInfluencer: any;
  variant?: "list" | "influencer" | undefined;
  openAddToList?: any;
};

type SocialNetworkItemProps = {
  social: SocialNetworkEnum;
  followers: number;
};

const socialNetworksToShowInCard = 2;
const SocialNetworkItem = (props: SocialNetworkItemProps) => {
  return props.followers ? (
    <Box className={"social-network-item"}>
      <SocialIcon className={"colored"} social={props.social} />
      <span style={{ fontSize: 12 }}> {formatBigNumbers(props.followers)}</span>
    </Box>
  ) : null;
};

const sortSocialWithFollowers = (infl: InfluencerShallowData) => {
  let socials = [
    {
      social: SocialNetworkEnum.instagram,
      followers: infl?.instagram_data?.followers,
    },
    {
      social: SocialNetworkEnum.twitter,
      followers: infl?.twitter_data?.followers,
    },
    {
      social: SocialNetworkEnum.tiktok,
      followers: infl?.tiktok_data?.followers,
    },
    {
      social: SocialNetworkEnum.youtube,
      followers: infl?.youtube_data?.followers,
    },
  ];
  socials = socials.sort((a, b) => {
    return b.followers - a.followers;
  });
  return socials.splice(0, socialNetworksToShowInCard);
};

export const LookalikeCard = ({
  influencer,
  openInfluencer,
  variant,
  openAddToList,
}: Props) => {
  const socialData = sortSocialWithFollowers(influencer);
  return (
    <Card
      m={1}
      h={variant === "list" ? "auto" : "63px"}
      className={
        variant === "list" ? "suggestion-influ-card" : "lookalike-card"
      }
    >
      <Box
        justifyContent={"center"}
        alignContent={"center"}
        display={"flex"}
        p={"8px"}
        pb={0}
      >
        <Avatar
          size={variant === "list" ? "md" : "sm"}
          src={influencer.picture_url}
          onClick={openInfluencer}
          className="clickable"
        />
        <span
          onClick={openInfluencer}
          className="ellipsis"
          style={{
            color: "var(--main-baby-blue-color)",
            width: "100%",
            flex: 1,
            alignSelf: "center",
            justifyContent: "flex-start",
            textAlign: "center",
            marginLeft: 5,
            fontSize: 12,
            cursor: "pointer",
          }}
        >
          {influencer.name}
        </span>
      </Box>
      <Box display={"flex"} alignSelf="center">
        {socialData.map((sni, i) => (
          <SocialNetworkItem
            key={i}
            social={sni.social}
            followers={sni.followers}
          />
        ))}
      </Box>
      {variant === "list" ? (
        <Box>
          <Button
            size="sm"
            className="btn btn-primary"
            variant="solid"
            borderRadius={15}
            onClick={openAddToList}
          >
            <i className="moi-add-list" />
            &nbsp;Add to list{" "}
          </Button>
        </Box>
      ) : null}
    </Card>
  );
};
