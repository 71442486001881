import React from "react";
import { Trans, TranslationProps, withTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider from "react-slick";
import { AcademyCourseProgress } from "./academyCourseProgress";

interface OwnProps {
  id: bigint;
  getSingleCourse: any;
  navigateToCourse: any;
  navigateToLesson: any;
  current_course: any;
  loading: boolean;
  getCourses: any;
  navigateToQuiz: any;
}

type Props = OwnProps;

type State = Readonly<{
  currentChapter: any;
  loading: boolean;
}>;

class AcademySingleCourseInner extends React.Component<
  Props,
  State,
  TranslationProps
> {
  readonly state: State = {
    currentChapter: {},
    loading: false,
  };

  componentDidMount(): void {
    this.setState({});
  }

  render() {
    if (!this.props.current_course || !this.props.current_course?.all_chapters)
      return (
        <div className="courses">
          <div className={"single-course-container"}>
            {this.props.loading || this.state.loading ? (
              <span>
                <Trans>Loading course...</Trans>
              </span>
            ) : (
              <span>
                <Trans>Course not found.</Trans>
              </span>
            )}
          </div>
        </div>
      );
    return (
      <>
        {
          <AcademyCourseProgress
            navigate_to_lesson={this.props.navigateToLesson}
            {...this.props}
          />
        }
        <div className="courses">
          <div className={"single-course-container"}>
            {this.props.current_course?.all_chapters.map(
              (c: any, i: number) => {
                return this.renderChapterBox(c, i);
              },
            )}
            {/* <textarea style={{height:1000,width:1000}} defaultValue={JSON.stringify(this.props.current_course,null,'\t')}/> */}
          </div>
        </div>
      </>
    );
  }

  isRTL = () => {
    return ["he", "ar"].includes(
      this.props.current_course.subscription_status.language,
    );
  };

  renderChapterBox = (chap: any, ordinal_number: number) => {
    const currentChapter = this.props.current_course?.current_chapter;
    const isAvailable = currentChapter?.ordinal_num >= chap.ordinal_number;
    const isCurrentChapter = currentChapter?.id === chap.id;
    const { current_chapter_status: currentChapterStatus } =
      this.props.current_course;
    if (!currentChapterStatus.watched_video)
      currentChapterStatus.lesson_number = 0;
    if (
      isAvailable &&
      !chap.is_completed &&
      !currentChapterStatus.completed_test
    )
      // eslint-disable-next-line no-param-reassign
      chap.is_open = true;
    return (
      <div
        className={`chapter-box-wrapper ${this.isRTL() ? "rtl" : "ltr"}`}
        key={chap.id}
      >
        <div
          className={`chapter-box${chap.is_completed ? " completed" : ""}${
            isAvailable ? " available" : ""
          }`}
        >
          <div
            onClick={() => {
              // eslint-disable-next-line no-param-reassign
              chap.is_open = !chap.is_open;
              this.setState({});
            }}
            className={`clickable open-status ${chap.is_open ? "open" : ""}`}
          >
            <i className={`fa fa-chevron-${chap.is_open ? "up" : "down"}`} />
          </div>
          <div
            className="top-content"
            onClick={() => {
              // eslint-disable-next-line no-param-reassign
              chap.is_open = !chap.is_open;
              this.setState({});
            }}
          >
            <div
              className="chapterNum"
              onClick={() => {
                if (isCurrentChapter)
                  this.props.navigateToLesson(
                    chap.id,
                    currentChapterStatus.lesson_number,
                  );
              }}
            >
              <span>
                <Trans>CHAPTER</Trans>
              </span>
              <span>0{ordinal_number + 1}</span>
            </div>
            <div className="horiz-spacer" />
            <div
              className="chap-title"
              onClick={() => {
                if (isCurrentChapter)
                  this.props.navigateToLesson(
                    chap.id,
                    currentChapterStatus.lesson_number,
                  );
              }}
            >
              <strong>{chap.title}</strong>
            </div>
            <div className="horiz-spacer" />
            <div className="lesson-status">
              {chap.is_completed ? (
                <div className="completed">
                  <i className="fas fa-check" />
                </div>
              ) : (
                <>
                  <i className="fas fa-laptop-code" />
                  <div
                    className="current-lesson-state"
                    onClick={() => {
                      if (isCurrentChapter)
                        this.props.navigateToLesson(
                          chap.id,
                          currentChapterStatus.lesson_number,
                        );
                    }}
                  >
                    <span>
                      <Trans>LESSON</Trans>{" "}
                      {isCurrentChapter
                        ? currentChapterStatus.lesson_number
                        : 0}
                      /{chap.number_of_lessons}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          {chap.is_open && (
            <div className="bottom-content">
              <hr />
              <div className="description">
                {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore */}
                <ReactMarkdown>{chap.description}</ReactMarkdown>
              </div>
              {this.renderLessonsSlider(
                chap.number_of_lessons,
                chap.is_completed,
                chap.id,
                isCurrentChapter,
                chap,
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  getLessonState = (lesson: number, is_course_completed: boolean) => {
    const { current_chapter_status: currentChapterStatus } =
      this.props.current_course;
    let isCompleted = currentChapterStatus.lesson_number > lesson;
    if (
      currentChapterStatus.lesson_number === lesson &&
      currentChapterStatus.completed_lessons
    ) {
      isCompleted = true;
    }
    if (is_course_completed || isCompleted) {
      return "completed";
    }
    if (lesson === 0 || lesson <= currentChapterStatus.lesson_number) {
      return "active";
    }
    return "disabled";
  };

  renderLessonsSlider = (
    number_of_lessons: number,
    is_course_completed: boolean,
    chapter_id: number,
    isCurrentChapter: boolean,
    chapter: any,
  ) => {
    const hasQuiz =
      isCurrentChapter &&
      !this.props.current_course.current_chapter_status.completed_test;
    const quizEnabled =
      hasQuiz &&
      this.props.current_course.current_chapter_status.completed_lessons;
    let slides = chapter.video_url ? number_of_lessons + 1 : number_of_lessons;
    slides = hasQuiz ? slides + 1 : slides;
    const sliderSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: number_of_lessons > 7 ? 8 : slides,
      slidesToScroll: number_of_lessons > 7 ? 8 : slides,
      draggable: false,
    };
    const lessons = [];
    if (chapter.video_url) {
      lessons.push({
        id: 0,
        lesson_state: `${this.getLessonState(
          0,
          is_course_completed,
        )} clickable`,
      });
    }
    for (let i = 0; i < number_of_lessons; i += 1) {
      const lessonId = i + 1;
      let lessonState = this.getLessonState(lessonId, is_course_completed);
      if (lessonState !== "disabled") lessonState += " clickable";
      lessons.push({ id: lessonId, lesson_state: lessonState });
    }
    return (
      <div style={{ margin: "0 auto" }} className={"lesson-slides"}>
        {" "}
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        }
        <Slider {...(sliderSettings as any)} className="seg-posts-slider">
          {lessons.map((lesson: any) => (
            <div key={lesson.id}>
              {lesson.id === 0 ? (
                <>
                  <div
                    className={`current-lesson-state ${lesson.lesson_state}`}
                  >
                    <div
                      className="lesson-on-progress"
                      onClick={() =>
                        this.props.navigateToLesson(chapter_id, lesson.id)
                      }
                    >
                      <i className="fas fa-video" />
                      <div>
                        <Trans>INTRO</Trans>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={`current-lesson-state ${lesson.lesson_state}`}>
                  <div
                    className="lesson-on-progress"
                    onClick={() =>
                      this.props.navigateToLesson(chapter_id, lesson.id)
                    }
                  >
                    <i className="fas fa-laptop-code" />
                    <div>
                      <Trans>LESSON</Trans> {lesson.id}/{number_of_lessons}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {hasQuiz && (
            <>
              <div
                className={`current-lesson-state ${
                  quizEnabled ? "active clickable" : "disabled"
                }`}
              >
                <div
                  className="lesson-on-progress"
                  onClick={() => {
                    if (quizEnabled) {
                      this.props.navigateToQuiz(
                        this.props.current_course?.current_chapter.id,
                        this.props.current_course.current_chapter_status
                          .current_lesson_id,
                        this.props.current_course?.current_chapter.test_id,
                      );
                    }
                  }}
                >
                  <i className="fa fa-wpforms" />
                  <div>
                    <Trans>QUIZ</Trans>
                  </div>
                </div>
              </div>
            </>
          )}
        </Slider>
      </div>
    );
  };
}
const mapStateToProps = (state: any) => {
  return {
    current_course: state.academyReducer.current_course,
    loading: state.loadersReducer.general,
  };
};

const mapDispatchToProps = () => {
  return {};
};
export const AcademySingleCourse = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AcademySingleCourseInner));
