import {
  As,
  AspectRatio,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image as ImageComponent,
  Link,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import MobileDetect from "mobile-detect";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useGetSharesInvite } from "src/api/actions/shareActions";
import { useBoolean } from "src/hooks/coreHooks";
import appStoreSrc from "src/images/Icons-App-Store.png";
import playStoreSrc from "src/images/Icons-Google-play.png";
import Logo from "src/images/svgs/humanz_text.svg";
import newAuctionImage from "src/images/svgs/placeholder.svg";
import Background from "src/images/svgs/watermark.svg";
import { sanitizeHtml } from "../utilities/general";

type Props = {
  auth: string;
  onError: () => void;
};

enum Device {
  ios,
  android,
  other,
}

export const ShareInvite: React.FC<Props> = ({ auth, onError }) => {
  const res = useGetSharesInvite({
    "auth-key": auth,
  });

  useEffect(() => {
    if (res.error) {
      onError();
    }
  }, [onError, res.error]);

  const device = useMemo(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    const os = md.os();

    if (os === "AndroidOS") {
      return Device.android;
    }
    if (os === "iOS") {
      return Device.ios;
    }
    return Device.other;
  }, []);

  const [triedToOpenApp, setTriedToOpenApp] = useBoolean(false);
  useEffect(() => {
    if (!triedToOpenApp && res.data) {
      if (device === Device.android) {
        window.location.href = `humanz://campaign/${res.data.data.id}`;
      } else if (device === Device.ios) {
        window.location.href = `humanzai://campaign/${res.data.data.id}`;
      }
      setTriedToOpenApp.on();
    }
  }, [res.data, device, triedToOpenApp, setTriedToOpenApp]);

  let imageSrc: string | undefined;
  if (res.data) {
    const images = res.data.data.images!;
    const imagesLength = images.length;
    if (imagesLength !== 0) {
      imageSrc = images[imagesLength - 1];
    } else {
      imageSrc = newAuctionImage;
    }
  }

  const [isImageLoaded, setImageLoaded] = useBoolean(false);
  useEffect(() => {
    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        setImageLoaded.on();
      };
    }
  }, [imageSrc, setImageLoaded]);

  const [isLandscape] = useMediaQuery("(min-aspect-ratio: 1/1)");

  return (
    <Flex position="absolute" h="full" w="full" justifyContent="center">
      <Box
        position="relative"
        h="full"
        w={isLandscape ? "3xl" : "full"}
        overflow="hidden"
      >
        <Icon
          as={Background as As}
          position="absolute"
          boxSize="80"
          top="-24px"
          right="-64px"
        />
        <Flex
          position="relative"
          textAlign="center"
          h="full"
          w="full"
          overflow="auto"
        >
          <Flex flex="1 0" w="full" flexDir="column">
            <Icon as={Logo as As} w="48" h="auto" alignSelf="center" pt="4" />
            <Flex
              p="8"
              pt="6"
              flex="1"
              w="full"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
            >
              {res.isLoading || !res.data ? (
                <Spinner />
              ) : (
                <>
                  {res.data.data.advertiser_info || res.data.data.brand_info ? (
                    <Flex w="full" pb="4" justifyContent="space-between">
                      {res.data.data.advertiser_info ? (
                        <Flex alignItems="center" flex="1 1" overflow="hidden">
                          <Avatar
                            name={res.data.data.advertiser_info.name}
                            src={res.data.data.advertiser_info.image}
                          />
                          <Text ps="3" isTruncated>
                            {res.data.data.advertiser_info?.name}
                          </Text>
                        </Flex>
                      ) : null}
                      {res.data.data.brand_info ? (
                        <Flex alignItems="center" flex="1 1" overflow="hidden">
                          <Avatar
                            name={res.data.data.brand_info.brand_name}
                            src={res.data.data.brand_info.image_url}
                          />
                          <Text ps="3" isTruncated>
                            {res.data.data.brand_info.brand_name}
                          </Text>
                        </Flex>
                      ) : null}
                    </Flex>
                  ) : null}
                  <Flex
                    w="full"
                    flex="1"
                    flexDir="column"
                    rounded="2xl"
                    boxShadow="xl"
                    bg="white"
                    overflow="hidden"
                    textAlign="initial"
                  >
                    <AspectRatio ratio={3 / 2} w="full">
                      {imageSrc && isImageLoaded ? (
                        <ImageComponent
                          borderTopRadius="2xl"
                          src={imageSrc}
                          objectFit="cover"
                        />
                      ) : (
                        <Flex>
                          <Spinner />
                        </Flex>
                      )}
                    </AspectRatio>
                    <Heading size="lg" px="3">
                      {res.data.data.title}
                    </Heading>
                    <Box
                      mx="3"
                      flex="1 0 56px"
                      overflow="auto"
                      whiteSpace="pre-line"
                    >
                      {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        res.data.data.raw_description ? (
                          <Text className="blurable-large">
                            {parse(
                              sanitizeHtml(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                res.data.data.raw_description,
                              ),
                            )}
                          </Text>
                        ) : (
                          <Text>{res.data.data.description}</Text>
                        )
                      }
                    </Box>
                    <Text
                      textAlign="center"
                      py="2"
                      fontSize="sm"
                      color="brand.500"
                    >
                      Ends on{" "}
                      {moment(res.data.data.end_time).format(
                        window.time.short_date,
                      )}
                    </Text>
                    <Divider />
                    <Grid py="4" templateColumns="repeat(11, 1fr)" gap={3}>
                      {device === Device.other ? (
                        <>
                          <GridItem colSpan={4} colStart={2}>
                            <Link
                              isExternal
                              href="https://apps.apple.com/us/app/moimedia/id1249804084"
                              variant="invisible"
                            >
                              <ImageComponent w="full" src={appStoreSrc} />
                            </Link>
                          </GridItem>
                          <GridItem colSpan={4} colStart={7}>
                            <Link
                              isExternal
                              href="https://play.google.com/store/apps/details?id=com.moimedia.moi"
                              variant="invisible"
                            >
                              <ImageComponent w="full" src={playStoreSrc} />
                            </Link>
                          </GridItem>
                        </>
                      ) : (
                        <GridItem colSpan={7} colStart={3}>
                          <Link
                            href={
                              {
                                [Device.android]: `intent://campaign/${res.data.data.id}#Intent;scheme=humanz;package=com.moimedia.moi;end`,
                                [Device.ios]:
                                  "https://apps.apple.com/us/app/moimedia/id1249804084",
                              }[device]
                            }
                            variant="invisible"
                          >
                            <Button
                              rounded="full"
                              w="full"
                              leftIcon={
                                <i
                                  className={
                                    "fa-regular fa-up-right-from-square"
                                  }
                                />
                              }
                              textDecoration="none"
                            >
                              Take me there
                            </Button>
                          </Link>
                        </GridItem>
                      )}
                    </Grid>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
