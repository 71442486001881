import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { deleteTemplate, getTemplates } from "src/api/actions/auctionActions";
// eslint-disable-next-line import-access/jsdoc
import { TodoTemplate } from "src/api/types";
// import { Input } from "@material-ui/core";
import NewTemplateButton from "./NewTemplateButton";
import TemplatesMenu from "./TemplatesMenu";

const ImportTodoTemplate = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [templates, setTemplates] = useState<TodoTemplate[]>([]);
  const [templateName, setTemplateName] = useState("");

  const handleBuildNewTemplate = () => {
    setShowModal(true); // Show the modal when the button is clicked
  };

  useEffect(() => {
    getTemplates()
      .then((res) => {
        if (res?.data.items) {
          const templateItems = res.data.items;
          setTemplates(templateItems);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleTemplateNameChange = (event: any) => {
    setTemplateName(event.target.value);
  };
  const handleNext = () => {
    props.setTemplateName(templateName);
    props.setStep("new-template");
    setShowModal(false);
  };

  const handleDeleteTemplate = (templateId: number) => {
    deleteTemplate(templateId)
      .then((res) => {
        if (res) {
          getTemplates()
            .then((res2) => {
              if (res2?.data.items) {
                const templateItems = res2.data.items;
                setTemplates(templateItems);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <NewTemplateButton onClick={handleBuildNewTemplate} />
      </Box>
      <TemplatesMenu
        handleDeleteTemplate={handleDeleteTemplate}
        templates={templates}
        setStep={props.setStep}
        setTemplateTasks={props.setTemplateTasks}
        setHeader={props.setHeader}
        setTemplateId={props.setTemplateId}
        setTemplate={props.setTemplate}
        userId={props.userId}
      />
      {showModal && (
        <Modal
          size="sm"
          isOpen={true}
          onClose={() => {
            setShowModal(false);
            setTemplateName("");
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Template Name</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
            >
              <Input
                onChange={handleTemplateNameChange}
                sx={{
                  border: "none",
                  padding: "0",
                  borderBottom: "1px solid gray",
                  borderRadius: "0",
                  "&:focus": {
                    boxShadow: "none",
                  },
                }}
                placeholder="Template Name"
              />
              <Button
                mt={4}
                onClick={handleNext}
                isDisabled={templateName === ""}
              >
                Next
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ImportTodoTemplate;
