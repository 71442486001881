import { Box, Text, Textarea } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";

type Props = {
  header?: string;
  value: string;
  onChange: (newValue?: string) => void;
  placeholder: string;
  required?: true;
  width?: string;
  height?: string;
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
  optional?: boolean;
};

const IndicatorTextarea = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (props.required && props.value === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value; // Extract the value from the event
    props.onChange(newValue);
  };
  return (
    <>
      <Box display="flex">
        <Box
          sx={{
            background: isFocused ? "#249FF0" : "#EBEBEB",
            height: "auto",
            marginRight: "10px",
            width: "1px",
            display: "flex",
            flexDirection: "column",
          }}
        ></Box>
        <Box>
          {props.header && (
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "auto",
                padding: 0,
                color: "#222325",
                marginBottom: "8px",
              }}
            >
              {props.header}
              {props.optional && (
                <Text
                  as="span"
                  sx={{
                    fontSize: "12px",
                    marginLeft: "8px",
                    fontWeight: 300,
                    color: "#AAAAAA",
                    fontStyle: "italic",
                  }}
                >
                  Optional
                </Text>
              )}
            </Text>
          )}
          <Textarea
            placeholder={props.placeholder}
            value={props.value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            background="white"
            focusBorderColor="#249FF0"
            borderWidth="0.5px"
            width={props.width ? props.width : "733px"}
            height={props.height ? props.height : "150px"}
            disabled={props.disabled}
            sx={{ "::placeholder": { color: "#95979D", fontWeight: 300 } }}
          />
        </Box>
      </Box>
      {/* if we want to add an error massage replace false with "error" */}
      {error && !isFocused && (
        <div>
          <i className="error-mark" />
          <span className="error-text">
            Whoops! This field can not be empty
          </span>
        </div>
      )}
    </>
  );
};

export default IndicatorTextarea;
