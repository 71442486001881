import { Box, Button } from "@chakra-ui/react";

const TodoButton = (props: any) => {
  const handleClick = () => {
    props.onClick();
  };
  const buttonWidth = props.tasks.length === 0 ? "100px" : "40px";

  return (
    <Button
      onClick={handleClick}
      style={{
        background: "var(--light-blue, #E7F3FB)",
        flexDirection: "column",
        paddingRight: 5,
        paddingLeft: 5,
        display: "flex",
        height: "auto",
        borderRadius: 0,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="-10 -10 59 58"
        fill="none"
        style={{
          borderRadius: "16px",
          background: "var(--light-blue, #E7F3FB)",
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.243 3.93975C13.6905 4.41921 13.6646 5.17064 13.1851 5.61813L6.39942 11.9515C6.15856 12.1763 5.83515 12.2909 5.50649 12.268C5.17784 12.245 4.87348 12.0866 4.66618 11.8305L2.74357 9.45551C2.33091 8.94576 2.40962 8.19801 2.91937 7.78536C3.42912 7.37271 4.17687 7.45142 4.58952 7.96116L5.71042 9.3458L11.5646 3.88188C12.0441 3.43439 12.7955 3.4603 13.243 3.93975ZM18.3124 7.91667C18.3124 7.26083 18.844 6.72917 19.4999 6.72917H35.3332C35.989 6.72917 36.5207 7.26083 36.5207 7.91667C36.5207 8.57251 35.989 9.10417 35.3332 9.10417H19.4999C18.844 9.10417 18.3124 8.57251 18.3124 7.91667ZM13.243 26.1064C13.6905 26.5859 13.6646 27.3373 13.1851 27.7848L6.39942 34.1181C6.15856 34.3429 5.83515 34.4576 5.50649 34.4346C5.17784 34.4117 4.87348 34.2532 4.66618 33.9972L2.74357 31.6222C2.33091 31.1124 2.40962 30.3647 2.91937 29.952C3.42912 29.5394 4.17687 29.6181 4.58952 30.1278L5.71042 31.5125L11.5646 26.0485C12.0441 25.6011 12.7955 25.627 13.243 26.1064ZM18.3124 30.0833C18.3124 29.4275 18.844 28.8958 19.4999 28.8958H35.3332C35.989 28.8958 36.5207 29.4275 36.5207 30.0833C36.5207 30.7392 35.989 31.2708 35.3332 31.2708H19.4999C18.844 31.2708 18.3124 30.7392 18.3124 30.0833Z"
          fill="#249FF0"
        />
        <g opacity="0.5">
          <path
            d="M13.243 15.023C13.6905 15.5025 13.6646 16.2539 13.1851 16.7014L6.39942 23.0347C6.15856 23.2595 5.83515 23.3741 5.50649 23.3512C5.17784 23.3283 4.87348 23.1698 4.66618 22.9138L2.74357 20.5388C2.33091 20.029 2.40962 19.2813 2.91937 18.8686C3.42912 18.456 4.17687 18.5347 4.58952 19.0444L5.71042 20.4291L11.5646 14.9651C12.0441 14.5176 12.7955 14.5435 13.243 15.023Z"
            fill="#249FF0"
          />
          <path
            d="M18.3124 18.9999C18.3124 18.3441 18.844 17.8124 19.4999 17.8124H35.3332C35.989 17.8124 36.5207 18.3441 36.5207 18.9999C36.5207 19.6558 35.989 20.1874 35.3332 20.1874H19.4999C18.844 20.1874 18.3124 19.6558 18.3124 18.9999Z"
            fill="#249FF0"
          />
        </g>
      </svg>
      <Box
        style={{
          borderRadius: "24px",
          height: "18px",
          width: buttonWidth,
          background: "rgba(36, 159, 240, 0.70)",
          fontSize: "12px",
          color: "var(--white, #FFF)",
          fontWeight: 400,
          paddingRight: "5px",
          paddingLeft: "5px",
          alignItems: "center",
          marginBottom: "3px",
        }}
      >
        {props.tasks.length === 0 ? (
          "Add to do list"
        ) : (
          <>
            {props.completedTasks.length}/{props.tasks.length}
          </>
        )}
      </Box>
    </Button>
  );
};

export default TodoButton;
