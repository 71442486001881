import { AuctionDataExtended, UserDetails } from "src/api/ExtendedTypes";
import { CampaignTag, ListSummary } from "src/api/types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CampaignManagerProps {
  // props
  id: number;
  share: boolean;
  board: AuctionDataExtended;
  lists: ListSummary[];
  premium: any;
  user: UserDetails;
  openInfluencerSegmentations: any;
  openAddToList: any;
  closeChatDialog: any;
  closeInfluencerSegmentations: any;
  showCost: any;
  navigate: any;
  triggerMonthChange: any;
  month: any;

  // API Calls
  acceptInfluencerBid: any;
  changeInfluencerNoteOnBoard: any;
  deleteInfluencerShortLink: any;
  editInfluencerShortLink: any;
  editTasksOnCampaign: any;
  getBoardShortLinks: any;
  getInfluencerData: any;
  getShareBoardDetails: any;
  getShareNotesSummary: any;
  markSeenColumnNotif: any;
  markTaskInvitesAsSeen: any;
  negotiateInfluencerBid: any;
  payInfluencer: any;
  responseForInfluencerCounterOffer: any;
  responseForInfluencerTaskCounterOffer: any;
  searchOnBoard: any;
  sendToTrash: any;
  setBoardIfluencerTags: any;
  updateBoardDetails: any;
  updateBoardState: any;
  setApprovalInfluencer: any;
  getAuctionGoals: any;
}

export interface SingleInfoBox {
  title: string;
  count?: string | number;
  info?: string;
  variant?: string;
  status?: { type: "success" | "warning"; label: string };
}

export enum Columns {
  bids = 0,
  invite = 1,
  approval = 2,
  publish = 3,
  trash = 4,
}

export const starterBoxes: Array<SingleInfoBox> = [
  {
    title: "Influencers",
    count: 0,
    info: null,
  },
  {
    title: "Tasks",
    count: 0,
    info: null,
  },
];

export interface ManageColumn {
  name: string;
  label: string;
}
export const manageColumns: ManageColumn[] = [
  { label: "bids", name: "bids" },
  { label: "invites", name: "invite" },
  { label: "approval", name: "approval" },
  { label: "publish", name: "publish" },
  { label: "trash", name: "trash" },
];

export interface CampaignFilter {
  name?: string;
  label?: string;
  labels?: string[];
  type?: string;
  selected?: any;
  row?: string;
  isOpen?: boolean;
  label_tooltip?: string | any;
  values?: string[] | any;
  isMulti?: boolean;
}

export interface CampaignTagExtended extends CampaignTag {
  temp_id?: string;
}

export const campaignFilters: CampaignFilter[] = [
  {
    name: "avg_review",
    label: "MINIMUM RATING",
    type: "stars",
    selected: null,
    row: "influencer",
    isOpen: false,
    label_tooltip: "Based on ratings of other advertisers in Humanz",
  },
  {
    name: "gender",
    label: "GENDER",
    type: "checkbox",
    values: ["All", "Female", "Male"],
    selected: "All",
    isMulti: false,
  },
  {
    name: "social",
    label: "SOCIAL",
    type: "checkbox",
    values: ["All", "Instagram", "Twitter", "Youtube"],
    selected: ["All"],
    isMulti: true,
  },
  {
    name: "influencer_min_score",
    label: "MINIMUM SCORE",
    type: "slider",
    values: [0, 100],
    selected: [0],
    isMulti: true,
    isOpen: false,
    row: "influencer",
  },
  {
    name: "influencer_followers",
    label: "FOLLOWERS AMOUNT",
    labels: ["Min Followers", "Max Followers"],
    type: "multiple_field",
    values: [
      [
        { value: -1, label: "All" },
        { value: 1000, label: "1k" },
        { value: 5000, label: "5k" },
        { value: 10000, label: "10k" },
        { value: 25000, label: "25k" },
        { value: 50000, label: "50k" },
        { value: 100000, label: "100k" },
      ],
      [
        { value: -1, label: "All" },
        { value: 10000, label: "10k" },
        { value: 50000, label: "50k" },
        { value: 100000, label: "100k" },
        { value: 250000, label: "250k" },
        { value: 1000 * 1000, label: "1m" },
      ],
    ],
    selected: [-1, -1],
    isOpen: false,
    row: "influencer",
  },
];

export type NegotiateResponseType =
  | null
  | "negotiate"
  | "decline"
  | "accept"
  | "invite"
  | any;
