import { Flex, Tag } from "@chakra-ui/react";
import { useMemo } from "react";
import Clipboard from "react-clipboard.js";
import NoImage from "../../../images/svgs/placeholder.svg";
import { MessageDialog } from "../dialogs/MessageDialog";
import { isValidUrl } from "../utilities/general";

type Props = {
  data: any;
  idx: number;
  shortLink: any;
  search: string;
  links: any;
  saving: any;
  isOpenToEdit: any;
  saveInfLink: any;
  renderLinkAlert: any;
  saveExtraInfLink: any;
  addExtraLink: any;
  deleteExtraLink: any;
  currentInfluencer: any;
  isExtraLoading: any;
  setState: any;
};

const CampaignLinksSideInfluencerRow = ({
  data,
  idx,
  shortLink,
  search,
  links,
  saveInfLink,
  renderLinkAlert,
  saveExtraInfLink,
  addExtraLink,
  deleteExtraLink,
  isExtraLoading,
  isOpenToEdit,
  setState,
}: Props) => {
  const inSearch = useMemo(
    () => data.name?.toLowerCase().includes(search?.toLowerCase()),
    [search],
  );

  const link = useMemo(() => {
    let l = `${shortLink}/${data.influencer_id}`;
    if (!l.startsWith("http")) l = `https://${l}`;
    return l;
  }, [data]);

  const influLink = useMemo((): any => {
    return links.find((x: any) => x.influencer_id === data.influencer_id);
  }, [links, data]);

  const longLink = influLink ? influLink.long_link : "";

  if (inSearch) {
    return (
      <div key={idx} className={`import-list-row ${isOpenToEdit && "edit"}`}>
        <Flex>
          <div style={{ width: 45, height: 45 }}>
            <img
              className="blurable"
              style={{ width: 45, height: 45 }}
              src={data.picture_url ? data.picture_url : NoImage}
            />
          </div>
          <div
            className={"import-list-row-details "}
            style={{ display: "flex", width: 650, marginLeft: 11 }}
          >
            <strong
              style={{ width: 180 }}
              className="import-list-name blurable-large"
              title={data.name}
            >
              {data.name}
            </strong>
            <a
              title={link}
              target="_blank"
              style={{
                paddingTop: 2,
                width: 330,
                marginLeft: 20,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "block",
                cursor: "pointer",
              }}
              href={link}
              rel="noreferrer"
            >
              {link}
            </a>
            <Clipboard
              style={{ background: "transparent", border: "none" }}
              data-clipboard-text={link}
            >
              <i
                className="far fa-copy"
                style={{ fontSize: 20, marginLeft: 30 }}
              />
            </Clipboard>
            <div
              style={{
                width: 118,
                textAlign: "center",
                marginLeft: 18,
                marginRight: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                data.new_long_link = longLink;
                setState({ isOpenToEdit: data.influencer_id });
              }}
            >
              <i className="far fa-edit" style={{ fontSize: 20 }} />
            </div>
            <div style={{ width: 50 }}>
              <strong className="">
                {influLink
                  ? !influLink?.extra_links ||
                    influLink?.extra_links?.length === 0
                    ? "1"
                    : influLink.extra_links.length + 1
                  : "1"}
              </strong>
            </div>
          </div>
        </Flex>
        {isOpenToEdit === data.influencer_id && (
          <MessageDialog
            image={data.picture_url ? data.picture_url : NoImage}
            handleClose={() => setState({ isOpenToEdit: null })}
            style={{ maxWidth: 800 }}
            title={`Campaign links - ${data.name}`}
          >
            <div
              style={{
                maxHeight: "70vh",
                padding: 10,
                position: "relative",
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                  overflowY: "auto",
                  maxHeight: "68vh",
                  height: "100%",
                }}
                className="import-list-row"
              >
                <>
                  {isExtraLoading ? (
                    <div style={{ marginTop: 10 }}>Loading...</div>
                  ) : (
                    <div className="links-inner" style={{ margin: 5 }}>
                      <div className="extra-links">
                        <div
                          className="import-list-influencers-row"
                          style={{ paddingLeft: 0, borderRadius: 10 }}
                        >
                          <strong className="small-title" style={{ flex: 3 }}>
                            Redirect to
                          </strong>
                          <strong className="small-title" style={{ flex: 2 }}>
                            Link
                          </strong>
                          <strong className="small-title" style={{ flex: 1 }}>
                            Copy Link
                          </strong>
                          <strong className="small-title" style={{ flex: 1 }}>
                            Actions
                          </strong>
                        </div>

                        <div className={"extra-link-row"}>
                          {data.created_by_influencer ? (
                            <div className="link-by-inf">
                              Created by the influencer
                            </div>
                          ) : null}
                          <div className="flex">
                            <div className="num">
                              <i className="fa fa-star" />
                            </div>
                            <input
                              defaultValue={longLink}
                              onChange={(e) => {
                                const newLink = e?.target?.value?.trim();
                                data.new_long_link = newLink;
                                data.validate_link =
                                  newLink && isValidUrl(newLink);
                                setState({ data: data });
                              }}
                              className="input-line"
                              style={{ marginRight: 20 }}
                              placeholder="Your website link"
                              disabled={data.created_by_influencer}
                            />
                            <input
                              disabled
                              value={link}
                              className="input-line"
                              style={{ marginRight: 20 }}
                              placeholder="New link"
                              readOnly={true}
                            />
                            <Clipboard
                              style={{
                                background: "transparent",
                                border: "none",
                              }}
                              data-clipboard-text={link}
                            >
                              <i
                                className="far fa-copy"
                                style={{
                                  fontSize: 20,
                                  marginRight: 64,
                                }}
                              />
                            </Clipboard>
                            <button
                              className="btn btn-primary"
                              disabled={
                                !data.new_long_link?.length ||
                                !isValidUrl(data?.new_long_link) ||
                                data?.created_by_influencer
                              }
                              style={{
                                height: 30,
                                paddingTop: 5,
                                background: "var(--main-baby-blue-color)",
                                border: "none",
                              }}
                              onClick={() => saveInfLink(data)}
                            >
                              Save
                            </button>
                          </div>
                          {renderLinkAlert(data)}
                        </div>
                        {influLink &&
                          influLink?.extra_links &&
                          influLink.extra_links.map((l: any) => {
                            const short_link =
                              l.short_link ||
                              `${shortLink}/${influLink.influencer_id}/${l.extra_id}`;
                            return (
                              <div
                                className={"extra-link-row"}
                                key={l.extra_id}
                              >
                                {l.created_by_influencer ? (
                                  <Tag mb={1}>Created by the influencer</Tag>
                                ) : null}
                                <div className={"flex"}>
                                  <div
                                    style={{
                                      borderRadius: 4,
                                      width: "auto",
                                      minWidth: 20,
                                      padding: "0 2px",
                                    }}
                                    className={`num ${!l.created_by_influencer}`}
                                  >
                                    {l.extra_id}
                                  </div>

                                  <input
                                    defaultValue={l.long_link}
                                    disabled={!!l.created_by_influencer}
                                    onChange={(e) => {
                                      const newLink = e?.target?.value?.trim();
                                      l.long_link = newLink;
                                      l.validate_link =
                                        newLink && isValidUrl(newLink);
                                      setState({});
                                    }}
                                    className="input-line"
                                    style={{ marginRight: 20 }}
                                    placeholder="Extra link"
                                  />

                                  <input
                                    disabled
                                    value={`https://${short_link}`}
                                    className="input-line"
                                    style={{ marginRight: 20 }}
                                    placeholder="Short link"
                                    readOnly={true}
                                  />
                                  <Clipboard
                                    style={{
                                      background: "transparent",
                                      border: "none",
                                    }}
                                    data-clipboard-text={`https://${short_link}`}
                                  >
                                    <i
                                      className="far fa-copy"
                                      style={{ fontSize: 20, marginRight: 30 }}
                                    />
                                  </Clipboard>

                                  <i
                                    className="far fa-trash-alt"
                                    style={{
                                      color: "red",
                                      fontSize: 18,
                                      marginLeft: 10,
                                      marginTop: 7,
                                      marginRight: 0,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      deleteExtraLink(influLink, l, () => {
                                        setState({
                                          isOpenToEdit: data.influencer_id,
                                        });
                                      });
                                    }}
                                  />

                                  <button
                                    className="btn btn-primary"
                                    disabled={
                                      !l?.long_link?.length ||
                                      !isValidUrl(l?.long_link) ||
                                      l.created_by_influencer
                                    }
                                    style={{
                                      height: 30,
                                      paddingTop: 5,
                                      marginLeft: 10,
                                      background: "var(--main-baby-blue-color)",
                                      border: "none",
                                    }}
                                    onClick={() =>
                                      saveExtraInfLink(l, influLink)
                                    }
                                  >
                                    Save
                                  </button>
                                </div>
                                {renderLinkAlert(l)}
                                <div className="link-description">
                                  <input
                                    defaultValue={l.description}
                                    disabled={!!l.created_by_influencer}
                                    onChange={(e) => {
                                      l.description = e.target.value;
                                      setState({});
                                    }}
                                    className="input-line"
                                    style={{ marginRight: 20 }}
                                    placeholder="Description"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {influLink && influLink.extra_links !== undefined && (
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <button
                            style={{
                              marginTop: 2,
                              marginBottom: 10,
                            }}
                            className={"btn btn-primary"}
                            onClick={() => addExtraLink(influLink)}
                          >
                            <i className="far fa-plus-circle" /> Add link
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              </div>
            </div>
          </MessageDialog>
        )}
      </div>
    );
  }
  return null;
};

export default CampaignLinksSideInfluencerRow;
