import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { LocalTask } from "src/api/ExtendedTypes";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import SingleTaskRow from "../../campaign_components/Tasks/SingleTaskRow";
import { InfoToolTip } from "../../general_components/InfoToolTip";
import { numberWithCurrency } from "../../utilities/general";

type Props = {
  bundle: any;
  bundleIndex?: number;
  editBundle?: any;
  taskUpdated?: any;
  onToggleTask?: any;
  editMode?: any;
  setEditMode?: any;
  locked?: boolean;
  modifications?: any;
  attachMode?: any;
  isOffer?: boolean;
};

const BundleAccordion = ({
  bundle,
  bundleIndex,
  editBundle,
  taskUpdated,
  onToggleTask,
  editMode,
  setEditMode,
  locked,
  modifications,
  attachMode,
  isOffer,
}: Props) => {
  const boardReducer = useSelector((store) => store.boardReducer.board);
  return (
    bundle && (
      <Accordion key={bundle?.bundle_id} allowToggle defaultIndex={[0]}>
        <AccordionItem className={"tasks-bundle"}>
          {({ isExpanded }) => (
            <>
              <AccordionButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "2px 6px",
                  alignItems: "center",
                  borderRadius: "12px",
                  width: "100%",
                  gap: 2,
                }}
              >
                <Flex gap={2} justifyContent={"center"} alignItems="center">
                  <Tag variant={"outline"} colorScheme="gray">
                    Bundle{" "}
                    {bundle?.index !== undefined ? bundle.index + 1 : null}
                  </Tag>
                  <Tag>
                    {numberWithCurrency(
                      Number(bundle.price) || 0,
                      boardReducer?.summary?.currency,
                    )}
                  </Tag>
                  {modifications?.price && (
                    <Tag colorScheme={"green"}>
                      Offered price:{" "}
                      {numberWithCurrency(
                        Number(modifications?.price) || 0,
                        boardReducer?.summary?.currency,
                      )}
                    </Tag>
                  )}
                  <Text color={"#808080"} fontSize={14}>
                    <InfoToolTip
                      iconStyle={{ marginRight: "5px" }}
                      overlay={
                        "Bundled tasks should be completed together - and will be paid together."
                      }
                    />
                    Tasks bundle ({bundle.tasks?.length})
                  </Text>
                </Flex>
                <Flex style={{ marginRight: "10px", alignItems: "center" }}>
                  {bundle.renews && (
                    <Tag colorScheme={"blue"} mr={2}>
                      <i
                        className="far fa-arrows-spin"
                        style={{ marginRight: 2 }}
                      />
                      Recurring
                    </Tag>
                  )}
                  {bundle.complete_at_end_of_month && (
                    <Tooltip
                      label={
                        "Influencer will receive the payment at the end of the month, regardless of tasks completion"
                      }
                    >
                      <Tag colorScheme={"purple"}>
                        <i
                          className="fa-duotone fa-money-check-dollar"
                          style={{ marginRight: 2 }}
                        />
                        Guaranteed payment
                      </Tag>
                    </Tooltip>
                  )}
                  {!locked && (
                    <HumanzActionsMenu
                      actions={[
                        {
                          action: () => editBundle(bundle),
                          text: "Edit tasks bundle",
                          icon: "far fa-edit",
                        },
                      ]}
                    />
                  )}
                  <i
                    style={{ fontSize: 16 }}
                    className={`fa-regular ${
                      isExpanded ? "fa-chevron-up" : "fa-chevron-down"
                    } fa-lg`}
                  />
                </Flex>
              </AccordionButton>
              <AccordionPanel p={0} pt={2}>
                {bundle?.tasks?.map((task: LocalTask, i: number) => (
                  <SingleTaskRow
                    key={i}
                    bundleIndex={bundleIndex}
                    task={task}
                    index={i}
                    isOpen={task.new}
                    taskUpdated={taskUpdated}
                    onToggleTask={onToggleTask}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    inBundleMode={true}
                    attachMode={attachMode}
                    isOffer={isOffer}
                  />
                ))}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    )
  );
};

export default BundleAccordion;
