// TODO - lint
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars, func-style, func-style, func-style, react/react-in-jsx-scope, react/react-in-jsx-scope, react/react-in-jsx-scope, react/react-in-jsx-scope, react/react-in-jsx-scope, react/react-in-jsx-scope, react/no-unescaped-entities, react/no-unescaped-entities, react/no-unescaped-entities, react/react-in-jsx-scope, react/react-in-jsx-scope, react/react-in-jsx-scope, react/react-in-jsx-scope, import/no-default-export */
import Dialog from "rc-dialog";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { approveTerms, clearUser } from "../../../actions/userActions";

function TermsDialog() {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  function confirm() {
    setSaving(true);
    dispatch(approveTerms(() => setSaving(false)));
  }

  function logout() {
    clearUser();
  }

  return (
    <Dialog
      maskClosable={false}
      onClose={() => {}}
      closable={false}
      className="confirm-action-dialog"
      visible={true}
      style={{ width: "50%" }}
      animation="slide-fade"
      maskAnimation="fade"
    >
      <div>
        <h3 className="confirm-action-dialog-title">
          We have updated our Terms of Service and Privacy Policy
        </h3>
      </div>
      <label>
        Please read the newest update in order to continue using Humanz platform
      </label>
      <iframe
        src="/termsAndConditions.html"
        style={{
          border: "none",
          height: "50vh",
          width: "100%",
          margin: "15px 0",
        }}
        frameBorder="0"
      ></iframe>
      <label>
        By clicking "Agree" you agree to Humanz's Terms of Service and Privacy
        Policy.
      </label>
      <div
        className={`confirm-dialog-buttons-container ${
          saving ? "disabled" : ""
        }`}
      >
        <button
          className="btn btn-primary accept-confirm-dialog-button"
          onClick={confirm}
        >
          {window.translate("I Agree", "i_agree")}
        </button>
        <button
          style={{ marginLeft: 10 }}
          className="btn cancel-confirm-dialog-button"
          onClick={logout}
        >
          {window.translate("Logout", "logout")}
        </button>
      </div>
    </Dialog>
  );
}

const TermsAndConditionsDialog = (props: { should_update_toc: boolean }) => {
  return props.should_update_toc ? <TermsDialog /> : null;
};

export default TermsAndConditionsDialog;
