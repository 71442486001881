import { Tab, TabList, Tabs as TabsContainer } from "@chakra-ui/react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Tabs, { TabPane } from "rc-tabs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InkTabBar from "rc-tabs/lib/InkTabBar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TabContent from "rc-tabs/lib/TabContent";
import React, { useContext } from "react";
import CountUp from "react-countup";
import {
  CartesianGrid,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getProductsSalesROI } from "src/api/actions/auctionActions";
import { postProductsSalesROIShared } from "src/api/actions/shareActions/auctionActions";
import {
  CampaignReportFilterParameters,
  ManagedCampaignROI,
  PixelConversions,
  PurchaseClicksType,
} from "src/api/types";
import { InfiniteLoader } from "src/components/feed_components/general_components/InfiniteLoader";
import { InfoToolTip } from "src/components/feed_components/general_components/InfoToolTip";
import NoDataMessage from "src/components/feed_components/general_components/NoDataMessage";
import { chartColors, formatBigNumbers } from "../../../utilities/general";
import { ClicksOverTimeChart } from "./ClicksOverTimeChart";
import { RoiContext } from "./RoiContext";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CustomTooltip, renderActiveShape } from "./RoiUtils";
import { SalesProductsTable, getProductsFilters } from "./SalesProductsTable";

const CustomTooltip2 = ({ active, payload, label }: any) => {
  let tooltip = "";
  if (active && payload && payload.length) {
    switch (payload[0]?.name) {
      case "purchases":
        tooltip = "Total Purchases";
        break;
      case "units":
        tooltip = "Total Units";
        break;
      case "total_price":
        tooltip = "Sales Value";
        break;
      default:
        tooltip = "Total";
        break;
    }
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        <p className="label">{`${tooltip}: ${formatBigNumbers(
          payload[0].value,
        )}`}</p>
      </div>
    );
  }

  return null;
};
interface PixelConversionsExtended extends PixelConversions {
  total_products_prices: number;
  total_products_purchases: number;
  total_coupons_purchases: number;
  total_influencers_payment: number;
  product_to_influencers: {
    [product: string]: ManagedCampaignROI["per_influencer"];
  };
  coupon_to_influencers: {
    [coupon: string]: ManagedCampaignROI["per_influencer"];
  };
  phases: {
    name: string;
    phase_id: number;
    purchases: number;
    sales: number;
    sales_commission: number;
    sales_net: number;
  }[];
}

interface RoiPixelProps {
  influencersData: {
    conversions_data?: {
      total_purchases?: number;
      total_value?: number;
      total_coupons_redeem: number;
      net_sales_value: number;
    };
    influencerDetails: NonNullable<ManagedCampaignROI["per_influencer"]>[0];
  }[];
  shareReport: any;
}

interface RoiPixelInnerProps extends RoiPixelProps {
  loaded: boolean;
  summaryData: {
    pixel_conversions: PixelConversionsExtended;
    campaign_details: ManagedCampaignROI["campaign_details"];
    clicksPerDay: any[];
    kpis: ManagedCampaignROI["kpi"];
  };

  showFilters(): void;
  allowFilters: boolean;
  filters: CampaignReportFilterParameters;
  shared: boolean;
  Header: React.FC;
}

const formatCountUp = (number: number) =>
  formatBigNumbers(number, { format: "0a" });

const SalesTimeLine = ({
  summaryData,
}: {
  summaryData: RoiPixelInnerProps["summaryData"];
}) => {
  const [selected, setSelected] = React.useState(0);

  if (!summaryData.pixel_conversions) return null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let data: PurchaseClicksType[] | DateAndCount[] =
    summaryData.pixel_conversions.purchases_per_day;
  let content = null;

  switch (selected) {
    case 0:
      content = <Line type="monotone" dataKey="purchases" stroke="#82ca9d" />;
      break;
    case 1:
      content = <Line type="monotone" dataKey="units" stroke="#3f4e61" />;
      break;
    case 2:
      content = <Line type="monotone" dataKey="total_price" stroke="#249ff0" />;
      break;
    case 3:
    case 4:
      {
        const isAddToCart =
          selected === 3 &&
          !!summaryData.pixel_conversions.add_to_cart_per_day?.length;
        data =
          summaryData.pixel_conversions[
            isAddToCart ? "add_to_cart_per_day" : "checkout_visits_per_day"
          ];
        content = (
          <Line
            type="monotone"
            dataKey="count"
            stroke={isAddToCart ? "#9655A2" : "#EB417A"}
          />
        );
      }
      break;
    default:
      break;
  }

  if (data?.length) {
    return (
      <div
        style={{
          marginLeft: 0,
          width: "100%",
          marginBottom: 10,
          color: "black",
          background: "white",
          padding: 15,
          borderRadius: 6,
          boxShadow: "1px 5px 27px 1px rgb(0 0 0 / 3%)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <strong
            style={{
              color: "#333",
              display: "block",
              textAlign: "left",
              marginLeft: 20,
              marginBottom: 10,
              fontSize: 20,
            }}
          >
            Sales Over Time
          </strong>
          <TabsContainer
            flex={"initial"}
            variant="soft-rounded"
            colorScheme="brand"
            onChange={(index) => setSelected(index)}
          >
            <TabList mb="1em" border={"none"}>
              <Tab borderBottom={0} fontSize={15} marginRight={5}>
                Purchases
              </Tab>
              <Tab borderBottom={0} fontSize={15} marginRight={5}>
                Units Sold
              </Tab>
              <Tab borderBottom={0} fontSize={15} marginRight={5}>
                Sales Value
              </Tab>
              {summaryData.pixel_conversions.add_to_cart_per_day?.length ? (
                <Tab borderBottom={0} fontSize={15} marginRight={5}>
                  Add To Cart
                </Tab>
              ) : null}
              {summaryData.pixel_conversions.checkout_visits_per_day?.length ? (
                <Tab borderBottom={0} fontSize={15} marginRight={5}>
                  Checkout page visits
                </Tab>
              ) : null}
            </TabList>
          </TabsContainer>
        </div>
        <ResponsiveContainer width={"100%"} height={250}>
          <LineChart width={350} height={250} data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
            <XAxis dataKey={selected > 2 ? "date" : "name"} />
            <YAxis />
            <Tooltip
              allowEscapeViewBox={{
                x: true,
                y: true,
              }}
              content={<CustomTooltip2 />}
            />
            {content}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
  return null;
};

export const KpiCube = ({
  label,
  value,
  iconClass,
  color,
  separator,
  icon,
  decimals,
  width,
  formattingFn = formatCountUp,
  percentage,
  height,
  infoText,
}: {
  label: string | JSX.Element;
  value: any;
  iconClass?: string;
  color: string;
  formattingFn?: (n: number) => string;
  separator?: string;
  icon?: JSX.Element;
  decimals?: number;
  width?: number;
  percentage?: boolean;
  height?: number;
  infoText?: string;
}) => (
  <div
    className="kpi-cubes"
    style={{ marginLeft: 0, width: width || 213, height }}
  >
    <div>
      <strong style={{ color }}>{label}</strong>
      {infoText && <InfoToolTip overlay={<div>{infoText}</div>} />}
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        color,
        justifyContent: "space-between",
      }}
    >
      <span>
        <CountUp
          end={value || 0}
          formattingFn={formattingFn}
          separator={separator}
          decimals={decimals}
        />
        {percentage && "%"}
        &nbsp;
      </span>
      {icon || (
        <div style={{ color }}>
          <li className={iconClass} />
        </div>
      )}
    </div>
  </div>
);
interface State {
  openCoupon: string;
  topDataTable: {
    brand: PurchaseClicksType[];
    sku: PurchaseClicksType[];
    link: PurchaseClicksType[];
  };
  loadingDataTable: { brand: boolean; sku: boolean; link: boolean };
  filters: RoiPixelInnerProps["filters"];
}
export enum RoiSalesBreakdown {
  sku = "sku",
  brand = "brand",
  link = "link",
}

const tableItems: RoiSalesBreakdown[] = [
  RoiSalesBreakdown.brand,
  RoiSalesBreakdown.link,
  RoiSalesBreakdown.sku,
];

class RoiPixelInner extends React.Component<RoiPixelInnerProps, State> {
  state: State = {
    openCoupon: null,
    topDataTable: { brand: [], link: [], sku: [] },
    loadingDataTable: { brand: true, link: true, sku: true },
    filters: {},
  };

  initData = (breakdown: RoiSalesBreakdown) => {
    if (this.props.loaded) {
      const filters = this.props.allowFilters
        ? getProductsFilters(this.props.filters)
        : {};
      this.setState(
        (prevState) => ({
          loadingDataTable: {
            ...prevState.loadingDataTable,
            [breakdown]: true,
          },
          filters,
        }),
        () => {
          const getProductsFun = this.props.shared
            ? postProductsSalesROIShared({ filters }, { breakdown })
            : getProductsSalesROI(
                this.props.summaryData.campaign_details.auction_id,
                {
                  filters,
                },
                { breakdown },
              );
          getProductsFun.then((r) => {
            this.setState((prevState) => ({
              topDataTable: {
                ...prevState.topDataTable,
                [breakdown]: r.data.items,
              },
              loadingDataTable: {
                ...prevState.loadingDataTable,
                [breakdown]: false,
              },
            }));
          });
        },
      );
    }
  };

  componentDidMount() {
    tableItems.forEach((breakdown) => this.initData(breakdown));
  }

  componentDidUpdate(prevProps: Readonly<RoiPixelInnerProps>): void {
    if (prevProps.loaded !== this.props.loaded) {
      tableItems.forEach((breakdown) => this.initData(breakdown));
    }
  }

  toggleCoupon = (name: string) => {
    this.setState({ openCoupon: name === this.state.openCoupon ? null : name });
  };

  render() {
    const { summaryData, Header } = this.props;

    return (
      <>
        <div className="main-middle-container">
          {
            this.props.loaded && summaryData ? (
              <div className="roi-main-section">
                <Header />
                {this.renderSummaryData()}

                {/* Purchases Per Day */}
                <SalesTimeLine summaryData={this.props.summaryData} />

                {/* Clicks Per Day */}
                <ClicksOverTimeChart
                  clicksPerDay={this.props.summaryData.clicksPerDay}
                />

                {/* Charts */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 5,
                  }}
                >
                  {/* Products Chart */}
                  {this.renderProductsPurchaseChart()}
                  {this.renderInfluencersPurchaseChart()}
                  {/* Coupons */}
                  {this.renderCouponsChart()}
                </div>

                {/* View All Products Table */}
                <div id="pixel_tabs">
                  <Tabs
                    renderTabBar={() => <InkTabBar />}
                    renderTabContent={() => <TabContent />}
                    onChange={() => {}}
                  >
                    {this.renderDataTable(RoiSalesBreakdown.sku)}
                    {this.renderDataTable(RoiSalesBreakdown.brand)}
                    {this.renderDataTable(RoiSalesBreakdown.link)}
                    {this.renderRulesTable()}
                    {this.renderCouponsTable()}
                  </Tabs>
                </div>
              </div>
            ) : (
              /* Loader */
              <div className="logo-loader">
                <div className="loader" />
                <img src={"/images/humanz-logo.png"} />
              </div>
            )
            /* End Loader */
          }
        </div>
      </>
    );
  }

  renderSummaryData = () => {
    const { summaryData } = this.props;
    const conversionRate = (
      summaryData?.kpis?.conversions?.current
        ? ((summaryData.pixel_conversions.total_purchases || 0) /
            summaryData.kpis.conversions.current) *
          100
        : 0
    ).toFixed(2);
    return (
      <div
        style={{ marginBottom: 20, display: "flex", flexWrap: "wrap", gap: 15 }}
      >
        <KpiCube
          label="Clicks"
          value={summaryData?.kpis?.conversions?.current}
          color="#f57e20"
          iconClass=" far fa-hand-back-point-up"
        />

        {summaryData.pixel_conversions?.total_added_to_cart ? (
          <KpiCube
            label="Add to Carts"
            value={summaryData.pixel_conversions?.total_added_to_cart}
            color="#9655A2"
            iconClass=" far fa-cart-shopping"
          />
        ) : null}

        {summaryData.pixel_conversions?.total_checkouts_visits ? (
          <KpiCube
            label="Checkout Visits"
            value={summaryData.pixel_conversions?.total_checkouts_visits}
            color="#EB417A"
            iconClass=" far fa-money-check-dollar-pen"
          />
        ) : null}

        <KpiCube
          label="Total Purchases"
          value={summaryData.pixel_conversions?.total_purchases}
          color="#32B09B"
          iconClass=" far fa-bag-shopping"
        />

        <KpiCube
          label="Total Units Sold"
          value={summaryData.pixel_conversions?.total_products_purchases}
          color="#3f4e61"
          iconClass=" far fa-box"
        />
        <KpiCube
          label="Total Sales Value"
          value={summaryData.pixel_conversions?.total_products_prices}
          color="#44a8df"
          icon={
            <div className="fa" style={{ color: "#3f4e61" }}>
              <span
                className="fas"
                style={{ fontSize: 14, fontWeight: "bold" }}
              >
                {window.translate(
                  "",
                  `money_symbol_${
                    summaryData.pixel_conversions?.sales_user_data?.currency ||
                    "USD"
                  }`,
                )}
              </span>
            </div>
          }
          separator=","
          formattingFn={null}
        />

        <KpiCube
          label="Conversion Rate"
          value={conversionRate}
          color="#5D6BB2"
          iconClass=" far fa-arrows-spin"
          decimals={2}
          formattingFn={null}
          percentage
          // formattingFn={formatCountUp}
        />

        {summaryData?.pixel_conversions?.total_influencers_payment ? (
          <KpiCube
            decimals={2}
            label={
              <InfoToolTip overlay="The total sales value / (influencers affiliation + media)">
                ROAS
              </InfoToolTip>
            }
            formattingFn={null}
            color="#309FAD"
            icon={
              <div className="fa" style={{ color: "#309FAD" }}>
                <span
                  className="fas"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  {window.translate(
                    "",
                    `money_symbol_${
                      summaryData.pixel_conversions?.sales_user_data
                        ?.currency || "USD"
                    }`,
                  )}
                </span>
              </div>
            }
            value={(
              summaryData.pixel_conversions.total_products_prices /
              summaryData.pixel_conversions.total_influencers_payment
            ).toFixed(2)}
          />
        ) : null}
        {/* <KpiCube empty={true} label="" value={0} color="" />
        <KpiCube empty={true} label="" value={0} color="" /> */}
      </div>
    );
  };

  renderProductsPurchaseChart = () => {
    const { summaryData } = this.props;
    if (!summaryData.pixel_conversions) return null;
    if (this.state.loadingDataTable.sku) {
      return (
        <div
          className="roi-eng-cube"
          style={{ marginLeft: 0, width: 290, height: 280, marginBottom: 10 }}
        >
          <InfiniteLoader />
        </div>
      );
    }
    const data = this.state.topDataTable.sku;
    if (data?.length) {
      const chartData = data
        .map((d, idx) => {
          let label = d.external_name || d.name || "";

          const fullLabel = label;
          if (label?.length > 10) {
            label = `${label.substring(0, 10)}...`;
          }
          const p = Number(
            (
              ((d.purchases || 0) /
                summaryData.pixel_conversions.total_products_purchases) *
              100
            ).toFixed(2),
          );
          const colorIdx = idx % chartColors.length;
          return {
            label,
            name: "",
            value: p,
            fill: chartColors[colorIdx],
            fontSize: "small",
            fullLabel,
            hidePercentage: true,
          };
        })
        .sort((a, b) => b.value - a.value)
        .slice(0, 10);

      const active = chartData.map((_, idx) => idx);

      return (
        <div
          className="roi-eng-cube"
          style={{ marginLeft: 0, width: 290, height: 280, marginBottom: 10 }}
        >
          <strong
            style={{
              color: "#249ff0",
              display: "block",
              textAlign: "left",
              marginLeft: 20,
              marginBottom: -10,
            }}
          >
            Units Sold By Product (Top 10)
          </strong>
          <PieChart width={290} height={280}>
            <Pie
              startAngle={90}
              endAngle={450}
              activeIndex={active}
              activeShape={renderActiveShape}
              data={chartData}
              cx={"50%"}
              cy={"50%"}
              innerRadius={35}
              outerRadius={45}
              dataKey={"value"}
            />
            <Tooltip
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              content={<CustomTooltip />}
            />
          </PieChart>
        </div>
      );
    }
    return null;
  };

  renderInfluencersPurchaseChart = () => {
    const { summaryData, influencersData } = this.props;
    if (!summaryData.pixel_conversions) return null;
    const data = influencersData;
    if (data) {
      const chartData = data.map((d, idx) => {
        let label = d.influencerDetails.name || "";

        const fullLabel = label;
        if (label?.length > 10) {
          label = `${label.substring(0, 10)}...`;
        }
        const p = Number(
          (
            ((d.conversions_data?.total_purchases || 0) /
              summaryData.pixel_conversions.total_products_purchases) *
            100
          ).toFixed(2),
        );
        const colorIdx = idx % chartColors.length;
        return {
          label,
          name: "",
          value: p,
          fill: chartColors[colorIdx],
          fontSize: "small",
          fullLabel,
          hidePercentage: true,
        };
      });
      const active = chartData.map((_, idx) => idx);

      return (
        <div
          className="roi-eng-cube"
          style={{ marginLeft: 0, width: 290, height: 280, marginBottom: 10 }}
        >
          <strong
            style={{
              color: "#249ff0",
              display: "block",
              textAlign: "left",
              marginLeft: 20,
              marginBottom: -10,
            }}
          >
            Units Sold By Influencer
          </strong>
          <PieChart width={290} height={280}>
            <Pie
              startAngle={90}
              endAngle={450}
              activeIndex={active}
              activeShape={renderActiveShape}
              data={chartData}
              cx={"50%"}
              cy={"50%"}
              innerRadius={35}
              outerRadius={45}
              dataKey={"value"}
            />
            <Tooltip
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              content={<CustomTooltip />}
            />
          </PieChart>
        </div>
      );
    }
    return null;
  };

  renderCouponsChart = () => {
    const { summaryData } = this.props;
    if (!summaryData.pixel_conversions) return null;
    const data = summaryData.pixel_conversions.coupons;
    if (data?.length) {
      const chartData = data
        .map((d, idx) => {
          let label = d.external_name || d.name || "";

          const fullLabel = label;
          if (label?.length > 10) {
            label = `${label.substring(0, 10)}...`;
          }
          const p = Number(
            (
              ((d.purchases || 0) /
                summaryData.pixel_conversions.total_coupons_purchases) *
              100
            ).toFixed(2),
          );
          const colorIdx = idx % chartColors.length;
          return {
            label,
            name: "",
            value: p,
            fill: chartColors[colorIdx],
            fontSize: "small",
            fullLabel,
            hidePercentage: true,
          };
        })
        .sort((a, b) => b.value - a.value)
        .slice(0, 10);
      const active = chartData.map((_, idx) => idx);

      return (
        <div
          className="roi-eng-cube"
          style={{ marginLeft: 0, width: 290, height: 280, marginBottom: 10 }}
        >
          <strong
            style={{
              color: "#249ff0",
              display: "block",
              textAlign: "left",
              marginLeft: 20,
              marginBottom: -10,
            }}
          >
            Coupons Distribution (Top 10)
          </strong>
          <PieChart width={290} height={280}>
            <Pie
              startAngle={90}
              endAngle={450}
              activeIndex={active}
              activeShape={renderActiveShape}
              data={chartData}
              cx={"50%"}
              cy={"50%"}
              innerRadius={35}
              outerRadius={45}
              dataKey={"value"}
            />
            <Tooltip
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              content={<CustomTooltip />}
            />
          </PieChart>
        </div>
      );
    }
    return (
      <div
        className="roi-eng-cube"
        style={{ marginLeft: 0, width: 290, height: 280, marginBottom: 10 }}
      >
        <strong
          style={{
            color: "#249ff0",
            display: "block",
            textAlign: "left",
            marginLeft: 20,
            marginBottom: -10,
          }}
        >
          Coupons
        </strong>
      </div>
    );
  };

  renderCouponsTable = () => {
    const { summaryData } = this.props;
    if (!summaryData.pixel_conversions) return null;
    const data = summaryData.pixel_conversions.coupons;
    if (data?.length) {
      return (
        <TabPane tab={<span className="uppercase">Coupons</span>} key="coupons">
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="products-headers">
              <div>Name</div>
              <div>Redeemed</div>
              <div>Influencers</div>
              <div style={{ width: 20 }} />
            </div>

            {data.map((coupon) => {
              const open = this.state.openCoupon === coupon.name;
              const influencers =
                summaryData.pixel_conversions.coupon_to_influencers[
                  coupon.name || ""
                ];
              return (
                <div className={`product-row ${open}`} key={coupon.name}>
                  <div onClick={() => this.toggleCoupon(coupon.name)}>
                    <div>{coupon.external_name || coupon.name}</div>
                    <div>{coupon.purchases}</div>
                    <div>{influencers?.length || 0}</div>
                    <div
                      style={{ width: 20, marginTop: 5, height: 20 }}
                      className="moi-arrow-left"
                    />
                  </div>
                  {open ? (
                    <div
                      style={{
                        marginBottom: 5,
                        color: "black",
                        background: "white",
                        padding: 5,
                        borderRadius: 6,
                        boxShadow: "1px 5px 27px 1px rgb(0 0 0 / 3%)",
                        border: "1px solid #eee",
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginTop: 10,
                      }}
                    >
                      <div
                        className="products-headers"
                        style={{
                          borderBottom: "1px solid #eee",
                          paddingBottom: 3,
                        }}
                      >
                        <div style={{ width: "100%" }}>Name</div>
                        <div>Coupons Redeemed</div>
                      </div>
                      {influencers?.map((x) => {
                        const iData =
                          x.insights?.pixel_conversions?.coupons?.find(
                            (o) => o.name === coupon.name,
                          );
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <div>
                            <div
                              className={"product-row"}
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                borderBottom: "1px solid #eee",
                              }}
                            >
                              <div>
                                <div style={{ width: "100%" }}>
                                  <img
                                    src={x.picture_url}
                                    className="avatar"
                                    style={{ width: 30, height: 30 }}
                                  />
                                  <strong style={{ fontSize: 14 }}>
                                    {x.name}
                                  </strong>
                                </div>
                                <div>{iData?.purchases || 0}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </TabPane>
      );
    }
    return null;
  };

  renderDataTable = (breakdown: "brand" | "sku" | "link") => {
    const header = breakdown === "sku" ? "product" : breakdown;
    const isLoading = this.state.loadingDataTable[breakdown];
    const hasData = this.state?.topDataTable?.[breakdown]?.length > 0;
    if (isLoading) {
      return (
        <TabPane
          tab={<span className="uppercase">{header}S</span>}
          key={breakdown}
        >
          <InfiniteLoader />
        </TabPane>
      );
    }
    return (
      <TabPane
        tab={<span className="uppercase">{header}S</span>}
        key={breakdown}
      >
        {hasData ? (
          <>
            <SalesProductsTable
              initialProducts={this.state.topDataTable[breakdown]}
              campaignId={this.props.summaryData.campaign_details.auction_id}
              filters={this.state.filters}
              shareMode={this.props.shared}
              breakdown={breakdown}
            />
          </>
        ) : (
          <NoDataMessage>No data to display yet</NoDataMessage>
        )}
      </TabPane>
    );
  };

  renderRulesTable = () => {
    const { summaryData } = this.props;
    if (!summaryData.pixel_conversions) return null;
    const data = summaryData?.pixel_conversions?.phases ?? [];
    if (data?.length) {
      return (
        <TabPane tab={<span>RULES</span>} key={"rules"}>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="products-headers">
              <div>Name</div>
              <div>Commission</div>
              <div>Total Purchases</div>
              <div>Sales value</div>
              <div>Net sales</div>
              <div style={{ width: 20 }} />
            </div>
            {data.map((phase) => {
              return (
                <div
                  className={`product-row`}
                  style={{ cursor: "default" }}
                  key={phase.phase_id}
                >
                  <div>
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {phase.name}
                    </div>
                    <div>{formatBigNumbers(phase.sales_commission)}</div>
                    <div>{formatBigNumbers(phase.purchases)}</div>
                    <div>{formatBigNumbers(phase.sales)}</div>
                    <div>{formatBigNumbers(phase.sales_net)}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </TabPane>
      );
    }
    return null;
  };
}

export default function RoiPixel(props: RoiPixelProps) {
  const context = useContext(RoiContext);
  return <RoiPixelInner {...context} {...props} />;
}
