export default function FilterRoiButton({
  allowFilters,
  showFilters,
}: {
  allowFilters: boolean;
  showFilters: any;
}) {
  if (!allowFilters) return null;
  return (
    <div
      aria-label="Filter"
      style={{
        color: "white",
        background: "var(--main-baby-blue-color)",
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        width: 30,
        borderRadius: 20,
        marginLeft: 10,
      }}
      onClick={() => showFilters()}
      className="small-title"
    >
      <i
        style={{
          textTransform: "none",
          fontSize: 18,
        }}
        className="fa fa-filter"
      />
    </div>
  );
}
