import { Box, Button } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  deleteTaskInTemplate,
  editTodoItemTemplate,
  importTemlate,
} from "src/api/actions/auctionActions";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import TemplateTasks from "./TemplateTasks";

const Template = (props: any) => {
  const params = useParams();
  const campaignId = params?.id;

  const handleStartTemplate = () => {
    props.setStep("creat-todo");
    props.setTasks(props.templateTasks);

    if (campaignId) {
      importTemlate(Number(campaignId), { templateId: props.templateId })
        .then((res) => {
          props.setTasks(res.data.items);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const isDisable =
    props.template.is_global && props.template.created_by !== props.userId;
  const handleDeleteTemplateTask = (taskId: number) => {
    deleteTaskInTemplate(props.templateId, taskId);
    const updatedTasks = props.templateTasks.filter(
      (task: AuctionToDoItem) => task.id !== taskId,
    );
    props.setTasks(updatedTasks);
  };

  const handleEditTemplateTask = (
    taskId: number,
    taskData: AuctionToDoItem,
  ) => {
    editTodoItemTemplate(props.templateId, taskId, taskData);
    const updatedTasks = props.templateTasks.map((task: AuctionToDoItem) => {
      if (task.id === taskId) {
        // If the task id matches, update the taskData
        return taskData;
      }
      return task; // For other tasks, return as-is
    });
    props.setTasks(updatedTasks);
  };
  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <TemplateTasks
          templateTasks={props.templateTasks}
          templateId={props.templateId}
          handleDeleteTemplateTask={handleDeleteTemplateTask}
          handleEditTask={handleEditTemplateTask}
          template={props.template}
          isDisable={isDisable}
        />
        <Button
          onClick={handleStartTemplate}
          style={{
            margin: "10px",

            borderRadius: "50px",
            width: "100px",
            fontWeight: 400,
          }}
        >
          Add
        </Button>
      </Box>
    </>
  );
};

export default Template;
