// TODO - lint
/* eslint-disable prefer-regex-literals, prefer-regex-literals, prefer-regex-literals, global-require, react/no-deprecated, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, no-plusplus, react/no-direct-mutation-state, class-methods-use-this, no-unused-vars, no-unused-vars, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, class-methods-use-this, react/no-unescaped-entities, react/no-unescaped-entities, react/no-string-refs, react/no-string-refs, react/no-string-refs, no-shadow, import/no-default-export */
// General Imports
import Dropzone from "@humanz/react-dropzone";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
// Actions imports
import {
  addPasswordToUser,
  editAdvertiserDetails,
  getDetailsOfUserFromServer,
} from "../../../actions/userActions";
import { AddAgentDialog } from "../agency/AddAgentDialog";
import { AgentSelect } from "../agency/AgentSelect";
// Components Import
import CheckBox from "../general_components/CheckBox";
import { getUserCurrencies, isValidEmail } from "../utilities/general";

const requiredPasswordLength = 8;
const requiredPasswordLengthWithoutValidation = 12;

class EditSettingsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      editing: false,
      imgBase64: "",
      imgType: null,
      logoutAll: false,
      categories: {
        name: "categories",
        label: "Categories",
        type: "checkbox",
        selected: [],
        isOpen: true,
        isMulti: true,
        labels: [
          "Art",
          "Beauty",
          "Bloggers",
          "Books",
          "Coaching",
          "Family",
          "Fashion",
          "Finance",
          "Fitness",
          "Food & Drink",
          "Funny",
          "Gaming",
          "Health",
          "Home&Garden",
          "Lifestyle",
          "Motherhood",
          "Music",
          "News&TV",
          "Nightlife",
          "Pets",
          "Photography",
          "Science",
          "Soldiers",
          "Sports",
          "Tech",
          "Travel",
          "Vegan",
        ],
        values: [
          "art",
          "beauty",
          "bloggers",
          "books",
          "coaching",
          "family",
          "fashion",
          "finance",
          "fitness",
          "food",
          "funny",
          "gaming",
          "health",
          "home",
          "lifestyle",
          "motherhood",
          "music",
          "news",
          "nightlife",
          "photography",
          "science",
          "soldiers",
          "sports",
          "tech",
          "travel",
          "vegan",
        ],
      },
      errors: {},
      newPassword: null,
      newPasswordConfirmation: null,
      oldPassword: null,
      collapse: { passowrd: true, categories: true, links: true },
      selectedAgency: null,
      validLength: false,
      hasNumber: false,
      upperCase: false,
      lowerCase: false,
      match: false,
      specialChar: false,
      validLengthWithoutValidation: false,
    };
    this.state.isValidPass =
      this.state.validLengthWithoutValidation ||
      (this.state.validLength &&
        this.state.hasNumber &&
        this.state.upperCase &&
        this.state.lowerCase &&
        this.state.specialChar);

    this.pages_regexes = {
      instagram: new RegExp(
        "^(?:(?:http|https)://)?(?:www.)?(?:instagram.com|instagr.am)/([A-Za-z0-9-_]+)(/?)?(.*)",
      ),
      facebook: new RegExp(
        "(?:(?:http|https)://)?(?:www.)?facebook.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?",
      ),
      twitter: new RegExp(
        "^(?:(?:http|https)://)?(?:www.)?(?:twitter.com)/([A-Za-z0-9-_]+)(/?)?(.*)",
      ),
    };

    this.countries = require("../utilities/countries.json");
    this.countries = this.countries.data;
    this.CURRENCIES = getUserCurrencies(
      props.user.userDetails?.sidebar_data?.extra_currencies,
    );
  }

  agentSelect = React.createRef();

  componentWillMount() {
    if (this.props.user) {
      this.setState({ details: this.props.user.userDetails });
    }
  }

  editDetails(e) {
    e.preventDefault();
    if (this.state.editing) return;
    const {
      details,
      imgBase64,
      imgType,
      newPassword,
      newPasswordConfirmation,
      oldPassword,
      logoutAll,
    } = this.state;
    const { company_details } = details;
    const errors = {};
    if (
      company_details.instagram_page &&
      company_details.instagram_page !== ""
    ) {
      if (!this.pages_regexes.instagram.test(company_details.instagram_page)) {
        errors.instagram = "Not valid instagram link , put full website link";
      }
    }
    if (company_details.facebook_page && company_details.facebook_page !== "") {
      if (!this.pages_regexes.facebook.test(company_details.facebook_page)) {
        errors.facebook = "Not valid facebook link , put full website link";
      }
    }
    if (company_details.twitter_page && company_details.twitter_page !== "") {
      if (!this.pages_regexes.twitter.test(company_details.twitter_page)) {
        errors.twitter = "Not valid twitter link , put full website link";
      }
    }
    if (newPassword && newPassword !== "") {
      if (newPassword.length < 3) {
        errors.new_password = "Short password";
      }
      if (newPassword !== newPasswordConfirmation) {
        errors.new_password =
          "New password and confirmation password must be the same";
      }
      if (!oldPassword || oldPassword === "") {
        errors.old_password = "Must enter old password";
      }
    }
    if (!details.full_name || details.full_name === "") {
      errors.full_name = "Must enter name";
    }
    if (
      details.company_details.invoice_email &&
      !isValidEmail(details.company_details.invoice_email)
    ) {
      errors.invoice_email = "Invalid email";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    this.setState({ editing: true, errors: {} });

    let base64 = imgBase64.split(",")[1];
    base64 = base64 || null;

    const newDetails = {
      full_name: details.full_name,
      company_name: details.company_details.name,
      contact_details: details.contact_details,
      company_address: details.company_details.address,
      about_us: details.about,
      facebook_page: details.company_details.facebook_page,
      instagram_page: details.company_details.instagram_page,
      twitter_page: details.company_details.twitter_page,
      youtube_page: details.company_details.youtube_page,
      company_country: details.company_details.country,
      categories: details.categories,
      currency: details.currency,
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confimration: newPasswordConfirmation,
      advertiser_type: details.advertiser_type,
      image_changed: !!base64,
      original_image_url: details.picture_url,
      email: details.user_email,
      image: base64
        ? {
            data: base64,
            mime_type: imgType,
          }
        : null,
      show_bids_notifications: details.sidebar_data.show_bids_notifications,
      invoice_email: details.company_details.invoice_email,
    };

    this.props.editAdvertiserDetails(newDetails, logoutAll, () =>
      this.setState({ editing: false }),
    );
  }

  addPicture(accepted) {
    const reader = new FileReader();
    const { type } = accepted[0];
    reader.readAsDataURL(accepted[0]);
    reader.onload = () => {
      this.setState({ imgBase64: reader.result, imgType: type });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  changePhoneNumber(e) {
    const text = e.target.value;
    let newText = "";
    const numbers = "0123456789+-#*()";

    for (let i = 0; i < text.length; i++) {
      if (numbers.indexOf(text[i]) > -1) {
        newText += text[i];
      }
    }

    // eslint-disable-next-line no-param-reassign
    e.target.value = newText;
    this.state.details.contact_details = newText;
  }

  openAddAgentDialog = (details) => {
    this.setState({ showAddAgentDialog: { details } });
  };

  updateAgentsSelect = () => {
    if (this.agentSelect && this.agentSelect.current) {
      this.agentSelect.current.initOptions(true);
    }
  };

  handleDeleteAgency = (id) => {
    this.props.getUserDetails(() => {
      this.updateAgentsSelect();
      this.setState({ selectedAgency: null });
    });
  };

  closeAddAgentDialog = (agentId) => {
    this.setState({ showAddAgentDialog: false });
  };

  editAgency = () => {
    if (this.props.user.agents && this.state.selectedAgency) {
      const agency = this.props.user.agents.find(
        (x) => x.agent_id === this.state.selectedAgency,
      );
      this.openAddAgentDialog(agency);
    }
  };

  validateRecoverPassword = (value) => {
    return this.setState(
      {
        validLength: value.length >= requiredPasswordLength,
        validLengthWithoutValidation:
          value.length >= requiredPasswordLengthWithoutValidation,
        hasNumber: /\d/.test(value),
        upperCase: /[A-Z]/.test(value),
        lowerCase: /[a-z]/.test(value),
        specialChar: /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(value),
      },
      () => {
        return this.setState(
          {
            isValidPass:
              this.state.validLengthWithoutValidation ||
              (this.state.validLength &&
                this.state.hasNumber &&
                this.state.upperCase &&
                this.state.lowerCase &&
                this.state.specialChar),
          },
          () => {
            this.setState({
              passError: !this.state.isValidPass,
              passwordFocus: !this.state.isValidPass,
            });
            return this.state.isValidPass;
          },
        );
      },
    );
  };

  saveNewPassword = () => {
    const { newPassword, newPasswordConfirmation } = this.state;
    const errors = {};
    if (newPassword && newPassword !== "") {
      if (newPassword.length < 3) {
        errors.new_password = "Short password";
      }
      if (newPassword !== newPasswordConfirmation) {
        errors.new_password =
          "New password and confirmation password must be the same";
      }
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ editing: true });
    this.props.addPasswordToUser(newPassword, (success) => {
      if (success) {
        this.state.details.using_password_to_login = true;
        this.state.collapse.passowrd = true;
        this.state.newPassword = "";
        this.state.newPasswordConfirmation = "";
      }

      this.setState({ editing: false });
    });
  };

  validIcon(condition) {
    return condition ? (
      <span>
        <i className="fa fa-check-circle with-color" />
      </span>
    ) : (
      <span>
        <i className="fa fa-times-circle with-color" />
      </span>
    );
  }

  recoverySetFirst = (value) => {
    this.validateRecoverPassword(value);
  };

  recoverySetSecond = (value) => {
    this.validateRecoverPassword(value);
  };

  renderPasswordValidation() {
    return (
      <div
        className={`passwordValidation ${
          this.state.passwordFocus ? "active" : ""
        }`}
      >
        <div className="password-validation-title">Password validation</div>
        <ul>
          {!(
            this.state.validLength &&
            this.state.hasNumber &&
            this.state.upperCase &&
            this.state.lowerCase &&
            this.state.specialChar
          ) && (
            <li>
              {this.validIcon(this.state.validLengthWithoutValidation)} Length
              must be at least 12 characters
            </li>
          )}
          {!this.state.isValidPass && (
            <>
              <hr />
              <div style={{ textAlign: "center" }}>
                <strong>OR</strong>
              </div>
              <hr />
            </>
          )}
          {!this.state.validLengthWithoutValidation && (
            <>
              <li>
                {this.validIcon(
                  this.state.validLength ||
                    this.state.validLengthWithoutValidation,
                )}{" "}
                Length must be at least 8 characters
              </li>
              <li>
                {this.validIcon(this.state.hasNumber)} Must contain at least one
                number
              </li>
              <li>
                {this.validIcon(this.state.upperCase)} Must contain at least one
                uppercase letter
              </li>
              <li>
                {this.validIcon(this.state.lowerCase)} Must contain at least one
                lowercase letter
              </li>
              <li>
                {this.validIcon(this.state.specialChar)} Must contain at least
                one special character <br /> (~|!,;\-/@#$%^&+=)
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }

  render() {
    const isSubUser =
      this.props.user &&
      this.props.user.userDetails &&
      this.props.user.userDetails.sidebar_data.is_sub_user;
    const { details, errors, categories, collapse } = this.state;
    return (
      <div className="create-tab-content" style={{ marginTop: 30 }}>
        <div className="my-profile-section">
          <div className="campaigns-title">
            <span>My Profile</span>
          </div>

          {/* Description Section */}
          <div className="create-description-form">
            <div style={{ display: "flex" }}>
              <div style={{ width: "65%", float: "left", marginRight: "15%" }}>
                <div className="input-row">
                  <strong className="label-title">full NAME</strong>
                  <div>
                    <input
                      className="input-line"
                      defaultValue={details.full_name}
                      onChange={(e) => {
                        details.full_name = e.target.value;
                      }}
                    />
                    <span style={{ color: "red" }}>{errors.full_name}</span>
                  </div>
                </div>

                {details.using_password_to_login || details.email ? (
                  <div className="input-row">
                    {details.using_password_to_login ? (
                      <strong
                        className="label-title"
                        style={{ marginBottom: 0 }}
                      >
                        change password
                        <span className="optional-title">
                          (Leave blank if you don't want to change)
                        </span>{" "}
                        <span
                          className={`collapse-input ${collapse.passowrd}`}
                          onClick={() =>
                            this.setState({
                              collapse: {
                                ...collapse,
                                passowrd: !collapse.passowrd,
                              },
                            })
                          }
                        />
                      </strong>
                    ) : (
                      <strong
                        className="label-title"
                        style={{ marginBottom: 0 }}
                      >
                        Add password
                        <span className="optional-title">
                          (Leave blank if you don't want to add)
                        </span>{" "}
                        <span
                          className={`collapse-input ${collapse.passowrd}`}
                          onClick={() =>
                            this.setState({
                              collapse: {
                                ...collapse,
                                passowrd: !collapse.passowrd,
                              },
                            })
                          }
                        />
                      </strong>
                    )}
                    {!collapse.passowrd && (
                      <div className={"academy-box"} style={{ padding: 10 }}>
                        {details.using_password_to_login ? (
                          <div>
                            <div
                              className="input-row"
                              style={{ position: "relative" }}
                            >
                              <strong className={"label-title"}>
                                Old Password
                              </strong>
                              <input
                                style={{ marginTop: 15 }}
                                type="password"
                                className="input-line"
                                placeholder="Old password"
                                onChange={(e) => {
                                  this.setState({
                                    oldPassword: e.target.value,
                                  });
                                }}
                              />
                              <span style={{ color: "red" }}>
                                {errors.old_password}
                              </span>
                            </div>
                          </div>
                        ) : null}

                        <div
                          className="input-row"
                          style={{ position: "relative" }}
                        >
                          <strong
                            className={`label-title${
                              this.state.passError ? " error-text" : ""
                            }`}
                          >
                            New Password
                          </strong>
                          <div>
                            <input
                              className={`input-line ${
                                !this.state.isValidPass ? " input-error" : ""
                              }`}
                              type="password"
                              onFocus={() =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={() =>
                                this.setState({ passwordFocus: false })
                              }
                              onChange={(e) => {
                                this.setState({ newPassword: e.target.value });
                                this.recoverySetFirst(e.target.value);
                              }}
                            />
                          </div>
                          {this.renderPasswordValidation()}
                        </div>
                        <div className="input-row">
                          <strong
                            className={`label-title${
                              this.state.passError ? " error-text" : ""
                            }`}
                          >
                            Repeat Password
                          </strong>
                          <div>
                            <input
                              className="input-line"
                              onChange={(e) => {
                                this.setState({
                                  newPasswordConfirmation: e.target.value,
                                });
                                this.recoverySetSecond(e.target.value);
                              }}
                              type="password"
                            />
                          </div>
                          <span style={{ color: "red" }}>
                            {errors.new_password}
                          </span>
                        </div>
                        {details.using_password_to_login ? null : (
                          <div>
                            <button className="btn">Cancel</button>
                            <button
                              className={`btn btn-primary ${
                                this.state.editing ? "disabled" : ""
                              }`}
                              style={{ marginLeft: 10 }}
                              onClick={this.saveNewPassword}
                            >
                              Save
                            </button>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            style={{ margin: 0, marginRight: 7 }}
                            type="checkbox"
                            value={this.state.logoutAll}
                            onChange={() =>
                              this.setState({
                                logoutAll: !this.state.logoutAll,
                              })
                            }
                          />
                          <p style={{ margin: 0 }}>
                            Do you want to sign out from all devices?
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>

              <div className="small-upload-image">
                <strong className="label-title">brand logo/image</strong>
                <Dropzone
                  disabled={isSubUser}
                  accept={{ "image/jpeg": [], "image/png": [] }}
                  onDropAccepted={(accepted) => this.addPicture(accepted)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className="small-upload-image-dropzone"
                    >
                      <input {...getInputProps()} />
                      {this.state.imgBase64 || details.picture_url ? (
                        <div>
                          <img
                            src={
                              this.state.imgBase64
                                ? this.state.imgBase64
                                : details.picture_url
                            }
                          />
                        </div>
                      ) : (
                        <div className="inner-small-upload-dropzone">
                          <div className="moi-right-arrow" />
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className="input-row" style={{ width: "50%" }}>
                <strong className="label-title">company name</strong>
                <div>
                  <input
                    disabled={isSubUser}
                    className="input-line"
                    style={{ height: 35 }}
                    defaultValue={details.company_details.name}
                    onChange={(e) => {
                      details.company_details.name = e.target.value;
                    }}
                  />
                </div>
              </div>

              <div
                className="input-row"
                style={{ width: "20%", marginLeft: "5%" }}
              >
                <strong className="label-title">country</strong>
                <div className="searchable-input">
                  <Select
                    disabled={isSubUser}
                    ref="stateSelect"
                    options={this.countries}
                    placeholder={""}
                    value={details.company_details.country}
                    simpleValue
                    clearable={false}
                    onChange={(value) => {
                      details.company_details.country = value;
                      this.setState({});
                    }}
                    name="selected-state"
                    searchable={true}
                  />
                </div>
              </div>
              <div
                className="input-row"
                style={{ width: "20%", marginLeft: "5%" }}
              >
                <strong className="label-title">Currency</strong>
                <div className="searchable-input">
                  <Select
                    ref="stateSelect"
                    options={this.CURRENCIES}
                    placeholder={""}
                    value={details.currency}
                    simpleValue
                    clearable={false}
                    onChange={(value) => {
                      details.currency = value;
                      this.setState({});
                    }}
                    name="selected-state"
                    disabled={isSubUser}
                    searchable={true}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="input-row" style={{ width: "50%" }}>
                <strong className="label-title">Email</strong>
                <div disabled>
                  <input
                    className="input-line"
                    style={{ height: 35 }}
                    defaultValue={details.email}
                  />
                </div>
              </div>
              <div
                className="input-row"
                style={{ width: "45%", marginLeft: "5%" }}
              >
                <strong className="label-title">
                  Invoices mail
                  <span className="optional-title">(Optional)</span>
                </strong>
                <div>
                  <input
                    className="input-line"
                    style={{ height: 35 }}
                    type="email"
                    defaultValue={details.company_details.invoice_email || ""}
                    onChange={(e) => {
                      details.company_details.invoice_email = e?.target?.value
                        ?.trim()
                        ?.toLowerCase();
                    }}
                  />
                  <span style={{ color: "red" }}>{errors.invoice_email}</span>
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="input-row" style={{ width: "65%" }}>
                <strong className="label-title">phone number</strong>
                <div>
                  <input
                    className="input-line"
                    defaultValue={details.phone}
                    disabled={isSubUser}
                    onChange={this.changePhoneNumber.bind(this)}
                  />
                </div>
              </div>
              <div
                className="input-row"
                style={{ width: "30%", marginLeft: "5%" }}
              >
                <strong className="label-title">Bids Notifications</strong>
                <div className="searchable-input">
                  <Select
                    ref="stateSelect"
                    disabled={isSubUser}
                    options={[
                      { label: "ON", value: true },
                      { label: "OFF", value: false },
                    ]}
                    placeholder={""}
                    value={
                      details.sidebar_data.show_bids_notifications !== false
                    }
                    simpleValue
                    clearable={false}
                    onChange={(value) => {
                      details.sidebar_data.show_bids_notifications = value;
                      this.setState({});
                    }}
                    name="selected-state"
                    searchable={true}
                  />
                </div>
              </div>
            </div>

            {this.props.user.userDetails.sidebar_data
              .allowed_influencer_agent ? (
              <div style={{ marginTop: -20, marginBottom: 20 }}>
                <strong className="label-title">
                  Agencies{" "}
                  <span className="optional-title">
                    (Details with agencies you work with)
                  </span>{" "}
                </strong>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: 200, marginLeft: 5, marginRight: 10 }}>
                    <AgentSelect
                      ref={this.agentSelect}
                      value={this.state.selectedAgency}
                      onChange={(value) =>
                        this.setState({ selectedAgency: value })
                      }
                      editAgency={(details) => this.openAddAgentDialog(details)}
                    />
                  </div>
                  <i
                    className="fas fa-plus"
                    onClick={() => this.openAddAgentDialog()}
                    style={{
                      cursor: "pointer",
                      color: "var(--main-baby-blue-color)",
                    }}
                  />
                  {this.state.selectedAgency ? (
                    <i
                      className="fas fa-pen"
                      onClick={this.editAgency}
                      style={{
                        cursor: "pointer",
                        color: "var(--main-baby-blue-color)",
                        marginLeft: 10,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            <div style={{ marginBottom: 10 }}>
              <strong className="label-title">
                Brand Categories{" "}
                <span
                  className={`collapse-input ${collapse.categories}`}
                  onClick={() =>
                    this.setState({
                      collapse: {
                        ...collapse,
                        categories: !collapse.categories,
                      },
                    })
                  }
                />
              </strong>
              {!collapse.categories && (
                <div className="checkbox-container">
                  {categories.values.map((x, index) => {
                    const checked = details
                      ? details.categories.includes(x)
                      : false;
                    let label = x;
                    if (categories.labels) {
                      label = categories.labels[index];
                    }
                    return (
                      <CheckBox
                        disabled={isSubUser}
                        style={{ marginTop: 5 }}
                        checked={checked}
                        onChange={() => {
                          details.categories.addOrRemove(x);
                          this.setState({ updated: true });
                        }}
                        label={label}
                        key={x}
                      />
                    );
                  })}
                </div>
              )}
            </div>

            <strong className="label-title">
              links{" "}
              <span
                className={`collapse-input ${collapse.links}`}
                onClick={() =>
                  this.setState({
                    collapse: { ...collapse, links: !collapse.links },
                  })
                }
              />
            </strong>
            {!collapse.links && (
              <div>
                <div className="input-row">
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        fontSize: "19px",
                      }}
                      className="fa fa-instagram colored circle-icon"
                    />
                    <input
                      disabled={isSubUser}
                      className="input-line"
                      placeholder="Instagram link"
                      defaultValue={details.company_details.instagram_page}
                      onChange={(e) => {
                        details.company_details.instagram_page = e.target.value;
                      }}
                      style={{ marginTop: 12 }}
                    />
                  </div>
                  <span style={{ color: "red" }}>
                    {this.state.errors.instagram}
                  </span>
                </div>

                <div className="input-row" style={{ marginTop: 40 }}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        fontSize: "17px",
                      }}
                      className="fa fa-facebook circle-icon"
                    />
                    <input
                      disabled={isSubUser}
                      className="input-line"
                      placeholder="Facebook link"
                      defaultValue={details.company_details.facebook_page}
                      onChange={(e) => {
                        details.company_details.facebook_page = e.target.value;
                      }}
                      style={{ marginTop: 12 }}
                    />
                  </div>
                  <span style={{ color: "red" }}>
                    {this.state.errors.facebook}
                  </span>
                </div>

                <div className="input-row" style={{ marginTop: 40 }}>
                  <div style={{ display: "flex" }}>
                    <div
                      className="fa fa-twitter colored circle-icon"
                      style={{
                        fontSize: "17px",
                      }}
                    />
                    <input
                      disabled={isSubUser}
                      className="input-line"
                      placeholder="Twitter link"
                      defaultValue={details.company_details.twitter_page}
                      onChange={(e) => {
                        details.company_details.twitter_page = e.target.value;
                      }}
                      style={{ marginTop: 12 }}
                    />
                  </div>
                  <span style={{ color: "red" }}>
                    {this.state.errors.twitter}
                  </span>
                </div>

                <div className="input-row" style={{ marginTop: 40 }}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        fontSize: "17px",
                      }}
                      className="fa fa-youtube-play colored circle-icon"
                    />
                    <input
                      disabled={isSubUser}
                      className="input-line"
                      placeholder="Youtube link"
                      defaultValue={details.company_details.youtube_page}
                      onChange={(e) => {
                        details.company_details.youtube_page = e.target.value;
                      }}
                      style={{ marginTop: 12 }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* End Description Section */}
        </div>

        <div className="create-buttons-row">
          <button
            className="btn btn-primry"
            onClick={() => this.props.navigate(-1)}
          >
            Back
          </button>
          <button
            className={`btn btn-primry ${
              (this.state.editing || this.state.card_saving) && "disabled"
            }`}
            onClick={(e) => this.editDetails(e)}
          >
            Save
          </button>
        </div>

        {this.state.showAddAgentDialog && (
          <AddAgentDialog
            onDelete={this.handleDeleteAgency}
            updateAgentsSelect={this.updateAgentsSelect}
            agentDetails={this.state.showAddAgentDialog.details}
            onClose={this.closeAddAgentDialog}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editAdvertiserDetails: (newDetails, logoutAll, callback) => {
      dispatch(editAdvertiserDetails(newDetails, logoutAll, callback));
    },
    addPasswordToUser: (password, callback) => {
      dispatch(addPasswordToUser(password, callback));
    },
    getUserDetails: (callback) => {
      dispatch(getDetailsOfUserFromServer(false, callback));
    },
  };
};

const EditSettingsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSettingsTab);

export default (props) => {
  const navigate = useNavigate();

  return <EditSettingsTabConnected {...props} navigate={navigate} />;
};
