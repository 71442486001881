// import { Droppable } from "react-beautiful-dnd";
// import { Box } from "@chakra-ui/react";
// import { deleteTaskInTemplate } from "src/api/actions/auctionActions";
import { Box } from "@chakra-ui/react";
import TemplateTask from "./TemplateTask";

const TemplateTasks = (props: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "calc(100vh - 220px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {props.templateTasks.map((task: any, index: number) => (
        <TemplateTask
          key={index}
          index={index}
          task={task}
          templateId={props.templateId}
          handleDeleteTemplateTask={props.handleDeleteTemplateTask}
          handleEditTask={props.handleEditTask}
          template={props.template}
          isDisable={props.isDisable}
        />
      ))}
    </Box>
  );
};

export default TemplateTasks;
