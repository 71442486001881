import { Box, Text } from "@chakra-ui/react";
import { CSSProperties, useEffect, useState } from "react";
import Select, { Option, Options } from "react-select";

type Props = {
  header: string;
  options: Options<any>;
  onChange: (item: Option<any>) => void;
  placeholder?: string;
  description?: any;
  width?: string;
  name?: string;
  searchable?: boolean;
  labelKey?: string;
  valueKey?: string;
  clearable?: boolean;
  disabled?: boolean;
  dir?: string;
  style?: CSSProperties;
  icon?: string;
  defaultValue?: any;
  className?: string;
  simpleValue?: boolean;
  optional?: boolean;
  editMode?: boolean;
  coloredBlue?: true;
  descriptionStyle?: any;
};

const IndicatorSelect = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [currentValue, setValue] = useState(props.defaultValue);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (props.defaultValue !== currentValue) {
      setValue(props.defaultValue);
    }
    // eslint-disable-next-line
  }, [props.defaultValue]);

  return (
    <>
      <Box sx={{ display: "flex" }} className={props?.className}>
        <Box
          sx={{
            background: isFocused ? "#249FF0" : "#EBEBEB",
            height: "auto",
            marginRight: "8px",
            width: "1px",
            display: "flex",
            flexDirection: "column",
          }}
        ></Box>
        <Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "auto",

                padding: 0,
                color: "#222325",
              }}
            >
              {props.header}
            </Text>
            {props.optional && (
              <Text
                as="span"
                sx={{
                  fontSize: "12px",
                  marginLeft: "8px",
                  fontWeight: 300,
                  color: "#AAAAAA",
                  fontStyle: "italic",
                }}
              >
                Optional
              </Text>
            )}
          </Box>
          {props.description && (
            <Text
              sx={{
                fontSize: "12px",
                marginLeft: "8px",
                fontWeight: 400,
                color: "#AAAAAA",
                ...props?.descriptionStyle,
              }}
            >
              {props.description}
            </Text>
          )}
          <Box style={{ display: "flex" }}>
            {props.icon && (
              <div
                style={{
                  background: props.disabled ? "" : "white",
                  marginTop: 4,
                  display: "flex",
                  alignItems: "center",
                  borderLeft: "1px solid #EAE8E8",
                  borderTop: "1px solid #EAE8E8",
                  borderBottom: "1px solid #EAE8E8",
                  borderRadius: "5px 0 0 5px",
                  height: "36px",
                }}
              >
                <i
                  className={props.icon}
                  style={{ marginLeft: "5px", padding: 3 }}
                ></i>
              </div>
            )}
            <div className={props.coloredBlue && "campaign-type"}>
              <Select
                options={props.options}
                placeholder={props.placeholder}
                value={currentValue}
                labelKey={props.labelKey}
                valueKey={props.valueKey}
                onChange={(item: Option<any>) => {
                  // eslint-disable-next-line no-nested-ternary
                  const val = item
                    ? props?.valueKey
                      ? item[props?.valueKey]
                      : item?.value
                    : null;
                  setValue(val);
                  props.onChange(item);
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name={props.name}
                searchable={props.searchable}
                clearable={props.clearable}
                disabled={props.disabled}
                style={{
                  ...props.style,
                  marginTop: 4,
                  width: props.width ? props.width : "416px",
                  border: "1px solid #EAE8E8",
                  borderLeft: props.icon ? "none" : "1px solid #EAE8E8",
                  borderRadius: props.icon ? "0px 5px 5px 0px" : "",
                }}
                simpleValue={props?.simpleValue}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IndicatorSelect;
