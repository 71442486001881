// TODO - lint
/* eslint-disable no-unused-vars, import/named, no-unused-vars, no-param-reassign, class-methods-use-this, camelcase, camelcase, prefer-const, prefer-const, prefer-const, camelcase, camelcase, camelcase, camelcase, class-methods-use-this, no-param-reassign, class-methods-use-this, class-methods-use-this, class-methods-use-this, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, consistent-return, camelcase, no-unused-vars, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, react/no-unescaped-entities, react/jsx-no-duplicate-props, no-unused-vars, camelcase, import/no-default-export */
import { Button } from "@chakra-ui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNextBillingPeriod } from "../../../actions/paymentActions";
import { CampaignInfluencerPrice } from "../campaign_components/CampaignInfluencerPrice/CampaignInfluencerPrice";
import NoDataMessage from "../general_components/NoDataMessage";

class UpcomingPaymentsConnected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentData: null,
    };
  }

  getPaymentData() {
    this.props.getNextBillingPeriod((data) => {
      if (data && data.services) {
        data.services = this.filterServices(data);
      }
      this.setState({ paymentData: data || false });
    });
  }

  componentDidMount() {
    this.getPaymentData();
  }

  getTotalFromAllCategories(data) {
    let t = 0;
    if (!data) return t;
    if (data.services) t += data.services.total.price;
    if (data.reports) t += data.reports.total.price;
    return t;
  }

  triggerCollapse(item) {
    item.collapsed = !item.collapsed;
    this.setState({});
  }

  renderPriceDetails(paymentData, priceDetails) {
    return (
      <CampaignInfluencerPrice
        isTotalWithTax
        influencerData={{
          influencer_id: undefined,
          payment_status: "none_paid",
          name: undefined,
          price_details: priceDetails,
        }}
      />
    );
  }

  getServiceName(name) {
    if (!name) return "";
    if (name === "media_fee") {
      return "Campaigns";
    }
    return name.toUpperCase().replace(/_/g, " ");
  }

  filterServices(paymentData) {
    if (!paymentData) return;
    const services_names = [];
    const services_categorised = [];
    // Extract services names
    paymentData.services.items.forEach((item) => {
      if (!services_names.includes(item.charge_type)) {
        services_names.push(item.charge_type);
      }
    });
    services_names.forEach((name) => {
      const newCat = {
        name: this.getServiceName(name),
        items: paymentData.services.items.filter((s) => s.charge_type === name),
      };

      // todo: temp only for campaigns
      if (newCat.name === "Campaigns") {
        services_categorised.push(newCat);
      }
    });
    return {
      total: paymentData.services.total,
      items: services_categorised,
      due_date: paymentData.services.due_date
        ? paymentData.services.due_date.replace(/-/g, "/")
        : null,
      payment_date: paymentData.services.payment_date
        ? paymentData.services.payment_date.replace(/-/g, "/")
        : null,
      from_date: paymentData.services.from_date
        ? paymentData.services.from_date.replace(/-/g, "/")
        : null,
    };
  }

  goToDispute = (auctionId, influ) => {
    console.log(influ);
    this.props.navigate(
      `/campaign/${auctionId}/payments?i=${influ.influencer_id}${
        influ.month_relevance ? "&month=" + influ.month_relevance : ""
      }`,
    );
  };

  renderPayments() {
    const { paymentData } = { ...this.state };
    const { services, reports } = paymentData;

    return (
      <div className={"position-relative"}>
        <div className="campaigns-title">
          <span>Your account balance</span>
          <div className="font-weight-bolder upper-case baby-blue pull-right total-big">
            &nbsp;Payment date - {services.payment_date}
          </div>
          <hr />
        </div>
        <div
          className="baby-blue rows-sum position-relative"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            paddingTop: "12px",
          }}
        >
          <div>Total</div>
          <div style={{ display: "flex" }}>
            {services.total.map((total) => (
              <div style={{ margin: "0 12px" }} key={total.currency}>
                {this.renderPriceDetails(paymentData, total)}
              </div>
            ))}
          </div>
        </div>
        <div className="payments-data">
          <div className="payments-title">
            <span className="pull-left">
              {services.from_date} - {services.due_date}
            </span>
            <span className="pull-right"></span>
            <div className="clearfix"></div>
          </div>
          <div className="collapse-items">
            {services.items.map((item, i) => {
              return (
                <div key={i}>
                  <div
                    className="clickable collapse-item collapse-title"
                    onClick={() => this.triggerCollapse(item)}
                  >
                    <span className="collapseTrigger">
                      <i
                        className={`fa${
                          item.collapsed
                            ? " fa-chevron-right"
                            : " fa-chevron-down"
                        }`}
                      />
                    </span>
                    <span>{item.name}</span>
                  </div>
                  <div
                    className={`collapse-content${
                      !item.collapsed ? " collapsed" : ""
                    }`}
                  >
                    {services.items[i].items
                      ? services.items[i].items.map((inner_item, j) => {
                          return (
                            <div key={j}>
                              <div
                                className={
                                  "clickable collapse-item collapse-title inner-item"
                                }
                                onClick={() => this.triggerCollapse(inner_item)}
                              >
                                <span className="collapseTrigger">
                                  <i className="fa fa-chevron-down" />
                                </span>
                                <span>{inner_item.name}</span>
                                <div
                                  className="font-weight-bolder upper-case pull-right rows-sum position-relative"
                                  style={{ marginTop: -12 }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {this.renderPriceDetails(
                                    paymentData,
                                    inner_item.price_details,
                                  )}
                                </div>
                              </div>
                              <div
                                className={`collapse-content${
                                  inner_item.collapsed ? " collapsed" : ""
                                }`}
                              >
                                {inner_item.influencers
                                  ? inner_item.influencers.map(
                                      (sub_inner_item, l) => {
                                        return (
                                          <div
                                            key={l}
                                            className={"collapse-item subitem"}
                                          >
                                            <span>{sub_inner_item.name}</span>
                                            <div
                                              className="font-weight-bolder upper-case pull-right rows-sum position-relative"
                                              style={{ marginTop: -12 }}
                                            >
                                              {this.renderPriceDetails(
                                                paymentData,
                                                sub_inner_item.price_details,
                                              )}
                                            </div>
                                            <div className="pull-right">
                                              {sub_inner_item.dispute_id ? (
                                                <button
                                                  className="btn blue-button right-spacer"
                                                  disabled
                                                >
                                                  PENDING DISPUTE
                                                </button>
                                              ) : (
                                                <Button
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.goToDispute(
                                                      inner_item.id,
                                                      sub_inner_item,
                                                    );
                                                  }}
                                                  rightIcon={
                                                    <i className="far fa-external-link" />
                                                  }
                                                  type="button"
                                                  variant={"small-action-white"}
                                                >
                                                  Show in campaign
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      },
                                    )
                                  : ""}
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="clearfix" style={{ marginBottom: 20 }}></div>
        </div>

        {/* <div className="report-data"> */}
        {/*    <div className="payments-title">Report charges</div> */}
        {/*    <div className="collapse-items"> */}
        {/*        {reports.items.map((item, i) => */}
        {/*            <div className="collapse-item"> */}
        {/*                <span>{item.name}</span> */}
        {/*                <div className="font-weight-bolder upper-case pull-right"> */}
        {/*                    {window.translate("", "money_symbol_" + paymentData.currency)+ item.price_details.price} */}
        {/*                </div> */}
        {/*                <div className="pull-right right-spacer">DUE {item.due}</div> */}
        {/*            </div>)} */}
        {/*    </div> */}
        {/*    <div className="pull-right baby-blue rows-sum position-relative"> */}
        {/*        {this.renderPriceDetails(paymentData,reports_total)} */}
        {/*    </div> */}
        {/* </div> */}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.paymentData ? (
          <>{this.renderPayments()}</>
        ) : (
          <div className="main-middle-container">
            {this.state.paymentData === null ? (
              <div className="logo-loader">
                <div className="loader" />
                <img src={"/images/humanz-logo.png"} />
              </div>
            ) : (
              <NoDataMessage>No data to display</NoDataMessage>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNextBillingPeriod: (callback) => {
      dispatch(getNextBillingPeriod(callback));
    },
  };
};

const UpcomingPaymentsConnectedExport = connect(
  null,
  mapDispatchToProps,
)(UpcomingPaymentsConnected);
export const UpcomingPayments = (props) => {
  const navigate = useNavigate();
  return <UpcomingPaymentsConnectedExport {...props} navigate={navigate} />;
};
