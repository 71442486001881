import { Box } from "@chakra-ui/react";
import React, { forwardRef, useState } from "react";
import moment from "moment";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DatePicker from "react-datepicker";
import DateIcon from "../../../Icons/DateIcon";

const DisplayTaskDate = (props: {
  // disable: boolean;
  task: AuctionToDoItem;
  style: any;
  campaignId: string;
  handleEditTask: (taskId: number, taskData: AuctionToDoItem) => void;
}) => {
  const boxStyle = {
    backgroundColor: "white",
    display: "inline-block",
    borderRadius: "24px",
    justifyContent: "center",
    padding: "4px",
    fontSize: "12px",
    border: "1px solid #CFCFCF",
    margin: "3px 3px 8px 3px",
  };
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const handleDateChange = (i_date: moment.Moment | null) => {
    setSelectedDate(i_date);
    props.handleEditTask(props.task.id, {
      ...props.task,
      due_date: i_date.toISOString(),
    });
  };

  const date = props.task.due_date;
  const CustomInput = forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (props: any, ref: React.Ref<HTMLInputElement>) => {
      const isAddDate = !date;
      const inputWidth = isAddDate ? "60px" : "80px";

      return (
        <input
          {...props}
          ref={ref}
          placeholder={
            date ? moment(date).format(window.time.short_date) : "Add Date"
          }
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            cursor: "pointer",
            width: inputWidth,
          }}
        />
      );
    },
  );

  return (
    <>
      <Box style={boxStyle}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <DateIcon />
          <DatePicker
            selected={selectedDate}
            dateFormat="DD/MM/YYYY"
            onChange={handleDateChange}
            customInput={<CustomInput selected={selectedDate} />}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default DisplayTaskDate;
