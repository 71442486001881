import React from "react";

type Props = { tags: any[] };

const NoMediaMessage = (props: Props) => {
  const icon: React.CSSProperties = {
    fontSize: "56px",
    color: "#AAAAAA",
  };
  const title: React.CSSProperties = {
    color: "var(--Secondary-text-color, #808080)",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "40px",
    marginTop: "10px",
    marginBottom: "-4px",
  };

  const subTitle: React.CSSProperties = {
    color: "var(--Triatary-text-color, #AAA)",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  };

  return props.tags.length === 0 ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "32px",
      }}
    >
      <i className="fa-regular fa-face-meh-blank" style={icon}></i>
      <div style={title}>No results found</div>
      <div style={subTitle}>Set filters to see results</div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "32px",
      }}
    >
      <i className="fa-regular fa-face-meh-blank" style={icon}></i>
      <div style={title}>No results found</div>
      <div style={subTitle}>Try using different filters</div>
    </div>
  );
};

export default NoMediaMessage;
