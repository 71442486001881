// TODO - lint
/* eslint-disable import/no-named-as-default, no-unused-vars, no-unused-vars, eqeqeq, no-unused-vars, no-unused-vars, eqeqeq, class-methods-use-this, react/no-string-refs, no-plusplus, no-plusplus, no-shadow, consistent-return, import/no-default-export */
// General Imports
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tooltip from "rc-tooltip";
import React, { useEffect } from "react";
import Infinite from "react-infinite";
import { connect, useDispatch } from "react-redux";
import { Link, useMatch, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  deleteUnfinishedCampaign,
  getUserBoards,
  toggleArchiveAuction,
} from "../../../actions/auctionsActions";
import Logo from "../../../images/humanz-logo.png";
import NewAuctionImage from "../../../images/svgs/placeholder.svg";
import { campaignActions } from "../../../reducers/campaignReducer";
import { MessageDialog } from "../dialogs/MessageDialog";
import CheckBox from "../general_components/CheckBox";
import { TagsPopup } from "../lists_components/TagsPopup";
import { EnterpriseSelectCampaignUsers } from "../side_menus/EnterpriseSelectCampaignUsers";
import { formatBigNumbers } from "../utilities/general";
import { CampaignTypeTag } from "./CampaignTypeTag";

class CampaignsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      overCube: -1,
      view: "rows",
      isPagingLoading: true,
      currentTab: "Active",
      multiReportSelected: [],
      selectedCampaign: null,
    };
  }

  /* ###### Functions ##### */

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedAuctions !== this.props.selectedAuctions) {
      this.setState({ multiReportSelected: this.props.selectedAuctions });
    }
    if (prevProps.campaign.filter.tags !== this.props.campaign.filter.tags) {
      this.init(this.state.showArchive, this.state.showFavorites);
    }
  }

  init() {
    const boards = this.getBoards();
    if (!boards || boards.length === 0) {
      this.setState({ loading: true });
    } else {
      window.scrollTo(0, 0);
    }
    this.props.getUserBoards(
      this.state.currentTab === "Archived",
      this.props.campaign.filter.search,
      null,
      this.props.campaign.filter.tags,
      () => this.setState({ isPagingLoading: false, loading: false }),
    );
    if (this.props.fromMultiReport) {
      if (this.props.selectedAuctions) {
        this.setState({ multiReportSelected: this.props.selectedAuctions });
      }
    }
  }

  getBoards = () => {
    const { boardReducer } = this.props;
    if (this.state.currentTab === "Archived") {
      return boardReducer.archived;
    }
    // if (this.props.fromMultiReport) {
    //     let hasMore = boardReducer.boards && !!boardReducer.boards.has_more;
    //     // let boards = boardReducer.boards.filter(r => r.campaign_state === 'report');
    //     boards.has_more = hasMore;
    //     return boards;
    // }
    return boardReducer.boards; // else
  };

  overCube(id) {
    this.setState({ overCube: id });
  }

  toggleArchive = (id, force) => {
    const archive = this.state.currentTab !== "Archived";
    if (archive && !force) {
      this.setState({ archiveCampaign: id });
      return;
    }

    this.setState({ archiving: true });
    this.props.toggleArchiveAuction(id, archive, () => {
      this.setState({ archiveCampaign: null, archiving: false });
    });
  };
  /* ###### End Functions ##### */

  /* ###### Renders ##### */
  renderCampaignsCubes(c, index) {
    const unfinishedCampaign = c.campaign_type == "unfinished";
    const onClickMoveTo =
      (unfinishedCampaign ? "/create-campaign/" : "/campaign/") + c.id;
    const onEditMoveTo =
      (unfinishedCampaign ? "/create-campaign/" : "/edit-campaign/") + c.id;

    const image = `url(${c.image ? c.image : "/images/no-image.png"})`;
    const { overCube } = this.state;
    const focused = overCube === c.id;
    return (
      <div
        key={index}
        className="campaign-cube blurable"
        style={{ backgroundImage: image, backgroundSize: "cover" }}
        onMouseEnter={(e) => this.overCube(c.id)}
        onMouseLeave={(e) => this.overCube(-1)}
      >
        <div className={`${focused} campaign-cube-details`}>
          <div
            className="campaign-cube-title blurable"
            onClick={() => this.props.navigate(onClickMoveTo)}
          >
            <div>
              <strong>{c.name}</strong>
            </div>
            <strong>{c.campaign_state}</strong>
          </div>
          {focused && (
            <>
              <div
                style={{ display: "flex", justifyContent: "space-around" }}
                onClick={() => this.props.navigate(onClickMoveTo)}
              >
                <div style={{ textAlign: "center" }}>
                  <strong style={{ fontSize: 20 }}>
                    {formatBigNumbers(c.influencers_amount)}
                  </strong>
                  <br />
                  <strong>INFLUENCERS</strong>
                </div>
                <div style={{ textAlign: "center" }}>
                  <strong style={{ fontSize: 20 }}>
                    {formatBigNumbers(c.content_amount)}
                  </strong>
                  <br />
                  <strong>
                    CONTENTS
                    <Tooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={
                        <span>
                          {
                            "The amount of content pieces that produced and attached in this campaign"
                          }
                        </span>
                      }
                    >
                      <i
                        className="fa-solid fa-info-circle"
                        style={{ color: "white", marginLeft: 4 }}
                      />
                    </Tooltip>
                  </strong>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 8,
                  fontSize: 18,
                }}
              >
                {!unfinishedCampaign && !c.waiting_approval && (
                  <Link
                    to={`/campaign/${c.id}/list`}
                    className="inf-row-button"
                    style={{ background: "#249ff0" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <li className="moi-list" style={{ color: "white" }} />{" "}
                      <span>Campaign List</span>
                    </div>
                  </Link>
                )}
                <div>
                  <TagsPopup
                    tags={this.props.user.tags.auction_tags}
                    tagType="auction"
                    updateParentState={() => this.forceUpdate()}
                    objectId={c.id}
                  />
                  <div
                    aria-label="Edit"
                    className="fas fa-pencil-alt"
                    style={{ fontSize: 17, marginRight: 20 }}
                    onClick={() => this.props.navigate(onEditMoveTo)}
                  />
                  {unfinishedCampaign ? (
                    <div
                      aria-label="Delete"
                      className="moi-trash"
                      onClick={() =>
                        this.setState({
                          selectedAuction: c.id,
                          showDeleteDialog: true,
                        })
                      }
                    ></div>
                  ) : (
                    <div
                      aria-label="Share"
                      className="far fa-share"
                      onClick={() =>
                        this.props.toggleShareCampain(c.id, c.name)
                      }
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  onDeleteAuction = (id) => {
    this.props.deleteUnfinishedCampaign(id, () => {
      this.setState({ showDeleteDialog: false });
    });
  };

  handleInfiniteLoad = () => {
    const boards = this.getBoards();
    if (
      this.state.isPagingLoading ||
      !boards ||
      !boards.has_more ||
      boards.length === 0
    ) {
      return;
    }

    this.setState({ isPagingLoading: true }, () => {
      const lastTime = boards[boards.length - 1].creation_time;
      this.props.getUserBoards(
        this.state.currentTab === "Archived",
        this.props.campaign.filter.search,
        lastTime,
        this.props.campaign.filter.tags,
        () => {
          this.setState({ isPagingLoading: false });
        },
      );
    });
  };

  onChangeTabs = (e) => {
    this.props.resetFilter();
    this.setState({ currentTab: e }, () => {
      this.init();
    });
  };

  handleSearch = (e) => {
    const { value } = e.target;
    this.props.setFilterSearch(value);
    this.setState({ loading: true });
    window.setTimeout(() => {
      if (this.props.campaign.filter.search === value) {
        this.props.getUserBoards(
          this.state.currentTab === "Archived",
          this.props.campaign?.filter?.search?.toLowerCase()?.trim(),
          null,
          this.props.campaign.filter.tags,
          () => {
            this.setState({ loading: false });
          },
        );
      }
    }, 800);
  };

  campaignClickOnMultiReport = (c) => {
    this.state.multiReportSelected.addOrRemove(c.id);
    this.setState({});
    this.props.onCampaignClick(c, this.state.multiReportSelected);
  };

  renderCampaignsRowsForMultiReport = (c, index) => {
    const renderCampaign =
      c.campaign_state === "report" && c.campaign_type !== "unfinished";
    return (
      <div
        onClick={() =>
          renderCampaign ? this.campaignClickOnMultiReport(c) : () => {}
        }
        className={`campaign-details-container campaign-details-selectable ${
          renderCampaign ? "" : " disabled "
        }${
          this.state.multiReportSelected.indexOf(c.id) > -1 ? " selected" : ""
        }`}
        key={index}
        style={{ marginBottom: 20, cursor: "pointer" }}
      >
        <div
          className="checkbox-container"
          style={{ height: 35, marginTop: -7 }}
        >
          <CheckBox
            checked={this.state.multiReportSelected.indexOf(c.id) > -1}
            onChange={() => {}}
          />
        </div>
        <img className="blurable" src={c.image ? c.image : NewAuctionImage} />
        <div className="campaign-details-rows">
          <div
            className="campaign-details-row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <strong
              className="campaign-name blurable"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              {c.name} {!renderCampaign ? " (No available report)" : ""}
            </strong>
          </div>
          {renderCampaign && (
            <div>
              <div className="campaign-details-rows">
                {this.props.parent && c.owner ? (
                  <div
                    style={{
                      textAlign: "left",
                      display: "inline-block",
                      marginLeft: 30,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        marginTop: 8,
                        marginBottom: 8,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {c.owner.image ? (
                        <img
                          alt={c.owner.name}
                          src={c.owner.image}
                          style={{
                            width: 35,
                            height: 35,
                            borderRadius: "50%",
                            marginRight: 5,
                          }}
                        />
                      ) : null}
                      {c.owner.name}
                    </div>
                    <div>
                      <strong className="list-influencer-details-desc">
                        Managed By
                      </strong>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderEnterpriseEditCampaignMiniUsers = (c) => {
    if (
      !c ||
      !this.props.user.userDetails ||
      !this.props.user.userDetails.sidebar_data
    ) {
      return null;
    }
    const users = this.props.user.userDetails.sidebar_data.sub_users?.filter(
      (u) => {
        return (
          c.sub_users_allowed.includes(u.id) || c.original_creator_id === u.id
        );
      },
    );

    return (
      <>
        <div className="mini-users">
          {
            <div className="specific-mini-users">
              {!users || c.all_sub_users_allowed || users.length > 1 ? (
                <div
                  className="subuser-campaign"
                  style={{ justifyContent: "center" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ selectedCampaign: c });
                  }}
                >
                  <strong
                    className="campaign-name blurable"
                    style={{
                      textAlign: "center",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      placeContent: "center",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                    }}
                  >
                    {users?.length > 1 ? `${users.length} Users` : "All users"}
                  </strong>
                </div>
              ) : (
                users.map((u, i) => {
                  return (
                    <div
                      key={i}
                      className="subuser-campaign"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({ selectedCampaign: c });
                      }}
                    >
                      <img
                        className="blurable"
                        src={u.image ? u.image : NewAuctionImage}
                      />
                      <strong
                        className="campaign-name blurable"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        {u.name}
                      </strong>
                    </div>
                  );
                })
              )}
            </div>
          }
        </div>
      </>
    );
  };

  renderCampaignsRows = (c, index) => {
    const unfinishedCampaign = c.campaign_type == "unfinished";
    const onClickMoveTo =
      (unfinishedCampaign ? "/create-campaign/" : "/campaign/") + c.id;
    const onEditMoveTo =
      (unfinishedCampaign ? "/create-campaign/" : "/edit-campaign/") + c.id;
    const isEnterprise = this.props.user?.enableShareWithSubUsers;
    const campaignLabel = c.campaign_state;

    const tags = this.props.user.tags.auction_tags.filter((t) =>
      t.ids?.includes(c.id),
    );
    return (
      <Link
        key={index}
        to={onClickMoveTo}
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <div
          className={`campaign-details-container campaign-details-selectable ${
            c.waiting_approval ? "container-waiting-approval" : ""
          } ${unfinishedCampaign ? " unfinished" : ""}`}
          key={index}
          style={{ marginBottom: 20, cursor: "pointer" }}
        >
          <img className="blurable" src={c.image ? c.image : NewAuctionImage} />
          <CampaignTypeTag auction={c} />
          <div className="campaign-details-rows">
            <div
              className="campaign-details-row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <strong
                className="campaign-name blurable"
                style={{
                  width: 250,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  display: "inline-block",
                }}
              >
                {c.name}
              </strong>
              {!unfinishedCampaign && !c.waiting_approval && (
                <Link
                  to={`/campaign/${c.id}/list`}
                  className="inf-row-button"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <li className="moi-list" style={{ color: "white" }} />{" "}
                    <span>Campaign List</span>
                  </div>
                </Link>
              )}

              {!unfinishedCampaign && isEnterprise && (
                <button
                  style={{ marginRight: 15 }}
                  className={"btn inf-row-button"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ selectedCampaign: c });
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <li>
                      <i
                        className="fas fa-user-alt"
                        style={{
                          transform: "translateY(-2px)",
                          color: "white",
                        }}
                      />
                    </li>
                    <span> Users</span>
                  </div>
                </button>
              )}

              <strong className="campaign-label">
                {campaignLabel}
                {c.waiting_approval && (
                  <Tooltip
                    placement="top"
                    trigger={["hover", "click"]}
                    overlay={
                      <span>{"Humanz will approve your campaign soon"}</span>
                    }
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#aaaaaa", marginLeft: 4 }}
                    />
                  </Tooltip>
                )}
              </strong>

              {this.props.parent ? null : (
                <>
                  <div
                    className="campaign-details-actions"
                    style={{ width: 200, justifyContent: "flex-end" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <TagsPopup
                      tags={this.props.user.tags.auction_tags}
                      tagType="auction"
                      updateParentState={() => this.forceUpdate()}
                      objectId={c.id}
                    />
                    <Tooltip overlay="Edit" trigger="hover" placement="top">
                      <div
                        className="fas fa-pencil-alt"
                        onClick={() => this.props.navigate(onEditMoveTo)}
                      />
                    </Tooltip>
                    {unfinishedCampaign ? (
                      <Tooltip overlay="Delete" trigger="hover" placement="top">
                        <div
                          className="moi-trash"
                          onClick={() =>
                            this.setState({
                              selectedAuction: c.id,
                              showDeleteDialog: true,
                            })
                          }
                        />
                      </Tooltip>
                    ) : (
                      <>
                        <Tooltip
                          overlay="Share"
                          trigger="hover"
                          placement="top"
                        >
                          <div
                            className="far fa-share"
                            onClick={() =>
                              this.props.toggleShareCampain(c.id, c.name)
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          overlay={
                            this.state.currentTab === "Archived"
                              ? "Active"
                              : "Archive"
                          }
                          trigger="hover"
                          placement="top"
                        >
                          <div
                            className="fas fa-archive"
                            onClick={() => this.toggleArchive(c.id)}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            {!unfinishedCampaign && (
              <div>
                <div
                  className="campaign-details-rows"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ textAlign: "left", display: "inline-block" }}>
                    <div
                      style={{
                        fontSize: 18,
                        marginTop: 8,
                        marginBottom: 8,
                        textAlign: "center",
                      }}
                    >
                      {formatBigNumbers(c.influencers_amount)}
                    </div>
                    <div>
                      <strong className="list-influencer-details-desc">
                        influencers
                      </strong>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      display: "inline-block",
                      marginLeft: 30,
                    }}
                  >
                    {
                      <div
                        style={{
                          fontSize: 18,
                          marginTop: 8,
                          marginBottom: 8,
                          textAlign: "center",
                          marginLeft: -5,
                        }}
                      >
                        {formatBigNumbers(c.content_amount)}
                      </div>
                    }
                    <div>
                      <strong className="list-influencer-details-desc">
                        Contents
                        <Tooltip
                          placement="top"
                          trigger={["hover"]}
                          overlay={
                            <span>
                              {
                                "The amount of content pieces that produced and attached in this campaign"
                              }
                            </span>
                          }
                        >
                          <i
                            className="fa-solid fa-info-circle"
                            style={{ color: "#bbb", marginLeft: 4 }}
                          />
                        </Tooltip>
                        {/* <Tooltip placement="top" trigger={['hover', 'click']} overlay={<span>{"The amount of tasks of approved influencers."}</span>}><i className="fa-solid fa-info-circle" /></Tooltip> */}
                      </strong>
                    </div>
                  </div>
                  {this.props.parent && c.owner ? (
                    <div
                      style={{
                        textAlign: "left",
                        display: "inline-block",
                        marginLeft: 30,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          marginTop: 8,
                          marginBottom: 8,
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {c.owner.image ? (
                          <img
                            alt={c.owner.name}
                            src={c.owner.image}
                            style={{
                              width: 35,
                              height: 35,
                              borderRadius: "50%",
                              marginRight: 5,
                            }}
                          />
                        ) : null}
                        {c.owner.name}
                      </div>
                      <div>
                        <strong className="list-influencer-details-desc">
                          Managed By
                        </strong>
                      </div>
                    </div>
                  ) : null}
                  {isEnterprise && (
                    <div
                      style={{
                        textAlign: "left",
                        display: "inline-block",
                        marginLeft: 20,
                        alignSelf: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          marginTop: 8,
                          marginBottom: 8,
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {this.renderEnterpriseEditCampaignMiniUsers(c)}
                      </div>
                      <div>
                        <strong className="list-influencer-details-desc">
                          Managed By
                        </strong>
                      </div>
                    </div>
                  )}

                  {tags.length ? (
                    <div
                      className="tags-row"
                      style={{ marginRight: -20, alignSelf: "flex-end" }}
                    >
                      {tags.map(this.renderTagOnRow)}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
    );
  };

  renderTagOnRow = (t, idx) => {
    return (
      <div
        style={{ background: t.color, marginBottom: 0 }}
        key={idx}
        dir="auto"
      >
        {t.label}
      </div>
    );
  };

  handleTagSelected = (tag) => {
    this.props.addOrRemoveFilterTag(tag);
  };

  /* ###### End Renders ##### */

  render() {
    const { view } = this.state;
    const boards = this.getBoards();
    const isEnterprise = this.props.user?.enableShareWithSubUsers;
    return (
      <div style={{ background: "#fafafa", height: "100%", paddingBottom: 30 }}>
        {/* Top bar */}
        <div className="section-top-bar"></div>
        <Tabs
          renderTabBar={() =>
            this.props.fromMultiReport ? <></> : <InkTabBar />
          }
          renderTabContent={() => <TabContent />}
          activeKey={this.state.currentTab}
          onChange={this.onChangeTabs}
          className="campaign-top-bar"
        >
          <TabPane tab={<span>Active</span>} key="Active"></TabPane>
          <TabPane tab={<span>Archived</span>} key="Archived"></TabPane>
        </Tabs>
        {/* End top bar */}
        <div className="main-lists-container">
          {/* Actions Row */}
          <div
            className="middle-header"
            style={{ paddingTop: 0, marginTop: -87 }}
          >
            {!this.props.fromMultiReport && (
              <div className="list-title">
                <strong>Campaigns - {this.state.currentTab}</strong>
                <div
                  className={"create-buttons-row pull-right "}
                  style={{ padding: "2.5px 0" }}
                ></div>
              </div>
            )}
            <div style={{ display: "flex" }}>
              <div className="inner-toolbar lists-toolbar">
                <div className="inner-toolbar-search-input">
                  <div className="moi-search-bold" />
                  <input
                    placeholder="Campaign Name"
                    value={this.props.campaign.filter.search}
                    onChange={this.handleSearch}
                    className="form-control"
                  />
                </div>
              </div>
              {this.renderTagsFilter()}
            </div>
            {/* End Actions Row */}
          </div>

          {/* Campaigns cubes */}
          {this.state.loading ? (
            <div style={{ marginTop: 100 }} className="logo-loader">
              <div className="loader" />
              <img src={Logo} />
            </div>
          ) : (
            <>
              {/* Campaigns cubes */}
              {view === "cubes" && (
                <div className="campaigns-cubes">
                  <Infinite
                    elementHeight={240}
                    className="campaign-influencers-table-container"
                    useWindowAsScrollContainer={true}
                    infiniteLoadBeginEdgeOffset={300}
                    onInfiniteLoad={this.handleInfiniteLoad}
                    loadingSpinnerDelegate={this.elementInfiniteLoad()}
                    isInfiniteLoading={this.state.isPagingLoading}
                  >
                    {this.renderCampaignCubeRow(this.getBoards())}
                  </Infinite>
                </div>
              )}
              {/* End Campaigns cubes */}

              {view === "rows" && (
                <div className="lists-rows">
                  {/* <strong className="small-title">LINES MODE</strong> */}
                  <Infinite
                    elementHeight={!this.props.fromMultiReport ? 126 : 50}
                    className="campaign-influencers-table-container"
                    useWindowAsScrollContainer={!this.props.fromMultiReport}
                    infiniteLoadBeginEdgeOffset={
                      !this.props.fromMultiReport ? 300 : 50
                    }
                    containerHeight={this.props.fromMultiReport ? 250 : null}
                    onInfiniteLoad={this.handleInfiniteLoad}
                    loadingSpinnerDelegate={this.elementInfiniteLoad()}
                    isInfiniteLoading={this.state.isPagingLoading}
                  >
                    {this.props.fromMultiReport
                      ? boards.map(this.renderCampaignsRowsForMultiReport)
                      : boards.map(this.renderCampaignsRows)}
                  </Infinite>
                </div>
              )}
            </>
          )}

          {this.state.showDeleteDialog && (
            <MessageDialog
              handleClose={() => {
                this.setState({ showDeleteDialog: false });
              }}
              icon="moi-trash"
            >
              <div style={{ textAlign: "center", padding: 20 }}>
                <strong>Notice</strong>
                <p style={{ marginTop: 15 }}>
                  Deleting an auction is irreversible.
                </p>
                <button
                  onClick={() =>
                    this.onDeleteAuction(this.state.selectedAuction)
                  }
                  className="btn btn-primry"
                  style={{
                    background: "var(--main-baby-blue-color)",
                    color: "white",
                    borderRadius: "40px",
                    width: 100,
                    marginTop: 15,
                  }}
                >
                  DELETE
                </button>
              </div>
            </MessageDialog>
          )}
          {this.state.archiveCampaign && (
            <MessageDialog
              handleClose={() => {
                this.setState({ archiveCampaign: false });
              }}
              icon="fas fa-archive"
            >
              <div style={{ textAlign: "center", padding: 20 }}>
                <strong>Notice</strong>
                <p style={{ marginTop: 15 }}>
                  You will still need to work with the influencers that already
                  running in the campaign.
                </p>
                <button
                  onClick={() =>
                    this.toggleArchive(this.state.archiveCampaign, true)
                  }
                  className={`btn btn-primry ${
                    this.state.archiving ? "disabled" : ""
                  }`}
                  style={{
                    background: "var(--main-baby-blue-color)",
                    color: "white",
                    borderRadius: "40px",
                    width: 100,
                    marginTop: 15,
                  }}
                >
                  ARCHIVE
                </button>
              </div>
            </MessageDialog>
          )}
          {/* End Campaigns cubes */}
        </div>

        {this.state.selectedCampaign && isEnterprise && (
          <EnterpriseSelectCampaignUsers
            campaign={this.state.selectedCampaign}
            all_users={this.props.user.userDetails.sidebar_data.sub_users}
            handleClose={() => {
              this.setState({ selectedCampaign: null });
              this.init();
            }}
          />
        )}
      </div>
    );
  }

  renderTagsFilter = () => {
    const tags = this.props.user.tags.auction_tags;
    const filteredTagIds = this.props.campaign.filter.tags.map((x) => x.id);
    const filteredTags = tags.filter(
      (tag) => filteredTagIds.indexOf(tag.id) !== -1,
    );
    if (tags) {
      return (
        <div className="tags-toolbar-container">
          <Select
            valueKey="id"
            className="tags-toolbar-select"
            ref="stateSelect"
            options={tags}
            placeholder={"Tags"}
            clearable={false}
            value={filteredTags.length !== 0 ? filteredTags : null}
            onChange={this.handleTagSelected}
            searchable={true}
          />
          <div className="filter-multi-cubes">
            {this.props.campaign.filter.tags.map((t, idx) => (
              <div key={`${t}_${idx}`}>
                <span dir="auto">{t.label}</span>{" "}
                <a
                  className="far fa-times"
                  onClick={() => this.handleTagSelected(t)}
                ></a>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  renderCampaignCubeRow = (boards) => {
    const CUBES_PER_ROW = 3;
    let numberOfRows = boards.length / CUBES_PER_ROW;
    if (boards % CUBES_PER_ROW !== 0) {
      numberOfRows += 1;
    }
    const groups = [];
    for (let i = 0; i < numberOfRows; i++) {
      const baseNumber = i * CUBES_PER_ROW;
      const group = [];
      for (
        let j = 0;
        j + baseNumber < boards.length && j < CUBES_PER_ROW;
        j++
      ) {
        group.push(boards[baseNumber + j]);
      }
      if (group.length > 0) {
        groups.push(group);
      }
    }

    return groups.map(this.renderCampaignGroup);
  };

  renderCampaignGroup = (group, idx) => {
    return (
      <div className="row" key={idx}>
        {group.map((campaign, idx) => this.renderCampaignsCubes(campaign, idx))}
      </div>
    );
  };

  elementInfiniteLoad = () => {
    if (!this.state.isPagingLoading) return;
    return (
      <div className="infinite-list-item">
        <div
          className="spinner small"
          style={{ marginTop: 10, marginBottom: 0 }}
        >
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    boardReducer: state.boardReducer,
    parent: state.userReducer.parent,
    user: state.userReducer,
    campaign: state.campaignReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilterSearch(arg) {
      dispatch(campaignActions.setFilterSearch(arg));
    },
    addOrRemoveFilterTag(arg) {
      dispatch(campaignActions.addOrRemoveFilterTag(arg));
    },
    resetFilter(arg) {
      dispatch(campaignActions.resetFilter(arg));
    },
    deleteUnfinishedCampaign: (campaignId, callback) => {
      dispatch(deleteUnfinishedCampaign(campaignId, callback));
    },
    getUserBoards: (archive, search, lastTime, tags, callback) => {
      dispatch(getUserBoards(archive, search, lastTime, tags, callback));
    },
    toggleArchiveAuction: (auctionId, archive, callback) => {
      dispatch(toggleArchiveAuction(auctionId, archive, callback));
    },
  };
};

const CampaignsSectionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignsSection);

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const campaignsMatch = useMatch("/campaigns");
  const campaignMatch = useMatch({
    path: "/campaign",
    end: false,
  });
  const isLeavingCampaigns = !campaignsMatch && !campaignMatch;

  useEffect(() => {
    if (isLeavingCampaigns) {
      dispatch(campaignActions.resetFilter());
    }
  }, [dispatch, isLeavingCampaigns]);

  return <CampaignsSectionConnected {...props} navigate={navigate} />;
};
