import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import Dialog from "rc-dialog";
import React from "react";
import { InfoToolTip } from "../general_components/InfoToolTip";
import { getSocialNetworkUsernameUrl } from "../utilities/general";

export const addCommasToNumbers = (str: string) => {
  return str.replace(/\b([\d.-[()[(]+[\d])\b/g, function (_: any, number: any) {
    if (/[-+[)[(]/.test(number)) {
      return number;
    }

    return parseFloat(number).toLocaleString();
  });
};

export const formatText = (social: any, text: string) => {
  const usernameRegex = /^(\n| |\t)@[a-zA-Z0-9/._]+/;
  const content = text?.split(
    /((?:#|\n@|\s@|https?:\/\/[^\s]+)[a-zA-Z0-9_/.]+[a-zA-Z0-9_/])/g,
  );
  let username;
  return content?.map((word: string, index: any) => {
    if (usernameRegex.test(word)) {
      username = word?.replace("@", "")?.trim();
      return (
        <Link
          href={getSocialNetworkUsernameUrl(social, username)}
          target="_blank"
          key={index}
        >
          {" "}
          <a className="text-cyanBlue/80 hover:text-cyanBlue">{word}</a>{" "}
        </Link>
      );
    }
    if (word.includes("http")) {
      return (
        <a
          key={index}
          target="_blank"
          href={word}
          className="text-cyanBlue/80 hover:text-cyanBlue"
          rel="noreferrer"
        >
          {word}{" "}
        </a>
      );
    }
    return addCommasToNumbers(word);
  });
};

export const SmartText = ({ social, text, length = 20 }: any) => {
  const [visible, setVisible] = React.useState(false);

  if (text?.length < length) {
    return <p>{text}</p>;
  }

  const data = `${text?.slice(0, length)}...`;

  return (
    <>
      <div>
        <p>{data}</p>
        <Button
          onClick={() => setVisible(true)}
          style={{ padding: 0, height: "auto" }}
          variant={"link"}
        >
          &nbsp;Read more
        </Button>
      </div>

      <Dialog
        onClose={() => setVisible(false)}
        className={`message-dialog`}
        visible={visible}
        animation="slide-fade"
        maskAnimation="fade"
        style={{ maxWidth: 800, minWidth: 700 }}
      >
        <Flex
          justifyContent={"center"}
          borderTopEndRadius={"20px"}
          borderTopStartRadius={"20px"}
          alignItems="center"
          height={"60px"}
          paddingTop={"20px"}
        >
          <Box flex={1} textAlign="center">
            <Text
              fontSize={18}
              display={"flex"}
              gap={"5px"}
              marginLeft={"34px"}
              justifyContent="center"
            >
              <Text color={"brand.500"} fontWeight="bold">
                Humanz Ai & Chat GPT{" "}
              </Text>
              <Text fontWeight={"bold"}>Summary</Text>
              <InfoToolTip
                placement="bottom"
                iconStyle={{ fontWeight: "500" }}
                overlay={
                  <div>
                    <Text
                      fontSize={"13px"}
                      maxW={300}
                    >{`We trained ChatGPT on creators data and we asked what it says about this influencer `}</Text>
                  </div>
                }
              />
            </Text>
          </Box>
        </Flex>

        <p
          style={{
            maxWidth: 800,
            whiteSpace: "pre-line",
            padding: 20,
          }}
        >
          {formatText(social, text)}
        </p>
      </Dialog>
    </>
  );
};
