import React, { useEffect } from "react";
import RichTextEditor from "@humanz/react-rte";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

// eslint-disable-next-line import/no-relative-packages
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface Props {
  handleClose(): void;
  value: any;
  setValue(text: string): void;
}

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
    { label: "Strikethrough", style: "STRIKETHROUGH" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export const MarkDownEditor: React.FC<Props> = (props) => {
  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.createValueFromString(props.value || "", "markdown"),
  );

  const onChange = (newValue: any) => {
    setEditorValue(newValue);
    props.setValue(newValue.toString("markdown"));
  };

  return (
    <RichTextEditor
      value={editorValue}
      onChange={onChange}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toolbarConfig={toolbarConfig}
    />
  );
};

export const EditorConvertToHtml = ({
  value,
  setValue,
  disabled,
  placeholder,
  isShare,
  onBlur,
  onFocus,
}: {
  value: string;
  setValue(text: string): void;
  disabled?: boolean;
  placeholder?: string;
  isShare: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(value ?? "").contentBlocks),
    ),
  );
  useEffect(() => {
    if (isShare)
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(value ?? "").contentBlocks,
          ),
        ),
      );
  }, [isShare, value]);

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    if (!newEditorState.getCurrentContent().hasText()) {
      setValue("");
      return;
    }
    setValue(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
  };

  return (
    <div className="wysiwyg-editor">
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        editorClassName="custom-editor"
        wrapperStyle={{
          backgroundColor: "white",
          position: "relative",
          zIndex: 1,
          height: "inherit",
          width: "inherit",
          listStyleType: "none",
        }}
        editorStyle={{
          fontSize: 14,
          minHeight: 140,
          maxHeight: 140,
          padding: "0 10px",
        }}
        toolbar={{
          options: ["inline", "list", "link", "emoji", "history"],
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
          },
          list: { options: ["unordered", "ordered"] },
        }}
        placeholder={placeholder}
        readOnly={disabled}
        toolbarHidden={isShare}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};
