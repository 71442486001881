import React from "react";
import { TranslationProps, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AcademyActions } from "../../../actions/academyActions";
import Logo from "../../../images/humanz-logo.png";
import { Course, CourseSummary } from "../../../models/Api";
import "./academy.css";
import { AcademyCourses } from "./academyCourses";
import { AcademyLesson } from "./academyLesson";
import { AcademyQuiz } from "./academyQuiz";
import { AcademySingleCourse } from "./academySingleCourse";
import { AcademySubscribe } from "./academySubscribe";

interface OwnProps {
  getCourses: any;
  changeAcademyPage: any;
  params: any;
  courses: Array<Course>;
  router: any;
  location: any;
  current_chapter: any;
  current_course: any;
  getSingleCourse: any;
}

type Props = OwnProps & {
  navigate: NavigateFunction;
  params: any;
  location: Location;
};

type State = Readonly<{
  mode: string;
  page_data: any;
  loaded: boolean;
  courses: Array<CourseSummary>;
}>;

class AcademyInner extends React.Component<Props, State, TranslationProps> {
  readonly state: State = {
    mode: "home",
    page_data: null,
    loaded: false,
    courses: new Array<CourseSummary>(),
  };

  getCourses = (refetch: boolean) => {
    if (refetch) {
      this.props.getCourses("", "", (data: any) => {
        // if(!data.data[0].subscription_status){
        //   this.props.navigate.push('/home');
        //   return;
        // }
        this.setState({ loaded: true, courses: data.data }, () => {
          if (
            this.state.courses &&
            !this.props.current_course.id &&
            this.state.courses[0] &&
            this.state.courses[0].subscription_status
          ) {
            this.props.getSingleCourse(
              this.state.courses[0].subscription_status.translation_id,
            );
          } else {
            this.props.getSingleCourse(this.props.current_course.course_id);
          }
        });
      });
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // console.log("PATH CHANGED",this.props.router);
      this.fetchPageTemplate();
    }
  }

  fetchPageTemplate = () => {
    this.getCourses(true);
    // console.log("Fetching page template:",this.props.params,this.props.current_course)
    if (this.props.params?.quiz_id) {
      this.setState({ mode: "quiz" });
    } else if (this.props.params?.lesson) {
      this.setState({ mode: "lesson" });
    } else if (this.props.params?.course) {
      this.props.getSingleCourse(this.props.params.course);
      this.setState({ mode: "course" });
    } else {
      // todo: should be home, but we have only one course
      // this.setState({mode:'home'});
      this.setState({ mode: "course" });
    }
    this.setState({ loaded: true });
  };

  componentDidMount(): void {
    this.fetchPageTemplate();
  }

  navigateToCourse = (id: number) => {
    this.setState({ mode: "course" });
    this.props.changeAcademyPage("course", id);
  };

  navigateToLesson = (chapter_id: number, lesson_id: number) => {
    this.setState({ mode: "lesson" });
    this.props.changeAcademyPage("lesson", chapter_id, lesson_id);
  };

  navigateToQuiz = (chapter_id: number, lesson_id: number, quiz_id: string) => {
    this.setState({ mode: "lesson" });
    this.props.changeAcademyPage("quiz", chapter_id, lesson_id, quiz_id);
  };

  setMode = (mode: string, page_data: any) => {
    this.setState({ mode, page_data });
  };

  render() {
    const courseIndex = 0;
    const isSubscribed =
      this.state?.courses &&
      !!this.state?.courses[courseIndex]?.subscription_status;
    if (!this.state.loaded) {
      return (
        <div className="academy-wrapper section-middle">
          <div className="logo-loader">
            <div className="loader" />
            <img src={Logo} />
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="section-middle academy-wrapper">
          <div className="courses">
            {!isSubscribed ? (
              <>
                <div>
                  <strong
                    style={{
                      fontSize: 36,
                      color: "#249ff0",
                    }}
                  >
                    Level up your influence
                  </strong>
                </div>
                <AcademySubscribe
                  navigateToLesson={this.navigateToLesson}
                  subscribeToCourse={undefined}
                  course_index={courseIndex}
                  courses={this.getCourses}
                  {...this.props}
                />
              </>
            ) : (
              <div className="campaigns-title">
                Humanz <strong>Academy</strong>
              </div>
            )}
          </div>
          {isSubscribed &&
            ((): any => {
              let content;
              switch (this.state.mode) {
                case "home":
                  content = (
                    <AcademyCourses navigateToCourse={this.navigateToCourse} />
                  );
                  break;
                case "course":
                  content = (
                    <AcademySingleCourse
                      getCourses={this.getCourses}
                      id={this.props?.params?.course}
                      navigateToCourse={this.navigateToCourse}
                      navigateToLesson={this.navigateToLesson}
                      navigateToQuiz={this.navigateToQuiz}
                      getSingleCourse={this.props.getSingleCourse}
                    />
                  );
                  break;
                case "lesson":
                  content = (
                    <AcademyLesson
                      getCourses={this.getCourses}
                      navigateToQuiz={this.navigateToQuiz}
                      course_id={this.props?.params?.course}
                      lesson_id={this.props?.params?.lesson}
                      chapter_id={this.props?.params?.chapter}
                      navigateToCourse={this.navigateToCourse}
                      navigateToLesson={this.navigateToLesson}
                    />
                  );
                  break;
                case "quiz":
                  content = (
                    <AcademyQuiz
                      getCourses={this.getCourses}
                      quiz_id={this.props?.params?.quiz_id}
                      current_chapter={this.props?.current_chapter}
                      chapter_id={this.props?.params?.chapter}
                      navigateToCourse={this.navigateToCourse}
                      navigateToLesson={this.navigateToLesson}
                    />
                  );
                  break;
                default:
              }
              return content;
            })()}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    courses: state.academyReducer.courses,
    current_course: state.academyReducer.current_course,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCourses: (last_seen_time: string, filter: string, callback: any) => {
      dispatch(AcademyActions.getCourses(last_seen_time, filter, callback));
    },
    getSingleCourse: (id: number, callback: any) => {
      dispatch(AcademyActions.getCourseTranslation(id, callback));
    },
  };
};

const AcademyConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AcademyInner));

export const Academy = ((props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  return (
    <AcademyConnected
      {...props}
      navigate={navigate}
      params={params}
      location={location}
    />
  );
}) as React.FC<OwnProps>;
