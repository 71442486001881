import axios from "axios";
import { toast } from "react-toastify";
import uuidv4 from "uuid/v4";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";
import { getUserSideDetails } from "./userActions";

const getBrandReportsUrlBase = () => `${getBackendUri()}/moi/brand_report`;

// Email consts= to fix react loopin=>g
const emailsObjectToArray = (oemails) => {
  const emails = [];
  oemails.forEach((email) => emails.push(email.email));
  return emails;
};
const emailsArrayToObject = (aemails) => {
  const emails = [];
  aemails.forEach((email) => emails.push({ id: uuidv4(), email }));
  return emails;
};

export const getAllReports = (callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(getBrandReportsUrlBase(), {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) {
          // if (response.data && response.data.length) {
          //     response.data.forEach(report => {
          //         report.emails = emailsArrayToObject(report.emails);
          //     });
          // }
          callback(response.data);
        }
        dispatch({ type: "GET_BRAND_REPORTS", payload: response.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get reports",
          "system_notifications_failure_get_brand_reports",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false);
        console.error(err);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getStandaloneReport = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(`${getBrandReportsUrlBase()}/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) {
          // eslint-disable-next-line no-param-reassign
          response.data.emails = emailsArrayToObject(response.data.emails);
          callback(response.data);
        }
        dispatch({ type: "GET_STANDALONE_REPORT", payload: response.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get report",
          "system_notifications_failure_get_standalone_report",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false);
        // console.error(err);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const createStandaloneReport = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    const newData = { ...data };
    newData.emails = emailsObjectToArray(newData.emails);
    axios
      .put(getBrandReportsUrlBase(), newData, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
        dispatch(getUserSideDetails());
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.bad_rows) {
          callback(false, err.response.data.bad_rows);
        } else {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to create report",
            "system_notifications_failure_create_standalone_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const editStandaloneReport = (id, data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    const newData = { ...data };
    newData.emails = emailsObjectToArray(newData.emails);
    axios
      .post(`${getBrandReportsUrlBase()}/${id}`, newData, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.bad_rows) {
          callback(false, err.response.data.bad_rows);
        } else {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to edit report",
            "system_notifications_failure_edit_standalone_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getUserProfile = (userID, socialNetwork, callback) => {
  return (dispatch) => {
    // eslint-disable-next-line no-param-reassign
    socialNetwork = socialNetwork || "instagram";
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    const callUrl = `${getBackendUri()}/moi/exact_username_search`;
    axios
      .post(
        callUrl,
        { username: userID, socialNetwork },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.bad_rows) {
          callback(false, err.response.data.bad_rows);
        } else if (callback) callback(false, null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const toggleReport = (report, state, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    // change the "enabled" property state
    // eslint-disable-next-line no-param-reassign
    report.enabled = state;
    axios
      .post(`${getBrandReportsUrlBase()}/${report.id}`, report, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.bad_rows) {
          callback(false, err.response.data.bad_rows);
        } else {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to toggle report",
            "system_notifications_failure_toggle_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const deleteStandaloneReport = (id, callback) => {
  return () => {
    axios
      .delete(`${getBrandReportsUrlBase()}/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to delete report",
          "system_notifications_failure_delete_standalone_report",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false);
      });
  };
};
