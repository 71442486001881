import { Flex } from "@chakra-ui/react";
import React from "react";

export const AboutTab = () => {
  return (
    <div className="create-tab-content" style={{ marginTop: 60 }}>
      <div style={{ textAlign: "center" }}>
        <img style={{ height: 50 }} src={"/images/humanz_text.png"} />
        <div className="about-tab-icons">
          <a
            href="https://www.instagram.com/humanz/"
            target="_blank"
            className="fa fa-instagram"
            rel="noreferrer"
          />
          <a
            href="https://www.tiktok.com/@humanz"
            target="_blank"
            className="fa-brands fa-tiktok"
            rel="noreferrer"
          />
          <a
            href="https://www.facebook.com/humanzglobal"
            target="_blank"
            className="fa fa-facebook"
            rel="noreferrer"
          />
          <a
            href="https://www.youtube.com/channel/UC_Z2NJFeIJ0HGpyQPs13umw"
            target="_blank"
            className="fa fa-youtube-play"
            rel="noreferrer"
          />
          <a
            href="https://www.linkedin.com/company/humanz/"
            target="_blank"
            className="fa fa-linkedin"
            rel="noreferrer"
          />
        </div>
        <Flex justifyContent={"center"} gap={10}>
          <div style={{ marginTop: 10 }}>
            <a
              href="https://www.humanz.ai/PrivacyPolicy.html?origin=https://www.humanz.ai"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
          <div style={{ marginTop: 10 }}>
            <a
              href="https://www.humanz.ai/termsAndConditions.html"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
          </div>
          <div style={{ marginTop: 10 }}>
            <a
              href="https://www.humanz.ai/cookie-policy"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
          </div>
          <div style={{ marginTop: 10 }}>
            <a href="/web-opensources" target="_blank">
              Open source libraries
            </a>
          </div>
        </Flex>
      </div>
    </div>
  );
};
