import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { CSSProperties, ReactNode } from "react";

interface CustomAccordionProps {
  children: ReactNode;
  header: string | any;
  style?: CSSProperties;
  open?: boolean;
  key?: string;
  isShare?: boolean;
  onDelete?: () => void;
  onDuplicate?: () => void;
}

const AccordionUI = ({
  children,
  header,
  style,
  open = true,
  isShare,
  key,
  onDelete,
  onDuplicate,
}: CustomAccordionProps) => {
  return (
    <Accordion
      allowToggle
      defaultIndex={open ? [0] : []}
      style={{ ...style, overflow: "visible" }}
      key={key}
    >
      <AccordionItem
        sx={{
          background: "#EBEBEB",
          borderRadius: "12px",
          boxShadow: "none",
          overflow: "visible",
          "& .chakra-collapse": {
            overflow: "visible !important",
          },
        }}
      >
        {({ isExpanded }) => (
          <div style={{ color: "#808080" }}>
            <>
              <AccordionButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between", // Align elements to the left and right
                  borderRadius: "12px",
                  background: "#EBEBEB",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "10px" }}>
                    <i
                      className={`fa-regular ${
                        isExpanded ? "fa-chevron-up" : "fa-chevron-down"
                      } fa-lg`}
                    />
                  </div>
                  <span style={{ fontSize: "16px", paddingTop: "2px" }}>
                    {header}
                  </span>
                </div>
                {!isShare && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: "10px" }}>
                      {onDuplicate && (
                        <i
                          className="fa-light fa-clone fa-lg"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent accordion toggle
                            onDuplicate();
                          }}
                        ></i>
                      )}
                    </div>
                    {onDelete && (
                      <i
                        className="far fa-trash fa-lg"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent accordion toggle
                          onDelete();
                        }}
                      ></i>
                    )}
                  </div>
                )}
              </AccordionButton>
            </>
            <AccordionPanel
              sx={{
                background: "#FAFAFA",
                color: "black",
                padding: "30px 12px 32px 40px",
              }}
            >
              {children}
            </AccordionPanel>
          </div>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionUI;
