import { CSSProperties } from "react";

export const InfiniteLoader = ({ style }: { style?: CSSProperties }) => (
  <div className="infinite-list-item" style={style}>
    <div className="spinner small" style={{ marginTop: 10, marginBottom: 0 }}>
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  </div>
);
