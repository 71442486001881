import { MessageDialog } from "../../dialogs/MessageDialog";
import { numberWithCommas } from "../../utilities/general";

interface OwnProps {
  user: any;
  payToInfluencer: any;
  onClose: any;
  paymentDialogData: { column: string; influencer: any };
  navigate: any;
}

export default function CampaignPaymentDialog(props: OwnProps) {
  if (props.user.userDetails.sidebar_data.has_credit_card) {
    const { influencer } = props.paymentDialogData;
    const commission = influencer.price_details.calculated_fields.commission_sum
      ? numberWithCommas(
          Number(
            influencer.price_details.calculated_fields.commission_sum.toFixed(
              2,
            ),
          ),
        )
      : 0;
    const vat = influencer.price_details.calculated_fields.vat_sum
      ? numberWithCommas(
          Number(influencer.price_details.calculated_fields.vat_sum.toFixed(2)),
        )
      : 0;
    const currencySymbol = window.translate(
      "",
      `money_symbol_${influencer.price_details.currency}`,
    );
    return (
      <MessageDialog handleClose={props.onClose} icon="fal fa-money-bill">
        <div style={{ textAlign: "center", padding: 20 }}>
          <strong>Notice</strong>
          <div>
            You are about to pay to <strong>{influencer.name}</strong>
            <div className="dialog-payment-details">
              <div>
                <div>MEDIA</div>
                <div>COMMISSION</div>
                <div>VAT</div>
                <strong style={{ fontSize: 18 }}>TOTAL PRICE</strong>
              </div>
              <div>
                <div>
                  {currencySymbol}{" "}
                  {numberWithCommas(influencer.price_details.price)}
                </div>
                <div>
                  {currencySymbol} {commission}
                </div>
                <div>
                  {currencySymbol} {vat}
                </div>
                <strong style={{ fontSize: 18 }}>
                  {currencySymbol}{" "}
                  {numberWithCommas(
                    influencer.price_details.calculated_fields.total,
                  )}
                </strong>
              </div>
            </div>
          </div>
          <button
            style={{ marginTop: 15, fontSize: 18, height: 35, width: 230 }}
            className={"show-content-btn btn btn-primary"}
            onClick={props.payToInfluencer}
          >
            PAY NOW
          </button>
        </div>
      </MessageDialog>
    );
  }
  return (
    <MessageDialog handleClose={props.onClose} icon="fal fa-money-bill">
      <div style={{ textAlign: "center", padding: 20 }}>
        <strong>Notice</strong>
        <div>
          In order to continue you need to upgrade your user to
          &quot;MARKETPLACE USER&quot;
          <br />
          All you need to do is to add your payment method in order to pay for
          the influencers
        </div>
        <button
          style={{ marginTop: 15, fontSize: 18, height: 35, width: 230 }}
          className={"show-content-btn btn btn-primary"}
          onClick={() => props.navigate("/edit-details/payment-method")}
        >
          Take me there
        </button>
      </div>
    </MessageDialog>
  );
}
