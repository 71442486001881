import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Tag,
} from "@chakra-ui/react";
import { Box, IconButton } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TargetTypes } from "src/api/types";
import { formatBigNumbers } from "src/components/feed_components/utilities/general";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import CampaignTargetStep from "./CampaignTargetStep";
import { FormAuctionGoal } from "./CampaignTargetsModal";

interface ICampaignViewTarget {
  headerIcon: string;
  title: string;
  target: FormAuctionGoal;
  handleStartEditing?: () => void;
  currency?: string;
}

const CampaignViewTarget = ({
  headerIcon,
  title,
  target,
  handleStartEditing,
  currency,
}: ICampaignViewTarget) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTargetCompleted, setIsTargetCompleted] = useState(false);
  // const [sumKpi, setSumKpi] = useState(0);
  const { board } = useSelector((state: any) => state.boardReducer);
  useEffect(() => {
    const completedSteps = target?.steps.filter((step) => {
      return step.completed_at ? step : null;
    });

    setIsTargetCompleted(completedSteps.length === target.steps.length);
  }, []);

  // useEffect(() => {
  //   const kpiSum = target?.steps.reduce((acc, currentKpi) => {
  //     const sum = acc + currentKpi.target_value?.value;
  //     return sum;
  //   }, 0);

  //   setSumKpi(kpiSum);
  // }, [target]);

  const renderTargetStatus = (endTime?: string) => {
    const formated = moment(endTime).format("MMMM YYYY");
    const isExpired = !moment(endTime).isAfter(moment());

    if (!endTime || target?.renew) {
      return (
        <>
          <Tag colorScheme={"blue"}>
            <i className="far fa-arrows-spin" style={{ marginRight: 2 }} />
            Recurring
          </Tag>
        </>
      );
    }

    if (isExpired) {
      return (
        <>
          <Box
            component="i"
            className="fa-sharp-duotone fa-solid fa-calendar-days"
          />
          <Box component="p" style={{ opacity: 0.6 }}>
            Expired
          </Box>
          <Box component="p">{formated}</Box>
        </>
      );
    } else {
      return (
        <>
          <Box className="fa-sharp-duotone fa-solid fa-calendar-days" />
          <Box component="p" style={{ opacity: 0.6 }}>
            Due
          </Box>
          <Box component="p">{formated}</Box>
        </>
      );
    }
  };

  const renderInfluencerActions = () => {
    const isCurrentMonth =
      board?.one_month_campaign ||
      moment(board?.current_manage_month).isSame(moment(), "month");
    if (!isCurrentMonth) {
      return null;
    }
    const actions = [];
    actions.push({
      action: handleStartEditing,
      text: "Edit Target",
      icon: `fa-solid fa-pen-to-square`,
    });

    return (
      <Box>
        <HumanzActionsMenu
          buttontype="dots"
          placement="bottomRight"
          actions={actions}
        />
      </Box>
    );
  };
  const lastStepValue =
    target?.steps[target.steps.length - 1].target_value.value;

  let progress = (target.current_metric_value / lastStepValue) * 100;
  if (progress >= 100) {
    progress = 100;
  }

  return (
    <Accordion allowToggle className="target-accordion">
      <AccordionItem
        style={{
          borderRadius: "12px",
          border: isTargetCompleted ? "1px solid #99ddd8" : null,
          backgroundColor: isTargetCompleted ? "#f1f8f8" : null,
        }}
      >
        <AccordionButton
          onClick={() => setIsExpanded((prev) => !prev)}
          className="target-accordion__summary"
          style={{ minHeight: "52px", height: "52px", color: "#333333" }}
        >
          <Box className="campaign-view-target__container">
            <Box className="campaign-view-target__header-container">
              <Box className={headerIcon} />
              <Box component="p" className="campaign-view-target__title">
                {title}
              </Box>
            </Box>
            <Box className="campaign-view-target__progressbar-container">
              <Flex justifyContent={"center"} gap={1} alignItems={"center"}>
                <Box
                  component="p"
                  className="campaign-view-target-progressbar-container__amount"
                >
                  0
                </Box>
                <Box
                  className="campaign-view-target__progressbar"
                  sx={{ position: "relative" }}
                >
                  <Box
                    component="p"
                    className="campaign-view-target-progressbar-container__amount_bar"
                    sx={{
                      position: "absolute",
                      textAlign: "right",
                      maxWidth: "179px",
                      width: `${progress}%` || 0,
                    }}
                  />
                  <Box
                    className="campaign-view-target-progressbar__scale"
                    sx={{
                      maxWidth: "179px",
                      height: "8px",
                      width: `${progress}%` || 0,
                    }}
                  />
                </Box>
              </Flex>

              {isTargetCompleted ? (
                <Box className="fa-solid fa-check fa-xs campaign-target-step__check-icon" />
              ) : (
                <Box
                  component="p"
                  className="campaign-view-target-progressbar-container__amount"
                >
                  {formatBigNumbers(Number(lastStepValue))}
                </Box>
              )}
            </Box>
          </Box>
          <Box className="campaign-view-target__header-container">
            {((target.target_type === TargetTypes.one_time &&
              target.end_time?.value) ||
              target?.renew) && (
              <Box
                className={`campaign-view-target__status campaign-view-target__status_${
                  moment(target.end_time?.value).isAfter(moment()) ||
                  target.target_type === TargetTypes.monthly
                    ? TargetTypes.monthly
                    : "expired"
                }`}
              >
                {renderTargetStatus(target.end_time?.value)}
              </Box>
            )}
            <IconButton
              size="small"
              className="target-accordion-summary__delete-button"
              onClick={(e) => e.preventDefault()}
            >
              {renderInfluencerActions()}
            </IconButton>
            <IconButton
              size="small"
              className="target-accordion-summary__delete-button"
            >
              {isExpanded ? (
                <Box component="div" className="fa-solid fa-angle-up" />
              ) : (
                <Box component="div" className="fa-solid fa-angle-down" />
              )}
            </IconButton>
          </Box>
        </AccordionButton>
        <AccordionPanel className="target-accordion__details">
          <Box className="target-accordion__steps">
            <Box
              className="target-accordion-steps__title campaign-target-step__title"
              sx={{ marginBottom: "16px" }}
            >
              <Box component="i" className="fa-solid fa-stairs" />
              <Box component="p" className="campaign-target-step__title">
                Steps
              </Box>
            </Box>
            <Box className="target-accordion__steps-container">
              {target?.steps.map((step, index) => (
                <CampaignTargetStep
                  key={step.id || step.target_value.value}
                  stepIndex={index + 1}
                  isView
                  step={{
                    ...step,
                    target_value: {
                      value: step.target_value?.value,
                      error: "",
                    },
                  }}
                  isCompleted={Boolean(step.completed_at)}
                  currentKpi={target.current_metric_value}
                  steps={target?.steps.slice(0, index)}
                  currency={currency}
                  currentTarget={target}
                />
              ))}
            </Box>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default CampaignViewTarget;
