import { Box } from "@chakra-ui/react";

const Notes = () => {
  return (
    <Box>
      <Box
        sx={{
          margin: 1,
          p: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_40_38353)">
            <circle
              cx="8.00016"
              cy="7.99992"
              r="6.66667"
              stroke="#00A99D"
              strokeWidth="1.5"
            />
            <path
              d="M5.6665 8.33325L6.99984 9.66659L10.3332 6.33325"
              stroke="#00A99D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_40_38353">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span
          style={{
            marginLeft: "8px",
            color: "var(--black-1, #333)",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "17.143px",
          }}
        >
          Manage all your tasks in one place
        </span>
      </Box>
      <Box
        sx={{
          margin: 1,
          p: 1,
          display: "flex",
          alignItems: "center",
          width: "254px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_40_38356)">
            <circle
              cx="8.00016"
              cy="7.99992"
              r="6.66667"
              stroke="#F56565"
              strokeWidth="1.5"
            />
            <path
              d="M9.66682 6.33327L6.3335 9.66659M6.33348 6.33325L9.6668 9.66657"
              stroke="#F56565"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_40_38356">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span
          style={{
            marginLeft: "8px",
            color: "var(--black-1, #333)",

            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "17.143px",
          }}
        >
          No need in Excel
        </span>
      </Box>
      <Box
        sx={{
          margin: 1,
          p: 1,
          display: "flex",
          alignItems: "center",
          width: "230px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
          style={{ flexShrink: 0 }}
        >
          <g clipPath="url(#clip0_40_38353)">
            <circle
              cx="8.00016"
              cy="7.99992"
              r="6.66667"
              stroke="#00A99D"
              strokeWidth="1.5"
            />
            <path
              d="M5.6665 8.33325L6.99984 9.66659L10.3332 6.33325"
              stroke="#00A99D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_40_38353">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span
          style={{
            // marginLeft: "8px",
            color: "var(--black-1, #333)",

            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "17.143px",
          }}
        >
          Transfer Your Excel template by choosing an existing template
        </span>
      </Box>
    </Box>
  );
};

export default Notes;
