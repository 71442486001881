/* eslint-disable no-restricted-syntax */
import {
  Box,
  Flex,
  SkeletonCircle,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import Dialog from "rc-dialog";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { getBoardDetails } from "src/actions/auctionsActions";
import { toggleInfluencersNextMonth } from "src/api/actions/auctionActions";
import { AuctionQuestionWithSelected } from "src/api/ExtendedTypes";
// eslint-disable-next-line import-access/jsdoc
import {
  InfluencerInManageAuctionData,
  ManageAuctionState,
  ShareNotesSummary,
} from "src/api/types";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import HumanzDismissableAlert from "src/ui/humanz/HumanzDismissableAlert";
import { SearchBar } from "src/ui/humanz/SearchBar";
import EditTasksDialog from "../../dialogs/EditTasksDialog/EditTasksDialog";
import CampaignPreviewDialog from "../dialogs/CampaignPreviewDialog";
import RateInfluencerDialog from "../dialogs/RateInfluencerDialog";
import { CampaignInfluencerTags } from "./CampaignInfluencerTags";
import { CampaignManagerInfluencers } from "./CampaignManagerInfluencers";
import { Columns, manageColumns } from "./CampaignManagerInterfaces";
import CampaignTrashMultiDialog from "./CampaignTrashMultiDialog";

type Props = {
  openAlarmDialog: any;
  openChatsAndContent: any;
  share: any;
  showCost: any;
  negotiateInfluencerBid: any;
  openInfluencerSegmentations: any;
  getInfluencerData: any;
  acceptInfluencerBid: any;
  changeInfluencerNoteOnBoard: any;
  updateBoardDetails: any;
  user: any;
  responseForInfluencerCounterOffer: any;
  markTaskInvitesAsSeen: any;
  currencyLabel: any;
  editTasks: any;
  sendTaskInviteResponse: any;
  onClose: any;
  sendToTrash: any;
};

const PlanNextMonthDialog = (props: Props) => {
  const board: any = useSelector((store) => store.boardReducer);
  const currentBoard = board.board;
  const nextMonthBoard = board.nextMonth;

  const {
    openAlarmDialog,
    openChatsAndContent,
    share,
    showCost,
    negotiateInfluencerBid,
    openInfluencerSegmentations,
    getInfluencerData,
    acceptInfluencerBid,
    changeInfluencerNoteOnBoard,
    updateBoardDetails,
    user,
    responseForInfluencerCounterOffer,
    markTaskInvitesAsSeen,
    currencyLabel,
    sendTaskInviteResponse,
    editTasks,
    onClose,
  } = props;

  const [influencersSelected, setInfluencersSelected] = useState([]);
  const [setOpenAttachLinks] = useState<any>(false);
  const [searchLoader] = useState(false);
  const [waitingNotes] = useState<ShareNotesSummary>({});
  const [openProposals, setOpenProposals] = useState(null);

  const [questions] = useState<AuctionQuestionWithSelected[]>([]);
  const [editTasksData, setEditTasksData] = useState(null);
  const [negotiate, setNegotiate] = useState(null);
  const [search, setSearch] = useState(null);
  const [filteredResult] = useState(null);
  const [tagsFilter] = useState([]);
  const [columns, setColumns] = useState<ManageAuctionState[]>([]);
  const [selectedColumn] = useState<Columns>(Columns.approval);
  const [showDeleteInfluencersDialog, setShowDeleteInfluencersDialog] =
    useState(false);
  const influencerRating = useRef<any>();
  const dispatch = useDispatch();
  const toast = useToast();
  // const navigate = useNavigate();

  const filterInfluencer = useCallback(
    (influencer: InfluencerInManageAuctionData) => {
      if (!influencer) {
        return false;
      }
      if (
        filteredResult &&
        !filteredResult.includes(influencer.influencer_id)
      ) {
        return false;
      }

      let hasTags = true;
      if (tagsFilter?.length > 0) {
        hasTags = false;
        const influTags =
          currentBoard && currentBoard.tags
            ? currentBoard.tags.filter((x: any) =>
                x.influencers.includes(influencer.influencer_id),
              )
            : [];
        if (!influTags || influTags.length === 0) return false;
        for (const tag of influTags) {
          if (tagsFilter?.includes(tag.label)) {
            hasTags = true;
            break;
          }
        }
      }

      if (!hasTags) return false;
      return true;
    },
    [filteredResult, tagsFilter, currentBoard],
  );

  const renderInfluencerSearch = () => {
    return (
      <div className={`search-field ${search?.length ? "active" : ""}`}>
        <SearchBar
          onSearch={(e: any) => {
            setSearch(e.target.value.toLowerCase());
          }}
        />
      </div>
    );
  };

  const filterBySearch = useCallback(
    (_search: string, influencer: InfluencerInManageAuctionData) => {
      // Data filters
      if (!filterInfluencer(influencer)) return false;

      if (!_search) return true;

      // Search name/tag filters
      const searchKey = _search?.replace("tag:", "")?.trim();
      if (influencer.tags) {
        for (const tag of influencer.tags) {
          if (tag?.toLowerCase()?.indexOf(searchKey) > -1) {
            return true;
          }
        }
      }

      if (search.includes("tag:")) return false;

      if (!search || search === "") {
        return true;
      }
      if (search === "need_payment") {
        return influencer.payment_status !== "paid";
      }
      if (
        influencer.name &&
        influencer.name.toLowerCase().indexOf(search) > -1
      ) {
        return true;
      }
      const social: any = influencer.social_network_data;
      for (const socialNetwork of ["instagram", "twitter", "youtube"]) {
        const currentNetworkData = social[socialNetwork];
        if (
          currentNetworkData &&
          currentNetworkData.username &&
          currentNetworkData.username.toLowerCase().indexOf(search) > -1
        ) {
          return true;
        }
      }
      return false;
    },
    [filterInfluencer, search],
  );

  const selectInfluencer = useCallback(
    (ids: any) => {
      let newInfluencersSelected: number[] = [...influencersSelected];
      if (ids.length > 1 && ids.length === influencersSelected.length) {
        newInfluencersSelected = [];
      } else if (ids.length > 1) {
        newInfluencersSelected = ids;
      } else {
        ids.forEach((id: any) => {
          newInfluencersSelected.addOrRemove(id);
        });
      }
      setInfluencersSelected(newInfluencersSelected);
    },
    [influencersSelected],
  );

  const renderCampaignActions = () => {
    const disableStopWorking = columns[selectedColumn]?.influencers?.some(
      (i) => i.start_from,
    );
    return influencersSelected?.length &&
      columns[selectedColumn]?.influencers.length ? (
      <HumanzActionsMenu
        style={{ width: 120, borderRadius: 20, marginLeft: 8 }}
        buttontype="button"
        buttontext=" Actions "
        variant={"solid"}
        trigger={["hover"]}
        onClick={() => {}}
        actions={[
          !!influencersSelected?.length &&
            columns[selectedColumn]?.influencers.length && {
              action: () => {
                setEditTasksData(columns[selectedColumn]?.influencers);
              },
              text: "Add Tasks to selected influencers (soon)",
              icon: "fas fa-tasks",
              disabled: true,
            },
          {
            action: () => {
              setShowDeleteInfluencersDialog(true);
            },
            text: "Stop working with selected influencers",
            icon: "fal fa-trash",
            disabled: disableStopWorking,
          },
        ]}
      />
    ) : null;
  };

  const getConcatColumn = (data: any, columnId: number, filter?: any) => {
    const column: ManageAuctionState = {
      notifications: 0,
      influencers: data
        ? data?.states[manageColumns[columnId].name].influencers
        : [],
      count: data ? data?.states[manageColumns[columnId].name]?.length : 0,
    };
    // if (column.influencers && column.influencers.length > 0) {
    //   column.influencers = sortInfluencers(column.influencers, sortKey);
    // }
    // if (column.influencers.length > 0 && filter) {
    //   const avgReview = filters?.find(
    //     (f: any) => f.name === "avg_review",
    //   ).selected;
    column.influencers = column?.influencers?.filter((x: any) =>
      filterBySearch(search?.trim(), x),
    );
    //   column.influencers = column.influencers.filter((x: any) =>
    //     filterByStars(avgReview, x),
    //   );
    // }
    console.log("filter", filter);
    console.log("column", column);
    column.count = column.influencers?.length ?? 0;
    return column;
  };

  const updateNextMonthData = () => {
    dispatch(getBoardDetails(currentBoard.board_id, "next"));
  };

  const updateColumns = useCallback(() => {
    console.log("nextMonthBoard", nextMonthBoard);
    setColumns([
      null,
      null,
      getConcatColumn(nextMonthBoard, Columns.approval, true),
      null,
      null,
    ]);
  }, [search, filteredResult, nextMonthBoard]);

  useEffect(() => {
    console.log("NEXT", nextMonthBoard);
  }, [nextMonthBoard]);

  useEffect(() => {
    updateColumns();
  }, [filteredResult, search, board]);

  useEffect(() => {
    updateNextMonthData();
  }, []);
  const renderCampaignNavigationAndFilters = () => {
    return (
      <>
        <div className="flex">
          <Box flex={1} alignSelf="center">
            <h3
              style={{
                color: "#808080",
                fontSize: 16,
                margin: 0,
                padding: 0,
              }}
            >
              {nextMonthBoard?.states[manageColumns[selectedColumn].name]
                .influencers?.length || 0}{" "}
              Influencers
            </h3>
          </Box>
          <div className="campaign-header-right">
            {/* <Button
              style={{ justifySelf: "flex-start", marginTop: 4, height: 34 }}
              onClick={
                // navigate to list
                () => {
                  navigate(`/campaign/${currentBoard.board_id}/list`);
                }
              }
              leftIcon={
                // list add icon
                <i className="fal fa-user-plus" />
              }
              variant={"outline"}
            >
              Jump to list
            </Button> */}
            <div
              className="manage-navigation"
              style={{ padding: "0 15px", background: "none" }}
            >
              {influencersSelected?.length ? (
                <div
                  className="single-navigation-block"
                  style={{ marginRight: 10 }}
                >
                  <div className="gray-circle-icon">
                    <CampaignInfluencerTags
                      board={currentBoard}
                      selectedInfluencers={influencersSelected}
                      boardTags={currentBoard.tags}
                      influencerId={-1}
                      onUpdate={() => updateColumns()}
                    />
                  </div>
                </div>
              ) : null}
              <div className="single-navigation-block">
                {renderInfluencerSearch()}
              </div>
              <div className="single-navigation-block">
                {/* {renderCampaignSorting()} */}
              </div>
              <div className="single-navigation-block">
                {renderCampaignActions()}
              </div>
              <div className="single-navigation-block">
                <div
                  className="checkbox"
                  style={{ padding: 0, margin: 0, marginTop: 6 }}
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={
                        influencersSelected?.length &&
                        columns[selectedColumn]?.influencers.length ===
                          influencersSelected?.length
                      }
                      onChange={() =>
                        selectInfluencer(
                          columns[selectedColumn]?.influencers
                            .map(
                              (x: InfluencerInManageAuctionData) =>
                                x?.influencer_id,
                            )
                            .filter((i: any) => i),
                        )
                      }
                    />
                    <span className="checkbox-material">
                      <span className="check"></span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        maskClosable={false}
        onClose={onClose}
        className="plan-next-month-dialog"
        visible={true}
        style={{
          width: "70vw",
          minWidth: 1024,
          margin: "28px auto",
        }}
        animation="slide-fade"
        maskAnimation="fade"
        keyboard={true}
      >
        <Box margin={"0 auto"} pl={1} maxWidth={888}>
          <Flex>
            <Flex alignItems="flex-start" flex={1}>
              <h1 style={{ fontSize: "x-large" }}>{"Plan next month"}</h1>
              <span
                style={{
                  marginTop: "20px",
                  marginLeft: "20px",
                  fontSize: "18px",
                  fontWeight: 400,
                  color: "#808080",
                }}
              >
                {moment(currentBoard.current_manage_month)
                  .add(1, "month")
                  .format("MMM YYYY")}
              </span>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" alignItems={"center"}>
            <Box>
              <h2 style={{ marginTop: "auto" }} className="blurable subtitle">
                {currentBoard.summary.pixel_enabled ? (
                  <i className="fa-solid fa-badge-dollar sales-icon" />
                ) : null}{" "}
                {currentBoard.summary.name}
              </h2>
            </Box>
          </Flex>
          <HumanzDismissableAlert
            title="Notice"
            description='Any changes for an influencer that will be made in this
                      board will be applied to the next and all following
                      months. Editing the influencer tasks here will disable the
                      "recurring" feature for this influencer in
                      current month tasks editing.'
            icon={<i className="fad fa-exclamation-triangle" />}
            open={true}
            status="warning"
            dismissable={true}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className="campaigns-section-main">
              <Flex
                className="main-middle-container"
                style={{
                  padding: 10,
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {!nextMonthBoard ? (
                  <Box
                    style={{
                      width: "100%",
                      maxWidth: 780,
                      marginTop: 80,
                    }}
                  >
                    {[0, 1, 2, 3, 4].map((a: any) => (
                      <Box
                        key={a}
                        className="influencer"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Box
                          padding="6"
                          borderRadius={6}
                          boxShadow="lg"
                          bg="white"
                          mb={"10px"}
                        >
                          <Flex>
                            <SkeletonCircle mr={20} size="20" />
                            <SkeletonText
                              flex={1}
                              mt="4"
                              noOfLines={3}
                              spacing="4"
                              skeletonHeight="2"
                            />
                          </Flex>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box w={"100%"}>
                    {/* Top Details */}
                    {renderCampaignNavigationAndFilters()}
                    {/* End Top Details */}

                    {searchLoader ? (
                      <div className="logo-loader">
                        <div className="loader" />
                        <img src={"/images/humanz-logo.png"} />
                      </div>
                    ) : (
                      <CampaignManagerInfluencers
                        questions={questions}
                        selectedColumn={selectedColumn}
                        column={columns[selectedColumn]}
                        setEditTasksData={setEditTasksData}
                        openAlarmDialog={openAlarmDialog}
                        openChatsAndContent={openChatsAndContent}
                        influencerRating={influencerRating}
                        influencersSelected={influencersSelected}
                        sendToTrash={props.sendToTrash}
                        share={share}
                        showCost={showCost}
                        negotiate={negotiate}
                        setNegotiate={setNegotiate}
                        waitingNotes={waitingNotes}
                        negotiateInfluencerBid={negotiateInfluencerBid}
                        selectInfluencer={selectInfluencer}
                        setOpenAttachLinks={setOpenAttachLinks}
                        openInfluencerSegmentations={
                          openInfluencerSegmentations
                        }
                        getInfluencerData={getInfluencerData}
                        acceptInfluencerBid={acceptInfluencerBid}
                        changeInfluencerNoteOnBoard={
                          changeInfluencerNoteOnBoard
                        }
                        updateBoardDetails={updateBoardDetails}
                        board={nextMonthBoard}
                        user={user}
                        updateColumns={updateColumns}
                        responseForInfluencerCounterOffer={
                          responseForInfluencerCounterOffer
                        }
                        nextMonthMode={true}
                        useWindowAsScrollContainer={false}
                        lastMonth={false}
                        openTargetsModal={undefined}
                        month={moment(currentBoard.current_manage_month)
                          .add(1, "month")
                          .toISOString()}
                      />
                    )}
                  </Box>
                )}
              </Flex>
            </div>
          </div>
        </Box>
      </Dialog>

      {showDeleteInfluencersDialog && (
        <CampaignTrashMultiDialog
          callback={() => {}}
          influencersSelected={influencersSelected}
          influencers={
            nextMonthBoard.states[manageColumns[selectedColumn].name]
              .influencers
          }
          sendToTrash={() => {
            toggleInfluencersNextMonth(nextMonthBoard.summary.id, {
              influencers_ids: influencersSelected,
              stop: true,
            })
              .then(() => {
                updateNextMonthData();
              })
              .catch((e) => {
                console.log(e);
                toast({
                  title: e?.error || "Error",
                  status: "error",
                  duration: 1000,
                  isClosable: false,
                });
              })
              .finally(() => {
                setShowDeleteInfluencersDialog(false);
              });
          }}
          onClose={() => {
            setShowDeleteInfluencersDialog(false);
          }}
        />
      )}

      {editTasksData && (
        <EditTasksDialog
          mode="next_month"
          markSeenTaskInvites={markTaskInvitesAsSeen}
          sendInviteCounterResponse={sendTaskInviteResponse}
          currencyLabel={currencyLabel}
          onSave={editTasks}
          board={currentBoard}
          influencer={editTasksData}
          handleClose={() => setEditTasksData(null)}
          month={moment(currentBoard.current_manage_month)
            .add(1, "month")
            .toISOString()}
        />
      )}
      {openProposals !== null && (
        <CampaignPreviewDialog
          newOnly={false}
          waitingNotes={waitingNotes}
          shareGuid={nextMonthBoard.share_guid}
          allowedUploadContent={
            user && user.userDetails && user.userDetails.sidebar_data
              ? user.userDetails.sidebar_data.allowed_upload_content
              : false
          }
          updateColumns={updateColumns}
          columns={columns}
          handleClose={() => {
            setOpenProposals(null);
          }}
          influencer_id={openProposals}
          isShowingModal={openProposals !== null}
          boardId={nextMonthBoard.summary.id}
          shareActive={nextMonthBoard.summary.shared_campaign}
          share={props.share}
          onlyChat={
            selectedColumn === Columns.invite ||
            selectedColumn === Columns.bids ||
            selectedColumn === Columns.trash
          }
        />
      )}
      <RateInfluencerDialog ref={influencerRating} isShowingModal={true} />
    </>
  );
};

export default PlanNextMonthDialog;
