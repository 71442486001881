import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTodoItems } from "src/api/actions/auctionActions";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import ManageToDo from "./ManageToDo";
import TodoButton from "./TodoButton";

const TodoList = (props: any) => {
  const [tasks, setTasks] = useState<AuctionToDoItem[]>([]);
  const [completedTasks, setCompletedTasks] = useState<AuctionToDoItem[]>([]);
  const params = useParams();
  const campaignId = params?.id;
  useEffect(() => {
    if (campaignId) {
      const completeTasks = tasks.filter((task) => task.completed);
      setCompletedTasks(completeTasks);
    }
  }, [campaignId, tasks]);
  useEffect(() => {
    if (campaignId) {
      getTodoItems(Number(campaignId))
        .then((res) => {
          if (res?.data.items) {
            setTasks(res.data.items);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [campaignId]);

  const handleUpdateTodoButton = (
    i_CompletedTasks: AuctionToDoItem[],
    i_Tasks: AuctionToDoItem[],
  ) => {
    setCompletedTasks(i_CompletedTasks);
    setTasks(i_Tasks);
  };

  return (
    <>
      <div id="flow_indicator">
        <TodoButton
          onClick={props.setShowTodoList}
          completedTasks={completedTasks}
          tasks={tasks}
        />
      </div>

      {props.isOpen && (
        <ManageToDo
          isOpen={props.isOpen}
          setShowTodoList={props.setShowTodoList}
          handleUpdateTodoButton={handleUpdateTodoButton}
        />
      )}
    </>
  );
};

export default TodoList;
