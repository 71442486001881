import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getListFormData,
  getMoreListAnswers,
} from "src/api/actions/listActions";
import { SwaggerResponse } from "src/api/config";
import {
  AuctionQuestion,
  AuctionQuestions,
  ListFormAnswer,
} from "src/api/types";
import DataTable from "../general_components/DataTable";

const answersAmount = 100;

interface OwnProps {
  listId: number;
}

interface ListFormResponse {
  answers?: ListFormAnswer[];
  form_uid?: string;
  questions?: AuctionQuestions;
  total_answers?: number;
}

export const InfluencersListForm = (props: OwnProps) => {
  const [data, setData] = useState({} as ListFormResponse);
  const [hasMoreAnswers, setHasMoreAnswers] = useState(true as boolean);
  const [isLoading, setIsLoading] = useState(false as boolean);
  useEffect(() => {
    setIsLoading(true);
    getListFormData(props.listId)
      .then((res: SwaggerResponse<ListFormResponse>) => {
        setData(res?.data);
        if (res.data.total_answers === answersAmount) {
          setHasMoreAnswers(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setData({});
      });
  }, [props.listId]);

  const loadMore = () => {
    setIsLoading(true);
    getMoreListAnswers(props.listId, {
      amount: answersAmount,
      form_uuid: data.form_uid,
      last_seen_time:
        data?.answers && data?.answers.length
          ? data?.answers[data.answers.length - 1]?.answer_time
          : null,
    })
      .then((res: SwaggerResponse<ListFormResponse>) => {
        if (res.data.answers.length) {
          const newData = { ...data };
          newData.answers = newData.answers.concat(res.data.answers);
          setData(newData);
          setHasMoreAnswers(res.data.answers.length === answersAmount);
        } else {
          setHasMoreAnswers(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const columns = React.useMemo<ColumnDef<AuctionQuestion>[]>(() => {
    const columnsData: any = [];
    const staticFields: any = [
      {
        accessorKey: "time",
        header: "Time",
        accessorFn: (row: any) => {
          return moment(row.answer_time).format(window.time.date_with_hour);
        },
      },
      { accessorKey: "email", header: "Email" },
      { accessorKey: "phone", header: "Phone" },
    ];
    staticFields.forEach((sf: any) => {
      columnsData.push({
        accessorKey: sf.accessorKey,
        header: sf.header,
        cell: (info: any) => info.getValue(),
        accessorFn: (row: any) => {
          return sf?.accessorFn ? sf.accessorFn(row) : row[sf.accessorKey];
        },
      });
    });
    if (data?.questions) {
      data.questions.forEach((question: AuctionQuestion) => {
        columnsData.push({
          accessorKey: question?.id,
          header: question?.question,
          cell: (info: any) => info.getValue(),
          accessorFn: (row: any) => {
            const answer = row.question_answers.find(
              (a: any) => a.id === question?.id,
            );
            if (!answer) return null;
            return answer?.answer;
          },
        });
      });
    }
    return columnsData;
  }, [data]);

  return (
    <div className="list-forms">
      <div
        className="list-title blurable-large "
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 0,
        }}
      >
        Form Entries {data.total_answers ? `(${data.total_answers})` : null}
      </div>
      {data.questions ? (
        <DataTable
          containerClassName="invoice-table"
          containerProps={{
            dir: "auto",
            bg: "white",
            maxWidth: "100%",
            boxShadow: "0 5px 10px rgba(0, 0, 0, 0.15)",
            width: "100%",
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
          }}
          tableContainerProps={{
            maxH: "calc(100vh - 320px)",
            style: { overflow: "auto" },
          }}
          data={data.answers || []}
          columns={columns || []}
          pageSizeAmount={99999999}
          handleInfinitLoad={hasMoreAnswers ? loadMore : null}
          isPagingLoading={isLoading}
          isInfinit={true}
          containerHeight={window.innerHeight - 250}
          rowHeight={47}
        />
      ) : null}
    </div>
  );
};
