// TODO - lint
/* eslint-disable @typescript-eslint/naming-convention, no-cond-assign, no-param-reassign, no-param-reassign, no-param-reassign, no-nested-ternary, no-cond-assign, @typescript-eslint/ban-ts-comment, no-nested-ternary, eqeqeq, no-nested-ternary, class-methods-use-this, consistent-return, import/no-default-export */
import React from "react";
import { Trans, TranslationProps, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  getShareBoardDetails,
  getSharedProposalsHistory,
  responseToShareProposal,
} from "../../../actions/shareActions";
import {
  InfluencerInManageAuctionData,
  ManageAuctionData,
  PostSubTypes,
  ProposalData,
} from "../../../models/Api";
import { SideNotes } from "./SideNotes";

interface OwnProps {
  id: string;
}

type Props = OwnProps & { navigate: NavigateFunction } & ReturnType<
    typeof mapStateToProps
  > &
  ReturnType<typeof mapDispatchToProps>;

interface State {
  influencer?: InfluencerInManageAuctionData;
  loadingProposals?: boolean;
  loading?: boolean;
  selectedStatus?: string;
  activeTab?: any;
  proposals: Array<Proposal>;
  oldProposals?: any;
  selectedProposal: number;
  selectedUpload: number;
  loadingResponse?: boolean;
  openProposalNote?: boolean;
}

type PostType = "ig_post" | "ig_story" | "tk_video" | "yt_video" | "tw_tweet";

interface Proposal extends ProposalData {
  task_id: number;
  new_proposal?: ProposalData;
  description?: string;
  post_type: PostType;
  task_completed?: boolean;
  post_sub_type?: PostSubTypes;
  uploads: NonNullable<ProposalData["uploads"]>;
  semi_approve?: boolean;
}

type Upload = Proposal["uploads"][-1];

class ShareContentsPage extends React.Component<
  Props,
  State,
  TranslationProps
> {
  state: State = {
    selectedProposal: -1,
    selectedUpload: 0,
    loading: true,
    proposals: [],
  };

  POST_TYPES = {
    ig_post: "Instagram Post",
    ig_story: "Instagram Story",
    tw_tweet: "Twitter Tweet",
    yt_video: "YouTube Video",
    tk_video: "TikTok Video",
  };

  componentDidMount() {
    const influencer = this.props.board?.states?.approval?.influencers?.find(
      (x) => x.influencer_id === Number(this.props.id),
    );
    if (influencer && this.props.board) {
      this.setState({ influencer });
      getSharedProposalsHistory(influencer?.influencer_id, this.callback);
    } else {
      window.location.href = window.location.href.replace(this.props.id, "");
    }
  }

  callback = (data: any) => {
    const { influencer } = this.state;
    if (data && influencer) {
      const proposals = data
        .filter((x: any) => !x.subtask_of)
        .map((x: any) => {
          const task = influencer.tasks?.find((i) => i.id === x.task_id);
          const taskCompleted = task?.completed;
          const subType = task?.post_sub_type;
          let new_proposal: Proposal;
          if (x.current_proposal) {
            new_proposal = {
              ...x.current_proposal,
              task_id: x.task_id,
              post_type: x.post_type,
              task_completed: taskCompleted,
              post_sub_type: subType,
            };
          } else {
            new_proposal = {
              ...x,
              post_sub_type: subType,
              new_proposal: {
                uploads: [],
                post_type: x.post_type,
                text: "",
                post_sub_type: subType,
              },
            };
          }
          return new_proposal;
        });
      let selectedStatus = "";
      let index;
      if (
        proposals &&
        (index = proposals.findIndex(
          (x: any) => x.approved === null && !x.task_completed,
        )) > -1
      ) {
        selectedStatus = "waiting";
      } else {
        index = "0";
        selectedStatus = "all";
      }
      index = index.toString();
      this.setState({
        loading: false,
        selectedStatus,
        activeTab: index,
        proposals,
        oldProposals: data
          .filter((x: any) => x.old_proposals)
          .map((x: any) => {
            x.old_proposals.task_id = x.task_id;
            return x.old_proposals;
          }),
      });
    }
  };

  onBack = () => {
    if (this.state.openProposalNote) {
      this.setState({ openProposalNote: false });
      return;
    }
    if (this.state.loadingResponse) return;
    if (this.state.selectedProposal > -1) {
      if (this.state.selectedUpload) {
        this.setState({ selectedUpload: this.state.selectedUpload - 1 });
      } else {
        this.setState({ selectedProposal: -1 });
      }
    } else {
      this.props.navigate(-1);
    }
  };

  responseToProposal(proposal: any, accepted: boolean) {
    if (this.state.loadingResponse) return;
    let textRemark = null;
    let imageRemark = null;
    if (!accepted) {
      textRemark = (
        document.getElementById("text_comments") as HTMLInputElement
      )?.value;
      imageRemark = (
        document.getElementById("media_comments") as HTMLInputElement
      )?.value;
    }
    const response = {
      accepted,
      text_remark: textRemark,
      image_remark: imageRemark,
      images_feedback: proposal.uploads
        ? proposal.uploads.map((x: any) => {
            return { feeback: x.approved, upload_id: x.upload_id };
          })
        : [],
    };

    const propsProposal = this.state.influencer?.tasks
      ?.filter((x) => x.proposal && !x.subtask_of)
      .map((x) => x.proposal)
      .find((x) => x !== undefined && x.id === proposal.id);

    this.setState({ loadingResponse: true });

    responseToShareProposal(proposal.id, response, () =>
      this.responseCallback(proposal, propsProposal, accepted),
    );
  }

  responseCallback(proposal: any, propsProposal: any, accepted: boolean) {
    const { proposals } = this.state;
    const stateProposal = this.state.proposals?.find(
      (x) => x.id === proposal.id,
    );
    if (stateProposal) {
      stateProposal.approved = accepted;
      stateProposal.response_at = new Date().toISOString();
    }
    if (propsProposal) {
      propsProposal.uploads = proposal.uploads;
      propsProposal.approved = accepted;
    }
    this.setState({
      loadingResponse: false,
      proposals,
      selectedProposal: -1,
      selectedUpload: 0,
    });
  }

  render() {
    return (
      <div className="contents-approval" dir={"auto"}>
        {this.state.loading ? (
          <div className="logo-loader">
            <div className="loader" />
            <img src={"/images/humanz-logo.png"} />
          </div>
        ) : (
          <>
            <div className="share-content-header">
              <div className="share-back" onClick={this.onBack}>
                <i className=" fa fa-chevron-left" />
                <Trans>BACK</Trans>
              </div>
              <strong style={{ fontSize: 17 }}>
                {this.state.selectedProposal === -1 ? "CONTENT" : "POST"}
              </strong>
              <div
                style={{ width: 80 }}
                onClick={() => this.setState({ openProposalNote: true })}
                className={
                  this.state.selectedProposal === -1 ? "" : "fa fa-comment"
                }
              ></div>
            </div>
            {this.state.openProposalNote
              ? this.renderShareNotes()
              : this.state.selectedProposal >= 0
              ? this.renderProposal()
              : this.renderTasks()}
          </>
        )}
      </div>
    );
  }

  renderShareNotes = () => {
    if (this.state.influencer) {
      let proposal;
      const notesType = `campaign_content_approval_${this.state.influencer.influencer_id}`;
      if ((proposal = this.state.proposals[this.state.selectedProposal])) {
        return (
          // @ts-ignore
          <SideNotes
            handleClose={() => this.setState({ openProposalNote: false })}
            objectType="campaign"
            name={""}
            notesType={notesType}
            isFromShare={true}
            itemId={proposal.task_id}
            containerClassName="preview-side-notes-share"
          />
        );
      }
    }
    return null;
  };

  renderTasks = () => {
    const { influencer } = this.state;
    const { proposals } = this.state;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={influencer?.picture_url}
            style={{ width: 60, height: 60, borderRadius: "50%" }}
          />
          <strong style={{ fontSize: 18, marginTop: 5 }}>
            {influencer?.name}
          </strong>
        </div>
        <h3>
          <Trans>Tasks</Trans>
        </h3>

        {this.renderProposals(proposals, "new")}
        {this.renderProposals(proposals, "declined")}
        {this.renderProposals(proposals, "approved")}
      </>
    );
  };

  renderProposal = () => {
    if (!this.state.proposals) return null;
    const proposal = this.state.proposals[this.state.selectedProposal];
    const responded =
      proposal.approved !== null || proposal.shared_approved != null;
    let upload: Upload = {};
    if (proposal.uploads?.length) {
      upload = proposal.uploads[this.state.selectedUpload];
    }
    const showTaskSummary =
      this.state.selectedUpload >= proposal.uploads?.length &&
      this.state.selectedUpload > 0;

    return (
      <div>
        <h3>{this.POST_TYPES[proposal.post_type]}</h3>

        {showTaskSummary ? (
          <div style={{ marginTop: 20 }}>
            <div style={{ textAlign: "center" }}>
              <h4>
                <Trans>
                  {responded ? "Selected choice" : "Please select one option"}
                </Trans>
                :
              </h4>
            </div>
            <div
              onClick={() => {
                if (!responded) proposal.semi_approve = true;
                this.forceUpdate();
              }}
              className={`final-approval-btn ${
                proposal.approved ||
                proposal.shared_approved === true ||
                proposal.semi_approve === true
                  ? "true"
                  : ""
              }`}
            >
              <Trans>
                The influencer can continue publishing with the approved
                contents
              </Trans>
            </div>
            <div
              onClick={() => {
                if (!responded) proposal.semi_approve = false;
                this.forceUpdate();
              }}
              className={`final-approval-btn ${
                proposal.approved === false ||
                proposal.shared_approved === false ||
                proposal.semi_approve === false
                  ? "true"
                  : ""
              }`}
            >
              <Trans>The influencer needs to do edits and upload again</Trans>
            </div>
            {proposal.semi_approve === false ||
            proposal.shared_approved === false ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h4>
                    <Trans>Tell us why</Trans>:
                  </h4>
                </div>
                <div>
                  <div
                    style={{
                      borderBottom: "1px solid #ebebeb",
                      marginBottom: 10,
                    }}
                  >
                    <Trans>Text Comments</Trans>
                  </div>
                  <textarea
                    disabled={responded}
                    defaultValue={
                      proposal.shared_rejection?.text_comments || ""
                    }
                    id="text_comments"
                    style={{ display: "block", borderRadius: 6, width: "100%" }}
                  />
                </div>
                {proposal.uploads?.length ? (
                  <div style={{ marginTop: 15, marginBottom: 15 }}>
                    <div
                      style={{
                        borderBottom: "1px solid #ebebeb",
                        marginBottom: 10,
                      }}
                    >
                      <Trans>Media Comments</Trans>
                    </div>
                    <textarea
                      disabled={responded}
                      defaultValue={
                        proposal.shared_rejection?.uploads_comments || ""
                      }
                      id="media_comments"
                      style={{
                        display: "block",
                        borderRadius: 6,
                        width: "100%",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {responded ? null : this.state.loadingResponse ? (
              <div className="send-buttons-loader" style={{ margin: "auto" }} />
            ) : (
              <button
                onClick={() =>
                  this.responseToProposal(proposal, !!proposal.semi_approve)
                }
                className={`btn btn-primary ${
                  proposal.semi_approve != undefined ? "" : "disabled"
                }`}
                style={{ width: "90%", margin: "5%" }}
              >
                <Trans>Submit</Trans>
              </button>
            )}
          </div>
        ) : (
          <>
            {this.renderPost(proposal, upload)}

            {upload?.upload_id ? (
              <div
                className={`upload-btn-container ${
                  proposal.shared_rejection &&
                  proposal.shared_rejection.uploads_responses
                    ? proposal.shared_rejection.uploads_responses[
                        this.state.selectedUpload
                      ].approved
                    : upload.approved
                }`}
              >
                <strong className="label-title">
                  {responded ? (
                    <Trans>Your Choice</Trans>
                  ) : (
                    <Trans>Select your choice</Trans>
                  )}
                </strong>
                <li
                  className="fa fa-times"
                  onClick={() => {
                    if (responded) {
                      return;
                    }
                    upload.approved = false;
                    this.setState({});
                  }}
                />
                <li
                  className="fa fa-check"
                  onClick={() => {
                    if (responded) {
                      return;
                    }
                    upload.approved = true;
                    this.setState({});
                  }}
                />
              </div>
            ) : null}
            <button
              className={`btn btn-primary ${
                proposal.shared_rejection &&
                proposal.shared_rejection.uploads_responses
                  ? proposal.shared_rejection.uploads_responses[
                      this.state.selectedUpload
                    ].approved
                  : upload.approved !== null || proposal.approved !== null
                  ? ""
                  : "disabled"
              }`}
              onClick={() => {
                window.scroll(0, 0);
                this.setState({
                  selectedUpload: this.state.selectedUpload + 1,
                });
              }}
              style={{ width: "90%", margin: "5%" }}
            >
              <Trans>Next</Trans>
            </button>
          </>
        )}
      </div>
    );
  };

  renderPost = (proposal: Proposal, upload: Upload | null) => {
    return (
      <div style={{ border: "1px solid #ddd", borderRadius: 6, padding: 10 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={this.state.influencer?.picture_url}
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          <strong style={{ fontSize: 16 }}>
            {this.state.influencer?.name}
          </strong>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection:
              proposal.post_type === "tw_tweet" ? "column-reverse" : "column",
          }}
        >
          {upload ? this.renderUploadMedia(upload) : null}
          {proposal.text ? (
            <div style={{ fontSize: 16 }}>
              {proposal.post_type !== "tw_tweet" ? (
                <>
                  <strong>{this.state.influencer?.name}</strong>
                  &nbsp;
                </>
              ) : null}
              <span>{proposal.text}</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  renderUploadMedia = (upload: Upload) => {
    if (upload.upload_type === "image") {
      return (
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: -10,
            marginRight: -10,
          }}
        >
          <img src={upload.upload_url} style={{ width: "100%" }} />
        </div>
      );
    }
    if (upload.upload_type === "video") {
      return (
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: -10,
            marginRight: -10,
          }}
        >
          <video
            style={{ width: "100%" }}
            src={upload.upload_url}
            controls={true}
          />
        </div>
      );
    }
    return null;
  };

  renderProposals = (
    proposals: Proposal[],
    type: "new" | "approved" | "declined",
  ) => {
    const items = proposals
      .map((proposal, idx) => {
        if (
          type === "new" &&
          (proposal.approved !== null || proposal.shared_approved !== null)
        ) {
          return null;
        }
        if (
          type === "approved" &&
          proposal.approved !== true &&
          proposal.shared_approved !== true
        ) {
          return null;
        }
        if (
          type === "declined" &&
          proposal.approved !== false &&
          proposal.shared_approved !== false
        ) {
          return null;
        }
        return (
          <div className="share-task-row" key={idx}>
            {idx + 1}. {this.POST_TYPES[proposal.post_type]}
            <div
              className="btn btn-primary"
              onClick={() =>
                this.setState({ selectedProposal: idx, selectedUpload: 0 })
              }
            >
              {type === "new" ? <Trans>Review</Trans> : <Trans>View</Trans>}
            </div>
          </div>
        );
      })
      .filter((x) => x !== null);
    if (items.length) {
      return (
        <>
          <h4
            style={{
              borderBottom: "1px solid #ddd",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 5,
            }}
          >
            {type === "new" && <Trans>Pending</Trans>}
            {type === "declined" && (
              <>
                <Trans>Declined</Trans>
                <div className="fa fa-times" style={{ color: "#f15a24" }} />
              </>
            )}
            {type === "approved" && (
              <>
                <Trans>Approved</Trans>
                <div className="fa fa-check" style={{ color: "#22b573" }} />
              </>
            )}
          </h4>
          {items}
        </>
      );
    }
  };
}

const mapStateToProps = (state: any) => {
  return {
    board: state.boardReducer.board as ManageAuctionData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getShareBoardDetails: (callback: any) => {
      dispatch(getShareBoardDetails(callback));
    },
  };
};

const ShareContentsPageConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ShareContentsPage));

export default ((props) => {
  const navigate = useNavigate();

  return <ShareContentsPageConnected {...props} navigate={navigate} />;
}) as React.FC<OwnProps>;
