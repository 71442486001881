// TODO - lint
/* eslint-disable import/no-default-export */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      approve_content: "APPROVE CONTENT",
      view_content: "VIEW CONTENT",
      waiting_for_content: "WAITING FOR CONTENT",
      influencers: "influencers",
      BACK: "BACK",
      Tasks: "Tasks",
      "Please select one option": "Please select one option",
      "Text Comments": "Text Comments",
      "Media Comments": "Media Comments",
      "The influencer can continue publishing with the approved contents":
        "The influencer can continue publishing with the approved contents",
      "The influencer needs to do edits and upload again":
        "The influencer needs to do edits and upload again",
      Submit: "Submit",
      "Your Choice": "Your Choice",
      "Select your choice": "Select your choice",
      Next: "Next",
      Pending: "Pending",
      Declined: "Declined",
      Approved: "Approved",
      Review: "Review",
      View: "View",
      "Selected choice": "Selected choice",
      "Tell us why": "Tell us why",
    },
  },
  he: {
    translation: {
      approve_content: "אישור תכנים",
      view_content: "צפיה בתכנים",
      waiting_for_content: "ממתין לתכנים",
      influencers: "משפיענים",
      BACK: "חזור",
      Tasks: "משימות",
      "Please select one option": "נא לבחור את אחת האפשרויות",
      "Text Comments": "הערות על הטקסט",
      "Media Comments": "הערות על המדיה",
      "The influencer can continue publishing with the approved contents":
        "המשפיען יכול/ה להמשיך קדימה ולפרסם את התכנים בזמן שנקבע",
      "The influencer needs to do edits and upload again":
        "ֿהמשפיען צריך/ה לערוך ולשלוח חדש",
      Submit: "אישור",
      "Your Choice": "הבחירה שלך",
      "Select your choice": "נא לבחור את אחת האפשרויות",
      Next: "הבא",
      Pending: "ממתין",
      Declined: "נדחו",
      Approved: "אושרו",
      Review: "הגב/י",
      View: "צפייה",
      "Selected choice": "האפשרות הנבחרת",
      "Tell us why": "ספרו לנו למה",
      completed: "הושלם",
    },
  },
};

const detection = {
  order: ["sessionStorage", "navigator"],
  lookupSessionStorage: "i18nextLng",
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection,
  });

export default i18n;
