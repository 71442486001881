import React from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { Form } from "src/ui/Form";
import { FormFooterButtonGroup } from "src/ui/FormFooterButtonGroup";

export const DeletePrompt: React.FC<{
  onYes: () => void;
  onNo: () => void;
  text?: string;
}> = ({ onYes, onNo, text }) => (
  <Form onSubmit={onYes}>
    <Heading py="3" size="sm" textAlign="center">
      Notice
    </Heading>
    <Text py="3" textAlign="center">
      {text ?? "Are you sure you want to delete?"}
    </Text>
    <FormFooterButtonGroup py="3">
      <Button type="submit" tabIndex={2}>
        Yes
      </Button>
      <Button autoFocus type="button" onClick={onNo} tabIndex={1}>
        Cancel
      </Button>
    </FormFooterButtonGroup>
  </Form>
);
