import {
  Avatar,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onClose(): void;
  relevantInfluencers: any;
  getPrice: (price: number) => string;
};

const ChangedPaymentsList = ({
  isOpen,
  onClose,
  relevantInfluencers,
  getPrice,
}: Props) => {
  return isOpen ? (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent width="750px">
        <ModalHeader backgroundColor="#FAFAFA" color={"#333"} p={4}>
          <Text style={{ fontSize: "22px", fontWeight: 400 }}>
            Changes in flat fee next month
          </Text>
        </ModalHeader>
        <ModalCloseButton color="#808080" />
        <ModalBody background={"white"} px={14} pb={14}>
          <Flex style={{ flexDirection: "row-reverse", margin: "16px" }}>
            <Text
              style={{
                color: "#555",
                fontSize: "14px",
                fontWeight: 500,
                width: "200px",
                textAlign: "right",
              }}
            >
              Next month price
            </Text>
            <Text
              style={{
                color: "#555",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Current price
            </Text>
          </Flex>
          <Box>
            {relevantInfluencers?.map((influencer: any, index: number) => {
              const recurringPaymentIndex = influencer.payments.findIndex(
                (payment: any) => payment.payment_type === "recurring_payment",
              );
              return recurringPaymentIndex >= 0 &&
                influencer.recurring_price !==
                  influencer.payments[recurringPaymentIndex].price ? (
                <Flex
                  alignItems={"center"}
                  gap={3}
                  key={influencer.id}
                  padding={"4px"}
                  background={index % 2 === 0 ? "#FAFAFA" : "white"}
                >
                  <Avatar
                    src={influencer.image_url}
                    style={{ width: "35px", height: "35px" }}
                  />
                  <Box style={{ display: "flex", flex: 3 }}>
                    <Text fontWeight={400} color={"#333"} fontSize={"14px"}>
                      {influencer.name}
                    </Text>
                  </Box>
                  <Box style={{ display: "flex" }}>
                    <Text
                      style={{
                        fontSize: "20px",
                        fontWeight: 500,
                        color: "#333",
                      }}
                    >
                      {getPrice(
                        influencer.payments[recurringPaymentIndex].price,
                      )}
                    </Text>
                    <Text
                      style={{
                        marginRight: "16px",
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#808080",
                        width: "200px",
                        textAlign: "right",
                      }}
                    >
                      {getPrice(influencer.recurring_price)}
                    </Text>
                  </Box>
                </Flex>
              ) : null;
            })}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : null;
};

export default ChangedPaymentsList;
