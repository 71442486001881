import { Box } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import CheckBoxTask from "./DisplayTask/CheckBoxTask";
import DisplayTaskDate from "./DisplayTask/DisplayTaskDate";
import DisplayTaskDescription from "./DisplayTask/DisplayTaskDescription";
import DisplayTaskTag from "./DisplayTask/DisplayTaskTag";
import DisplayTaskTitle from "./DisplayTask/DisplayTaskTitle";

const Task = (props: any) => {
  const taskBox = useRef<HTMLDivElement>(null);
  const [checked, setChecked] = useState(props.task.completed);
  const handelUpdateTasksAfterDelete = (taskId: number) => {
    const updatedTasks = props.tasks.filter((task: any) => task.id !== taskId);
    props.setTasks(updatedTasks);
  };

  const DeleteToDoItem = [
    {
      action: () => {
        props.deleteTask(props.task.id);
        handelUpdateTasksAfterDelete(props.task.id);
      },
      text: "Delete task",
      icon: "fa-thin fa-trash-can",
    },
  ];
  const bgColor = props.task.completed ? "rgba(247, 250, 250, 0.55)" : "white";
  return (
    <>
      {props.dragDropEnabled ? (
        <Draggable draggableId={`task-${props.id}`} index={props.index}>
          {(provided) => {
            let top = "auto";
            if ((provided.draggableProps?.style as any)?.top) {
              top = `${
                // eslint-disable-next-line no-unsafe-optional-chaining
                (provided.draggableProps?.style as any)?.top -
                // eslint-disable-next-line no-unsafe-optional-chaining
                (provided.draggableProps?.style as any)?.height
              }px`;
            }
            return (
              <Box ref={taskBox}>
                <Box
                  {...provided.draggableProps}
                  sx={{
                    minHeight: "91px",
                    display: "flex",
                    background: bgColor,
                    marginBottom: "2px",
                    padding: "10px 0",
                  }}
                  style={{
                    ...provided.draggableProps?.style,
                    left: 0,
                    top,
                  }}
                  className=""
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <Box
                    sx={{
                      width: "55%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {!props.disableCheckBox ? (
                      <Box alignItems="center">
                        <CheckBoxTask
                          task={props.task}
                          todoState={props.todoState}
                          setCompleted={props.setCompleted}
                          taskBox={taskBox}
                          checked={checked}
                          setChecked={setChecked}
                        />
                      </Box>
                    ) : (
                      <Box alignItems="center" padding="20px"></Box>
                    )}
                    <Box
                      sx={{
                        flexDirection: "column",
                      }}
                    >
                      <DisplayTaskTitle
                        disable={false}
                        task={props.task}
                        campaignId={props.campaignId}
                        handleEditTask={props.handleEditTask}
                      />
                      <DisplayTaskDescription
                        disable={false}
                        task={props.task}
                        campaignId={props.campaignId}
                        handleEditTask={props.handleEditTask}
                      />
                    </Box>
                  </Box>

                  <div
                    style={{
                      width: "1px",
                      height: "auto",
                      background: "#EBEBEB",
                      margin: "5px",
                    }}
                  ></div>
                  <Box flexDirection="column" width={"45%"} alignItems="left">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "6px",
                      }}
                    >
                      <DisplayTaskDate
                        style={{ marginBottom: "10px" }}
                        task={props.task}
                        campaignId={props.campaignId}
                        handleEditTask={props.handleEditTask}
                      />
                      <HumanzActionsMenu
                        buttontype="dots"
                        actions={DeleteToDoItem}
                        buttontext="hi"
                      />
                    </Box>
                    <DisplayTaskTag
                      task={props.task}
                      campaignId={props.campaignId}
                      handleEditTask={props.handleEditTask}
                    />
                  </Box>
                </Box>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    background: "#EBEBEB",
                  }}
                ></div>
              </Box>
            );
          }}
        </Draggable>
      ) : (
        <>
          <Box
            sx={{
              minHeight: "91px",
              display: "flex",
              background: "white",
              marginBottom: "2px",
              width: "100%",
              padding: "10px 0",
            }}
          >
            <Box
              sx={{
                width: "55%",
                alignItems: "center",
                display: "flex",
              }}
            >
              {!props.disableCheckBox ? (
                <Box alignItems="center">
                  <CheckBoxTask
                    task={props.task}
                    todoState={props.todoState}
                    setCompleted={props.setCompleted}
                    taskBox={taskBox}
                    checked={checked}
                    setChecked={setChecked}
                  />
                </Box>
              ) : (
                <Box alignItems="center" padding="20px"></Box>
              )}
              <Box
                sx={{
                  flexDirection: "column",
                }}
              >
                <DisplayTaskTitle
                  disable={false}
                  task={props.task}
                  campaignId={props.campaignId}
                  handleEditTask={props.handleEditTask}
                />
                <DisplayTaskDescription
                  disable={false}
                  task={props.task}
                  campaignId={props.campaignId}
                  handleEditTask={props.handleEditTask}
                />
              </Box>
            </Box>

            <div
              style={{
                width: "1px",
                height: "70px",
                background: "#EBEBEB",
                margin: "5px",
              }}
            ></div>
            <Box flexDirection="column" width={"45%"} alignItems="left">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <DisplayTaskDate
                  style={{ marginBottom: "10px" }}
                  task={props.task}
                  campaignId={props.campaignId}
                  handleEditTask={props.handleEditTask}
                />
                <HumanzActionsMenu
                  buttontype="dots"
                  actions={DeleteToDoItem}
                  buttontext="hi"
                />
              </Box>
              <DisplayTaskTag
                task={props.task}
                campaignId={props.campaignId}
                handleEditTask={props.handleEditTask}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Task;
