import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HomepageDashboard } from "src/api/types";
import DashboardTile, { DashboardTileProps } from "./DashboardTile";

type Props = {
  loading?: boolean;
  dashboardData: HomepageDashboard;
  type: "main" | "tasks";
};

const instagramGradient =
  "radial-gradient(circle at 20% 108%, rgb(253, 244, 151) 0%,  rgb(253, 89, 73) 40%, rgb(214, 36, 159) 67%, rgb(40, 90, 235) 143%)";

const DashboardTiles = ({ dashboardData, loading, type }: Props) => {
  const [tiles, setTiles] = useState([]);
  const populateTasks = (data: HomepageDashboard): DashboardTileProps[] => {
    return [
      {
        title: "Completed Tasks",
        icon: "",
        color: "#434D56",
        noBackground: true,
        customIcon: (
          <i
            className="fad fa-tasks"
            style={{
              height: "44px",
              width: "44px",
              borderRadius: "44px",
              color: "#808080",
              lineHeight: "50px",
              background: "#FAFAFA",
            }}
          />
        ),
        variant: "small",
      },
      {
        title: "Total",
        icon: "fad fa-tasks",
        color: "#3BB574",
        value: data?.total_tasks?.value || 0,
        variant: "small",
      },
      {
        title: "Story",
        icon: "",
        color: "",
        customIcon: (
          <i
            className="fa moi moi-stories"
            style={{
              height: "44px",
              width: "44px",
              borderRadius: "44px",
              color: "white",
              lineHeight: "50px",
              background: instagramGradient,
            }}
          />
        ),
        value: data?.total_tasks_story?.value || 0,
        variant: "small",
      },
      {
        title: "Post",
        icon: "",
        color: "",
        customIcon: (
          <i
            className="fa fa-instagram"
            style={{
              height: "44px",
              width: "44px",
              borderRadius: "44px",
              color: "white",
              lineHeight: "44px",
              background: instagramGradient,
            }}
          />
        ),
        value: data?.total_tasks_ig?.value || 0,
        variant: "small",
      },
      {
        title: "YouTube",
        icon: "",
        customIcon: (
          <i
            className="fa fa-youtube"
            style={{
              height: "44px",
              width: "44px",
              borderRadius: "44px",
              color: "white",
              lineHeight: "44px",
              background: "#ED1D24",
            }}
          />
        ),
        color: "",
        value: data?.total_tasks_yt?.value || 0,
        variant: "small",
      },
      {
        title: "Tweets",
        customIcon: (
          <i
            className="fa fa-twitter"
            style={{
              height: "44px",
              width: "44px",
              borderRadius: "44px",
              color: "white",
              lineHeight: "44px",
              background: "#1B9DF0",
            }}
          />
        ),
        color: "",
        icon: "",
        value: data?.total_tasks_tw?.value || 0,
        variant: "small",
      },
      {
        title: "TikTok",
        customIcon: (
          <i
            className="fa-brands fa-tiktok"
            style={{
              height: "44px",
              width: "44px",
              borderRadius: "44px",
              color: "white",
              lineHeight: "44px",
              background: "#000000",
            }}
          />
        ),
        color: "",
        icon: "",
        value: data?.total_tasks_tk?.value || 0,
        variant: "small",
      },
      {
        title: "Content",
        customIcon: (
          <i
            className="fas fa-photo-video"
            style={{
              height: "44px",
              width: "44px",
              borderRadius: "44px",
              color: "white",
              lineHeight: "44px",
              background: "#3BB574",
            }}
          />
        ),
        color: "",
        icon: "",
        value: data?.content_tasks?.value || 0,
        variant: "small",
      },
    ];
  };

  const populateMainTiles = (data: HomepageDashboard): DashboardTileProps[] => {
    return [
      {
        title: "Total impressions",
        icon: "fad fa-eye",
        color: "#FA9398",
        value: data?.total_impressions?.value || 0,
      },
      {
        title: "Total engagements",
        icon: "fad fa-chart-line",
        color: "#FCAB90",
        value: data?.total_engagement?.value || 0,
      },
      {
        title: "Influencers",
        icon: "fad fa-users",
        color: "#A86AF8",
        value: data?.influencers?.value || 0,
      },
      {
        title: "Active Campaigns",
        icon: "fad fa-bullhorn",
        color: "#00A99D",
        value: data?.active_campaigns?.value || 0,
      },
      {
        title: "Total Video Views",
        color: "#ED685C",
        icon: "fas fa-play-circle",
        value: data?.total_video_views?.value || 0,
      },
      {
        title: "Total Story Views",
        icon: "fa moi-stories",
        color: "#99ABF5",
        value: data?.total_story_views?.value || 0,
      },
      {
        title: "Total Clicks",
        icon: "fad fa-computer-mouse",
        color: "#DBAD00",
        value: data?.total_clicks?.value || 0,
      },
      {
        title: "Total Sales",
        icon: "fad fa-coins",
        color: "#249FF0",
        value: data?.total_sales?.value || 0,
      },
    ];
  };

  useEffect(() => {
    setTiles(
      type === "main"
        ? populateMainTiles(dashboardData)
        : populateTasks(dashboardData),
    );
  }, [dashboardData, type]);

  return (
    <Flex gap={4} display={"flex"} flexWrap={"wrap"}>
      {tiles?.map((tile: DashboardTileProps, i: number) => (
        <DashboardTile
          key={i}
          noBackground={tile?.noBackground}
          variant={tile?.variant}
          color={tile?.color}
          icon={tile?.icon}
          customIcon={tile?.customIcon}
          title={tile?.title}
          value={tile?.value}
          suffix={tile?.suffix}
          loading={loading}
        />
      ))}
    </Flex>
  );
};

export default DashboardTiles;
