import axios from "axios";
import { Dispatch } from "redux";
import { getHeaders, getBackendUri } from "./commonActionsConf";

export const getWizardData = (
  uuid: string,
  password: string | null,
  callback: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    const headers: any = getHeaders();
    headers["x-pixel-wizard-uuid"] = uuid;
    headers["x-pixel-wizard-pass"] = password;
    axios
      .get(`${getBackendUri()}/pixel_wizard/`, { headers })
      .then((data) => {
        if (callback) {
          callback(data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (callback) {
          callback(err);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const testPixelEvent = (
  uuid: string,
  pdata: any,
  password: string | null,
  callback: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    const headers: any = getHeaders();
    headers["x-pixel-wizard-uuid"] = uuid;
    headers["x-pixel-wizard-pass"] = password;
    axios
      .post(`${getBackendUri()}/pixel_wizard/`, pdata, { headers })
      .then((data) => {
        if (callback) {
          callback(data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (callback) {
          callback(err);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const finishPixelwizard = (
  uuid: string,
  password: string | null,
  callback: any,
) => {
  return (dispatch: any) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    const headers: any = getHeaders();
    headers["x-pixel-wizard-uuid"] = uuid;
    headers["x-pixel-wizard-pass"] = password;
    axios
      .put(`${getBackendUri()}/pixel_wizard/`, { status: true }, { headers })
      .then((data) => {
        if (callback) {
          callback(data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (callback) {
          callback(err);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const updatePlatform = (
  uuid: string,
  pdata: any,
  password: string | null,
  callback: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    const headers: any = getHeaders();
    headers["x-pixel-wizard-uuid"] = uuid;
    headers["x-pixel-wizard-pass"] = password;
    axios
      .post(`${getBackendUri()}/pixel_wizard/update_platform`, pdata, {
        headers,
      })
      .then((data) => {
        if (callback) {
          callback(data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (callback) {
          callback(err);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};
