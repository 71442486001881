import moment from "moment";
import { extractAuctionsRoiMedia } from "src/api/actions/auctionActions";
import {
  InfluencerWithMedias,
  ManagedCampaignROI,
  RoiContent,
} from "src/api/types";
import { getSocialMediaLinkByContentType } from "src/helpers/SocialNetworksHelper";
import { ReportFilterParameters } from "src/models/Api";

export interface SlidesData {
  avg_eng_per_post: string;
  avg_reach_per_post: string;
  campaign_name: string;
  charts_data: { [key: string]: SlidesChartsDatum };
  engagement_rate: string;
  ig_posts_amount: number;
  ig_posts_eng: number;
  ig_posts_reach: number;
  ig_posts_impressions: number;
  ig_stories_amount: number;
  ig_stories_eng: number;
  ig_stories_reach: number;
  ig_stories_impressions: number;
  image_brand_logo: SlidesImage;
  image_engagement_breakdown_gauge: SlidesImage;
  image_posts_breakdown_gauge: SlidesImage;
  image_posts_vs_engagement_graph: SlidesImage;
  image_posts_vs_impressions_graph: SlidesImage;
  image_posts_vs_reach_graph: SlidesImage;
  image_reach_breakdown_gauge: SlidesImage;
  image_small_ig: SlidesImage;
  image_small_tk: SlidesImage;
  image_small_tw: SlidesImage;
  image_small_yt: SlidesImage;
  top_performing: SlidesTopPerforming[];
  influencer_amount: number;
  influencers: Influencer[];
  link_to_humanz_share_report: SlidesLink;
  num_of_influencers: number;
  num_of_posts: number;
  time_range: string;
  tk_posts_amount: number;
  tk_posts_eng: number;
  tk_posts_reach: number;
  tk_posts_impressions: number;
  total_eng: number;
  total_impressions: number;
  total_reach: number;
  tw_tweets_amount: number;
  tw_tweets_eng: number;
  tw_tweets_reach: number;
  avg_impressions_per_ig_post: string;
  avg_eng_per_ig_post: string;
  avg_impressions_per_tk_post: string;
  avg_eng_per_tk_post: string;
  top_performers: SlidesTopPerforming[];
}

export interface SlidesTopPerforming {
  performer_name: string;
  performer_image: string;
  performer_content?: string;
}

export interface SlidesChartsDatum {
  engagement: number;
  impressions: number;
  posts: number;
  reach: number;
}

export interface SlidesImage {
  image_url: string;
}

export interface Influencer {
  image_influencer_profile_pic: SlidesImage;
  influencer_name: string;
  link_ig_link: SlidesLink | null;
  link_tk_link: SlidesLink | null;
  contents: any;
}

export interface SlidesLink {
  link: string;
  text: string;
}

interface SummaryDataProps {
  kpis: ManagedCampaignROI["kpi"];
  campaign_details: ManagedCampaignROI["campaign_details"];
  socialsData: ManagedCampaignROI["social_network_roi"];
  tasks: {
    ig_post: number;
    ig_story: number;
    tk_video: number;
    tw_tweet: number;
    yt_video: number;
  };
}

const filterContents = (c: RoiContent) =>
  ["ig_post", "ig_story", "tk_video"].includes(c.post_type);

const sortContents = (a: RoiContent, b: RoiContent) => {
  const aEng =
    (a.engagemnts.comments || 0) +
    (a.engagemnts.likes || 0) +
    (a.engagemnts.story_engagements || 0);
  const bEng =
    (b.engagemnts.comments || 0) +
    (b.engagemnts.likes || 0) +
    (b.engagemnts.story_engagements || 0);
  return bEng - aEng;
};

const getChartsData = (posts: { [key: string]: RoiContent[] }) => {
  const all = (posts.ig_post || []).concat(posts.ig_story || []);
  const dates: SlidesData["charts_data"] = {};
  all.forEach((content) => {
    const date = (content.media_creation_time || content.media_linked_at).split(
      "T",
    )[0];
    if (!dates[date]) {
      dates[date] = {
        engagement: 0,
        impressions: 0,
        posts: 0,
        reach: 0,
      };
    }
    dates[date].posts++;
    dates[date].engagement += content.engagemnts.comments || 0;
    dates[date].engagement += content.engagemnts.likes || 0;
    dates[date].engagement += content.engagemnts.story_engagements || 0;
    dates[date].impressions += content.engagemnts.impressions || 0;
    dates[date].reach +=
      (content.engagemnts.reach || 0) + (content.engagemnts.story_reach || 0);
  });
  return dates;
};

type AllMediasMap = { [key: string]: InfluencerWithMedias["medias"][number] };

const mapAllMedias = async (
  influencers: ManagedCampaignROI["per_influencer"],
) => {
  const allPosts = influencers
    ?.filter(
      (inf) =>
        inf.contents?.filter(
          (x) => x.post_type === "ig_post" || x.post_type === "tk_video",
        ).length > 0,
    )
    .map((inf) => {
      const data: InfluencerWithMedias = {
        influencer_id: inf.influencer_id,
        medias: inf.contents
          ?.filter(
            (x) => x.post_type === "ig_post" || x.post_type === "tk_video",
          )
          .map((content) => ({
            media_id: content.linked_media,
            post_type: content.post_type,
          })),
      };
      return data;
    });

  if (allPosts?.length === 0) return {};
  const postsMap: AllMediasMap = {};
  try {
    const infMedias: InfluencerWithMedias[] = (
      await extractAuctionsRoiMedia(allPosts)
    ).data as any;

    infMedias?.forEach((inf: InfluencerWithMedias) => {
      inf.medias.forEach((media) => {
        postsMap[media.media_id] = media;
      });
    });
  } catch (e) {
    /* */
  }
  return postsMap;
};

const getInfluencers = (
  influencers: ManagedCampaignROI["per_influencer"],
  allMediasMap: AllMediasMap,
) => {
  const influencersData: Influencer[] = influencers
    .filter((x) => x.contents?.some(filterContents))
    .map((inf) => {
      const contents = inf.contents
        ?.filter(filterContents)
        ?.filter(
          (p) =>
            !["ig_post", "tk_video"].includes(p.post_type) ||
            allMediasMap[p.linked_media]?.media_url,
        )
        .sort(sortContents)
        .slice(0, 12)
        .map((content) => ({
          image:
            content.post_type === "ig_story"
              ? content.linked_media_img_url || content.linked_media
              : allMediasMap[content.linked_media]?.media_url,
          likes: content.engagemnts.likes,
          comments: content.engagemnts.comments,
          reach: content.engagemnts.reach,
          views: content.engagemnts.views,
          impressions:
            content.engagemnts.impressions +
              (content.engagemnts.boosted_impressions || 0) ||
            content.engagemnts.views,
          story_engagements: content.engagemnts.story_engagements,
          type: content.post_type,
          url:
            content.post_type === "ig_story"
              ? content.linked_media
              : getSocialMediaLinkByContentType(
                  content.post_type,
                  content.linked_media,
                ),
        }))
        .filter(
          (x) =>
            x.image && !(x.image.endsWith(".mp4") || x.image.endsWith(".mov")),
        );
      const influencer: Influencer = {
        contents,
        influencer_name:
          inf.social_network_data.instagram?.username || inf.name,
        link_ig_link:
          inf.social_network_data.instagram?.username &&
          inf.contents?.some(
            (x) => x.post_type === "ig_post" || x.post_type === "ig_story",
          )
            ? {
                link: `https://www.instagram.com/${inf.social_network_data.instagram.username}`,
                text: "Instagram Profile",
              }
            : null,
        link_tk_link:
          inf.social_network_data.tiktok?.username &&
          inf.contents?.some((x) => x.post_type === "tk_video")
            ? {
                link: `https://www.tiktok.com/@${inf.social_network_data.tiktok.username}`,
                text: "TikTok Profile",
              }
            : null,
        image_influencer_profile_pic: {
          image_url:
            inf.social_network_data.instagram?.image_url || inf.picture_url,
        },
      };
      return influencer;
    });
  return influencersData;
};

const getTopPosts = (
  influencers: ManagedCampaignROI["per_influencer"],
  allMediasMap: AllMediasMap,
) => {
  const allPosts: Array<
    RoiContent & { username: string; profile_image: string }
  > = [];
  influencers.forEach((inf) => {
    inf.contents?.filter(filterContents).forEach((content) => {
      allPosts.push({
        ...content,
        username: inf.social_network_data.instagram?.username || inf.name,
        profile_image:
          inf.social_network_data.instagram?.image_url || inf.picture_url,
      });
    });
  });
  const top = allPosts.sort((a, b) => {
    const aEng =
      (a.engagemnts.comments || 0) +
      (a.engagemnts.likes || 0) +
      (a.engagemnts.story_engagements || 0);
    const bEng =
      (b.engagemnts.comments || 0) +
      (b.engagemnts.likes || 0) +
      (b.engagemnts.story_engagements || 0);
    return bEng - aEng;
  });
  const topPosts: SlidesTopPerforming[] = top
    ?.filter(
      (p) =>
        p.post_type !== "ig_post" || allMediasMap[p.linked_media]?.media_url,
    )
    .slice(0, 4)
    .map((content) => ({
      performer_content:
        content.post_type === "ig_story"
          ? content.linked_media_img_url || content.linked_media
          : allMediasMap[content.linked_media]?.media_url,
      performer_content_url:
        content.post_type === "ig_story"
          ? content.linked_media
          : getSocialMediaLinkByContentType(
              content.post_type,
              content.linked_media,
            ),
      performer_image: content.profile_image,
      performer_name: content.username,
    }));
  return topPosts;
};

const getTimeRange = (filters: ReportFilterParameters) => {
  const startDate = filters?.time_filter?.from
    ? moment(filters.time_filter.from).format("DD-MM-YYYY")
    : "";
  const endDate = filters?.time_filter?.to
    ? moment(filters.time_filter.to).format("DD-MM-YYYY")
    : "";

  if (startDate || endDate) return `${startDate} - ${endDate}`;
  return "";
};

const getTopPerformers = (
  influencers: ManagedCampaignROI["per_influencer"],
) => {
  const allInfluencers: Array<{
    engagement: number;
    username: string;
    profile_image: string;
  }> = [];
  influencers.forEach((inf) => {
    const eng = inf.contents
      ?.filter(filterContents)
      .reduce(
        (a, b) =>
          a +
          (b.engagemnts?.comments || 0) +
          (b.engagemnts?.likes || 0) +
          (b.engagemnts?.story_engagements || 0),
        0,
      );
    allInfluencers.push({
      engagement: eng || 0,
      username: inf.social_network_data.instagram?.username || inf.name,
      profile_image:
        inf.social_network_data.instagram?.image_url || inf.picture_url,
    });
  });
  const top = allInfluencers.sort((a, b) => {
    return b.engagement - a.engagement;
  });
  const topInf: SlidesTopPerforming[] = top.slice(0, 4).map((inf) => ({
    performer_image: inf.profile_image,
    performer_name: inf.username,
  }));
  return topInf;
};

export const generateSlidesData = async (
  summaryData: SummaryDataProps,
  filters: ReportFilterParameters,
  influencersData: ManagedCampaignROI["per_influencer"],
) => {
  const posts: { [key: string]: RoiContent[] } = {};
  influencersData?.forEach((inf) => {
    inf.contents?.filter(filterContents).forEach((content) => {
      if (!posts[content.post_type]) {
        posts[content.post_type] = [];
      }
      posts[content.post_type].push(content);
    });
  });

  const allMediasMap = await mapAllMedias(influencersData);

  const igPostsEng =
    posts.ig_post?.reduce(
      (a, b) => a + (b.engagemnts.comments || 0) + (b.engagemnts.likes || 0),
      0,
    ) || 0;
  const igPostsReach =
    posts.ig_post?.reduce((a, b) => a + (b.engagemnts.reach || 0), 0) || 0;

  const instagramPostsImpressions =
    posts.ig_post?.reduce(
      (a, b) =>
        a +
        (b.engagemnts.impressions || 0) +
        (b.engagemnts.boosted_impressions || 0),
      0,
    ) || 0;
  const instagramStoriesImpressions =
    posts.ig_post?.reduce(
      (a, b) =>
        a +
        (b.engagemnts.impressions || b.engagemnts.views || 0) +
        (b.engagemnts.boosted_impressions || 0),
      0,
    ) || 0;
  const tiktokPostsImpressions =
    posts.tk_video?.reduce(
      (a, b) =>
        a +
        (b.engagemnts.impressions || b.engagemnts.views || 0) +
        (b.engagemnts.boosted_impressions || 0),
      0,
    ) || 0;

  const igStoryEng = posts.ig_story?.reduce(
    (a, b) => a + (b.engagemnts.story_engagements || 0),
    0,
  );

  const igStoryReach =
    posts.ig_story?.reduce((a, b) => a + (b.engagemnts.story_reach || 0), 0) ||
    0;

  const tkPostsEng =
    posts.tk_video?.reduce(
      (a, b) => a + (b.engagemnts.comments || 0) + (b.engagemnts.likes || 0),
      0,
    ) || 0;

  const totalPosts =
    summaryData.tasks.ig_post +
    summaryData.tasks.ig_story +
    summaryData.tasks.tk_video;

  // const totalReach = igPostsReach + igStoryReach;

  const totalFollowers =
    (summaryData.socialsData.instagram?.funnel?.followers?.total_followers ||
      0) +
    (summaryData.socialsData.tiktok?.funnel?.followers?.total_followers || 0);

  const chartsData = getChartsData(posts);

  const topPosts = getTopPosts(influencersData, allMediasMap);

  const topPerformers = getTopPerformers(influencersData);

  const influencers = getInfluencers(influencersData, allMediasMap);

  if (influencers.length === 0) return null;

  const timeFilter = getTimeRange(filters);

  const data: SlidesData = {
    avg_eng_per_post: (igPostsEng / summaryData.tasks.ig_post).toFixed(2),
    avg_reach_per_post: (igPostsReach / summaryData.tasks.ig_post).toFixed(2),
    avg_impressions_per_ig_post: (
      instagramPostsImpressions / summaryData.tasks.ig_post || 0
    ).toFixed(2),
    avg_impressions_per_tk_post: (
      tiktokPostsImpressions / summaryData.tasks.tk_video || 0
    ).toFixed(2),
    avg_eng_per_ig_post: (igPostsEng / summaryData.tasks.ig_post || 0).toFixed(
      2,
    ),
    avg_eng_per_tk_post: (tkPostsEng / summaryData.tasks.tk_video || 0).toFixed(
      2,
    ),
    campaign_name: summaryData.campaign_details.title,
    charts_data: chartsData,
    engagement_rate: (
      summaryData.kpis.engagements.current / totalFollowers
    ).toFixed(2),
    ig_posts_amount: summaryData.tasks.ig_post,
    ig_posts_eng: igPostsEng,
    ig_posts_reach: igPostsReach,
    ig_stories_amount: summaryData.tasks.ig_story,
    ig_stories_eng: igStoryEng,
    ig_stories_reach: igStoryReach,
    image_brand_logo: { image_url: summaryData.campaign_details.image },
    image_engagement_breakdown_gauge: {
      image_url: "update_image_engagement_breakdown_gauge",
    },
    image_posts_breakdown_gauge: {
      image_url: "update_image_posts_breakdown_gauge",
    },
    image_posts_vs_engagement_graph: {
      image_url: "update_image_posts_vs_engagement_graph",
    },
    image_posts_vs_impressions_graph: {
      image_url: "update_image_posts_vs_impressions_graph",
    },
    image_posts_vs_reach_graph: {
      image_url: "update_image_posts_vs_reach_graph",
    },
    image_reach_breakdown_gauge: {
      image_url: "update_image_reach_breakdown_gauge",
    },
    image_small_ig: {
      image_url: "https://i.ibb.co/XkCmY0T/ig-grayed.png",
    },
    image_small_tk: {
      image_url: "https://i.ibb.co/Q6RRjZy/tk-grayed.png",
    },
    image_small_tw: {
      image_url: "https://i.ibb.co/4gX3kKX/tw-grayed.png",
    },
    image_small_yt: {
      image_url: "https://i.ibb.co/BnGL9T2/yt-grayed.png",
    },

    influencer_amount: influencers.length,
    influencers: influencers.filter((x) => x.contents.length > 0),
    link_to_humanz_share_report: {
      text: "View full report",
      link: `${window.location.origin}/shares/roi/${summaryData.campaign_details.shared_guid}`,
    },
    num_of_influencers: influencers.length,
    num_of_posts: totalPosts,
    time_range: timeFilter,
    tk_posts_amount: summaryData.tasks.tk_video,
    tk_posts_eng: tkPostsEng,
    tk_posts_reach: 0,
    total_eng:
      (summaryData.socialsData.instagram?.funnel?.total?.engagement || 0) +
      (summaryData.socialsData.tiktok?.funnel?.total?.engagement || 0),
    total_impressions:
      (summaryData.socialsData.instagram?.funnel?.total?.impressions || 0) +
      (summaryData.socialsData.instagram?.funnel.total.boosted_impressions ||
        0) +
      (summaryData.socialsData.tiktok?.funnel?.total?.impressions || 0),
    total_reach: igPostsReach + igStoryReach,
    tw_tweets_amount: 0,
    tw_tweets_eng: 0,
    tw_tweets_reach: 0,
    top_performing: topPosts,
    ig_posts_impressions: instagramPostsImpressions,
    tk_posts_impressions: tiktokPostsImpressions,
    ig_stories_impressions: instagramStoriesImpressions,
    top_performers: topPerformers,
  };

  return data;
};
