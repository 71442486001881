// TODO - lint
/* eslint-disable func-style, func-style, func-style, func-style */
export function getReportJson() {
  return {
    name: "",
    image: "",
    budget: 0,
    currency: "NIS",
    kpis: {
      impressions: 0,
      engagement: 0,
      conversions: 0,
      budget: 0,
    },
    target_audience: {
      gender: [],
      age: [],
    },
    media: [
      {
        username: "",
        post_link: "",
        story_views: "",
        clicks: "",
        id: 0,
      },
    ],
  };
}

export function getMultiReportJson() {
  return {
    title: "",
    image_url: "",
    kpi: {
      currency: "NIS",
      impressions: 0,
      engagement: 0,
      conversions: 0,
      budget: 0,
    },
    target_audience: {
      gender: ["female", "male"],
      age: ["child", "teen", "adult", "elderlies"],
    },
    auction_ids: [],
    auctions_data: [],
  };
}

export function getAmbassadorReportJson() {
  return {
    name: "",
    image: "",
    budget: 0,
    currency: "NIS",
    kpis: {
      impressions: 0,
      engagement: 0,
      conversions: 0,
      budget: 0,
    },
    target_audience: {
      gender: [],
      age: [],
    },
    hashtags: [],
    mentions: [],
    new_influencers: [],
    influencers: [],
    package_type: "small",
    link: { id: null, short_link: "", long_link: "" },
    suggested_influencers: [],
  };
}

export function initAudience() {
  return [
    {
      name: "gender",
      label: "Gender",
      type: "checkbox",
      values: ["female", "male"],
      selected: ["female", "male"],
      isOpen: true,
      isMulti: true,
      row: "audience",
    },
    {
      name: "age",
      label: "Age",
      type: "checkbox",
      labels: ["0-12", "13-18", "19-35", "35+"],
      values: ["child", "teen", "adult", "elderlies"],
      selected: ["child", "teen", "adult", "elderlies"],
      isOpen: true,
      isMulti: true,
      row: "audience",
    },
  ];
}
