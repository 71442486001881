/* eslint-disable no-param-reassign */
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import Indicator from "../../campaign_components/CreateCampaignSection/FormUI/Indicator";
import { InfoToolTip } from "../../general_components/InfoToolTip";
import { EditTasksContext } from "./EditTasksContext";

const BundleHeader = ({
  bundle,
  splitPayment,
  setSplitPayment,
  price,
  setPrice,
  bundleUpdated,
  insertMode,
}: {
  bundle: any;
  splitPayment: boolean;
  setSplitPayment: any;
  price?: number | null;
  setPrice?: any;
  bundleUpdated?: any;
  insertMode?: boolean;
}) => {
  const board = useSelector((state) => state.boardReducer);
  const context = useContext(EditTasksContext);
  return (
    bundle && (
      <Box
        style={{
          borderRadius: 6,
          background: "#F6F6F6",
          padding: 15,
          margin: 10,
        }}
        className="bundle-header"
      >
        {/* <Flex>
          <Text style={{ fontSize: 16, marginBottom: 10 }}>
            Payment settings
          </Text>
        </Flex> */}
        <Flex className="tasks-selectors" gap={6}>
          {insertMode && (
            <Indicator
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              header={
                <>
                  <InfoToolTip
                    iconStyle={{ color: "InactiveCaptionText" }}
                    overlay={
                      "Split payments will set a different price for each task."
                    }
                  />{" "}
                  Payment type
                </>
              }
            >
              <Box>
                <Select
                  placeholder={""}
                  defaultValue={splitPayment ? "true" : "false"}
                  onChange={(event) => {
                    const { value } = event.target;
                    setSplitPayment(value === "true");
                  }}
                  name="selected-state"
                >
                  <option value={"false"}>Total payment for all tasks</option>
                  <option value={"true"}>Split payment</option>
                </Select>
              </Box>
            </Indicator>
          )}
          {!splitPayment &&
            !context.influencer.edited_next_month &&
            !board?.board?.one_month_campaign && (
              <Indicator
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                header={
                  <>
                    <InfoToolTip
                      iconStyle={{ color: "InactiveCaptionText" }}
                      overlay={
                        "Select if you want this task to be repeated every month."
                      }
                    />{" "}
                    Repeat every month
                  </>
                }
              >
                <Box>
                  <Select
                    placeholder={""}
                    defaultValue={String(bundle.renews)}
                    onChange={(event) => {
                      const { value } = event.target;
                      bundle.renews = value === "true";
                      bundle.changed = true;
                      bundleUpdated(bundle);
                    }}
                    name="selected-state"
                  >
                    <option value={"false"}>Do not repeat</option>
                    <option value={"true"}>Repeat</option>
                  </Select>
                </Box>
              </Indicator>
            )}
          {!splitPayment && !board?.board?.one_month_campaign && (
            <Indicator
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              header={
                <>
                  <InfoToolTip
                    iconStyle={{ color: "InactiveCaptionText" }}
                    overlay={
                      "Tasks to be paid at completion or at the end of the month regardless of tasks completion."
                    }
                  />{" "}
                  Eligible for payment on
                </>
              }
            >
              <Box>
                <Select
                  placeholder={""}
                  defaultValue={String(bundle.complete_at_end_of_month)}
                  onChange={(event) => {
                    const { value } = event.target;
                    bundle.complete_at_end_of_month = value === "true";
                    bundle.changed = true;
                    bundleUpdated(bundle);
                  }}
                  name="selected-state"
                >
                  <option value={"false"}>Tasks Completion</option>
                  <option value={"true"}>Guaranteed payment</option>
                </Select>
              </Box>
            </Indicator>
          )}
          {
            <Indicator
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              header={
                <>
                  <InfoToolTip
                    iconStyle={{ color: "InactiveCaptionText" }}
                    overlay={
                      "The total price of all the tasks in this bundle. You can also set a different price for each task if you enable split payment."
                    }
                  />{" "}
                  Total price
                </>
              }
            >
              <Box
                width={120}
                style={{
                  background: "#F6F6F6",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {
                  <Box justifyContent={"center"} alignItems="center">
                    <InputGroup>
                      <Input
                        value={splitPayment ? price : undefined}
                        defaultValue={splitPayment ? undefined : price}
                        onChange={(e) => {
                          if (!splitPayment) {
                            setPrice(Number(e.target.value));
                            bundle.price = price;
                            bundle.changed = true;
                            bundleUpdated(bundle);
                          }
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        placeholder="Price"
                        className=""
                        type="number"
                        style={{ background: "white" }}
                        disabled={
                          splitPayment || bundle.can_change_price === false
                        }
                        variant={splitPayment ? "filled" : "outline"}
                      />
                      <InputRightElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.2em"
                        marginTop={0}
                      >
                        {window.translate(
                          "",
                          `money_symbol_${board.board.summary.currency}`,
                        )}
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                }
              </Box>
            </Indicator>
          }
        </Flex>
      </Box>
    )
  );
};

export default BundleHeader;
