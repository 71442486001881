import Tooltip, { RCTooltip } from "rc-tooltip";
import { CSSProperties, ReactChild } from "react";

interface Props {
  overlay: ReactChild;
  trigger?: Array<"hover" | "click">;
  iconStyle?: CSSProperties;
  overlayStyle?: CSSProperties;
  children?: ReactChild | string;
  placement?: RCTooltip.Placement;
  containerStyle?: CSSProperties;
}

export const InfoToolTip = ({
  overlay,
  trigger,
  iconStyle,
  overlayStyle,
  children,
  placement,
  containerStyle,
}: Props) => {
  return (
    <Tooltip
      placement={placement || "top"}
      trigger={trigger || ["hover", "click"]}
      overlay={overlay}
      overlayStyle={overlayStyle}
    >
      <span style={containerStyle}>
        {children} <i className="fa-solid fa-info-circle" style={iconStyle} />
      </span>
    </Tooltip>
  );
};
