import { Avatar, Box, Button, Flex, Text, Textarea } from "@chakra-ui/react";
import Dialog from "rc-dialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes } from "src/api/services";
import { AuctionPaymentsData } from "src/api/types";
import { FormFooterButtonGroup } from "src/ui/FormFooterButtonGroup";

interface OwnProps {
  callback: any;
  onClose: any;
  disputeInfluencer: AuctionPaymentsData["months"][0]["influencers"][0];
}

export default function CampaignDisputeDialog(props: OwnProps) {
  const [disputeMessage, setDisputeMessage] = useState("");
  const [isSendingDispute, setIsSendingDispute] = useState(false);
  console.log(props.disputeInfluencer);
  const board = useSelector((store) => store.boardReducer.board);
  const submitDispute = () => {
    setIsSendingDispute(true);
    postMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes(
      board.board_id,
      props.disputeInfluencer.influencer_id,
      {
        dispute_reason: disputeMessage,
        ids: props.disputeInfluencer.payments.map((payment) => payment.id),
      },
    )
      .then((res) => {
        setDisputeMessage("");
        toast.success("Dispute opened successfully");
        props.callback(res);
      })
      .catch(() => {
        props.callback();
        toast.error("Error sending dispute. Please try again.");
      })
      .finally(() => {
        setIsSendingDispute(false);
      });
  };

  return props.disputeInfluencer ? (
    <Dialog
      onClose={props.onClose}
      className="confirm-action-dialog"
      style={{ width: "40%", marginTop: "18%" }}
      animation="slide-fade"
      visible={true}
      maskAnimation="fade"
    >
      <div className="edit-tasks-header">Open a dispute</div>
      <Flex justifyContent={"space-between"} alignItems="center" paddingY={2}>
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          Open a dispute
        </div>
        <Flex
          backgroundColor={"red.100"}
          borderRadius={20}
          p={2}
          alignItems={"center"}
          gap={3}
          display={"inline-flex"}
        >
          {props?.disputeInfluencer?.image_url && (
            <Avatar size={"sm"} src={props?.disputeInfluencer?.image_url} />
          )}
          <Box>
            <Text fontWeight={400} color={"#333"} fontSize={"14px"}>
              {props?.disputeInfluencer?.name}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <div>
        <label htmlFor="dispute_message" style={{ fontWeight: 400 }}>
          Here you can request a dispute for an influencer. <br /> Please tell
          us what&apos;s wrong and we will get back to you with a solution as
          soon as possible.
        </label>

        <Box py={3}>
          <Textarea
            onChange={(e) => {
              setDisputeMessage(e.target.value);
            }}
            value={disputeMessage}
            name="dispute_message"
            id={"dispute_message"}
            placeholder={"Your message..."}
          />
        </Box>
      </div>
      <FormFooterButtonGroup py={3}>
        <Button
          style={{ marginRight: 6 }}
          type="button"
          onClick={props.onClose}
          isDisabled={isSendingDispute}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSendingDispute}
          onClick={submitDispute}
          type="submit"
        >
          Submit
        </Button>
      </FormFooterButtonGroup>
    </Dialog>
  ) : null;
}
