// TODO - lint
/* eslint-disable @typescript-eslint/default-param-last, no-param-reassign, no-param-reassign, import/no-default-export */
const academyReducer = (
  state: any = { courses: [], current_course: {} },
  action: any,
) => {
  switch (action.type) {
    case "ACADEMY_GET_COURSES":
      state = {
        ...state,
        courses: action.payload ? action.payload : [],
      };
      break;
    case "GET_COURSE_TRANSLATION":
      state = {
        ...state,
        current_course: action.payload ? action.payload : {},
      };
      break;
    default:
      break;
  }
  return state;
};
export default academyReducer;
