import { Box } from "@chakra-ui/react";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import TemplateButton from "./TemplateButton";

const TemplatesMenu = (props: {
  templates: any[];
  setStep: (arg0: string) => void;
  setTemplateTasks: (arg0: AuctionToDoItem[]) => void;
  setHeader: (arg0: string) => void;
  setTemplateId: (arg0: string) => void;
  setTemplate: (arg0: any) => void;
  handleDeleteTemplate: (arg0: number) => void;
  userId: number;
}) => {
  return (
    <Box
      sx={{
        padding: "10px",
        width: "100%",
        maxHeight: "calc(100vh - 220px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {props.templates.map((template, index) => {
        if (template.tasks !== null) {
          return (
            <TemplateButton
              key={index}
              template={template}
              setStep={props.setStep}
              setTemplateTasks={props.setTemplateTasks}
              setHeader={props.setHeader}
              setTemplateId={props.setTemplateId}
              handleDeleteTemplate={props.handleDeleteTemplate}
              setTemplate={props.setTemplate}
              userId={props.userId}
            />
          );
        }
        return null;
      })}
    </Box>
  );
};
export default TemplatesMenu;
