// TODO - lint
/* eslint-disable import/no-default-export, class-methods-use-this, class-methods-use-this */
import MobileDetect from "mobile-detect";
import React from "react";
import AppStoreIcon from "../../images/Icons-App-Store.png";
import PlayStoreIcon from "../../images/Icons-Google-play.png";

const md = new MobileDetect(window.navigator.userAgent);
export default class MoveToShop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      os: md.os(),
      forced: false,
    };
  }

  async componentDidMount() {
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.hidden) {
          if (this.state.forced) return;
          this.gotoStore();
          this.setState({ forced: true });
        } else {
          // this.gotoStore();
        }
      },
      false,
    );

    setTimeout(() => {
      this.setState({ forced: true });
    }, 3000);

    this.gotoStore();
  }

  redirect() {
    const os = md.os();
    if (os === "AndroidOS") {
      window.location = "humanz://";
    }
    if (os === "iOS") {
      window.location = "humanzai://post/";
    }
  }

  gotoStore(store) {
    const os = md.os();
    if (store === "playstore" || os === "AndroidOS") {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.moimedia.moi",
      );
    }
    if (store === "appstore" || os === "iOS") {
      window.location.replace(
        "itms-apps://itunes.apple.com/us/app/moimedia/id1249804084",
      );
    }
  }

  render() {
    return (
      <section
        className="welcome"
        id="welcome-container"
        style={{ backgroundImage: "url(/images/watermark.svg)" }}
      >
        <div className="container">
          {this.state.text}
          <a
            onClick={() => this.redirect()}
            className="btn btn-primary btn-open-in-app"
          >
            Open Humanz App
          </a>
          <h3 style={{ marginTop: 50 }}>
            Don&#39;t have humanz app yet? Download now!
          </h3>
          <div
            style={{
              marginTop: 30,
              flexDirection: "column",
              cursor: "pointer",
            }}
            id="go_to_store"
          >
            {this.state.os === "AndroidOS" ? null : (
              <div onClick={() => this.gotoStore("appstore")}>
                <img
                  className="CToWUd"
                  style={{ width: "60%", maxWidth: 300 }}
                  src={AppStoreIcon}
                />
              </div>
            )}
            {this.state.os === "iOS" ? null : (
              <div onClick={() => this.gotoStore("playstore")}>
                <img
                  className="CToWUd"
                  style={{ width: "60%", maxWidth: 310 }}
                  src={PlayStoreIcon}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
