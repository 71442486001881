// TODO - lint
/* eslint-disable func-style, no-underscore-dangle, no-unused-vars, no-plusplus, func-style, vars-on-top, no-var */
const rotation = {
  1: "rotate(0deg)",
  3: "rotate(180deg)",
  6: "rotate(90deg)",
  8: "rotate(270deg)",
};

function _arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
export function orientation(id, file, callback) {
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(file);
  fileReader.onload = function () {
    try {
      const scanner = new DataView(fileReader.result);
      let idx = 0;
      let value = 1; // Non-rotated is the default
      if (fileReader.result.length < 2 || scanner.getUint16(idx) !== 0xffd8) {
        // Not a JPEG
        // callback(id,file,rotation[null]);
      }
      idx += 2;
      let maxBytes = scanner.byteLength;
      while (idx < maxBytes - 2) {
        const uint16 = scanner.getUint16(idx);
        idx += 2;
        switch (uint16) {
          case 0xffe1: // Start of EXIF
            var exifLength = scanner.getUint16(idx);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          case 0x0112:
            value = scanner.getUint16(idx + 6, false);
            maxBytes = 0; // Stop scanning
            break;
          default:
            break;
        }
      }
      callback(id, file, rotation[value]);
    } catch (e) {
      callback(id, file, rotation[1]);
    }
  };
}
