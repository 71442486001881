import { Box, Button } from "@chakra-ui/react";
// import { Button, Input } from "@material-ui/core";
import { useState } from "react";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import TaskDate from "./TaskDate";
import TaskDescription from "./TaskDescription";
import TaskTag from "./TaskTag";
import TaskTitle from "./TaskTitle";

const AddTask = (props: any) => {
  const [taskData, setTaskData] = useState<AuctionToDoItem>({
    completed: false,
    description: "",
    due_date: "",
    order: props.tasks.length,
    tag: "General",
    title: "",
  });

  const handleCloseAdd = () => {
    props.setShowAdd(false);
  };
  const isDisable = taskData.title === "" && taskData.description === "";
  const handleSaveClick = () => {
    if (!isDisable) {
      props.callBack(taskData);
      handleCloseAdd();
    }
  };

  const handleButtonContainerClick = (event: any) => {
    event.stopPropagation(); // Prevent the event from bubbling up
  };

  return (
    <Box
      sx={{
        height: "128px",
        width: "100%",
        bgColor: "rgba(235, 235, 235, 0.30)",
        display: "flex",
        position: "relative",
        paddingLeft: "20px",
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          minWidth: "50%",
          // justifyContent: "center",
        }}
      >
        <TaskTitle taskData={taskData} setTaskData={setTaskData} />
        <TaskDescription taskData={taskData} setTaskData={setTaskData} />
      </Box>

      <div
        style={{
          width: "1px",
          height: "110px",
          background: "#EBEBEB",
          margin: "7px 7px 0 10px",
        }}
      ></div>
      <Box
        style={{
          minWidth: "45%",
          flexDirection: "column",
        }}
      >
        <TaskDate taskData={taskData} setTaskData={setTaskData} />
        <TaskTag taskData={taskData} setTaskData={setTaskData} />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          display: "flex",
          gap: "10px",
        }}
      >
        <Button
          onClick={handleCloseAdd}
          style={{
            color: "var(--dark-grey, #808080)",
            background: "rgba(235, 235, 235, 0.30)",
            padding: "1px 1px",
            borderRadius: "24px",
            // boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)",

            fontSize: "12px",
            fontWeight: 500,
            width: "51px",
            height: "25px",
          }}
        >
          Cancel
        </Button>
        <div onClick={handleButtonContainerClick}>
          <Button
            disabled={isDisable}
            onClick={handleSaveClick}
            style={{
              color: "white",
              padding: "0 1px",
              borderRadius: "24px",
              boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)",
              fontSize: "12px",
              fontWeight: 500,
              width: "51px",
              height: "25px",
              backgroundColor:
                taskData.title === "" && taskData.description === ""
                  ? "gray"
                  : "rgba(66, 153, 225, 1)",
              cursor:
                taskData.title === "" && taskData.description === ""
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            Save
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default AddTask;
