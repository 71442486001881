import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatBigNumbers } from "src/components/feed_components/utilities/general";

export const ClicksOverTimeChart = ({
  clicksPerDay,
}: {
  clicksPerDay: any[];
}) => {
  if (!clicksPerDay?.length) return null;
  const data = clicksPerDay;

  const CustomTooltip2 = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="label">Clicks {formatBigNumbers(payload[0].value)}</p>
        </div>
      );
    }

    return null;
  };

  if (data?.length) {
    return (
      <div
        style={{
          marginLeft: 0,
          width: "100%",
          marginBottom: 20,
          color: "black",
          background: "white",
          padding: 15,
          borderRadius: 6,
          boxShadow: "1px 5px 27px 1px rgb(0 0 0 / 3%)",
          marginTop: 20,
        }}
      >
        <div
          style={{
            marginBottom: 10,
          }}
        >
          <strong
            style={{
              color: "#333",
              display: "block",
              textAlign: "left",
              marginLeft: 20,
              marginBottom: 10,
              fontSize: 20,
            }}
          >
            Clicks Over Time
          </strong>
        </div>
        <ResponsiveContainer width={"100%"} height={250}>
          <LineChart width={350} height={250} data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
            <XAxis dataKey="name" />
            <YAxis />
            <ChartTooltip
              allowEscapeViewBox={{
                x: true,
                y: true,
              }}
              content={<CustomTooltip2 />}
            />
            <Line type="monotone" dataKey="value" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
  return null;
};
