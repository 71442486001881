import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { isValidUrl } from "src/components/feed_components/utilities/general";
import { getTabs, Tab, TabStatus } from "../BriefTabs/BriefDefinitions";
import NavigateBox from "./NavigateBox";

interface CreateCampaignBarProps {
  overview: any;
  state: any;
  setState: any;
  onClick: any;
  currentTabProp: any;
  editMode: boolean;
  checkSaveSales: any;
  isShare: boolean;
  checkSalesForErrors: any;
  onChangeTabShowErrors: any;
}

const CreateCampaignBar: React.FC<CreateCampaignBarProps> = ({
  overview,
  state,
  setState,
  currentTabProp,
  editMode,
  checkSaveSales,
  isShare,
  checkSalesForErrors,
  onChangeTabShowErrors,
}) => {
  const [tabsStatus, setTabsStatus] = useState<Record<string, TabStatus>>({
    Type: editMode || isShare ? "default" : "Active",
    Overview: editMode || isShare ? "Active" : "default",
    Sales: "default",
    Tasks: "default",
    Questions: "default",
    Product: "default",
    Payments: "default",
    DoDonts: "default",
  });
  const QuestionTypes = ["verification", "checkbox", "open", "select"];
  const [wasActive, setActive] = useState<Record<string, boolean>>({
    Type: true,
    Overview: false,
    Sales: false,
    Tasks: false,
    Questions: false,
    Product: false,
    Payments: false,
    DoDonts: false,
  });

  const [currentTab, setCurrentTab] = useState("Type");

  const handleTabClick = (tab: string) => {
    if (!tab) return;
    setState({ currentTab: Tab[tab as keyof typeof Tab] });
    setCurrentTab(tab);
    setTabsStatus((prevTabsStatus) => ({
      ...prevTabsStatus,
      [currentTab]: "default",
      [tab]: "Active",
    }));
    setActive((prevTabsStatus) => ({
      ...prevTabsStatus,
      [tab]: true,
    }));
    window.scrollTo(0, 0);
  };
  const checkCanSaveQuestions = () => {
    const { questions } = state.fields;
    if (!questions || !questions.length) {
      return state.fields.overview.ribbon !== "survey";
    }
    return (
      questions.filter(
        (x: any) =>
          !x.question ||
          (x.question_type === QuestionTypes[3] &&
            (!x.options || x.options.filter((xx: any) => !!xx).length === 0)),
      ).length === 0
    );
  };
  const getOverviewStatus = () => {
    if (tabsStatus.Overview === "Active") {
      return "Active";
    }
    if (
      overview.title &&
      overview.brand.brand_name &&
      overview.description &&
      overview.description.length &&
      (overview.categories ? overview?.categories[0] : false) &&
      isValidUrl(overview.auction_link.long_link) &&
      !state.errorFields.includes("short_link") &&
      !overview?.auction_link?.long_link === !state.shortLink
    ) {
      return "Ready";
    }
    if (wasActive.Overview || editMode) {
      return "Not finished";
    }
    return "default";
  };

  const getTasksStatus = () => {
    if (tabsStatus.Tasks === "Active") {
      return "Active";
    }
    if (state.isTasksValid) {
      return "Ready";
    }
    if (wasActive.Tasks || editMode) {
      return "Not finished";
    }
    return "default";
  };
  const getSalesStatus = () => {
    if (tabsStatus.Sales === "Active") {
      return "Active";
    }
    if (checkSaveSales() && !checkSalesForErrors(state.fields.sales, false)) {
      return "Ready";
    }
    if (wasActive.Sales || editMode) {
      return "Not finished";
    }
    return "default";
  };
  const getQuestionStatus = () => {
    if (tabsStatus.Questions === "Active") {
      return "Active";
    }
    if (
      (checkCanSaveQuestions() && wasActive.Questions) ||
      (checkCanSaveQuestions() && editMode)
    ) {
      return "Ready";
    }
    if (wasActive.Questions) {
      return "Not finished";
    }
    return "default";
  };
  const getTabStatus = (tab: string) => {
    if (tabsStatus[tab] === "Active") {
      return "Active";
    }
    if (wasActive[tab] || editMode || isShare) {
      return "Ready";
    }
    return "default";
  };

  useEffect(() => {
    setState({ tabs: getTabs(state.fields.sales?.sales_enabled) });
    if (currentTabProp) {
      const indexOfTab = Object.values(Tab).indexOf(
        currentTabProp as unknown as Tab,
      );
      const key = Object.keys(Tab)[indexOfTab];
      handleTabClick(key);
    }
  }, [currentTabProp]);

  return (
    <Box className="center-bar">
      {!isShare && (
        <>
          <NavigateBox
            text="Type"
            icon="fa-solid fa-megaphone"
            status={getTabStatus("Type")}
            onClick={() => handleTabClick("Type")}
          />
          <i
            className="fa-solid fa-chevron-right fa-lg"
            style={{ color: "#D6D6D6" }}
          ></i>
        </>
      )}
      <NavigateBox
        text="Overview"
        icon="fa-solid fa-pen"
        status={getOverviewStatus()}
        onClick={() => {
          handleTabClick("Overview");
          if (getOverviewStatus() === "Not finished") {
            onChangeTabShowErrors("overview_tab");
          }
        }}
      />

      <i
        className="fa-solid fa-chevron-right fa-lg"
        style={{ color: "#D6D6D6" }}
      ></i>
      {state.fields.sales?.sales_enabled && !isShare && (
        <>
          <NavigateBox
            text="Sales"
            icon="fa-solid fa-badge-percent fa-lg"
            status={getSalesStatus()}
            onClick={() => {
              handleTabClick("Sales");
              if (getSalesStatus() === "Not finished") {
                onChangeTabShowErrors("sales_tab");
              }
            }}
          />
          <i
            className="fa-solid fa-chevron-right fa-lg"
            style={{ color: "#D6D6D6" }}
          ></i>
        </>
      )}
      <NavigateBox
        text="Tasks"
        icon="fa-solid fa-list-check"
        status={getTasksStatus()}
        onClick={() => {
          handleTabClick("Tasks");
          if (getTasksStatus() === "Not finished") {
            onChangeTabShowErrors("tasks_tab");
          }
        }}
      />
      <i
        className="fa-solid fa-chevron-right fa-lg"
        style={{ color: "#D6D6D6" }}
      ></i>
      <NavigateBox
        text="Questions"
        icon="fa-solid fa-circle-question fa-lg"
        status={getQuestionStatus()}
        onClick={() => {
          handleTabClick("Questions");
        }}
      />
      <i
        className="fa-solid fa-chevron-right fa-lg"
        style={{ color: "#D6D6D6" }}
      ></i>
      <NavigateBox
        text="Product"
        icon="fa-solid fa-bag-shopping"
        status={getTabStatus("Product")}
        onClick={() => {
          handleTabClick("Product");
        }}
      />
      <i
        className="fa-solid fa-chevron-right fa-lg"
        style={{ color: "#D6D6D6" }}
      ></i>
      {!isShare && (
        <>
          <NavigateBox
            text="Payments"
            icon="fa-solid fa-circle-dollar"
            status={getTabStatus("Payments")}
            onClick={() => {
              handleTabClick("Payments");
              onChangeTabShowErrors("payments_tab");
            }}
          />
          <i
            className="fa-solid fa-chevron-right fa-lg"
            style={{ color: "#D6D6D6" }}
          ></i>
        </>
      )}

      <NavigateBox
        text="Do & Dont's"
        icon="fa-solid fa-thumbs-up"
        status={getTabStatus("DoDonts")}
        onClick={() => {
          handleTabClick("DoDonts");
        }}
      />
    </Box>
  );
};

export default CreateCampaignBar;
