import { Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { InfluencerInManageAuctionData, TaskManageData } from "src/api/types";
import {
  getLabelFromValue,
  get_post_type_icon,
} from "src/components/feed_components/utilities/providers";

type Props = {
  influencer: InfluencerInManageAuctionData;
  setShowTasks: (bool: boolean) => void;
  handleAttachPost: (task: TaskManageData) => void;
  showTasks: boolean;
  board_id: number;
  post: any;
  removeMedia: (id: string) => void;
  setOpenTaskDialog: (bool: boolean) => void;
};

const AttachTasksList = (props: Props) => {
  const unAttachedTasks = useMemo(() => {
    const tasks = props.influencer?.tasks_details?.tasks || [];
    const tasksInvites = props.influencer?.tasks_invites || [];

    const combinedTasks = [...tasks, ...tasksInvites];
    return combinedTasks.filter(
      (task: any) =>
        task.post_type === props.post.post_type &&
        ((!task.linked_media_id && task.post_type !== "ig_story") ||
          (task.post_type === "ig_story" && !task.subtask_of)),
    );
  }, [
    props.influencer?.tasks_details?.tasks,
    props.post,
    props.influencer?.tasks_invites,
  ]);

  return (
    <div className="attach-tasks-box">
      {unAttachedTasks?.map((task: any, index: number) => (
        <div
          key={index}
          onClick={() => {
            props.handleAttachPost(task);
            props.setShowTasks(!props.showTasks);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#4D4D4D",
            }}
            className="hover-effect"
          >
            <div style={{ position: "relative" }}>
              <i
                className={`${get_post_type_icon(task.post_type)} colored`}
                style={{
                  margin: "2px",
                  padding: "5px",
                  width: "30px",
                  fontSize: "18px",
                }}
              />
              {task.linked_media_id && (
                <Box
                  style={{
                    width: 13,
                    height: 13,
                    borderRadius: "50%",
                    background:
                      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%",
                    position: "absolute",
                    top: "18px",
                    right: "5px",
                    cursor: "pointer",
                    fontSize: 8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 2,
                  }}
                >
                  <i
                    style={{ fontWeight: 800, color: "white" }}
                    className="fa fa-repeat"
                  ></i>
                </Box>
              )}
            </div>

            <div style={{ display: "flex" }} className="hover-effect">
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: 500,
                  width: "50px",
                }}
              >
                {`Task ${index + 1}`}
              </span>
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {getLabelFromValue(task.post_type)}
              </span>
            </div>
          </div>
          <div
            style={{
              height: "1px",
              backgroundColor: "#EBEBEB",
            }}
          ></div>
        </div>
      ))}
      <div>
        <div
          className="blue-text-button"
          onClick={() => props.setOpenTaskDialog(true)}
        >
          {" "}
          <i
            className="fa-solid fa-plus fa-sm"
            style={{ marginRight: "3px" }}
          ></i>
          <span>New Task</span>
        </div>
      </div>
    </div>
  );
};

export default AttachTasksList;
