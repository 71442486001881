import {
  Button,
  Flex,
  IconButton,
  Input,
  useClipboard,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  value: string;
};

export const CopyToClipboard: React.FC<
  Props & React.ComponentProps<typeof Flex>
> = ({ value, ...otherProps }) => {
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <Flex {...otherProps}>
      <Input
        value={value}
        onFocus={(e) => {
          e.target.select();
        }}
        isReadOnly
        overflow="hidden"
        borderEndRadius="0"
      />
      {hasCopied ? (
        <Button
          w="28"
          borderStartRadius="0"
          tabIndex={-1}
          style={{ fontSize: "12px" }}
        >
          Copied!
        </Button>
      ) : (
        <IconButton
          w="28"
          borderStartRadius="0"
          tabIndex={-1}
          icon={<div className="fa fa-clipboard" />}
          aria-label="Copy to clipboard"
          onClick={onCopy}
        />
      )}
    </Flex>
  );
};
