// TODO - lint
/* eslint-disable no-unused-vars, react/no-direct-mutation-state, no-unused-vars, import/no-default-export */
import React from "react";
import { connect } from "react-redux";
import Clipboard from "react-clipboard.js";
import Tooltip from "rc-tooltip";
import { MessageDialog } from "../../../dialogs/MessageDialog";
import { editAmbassadorInfluencerShortLink } from "../../../../../actions/ambassadorReportsActions";

class ReplaceAmbassadorShortLinkDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorFields: [],
      isLoading: false,
      shortLink: null,
      longLink: null,
    };
  }

  componentDidMount() {
    this.addOrRemoveError("shortLink", "remove");
    const { longLink } = { ...this.props };
    const { shortLink } = { ...this.props };

    this.setState({ shortLink, longLink });
  }

  addOrRemoveError(name, type) {
    const { errorFields } = this.state;
    if (type === "add") {
      if (!errorFields.includes(name)) errorFields.push(name);
    } else if (errorFields.includes(name)) {
      errorFields.addOrRemove(name);
    }
    this.setState({ errorFields });
    this.setState({ updated: true });
  }

  changeInfluencerShortLink() {
    this.addOrRemoveError("shortLink", "remove");
    this.props.editAmbassadorInfluencerShortLink(
      this.props.reportId,
      {
        short_link: {
          long_link: this.state.longLink,
          short_link: this.state.shortLink,
        },
        influencer_id: this.props.influencer.id,
      },
      "ambassador_report",
      (data) => {
        this.setState({ isLoading: false });
        window.location.reload();
        this.props.handleClose();
      },
      () => {
        this.addOrRemoveError("shortLink", "add");
      },
    );
  }

  changeLongLink = (e) => {
    const { value } = e.target;
    const longLink = value === this.state.DEFAULT_LINK ? "" : value;
    this.setState({ shortLinkLoading: false, longLink });
  };

  render() {
    const { shortLink } = this.state;
    return (
      <MessageDialog
        handleClose={() => {
          this.props.handleClose();
        }}
        icon="fas fa-user-circle"
      >
        <div style={{ textAlign: "center", padding: 20 }}>
          <div className="create-section">
            <div className="campaigns-title">
              <span style={{ fontSize: 24 }}>
                Links{" "}
                <span className="optional-title">
                  (Optional, both fields should be filled if used)
                </span>
              </span>
            </div>
            <div className="input-row">
              <strong className="label-title">Short link</strong>
              <div className="blurable-large">
                <div className="flex">
                  <input
                    disabled
                    className="input-line disabled flex-2"
                    value={`${shortLink}/${this.props.influencer.id}`}
                    placeholder={`${this.DEFAULT_LINK}campaign-name`}
                    type="text"
                  />
                  <Clipboard
                    style={{ background: "transparent", border: "none" }}
                    data-clipboard-text={`${shortLink}/${this.props.influencer.id}`}
                  >
                    <Tooltip overlay={"Copy"}>
                      <i className="far fa-copy copy-btn" />
                    </Tooltip>
                  </Clipboard>
                </div>
              </div>
            </div>

            <div className="input-row">
              <strong className="label-title">redirect to</strong>
              <div className="blurable-large">
                <input
                  className="input-line"
                  onBlur={(e) => {
                    this.changeLongLink(e);
                  }}
                  defaultValue={this.state.longLink}
                  onChange={(e) => {
                    this.state.longLink = e.target.value;
                    this.setState({});
                  }}
                  placeholder="Your website url"
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => this.changeInfluencerShortLink()}
            className="btn btn-primry"
            style={{
              background: "var(--main-baby-blue-color)",
              color: "white",
              borderRadius: "40px",
              width: 100,
              marginTop: 15,
            }}
          >
            Change
          </button>
        </div>
      </MessageDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    editAmbassadorInfluencerShortLink: (
      reportId,
      shortLink,
      type,
      callback,
      errorCallbak,
    ) => {
      dispatch(
        editAmbassadorInfluencerShortLink(
          reportId,
          type,
          shortLink,
          callback,
          errorCallbak,
        ),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReplaceAmbassadorShortLinkDialog);
