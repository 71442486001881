// TODO - lint
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention, @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention, @typescript-eslint/ban-ts-comment, react/no-direct-mutation-state, @typescript-eslint/no-unused-vars, import/no-default-export */
import { Component } from "react";
import { connect } from "react-redux";
// @ts-ignore
import ToggleButton from "react-toggle-button";
import { updateListMiniUsers } from "../../../actions/enterpriseActions";
import NoImage from "../../../images/svgs/placeholder.svg";

interface OwnProps {
  list: any;
  all_users: any;
  handleClose: any;
  all_sub_users_allowed: false;
  mini_users: [];
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{
  loading?: boolean;
  all_sub_users_allowed?: any;
  mini_users?: any;
}>;

class EnterpriseSelectListUsers extends Component<Props, State> {
  readonly state: State = {};

  componentDidMount(): void {
    this.setState({
      loading: false,
      all_sub_users_allowed: this.props.list
        ? this.props.list.all_sub_users_allowed
        : false,
      mini_users: this.props.list ? this.props.list.sub_users_allowed : [],
    });
  }

  selectUser = (user: any) => {
    const users = [...this.state.mini_users];
    users.addOrRemove(user.id);
    this.setState({ mini_users: users }, () => {
      this.changeAuctionMiniUsers(this.props.list);
    });
  };

  renderRow = (user: any) => {
    const original_creator = this.props.list.original_creator_id === user.id;
    const checked =
      (this.state.mini_users && this.state.mini_users.includes(user.id)) ||
      original_creator;
    return (
      <div
        className={`import-list-row sub-users-list ${checked}`}
        // @ts-ignore
        disabled={original_creator}
        key={`sub-users-list${user.id}`}
        onClick={() => {
          this.selectUser(user);
        }}
      >
        <div style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }}>
          <img className="blurable" src={user.image ? user.image : NoImage} />
        </div>
        <div
          className={`import-list-row-details import-user-row-details ${checked}`}
        >
          <strong className="invite-user-name blurable-large" title={user.name}>
            {user.name}
          </strong>
        </div>
        <div
          className={"checkbox "}
          style={{ padding: 0, paddingTop: 0, marginLeft: -10 }}
        >
          <label>
            <input type="checkbox" readOnly checked={checked} />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </div>
    );
  };

  changeAuctionMiniUsers = (c: any) => {
    this.setState({ loading: true });
    this.props.updateListMiniUsers(
      c.id,
      {
        all_mini_allowed: this.state.all_sub_users_allowed,
        allowed_mini_users: this.state.mini_users,
      },
      () => {
        this.setState({ loading: false });
      },
    );
  };

  render() {
    const c = this.props.list;
    if (!c) return null;
    const all_users = this.props.all_users ? this.props.all_users : [];
    return (
      <div
        style={{
          width: 750,
          marginLeft: "calc(100% - 970px)",
          position: "fixed",
          top: 0,
        }}
        className="import-from-list-container animated fadeInRight faster"
        id="enterprise_select_list_users_menu"
      >
        {this.state.loading ? (
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        ) : (
          <div className="import-from-list-inner-container">
            <div className="header">
              <div style={{ fontSize: 22 }}>Edit users for list {c.name}</div>
              <div
                className="import-list-close-button"
                onClick={() => this.props.handleClose()}
              >
                <span className="moi-ximage" />
              </div>
            </div>
            <div className="content" style={{ marginTop: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <label htmlFor="">All sub users allowed?</label>
                <ToggleButton
                  value={this.state.all_sub_users_allowed}
                  onToggle={() => {
                    // @ts-ignore
                    this.state.all_sub_users_allowed =
                      !this.state.all_sub_users_allowed;
                    this.changeAuctionMiniUsers(c);
                  }}
                  inactiveLabel={"No"}
                  activeLabel={"Yes"}
                />
              </div>
              {all_users &&
                all_users.map((u: any) => {
                  return this.renderRow(u);
                })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.userReducer,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  updateListMiniUsers: (id: bigint, data: any, callback: any) =>
    dispatch(updateListMiniUsers(id, data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterpriseSelectListUsers);
