import SockJS from "sockjs-client";
import ReconnectingWebSocket from "reconnecting-websocket";
import { toast } from "react-toastify";
import { store } from "../store";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";
import { transalteNotifData } from "../components/feed_components/utilities/general";

let sock;

const authHeaderKey = "X-MoiBackendAuthorization";
const allowedTransports = [
  "websocket",
  "xhr-streaming",
  "xdr-streaming",
  "eventsource",
  "iframe-eventsource",
  "htmlfile",
  "iframe-htmlfile",
  "xhr-polling",
  "xdr-polling",
  "iframe-xhr-polling",
];
// var allowedTransports = ['eventsource', 'iframe-eventsource', 'htmlfile', 'iframe-htmlfile', 'xhr-polling', 'xdr-polling', 'iframe-xhr-polling'];
/* var options = {
 'transports': allowedTransports
}; */

export const getSock = () => {
  return sock;
};

export const closeSocket = () => {
  if (sock) {
    try {
      sock.close();
    } catch (err) {
      console.log(err);
    }
  }
};

const getWebSocketUrl = () => {
  const token = encodeURI(getSavedJwtToken());
  return `${getBackendUri()}/push?${authHeaderKey}=${token}&client=browser`;
};

export const InitSock = () => {
  //
  // var authInQueryString = getWebSocketUrl();
  // console.log(authInQueryString);
  // userId = id;
  const reconnectingOptions = { constructor: SockJS, debug: true };
  sock = new ReconnectingWebSocket(
    getWebSocketUrl,
    allowedTransports,
    reconnectingOptions,
  );
  //  sock = new SockJS(authInQueryString, null, options);
  // sock.onopen = function(){
  //     console.log('sock open');
  // };
  sock.addEventListener("message", function (e) {
    const data = JSON.parse(e.data);
    // todo: Remove when support center is developed
    if (data?.object_type?.toLowerCase() === "support") {
      return;
    }
    if (data.message_type === "notification") {
      store.dispatch({ type: "ADD_NOTIF_TO_COUNTER", payload: data });
      const translatedText = transalteNotifData({
        notification_data: data,
      }).data_text;
      notificationOpts.title = window.translate(
        "Notification",
        "system_notifications_info",
      );
      notificationOpts.message = translatedText;
      toast.info(notificationOpts.message);
    } else if (data.message_type === "message") {
      const time = new Date(e.timeStamp);
      data.recived_time = time;
      store.dispatch({ type: "ADD_TO_INBOX_COUNTERS", payload: data });
    } else if (data.message_type === "inbox_inserted") {
      const eventGuid = data.event_guid;
      const { inboxReducer } = store.getState();

      if (eventGuid in inboxReducer.read_messages) {
        const messageData = inboxReducer.read_messages[eventGuid];
        messageData.message_type = "message_seen_response";
        messageData.message_id = data.inbox_message_id;
        messageData.seen = true;

        sock.send(JSON.stringify(messageData));
        delete inboxReducer.read_messages[eventGuid];
      }
    }
  });
  // sock.onclose = function(e){
  //   console.log("SOCKET CLOSING");
  //   if(closingSockjs){
  //     console.log("NOT REFRESHING SOCKET");
  //     closingSockjs = false;
  //     return;
  //   }
  //   console.log("REFRESHING SOCKET");
  //   InitSock(userId);
  // };
};
