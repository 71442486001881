type ConditonalWrapperProps = {
  condition: boolean;
  children: React.ReactNode;
  wrapper: (children: React.ReactNode) => JSX.Element;
};
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditonalWrapperProps) => (condition ? wrapper(children) : children);
