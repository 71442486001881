import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";

const FeedbackModal = ({
  description,
  onSubmit,
}: {
  description: string;
  onSubmit: (rate: number, comment: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [rate, setRate] = useState<number>(-1);
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    if (rate > -1) {
      setIsOpen(false);
      onSubmit(rate + 1, comment);
    }
  };

  const faces = [
    "face-pensive",
    "face-frown-slight",
    "face-meh",
    "face-smile",
    "face-laugh",
  ];

  const onClose = () => {
    setIsOpen(false);
    onSubmit(-1, "");
  };

  if (window.innerWidth < 700) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent
        className="middle-modal"
        style={{
          background: "#f8faff",
          color: "#4b537c",
          width: "100%",
          maxWidth: "450px",
          padding: 20,
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <ModalBody>
          <Text fontWeight={"700"} fontSize={16}>
            Give us a feedback
          </Text>
          <Text mt={"10px"}>{description}</Text>
          <Flex justifyContent={"center"} marginTop={"20px"}>
            {faces.map((icon, index) => (
              <Button
                variant={"ghost-non-focusable"}
                key={index}
                onClick={() => setRate(index)}
                isActive={index === rate}
                fontSize={30}
                padding={"30px"}
              >
                <i className={`fa-light fa-${icon}`}></i>
              </Button>
            ))}
          </Flex>
          <Box mt={"20px"}>
            <Text>Can you elaborate more? (optional)</Text>
            <Textarea
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Flex justifyContent={"space-between"} w="100%">
            <Button variant={"unstyled"} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={handleSubmit}
              isDisabled={rate < 0}
              variant="solid"
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
