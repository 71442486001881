import { Textarea } from "@chakra-ui/react";
import { useState } from "react";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";

const textStyle = {
  color: "var(--black-1, #333)",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14px",
  padding: 0,
  border: "none",
  minHeight: "42px",
  // maxHeight: "1000px",
  margin: 0,
};

const buttonStyle = {
  color: "var(--black-1, #333)",
  fontSize: "12px",
  fontWeight: 400,
  padding: 0,
  margin: 0,
  cursor: "pointer",
};

const DisplayTaskDescription = (props: {
  disable: boolean;
  task: AuctionToDoItem;
  campaignId: string;
  handleEditTask: (taskId: number, taskData: AuctionToDoItem) => void;
}) => {
  const [description, setDescription] = useState(props.task.description);
  const [expanded, setExpanded] = useState(false);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };
  const handleDescriptionBlur = () => {
    if (!props.disable) {
      props.handleEditTask(props.task.id, {
        ...props.task,
        description,
      });
    }
    setIsTextareaFocused(false);
    setExpanded(false);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const calculateNumRows = () => {
    const lines = description.split("\n");
    let calculatedHeight = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const line of lines) {
      const nonNewlineChars = line.length; // Count all characters, including whitespace
      calculatedHeight += Math.max(1, Math.ceil(nonNewlineChars / 35)); // Ensure at least one row for empty lines
    }

    return calculatedHeight;
  };

  const handleDescriptionFocus = () => {
    setIsTextareaFocused(true);
  };
  return (
    <>
      <Textarea
        style={textStyle}
        readOnly={props.disable}
        value={description}
        onChange={handleDescriptionChange}
        onBlur={handleDescriptionBlur}
        onFocus={handleDescriptionFocus}
        placeholder="Description"
        overflow="hidden"
        rows={isTextareaFocused || expanded ? calculateNumRows() : 3}
      />
      {!isTextareaFocused && calculateNumRows() > 3 && (
        <span
          onClick={toggleExpanded}
          className="clickable-span"
          style={buttonStyle}
        >
          {expanded ? "Show less" : "Read more..."}
        </span>
      )}
    </>
  );
};

export default DisplayTaskDescription;
