import { Box } from "@chakra-ui/react";
import Select from "react-select";

import moment from "moment";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AuctionDataExtended } from "src/api/ExtendedTypes";
import { Target, TargetMetrics } from "src/api/types";
import { RootState } from "src/declarations/react-redux";
import Indicator from "../../CreateCampaignSection/FormUI/Indicator";
import IndicatorInput from "../../CreateCampaignSection/FormUI/IndicatorInput";
import { FormAuctionGoal } from "./CampaignTargetsModal";

interface ICampaignTargetSelect {
  list?: { id?: number; option: string; value?: string }[];
  label: string;
  handleSelectChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  value: string | number;
  placeHolder?: string;
  handleTextfieldChange?: (newValue: string) => void;
  borderLine?: boolean;
  isRequired?: boolean;
  error?: string;
  defaultValue?: number | string;
  isHidden?: boolean;
  board?: AuctionDataExtended;
  auctionGoals?: FormAuctionGoal[];
  influencerId?: number;
  isLocked?: boolean;
}

const CampaignTargetSelect = ({
  list,
  label,
  handleSelectChange,
  value,
  handleTextfieldChange,
  placeHolder,
  isRequired,
  error,
  defaultValue,
  isHidden,
  board,
  auctionGoals,
  influencerId,
  isLocked,
}: ICampaignTargetSelect) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const { board_targets } = useSelector(
    (state: RootState) => state.boardReducer,
  );

  const influencerTargets = board_targets?.influencers[influencerId]?.filter(
    (target) =>
      moment(target?.end_time || target?.steps?.[0]?.month_relevance).isAfter(
        moment(),
      ),
  ) as Target[];

  useEffect(() => {
    if (defaultValue !== currentValue) {
      setCurrentValue(defaultValue);
    }
  }, [defaultValue, currentValue]);

  const shouldRenderOption = (value: string) => {
    switch (value) {
      case TargetMetrics.sales_value:
        return board?.summary?.pixel_enabled && !board.one_month_campaign;
      case TargetMetrics.clicks:
        return !!board?.summary?.short_link;
      default:
        return true;
    }
  };

  const options = useMemo(
    () =>
      list
        ?.map((option: any, index) => {
          const renderOption = shouldRenderOption(option?.value);
          const shouldDisableOption = (
            influencerTargets
              ? [...influencerTargets, ...auctionGoals]
              : auctionGoals
          )?.some((goal) => goal?.target_metric === option?.value);

          return renderOption
            ? {
                key: option.id || index + 1,
                label: option?.option,
                value: option?.value,
                disabled: shouldDisableOption,
              }
            : null;
        })
        .filter((o) => o) || [],
    [list, auctionGoals, influencerTargets, board],
  );
  return (
    <Box className={`target-select`}>
      {list ? (
        <Indicator header={label}>
          <Box sx={{ width: 178 }} className={isLocked && "disabled"}>
            <Select
              clearable={false}
              placeholder={placeHolder}
              searchable={false}
              menuStyle={{ maxHeight: 160 }}
              value={currentValue}
              onChange={(option: any) => {
                handleSelectChange({
                  target: { value: option?.value || null },
                } as any);
              }}
              style={{ height: "37px" }}
              options={options}
            />
            {!!error && (
              <div>
                <i className="error-mark" />
                <span className="error-text">{error || "Error"}</span>
              </div>
            )}
          </Box>
        </Indicator>
      ) : (
        <Box mt="4px">
          <IndicatorInput
            header={label}
            className="campaign-target__textfield"
            onChange={handleTextfieldChange}
            value={String(value)}
            placeholder={placeHolder}
            required={isRequired as true}
            error={!!error}
            helperText={error}
            width="211px"
            hiddenIndicator={isHidden}
          />
        </Box>
      )}
    </Box>
  );
};

export default CampaignTargetSelect;
