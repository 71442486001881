import { Box, Skeleton, Text } from "@chakra-ui/react";
import moment from "moment";
import { useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { HomepageDashboard } from "src/api/types";
import { formatBigNumbers } from "../../utilities/general";

type Props = {
  loading?: boolean;
  data: HomepageDashboard["sales_daily"];
};

const DashboardChart = (props: Props) => {
  const user = useSelector((store) => store.userReducer?.userDetails);
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {formatBigNumbers(payload[0]?.value)}
          {window.translate("", `money_symbol_${user.currency}`)}
        </div>
      );
    }
    return null;
  };

  // eslint-disable-next-line no-nested-ternary
  return props?.loading ? (
    <Box
      p={4}
      display="flex"
      gap={10}
      alignItems={"flex-end"}
      justifyContent={"space-evenly"}
      height={"100%"}
    >
      <Skeleton width={7} height={"80px"} />
      <Skeleton width={7} height={"180px"} />
      <Skeleton width={7} height={"110px"} />
      <Skeleton width={7} height={"130px"} />
      <Skeleton width={7} height={"80px"} />
      <Skeleton width={7} height={"120px"} />
      <Skeleton width={7} height={"100px"} />
    </Box>
  ) : props?.data?.length ? (
    <>
      <Text paddingTop={4} textAlign="center">
        Total Sales
      </Text>
      <BarChart
        width={435}
        height={338}
        data={props.data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        barSize={20}
      >
        <defs>
          {props.data.map((d, i) => (
            <linearGradient
              key={i}
              id={`colorUv${d.date}`}
              x1="0"
              y1="0"
              y2="100%"
              x2="0"
              spreadMethod="reflect"
            >
              <stop offset="0" stopColor="#249FF0" />
              <stop offset="1" stopColor="rgba(36, 159, 240, 0.28)" />
            </linearGradient>
          ))}
        </defs>
        <XAxis
          dataKey="date"
          axisLine={false}
          label={{ fontSize: 10 }}
          padding={{ left: 10, right: 10 }}
          tickFormatter={(tick: any) =>
            moment(tick).format(window.time.short_date)
          }
        />
        <YAxis axisLine={false} label={{ fontSize: 10 }} />
        <CartesianGrid strokeDasharray="5" vertical={false} />
        <Bar dataKey="sales_value" radius={[20, 20, 0, 0]}>
          {props.data.map((d, i) => (
            <Cell key={i} fill={`url(#colorUv${d.date})`} />
          ))}
        </Bar>

        <Tooltip
          content={CustomTooltip}
          cursor={{ fill: "rgba(255,255,255,0.3)" }}
        />
      </BarChart>
    </>
  ) : (
    <Box
      height={"100%"}
      justifyContent={"center"}
      alignItems="center"
      textAlign={"center"}
      display={"flex"}
    >
      <Text color="gray" fontWeight={600} fontSize="16">
        No sales data yet
      </Text>
    </Box>
  );
};

export default DashboardChart;
