// TODO - lint
/* eslint-disable consistent-return, import/no-default-export */
import React, { Component } from "react";
import Dialog from "rc-dialog";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { stopReport } from "../../../../../actions/ambassadorReportsActions";

class AmbassadorSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportToStop: null,
      deleteReportDialogOpened: false,
      saving: false,
    };
  }

  stopReport() {
    if (!this.props.reportToStop || !this.state.deleteReportDialogOpened) {
      return;
    }
    return (
      <Dialog
        onClose={() => {
          this.setState({ deleteReportDialogOpened: false });
        }}
        className="new-report-dialog"
        visible={true}
        style={{ marginTop: 50 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">Stop report</div>
        </div>

        <div style={{ paddingBottom: 5, paddingTop: 10, textAlign: "center" }}>
          <p>
            Are you sure you want to stop this report? This action is
            irreversible.{" "}
          </p>
          <div className={"preview-footer-buttons"}>
            <button
              className={`attach-links-btn btn btn-primary ${
                this.state.saving && "disabled"
              }`}
              onClick={() => {
                this.setState({ deleteReportDialogOpened: false });
                this.props.stopReport(this.props.reportToStop, () => {
                  this.props.navigate(`/insights`);
                });
                // this.props.getStandaloneReports();
              }}
            >
              <span>STOP</span>
            </button>
            <button
              className={`attach-links-btn btn${
                this.state.saving && "disabled"
              }`}
              onClick={() => this.setState({ deleteReportDialogOpened: false })}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  renderSummary() {
    return (
      <div className={`billing-summary ${this.props.className}`}>
        <div className="title">
          <div>
            <i className="fas fa-money-check" /> Summary
          </div>
        </div>
        <div className="summary-items flex">
          <div className="summary-item">
            <div className="summary-item-title">Next billing cycle</div>
            <div className="summary-item-content">
              {this.props.next_billing_cycle}
            </div>
          </div>
          <div className="summary-item">
            <div className="summary-item-title">Next billing payment</div>
            <div className="summary-item-content">
              {this.props.next_billing_payment}
            </div>
          </div>
          <div className="summary-item">
            <div className="summary-item-title">Current package</div>
            <div className="summary-item-content">
              {this.props.current_package}
            </div>
          </div>
          <div className="summary-item">
            <div className="summary-item-title">Total ambassadors</div>
            <div className="summary-item-content">
              {this.props.total_ambassadors}
            </div>
          </div>
          {!this.props.isCollapse && (
            <div className="summary-item">
              <button
                className={"btn btn-gray"}
                onClick={() =>
                  this.setState({ deleteReportDialogOpened: true })
                }
              >
                Stop report
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderSummary()}
        {this.stopReport()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    stopReport: (listId, callback) => {
      dispatch(stopReport(listId, callback));
    },
  };
};

const AmbassadorSummaryConnected = connect(
  null,
  mapDispatchToProps,
)(AmbassadorSummary);

export default (props) => {
  const navigate = useNavigate();

  return <AmbassadorSummaryConnected {...props} navigate={navigate} />;
};
