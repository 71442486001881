import { toast } from "react-toastify";
import { toggleInfluencersNextMonth } from "src/api/actions/auctionActions";
import { InfluencerInManageAuctionData } from "src/api/types";
import { MessageDialog } from "../../dialogs/MessageDialog";

interface OwnProps {
  openTrashDialog: InfluencerInManageAuctionData;
  onDispute: any;
  onClose: any;
  callback: any;
  selectedColumn: number;
  user: any;
  sendToTrash: any;
  oneMonthCampaign: boolean;
  auctionId: number;
  refreshBoard?: () => void;
  planNextMonth: boolean;
}
export default function CampaignTrashDialog(props: OwnProps) {
  const stopNextMonth = async () => {
    toggleInfluencersNextMonth(props.auctionId, {
      influencers_ids: [props.openTrashDialog.influencer_id],
      stop: true,
    })
      .then(() => {
        props.refreshBoard();
        toast.success("Influencer stopped from next month");
      })
      .catch(() => {
        toast.error("Failed to stop influencer from next month");
      })
      .finally(() => {
        props.onClose();
      });
  };
  if (props.openTrashDialog?.media_paid?.paid_on) {
    return (
      <MessageDialog handleClose={props.onClose} icon="moi-trash">
        <div style={{ textAlign: "center", padding: 20 }}>
          <strong>Error</strong>
          <p style={{ marginTop: 15 }}>
            The media for the campaign was already paid, therefore this
            influencer can not be trashed.
            <br />
            <br />{" "}
            <strong>
              In order to trash the influencer you can open a dispute.
            </strong>{" "}
            <br />
            <br />
            <button
              className={"btn btn-primary"}
              onClick={props.onDispute}
              style={{ marginRight: 6 }}
            >
              Open a dispute
            </button>
            <button className={"btn btn-default"} onClick={props.onClose}>
              Cancel
            </button>
          </p>
        </div>
      </MessageDialog>
    );
  }
  if (
    props.selectedColumn === 2 &&
    props.user.userDetails.sidebar_data.pay_per_bid
  ) {
    return (
      <MessageDialog handleClose={props.onClose} icon="moi-trash">
        <div style={{ textAlign: "center", padding: 20 }}>
          <strong>Notice</strong>
          <p style={{ marginTop: 15 }}>
            In order to send to delete selected influencer you need to contact
            us at info@humanz.ai
          </p>
        </div>
      </MessageDialog>
    );
  }
  if (
    (props.planNextMonth && !props.openTrashDialog.start_from) ||
    (!props.oneMonthCampaign &&
      props.openTrashDialog?.tasks_details?.tasks?.some(
        (task) => task.completed,
      ))
  ) {
    return (
      <MessageDialog
        handleClose={props.onClose}
        icon="fa-sharp fa-regular fa-octagon-exclamation"
      >
        <div style={{ textAlign: "center", padding: 20 }}>
          <strong>Stop next month</strong>
          {props.planNextMonth && !props.openTrashDialog.start_from ? (
            <p style={{ marginTop: 15 }}>
              Stop working with the influencer{" "}
              <span style={{ color: "#249ff0" }}>
                {props.openTrashDialog.name}{" "}
              </span>{" "}
              <span style={{ fontWeight: "bold" }}>from next month?</span>
            </p>
          ) : (
            <p style={{ marginTop: 15 }}>
              The influencer{" "}
              <span style={{ color: "#249ff0" }}>
                {props.openTrashDialog.name}{" "}
              </span>{" "}
              <span style={{ fontWeight: "bold" }}>can not be trashed</span>{" "}
              because he has completed tasks.
              <br />
              You can stop the influencer from working from next month forward.
            </p>
          )}
          <button
            onClick={stopNextMonth}
            className="btn btn-primry"
            style={{
              background: "var(--main-baby-blue-color)",
              color: "white",
              borderRadius: "40px",
              width: 200,
              marginTop: 15,
            }}
          >
            Stop from next month
          </button>
        </div>
      </MessageDialog>
    );
  }
  return (
    <MessageDialog handleClose={props.onClose} icon="moi-trash">
      <div style={{ textAlign: "center", padding: 20 }}>
        <strong>Notice</strong>
        <p style={{ marginTop: 15 }}>
          Are you sure you want to trash{" "}
          <span style={{ color: "#249ff0" }}>
            {props.openTrashDialog.name} ?{" "}
          </span>
        </p>
        <button
          onClick={() => {
            props.sendToTrash(props.openTrashDialog);
          }}
          className="btn btn-primry"
          style={{
            background: "var(--main-baby-blue-color)",
            color: "white",
            borderRadius: "40px",
            width: 100,
            marginTop: 15,
          }}
        >
          Trash
        </button>
      </div>
    </MessageDialog>
  );
}
