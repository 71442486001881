/* eslint-disable no-nested-ternary */
import { Box, Text } from "@chakra-ui/react";
import RCTooltip from "rc-tooltip";
import { useCallback } from "react";

type Props = {
  social: string;
  data: any;
  openSocialPage: any;
};

export const VerifiedSocialBadge = (props: any) => {
  return (
    <RCTooltip placement="bottom" overlay={`Authorised ${props?.social}`}>
      <span
        style={{
          height: "1em",
          marginRight: 12,
          marginLeft: 2,
          position: "relative",
          ...props?.style,
        }}
      >
        <i
          style={{
            color: "white",
            textShadow: "0 2px 4px rgba(0,0,0,0.5)",
            position: "absolute",
            fontSize: "1em",
          }}
          className="fad fa-badge-check"
        />
        <i
          style={{
            color: "var(--main-baby-blue-color)",
            textShadow: "0 0 5px white",
            position: "absolute",
            fontSize: "0.8em",
            left: 1.5,
            top: 1.5,
          }}
          className="fas fa-badge-check"
        />
      </span>
    </RCTooltip>
  );
};

export const VerifiedSocial = (props: Props) => {
  const isApiVerified = useCallback(() => {
    switch (props?.social) {
      case "instagram":
        return props?.data?.api_details?.permissions?.length;
      case "tiktok":
        return props?.data?.verification_type === "api";
      case "youtube":
      case "twitter":
      default:
        return props?.data !== null;
    }
  }, [props]);
  return (
    <Box
      sx={{
        background: isApiVerified() ? "#D9FAEC" : "transparent",
        borderRadius: "6px",
        padding: "2px 6px",
        textAlign: "center",
        display: "inline-flex",
        alignContent: "center",
        alignItems: "center",
        position: "relative",
        justifyContent: "flex-start",
      }}
    >
      <a
        style={{ cursor: "pointer" }}
        target="_blank"
        href={props.openSocialPage(props?.data?.username, props?.data?.id)}
        rel="noreferrer"
      >
        <Text
          sx={{
            color: isApiVerified() ? "green.500" : "black.500",
            fontWeight: 400,
          }}
        >
          @{props?.data?.username}
        </Text>{" "}
      </a>
      {(
        props?.social === "instagram"
          ? props?.data?.api_details?.permissions?.includes(
              "business_management",
            )
          : isApiVerified()
      ) ? (
        <VerifiedSocialBadge social={props?.social} style={{ zoom: 1.2 }} />
      ) : null}
    </Box>
  );
};
