import { Box, Button, Center, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { StagesAccordion } from "../StagesAccordion";

interface Props {
  isLoading: boolean;
  nextStep: any;
  platform: any;
}

const shopifySteps: any = (setCurrentStep: any) => [
  {
    id: 0,
    title: "Add Humanz App to your Shopify store",
    body: (
      <>
        <Box className="small-step">
          <p>
            Open the link to install Humanz Shopify App <br />
            <Center>
              <Link
                href="https://apps.shopify.com/humanz"
                target={"_blank"}
                onClick={() => setCurrentStep(1)}
              >
                <Button m={5} size="lg" colorScheme={"green"}>
                  Install from Shopify app store&nbsp;
                  <i className="far fa-external-link" />
                </Button>
              </Link>
            </Center>
          </p>
        </Box>
      </>
    ),
  },
  {
    id: 1,
    title: "Install Humanz App",
    body: (
      <>
        <Box className="small-step">
          Click &quot;Add app&quot; and follow the instructions to install it on
          your platform <br />
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/shopify-animation.gif"
            alt=""
            width="400"
          />
        </Box>
      </>
    ),
  },
  {
    id: 2,
    title: "Complete the App installation process",
    body: (
      <>
        <p>
          Make sure you have finished the installation process - now we can
          proceed to test the installation.
        </p>
      </>
    ),
  },
];

const wixSteps: any = (setCurrentStep: any) => [
  {
    id: 0,
    title: "Add Humanz App to your Wix store",
    body: (
      <>
        <Box className="small-step">
          <p>
            Open the link to install Humanz Lite Wix App <br />
            <Center>
              <Link
                href="https://www.wix.com/app-market/humanz-lite"
                target={"_blank"}
                onClick={() => setCurrentStep(1)}
              >
                <Button m={5} size="lg" colorScheme={"green"}>
                  Install from Wix market&nbsp;
                  <i className="far fa-external-link" />
                </Button>
              </Link>
            </Center>
          </p>
        </Box>
      </>
    ),
  },
  {
    id: 1,
    title: "Install Humanz App",
    body: (
      <>
        <Box className="small-step">
          Click &quot;Add to site&quot; and follow the instructions to install
          it on your platform <br />
          <img
            src="https://humanz-assets.s3.eu-west-1.amazonaws.com/humanz-lite-animation.gif"
            alt=""
            width="400"
          />
        </Box>
      </>
    ),
  },
  {
    id: 2,
    title: "Complete the App installation process",
    body: (
      <>
        <p>
          Make sure you have finished the installation process - now we can
          proceed to test your purchase events.
        </p>
      </>
    ),
  },
];

export const Lite = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps(
      props.platform.name === "Wix"
        ? wixSteps(setCurrentStep)
        : shopifySteps(setCurrentStep),
    );
  }, [props]);

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <StagesAccordion
      currentStep={currentStep}
      isLoading={props.isLoading}
      nextStep={nextStep}
      prevStep={prevStep}
      completeStage={props.nextStep}
      steps={steps}
      description={null}
      eventValidation={true}
      allowNextSteps={true}
      setStep={(step: any) => () => setCurrentStep(step.id)}
    />
  );
};
