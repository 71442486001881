import axios from "axios";
import { toast } from "react-toastify";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";

const ambassadorReportsUrlBase = () =>
  `${getBackendUri()}/moi/ambassador_reports`;

export const createAmbassadorReport = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(ambassadorReportsUrlBase(), data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) callback(response);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        console.log(err);
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to create  Ambassador report",
          "system_notifications_failure_createAmbassadorReport",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false, null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const createSingleAmbassador = (influencer, reportId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    if (influencer[0]) influencer = influencer[0]; // convert array to single
    axios
      .post(
        `${ambassadorReportsUrlBase()}/${reportId}/ambassador`,
        influencer,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        console.log(err);
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to create  Ambassador report",
          "system_notifications_failure_createAmbassadorReport",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false, null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const createUnfinishedAmbassador = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${ambassadorReportsUrlBase()}/unfinished`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) callback(response);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        console.log(err);
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to create Unfinished Ambassador report",
          "system_notifications_failure_createUnfinishedAmbassador",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false, null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const editUnfinishedAmbassador = (id, data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(`${ambassadorReportsUrlBase()}/unfinished/${id}`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) callback(response);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        console.log(err);
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to save Unfinished Ambassador report",
          "system_notifications_failure_createUnfinishedAmbassador",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false, null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getUnfinishedAmbassador = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(`${ambassadorReportsUrlBase()}/unfinished/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) callback(response);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get Unfinished Ambassador report",
          "system_notifications_failure_getUnfinishedAmbassador",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false, null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const editAmbassadorReport = (id, data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(`${ambassadorReportsUrlBase()}/${id}`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.bad_rows) {
          callback(false, err.response.data.bad_rows);
        } else {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to edit report",
            "system_notifications_failure_edit_standalone_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const deleteAmbassador = (reportId, groupId, ambassador, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${ambassadorReportsUrlBase()}/${reportId}/group/${groupId}`,
        ambassador,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.bad_rows) {
          callback(false, err.response.data.bad_rows);
        } else {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to edit report",
            "system_notifications_failure_delete_single_ambassador",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getAmbassador = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(`${ambassadorReportsUrlBase()}/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback(response.data);
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback(false);
      });
  };
};

export const stopReport = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });

    axios
      .delete(`${ambassadorReportsUrlBase()}/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Success",
          "system_notifications_success",
        );
        notificationOpts.message = window.translate(
          "Report was stopped successfully",
          "system_notifications_success_get_filtered_ambassador_report",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(response.data);
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback(callback);
      });
  };
};

export const getFilteredAmbassadorReport = (id, data, queryId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${ambassadorReportsUrlBase()}/${id}/filter`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) callback({ ...response, queryId });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        console.log(err);
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get filtered ambassador report",
          "system_notifications_failure_get_filtered_ambassador_report",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false, null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const editAmbassadorInfluencerShortLink = (
  reportId,
  influencerId,
  data,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${ambassadorReportsUrlBase()}/${reportId}/updateInfluencerShortLink`,
        data,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async (response) => {
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit ambassador influencer link",
          "system_notifications_failure_edit_ambassador_inf_link",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback(false);
        }
        console.error(err);
      });
  };
};

export const addStoriesToAmbassador = (
  reportId,
  influencerId,
  data,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${ambassadorReportsUrlBase()}/${reportId}/attach_story/${influencerId}`,
        { views: data },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async (response) => {
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit ambassador influencer stories",
          "system_notifications_failure_edit_ambassador_inf_stories",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback(false);
        }
        console.error(err);
      });
  };
};

export const getAmbassadorPricingPackages = (callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(`${ambassadorReportsUrlBase()}/package_pricing`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(response.data);
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(false);
      });
  };
};

export const addLinkToAmbassador = (reportId, groupId, links, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${ambassadorReportsUrlBase()}/${reportId}/post_link/${groupId}`,
        links,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(response.data);
      })
      .catch(() => {
        notificationOpts.title = window.translate("Failed", "add_link_failed");
        notificationOpts.message = window.translate(
          "Failed to add link. Please make sure the link is valid.",
          "system_notifications_failure_add_link",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(false);
      });
  };
};

export const upgradeAmbassadorPricingPackages = (id, pkg, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${ambassadorReportsUrlBase()}/${id}/package`,
        { new_package: pkg },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(response.data);
      })
      .catch(() => {
        callback(false);
      });
  };
};

export const downgradeAmbassadorPricingPackages = (id, pkg, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(`${ambassadorReportsUrlBase()}/${id}/package`, {
        data: { new_package: pkg },
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(response.data);
      })
      .catch(() => {
        callback(false);
      });
  };
};

export const addAmbassadorFromSuggestions = (reportId, groupId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${ambassadorReportsUrlBase()}/${reportId}/add_from_suggested/${groupId}`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        // dispatch({type: "DEACTIVE_GENERAL_LOADER", payload: true});
        callback(response.data);
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(false);
      });
  };
};

export const getAmbassadorMedias = (reportId, groupId, type, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(
        `${ambassadorReportsUrlBase()}/${reportId}/all_medias/${groupId}?ambassador_type=${type}`,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(response.data);
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(false);
      });
  };
};

export const deleteAmbassadorMedias = (
  mediaId,
  groupId,
  reportId,
  type,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(
        `${ambassadorReportsUrlBase()}/${reportId}/media/${mediaId}/${groupId}?ambassador_type=${type}`,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(response.data);
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(false);
      });
  };
};
