import {
  AspectRatio,
  Box,
  Flex,
  Image as ImageComponent,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { assertUnreachable } from "src/declarations/UtilityTypes";
import { useBoolean } from "src/hooks/coreHooks";

type Props = {
  type: "image" | "video";
  ratio?: number;
  contain?: boolean;
  autoAspectRatio?: boolean;
  soundVideo?: boolean;
} & React.ComponentProps<typeof ImageComponent>;

export const RatioMedia = ({
  ratio,
  type,
  src,
  contain,
  autoAspectRatio,
  soundVideo,
  ...otherProps
}: Props) => {
  const [isMediaLoaded, setMediaLoaded] = useBoolean(false);
  useEffect(() => {
    if (src) {
      switch (type) {
        case "image": {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            setMediaLoaded.on();
          };
          img.onerror = () => {
            setMediaLoaded.on();
          };
          break;
        }
        case "video": {
          setMediaLoaded.on();
          break;
        }
        default:
          assertUnreachable(type);
          break;
      }
    }
  }, [src, setMediaLoaded, type]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isHoverActive, setIsHoverActive] = useBoolean(false);
  useEffect(() => {
    if (videoRef.current) {
      if (isHoverActive) {
        try {
          videoRef.current?.play();
        } catch (e) {
          console.log(e);
        }
      } else {
        videoRef.current?.pause();
        videoRef.current.currentTime = 0;
      }
    }
  }, [isHoverActive]);

  const renderContent = () => {
    return src && isMediaLoaded ? (
      <>
        {type === "image" && (
          <ImageComponent
            style={{ objectFit: contain ? "contain" : "cover" }}
            src={src}
          />
        )}
        {type === "video" && (
          <>
            <video
              onMouseOver={setIsHoverActive.on}
              onMouseOut={setIsHoverActive.off}
              ref={videoRef}
              src={src}
              preload="metadata"
              loop
              muted={!soundVideo}
              controls={soundVideo}
            />
            {isHoverActive ? null : (
              <Box pointerEvents="none">
                <Flex className="camera-icon">
                  <i className="fa fa-video-camera" aria-hidden="true"></i>
                </Flex>
              </Box>
            )}
          </>
        )}
      </>
    ) : (
      <Flex>
        <Spinner />
      </Flex>
    );
  };
  return autoAspectRatio ? (
    <Box {...otherProps} className="auto-aspect-ratio">
      {
        <div
          style={{
            margin: "0 auto",
            justifyContent: "center",
            display: "flex",
            position: "relative",
          }}
        >
          {renderContent()}
        </div>
      }
    </Box>
  ) : (
    <AspectRatio ratio={ratio} {...otherProps}>
      {renderContent()}
    </AspectRatio>
  );
};
