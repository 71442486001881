/* eslint-disable no-param-reassign */
import { LocalTask, MixedTasks } from "src/api/ExtendedTypes";
import {
  InfluencerInManageAuctionData,
  TaskModification,
  TaskNotRemovableReason,
} from "src/api/types";
import uuid4 from "uuid";

export const createMixedTasks = (
  influencer: InfluencerInManageAuctionData,
  tasks: LocalTask[],
  offer?: TaskModification,
): MixedTasks => {
  // Get original bundles from influencer
  const originalBundles = influencer?.tasks_details?.bundles_attributes;
  const bundles: string[] = [];
  tasks?.forEach((task: any) => {
    if (task.bundle_id) {
      if (
        !bundles?.includes(task.bundle_id) &&
        !originalBundles?.find((b: any) => b.bundle_id === task.bundle_id)
      ) {
        bundles.push(task.bundle_id);
      }
    }
  });

  // Push offer bundles to original bundles
  if (offer) {
    // add bundles to original bundles
    offer?.bundles_attributes?.forEach((b: any) => {
      if (b?.bundle_id && !bundles?.includes(b?.bundle_id)) {
        bundles.push(b.bundle_id);
      }
    });
  }

  // Create local bundles with tasks
  const localBundles = bundles?.map((b) => {
    const bundleTasks = tasks.filter((t: any) => {
      return (
        t.bundle_id === b && !offer?.excluded?.includes(t.id) && !t.subtask_of
      );
    });
    // if offer, add new tasks in offer to bundleTasks
    if (offer) {
      offer?.new?.forEach((t: any) => {
        if (b === t.bundle_id)
          bundleTasks.push({
            ...t,
            uuid: t?.uuid || uuid4(),
            index: -1,
            new: true,
          });
      });
    }
    return {
      bundle_id: b,
      price: 0,
      tasks: bundleTasks,
      renews: false,
    };
  });

  // add uuid for tasks
  originalBundles?.forEach((b: any) => {
    b.tasks = tasks
      ?.filter((t: any) => t.bundle_id === b.bundle_id && !t.subtask_of)
      ?.map((t: any) => ({ ...t, uuid: t?.uuid || uuid4() }));
  });

  // Combine original bundles with local bundles
  const combinedBundles = originalBundles?.map((b: any) => {
    const localBundle = localBundles?.find(
      (lb: any) => lb.bundle_id === b.bundle_id,
    );
    if (localBundle) {
      return { ...b, tasks: localBundle.tasks };
    }
    return b;
  });

  // Give index to bundle
  combinedBundles?.forEach((b: any, i: number) => {
    if (b) {
      b.index = i;
    }
  });

  // get single tasks
  let singleTasks = tasks;

  // add tasks from new array inside offer to singleTasks
  if (offer) {
    offer?.new?.forEach((t: any) => {
      singleTasks.push({ ...t, new: true, uuid: t?.uuid || uuid4() });
    });
  }

  singleTasks = singleTasks
    ?.filter((t: any) => {
      return !t.bundle_id && !offer?.excluded?.includes(t.id) && !t.subtask_of;
    })
    ?.map((t: any) => ({ ...t, uuid: t?.uuid || uuid4() }));

  return {
    bundles: combinedBundles,
    single: singleTasks,
  };
};

export const TaskNotRemovableReasonString = (
  reason: TaskNotRemovableReason,
) => {
  switch (reason) {
    case "charged":
      return "This task has been charged.";
    case "paid":
      return "This task has been paid.";
    case "completed":
      return "This task has been completed.";
    case "on_contract_task":
      return "This task is on contract.";
    default:
      return "";
  }
};
