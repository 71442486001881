// TODO - lint
/* eslint-disable no-unused-vars, no-unused-vars, react/no-deprecated, eqeqeq, no-plusplus, vars-on-top, no-var, no-loop-func, no-loop-func, radix, class-methods-use-this, import/no-default-export */
// General Imports
import React from "react";
import Infinite from "react-infinite";
import { connect } from "react-redux";
// Components Import
// Actions imports
import { addUsersToLists } from "../../../actions/premiumActions";
import NoImage from "../../../images/svgs/placeholder.svg";
import { CreateListSection } from "../lists_components/CreateListSection";

// Css's import

class AddToListMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLists: [],
      loading: false,
      search: "",
      filterCampaigns: false,
      section: 0,
      currentTab: "0",
    };
  }

  linkListsContainerRef() {
    const { listsContainerRef } = this;
    this.setState({ listsContainerRef });
  }

  componentDidMount() {
    this.linkListsContainerRef();
  }

  componentWillMount() {
    const { lists } = this.props.lists;
    const auctionId =
      this.props.auctionId && window.location.pathname.includes("discovery")
        ? this.props.auctionId
        : null;
    if (auctionId && lists) {
      const list = lists.find((x) => x.linked_auction_id == auctionId);
      if (list) {
        this.state.selectedLists.push(list.id);
        this.setState({ filterCampaigns: true });
      }
    }
  }

  onListSelect(id) {
    const { selectedLists } = this.state;
    if (selectedLists.includes(id)) {
      selectedLists.splice(selectedLists.indexOf(id), 1);
    } else selectedLists.push(id);
    this.setState({ selectedLists });
  }

  addUsersToLists() {
    if (!this.state.loading) {
      this.setState({ loading: true });
      const ids = this.props.influencers.map((x) => x.id);
      const { selectedLists } = this.state;
      if (selectedLists.length > 0 && ids.length > 0) {
        const array = [];
        let i;
        for (i = 0; i < selectedLists.length; i++) {
          var selectedList = this.props.lists.lists.find(
            (x) => x.id === selectedLists[i],
          );
          const idsToList = ids.filter(
            (x) => !selectedList.influencer_ids.includes(x),
          );
          if (idsToList.length > 0) {
            array.push({ list_id: selectedList.id, influencers: idsToList });
          }
        }
        if (array.length > 0) {
          this.props.addUsersToLists(array, () =>
            this.setState({ loading: false }),
          );
        } else {
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
      }
    }
  }

  selectTab(id) {
    this.setState(
      { currentTab: id, filterCampaigns: id === "1" },
      this.linkListsContainerRef,
    );
  }

  render() {
    const lists = {
      ...this.props.lists,
    };
    const { filterCampaigns } = this.state;

    if (this.state.currentTab === "2") {
      lists.lists = lists.lists.filter((l) => l.is_favorite);
    }

    if (this.state.filterCampaigns && this.state.currentTab === "1") {
      lists.lists = lists.lists.filter((l) => l.list_type === "campaign");
    }

    return (
      <div className="black-background">
        <div
          className="import-from-list-container animated fadeInRight faster"
          id="add_to_list_menu"
        >
          <div>
            {this.state.section === 0 ? (
              <>
                <div style={{ fontSize: 22 }}>Add to list</div>
                <div
                  className="import-list-close-button"
                  onClick={() => this.props.close()}
                >
                  <span className="moi-ximage" />
                </div>
                <div className="add-to-list-top-avatars">
                  {this.props.influencers.map((x, index) => (
                    <img
                      className="avatar"
                      src={
                        x.picture_url &&
                        x.picture_url.replace(".jpg", "_small.jpg")
                      }
                      title={x.name}
                      key={`avatars${index}`}
                    />
                  ))}
                </div>
                <div
                  className="import-from-list-spacer"
                  style={{ marginTop: 20, marginBottom: 20 }}
                />

                <div
                  style={{
                    fontSize: 18,
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginTop: 15,
                  }}
                >
                  <div
                    className={"flex flex1"}
                    style={{ alignItems: "center" }}
                  >
                    <div style={{ flex: 1 }}>My Lists</div>
                    <div
                      className={"create-buttons-row pull-right "}
                      style={{ padding: "2.5px 0" }}
                    >
                      <button
                        disabled={this.state.filterCampaigns}
                        onClick={() => this.setState({ section: 1 })}
                        className={"btn btn-primry"}
                      >
                        <span
                          className="moi-plus"
                          style={{
                            color: "var(--main-baby-blue-color)",
                            fontSize: 11,
                          }}
                        />{" "}
                        Add new
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="import-list-influencers-row"
                  style={{ paddingRight: 40, justifyContent: "space-between" }}
                >
                  <div className="tabs">
                    <div
                      className={`tab ${
                        this.state.currentTab === "0" ? "active" : ""
                      }`}
                      onClick={() => this.selectTab("0")}
                    >
                      General Lists
                    </div>
                    <div
                      className={`tab ${
                        this.state.currentTab === "1" ? "active" : ""
                      }`}
                      onClick={() => this.selectTab("1")}
                    >
                      Campaign Lists
                    </div>
                    <div
                      className={`tab ${
                        this.state.currentTab === "2" ? "active" : ""
                      }`}
                      onClick={() => this.selectTab("2")}
                    >
                      Favorites
                    </div>
                  </div>
                  <div style={{ alignSelf: "center" }}>
                    <div className="inner-toolbar-search-input">
                      <div
                        className="moi-search-bold"
                        style={{ fontSize: 12, marginTop: 4 }}
                      />
                      <input
                        onChange={(e) =>
                          this.setState({
                            search: e.target.value.toLowerCase(),
                          })
                        }
                        style={{ height: 20, width: 114 }}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div
                  ref={(el) => {
                    this.listsContainerRef = el;
                  }}
                  className="import-list-section"
                  style={{ height: "calc(100vh - 435px)" }}
                >
                  {this.state.listsContainerRef && (
                    <Infinite
                      elementHeight={50}
                      containerHeight={window.innerHeight - 450}
                      timeScrollStateLastsForAfterUserScrolls={0}
                      className="campaign-influencers-table-container"
                      useWindowAsScrollContainer={false}
                    >
                      {lists.lists
                        .filter((x) => this.filterList(x, filterCampaigns))
                        .map(this.renderList)}
                    </Infinite>
                  )}
                </div>
              </>
            ) : (
              this.renderNewList()
            )}
            <div className="import-from-list-buttons-row">
              {this.state.section === 0 ? (
                <>
                  <button
                    className={`btn btn-primry ${
                      this.state.loading ? "disabled" : ""
                    }`}
                    onClick={() => this.addUsersToLists()}
                  >
                    Add
                  </button>
                  <button
                    className={`btn btn-primry ${
                      this.state.loading ? "disabled" : ""
                    }`}
                    onClick={() => this.props.close()}
                  >
                    Cancel
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNewList() {
    return (
      <CreateListSection
        onBack={() => this.setState({ section: 0 })}
        menu={true}
      />
    );
  }

  filterList = (list, filterCampaigns) => {
    if (list.list_type === "list_fee") {
      return false;
    }
    if (filterCampaigns) {
      if (list.list_type !== "campaign") return false;
    } else if (list.list_type === "campaign") {
      return false;
    }

    if (!list.title.toLowerCase().includes(this.state.search)) return false;

    if (!list.influencer_ids) return false;

    return true;
  };

  renderList = (list) => {
    const { influencers } = this.props;

    return (
      <div className="import-list-row" key={`add-to-list${list.id}`}>
        <div style={{ width: 40, height: 40 }}>
          <img
            className="blurable"
            src={list.image_url ? list.image_url : NoImage}
          />
        </div>
        <div
          className={`import-list-row-details ${this.state.selectedLists.includes(
            list.id,
          )}`}
          onClick={() => this.onListSelect(list.id)}
        >
          <strong
            className="invite-list-name blurable-large"
            title={list.title}
          >
            {list.title}
          </strong>
          <div
            className={`list-small-faces ${
              influencers.length > 5 ? "overlap" : ""
            }`}
          >
            {influencers
              .filter((x) =>
                list.influencer_ids.includes(
                  typeof x.id === "string" && x.id.indexOf("all_") > 0
                    ? x.id
                    : parseInt(x.id),
                ),
              )
              .slice(0, 10)
              .map((x) => this.renderInfluencer(x, list.id))}
          </div>
        </div>
        <div
          className="checkbox"
          style={{ padding: 0, paddingTop: 0, marginLeft: -10 }}
        >
          <label>
            <input
              type="checkbox"
              checked={this.state.selectedLists.includes(list.id)}
              onChange={() => this.onListSelect(list.id)}
            />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </div>
    );
  };

  renderInfluencer(x, listId) {
    return (
      <div className="small-face" key={`small-face-${listId}-${x.id}`}>
        <img
          className="avatar"
          src={x.picture_url && x.picture_url.replace(".jpg", "_small.jpg")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lists: state.listsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUsersToLists: (listId, influencers, callback) => {
      dispatch(addUsersToLists(listId, influencers, -1, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToListMenu);
