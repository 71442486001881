import type { ComponentSingleStyleConfig } from "@chakra-ui/react";

export const LinkTheme: ComponentSingleStyleConfig = {
  variants: {
    invisible: {
      _hover: {
        textDecoration: "none",
      },
      _focus: {
        boxShadow: "none",
      },
    },
  },
};
