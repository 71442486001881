import { Box, BoxProps } from "@chakra-ui/react";

interface OwnProps extends BoxProps {
  children?: any;
  showIcon?: boolean;
  props?: any;
}

const NoDataMessage = (props: OwnProps) => {
  return (
    <Box
      padding="6"
      borderRadius={6}
      bg="#ebebeb"
      textAlign={"center"}
      paddingInline={35}
      color={"GrayText"}
      letterSpacing={0.4}
      {...props}
    >
      <Box fontSize={20} mt={10}>
        {props.children || "No content"}
      </Box>
      <Box m={10}>
        {props.showIcon ? (
          <i className="fal fa-face-meh-blank" style={{ fontSize: 40 }}></i>
        ) : null}
      </Box>
    </Box>
  );
};

export default NoDataMessage;
