/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */

import { Box, Button, Flex, Tag, Text, Tooltip } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { BundledTasks, LocalTask } from "src/api/ExtendedTypes";
import { BundleAttributes, TaskNotRemovableReason } from "src/api/types";
import HumanzDismissableAlert from "src/ui/humanz/HumanzDismissableAlert";
import uuid4 from "uuid";
import SingleTaskRow from "../../campaign_components/Tasks/SingleTaskRow";
import { InfoToolTip } from "../../general_components/InfoToolTip";
import AddTaskPanel from "./AddTaskPanel";
import BundleHeader from "./BundleHeader";
import { EditTasksContext } from "./EditTasksContext";

type Props = {
  type: "task" | "bundle";
  data: any;
  taskUpdated: any;
  setEditMode: any;
  onToggleTask: any;
  editMode: any;
  insertMode: boolean;
  month: string;
  isMultiple?: boolean;
  mode?: string;
};

let latestIndex = 0;

const EditMode = (props: Props) => {
  const [splitPayment, setSplitPayment] = useState(false);
  const [tempTasks, setTempTasks] = useState<LocalTask[]>([]);
  const context = useContext(EditTasksContext);
  const getTasksLatestIndex = (tasks?: any) => {
    if (latestIndex) {
      latestIndex += 1;
      return latestIndex;
    }
    let newIndex = context?.mixedTasks?.single?.length;
    // loop all bundles tasks and increas newIndex
    context?.mixedTasks?.bundles?.forEach((bundle) => {
      newIndex += tasks?.length || bundle?.tasks?.length || 1;
    });
    latestIndex = newIndex;
    return newIndex;
  };

  const getReason = (reason: TaskNotRemovableReason) => {
    switch (reason) {
      // generate cases from TaskNotRemovableReason
      case TaskNotRemovableReason.completed:
        return "Task has been completed";
      case TaskNotRemovableReason.paid:
        return "Task has been paid";
      case TaskNotRemovableReason.charged:
        return "Task has been charged";
      case TaskNotRemovableReason.on_contract_task:
        return "Task is on active contract";
      default:
        return "";
    }
  };

  const generateTempBundle = (bundle?: BundleAttributes) => {
    const tempBundleObj: BundledTasks = {
      ...bundle,
      bundle_id: bundle?.bundle_id || uuid4(),
      price: bundle?.price || 0,
      tasks: tempTasks,
      renews: bundle?.renews || false,
      new: !!bundle?.bundle_id,
      changed: true,
    };
    return tempBundleObj;
  };

  const [tempBundle, setTempBundle] = useState<BundledTasks>(
    generateTempBundle(props.data),
  );

  const showTempBundleData =
    (props?.type === "bundle" && tempTasks?.length > 1) ||
    tempTasks?.length > 0;

  const insertOrDeleteTempTask = (
    index: number,
    type?: any,
    bundleId?: string,
    uuid?: string,
  ) => {
    let tasks: LocalTask[] = [...(tempTasks || [])];
    const existingTaskIndex = tempTasks?.findIndex((t) => t.uuid === uuid);
    // Remove from tasks array if new
    if (
      existingTaskIndex !== null &&
      uuid !== null &&
      tasks[existingTaskIndex]?.new
    ) {
      tasks = tempTasks.filter((t) => t.uuid !== uuid);
      // Mark as deleted if exsiting
    } else if (
      existingTaskIndex !== null &&
      index !== null &&
      tasks[existingTaskIndex] &&
      !tasks[existingTaskIndex]?.new
    ) {
      tasks[existingTaskIndex].deleted = true;
      // Not existing, insert new
    } else if (index === null) {
      // create index
      const newIndex = getTasksLatestIndex(tasks);
      tasks.push({
        post_type: type,
        tags: ["#ad"],
        content_due_date: null,
        due_date: null,
        description: "",
        changed: true,
        new: true,
        additonal_price: 0,
        references: [],
        index: newIndex || 0,
        renews: false,
        uuid: uuid4(),
        bundle_id: bundleId,
        complete_at_end_of_month: false,
        removable: true,
      });
    }
    setTempTasks(tasks);
  };

  useEffect(() => {
    setTempTasks(props.data.tasks);
    setTempBundle(
      generateTempBundle(props.data?.bundle_id ? props.data : null),
    );

    return () => {
      latestIndex = 0;
    };
  }, []);

  const calculateTotalPrice = () => {
    if (splitPayment && (props.type === "bundle" || tempTasks?.length > 0)) {
      const sum = tempTasks?.reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + currentValue.additonal_price,
        0,
      );
      setTempBundle({ ...tempBundle, price: sum, tasks: tempTasks });
    }
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [props.taskUpdated, splitPayment, tempTasks]);

  const onSave = () => {
    const updatedMixedTasks = { ...context.mixedTasks };

    // update single tasks to have bundle id from tempBundle if split payment is not selected
    if (tempTasks?.length > 1) {
      tempTasks.forEach((task) => {
        task.bundle_id = splitPayment ? null : tempBundle.bundle_id;
      });
    } else if (!props.insertMode) {
      const idx = updatedMixedTasks.single.findIndex(
        (t) => t.index === props.data.index,
      );
      if (idx !== -1) {
        updatedMixedTasks.single[idx] = props.data;
        context.setMixedTasks(updatedMixedTasks);
        props.setEditMode(null);
      }
    }

    // update single tasks
    updatedMixedTasks.single =
      props.type === "task" || splitPayment || tempTasks?.length === 1
        ? tempTasks
          ? [
              ...updatedMixedTasks.single.filter((t) => !t.bundle_id),
              ...tempTasks.filter((t) => !t.bundle_id),
            ]
          : []
        : updatedMixedTasks.single;

    // Updated mixed bundles
    updatedMixedTasks.bundles = updatedMixedTasks?.bundles?.map(
      (bundle: BundleAttributes) => {
        if (bundle?.bundle_id === tempBundle?.bundle_id) {
          return { ...tempBundle, tasks: tempTasks };
        }
        return bundle;
      },
    );

    // Insert new bundle
    if (props.insertMode && tempTasks?.length > 1 && !splitPayment) {
      tempBundle.tasks = tempTasks;
      updatedMixedTasks.bundles = updatedMixedTasks.bundles
        ? [...updatedMixedTasks.bundles, tempBundle]
        : [tempBundle];
    }

    context.setMixedTasks(updatedMixedTasks);
    props.setEditMode(null);
  };

  const canAddTasks = props.insertMode || props.type === "bundle";

  const haveNewTasks = useCallback(
    () => tempTasks?.filter((t) => t.new).length > 0,
    [tempTasks],
  );

  const canSaveTemp = useCallback(
    () =>
      props.type === "task" ||
      tempTasks?.filter((x) => !x.deleted).length ||
      (tempBundle.price === 0 && props.data.price === 0),
    [tempTasks],
  );

  const isSingleNewTask = tempTasks?.length === 1 && tempTasks[0]?.new;

  return (
    <Box sx={{ position: "relative" }}>
      {context.influencer.edited_next_month && (
        <Box m={3}>
          <HumanzDismissableAlert
            title="Notice"
            description='Next month tasks for this influencer were edited. If you want to edit recurring tasks, please do it from the "Plan next month" section.'
            icon={<i className="fad fa-exclamation-triangle" />}
            open={true}
            status="warning"
          />
        </Box>
      )}
      <Box p={4} paddingBottom={0}>
        {/* Title - edit tasks or add new tasks */}
        <Text fontSize={16} textAlign={"left"} mb={1}>
          {props.insertMode ? "Add new tasks" : "Edit tasks"}
        </Text>
      </Box>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        {showTempBundleData && tempTasks?.length > 0 && !isSingleNewTask && (
          <>
            <Box mx={3}>
              {tempBundle?.removable !== false &&
                tempBundle.not_removable_reason && (
                  <HumanzDismissableAlert
                    title="Notice"
                    description={`This bundle can't be deleted. ${getReason(
                      tempBundle.not_removable_reason,
                    )}`}
                    icon={<i className="fad fa-info-circle" />}
                    open={true}
                    status="info"
                    dismissable={true}
                  />
                )}
            </Box>

            {!isSingleNewTask && (
              <BundleHeader
                bundle={tempBundle}
                setSplitPayment={setSplitPayment}
                splitPayment={splitPayment}
                setPrice={(price: number) => {
                  setTempBundle({ ...tempBundle, price });
                }}
                insertMode={props.insertMode}
                price={tempBundle.price}
                bundleUpdated={(bundle: BundledTasks) => {
                  const updatedTasks: LocalTask[] = [];
                  tempTasks?.forEach((task: LocalTask) => {
                    if (bundle.renews) {
                      task.renews = true;
                    }
                    updatedTasks.push(task);
                  });
                  setTempTasks(updatedTasks);
                }}
              />
            )}
          </>
        )}
      </Box>
      <Flex
        flexDirection={"column"}
        className={showTempBundleData ? "tasks-bundle-edit" : ""}
      >
        <Flex mb={2} justifyContent={"space-between"} alignItems="center">
          {showTempBundleData && (
            <Text ml={1} color={"#808080"} fontSize={14}>
              {isSingleNewTask ? (
                "New single task"
              ) : splitPayment ? (
                <>
                  <InfoToolTip
                    iconStyle={{ marginRight: "5px" }}
                    overlay={"Single tasks will be paid separately."}
                  />
                  {tempTasks?.length || 0} Single tasks
                </>
              ) : (
                <>
                  <InfoToolTip
                    iconStyle={{ marginRight: "5px" }}
                    overlay={
                      "Bundled tasks should be completed together - and will be paid together."
                    }
                  />
                  {tempTasks?.length || 0} Tasks bundle
                </>
              )}
            </Text>
          )}
          <Flex gap={2}>
            {/* // todo: delete bundle */}
            {/* {showTempBundleData && (
              <Button variant="small-action-white" colorScheme={"red"}>
                <i className="far fa-trash" style={{ marginRight: 2 }} />
                Delete bundle
              </Button>
            )} */}
            {tempBundle.renews && !splitPayment && (
              <Tag colorScheme={"blue"}>
                <i className="far fa-arrows-spin" style={{ marginRight: 2 }} />
                Recurring
              </Tag>
            )}
            {tempBundle.complete_at_end_of_month && !splitPayment && (
              <Tooltip
                label={
                  "Influencer will receive the payment at the end of the month, regardless of tasks completion"
                }
              >
                <Tag colorScheme={"purple"}>
                  <i
                    className="fa-duotone fa-money-check-dollar"
                    style={{ marginRight: 2 }}
                  />
                  Guaranteed payment
                </Tag>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        {tempTasks?.length ? (
          <>
            {tempTasks
              ?.filter((t) => !t.new)
              .map((task: LocalTask, i: number) => (
                <SingleTaskRow
                  key={i}
                  task={task}
                  index={i}
                  isOpen={task.new}
                  taskUpdated={insertOrDeleteTempTask}
                  onToggleTask={insertOrDeleteTempTask}
                  editMode={props.editMode}
                  setEditMode={props.setEditMode}
                  showPrice={splitPayment}
                  splitPayment={splitPayment}
                  inBundleMode={props.type === "bundle" && !isSingleNewTask}
                  mode={props.mode}
                />
              ))}
            {haveNewTasks() && !props.insertMode && (
              <Flex gap={3} m={5} alignContent="center">
                <Box color={"#808080"}>New</Box>
                <Box
                  borderBottom={"2px dashed #AAAAAA"}
                  width={"100%"}
                  height={"12px"}
                ></Box>
              </Flex>
            )}
            {tempTasks
              ?.filter((t) => t.new)
              .map((task: LocalTask, i: number) => (
                <SingleTaskRow
                  key={i}
                  task={task}
                  index={i}
                  isOpen={task.new}
                  taskUpdated={insertOrDeleteTempTask}
                  onToggleTask={insertOrDeleteTempTask}
                  editMode={props.editMode}
                  setEditMode={props.setEditMode}
                  showPrice={splitPayment}
                  splitPayment={splitPayment}
                  inBundleMode={props.type === "bundle" && !isSingleNewTask}
                  mode={props.mode}
                />
              ))}
          </>
        ) : (
          <SingleTaskRow
            task={props.data}
            index={0}
            isOpen={props.data?.new || props.editMode?.uuid}
            taskUpdated={insertOrDeleteTempTask}
            onToggleTask={insertOrDeleteTempTask}
            editMode={props.editMode}
            setEditMode={props.setEditMode}
            showPrice={splitPayment}
            inBundleMode={showTempBundleData && tempTasks?.length > 1}
            mode={props.mode}
          />
        )}
      </Flex>
      {canAddTasks && (
        <Box p={5} maxWidth={450} margin={"0 auto"}>
          {<AddTaskPanel onToggleTask={insertOrDeleteTempTask} />}
        </Box>
      )}
      <Flex
        paddingX={5}
        paddingY={2}
        position="sticky"
        bottom={0}
        width={"100%"}
        background="white"
      >
        <Flex
          justifyContent={props.isMultiple ? "flex-end" : "space-between"}
          paddingX={2}
          paddingY={2}
          background="#f6f6f6"
          borderRadius={12}
          width={"100%"}
        >
          {!props.isMultiple && (
            <Button
              leftIcon={<i className="fas fa-arrow-left" />}
              className={`cancel`}
              onClick={() => props.setEditMode(null)}
            >
              <span>Back</span>
            </Button>
          )}
          <Button
            isDisabled={!canSaveTemp()}
            leftIcon={<i className="fad fa-check" />}
            onClick={onSave}
            id="edit-tasks-continue-btn"
          >
            <span>Continue</span>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default EditMode;
