import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Dialog from "rc-dialog";
import { Link } from "react-router-dom";

const AgentDetectedModal = ({ onClose }: { onClose: any }) => {
  return (
    <Dialog
      className="agent-detected-dialog"
      visible={true}
      style={{
        minWidth: 740,
        height: "80vh",
        margin: "65px auto",
        display: "flex",
        alignItems: "center",
      }}
      closable={false}
      animation="slide-fade"
      maskAnimation="fade"
      zIndex={999}
    >
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        p={5}
        flexDirection="column"
        textAlign={"center"}
      >
        <Box mb={2}>
          <Text fontSize={24} fontWeight={"bold"}>
            Our platform detected that you are a talent manager!
          </Text>
          <Text lineHeight={1.6} fontSize={16}>
            This page is for advertisers only. <br />
            If you are a talent manager - don’t worry! we can take you to your
            page. <br />
            If you believe this is a mistake, please contact us at{" "}
            <a href="mailto:info@humanz.ai">info@humanz.ai</a>
          </Text>
        </Box>
        <Box m={6}>
          <svg
            width="154"
            height="154"
            viewBox="0 0 154 154"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="77" cy="77" r="77" fill="url(#paint0_linear_1_315)" />
            <path
              d="M18.8182 18.8182H76.8182V92.2727H18.8182V18.8182Z"
              fill="#E6E6E8"
            />
            <path
              d="M26.6364 49.7273H38.2727V61.3636H26.6364V49.7273Z"
              fill="#50B2F3"
            />
            <path d="M26.6364 31H38.2727V42.6364H26.6364V31Z" fill="#A7D9F9" />
            <path
              d="M26.6364 68.2727H38.2727V79.9091H26.6364V68.2727Z"
              fill="#3476BD"
            />
            <path d="M44.6364 31H69.1818V35.1818H44.6364V31Z" fill="#249FF0" />
            <path
              d="M44.6364 38.4545H57.3636V42.6364H44.6364V38.4545Z"
              fill="#249FF0"
            />
            <path
              d="M44.6364 49.7273H69.1818V53.9091H44.6364V49.7273Z"
              fill="#249FF0"
            />
            <path
              d="M44.6364 57.1818H57.3636V61.3636H44.6364V57.1818Z"
              fill="#249FF0"
            />
            <path
              d="M44.6364 68.2727H69.1818V72.4545H44.6364V68.2727Z"
              fill="#249FF0"
            />
            <path
              d="M44.6364 75.7273H57.3636V79.9091H44.6364V75.7273Z"
              fill="#249FF0"
            />
            <path
              d="M96.6851 65.928C97.3548 63.3008 96.7144 60.8695 95.2549 60.4975C93.7954 60.1254 92.0693 61.9536 91.3996 64.5807C90.73 67.2079 91.3703 69.6392 92.8298 70.0113C94.2894 70.3833 96.0154 68.5552 96.6851 65.928Z"
              fill="#C6A28A"
            />
            <path
              d="M57.242 64.614C56.5723 61.9868 54.8463 60.1587 53.3867 60.5307C51.9272 60.9028 51.2869 63.3341 51.9565 65.9613C52.6262 68.5884 54.3523 70.4166 55.8118 70.0445C57.2713 69.6725 57.9117 67.2412 57.242 64.614Z"
              fill="#D5B79F"
            />
            <path
              d="M66.2727 81.1818H81.7273V93.7273H66.2727V81.1818Z"
              fill="#D5B79F"
            />
            <path
              d="M66.2727 81.1818H73.9091V93.7273H66.2727V81.1818Z"
              fill="#C6A28A"
            />
            <path
              d="M74.4545 85.7273C84.9982 85.7273 93.5454 75.1449 93.5454 62.0909C93.5454 49.0369 84.9982 38.4545 74.4545 38.4545C63.9109 38.4545 55.3636 49.0369 55.3636 62.0909C55.3636 75.1449 63.9109 85.7273 74.4545 85.7273Z"
              fill="#D5B79F"
            />
            <path
              d="M74.4545 38.4545V85.7273C85 85.7273 93.5455 75.1818 93.5455 62.0909C93.5455 49 85 38.4545 74.4545 38.4545Z"
              fill="#C6A28A"
            />
            <path
              d="M107.727 113.545C107.909 107 103.364 102.636 98.0909 98.8182C92.8182 95 81.7273 91.3636 81.7273 91.3636L74.4545 93.9091L73.9091 93.3636L67.1818 91.5455C67.1818 91.5455 56.2727 95.3636 50.8182 99C45.5455 102.818 41 107.182 41.1818 113.727C41.3636 120.273 41.1818 135.364 41.1818 135.364H74.4545H107.727C107.727 135.182 107.545 120.091 107.727 113.545Z"
              fill="#D4D8DB"
            />
            <path
              d="M107.727 113.545C107.909 107 103.364 102.636 98.0909 98.8182C92.8182 95 81.7273 91.3636 81.7273 91.3636L74.0909 93.1818V135.182H107.909C107.727 135.182 107.545 120.091 107.727 113.545Z"
              fill="#E6E6E8"
            />
            <path
              d="M107.727 135.182H74.0909C82.4545 113.727 83 96.0909 83 91.7273C83.9091 92.0909 85.3636 92.6364 87 93.3636C90.6364 94.8182 95.1818 96.8182 98.0909 98.8182C103.364 102.636 107.909 107 107.909 113.545C107.545 120.091 107.727 135.182 107.727 135.182Z"
              fill="#D4D8DB"
            />
            <path
              d="M76.4545 93.1818H71.5455L69.1818 97L71.5455 100.818H76.4545L78.8182 97L76.4545 93.1818Z"
              fill="#50B2F3"
            />
            <path
              d="M78.8182 97L76.4545 93.1818H74.0909V100.818H76.4545L78.8182 97Z"
              fill="#249FF0"
            />
            <path
              d="M81.7273 87.5455L74.0909 93.1818L81.3636 99L83.3636 91.9091L81.7273 87.5455Z"
              fill="#B5BABE"
            />
            <path
              d="M66.2727 87.5455L74.0909 93.1818L66.6364 99L64.6364 92.2727L66.2727 87.5455Z"
              fill="#E6E6E8"
            />
            <path
              d="M78.6364 135.182H74.0909V100.818H76.4545L78.6364 135.182Z"
              fill="#50B2F3"
            />
            <path
              d="M74.0909 135.182H69.3636L71.5455 100.818H74.0909V135.182Z"
              fill="#249FF0"
            />
            <path
              d="M73.9091 135.182H41.1818C41.1818 135.182 41.3636 120.091 41.1818 113.545C41 107 45.5455 102.636 50.8182 98.8182C53.5455 96.8182 57.7273 95 61.1818 93.5455C62.6364 93 64.0909 92.4545 65 92.0909C65.1818 97 65.9091 114.091 73.9091 135.182Z"
              fill="#3B4C56"
            />
            <path
              d="M107.727 135.182H74.0909C82.4545 113.727 83 96.0909 83 91.7273C83.9091 92.0909 85.3636 92.6364 87 93.3636C90.6364 94.8182 95.1818 96.8182 98.0909 98.8182C103.364 102.636 107.909 107 107.909 113.545C107.545 120.091 107.727 135.182 107.727 135.182Z"
              fill="#465C6A"
            />
            <path
              d="M86.4545 108.455L87.9091 113.364C83.5454 127.182 74.6364 134.818 74.0909 135.364C80.0909 119.909 82.6363 105.727 83 90.8182L87 93.3636L89.1818 105.182L86.4545 108.455Z"
              fill="#3B4C56"
            />
            <path
              d="M73.9091 135.182C73 134.455 65.3636 126.818 61 113.182L62.4545 108.273L59.7273 105L61.1818 93.5455L65.1818 90.6364C65.7273 105.727 67.9091 119 73.9091 135.182Z"
              fill="#465C6A"
            />
            <path
              d="M92.2727 42.6364C92.2727 42.6364 89.5455 33.5455 83 33.3636C77.1818 33.1818 67 32.4545 59.1818 37.3636C57.3636 38.4545 55.9091 40.2727 55 42.4545C53.7273 45.7273 52.2727 51.7273 54.0909 59L56.6364 68.2727C56.8182 68.8182 57.3636 69.1818 57.9091 69.1818C58.6364 69.1818 59.3636 68.4545 59.1818 67.7273C58.8182 64.0909 57.9091 54.2727 59.3636 52.0909C61.1818 49.3636 61.5455 49.1818 67.3636 50.6364C73.1818 52.2727 86.2727 51.3636 87.5455 49.7273C87.5455 49.7273 88.8182 52.6364 88.8182 55.9091C88.8182 58.6364 90.0909 65.9091 90.4546 68.2727C90.4546 68.6364 91 69 91.3636 69C91.7273 69 92.2727 68.6364 92.2727 68.2727C92.8182 64.6364 95 50.0909 95.3636 46.6364C95.9091 42.8182 92.2727 42.6364 92.2727 42.6364Z"
              fill="#465C6A"
            />
            <path
              d="M92.2727 42.6364C92.2727 42.6364 89.5455 33.5455 83 33.3636C80.6364 33.1818 77.7273 33.1818 74.4545 33.1818V51.3636C80.2727 51.5455 86.8182 50.8182 87.7273 49.7273C87.7273 49.7273 89 52.6364 89 55.9091C89 58.6364 90.2727 65.9091 90.6364 68.2727C90.6364 68.6364 91.1818 69 91.5455 69C91.9091 69 92.4545 68.6364 92.4545 68.2727C93 64.6364 95.1818 50.0909 95.5455 46.6364C95.9091 42.8182 92.2727 42.6364 92.2727 42.6364Z"
              fill="#3B4C56"
            />
            <path
              d="M121.545 98.8182H97.3636V90.4546C97.3636 89.5455 98.0909 89 98.8182 89H119.909C120.818 89 121.364 89.7273 121.364 90.4546V98.8182H121.545ZM99.9091 96.2727H119V91.3636H99.9091V96.2727Z"
              fill="#3B4C56"
            />
            <path
              d="M85.1818 94.8182V132.636C85.1818 133.909 86.2727 135 87.5455 135H131.545C132.818 135 133.909 133.909 133.909 132.636V94.8182H85.1818Z"
              fill="#C6A28A"
            />
            <path
              d="M133.182 94.8182H85.7273C84.6364 94.8182 83.7273 95.7273 83.7273 96.8182V112.273C83.7273 113.364 84.6364 114.273 85.7273 114.273H133.182C134.273 114.273 135.182 113.364 135.182 112.273V96.8182C135.182 95.7273 134.273 94.8182 133.182 94.8182Z"
              fill="#D5B79F"
            />
            <path
              d="M109.545 94.8182V135H131.545C132.818 135 133.909 133.909 133.909 132.636V94.8182H109.545Z"
              fill="#D5B79F"
            />
            <path
              d="M133.182 94.8182H109.545V114.091H133.182C134.273 114.091 135.182 113.182 135.182 112.091V96.6364C135.182 95.7273 134.273 94.8182 133.182 94.8182Z"
              fill="#C6A28A"
            />
            <path
              d="M106.636 110.091V115.182C106.636 116.818 107.909 118.091 109.545 118.091C111.182 118.091 112.455 116.818 112.455 115.182V110.273H106.636V110.091Z"
              fill="#465C6A"
            />
            <path
              d="M109.545 116.455C110.349 116.455 111 115.803 111 115C111 114.197 110.349 113.545 109.545 113.545C108.742 113.545 108.091 114.197 108.091 115C108.091 115.803 108.742 116.455 109.545 116.455Z"
              fill="#E6E6E8"
            />
            <path
              d="M38.9034 134.625C49.8957 134.625 58.8068 125.714 58.8068 114.722C58.8068 103.729 49.8957 94.8182 38.9034 94.8182C27.911 94.8182 19 103.729 19 114.722C19 125.714 27.911 134.625 38.9034 134.625Z"
              fill="#249FF0"
            />
            <path
              d="M38.9033 128.055C46.2671 128.055 52.2367 122.085 52.2367 114.722C52.2367 107.358 46.2671 101.388 38.9033 101.388C31.5395 101.388 25.57 107.358 25.57 114.722C25.57 122.085 31.5395 128.055 38.9033 128.055Z"
              fill="#50B2F3"
            />
            <path
              d="M38.9034 94.8182V134.818C49.9179 134.818 58.8068 125.929 58.8068 114.915C58.8068 103.707 49.9179 94.8182 38.9034 94.8182Z"
              fill="#50B2F3"
            />
            <path
              d="M38.9034 101.388V128.055C46.2464 128.055 52.2367 122.065 52.2367 114.722C52.2367 107.379 46.2464 101.388 38.9034 101.388Z"
              fill="#249FF0"
            />
            <path
              d="M40.9466 106.85C40.9466 107.216 40.8326 107.574 40.619 107.878C40.4054 108.183 40.1018 108.42 39.7466 108.56C39.3914 108.7 39.0006 108.737 38.6235 108.665C38.2464 108.594 37.9001 108.418 37.6282 108.159C37.3564 107.9 37.1712 107.57 37.0962 107.211C37.0212 106.853 37.0597 106.48 37.2068 106.142C37.354 105.804 37.6031 105.515 37.9228 105.312C38.2424 105.109 38.6183 105 39.0027 105C39.5183 105 40.0127 105.195 40.3773 105.542C40.7418 105.889 40.9466 106.36 40.9466 106.85ZM45.8402 121.136L40.2097 110.66C40.099 110.44 39.9274 110.254 39.7135 110.121C39.4997 109.988 39.2517 109.913 38.9964 109.905C38.741 109.913 38.493 109.988 38.2792 110.121C38.0653 110.254 37.8937 110.44 37.783 110.66L32.1551 121.136C31.9866 121.445 31.9539 121.806 32.0643 122.138C32.1747 122.47 32.4192 122.747 32.7439 122.907L32.763 122.917C32.7799 122.924 32.7977 122.93 32.8161 122.934C33.1352 123.03 33.4797 123.02 33.7916 122.904C34.1035 122.787 34.364 122.573 34.5294 122.295L35.373 120.654L36.5895 118.189L39.0015 113.579L41.4134 118.189L42.6268 120.654L43.4703 122.295C43.6351 122.573 43.8955 122.788 44.2077 122.905C44.5198 123.021 44.8645 123.031 45.1837 122.934C45.202 122.93 45.2198 122.924 45.2367 122.917C45.3989 122.839 45.5435 122.733 45.6623 122.602C45.7811 122.472 45.8718 122.321 45.9291 122.157C45.9865 121.993 46.0094 121.82 45.9965 121.648C45.9837 121.476 45.9353 121.308 45.8542 121.154L45.8446 121.136"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_315"
                x1="77"
                y1="19"
                x2="77"
                y2="255"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E9F5FE" />
                <stop offset="1" stop-color="#E9F5FE" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
        <Box>
          <Link to="https://agents.humanz.ai">
            <Button
              rightIcon={<i className="far fa-arrow-right" />}
              size={"lg"}
            >
              Take me to Humanz for Talent Managers
            </Button>
          </Link>
        </Box>
      </Flex>
      <Flex alignItems={"center"} justifyContent={"center"}>
        <Button variant={"ghost"} onClick={onClose}>
          Stay here
        </Button>
      </Flex>
    </Dialog>
  );
};

export default AgentDetectedModal;
