import { Button } from "@chakra-ui/react";
import React from "react";

type Size = "sm" | "lg";
type Props = {
  type: string;
  onClick: () => void;
  size: Size;
  style?: React.CSSProperties;
};

const AddButton = (props: Props) => {
  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    border: "2px solid #EBEBEB",
    background: "#FAFAFA",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 11px 25px 0px rgba(0, 0, 0, 0.06), 0px 45px 45px 0px rgba(0, 0, 0, 0.05), 0px 101px 61px 0px rgba(0, 0, 0, 0.03), 0px 180px 72px 0px rgba(0, 0, 0, 0.01), 0px 281px 79px 0px rgba(0, 0, 0, 0.00)",
    color: "black",
    fontWeight: 500,
    fontSize: "14px",
    padding: "14px 26px",
    ...props.style,
  };

  if (props.size === "sm") {
    buttonStyles.padding = "10px 20px";
  } else if (props.size === "lg") {
    buttonStyles.padding = "44px 90px";
  }
  return (
    <Button style={buttonStyles} onClick={props.onClick}>
      <i
        className="far fa-plus fa-sm"
        style={{ marginRight: "5px", color: "#249ff0", fontWeight: 600 }}
      />
      Add {props.type}
    </Button>
  );
};

export default AddButton;
