import { PostTypes } from "src/api/types";
import { TikTokVideoPrefix } from "src/components/feed_components/utilities/general";

export enum SocialNetworkEnum {
  instagram = "instagram",
  twitter = "twitter",
  youtube = "youtube",
  tiktok = "tiktok",
}

export const AllSocialIcons = {
  ig_post: "fa fa-instagram",
  ig_story: "fa moi-stories",
  tk_video: "fa-brands fa-tiktok",
  tw_tweet: "fa fa-twitter",
  yt_video: "fa fa-youtube-play",
  influencer_content: "fas fa-photo-video",
};

export const SocialIconsT = {
  instagram: AllSocialIcons[PostTypes.ig_post],
  twitter: AllSocialIcons[PostTypes.tw_tweet],
  youtube: AllSocialIcons[PostTypes.yt_video],
  tiktok: AllSocialIcons[PostTypes.tk_video],
};

export const getSocialNetworkByPostType = (postType: PostTypes) => {
  switch (postType) {
    case PostTypes.ig_post:
    case PostTypes.ig_story:
      return SocialNetworkEnum.instagram;
    case PostTypes.tw_tweet:
      return SocialNetworkEnum.twitter;
    case PostTypes.yt_video:
      return SocialNetworkEnum.youtube;
    case PostTypes.tk_video:
      return SocialNetworkEnum.tiktok;
    case PostTypes.influencer_content:
      return null;
    default:
      return null;
  }
};

export const getSocialMediaLinkByContentType = (
  contentType: PostTypes,
  media: string,
) => {
  switch (contentType) {
    case PostTypes.ig_post:
      return `https://www.instagram.com/p/${media}`;
    case PostTypes.ig_story:
      return media;
    case PostTypes.tw_tweet:
      return `https://twitter.com/statuses/statuses/${media}`;
    case PostTypes.yt_video:
      return `https://www.youtube.com/watch?v=${media}`;
    case PostTypes.tk_video:
      return `${TikTokVideoPrefix}${media}`;
    case PostTypes.influencer_content:
      return "";
    default:
      return "";
  }
};

export const getProfileLinkBySocialNetwork = (
  socialNetwork: SocialNetworkEnum,
  username: string,
) => {
  switch (socialNetwork) {
    case SocialNetworkEnum.instagram:
      return `https://www.instagram.com/${username}`;
    case SocialNetworkEnum.twitter:
      return `https://www.twitter.com/${username}`;
    case SocialNetworkEnum.youtube:
      return `https://www.youtube.com/channel/${username}`;
    case SocialNetworkEnum.tiktok:
      return `https://www.tiktok.com/@${username}`;
    default:
      return "";
  }
};

export const TasksTypes: PostTypes[] = [
  PostTypes.ig_post,
  PostTypes.ig_story,
  PostTypes.tw_tweet,
  PostTypes.tk_video,
  PostTypes.yt_video,
  PostTypes.influencer_content,
];

export const SocialIcon = (props: {
  social: SocialNetworkEnum;
  onClick?: any;
  style?: any;
  className?: any;
}) => {
  if (!props.social || SocialNetworkEnum[props.social] === undefined)
    return null;
  return (
    <i
      style={props.style}
      className={`${SocialIconsT[props.social]} ${props.className}`}
      onClick={props.onClick}
    />
  );
};
