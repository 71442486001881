import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import produce from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { useAddExtraPayout } from "src/api/actions/auctionActions";
import { Form } from "src/ui/Form";
import { FormFooterButtonGroup } from "src/ui/FormFooterButtonGroup";
import { MoneyInput } from "src/ui/MoneyInput";
import {
  CampaignPaymentsContext,
  CampaignPaymentsContextType,
} from "../CampaignPaymentsContext";

type Props = {
  onClose: any;
  currencyLabel?: any;
  influencerId: number;
  auctionId: number;
  paymentDue?: string;
  paymentStatus?: string;
  paymentDetails?: any;
  priceDetails?: any;
  month?: any;
};

const AddAmountToPayModal = (props: Props) => {
  const context = React.useContext<CampaignPaymentsContextType>(
    CampaignPaymentsContext,
  );
  const { getPaymentData } = context;
  const currencySymbol = window.translate(
    "",
    `money_symbol_${props.currencyLabel}`,
  );

  const [extraPayout, setExtraPayouts] = useState<
    { amount: number; comment: string; month_relevance: string } | undefined
  >();

  useEffect(() => {
    setExtraPayouts({
      amount: 0,
      comment: "",
      month_relevance: props.month,
    });
  }, []);

  const handleAmountChange = useCallback<
    NonNullable<React.ComponentProps<typeof MoneyInput>["onValueChange"]>
  >(
    (newValue) => {
      setExtraPayouts(
        produce((draft) => {
          if (draft) {
            draft.amount = newValue;
          }
        }),
      );
    },
    [setExtraPayouts],
  );
  const handleCommentChange = useCallback<
    NonNullable<React.ComponentProps<typeof Textarea>["onChange"]>
  >(
    (e) => {
      setExtraPayouts(
        produce((draft) => {
          if (draft) {
            draft.comment = e.target.value.substring(0, 140);
          }
        }),
      );
    },
    [setExtraPayouts],
  );

  const addExtraPayout = useAddExtraPayout();

  // let paymentDueFormatted: string | undefined;
  // if (props.paymentDue) {
  // const paymentDueDate = new Date(props.paymentDue);
  // paymentDueFormatted = `${paymentDueDate.getDate()}-${
  //   paymentDueDate.getMonth() + 1
  // }-${paymentDueDate.getFullYear()}`;
  // }

  const isValid = extraPayout
    ? extraPayout?.amount > 0 && extraPayout?.comment !== ""
    : true;

  const handleFormSubmit = useCallback(async () => {
    await addExtraPayout.mutateAsync({
      auction_id: props.auctionId,
      influencer_id: props.influencerId,
      requestBody: {
        amount: extraPayout?.amount,
        comment: extraPayout?.comment,
        month_relevance: extraPayout?.month_relevance,
      },
    });
    getPaymentData();
    props.onClose();
  }, [
    extraPayout,
    addExtraPayout,
    props.influencerId,
    props.auctionId,
    props.onClose,
  ]);
  return (
    <Modal size="xl" isOpen={true} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add amount to pay</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!(
            ["paid", "early_payout"] as (typeof props.paymentStatus)[]
          ).includes(props.paymentStatus) ? (
            <Container pt={6} mb={6}>
              <Text fontSize="xl" pb={3}>
                Add amount to pay
              </Text>
              <Form onSubmit={handleFormSubmit}>
                <Flex alignItems="center" py={3}>
                  <FormControl>
                    <Flex align="baseline">
                      <FormLabel flex={1}>Amount to add</FormLabel>
                      <MoneyInput
                        flex={1}
                        autoFocus
                        currencyLabel={currencySymbol}
                        value={extraPayout?.amount}
                        onValueChange={handleAmountChange}
                      />
                    </Flex>
                  </FormControl>
                </Flex>
                <Box py={3}>
                  <Textarea
                    placeholder="Add comment..."
                    value={extraPayout?.comment}
                    onChange={handleCommentChange}
                  />
                </Box>
                <FormFooterButtonGroup py={3}>
                  <Button
                    isDisabled={!isValid}
                    isLoading={addExtraPayout?.isPending}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button type="button" onClick={props.onClose}>
                    Cancel
                  </Button>
                </FormFooterButtonGroup>
              </Form>
            </Container>
          ) : undefined}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddAmountToPayModal;
