// TODO - lint
/* eslint-disable import/no-default-export */
import React from "react";
import Tooltip from "rc-tooltip";

const CheckBox = (props) => {
  return (
    <div
      className={`mt-checkbox-small ${props.disabled ? "disabled" : ""}`}
      style={props.style}
    >
      <input
        className="inp-cbx"
        onChange={() => {}}
        disabled={props.disabled}
        checked={props.checked}
        type="checkbox"
        style={{ display: "none" }}
      />
      <label className="cbx" onClick={() => props.onChange()}>
        <span>
          <svg
            width="12px"
            height="10px"
            viewBox="0 0 12 10"
            style={{ left: 1 }}
          >
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </svg>
        </span>
        {props.tooltip ? (
          <Tooltip
            placement="top"
            trigger={["hover"]}
            overlay={<span>{props.tooltip}</span>}
          >
            <span style={{ color: props.labelColor }}>{props.label}</span>
          </Tooltip>
        ) : (
          <span style={{ color: props.labelColor }}>{props.label}</span>
        )}
      </label>
    </div>
  );
};

export default CheckBox;
