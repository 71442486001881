// TODO - lint
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars, import/no-default-export, react/no-unescaped-entities, react/jsx-no-target-blank, react/no-unescaped-entities */
import React, { useEffect } from "react";
import { GenerateAuthLink } from "../ExternalInvitation/GenerateAuthLink";
import {
  getSocialNetworkUsernameUrl,
  SOCIAL_NETWORK,
} from "../utilities/general";

const SEARCH_AGAIN_TIME = 10;

interface Props {
  username: string;
  searchInfluencer(): void;
  resultsFound: boolean;
  social_network: SOCIAL_NETWORK;
}

export default function UserNotFound(props: Props) {
  // eslint-disable-next-line prefer-const
  let [timer, setTimer] = React.useState(SEARCH_AGAIN_TIME);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (timer === 1) {
        window.clearTimeout(timeInterval);
      }
      timer -= 1;
      setTimer(timer);
    }, 1000);

    return () => clearInterval(timeInterval);
  }, []);

  return (
    <div className={`no_user_found ${props.resultsFound}`}>
      {props.resultsFound ? (
        <h4>Not the creator you were looking for?</h4>
      ) : (
        <h3>
          Hmmm... We couldn't find{" "}
          <a
            href={getSocialNetworkUsernameUrl(
              props.social_network,
              props.username,
            )}
            target="_blank"
          >
            @{props.username}
          </a>
        </h3>
      )}
      <h5>
        Please check that this social handle exists and ensure it's spelt
        correctly and in full.
      </h5>
      <h5>
        Want us to try again?
        {timer > 0 ? (
          <span>
            &nbsp;You can use our deep search in:
            <br />
            <strong className="user-try-again-time">{timer} seconds</strong>
          </span>
        ) : null}
      </h5>
      <button
        onClick={props.searchInfluencer}
        disabled={timer > 0}
        className="btn btn-primary"
      >
        Use deep search
      </button>
      {props.social_network !== "only_humanz" && (
        <>
          <div style={{ margin: 10 }}>or</div>
          <GenerateAuthLink
            buttonContent={`Get ${props.social_network} auth link`}
            buttonProps={{ background: "#4267b2" }}
            hintUserName={props.username}
            provider={props.social_network}
          />
        </>
      )}
    </div>
  );
}
