import { InfoToolTip } from "../../general_components/InfoToolTip";
import { SingleInfoBox } from "../CampaignManager/CampaignManagerInterfaces";

const CampaignInfoBox = ({ box }: { box: SingleInfoBox }) => {
  return (
    <div className={`single-info-box ${box?.variant}`} key={box.title}>
      {box.info ? (
        <InfoToolTip overlay={box.info}>
          <div className="single-info-box-title">{box.title}</div>
        </InfoToolTip>
      ) : (
        <div className="single-info-box-title">{box.title}</div>
      )}
      <div className="single-info-box-content">
        {box?.status ? (
          <div
            style={{
              color: box.status.type === "success" ? "#00A99D" : "#F56565",
              fontSize: 14,
            }}
          >
            <i
              className={`fa-sharp fa-solid fa-circle-${
                box.status.type === "success" ? "check" : "exclamation"
              }`}
              style={{ marginRight: 5 }}
            />
            {box.status.label}
          </div>
        ) : (
          <div />
        )}
        <div className="single-info-box-count">{box?.count}</div>
      </div>
    </div>
  );
};

export default CampaignInfoBox;
