// TODO - lint
/* eslint-disable no-unused-vars, no-plusplus, no-plusplus, default-case, no-nested-ternary, no-unused-vars, import/no-default-export */
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import React, { useContext } from "react";
import Infinite from "react-infinite";
import { TwitterTweetEmbed } from "react-twitter-embed";
import YouTube from "react-youtube";
import { RatioMedia } from "../../../../../ui/RatioMedia";
import NoDataMessage from "../../../general_components/NoDataMessage";
import { formatBigNumbers, parseDate } from "../../../utilities/general";
import { RoiContext } from "./RoiContext";
// interface Props {
//     loaded: boolean,
//     summaryData: any,
//     allContents: any,
//     fromCampaign: boolean,
//     isAmbassador: boolean
// }

const splitInfluencersToRequestTimeGroups = (posts) => {
  if (!posts) return { posts: [], dates: [] };
  const postsByDates = [];
  posts.forEach((p) => {
    const date = moment(p.media_creation_time || p.media_linked_at).format(
      window.time.short_date,
    );
    let inserted = false;
    postsByDates.forEach((d) => {
      if (d.date === date) {
        d.posts.push(p);
        inserted = true;
      }
    });
    if (!inserted) {
      postsByDates.push({ date, posts: [p] });
    }
  });

  const sortedByDate = postsByDates.sort(
    (a, b) => parseDate(b.date, "dd-mm-yyyy") - parseDate(a.date, "dd-mm-yyyy"),
  );

  return sortedByDate;
};

class RoiInfluencersInner extends React.PureComponent {
  render() {
    return (
      <div className="main-middle-container" style={{ position: "relative" }}>
        {
          this.props.loaded && this.props.summaryData ? (
            this.renderAllContentsTab()
          ) : (
            /* Loader */
            <div className="logo-loader">
              <div className="loader" />
              <img src={"/images/humanz-logo.png"} />
            </div>
          )
          /* End Loader */
        }
      </div>
    );
  }

  renderAllContentsTab() {
    const { summaryData, allContents, shareDatesContent } = this.props;
    if ((!allContents || !allContents.length) && this.props.loaded) {
      return (
        <NoDataMessage style={{ marginTop: 20 }}>
          No content to display right now.
        </NoDataMessage>
      );
    }
    const instagramPosts = allContents.filter((x) => x.post_type === "ig_post");
    const instagramStories = allContents.filter(
      (x) => x.post_type === "ig_story" && x.linked_media,
    );
    const twitterTweets = allContents.filter((x) => x.post_type === "tw_tweet");
    const youtubeVideos = allContents.filter((x) => x.post_type === "yt_video");
    const tiktokVideos = allContents.filter((x) => x.post_type === "tk_video");
    // let currentTab = this.getContentsTab(instagramPosts, instagramStories, twitterTweets, youtubeVideos, tiktokVideos);
    const instagramPostsFilterByDate =
      splitInfluencersToRequestTimeGroups(instagramPosts);
    const instagramStoriesFilterByDates =
      splitInfluencersToRequestTimeGroups(instagramStories);
    const tiktokVideosFilterByDate =
      splitInfluencersToRequestTimeGroups(tiktokVideos);
    const twitterTweetsFilterByDate =
      splitInfluencersToRequestTimeGroups(twitterTweets);
    const youtubeVideosFilterByDate =
      splitInfluencersToRequestTimeGroups(youtubeVideos);

    return (
      <div className="roi-main-section">
        <this.props.Header />

        {(instagramPosts.length > 0 ||
          instagramStories.length ||
          tiktokVideos.length > 0 ||
          youtubeVideos.length ||
          twitterTweets.length > 0) && (
          <Tabs
            renderTabBar={() => <InkTabBar />}
            renderTabContent={() => <TabContent />}
            // activeKey={currentTab}
            // onChange={t => this.setState({ contentsTab: t })}
          >
            {instagramPosts.length > 0 && (
              <TabPane
                tab={
                  <span>
                    INSTAGRAM POSTS{" "}
                    <span className="optional-title">{` (${instagramPosts.length})`}</span>
                  </span>
                }
                key="ig_post"
              >
                <Infinite
                  className="campaign-influencers-table-container"
                  elementHeight={
                    !shareDatesContent
                      ? 565
                      : instagramPostsFilterByDate.map(
                          (s) => Math.ceil(s.posts.length / 3) * 571 + 30.7,
                        )
                  }
                  useWindowAsScrollContainer
                >
                  {!shareDatesContent
                    ? this.renderMediaCubeRow(instagramPosts)
                    : instagramPostsFilterByDate.map((s) => (
                        <Box key={s.date} mt="30px">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            py="3px"
                            borderY="1px"
                            borderColor="#dadada"
                          >
                            <Text
                              fontSize="lg"
                              color="blackAlpha.700"
                              fontWeight="bold"
                            >
                              {s.date}
                            </Text>
                          </Box>
                          {this.renderMediaCubeRow(s.posts)}
                        </Box>
                      ))}
                </Infinite>
              </TabPane>
            )}
            {instagramStories.length > 0 && (
              <TabPane
                tab={
                  <span>
                    INSTAGRAM STORIES
                    <span className="optional-title">{` (${instagramStories.length})`}</span>
                  </span>
                }
                key="ig_story"
              >
                <Infinite
                  className="campaign-influencers-table-container"
                  elementHeight={
                    !shareDatesContent
                      ? 620
                      : instagramStoriesFilterByDates.map(
                          (s) => Math.ceil(s.posts.length / 3) * 620 + 30.7,
                        )
                  }
                  useWindowAsScrollContainer
                >
                  {!shareDatesContent
                    ? this.renderMediaCubeRow(instagramStories)
                    : instagramStoriesFilterByDates.map((s) => (
                        <Box key={s.date} mt="30px">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            py="3px"
                            borderY="1px"
                            borderColor="#dadada"
                          >
                            <Text
                              fontSize="lg"
                              color="blackAlpha.700"
                              fontWeight="bold"
                            >
                              {s.date}
                            </Text>
                          </Box>
                          {this.renderMediaCubeRow(s.posts)}
                        </Box>
                      ))}
                </Infinite>
              </TabPane>
            )}
            {tiktokVideos.length > 0 && (
              <TabPane
                tab={
                  <span>
                    TIKTOK VIDEOS
                    <span className="optional-title">{` (${tiktokVideos.length})`}</span>
                  </span>
                }
                key="tk_video"
              >
                <Infinite
                  className="campaign-influencers-table-container"
                  elementHeight={
                    !shareDatesContent
                      ? 565
                      : tiktokVideosFilterByDate.map(
                          (s) => Math.ceil(s.posts.length / 3) * 551 + 30.7,
                        )
                  }
                  useWindowAsScrollContainer
                >
                  {!shareDatesContent
                    ? this.renderMediaCubeRow(tiktokVideos)
                    : tiktokVideosFilterByDate.map((s) => (
                        <Box key={s.date} mt="30px">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            py="3px"
                            borderY="1px"
                            borderColor="#dadada"
                          >
                            <Text
                              fontSize="lg"
                              color="blackAlpha.700"
                              fontWeight="bold"
                            >
                              {s.date}
                            </Text>
                          </Box>
                          {this.renderMediaCubeRow(s.posts)}
                        </Box>
                      ))}
                </Infinite>
              </TabPane>
            )}
            {twitterTweets.length > 0 && (
              <TabPane
                tab={
                  <span>
                    TWITTER TWEETS
                    <span className="optional-title">{` (${twitterTweets.length})`}</span>
                  </span>
                }
                key="tw_tweet"
              >
                <Infinite
                  className="campaign-influencers-table-container"
                  elementHeight={
                    !shareDatesContent
                      ? 565
                      : twitterTweetsFilterByDate.map(
                          (s) => Math.ceil(s.posts.length / 3) * 571 + 30.7,
                        )
                  }
                  useWindowAsScrollContainer
                >
                  {!shareDatesContent
                    ? this.renderMediaCubeRow(twitterTweets)
                    : twitterTweetsFilterByDate.map((s) => (
                        <Box key={s.date} mt="30px">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            py="3px"
                            borderY="1px"
                            borderColor="#dadada"
                          >
                            <Text
                              fontSize="lg"
                              color="blackAlpha.700"
                              fontWeight="bold"
                            >
                              {s.date}
                            </Text>
                          </Box>
                          {this.renderMediaCubeRow(s.posts)}
                        </Box>
                      ))}
                </Infinite>
              </TabPane>
            )}
            {youtubeVideos.length > 0 && (
              <TabPane
                tab={
                  <span>
                    YOUTUBE VIDEOS
                    <span className="optional-title">{` (${youtubeVideos.length})`}</span>
                  </span>
                }
                key="yt_video"
              >
                <Infinite
                  className="campaign-influencers-table-container"
                  elementHeight={
                    !shareDatesContent
                      ? 565
                      : youtubeVideosFilterByDate.map(
                          (s) => Math.ceil(s.posts.length / 3) * 565 + 30.7,
                        )
                  }
                  useWindowAsScrollContainer
                >
                  {!shareDatesContent
                    ? this.renderMediaCubeRow(youtubeVideos)
                    : youtubeVideosFilterByDate.map((s) => (
                        <Box key={s.date} mt="30px">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            py="3px"
                            borderY="1px"
                            borderColor="#dadada"
                          >
                            <Text
                              fontSize="lg"
                              color="blackAlpha.700"
                              fontWeight="bold"
                            >
                              {s.date}
                            </Text>
                          </Box>
                          {this.renderMediaCubeRow(s.posts)}
                        </Box>
                      ))}
                </Infinite>
              </TabPane>
            )}
          </Tabs>
        )}
      </div>
    );
  }

  renderMediaCubeRow = (influencers) => {
    const CUBES_PER_ROW = 3;
    let numberOfRows = influencers.length / CUBES_PER_ROW;
    if (influencers % CUBES_PER_ROW !== 0) {
      numberOfRows += 1;
    }
    const groups = [];
    for (let i = 0; i < numberOfRows; i++) {
      const baseNumber = i * CUBES_PER_ROW;
      const group = [];
      for (
        let j = 0;
        j + baseNumber < influencers.length && j < CUBES_PER_ROW;
        j++
      ) {
        group.push(influencers[baseNumber + j]);
      }
      if (group.length > 0) {
        groups.push(group);
      }
    }

    return groups.map(this.renderMediaGroup);
  };

  renderMediaGroup = (group, idx) => {
    return (
      <div style={{ display: "flex", gap: "10px" }} key={idx}>
        {group.map(this.renderMediaCube)}
      </div>
    );
  };

  openInfluencerDetails = (id, name) => {
    if (this.props.shared) {
      window.open_in_new_tab(`https://www.instagram.com/${name}`);
    } else {
      this.props.openInfluencerSegmentations(id);
    }
  };

  renderMediaCube = (media, idx) => {
    let post = null;
    switch (media.post_type) {
      case "ig_post":
        post = (
          <div className="blurable-frame ig-frame" key={idx}>
            <iframe
              src={`https://www.instagram.com/p/${media.linked_media}/embed/captioned`}
              width="288"
              height="535"
              frameBorder="0"
              scrolling="no"
            ></iframe>
          </div>
        );
        break;
      case "ig_story":
        {
          const influencer = this.props.igInfluencersMap[media.influencer_id];
          post = (
            <div key={idx} style={{ marginTop: 10 }}>
              <Box
                background={"#EEE"}
                border={"1px solid #EAE8E8"}
                borderRadius={"8px"}
              >
                <Flex
                  cursor={"pointer"}
                  alignItems={"center"}
                  gap="10px"
                  padding={"5px 10px"}
                  onClick={() =>
                    this.openInfluencerDetails(
                      media.influencer_id,
                      influencer.name,
                    )
                  }
                >
                  <Avatar
                    src={influencer?.image}
                    width="30px"
                    aspectRatio={1}
                    size
                  />
                  <Text fontSize={"medium"}>{influencer?.name}</Text>
                </Flex>
                <RatioMedia
                  src={media.linked_media}
                  type={
                    media.mime_type && media.mime_type.includes("video")
                      ? "video"
                      : "image"
                  }
                  soundVideo={true}
                  height={"515px"}
                  width={"290px"}
                  contain={true}
                />
                <Box
                  border={"1px solid #EAE8E8"}
                  borderRadius={"8px"}
                  padding={"5px 10px"}
                >
                  <Box
                    background={"white"}
                    border={"1px solid #EAE8E8"}
                    borderRadius={"8px"}
                    padding={"5px 10px"}
                  >
                    {
                      {
                        ongoing: (
                          <Text color="green.500">
                            <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                            Story still running
                          </Text>
                        ),
                        deleted: (
                          <Text color="red.500">
                            <i
                              className="fa fa-times-circle"
                              aria-hidden="true"
                            />{" "}
                            Deleted story
                          </Text>
                        ),
                        api_not_connected: (
                          <Text>
                            Views Count:{" "}
                            {formatBigNumbers(media.engagemnts.views)}
                          </Text>
                        ),
                        valid: (
                          <Text>
                            Views Count:{" "}
                            {formatBigNumbers(media.engagemnts.views)}
                          </Text>
                        ),
                      }[media.story_status]
                    }
                  </Box>
                </Box>
              </Box>
            </div>
          );
        }
        break;
      case "tw_tweet":
        post = (
          <div className="blurable-frame ig-frame" key={idx}>
            <TwitterTweetEmbed tweetId={media.linked_media} />
          </div>
        );
        break;
      case "yt_video":
        post = (
          <div className="blurable-frame ig-frame" key={idx}>
            <YouTube
              videoId={media.linked_media}
              opts={{ width: "290", height: 535 }}
            />
          </div>
        );
        break;
      case "tk_video":
        post = (
          <div
            className="blurable-frame"
            style={{
              display: "inline-block",
              width: 290,
              height: 535,
              overflow: "hidden",
              paddingLeft: 20,
              marginTop: 10,
            }}
            key={idx}
          >
            <iframe
              style={{
                display: "inline-block",
                width: 290,
                height: 535,
                overflow: "hidden",
              }}
              src={`https://www.tiktok.com/embed/${media.linked_media}`}
              frameBorder={"0"}
              allow={
                "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              }
            />
          </div>
        );
        break;
    }
    return (
      <div className={"single-post-content"}>
        {this.props.isAmbassador && (
          <div
            className="media-header"
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "-20px",
            }}
          >
            <div style={{ flex: 1 }}>
              <span>
                Attached at:{" "}
                {moment(media.attached_at).format(window.time.date_with_hour)}
              </span>
              {media.is_manual ? (
                <span className={"links-label"}>Attached manually</span>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {post}
      </div>
    );
  };
}

export default function RoiInfluencers(props) {
  const context = useContext(RoiContext);

  const igInfluencersMap = React.useMemo(() => {
    const dic = {};
    if (props.influencersData?.length) {
      props.influencersData.forEach((i) => {
        if (
          i.influencerDetails?.influencer_id &&
          i.influencerDetails?.social_network_data?.instagram
        ) {
          dic[i.influencerDetails.influencer_id] = {
            name:
              i.influencerDetails.social_network_data.instagram.username ||
              i.influencerDetails.name,
            image:
              i.influencerDetails.social_network_data.instagram.image_url ||
              i.influencerDetails.picture_url,
          };
        }
      });
    }
    return dic;
  }, [props.influencersData]);

  return (
    <RoiInfluencersInner
      {...context}
      {...props}
      igInfluencersMap={igInfluencersMap}
    />
  );
}
