/* eslint-disable no-nested-ternary */
import { Alert, Flex } from "@chakra-ui/react";
import moment from "moment";
import CampaignType from "../FormUI/CampaignType";
import FormCard from "../FormUI/FormCard";

type Props = {
  state: any;
  setState: any;
  renderNotesBtn: any;
  shouldDisableTogglePixel: any;
  changeCampaignType: any;
  children?: any;
  hasNoWebsites?: boolean;
};

const BriefType = ({
  state,
  setState,
  renderNotesBtn,
  changeCampaignType,
  children,
  shouldDisableTogglePixel,
  hasNoWebsites,
}: Props) => {
  const campaignDatesPassed = state.fields?.sales?.sales_start_time
    ? moment().isAfter(state.fields.sales.sales_start_time)
    : false || moment().isAfter(state.fields?.tasks?.campaign_dates?.start);
  const pixelDisabled = shouldDisableTogglePixel || hasNoWebsites;

  return (
    <FormCard>
      <div
        className="campaigns-title"
        style={{ color: "#249ff0", fontSize: 24 }}
      >
        <span>Campaign Type</span>
        {renderNotesBtn(1)}
      </div>
      <Flex justifyContent={"flex-start"} flexDirection="column" gap={6}>
        <CampaignType
          type="one_time"
          disabled={state.draftMode ? false : campaignDatesPassed}
          active={state.campaignMode === "one_time"}
          onClick={() => {
            setState(
              {
                campaignMode: "one_time",
              },
              () => {
                changeCampaignType("one_time");
              },
            );
          }}
        />
        <CampaignType
          type="monthly"
          active={state.campaignMode === "monthly"}
          disabled={state.draftMode ? false : campaignDatesPassed}
          onClick={() => {
            setState(
              {
                campaignMode: "monthly",
              },
              () => {
                changeCampaignType("monthly");
              },
            );
          }}
        />
        <CampaignType
          type="affiliation"
          disabled={
            state.draftMode && !pixelDisabled
              ? false
              : campaignDatesPassed || pixelDisabled
          }
          active={state.campaignMode === "affiliation"}
          onClick={() => {
            setState(
              {
                campaignMode: "affiliation",
              },
              () => {
                changeCampaignType("affiliation");
              },
            );
          }}
        />
      </Flex>
      {state.campaignMode === "affiliation" ||
      hasNoWebsites ||
      pixelDisabled ||
      campaignDatesPassed ? (
        <Alert
          margin={"20px auto"}
          maxWidth={690}
          status={"info"}
          variant={"subtle"}
          backgroundColor="gray.100"
          textAlign="center"
          alignContent={"center"}
          justifyContent="center"
        >
          {hasNoWebsites ? (
            <p>
              Sales campaign only available for users with humanz tracking pixel
              installed on their website. <br /> Please contact us in order to
              add a new tracking pixel and enable sales campaigns.
            </p>
          ) : state.campaignMode === "affiliation" ? (
            <p>Pixel tracking can&apos;t be disabled once sales are active.</p>
          ) : (
            <p>
              Campaign type can&apos;t be changed once the campaign is live.
            </p>
          )}
        </Alert>
      ) : null}
      {children}
    </FormCard>
  );
};

export default BriefType;
