// TODO - lint
/* eslint-disable func-style, camelcase, no-restricted-syntax, guard-for-in, vars-on-top, no-var, no-restricted-syntax, guard-for-in, block-scoped-var, camelcase, block-scoped-var, camelcase, default-param-last, vars-on-top, no-var, vars-on-top, no-var, camelcase, no-param-reassign, camelcase, camelcase, no-param-reassign, camelcase, eqeqeq, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, import/no-default-export */
import { transalteNotifData } from "../components/feed_components/utilities/general";

function removeDuplicates(arr, prop) {
  const new_arr = [];
  const lookup = {};

  for (var i in arr) {
    lookup[arr[i][prop]] = arr[i];
  }

  for (i in lookup) {
    new_arr.push(lookup[i]);
  }

  return new_arr;
}

const notificationsReducer = (
  state = {
    notifications: null,
    unseen_notif_count: 0,
    query_time_from: null,
    hasMore: false,
  },
  action,
) => {
  let array;
  switch (action.type) {
    case "GET_NOTIFICATIONS_FULFILLED":
      array = action.payload;
      var newArray = array.map((n) => transalteNotifData(n));
      var query_time =
        array.length > 0 ? array[array.length - 1].creation_time : null;
      state = {
        ...state,
        notifications: newArray,
        query_time_from: query_time,
        hasMore: action.payload.hasMore,
      };
      break;
    case "UPDATE_NOTIFICATIONS_FULFILLED":
      array = state.notifications.concat(action.payload);
      array = removeDuplicates(array, "id")
        .map((n) => transalteNotifData(n))
        .filter((x) => x)
        .sort(function (a, b) {
          return new Date(b.creation_time) - new Date(a.creation_time);
        });
      query_time =
        array.length > 0 ? array[array.length - 1].creation_time : null;
      state = {
        ...state,
        notifications: array,
        query_time_from: query_time,
        hasMore: action.payload.hasMore,
      };
      break;
    case "GET_NEW_NOTIFICATIONS_COUNT_FULFILLED":
      if (
        state.unseen_notif_count != action.payload.not_sent_notifications_count
      ) {
        state = {
          ...state,
          unseen_notif_count: action.payload.not_sent_notifications_count,
        };
      }
      break;
    case "ADD_NOTIF_TO_COUNTER":
      state = {
        ...state,
        unseen_notif_count: state.unseen_notif_count + 1,
      };
      break;
    case "NOTIFICATION_DELETED_FULFILLED":
      array = state.notifications.filter((n) => n.id !== action.payload);
      state = {
        ...state,
        notifications: array,
      };
      break;
    case "ALL_NOTIFICATIONS_DELETED_FULFILLED":
      state = {
        ...state,
        notifications: [],
      };
      break;
    default:
      break;
  }
  return state;
};
export default notificationsReducer;
