// General Imports
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import { getAgents } from "../../../actions/userActions";

export const AgentDetails = ({ details }) => {
  return (
    <span className="agent-details">
      <span className="agent-d-row">
        <span>Name:</span> <strong>{details.name}</strong>
      </span>
      <span className="agent-d-row">
        <span>Email:</span> <strong>{details.email}</strong>
      </span>
      <span className="agent-d-row">
        <span>Phone:</span> <strong>{details.phone}</strong>
      </span>
      <span className="agent-d-row">
        <span>Company Id:</span> <strong>{details.company_id}</strong>
      </span>
    </span>
  );
};

class AgentSelectInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      options: [],
      loading: false,
      selected: -1,
    };
  }

  componentDidMount() {
    this.initOptions();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.selected
    ) {
      this.setState({ selected: this.props.value }, () => {
        this.select.closeMenu();
      });
    }
  }

  initOptions(refresh) {
    if (
      !refresh &&
      this.props.user.agents &&
      this.props.user.agents.length > 0
    ) {
      this.setState({ loading: false }, () => this.loadOptions());
    } else {
      this.setState({ loading: true });
      this.props.getAgents(this.state.name, (options) => {
        this.setState({ loading: false }, () => this.loadOptions(options));
      });
    }
    if (this.props.value) {
      this.setState({ selected: this.props.value });
    }
  }

  loadOptions = (options) => {
    // eslint-disable-next-line no-param-reassign
    options = options || this.props.user.agents;
    const filtered = options.filter(
      (x) =>
        x.name && x.name.toLowerCase().includes(this.state.name.toLowerCase()),
    );
    this.setState({ options: filtered });
  };

  handleInputChange = (name) => {
    if (this.state.loading) return;
    this.setState({ name }, () => this.loadOptions());
  };

  handleSelect = (value) => {
    this.setState({ selected: value });
    this.select.closeMenu();
    this.props.onChange(value);
  };

  editAgency = (details) => {
    this.props.editAgency(details);
  };

  render() {
    return (
      <Select
        ref={(r) => {
          this.select = r;
        }}
        noResultsText={this.state.loading ? "Loading..." : "No results found"}
        filterOptions={false}
        optionComponent={this.CustomOption}
        valueKey="agent_id"
        labelKey="name"
        onChange={this.handleSelect}
        value={this.state.selected}
        isLoading={this.state.loading}
        inputValue={this.state.name}
        onInputChange={this.handleInputChange}
        options={this.state.options}
        simpleValue
        clearable={true}
        name="selected-state"
        disabled={false}
        searchable={true}
        placeholder={"Select an agent..."}
      />
    );
  }

  CustomOption = ({ option }) => {
    return (
      <div className="custom-option" key={option.agent_id}>
        <span
          onClick={() => this.handleSelect(option.agent_id)}
          style={{ flex: 1 }}
        >
          {option.name}
        </span>
        <Tooltip
          trigger={["hover"]}
          placement="top"
          overlayClassName={"agent-details-container"}
          overlay={<AgentDetails details={option} />}
        >
          <i
            className="fal fa-info-circle"
            style={{ marginLeft: 10, marginRight: 10 }}
          />
        </Tooltip>
        {!this.props.editDisabled && (
          <i className="fal fa-pen" onClick={() => this.editAgency(option)} />
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAgents: (name, callback) => {
      dispatch(getAgents(name, callback));
    },
  };
};

export const AgentSelect = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AgentSelectInner);
