import React from "react";
import Dialog from "rc-dialog";
import { connect } from "react-redux";
import {
  addAgent,
  updateAgent,
  deleteAgencyBank,
  saveAgencyBank,
  deleteAgency,
  saveUKAgencyBank,
  saveTurkeyAgencyBank,
  saveUSAgencyBank,
} from "../../../actions/userActions";
import { AgencyBankDetails, getTaxNumberLabel } from "./AgencyBankDetails";
import { InfoToolTip } from "../general_components/InfoToolTip";

class AddAgentDialogInner extends React.Component {
  state = {
    saving: false,
    bankChanged: false,
    agentId: null,
    email: null,
    phone: null,
    isGlobalAgent: false,
    allowedToEditAgent: true,
  };

  componentDidMount() {
    if (this.props.agentDetails) {
      this.setState(
        {
          agentId: this.props.agentDetails.agent_id,
          isGlobalAgent: this.props.agentDetails.is_global_agent,
          allowedToEditAgent: this.props.agentDetails.allowed_to_edit_agent,
        },
        () => {
          this.nameInput.value = this.props.agentDetails.name;
          this.emailInput.value = this.props.agentDetails.email;
          this.phoneInput.value = this.props.agentDetails.phone;
          this.companyId.value = this.props.agentDetails.company_id;
          this.isGlobalAgent = this.props.agentDetails.is_global_agent;
          this.allowedToEditAgent =
            this.props.agentDetails.allowed_to_edit_agent;
        },
      );
    }
  }

  close = (id) => {
    this.props.onClose(id);
  };

  save = () => {
    const name = this.nameInput.value;
    const email = this.emailInput.value;
    const phone = this.phoneInput.value;
    const companyId = this.companyId.value;
    const { isGlobalAgent } = this.state;
    if (!name || !email || !phone || !companyId || this.state.saving) {
      return;
    }
    this.setState({ saving: true, error: "" });
    const data = {
      name,
      email,
      phone,
      company_id: companyId,
      is_global_agent: isGlobalAgent,
    };

    if (this.state.agentId) {
      data.agent_id = this.state.agentId;
      this.props.updateAgent(this.state.agentId, data, (id, error) => {
        if (id) {
          this.bankDetails.saveBankDetails();
          setTimeout(() => {
            if (this.props.updateAgentsSelect) {
              this.props.updateAgentsSelect();
            }
          }, 300);
        } else {
          if (!error) {
            // eslint-disable-next-line no-param-reassign
            error = "Something Went Wrong";
          }
          this.setState({ saving: false, error });
        }
      });
    } else {
      this.props.addAgent(data, (id, error) => {
        if (id) {
          this.setState({ agentId: id }, () => {
            this.bankDetails.saveBankDetails();
          });
          setTimeout(() => {
            if (this.props.updateAgentsSelect) {
              this.props.updateAgentsSelect();
            }
          }, 300);
        } else {
          if (!error) {
            // eslint-disable-next-line no-param-reassign
            error = "Something Went Wrong";
          }
          this.setState({ saving: false, error });
        }
      });
    }
  };

  saveBankDetailsCallback = (success) => {
    if (success) {
      this.close(this.state.agentId);
    } else {
      this.setState({ saving: false });
    }
  };

  deleteAgency = () => {
    if (this.state.saving) return;
    this.setState({ saving: true });
    this.props.deleteAgency(this.props.agentDetails.agent_id, (success) => {
      if (success) {
        try {
          this.props.onDelete();
          this.close();
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else {
        this.setState({ saving: false });
      }
    });
  };

  render() {
    return (
      <Dialog
        className="confirm-action-dialog"
        visible={true}
        style={{ width: 650, marginTop: 20 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <h3>{this.props.agentDetails ? "Edit Agency" : "Add Agency"}</h3>

        <div
          className={`flex-row-space-around${
            this.state.saving ? " disabled " : ""
          }`}
        >
          <div style={{ width: "45%" }}>
            <strong className="label-title">Name </strong>
            <div>
              <input
                className="input-line"
                ref={(r) => {
                  this.nameInput = r;
                }}
              />
            </div>
          </div>
          <div style={{ width: "45%" }}>
            <strong className="label-title">email </strong>
            <div>
              <input
                className="input-line"
                ref={(r) => {
                  this.emailInput = r;
                }}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
            </div>
          </div>

          <div style={{ width: "45%" }}>
            <strong className="label-title">phone </strong>
            <div>
              <input
                className="input-line"
                ref={(r) => {
                  this.phoneInput = r;
                }}
                onChange={(e) => {
                  this.setState({ phone: e.target.value });
                }}
              />
            </div>
          </div>

          <div style={{ width: "45%" }}>
            <strong className="label-title">
              {getTaxNumberLabel(
                this.props?.user?.userDetails?.company_details?.country,
              )}
            </strong>
            <div>
              <input
                className="input-line"
                ref={(r) => {
                  this.companyId = r;
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", marginLeft: "15px" }}>
          <div style={{ flexGrow: 2 }}>
            <AgencyBankDetails
              saving={this.state.saving}
              deleteBankDetails={deleteAgencyBank}
              addBankAccount={saveAgencyBank}
              addTurkeyBankAccount={saveTurkeyAgencyBank}
              addBankAccountUK={saveUKAgencyBank}
              addUSBankAccount={saveUSAgencyBank}
              user={this.props.user}
              agentId={this.state.agentId}
              companyId={this.companyId?.value}
              agentPhone={this.state.phone}
              agentEmail={this.state.email}
              callback={this.saveBankDetailsCallback}
              allowedToEditAgent={!!this.state.allowedToEditAgent}
              ref={(r) => {
                this.bankDetails = r;
              }}
            />
          </div>
          {this.props?.user?.userDetails?.sidebar_data
            ?.global_agents_allowed_countires.length > 0 && (
            <div
              className="checkbox"
              style={{
                display: "flex",
                flexGrow: 1.5,
                alignItems: "start",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  checked={this.state.isGlobalAgent}
                  style={{ padding: "0px !important", marginRight: 5 }}
                  onChange={() =>
                    this.setState({ isGlobalAgent: !this.state.isGlobalAgent })
                  }
                />
                <span
                  className="checkbox-material"
                  style={{ marginRight: "10px" }}
                >
                  <span className="check"></span>
                </span>
                Global agent
                <InfoToolTip
                  overlay={
                    "A global agent is visible to all of the advertisers in your country"
                  }
                ></InfoToolTip>
              </label>
            </div>
          )}
        </div>

        {this.state.error && (
          <div style={{ fontSize: 15, color: "#f15a24", marginTop: 10 }}>
            {this.state.error}
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 15,
          }}
        >
          <button
            className={`btn btn-primry ${this.state.saving ? "disabled" : ""}`}
            onClick={this.close}
          >
            Cancel
          </button>
          {this.props.agentDetails && !!this.state.allowedToEditAgent ? (
            <button
              className={`btn btn-primary delete ${
                this.state.saving ? "disabled" : ""
              }`}
              onClick={this.deleteAgency}
            >
              Delete Agent
            </button>
          ) : null}
          {!!this.state.allowedToEditAgent && (
            <button
              className={`btn btn-primary ${
                this.state.saving ? "disabled" : ""
              }`}
              onClick={this.save}
            >
              SAVE
            </button>
          )}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAgent: (data, callback) => {
      dispatch(addAgent(data, callback));
    },
    addUSBankAccount: (agentId, data, callback) => {
      dispatch(saveUSAgencyBank(agentId, data, callback));
    },
    deleteAgency: (id, callback) => {
      dispatch(deleteAgency(id, callback));
    },
    updateAgent: (agentId, data, callback) => {
      dispatch(updateAgent(agentId, data, callback));
    },
  };
};

export const AddAgentDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAgentDialogInner);
