/**
 * You can modify this file
 *
 * @version 5
 */
import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import qs from "qs";
import { getBackendUri, getHeaders } from "src/actions/commonActionsConf";
import { setAxiosInterceptor } from "src/actions/security";

const baseConfig: () => AxiosRequestConfig = () => ({
  baseURL: getBackendUri(),
  withCredentials: true,
  headers: getHeaders().headers,
  paramsSerializer: (param) => qs.stringify(param, { indices: false }),
});

class RequestError extends Error {
  constructor(
    public message: string,
    public status?: number,
    public response?: AxiosResponse,
  ) {
    super(message);
  }

  isApiException = true;
}

let axiosInstance: AxiosInstance;

const getAxiosInstance = (): AxiosInstance => {
  if (!axiosInstance) {
    axiosInstance = Axios.create(baseConfig());

    // Response interceptor
    axiosInstance.interceptors.response.use(
      // Generated like this
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (async (response: AxiosResponse): Promise<SwaggerResponse<any>> => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        /**
         * Example on response manipulation
         *
         * @example
         *   const swaggerResponse: SwaggerResponse = {
         *     ...response,
         *   };
         *   return swaggerResponse;
         */
        return response;
        // Generated like this
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any,
      (error: AxiosError) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response) {
          return Promise.reject(
            new RequestError(
              error?.response?.data?.toString(),
              error.response.status,
              error.response,
            ),
          );
        }

        if (error.isAxiosError) {
          return Promise.reject(new RequestError("noInternetConnection"));
        }
        return Promise.reject(error);
      },
    );
  }

  // ًًRequest interceptor
  setAxiosInterceptor(axiosInstance, (async (
    response: AxiosResponse,
  ): Promise<SwaggerResponse<any>> => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    /**
     * Example on response manipulation
     *
     * @example
     *   const swaggerResponse: SwaggerResponse = {
     *     ...response,
     *   };
     *   return swaggerResponse;
     */
    return response;
    // Generated like this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any);

  return axiosInstance;
};

// Generated like this
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Security = any[] | undefined;

export type SwaggerResponse<R> = AxiosResponse<R>;

export { getAxiosInstance, RequestError };
