import React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { RequiredBy } from "src/models/RequiredBy";
import { changeEnterprise } from "../../../actions/userActions";
import { EnterpriseUser } from "../../../models/Api";
import { UserReducer } from "../../../reducers/userReducer";
import WatchClickOutside from "./WatchClickOutside";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { navigate: NavigateFunction };

class UserProfileImageDialogInner extends React.Component<Props> {
  state = {
    showMenu: false,
  };

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  selectEnterprise = (id: number) => {
    this.props.changeEnterprise(id);
  };

  render() {
    const { userDetails } = this.props.user;
    return (
      <>
        <div
          // eslint-disable-next-line react/no-string-refs
          ref="container"
        >
          <div className="top-user-pic-container" onClick={this.toggleMenu}>
            <img
              className="top-user-pic"
              alt="user"
              src={userDetails?.picture_url}
            />
          </div>
          {this.state.showMenu && this.renderMenu(userDetails)}
        </div>
      </>
    );
  }

  renderMenu = (userDetails: UserReducer["userDetails"]) => {
    return (
      <WatchClickOutside
        // eslint-disable-next-line react/no-string-refs
        container={this.refs.container}
        onClickOutside={this.toggleMenu}
      >
        <div className="user-menu">
          <img alt="user" src={userDetails?.picture_url} />
          <strong>{userDetails?.company_details?.name}</strong>
          <span>{userDetails?.full_name}</span>
          <span>{userDetails?.email}</span>
          <button
            className="btn btn-primary"
            onClick={() => {
              this.props.navigate("/edit-details/account");
              this.closeMenu();
            }}
          >
            Manage Your Account{" "}
          </button>
          <div
            style={{
              height: 1,
              width: "100%",
              background: "#ddd",
              marginTop: 15,
              marginBottom: 15,
            }}
          />
          {userDetails?.sidebar_data?.enterprises
            ?.filter((x) => x.id !== userDetails.user_id)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .map(this.renderEnterpriseRow)}
        </div>
      </WatchClickOutside>
    );
  };

  renderEnterpriseRow = (enterprise: RequiredBy<EnterpriseUser, "id">) => {
    return (
      <div
        className="enterprise-row"
        key={enterprise.id}
        onClick={() => this.selectEnterprise(enterprise.id)}
      >
        <img alt="user" src={enterprise?.image} />
        <div>
          <strong>{enterprise?.name}</strong>
          <span>{enterprise?.email}</span>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: { userReducer: UserReducer }) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeEnterprise: (id: number) => {
      dispatch(changeEnterprise(id));
    },
  };
};

const UserProfileImageDialogConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfileImageDialogInner);

export const UserProfileImageDialog: React.FC = (props) => {
  const navigate = useNavigate();

  return <UserProfileImageDialogConnected {...props} navigate={navigate} />;
};
