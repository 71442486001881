// TODO - lint
/* eslint-disable no-unused-vars, react/no-unescaped-entities, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, no-unused-vars, import/no-default-export */
// General Imports
import React from "react";
import Dialog from "rc-dialog";
import DatePicker from "react-datepicker";
import Toggle from "react-toggle";
import Clipboard from "react-clipboard.js";
import { connect } from "react-redux";

import "../../../css/share-dialog.css";
// Components Import
import moment from "moment";
import Textarea from "react-textarea-autosize";
import CheckBox from "../general_components/CheckBox";

// Actions imports

// Functions import

import {
  getSharedInsight,
  shareInsight,
  disableShareInsight,
} from "../../../actions/shareInsightActions";

class ShareInsightDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareInsight: false,
      comments: {},
      shareLink: null,
      loading: false,
      shareEndTime: moment(new Date()).add(1, "M"),
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    console.log(this.props.insight);
    const insightId = this.props.insight.id;
    this.props.getSharedInsight(insightId, (response) => {
      const newState = { loading: false };
      // the second clause if for endtime components
      if (
        response.share_enabled ||
        (!response.share_enabled && response.link)
      ) {
        newState.shareInsight = response.share_enabled;
        newState.shareLink = response.link;
        newState.comments = response.comments;
        newState.shareEndTime = moment(response.sharing_end_time);
        newState.sharedDataSelected = response.shared_data;
      }
      this.setState(newState);
    });
  }

  onChangeShare = () => {
    this.setState({ loading: true });
    const insightId = this.props.insight.id;
    if (!this.state.shareInsight) {
      // on first time default parameters taken from share for first time sharing
      const dataToSend = {
        share_enabled: true,
        comments: this.state.comments,
        sharing_end_time: this.state.shareEndTime,
        shared_data: this.state.sharedDataSelected,
      };
      this.props.shareInsight(insightId, dataToSend, (data) => {
        if (data) {
          this.setState({
            loading: false,
            shareInsight: true,
            shareLink: data.shared_link,
            shareEndTime: moment(data.share_end_time),
          });
        }
      });
    } else {
      this.props.disableShareInsight(insightId, () => {
        this.setState({ loading: false, shareInsight: false });
      });
    }
  };

  handleUpdate = () => {
    this.setState({ loading: true });
    const insightId = this.props.insight.id;
    const dataToSend = {
      share_enabled: true,
      comments: this.state.comments,
      sharing_end_time: this.state.shareEndTime,
      shared_data: this.state.sharedDataSelected,
    };
    this.props.shareInsight(insightId, dataToSend, (data) => {
      if (data) {
        this.props.handleClose();
      }
    });
  };

  render() {
    const disabledByTime = this.state.shareEndTime < moment(new Date());
    return (
      <Dialog
        onClose={this.props.handleClose}
        className="share-dialog "
        visible={true}
        style={{ marginTop: 100 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">Share Insight</div>
          <div style={{ fontSize: 18, fontWeight: 300, marginTop: 20 }}>
            Share Insight
          </div>

          {this.state.loading ? (
            <div
              className="spinner small"
              style={{ marginTop: 40, marginBottom: 0 }}
            >
              <div className="rect1" />
              <div className="rect2" />
              <div className="rect3" />
              <div className="rect4" />
              <div className="rect5" />
            </div>
          ) : (
            <div>
              <div style={{ marginTop: 20 }}>
                <strong className="label-title" style={{ marginBottom: 15 }}>
                  Share insight {this.props.insight.name}
                </strong>
                <div className="toggle-input">
                  <label>
                    <div className="toggle-label">
                      <div className="fa fa-times" />
                      <div className="fa fa-check" />
                    </div>
                    <Toggle
                      defaultChecked={this.state.shareInsight}
                      icons={false}
                      onChange={this.onChangeShare}
                    />
                  </label>
                </div>
              </div>

              {this.state.shareLink && (
                <div>
                  <div className="input-row" style={{ marginTop: 20 }}>
                    <strong className="label-title">LINK</strong>
                    <div style={{ display: "flex" }}>
                      <input
                        value={this.state.shareLink}
                        className="input-line"
                        readOnly={true}
                      />
                      <Clipboard
                        style={{ background: "transparent", border: "none" }}
                        data-clipboard-text={this.state.shareLink}
                      >
                        <i className="far fa-copy copy-btn" />
                      </Clipboard>
                    </div>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <strong
                      className="label-title"
                      style={{ marginBottom: 15 }}
                    >
                      Comments
                    </strong>
                    <div style={{ marginTop: 25 }}>
                      <strong className="label-title">KPI'S COMMENTS</strong>
                      <div>
                        <Textarea
                          className={"input-line"}
                          defaultValue={this.state.comments.kpi}
                          onChange={(e) => {
                            this.state.comments.kpi = e.target.value;
                            this.setState({});
                          }}
                          minRows={1}
                          maxRows={5}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 15 }}>
                      <strong className="label-title">Tasks COMMENTS</strong>
                      <div>
                        <Textarea
                          className={"input-line"}
                          defaultValue={this.state.comments.tasks}
                          onChange={(e) => {
                            this.state.comments.tasks = e.target.value;
                            this.setState({});
                          }}
                          minRows={1}
                          maxRows={5}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 15 }}>
                      <strong className="label-title">funnel COMMENTS</strong>
                      <div>
                        <Textarea
                          className={"input-line"}
                          defaultValue={this.state.comments.funnel}
                          onChange={(e) => {
                            this.state.comments.funnel = e.target.value;
                            this.setState({});
                          }}
                          minRows={1}
                          maxRows={5}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 15 }}>
                      <strong className="label-title">
                        segmentations COMMENTS
                      </strong>
                      <div>
                        <Textarea
                          className={"input-line"}
                          defaultValue={this.state.comments.segmentation}
                          onChange={(e) => {
                            this.state.comments.segmentation = e.target.value;
                            this.setState({});
                          }}
                          minRows={1}
                          maxRows={5}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <strong
                      className="label-title"
                      style={{ marginBottom: 15 }}
                    >
                      SHARING UNTIL
                    </strong>
                    <div style={{ display: "flex" }}>
                      <div>
                        <div
                          style={{ width: 200, marginLeft: 5 }}
                          className="searchable-input"
                        >
                          <DatePicker
                            error={true}
                            selected={this.state.shareEndTime}
                            isClearable={true}
                            placeholderText="Share end date"
                            dateFormat={window.time.date_with_hour}
                            onChange={(date) => {
                              this.setState({
                                updated: true,
                                shareEndTime: date,
                              });
                            }}
                            className={`input-line ${
                              disabledByTime ? "disabled-by-time" : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            style={{
              paddingBottom: 5,
              paddingTop: 10,
              marginTop: 40,
              textAlign: "center",
            }}
          >
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn share-save-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={this.handleUpdate}
            >
              <span>Save</span>
            </button>
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={() => this.props.handleClose()}
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSharedInsight: (id, callback) => {
      dispatch(getSharedInsight(id, callback));
    },
    shareInsight: (id, data, callback) => {
      dispatch(shareInsight(id, data, callback));
    },
    disableShareInsight: (id, callback) => {
      dispatch(disableShareInsight(id, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShareInsightDialog);
