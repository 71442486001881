import AccordionUI from "../FormUI/AccordionUI";
import AddButton from "../FormUI/AddButton";
import FormCard from "../FormUI/FormCard";
import Indicator from "../FormUI/Indicator";

type Props = {
  renderNotesBtn: any;
  fields: any;
  renderDoDonts: any;
  isShare: boolean;
  onToggleDoDont: any;
  children: any;
};

const BriefDos = ({
  renderNotesBtn,
  fields,
  renderDoDonts,
  isShare,
  onToggleDoDont,
  children,
}: Props) => {
  return (
    <FormCard>
      <div className="create-campaign-title">
        <span>Do&apos;s &amp; Don&apos;ts</span>

        {renderNotesBtn(6)}
      </div>
      <div className="products-overview-section">
        <AccordionUI header="Do's" style={{ marginTop: "24px" }}>
          <Indicator
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <i
                  className="fa-regular fa-circle-check fa-xl"
                  style={{
                    color: "#00A99D",
                    marginRight: "5px",
                  }}
                ></i>
                <div style={{ fontSize: 14, fontWeight: 600 }}>Do&apos;s</div>
              </div>
            }
            description="Include any campaign guidelines that influencers will be
                required to follow if they take part in your campaign. This
                should include all legal requirements."
          >
            <div style={{ width: "60%" }}>
              {fields.instructions.dos.length > 0 &&
                fields.instructions.dos.map((x: any, index: number) =>
                  renderDoDonts(x, index, "dos"),
                )}
              {!isShare && (
                <div>
                  <AddButton
                    size="sm"
                    type="Do's"
                    onClick={() => onToggleDoDont(null, "dos")}
                  />
                </div>
              )}
            </div>
          </Indicator>
        </AccordionUI>
        <AccordionUI header="Don'ts">
          <Indicator
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <i
                  className="fa-regular fa-circle-xmark fa-xl"
                  style={{ color: "#F56565", marginRight: "5px" }}
                ></i>
                <div style={{ fontSize: 14, fontWeight: 600 }}>Don&apos;ts</div>
              </div>
            }
            description="Include any restrictions on what influencers are able to say or
                do when they are involved in your campaign"
          >
            {/* <strong className="label-title">DON'T</strong> */}
            <div style={{ width: "70%" }}>
              {fields.instructions.donts.length > 0 &&
                fields.instructions.donts.map((x: any, index: number) =>
                  renderDoDonts(x, index, "donts"),
                )}
              {!isShare && (
                <div>
                  <AddButton
                    size="sm"
                    type="Don'ts"
                    onClick={() => onToggleDoDont(null, "donts")}
                  />
                </div>
              )}
            </div>
          </Indicator>
        </AccordionUI>
      </div>
      {children}
    </FormCard>
  );
};

export default BriefDos;
