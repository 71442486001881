import { type Colors } from "@chakra-ui/react";

export const colors: Colors = {
  brand: {
    50: "#EBF8FF",
    100: "#BEE3F8",
    200: "#90CDF4",
    300: "#63B3ED",
    400: "#53A6EB",
    500: "#249ff0",
    600: "#3483c6",
    700: "#204d74",
    800: "#2A4365",
    900: "#1A365D",
  },
  brandBlack: {
    500: "#222D3A",
  },
  red: {
    500: "#F56565",
  },
  gray: {
    50: "#F0F0F0",
  },
  green: {
    50: "#e6faf5",
    100: "#bfebe1",
    200: "#99dccd",
    300: "#4cc1a1",
    400: "#00a99d",
    500: "#009688",
    600: "#00897b",
    700: "#00796b",
    800: "#00695c",
    900: "#004d40",
  },
};
