import type React from "react";

export type RequiredBy<T, K extends keyof T> = Required<Pick<T, K>> &
  Omit<T, K>;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type Shift<T extends any[]> = ((...t: T) => any) extends (
  first: any,
  ...rest: infer Rest
) => any
  ? Rest
  : never;

type ShiftUnion<T> = T extends any[] ? Shift<T> : never;

export type DeepRequired<T, P extends string[]> = T extends object
  ? Omit<T, Extract<keyof T, P[0]>> &
      Required<{
        [K in Extract<keyof T, P[0]>]: NonNullable<
          DeepRequired<T[K], ShiftUnion<P>>
        >;
      }>
  : T;

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const assertUnreachable: (x: never) => never = () => {
  throw new Error("Didn't expect to get here");
};

type MaybeArray<T> = T[] | T;
export type ChildrenOfType<T extends React.ElementType> = MaybeArray<
  React.ReactElement<React.ComponentProps<T>>
>;

export type CallbackOfType<
  T extends React.ElementType,
  K extends keyof React.ComponentPropsWithoutRef<T>,
> = NonNullable<React.ComponentProps<T>[K]>;
