import { Box } from "@chakra-ui/react";

const AddArrow = (props: any) => {
  return (
    <div>
      {!props.showAddTask && props.tasksLength === 0 && (
        <Box
          sx={{
            alignItems: "center",
            display: "inline-flex",
            flexDirection: "column",
            gap: "32px",
            marginTop: "30px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="9em"
            width="9em"
            viewBox="0 0 320 512"
          >
            <style></style>
            <path
              d="M318 177.5c3.8-8.8 2-19-4.6-26l-136-144C172.9 2.7 166.6 0 160 0s-12.9 2.7-17.4 7.5l-136 144c-6.6 7-8.4 17.2-4.6 26S14.4 192 24 192H96l0 288c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32l0-288h72c9.6 0 18.2-5.7 22-14.5z"
              fill="#dfdfe2"
            />
          </svg>
          <Box
            sx={{
              alignItems: "center",
              color: "var(--dark-grey, #808080)",
              textAlign: "center",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              padding: "10px",
              display: "flex",
              width: "200px",
              height: "31px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            Add your first task
          </Box>
        </Box>
      )}
    </div>
  );
};

export default AddArrow;
