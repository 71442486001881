import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Input,
  Select,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
// import { numberWithCurrency } from "src/components/feed_components/utilities/general";
import { numberWithCurrency } from "../../../utilities/general";
import CampaignInfoBox from "../CampaignInfoBox";
import {
  CampaignPaymentsContext,
  CampaignPaymentsContextType,
} from "../CampaignPaymentsContext";
import PaymentsTable from "./PaymentsTable";

const PaymentsAccordion = () => {
  const context = React.useContext<CampaignPaymentsContextType>(
    CampaignPaymentsContext,
  );
  const board: any = useSelector((store) => store.boardReducer?.board);
  const [monthFilter, setMonthFilter] = useState<string | null>(null);
  const [influencerFilter, setInfluencerFilter] = useState<string | null>(null);
  const [showInput, setShowInput] = useState(true);
  const handleIconClick = () => {
    setShowInput(true);
    document.getElementById("influencerInput")?.focus();
  };

  const [params] = useSearchParams();

  const { payments } = context;

  const getDefaultMonthIndex = () => {
    const monthStr = params.get("month")
      ? moment(params.get("month")).format("YYYY-MM")
      : moment(board.current_manage_month).format("YYYY-MM");
    if (monthStr) {
      return (
        payments?.months.findIndex((month) => {
          return moment(month.month).format("YYYY-MM") === monthStr;
        }) || 0
      );
    }
    return 0;
  };
  const getPrice: any = (price: number, full?: boolean) => {
    if (!board) {
      return null;
    }
    return numberWithCurrency(price, board.summary.currency, full);
  };

  // total calculations

  const monthTotals = useMemo(
    () =>
      payments?.months
        ?.filter(
          (month) =>
            month.month === monthFilter ||
            !monthFilter ||
            monthFilter === "All",
        )
        .filter((month) => {
          return month.influencers.some((influencer) =>
            influencer?.name
              ?.toLowerCase()
              .includes((influencerFilter || "").toLowerCase()),
          );
        })
        ?.map((month, idx) => {
          const chargedTotal = month.influencers.reduce(
            (influencerSum: number, influencer: any) => {
              const paymentSum: number = influencer.payments.reduce(
                (acc: number, payment: any) => {
                  if (payment.charged) {
                    return (
                      acc +
                      (payment.only_commission ? 0 : payment.price) +
                      payment.commission
                    );
                  }
                  return acc;
                },
                0,
              );
              return influencerSum + paymentSum;
            },
            0,
          );

          const priceTotal = month.influencers.reduce(
            (influencerSum: number, influencer: any) => {
              const paymentSum: number = influencer.payments.reduce(
                (acc: number, payment: any) =>
                  acc +
                  (payment.only_commission ? 0 : payment.price) +
                  payment.commission,
                0,
              );
              return influencerSum + paymentSum;
            },
            0,
          );

          const totalMedia = month.influencers.reduce(
            (influencerSum: number, influencer: any) => {
              const paymentSum: number = influencer.payments.reduce(
                (acc: number, payment: any) => acc + payment.price,
                0,
              );
              return influencerSum + paymentSum;
            },
            0,
          );

          const isCurrentMonth =
            idx === 0 && moment(month.month).isSame(moment(), "month");

          return {
            amount_of_influencers: month?.influencers?.length || 0,
            month_total: priceTotal,
            total_media: totalMedia,
            isCurrentMonth,
            monthStatus:
              // eslint-disable-next-line no-nested-ternary
              isCurrentMonth || !chargedTotal
                ? undefined
                : priceTotal - chargedTotal === 0
                ? "success"
                : "warning",
          };
        }),
    [payments, monthFilter, influencerFilter],
  );

  return (
    <Box>
      <Flex style={{ alignItems: "center", gap: 8 }} mt={5} mb={8}>
        <Box
          style={{
            borderRadius: 100,
            background: "#E9ECEF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            width: "180px",
          }}
        >
          <Select
            variant="filled"
            onChange={(o) => {
              setMonthFilter(o.target.value);
            }}
            style={{
              borderRadius: 100,
              background: "white",
              boxShadow: "0px 1.33469px 2.33571px 0px rgba(0, 0, 0, 0.17)",
              cursor: "pointer",
              fontWeight: "600",
            }}
          >
            <option value={null}>All</option>
            {payments?.months.map((month, index) => (
              <option key={index} value={month.month}>
                {moment(month.month).format("MMM YYYY")}
              </option>
            ))}
          </Select>
        </Box>
        <Flex
          style={{
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            width: "180px",
            position: "relative",
          }}
        >
          {showInput && (
            <Box
              className="moi-search-bold "
              onClick={handleIconClick}
              style={{
                position: "absolute",
                left: "17px",
                top: "19px",
                zIndex: 1,
                background: "white",
                color: "#495057",
              }}
            ></Box>
          )}
          <Box
            style={{
              borderRadius: 100,
              background: "#E9ECEF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              width: "180px",
            }}
          >
            <Input
              id="influencerInput"
              onChange={(e) => {
                setInfluencerFilter(e?.target?.value);
              }}
              placeholder="Influencer"
              style={{
                paddingLeft: showInput ? "25px" : "12px",
                borderRadius: 100,
                background: "white",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.25)",
              }}
              onFocus={() => setShowInput(false)}
              onBlur={() => setShowInput(true)}
            />
          </Box>
        </Flex>
      </Flex>
      <Accordion
        variant={"payments"}
        defaultIndex={[getDefaultMonthIndex()]}
        allowMultiple
      >
        {payments?.months
          ?.filter(
            (month) =>
              month.month === monthFilter ||
              !monthFilter ||
              monthFilter === "All",
          )
          .filter((month) => {
            return month.influencers?.some((influencer) =>
              influencer.name
                ?.toLowerCase()
                ?.includes((influencerFilter || "")?.toLowerCase()),
            );
          })
          ?.map((month, i) => {
            const monthStr = moment(month.month).format("YYYY-MM");
            const hasDispute = month.influencers.some(
              (influ) => influ?.dispute_details?.creation_time,
            );
            const extraMonthData = monthTotals[i];
            const status = extraMonthData?.monthStatus
              ? {
                  label:
                    extraMonthData?.monthStatus === "success"
                      ? "Issued"
                      : "Partial",
                  type: (extraMonthData?.monthStatus === "success"
                    ? "success"
                    : "warning") as "success" | "warning",
                }
              : undefined;
            return (
              <AccordionItem key={i}>
                <h2 style={{ marginBottom: 0 }}>
                  <AccordionButton
                    name={monthStr}
                    style={{
                      background: extraMonthData?.isCurrentMonth
                        ? "#e9f5fd"
                        : "",
                    }}
                  >
                    <AccordionIcon style={{ color: "#333" }} />
                    {
                      <Flex
                        as="span"
                        flex="1"
                        textAlign="left"
                        gap={"10px"}
                        alignItems={"center"}
                      >
                        <Box>{moment(month.month).format("MMM YYYY")}</Box>
                        {extraMonthData?.isCurrentMonth && (
                          <Box
                            style={{
                              background: "#d3ecfc",
                              padding: "10px",
                              color: "#249ff0",
                              borderRadius: "24px",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            {"Current month"}
                          </Box>
                        )}
                        {hasDispute && (
                          <Badge
                            // bgColor={"#F56565"}
                            // color="white"
                            colorScheme={"red"}
                            variant={"solid"}
                            fontWeight={600}
                            borderRadius="30px"
                            zIndex={9}
                          >
                            <i
                              className="fas fa-exclamation-triangle"
                              style={{ marginRight: "5px" }}
                            />
                            Disputes
                          </Badge>
                        )}
                      </Flex>
                    }

                    {monthTotals && (
                      <Box
                        className="accordion-boxes"
                        style={{ alignItems: "right" }}
                      >
                        <CampaignInfoBox
                          box={{
                            title: "Influencers Num",
                            count: extraMonthData?.amount_of_influencers,
                          }}
                        />
                        <CampaignInfoBox
                          box={{
                            title: "Influencers Fees",
                            count: getPrice(extraMonthData?.total_media),
                          }}
                        />
                        <CampaignInfoBox
                          box={{
                            title: `${
                              extraMonthData?.isCurrentMonth ? "Est." : ""
                            } Platform Bill`,
                            count: getPrice(extraMonthData?.month_total),
                            status,
                          }}
                        />
                      </Box>
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <PaymentsTable
                    month={{
                      ...month,
                      influencers: month.influencers.filter((influencer) => {
                        return (
                          influencer?.name
                            ?.toLowerCase()
                            ?.includes(
                              (influencerFilter || "")?.toLowerCase(),
                            ) || !influencerFilter
                        );
                      }),
                    }}
                    isCurrentMonth={extraMonthData?.isCurrentMonth}
                    getPrice={getPrice}
                    monthChargeStatus={extraMonthData?.monthStatus}
                  />
                </AccordionPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    </Box>
  );
};

export default PaymentsAccordion;
