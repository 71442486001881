import { Box, Text } from "@chakra-ui/react";
import { Tooltip } from "@material-ui/core";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Tabs, { TabPane } from "rc-tabs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InkTabBar from "rc-tabs/lib/InkTabBar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TabContent from "rc-tabs/lib/TabContent";
import { Component, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toggle from "react-toggle";
import { useUpdateSubUserPermission } from "src/api/actions/auctionActions";
import {
  createEnterpriseMiniUser,
  deleteEnterpriseMiniUser,
  editEnterpriseMiniUser,
  getMiniUsers,
} from "../../../actions/enterpriseActions";
import NoImage from "../../../images/svgs/placeholder.svg";
import { MessageDialog } from "../dialogs/MessageDialog";
import { InfoToolTip } from "../general_components/InfoToolTip";
import { EnterpriseActivityLog } from "./EnterpriseActivityLog";

interface OwnProps {
  user: any;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{
  currentTab: string;
  mini_users: any[];
  loading?: boolean;
  sendingForm?: boolean;
  userToRemove?: any;
  userToEdit?: any;
  newUserInvite?: {
    name: string;
    email: string;
  } | null;
  fullAccess: boolean;
}>;

const SubUsersAccessToggle = () => {
  const fullAccess = useSelector((state) => state).userReducer?.userDetails
    ?.sub_user_permission;
  const updateSubUserPermission = useUpdateSubUserPermission();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleChange = async () => {
    setLoading(true);
    dispatch({
      type: "ACTIVE_GENERAL_LOADER",
      payload: {},
    });
    try {
      await updateSubUserPermission.mutateAsync();
      toast.success("Sub user permission updated successfully");
    } catch (e) {
      toast.error("Failed to update sub user permission");
    }
    setLoading(false);
    dispatch({
      type: "DEACTIVE_GENERAL_LOADER",
      payload: {},
    });
  };

  return (
    <Box
      style={{
        borderRadius: 24,
        background: "white",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 16,
        gap: 15,
        padding: "5px",
      }}
      className={loading ? "disabled" : ""}
    >
      <InfoToolTip
        containerStyle={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
          gap: 5,
        }}
        iconStyle={{
          color: "var(--main-baby-blue-color)",
          marginLeft: 5,
        }}
        overlay={
          "The sub users will be able to view and manage all the lists and campaigns in this account."
        }
      >
        <Text>Allow full access</Text>
      </InfoToolTip>
      <div className="toggle-input" style={{ height: 25 }}>
        <label>
          <div className="toggle-label">
            <div className="fa fa-times" />
            <div className="fa fa-check" />
          </div>
          <Toggle checked={fullAccess} icons={false} onChange={handleChange} />
        </label>
      </div>
    </Box>
  );
};

class SubUsersInner extends Component<Props, State> {
  readonly state: State = {
    mini_users: [],
    currentTab: "users",
    fullAccess: false,
  };

  init = () => {
    this.setState({ loading: true });
    this.props.getMiniUsers((users: any) => {
      this.setState({
        mini_users: users,
        fullAccess: false,
        loading: false,
        userToRemove: null,
        userToEdit: null,
      });
    });
  };

  componentDidMount(): void {
    this.init();
  }

  // eslint-disable-next-line class-methods-use-this
  selectUser = (user: any) => {
    console.log(user);
  };

  // eslint-disable-next-line class-methods-use-this
  renderInviteStatus = (
    status: "accepted" | "rejected" | "removed" | "waiting_response",
  ) => {
    switch (status) {
      case "waiting_response":
        return "Invitation Sent ";
      case "accepted":
        return "Accepted";
      case "rejected":
        return "Invitation Rejected";
      case "removed":
        return "Removed";
      default:
        return "";
    }
  };

  renderRow = (user: any) => {
    const created = new Date(user.invite_created_at);
    return (
      <div
        className={`invoice-row ${
          this.state.mini_users && this.state.mini_users.includes(user.user_id)
        }`}
        key={`sub-users-list${user.user_id}`}
        onClick={() => {
          this.selectUser(user);
        }}
      >
        <div
          style={{
            width: 40,
            height: 40,
            marginLeft: 10,
            marginRight: 10,
            marginTop: 10,
          }}
        >
          <img
            className="round-image blurable"
            src={user.image ? user.image : NoImage}
            style={{ width: 34 }}
          />
        </div>
        <div
          className={"invoice-row-details sub-users-row"}
          style={{ width: "100%" }}
        >
          <div style={{ width: 150 }}>
            <strong
              className="invite-user-name blurable-large"
              title={user.name}
            >
              {user.name}
            </strong>
          </div>
          <div style={{ width: 100 }}>
            {`${created.getDate()}-${
              created.getMonth() + 1
            }-${created.getFullYear()}`}
          </div>
          <div style={{ width: 200 }}>{user.email}</div>
          <div style={{ width: 200 }}>
            {this.renderInviteStatus(user.invite_status)}
          </div>
          <div
            className={"list-influencer-actions"}
            style={{ display: "flex", placeContent: "center", fontSize: 16 }}
          >
            <Tooltip placement={"top"} title={"Remove user"}>
              <div
                className="clickable"
                style={{ marginRight: 10 }}
                onClick={() => {
                  this.setState({ userToRemove: { email: user.email } });
                }}
              >
                <i className="far fa-trash" />
              </div>
            </Tooltip>
            <Tooltip placement={"top"} title={"Edit user"}>
              <div className="clickable">
                <i
                  className="far fa-pencil"
                  onClick={() => {
                    this.setState({ userToEdit: user });
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        {/* <div className="checkbox" style={{padding: 0, paddingTop: 0, marginLeft: -10, marginTop:17}}> */}
        {/*  <label> */}
        {/*    <input type="checkbox" */}
        {/*           checked={this.state.mini_users&&this.state.mini_users.includes(user.user_id)} */}
        {/*    /><span className="checkbox-material"><span */}
        {/*      className="check"></span></span> */}
        {/*  </label> */}
        {/* </div> */}
      </div>
    );
  };

  inviteNewUser = () => {
    this.setState({ sendingForm: true });
    this.props.createMiniUser(this.state.newUserInvite, () => {
      this.setState({ newUserInvite: null, sendingForm: false });
      this.init();
    });
  };

  removeUser = () => {
    this.setState({ sendingForm: true });
    this.props.deleteMiniUser(this.state.userToRemove, () => {
      this.setState({ userToRemove: null, sendingForm: false });
      this.init();
    });
  };

  editUser = () => {
    this.setState({ sendingForm: true });
    this.props.editEnterpriseMiniUser(this.state.userToEdit, () => {
      this.setState({ userToEdit: null, sendingForm: false });
      this.init();
    });
  };

  renderUsers = () => {
    return (
      <>
        <div className="payments-overview-section">
          <div className="campaigns-title">
            <span>Manage sub-users</span>
            <SubUsersAccessToggle />

            <button
              className="btn btn-primary pull-right"
              onClick={() =>
                this.setState({ newUserInvite: { email: "", name: "" } })
              }
            >
              <i className="fa fa-plus" /> Invite new user
            </button>
          </div>
        </div>
        <div className="invoice-top-bar">
          <div style={{ width: 90 }}></div>
          <div>Name</div>
          <div>Invite date</div>
          <div>Email</div>
          <div>Invite Status</div>
          <div style={{ paddingLeft: 70 }}>Actions</div>
        </div>
        <div className="sub-users">
          {this.state.mini_users.length
            ? this.state.mini_users.map((u: any) => this.renderRow(u))
            : null}
        </div>
      </>
    );
  };

  renderDialogs = () => {
    return (
      <>
        {this.state.newUserInvite && this.state.newUserInvite !== null && (
          <MessageDialog
            handleClose={() => {
              this.setState({ newUserInvite: null });
            }}
            icon="far fa-envelope"
          >
            <div style={{ textAlign: "center", padding: 20 }}>
              <strong>New user invite</strong>
              {
                <div className={"input-block"} style={{ display: "flex" }}>
                  <label htmlFor="name">Name</label>
                  <input
                    name="name"
                    value={this.state.newUserInvite.name}
                    type="text"
                    placeholder={"Name"}
                    className={"input-line"}
                    onChange={(e) => {
                      const newState = { ...this.state };
                      if (newState.newUserInvite) {
                        newState.newUserInvite.name = e.target.value;
                        this.setState(newState);
                      }
                    }}
                  />
                </div>
              }
              <div className={"input-block"} style={{ display: "flex" }}>
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  value={this.state.newUserInvite.email}
                  type="text"
                  placeholder={"Email"}
                  className={"input-line"}
                  onChange={(e) => {
                    const newState = { ...this.state };
                    if (newState.newUserInvite) {
                      newState.newUserInvite.email = e.target.value;
                      this.setState(newState);
                    }
                  }}
                />
              </div>
              <button
                onClick={() => this.inviteNewUser()}
                className="btn btn-primry"
                style={{
                  background: "var(--main-baby-blue-color)",
                  color: "white",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
                disabled={this.state.sendingForm}
              >
                Invite
              </button>
              <button
                onClick={() => this.setState({ newUserInvite: null })}
                className="btn btn-cancel"
                style={{
                  background: "white",
                  color: "var(--main-baby-blue-color)",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
              >
                Cancel
              </button>
            </div>
          </MessageDialog>
        )}

        {this.state.userToEdit && this.state.userToEdit !== null && (
          <MessageDialog
            handleClose={() => {
              this.setState({ userToEdit: null });
            }}
            icon="far fa-user"
          >
            <div style={{ textAlign: "center", padding: 20 }}>
              <strong>Edit user</strong>
              {
                <div className={"input-block"} style={{ display: "flex" }}>
                  <label htmlFor="name">Name</label>
                  <input
                    name="name"
                    value={this.state.userToEdit.name}
                    type="text"
                    placeholder={"Name"}
                    className={"input-line"}
                    onChange={(e) => {
                      const newState = { ...this.state };
                      newState.userToEdit.name = e.target.value;
                      this.setState(newState);
                    }}
                  />
                </div>
              }
              <div className={"input-block"} style={{ display: "flex" }}>
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  disabled
                  value={this.state.userToEdit.email}
                  type="text"
                  placeholder={"Email"}
                  className={"input-line"}
                />
              </div>
              <button
                onClick={() => this.editUser()}
                className="btn btn-primry"
                style={{
                  background: "var(--main-baby-blue-color)",
                  color: "white",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
                disabled={this.state.sendingForm}
              >
                save
              </button>
              <button
                onClick={() => this.setState({ userToEdit: null })}
                className="btn btn-cancel"
                style={{
                  background: "white",
                  color: "var(--main-baby-blue-color)",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
              >
                Cancel
              </button>
            </div>
          </MessageDialog>
        )}

        {this.state.userToRemove && (
          <MessageDialog
            handleClose={() => {
              this.setState({ userToRemove: null });
            }}
            icon="moi-trash"
          >
            <div style={{ textAlign: "center", padding: 20 }}>
              <strong>Delete user</strong>
              <p style={{ marginTop: 15 }}>
                Are you sure you want to remove this user?
              </p>
              <button
                onClick={() => this.removeUser()}
                className="btn btn-primry"
                style={{
                  background: "var(--main-baby-blue-color)",
                  color: "white",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
                disabled={this.state.sendingForm}
              >
                Delete
              </button>
              <button
                onClick={() => this.setState({ userToRemove: null })}
                className="btn btn-cancel"
                style={{
                  background: "white",
                  color: "var(--main-baby-blue-color)",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
              >
                Cancel
              </button>
            </div>
          </MessageDialog>
        )}
      </>
    );
  };

  render() {
    return (
      <div style={{ marginTop: 60 }}>
        {this.state.loading === true ? (
          <div
            className="spinner small"
            style={{ marginTop: 40, marginBottom: 0 }}
          >
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        ) : (
          <div className="create-tab-content" style={{ paddingTop: 0 }}>
            <div className="payments-method-section">
              <div
                className="create-tab-content payments"
                style={{ marginTop: -40, paddingBottom: 0 }}
              >
                <Tabs
                  defaultActiveKey="users"
                  renderTabBar={() => <InkTabBar />}
                  renderTabContent={() => (
                    <TabContent style={{ paddingTop: 0 }} />
                  )}
                  activeKey={this.state.currentTab}
                  onChange={(e: any) => this.setState({ currentTab: e })}
                  className="text-left campaign-top-bar list-top-bar"
                >
                  <TabPane tab={<span>Users</span>} key="users">
                    {this.renderUsers()}
                  </TabPane>
                  <TabPane tab={<span>Activity</span>} key="activity">
                    <EnterpriseActivityLog />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        )}

        {this.renderDialogs()}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => ({
  getMiniUsers: (callback: any) => dispatch(getMiniUsers(callback)),
  deleteMiniUser: (data: any, callback: any) =>
    dispatch(deleteEnterpriseMiniUser(data, callback)),
  createMiniUser: (data: any, callback: any) =>
    dispatch(createEnterpriseMiniUser(data, callback)),
  editEnterpriseMiniUser: (data: any, callback: any) =>
    dispatch(editEnterpriseMiniUser(data, callback)),
});

export const SubUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubUsersInner);
