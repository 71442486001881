import { Box, FormControlLabel, Switch } from "@material-ui/core";

interface ICampaignSwitch {
  switchValue: boolean;
  handleSwitchChange: () => void;
  isLocked?: boolean;
}

const CampaignSwitch = ({
  switchValue,
  handleSwitchChange,
  isLocked,
}: ICampaignSwitch) => {
  return (
    <FormControlLabel
      label=""
      control={
        <Switch
          icon={
            <Box className="campaign-switch__cross-icon">
              <Box
                component="i"
                className="fa-sharp fa-solid fa-xmark fa-2xs"
                sx={{ color: "#fff" }}
              />
            </Box>
          }
          onChange={handleSwitchChange}
          disabled={isLocked}
          checked={switchValue}
          checkedIcon={
            <Box className="campaign-switch__cross-icon campaign-switch__cross-icon_check-color">
              <Box
                className="fa-sharp fa-solid fa-check fa-2xs"
                sx={{ color: "#fff" }}
              />
            </Box>
          }
          className="campaign-switch"
        />
      }
    />
  );
};

export default CampaignSwitch;
