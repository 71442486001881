import { Box } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AuctionPaymentsData } from "src/api/types";
// eslint-disable-next-line import-access/jsdoc
import { getMoiAuctionManageAuction_idPayments } from "../../../../api/services";
import { InfiniteLoader } from "../../general_components/InfiniteLoader";
import NoDataMessage from "../../general_components/NoDataMessage";
import CampaignDisputeDialog from "../CampaignManager/CampaignDisputeDialog";
import { CampaignPaymentsContext } from "./CampaignPaymentsContext";
import CampaignPaymentsHeader from "./CampaignPaymentsHeader";
import PaymentsAccordion from "./Cycles/PaymentsAccordion";

type Props = {
  id: any;
  closeChatDialog: any;
  showCost: any;
  openInfluencerSegmentations: any;
  closeInfluencerSegmentations: any;
  disputeInfluencer: any;
  setDisputeInfluencer: any;
};

const CampaignPayments = (props: Props) => {
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState<AuctionPaymentsData>(null);
  const [params] = useSearchParams();

  const getPaymentData = () => {
    setLoading(true);
    const month = params.get("month");
    getMoiAuctionManageAuction_idPayments(props.id)
      .then((res) => {
        setPayments(res?.data);
        if (params.get("i")) {
          window.setTimeout(() => {
            const element = document.getElementsByName(
              month
                ? `${params.get("i")}_${moment(month).utc().format("YYYY-MM")}`
                : params.get("i"),
            )?.[0];
            if (element) {
              element.click();
              element.scrollIntoView(true);
              element.parentElement.parentElement.classList.add("glower");
              window.setTimeout(() => {
                element.parentElement.parentElement.classList.remove("glower");
              }, 4000);
            }
          }, 200);
        }
      })
      .catch(() => {
        toast.error("Error fetching payments data");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getPaymentData();

    return () => {
      if (params.get("i")) {
        params.delete("i");
      }
    };
  }, []);

  return (
    <div className="humanz-page">
      <div className="humanz-container">
        <CampaignPaymentsContext.Provider
          value={{
            search,
            setSearch,
            payments,
            boardId: props.id,
            getPaymentData,
            disputeInfluencer: props.disputeInfluencer,
            setDisputeInfluencer: props.setDisputeInfluencer,
          }}
        >
          {loading ? (
            <Box style={{ marginTop: "100px" }}>
              <InfiniteLoader />
            </Box>
          ) : (
            <>
              <CampaignPaymentsHeader />
              <Box>
                {payments?.months ? (
                  <PaymentsAccordion />
                ) : (
                  <NoDataMessage mt={3}>No payments found</NoDataMessage>
                )}
              </Box>
            </>
          )}
          {
            <CampaignDisputeDialog
              disputeInfluencer={props.disputeInfluencer}
              callback={(res: any) => {
                props.setDisputeInfluencer(null);
                if (res?.data?.dispute_id) {
                  getPaymentData();
                }
              }}
              onClose={() => props.setDisputeInfluencer(null)}
            />
          }
        </CampaignPaymentsContext.Provider>
      </div>
    </div>
  );
};

export default CampaignPayments;
