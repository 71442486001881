import "./search_loader.css";

const itemHeight = 290;
const numOfItems = 4;

export const SearchLoader = () => {
  const height = window.innerHeight - 300;
  // eslint-disable-next-line radix
  const rows = Math.floor(height / itemHeight) || 1;
  const items = Array.from({ length: numOfItems * rows });
  return (
    <div className="search-loader-container">
      {items.map((_, index) => (
        <div key={index} className="animation-card">
          <div className="card-wrapper">
            <div className="card-header"></div>
            <div className="card-loader">
              <div className="card-image"></div>
              <div className="card-text"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
