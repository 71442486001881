// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Tabs, { TabPane } from "rc-tabs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InkTabBar from "rc-tabs/lib/InkTabBar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TabContent from "rc-tabs/lib/TabContent";
import React, { useEffect, useState } from "react";
import { ExternalInvitationPage } from "../ExternalInvitation/ExternalInvitationPage";
import { ExternalInfluencersPage } from "./ExternalInfluencersPage";
import { ListsPage } from "./ListsPage";

interface InfluencersPageProps {
  listsLoaded: any;
  tab: string;
  user: any;
  openAddToList: any;
}

const InfluencersPage: React.FC<InfluencersPageProps> = (props) => {
  // Define the component's logic here
  const listsLoaded: any = { ...props };
  const [selectedTab, setSelectedTab]: any = useState();
  const onChange = (tab: string) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (props.tab) {
      setSelectedTab(props.tab);
    }
  }, [props.tab]);

  return (
    // Return the JSX to render the component's UI
    <div style={{ background: "#fafafa", height: "100%", paddingBottom: 30 }}>
      {/* Top bar */}
      <div className="section-top-bar"></div>
      <div>
        <Tabs
          renderTabBar={() => <InkTabBar animated={false} />}
          renderTabContent={() => <TabContent animated={false} />}
          activeKey={selectedTab}
          onChange={onChange}
          className="campaign-top-bar"
        >
          <TabPane tab={<span>Influencers Lists</span>} key="influencers_lists">
            <ListsPage listsLoaded={listsLoaded} />
          </TabPane>
          {props?.user?.userDetails?.sidebar_data
            ?.allowed_shadow_influencers && (
            <TabPane
              tab={<span>External Influencers</span>}
              key="external_influencers"
            >
              <ExternalInfluencersPage openAddToList={props.openAddToList} />
            </TabPane>
          )}
          <TabPane
            tab={<span>Social Connect Invites</span>}
            key="social_connect_invites"
          >
            <ExternalInvitationPage />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default InfluencersPage;
