/* eslint-disable no-nested-ternary */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Link,
  Tag,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Clipboard from "react-clipboard.js";
import { StagesAccordion } from "../StagesAccordion";

interface Props {
  isLoading: boolean;
  nextStep: any;
  platform: any;
  appId: number;
}

export const GTM = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const accordElem = useRef(null);
  const { appId } = props;
  const toast = useToast();
  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    if (accordElem?.current?.children[currentStep]) {
      accordElem.current.children[currentStep].scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [setCurrentStep, currentStep]);

  const initTag = `<script>
    function lHzpAsy(u, c) {
        var d = document, t = 'script',
            o = d.createElement(t),
            s = d.getElementsByTagName(t)[0];
        o.src = u;
        if (c) { o.addEventListener('load', function (e) { c(null, e); }, false); }
        s.parentNode.insertBefore(o, s);
    }
    lHzpAsy("https://assets.humanz.com/${appId}/humanz-gtm.js", function () {
        try {
            var hmz = new window.hzi("${appId}");
        } catch (e) { }
    });
</script>
`;

  const purchaseTag = `<script>
  function lHzpAsy(u, c) {
      var d = document, t = 'script',
          o = d.createElement(t),
          s = d.getElementsByTagName(t)[0];
      o.src = u;
      if (c) { o.addEventListener('load', function (e) { c(null, e); }, false); }
      s.parentNode.insertBefore(o, s);
  }
  lHzpAsy("https://assets.humanz.com/${appId}/humanz-gtm.js", function () {
      try {
          var hmz = new window.hzi("${appId}");
          var h = window.hzp;
          var products = {{dlv-products}} || [];
          var coupon = {{dlv-coupon}} || "";
          var orderId = {{dlv-order-id}} || ""
          var revenue = {{dlv-revenue}}
          if (h) {
              h.gtmPurchaseRevenue(products,coupon,orderId,revenue);
          }
      } catch (e) { }
  });
</script>
`;
  const steps: any = [
    {
      id: 0,
      title: "Open Google Tag Manager",
      body: (
        <Flex>
          <div className="preview-description">
            <Box>
              <p>
                In this manual we are going to create 2 Tags - Init and
                Purchase. <br />
                We are also going to create 3 variables for the purchased items,
                order ID and a coupon.
                <br />
                <br />
                You will need to have your GTM already configured with a
                purchase trigger. <br />
                <br />
                <Alert status="info">
                  <AlertIcon />
                  <span>
                    If you need any help with that you can take a look at{" - "}
                  </span>
                  <Link
                    href="https://support.google.com/tagmanager/answer/6103696?hl=en"
                    isExternal
                  >
                    &nbsp;Google official reference &nbsp;
                    <i className="far fa-external-link" />
                  </Link>
                </Alert>
                <br />
                <br />
                <Center>
                  <Link href="https://tagmanager.google.com/#/home" isExternal>
                    <Button m={5} size="lg" colorScheme={"green"}>
                      Open GTM &nbsp;
                      <i className="far fa-external-link" />
                    </Button>
                  </Link>
                </Center>
              </p>
            </Box>
          </div>
        </Flex>
      ),
    },
    {
      id: 1,
      title: "Create variables",
      body: (
        <>
          <Flex>
            <div className="preview-description">
              <Box>
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/lhNCJMDXuww"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
              <Divider mb={2} />
              <Box>
                <p>
                  You will need to create 4 variables:
                  <br />
                </p>
                <Divider mb={2} />
                <Box>
                  <p>
                    <strong>dlv-products</strong>
                  </p>
                  <p>
                    <span>
                      An array of products with{" "}
                      <Link
                        href="https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#purchases"
                        isExternal
                      >
                        this structure&nbsp;
                        <i className="far fa-external-link" />
                      </Link>
                      .
                    </span>
                    <p>
                      <span>
                        Usually can be taken from{" "}
                        <Tag>ecommerce.purchase.products</Tag>
                      </span>
                    </p>
                  </p>
                </Box>
                <Divider mb={2} />
                <Box>
                  <p>
                    <strong>dlv-coupon</strong>
                  </p>
                  <p>
                    <span>A string of the coupon applied to the purchase.</span>
                    <p>
                      <span>
                        Usually can be taken from{" "}
                        <Tag>ecommerce.purchase.actionField.coupon</Tag>
                      </span>
                    </p>
                  </p>
                </Box>
                <Divider mb={2} />
                <Box>
                  <p>
                    <strong>dlv-order-id</strong>
                  </p>
                  <p>
                    <span>
                      The order ID can be your internal identifier string or a
                      random string as long as it&apos;s unique, used to trace
                      back orders.
                    </span>
                    <p>
                      <span>
                        Usually can be taken from{" "}
                        <Tag>ecommerce.purchase.actionField.id</Tag>
                      </span>
                    </p>
                  </p>
                </Box>
                <Divider mb={2} />
                <Box>
                  <p>
                    <strong>dlv-revenue</strong>
                  </p>
                  <p>
                    <span>The revenue is the cart total after discounts.</span>
                    <p>
                      <span>
                        Usually can be taken from{" "}
                        <Tag>ecommerce.purchase.actionField.revenue</Tag>
                      </span>
                    </p>
                  </p>
                </Box>
                <Divider mb={2} />
                <Alert status="info">
                  <AlertIcon />
                  <div>
                    <strong>
                      <Box as="span" flex="1" textAlign="left">
                        Where I can take the variables from?
                      </Box>
                    </strong>
                    <br />
                    Those variables are extracted from your GTM Data Layer.{" "}
                    <br />
                    You can preview your data layer events using the preview
                    button on GTM -{" "}
                    <Link
                      href="https://support.google.com/tagmanager/answer/6107056?hl=en"
                      isExternal
                    >
                      See how <i className="far fa-external-link" />
                    </Link>
                    <br />
                    You will need to make a purchase while in preview mode, then
                    click on the purchase event -&gt; Data layer tab and the
                    object will be shown.
                  </div>
                </Alert>
              </Box>
            </div>
          </Flex>
        </>
      ),
    },
    {
      id: 2,
      title: "Create Page Init tag",
      body: (
        <>
          <Flex>
            <div className="preview-description">
              <Box>
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/neiuBC2Ksqo"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
              <Divider mb={2} />
              <p>
                In order to detect the potential client, we need to have this
                tag loaded on all pages.
                <br />
              </p>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>
                    On the left menu, Go to Tags and create a new tag
                  </strong>
                </p>
                <p>
                  Click on &quot;Tag Configuration&quot; - The new tag should be
                  of type &quot;Custom HTML&quot;. You can call it &quot;Humanz
                  Init&quot; or a name of your choice.
                </p>
              </Box>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>Paste the following code and save:</strong>
                </p>
                <p>
                  <pre style={{ position: "relative" }}>
                    {" "}
                    <Clipboard
                      style={{
                        background: "transparent",
                        border: "none",
                        right: 0,
                        top: 5,
                        position: "absolute",
                      }}
                      data-clipboard-text={initTag}
                      onClick={() =>
                        toast({
                          title: "Code copied to your clipboard",
                          status: "success",
                          duration: 1000,
                          isClosable: false,
                        })
                      }
                    >
                      <Tooltip hasArrow label="Copy to clipboard">
                        <i className="far fa-copy copy-btn" />
                      </Tooltip>
                    </Clipboard>
                    {initTag}
                  </pre>
                </p>
              </Box>
            </div>
          </Flex>
        </>
      ),
    },
    {
      id: 3,
      title: "Create Purchase tag",
      body: (
        <>
          <Flex>
            <div className="preview-description">
              <Box>
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/ohnddDj0YG0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
              <Divider mb={2} />
              <p>
                This tag should be triggered with a purchase event on your
                website along with your cart items.
                <br />
              </p>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>
                    On the left menu, Go to Tags and create a new tag
                  </strong>
                </p>
                <p>
                  Click on &quot;Tag Configuration&quot; - The new tag should be
                  of type &quot;Custom HTML&quot;. You can call it &quot;Humanz
                  Purchase&quot; or a name of your choice.
                </p>
              </Box>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>Paste the following code and save:</strong>
                </p>
                <p>
                  <pre style={{ position: "relative" }}>
                    {" "}
                    <Clipboard
                      style={{
                        background: "transparent",
                        border: "none",
                        right: 0,
                        top: 5,
                        position: "absolute",
                      }}
                      data-clipboard-text={purchaseTag}
                      onClick={() =>
                        toast({
                          title: "Code copied to your clipboard",
                          status: "success",
                          duration: 1000,
                          isClosable: false,
                        })
                      }
                    >
                      <Tooltip hasArrow label="Copy to clipboard">
                        <i className="far fa-copy copy-btn" />
                      </Tooltip>
                    </Clipboard>
                    {purchaseTag}
                  </pre>
                </p>
              </Box>
            </div>
          </Flex>
        </>
      ),
    },
    {
      id: 4,
      title: "Implement the event on your side",
      body: (
        <>
          <Flex>
            <div className="preview-description">
              <Box>
                <p>
                  <strong>
                    If you have a purchase trigger and an event implemented, you
                    can skip this step.
                  </strong>
                </p>
                <p>
                  Implement the event on your side, make sure to call the same
                  event name as your purchase trigger.
                </p>
              </Box>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>Example for sending event through :</strong>
                </p>
                <p>
                  <pre>
                    {`dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
dataLayer.push({
  'event_name':'purchase', // The name of your custom purchase event
  'ecommerce': {
    'purchase': {
      'actionField': { // optional if you don’t have coupon or transaction ID
        'id': 'T12345', // Transaction ID/Order ID
        'affiliation': 'Online Store',
        'revenue': '35.43', // Total transaction value
        'tax':'4.90',
        'shipping': '5.99',
        'coupon': 'SUMMER_SALE'
      },
      'products': [{ // List of productFieldObjects.
        'name': 'Triblend Android T-Shirt', // Name or ID is required.
        'id': '12345',
        'price': '15.25',
        'brand': 'Google',
        'category': 'Apparel',
        'variant': 'Gray',
        'quantity': 1,
        'coupon': '' // Optional fields may be omitted or set to empty string.
       },
       {
        'name': 'Donut Friday Scented T-Shirt',
        'id': '67890',
        'price': '33.75',
        'brand': 'Google',
        'category': 'Apparel',
        'variant': 'Black',
        'quantity': 1
       }]
    }
  }
});
`}
                  </pre>
                </p>
              </Box>
            </div>
          </Flex>
        </>
      ),
    },
  ];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <StagesAccordion
      currentStep={currentStep}
      isLoading={props.isLoading}
      nextStep={nextStep}
      prevStep={prevStep}
      steps={steps}
      setStep={(step: any) => () => setCurrentStep(step.id)}
      completeStage={props.nextStep}
      description={
        <>
          This part will walk you through installing Humanz pixel tag in GTM.{" "}
          <br /> Please follow the instructions below
        </>
      }
      eventValidation={true}
    />
  );
};
