import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  addYears,
  startOfYear,
  endOfYear,
} from "date-fns";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfThreeMonthsAgo: addMonths(startOfMonth(new Date()), -3),
  endOfThreeMonthsAgo: endOfDay(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastQuarter: startOfQuarter(addQuarters(new Date(), -1)),
  endOfLastQuarter: endOfQuarter(addQuarters(new Date(), -1)),
  startofLastSixMonths: startOfMonth(addMonths(new Date(), -6)),
  endOfLastSixMonths: endOfMonth(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export const createStaticRanges = (ranges) => {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
};

export const defaultStaticRanges = createStaticRanges([
  // {
  //   label: 'Today',
  //   range: () => ({
  //     startDate: defineds.startOfToday,
  //     endDate: defineds.endOfToday,
  //   }),
  // },
  // {
  //   label: 'Yesterday',
  //   range: () => ({
  //     startDate: defineds.startOfYesterday,
  //     endDate: defineds.endOfYesterday,
  //   }),
  // },
  {
    label: "This Week",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "3 Months Ago",
    range: () => ({
      startDate: defineds.startOfThreeMonthsAgo,
      endDate: defineds.endOfThreeMonthsAgo,
    }),
  },
  {
    label: "Last Quarter",
    range: () => ({
      startDate: defineds.startOfLastQuarter,
      endDate: defineds.endOfLastQuarter,
    }),
  },
  {
    label: "Last 6 Months",
    range: () => ({
      startDate: defineds.startofLastSixMonths,
      endDate: defineds.endOfLastSixMonths,
    }),
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
]);
