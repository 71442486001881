import React from "react";
import { connect } from "react-redux";
import Infinite from "react-infinite";
import { toast } from "react-toastify";
import NoImage from "../../../images/svgs/placeholder.svg";
import { formatBigNumbers } from "../utilities/general";
import { getShadowInfluencers } from "../../../api/actions/listActions";

import {
  getListById,
  addUsersToList,
  getListFrequencies,
} from "../../../actions/premiumActions";

// Css's import

class ImportFromListMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openList: -1,
      loadingList: false,
      listData: {},
      selected: [],
      search: "",
      searchList: "",
      filterCampaigns: false,
      currentTab: "0",
      importTagsAndComments: null,
      importContents: null,
      listsContainerClientHeight: undefined,
      externalInfluencers: null,
    };
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    const { listsContainerRef } = this;
    if (listsContainerRef) {
      const { clientHeight } = listsContainerRef;
      this.setState({ listsContainerClientHeight: clientHeight });
    } else {
      this.setState({ listsContainerClientHeight: undefined });
    }
  };

  onListSelect(id) {
    this.setState({ openList: id, loadingList: true, selected: [] });
    this.props.getListById(id, (data) => this.callback(data), true);
  }

  callback(data) {
    this.setState(
      { listData: data, loadingList: false },
      this.handleWindowResize,
    );
  }

  onCheck(id) {
    const { selected } = this.state;
    if (selected.includes(id)) selected.splice(selected.indexOf(id), 1);
    else selected.push(id);

    this.setState({ selected });
  }

  onCheckAll(influencers) {
    let { selected } = this.state;
    if (selected.length === influencers.length) {
      selected = [];
    } else {
      selected = influencers.map((x) => x.id);
    }

    this.setState({ selected });
  }

  renderList(list) {
    return (
      <div className="import-list-row" key={`import-list${list.id}`}>
        <div style={{ width: 40, height: 40 }}>
          <img
            className="blurable-large"
            src={list.image_url ? list.image_url : NoImage}
          />
        </div>
        <div
          className={`import-list-row-details ${
            this.state.openList === list.id
          }`}
          onClick={() => this.onListSelect(list.id)}
        >
          <strong
            className="import-list-name blurable-large"
            title={list.title}
          >
            {list.title}
          </strong>
          <strong className="small-title">
            Total {list.influencer_ids.length}
          </strong>
        </div>
      </div>
    );
  }

  importUsers() {
    const ids = this.state.selected.map((x) => {
      return { influencer_id: x };
    });
    this.props.addUsersToList(
      this.props.id,
      ids,
      this.state.importTagsAndComments ? this.state.importFromListId : null,
      this.state.importContents ? this.state.importFromListId : null,
      () => this.addCallback(),
    );
  }

  addCallback() {
    this.setState({ selected: [] });
    const listId = this.props.id;
    if (
      this.props.lists.list &&
      this.props.lists.list.list_summary_data.id === listId
    ) {
      this.props.getListById(
        listId,
        () => {
          if (this.props.addCallback) {
            this.props.addCallback();
          }
        },
        false,
      );
      this.props.getListFrequencies(listId);
    }
  }

  renderInfluencer(data) {
    const { selected, search } = this.state;
    if (!data.name) {
      // eslint-disable-next-line no-param-reassign
      data.name = "Unknown";
    }
    if (!data.name.toLowerCase().includes(search) || data.hidden) {
      return null;
    }
    const instagramData =
      data.instagram_data && data.instagram_data.username
        ? data.instagram_data
        : null;
    const twitterData =
      data.twitter_data && data.twitter_data.username
        ? data.twitter_data
        : null;
    const youtubeData =
      data.youtube_data && data.youtube_data.username
        ? data.youtube_data
        : null;
    const tiktokData =
      data.tiktok_data && data.tiktok_data.username ? data.tiktok_data : null;
    return (
      <div className="import-list-row" key={`import-list-influencer${data.id}`}>
        <div style={{ width: 40, height: 40, paddingTop: 10 }}>
          <img
            className="avatar"
            src={
              data.picture_url
                ? data.picture_url.replace(".jpg", "_small.jpg")
                : NoImage
            }
          />
        </div>
        <div className="import-list-row-details">
          <strong
            className="import-list-influencer-name"
            style={{ width: "114px" }}
            title={data.name}
          >
            {data.name}
          </strong>

          <div
            className="list-socials"
            style={{
              height: 32,
              width: 130,
              marginLeft: 5,
              textAlign: "center",
            }}
          >
            {instagramData && (
              <div
                className="list-social-followers"
                style={{ fontSize: 11, height: 15, width: 60 }}
              >
                <li className="fa fa-instagram" />
                {formatBigNumbers(instagramData.followers)}
              </div>
            )}
            {twitterData && (
              <div
                className="list-social-followers"
                style={{ fontSize: 11, height: 15, width: 60 }}
              >
                <li className="fa fa-twitter" />
                {formatBigNumbers(twitterData.followers)}
              </div>
            )}
            {youtubeData && (
              <div
                className="list-social-followers"
                style={{ fontSize: 11, height: 15, width: 60 }}
              >
                <li className="fa fa-youtube-play" />
                {formatBigNumbers(youtubeData.followers)}
              </div>
            )}
            {tiktokData && (
              <div
                className="list-social-followers"
                style={{ fontSize: 11, height: 15, width: 60 }}
              >
                <li className="fa-brands fa-tiktok" />
                {formatBigNumbers(tiktokData.followers)}
              </div>
            )}
          </div>

          <div
            className="list-socials"
            style={{
              height: 32,
              width: 130,
              marginLeft: 5,
              textAlign: "center",
            }}
          >
            {instagramData && (
              <div
                className="list-social-followers"
                style={{ fontSize: 11, height: 15, width: 60 }}
              >
                <li className="fa fa-instagram" />
                {formatBigNumbers(instagramData.score)}
              </div>
            )}
            {twitterData && (
              <div
                className="list-social-followers"
                style={{ fontSize: 11, height: 15, width: 60 }}
              >
                <li className="fa fa-twitter" />
                {formatBigNumbers(twitterData.score)}
              </div>
            )}
            {youtubeData && (
              <div
                className="list-social-followers"
                style={{ fontSize: 11, height: 15, width: 60 }}
              >
                <li className="fa fa-youtube-play" />
                {formatBigNumbers(youtubeData.score)}
              </div>
            )}
            {tiktokData && (
              <div
                className="list-social-followers"
                style={{ fontSize: 11, height: 15, width: 60 }}
              >
                <li className="fa-brands fa-tiktok" />
                {formatBigNumbers(tiktokData.score)}
              </div>
            )}
          </div>
        </div>
        <div
          className="checkbox"
          style={{ padding: 0, paddingTop: 5, marginLeft: -10 }}
        >
          <label>
            <input
              type="checkbox"
              checked={selected.includes(data.id)}
              onChange={() => this.onCheck(data.id)}
            />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </div>
    );
  }

  selectTab(id) {
    this.setState({ currentTab: id, filterCampaigns: id === "1" }, () => {
      this.handleWindowResize();
      if (this.state.currentTab === "3") {
        this.getExternalInfluencers();
      } else {
        this.setState({ listData: {}, openList: -1, loadingList: false });
      }
    });
  }

  getExternalInfluencers = (callback) => {
    this.setState({ loadingList: true, openList: 0 }, () => {
      getShadowInfluencers()
        .then((res) => {
          this.setState({
            externalInfluencers: res.data || null,
            loadingList: false,
          });
          if (callback) {
            callback(res.data);
          }
          this.callback({
            list_summary_data: { title: "External Influencers" },
            influencers_data: res.data.map((i) => {
              return { ...i, id: `${i?.influencer_id}` };
            }),
          });
        })
        .catch(() => {
          this.setState({ externalInfluencers: null, loadingList: false });
          if (callback) callback([]);
          this.callback([]);
          toast.error("Failed to retrive data");
        });
    });
  };

  render() {
    const { listsLoaded } = this.props;
    let lists = [...this.props.lists.lists];
    if (this.state.currentTab === "2") {
      lists = lists.filter((l) => l.is_favorite);
    }
    if (this.state.filterCampaigns && this.state.currentTab === "1") {
      lists = lists.filter((l) => l.list_type === "campaign");
    }

    const { openList, listData, loadingList } = this.state;
    const influencers = listData.influencers_data
      ? listData.influencers_data
      : [];
    return (
      <div
        className="import-from-list-container animated fadeInRight faster"
        id="import-from-list"
      >
        {listsLoaded ? (
          <div>
            <div style={{ fontSize: 22 }}>Import influencers</div>
            <div
              className="import-list-close-button"
              onClick={() => this.props.closeImportMenu()}
            >
              <span className="moi-ximage" />
            </div>
            <div
              className="import-from-list-spacer"
              style={{ marginTop: 10, marginBottom: 10 }}
            />

            {/* Lists Sections */}
            <div style={{ fontSize: 18, marginBottom: 10 }}>My Lists</div>

            <div
              className="import-list-influencers-row"
              style={{
                paddingRight: 0,
                paddingLeft: 0,
                justifyContent: "space-between",
              }}
            >
              <div className="tabs">
                <div
                  className={`tab ${
                    this.state.currentTab === "0" ? "active" : ""
                  }`}
                  onClick={() => this.selectTab("0")}
                >
                  General Lists
                </div>
                <div
                  className={`tab ${
                    this.state.currentTab === "1" ? "active" : ""
                  }`}
                  onClick={() => this.selectTab("1")}
                >
                  Campaign Lists
                </div>
                <div
                  className={`tab ${
                    this.state.currentTab === "2" ? "active" : ""
                  }`}
                  onClick={() => this.selectTab("2")}
                >
                  Favorites
                </div>

                <div
                  className={`tab ${
                    this.state.currentTab === "3" ? "active" : ""
                  }`}
                  onClick={() => this.selectTab("3")}
                >
                  External
                </div>
              </div>
              <div style={{ alignSelf: "center", marginRight: 30 }}>
                <div className="inner-toolbar-search-input">
                  <div
                    className="moi-search-bold"
                    style={{ fontSize: 12, marginTop: 4 }}
                  />
                  <input
                    onChange={(e) =>
                      this.setState({
                        searchList: e.target.value.toLowerCase(),
                      })
                    }
                    style={{ height: 20, width: 114 }}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div
              ref={(el) => {
                this.listsContainerRef = el;
              }}
              className="import-list-section"
              style={{
                marginTop: 10,
                height:
                  // eslint-disable-next-line no-nested-ternary
                  openList > -1
                    ? this.state.currentTab === "3"
                      ? 0
                      : " calc(50vh - 310px)"
                    : "calc(100vh - 280px)",
              }}
            >
              {this.state.listsContainerClientHeight ? (
                <Infinite
                  elementHeight={50}
                  timeScrollStateLastsForAfterUserScrolls={0}
                  containerHeight={this.state.listsContainerClientHeight}
                  className="campaign-influencers-table-container"
                  useWindowAsScrollContainer={false}
                >
                  {lists
                    .filter((l) => {
                      if (
                        !l.title
                          .toLowerCase()
                          .includes(this.state.searchList) ||
                        this.props.id === l.id
                      ) {
                        return false;
                      }

                      if (this.state.filterCampaigns) {
                        if (l.list_type !== "campaign") return false;
                      } else if (l.list_type === "campaign") {
                        return false;
                      }

                      return true;
                    })
                    .map((x) => this.renderList(x))}
                </Infinite>
              ) : null}
            </div>

            {/* End Lists Sections */}
            <div
              className="import-from-list-spacer"
              style={{ marginBottom: 10 }}
            />
            {
              /* Influencers Sections */
              openList > -1 &&
                (loadingList ? (
                  <div className="spinner" style={{ marginTop: 40 }}>
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                  </div>
                ) : (
                  influencers && (
                    <div>
                      <div
                        className="blurable-large"
                        style={{ fontSize: 18, marginBottom: 5 }}
                      >
                        {listData?.list_summary_data?.title}
                      </div>
                      <div className="import-list-influencers-row">
                        <div className="inner-toolbar-search-input">
                          <div
                            className="moi-search-bold"
                            style={{ fontSize: 12, marginTop: 4 }}
                          />
                          <input
                            onChange={(e) =>
                              this.setState({
                                search: e.target.value.toLowerCase(),
                              })
                            }
                            style={{ height: 20, width: 114 }}
                            className="form-control"
                          />
                        </div>
                        <strong className="small-title">Followers</strong>
                        <strong className="small-title">score</strong>
                        <div
                          className="checkbox"
                          style={{
                            padding: 0,
                            paddingTop: 5,
                            marginLeft: 10,
                            height: 0,
                            marginTop: -10,
                          }}
                        >
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                this.state?.selected?.length ===
                                influencers?.length
                              }
                              onChange={() => this.onCheckAll(influencers)}
                            />
                            <span className="checkbox-material">
                              <span className="check"></span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="import-list-section no-infinite"
                        style={{ height: "calc(50vh - 150px)" }}
                      >
                        {influencers?.map((x) => this.renderInfluencer(x))}
                      </div>

                      <div className="import-from-list-buttons-row">
                        <div style={{ marginBottom: 20, marginTop: 5 }}>
                          <div
                            className="checkbox"
                            style={{
                              padding: 0,
                              paddingTop: 5,
                              marginLeft: 10,
                              height: 0,
                              marginTop: -10,
                            }}
                          >
                            <label>
                              <input
                                type="checkbox"
                                checked={this.state.importTagsAndComments}
                                onChange={() => {
                                  this.setState({
                                    importTagsAndComments:
                                      !this.state.importTagsAndComments,
                                    importFromListId:
                                      listData.list_summary_data.id || null,
                                  });
                                }}
                              />
                              <span className="checkbox-material">
                                <span className="check"></span>{" "}
                                <span
                                  style={{
                                    display: "inline-block",
                                    transform: "translateY(-5px)",
                                  }}
                                >
                                  Import tags and comments
                                </span>
                              </span>
                            </label>
                          </div>
                          <div
                            className="checkbox"
                            style={{
                              padding: 0,
                              paddingTop: 5,
                              marginLeft: 10,
                              height: 0,
                              marginTop: -10,
                            }}
                          >
                            <label>
                              <input
                                type="checkbox"
                                checked={this.state.importContents}
                                onChange={() => {
                                  this.setState({
                                    importContents: !this.state.importContents,
                                    importFromListId:
                                      listData.list_summary_data.id || null,
                                  });
                                }}
                              />
                              <span className="checkbox-material">
                                <span className="check"></span>{" "}
                                <span
                                  style={{
                                    display: "inline-block",
                                    transform: "translateY(-5px)",
                                  }}
                                >
                                  Import Contents References
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                        <button
                          className="btn btn-primry"
                          onClick={() => this.importUsers()}
                          style={{ marginRight: 15 }}
                        >
                          IMPORT
                        </button>
                        <button
                          className="btn btn-primry"
                          onClick={() =>
                            this.setState(
                              { openList: -1, listData: {}, selected: [] },
                              this.handleWindowResize,
                            )
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )
                ))

              /* End Influencers Sections */
            }
          </div>
        ) : (
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lists: state.listsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListById: (id, callback, onlyData) => {
      dispatch(getListById(id, callback, onlyData));
    },
    addUsersToList: (
      listId,
      influencers,
      importExtraFromList,
      importContentFromList,
      callback,
    ) => {
      dispatch(
        addUsersToList(
          listId,
          influencers,
          importExtraFromList,
          importContentFromList,
          callback,
        ),
      );
    },
    getListFrequencies: (id, callback) => {
      dispatch(getListFrequencies(id, callback));
    },
  };
};

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(ImportFromListMenu);
