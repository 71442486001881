import { Accordion, CircularProgress, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import Infinite from "react-infinite";
import Select from "react-select";
import { toast } from "react-toastify";
import { getInfluencerUploads } from "src/api/actions/auctionActions";
import { InfluencerInManageAuctionData, PostTypes } from "src/api/types";
import HumanzRangePicker from "src/ui/humanz/HumanzRangePicker";
import AttachMediaFrame from "./AttachMediaFrame";
import InfluencerAttachAccordion from "./InfluencerAttachAccordion";
import NoMediaMessage from "./NoMediaMessage";

interface OptionType {
  label: string;
  value: PostTypes | null;
  icon: string;
}
const AttachTasks = (props: any) => {
  const getTags = () => {
    const influencersTags: string[] = [];
    props.board.states.approval?.influencers?.forEach((influencer: any) =>
      influencer?.tasks_details?.tasks.forEach((task: any) =>
        task.tags.forEach((tag: string) => {
          if (!influencersTags.includes(tag)) {
            influencersTags.push(tag);
          }
        }),
      ),
    );
    return influencersTags;
  };

  const [tags, setTags] = useState([...getTags()]);

  const [inputValue, setInputValue] = useState("");
  const [startDate, setStartDate] = useState(moment().subtract(1, "month"));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [selectedType, setType] = useState<OptionType>({
    value: null,
    label: "All Types",
    icon: "",
  });
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [showDatePicker, setDatePicker] = useState(false);

  useEffect(() => {
    setLoading(true);
    getInfluencerUploads(props.board.board_id, {
      caption: tags,
      start_time:
        startDate?.format("YYYY-MM-DDTHH:mm:ss.SSSZ") ||
        moment()
          ?.clone()
          ?.subtract(1, "months")
          ?.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      end_time:
        endDate?.format("YYYY-MM-DDTHH:mm:ss.SSSZ") ||
        moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      post_type: selectedType?.value !== null ? selectedType?.value : null,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        toast.error("Error fetching tasks content");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tags, selectedType, props.board.board_id, startDate, endDate]);

  const handleDateChange = (date: any) => {
    setStartDate(date);
  };
  const handleChangeTag = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleChangeSearch = (e: any) => {
    setSearchInput(e.target.value);
  };
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && inputValue?.trim() !== "") {
      setTags([...tags, inputValue?.trim()]);
      setInputValue("");
    }
  };

  const removeTag = (indexToRemove: number) => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(newTags);
  };

  const removeMedia = (media_id: string) => {
    setData((prevData: any) => {
      const newData = prevData.map((influencer: any) => {
        const updatedMedias = influencer.medias.filter(
          (media: any) => media.media_id !== media_id,
        );
        return {
          ...influencer,
          medias: updatedMedias,
        };
      });
      return newData;
    });
  };

  const createArraysOfThreeElements = function (inputArray: any[]) {
    const resultArray = [];

    for (let i = 0; i < inputArray.length; i += 3) {
      resultArray.push(inputArray.slice(i, i + 3));
    }
    return resultArray;
  };

  const influencersMapping = useMemo(() => {
    const dic: Record<number, InfluencerInManageAuctionData> = {};
    props.board.states?.approval?.influencers.forEach(
      (inf: InfluencerInManageAuctionData) => {
        if (inf?.name?.toLowerCase()?.includes(searchInput?.toLowerCase())) {
          dic[inf.influencer_id] = inf;
        }
      },
    );
    return dic;
  }, [props.board.states?.approval?.influencers, searchInput]);

  const handleTypeChange = (selectedOption: any) => {
    setType(selectedOption);
  };
  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{ color: "#808080", cursor: "pointer", marginRight: "10px" }}
          onClick={props.goBack}
        >
          <i className="fa-regular fa-arrow-left"></i>
          <span style={{ marginLeft: "5px" }}>Go back</span>
        </div>
        <span style={{ fontWeight: 500, fontSize: "16px", color: "#249FF0" }}>
          {props.board.summary.name}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "27px",
          marginBottom: "12px",
        }}
      >
        <Input
          sx={{
            width: "300px",
            borderRadius: "24px",
            background: "white",
            marginTop: "5px",
            "::placeholder": {
              color: "#aaaaaa",
            },
          }}
          placeholder="Search Influencer"
          onChange={handleChangeSearch}
          onKeyDown={handleKeyPress}
          value={searchInput}
        />
        <button
          className="attach-task-button"
          style={{ background: "#249FF0", width: "200px", margin: 0 }}
          onClick={props.goBack}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "white",
              gap: 10,
            }}
          >
            <i
              className="fa-regular fa-circle-xmark"
              style={{ fontSize: "24px" }}
            ></i>
            <span>Attach tasks to content</span>
            <div
              className="attach-task-icon"
              style={{ background: "#87CAF7", margin: 0 }}
            >
              <i className="fa-sharp fa-solid fa-link"></i>
            </div>
          </div>
        </button>
      </div>
      <div
        style={{
          background: "#F3F6F9",
          borderRadius: "8px",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", gap: "10%" }}>
          <div style={{ width: "300px" }}>
            <span className="sub-header">Tags, Handles, key words</span>
            <Input
              sx={{
                background: "white",
                marginTop: "5px",
                "::placeholder": {
                  color: "#aaaaaa",
                },
              }}
              placeholder="#hashtag @mention , key word"
              onChange={handleChangeTag}
              onKeyDown={handleKeyPress}
              value={inputValue}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 5,
                width: "330px",
                marginTop: "5px",
              }}
            >
              {tags?.map((tag: string, index: number) => (
                <div key={index} className="search-tag">
                  <span>{tag}</span>
                  <i
                    className="fa-solid fa-xmark"
                    onClick={() => removeTag(index)}
                  ></i>
                </div>
              ))}
            </div>
          </div>
          <div>
            <span className="sub-header">Due dates</span>
            <div
              className="datepicker-box"
              style={{
                marginTop: 5,
                cursor: "pointer",
              }}
              onClick={() => {
                setDatePicker(true);
              }}
            >
              <DatePicker
                readOnly={true}
                selected={startDate}
                onChange={handleDateChange}
                dateFormat={window.time.short_date}
                placeholderText="DD/MM/YY"
                className="borderless datepicker-width"
              />
              {/* <span style={{ marginRight: "8px", color: "#aaaaaa" }}>-</span> */}
              <DatePicker
                readOnly={true}
                selected={endDate}
                onChange={handleDateChange}
                dateFormat={window.time.short_date}
                placeholderText="DD/MM/YY"
                className="borderless datepicker-width"
              />
              <i
                className="fa-duotone fa-calendar-days fa-lg"
                style={{ color: "grey" }}
              ></i>
            </div>
          </div>
          <HumanzRangePicker
            buttonStyle={{
              placeSelf: "flex-end",
            }}
            showButton={false}
            onClose={() => {
              setDatePicker(false);
            }}
            open={showDatePicker}
            startDate={moment(startDate || Date.now()).toDate()}
            endDate={moment(endDate || Date.now()).toDate()}
            onChange={(res: any) => {
              if (res && res.startDate && res.endDate) {
                setStartDate(moment(res.startDate));
                setEndDate(moment(res.endDate));
              }
              setDatePicker(false);
            }}
          />
          <div>
            <span className="sub-header">Content type</span>
            <Select
              options={[
                { value: null, label: "All Types", icon: "" },
                ...props.options,
              ]}
              clearable={false}
              onChange={handleTypeChange}
              value={selectedType}
              name="selected-state"
              searchable={true}
              style={{ width: "160px" }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {loading ? (
          <div style={{ alignSelf: "center", marginTop: "24px" }}>
            <CircularProgress isIndeterminate size={"100px"} />
          </div>
        ) : (
          <>
            {data && data.length > 0 ? (
              <Accordion allowToggle>
                {data.map((inf: any, index: number) => {
                  const influencer = influencersMapping[inf.influencer_id];
                  const threes = createArraysOfThreeElements(inf.medias);
                  if (!influencer) {
                    return null;
                  }
                  return (
                    <InfluencerAttachAccordion
                      header={influencer?.name}
                      key={index}
                      avatar={influencer?.picture_url}
                    >
                      <div
                        key={influencer.influencer_id}
                        style={{
                          background: "white",

                          width: "100%",
                        }}
                      >
                        <Infinite elementHeight={490} containerHeight={528}>
                          {threes?.map((threeMedias: any, inx: number) => (
                            <div key={inx} className="attach-media-row">
                              {threeMedias.map((media: any) => {
                                return (
                                  <div
                                    key={media.media_id}
                                    className="margin-medias"
                                  >
                                    <AttachMediaFrame
                                      key={media.media_id}
                                      influencerTasks={
                                        influencer?.tasks_details?.tasks
                                      }
                                      influencer={influencer}
                                      postType={media.post_type}
                                      id={media.media_id}
                                      board_id={props.board.board_id}
                                      post={media}
                                      removeMedia={removeMedia}
                                      month={props.month}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                        </Infinite>
                      </div>
                    </InfluencerAttachAccordion>
                  );
                })}
              </Accordion>
            ) : (
              <NoMediaMessage tags={tags} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AttachTasks;
