/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Tag,
  Text,
  Textarea,
} from "@chakra-ui/react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { useContext } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import TagsInput from "react-tagsinput";
import { EditModeProps, LocalTask } from "src/api/ExtendedTypes";
// import Select from "react-select";
import Toggle from "react-toggle";
import { PostSubTypes } from "src/api/types";
import { TaskNotRemovableReasonString } from "src/helpers/ManageDataHelper";
import { getSocialNetworkByPostType } from "src/helpers/SocialNetworksHelper";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import { EditTasksContext } from "../../dialogs/EditTasksDialog/EditTasksContext";
import TaskReferencesPopup from "../../dialogs/TaskReferencesPopup";
import { InfoToolTip } from "../../general_components/InfoToolTip";
import {
  isValidUrl,
  numberWithCurrency,
  SubTypes,
} from "../../utilities/general";
import {
  get_post_type_icon,
  get_post_type_label,
  TaskTypes,
} from "../../utilities/providers";
import Indicator from "../CreateCampaignSection/FormUI/Indicator";
import { isEmptyPostStats } from "../dialogs/AttachLinksDialog/AttachLinksHelpers";

const POST_SUB_TYPE = {
  reel: "Reel",
  igtv: "IGTV",
  post: "Post",
};

type Props = {
  task: LocalTask;
  index: number;
  bundleIndex?: number;
  taskUpdated?: any;
  editMode?: EditModeProps;
  isOpen?: boolean;
  editable?: boolean;
  onToggleTask?: any;
  setEditMode?: any;
  showPrice?: boolean;
  inBundleMode?: boolean;
  locked?: boolean;
  isOffer?: boolean;
  modifications?: { deleted: boolean };
  attachMode?: any;
  splitPayment?: boolean;
  mode?: string;
};

const SingleTaskRow = ({
  task,
  index,
  bundleIndex,
  taskUpdated,
  editMode,
  isOpen,
  onToggleTask,
  setEditMode,
  showPrice,
  inBundleMode,
  locked,
  modifications,
  isOffer,
  attachMode,
  editable = true,
  mode,
  splitPayment,
}: Props) => {
  const board = useSelector((state) => state.boardReducer);
  const user = useSelector((state) => state.userReducer);
  const showTikTok = user.userDetails.sidebar_data.can_view_tiktok;
  const context = useContext(EditTasksContext);
  const date = task.due_date ? moment(task.due_date) : "";
  const dueDate = task.content_due_date ? moment(task.content_due_date) : "";

  const { completed } = task;
  const hasOpenProposal = task?.proposal && task?.proposal?.approved === null;
  const taskOptions = showTikTok
    ? TaskTypes
    : TaskTypes.filter((x: any) => x.value !== "tk_video");
  const taskInfo = get_post_type_label(task.post_type);

  const handleTagsChange = (ix: number, taskToUpdate: any, tags: any) => {
    taskToUpdate.changed = true;
    taskToUpdate.tags = tags;
    window.setTimeout(() => {
      try {
        const objDiv = document.getElementById(`tag-input${ix}`);
        objDiv.scrollTop = objDiv.scrollHeight;
      } catch (e) {
        // tdb
      }
    }, 1);
    taskUpdated();
  };

  const handleChangeTagInput = (ix: number, tag: any) => {
    // eslint-disable-next-line prefer-regex-literals, no-useless-escape
    const reg = new RegExp(/^[#?|@]?[^\s!@#$%^&*()=+./,\[{\]};:'"?><]+$/);
    tag = tag.replace(" ", "_");
    if (
      tag === "" ||
      tag === "#" ||
      tag === "@" ||
      ((tag.startsWith("#") || tag.startsWith("@")) && reg.test(tag))
    ) {
      task.tags[ix] = tag;
      //   this.state.tagInputs[index] = tag;
      //   this.setState({ tagInputs: this.state.tagInputs });
      taskUpdated();
    }
  };
  const socialNetwork = getSocialNetworkByPostType(task.post_type);

  const isAPI =
    context?.influencer?.social_network_data?.[socialNetwork]
      ?.verification_type === "api";
  const warning = completed && !isAPI && isEmptyPostStats(task.engagemnts);
  const removable = task.removable !== false || (!completed && inBundleMode);

  return (
    taskInfo && (
      <Accordion
        style={{
          alignItems: "center",
          width: "100%",
        }}
        allowToggle
        defaultIndex={isOpen ? [0] : null}
        key={`tasks${index}`}
        paddingX={3}
      >
        <AccordionItem
          m={0}
          sx={{
            borderRadius: 12,
            background: "#FFF",
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          {({ isExpanded }) => {
            return (
              <Box className={isExpanded ? "expanded" : ""}>
                <Box display={editMode ? "flex" : "block"}>
                  {editMode &&
                    (showPrice ||
                      (task.new && !inBundleMode) ||
                      (!inBundleMode && mode === "list")) && (
                      <Box
                        width={120}
                        style={{
                          background: "#F6F6F6",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {
                          <Box justifyContent={"center"} alignItems="center">
                            <InputGroup>
                              <Input
                                defaultValue={task.additonal_price}
                                onFocus={(e) => {
                                  e.target.select();
                                }}
                                onChange={(e) => {
                                  task.additonal_price = Number(e.target.value);
                                  task.changed = true;
                                  taskUpdated();
                                }}
                                placeholder="Price"
                                className=""
                                type="number"
                                style={{ background: "white", margin: 6 }}
                              />
                              <InputRightElement
                                pointerEvents="none"
                                color="gray.300"
                                fontSize="1.2em"
                                marginTop={1}
                              >
                                {window.translate(
                                  "",
                                  `money_symbol_${board.board.summary.currency}`,
                                )}
                              </InputRightElement>
                            </InputGroup>
                          </Box>
                        }
                      </Box>
                    )}
                  <Box flex={1} alignSelf={"center"}>
                    <AccordionButton
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2px 12px",
                        alignItems: "center",
                        minHeight: 35,
                      }}
                    >
                      <Flex
                        gap={4}
                        justifyContent={"center"}
                        alignItems="center"
                      >
                        {!editMode && (
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={
                              <span>
                                {index !== null
                                  ? isOffer
                                    ? "Offered task"
                                    : `Task number ${
                                        bundleIndex !== undefined
                                          ? `${bundleIndex + 1}.`
                                          : ""
                                      }${index + 1} ${
                                        task.id ? `(${task.id})` : ""
                                      }`
                                  : "New task"}
                              </span>
                            }
                          >
                            <Box
                              className="task-num-circle"
                              position={"relative"}
                              fontSize={12}
                              fontWeight={500}
                              background={
                                index === undefined || index === -1 || task.new
                                  ? "green.600"
                                  : "#AAAAAA"
                              }
                            >
                              {index === undefined ||
                              index === -1 ||
                              task.new ? (
                                <Text color={"white"} textTransform="uppercase">
                                  NEW
                                </Text>
                              ) : (
                                `${
                                  bundleIndex !== undefined
                                    ? `${bundleIndex + 1}.${index + 1}`
                                    : index + 1
                                }`
                              )}
                            </Box>
                          </Tooltip>
                        )}
                        {!task.bundle_id && !editMode && (
                          <Tag>
                            {numberWithCurrency(
                              Number(task?.additonal_price) || 0,
                              board.board.summary.currency,
                            )}
                          </Tag>
                        )}
                        <i
                          style={{ fontSize: 20 }}
                          className={`${taskInfo.icon} colored `}
                        />
                        <Text fontSize={16}>
                          <strong>
                            {taskInfo.label}
                            {socialNetwork === "instagram" &&
                            task.post_type === "ig_post"
                              ? ` (${
                                  (POST_SUB_TYPE as any)[
                                    task.post_sub_type ?? "post"
                                  ]
                                })`
                              : null}
                          </strong>
                        </Text>
                      </Flex>

                      <Flex
                        gap={2}
                        style={{ marginRight: "10px", alignItems: "center" }}
                      >
                        {warning && attachMode && (
                          <Tooltip overlay={"Add post data"}>
                            <i
                              className="far fa-warning"
                              style={{ marginLeft: 5, color: "#f25b23" }}
                            />
                          </Tooltip>
                        )}
                        {completed ? (
                          <>
                            <Tag colorScheme={"green"}>
                              <i
                                className="far fa-check-circle"
                                style={{ marginRight: 2 }}
                              />
                              Completed
                            </Tag>
                          </>
                        ) : (
                          task?.due_date && (
                            <Tag
                              colorScheme={
                                moment(task.due_date).isBefore(moment())
                                  ? "red"
                                  : "gray"
                              }
                            >
                              {task.due_date ? (
                                <Text
                                  isTruncated
                                  textAlign="start"
                                  flexShrink={0}
                                >
                                  Due{" "}
                                  {moment(task.due_date).format(
                                    window.time.short_date,
                                  )}
                                </Text>
                              ) : null}
                            </Tag>
                          )
                        )}
                        {!task.completed && attachMode && (
                          <Tag colorScheme={"cyan"}>
                            <i
                              className="far fa-arrow-progress"
                              style={{ marginRight: 4 }}
                            />
                            {"In progress"}
                          </Tag>
                        )}

                        {(modifications && modifications.deleted) ||
                        task.deleted ? (
                          // <Tooltip overlay={"Click to restore"}>
                          <Tag
                            colorScheme={"red"}
                            className="clickable"
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            //   task.deleted = undefined;
                            //   taskUpdated(null, null, null, task.uuid);
                            // }}
                          >
                            {/* <i
                            className="far fa-times"
                            style={{ marginRight: 2 }}
                          /> */}
                            Marked for deletion
                          </Tag>
                        ) : // </Tooltip>
                        null}

                        {!inBundleMode && task.complete_at_end_of_month && (
                          <Tooltip
                            overlay={
                              "Influencer will receive the payment at the end of the month, regardless of tasks completion"
                            }
                          >
                            <Tag colorScheme={"purple"}>
                              <i
                                className="fa-duotone fa-money-check-dollar"
                                style={{ marginRight: 2 }}
                              />
                              Guaranteed payment
                            </Tag>
                          </Tooltip>
                        )}

                        {!inBundleMode && task.renews ? (
                          <Tag colorScheme={"blue"}>
                            <i
                              className="far fa-arrows-spin"
                              style={{ marginRight: 2 }}
                            />
                            Recurring
                          </Tag>
                        ) : null}
                        {(editMode || !inBundleMode) &&
                          !(!inBundleMode && editMode) &&
                          !completed &&
                          !locked &&
                          !task.deleted &&
                          editable && (
                            <HumanzActionsMenu
                              style={{ padding: 0, minWidth: 15 }}
                              actions={[
                                !editMode && {
                                  action: () => {
                                    setEditMode(task);
                                    taskUpdated();
                                  },
                                  text: "Edit task",
                                  icon: "far fa-edit",
                                },

                                {
                                  action: () =>
                                    onToggleTask(
                                      task.index,
                                      null,
                                      null,
                                      task?.uuid,
                                    ),
                                  text:
                                    !removable && task.not_removable_reason
                                      ? `Delete - ${TaskNotRemovableReasonString(
                                          task.not_removable_reason,
                                        )}`
                                      : "Delete",
                                  icon: "fal fa-trash-alt",
                                  disabled: task.new
                                    ? false
                                    : completed ||
                                      hasOpenProposal ||
                                      !removable,
                                },
                              ]}
                            />
                          )}
                        <i
                          style={{ fontSize: 16 }}
                          className={`fa-regular ${
                            isExpanded ? "fa-chevron-up" : "fa-chevron-down"
                          } fa-lg`}
                        />
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel>
                      {!attachMode ? (
                        <>
                          <Flex className="tasks-selectors" gap={4} mb={4}>
                            {editMode && (
                              <Box>
                                <Indicator header={"Task type"}>
                                  <Flex>
                                    <Box
                                      style={{ marginTop: 0 }}
                                      className={`${get_post_type_icon(
                                        task?.post_type,
                                      )} colored circle-icon`}
                                    />
                                    <Box style={{ width: 150, marginLeft: 5 }}>
                                      <Select
                                        placeholder={""}
                                        defaultValue={task.post_type}
                                        onChange={(event) => {
                                          const { value } = event.target;
                                          task.changed_mandatory = true;
                                          task.post_type = value as any;
                                          if (value !== "ig_post")
                                            task.post_sub_type = null;
                                          taskUpdated();
                                        }}
                                      >
                                        {taskOptions.map((option: any) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </Select>
                                    </Box>
                                  </Flex>
                                </Indicator>
                              </Box>
                            )}
                            {task.post_type === "ig_post" && editMode && (
                              <Indicator header="Sub Type">
                                <div style={{ marginLeft: 5 }}>
                                  <Select
                                    placeholder={""}
                                    value={task.post_sub_type}
                                    onChange={(event) => {
                                      const value = event.target
                                        .value as PostSubTypes;
                                      task.post_sub_type = value;
                                      task.changed = true;
                                      taskUpdated();
                                    }}
                                  >
                                    {SubTypes.map((subType) => (
                                      <option
                                        key={subType.value}
                                        value={subType.value}
                                      >
                                        {subType.label}
                                      </option>
                                    ))}
                                  </Select>
                                </div>
                              </Indicator>
                            )}

                            {editMode &&
                              (!inBundleMode || splitPayment) &&
                              !context.influencer.edited_next_month &&
                              !board?.board?.one_month_campaign && (
                                <Indicator
                                  header={
                                    <>
                                      <InfoToolTip
                                        iconStyle={{
                                          color: "InactiveCaptionText",
                                        }}
                                        overlay={
                                          "Select if you want this task to be repeated every month."
                                        }
                                      />{" "}
                                      Repeat every month
                                    </>
                                  }
                                >
                                  <Box>
                                    <Select
                                      placeholder={""}
                                      defaultValue={String(task.renews)}
                                      onChange={(event) => {
                                        const { value } = event.target;
                                        task.renews = value === "true";

                                        task.changed = true;
                                        taskUpdated();
                                      }}
                                      name="selected-state"
                                    >
                                      <option value={"false"}>
                                        Do not repeat
                                      </option>
                                      <option value={"true"}>Repeat</option>
                                    </Select>
                                  </Box>
                                </Indicator>
                              )}
                            {editMode &&
                              (!inBundleMode || splitPayment) &&
                              !context.influencer.edited_next_month &&
                              !board?.board?.one_month_campaign && (
                                <Indicator
                                  header={
                                    <>
                                      <InfoToolTip
                                        iconStyle={{
                                          color: "InactiveCaptionText",
                                        }}
                                        overlay={
                                          "Select if you want this tasks to be paid at completion or at the end of the month."
                                        }
                                      />{" "}
                                      Eligible for payment on
                                    </>
                                  }
                                >
                                  <Box>
                                    <Select
                                      placeholder={""}
                                      defaultValue={String(
                                        task.complete_at_end_of_month,
                                      )}
                                      onChange={(event) => {
                                        const { value } = event.target;
                                        task.complete_at_end_of_month =
                                          value === "true";
                                        task.changed = true;
                                        taskUpdated();
                                      }}
                                      name="selected-state"
                                    >
                                      <option value={"false"}>
                                        Tasks Completion
                                      </option>
                                      <option value={"true"}>
                                        Guaranteed payment
                                      </option>
                                    </Select>
                                  </Box>
                                </Indicator>
                              )}
                          </Flex>
                          <Flex gap={4} mb={4}>
                            <Box flex={1}>
                              <Indicator
                                header={"Tags, handles"}
                                description={
                                  editMode
                                    ? "Write your tag/handle with #/@ accordingly and press enter"
                                    : ""
                                }
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    // height: 35,
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                    padding: 3,
                                  }}
                                  className={editMode ? "whitebox-input" : null}
                                  id={`tag-input${index}`}
                                >
                                  {task.tags?.length || editMode ? (
                                    <TagsInput
                                      value={task.tags}
                                      addOnBlur={true}
                                      onChange={(
                                        tags,
                                        _changed,
                                        changedIndexes,
                                      ) =>
                                        handleTagsChange(
                                          changedIndexes[index],
                                          task,
                                          tags,
                                        )
                                      }
                                      // inputValue={tagInputs[index]}
                                      onChangeInput={(tag) =>
                                        handleChangeTagInput(index, tag)
                                      }
                                      inputProps={{
                                        placeholder: !editMode
                                          ? null
                                          : "#hashtag @mention",
                                        className: "tasks-tags-input",
                                      }}
                                    />
                                  ) : (
                                    <Text>No tags / handles specified</Text>
                                  )}
                                </div>
                              </Indicator>
                            </Box>

                            {editMode || task.references?.length ? (
                              <Box>
                                <Indicator header="References">
                                  <Box>
                                    <TaskReferencesPopup
                                      task={task as any}
                                      onClose={() => {}}
                                      onTaskChanged={(newTask) => {
                                        task.references = newTask.references;
                                        task.changed = true;
                                        taskUpdated();
                                      }}
                                    />
                                  </Box>
                                </Indicator>
                              </Box>
                            ) : null}
                          </Flex>
                          <Flex gap={4}>
                            <Indicator
                              full
                              header={"Task Description"}
                              style={{ flex: 1 }}
                            >
                              {editMode ? (
                                <Textarea
                                  className="whitebox-input"
                                  value={task.description}
                                  onChange={(e) => {
                                    task.description = e.target.value;
                                    task.changed = true;
                                    taskUpdated();
                                  }}
                                  placeholder="Task description"
                                  //   minRows={1}
                                  //   maxRows={3}
                                />
                              ) : (
                                <Text padding={1}>
                                  {task.description || "No description"}
                                </Text>
                              )}
                            </Indicator>
                          </Flex>
                          {task.for_ads ||
                          (user.userDetails?.sidebar_data?.allowed_ads_tasks &&
                            (socialNetwork === "instagram" ||
                              socialNetwork === null)) ? (
                            <>
                              <Flex gap={4} mt={4}>
                                <Indicator
                                  full
                                  header={"Promote on Meta Ads"}
                                  style={{ flex: 1 }}
                                >
                                  <div
                                    className="toggle-input"
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <label>
                                      <div className="toggle-label">
                                        <div className="fa fa-times" />
                                        <div className="fa fa-check" />
                                      </div>
                                      <Toggle
                                        defaultChecked={task.for_ads}
                                        icons={false}
                                        disabled={!editMode}
                                        onChange={() => {
                                          task.changed = true;
                                          task.for_ads = !task.for_ads;
                                          taskUpdated();
                                        }}
                                      />
                                    </label>
                                  </div>
                                </Indicator>
                              </Flex>
                              {task.for_ads ? (
                                <Flex gap={4} mt={4}>
                                  <Indicator
                                    full
                                    header={"Ads Link (Optional)"}
                                    style={{ flex: 1 }}
                                  >
                                    {editMode ? (
                                      <Input
                                        type="text"
                                        placeholder="Product website url"
                                        value={task.ads_long_link}
                                        onBlur={() => {
                                          if (
                                            task.ads_long_link &&
                                            !isValidUrl(task.ads_long_link)
                                          ) {
                                            task.ads_long_link = "";
                                            taskUpdated();
                                          }
                                        }}
                                        onChange={(e) => {
                                          task.changed = true;
                                          task.ads_long_link =
                                            e.target.value.trim();
                                          taskUpdated();
                                        }}
                                      />
                                    ) : (
                                      <Text padding={1}>
                                        {task.ads_long_link ||
                                          "No URL specified"}
                                      </Text>
                                    )}
                                  </Indicator>
                                </Flex>
                              ) : null}
                            </>
                          ) : null}
                          <Flex mt={4} gap={4}>
                            <Indicator header={"Approval Due Date"}>
                              {editMode ? (
                                <Box className="datepicker-box">
                                  <DatePicker
                                    dateFormat={window.time.short_date}
                                    className="borderless-datepicker"
                                    isClearable={true}
                                    placeholderText="Approval day"
                                    popperPlacement="right-end"
                                    selected={!dueDate ? null : dueDate}
                                    onChange={(newDate) => {
                                      task.changed = true;
                                      task.content_due_date = moment(newDate)
                                        .utc(true)
                                        .toISOString();
                                      taskUpdated();
                                    }}
                                  />
                                  <i
                                    className="fa-duotone fa-calendar-days fa-lg"
                                    style={{ color: "grey" }}
                                  ></i>
                                </Box>
                              ) : (
                                <Text>
                                  {dueDate
                                    ? moment(dueDate).format(
                                        window.time.short_date,
                                      )
                                    : "No date specified"}
                                </Text>
                              )}
                            </Indicator>
                            <Indicator header={"Publish Due Date"}>
                              {editMode ? (
                                <Box className="datepicker-box">
                                  <DatePicker
                                    dateFormat={window.time.short_date}
                                    className="borderless-datepicker"
                                    isClearable={true}
                                    popperPlacement="right-end"
                                    placeholderText="Publish day"
                                    selected={!date ? null : date}
                                    onChange={(newDate) => {
                                      task.changed = true;

                                      task.due_date = moment(newDate)
                                        .utc(true)
                                        .toISOString();
                                      taskUpdated();
                                    }}
                                  />
                                  <i
                                    className="fa-duotone fa-calendar-days fa-lg"
                                    style={{ color: "grey" }}
                                  ></i>
                                </Box>
                              ) : (
                                <Text>
                                  {date
                                    ? moment(date).format(
                                        window.time.short_date,
                                      )
                                    : "No date specified"}
                                </Text>
                              )}
                            </Indicator>
                          </Flex>
                        </>
                      ) : (
                        attachMode
                      )}
                    </AccordionPanel>
                  </Box>
                </Box>
              </Box>
            );
          }}
        </AccordionItem>
      </Accordion>
    )
  );
};

export default SingleTaskRow;
