import React from "react";
import { connect } from "react-redux";
import { YAAD_COINS } from "../../../actions/paymentActions";

/**
 * Constants
 */
const IS_TEST = () =>
  window.SERVER_CONF?.YAAD_MASOF_NUMBER?.substr(0, 2) === "00";
const YAAD_PASSWORD = import.meta.env.YAAD_PASSWORD || "humanz.ai";
const YAAD_PAYMENT_URL =
  import.meta.env.YAAD_PAYMENT_URL || "https://icom.yaad.net/p/?action=pay";
const eventTitle = "humanz_yaad_redirect";

interface YaadFormParams {
  Amount: number;
  Info?: string;
  Coin?: number;
  PageLang?: number;
  ClientName: string;
  ClientLName: string;
  cell: string;
  phone?: string;
  city: string;
  street: string;
  email: string;
  J5?: string;
  zip: number;
  Masof?: string;
  sendemail?: string;
  UTF8?: string;
  UTF8out?: string;
  UserId?: string;
  MoreData: string;
  SendHesh: string;
  PassP: string;
}

interface State {
  isLoading: boolean;
}

interface OwnProps {
  country: string;
  cardAddress: any;
  cardDetails: any;
  onRedirect: any;
  coin: string;
}

class YaadCreditCardInner extends React.Component<OwnProps, State> {
  state: State = {
    isLoading: false,
  };

  handleRedirectEvent = (e: any) => {
    this.props.onRedirect(e.data, () => {
      this.setState({ isLoading: false });
    });
  };

  componentDidMount(): void {
    window.addEventListener(eventTitle, this.handleRedirectEvent);
  }

  componentWillUnmount(): void {
    window.removeEventListener(eventTitle, this.handleRedirectEvent);
  }

  // eslint-disable-next-line class-methods-use-this
  buildIframeUrl = (defaultParams: YaadFormParams) => {
    const params = {
      Amount: defaultParams.Amount || 5,
      ClientLName: defaultParams.ClientLName || "",
      ClientName: defaultParams.ClientName || "",
      Coin: defaultParams.Coin || Number(YAAD_COINS.NIS),
      Info: defaultParams.Info || "Humanz LTD general payment",
      J5: defaultParams.J5 || "True",
      Masof: defaultParams.Masof || window.SERVER_CONF.YAAD_MASOF_NUMBER,
      MoreData: defaultParams.MoreData || "True",
      PageLang: defaultParams.PageLang || "ENG",
      SendHesh: defaultParams.SendHesh || "",
      Sign: "True",
      UserId: (defaultParams as any).UserId || "000000000",
      UTF8: defaultParams.UTF8 || "True",
      UTF8out: defaultParams.UTF8out || "True",
      cell: defaultParams.cell || "",
      city: defaultParams.city || "",
      email: defaultParams.email || "",
      phone: defaultParams.phone || "",
      sendemail: defaultParams.sendemail || "True",
      street: defaultParams.street || "",
      zip: defaultParams.zip || "",
      tmp: 3,
      PassP: undefined as string | undefined,
    };
    if (IS_TEST()) {
      params.PassP = YAAD_PASSWORD;
    } else {
      delete params.PassP;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const paramsString = new URLSearchParams(params).toString();
    return `${YAAD_PAYMENT_URL}&${paramsString}`;
  };

  // eslint-disable-next-line class-methods-use-this
  renderYaadIframe = (url: string) => {
    return (
      <iframe
        src={url}
        style={{
          border: "none",
          width: "100%",
          height: 500,
        }}
      />
    );
  };

  render() {
    return this.state.isLoading ? (
      <></>
    ) : (
      this.renderYaadIframe(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.buildIframeUrl({
          UserId: this.props.cardAddress.tax_number,
          Coin: Number(
            YAAD_COINS[
              this.props.cardDetails.currency as keyof typeof YAAD_COINS
            ] ?? YAAD_COINS.NIS,
          ),
        }),
      )
    );
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = () => {
  return {};
};

export const YaadCreditCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(YaadCreditCardInner);
