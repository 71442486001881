import { useState } from "react";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";

const DisplayTaskTag = (props: any) => {
  const [tag, setTaskTag] = useState(props.task.tag);

  const getColor = (i_tag: string) => {
    let boxColor;
    let widthValue;
    if (i_tag === "General") {
      widthValue = "95px";
      boxColor = "var(--grey-2, #EBEBEB)";
    } else if (i_tag === "Brief") {
      widthValue = "70px";
      boxColor = "rgba(252, 199, 141, 0.60)";
    } else if (i_tag === "List") {
      widthValue = "62px";
      boxColor = "rgba(186, 153, 233, 0.50)";
    } else if (i_tag === "Manage") {
      widthValue = "98px";
      boxColor = "rgba(142, 200, 255, 0.50)";
    } else if (i_tag === "ROI") {
      widthValue = "63px";
      boxColor = "rgba(172, 252, 141, 0.60)";
    } else {
      boxColor = "gray";
    }
    return { boxColor, widthValue };
  };

  const res = getColor(tag);
  const boxStyle = {
    backgroundColor: res.boxColor,
    borderRadius: "24px",
    justifyContent: "center",
    border: "1px solid #CFCFCF",
    width: res.widthValue,
    paddingLeft: "8px",
    paddingRight: "5px",
    paddingBottom: "1px",
    fontSize: "14px",
    marginLeft: "4px",
    maxHeight: "24px",
  };

  const TypesActions = [
    {
      action: () => {
        setTaskTag("General");
        props.handleEditTask(props.task.id, {
          ...props.task,
          tag: "General",
        });
      },
      text: "General",
    },
    {
      action: () => {
        setTaskTag("Brief");
        props.handleEditTask(props.task.id, {
          ...props.task,
          tag: "Brief",
        });
      },
      text: "Brief",
    },
    {
      action: () => {
        setTaskTag("List");
        props.handleEditTask(props.task.id, {
          ...props.task,
          tag: "List",
        });
      },
      text: "List",
    },
    {
      action: () => {
        setTaskTag("Manage");
        props.handleEditTask(props.task.id, {
          ...props.task,
          tag: "Manage",
        });
      },
      text: "Manage",
    },

    {
      action: () => {
        setTaskTag("ROI");
        props.handleEditTask(props.task.id, {
          ...props.task,
          tag: "ROI",
        });
      },
      text: "ROI",
    },
  ];
  return (
    <>
      <div style={boxStyle}>
        <HumanzActionsMenu
          buttontype="button"
          actions={TypesActions}
          buttontext={tag}
          size={"20px"}
          disabled={props.disable}
        />
      </div>
      {/* <Box style={boxStyle}>{tag}</Box> */}
    </>
  );
};

export default DisplayTaskTag;
