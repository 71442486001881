import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  FileTypes,
  FileUpload,
  uploadFileSizeValidator,
} from "src/ui/FileUpload";

const AddBrandModal = (props: any) => {
  const [brandName, setBrandName] = useState("");
  const [brandImg, setBrandImg] = useState("");

  const handleBrandNameChange = (event: any) => {
    const newValue = event.target.value;
    setBrandName(newValue);
  };

  return (
    <Modal
      size="sm"
      isOpen={true}
      onClose={() => {
        props.onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Brand</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <Input
            onChange={handleBrandNameChange}
            placeholder="Brand Name"
            sx={{ "::placeholder": { color: "#AAAAAA" }, border: "none" }}
            autoFocus
          />
          <div
            style={{
              display: "flex",
              flex: "0 0 200px",
              height: "200px",
              width: "200px",

              marginTop: "10px",
            }}
          >
            <FileUpload
              placeholder="Brand logo/image"
              variant="round"
              fileType={FileTypes.image}
              previewFileUrl={brandImg || undefined}
              onFileUploaded={(image_url) => {
                setBrandImg(image_url);
              }}
              styleImg={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
                borderRadius: "50%",
                border: "3px solid #eae8e8",
                padding: "4px",
              }}
              circle
              validator={uploadFileSizeValidator}
            />
          </div>
          <Button
            mt={4}
            onClick={() => {
              props.handleAddBrand(brandName, brandImg);
              props.onClose();
            }}
            isDisabled={!brandName?.trim()}
          >
            Next
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddBrandModal;
