import { createStore, applyMiddleware, combineReducers } from "redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import Promise from "redux-promise-middleware";
import { showLogger } from "./actions/commonActionsConf";
import { userReducer } from "./reducers/userReducer";
import { campaignReducer } from "./reducers/campaignReducer";
import inboxReducer from "./reducers/inboxReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import searchReducer from "./reducers/searchReducer";
import boardReducer from "./reducers/boardReducer";
import listsReducer from "./reducers/listsReducer";
import loadersReducer from "./reducers/loadersReducer";
import reportsReducer from "./reducers/reportsReducer";
import brandReportsReducer from "./reducers/brandReportsReducer";
import sharedReducer from "./reducers/sharedReducer";
import academyReducer from "./reducers/academyReducer";
import { listsReducer as listsSliceReducer } from "./reducers/listsSlice";

let middleWare;
if (showLogger) {
  middleWare = applyMiddleware(createLogger(), thunk, Promise());
} else {
  middleWare = applyMiddleware(thunk, Promise());
}

export const store = createStore(
  combineReducers({
    userReducer,
    loadersReducer,
    inboxReducer,
    notificationsReducer,
    searchReducer,
    boardReducer,
    listsReducer,
    reportsReducer,
    brandReportsReducer,
    sharedReducer,
    academyReducer,
    campaignReducer,
    lists: listsSliceReducer,
  }),
  {},
  middleWare,
);
