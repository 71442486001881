import { MetaTag } from "src/api/ExtendedTypes";
import { AllAdvertiserDetails, InfluencerAgentInfo } from "src/api/types";

export interface UserReducer {
  id?: number;
  userDetails?: AllAdvertiserDetails;
  agents?: Array<InfluencerAgentInfo>;
  parent?: boolean;
  tags: { auction_tags: Array<MetaTag>; lists_tags: Array<MetaTag> };
  enableShareWithSubUsers?: boolean;
}

const setSubUsersFlag = (state: UserReducer) => {
  // eslint-disable-next-line no-param-reassign
  state.enableShareWithSubUsers = !!(
    !state.userDetails.sub_user_permission &&
    (state.userDetails?.sidebar_data?.is_enterprise ||
      state.userDetails?.sidebar_data?.sub_users)
  );
};

export const userReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: UserReducer = { tags: { auction_tags: [], lists_tags: [] } },
  action: any,
) => {
  let newDetails;
  switch (action.type) {
    case "SET_USER":
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        id: action.payload.user_id,
        // no more presenters on login website
        userDetails: { ...action.payload, is_presenter: false },
        parent:
          action.payload.child_users && action.payload.child_users.length > 0,
      };
      setSubUsersFlag(state);
      localStorage.setItem("MOI_USER_KEY_0_8_0", JSON.stringify(state));
      break;

    case "SET_USER_FROM_LOCALSTORAGE":
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        id: action.payload.id,
        userDetails: action.payload.userDetails,
        parent: action.payload.parent,
      };
      setSubUsersFlag(state);
      break;
    case "Clear_User":
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      state = {};
      break;
    case "GET_USER_SIDE_DETAILS_FULFILLED":
      newDetails = action.payload;
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        userDetails: { ...state.userDetails, sidebar_data: newDetails },
      };
      setSubUsersFlag(state);
      localStorage.setItem("MOI_USER_KEY_0_8_0", JSON.stringify(state));
      break;
    case "USER_LIMIT_NUMBERS_UPDATE": {
      const clonedSideBar = state.userDetails?.sidebar_data;
      if (clonedSideBar) {
        switch (action.payload.limit) {
          case "lists":
            clonedSideBar.max_number_of_lists = action.payload.max;
            clonedSideBar.number_of_lists = action.payload.current;
            break;
          case "searches":
            clonedSideBar.max_number_of_searches = action.payload.max;
            clonedSideBar.number_of_searches = action.payload.current;
            break;
          case "segmentation_views":
            clonedSideBar.max_number_of_segmentation_views = action.payload.max;
            clonedSideBar.number_of_segmentation_views = action.payload.current;
            break;
          default:
            break;
        }
      }
      break;
    }
    case "EDIT_USER_COUNTRY": {
      const { country } = action.payload;
      if (action.payload.user_type === "advertiser") {
        // eslint-disable-next-line no-param-reassign
        state = {
          ...state,
          userDetails: {
            ...state.userDetails,
            company_details: {
              ...state.userDetails?.company_details,
              country,
            },
          },
        };
      }
      break;
    }
    case "SET_USER_AGENTS_FULFILLED":
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        agents: action.payload,
      };
      break;
    case "ADD_NEW_AGENT":
      // eslint-disable-next-line no-case-declarations
      let agents: InfluencerAgentInfo[] = [];
      if (state.agents) agents = state.agents;
      agents.unshift(action.payload);
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        agents,
      };
      break;
    case "EDIT_AGENT":
      if (state.agents) {
        const index = state.agents.findIndex(
          (x) => x.agent_id === action.payload.agent_id,
        );
        if (index > -1) {
          // eslint-disable-next-line no-param-reassign
          state.agents[index] = action.payload;
        }
      }

      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
      };
      break;
    case "DELETE_AGENT_FULFILLED":
      if (state.agents) {
        const index = state.agents.findIndex(
          (x) => x.agent_id === action.payload,
        );
        if (index > -1) {
          state.agents.splice(index, 1);
          // eslint-disable-next-line no-param-reassign
          state = {
            ...state,
          };
        }
      }
      break;
    case "SET_USER_TAGS_FULFILLED":
      if (action.payload?.auction_tags) {
        // eslint-disable-next-line no-param-reassign
        state.tags.auction_tags = action.payload.auction_tags;
      }

      if (action.payload?.lists_tags) {
        // eslint-disable-next-line no-param-reassign
        state.tags.lists_tags = action.payload.lists_tags;
      }
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
      };
      break;
    case "PASSWORD_ADDED":
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        userDetails: { ...state.userDetails, using_password_to_login: true },
      };
      break;
    case "SET_ACCEPTED_TC":
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        userDetails: {
          ...state.userDetails,
          sidebar_data: {
            ...state.userDetails?.sidebar_data,
            should_update_toc: false,
          },
        },
      };
      localStorage.setItem("MOI_USER_KEY_0_8_0", JSON.stringify(state));
      break;
    case "SET_SUB_USERS_PERMISSIONS":
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        userDetails: {
          ...state.userDetails,
          sub_user_permission: !state.userDetails?.sub_user_permission,
        },
      };
      setSubUsersFlag(state);
      localStorage.setItem("MOI_USER_KEY_0_8_0", JSON.stringify(state));
      break;
    default:
      break;
  }

  return state;
};
