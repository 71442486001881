import React from "react";
import { Trans, TranslationProps, withTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import { AcademyActions } from "../../../actions/academyActions";
import "react-markdown-editor-lite/lib/index.css";

interface OwnProps {
  getLesson: any;
  chapter_id: number;
  lesson_id: number;
  course_id: number;
  current_course: any;
  courses: any;
  saveLesson: any;
  user: any;
  navigateToQuiz: any;
  navigateToLesson: any;
  completeLesson: any;
  getCourses: any;
  navigateToCourse: any;
}

type Props = OwnProps;

type State = Readonly<{
  lessonData: any;
  editMode: boolean;
  markdown: any;
  title: string;
  canEdit: boolean;
  courseId: number;
  loading: boolean;
  currentLessonId: number;
  language: string;
}>;

class AcademyLessonInner extends React.Component<
  Props,
  State,
  TranslationProps
> {
  mdParser: MarkdownIt;

  mdEditor?: MdEditor = undefined;

  readonly state: State = {
    lessonData: null,
    editMode: false,
    markdown: "",
    canEdit: false,
    title: "",
    courseId: 0,
    loading: true,
    currentLessonId: 1,
    language: "en",
  };

  constructor(props: Props) {
    super(props);
    this.mdParser = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
    });
  }

  init(): void {
    this.getLesson(this.props.chapter_id, this.props.lesson_id);
    this.setState({
      courseId: this.props.current_course.id,
      currentLessonId: Number(this.props.lesson_id),
    });
    this.setLessonLang();
  }

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    if (
      prevProps.current_course !== this.props.current_course &&
      !prevState.lessonData
    ) {
      this.init();
    }
  }

  setLessonLang = () => {
    if (this.props.current_course) {
      this.setState({
        language:
          this.props.current_course &&
          this.props.current_course.subscription_status
            ? this.props.current_course.subscription_status.language
            : "en",
      });
    }
  };

  canEdit = () => {
    return this.props.user?.userDetails?.sidebar_data?.academy_editor;
  };

  // eslint-disable-next-line class-methods-use-this
  handleImageUpload = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (data) => {
        resolve(data.target?.result as any);
      };
      reader.readAsDataURL(file);
    });
  };

  // eslint-disable-next-line class-methods-use-this
  onCustomImageUpload = (): Promise<{ url: string }> => {
    return new Promise((resolve) => {
      const result = window.prompt("Please enter image url here...") as string;
      resolve({ url: result });
    });
  };

  getLesson = (chapterId: number, lessonId: number) => {
    if (!this.props.current_course.all_chapters) {
      return;
    }
    this.props.getLesson(chapterId, lessonId, (data: any) => {
      if (data.data) {
        window.scrollTo(0, 0);
        this.setState({
          currentLessonId: Number(lessonId),
          lessonData: data.data,
          title: data.data.title,
          loading: false,
          canEdit: this.canEdit(),
        });
      } else if (Number(lessonId) === 0) {
        const chap = this.props.current_course.all_chapters.find(
          (c: any) => Number(c.id) === Number(this.props.chapter_id),
        );
        const lessonData = {
          course_id: this.props.course_id,
          title: chap.title,
          video: chap.video_url,
          // video:'https://www.youtube.com/embed/Y7W41VMxyQE', // example video with subtitles in all languages
          is_completed:
            this.props.current_course.current_chapter_status.watched_video,
          markdown_text: "",
        };
        window.scrollTo(0, 0);
        this.setState({
          currentLessonId: Number(lessonId),
          lessonData,
          title: lessonData.title,
          loading: false,
          canEdit: false,
        });
      }
    });
  };

  handleEditorChange = (it: { text: string; html: string }) => {
    this.setState({ markdown: it.text });
  };

  saveChanges = () => {
    this.setState({ loading: true });
    this.props.saveLesson(
      this.props.chapter_id,
      this.props.lesson_id,
      { title: this.state.title, markdown_text: this.state.markdown },
      () => {
        const newState = { ...this.state };
        newState.lessonData.title = this.state.title;
        newState.lessonData.markdown_text = this.state.markdown;
        newState.loading = false;
        this.setState(newState);
      },
    );
  };

  renderEditForm = () => {
    return (
      <>
        <div className="input-row">
          <strong className="label-title">Title</strong>
          <div>
            <input
              className="input-line"
              type="text"
              onChange={(e) => {
                this.setState({ title: e.target.value });
              }}
              value={this.state.title}
            />
          </div>
        </div>
        <MdEditor
          value={this.state.markdown}
          renderHTML={(text) => this.mdParser.render(text)}
          onChange={this.handleEditorChange}
          ref={(node) => {
            this.mdEditor = node || undefined;
          }}
          style={{ height: "500px", width: "100%" }}
          config={{
            view: {
              menu: true,
              md: true,
              html: true,
              fullScreen: true,
              hideMenu: true,
            },
            table: {
              maxRow: 5,
              maxCol: 6,
            },
            imageUrl: "/images/humanz-logo.png",
            syncScrollMode: ["leftFollowRight", "rightFollowLeft"],
          }}
          // onImageUpload={this.handleImageUpload}
          // onFocus={e => console.log('focus', e)}
          // onBlur={e => console.log('blur', e)}
        />
      </>
    );
  };

  getChapterData = () => {
    let courseData = {};
    return courseData;
    const course = this.props.courses.find(
      (c: any) => c.id === this.state.courseId,
    );
    if (course) {
      courseData = {
        current_chapter: course.subscription_status.chapters_completed + 1,
      };
    }
    return courseData;
  };

  prevLesson = () => {
    if (this.state.currentLessonId > 1) {
      this.getLesson(this.props.chapter_id, this.state.currentLessonId - 1);
      this.props.navigateToLesson(
        this.props.chapter_id,
        this.state.currentLessonId - 1,
      );
    } else {
      this.props.navigateToLesson(this.props.chapter_id, 0);
      this.getLesson(this.props.chapter_id, 0);
    }
  };

  nextLesson = () => {
    const currentChapter = this.props.current_course?.current_chapter;
    const chap = this.props.current_course.all_chapters.find(
      (c: any) => c.id === currentChapter.id,
    );
    const lessonId = Number(this.state.currentLessonId);
    const isCurrentChapter =
      Number(currentChapter?.id) === Number(this.props.chapter_id);
    this.props.completeLesson(currentChapter.id, lessonId, () => {
      if (lessonId < chap.number_of_lessons) {
        this.getLesson(currentChapter.id, lessonId + 1);
        this.props.navigateToLesson(this.props.chapter_id, lessonId + 1);
      } else if (isCurrentChapter) {
        this.props.navigateToQuiz(
          currentChapter.id,
          lessonId,
          currentChapter.test_id,
        );
      } else {
        this.props.navigateToCourse(this.state.courseId);
      }
    });
  };

  renderChapterBox = () => {
    // return <> </>;
    if (
      !this.props.current_course ||
      !this.props.current_course?.current_chapter
    )
      return null;
    const currentChapter = this.props.current_course?.current_chapter;
    const isCurrentChapter =
      Number(currentChapter?.id) === Number(this.props.chapter_id);
    const chap = this.props.current_course.all_chapters.find(
      (c: any) =>
        c.id ===
        (isCurrentChapter ? currentChapter.id : Number(this.props.chapter_id)),
    );
    return (
      chap && (
        <div
          className={`chapter-box-wrapper ${
            ["he", "ar"].indexOf(this.state.language) !== -1 ? "rtl" : ""
          }`}
          key={chap.id}
        >
          <div
            className={`chapter-box${
              chap.is_completed ? " completed" : ""
            } available`}
          >
            <div className="top-content">
              <div className="chapterNum">
                <span>
                  <Trans>CHAPTER</Trans>
                </span>
                <span>0{chap.ordinal_number}</span>
              </div>
              <div className="horiz-spacer" />
              <div className="chap-title">
                <strong>{this.state.title}</strong>
              </div>
              <div className="horiz-spacer" />
              <div className="lesson-status">
                {isCurrentChapter && this.state.currentLessonId > 1 ? (
                  <i
                    onClick={this.prevLesson}
                    className="clickable fa fa-chevron-circle-left"
                  />
                ) : (
                  ""
                )}
                <div className="lesson-status-inner">
                  {chap.is_completed ? (
                    <div className="completed">
                      <i className="fas fa-check" />
                    </div>
                  ) : (
                    <>
                      <i className="fas fa-laptop-code" />
                      <div className="current-lesson-state">
                        <span>
                          <Trans>LESSON</Trans> {this.state.currentLessonId}/
                          {chap.number_of_lessons}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {isCurrentChapter &&
                this.state.currentLessonId <= chap.number_of_lessons ? (
                  <i
                    onClick={this.nextLesson}
                    className="clickable fa fa-chevron-circle-right"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  render() {
    const { lessonData, editMode } = this.state;
    let isYoutube = false;
    if (!this.props.current_course || !this.props.current_course.all_chapters)
      return null;
    const currentChapter = this.props.current_course?.current_chapter;
    const chap = this.props.current_course.all_chapters.find(
      (c: any) => c.id === currentChapter.id,
    );
    const isCourseCompleted =
      this.props.current_course?.current_chapter_status?.completed_test;
    const isCurrentChapter =
      Number(currentChapter?.id) === Number(this.props.chapter_id);
    const showQuiz =
      this.state.currentLessonId === chap.number_of_lessons && isCurrentChapter;
    const hasIntro =
      this.props.current_course?.current_chapter_status?.watched_video;
    if (lessonData && lessonData.video) {
      isYoutube = lessonData.video.indexOf("youtube.com") > -1;
    }
    return (
      <>
        <Link className={"top-link"} to={`/academy/${this.state.courseId}`}>
          <i className="fa fa-chevron-left" /> chapters
        </Link>
        {lessonData && (
          <div className="lesson">
            {this.state.loading ? (
              <span>Loading...</span>
            ) : (
              <div className="courses">
                <div
                  className={`single-course-container single-lesson ${
                    ["he", "ar"].indexOf(this.state.language) !== -1
                      ? "rtl"
                      : ""
                  }`}
                >
                  {this.renderChapterBox()}
                  <hr />
                  {lessonData.video && (
                    <div className="header-video">
                      {isYoutube ? (
                        <iframe
                          width="100%"
                          height="500"
                          src={lessonData.video}
                          frameBorder="0"
                          allow="autoplay; encrypted-media"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <ReactPlayer
                          controls={true}
                          width={"100%"}
                          url={lessonData.video}
                        />
                      )}
                    </div>
                  )}
                  <div className="lesson-text">
                    {this.state.canEdit && (
                      <div className="edit-row">
                        <button
                          className="btn btn-gray edit_lesson"
                          onClick={() => {
                            this.setState({
                              editMode: !this.state.editMode,
                              markdown: lessonData.markdown_text,
                            });
                          }}
                        >
                          {this.state.editMode ? "Cancel edit" : "Edit Lesson"}
                        </button>
                        {this.state.editMode && (
                          <button
                            style={{ float: "right" }}
                            className="btn btn-success"
                            onClick={this.saveChanges}
                          >
                            Save
                          </button>
                        )}
                      </div>
                    )}

                    {editMode ? (
                      this.renderEditForm()
                    ) : (
                      <ReactMarkdown allowDangerousHtml={true}>
                        {lessonData.markdown_text}
                      </ReactMarkdown>
                    )}
                  </div>
                  <hr />
                  <div className="text-center">
                    {hasIntro && this.state.currentLessonId === 1 && (
                      <button
                        onClick={this.prevLesson}
                        className="clickable btn btn-primary"
                      >
                        <span>
                          <i className="fa fa-chevron-left" />
                          <Trans> Watch intro</Trans>
                        </span>
                      </button>
                    )}

                    {this.renderButton(
                      showQuiz,
                      isCourseCompleted,
                      isCurrentChapter,
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  private renderButton(
    showQuiz: boolean,
    isCourseCompleted: any,
    isCurrentChapter: boolean,
  ): React.ReactNode {
    if (showQuiz) {
      return (
        <>
          {!isCourseCompleted && isCurrentChapter && (
            <button
              onClick={this.nextLesson}
              className="clickable btn btn-primary"
            >
              <i className="fa fa-wpforms" /> <Trans>Take quiz</Trans>
            </button>
          )}
        </>
      );
    }
    if (isCurrentChapter) {
      return (
        <button onClick={this.nextLesson} className="clickable btn btn-primary">
          <span>
            <Trans>Next lesson</Trans>
            <i className="fa fa-chevron-right" />
          </span>
        </button>
      );
    }
    return (
      <button
        onClick={() => this.props.navigateToCourse(this.state.courseId)}
        className="clickable btn btn-primary"
      >
        <span>
          <i className="fa fa-chevron-left" />
        </span>{" "}
        <Trans>Back to course</Trans>
      </button>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    current_course: state.academyReducer.current_course,
    courses: state.academyReducer.courses,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLesson: (
      chapter_id: number,
      lesson_ordinal_num: number,
      callback: any,
    ) => {
      dispatch(
        AcademyActions.getLesson(chapter_id, lesson_ordinal_num, callback),
      );
    },
    completeLesson: (
      chapter_id: number,
      lesson_ordinal_num: number,
      callback: any,
    ) => {
      dispatch(
        AcademyActions.completeLesson(chapter_id, lesson_ordinal_num, callback),
      );
    },
    saveLesson: (
      chapter_id: number,
      lesson_ordinal_num: number,
      data: any,
      callback: any,
    ) => {
      dispatch(
        AcademyActions.saveLesson(
          chapter_id,
          lesson_ordinal_num,
          data,
          callback,
        ),
      );
    },
  };
};
export const AcademyLesson = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AcademyLessonInner));
