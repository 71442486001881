import moment from "moment";
import { useContext } from "react";
import { RoiContext } from "./RoiContext";
import FilterRoiButton from "./RoiFilterButton";
import ShareRoiButton from "./RoiShareButton";

const ShareNotesButton = () => {
  const { openNotes, notesSummary } = useContext(RoiContext);
  const seen = notesSummary[-1]?.seen !== false;
  return (
    <div
      aria-label="Notes"
      style={{
        color: "white",
        background: "var(--main-baby-blue-color)",
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        width: 30,
        borderRadius: 20,
        marginLeft: 10,
      }}
      onClick={() => openNotes(-1)}
      className="small-title"
      id="share-notes"
    >
      <i
        style={{
          textTransform: "none",
          fontSize: 15,
          marginTop: -2,
          marginLeft: 1,
        }}
        className="fa fa-comment"
      />

      {!seen && (
        <div
          style={{
            background: "red",
            width: 11,
            height: 11,
            borderRadius: "50%",
            position: "absolute",
            marginTop: -5,
            marginLeft: -110,
          }}
        />
      )}
    </div>
  );
};

const RoiHeader = ({ extraButton }: any) => {
  const {
    summaryData,
    filters,
    shared,
    fromCampaign,
    showFilters,
    allowFilters,
    notesSummary,
    shareReport,
  } = useContext(RoiContext);

  const from = filters?.time_filter?.from
    ? moment(filters.time_filter.from).format("LL")
    : null;
  const to = filters?.time_filter?.to
    ? moment(filters.time_filter.to).format("LL")
    : null;

  return (
    <div className="roi-title">
      <div
        className="blurable-large"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {summaryData.campaign_details.title}
        <div
          style={{
            marginLeft: "10px",
            fontSize: "18px",
            fontWeight: 400,
            color: "#808080",
          }}
        >
          {from}
          {from && to ? " - " : null}
          {to}
        </div>
      </div>

      <div>
        {shared ? (
          <>
            {fromCampaign ? <ShareNotesButton /> : null}
            {fromCampaign ? (
              <FilterRoiButton
                showFilters={showFilters}
                allowFilters={allowFilters}
              />
            ) : null}
          </>
        ) : (
          <>
            <FilterRoiButton
              showFilters={showFilters}
              allowFilters={allowFilters}
            />
            {notesSummary.shared_guid && fromCampaign ? (
              <ShareNotesButton />
            ) : null}
          </>
        )}
        <ShareRoiButton
          shareMode={shared}
          summaryData={summaryData}
          shareReport={shareReport}
        />
        {extraButton}
      </div>
    </div>
  );
};

export default RoiHeader;
