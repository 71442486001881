import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuctionGoals } from "src/api/actions/auctionActions";
import { Target } from "src/api/types";
import CampaignTargetsModal from "./CampaignTargetsModal";

type Props = {
  influencer: any;
  refreshBoard: any;
  board: any;
  editMode: boolean;
  onClose: any;
  selectedMonth?: string;
};

const CampaignTargetsModalRenderer = ({
  influencer,
  refreshBoard,
  board,
  editMode,
  onClose,
  selectedMonth,
}: Props) => {
  const params = useParams();

  // States
  const [targetList, setTargetList] = useState<Target[]>();
  const [targetsLoading, setTargetsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(!!influencer);

  const handleModalOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen && params.id) {
      handleGetAuctionGoals(Number(board?.board_id), influencer.influencer_id);
    }
    if (!isOpen) {
      onClose();
    }
  }, [isOpen]);

  const changeMonth = (_month: string) => {
    setTargetsLoading(true);
    refreshBoard(_month, () => {
      setTargetsLoading(false);
    });
  };

  const handleGetAuctionGoals = (auctionId: number, influencerId: number) => {
    setTargetsLoading(true);
    return getAuctionGoals(auctionId, {
      influencer_id: influencerId,
      month_relevance: board?.one_month_campaign
        ? undefined
        : selectedMonth || board?.current_manage_month,
    })
      .then((res) => {
        setTargetList(res.data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setTargetsLoading(false);
      });
  };

  return (
    board?.board_id && (
      <>
        {" "}
        <CampaignTargetsModal
          selectedMonth={selectedMonth || board?.current_manage_month}
          influencer={influencer}
          isOpen={isOpen}
          isCreate={editMode}
          setIsOpen={handleModalOpen}
          board={board}
          targetList={editMode ? undefined : targetList}
          handleMonthChange={(month: string) => changeMonth(month)}
          loading={targetsLoading}
          setIsLoading={setTargetsLoading}
        />
      </>
    )
  );
};

export default CampaignTargetsModalRenderer;
