import { Box } from "@chakra-ui/react";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";

const TemplateButton = (props: any) => {
  const isDisable =
    props.template.is_global && props.template.created_by !== props.userId;
  const handleClickOnTemplate = () => {
    props.setTemplateTasks(props.template.tasks);
    props.setHeader(props.template.title);
    props.setTemplate(props.template);

    const handleMoveToTemplate = () => {
      props.setTemplateId(props.template.id);
      props.setStep("start-from-template");
    };
    handleMoveToTemplate();
  };
  const DeleteAction = [
    {
      action: () => {
        props.handleDeleteTemplate(props.template.id);
      },
      text: "Delete",
      icon: "fa-thin fa-trash-can",
    },
  ];

  return (
    <Box
      onClick={handleClickOnTemplate}
      sx={{
        display: "flex",
        padding: "16px 0px 14px 12px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "4px",
        background: "var(--white, #FFF)",
        boxShadow:
          "-5px 5px 17px 1px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
        color: "black",
        marginBottom: "10px",
        width: "100%",
        transition: "background-color 0.3s",
        "&:hover": {
          background: "#E7F3FB",
          color: "#249FF0",
          textDecoration: "none",
        },
        fontWeight: 400,
        cursor: "pointer",
        maxHeight: "60px",
      }}
    >
      <span style={{ color: "#249FF0" }}>{props.template.title}</span>
      {!isDisable && (
        <HumanzActionsMenu
          buttontype="dots"
          actions={DeleteAction}
          buttontext={"delete"}
        />
      )}
    </Box>
  );
};

export default TemplateButton;
