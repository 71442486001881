// TODO - lint
/* eslint-disable react/no-deprecated, class-methods-use-this, no-useless-escape, camelcase, no-unused-vars, camelcase, camelcase, no-plusplus, camelcase, camelcase, camelcase, camelcase, camelcase, react/no-direct-mutation-state, camelcase, react/no-direct-mutation-state, camelcase, camelcase, camelcase, react/no-direct-mutation-state, camelcase, camelcase, camelcase, react/no-direct-mutation-state, camelcase, react/no-direct-mutation-state, camelcase, react/no-direct-mutation-state, camelcase, class-methods-use-this, consistent-return, camelcase, default-case, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, react/no-direct-mutation-state, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, no-unused-vars, camelcase, camelcase, camelcase, camelcase, camelcase, react/no-unescaped-entities, consistent-return, no-unused-vars, import/no-default-export */
import React from "react";
import Dialog from "rc-dialog";
import { connect } from "react-redux";
import AmbassadorIcons from "./ambassadorIcons";

class AmbassadorAddOrEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      temp_ambs_instagram: "",
      temp_ambs_twitter: "",
      temp_ambs_youtube: "",
      temp_ambs_tiktok: "",
      errorFields: [],
      newInfluencer: [],
      tmpName: "",
      validData: {
        ambs_instagram: null,
        ambs_twitter: null,
        ambs_youtube: null,
        ambs_tiktok: null,
      },
      isLoading: {
        ambs_instagram: false,
        ambs_twitter: false,
        ambs_youtube: false,
        ambs_tiktok: false,
      },
      group_id: 0,
      enforceValidations: true,
      nameWasChanged: false,
    };
  }

  componentWillMount() {
    setTimeout(() => {
      this.updateAmbassadorData();
    }, 100);
  }

  updateAmbassadorData() {
    this.setState({ errorFields: [] });
    if (this.props.ambassador) {
      // An existing influencer in the array - updating
      const newState = { ...this.state };

      newState.temp_ambs_instagram = this.props.ambassador.instagram_username;
      newState.temp_ambs_twitter = this.props.ambassador.twitter_username;
      newState.temp_ambs_youtube = this.props.ambassador.youtube_username;
      newState.temp_ambs_tiktok = this.props.ambassador.tiktok_username;
      newState.validData.ambs_instagram =
        !!this.props.ambassador.instagram_username || null;
      newState.validData.ambs_twitter =
        !!this.props.ambassador.twitter_username || null;
      newState.validData.ambs_youtube =
        !!this.props.ambassador.youtube_username || null;
      newState.validData.ambs_tiktok =
        !!this.props.ambassador.twittetiktok_username || null;
      newState.tmpName = this.props.ambassador.name
        ? this.props.ambassador.name
        : newState.tmpName;
      newState.group_id = this.props.ambassador.id
        ? this.props.ambassador.id
        : this.props.ambassador.group_id;

      this.setState(newState);
    } else {
      // A new influencer
      this.setState({
        temp_ambs_instagram: "",
        temp_ambs_twitter: "",
        temp_ambs_youtube: "",
        temp_ambs_tiktok: "",
        tmpName: "",
        group_id: this.props.groupId,
      });
    }
  }

  extractUsernameFromPotentialLink(link) {
    let profileName = link || "";
    const profileLink = profileName.match(/(?:https?\:\/\/(?:www\.)?.*)\/(.*)/);
    if (profileLink && profileLink[1]) {
      profileName = profileLink[1].replace(/\//g, "");
    }
    if (profileName.charAt(0) === "@") {
      profileName = profileName.substr(1);
    }
    return profileName;
  }

  searchProfile = (profileName, social_network) => {
    return new Promise((resolve, reject) => {
      this.setState({ searchLoading: true });
      this.props.getUserProfile(profileName, social_network, (res) => {
        this.setState({ searchLoading: false });
        if (res.data) {
          const newState = { ...this.state };
          newState.validData[`ambs_${social_network}`] = true;
          newState.nameWasChanged = false;
          this.setState(newState);
          resolve(res.data);
        } else {
          this.addOrRemoveError(
            `social_username_error_${social_network}`,
            "add",
          );
          resolve(false);
        }
      });
    });
  };

  oneOfValidation() {
    const accounts = [
      this.state.temp_ambs_instagram,
      this.state.temp_ambs_tiktok,
      this.state.temp_ambs_youtube,
      this.state.temp_ambs_twitter,
    ];
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i] && accounts[i].length) return true;
    }
    return false;
  }

  removeInfluencerBySocialNetwork(socialNetwork) {
    const newInfluencerArray = [];
    this.state.newInfluencer.forEach((inf) => {
      if (socialNetwork) {
        if (inf.social_network !== socialNetwork) {
          newInfluencerArray.push(inf);
        }
      }
    });
    this.setState({ newInfluencer: newInfluencerArray });
    return newInfluencerArray;
  }

  deleteInfluencerBySocialNetwork(socialNetwork) {
    const newState = { ...this.state };
    newState.newInfluencer = {
      username: this.state[`temp_ambs_${socialNetwork}`],
      social_network: socialNetwork,
      group_id: this.state.group_id,
      name: this.state.tmpName,
      deleted: true,
    };
    newState.enforceValidations = false;
    this.setState(newState);
    this.props.updateNewInfluencers(newState.newInfluencer);
    this.props.handleClose();
  }

  addNewAmbsUsername = (influencer_name, social_network) => {
    this.addOrRemoveError(`social_username_error_${social_network}`, "remove");
    if (!influencer_name || !influencer_name.length) {
      this.state.isLoading[`ambs_${social_network}`] = false;
      this.setState({ updated: true });
      return;
    }
    this.state.isLoading[`ambs_${social_network}`] = true;
    this.setState({});
    this.searchProfile(influencer_name, social_network).then((res) => {
      if (
        res &&
        !this.state.newInfluencer.filter((i) => {
          return (
            i.username === influencer_name &&
            i.social_network === social_network
          );
        }).length
      ) {
        if (!this.props.ambassador && this.state.tmpName === "") {
          this.state.tmpName = this.state[`temp_ambs_${social_network}`];
        }
        this.state.newInfluencer.push({
          social_network,
          name: this.state.tmpName,
          username: this.state[`temp_ambs_${social_network}`],
          group_id: this.state.group_id,
          profile_picture: res.data ? res.data.profile_picture : null,
          new: this.props.ambassador ? !!this.props.ambassador.new : true,
        });
        this.state.validData[`ambs_${social_network}`] = true;
      } else {
        this.state.validData[`ambs_${social_network}`] = false;
      }
      this.addOrRemoveError(
        "one_of_influencers",
        this.oneOfValidation() ? "remove" : "add",
      );
      this.state.isLoading[`ambs_${social_network}`] = false;
      this.setState({ updated: true });
    });
  };

  addOrRemoveError(name, type) {
    const { errorFields } = this.state;
    if (type === "add") {
      if (!errorFields.includes(name)) errorFields.push(name);
    } else if (errorFields.includes(name)) {
      errorFields.addOrRemove(name);
    }
    this.setState({ errorFields });
    this.setState({ updated: true });
  }

  renderIcon(social_network) {
    switch (social_network) {
      case "instagram":
        return <i className="fa fa-instagram" />;
      case "twitter":
        return <i className="fa fa-twitter" />;
      case "youtube":
        return <i className="fa fa-youtube-play" />;
      case "tiktok":
        return <i className="fa-brands fa-tiktok" />;
    }
  }

  triggerSearch(social_network, event, value) {
    const val = value || event.target.value;
    this.addOrRemoveError(`social_username_error_${social_network}`, "remove");
    this.addNewAmbsUsername(val, social_network);
  }

  renderSocialSearchField(social_network) {
    const social_network_state = `temp_ambs_${social_network}`;
    return (
      <div className="input-row">
        <div className="flex">
          <div className="">{this.renderIcon(social_network)}</div>
          <div className="flex2">
            <strong className="label-title">Ambassador username</strong>
            <div className="blurable-large" style={{ width: 260 }}>
              <input
                placeholder={"@username"}
                // onBlur={(event => {
                //     this.triggerSearch(social_network, event);
                // })}
                onKeyUp={() => {
                  this.state.validData[`ambs_${social_network}`] = null;
                  this.setState({ nameWasChanged: true });
                }}
                className={`input-line pull-left ${
                  this.state.errorFields.includes(
                    `social_username_error_${social_network}`,
                  ) && "input-error"
                }`}
                style={{ width: 169 }}
                type="text"
                value={this.state[social_network_state] || ""}
                onChange={(e) => {
                  const newState = {};
                  newState[social_network_state] =
                    this.extractUsernameFromPotentialLink(e.target.value);
                  this.setState(newState);
                }}
              />
              {this.state[social_network_state] && (
                <div className="influencer-status">
                  {this.state.validData[`ambs_${social_network}`] === true && (
                    <div className="fa fa-check-circle with-color" />
                  )}
                </div>
              )}

              {this.state.nameWasChanged &&
                this.state.validData[`ambs_${social_network}`] !== true &&
                this.state[social_network_state] && (
                  <button
                    className={`btn ${
                      this.state.validData[`ambs_${social_network}`] === null
                        ? "btn-success animated pulse search-amb-user"
                        : ""
                    }`}
                    onClick={(event) => {
                      this.triggerSearch(
                        social_network,
                        null,
                        this.state[social_network_state],
                      );
                    }}
                  >
                    <i className="fa fa-search" />
                  </button>
                )}

              {this.props.ambassador &&
                this.state[social_network_state] &&
                this.state[social_network_state].length && (
                  <div
                    className={"btn"}
                    onClick={() =>
                      this.deleteInfluencerBySocialNetwork(social_network)
                    }
                  >
                    {" "}
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                )}
              {this.state.isLoading[`ambs_${social_network}`] && (
                <span>Checking username...</span>
              )}
              {this.state.errorFields.includes(
                `social_username_error_${social_network}`,
              ) && (
                <div>
                  <i className="error-mark" />
                  <span className="error-text">
                    Whoops! User doesn't exists
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        className="add-ambassador-dialog message-dialog"
        visible={this.props.show}
        style={{}}
        animation="slide-fade"
        width={600}
        destroyOnClose={true}
        onClose={this.props.handleClose}
        maskAnimation="fade"
      >
        <div
          style={{
            height: 120,
            paddingTop: 20,
            background: "var(--main-baby-blue-color)",
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
          }}
        >
          <div className={"message-dialog-icon "}>
            {AmbassadorIcons.small(50, 50)}
          </div>
        </div>
        <div className="inner-container">
          <div className="title">
            {this.props.ambassador ? "Edit " : "Add new "}ambassador
          </div>
          <p>
            Name your ambassador and link the social accounts. You can paste the
            social profile link or username in the relevant social network field
          </p>
          <div className="name ambassador_properties">
            {this.props.ambassador ? (
              <strong>{this.props.ambassador.name}</strong>
            ) : (
              <input
                placeholder={"Ambassador name"}
                onChange={(e) => this.setState({ tmpName: e.target.value })}
                className={"input-line"}
                disabled={this.props.ambassador}
                onKeyUp={() => {
                  if (!this.props.ambassador) {
                    this.addOrRemoveError(
                      "name_your_ambassador",
                      !this.state.tmpName ||
                        !this.state.tmpName.length ||
                        this.state.tmpName.replace(/ /g, "").length === 0
                        ? "add"
                        : "remove",
                    );
                  }
                }}
                style={{ width: 169 }}
                type="text"
                value={this.state.tmpName}
              />
            )}
            {this.state.errorFields.includes("name_your_ambassador") && (
              <div>
                <i className="error-mark" />
                <span className="error-text">Please name your ambassador</span>
              </div>
            )}
          </div>
          <div style={{ marginTop: 30, justifyContent: "center" }}>
            {this.renderSocialSearchField("instagram")}
            {this.renderSocialSearchField("twitter")}

            <div className="clearfix"></div>

            {this.state.errorFields.includes("one_of_influencers") && (
              <div>
                <i className="error-mark" />
                <span className="error-text">
                  Please add at least one social media account to an ambassador
                </span>
              </div>
            )}
          </div>
          <div className="create-buttons-row">
            <button className="btn btn-primry" onClick={this.props.handleClose}>
              Cancel
            </button>
            <button
              className={`btn btn-primry ${
                this.state.errorFields.length ? "disabled" : ""
              }`}
              disabled={
                (this.state.enforceValidations &&
                  !this.props.ambassador &&
                  !this.state.tmpName.length) ||
                this.state.searchLoading ||
                this.state.nameWasChanged ||
                (this.state.errorFields
                  ? this.state.errorFields.length > 0
                  : false)
              }
              onClick={() => {
                if (!this.oneOfValidation()) {
                  this.addOrRemoveError("one_of_influencers", "add");
                  return false;
                }
                if (!this.state.tmpName || !this.state.tmpName.length) {
                  this.state.errorFields.push("name_your_ambassador");
                  return false;
                }

                this.props.updateNewInfluencers(this.state.newInfluencer);

                this.props.handleClose();
              }}
            >
              {this.props.ambassador ? "Approve changes" : "Add Ambassador"}
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(AmbassadorAddOrEditDialog);
