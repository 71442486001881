import React from "react";
import Dialog from "rc-dialog";

interface OwnProps {
  isLoading: boolean;
  handleClose: any;
  handleAccept: any;
  alertTitle: string;
  alertMessage: string;
  buttonAccept?: string;
  buttonCancel?: string;
}

export const PhaseAlert: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Dialog
      onClose={props.handleClose}
      className="share-dialog"
      visible={true}
      style={{ marginTop: 160 }}
      animation="slide-fade"
      maskAnimation="fade"
    >
      <div>
        <div className="edit-tasks-header">{props.alertTitle}</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: 300,
            marginTop: 20,
            minHeight: 95,
          }}
        >
          {props.alertMessage}
        </div>
        {props.isLoading ? (
          <div
            className="spinner small"
            style={{ marginTop: 40, marginBottom: 0 }}
          >
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        ) : (
          <></>
        )}
        <div className="footer-buttons center">
          <button
            style={{ marginLeft: 10 }}
            className={`attach-links-btn share-save-btn btn btn-primary ${
              props.isLoading && "disabled"
            }`}
            onClick={props.handleAccept}
          >
            <span>{props.buttonAccept || "Accept"}</span>
          </button>
          <button
            style={{ marginLeft: 10 }}
            className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
              props.isLoading && "disabled"
            }`}
            onClick={props.handleClose}
          >
            <span>{props.buttonCancel || "Cancel"}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};
