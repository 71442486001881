import { type ComponentSingleStyleConfig } from "@chakra-ui/react";

export const TooltipTheme: ComponentSingleStyleConfig = {
  baseStyle: {
    py: 2,
    px: 3,
    borderRadius: "md",
    bg: "#373737",
  },
};
