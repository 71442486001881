// TODO - lint
/* eslint-disable camelcase, camelcase, camelcase, no-nested-ternary, class-methods-use-this, consistent-return, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, react/no-unknown-property, import/no-default-export */
import React, { Component } from "react";
import Slider from "react-slick/lib";
import YouTube from "react-youtube";
import { TwitterTweetEmbed } from "react-twitter-embed";
import NoImageFound from "../../../images/no-image.png";

export class InfluContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content_open: props.searchMode || false,
    };
  }

  renderInfluencersSlides(influencer) {
    const influencer_posts =
      influencer && influencer.posts ? influencer.posts : null;
    return (
      influencer_posts && (
        <div style={{ position: "relative" }} className={"influencer_slider"}>
          <div className="influencers-content-container">
            <div className="rec-slider-full-width">
              <Slider
                isInfinite={false}
                delay={0}
                slidesToShow={
                  influencer.posts.length > 2 ? 3 : influencer.posts.length
                }
                slidesToScroll={3}
              >
                {influencer_posts.map((post, key) => {
                  return (
                    <div
                      className="influ-post-cube"
                      key={key}
                      style={{ height: 535 }}
                    >
                      {this.props.searchMode ? (
                        <button
                          onClick={() =>
                            this.props.attachToInfInList(influencer.id, post)
                          }
                          style={{ maxWidth: 290 }}
                          className={`btn btn-primary ${
                            post.exists ? " delete " : ""
                          }${post.saving ? "disabled" : ""}`}
                        >
                          {post.exists
                            ? "Remove post from the list"
                            : "Add post to the list"}
                        </button>
                      ) : this.props.shared ? null : (
                        <button
                          onClick={() =>
                            this.props.attachToInfInList(influencer.id, post)
                          }
                          style={{
                            width: 40,
                            marginBottom: 10,
                            alignSelf: "center",
                          }}
                          className={`btn btn-primary delete${
                            post.saving ? "disabled" : ""
                          }`}
                        >
                          <i
                            style={{ fontSize: 16 }}
                            className="far fa-trash-alt"
                          />
                        </button>
                      )}

                      <div className="embedded_post">
                        {this.renderPost(post, key)}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      )
    );
  }

  renderPost = (post) => {
    const { post_type } = post;

    if (post_type === "ig_story") {
      return (
        <div
          className="blurable-frame"
          style={{
            display: "inline-block",
            width: 290,
            height: 535,
            overflow: "hidden",
          }}
          key={post}
        >
          {post.media_id ? (
            <img alt="" className="blurable" src={post.media_id} />
          ) : (
            <img alt="" className="no-image" src={NoImageFound} />
          )}
        </div>
      );
    }
    if (post_type === "ig_post") {
      return (
        <div
          className="blurable-frame"
          style={{
            display: "inline-block",
            width: 290,
            height: 535,
            overflow: "hidden",
          }}
          key={post}
        >
          <iframe
            title="Instagram"
            src={`https://www.instagram.com/p/${post.media_id}/embed/captioned`}
            width="288"
            height="535"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      );
    }
    if (post_type === "tw_tweet") {
      return (
        <div
          className="blurable-frame"
          style={{
            display: "inline-block",
            width: 290,
            height: 535,
            overflow: "hidden",
          }}
          key={post}
        >
          <TwitterTweetEmbed tweetId={post.media_id} />
        </div>
      );
    }
    if (post_type === "yt_video") {
      return (
        <div
          className="blurable-frame"
          style={{
            display: "inline-block",
            width: 350,
            height: 250,
            overflow: "hidden",
          }}
          key={post}
        >
          <YouTube
            videoId={post.media_id}
            opts={{ width: "350", height: 250 }}
          />
        </div>
      );
    }
    if (post_type === "tk_video") {
      return (
        <div style={{ overflow: "hidden", width: 295 }}>
          <iframe
            title="TikTok"
            width={"265"}
            height={"535"}
            src={`https://www.tiktok.com/embed/${post.media_id}`}
            frameborder={"0"}
            allow={
              "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            }
          ></iframe>
        </div>
      );
    }
  };

  render() {
    const { searchMode } = this.props;
    return this.props.influencer &&
      this.props.influencer.posts &&
      this.props.influencer.posts.length ? (
      <>
        <div
          className={`open-influe-content ${
            this.state.content_open ? "open" : ""
          }`}
        >
          {!searchMode && (
            <div
              className={"influe-content-trigger"}
              onClick={() => {
                this.setState({ content_open: !this.state.content_open });
              }}
            >
              <i
                className={`fas ${
                  this.state.content_open
                    ? "fa-chevron-circle-up"
                    : "fa-chevron-circle-down"
                }`}
              />
              {this.state.content_open
                ? " Hide reference content"
                : " Show reference content"}
            </div>
          )}
          {this.state.content_open && (
            <div className="influ-content-slide">
              {this.renderInfluencersSlides(this.props.influencer)}
            </div>
          )}
        </div>
      </>
    ) : null;
  }
}
