import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";

const TaskTag = (props: any) => {
  const { taskData, setTaskData } = props;
  let widthValue;
  let boxColor;

  switch (taskData.tag) {
    case "General":
      widthValue = "95px"; // Set the desired width for 'General'
      boxColor = "var(--grey-2, #EBEBEB)";
      break;
    case "Brief":
      widthValue = "70px"; // Set the desired width for 'Brief'
      boxColor = "rgba(252, 199, 141, 0.60)";
      break;
    case "List":
      widthValue = "62px"; // Set the desired width for 'List'
      boxColor = "rgba(186, 153, 233, 0.50)";
      break;
    case "Manage":
      widthValue = "98px";
      boxColor = "rgba(142, 200, 255, 0.50)";
      break;
    case "ROI":
      widthValue = "63px";
      boxColor = "rgba(172, 252, 141, 0.60)";
      break;
    default:
      widthValue = "100px"; // Default width in case the taskType is not one of the specified cases
      boxColor = "grey";
  }
  const boxStyle = {
    backgroundColor: boxColor,
    borderRadius: "24px",
    justifyContent: "center",
    border: "1px solid #CFCFCF",
    width: widthValue,
    paddingLeft: "8px",
    paddingRight: "5px",
    paddingBottom: "1px",
    fontSize: "14px",
  };
  const TypesActions = [
    {
      action: () => {
        setTaskData({ ...taskData, tag: "General" });
      },
      text: "General",
    },
    {
      action: () => {
        setTaskData({ ...taskData, tag: "Brief" });
      },
      text: "Brief",
    },
    {
      action: () => {
        setTaskData({ ...taskData, tag: "List" });
      },
      text: "List",
    },
    {
      action: () => {
        setTaskData({ ...taskData, tag: "Manage" });
      },
      text: "Manage",
    },

    {
      action: () => {
        setTaskData({ ...taskData, tag: "ROI" });
      },
      text: "ROI",
    },
  ];
  return (
    <>
      <div style={boxStyle}>
        <HumanzActionsMenu
          buttontype="button"
          actions={TypesActions}
          buttontext={taskData.tag}
          size={"20px"}
        />
      </div>
    </>
  );
};

export default TaskTag;
