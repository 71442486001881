import { Box } from "@chakra-ui/react";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import ThumbUp from "../Icons/ThumbUp.png";

const AllDoneGoodJob = (props: any) => {
  const { todoState, tasks } = props;
  const allDone =
    tasks.every((task: AuctionToDoItem) => task.completed === true) &&
    tasks.length > 0;
  if (todoState === "ToDo" && allDone) {
    return (
      <>
        {!props.showAddTask && (
          <Box
            style={{
              textAlign: "center",
              color: "var(--dark-grey, #808080)",
              fontWeight: 500,
            }}
          >
            <img src={ThumbUp} alt="Description" />
            <div style={{ fontSize: "20px", marginBottom: "10px" }}>
              All Tasks complete
            </div>
            <div style={{ fontSize: "12px", color: "var(--grey, #CFCFCF)" }}>
              Nice Work!
            </div>
          </Box>
        )}
      </>
    );
  }

  return null;
};

export default AllDoneGoodJob;
