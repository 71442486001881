// TODO - lint
/* eslint-disable @typescript-eslint/ban-types, class-methods-use-this, class-methods-use-this, camelcase, camelcase, camelcase, camelcase, camelcase, import/no-default-export, @typescript-eslint/naming-convention */
import {
  Avatar,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteInvoice } from "src/api/actions/auctionActions";
import { get12Months } from "src/global";
import uuid4 from "uuid";
import { attachInvoice } from "../../../../actions/auctionsActions";
import { saveShareInvoice } from "../../../../api/actions/shareActions/auctionActions";
import { FileTypes, FileUpload } from "../../../../ui/FileUpload";
import { FormFooterButtonGroup } from "../../../../ui/FormFooterButtonGroup";
import InvoicesList from "../CampaignInfluencerPrice/InvoicesList";

class UploadInvoiceDialog extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      progress: 0,
      month: "all",
      invoices: this.props?.invoices,
    };
  }

  months = get12Months();

  getUploadType = (type?: any) => {
    switch (type) {
      case type.indexOf("pdf"):
        return "pdf";
      case type.indexOf("doc"):
      case type.indexOf("docx"):
        return "doc";
      case type.indexOf("image"):
        return "image";
      default:
        return "pdf";
    }
  };

  handleFileUpload = (fileUrl: string) => {
    const { auction_id, influencer_id, month } = this.props;
    const id = uuid4();
    this.setState({
      invoice_uploaded: fileUrl,
      progress: 0,
    });
    const monthToSend = month?.split("T")[0];
    if (this.props.shareMode) {
      saveShareInvoice({
        auction_id,
        influencer_id,
        file_url: fileUrl,
        month: monthToSend,
      })
        .then((res) => {
          if (res) {
            this.props.callback(id, fileUrl);
          } else {
            this.props.onClose();
          }
        })
        .catch(() => {
          this.props.onClose();
        });
    } else {
      this.props.attachInvoice(
        this.props.auction_id,
        this.props.influencer_id,
        fileUrl,
        monthToSend,
        () => {
          this.props.callback(id, fileUrl);
        },
      );
      this.props.onClose();
    }
  };

  handleDeleteClick = (auction_id: number, invoiceId: number) => {
    if (invoiceId) {
      deleteInvoice(auction_id, { invoice_id: invoiceId })
        .then(() => {
          this.setState((prevState: any) => {
            const updatedInvoices = [...prevState.invoices];
            const indexToRemove = updatedInvoices.findIndex(
              (i) => i.id === invoiceId,
            );

            if (indexToRemove !== -1) {
              updatedInvoices.splice(indexToRemove, 1);
              this.props.invoices.splice(indexToRemove, 1);
            }

            return { invoices: updatedInvoices };
          });

          toast.success("Deleted invoice successfully");
        })
        .catch(() => {
          toast.error("Couldn't delete invoice");
        })
        .finally(() => {});
    } else {
      deleteInvoice(auction_id)
        .then(() => {
          toast.success("Deleted invoice successfully");
        })
        .catch(() => {
          toast.error("Couldn't delete invoice");
        });
    }
  };

  render() {
    const disableUpload = this.props.invoices?.length > 5;
    return (
      <Modal
        size={"2xl"}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader backgroundColor="#F1F1F1" color={"#808080"} p={4}>
            <Flex gap={10} justifyContent={"space-between"}>
              <Flex alignItems={"center"} gap={3}>
                <Avatar src={this.props.image_url} />
                <Box>
                  <Text fontWeight={400} color={"#333"}>
                    {this.props.influencer_name}
                  </Text>
                  <Text fontWeight={400}>Invoices</Text>
                </Box>
              </Flex>
              {window.pixel_enabled && (
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      marginRight: 30,
                      marginTop: 8,
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                        fontSize: 16,
                        fontWeight: 600,
                        marginRight: 8,
                      }}
                    >
                      {moment(this.props.month).utc().format("MMMM YYYY")}
                    </span>
                    <span
                      style={{ color: "#08080", fontSize: 16, fontWeight: 500 }}
                    >
                      invoices
                    </span>
                  </Box>
                </Box>
              )}
            </Flex>
          </ModalHeader>
          <ModalCloseButton color="#808080" />
          <ModalBody pt={6}>
            {!this.state.loading && (
              <div className="input-row">
                <>
                  {disableUpload ? (
                    <Tooltip
                      label="Can't upload more than 6 invoices"
                      placement="top"
                      hasArrow
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          height: "230px",
                        }}
                      >
                        <FileUpload
                          fileType={[FileTypes.image, FileTypes.document]}
                          uploadPath={
                            this.props.shareMode ? "share-invoice" : "invoice"
                          }
                          shareMode={true}
                          onFileUploaded={this.handleFileUpload}
                          styleImg={{
                            background: "rgba(235, 235, 235, 0.60)",
                            border: "3px dashed var(--grey, #CFCFCF)",
                          }}
                          disabled={disableUpload}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        height: "230px",
                      }}
                    >
                      <FileUpload
                        fileType={[FileTypes.image, FileTypes.document]}
                        uploadPath={
                          this.props.shareMode ? "share-invoice" : "invoice"
                        }
                        shareMode={true}
                        onFileUploaded={this.handleFileUpload}
                        styleImg={{
                          background: "rgba(235, 235, 235, 0.60)",
                          border: "3px dashed var(--grey, #CFCFCF)",
                        }}
                        disabled={disableUpload}
                      />
                    </div>
                  )}
                </>
                <Box marginTop={2}>
                  <InvoicesList
                    invoices={this.state.invoices}
                    paymentTime={this.props.paymentTime}
                    isOpen={true}
                    auction_id={this.props.auction_id}
                    handleDeleteClick={this.handleDeleteClick}
                    influencerData={this.props.influencer_data}
                  />
                </Box>
              </div>
            )}

            <div className="clearfix" />
            {this.state.loading && <div>Uploading....</div>}
            <FormFooterButtonGroup py={3}>
              <Button
                type="button"
                variant="solid"
                onClick={this.props.onClose}
              >
                Close
              </Button>
            </FormFooterButtonGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    attachInvoice: (
      auction_id: number,
      influencer_id: number,
      file_url: string,
      month: string,
      callback: any,
    ) => {
      dispatch(
        attachInvoice(auction_id, influencer_id, file_url, month, callback),
      );
    },
  };
};

export default connect(null, mapDispatchToProps)(UploadInvoiceDialog);
