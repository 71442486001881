import { Flex } from "@chakra-ui/react";
// Components Import
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Tabs, { TabPane } from "rc-tabs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InkTabBar from "rc-tabs/lib/InkTabBar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TabContent from "rc-tabs/lib/TabContent";
import Tooltip from "rc-tooltip";
import React, { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Infinite from "react-infinite";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  NavigateFunction,
  useMatch,
  useNavigate,
} from "react-router-dom";
import Select from "react-select";
import "react-virtualized/styles.css";
import { MetaTag as MetaTagApi } from "src/api/ExtendedTypes";
import { ExternalInfluencerInviteDetails } from "src/api/types";
import { MessageDialog } from "src/components/feed_components/dialogs/MessageDialog";
import ShareListDialog from "src/components/feed_components/dialogs/ShareListDialog";
import {
  useDebounceListSearch,
  useListsScrolling,
} from "src/hooks/listsPageHooks";
import NoImage from "src/images/new_auction.png";
import { listsActions } from "src/reducers/listsSlice";
import {
  archiveList,
  deleteList,
  disableShareList,
  shareList,
  toggleListFavorite,
} from "../../../actions/premiumActions";
import { getUserTags } from "../../../actions/userActions";
import { MetaTag, MinimalListSummary } from "../../../models/Api";
import { UserReducer } from "../../../reducers/userReducer";
import EnterpriseSelectListUsers from "../side_menus/EnterpriseSelectListUsers";
import { tagColors } from "../utilities/general";
import { TagsPopup } from "./TagsPopup";

interface OwnProps {
  user?: UserReducer;
  filter?: {
    search: string;
    tags: MetaTagApi[];
  };
  lastSeenTime?: string;
  tab?: "active" | "favorite" | "archived";
  onTabChange?: (newTab: "active" | "favorite" | "archived") => void;
  addOrRemoveFilterTag?: (arg: MetaTagApi) => void;
  setFilterSearch?: (arg: string) => void;
  setLastSeenTime?: (arg: string) => void;
  shareList?: (listId: any, shareNotes: boolean, callback: any) => void;
  disableShareList?: (listId: any, callback: any) => void;
  deleteList?: (listId: any, callback: any) => void;
  archiveList?: (id: any, toArchive: boolean, callback: any) => void;
  toggleListFavorite?: (id: any, callback: any) => void;
  getUserTags?: () => void;
  lists?: MinimalListSummary[];
  loading?: boolean;
  listsFetchMore?: () => void;
  listToArchive?: number;
  onListToArchiveChange?: (id?: number) => void;
  externalInvitations?: ExternalInfluencerInviteDetails[];
  listsLoaded?: any;
}

type Props = OwnProps & { navigate: NavigateFunction };

interface State {
  openShareList: boolean;
  selected: any;
  showDeleteDialog: boolean;
  saveSession: boolean;
  selectedList?: number | null;
  saving?: boolean;
  editableTag?: number;
  tagInput?: string;
  selectedListNum?: number;
}

class ListsPageClass extends React.Component<Props, State> {
  state: State = {
    openShareList: false,
    selected: [],
    showDeleteDialog: false,
    saveSession: false,
    selectedList: null,
    selectedListNum: undefined,
  };

  tagColors = tagColors;

  componentDidMount() {
    if (sessionStorage.getItem("SHOW_ARCHIVED")) {
      this.props.onTabChange("archived");
    }
    sessionStorage.removeItem("SHOW_ARCHIVED");
    this.props.getUserTags();
  }

  async componentWillUnmount() {
    if (this.state.saveSession) {
      if (this.props.tab === "archived") {
        await sessionStorage.setItem("SHOW_ARCHIVED", "true");
      } else await sessionStorage.removeItem("SHOW_ARCHIVED");
    }
  }

  onShareList = (share: boolean, allowNotes: boolean, callback: any) => {
    if (share) {
      this.props.shareList(this.state.selectedList, allowNotes, callback);
    } else {
      this.props.disableShareList(this.state.selectedList, callback);
    }
  };

  onDeleteList(id: any) {
    this.props.deleteList(id, () => {
      this.setState({ showDeleteDialog: false });
    });
  }

  onSelect(id: any) {
    const { selected } = this.state;
    if (selected.includes(id)) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }

    this.setState({ selected });
  }

  archiveList = () => {
    if (this.state.saving) return;
    this.setState({ saving: true });
    this.props.archiveList(
      this.props.listToArchive,
      this.props.tab !== "archived",
      () => {
        this.props.onListToArchiveChange(undefined);
        this.setState({ saving: false });
      },
    );
  };

  toggleListFavorite(data: MinimalListSummary) {
    this.props.toggleListFavorite(data.id, () => {});
  }

  renderEnterpriseEditListMiniUsers = (c: any) => {
    if (
      !c ||
      !this.props.user.userDetails ||
      !this.props.user.userDetails.sidebar_data
    )
      return null;
    const users = this.props.user.userDetails.sidebar_data.sub_users?.filter(
      (u) => {
        return (
          c.sub_users_allowed.includes(u.id) || c.original_creator_id === u.id
        );
      },
    );
    return (
      <>
        <div className="mini-users">
          {
            <div className="specific-mini-users">
              {!users || c.all_sub_users_allowed || users.length > 1 ? (
                <div
                  className="subuser-campaign"
                  style={{ justifyContent: "center" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ selectedList: c });
                  }}
                >
                  <strong
                    className="campaign-name blurable"
                    style={{
                      textAlign: "center",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      placeContent: "center",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                      color: "black",
                    }}
                  >
                    Managed by{" "}
                    {users !== undefined && users.length > 1
                      ? `${users.length} Users`
                      : "All users"}
                  </strong>
                </div>
              ) : (
                users.map((u, i) => {
                  return (
                    <div
                      key={i}
                      className="subuser-campaign"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({ selectedList: c });
                      }}
                    >
                      <img
                        className="blurable"
                        src={(u as any).image ? (u as any).image : NoImage}
                      />
                      <strong
                        className="campaign-name blurable"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                          color: "black",
                        }}
                      >
                        {u.name}
                      </strong>
                    </div>
                  );
                })
              )}
            </div>
          }
        </div>
      </>
    );
  };

  onChange = (e: "active" | "favorite" | "archived") => {
    this.props.onTabChange(e);
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value?.toLowerCase()?.trim();
    this.props.setFilterSearch(value);
  };

  render() {
    const { lists } = this.props;
    const isEnterprise = this.props.user?.enableShareWithSubUsers;
    return (
      <div>
        <div className="main-lists-container" style={{ paddingTop: 50 }}>
          <div className="middle-header" style={{ paddingTop: 0 }}>
            <div className="list-title">
              <span>My Lists</span>
              <div
                className={"create-buttons-row pull-right "}
                style={{ padding: "2.5px 0" }}
              >
                <button
                  onClick={() => this.props.navigate("/create-list")}
                  className={"btn btn-primry"}
                >
                  <span
                    className="moi-plus"
                    style={{
                      color: "var(--main-baby-blue-color)",
                      fontSize: 11,
                    }}
                  />{" "}
                  Add new
                </button>
              </div>
            </div>
            <Flex style={{ height: 50 }}>
              <div style={{ flex: 1, minWidth: 320 }}>
                <Tabs
                  renderTabBar={() => <InkTabBar animated={false} />}
                  renderTabContent={() => <TabContent animated={false} />}
                  activeKey={this.props.tab}
                  onChange={this.onChange}
                  className="campaign-top-bar lists-bar"
                >
                  <TabPane tab={<span>Active</span>} key="active"></TabPane>
                  <TabPane
                    tab={<span>Favorites</span>}
                    key="favorite"
                  ></TabPane>
                  <TabPane tab={<span>Archived</span>} key="archived"></TabPane>
                </Tabs>
              </div>

              <div style={{ display: "flex" }}>
                <div className="inner-toolbar lists-toolbar">
                  <div className="inner-toolbar-search-input">
                    <div className="moi-search-bold" />
                    <input
                      placeholder="List Name"
                      onChange={this.handleSearch}
                      className="form-control"
                    />
                  </div>
                </div>
                {this.renderTagsFilter()}
              </div>
            </Flex>
            {/* End top bar */}
          </div>
          <div className="lists-rows">
            {/* Actions Row */}
            <div style={{ height: 50 }} />
            <Infinite
              elementHeight={95}
              className="campaign-influencers-table-container"
              useWindowAsScrollContainer={true}
              infiniteLoadBeginEdgeOffset={300}
              onInfiniteLoad={this.props.listsFetchMore}
              loadingSpinnerDelegate={
                this.props.loading ? (
                  <div className="infinite-list-item">
                    <div
                      className="spinner small"
                      style={{ marginTop: 10, marginBottom: 0 }}
                    >
                      <div className="rect1"></div>
                      <div className="rect2"></div>
                      <div className="rect3"></div>
                      <div className="rect4"></div>
                      <div className="rect5"></div>
                    </div>
                  </div>
                ) : null
              }
              isInfiniteLoading={this.props.loading}
            >
              {(lists ?? []).map(this.renderListRow)}
            </Infinite>
            <div style={{ height: 40 }} />
          </div>
          {this.state.showDeleteDialog && (
            <MessageDialog
              handleClose={() => {
                this.setState({ showDeleteDialog: false });
              }}
              icon="fas fa-trash"
            >
              <div style={{ textAlign: "center", padding: 20 }}>
                <strong>Notice</strong>
                <p style={{ marginTop: 15 }}>
                  Deleting a list is irreversible.
                </p>
                <button
                  onClick={() => this.onDeleteList(this.state.selectedListNum)}
                  className="btn btn-primry"
                  style={{
                    background: "var(--main-baby-blue-color)",
                    color: "white",
                    borderRadius: "40px",
                    width: 100,
                    marginTop: 15,
                  }}
                >
                  DELETE
                </button>
              </div>
            </MessageDialog>
          )}
          {!!this.props.listToArchive && (
            <MessageDialog
              handleClose={() => {
                this.props.onListToArchiveChange(undefined);
              }}
              icon="fas fa-archive"
            >
              <div style={{ textAlign: "center", padding: 20 }}>
                <strong>Notice</strong>
                <p style={{ marginTop: 15 }}>
                  {`Are you sure you want to ${
                    this.props.tab === "archived" ? "unarchive" : "archive"
                  } the list?`}
                </p>
                <button
                  onClick={this.archiveList}
                  className={`btn btn-primry ${
                    this.state.saving && "disabled"
                  }`}
                  style={{
                    background: "var(--main-baby-blue-color)",
                    color: "white",
                    borderRadius: "40px",
                    width: 100,
                    marginTop: 15,
                  }}
                >
                  {this.props.tab === "archived" ? "Unarchive" : "Archive"}
                </button>
              </div>
            </MessageDialog>
          )}
          {this.state.openShareList && (
            <ShareListDialog
              onShareList={this.onShareList}
              list={{ list_summary_data: this.state.openShareList }}
              handleClose={() => this.setState({ openShareList: false })}
            />
          )}
        </div>
        {this.state.selectedList && isEnterprise && (
          <EnterpriseSelectListUsers
            all_sub_users_allowed={false}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mini_users={undefined}
            list={this.state.selectedList}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            all_users={this.props.user.userDetails?.sidebar_data.sub_users}
            handleClose={() => {
              this.setState({ selectedList: null });
              // this.init(false);
            }}
          />
        )}
      </div>
    );
  }

  handleTagSelected = (e: any) => {
    this.props.addOrRemoveFilterTag(e);
  };

  renderTagsFilter = () => {
    const tags = this.props.user.tags.lists_tags;
    if (tags) {
      return (
        <div className="tags-toolbar-container">
          <Select
            valueKey="id"
            className="tags-toolbar-select"
            // eslint-disable-next-line react/no-string-refs
            ref="stateSelect"
            options={tags}
            placeholder={"Tags"}
            clearable={false}
            onChange={this.handleTagSelected}
            searchable={true}
          />
          <div className="filter-multi-cubes">
            {this.props.filter.tags.map((t) => (
              <div key={t.id}>
                <span dir="auto">{t.label}</span>{" "}
                <a
                  className="far fa-times"
                  onClick={() => this.handleTagSelected(t)}
                ></a>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  // eslint-disable-next-line class-methods-use-this
  renderTagOnRow = (t: MetaTag, idx: number) => {
    return (
      <div
        style={{ background: t.color, marginBottom: 0 }}
        key={idx}
        dir="auto"
      >
        {t.label}
      </div>
    );
  };

  renderListRow = (data: MinimalListSummary, index: number) => {
    const { id } = data;
    if (!id) return null;

    const tags: Array<MetaTag> = this.props.user.tags.lists_tags.filter((t) =>
      t.ids?.includes(id),
    );
    const isEnterprise = this.props.user?.enableShareWithSubUsers;

    return (
      <Link
        key={index}
        to={`/list/${data.id}`}
        style={{ width: "100%", color: "inherit", textDecoration: "none" }}
      >
        <div
          className="list-influencer-row"
          onMouseDown={(e) => {
            if (e.button === 1 || e.button === 0) {
              this.setState({ saveSession: true });
            }
          }}
          // style={style}
        >
          <div className="lists-influencer-details">
            {/* Left Side */}
            <div className="lists-influencer-left">
              <div>
                <img
                  className="blurable"
                  src={data.image_url ? data.image_url : NoImage}
                />
              </div>
            </div>
            {/* End Left Side */}
            {/* Actions Row */}
            <div style={{ width: "90%" }}>
              <div
                style={{ height: 30 }}
                className="list-influencer-details-first lists-influencer-details-first"
              >
                <div>
                  <div>
                    <strong className="blurable">{data.title}</strong>
                  </div>
                </div>
                {this.props.user.parent ? null : (
                  <div className="list-influencer-actions">
                    <div
                      style={{ display: "flex", fontSize: 14 }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <TagsPopup
                        tags={this.props.user.tags.lists_tags}
                        tagType="list"
                        updateParentState={() => this.forceUpdate()}
                        objectId={id}
                      />
                      <Tooltip
                        overlay={data.is_favorite ? "Unfavorite" : "Favorite"}
                        trigger={["hover"]}
                        placement="top"
                      >
                        <div
                          className={`fas fa-star ${
                            data.is_favorite ? "active" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.toggleListFavorite(data);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        overlay={"Edit"}
                        trigger={["hover"]}
                        placement="top"
                      >
                        <div
                          className="moi-pencil-edit-button"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.navigate(`/edit-list/${data.id}`);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        overlay={"Share"}
                        trigger={["hover"]}
                        placement="top"
                      >
                        <div
                          className="moi-forward-arrow2"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              openShareList: data,
                            });
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        overlay={
                          this.props.tab === "archived"
                            ? "Unarchive"
                            : "Archive"
                        }
                        trigger={["hover"]}
                        placement="top"
                      >
                        <div
                          className="fal fa-archive"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.onListToArchiveChange(data.id);
                          }}
                        />
                      </Tooltip>
                      {isEnterprise && (
                        <Tooltip
                          overlay={"Manage sub users"}
                          trigger={["hover"]}
                          placement="top"
                        >
                          <div>
                            <i
                              className="far fa-user-alt"
                              style={{ display: "table-cell" }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                this.setState({ selectedList: data });
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                      {data.list_type !== "campaign" && (
                        <Tooltip
                          overlay={"Delete"}
                          trigger={["hover"]}
                          placement="top"
                        >
                          <div
                            className="fas fa-trash"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({
                                selectedListNum: data.id,
                                showDeleteDialog: true,
                              });
                            }}
                          ></div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 10,
                  justifyContent: "space-between",
                  marginTop: 10,
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: 15 }}>
                    <span>Influencers: {data.influencers_count}</span>
                  </div>
                  {this.props.user.parent && data.owner ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Managed by:{" "}
                      {data.owner.image ? (
                        <img
                          alt={data.owner.name}
                          src={data.owner.image}
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            marginRight: 5,
                            marginLeft: 5,
                          }}
                        />
                      ) : null}{" "}
                      {data.owner.name}
                    </span>
                  ) : null}
                  {isEnterprise && (
                    <div
                      style={{
                        textAlign: "left",
                        display: "inline-block",
                        marginLeft: 20,
                      }}
                    >
                      {this.renderEnterpriseEditListMiniUsers(data)}
                    </div>
                  )}
                </div>
                {tags.length ? (
                  <div className="tags-row" style={{ marginRight: -10 }}>
                    {tags.map(this.renderTagOnRow)}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  };
}

export const ListsPage = ((props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((store) => store.userReducer);
  const tags = useSelector((store) => store.lists.filter.tags);

  const [search, setFilterSearch] = useDebounceListSearch();

  const [listToArchive, setListToArchive] = useState<number | undefined>();
  const [tab, setTab] = useState(
    "active" as "active" | "favorite" | "archived",
  );

  const addOrRemoveFilterTag = useCallback<
    React.ComponentProps<typeof ListsPageClass>["addOrRemoveFilterTag"]
  >(
    (arg) => {
      dispatch(listsActions.addOrRemoveFilterTag(arg));
    },
    [dispatch],
  );
  const shareListCb = useCallback(
    (listId: any, shareNotes: any, callback: any) => {
      dispatch(shareList(listId, shareNotes, callback));
    },
    [dispatch],
  );
  const disableShareListCb = useCallback(
    (listId: any, callback: any) => {
      dispatch(disableShareList(listId, callback));
    },
    [dispatch],
  );
  const deleteListCb = useCallback(
    (listId: any, callback: any) => {
      dispatch(deleteList(listId, callback));
    },
    [dispatch],
  );
  const archiveListCb = useCallback(
    (id: any, toArchive: any, callback: any) => {
      dispatch(archiveList(id, toArchive, callback));
    },
    [dispatch],
  );
  const toggleListFavoriteCb = useCallback(
    (id: any, callback: any) => {
      dispatch(
        toggleListFavorite(id, (success: boolean) => {
          if (success) {
            if (tab === "favorite") {
              dispatch(listsActions.removeFromList(id));
            } else {
              dispatch(listsActions.toggleIsFavorite(id));
            }
          }
          callback(success);
        }),
      );
    },
    [dispatch, tab],
  );
  const getUserTagsCb = useCallback(() => {
    dispatch(getUserTags());
  }, [dispatch]);

  const listsMatch = useMatch("/lists");
  const listMatch = useMatch({
    path: "/list",
    end: false,
  });
  const isLeavingLists = !listsMatch && !listMatch;

  useEffect(() => {
    if (isLeavingLists) {
      dispatch(listsActions.resetFilter());
      dispatch(listsActions.resetLists());
    }
  }, [dispatch, isLeavingLists]);

  const res = useListsScrolling({
    archive: tab === "archived",
    favorites: tab === "favorite",
  });

  return (
    <ListsPageClass
      {...props}
      navigate={navigate}
      user={user}
      filter={{
        tags,
        search,
      }}
      listToArchive={listToArchive}
      onListToArchiveChange={setListToArchive}
      tab={tab}
      lists={res.data.lists}
      externalInvitations={res.data.externalInvitations}
      loading={res.response.isLoading}
      listsFetchMore={res.fetchMore}
      onTabChange={setTab}
      shareList={shareListCb}
      disableShareList={disableShareListCb}
      deleteList={deleteListCb}
      archiveList={archiveListCb}
      toggleListFavorite={toggleListFavoriteCb}
      getUserTags={getUserTagsCb}
      setFilterSearch={setFilterSearch}
      addOrRemoveFilterTag={addOrRemoveFilterTag}
    />
  );
}) as React.FC<OwnProps>;
