// TODO - lint
/* eslint-disable react/no-deprecated, import/no-default-export */
// General Imports
// Components Import
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MessageDialog } from "../dialogs/MessageDialog";
import { AboutTab } from "./AboutTab";
import BillingTab from "./BillingTab";
import EditSettingsTab from "./EditSettingsTab";
import PaymentMethodTab from "./PaymentMethodTab";
import { SubUsers } from "./SubUsers";

class EditDetailsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "account",
      paymentMethodToDelete: null,
    };
  }

  componentWillMount() {
    if (this.props.user) {
      const { tab } = this.props;
      if (
        tab &&
        (tab === "account" ||
          tab === "invoices" ||
          tab === "payment-method" ||
          tab === "sub-users")
      ) {
        this.onChangeTab(tab);
      } else {
        this.setState({ currentTab: "account" });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.tab && this.props.tab !== this.state.currentTab) {
      if (window.location.pathname.includes("edit-details")) {
        this.onChangeTab(this.props.tab);
      }
    }
  }

  onChangeTab(tab) {
    this.props.navigate(`/edit-details/${tab}`);
    this.setState({ currentTab: tab });
  }

  render() {
    const { currentTab } = this.state;
    const isEnterprise =
      this.props.user.userDetails &&
      this.props.user.userDetails.sidebar_data.is_enterprise;
    const isMiniUser =
      this.props.user.userDetails &&
      this.props.user.userDetails.sidebar_data.is_sub_user;

    return (
      <div className="create-campaigns-section-main">
        <div className="section-top-bar"></div>
        {this.props.user ? (
          <Tabs
            renderTabBar={() => <InkTabBar />}
            renderTabContent={() => <TabContent />}
            activeKey={this.state.currentTab}
            onChange={(e) => this.onChangeTab(e)}
          >
            {/* Account */}
            <TabPane tab="Account" key="account">
              {currentTab === "account" ? <EditSettingsTab /> : <div></div>}
            </TabPane>
            {/* End Account */}

            {/* Billing */}
            {!isMiniUser && (
              <TabPane tab="Billing" key="invoices">
                {currentTab === "invoices" ? <BillingTab /> : <div></div>}
              </TabPane>
            )}
            {/* End billing */}

            {/* Payment */}
            {!isMiniUser && (
              <TabPane tab="Payment Method" key="payment-method">
                {currentTab === "payment-method" ? (
                  <PaymentMethodTab
                    searchParams={this.props.searchParams}
                    openDeleteDialog={(callback) =>
                      this.setState({ paymentMethodToDelete: callback })
                    }
                    closeDeleteDialog={() =>
                      this.setState({ paymentMethodToDelete: null })
                    }
                  />
                ) : (
                  <div></div>
                )}
              </TabPane>
            )}
            {/* Sub users */}
            {isEnterprise && (
              <TabPane tab="Sub users" key="sub-users">
                {currentTab === "sub-users" ? (
                  <SubUsers user={this.props.user} />
                ) : (
                  <div></div>
                )}
              </TabPane>
            )}
            {/* End Payment */}

            <TabPane tab="About" key="about">
              {currentTab === "about" ? <AboutTab /> : <div></div>}
            </TabPane>
          </Tabs>
        ) : (
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        )}
        {this.state.paymentMethodToDelete !== null && (
          <MessageDialog
            handleClose={() => {
              this.setState({ paymentMethodToDelete: null });
            }}
            icon="moi-trash"
          >
            <div style={{ textAlign: "center", padding: 20 }}>
              <strong>Notice</strong>
              <p style={{ marginTop: 15 }}>
                Deleting your payment method will change your user status from
                premium in the next month. Add new payment if you want to
                continue be a premium user
              </p>
              <button
                onClick={() => this.state.paymentMethodToDelete()}
                className="btn btn-primry"
                style={{
                  background: "var(--main-baby-blue-color)",
                  color: "white",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
              >
                DELETE
              </button>
            </div>
          </MessageDialog>
        )}
      </div>
    );
  }
}

export default (props) => {
  const navigate = useNavigate();

  return <EditDetailsSection {...props} navigate={navigate} />;
};
