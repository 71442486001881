import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const ModalTheme: ComponentMultiStyleConfig = {
  parts: ["header", "closeButton", "body", "dialog"],
  baseStyle: {
    dialog: {
      bg: "transparent",
    },
    closeButton: {
      top: 0,
      insetEnd: 0,
      color: "white",
      padding: "0",
      lineHeight: "inherit",
      cursor: "pointer",
      backgroundColor: "transparent",
      textTransform: "none",
      overflow: "visible",
      outline: "none",
      border: "0",
      margin: 1,
      _focus: {
        shadow: "none",
      },
      svg: {
        display: "none",
      },
      _after: {
        content: '"×"',
        fontSize: "3xl",
        fontWeight: "300",
      },
    },
    header: {
      px: 4,
      py: 2,
      fontSize: "small",
      bg: "brand.500",
      borderTopRightRadius: "md",
      borderTopLeftRadius: "md",
      color: "white",
    },
    body: {
      bg: "#FAFAFA",
      borderBottomRightRadius: "md",
      borderBottomLeftRadius: "md",
    },
  },
  variants: {
    iconTitle: {
      closeButton: {
        top: 2,
        insetEnd: 2,
      },
      header: {
        borderTopRightRadius: "3xl",
        borderTopLeftRadius: "3xl",
      },
      body: {
        borderBottomRightRadius: "3xl",
        borderBottomLeftRadius: "3xl",
      },
    },
    clean: {
      closeButton: {
        color: "#333",
      },
      header: {
        bg: "#FAFAFA",
        color: "#333",
        fontWeight: "400",
      },
      body: {},
    },
  },
};
