import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getInvitesLinkSocialNetworks } from "src/api/actions/auctionActions";
import { ExternalInfluencerInviteDetails } from "src/api/types";

interface Props {
  buttonProps?: ButtonProps;
  buttonContent?: ReactNode;
  initialUsername?: string;
  children?: ReactNode;
  hintUserName?: string;
  provider: ExternalInfluencerInviteDetails["provider"];
}

const GenerateLinkModal = ({
  isOpen,
  onClose,
  initialUsername,
  hintUserName,
  provider,
}: {
  isOpen: boolean;
  onClose: () => void;
  initialUsername?: string;
  hintUserName?: string;
  provider: ExternalInfluencerInviteDetails["provider"];
}) => {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");

  const handleSaveLink = () => {
    const username =
      initialUsername ||
      (document.getElementById("username-auth") as HTMLInputElement).value
        ?.trim()
        ?.toLowerCase();
    if (username) {
      setLoading(true);
      getInvitesLinkSocialNetworks({
        influencer_name: username,
        invited_username: username,
        provider,
      })
        .then((res) => {
          setLink(
            `${window.location.origin}/connect-influencer/${res.data.invite_link}`,
          );
          setLoading(false);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (initialUsername) {
      handleSaveLink();
    }
  }, [initialUsername]);

  const handleCopy = () => {
    // Copy the text inside the text field
    navigator.clipboard?.writeText(link);
    toast.info("Link Copied");
  };

  if (!isOpen) {
    return null;
  }

  const providerUpper = provider.charAt(0).toUpperCase() + provider.slice(1);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant={"iconTile"}>
      <ModalOverlay />
      <ModalContent style={{ width: 500, maxWidth: "initial" }}>
        <ModalHeader>{providerUpper} link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            {`Use this link to invite the influencer to share their ${providerUpper} data with you. Once the influencer has been verified on Humanz, you will be able to see their audience data, as well as track the performance of their contents.`}
            {loading ? (
              <>
                <Spinner
                  alignSelf={"center"}
                  margin={10}
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </>
            ) : (
              <>
                {link ? (
                  <>
                    <div style={{ marginTop: 15 }}>
                      <strong className="label-title">link </strong>
                      <div>
                        <input
                          className="input-line"
                          value={link}
                          contentEditable={false}
                          id="auth-link"
                        />
                      </div>
                    </div>

                    <Button onClick={handleCopy} margin={"20px auto"}>
                      <i className="far fa-copy" style={{ marginRight: 10 }} />
                      Copy
                    </Button>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: 15 }}>
                      <strong className="label-title">username </strong>
                      <div>
                        <input
                          className="input-line"
                          defaultValue={hintUserName || ""}
                          id="username-auth"
                        />
                      </div>
                    </div>
                    <Button onClick={handleSaveLink} margin={"20px auto"}>
                      Get Link
                    </Button>
                  </>
                )}
              </>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const GenerateAuthLink = ({
  buttonProps,
  buttonContent,
  initialUsername,
  children,
  hintUserName,
  provider,
}: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const modal = useMemo(
    () =>
      isOpen ? (
        <GenerateLinkModal
          initialUsername={initialUsername}
          isOpen={isOpen}
          onClose={onClose}
          hintUserName={hintUserName}
          provider={provider}
        />
      ) : null,
    [isOpen, onClose, initialUsername, hintUserName, provider],
  );

  return (
    <>
      {children ? (
        <div className="fit-content pointer" onClick={onOpen}>
          {children}
        </div>
      ) : (
        <Button onClick={onOpen} {...(buttonProps || {})}>
          {buttonContent || ""}
        </Button>
      )}
      {modal}
    </>
  );
};
