import { Button, Flex, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { SocialNetworkOptions } from "src/api/types";
import { UserReducer } from "src/reducers/userReducer";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import HumanzExpendedInput from "src/ui/humanz/HumanzExpendInput";
import HumanzButtonGroup from "../../../ui/humanz/HumanzButtonGroup";

interface SortOptionProps {
  selectedSocial: SocialNetworkOptions;
  selectedValue: string;
  order?: any;
  data: {
    label: string;
    value: string;
    type?: string;
  }[];
  socials: SocialNetworkOptions[];
  manual: boolean;
}

const SortGroup = ({
  sortOptions,
  onSort,
}: {
  sortOptions: SortOptionProps;
  onSort: (sortOptions: SortOptionProps) => void;
}) => {
  const handleSort = (
    row?: SortOptionProps["data"][0]["value"],
    social?: SortOptionProps["socials"][0],
  ) => {
    const newSortOptions = { ...sortOptions };
    if (social && social !== newSortOptions.selectedSocial) {
      newSortOptions.selectedSocial = social;
    } else if (row && row !== newSortOptions.selectedValue) {
      newSortOptions.selectedValue = row;
    } else {
      return;
    }

    onSort(newSortOptions);
  };

  if (!sortOptions.selectedValue) {
    return null;
  }

  return (
    <HumanzButtonGroup
      label="SORT"
      containerProps={{ className: "top-view-buttons-container" }}
    >
      <HumanzActionsMenu
        buttontype="button"
        style={{
          background: "white",
          borderRadius: 100,
          padding: "2px 10px",
          height: "25px",
          paddingTop: 2,
          color: "GrayText",
          justifyContent: "space-between",
          whiteSpace: "nowrap",
        }}
        buttontext={<Text>{sortOptions.selectedValue?.capitalize()}</Text>}
        variant={"small-action"}
        actions={sortOptions.data.map((data) => ({
          action: () => {
            handleSort(data.value);
          },
          text: data.label.capitalize(),
        }))}
      />
      <HumanzActionsMenu
        buttontype="button"
        style={{
          background: "white",
          width: "40px",
          borderRadius: 100,
          padding: "2px 25px",
          height: "25px",
          paddingTop: 2,
          color: "GrayText",
        }}
        buttontext={
          <>
            <i
              style={{
                fontSize: 16,
              }}
              className={`fa-brands fa-${sortOptions.selectedSocial} colored`}
            />
          </>
        }
        variant={"small-action"}
        actions={sortOptions.socials.map((social) => ({
          action: () => {
            handleSort(undefined, social);
          },
          text: social.capitalize(),
          icon: `fa-brands fa-${social} colored`,
        }))}
      />
    </HumanzButtonGroup>
  );
};

export default function SearchToolBar({
  sideBarData,
  filterBySearch,
  onChangeUserNameSocial,
  selectedSearchByUsername,
  toggleFilterMenu,
  view,
  setView,
  isFiltersActive,
  onAddToList,
  isInfluencersSelected,
  toggleRecentMode,
  isRecentMode,
  sortOptions,
  onSort,
}: {
  sideBarData: UserReducer["userDetails"]["sidebar_data"];
  filterBySearch: any;
  onChangeUserNameSocial: any;
  selectedSearchByUsername: {
    username: string;
    social_network: SocialNetworkOptions | "only_humanz";
  };
  toggleFilterMenu: any;
  view: "rows" | "cubes";
  isFiltersActive: boolean;
  setView: (view: "rows" | "cubes") => void;
  onAddToList: () => void;
  isInfluencersSelected: boolean;
  toggleRecentMode(): void;
  isRecentMode: boolean;
  sortOptions: SortOptionProps;
  onSort: () => void;
}) {
  const UsernameSocialFilter = useCallback(() => {
    if (!selectedSearchByUsername?.social_network) {
      return null;
    }
    return (
      <>
        <HumanzActionsMenu
          buttontype="button"
          style={{
            background: "white",
            width: "40px",
            borderRadius: 100,
            padding: 2,
            height: "28px",
            paddingTop: 2,
            color: "GrayText",
            marginRight: -9,
          }}
          trigger={[]}
          buttontext={
            <i
              style={{
                fontSize: 16,
                marginRight: -3,
              }}
              className={`fa-brands fa-${
                selectedSearchByUsername?.social_network === "only_humanz"
                  ? " moi-humanz"
                  : selectedSearchByUsername?.social_network
              } colored`}
            />
          }
          variant={"small-action"}
          actions={[
            "instagram",
            "tiktok",
            "twitter",
            "youtube",
            "only_humanz",
          ].map((social) => ({
            action: () => {
              onChangeUserNameSocial(social);
            },
            text: social === "only_humanz" ? "Humanz" : social.capitalize(),
            icon:
              social === "only_humanz"
                ? "fa moi-humanz colored"
                : `fa-brands fa-${social} colored`,
          }))}
        />
      </>
    );
  }, [onChangeUserNameSocial, selectedSearchByUsername?.social_network]);

  return (
    <Flex alignItems={"flex-end"} gap={5}>
      <Flex alignItems={"flex-end"} gap={3} flex={1}>
        <HumanzExpendedInput
          containerProps={{ height: "35px", w: 160 }}
          onChange={(value) => {
            if (value?.trim() !== selectedSearchByUsername.username)
              filterBySearch(value?.trim(), "influencer_username");
          }}
          defaultValue={selectedSearchByUsername?.username}
          placeHolder="Username..."
          rightComponent={<UsernameSocialFilter />}
        />
        <HumanzExpendedInput
          containerProps={{ height: "35px", w: 160 }}
          onChange={(value) => {
            filterBySearch(value, "influencer_gpt");
          }}
          innerInput={{
            multiLine: true,
            textAreaProps: { placeholder: "Describe the influencers" },
          }}
          placeHolder={"Ai Search"}
        />
      </Flex>

      <Flex alignItems={"flex-end"} gap={2} justifyContent="flex-end">
        <Button
          variant={"roundedWhite"}
          className="button-shadow"
          onClick={toggleFilterMenu}
          isActive={isFiltersActive}
        >
          <Text mr={"10px"}>
            <strong>Filter</strong>
          </Text>{" "}
          <i className="fa-solid fa-filter"></i>
        </Button>

        {sideBarData?.is_premium ? (
          <Button
            variant={"roundedWhite"}
            className="button-shadow"
            onClick={toggleRecentMode}
            isActive={isRecentMode}
          >
            <Text mr={"10px"}>
              <strong>Recent</strong>
            </Text>{" "}
            <i className="fa-regular fa-clock-rotate-left"></i>
          </Button>
        ) : null}

        <HumanzButtonGroup
          label="View"
          containerProps={{ className: "top-view-buttons-container" }}
        >
          {["cubes", "rows"].map((v) => (
            <Button
              key={v}
              rounded="full"
              variant={"roundedWhite"}
              isActive={view === v}
              onClick={() => setView(v as "rows" | "cubes")}
              style={{ height: 25 }}
            >
              <div className={v === "rows" ? `moi-lines` : "moi-grid"}></div>
            </Button>
          ))}
        </HumanzButtonGroup>

        <SortGroup sortOptions={sortOptions} onSort={onSort} />

        <Button
          variant={"roundedWhite"}
          onClick={() => onAddToList()}
          className="button-shadow"
          isDisabled={!isInfluencersSelected}
          isActive={isInfluencersSelected}
        >
          <div
            className="moi-add-list"
            style={{
              fontSize: 18,
              marginTop: 4,
            }}
          />
        </Button>
      </Flex>
    </Flex>
  );
}

/* <SortBox
        options={this.sortOptions}
        onSort={this.onSort}
        active={this.state.sortedBy}
      /> */

/* {sideBarData?.is_premium ? (
        <div className="inner-toolbar-search-input">
          <div className="moi-search-bold" />
          <input
            placeholder="Username..."
            style={{ paddingRight: 105 }}
            id="search_inf_input"
            className="form-control"
            onChange={(e) =>
        
            }
          />
          <div className="search-input-spacer" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: 110,
              marginLeft: 2,
              marginRight: 10,
            }}
          >
            <div
              className="search-icon fa fa-instagram"
              onClick={() => onChangeUserNameSocial("instagram")}
              style={{
                color:
                  searchSocial === "instagram"
                    ? "var(--main-baby-blue-color)"
                    : "#bbbbbb",
              }}
            />
            {sideBarData?.can_view_tiktok && (
              <div onClick={() => onChangeUserNameSocial("tiktok")}>
                <div
                  className={"search-icon fa-brands fa-tiktok"}
                  style={{
                    color:
                      searchSocial === "tiktok"
                        ? "var(--main-baby-blue-color)"
                        : "#bbbbbb",
                  }}
                />
              </div>
            )}
            <div
              className="search-icon fa fa-twitter"
              onClick={() => onChangeUserNameSocial("twitter")}
              style={{
                color:
                  searchSocial === "twitter"
                    ? "var(--main-baby-blue-color)"
                    : "#bbbbbb",
              }}
            />
            <div
              className="search-icon fa fa-youtube-play"
              onClick={() => onChangeUserNameSocial("youtube")}
              style={{
                color:
                  searchSocial === "youtube"
                    ? "var(--main-baby-blue-color)"
                    : "#bbbbbb",
              }}
            />
            <div
              className="search-icon fa moi-humanz"
              onClick={() => onChangeUserNameSocial("only_humanz")}
              style={{
                marginLeft: 6,
                color:
                  searchSocial === "only_humanz"
                    ? "var(--main-baby-blue-color)"
                    : "#bbbbbb",
              }}
            />
          </div>
        </div>
      ) : null} */
