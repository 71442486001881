import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { defaultStaticRanges } from "../../staticRanges";

const HumanzRangePicker = (props: {
  open?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  onChange?: any;
  onClose?: any;
  minDate?: any | null;
  maxDate?: any | null;
  isSingle?: boolean | null;
  showButton?: boolean | null;
  buttonText?: string | null;
  buttonStyle?: any | null;
  forceMinDate?: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [state, setState] = React.useState({
    // eslint-disable-next-line no-nested-ternary
    startDate: props.startDate ?? props?.minDate ?? new Date(),
    // eslint-disable-next-line no-nested-ternary
    endDate: props?.endDate ?? props?.maxDate ?? new Date(),
    key: "selection",
    valid: true,
  });

  useEffect(() => {
    if (props.open) {
      onOpen();
    }
  }, [onOpen, props.open]);

  const isValid = (startDate: Date, endDate: Date) => {
    if (
      props?.minDate &&
      startDate &&
      moment(startDate)
        .startOf("day")
        .isBefore(moment(props.minDate).startOf("day"))
    ) {
      return false;
    }
    if (
      props?.maxDate &&
      endDate &&
      moment(props.maxDate).isBefore(moment(endDate))
    ) {
      return false;
    }
    return moment(endDate).isSameOrAfter(moment(startDate));
  };

  const handleChange = (item: any) => {
    const newState = { ...state };
    if (!item?.selection) return;
    newState.startDate = props.forceMinDate
      ? props.minDate
      : item.selection.startDate;
    newState.endDate = item.selection.endDate;
    newState.valid = isValid(newState.startDate, newState.endDate);
    setState(newState);
  };

  const onModalClose = () => {
    onClose();
    if (props?.onClose) {
      props.onClose();
    }
  };

  return (
    <>
      {" "}
      {props.showButton && (
        <Button
          className="date-button"
          leftIcon={<i className="far fa-calendar" />}
          style={props.buttonStyle}
          aria-label="Calendar"
          onClick={onOpen}
        >
          {props.buttonText ? (
            <span style={{ paddingLeft: 5 }}>{props.buttonText}</span>
          ) : null}
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onModalClose} isCentered size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pick a range of dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={10}>
            <DateRangePicker
              onChange={handleChange}
              onPreviewChange={handleChange}
              moveRangeOnFirstSelection={props?.isSingle}
              minDate={
                props?.minDate ? moment(props.minDate).toDate() : undefined
              }
              maxDate={
                props?.maxDate ? moment(props.maxDate).toDate() : undefined
              }
              startDatePlaceholder={
                props?.startDate
                  ? moment(props.startDate).toString()
                  : undefined
              }
              endDatePlaceholder={
                props?.endDate ? moment(props.endDate).toString() : undefined
              }
              displayMode={props?.isSingle ? "date" : "dateRange"}
              months={2}
              staticRanges={defaultStaticRanges}
              ranges={[state]}
              direction="horizontal"
            />
            <ModalFooter
              display={"block"}
              justifyContent={"center"}
              textAlign={"center"}
            >
              <Button
                variant="solid"
                size={"lg"}
                onClick={() => {
                  onModalClose();
                  if (props?.onChange) {
                    props.onChange(state);
                  }
                }}
                p={6}
                ml={1}
                isDisabled={!state?.valid}
              >
                Accept
              </Button>
              <Button
                variant="ghost"
                colorScheme={"gray"}
                size={"lg"}
                onClick={() => {
                  onModalClose();
                }}
                p={6}
                ml={1}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HumanzRangePicker;
