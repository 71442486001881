import AddButton from "../FormUI/AddButton";
import FormCard from "../FormUI/FormCard";

type Props = {
  renderNotesBtn: any;
  questions: any;
  renderQuestions: any;
  addNewQuestion: any;
  isShare: boolean;
  children?: any;
};

const BriefQuestions = ({
  renderNotesBtn,
  questions,
  renderQuestions,
  addNewQuestion,
  isShare,
  children,
}: Props) => {
  return (
    <FormCard>
      <div className="products-overview-section">
        <div className="create-campaign-title">
          <span>Questions For The Influencers</span>
          {renderNotesBtn(5)}
        </div>

        {questions && questions.length !== 0 ? (
          <div>
            <div>{renderQuestions(questions)}</div>
          </div>
        ) : (
          <div
            style={{
              fontSize: "14px",
              color: "#95979D",
              fontWeight: 400,
              width: "650px",
              lineHeight: "24px",
              marginTop: "20px",
            }}
          >
            Questions for the influencer before he places a BID. This way you
            can filter your influencers - Who’s a good match for the campaign
            and who’s not.
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          {!isShare && (
            <AddButton
              type="question"
              onClick={() => addNewQuestion()}
              size={questions?.length === 0 || !questions ? "lg" : "sm"}
            />
          )}
        </div>
      </div>
      {children}
    </FormCard>
  );
};

export default BriefQuestions;
