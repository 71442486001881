/* eslint-disable no-nested-ternary */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { postSimilarInfluencersById } from "src/api/actions/influencersActions";
import { EmbeddingSearchType } from "src/api/types";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { writeLog } from "../../../../actions/utilsActions";
import { LookalikeCard } from "./LookalikeCard";
import "./Lookalikes.css";

type Props = {
  influencerId: number;
  openInfluencer: any;
};

const slidesPerView = 2.5;

export const Lookalikes = ({ influencerId, openInfluencer }: Props) => {
  const [lookalikes, setLookalikes] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    postSimilarInfluencersById(influencerId, {
      same_country: true,
      search_type: EmbeddingSearchType.all,
      with_followers: true,
    })
      .then((res) => {
        if (res.data) {
          setLookalikes(res.data);
          writeLog({
            message: "User requested ai lookalikes",
            event_name: "influencer_id",
            event_value_number: influencerId,
          });
        }
      })
      .catch(() => {
        writeLog({
          message: "Error getting ai lookalikes",
          event_name: "influencer_id",
          event_value_number: influencerId,
          log_level: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [influencerId]);

  const slides =
    lookalikes.map((la) => (
      <SwiperSlide key={la.id}>
        <LookalikeCard
          openInfluencer={() => {
            openInfluencer(la.id);
          }}
          influencer={la}
        />
      </SwiperSlide>
    )) ?? [];

  return (
    <Box>
      <div
        style={{
          color: "#808080",
          fontSize: 12,
          marginBottom: 5,
        }}
      >
        <span>Lookalikes</span>
      </div>
      {loading ? (
        <Box
          style={{
            textAlign: "center",
          }}
        >
          <Spinner style={{ margin: "0 auto" }} />
        </Box>
      ) : lookalikes?.length ? (
        <Flex
          alignItems="center"
          css={{
            ".swiper": {
              marginLeft: "unset",
              marginRight: "unset",
              flex: 1,
            },
            ".swiper-slide": {
              textAlign: "center",
            },
            ".swiper-pagination": {
              position: "unset",
            },
            ".swiper-pagination-bullet-active": {
              backgroundColor: "brand.500",
            },
          }}
        >
          <Swiper
            // noSwipingClass="swiper-slide"
            navigation={{
              prevEl: ".stories-swiper-prev",
              nextEl: ".stories-swiper-next",
            }}
            pagination={{
              clickable: true,
            }}
            effect={"cards"}
            freeMode={true}
            grabCursor={true}
            modules={[Pagination, FreeMode]}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerView}
          >
            {slides}
          </Swiper>
        </Flex>
      ) : (
        <span>No similar influencers were found</span>
      )}
    </Box>
  );
};
