// General Imports
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Rnd } from "react-rnd";

// Components Import
import NoImage from "../../images/svgs/placeholder.svg";

// Actions imports
import {
  getNotifications,
  markNotifAsSeen,
  getMoreNotifications,
  deleteNotification,
  deleteAllNotifications,
} from "../../actions/notificationsActions";
import { MessageDialog } from "./dialogs/MessageDialog";

class FloatingNotificationsInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMore: false,
      loading: true,
      isDeletingNotification: 0,
      showDeleteDialog: false,
    };
  }

  componentDidMount() {
    this.props.getNotifications(20, () => this.setState({ loading: false }));
  }

  loadMore = () => {
    this.setState({ loadingMore: true });
    this.props.getMoreNotifications(
      this.props.notifications.query_time_from,
      10,
      () => this.setState({ loadingMore: false }),
    );
  };

  onNotificationClick = (data) => {
    if (!data.seen) {
      this.props.markNotifAsSeen(data.id);
      // eslint-disable-next-line no-param-reassign
      data.seen = true;
      this.setState({ updated: true });
    }
    let route = "/campaign/";
    if (data.objectType === "list") {
      route = "/list/";
    }
    this.props.navigate(route + data.auctionId);
  };

  deleteNotification(id) {
    this.setState({ isDeletingNotification: id });
    this.props.deleteNotification(id, () => {
      this.setState({ isDeletingNotification: 0 });
    });
  }

  deleteAllNotifications() {
    this.setState({ showDeleteDialog: false, loadingMore: true });
    this.props.closeNotifications();
    this.props.deleteAllNotifications(() => {
      this.setState({ loadingMore: false });
    });
  }

  renderNotification = (data, idx) => {
    return (
      <div
        className="notif-row"
        key={idx}
        style={{ opacity: data.seen ? 0.7 : 1 }}
      >
        <div>
          <img
            className="blurable notif-small-img"
            src={data.image_uri ? data.image_uri : NoImage}
          />
        </div>
        <div
          style={{ display: "block" }}
          className={
            this.state.isDeletingNotification === data.id ? "deleting" : ""
          }
        >
          <div className="notificationActions">
            {this.state.isDeletingNotification !== data.id ? (
              <div
                className="notificationAction deleteNotification"
                onClick={() => this.deleteNotification(data.id)}
              >
                <i className="fa fa-times" />
              </div>
            ) : (
              ""
            )}
            {this.state.isDeletingNotification === data.id ? this.loader() : ""}
          </div>
          <div
            className="notification-body"
            style={{ display: "block" }}
            onClick={() => this.onNotificationClick(data)}
          >
            <strong
              className="import-list-name blurable-large"
              title={data.auctionTitle}
            >
              {data.auctionTitle}
            </strong>
            <div>
              <label>{data.data_text}</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  loader() {
    return (
      <div className="spinner small" style={{ marginTop: 10, marginBottom: 0 }}>
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
    );
  }

  render() {
    const { notifications } = this.props.notifications;
    return (
      <>
        {this.state.showDeleteDialog && (
          <MessageDialog
            handleClose={() => {
              this.setState({ showDeleteDialog: false });
            }}
            icon="moi-trash"
          >
            <div style={{ textAlign: "center", padding: 20 }}>
              <strong>Notice</strong>
              <p style={{ marginTop: 15 }}>
                Are you sure you want to clear all notifications?
              </p>
              <button
                onClick={() => this.deleteAllNotifications()}
                className="btn btn-primry"
                style={{
                  background: "var(--main-baby-blue-color)",
                  color: "white",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
              >
                CLEAR
              </button>
            </div>
          </MessageDialog>
        )}
        <Rnd
          default={{
            x: window.innerWidth - 320,
            y: 50,
          }}
          className="notifications-draggable"
          cancel={".clickable"}
          bounds={"parent"}
          enableResizing={false}
        >
          <div className="notif-draggable-container">
            <div>
              <span>Notifications</span>
              <i
                className="fal fa-times clickable"
                onClick={this.props.closeNotifications}
              />
            </div>
            <div>
              {notifications && notifications.map(this.renderNotification)}
              {notifications &&
                this.props.notifications.hasMore &&
                !this.state.loadingMore && (
                  <button
                    onClick={this.loadMore}
                    className="btn btn-primry load-more-notif"
                  >
                    Load more notifications
                  </button>
                )}
              {notifications && notifications.length ? (
                <button
                  onClick={() => this.setState({ showDeleteDialog: true })}
                  className="btn btn-warning delete-all-notifications"
                >
                  Clear all
                </button>
              ) : (
                <span>There are no more notifications to show right now.</span>
              )}
              {this.state.loadingMore && this.loader()}
            </div>
          </div>
        </Rnd>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notificationsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (amount, callback) => {
      dispatch(getNotifications(amount, callback));
    },
    markNotifAsSeen: (id) => {
      dispatch(markNotifAsSeen(id));
    },
    getMoreNotifications: (to, amount, callback) => {
      dispatch(getMoreNotifications(to, amount, callback));
    },
    deleteNotification: (id) => {
      dispatch(deleteNotification(id));
    },
    deleteAllNotifications: (id) => {
      dispatch(deleteAllNotifications(id));
    },
  };
};

const FloatingNotificationsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FloatingNotificationsInner);

export const FloatingNotifications = (props) => {
  const navigate = useNavigate();

  return <FloatingNotificationsConnected {...props} navigate={navigate} />;
};
