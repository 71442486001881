// TODO - lint
/* eslint-disable no-unused-vars, react/no-deprecated, no-param-reassign, no-param-reassign, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, default-case, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, no-shadow, react/no-direct-mutation-state, react/no-direct-mutation-state, no-param-reassign, react/no-direct-mutation-state, react/no-direct-mutation-state, no-unused-vars, react/no-unescaped-entities, no-param-reassign, no-param-reassign, no-param-reassign, no-unused-vars, no-param-reassign, no-param-reassign, no-param-reassign, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, no-unused-vars, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-unescaped-entities, react/no-unescaped-entities, no-unused-vars, class-methods-use-this, consistent-return, react/no-unescaped-entities, react/no-unescaped-entities, react/no-string-refs, import/no-default-export */
// General Imports
import { Box, Button } from "@chakra-ui/react";
import moment from "moment";
import Dialog from "rc-dialog";
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import React from "react";
import Clipboard from "react-clipboard.js";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import Select from "react-select";
import Toggle from "react-toggle";
// Components Import
import uuid4 from "uuid";
import { eventColors } from "../../../../actions/commonActionsConf";
import {
  getShareCampaignData,
  shareCampaign,
} from "../../../../actions/premiumActions";
import "../../../../css/share-dialog.css";
import HumanzRangePicker from "../../../../ui/humanz/HumanzRangePicker";
import CheckBox from "../../general_components/CheckBox";
import { MarkDownEditor } from "../../general_components/MarkDownEditor";

// Actions imports

// Functions import

class ShareCampaignDialog extends React.Component {
  constructor(props) {
    super(props);
    this.colors = eventColors;
    this.eventTypes = ["picking", "content", "publish"];
    this.now = moment().format("YYYY-MM-DD").toString();
    this.state = {
      campaign: {},
      boardGuid: null,
      list: null,
      roi: null,
      password_protected: "",
      currentTab: "campaign",
      editableTag: {},
      events: [],
      tracker: {
        share_enabled: false,
        guid: null,
        events: [],
      },
      newEvent: {
        id: uuid4(),
        text: null,
        type: "upload",
        start_time: this.now,
        end_time: this.now,
        color: eventColors[0],
        is_completed: false,
        is_deleted: false,
        campaign_id: this.props.id,
        share_guid: null,
      },
      shareInfluencerContact: false,
    };
    this.checkboxes = [
      { label: "Followers", value: "followers" },
      { label: "Cost per engagement", value: "cpe" },
      { label: "Engagement", value: "engagement" },
      { label: "influencers", value: "influencers" },
      { label: "Impressions", value: "impressions" },
      { label: "Estimated Budget", value: "est_budget" },
      { label: "Instagram Views", value: "views" },
    ];
    this.listSegmentationOptions = [
      { label: "genders", value: "gender" },
      { label: "ages", value: "age" },
      { label: "countries", value: "country" },
      { label: "Frauds", value: "fraud" },
    ];
  }

  componentWillMount() {
    this.setState({ loading: true });
    getShareCampaignData(this.props.id, (data) => {
      if (data) {
        let currentTab = "campaign";
        if (data.campaign) {
          if (data.campaign.sharing_end_time) {
            data.campaign.sharing_end_time = moment(
              data.campaign.sharing_end_time,
            );
          } else {
            data.campaign.sharing_end_time = moment(new Date()).add(1, "M");
          }
          if (data.campaign.link) {
            if (
              this.props.board &&
              this.props.board.board &&
              this.props.board.board.share_guid
            ) {
              this.state.boardGuid = this.props.board.board.share_guid;
            } else {
              this.state.boardGuid = data.campaign.link.split("/");
              this.state.boardGuid =
                this.state.boardGuid[this.state.boardGuid.length - 1];
            }
            this.state.newEvent.share_guid = this.state.boardGuid;
          }
        } else {
          data.campaign = {
            client_approves_content: false,
            sharing_end_time: moment(new Date()).add(1, "M"),
          };
        }
        if (data.roi) {
          if (!data.roi.comments) {
            data.roi.comments = {};
          }
          if (!data.roi.filters) {
            data.roi.filters = {
              influencers: [],
              time_filter: {
                from: null,
                to: null,
                amount: 1,
                offset: 0,
              },
            };
          }
          if (!data.roi.budget) {
            data.roi.budget = "media";
          }
          data.roi.shareIndividualStats =
            !!data.roi.influencers_price_percentage;
        }

        if (this.props.tab && data[this.props.tab]) {
          currentTab = this.props.tab;
        }
        if (data.list && !data.list.shared_data) {
          data.list.shared_data = [
            "followers",
            "engagement",
            "influencers",
            "impressions",
          ];
        }
        if (data.list && !data.list.show_segementation_options) {
          data.list.show_segementation_options = ["gender", "age", "country"];
        }
        if (data.list && !data.list.select_social_networks) {
          data.list.select_social_networks = [];
        }
        if (data.list && !data.list.share_tags) {
          data.list.share_tags = false;
        }
        if (data.list && !data.list.share_contact_details) {
          this.state.shareInfluencerContact =
            data.list.share_influencer_contact;
        }
        if (data.tracker) {
          data.tracker = {
            share_enabled: data.tracker.share_enabled,
            guid: data.tracker.guid,
            events: data.tracker.events || [],
          };
          data.events = data.tracker.events;
        } else {
          data.tracker = {
            share_enabled: false,
            guid: this.state.boardGuid,
            events: [],
          };
        }
        this.setState({ ...data, loading: false, currentTab });
      }
    });
  }

  getEventColorFromValue = (val) => {
    const i = this.eventTypes.indexOf(val);
    return i !== -1 ? i : 0;
  };

  onChangeShare = (type) => {
    switch (type) {
      case "brief":
        this.state.campaign.share_brief = !this.state.campaign.share_brief;
        break;
      case "list":
        this.state.list.share_enabled = !this.state.list.share_enabled;
        if (!this.state.list.sharing_end_time) {
          this.state.list.sharing_end_time = moment(new Date()).add(1, "M");
          this.state.list.shared_data = this.state.list.shared_data
            ? this.state.list.shared_data
            : [];
          this.state.list.show_segementation_options = this.state.list
            .show_segementation_options
            ? this.state.list.show_segementation_options
            : [];
          this.state.list.select_social_networks = this.state
            .select_social_networks
            ? this.state.select_social_networks
            : [];
          this.state.list.share_influencer_contact =
            this.state.shareInfluencerContact || false;
        }
        break;
      case "content":
        this.state.campaign.share_content = !this.state.campaign.share_content;
        break;
      case "tracker":
        this.state.campaign.share_tracker = !this.state.campaign.share_tracker;
        this.state.tracker.share_enabled = this.state.campaign.share_tracker;
        break;
      case "client_approves_content":
        this.state.campaign.client_approves_content =
          !this.state.campaign.client_approves_content;
        break;
      case "tags":
        this.state.campaign.share_campaign_tags =
          !this.state.campaign.share_campaign_tags;
        break;
      case "roi":
        this.state.roi.share_enabled = !this.state.roi.share_enabled;
        if (!this.state.roi.comments) {
          this.state.roi.comments = {
            kpi: null,
            tasks: null,
            segmentation: null,
            funnel: null,
          };
        }
        if (!this.state.roi.filters) {
          this.state.roi.filters = {
            influencers: [],
            time_filter: {
              from: null,
              to: null,
              amount: 1,
              offset: 0,
            },
          };
        }
        if (!this.state.roi.sharing_end_time) {
          this.state.roi.sharing_end_time = moment(new Date()).add(1, "M");
        }
        if (!this.state.roi.budget) this.state.roi.budget = "media";
        break;
    }

    this.setState({});
  };

  handleUpdate = () => {
    this.setState({ loading: true });
    if (this.state.events && this.state.events.length) {
      this.state.events.forEach((e, i) => {
        this.state.events[i].is_deleted =
          this.state.events[i].is_deleted !== null
            ? this.state.events[i].is_deleted
            : false;
      });
    }
    const data = {
      campaign: this.state.campaign,
      list: this.state.list,
      roi: this.state.roi,
      password_protected: this.state.password_protected
        ? this.state.password_protected
        : null,
      tracker: {
        share_enabled: this.state.campaign.share_tracker,
        guid: this.state.boardGuid,
        events: this.state.events,
      },
    };
    if (data.roi && !data.roi.influencers_price_percentage) {
      data.roi.influencers_price_percentage =
        data.shareIndividualStats || data.share_cost_per_influencer
          ? 100
          : null;
    }
    shareCampaign(this.props.id, data, (data) => {
      if (data && data.campaign) {
        this.state.campaign.link = data.campaign.shared_link;
      }
      this.setState({ loading: false, currentTab: "campaign" });
    });
  };

  renderCheckboxItem = (item, items) => {
    const checked = items.indexOf(item.value) > -1;
    const style = { marginTop: 5 };
    // ui patch
    if (item.value === "unique_followers_dist") {
      style.marginRight = 33;
    }
    return (
      <CheckBox
        style={style}
        checked={checked}
        onChange={() => {
          items.addOrRemove(item.value);
          this.setState({ updated: true });
        }}
        label={item.label}
        key={item.label}
      />
    );
  };

  toggleShareCampaign = () => {
    this.state.campaign.share_enabled = !this.state.campaign.share_enabled;
    this.setState({});
  };

  addTrackerEvent = (e) => {
    const { form } = e.target;
    if (!form.checkValidity()) {
      return;
    }
    e.preventDefault();
    const events = [...this.state.events];
    events.push({ ...this.state.newEvent });
    const cleanEvent = {
      id: uuid4(),
      text: null,
      type: "upload",
      start_time: this.now,
      end_time: this.now,
      color: eventColors[0],
      is_completed: false,
      is_deleted: false,
      campaign_id: this.props.id,
      share_guid: this.state.boardGuid,
    };
    // clear the form?
    // form.reset();
    this.setState({ events, newEvent: cleanEvent });
  };

  updateTrackerEvent = (event) => {
    const events = [...this.state.events];
    const index = events.findIndex((e) => e.id === event.id);
    if (index !== -1) {
      events[index] = event;
      this.setState({ events });
    }
  };

  deleteTrackerEvent = (event) => {
    const events = [...this.state.events];
    const index = events.findIndex((e) => e.id === event.id);
    if (index !== -1) {
      event.is_deleted = true;
      events[index] = event;
      this.setState({ events });
    }
  };

  render() {
    const listShared = this.state.list && this.state.list.share_enabled;
    const roiShared = this.state.roi && this.state.roi.share_enabled;
    const trackerShared =
      this.state.tracker && this.state.tracker.share_enabled;
    const { currentTab } = this.state;
    return (
      <Dialog
        onClose={this.props.handleClose}
        className="share-dialog "
        visible={true}
        style={{ marginTop: 50 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">Share Campaign</div>
          <div style={{ fontSize: 18, fontWeight: 300, marginTop: 20 }}>
            Share - {this.props.name}
          </div>
          {this.state.loading ? (
            <div
              className="spinner small"
              style={{ marginTop: 40, marginBottom: 0 }}
            >
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          ) : (
            <div>
              <div style={{ marginTop: 20 }}>
                <div style={{ marginBottom: 15 }}>
                  <strong className="label-title">Active</strong>
                  {!this.state.campaign.share_enabled && (
                    <div style={{ color: "#f15a24", marginTop: 5 }}>
                      <strong>
                        The share is{" "}
                        <span style={{ textDecoration: "underline" }}>
                          inactive
                        </span>
                      </strong>
                    </div>
                  )}
                </div>
                <div className="toggle-input">
                  <label>
                    <div className="toggle-label">
                      <div className="fa fa-times" />
                      <div className="fa fa-check" />
                    </div>
                    <Toggle
                      checked={this.state.campaign.share_enabled}
                      icons={false}
                      onChange={this.toggleShareCampaign}
                    />
                  </label>
                </div>
              </div>
              {this.state.campaign && this.state.campaign.share_enabled ? (
                <div
                  className="input-row"
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  <strong className="label-title">LINK</strong>
                  <div style={{ display: "flex" }}>
                    <input
                      value={this.state.campaign.link}
                      className="input-line"
                      readOnly={true}
                    />
                    <Clipboard
                      style={{ background: "transparent", border: "none" }}
                      data-clipboard-text={this.state.campaign.link}
                    >
                      <i className="far fa-copy copy-btn" />
                    </Clipboard>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div
                className={this.state.campaign.share_enabled ? "" : "disabled"}
              >
                <strong className="label-title">SETTINGS</strong>
                <Tabs
                  renderTabBar={() => <InkTabBar />}
                  renderTabContent={() => <TabContent />}
                  onChange={(e) => {
                    this.setState({ currentTab: e });
                  }}
                  activeKey={this.state.currentTab}
                >
                  <TabPane tab={<span>GENERAL</span>} key="campaign">
                    {currentTab === "campaign" &&
                      this.renderGeneralOptions(
                        listShared,
                        roiShared,
                        trackerShared,
                      )}
                  </TabPane>
                  <TabPane tab={<span>LIST</span>} key="list">
                    {currentTab === "list" && this.renderListOptions()}
                  </TabPane>
                  <TabPane tab={<span>Content</span>} key="content">
                    {currentTab === "content" && this.renderContentOptions()}
                  </TabPane>
                  <TabPane tab={<span>ROI</span>} key="roi">
                    {currentTab === "roi" && (
                      <div style={{ height: 450 }}>
                        <Tabs
                          renderTabBar={() => <InkTabBar />}
                          renderTabContent={() => <TabContent />}
                        >
                          <TabPane
                            tab={<span>Share Settings</span>}
                            key="settings"
                          >
                            {this.renderRoiSettings()}
                          </TabPane>
                          <TabPane
                            tab={<span>Share Comments</span>}
                            key="comments"
                          >
                            {this.renderRoiComments()}
                          </TabPane>
                          {this.props.influencers && (
                            <TabPane
                              tab={<span>Share Filters</span>}
                              key="roi_filters"
                            >
                              {this.renderRoiFilters()}
                            </TabPane>
                          )}
                        </Tabs>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tab={<span>Tracker</span>} key="tracker">
                    {currentTab === "tracker" && (
                      <div style={{ height: 450 }}>
                        {this.renderTrackerShareOptions()}
                      </div>
                    )}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          )}
          <div
            style={{
              paddingBottom: 5,
              paddingTop: 10,
              marginTop: 40,
              textAlign: "center",
            }}
          >
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn share-save-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={this.handleUpdate}
            >
              <span>Save</span>
            </button>
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={() => this.props.handleClose()}
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  renderGeneralOptions(listShared, roiShared, trackerShared) {
    const disabledByTime =
      this.state.campaign.sharing_end_time < moment(new Date());
    return (
      <div>
        <div
          style={{
            marginTop: 20,
            justifyContent: "space-between",
            display: "flex",
            padding: 10,
            paddingRight: 15,
          }}
        >
          <div style={{ textAlign: "center" }}>
            <strong className="label-title" style={{ marginBottom: 5 }}>
              share brief
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={this.state.campaign.share_brief}
                  icons={false}
                  onChange={() => this.onChangeShare("brief")}
                />
              </label>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <strong className="label-title" style={{ marginBottom: 5 }}>
              share list
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={listShared}
                  icons={false}
                  onChange={() => this.onChangeShare("list")}
                />
              </label>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <strong className="label-title" style={{ marginBottom: 5 }}>
              share content
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={this.state.campaign.share_content}
                  icons={false}
                  onChange={() => this.onChangeShare("content")}
                />
              </label>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <strong className="label-title" style={{ marginBottom: 5 }}>
              share roi
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={roiShared}
                  icons={false}
                  onChange={() => this.onChangeShare("roi")}
                />
              </label>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <strong className="label-title" style={{ marginBottom: 5 }}>
              share tracker
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={trackerShared}
                  icons={false}
                  onChange={() => this.onChangeShare("tracker")}
                />
              </label>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          <strong className="label-title" style={{ marginBottom: 15 }}>
            Password
          </strong>
          <div>
            <input
              style={{ paddingRight: 80 }}
              id="share-password"
              name="share-password"
              autoComplete={"share-password"}
              defaultValue={this.state.password_protected}
              onChange={(e) => {
                this.setState({ password_protected: e.target.value });
              }}
              className="input-line"
              type={this.state.showPassword ? "normal" : "password"}
            />
            {this.state.password_protected && (
              <div
                onClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
                className="btn btn-primary"
                style={{
                  position: "absolute",
                  padding: "5px 10px",
                  marginTop: -35,
                  marginLeft: "89%",
                }}
              >
                {this.state.showPassword ? "HIDE" : "SHOW"}
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          <strong className="label-title" style={{ marginBottom: 15 }}>
            SHARING UNTIL
          </strong>
          <div style={{ display: "flex" }}>
            <div
              style={{ width: 200, marginLeft: 5 }}
              className="searchable-input"
            >
              <DatePicker
                error={true}
                selected={this.state.campaign.sharing_end_time}
                isClearable={true}
                placeholderText="Share end date"
                dateFormat={window.time.date_with_hour}
                onChange={(date) => {
                  this.state.campaign.sharing_end_time = date;
                  this.state.campaign.share_enabled = true;
                  this.setState({});
                }}
                className={`input-line ${
                  disabledByTime ? "disabled-by-time" : ""
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContentOptions() {
    const { campaign } = this.state;
    return (
      <div style={{ height: 200 }}>
        <div style={{ marginTop: 20 }}>
          <strong className="label-title" style={{ marginBottom: 15 }}>
            share content
          </strong>
          <div className="toggle-input">
            <label>
              <div className="toggle-label">
                <div className="fa fa-times" />
                <div className="fa fa-check" />
              </div>
              <Toggle
                defaultChecked={campaign.share_content}
                icons={false}
                onChange={() => this.onChangeShare("content")}
              />
            </label>
          </div>
        </div>

        {campaign.share_content && (
          <div style={{ marginTop: 20 }}>
            <strong className="label-title">share all content</strong>
            <div style={{ marginBottom: 15, marginTop: -5 }}>
              <span className="optional-title">
                (If disabled, needs to select every content that need approval)
              </span>
            </div>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={campaign.share_all_content}
                  icons={false}
                  onChange={() => {
                    campaign.share_all_content = !campaign.share_all_content;
                    this.setState({});
                  }}
                />
              </label>
            </div>
          </div>
        )}

        {campaign.share_content && (
          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 5 }}>
              When client approves content send approval to influencer
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={this.state.campaign.client_approves_content}
                  icons={false}
                  onChange={() => this.onChangeShare("client_approves_content")}
                />
              </label>
            </div>
          </div>
        )}

        <div style={{ marginTop: 20 }}>
          <strong className="label-title" style={{ marginBottom: 15 }}>
            Share campaign tags
          </strong>
          <div className="toggle-input">
            <label>
              <div className="toggle-label">
                <div className="fa fa-times" />
                <div className="fa fa-check" />
              </div>
              <Toggle
                defaultChecked={campaign.share_campaign_tags}
                icons={false}
                onChange={() => this.onChangeShare("tags")}
              />
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderListOptions() {
    const { list } = this.state;
    const disabledByTime = list.sharing_end_time < moment(new Date());
    const allowedShareContactDetails = this.props.user.userDetails
      ? this.props.user.userDetails.sidebar_data.allowed_share_contact_details
      : false;

    return (
      <div style={{ height: 420 }}>
        <div style={{ marginTop: 20 }}>
          <strong className="label-title" style={{ marginBottom: 15 }}>
            share list
          </strong>
          <div className="toggle-input">
            <label>
              <div className="toggle-label">
                <div className="fa fa-times" />
                <div className="fa fa-check" />
              </div>
              <Toggle
                defaultChecked={list.share_enabled}
                icons={false}
                onChange={() => this.onChangeShare("list")}
              />
            </label>
          </div>
        </div>

        <div
          style={
            !list.share_enabled ? { opacity: 0.5, pointerEvents: "none" } : {}
          }
        >
          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              share comments
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={list.share_comments}
                  icons={false}
                  onChange={() => {
                    list.share_comments = !list.share_comments;
                    this.setState({});
                  }}
                />
              </label>
            </div>
          </div>

          {list.share_comments ? (
            <div style={{ width: "100%", marginTop: 20 }}>
              <strong className="label-title">General comment</strong>
              <div className="blurable-large">
                <MarkDownEditor
                  value={list.general_comment}
                  setValue={(text) => {
                    list.general_comment = text;
                    this.setState({ updated: true });
                  }}
                />
              </div>
            </div>
          ) : null}

          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              share influencer followers' segmentations
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={list.share_segmentation}
                  icons={false}
                  onChange={() => {
                    list.share_segmentation = !list.share_segmentation;
                    this.setState({});
                  }}
                />
              </label>
            </div>
          </div>
          {allowedShareContactDetails && (
            <div style={{ marginTop: 20 }}>
              <strong className="label-title" style={{ marginBottom: 15 }}>
                Share influencer contact details
              </strong>
              <div className="toggle-input">
                <label>
                  <div className="toggle-label">
                    <div className="fa fa-times" />
                    <div className="fa fa-check" />
                  </div>
                  <Toggle
                    defaultChecked={list.share_contact_details}
                    icons={false}
                    onChange={() => {
                      list.share_contact_details = !list.share_contact_details;
                      this.setState({});
                    }}
                  />
                </label>
              </div>
            </div>
          )}
          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              share tags
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={list.share_tags}
                  icons={false}
                  onChange={() => {
                    list.share_tags = !list.share_tags;
                    this.setState({});
                  }}
                />
              </label>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              SHARE SPECIFIC SOCIALS
            </strong>
            {this.renderSocialNetwork(list)}
          </div>

          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              KPIS TO SHARE
            </strong>
            <div className="checkbox-container">
              {this.checkboxes.map((item) =>
                this.renderCheckboxItem(item, list.shared_data),
              )}
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              Share influencers' engagement rate
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={list.share_engagments_rate ?? true}
                  icons={false}
                  onChange={() => {
                    list.share_engagments_rate = !list.share_engagments_rate;
                    this.setState({});
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  chooseSocialNetwork = (list, social) => {
    list.select_social_networks.addOrRemove(social);
    this.setState({});
  };

  renderSocialNetwork = (list) => {
    const canViewTikTok = this.props.user.userDetails
      ? this.props.user.userDetails.sidebar_data.can_view_tiktok
      : true;
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
            width: 200,
          }}
        >
          <div className="filter-social">
            <div
              className={`filter-social-cube small ${list.select_social_networks.includes(
                "instagram",
              )}`}
              onClick={() => this.chooseSocialNetwork(list, "instagram")}
            >
              <li className="fa fa-instagram" />
            </div>
          </div>
          <div className="filter-social">
            <div
              className={`filter-social-cube small ${list.select_social_networks.includes(
                "twitter",
              )}`}
              onClick={() => this.chooseSocialNetwork(list, "twitter")}
            >
              <li className="fa fa-twitter" />
            </div>
          </div>
          <div className="filter-social">
            <div
              className={`filter-social-cube small ${list.select_social_networks.includes(
                "youtube",
              )}`}
              onClick={() => this.chooseSocialNetwork(list, "youtube")}
            >
              <li className="fa fa-youtube-play" />
            </div>
          </div>
          {canViewTikTok ? (
            <div className="filter-social">
              <div
                className={`filter-social-cube small ${list.select_social_networks.includes(
                  "tiktok",
                )}`}
                onClick={() => this.chooseSocialNetwork(list, "tiktok")}
              >
                {" "}
                <div className="tiktok-logo">
                  <div className="fa-brands fa-tiktok"></div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ width: 40 }} />
          )}
        </div>
      </div>
    );
  };

  renderTrackerShareOptions() {
    return (
      <div>
        <div style={{ marginTop: 20 }}>
          <strong className="label-title" style={{ marginBottom: 15 }}>
            share tracker
          </strong>
          <div className="toggle-input">
            <label>
              <div className="toggle-label">
                <div className="fa fa-times" />
                <div className="fa fa-check" />
              </div>
              <Toggle
                defaultChecked={this.state.campaign.share_tracker}
                icons={false}
                onChange={() => this.onChangeShare("tracker")}
              />
            </label>
          </div>
          {this.state.campaign && this.state.campaign.share_enabled ? (
            <div
              className="input-row"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              {this.state.campaign.link && (
                <>
                  <strong className="label-title">TRACKER LINK</strong>
                  <div style={{ display: "flex" }}>
                    <input
                      value={this.state.campaign.link.replace(
                        "/campaign/",
                        "/tracker/",
                      )}
                      className="input-line"
                      readOnly={true}
                    />
                    <Clipboard
                      style={{ background: "transparent", border: "none" }}
                      data-clipboard-text={this.state.campaign.link.replace(
                        "/campaign/",
                        "/tracker/",
                      )}
                    >
                      <i className="far fa-copy copy-btn" />
                    </Clipboard>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div></div>
          )}
          <div className="events-container">
            {this.state.campaign.share_tracker &&
            this.state.events &&
            this.state.events.length
              ? this.state.events.map(
                  (event) =>
                    !event.is_deleted && (
                      <div className="event" key={event.id}>
                        <form
                          style={{ position: "relative" }}
                          onSubmit={this.updateTrackerEvent}
                        >
                          <div
                            className="left-color-bar"
                            style={{ background: event.color }}
                          ></div>
                          <div className="flex" style={{ flexWrap: "wrap" }}>
                            <div className="field">
                              <label className={"label-title"}>Title</label>
                              <input
                                className="input-line"
                                onChange={(e) => {
                                  event.text = e.target.value;
                                  this.setState({});
                                }}
                                required
                                type="text"
                                placeholder={"Title"}
                                defaultValue={event.text}
                              />
                            </div>
                            <div className="field">
                              <label className={"label-title"}>Type</label>
                              <select
                                className="input-line"
                                onChange={(e) => {
                                  event.type = e.target.value;
                                  event.color =
                                    eventColors[
                                      this.getEventColorFromValue(
                                        e.target.value,
                                      )
                                    ];
                                  this.setState({});
                                }}
                                required
                                placeholder={"Type"}
                                defaultValue={event.type}
                              >
                                {this.eventTypes.map((v, i) => (
                                  <option key={i} value={v}>
                                    {v.toUpperCase()}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="field">
                              <label className={"label-title"}>Completed</label>
                              <div className="toggle-input">
                                <label>
                                  <div className="toggle-label">
                                    <div className="fa fa-times" />
                                    <div className="fa fa-check" />
                                  </div>
                                  <Toggle
                                    onChange={(e) => {
                                      event.is_completed = !event.is_completed;
                                      this.setState({});
                                    }}
                                    checked={event.is_completed}
                                    icons={false}
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <button
                                type={"button"}
                                className={"btn btn-danger"}
                                onClick={() => this.deleteTrackerEvent(event)}
                              >
                                <i className="fa fa-trash" />
                              </button>
                            </div>
                            <div className="field">
                              <label className={"label-title"}>
                                Start date
                              </label>

                              <DatePicker
                                dateFormat={window.time.date_with_hour}
                                error={true}
                                selected={moment(event.start_time)}
                                isClearable={false}
                                placeholderText="Start time"
                                onChange={(date) => {
                                  if (!date) return;
                                  event.start_time = date;
                                  this.setState({});
                                }}
                                className={"input-line"}
                              />
                            </div>
                            <div className="field">
                              <label className={"label-title"}>End date</label>

                              <DatePicker
                                dateFormat={window.time.date_with_hour}
                                error={true}
                                selected={moment(event.end_time)}
                                isClearable={false}
                                placeholderText="End time"
                                onChange={(date) => {
                                  if (!date) return;
                                  event.end_time = date;
                                  this.setState({});
                                }}
                                className={"input-line"}
                              />
                            </div>
                            <div style={{ width: 105 }} />
                          </div>
                        </form>
                      </div>
                    ),
                )
              : ""}
            {this.state.campaign.share_tracker && (
              <div className="event new-event">
                {this.state.campaign.share_enabled ? (
                  <>
                    <div className="event-title">New event</div>
                    <form onSubmit={this.addTrackerEvent}>
                      <div className="flex" style={{ flexWrap: "wrap" }}>
                        <div className="field">
                          <label className={"label-title"}>Title</label>
                          <input
                            className="input-line"
                            onChange={(e) => {
                              this.state.newEvent.text = e.target.value;
                              this.setState({});
                            }}
                            required
                            type="text"
                            placeholder={"Title"}
                            defaultValue={this.state.newEvent.text}
                          />
                        </div>
                        <div className="field">
                          <label className={"label-title"}>Type</label>
                          <select
                            className="input-line"
                            onChange={(e) => {
                              this.state.newEvent.type = e.target.value;
                              this.state.newEvent.color =
                                eventColors[
                                  this.getEventColorFromValue(e.target.value)
                                ];
                              this.setState({});
                            }}
                            required
                            placeholder={"Type"}
                            defaultValue={this.state.newEvent.type}
                          >
                            {this.eventTypes.map((v, i) => (
                              <option key={i} value={v}>
                                {v.toUpperCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="field">
                          <label className={"label-title"}>Completed</label>
                          <div className="toggle-input">
                            <label>
                              <div className="toggle-label">
                                <div className="fa fa-times" />
                                <div className="fa fa-check" />
                              </div>
                              <Toggle
                                onChange={(e) => {
                                  this.state.newEvent.is_completed =
                                    !this.state.newEvent.is_completed;
                                  this.setState({});
                                }}
                                checked={this.state.newEvent.is_completed}
                                icons={false}
                              />
                            </label>
                          </div>
                        </div>
                        <div style={{ width: 90 }} />
                        <div
                          className="field searchable-input"
                          onClick={() =>
                            this.setState({ showDatePicker: true })
                          }
                        >
                          <label className={"label-title"}>Start date</label>
                          <DatePicker
                            readOnly={true}
                            dateFormat={window.time.date_with_hour}
                            error={true}
                            selected={moment(this.state.newEvent.start_time)}
                            isClearable={false}
                            placeholderText="End time"
                            onChange={(date) => {
                              if (!date) return;
                              this.state.newEvent.start_time = date;
                              this.setState({});
                            }}
                            className={"input-line"}
                          />
                        </div>
                        <div
                          className="field searchable-input"
                          onClick={() =>
                            this.setState({ showDatePicker: true })
                          }
                        >
                          <label className={"label-title"}>End date</label>

                          <DatePicker
                            readOnly={true}
                            dateFormat={window.time.date_with_hour}
                            error={true}
                            selected={moment(this.state.newEvent.end_time)}
                            isClearable={false}
                            placeholderText="End time"
                            onChange={(date) => {
                              if (!date) return;
                              this.state.newEvent.end_time = date;
                              this.setState({});
                            }}
                            className={"input-line"}
                          />
                        </div>
                        <HumanzRangePicker
                          buttonStyle={{
                            placeSelf: "flex-end",
                          }}
                          showButton={false}
                          onClose={() => {
                            this.setState({ showDatePicker: false });
                          }}
                          open={this.state.showDatePicker}
                          startDate={moment(
                            this.state.newEvent.start_time || Date.now(),
                          ).toDate()}
                          endDate={moment(
                            this.state.newEvent.end_time || Date.now(),
                          ).toDate()}
                          onChange={(res) => {
                            if (res && res.startDate && res.endDate) {
                              const newState = { ...this.state };
                              newState.newEvent.start_time = moment(
                                res.startDate,
                              ).startOf("day");
                              newState.newEvent.end_time = moment(
                                res.endDate,
                              ).endOf("day");
                              this.setState(newState);
                            }
                            this.setState({ showDatePicker: false });
                          }}
                        />
                        <div
                          className="field"
                          style={{ alignSelf: "flex-end" }}
                        >
                          <button
                            type={"submit"}
                            className={"btn btn-success"}
                            style={{ width: 130 }}
                            onClick={this.addTrackerEvent}
                          >
                            + Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  <div className="alert-danger">
                    Please enable campaign share in order to add tracker events
                    and click "SAVE" before adding events.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderRoiSettings() {
    const settings = this.state.roi;
    const disabledByTime = settings.sharing_end_time < moment(new Date());
    return (
      <div>
        <div style={{ marginTop: 20 }}>
          <strong className="label-title" style={{ marginBottom: 15 }}>
            share roi
          </strong>
          <div className="toggle-input">
            <label>
              <div className="toggle-label">
                <div className="fa fa-times" />
                <div className="fa fa-check" />
              </div>
              <Toggle
                defaultChecked={settings.share_enabled}
                icons={false}
                onChange={() => this.onChangeShare("roi")}
              />
            </label>
          </div>
        </div>
        {settings.share_enabled && this.state.campaign.link && (
          <>
            <strong className="label-title">Report only link</strong>
            <div style={{ display: "flex" }}>
              <input
                value={this.state.campaign.link.replace(
                  "/campaign/",
                  "/campaign/report/",
                )}
                className="input-line"
                readOnly={true}
              />
              <Clipboard
                style={{ background: "transparent", border: "none" }}
                data-clipboard-text={this.state.campaign.link.replace(
                  "/campaign/",
                  "/campaign/report/",
                )}
              >
                <i className="far fa-copy copy-btn" />
              </Clipboard>
            </div>
          </>
        )}
        <div
          style={
            settings.share_enabled
              ? {}
              : { opacity: 0.5, pointerEvents: "none" }
          }
        >
          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              share influencers cost
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={settings.share_cost_per_influencer}
                  icons={false}
                  onChange={() => {
                    settings.share_cost_per_influencer =
                      !settings.share_cost_per_influencer;
                    settings.influencers_price_percentage =
                      settings.share_cost_per_influencer ? 100 : null;
                    this.setState({});
                  }}
                />
              </label>
            </div>
          </div>

          {!settings.share_cost_per_influencer && (
            <div style={{ marginTop: 20 }}>
              <strong className="label-title" style={{ marginBottom: 15 }}>
                share influencers performance (individual roi)
              </strong>
              <div className="toggle-input">
                <label>
                  <div className="toggle-label">
                    <div className="fa fa-times" />
                    <div className="fa fa-check" />
                  </div>
                  <Toggle
                    defaultChecked={settings.shareIndividualStats}
                    icons={false}
                    onChange={() => {
                      settings.shareIndividualStats =
                        !settings.shareIndividualStats;
                      settings.influencers_price_percentage =
                        settings.shareIndividualStats ? 100 : null;
                      this.setState({});
                    }}
                  />
                </label>
              </div>
            </div>
          )}

          {settings.shareIndividualStats ||
          settings.share_cost_per_influencer ? (
            <div style={{ marginTop: 20 }}>
              <strong className="label-title">
                Influencers cost percentage (Optional)
              </strong>
              <div style={{ marginBottom: 15 }}>
                <span style={{ color: "#888" }} className="optional-title">
                  This will effect all the influencers in the share report.
                  empty will be equal to 100%
                </span>
              </div>
              <div style={{ width: 80, display: "flex", alignItems: "center" }}>
                <input
                  type={"number"}
                  defaultValue={settings.influencers_price_percentage}
                  onChange={(e) => {
                    settings.influencers_price_percentage = e.target.value;
                    this.setState({ updated: true });
                  }}
                  className="input-line"
                />{" "}
                %{" "}
              </div>
            </div>
          ) : null}

          {settings.hide_impressions ? (
            <div style={{ marginTop: 20 }}>
              <strong className="label-title" style={{ marginBottom: 15 }}>
                Show Views Only
              </strong>
              <div className="toggle-input">
                <label>
                  <div className="toggle-label">
                    <div className="fa fa-times" />
                    <div className="fa fa-check" />
                  </div>
                  <Toggle
                    defaultChecked={settings.hide_impressions}
                    icons={false}
                    onChange={(e) => {
                      settings.hide_impressions = !settings.hide_impressions;
                      this.setState({ updated: true });
                    }}
                  />
                </label>
              </div>
            </div>
          ) : null}
          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              share content dates
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={settings.share_content_dates}
                  icons={false}
                  onChange={() => {
                    settings.share_content_dates =
                      !settings.share_content_dates;
                    this.setState({ updated: true });
                  }}
                />
              </label>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <strong className="label-title" style={{ marginBottom: 15 }}>
              Show Goals
            </strong>
            <div className="toggle-input" style={{ display: "flex" }}>
              <strong className="small-title roi-toggle-title">
                By Budget
              </strong>
              <label>
                <Toggle
                  defaultChecked={settings.budget === "real"}
                  icons={false}
                  onChange={() => {
                    settings.budget =
                      settings.budget === "real" ? "media" : "real";
                    this.setState({});
                  }}
                />
              </label>
              <strong className="small-title roi-toggle-title">
                Money Spent
              </strong>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getInfluencerUsername = (influencers, id) => {
    const infl = influencers.find((inf) => inf.id === id);
    if (infl) {
      return infl.name;
    }
  };

  renderRoiComments() {
    const { comments } = this.state.roi;
    return (
      <div
        style={
          this.state.roi.share_enabled
            ? {}
            : { opacity: 0.5, pointerEvents: "none" }
        }
      >
        <div className="optinal-title" style={{ marginTop: 20 }}>
          For your convenience you can add comments in any part of the report,
          please note that there comments will be exposed to the report viewers.
        </div>

        <div style={{ marginTop: 25 }}>
          <strong className="label-title">KPI'S COMMENTS</strong>
          <MarkDownEditor
            value={comments.kpi}
            setValue={(text) => {
              comments.kpi = text;
            }}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <strong className="label-title">Tasks COMMENTS</strong>
          <MarkDownEditor
            value={comments.tasks}
            setValue={(text) => {
              comments.tasks = text;
            }}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <strong className="label-title">funnel COMMENTS</strong>
          <MarkDownEditor
            value={comments.funnel}
            setValue={(text) => {
              comments.funnel = text;
            }}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <strong className="label-title">segmentation's COMMENTS</strong>
          <MarkDownEditor
            value={comments.segmentation}
            setValue={(text) => {
              comments.segmentation = text;
            }}
          />
        </div>
      </div>
    );
  }

  renderRoiFilters() {
    const { filters } = this.state.roi;
    return (
      <div
        style={
          this.state.roi.share_enabled
            ? { height: 390 }
            : { opacity: 0.5, pointerEvents: "none", height: 390 }
        }
      >
        {filters?.influencers && (
          <>
            <div className="campaigns-title" style={{ marginTop: 20 }}>
              <strong className="label-title">Influencers</strong>
            </div>

            <div className="selected_list react-tagsinput">
              {filters.influencers &&
                filters.influencers.map((influencer, i) => (
                  <span className="react-tagsinput-tag" key={i}>
                    {this.getInfluencerUsername(
                      this.props.influencers,
                      influencer,
                    )}
                    <a
                      className="react-tagsinput-remove"
                      onClick={() => {
                        filters.influencers.addOrRemove(influencer);
                        this.setState({});
                      }}
                    />
                  </span>
                ))}
            </div>

            <Select
              className="searchable-input"
              multi={true}
              options={this.props.influencers.map((i) => {
                return { value: i.id, label: i.name };
              })}
              clearable={true}
              onChange={(e) => {
                if (filters.influencers.indexOf(e[0].value) === -1) {
                  filters.influencers.push(e[0].value);
                  this.setState({});
                }
              }}
              searchable={true}
            />
          </>
        )}

        <div style={{ marginTop: 20 }}>
          <strong className="label-title">Report Times</strong>
        </div>
        <div className={"flex"}>
          <Box>
            <div
              className="searchable-input"
              onClick={() => this.setState({ showDatePicker: true })}
            >
              <DatePicker
                readOnly={true}
                dateFormat={window.time.date_with_hour}
                className="input-line"
                isClearable={false}
                placeholderText="from"
                selected={
                  filters.time_filter.from
                    ? moment(filters.time_filter.from)
                    : null
                }
                onChange={(date) => {
                  filters.time_filter.from = date;
                  this.setState({});
                }}
              />
              <i className="far fa-calendar" />
            </div>
          </Box>
          <Box>
            <span
              style={{
                marginTop: 7,
                fontSize: 12,
                padding: "4px 10px",
                color: "#555",
                background: "gainsboro",
                borderRadius: "50%",
                width: 35,
                height: 35,
                margin: "2px 7px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              to
            </span>
          </Box>
          <Box>
            <div
              className="searchable-input"
              onClick={() => this.setState({ showDatePicker: true })}
            >
              <DatePicker
                readOnly={true}
                dateFormat={window.time.date_with_hour}
                className="input-line"
                isClearable={false}
                placeholderText="to"
                selected={
                  filters.time_filter.to ? moment(filters.time_filter.to) : null
                }
                onChange={(date) => {
                  filters.time_filter.to = date;
                  this.setState({});
                }}
              />
              <i className="far fa-calendar" />
            </div>
          </Box>
          {(filters.time_filter.from || filters.time_filter.to) && (
            <Box ml={5}>
              <Button
                variant="outline"
                onClick={() => {
                  filters.time_filter.from = null;
                  filters.time_filter.to = null;
                  this.setState({});
                }}
              >
                Clear
              </Button>
            </Box>
          )}
          <HumanzRangePicker
            buttonStyle={{
              placeSelf: "center",
              marginLeft: 10,
            }}
            open={this.state.showDatePicker}
            onClose={() => {
              this.setState({ showDatePicker: false });
            }}
            startDate={moment(filters.time_filter.from || Date.now()).toDate()}
            endDate={moment(filters.time_filter.to || Date.now()).toDate()}
            onChange={(res) => {
              if (res && res.startDate && res.endDate) {
                filters.time_filter.from = res.startDate;
                filters.time_filter.to = res.endDate;
              }
              this.setState({ showDatePicker: false });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
    board: state.boardReducer,
  };
};

export default connect(mapStateToProps, null)(ShareCampaignDialog);
