// TODO - lint
/* eslint-disable no-unused-vars, import/no-default-export, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase */
import React from "react";
import moment from "moment";

import AmbassadorPackages from "../ambassador/ambassadorPackages";
import AmbassadorSummary from "../ambassador/ambassadorSummary";

export default class RoiPackages extends React.PureComponent {
  render() {
    const { summaryData, current_package_data } = this.props;
    return (
      <div className="payment-settings">
        <div className="main-middle-container">
          {
            this.props.loaded && this.props.paymentData ? (
              <div className={"roi-main-section"}>
                <div className="roi-title blurable-large">
                  {summaryData
                    ? `${summaryData.campaign_details.title} - `
                    : ""}
                  <span>Ambassador report plan</span>
                </div>
                <div>
                  <p>This feature is no longer supported.</p>
                </div>

                {!this.props.newMode && current_package_data && (
                  <AmbassadorSummary
                    managePlanCallback={() => this.props.managePlanCallback()}
                    reportToStop={this.props.id}
                    next_billing_cycle={moment(
                      current_package_data.next_payment_date,
                    ).format(window.time.short_date)}
                    next_billing_payment={current_package_data.next_payment}
                    current_package={
                      current_package_data.name ||
                      current_package_data.package_name
                    }
                    total_ambassadors={current_package_data.influencers}
                  />
                )}
                {!this.props.newMode && (
                  <div className="create-buttons-row">
                    <button
                      className="btn btn-primry"
                      onClick={() => this.props.back()}
                    >
                      BACK
                    </button>
                  </div>
                )}
              </div>
            ) : (
              /* Loader */
              <div className="logo-loader">
                <div className="loader" />
                <img src={"/images/humanz-logo.png"} />
              </div>
            )
            /* End Loader */
          }
        </div>
      </div>
    );
  }
}
