/* eslint-disable no-script-url */
import OhNoImage from "../../images/ohno.png";

export default function PageCrash() {
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: 20,
          margin: "0 auto",
          textAlign: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h1>Oh no, something went wrong!</h1>
        <img src={OhNoImage} />
        <p
          style={{
            fontSize: "16px",
            marginTop: "22px",
            color: "#a8a8a8",
            width: "100%",
          }}
        >
          <br />
          <span style={{ fontSize: 26, color: "#3299c0" }}>
            We&apos;re sorry, but it looks like an error has accured.
          </span>
          <br />
          Our team has been notified of the issue.
          <br />
          In the meantime, please try{" "}
          <a href="#" onClick={() => window.location.reload()}>
            refreshing the page
          </a>
          .
        </p>
      </div>
    </div>
  );
}
