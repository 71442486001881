// Bug with axios on CI, import AxiosRequestConfig
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from "axios";
import { toast } from "react-toastify";
import {
  MultiReport,
  MultiReportFilterParameters,
  MultiReportShareParameters,
} from "../models/Api";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";

const reportsBaseUrl = () => `${getBackendUri()}/moi/multi_report`;
export class MultiReportActions {
  static getMultiReport =
    (report_id: number, callback: any) => (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .get(`${reportsBaseUrl()}/${report_id}`, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
          if (callback && response?.data) callback(response.data);
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to get report",
            "system_notifications_failure_get_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static createMultiReport =
    (data: MultiReport, callback: any) => (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .post(`${reportsBaseUrl()}`, data, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback && response.data) callback(response.data);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to submit report",
            "system_notifications_failure_save_report",
          );

          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static updateMultiReport =
    (report_id: number, data: MultiReport, callback: any) =>
    (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .put(`${reportsBaseUrl()}/${report_id}`, data, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback && response.data) callback(response.data);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to submit report",
            "system_notifications_failure_save_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static suggestMultiReportKpis =
    (data: any, callback: any) => (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .post(`${reportsBaseUrl()}/suggest_kpis_target`, data, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback && response.data) callback(response.data);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to submit report",
            "system_notifications_failure_save_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static getMultiReportWithFilters =
    (
      report_id: number,
      filters: MultiReportFilterParameters,
      queryId: string,
      callback: any,
    ) =>
    (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .post(`${reportsBaseUrl()}/${report_id}/report`, filters, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback && response.data)
            callback({ ...response.data, queryId });
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to submit report",
            "system_notifications_failure_save_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static getMultiReportShareParams =
    (report_id: number, callback: any) => (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .get(`${reportsBaseUrl()}s/${report_id}/share`, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback && response.data) callback(response.data);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to get share data",
            "system_notifications_failure_save_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static setMultiReportShareParams =
    (report_id: number, params: MultiReportShareParameters, callback: any) =>
    (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .put(`${reportsBaseUrl()}s/${report_id}/share`, params, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback && response.data) callback(response.data);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to submit report",
            "system_notifications_failure_save_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static shareMultiReport =
    (
      report_id: number,
      shared_guid: string,
      params: MultiReportShareParameters,
      callback: any,
    ) =>
    (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .put(`${reportsBaseUrl()}s/${report_id}/share/${shared_guid}`, params, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback && response.data) callback(response.data);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to submit report",
            "system_notifications_failure_save_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };
}
