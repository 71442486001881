import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Select from "react-select";
import { AcademyCourseProgress } from "./academyCourseProgress";
import { Course, CourseSummary } from "../../../models/Api";
import { AcademyActions } from "../../../actions/academyActions";

interface OwnProps {
  courses: Array<CourseSummary>;
  subscribeToCourse: any;
  current_course: Course;
  navigateToLesson: any;
  course_index: number;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { navigate: NavigateFunction };

type State = Readonly<{
  selectedLang: any;
  translation: any;
}>;

class AcademySubscribeInner extends PureComponent<Props, State> {
  readonly state: State = {
    selectedLang: "en",
    translation: null,
  };

  private langs: Array<{ label: string; value: string }>;

  constructor(props: Props) {
    super(props);
    this.langs = [
      { label: "English", value: "en" },
      { label: "Português", value: "pt" },
      { label: "Türkçe", value: "tr" },
      { label: "עברית", value: "he" },
    ];
  }

  componentDidMount(): void {
    this.setState({
      translation: this.getTranslationByLangSelection(
        this.props.courses[this.props.course_index],
      ),
    });
  }

  subscribeToCourse = (id: number) => {
    this.props.subscribeToCourse(id, { subscribe: true }, () => {
      this.props.navigate(`/academy/${id}`);
      window.location.reload();
    });
  };

  getTranslationByLangSelection = (course: CourseSummary) => {
    return course?.summary_per_language?.find(
      (trans: any) => trans.language === (this.state.selectedLang || "en"),
    );
  };

  render() {
    if (!this.props.courses || !this.props.courses[this.props.course_index])
      return <></>;
    const translation =
      this.state.translation ||
      this.getTranslationByLangSelection(
        this.props.courses[this.props.course_index],
      );
    const course = this.props.courses[this.props.course_index];
    const langs = this.langs.map((l) => l.value);
    const langsSelection = this.langs.filter((lang, i) => {
      if (!course.summary_per_language[i]) return false;
      return langs.includes(lang.value);
    });
    return (
      <div className={"academy-box"}>
        <div className="academy-title">
          <div className="academy-title-big">
            <i className="fa fa-laptop" /> Humanz <strong>Academy</strong>
          </div>
          <div>{course.eng_name}</div>
        </div>
        <div className="academy-course">
          {this.renderStatus(course, translation, langsSelection)}
        </div>
      </div>
    );
  }

  private renderStatus(
    course: any,
    translation: any,
    langsSelection: { label: string; value: string }[],
  ): React.ReactNode {
    if (!course.subscription_status) {
      return (
        <>
          <div className="academy-course-text">
            Haven&#39;t started the course yet?
          </div>
          {translation && (
            <>
              <div className="academy-course-action">
                {langsSelection.length > 1 && (
                  <Select
                    clearable={false}
                    placeholder={"Language"}
                    wrapperStyle={{ width: 130, marginRight: 10 }}
                    value={this.state.selectedLang}
                    options={langsSelection}
                    onChange={(lang: any) =>
                      this.setState(
                        {
                          selectedLang: lang.value,
                        },
                        () => {
                          this.setState({
                            translation: this.getTranslationByLangSelection(
                              this.props.courses[this.props.course_index],
                            ),
                          });
                        },
                      )
                    }
                  />
                )}
              </div>
              <button
                disabled={!this.state.selectedLang}
                className="btn btn-big btn-success clickable"
                onClick={() => this.subscribeToCourse(translation.id)}
              >
                START HERE
              </button>
            </>
          )}
        </>
      );
    }
    if (course.subscription_status.is_completed) {
      return (
        <>
          <div className="course-completed success">
            <i className="fa fa-check" />
            <div>
              <strong>Congratulations!</strong>
              <span>You have successfully completed the course</span>
            </div>
          </div>
        </>
      );
    }
    return (
      this.props.current_course &&
      this.props.current_course.id && (
        <AcademyCourseProgress
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          current_course={this.props.current_course}
          navigate_to_lesson={this.props.navigateToLesson}
        />
      )
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.userReducer,
    courses: state.academyReducer.courses,
    current_course: state.academyReducer.current_course,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  subscribeToCourse: (id: number, data: any, callback: any) => {
    dispatch(AcademyActions.subsribeToCourseTranslation(id, data, callback));
  },
});

const AcademySubscribeConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcademySubscribeInner);

export const AcademySubscribe = ((props) => {
  const navigate = useNavigate();

  return <AcademySubscribeConnected {...props} navigate={navigate} />;
}) as React.FC<OwnProps>;
