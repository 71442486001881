import axios from "axios";
// eslint-disable-next-line import-access/jsdoc
import { postUtilsWrite_log } from "src/api/services";
import { store } from "../store";
import { getBackendUri, getHeaders } from "./commonActionsConf";

export const getMediaId = (
  originalUrl: string,
  postType: string,
  callback: any = () => {},
) => {
  axios
    .post(
      `${getBackendUri()}/moi/get_media_id`,
      { original_url: originalUrl, post_type: postType },
      getHeaders(),
    )
    .then((r: any) => {
      callback(r.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const getTipaltiLink = (guid: string, callback: any) => {
  return () => {
    axios
      .get(`${getBackendUri()}/tipalti/generate_external_link/${guid}`)
      .then((response) => {
        if (response && response.data) {
          if (callback) callback(response.data);
        } else {
          callback(null);
        }
      })
      .catch((err) => {
        console.log(err);
        if (callback) callback(null);
      });
  };
};

type LogData = {
  message: string;
  log_level?: "info" | "error";
  event_name?: string;
  event_value_str?: string;
  event_value_number?: string;
};

// eslint-disable-next-line consistent-return
export const writeLog = (data: LogData, callback?: any) => {
  try {
    const logData: any = {
      user_id: store.getState()?.userReducer?.id,
      phone_id: "0",
      message: data?.message,
      phone_type: "android",
      log_level: data?.log_level || "info",
      app_window: window.location.href,
      method_name: "frontend",
      version: "1",
      event_name: data?.event_name || "log",
      event_value_str: data?.event_value_str ?? null,
      event_value_number: data?.event_value_number
        ? Number(data?.event_value_number)
        : null,
      user_country:
        store.getState()?.userReducer?.userDetails?.company_details?.country,
      feed_post_id: null,
    };

    postUtilsWrite_log(logData)
      .then(() => {
        if (callback) callback(true);
      })
      .catch(() => {
        if (callback) callback(false);
      });
  } catch (e: any) {
    console.log("Unable to write log", e);
  }
};
