import { Box, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import DetailsIcon from "../../../Icons/DetailsIcon";

const TaskDescription = (props: {
  taskData: { description?: string };
  setTaskData: (arg0: any) => void;
}) => {
  const [text, setText] = useState(props.taskData.description);
  const [timerId, setTimerId] = useState(null);
  const handleChange = (event: { target: { value: any } }) => {
    const textareaValue = event.target.value;
    setText(textareaValue);

    // Clear previous timeout (if any)
    if (timerId) {
      clearTimeout(timerId);
    }

    // Set a new timeout to save the data after 0.5 seconds
    const newTimerId = setTimeout(() => {
      props.setTaskData({
        ...props.taskData,
        description: textareaValue,
      });
    }, 500);

    // Store the new timerId in state
    setTimerId(newTimerId);
  };

  return (
    <>
      <Box position="relative" minHeight="50px" minWidth="100px">
        <Textarea
          minHeight="80px"
          minWidth="100px"
          paddingLeft={text === "" ? "20px" : "5px"} // Use conditional (ternary) operator
          placeholder="Details"
          value={text}
          onChange={handleChange}
          border="none"
          fontSize="12px"
          background="white"
        />
        {text === "" && (
          <Box
            position="absolute"
            width="15px"
            height="15px"
            left="5px"
            top="5px"
            zIndex="1"
          >
            <DetailsIcon />
          </Box>
        )}
      </Box>
    </>
  );
};

export default TaskDescription;
