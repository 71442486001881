// General Imports
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FileTypes,
  FileUpload,
  uploadFileSizeValidator,
} from "src/ui/FileUpload";
import { addNewList, editList } from "../../../actions/premiumActions";

interface Props {
  id?: string;
  menu?: boolean;
  onBack?: () => void;
}

export const CreateListSection: React.FC<Props> = ({ id, menu, onBack }) => {
  const lists: {
    id: number;
    title: string;
    image_url: string;
    description: string;
  }[] = useSelector((state) => state.listsReducer.lists);
  const list = id ? lists.find((x) => x.id === Number(id)) : undefined;

  const [title, setTitle] = useState<string>(list?.title ?? "");
  const [saving, setSaving] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoBack = useCallback<() => void>(() => {
    if (onBack) onBack();
    else navigate(-1);
  }, [navigate, onBack]);

  const handleFormSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const target = e.target as HTMLFormElement;
      const listTitle = (target.listTitle as HTMLInputElement).value;
      const listDesc = (target.listDesc as HTMLInputElement).value;

      if (list) list.title = listTitle;
      const details = {
        name: listTitle,
        description: listDesc,
        upload_image: imageUrl ? { url: imageUrl } : null,
      };
      setSaving(true);
      if (id) {
        dispatch(editList(id, details, handleGoBack));
      } else dispatch(addNewList(details, handleGoBack));
    },
    [dispatch, handleGoBack, id, imageUrl, list],
  );

  const handleTitleChange = useCallback<
    React.FormEventHandler<HTMLInputElement>
  >((e) => {
    const titleValue = e.currentTarget.value;
    const titleLimited = titleValue.substring(0, 50);
    setTitle(titleLimited);
  }, []);

  const handleFileUploaded = useCallback<(newImageUrl: string) => void>(
    (newImageUrl) => {
      setImageUrl(newImageUrl);
      if (list) list.image_url = newImageUrl;
    },
    [list],
  );

  return (
    <>
      <div className="create-list-section-main">
        {!menu && <div className="section-top-bar"></div>}
        <div
          className="create-tab-content"
          style={menu ? { paddingTop: 30, width: "auto" } : {}}
        >
          <form onSubmit={handleFormSubmit}>
            <div style={{ display: "flex" }}>
              <div
                className="new-list-inputs"
                style={menu ? { marginLeft: 5, marginRight: 5 } : {}}
              >
                <div className="campaigns-title">
                  <span>{list ? "Edit List" : "Create New List"}</span>
                </div>

                {/* Description Section */}
                <div
                  className="create-description-form"
                  style={{ marginTop: 30 }}
                >
                  <div className="input-row">
                    <strong className="label-title">list name</strong>
                    <div>
                      <input
                        name="listTitle"
                        className="input-line"
                        value={title}
                        onChange={handleTitleChange}
                        autoFocus
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <strong className="label-title">list description</strong>
                    <div>
                      <input
                        name="listDesc"
                        className="input-line"
                        defaultValue={list?.description}
                        placeholder="Describe your new list"
                      />
                    </div>
                  </div>
                  <div
                    className="create-buttons-row"
                    style={{ paddingTop: 20 }}
                  >
                    <button
                      type="button"
                      className="btn btn-primry"
                      onClick={handleGoBack}
                    >
                      BACK
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primry"
                      disabled={!title || saving}
                    >
                      {list ? "Save" : "Create"}
                    </button>
                  </div>
                </div>
                {/* End Description Section */}
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "70px",
                  flex: "0 0 200px",
                  height: "200px",
                }}
              >
                <FileUpload
                  placeholder="List logo"
                  variant="round"
                  fileType={FileTypes.image}
                  previewFileUrl={list?.image_url ? list.image_url : imageUrl}
                  onFileUploaded={handleFileUploaded}
                  validator={uploadFileSizeValidator}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
