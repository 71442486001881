// TODO - lint
/* eslint-disable class-methods-use-this, camelcase, camelcase, camelcase, prefer-destructuring, camelcase, prefer-destructuring, no-empty, camelcase, camelcase, camelcase, camelcase, camelcase, no-underscore-dangle, no-restricted-syntax, guard-for-in, camelcase, camelcase, no-unused-vars, react/no-direct-mutation-state, import/no-default-export */
import Dialog from "rc-dialog";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { advertiserSupport } from "../../../actions/userActions";
import SingleImageDropZone from "../general_components/SingleImageDropZone";

import CheckBox from "../general_components/CheckBox";

class SupportDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      generalData: {
        name: "",
        contact_email: "",
        message: "",
        attachments: [],
        campaign_id: "",
        list_id: "",
      },
      notificationData: {
        gender: "both",
        min_followers: 0,
        notification_text: "",
      },
      issueType: "general",
      error: "",
    };
  }

  userDetails = {};

  issuesOptions = [
    { label: "General Issue", value: "general" },
    { label: "Notification Request", value: "notification" },
  ];

  getSupportDetails() {
    const data = localStorage.getItem("support_details");
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  getUserSupportDetails(userId) {
    const data = this.getSupportDetails();
    if (data && data[userId]) {
      return data[userId];
    }
    return {};
  }

  componentDidMount() {
    this.userDetails = {
      urlSubmittedFrom: document.location.href,
      advertiserType: this.props.user.advertiser_type,
      userId: this.props.user.user_id,
      contactName: this.props.user.full_name,
      isPremium: this.props.user.sidebar_data.is_premium,
      hasCredit: this.props.user.sidebar_data.has_credit_card,
      companyName: this.props.user.company_details.name,
      country: this.props.user.company_details.country,
      accountEmail: this.props.user.email,
      userAgent: window.navigator.userAgent,
    };

    const path = document.location.pathname;
    let list_id = "";
    let campaign_id = "";
    try {
      if (path.includes("/list/")) {
        list_id = path.split("/")[2];
      } else if (path.includes("/campaign/")) {
        campaign_id = path.split("/")[2];
      }
    } catch (e) {}

    const { name, contact_email } = this.getUserSupportDetails(
      this.props.user.user_id,
    );
    const newData = { list_id, campaign_id };
    if (name && contact_email) {
      newData.name = name;
      newData.contact_email = contact_email;
    }
    this.setState({ generalData: { ...this.state.generalData, ...newData } });
  }

  parseUserDetails = (br) => {
    let _br = "\n";
    if (br) _br = "<br/>";
    let str = "";
    for (const property in this.userDetails) {
      str += `${property}: ${this.userDetails[property]}${_br}`;
    }

    let attachments = "";
    this.state.generalData.attachments.forEach((a) => {
      attachments += `${_br}${a}`;
    });
    str += `${_br}attachments: ${attachments}${_br}`;

    return str;
  };

  submitForm = () => {
    if (this.state.loading) return;
    // Init data
    const data = {
      ...this.state.generalData,
    };

    let extended = false;
    if (this.props.user.sidebar_data.allowed_extended_support) {
      extended = true;
      data.notification_request =
        this.state.issueType === "notification"
          ? this.state.notificationData
          : null;
      data.list_segmentation =
        this.state.issueType === "segmentation"
          ? { list_id: this.state.generalData.list_id }
          : null;
    }

    // Verifications
    let error = "";
    if (!data.name.trim()) error = "Please fill out your name";
    if (!data.contact_email) error = "Please fill valid email";
    if (!data.message && this.state.issueType !== "segmentation") {
      error = "Please add a message";
    }
    if (!data.list_id && this.state.issueType === "segmentation") {
      error = "Please add the list id (from the url)";
    }
    if (
      data.notification_request &&
      !data.notification_request.notification_text
    ) {
      error = "Please enter the notification text";
    }
    if (error) {
      this.setState({ error });
      return; // Exit from the function if error found
    }

    // Adding extended data
    if (data.notification_request) {
      data.message = `${data.notification_request.notification_text}\n\n\n==============================\n${data.message}`;
    }
    data.message += `\n\n\n\n==============================\n${this.parseUserDetails()}`;

    // Sending to BE
    this.setState({ loading: true, error });
    this.props.advertiserSupport(data, extended, (success) => {
      this.setState({ loading: false });
      if (success) {
        this.props.handleClose();
      }
    });
    this.saveUserSupportDetails();
  };

  saveUserSupportDetails() {
    const userId = this.props.user.user_id;
    const data = {
      name: this.state.generalData.name,
      contact_email: this.state.generalData.contact_email,
    };

    let details = this.getSupportDetails();
    if (!details) {
      details = {};
    }
    details[userId] = data;
    localStorage.setItem("support_details", JSON.stringify(details));
  }

  renderNotificationFields() {
    const { notificationData, loading } = this.state;
    return (
      <div className={loading ? "disabled" : ""}>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginTop: 25 }}>
            <strong className="label-title">minimum followers</strong>
            <div style={{ width: 300 }}>
              <input
                defaultValue={notificationData.min_followers}
                onChange={(e) =>
                  this.setState({
                    notificationData: {
                      ...notificationData,
                      min_followers: e.target.value,
                    },
                  })
                }
                className="input-line"
                type="number"
                dir="auto"
              />
            </div>
          </div>
          <div style={{ width: 300 }}>
            <strong
              className="label-title"
              style={{ marginTop: 17, marginBottom: 10 }}
            >
              Gender
            </strong>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckBox
                style={{}}
                label={"Female"}
                checked={
                  notificationData.gender === "female" ||
                  notificationData.gender === "both"
                }
                onChange={() => {
                  notificationData.gender =
                    notificationData.gender === "both" ? "male" : "both";
                  this.setState({ updated: true });
                }}
              />
              <CheckBox
                style={{ marginLeft: 50 }}
                label={"Male"}
                checked={
                  notificationData.gender === "male" ||
                  notificationData.gender === "both"
                }
                onChange={() => {
                  notificationData.gender =
                    notificationData.gender === "both" ? "female" : "both";
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <label htmlFor="message">Notification Text</label>
          <br />
          <textarea
            maxLength={250}
            style={{ minHeight: 60, border: "1px solid #249ff0" }}
            className={"input-line"}
            required
            onChange={(t) =>
              this.setState({
                notificationData: {
                  ...notificationData,
                  notification_text: t.target.value,
                },
              })
            }
            defaultValue={notificationData.notification_text}
          />
        </div>
      </div>
    );
  }

  render() {
    const { generalData } = this.state;
    return (
      <Dialog
        maskClosable={false}
        onClose={this.props.handleClose}
        className="support-dialog"
        visible={true}
        style={{ width: "700px", margin: "25px auto" }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div className="support-dialog-inner">
          <div className="edit-tasks-header">Support</div>
          <div style={{ fontSize: 16, margin: "20px 0" }}>
            Having issues with the system? <br /> Please elaborate about the
            problem and we will step in to help!
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginBottom: 15 }}>
              <label htmlFor="message">Name</label>
              <br />
              <input
                style={{ width: "315px" }}
                className="input-line"
                defaultValue={generalData.name}
                onChange={(t) =>
                  this.setState({
                    generalData: { ...generalData, name: t.target.value },
                  })
                }
                dir="auto"
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <label htmlFor="message">Contact Email</label>
              <br />
              <input
                style={{ width: "315px" }}
                className="input-line"
                defaultValue={generalData.contact_email}
                onChange={(t) =>
                  this.setState({
                    generalData: {
                      ...generalData,
                      contact_email: t.target.value.trim(),
                    },
                  })
                }
                dir="auto"
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginBottom: 15 }}>
              <label htmlFor="message">
                Campaign ID <span className="optional-title">(Optional)</span>
              </label>
              <br />
              <input
                type="number"
                style={{ width: "315px" }}
                className="input-line no-spinners"
                defaultValue={generalData.campaign_id}
                onChange={(t) =>
                  this.setState({
                    generalData: {
                      ...generalData,
                      campaign_id: t.target.value.trim(),
                    },
                  })
                }
                dir="auto"
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <label htmlFor="message">
                List ID{" "}
                {this.state.issueType !== "segmentation" && (
                  <span className="optional-title">(Optional)</span>
                )}
              </label>
              <br />
              <input
                type="number"
                style={{ width: "315px" }}
                className="input-line no-spinners"
                defaultValue={generalData.list_id}
                onChange={(t) =>
                  this.setState({
                    generalData: {
                      ...generalData,
                      list_id: t.target.value.trim(),
                    },
                  })
                }
                dir="auto"
              />
            </div>
          </div>

          {this.props.user.sidebar_data.allowed_extended_support ? (
            <div style={{ marginBottom: 15 }}>
              <strong className="label-title">Issue</strong>
              <div style={{ width: 300 }} className="searchable-input">
                <Select
                  // eslint-disable-next-line react/no-string-refs
                  ref="stateSelect"
                  options={this.issuesOptions}
                  placeholder={""}
                  value={this.state.issueType}
                  simpleValue
                  clearable={false}
                  onChange={(value) => this.setState({ issueType: value })}
                  disabled={false}
                  searchable={false}
                />
              </div>
            </div>
          ) : null}

          {this.state.issueType === "notification" &&
            this.renderNotificationFields()}

          {!this.state.loading && (
            <div className="input-row">
              <label htmlFor="message">Write your message here:</label>
              <br />

              <textarea
                style={{ minHeight: 120, border: "1px solid #249ff0" }}
                className={"input-line"}
                required
                onChange={(t) =>
                  this.setState({
                    generalData: { ...generalData, message: t.target.value },
                  })
                }
                defaultValue={generalData.message}
                name="message"
                id="message"
                dir="auto"
              />

              {this.state.issueType !== "notification" && (
                <SingleImageDropZone
                  paddingTop={80}
                  upload_title={"attachments"}
                  image_uploaded={this.image_uploaded}
                  callback={(image_url, mime_type) => {
                    this.state.generalData.attachments.push(image_url);
                    this.state.image_uploaded = null;
                    this.setState({});
                  }}
                  accept={{
                    "image/jpeg": [],
                    "image/png": [],
                    "video/*": [],
                    "application/pdf": [],
                    "application/msword": [],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      [],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      [],
                    "application/vnd.ms-excel": [],
                    "application/vnd.ms-powerpoint": [],
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                      [],
                  }}
                />
              )}
            </div>
          )}

          {this.state.issueType !== "notification" && (
            <div className="attachments">
              {generalData.attachments &&
                generalData.attachments.map((at, i) => (
                  <div className="single-attachment" key={`a_${i}`}>
                    {
                      <div
                        className="moi-ximage delete-row"
                        onClick={() => {
                          this.state.generalData.attachments.addOrRemove(at);
                          this.setState({});
                        }}
                      />
                    }
                    <img src={at} alt="" />
                  </div>
                ))}
            </div>
          )}
          <div className="clearfix" />
          {this.state.loading && <div>Sending your message....</div>}
          {/* <div style={{marginTop:20, fontSize:10, color:'gray'}}> */}
          {/*    <div style={{textDecoration:'underline',cursor:'pointer'}}>Click here to see the details our support team will receive</div> */}
          {/*    <div dangerouslySetInnerHTML={{__html:parseUserDetails(true)}}/> */}
          {/* </div> */}

          {this.state.error && (
            <div style={{ color: "#f44336" }}>{this.state.error}</div>
          )}
          <div
            className="preview-footer-buttons"
            style={{ marginTop: 0, justifyContent: "center" }}
          >
            <button
              disabled={this.state.loading}
              className={"btn btn-pirmary "}
              style={{ width: "auto" }}
              type="submit"
              onClick={() => {
                this.submitForm();
              }}
            >
              Submit
            </button>
            <button
              className={"btn btn-pirmary cancel "}
              style={{ width: "auto" }}
              onClick={() => this.props.handleClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    advertiserSupport: (data, extended, callback) => {
      dispatch(advertiserSupport(data, extended, callback));
    },
  };
};

export default connect(null, mapDispatchToProps)(SupportDialog);
