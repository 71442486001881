import { Box, Button } from "@chakra-ui/react";
// import { Input } from "@material-ui/core";
import { useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { saveToTemplate } from "src/api/actions/auctionActions";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import AddArrow from "../../UI/AddArrow";
import AddTask from "../Task/AddTask/AddTask";
import AddTaskButton from "../Task/AddTask/AddTaskButton";
import Tasks from "../Task/Tasks";

const BuildNewTemplate = (props: any) => {
  const [todoState, setTodoState] = useState("All");
  const [typeTasksFilter, setTypeTasksFilter] = useState("All Types");
  const [showEditTask, setShowAdd] = useState(false);
  const [tasks, setTasks] = useState<AuctionToDoItem[]>([]);
  const [saveButtonClicked, setButtonClicked] = useState(false);
  const [countId, setCountId] = useState(1);
  const params = useParams();

  const campaignId = params?.id;

  const handleAddTask = () => {
    setShowAdd(true);
  };

  const ToDoActions = [
    {
      action: () => {
        setTodoState("All");
      },
      text: "All",
    },
    {
      action: () => {
        setTodoState("ToDo");
      },
      text: "ToDo",
    },
    {
      action: () => {
        setTodoState("Done");
      },
      text: "Done",
    },
  ];
  const TypesActions = [
    {
      action: () => {
        setTypeTasksFilter("All Types");
      },
      text: "All Types",
    },
    {
      action: () => {
        setTypeTasksFilter("General");
      },
      text: "General",
    },
    {
      action: () => {
        setTypeTasksFilter("Brief");
      },
      text: "Brief",
    },
    {
      action: () => {
        setTypeTasksFilter("List");
      },
      text: "List",
    },
    {
      action: () => {
        setTypeTasksFilter("Manage");
      },
      text: "Manage",
    },

    {
      action: () => {
        setTypeTasksFilter("ROI");
      },
      text: "ROI",
    },
  ];
  const addId = () => {
    const newCountId = countId + 1;
    setCountId(newCountId);

    return newCountId;
  };
  const handleSaveAddTask = (taskData: AuctionToDoItem) => {
    if (campaignId) {
      const taskDataToSend = { ...taskData };
      taskDataToSend.id = addId();
      if (taskDataToSend.due_date)
        taskDataToSend.due_date = new Date(
          taskDataToSend.due_date,
        ).toISOString();
      setTasks([taskDataToSend, ...tasks]);
    }
  };

  const handleDeleteTask = (taskId: number) => {
    console.log(tasks);
    const updatedTasks = tasks.filter(
      (task: AuctionToDoItem) => task.id !== taskId,
    );
    setTasks(updatedTasks);
  };

  const handleCheckboxChange = async () => {
    console.log("can't check template task");
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const resetTasks = (tasks: AuctionToDoItem[]) => {
    const tasksForTemplate = tasks.map((task: AuctionToDoItem) => {
      // const { due_date: string, ...rest } = task;
      const updatedTask: AuctionToDoItem = {
        ...task,
        completed: false,
        due_date: null,
      };
      return updatedTask;
    });
    return tasksForTemplate;
  };
  const handleAddTemplate = (templatName: string) => {
    const taskToTemplate = resetTasks(tasks);
    const template = {
      title: templatName,
      description: "",
      is_global: true,
      tasks: taskToTemplate,
    };
    saveToTemplate(template)
      .then(() => {
        toast.success("Template was created successfully");
        props.setStep("import-template");
      })
      .catch(() => {
        toast.error("Error couldn't add template");
      });
  };

  const filteredTasks = useMemo(() => {
    if (todoState === "All")
      if (typeTasksFilter === "All Types") {
        return tasks;
      } else {
        return tasks.filter(
          (task: AuctionToDoItem) => task.tag === typeTasksFilter,
        );
      }

    const completed = todoState === "Done";
    if (typeTasksFilter === "All Types") {
      return tasks.filter(
        (task: AuctionToDoItem) => task.completed === completed,
      );
    }

    return tasks.filter(
      (task: AuctionToDoItem) =>
        task.tag === typeTasksFilter && task.completed === completed,
    );
  }, [tasks, typeTasksFilter, todoState]);

  const handleNext = () => {
    if (!saveButtonClicked) {
      setButtonClicked(true);
      handleAddTemplate(props.templateName);
    }
  };
  const handleEditTask = (taskId: number, taskData: AuctionToDoItem) => {
    // editTodoItem(Number(campaignId), taskId, taskData);
    const updatedTasks = tasks.map((task: AuctionToDoItem) => {
      if (task.id === taskId) {
        // If the task id matches, update the taskData
        return taskData;
      }
      return task; // For other tasks, return as-is
    });
    setTasks(updatedTasks);
  };

  const OnDragEnd = (result: { destination: any; source: any }) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    const newFilteredTasks = Array.from(filteredTasks) as AuctionToDoItem[];
    const sourceTask = filteredTasks[source.index];
    const destTask = filteredTasks[destination.index];
    const sourceIndex = newFilteredTasks.findIndex(
      (t) => t.id === sourceTask.id,
    );
    const destIndex = newFilteredTasks.findIndex((t) => t.id === destTask.id);
    const originalIndexes = newFilteredTasks.map((x) => x.order);
    if (sourceIndex < destIndex) {
      let fromIndex = sourceIndex + 1;

      while (fromIndex <= destIndex) {
        newFilteredTasks[fromIndex].order = originalIndexes[fromIndex - 1];
        fromIndex += 1;
      }
    } else {
      let fromIndex = destIndex;
      while (fromIndex < sourceIndex) {
        newFilteredTasks[fromIndex].order = originalIndexes[fromIndex + 1];
        fromIndex += 1;
      }
    }
    newFilteredTasks[sourceIndex].order = originalIndexes[destIndex];

    const filteredTaskIds = filteredTasks.map((x: any) => x.id);
    let updatedTasks = Array.from(tasks) as AuctionToDoItem[];
    updatedTasks = updatedTasks.map((t) => {
      if (filteredTaskIds.includes(t.id)) {
        return newFilteredTasks.filter((x) => x.id === t.id)[0];
      }

      return t;
    });
    updatedTasks.sort((a, b) => b.order - a.order);
    setTasks(updatedTasks);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <HumanzActionsMenu
            buttontype="button"
            actions={ToDoActions}
            // buttontext={todoState}
            buttontext={<span style={{ color: "#249FF0" }}>{todoState}</span>}
          />
          <HumanzActionsMenu
            buttontype="button"
            actions={TypesActions}
            buttontext={
              <span style={{ color: "#808080" }}>{typeTasksFilter}</span>
            }
          />
        </Box>
      </Box>

      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#EBEBEB",
          marginTop: "5px",
          marginBottom: "10px",
        }}
      ></div>

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <AddTaskButton onClick={handleAddTask} />
        {showEditTask && (
          <AddTask
            setShowAdd={setShowAdd}
            tasks={tasks}
            setTasks={setTasks}
            callBack={handleSaveAddTask}
          />
        )}
        <DragDropContext onDragEnd={OnDragEnd}>
          <Tasks
            displayTasks={filteredTasks}
            setTasks={setTasks}
            deleteTask={handleDeleteTask}
            setCompleted={handleCheckboxChange}
            tasks={tasks}
            campaignId={campaignId}
            dragDropEnabled={true}
            handleEditTask={handleEditTask}
            todoState={todoState}
            disableCheckBox={false}
            maxHeight={"calc(100vh - 300px)"}
          />
        </DragDropContext>

        <AddArrow showEditTask={showEditTask} tasksLength={tasks.length} />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {tasks.length > 0 && (
            <Button
              style={{
                margin: "10px",

                borderRadius: "50px",
                width: "136px",
                fontWeight: 400,
              }}
              onClick={handleNext}
              disabled={saveButtonClicked}
            >
              Save template
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BuildNewTemplate;
