/* eslint-disable no-nested-ternary */
import { Box } from "@chakra-ui/react";

type Props = {
  auction: any;
};

export const CampaignTypeTag = (props: Props) => {
  const type = props?.auction?.one_month_campaign
    ? "One time"
    : props?.auction?.is_pixel_enabled
    ? "Sales"
    : "Monthly";
  const icon = (
    <i
      className={`icon fa-${
        type === "Sales"
          ? "badge-percent fas"
          : type === "Monthly"
          ? "calendar-alt fad"
          : "megaphone fad"
      }`}
    />
  );
  return props?.auction?.campaign_state !== "brief" ? (
    <Box
      className={`campaign-type-tag ${
        type === "Sales" ? "sales" : type === "Monthly" ? "monthly" : "one-time"
      }`}
    >
      {icon} {type}
    </Box>
  ) : null;
};
