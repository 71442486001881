// TODO - lint
/* eslint-disable no-unused-vars, func-style, camelcase, no-unused-vars, camelcase, import/no-default-export, no-unused-vars, no-param-reassign, react/no-string-refs, react/no-string-refs */
import React from "react";
import Select from "react-select";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import DatePicker from "react-datepicker";

import { Box } from "@chakra-ui/react";
import moment from "moment";
import HumanzRangePicker from "../../../../../ui/humanz/HumanzRangePicker";

function getFilterDataObject(influencers, auctions, time_filter) {
  const tmpDate = new Date();
  return {
    influencers: influencers || [],
    auctions: auctions || [],
    time_filter: time_filter || {
      from: null,
      to: null,
      amount: 1,
      offset: 0,
    },
  };
}

const filterMap = [
  {
    type: "date-range",
    label: "Date",
    isOpen: true,
    filteredData: "dateTime",
  },
  {
    type: "auctions",
    label: "Campaigns",
    isOpen: true,
  },
  {
    type: "influencers",
    label: "Influencers",
    isOpen: true,
  },
];

export default class MultiReportFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  initState() {
    return {
      filters: this.props.filters ? this.props.filters : getFilterDataObject(),
      isLoading: false,
      influencers: [],
      auctions: [],
      filteredData: getFilterDataObject(),
      showFilters: false,
      showDatePicker: false,
      filterMap,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && prevProps.loading !== this.props.loading) {
      this.setState({ filters: this.props.filters });
    }
  }

  triggerRoiUpdateState() {
    this.props.updateState({ filters: this.state.filteredData });
  }

  toggleFilterMenu = (close) => {
    if (this.state.showFilters || close) {
      const element = document.getElementById("list-filters");
      element.classList.remove("fadeInRight");
      element.classList.add("fadeOutRight");
      window.setTimeout(() => {
        this.setState({ showFilters: false });
      }, 500);
    } else {
      this.setState({ showFilters: true });
    }
  };

  renderFiltersMenu() {
    return (
      <div
        className="influencer-search-filters animated fadeInRight faster"
        id="report-filters"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Filter</div>
          <div
            className="filter-close-button"
            onClick={() => this.toggleFilterMenu(true)}
          >
            <span className="moi-ximage" />
          </div>
        </div>
        <div
          className="filter-refine-tab-spacer"
          style={{ marginBottom: 20 }}
        />
        <button
          onClick={() => {
            const newState = { ...this.state };
            this.setState({ filteredData: getFilterDataObject() }, () =>
              this.triggerRoiUpdateState(),
            );
          }}
          className="btn clear-btn btn-primary"
        >
          <strong>CLEAR FILTERS</strong>
        </button>
        <div
          className="filter-refine-tab-spacer"
          style={{ marginBottom: 20 }}
        />
        <div className="clearfix"></div>
        {this.state.filterMap.map((item, j) => (
          <div key={j}>
            <div className="small-title">
              <strong className="upper-case">{item.label}</strong>
              <div
                onClick={() => {
                  item.isOpen = !item.isOpen;
                  this.setState({ updated: true });
                }}
                className={`row-collapse ${
                  item.isOpen ? "moi-minus" : "moi-plus"
                }`}
              />
            </div>
            {item.isOpen && (
              <div className="inner-filter-content">
                {item.type === "influencers" && (
                  <>
                    <div className="selected_list react-tagsinput">
                      {this.state.filteredData.influencers.map((id, i) => (
                        <span className="react-tagsinput-tag" key={i}>
                          {
                            this.state.filters.influencers.find(
                              (inf) => inf.influencer_id === id,
                            ).name
                          }
                          <a
                            className="react-tagsinput-remove"
                            onClick={() => {
                              this.state.filteredData.influencers.addOrRemove(
                                id,
                              );
                              this.setState({}, () =>
                                this.triggerRoiUpdateState(),
                              );
                            }}
                          />
                        </span>
                      ))}
                    </div>
                    {this.state.filters.influencers && (
                      <Select
                        className="searchable-input"
                        ref="influencers"
                        multi={true}
                        options={this.state.filters.influencers
                          .map((i) => {
                            return { value: i.influencer_id, label: i.name };
                          })
                          .filter(
                            (i) =>
                              !this.state.filteredData.influencers.includes(
                                i.value,
                              ),
                          )}
                        clearable={true}
                        onChange={(e) => {
                          if (
                            this.state.filteredData.influencers.indexOf(
                              e[0].value,
                            ) === -1
                          ) {
                            this.state.filteredData.influencers.push(
                              e[0].value,
                            );
                          }
                          this.setState({}, () => this.triggerRoiUpdateState());
                        }}
                        name="influencers"
                        searchable={true}
                      />
                    )}
                  </>
                )}
                {item.type === "auctions" && (
                  <>
                    <div className="selected_list react-tagsinput">
                      {this.state.filteredData.auctions.map((id, i) => (
                        <span className="react-tagsinput-tag" key={i}>
                          {
                            this.state.filters.auctions.find((a) => a.id === id)
                              .title
                          }
                          <a
                            className="react-tagsinput-remove"
                            onClick={() => {
                              this.state.filteredData.auctions.addOrRemove(id);
                              this.setState({}, () =>
                                this.triggerRoiUpdateState(),
                              );
                            }}
                          />
                        </span>
                      ))}
                    </div>
                    {this.state.filters.auctions && (
                      <Select
                        className="searchable-input"
                        ref="auctions"
                        multi={true}
                        options={this.state.filters.auctions
                          .map((i) => {
                            return { value: i.id, label: i.title };
                          })
                          .filter(
                            (i) =>
                              !this.state.filteredData.auctions.includes(
                                i.value,
                              ),
                          )}
                        clearable={true}
                        onChange={(e) => {
                          if (
                            this.state.filteredData.auctions.indexOf(
                              e[0].value,
                            ) === -1
                          ) {
                            this.state.filteredData.auctions.push(e[0].value);
                          }
                          this.setState({}, () => this.triggerRoiUpdateState());
                        }}
                        name="influencers"
                        searchable={true}
                      />
                    )}
                  </>
                )}

                {item.type === "date-range" && (
                  <div className={"flex"}>
                    <Box>
                      <div
                        className="searchable-input"
                        onClick={() => this.setState({ showDatePicker: true })}
                      >
                        <DatePicker
                          readOnly={true}
                          dateFormat={window.time.short_date}
                          className="input-line"
                          isClearable={false}
                          placeholderText="from"
                          selected={
                            this.state.filteredData.time_filter.from
                              ? moment(this.state.filteredData.time_filter.from)
                              : null
                          }
                          onChange={(date) => {
                            const newState = { ...this.state };
                            newState.filteredData.time_filter.from = date;
                            this.setState(newState, () =>
                              this.triggerRoiUpdateState(),
                            );
                          }}
                        />
                        <i className="far fa-calendar" />
                      </div>
                    </Box>
                    <Box alignSelf={"center"}>
                      <span
                        style={{
                          marginTop: 7,
                          fontSize: 12,
                          padding: "2px 10px",
                          color: "#555",
                        }}
                      >
                        to
                      </span>
                    </Box>
                    <Box>
                      <div
                        className="searchable-input"
                        onClick={() => this.setState({ showDatePicker: true })}
                      >
                        <DatePicker
                          dateFormat={window.time.short_date}
                          className="input-line"
                          readOnly={true}
                          isClearable={false}
                          placeholderText="to"
                          selected={
                            this.state.filteredData.time_filter.to
                              ? moment(this.state.filteredData.time_filter.to)
                              : null
                          }
                          onChange={(date) => {
                            const newState = { ...this.state };
                            newState.filteredData.time_filter.to = date;
                            this.setState(newState, () =>
                              this.triggerRoiUpdateState(),
                            );
                          }}
                        />
                        <i className="far fa-calendar" />
                      </div>
                    </Box>
                    <HumanzRangePicker
                      buttonStyle={{
                        placeSelf: "center",
                        marginLeft: 10,
                      }}
                      onClose={() => this.setState({ showDatePicker: false })}
                      open={this.state.showDatePicker}
                      startDate={moment(
                        this.state.filteredData.time_filter.from || Date.now(),
                      ).toDate()}
                      endDate={moment(
                        this.state.filteredData.time_filter.to || Date.now(),
                      ).toDate()}
                      onChange={(res) => {
                        if (res && res.startDate && res.endDate) {
                          const newState = { ...this.state };
                          newState.filteredData.time_filter.from = moment(
                            res.startDate,
                          ).startOf("day");
                          newState.filteredData.time_filter.to = moment(
                            res.endDate,
                          ).endOf("day");
                          this.setState(newState);
                          this.triggerRoiUpdateState();
                        }
                        this.setState({ showDatePicker: false });
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            <div
              className="filter-refine-tab-spacer"
              style={{ marginBottom: 20 }}
            />
          </div>
        ))}
        <span className={"text-gray"}>
          <i className="fa fa-info-circle" />{" "}
          <i>Leave filter empty to get all.</i>
        </span>
      </div>
    );
  }

  render() {
    return this.state.showFilters ? (
      <div className="multi-report-filters ambassador-filters">
        {this.renderFiltersMenu()}
      </div>
    ) : null;
  }
}
