import produce from "immer";
import Tooltip from "rc-tooltip";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  useDeleteMetaTags,
  useEditMetaTags,
} from "src/api/actions/userActions";
import { MetaTag } from "src/api/ExtendedTypes";
import { PartialBy } from "src/declarations/UtilityTypes";
import { useBoolean, useOnClickOutside } from "src/hooks/coreHooks";
import { addOrRemove } from "src/utility";
import { tagColors } from "../utilities/general";

interface Props {
  tags: Array<MetaTag>;
  objectId: number;
  updateParentState?(): void;
  tagType: MetaTag["tag_type"];
}

type EditableTag =
  | {
      id: number;
      tagInputOpen: boolean;
      tagsChanged: boolean;
      edit: boolean;
      newTag: PartialBy<MetaTag, "id">;
    }
  | undefined;

const isTagHasId = (tag: PartialBy<MetaTag, "id">): tag is MetaTag => {
  return tag.id !== undefined;
};

export const TagsPopup: React.FC<Props> = (props) => {
  const { objectId, tags, tagType, updateParentState } = props;

  const [visible, setVisible] = useBoolean(false);
  const [editableTag, setEditableTag] = useState<EditableTag>();

  const { isPending: deletingTag, mutateAsync: deleteMetaTag } =
    useDeleteMetaTags();
  const { isPending: savingTag, mutateAsync: editMetaTag } = useEditMetaTags();

  const handleIconClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setVisible.toggle();
      setEditableTag({
        id: objectId,
        tagInputOpen: false,
        tagsChanged: false,
        edit: false,
        newTag: {},
      });
    },
    [objectId, setVisible],
  );

  const handleTagInputOpenClick = useCallback(() => {
    setEditableTag(
      produce((draftEditableTag) => {
        if (draftEditableTag) {
          draftEditableTag.tagInputOpen = false;
        }
      }),
    );
  }, []);

  const handleVisibleChange = useCallback<
    Required<React.ComponentProps<typeof Tooltip>>["onVisibleChange"]
  >(
    (newVisible) => {
      setVisible.set(newVisible ?? false);
      if (!newVisible) {
        setEditableTag({
          id: objectId,
          tagInputOpen: false,
          tagsChanged: false,
          edit: false,
          newTag: {},
        });
      }
    },
    [objectId, setVisible],
  );

  const handleNewTagInputChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      if (editableTag?.newTag) {
        setEditableTag(
          produce(editableTag, (draftEditableTag) => {
            draftEditableTag.newTag.label = e?.target?.value
              ?.trim()
              ?.substring(0, 49);
          }),
        );
      }
    },
    [editableTag],
  );

  const handleNewTagColorClick = useCallback(
    (color: string) => {
      if (editableTag?.newTag) {
        setEditableTag(
          produce(editableTag, (draftEditableTag) => {
            draftEditableTag.newTag.color = color;
          }),
        );
      }
    },
    [editableTag],
  );

  const handleEditClick = useCallback((tag: MetaTag) => {
    setEditableTag(
      produce((draftEditableTag) => {
        if (draftEditableTag) {
          draftEditableTag.tagInputOpen = true;
          draftEditableTag.edit = true;
          draftEditableTag.newTag = tag;
        }
      }),
    );
  }, []);

  const handleNewTagClick = useCallback(() => {
    setEditableTag(
      produce((draftEditableTag) => {
        if (draftEditableTag) {
          draftEditableTag.tagInputOpen = true;
          draftEditableTag.edit = false;
          draftEditableTag.newTag = {
            id: undefined,
            label: "",
            color: tagColors[0],
            ids: [objectId],
          };
        }
      }),
    );
  }, [objectId]);

  const addOrEditTag = useCallback(async () => {
    if (savingTag || !editableTag?.newTag) return;

    const tag: any = {
      id: editableTag.newTag.id,
      ids: editableTag.newTag.ids,
      color: editableTag.newTag.color,
      label: editableTag.newTag.label,
      tag_type: tagType,
    };

    const res = await editMetaTag({
      requestBody: tag,
    });

    const id = res?.data;
    if (id) {
      if (isTagHasId(tag)) {
        const index = tags.findIndex((x) => x.id === id);
        tags[index] = tag;
      } else if (tags) {
        tag.id = id;
        tags.push(tag as MetaTag);
      } else {
        tag.id = id;
        // tags = [tag];
      }

      setEditableTag({
        id: editableTag?.id,
        tagInputOpen: false,
        tagsChanged: false,
        edit: false,
        newTag: {},
      });
      if (updateParentState) {
        updateParentState();
      }
    }
  }, [editMetaTag, editableTag, savingTag, tagType, tags, updateParentState]);

  const deleteTag = useCallback(async () => {
    if (
      !deletingTag &&
      editableTag?.newTag?.id !== undefined &&
      editableTag.newTag.tag_type !== undefined
    ) {
      await deleteMetaTag({
        tag_id: editableTag.newTag.id,
        tag_type: editableTag.newTag.tag_type,
      });

      const index = tags.findIndex((x) => x.id === editableTag.newTag.id);
      tags.splice(index, 1);
      setEditableTag({
        id: editableTag.id,
        tagInputOpen: false,
        tagsChanged: false,
        edit: false,
        newTag: {},
      });
      if (updateParentState) {
        updateParentState();
      }
    }
  }, [editableTag, deletingTag, deleteMetaTag, tags, updateParentState]);

  const addOrRemoveInfluencerInTag = useCallback(
    async (tagId: number) => {
      const listId = objectId;
      const tag = tags.find((x) => x.id === tagId);
      if (!tag) return;
      if (tag.ids) {
        tag.ids = addOrRemove(tag.ids, [listId]);
      } else tag.ids = [listId];

      if (updateParentState) {
        updateParentState();
      }
      const res = await editMetaTag({
        requestBody: tag,
      });
      const id = res.data;
      tag.id = id;
      if (updateParentState) {
        updateParentState();
      }
    },
    [editMetaTag, objectId, tags, updateParentState],
  );

  const overlayElement = useRef<HTMLDivElement>(null);
  const tagIconElement = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        tagIconElement.current &&
        e.target &&
        !tagIconElement.current.contains(e.target as Node)
      ) {
        setVisible.off();
      }
    },
    [setVisible],
  );
  useOnClickOutside(overlayElement, handleClickOutside, {
    enabled: visible,
  });

  const handleOverlayClick = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >((e) => {
    e.stopPropagation();
  }, []);

  const createOrEditTag = useMemo(() => {
    if (!editableTag?.newTag) return null;
    return (
      <>
        <input
          className="new-tag-input"
          defaultValue={editableTag.newTag?.label}
          onChange={handleNewTagInputChange}
        />
        <div className="tag-color-pick">
          {tagColors.map((x: string) => (
            <div
              key={x}
              style={{ background: x }}
              onClick={() => {
                handleNewTagColorClick(x);
              }}
            >
              {editableTag.newTag
                ? editableTag.newTag.color === x && (
                    <i className="fas fa-check" />
                  )
                : null}
            </div>
          ))}
        </div>
        {savingTag ? (
          <div className="send-buttons-loader" />
        ) : (
          <div style={{ textAlign: "center" }}>
            <button
              className="btn btn-primary add-tag-btn"
              onClick={addOrEditTag}
            >
              {editableTag.edit ? "Save" : "Create"}
            </button>
            {editableTag.edit && (
              <button
                className="btn btn-primary add-tag-btn"
                style={{ background: "#cf513d", marginLeft: 50 }}
                onClick={deleteTag}
              >
                Delete
              </button>
            )}
          </div>
        )}
      </>
    );
  }, [
    editableTag,
    deleteTag,
    savingTag,
    handleNewTagInputChange,
    handleNewTagColorClick,
    addOrEditTag,
  ]);

  const overlay = useMemo(() => {
    return (
      <div onClick={handleOverlayClick} ref={overlayElement}>
        <div className="edit-tags-popup-title">
          {editableTag?.tagInputOpen ? (
            <div
              className="far fa-arrow-left"
              onClick={handleTagInputOpenClick}
            />
          ) : (
            <div />
          )}
          <span>Tags</span>
          <div className="far fa-times" onClick={setVisible.off} />
        </div>

        <div style={{ padding: "5px 10px", width: 280 }}>
          {editableTag?.tagInputOpen ? (
            createOrEditTag
          ) : (
            <>
              {tags.map((tag) => {
                const check = tag.ids?.includes(objectId);
                return (
                  <div className="inf-tags-container" key={tag.id}>
                    <div
                      style={{ backgroundColor: tag.color }}
                      onClick={() => addOrRemoveInfluencerInTag(tag.id)}
                    >
                      <div
                        title={tag.label}
                        className="campaign-tag"
                        dir="auto"
                      >
                        {tag.label}
                      </div>
                      {check && <div className="fas fa-check" />}
                    </div>
                    <div
                      className="fal fa-pen"
                      onClick={() => {
                        handleEditClick(tag);
                      }}
                    />
                  </div>
                );
              })}

              <div className="new-tag-button" onClick={handleNewTagClick}>
                Add new tag
              </div>
            </>
          )}
        </div>
      </div>
    );
  }, [
    objectId,
    createOrEditTag,
    editableTag,
    tags,
    addOrRemoveInfluencerInTag,
    setVisible,
    handleTagInputOpenClick,
    handleEditClick,
    handleNewTagClick,
    handleOverlayClick,
  ]);

  if (!tags) return null;

  return (
    <Tooltip
      visible={visible}
      onVisibleChange={handleVisibleChange}
      trigger={[]}
      placement="left"
      overlayClassName={"edit-popup"}
      overlay={overlay}
    >
      <Tooltip overlay={<>Tags</>} trigger={["hover"]} placement="top">
        <div
          className="fa fa-tags"
          ref={tagIconElement}
          onClick={handleIconClick}
        />
      </Tooltip>
    </Tooltip>
  );
};
