import { Box, Flex, FlexProps } from "@chakra-ui/react";

interface HumanzButtonGroupProps {
  children: any;
  label?: string;
  containerProps?: FlexProps;
}

export default function HumanzButtonGroup(props: HumanzButtonGroupProps) {
  return (
    <Box>
      {props.label ? <strong>{props.label}</strong> : null}
      <Flex
        background={"#E9ECEF"}
        padding={"5px"}
        borderRadius={200}
        gap={2}
        {...props.containerProps}
      >
        {props.children}
      </Flex>
    </Box>
  );
}
