import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const MenuTheme: ComponentMultiStyleConfig = {
  parts: ["item"],
  baseStyle: {
    item: {
      background: "transparent",
      borderWidth: "0",
      borderStyle: "solid",
      boxSizing: "border-box",
    },
  },
};
