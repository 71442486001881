import {
  ChakraProvider,
  ChakraTheme,
  extendTheme,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { theme as baseTheme } from "./theme";

export const theme = extendTheme(
  {
    components: {
      Button: {
        defaultProps: {
          variant: "form",
        },
      },
    },
  } as Partial<ChakraTheme>,
  baseTheme,
);

type Props = React.ComponentProps<typeof Flex>;

export const FormFooterButtonGroup: React.FC<Props> = ({
  children,
  ...otherProps
}) => {
  return (
    <ChakraProvider theme={theme}>
      <Flex justify="space-evenly" {...otherProps}>
        {children}
      </Flex>
    </ChakraProvider>
  );
};
