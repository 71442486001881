import { Box, Flex, Text } from "@chakra-ui/react";
import IndicatorSelect from "../../FormUI/IndicatorSelect";

type Props = {
  repeatEveryMonth: boolean;
  repeatEveryMonthChange: any;
  splitPayment: boolean;
  splitPaymentChange: any;
  completeEndMonth: any;
  completeEndMonthChange: any;
  oneMonthCampaign: boolean;
};

const BriefTasksPayments = ({
  repeatEveryMonth,
  splitPayment,
  repeatEveryMonthChange,
  splitPaymentChange,
  completeEndMonth,
  completeEndMonthChange,
  oneMonthCampaign,
}: Props) => {
  return (
    <Box background={"#F6F6F6"} borderRadius={10} p={3}>
      <Box mb={2}>
        <Text fontWeight={"bold"} mb={1}>
          Influencers payment settings
        </Text>
        <div className={"alert-info"} style={{ margin: "0px" }}>
          <i className="fa fa-info-circle" /> You can change each task price
          later on
        </div>
      </Box>
      <Flex gap={6} p={2}>
        <Box flex={1}>
          <IndicatorSelect
            optional={false}
            clearable={false}
            defaultValue={splitPayment}
            onChange={splitPaymentChange}
            style={{ maxWidth: 225 }}
            descriptionStyle={{ minHeight: "50px" }}
            options={[
              {
                label: "Total payment for all tasks",
                value: false,
              },
              {
                label: "Split payment",
                value: true,
              },
            ]}
            header="Brief Tasks Payment"
            description={
              splitPayment
                ? "The influencer will be paid for each tasks reletively"
                : "The total amount will be paid when the influencer completes all the tasks."
            }
          />
        </Box>
        {!oneMonthCampaign && (
          <Box flex={1}>
            <IndicatorSelect
              optional={false}
              disabled={window.pixel_enabled}
              clearable={false}
              defaultValue={repeatEveryMonth}
              onChange={repeatEveryMonthChange}
              style={{ maxWidth: 225 }}
              descriptionStyle={{ minHeight: "50px" }}
              options={[
                {
                  label: "Do not repeat",
                  value: false,
                },
                {
                  label: "Repeat",
                  value: true,
                },
              ]}
              header="Repeat"
              description={
                repeatEveryMonth
                  ? "Those tasks will repeat every month and the influencers will recieve and accept them automatically."
                  : "Those tasks will be created just once for the entier campaign"
              }
            />
          </Box>
        )}
        <Box flex={1}>
          <IndicatorSelect
            optional={false}
            clearable={false}
            style={{ maxWidth: 225 }}
            descriptionStyle={{ minHeight: "50px" }}
            onChange={completeEndMonthChange}
            defaultValue={String(completeEndMonth === true)}
            disabled={oneMonthCampaign}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            description={
              "Tasks to be paid at completion or at the end of the month regardless of tasks completion."
            }
            header={"Eligible for payment on"}
            options={[
              {
                label: "Tasks Completion",
                value: "false",
              },
              {
                label: "Guaranteed payment",
                value: "true",
              },
            ]}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default BriefTasksPayments;
