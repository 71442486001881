import { Box } from "@chakra-ui/react";

const StartBox = (props: any) => {
  const svgCreate = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.18 8.03933L18.6435 7.57589C19.4113 6.80804 20.6563 6.80804 21.4241 7.57589C22.192 8.34374 22.192 9.58868 21.4241 10.3565L20.9607 10.82M18.18 8.03933C18.18 8.03933 18.238 9.02414 19.1069 9.89309C19.9759 10.762 20.9607 10.82 20.9607 10.82M18.18 8.03933L13.9194 12.2999C13.6308 12.5885 13.4865 12.7328 13.3624 12.8919C13.2161 13.0796 13.0906 13.2827 12.9882 13.4975C12.9014 13.6797 12.8368 13.8732 12.7078 14.2604L12.2946 15.5L12.1609 15.901M20.9607 10.82L16.7001 15.0806C16.4115 15.3692 16.2672 15.5135 16.1081 15.6376C15.9204 15.7839 15.7173 15.9094 15.5025 16.0118C15.3203 16.0986 15.1268 16.1632 14.7396 16.2922L13.5 16.7054L13.099 16.8391M13.099 16.8391L12.6979 16.9728C12.5074 17.0363 12.2973 16.9867 12.1553 16.8447C12.0133 16.7027 11.9637 16.4926 12.0272 16.3021L12.1609 15.901M13.099 16.8391L12.1609 15.901"
        stroke="#249FF0"
        strokeWidth="1.5"
      />
      <path
        d="M8 13H10.5"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 9H14.5"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 17H9.5"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.8284 3.17157C18.6569 2 16.7712 2 13 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3 4.34315 3 6.22876 3 10V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H13C16.7712 22 18.6569 22 19.8284 20.8284C20.7715 19.8853 20.9554 18.4796 20.9913 16"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
  const svgChoose = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16 4.00171C18.175 4.01382 19.3529 4.11027 20.1213 4.87865C21 5.75733 21 7.17154 21 9.99997V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H9C6.17157 22 4.75736 22 3.87868 21.1213C3 20.2426 3 18.8284 3 16V9.99997C3 7.17154 3 5.75733 3.87868 4.87865C4.64706 4.11027 5.82497 4.01382 8 4.00171"
        stroke="#249FF0"
        strokeWidth="1.5"
      />
      <path
        d="M10.5 14L17 14"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 14H7.5"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 10.5H7.5"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 17.5H7.5"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.5 10.5H17"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.5 17.5H17"
        stroke="#249FF0"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 3.5C8 2.67157 8.67157 2 9.5 2H14.5C15.3284 2 16 2.67157 16 3.5V4.5C16 5.32843 15.3284 6 14.5 6H9.5C8.67157 6 8 5.32843 8 4.5V3.5Z"
        stroke="#249FF0"
        strokeWidth="1.5"
      />
    </svg>
  );
  const displayIcon = props.svg === "Create" ? svgCreate : svgChoose;
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Box className="startBox" onClick={handleClick}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          marginLeft: "45px",
          marginRight: "50px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>{displayIcon}</div>
          <span
            style={{
              color: "var(--black-1, #333)",

              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              maxWidth: "100%",
              textAlign: "left",
            }}
          >
            <div style={{ width: "150px" }}>{props.text}</div>
          </span>
        </div>
        <i className="fa-thin fa-circle-arrow-right fa-2xl"></i>
      </div>
    </Box>
  );
};

export default StartBox;
