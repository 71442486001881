import { Link, Tooltip } from "@chakra-ui/react";
import React, { useCallback } from "react";
import Infinite from "react-infinite";
import { getProductsSalesROI } from "src/api/actions/auctionActions";
import { postProductsSalesROIShared } from "src/api/actions/shareActions/auctionActions";
import {
  CampaignReportFilterParameters,
  PostMoiAuctionsIdProducts_sales_roiQueryParams,
  PurchaseClicksType,
} from "src/api/types";
import { InfiniteLoader } from "src/components/feed_components/general_components/InfiniteLoader";
import { formatBigNumbers } from "src/components/feed_components/utilities/general";

export const getProductsFilters = (filters: CampaignReportFilterParameters) => {
  let newFilters: CampaignReportFilterParameters = {};
  if (filters) {
    newFilters = JSON.parse(JSON.stringify(filters));
    if (filters.time_filter) {
      newFilters.time_filter.to =
        filters.time_filter.to || new Date().toISOString();
    } else {
      newFilters.time_filter = { to: new Date().toISOString() };
    }
  } else {
    newFilters = { time_filter: { to: new Date().toISOString() } };
  }
  return newFilters;
};

type ProductsScrollParams = Parameters<typeof getProductsSalesROI>;

export const SalesProductsTable = ({
  campaignId,
  initialProducts,
  filters,
  influencer_id,
  shareMode,
  breakdown,
}: {
  campaignId?: number;
  initialProducts: PurchaseClicksType[];
  filters: CampaignReportFilterParameters;
  influencer_id?: number;
  shareMode: boolean;
  breakdown?: PostMoiAuctionsIdProducts_sales_roiQueryParams["breakdown"];
}) => {
  const [products, setProducts] = React.useState(
    initialProducts as PurchaseClicksType[],
  );
  const [hasMore, setHasMore] = React.useState(true as boolean);
  const [loading, setLoading] = React.useState(
    influencer_id ? true : (false as boolean),
  );

  const getMoreProducts = useCallback(() => {
    if (products?.length) {
      const requestBody: ProductsScrollParams["1"] = {
        scroll: { amount_to_fetch: 20 },
        filters,
      };
      const queryParams: ProductsScrollParams["2"] = {
        influencer_id,
        breakdown,
      };
      let lastProduct: PurchaseClicksType = null;
      lastProduct = products[products.length - 1];
      requestBody.scroll.last_amount_seen = lastProduct.units;
      requestBody.scroll.last_price_seen = lastProduct.total_price;

      const getProductsFun = shareMode
        ? postProductsSalesROIShared(requestBody, queryParams)
        : getProductsSalesROI(campaignId, requestBody, queryParams);
      getProductsFun.then((r) => {
        const newProducts = r.data.items;
        setProducts(products.concat(newProducts));
        setHasMore(r.data.have_more);
        setLoading(false);
      });
    }
  }, [filters, influencer_id, products, campaignId, shareMode, breakdown]);

  React.useEffect(() => {
    if (influencer_id) {
      const getProductsFun = shareMode
        ? postProductsSalesROIShared({ filters }, { influencer_id, breakdown })
        : getProductsSalesROI(
            campaignId,
            { filters },
            {
              influencer_id,
              breakdown,
            },
          );
      getProductsFun.then((r) => {
        const newProducts = r.data.items;
        setProducts(products.concat(newProducts));
        setHasMore(r.data.have_more);
        setLoading(false);
      });
    }
  }, []);

  const handleLoadMore = () => {
    if (!loading) {
      setLoading(true);
      getMoreProducts();
    }
  };

  let breakdownType;

  if (breakdown === "link") {
    breakdownType = "Link";
  } else if (breakdown === "brand") {
    breakdownType = "Brand";
  } else {
    breakdownType = "SKU";
  }

  const getHeader = () => {
    if (breakdown === "sku" || !breakdown) {
      return "Name";
    }
    return breakdown === "link" ? "Description" : "";
  };
  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <div className="products-headers">
          <div style={{ width: 200, textAlign: "left" }}>{breakdownType}</div>
          <div style={{ width: 400, textAlign: "left" }}>{getHeader()}</div>
          <div style={{ width: "auto" }}>Units Sold</div>
          <div style={{ width: "auto", marginLeft: "20px" }}>Sales Value</div>
          <div style={{ width: 20 }} />
        </div>
        {products ? (
          <>
            <Infinite
              elementHeight={55}
              className="campaign-influencers-table-container"
              useWindowAsScrollContainer={false}
              infiniteLoadBeginEdgeOffset={-1}
              containerHeight={400}
              isInfiniteLoading={false}
              infiniteLoadBeginBottomOffset={-1}
              loadingSpinnerDelegate={
                hasMore ? (
                  <div
                    style={{
                      color: "var(--main-baby-blue-color)",
                      textAlign: "center",
                      fontSize: 20,
                      height: 55,
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    {loading ? (
                      <InfiniteLoader />
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleLoadMore()}
                      >
                        Load More
                      </button>
                    )}
                  </div>
                ) : (
                  <div style={{ height: 55 }} />
                )
              }
            >
              {products.map((product) => {
                return (
                  <div
                    className={`product-row`}
                    style={{ height: 55 }}
                    key={product.name}
                  >
                    <div>
                      <div style={{ width: 200 }} className="blurable">
                        <Tooltip
                          hasArrow
                          label={
                            <span
                              style={{
                                display: "inline-block",
                                maxWidth: "200px",
                                wordWrap: "break-word",
                              }}
                            >
                              {product.name}
                            </span>
                          }
                        >
                          <span
                            style={{
                              textOverflow: "ellipsis",
                              position: "relative",
                              overflow: "hidden",
                              textAlign: "left",
                              whiteSpace: "nowrap",
                              display: "block",
                              width: "200px",
                            }}
                          >
                            {product.link &&
                            (breakdown === "sku" || !breakdown) ? (
                              <Link
                                target="_blank"
                                href={product.link}
                                style={{ textDecoration: "none" }}
                                _focus={{ boxShadow: "none" }}
                              >
                                {product.name !== "" &&
                                !product.name.startsWith("UNKNOWN_")
                                  ? product.name
                                  : "Unknown"}
                              </Link>
                            ) : (
                              <span>
                                {product.name !== "" &&
                                !product.name.startsWith("UNKNOWN_")
                                  ? product.name
                                  : "Unknown"}
                              </span>
                            )}
                          </span>
                        </Tooltip>
                      </div>

                      <div style={{ width: 400, paddingLeft: 10 }}>
                        {!breakdown ||
                        breakdown === "sku" ||
                        breakdown === "link" ? (
                          <Tooltip
                            hasArrow
                            label={
                              breakdown === "link"
                                ? product.product_link_description
                                : product.product_name || product.external_name
                            }
                          >
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                position: "relative",
                                overflow: "hidden",
                                textAlign: "left",
                                whiteSpace: "nowrap",
                                marginLeft: "12px",
                                display: "block",
                                width: "400px",
                              }}
                            >
                              {product.link ? (
                                <Link
                                  target="_blank"
                                  href={product.link}
                                  style={{ textDecoration: "none" }}
                                  _focus={{ boxShadow: "none" }}
                                >
                                  {breakdown === "link"
                                    ? product.product_link_description
                                    : product.product_name ||
                                      product.external_name}{" "}
                                </Link>
                              ) : (
                                <>
                                  {breakdown === "link"
                                    ? product.product_link_description
                                    : product.product_name ||
                                      product.external_name}{" "}
                                </>
                              )}
                            </span>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>

                      <div style={{ width: 130 }}>{product.purchases}</div>
                      <div style={{ width: 130 }}>
                        {formatBigNumbers(product.total_price as number)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Infinite>
          </>
        ) : null}
      </div>
    </>
  );
};
