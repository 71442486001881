// General Imports
import React from "react";
import Dialog from "rc-dialog";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DatePicker from "react-datepicker";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Toggle from "react-toggle";
import Clipboard from "react-clipboard.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { connect } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Textarea from "react-textarea-autosize";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import Tooltip from "rc-tooltip";

// Components Import

// Functions import
import moment from "moment";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { uniqueId } from "recharts/es6/util/DataUtils";
import HumanzRangePicker from "src/ui/humanz/HumanzRangePicker";
import { Currencies } from "../utilities/general";
import { MultiReportShareParameters } from "../../../models/Api";
import { MultiReportActions } from "../../../actions/multiReportActions";

const filterMap = [
  {
    type: "date-range",
    label: "Date",
    isOpen: true,
    filteredData: "dateTime",
  },
  {
    type: "auctions",
    label: "Campaigns",
    isOpen: true,
  },
  {
    type: "influencers",
    label: "Influencers",
    isOpen: true,
  },
  {
    type: "kpis",
    label: "KPI's",
    isOpen: false,
  },
];

interface Props {
  insight: any;
  getMultiReport: any;
  report: any;
}

interface State {
  shareRoi: boolean;
  loading: boolean;
  changed: boolean;
  generalOpen: boolean;
  commentsOpen: boolean;
  showPassword: boolean;
  isCreate: boolean;
  isEdit: boolean;
  shares?: any;
  selectedShare?: any;
  report?: any;
  showDatePicker?: boolean;
}

class ShareMultiReportDialogInner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shareRoi: false,
      loading: false,
      changed: false,
      generalOpen: true,
      commentsOpen: false,
      showPassword: false,
      isCreate: false,
      isEdit: false,
      showDatePicker: false,
    };
  }

  updateSharesList() {
    (this.props as any).getMultiReportShareParams(
      this.props.insight.auction_id || this.props.insight.id,
      (response: any) => {
        const newState: any = {
          loading: false,
          shares: response,
          selectedShare: null,
        };
        this.setState(newState);
      },
    );
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true }, () => {
      this.updateSharesList();
    });
    if (!this.props.report) {
      (this.props as any).getMultiReportShareParams(
        this.props.insight.id,
        (data: any) => {
          // eslint-disable-next-line no-param-reassign
          if (!data.filter.influencers) data.filter.influencers = [];
          // eslint-disable-next-line no-param-reassign
          if (!data.filter.auctions) data.filter.auctions = [];
          const { editFields } = this.props as any;
          if (editFields) {
            // eslint-disable-next-line no-param-reassign
            data.influencers = editFields.influencers;
            // eslint-disable-next-line no-param-reassign
            data.auctions = editFields.auctions_data;
          }
          this.setState({ report: data, loading: false });
        },
      );
    } else {
      const { editFields } = this.props as any;
      if (editFields) {
        this.props.report.influencers = editFields.influencers;
        this.props.report.auctions = editFields.auctions_data;
      }

      if (
        this.props.report.roi &&
        !this.props.report.roi.influencers_price_percentage
      ) {
        this.props.report.roi.influencers_price_percentage =
          this.props.report.share_cost_per_influencer ||
          this.props.report.share_cost_per_influencer
            ? 100
            : null;
      }
      this.setState({ report: this.props.report, loading: false });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getShareDataToSend(data?: any) {
    if (data) return data;
    const dataToSend: any = {
      share_enabled: true,
      sharing_end_time: null,
      shared_guid: null,
      custom_title: "",
      password: "",
      link: "",
      comments: {
        kpi: "",
        tasks: "",
        funnel: "",
        segmentation: "",
      },
      kpis: {
        budget: null,
        conversions: null,
        currency: null,
        engagement: null,
        impressions: null,
      },
      filter: {
        influencers: [],
        auctions: [],
        time_filter: {
          from: "",
          to: "",
          amount: 1,
          offset: 0,
        },
      },
      share_cost_per_influencer: false,
      budget: "media",
      influencers_price_percentage: 0,
    };
    return dataToSend;
  }

  getInfluencerIds() {
    return this.state.report && this.state.report.influencers
      ? this.state.report.influencers.map((i: any) => i.influencer_id)
      : [];
  }

  getAuctionsIds() {
    return this.state.report && this.state.report.auctions_data
      ? this.state.report.auctions_data.map((i: any) => i.id)
      : [];
  }

  onChangeShare() {
    this.setState({ loading: true });
    const reportId = this.props.insight.auction_id || this.props.insight.id;
    console.log("CREATE?", this.state.isCreate);
    if (this.state.isCreate) {
      // on first time default parameters taken from share for first time sharing
      (this.props as any).setMultiReportShareParams(
        reportId,
        this.state.selectedShare,
        () => {
          this.setState(
            {
              loading: false,
              selectedShare: null,
              isCreate: false,
            },
            this.updateSharesList,
          );
        },
      );
    } else {
      (this.props as any).shareMultiReport(
        reportId,
        this.state.selectedShare.shared_guid,
        this.state.selectedShare,
        () => {
          this.setState({
            loading: false,
            selectedShare: null,
            isEdit: false,
            isCreate: false,
          });
        },
      );
    }
    this.setState({ changed: true, selectedShare: null });
  }

  handleUpdate() {
    if (this.state.selectedShare) {
      this.setState({ loading: true });
      const shareGuid = this.state.selectedShare.shared_guid;
      const dataToSend = this.getShareDataToSend(this.state.selectedShare);
      (this.props as any).shareMultiReport(
        this.props.insight.auction_id,
        shareGuid,
        dataToSend,
        () => {
          (this.props as any).handleClose(true);
        },
      );
      this.setState({ changed: true, selectedShare: null });
    } else {
      (this.props as any).handleClose(true);
    }
  }

  editSingleShare(singleShare: any) {
    if (!singleShare.filter) {
      // eslint-disable-next-line no-param-reassign
      singleShare.filter = {
        influencers: this.getInfluencerIds(),
        auctions: this.getAuctionsIds(),
        time_filter: {
          from: "",
          to: "",
          amount: 1,
          offset: 0,
        },
      };
    }
    this.setState({ selectedShare: singleShare, isEdit: true });
  }

  renderShareList() {
    return (
      <div className={"share-table"}>
        <div className={"share-lines-wrapper"}>
          {this.state.shares && this.state.shares.length ? (
            <div className="share-line-titles flex">
              <div className="flex1"></div>
              <div className="flex2">Title</div>
              <div className="flex2">Date</div>
              <div className="flex2">Actions</div>
              <div
                className="flex2"
                style={{ textAlign: "right", marginLeft: -12, marginRight: 12 }}
              >
                Active
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.shares && this.state.shares.length ? (
            this.state.shares.map((singleShare: any, i: number) => (
              <div
                className={`flex single-share-line ${
                  moment(singleShare.sharing_end_time) < moment()
                    ? "expired"
                    : ""
                }`}
                key={`share_${i}`}
              >
                <i className="fas fa-file-chart-line flex1"></i>
                <div className={"flex2"}>
                  {singleShare.custom_title
                    ? singleShare.custom_title
                    : this.state.report.name}
                </div>
                <div className={"flex2"}>
                  {moment(singleShare.sharing_end_time).format(
                    window.time.short_date,
                  )}
                </div>
                <div className={"flex2"}>
                  <Tooltip
                    placement="top"
                    trigger={["hover"]}
                    overlay={<span>Edit</span>}
                  >
                    <i
                      className="soft-btn far fa-edit"
                      onClick={() => this.editSingleShare(singleShare)}
                    />
                  </Tooltip>
                  <Clipboard
                    style={{ background: "transparent", border: "none" }}
                    data-clipboard-text={singleShare.link}
                  >
                    <Tooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={<span>Copy link</span>}
                    >
                      <i className="soft-btn far fa-copy copy-btn" />
                    </Tooltip>
                  </Clipboard>
                </div>

                <div className={"flex2 text-right"}>
                  <Toggle
                    className={
                      !singleShare.share_enabled ||
                      moment(singleShare.sharing_end_time) < moment()
                        ? "off"
                        : ""
                    }
                    defaultChecked={
                      moment(singleShare.sharing_end_time) > moment()
                        ? singleShare.share_enabled
                        : false
                    }
                    icons={false}
                    onChange={() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      // eslint-disable-next-line react/no-direct-mutation-state
                      this.state.selectedShare = singleShare;
                      // eslint-disable-next-line react/no-direct-mutation-state
                      this.state.selectedShare.share_enabled =
                        !singleShare.share_enabled;
                      this.setState({});
                      this.onChangeShare();
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <div style={{ marginTop: 20 }}>No shares created yet.</div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        maskClosable={false}
        onClose={() => (this.props as any).handleClose(this.state.changed)}
        className="share-dialog "
        visible={true}
        style={{ marginTop: 100 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">Share Combined Report</div>

          {/* modal header */}
          <div style={{ fontSize: 16, fontWeight: 300, marginTop: 20 }}>
            Share Combined Report
            {!this.state.isCreate && !this.state.isEdit && (
              <button
                onClick={() => {
                  this.setState({
                    isEdit: false,
                    isCreate: true,
                    selectedShare: this.getShareDataToSend(),
                  });
                }}
                className={"pull-right btn btn-primry"}
              >
                <span
                  className="moi-plus"
                  style={{
                    color: "var(--main-baby-blue-color)",
                    fontSize: 11,
                  }}
                />{" "}
                Add new
              </button>
            )}
            {(this.state.isCreate || this.state.isEdit) && (
              <button
                style={{ marginRight: 2 }}
                onClick={() => {
                  this.setState({
                    isEdit: false,
                    isCreate: false,
                    selectedShare: null,
                  });
                }}
                className={"pull-right btn btn-primry"}
              >
                <i
                  className="far fa-arrow-alt-left"
                  style={{
                    color: "var(--main-baby-blue-color)",
                    fontSize: 11,
                  }}
                />{" "}
                Back
              </button>
            )}
          </div>

          {/* modal content */}
          {this.state.loading ? (
            <div
              className="spinner small"
              style={{ marginTop: 40, marginBottom: 0 }}
            >
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          ) : (
            <div>
              {!this.state.isEdit &&
                !this.state.isCreate &&
                this.renderShareList()}
              {(this.state.isEdit || this.state.isCreate) &&
                this.renderSingleShare()}
            </div>
          )}

          {/* modal footer buttons */}
          <div
            style={{
              paddingBottom: 5,
              paddingTop: 10,
              marginTop: 40,
              textAlign: "center",
            }}
          >
            {this.state.isEdit && (
              <button
                style={{ marginLeft: 10 }}
                className={`attach-links-btn share-save-btn btn btn-primary ${
                  this.state.loading && "disabled"
                }`}
                onClick={() => this.handleUpdate()}
              >
                <span>Save</span>
              </button>
            )}
            {this.state.isCreate && (
              <button
                style={{ marginLeft: 10 }}
                className={`attach-links-btn share-save-btn btn btn-primary ${
                  this.state.loading && "disabled"
                }`}
                onClick={() => this.onChangeShare()}
              >
                <span>Create</span>
              </button>
            )}
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={() =>
                (this.props as any).handleClose(this.state.changed)
              }
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  renderKpis() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Impressions</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={this.state.selectedShare.kpis.impressions}
                onChange={(e) => {
                  // eslint-disable-next-line react/no-direct-mutation-state
                  this.state.selectedShare.kpis.impressions = e.target.value;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Engagements</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={this.state.selectedShare.kpis.engagement}
                onChange={(e) => {
                  // eslint-disable-next-line react/no-direct-mutation-state
                  this.state.selectedShare.kpis.engagement = e.target.value;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Conversions</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={this.state.selectedShare.kpis.conversions}
                onChange={(e) => {
                  // eslint-disable-next-line react/no-direct-mutation-state
                  this.state.selectedShare.kpis.conversions = e.target.value;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Total Budget</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={this.state.selectedShare.kpis.budget}
                onChange={(e) => {
                  // eslint-disable-next-line react/no-direct-mutation-state
                  this.state.selectedShare.kpis.budget = e.target.value;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Currency</strong>
            <div className="blurable-large">
              <div className="searchable-input" style={{ marginTop: -6 }}>
                <Select
                  // eslint-disable-next-line react/no-string-refs
                  ref="stateSelect"
                  options={Currencies}
                  placeholder={""}
                  value={this.state.selectedShare.kpis.currency}
                  simpleValue
                  clearable={false}
                  onChange={(value) => {
                    // eslint-disable-next-line react/no-direct-mutation-state
                    this.state.selectedShare.kpis.currency = value;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.setState({ updated: true });
                  }}
                  name="selected-state"
                  disabled={false}
                  searchable={true}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderSingleShare = () => {
    if (!this.state.selectedShare || !this.state.report) return false;
    const disabledByTime =
      moment(this.state.selectedShare.sharing_end_time) < moment(new Date());
    const { report } = this.state;
    const getInfluencerUsername = (influencers: any, id: any) => {
      const infl = influencers.find((inf: any) => inf.influencer_id === id);
      if (infl) {
        const name = infl.name
          ? infl.name
          : `@${
              infl.instagram_username
                ? infl.instagram_username
                : infl.twitter_username
            }`;
        return <>{name}</>;
      }
      return undefined;
    };
    const getAuctionTitle = (auctions: any, id: number) => {
      const auct = auctions.find((a: any) => a.id === id);
      if (auct) {
        const name = auct.title ? auct.title : "";
        return <>{name}</>;
      }
      return undefined;
    };
    return (
      <div style={{ marginTop: 20 }}>
        {this.state.isEdit && (
          <>
            <div className="flex">
              <div className="flex1 toggle-input">
                <strong className="label-title" style={{ marginBottom: 7 }}>
                  Share report
                </strong>
                <label>
                  <div className="toggle-label">
                    <div className="fa fa-times" />
                    <div className="fa fa-check" />
                  </div>
                  <Toggle
                    style={{ marginLeft: 15 }}
                    defaultChecked={this.state.selectedShare.share_enabled}
                    icons={false}
                    onChange={() => {
                      // eslint-disable-next-line react/no-direct-mutation-state
                      this.state.selectedShare.share_enabled =
                        !this.state.selectedShare.share_enabled;
                      this.onChangeShare();
                    }}
                  />
                </label>
              </div>
              {this.state.selectedShare.link && (
                <div className="flex2">
                  <strong className="label-title">LINK</strong>
                  <div style={{ display: "flex" }}>
                    <input
                      value={this.state.selectedShare.link}
                      className="input-line"
                      readOnly={true}
                    />
                    <Clipboard
                      style={{ background: "transparent", border: "none" }}
                      data-clipboard-text={this.state.selectedShare.link}
                    >
                      <i className="far fa-copy copy-btn" />
                    </Clipboard>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <div className="campaigns-title">
                <span style={{ fontSize: 16 }}>Title</span>
              </div>
              <div>
                <div
                  style={{ width: 200, marginLeft: 5 }}
                  className="searchable-input"
                >
                  <input
                    className={"input-line "}
                    type="text"
                    value={this.state.selectedShare.custom_title}
                    onChange={(e: any) => {
                      // eslint-disable-next-line react/no-direct-mutation-state
                      this.state.selectedShare.custom_title = e.target.value;
                      this.setState({});
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="campaigns-title">
                <span style={{ fontSize: 16 }}>Sharing until</span>
              </div>
              <div>
                <div
                  style={{ width: 200, marginLeft: 5 }}
                  className="searchable-input"
                >
                  <DatePicker
                    selected={
                      this.state.selectedShare.sharing_end_time
                        ? moment(this.state.selectedShare.sharing_end_time)
                        : null
                    }
                    isClearable={true}
                    placeholderText="Share end date"
                    dateFormat={window.time.date_with_hour}
                    onChange={(date: any) => {
                      this.setState({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        updated: true,
                        selectedShare: {
                          ...this.state.selectedShare,
                          sharing_end_time: date,
                        },
                      });
                    }}
                    className={`input-line ${
                      disabledByTime ? "disabled-by-time" : ""
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            {
              <div style={{ flex: 1, marginTop: 20 }}>
                <div style={{ flex: 1 }}>
                  <strong className="label-title" style={{ marginBottom: 15 }}>
                    share influencers performance
                    <br />
                    (individual roi)
                  </strong>
                  <div className="toggle-input">
                    <label>
                      <div className="toggle-label">
                        <div className="fa fa-times" />
                        <div className="fa fa-check" />
                      </div>
                      <Toggle
                        defaultChecked={
                          this.state.selectedShare.share_cost_per_influencer
                        }
                        icons={false}
                        onChange={() => {
                          // eslint-disable-next-line react/no-direct-mutation-state
                          this.state.selectedShare.share_cost_per_influencer =
                            !this.state.selectedShare.share_cost_per_influencer;
                          // eslint-disable-next-line react/no-direct-mutation-state
                          this.state.selectedShare.influencers_price_percentage =
                            this.state.selectedShare.share_cost_per_influencer
                              ? 100
                              : null;
                          this.setState({});
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            }
            <div style={{ flex: 1 }}>
              {this.state.selectedShare.share_cost_per_influencer ? (
                <div style={{ marginTop: 20, flex: 1 }}>
                  <strong className="label-title">
                    Influencers cost percentage (Optional)
                  </strong>
                  <div style={{ marginBottom: 15 }}>
                    <span style={{ color: "#888" }} className="optional-title">
                      This will effect all the influencers in the share report.
                      empty will be equal to 100%
                    </span>
                  </div>
                  <div
                    style={{ width: 80, display: "flex", alignItems: "center" }}
                  >
                    <input
                      type={"number"}
                      defaultValue={
                        this.state.selectedShare.influencers_price_percentage
                      }
                      onChange={(e) => {
                        // eslint-disable-next-line react/no-direct-mutation-state
                        this.state.selectedShare.influencers_price_percentage =
                          e.target.value;
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        this.setState({ updated: true });
                      }}
                      className="input-line"
                    />{" "}
                    %
                  </div>
                </div>
              ) : null}
              <div style={{ marginTop: 20, flex: 1 }}>
                <strong className="label-title" style={{ marginBottom: 15 }}>
                  Show Goals
                </strong>
                <div className="toggle-input" style={{ display: "flex" }}>
                  <strong className="small-title roi-toggle-title">
                    By Budget
                  </strong>
                  <label>
                    <Toggle
                      defaultChecked={
                        this.state.selectedShare.budget === "real"
                      }
                      icons={false}
                      onChange={() => {
                        // eslint-disable-next-line react/no-direct-mutation-state
                        this.state.selectedShare.budget =
                          this.state.selectedShare.budget === "real"
                            ? "media"
                            : "real";
                        this.setState({});
                      }}
                    />
                  </label>
                  <strong className="small-title roi-toggle-title">
                    Money Spent
                  </strong>
                </div>
              </div>
            </div>
          </div>

          {filterMap.map((item, j) => (
            <div key={j}>
              <div
                onClick={() => {
                  // eslint-disable-next-line no-param-reassign
                  item.isOpen = !item.isOpen;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  this.setState({ updated: true });
                }}
              >
                <div className="campaigns-title">
                  <span style={{ fontSize: 16 }}>{item.label}</span>
                </div>
                <div
                  className={`row-collapse ${
                    item.isOpen ? "moi-minus" : "moi-plus"
                  }`}
                />
              </div>
              {item.isOpen && (
                <div className="inner-filter-content">
                  {item.type === "influencers" && (
                    <>
                      <div className="selected_list react-tagsinput">
                        {this.state.selectedShare.filter.influencers &&
                          this.state.selectedShare.filter.influencers.map(
                            (influencer: any, i: number) => (
                              <span className="react-tagsinput-tag" key={i}>
                                {getInfluencerUsername(
                                  report.per_influencer,
                                  influencer,
                                )}
                                <a
                                  className="react-tagsinput-remove"
                                  onClick={() => {
                                    this.state.selectedShare.filter.influencers.addOrRemove(
                                      influencer,
                                    );
                                    this.setState({});
                                  }}
                                />
                              </span>
                            ),
                          )}
                      </div>
                      {report.per_influencer && (
                        <Select
                          className="searchable-input"
                          // eslint-disable-next-line react/no-string-refs
                          ref="mr_usrnm"
                          multi={true}
                          options={report.per_influencer.map((i: any) => {
                            return {
                              value: i.influencer_id,
                              label: i.name
                                ? i.name
                                : `@${
                                    i.instagram_username
                                      ? i.instagram_username
                                      : i.twitter_username
                                  }`,
                            };
                          })}
                          clearable={true}
                          onChange={(e) => {
                            if (
                              this.state.selectedShare.filter.influencers.indexOf(
                                (e as any)[0].value,
                              ) === -1
                            ) {
                              this.state.selectedShare.filter.influencers.push(
                                (e as any)[0].value,
                              );
                              this.setState({});
                            }
                          }}
                          name="mr_usrnm"
                          searchable={true}
                        />
                      )}
                    </>
                  )}
                  {item.type === "auctions" && (
                    <>
                      <div className="selected_list react-tagsinput">
                        {this.state.selectedShare.filter.auctions &&
                          this.state.selectedShare.filter.auctions.map(
                            (auction: any, i: number) => (
                              <span className="react-tagsinput-tag" key={i}>
                                {getAuctionTitle(report.auctions, auction)}
                                <a
                                  className="react-tagsinput-remove"
                                  onClick={() => {
                                    this.state.selectedShare.filter.auctions.addOrRemove(
                                      auction,
                                    );
                                    this.setState({});
                                  }}
                                />
                              </span>
                            ),
                          )}
                      </div>
                      {report.auctions && (
                        <Select
                          className="searchable-input"
                          // eslint-disable-next-line react/no-string-refs
                          ref="mr_usrnm"
                          multi={true}
                          options={report.auctions.map((i: any) => {
                            return { value: i.id, label: i.title };
                          })}
                          clearable={true}
                          onChange={(e) => {
                            if (
                              this.state.selectedShare.filter.auctions.indexOf(
                                (e as any)[0].value,
                              ) === -1
                            ) {
                              this.state.selectedShare.filter.auctions.push(
                                (e as any)[0].value,
                              );
                              this.setState({});
                            }
                          }}
                          name="mr_usrnm"
                          searchable={true}
                        />
                      )}
                    </>
                  )}
                  {item.type === "tags" && (
                    <div>
                      <div style={{ borderBottom: "1px solid #bbbbbb" }}>
                        <form role="presentation" autoComplete={"false"}>
                          <TagsInput
                            value={
                              this.state.selectedShare.filter[
                                item.filteredData as any
                              ]
                            }
                            onChange={(e) => {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              this.handleTagsChange(item, e);
                            }}
                            addOnBlur={true}
                            inputProps={{
                              placeholder:
                                item.filteredData === "hashtags" ? "#" : "@",
                              className: "tasks-tags-input main-tags",
                              autoComplete: "false",
                              name: uniqueId(),
                            }}
                          />
                        </form>
                      </div>
                    </div>
                  )}
                  {item.type === "date-range" && (
                    <div className={"flex"} style={{ width: 460 }}>
                      <div
                        className="searchable-input"
                        onClick={() => this.setState({ showDatePicker: true })}
                      >
                        <DatePicker
                          dateFormat={window.time.date_with_hour}
                          className="input-line"
                          isClearable={false}
                          readOnly={true}
                          placeholderText="from"
                          selected={
                            this.state.selectedShare.filter.time_filter.from
                              ? moment(
                                  this.state.selectedShare.filter.time_filter
                                    .from,
                                )
                              : null
                          }
                          onChange={(date: any) => {
                            // eslint-disable-next-line react/no-direct-mutation-state
                            this.state.selectedShare.filter.time_filter.from =
                              date;
                            this.setState({});
                          }}
                        />
                        <i className="far fa-calendar" />
                      </div>
                      <span
                        style={{
                          marginTop: 7,
                          fontSize: 12,
                          padding: "4px 10px",
                          color: "#555",
                          background: "gainsboro",
                          borderRadius: "50%",
                          width: 35,
                          height: 35,
                          margin: "2px 7px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        to
                      </span>
                      <div
                        className="searchable-input"
                        onClick={() => this.setState({ showDatePicker: true })}
                      >
                        <DatePicker
                          readOnly={true}
                          dateFormat={window.time.date_with_hour}
                          className="input-line"
                          isClearable={false}
                          placeholderText="to"
                          selected={
                            this.state.selectedShare.filter.time_filter.to
                              ? moment(
                                  this.state.selectedShare.filter.time_filter
                                    .to,
                                )
                              : null
                          }
                          onChange={(date: any) => {
                            // eslint-disable-next-line react/no-direct-mutation-state
                            this.state.selectedShare.filter.time_filter.to =
                              date;
                            this.setState({});
                          }}
                        />
                        <i className="far fa-calendar" />
                      </div>
                      <HumanzRangePicker
                        buttonStyle={{
                          placeSelf: "center",
                          marginLeft: 10,
                        }}
                        open={this.state.showDatePicker}
                        onClose={() => {
                          this.setState({ showDatePicker: false });
                        }}
                        showButton={false}
                        startDate={moment(
                          this.state.selectedShare.filter.time_filter.from ||
                            Date.now(),
                        ).toDate()}
                        endDate={moment(
                          this.state.selectedShare.filter.time_filter.to ||
                            Date.now(),
                        ).toDate()}
                        onChange={(res: any) => {
                          if (res && res.startDate && res.endDate) {
                            const newState = { ...this.state };
                            newState.selectedShare.filter.time_filter.from =
                              moment(res.startDate).startOf("day");
                            newState.selectedShare.filter.time_filter.to =
                              moment(res.endDate).endOf("day");
                            this.setState(newState);
                          }
                          this.setState({ showDatePicker: false });
                        }}
                      />
                    </div>
                  )}
                  {item.type === "kpis" && <>{this.renderKpis()}</>}
                </div>
              )}
              <div
                className="filter-refine-tab-spacer"
                style={{ marginBottom: 20 }}
              />
            </div>
          ))}

          {/* Password */}
          <div style={{ width: 400, marginLeft: 5, position: "relative" }}>
            <div className="campaigns-title">
              <span style={{ fontSize: 16 }}>Password</span>
            </div>
            <div>
              <input type="password" className="hidden" />
              <input
                id="share-password"
                name="share-password"
                autoComplete="false"
                style={{ paddingRight: 80 }}
                defaultValue={this.state.selectedShare.password}
                onChange={(e) => {
                  // eslint-disable-next-line react/no-direct-mutation-state
                  this.state.selectedShare.password = e.target.value;
                  this.setState({});
                }}
                className="input-line"
                type={this.state.showPassword ? "normal" : "password"}
              />
              {this.state.selectedShare.password && (
                <div
                  onClick={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                  className="btn btn-primary"
                  style={{
                    position: "absolute",
                    padding: "5px 10px",
                    marginTop: -35,
                    marginLeft: "89%",
                  }}
                >
                  {this.state.showPassword ? "HIDE" : "SHOW"}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* comments */}
        <div
          onClick={() => {
            this.setState({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              updated: true,
              commentsOpen: !this.state.commentsOpen,
            });
          }}
        >
          <div className="campaigns-title">
            <span style={{ fontSize: 16 }}>COMMENTS</span>
          </div>
          <div
            className={`row-collapse ${
              this.state.commentsOpen ? "moi-minus" : "moi-plus"
            }`}
          />
        </div>
        {this.state.commentsOpen && this.renderComments()}
      </div>
    );
  };

  renderComments() {
    const { comments } = this.state.selectedShare;
    return (
      <div>
        <div className="campaigns-title">
          <span style={{ fontSize: 16 }}>Comments</span>
        </div>
        <div className="optinal-title" style={{ marginTop: 20 }}>
          For your convenience you can add comments in any part of the report,
          please note that your comments will be exposed to all report viewers.
        </div>

        <div style={{ marginTop: 25 }}>
          <strong className="label-title">KPI&#39;S COMMENTS</strong>
          <div>
            <Textarea
              className={"input-line"}
              defaultValue={comments.kpi}
              onChange={(e: any) => {
                comments.kpi = e.target.value;
                this.setState({});
              }}
              minRows={1}
              maxRows={5}
            />
          </div>
        </div>

        <div style={{ marginTop: 15 }}>
          <strong className="label-title">Tasks COMMENTS</strong>
          <div>
            <Textarea
              className={"input-line"}
              defaultValue={comments.tasks}
              onChange={(e: any) => {
                comments.tasks = e.target.value;
                this.setState({});
              }}
              minRows={1}
              maxRows={5}
            />
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <strong className="label-title">funnel COMMENTS</strong>
          <div>
            <Textarea
              className={"input-line"}
              defaultValue={comments.funnel}
              onChange={(e: any) => {
                comments.funnel = e.target.value;
                this.setState({});
              }}
              minRows={1}
              maxRows={5}
            />
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <strong className="label-title">segmentations COMMENTS</strong>
          <div>
            <Textarea
              className={"input-line"}
              defaultValue={comments.segmentation}
              onChange={(e: any) => {
                comments.segmentation = e.target.value;
                this.setState({});
              }}
              minRows={1}
              maxRows={5}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMultiReportShareParams: (report_id: number, callback: any) => {
      dispatch(
        MultiReportActions.getMultiReportShareParams(report_id, callback),
      );
    },
    setMultiReportShareParams: (
      report_id: number,
      params: MultiReportShareParameters,
      callback: any,
    ) => {
      dispatch(
        MultiReportActions.setMultiReportShareParams(
          report_id,
          params,
          callback,
        ),
      );
    },
    shareMultiReport: (
      report_id: number,
      shared_guid: string,
      params: MultiReportShareParameters,
      callback: any,
    ) => {
      dispatch(
        MultiReportActions.shareMultiReport(
          report_id,
          shared_guid,
          params,
          callback,
        ),
      );
    },
  };
};
export const ShareMultiReportDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareMultiReportDialogInner);
