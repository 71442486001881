// TODO - lint
/* eslint-disable no-nested-ternary, no-unused-vars, class-methods-use-this, no-unused-vars, no-unused-vars, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, react/jsx-key, no-case-declarations, no-shadow, no-unused-vars, import/no-default-export */
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import React, { useContext } from "react";
import { CSVLink } from "react-csv";
import { TwitterTweetEmbed } from "react-twitter-embed";
import YouTube from "react-youtube";
import { semiMode } from "../../../../../actions/commonActionsConf";
import NoImageFound from "../../../../../images/no-image.png";
import { InfiniteLoader } from "../../../general_components/InfiniteLoader";
import NoDataMessage from "../../../general_components/NoDataMessage";
import {
  formatBigNumbers,
  numberWithCommas,
  TikTokVideoPrefix,
} from "../../../utilities/general";
import { RoiContext } from "./RoiContext";
import { KpiCube } from "./RoiPixel";
import {
  conversionsCharts,
  RenderDistributionChart,
  roiCharts,
  youtubeCharts,
} from "./RoiUtils";
import { getProductsFilters, SalesProductsTable } from "./SalesProductsTable";

class RoiInfluencersInner extends React.PureComponent {
  state = {
    selectedSocial: "",
    openInfluencer: null,
    sortBy: null,
    pixelEnabled: false,
    productsFilters: null,
    downloadingCsv: false,
  };

  hideImpressions = this.props.shareHideImpressions;

  initProductsFilters() {
    let filters = null;
    if (
      this.props.loaded &&
      this.props.summaryData?.campaign_details?.pixel_enabled
    ) {
      filters = this.props.allowFilters
        ? getProductsFilters(this.props.filters)
        : {};
    }
    this.setState({ productsFilters: filters });
  }

  componentDidMount() {
    this.setState({
      selectedSocial: this.props.selectedSocial,
      pixelEnabled: this.props.summaryData?.campaign_details?.pixel_enabled,
    });
    this.initProductsFilters();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSocial !== prevProps.selectedSocial) {
      this.setState({ selectedSocial: this.props.selectedSocial });
    }
    if (this.hideImpressions !== this.props.shareHideImpressions) {
      this.hideImpressions = this.props.shareHideImpressions;
      this.setState({});
    }
    if (
      prevProps.summaryData?.campaign_details?.pixel_enabled !==
      this.props.summaryData?.campaign_details?.pixel_enabled
    ) {
      this.setState({
        pixelEnabled: this.props.summaryData?.campaign_details?.pixel_enabled,
      });
    }
    if (prevProps.loaded !== this.props.loaded) {
      this.initProductsFilters();
    }
  }

  openInfluencer(id) {
    const { openInfluencer } = this.state;
    if (id === openInfluencer) {
      this.setState({ openInfluencer: -1 });
    } else {
      this.setState({ openInfluencer: id });
    }
  }

  handleOpenInfSeg = (e, id) => {
    if (!this.props.shared) {
      e.stopPropagation();
      this.props.openInfluencerSegmentations(id);
    }
  };

  getPostPrefixUrl(social) {
    switch (social) {
      case "instagram":
        return "https://www.instagram.com/p/";
      case "twitter":
        return "https://twitter.com/statuses/statuses/";
      case "youtube":
        return "https://www.youtube.com/watch?v=";
      case "tiktok":
        return TikTokVideoPrefix;
      default:
        break;
    }
    return "";
  }

  downloadCSV = () => {
    const hasReachFrequency = !!this.props.reachFrequency?.campaign;
    const influencers = this.props.influencersData;
    const isPixel =
      !!this.props.summaryData?.pixel_conversions?.total_products_purchases;
    const header = [
      "Name",
      "Social Network",
      "Username",
      "Price",
      "Followers",
      "Impressions",
      "Post Reach",
      "Likes",
      "Comments",
      "Video Views",
      "Story Views",
      "Story Engagements",
      // "Story Saved",
      // "Story Shares",
      "Story Reach",
      "Story Tap Back",
      "Story Tap Forward",
      "Swipes Forward",
      "Clicks",
      ...(hasReachFrequency ? ["Unique Reach", "Frequency"] : []),
      ...(isPixel ? ["Commission"] : []),
      ...(isPixel ? ["Purchases"] : []),
      ...(isPixel ? ["Units Sold"] : []),
      ...(isPixel ? ["Sales Value"] : []),
      ...(isPixel ? ["Net Sales Value"] : []),
      ...(isPixel ? ["Coupons Redeemed"] : []),
      "Contents",
    ];

    const table = [header];

    const socials = ["instagram", "twitter", "youtube", "tiktok"];

    influencers.forEach((inf) => {
      const details = inf.influencerDetails;

      if ((!details.contents || !details.contents[0].post_type) && isPixel) {
        const row = [
          details.name,
          "",
          "",
          details.price,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          inf.clicks_segmentations?.clicksDates?.total,
          ...(hasReachFrequency ? ["", ""] : []),
          ...(isPixel
            ? [
                (
                  inf.influencerDetails?.insights.pixel_conversions
                    ?.total_influencer_commission || 0
                ).toFixed(2),
              ]
            : []),
          ...(isPixel ? [inf.conversions_data.total_purchases] : []),
          ...(isPixel ? [inf.conversions_data.total_units] : []),
          ...(isPixel ? [inf.conversions_data.total_value.toFixed(2)] : []),
          ...(isPixel
            ? [
                (
                  inf.conversions_data.total_value *
                  (inf.conversions_data.net_sales_percentage / 100)
                ).toFixed(2),
              ]
            : []),
          ...(isPixel ? [inf.total_coupons_redeem] : []),
          "",
        ];
        table.push(row);
      } else {
        socials.forEach((social) => {
          const contents = details.contents?.filter(
            (y) =>
              y.post_type === this.props.TaskTypes[social] ||
              (social === "instagram" && y.post_type === "ig_story"),
          );
          const reachFrequencyData =
            social === "instagram" &&
            (details?.social_network_data?.instagram?.reach_and_frequency ||
              this.props.reachFrequency?.influencers?.[details.influencer_id]
                ?.reach_frequency);
          const socialData = details.social_network_data[social];
          if (contents?.length) {
            const {
              storyCount,
              storyEngagements,
              impression,
              post_reach,
              likesCount,
              commentsCount,
              viewsCount,
              // savesCount,
              // sharesCount,
              story_reach,
              taps_back,
              taps_forward,
              swipes_forward,
            } = this.getCounts(social, details);
            const row = [
              details.name,
              social,
              socialData?.username,
              details.price,
              socialData?.followers,
              impression,
              post_reach,
              likesCount,
              commentsCount,
              viewsCount,
              storyCount,
              storyEngagements,
              // savesCount,
              // sharesCount,
              story_reach,

              taps_back,
              taps_forward,
              swipes_forward,

              inf.clicks_segmentations?.clicksDates?.total,
              ...(hasReachFrequency
                ? [
                    reachFrequencyData?.unique_reach || "",
                    reachFrequencyData?.frequency || "",
                  ]
                : []),
              ...(isPixel
                ? [
                    (
                      inf.influencerDetails?.insights.pixel_conversions
                        ?.total_influencer_commission || 0
                    ).toFixed(2),
                  ]
                : []),
              ...(isPixel ? [inf.conversions_data.total_purchases] : []),
              ...(isPixel ? [inf.conversions_data.total_units] : []),
              ...(isPixel ? [inf.conversions_data.total_value.toFixed(2)] : []),
              ...(isPixel
                ? [
                    (
                      inf.conversions_data.total_value *
                      (inf.conversions_data.net_sales_percentage / 100)
                    ).toFixed(2),
                  ]
                : []),
              ...(isPixel ? [inf.total_coupons_redeem] : []),
              contents
                .filter((t) => t.linked_media)
                .map(
                  (x) =>
                    `${
                      x.post_type === "ig_story"
                        ? ""
                        : this.getPostPrefixUrl(social)
                    }${x.linked_media}`,
                ),
            ];
            table.push(row);
          }
        });
      }
    });

    this.setState({ csvData: table, downloadingCsv: true }, () => {
      window.setTimeout(() => {
        document.getElementById("csv-test").click();
        this.setState({ downloadingCsv: false });
      }, 1000);
    });
  };

  getCounts = (selectedSocial, details) => {
    const storyCount =
      selectedSocial === "instagram" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "ig_story")
            .reduce(function (a, b) {
              return a + b.engagemnts.views;
            }, 0)
        : null;
    const storyEngagements =
      selectedSocial === "instagram" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "ig_story")
            .reduce(function (a, b) {
              return a + b.engagemnts.story_engagements;
            }, 0)
        : null;
    const likesCount = details.contents
      ? details.contents
          .filter((x) => x.post_type === this.props.TaskTypes[selectedSocial])
          .reduce(function (a, b) {
            return a + b.engagemnts.likes;
          }, 0)
      : null;
    const commentsCount = details.contents
      ? details.contents
          .filter((x) => x.post_type === this.props.TaskTypes[selectedSocial])
          .reduce(function (a, b) {
            return a + b.engagemnts.comments;
          }, 0)
      : null;
    const taps_back =
      selectedSocial === "instagram" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "ig_story")
            .reduce(function (a, b) {
              return a + b.engagemnts.story_taps_back;
            }, 0)
        : null;
    const taps_forward =
      selectedSocial === "instagram" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "ig_story")
            .reduce(function (a, b) {
              return a + b.engagemnts.story_taps_forward;
            }, 0)
        : null;
    const swipes_forward =
      selectedSocial === "instagram" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "ig_story")
            .reduce(function (a, b) {
              return a + b.engagemnts.story_swipes_forward;
            }, 0)
        : null;
    const story_reach =
      selectedSocial === "instagram" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "ig_story")
            .reduce(function (a, b) {
              return a + b.engagemnts.story_reach;
            }, 0)
        : null;
    const impression =
      selectedSocial === "instagram" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "ig_post")
            .reduce(function (a, b) {
              return a + b.engagemnts.impressions;
            }, 0)
        : null;
    const post_reach =
      selectedSocial === "instagram" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "ig_post")
            .reduce(function (a, b) {
              return a + b.engagemnts.reach;
            }, 0)
        : null;
    const followers =
      details.social_network_data[selectedSocial] &&
      details.social_network_data[selectedSocial].followers;
    const retweets =
      selectedSocial === "twitter" && details.contents
        ? details.contents
            .filter((x) => x.post_type === "tw_tweet")
            .reduce(function (a, b) {
              return a + b.engagemnts.retweets;
            }, 0)
        : null;
    const viewsCount = details.contents
      ? details.contents
          .filter((x) => x.post_type === this.props.TaskTypes[selectedSocial])
          .reduce(function (a, b) {
            return a + b.engagemnts.views;
          }, 0)
      : null;

    return {
      storyCount,
      storyEngagements,
      likesCount,
      commentsCount,
      followers,
      impression,
      post_reach,
      retweets,
      viewsCount,
      // savesCount,
      // sharesCount,
      story_reach,
      taps_back,
      taps_forward,
      swipes_forward,
    };
  };

  renderDataTable = (breakdown, details) => {
    const header = breakdown === "sku" ? "product" : breakdown;
    return (
      <TabPane
        tab={<span className="uppercase">{header}S</span>}
        key={breakdown}
      >
        {this.state.productsFilters ? (
          <div style={{ padding: 10 }}>
            <SalesProductsTable
              campaignId={this.props.summaryData?.campaign_details?.auction_id}
              initialProducts={[]}
              filters={this.state.productsFilters}
              influencer_id={details.influencer_id}
              shareMode={this.props.shared}
              breakdown={breakdown}
            />
          </div>
        ) : (
          <InfiniteLoader />
        )}
      </TabPane>
    );
  };

  render() {
    const { loaded, influencersData, summaryData } = this.props;
    const { selectedSocial } = this.state;

    if (!loaded || !influencersData) {
      return (
        <div className="logo-loader">
          <div className="loader" />
          <img src={"/images/humanz-logo.png"} />
        </div>
      );
    }

    return (
      <div className="main-middle-container">
        <div className="roi-main-section roi-influencers">
          {
            <this.props.Header
              extraButton={
                influencersData?.length ? this.renderDownloadExcelBtn() : null
              }
            />
          }

          {influencersData?.length ? (
            <>
              {this.renderInfluencersSummary(influencersData)}
              <Tabs
                renderTabBar={() => <InkTabBar />}
                renderTabContent={() => <TabContent />}
                onChange={(e) => {
                  this.setState({
                    selectedSocial: e,
                    openInfluencer: -1,
                    sortBy: null,
                  });
                  window.scrollTo(0, 0);
                }}
                activeKey={selectedSocial}
              >
                {this.props.tabs.map((t) =>
                  this.renderInfluencersTabs(t, summaryData, influencersData),
                )}

                {this.renderInfluencersPerformanceTab(influencersData)}
                {this.renderInfluencersConversionsTab(influencersData)}
              </Tabs>
            </>
          ) : (
            <NoDataMessage>No data to display yet.</NoDataMessage>
          )}
        </div>

        {this.state.csvData && (
          <div className="hidden">
            <CSVLink
              filename={`${summaryData.campaign_details.auction_id}-${summaryData.campaign_details.title}-report.csv`}
              id="csv-test"
              data={this.state.csvData}
            />
          </div>
        )}
      </div>
    );
  }

  getDistributionData = (influencers, type) => {
    const row = influencers
      .map((i) => {
        let total = 0;
        let sum = 0;
        if (type === "impressions" && !this.hideImpressions) {
          total = this.props.summaryData?.kpis?.impressions?.current;
          sum = i.kpis?.total_impressions || 0;
          // sum += i.influencerDetails?.social_network_data[this.state.selectedSocial]?.total_views || 0;
        } else if (type === "engagements") {
          total = this.props.summaryData?.kpis?.engagements?.current;
          sum = i.kpis?.total_engagements;
        } else if (type === "conversions") {
          total = this.props.summaryData?.kpis?.conversions?.current;
          sum = i.clicks_segmentations?.clicksDates?.total
            ? parseFloat(
                i.clicks_segmentations.clicksDates.total.replace(/,/g, ""),
              )
            : 0;
        }
        return {
          percentage:
            !total || !sum ? 0 : Number(((sum / total) * 100).toFixed(2)),
          label:
            i.influencerDetails?.name ||
            i.influencerDetails?.social_network_data[this.state.selectedSocial]
              ?.username,
        };
      })
      .filter((x) => x.percentage)
      .sort((a, b) => b.percentage - a.percentage)
      .slice(0, 10);
    const others = 100 - row.reduce((a, b) => a + b.percentage, 0);
    if (others > 0.5 && others < 100) {
      row.push({
        percentage: Number(others.toFixed(2)),
        label: "Others",
      });
    }
    return row;
  };

  renderInfluencersSummary = (influencers) => {
    if (!influencers) return null;
    const impressions = this.getDistributionData(influencers, "impressions");
    const engagements = this.getDistributionData(influencers, "engagements");
    const conversions = this.getDistributionData(influencers, "conversions");

    return (
      <div style={{ marginTop: 20, textAlign: "center", marginBottom: 20 }}>
        {this.state.showDistribution ? (
          <>
            <strong className="small-title" style={{ marginBottom: 10 }}>
              Contribution From Each Influencer
            </strong>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              {impressions.length > 0 ? (
                <RenderDistributionChart
                  data={impressions}
                  color={"#23a086"}
                  label={"IMPRESSIONS"}
                />
              ) : null}
              {engagements.length > 0 ? (
                <RenderDistributionChart
                  data={engagements}
                  color={"rgb(52, 118, 189)"}
                  label={"ENGAGEMENTS"}
                />
              ) : null}
              {conversions.length > 0 ? (
                <RenderDistributionChart
                  data={conversions}
                  color={"rgb(226, 140, 55)"}
                  label={"CLICKS"}
                />
              ) : null}
            </div>
          </>
        ) : (
          <button
            className="btn btn-primary"
            onClick={() => this.setState({ showDistribution: true })}
          >
            SHOW CONTRIBUTION
          </button>
        )}
      </div>
    );
  };

  renderDownloadExcelBtn = () => {
    if (this.state.downloadingCsv) {
      return (
        <div>
          <div className="send-buttons-loader" />
        </div>
      );
    }
    return (
      <div
        aria-label="Excel"
        style={{
          color: "white",
          background: "var(--main-baby-blue-color)",
          display: "flex",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          height: 30,
          width: 30,
          borderRadius: 20,
        }}
        className="small-title"
        onClick={this.downloadCSV.bind(this)}
      >
        <i
          className="far fa-file-excel"
          aria-label="Download to Excel"
          style={{ fontSize: 18, cursor: "pointer", color: "white" }}
        />
      </div>
    );
  };

  renderInfluencersConversionsTab = (influencersData) => {
    if (!this.state.pixelEnabled) {
      return null;
    }

    const influencers = influencersData;
    const { sortBy } = this.state;
    return (
      <TabPane tab={<span className="uppercase">Sales</span>} key="sales">
        <div>
          <div className="roi-influencers" style={{ marginTop: 20 }}>
            <div
              style={{
                width: "100%",
                color: "#aaaaaa",
                textAlign: "center",
                fontSize: 13,
                marginBottom: 10,
                display: "flex",
              }}
            >
              <div style={{ color: "#555555", marginLeft: 75 }}>
                <p>INFLUENCER</p>
              </div>
              <div style={{ width: 140 }} />
              <div className="roi-table-header-box" style={{ width: "15%" }}>
                {!this.props.shared || this.props.shareInfluencerCost ? (
                  <>
                    <span>Price</span>{" "}
                    <SortBtn
                      type="price"
                      selected={sortBy}
                      onClick={this.sortBy}
                    />
                  </>
                ) : null}
              </div>
              <div className="roi-table-header-box">
                {!this.props.shared || this.props.shareInfluencerCost ? (
                  <>
                    <span>Commission</span>{" "}
                    <SortBtn
                      type="commission"
                      selected={sortBy}
                      onClick={this.sortBy}
                    />
                  </>
                ) : null}
              </div>
              <div className="roi-table-header-box">
                Purchases{" "}
                <SortBtn
                  type="uniquePurchases"
                  selected={sortBy}
                  onClick={this.sortBy}
                />
              </div>
              <div className="roi-table-header-box">
                Units Sold{" "}
                <SortBtn
                  type="purchases"
                  selected={sortBy}
                  onClick={this.sortBy}
                />
              </div>
              <div className="roi-table-header-box">
                Sales Value{" "}
                <SortBtn
                  type="total_value"
                  selected={sortBy}
                  onClick={this.sortBy}
                />
              </div>
              <div className="roi-table-header-box">
                Net Sales Value{" "}
                <SortBtn
                  type="net_value"
                  selected={sortBy}
                  onClick={this.sortBy}
                />
              </div>

              <div style={{ width: 40 }} />
            </div>
            <div className="inf-roi-container">
              {influencers
                .sort(this.sortInfluencers)
                .map(this.renderInfluencerConversionRow)}
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  renderInfluencerConversionRow = (data) => {
    const details = data.influencerDetails;
    const { total_units, total_value, total_coupons_redeem } =
      data.conversions_data;
    const { total_purchases, total_added_to_cart, total_checkouts_visits } =
      details.insights.pixel_conversions
        ? details.insights.pixel_conversions
        : {};
    let total_clicks = data.clicks_segmentations?.clicksDates.total || 0;
    if (typeof total_clicks === "string") {
      total_clicks = total_clicks.replace(/,/g, "");
    }

    const net_value = data.conversions_data.net_sales_percentage
      ? (data.conversions_data.net_sales_percentage * total_value) / 100
      : total_value;
    const commission =
      data.conversions_data.commission_percentage && net_value
        ? data.influencerDetails?.insights.pixel_conversions
            ?.total_influencer_commission || 0
        : 0;
    const isOpen = this.state.openInfluencer === details.influencer_id;
    const headerTextColor = isOpen ? "white" : "#333";
    const handleOpenInfSeg = (e) =>
      this.handleOpenInfSeg(e, details.influencer_id);

    return (
      <div
        key={details.influencer_id}
        className={`roi-influencer-container ${isOpen}`}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            background: isOpen ? "var(--main-baby-blue-color)" : "transparent",
            cursor: "pointer",
          }}
          onClick={() => this.openInfluencer(details.influencer_id)}
        >
          <img
            className="influencer-pic avatar blurable"
            onClick={handleOpenInfSeg}
            src={
              details.picture_url &&
              details.picture_url.replace(".jpg", "_small.jpg")
            }
            role="presentation"
          />
          <div
            style={{
              width: "calc(100% - 75px)",
              textAlign: "center",
              height: 60,
              padding: "20px 20px",
              display: "flex",
              background: isOpen ? "var(--main-baby-blue-color)" : "white",
            }}
          >
            <span
              className=""
              style={{
                textAlign: "left",
                width: "20%",
                fontSize: "13pt",
                color: isOpen ? "white" : "var(--main-baby-blue-color)",
              }}
            >
              <strong
                onClick={handleOpenInfSeg}
                className="inf-roi-name"
                style={{
                  width: 100,
                }}
              >
                {details.name}
              </strong>
            </span>
            <div
              style={{ color: headerTextColor, width: "20%", fontSize: 17 }}
              className="blurable-large"
            >
              {(!this.props.shared || this.props.shareInfluencerCost) && (
                <span>
                  {window.translate(
                    "",
                    `money_symbol_${this.props.summaryData.campaign_details.currency}`,
                  )}
                  <span>{numberWithCommas(details.price)}</span>
                </span>
              )}
            </div>
            <div
              style={{ color: headerTextColor, width: "20%", fontSize: 17 }}
              className="blurable-large"
            >
              {(!this.props.shared || this.props.shareInfluencerCost) && (
                <span>
                  {window.translate(
                    "",
                    `money_symbol_${
                      data.conversions_data.currency ||
                      this.props.summaryData.campaign_details.currency
                    }`,
                  )}
                  <span>{numberWithCommas(commission || 0)}</span>
                </span>
              )}
            </div>
            <div style={{ color: headerTextColor, width: "20%", fontSize: 17 }}>
              <span>{formatBigNumbers(total_purchases)}</span>
            </div>
            <div style={{ color: headerTextColor, width: "20%", fontSize: 17 }}>
              <span>{formatBigNumbers(total_units)}</span>
            </div>
            <div style={{ color: headerTextColor, width: "20%", fontSize: 17 }}>
              <span>{numberWithCommas(total_value)}</span>
            </div>
            <div style={{ color: headerTextColor, width: "20%", fontSize: 17 }}>
              <span>
                {formatBigNumbers(net_value, { minForFormat: 200000 })}
              </span>
            </div>

            <div
              style={{
                width: 20,
                color: isOpen ? "white" : "var(--main-baby-blue-color)",
                marginTop: 4,
                transform: `rotate(${isOpen ? "90deg" : "0deg"})`,
              }}
              className="moi-arrow-left"
            />
          </div>
        </div>
        {isOpen ? (
          <div>
            <div
              style={{
                padding: 10,
                display: "flex",
                flexWrap: "wrap",
                gap: 15,
              }}
            >
              <KpiCube
                label="Clicks"
                value={total_clicks}
                color="#f57e20"
                iconClass=" far fa-hand-back-point-up"
                width={205}
              />
              {total_added_to_cart ? (
                <KpiCube
                  label="Add to Carts"
                  value={total_added_to_cart}
                  color="#9655A2"
                  iconClass=" far fa-cart-shopping"
                  width={205}
                />
              ) : null}
              {total_checkouts_visits ? (
                <KpiCube
                  label="Checkout Visits"
                  value={total_checkouts_visits}
                  color="#EB417A"
                  iconClass=" far fa-money-check-dollar-pen"
                  width={205}
                />
              ) : null}
              {total_coupons_redeem ? (
                <KpiCube
                  label="Coupons Redeem"
                  value={total_coupons_redeem}
                  color="rgb(68, 168, 223)"
                  iconClass=" far fa-badge-percent"
                  width={205}
                />
              ) : null}
            </div>
            <Tabs
              defaultActiveKey="products"
              renderTabBar={() => <InkTabBar />}
              renderTabContent={() => <TabContent />}
            >
              {this.renderDataTable("sku", details)}
              {this.renderDataTable("brand", details)}
              {this.renderDataTable("link", details)}
              <TabPane tab="RULES" key="rules">
                <div style={{ padding: 10 }}>
                  <div
                    className="products-headers"
                    style={{ borderBottom: "1px solid #eee", paddingBottom: 3 }}
                  >
                    <div style={{ width: "100%" }}>Name</div>
                    <div>Commission</div>
                    <div>Total Purchases</div>
                    <div>Sales value</div>
                    <div>Net sales</div>
                  </div>
                  {details.insights?.pixel_conversions?.phases.map((phase) => {
                    return (
                      <div
                        className={"product-row"}
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          borderBottom: "1px solid #eee",
                        }}
                        key={phase.phase_id}
                      >
                        <div>
                          <div
                            style={{
                              width: "100%",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span>{phase.name}</span>
                          </div>
                          <div>
                            <span>
                              {formatBigNumbers(phase.sales_commission)}
                            </span>
                          </div>
                          <div>
                            <span>{formatBigNumbers(phase.purchases)}</span>
                          </div>
                          <div>
                            <span>{formatBigNumbers(phase.sales)}</span>
                          </div>
                          <div>
                            <span>{formatBigNumbers(phase.sales_net)}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPane>
            </Tabs>
          </div>
        ) : null}
      </div>
    );
  };

  renderInfluencersPerformanceTab = (influencersData) => {
    if (
      (this.props.shared && !this.props.shareIndividual) ||
      (!this.props.fromCampaign && !this.props.isMultiReport)
    ) {
      return null;
    }
    const influencers = influencersData;
    const { sortBy } = this.state;
    return (
      <TabPane
        tab={<span className="uppercase">Individual ROI</span>}
        key="general"
      >
        <div>
          <div className="roi-influencers" style={{ marginTop: 20 }}>
            <div
              style={{
                width: "100%",
                color: "#aaaaaa",
                textAlign: "center",
                fontSize: 13,
                marginBottom: 10,
                paddingLeft: 100,
                paddingRight: 20,
                display: "flex",
              }}
            >
              <div
                className="roi-table-header-box"
                style={{ width: "40%", textAlign: "left", color: "#555555" }}
              >
                INFLUENCERS
              </div>
              <div className="roi-table-header-box" style={{ paddingLeft: 10 }}>
                {!this.props.shared || this.props.shareInfluencerCost ? (
                  <>
                    <span>Price</span>{" "}
                    <SortBtn
                      type="price"
                      selected={sortBy}
                      onClick={this.sortBy}
                    />
                  </>
                ) : null}
              </div>
              {this.state.pixelEnabled ? (
                <div className="roi-table-header-box">
                  {(!this.props.shared || this.props.shareInfluencerCost) && (
                    <>
                      <span>Commission</span>{" "}
                      <SortBtn
                        type="commission"
                        selected={sortBy}
                        onClick={this.sortBy}
                      />
                    </>
                  )}
                </div>
              ) : null}
              {!this.hideImpressions && (
                <div className="roi-table-header-box">
                  CPM{" "}
                  <SortBtn type="cpm" selected={sortBy} onClick={this.sortBy} />
                </div>
              )}
              <div className="roi-table-header-box">
                CPV{" "}
                <SortBtn type="cpv" selected={sortBy} onClick={this.sortBy} />
              </div>
              <div className="roi-table-header-box">
                CPE{" "}
                <SortBtn type="cpe" selected={sortBy} onClick={this.sortBy} />
              </div>
              <div className="roi-table-header-box">
                CPC{" "}
                <SortBtn type="cpc" selected={sortBy} onClick={this.sortBy} />
              </div>
              {this.props.summaryData?.pixel_conversions
                ?.total_products_purchases ? (
                <>
                  <div className="roi-table-header-box">
                    CPA{" "}
                    <SortBtn
                      type="cpa"
                      selected={sortBy}
                      onClick={this.sortBy}
                    />
                  </div>
                  <div className="roi-table-header-box">
                    ROAS{" "}
                    <SortBtn
                      type="roas"
                      selected={sortBy}
                      onClick={this.sortBy}
                    />
                  </div>
                </>
              ) : (
                <div className="roi-table-header-box">
                  CTR{" "}
                  <SortBtn type="ctr" selected={sortBy} onClick={this.sortBy} />
                </div>
              )}
            </div>
            <div className="inf-roi-container">
              {influencers
                .sort(this.sortInfluencers)
                .map(this.renderInfluencerPerformanceRow)}
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  renderInfluencerPerformanceRow = (data) => {
    const details = data.influencerDetails;
    const clicks = data.clicks_segmentations;
    const { kpis } = data;
    const { total_value } = data.conversions_data;
    const net_value = data.conversions_data.net_sales_percentage
      ? (data.conversions_data.net_sales_percentage * total_value) / 100
      : total_value;
    const commission =
      data.conversions_data.commission_percentage && net_value
        ? data.influencerDetails?.insights.pixel_conversions
            .total_influencer_commission
        : 0;
    const totalPrice = this.getTotalPrice(data);
    let cpv = 0;
    let cpm = 0;
    let cpc = 0;
    let cpe = 0;
    let cpa = 0;
    let roas = 0;
    let ctr = 0;
    if (totalPrice) {
      cpm = kpis.total_impressions
        ? (totalPrice / kpis.total_impressions) * 1000
        : 0;
      cpe = kpis.total_engagements ? totalPrice / kpis.total_engagements : 0;
      cpv = kpis.total_views ? totalPrice / kpis.total_views : 0;
      if (
        clicks &&
        clicks.clicksDates &&
        clicks.clicksDates.total &&
        clicks.clicksDates.total !== "0"
      ) {
        const totalClicks =
          parseFloat(clicks.clicksDates.total.replace(/,/g, "")) || 0;
        cpc = totalPrice / totalClicks;
        if (kpis.total_impressions) {
          ctr = totalClicks / kpis.total_impressions;
        }
      }
      if (data?.conversions_data?.total_purchases) {
        cpa = totalPrice / data.conversions_data.total_purchases;
        roas = data.conversions_data.total_value / totalPrice;
      }
    }

    const handleOpenInfSeg = (e) =>
      this.handleOpenInfSeg(e, details.influencer_id);

    return (
      <div key={details.influencer_id} className={"roi-influencer-container "}>
        <div
          style={{ width: "100%", display: "flex", background: "transparent" }}
        >
          <img
            className="influencer-pic avatar blurable"
            style={{ cursor: "pointer" }}
            onClick={handleOpenInfSeg}
            src={
              details.picture_url &&
              details.picture_url.replace(".jpg", "_small.jpg")
            }
            role="presentation"
          />
          <div
            style={{
              width: "calc(100% - 75px)",
              textAlign: "center",
              height: 60,
              padding: "20px 20px",
              display: "flex",
              background: "white",
            }}
          >
            <span
              className="blurable"
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: "13pt",
                color: "var(--main-baby-blue-color)",
              }}
            >
              <strong
                className="inf-roi-name"
                style={{
                  width: 160,
                }}
              >
                {details.name}
              </strong>
            </span>
            <div
              style={{ color: "#333333", width: "20%", fontSize: 17 }}
              className="blurable-large"
            >
              {(!this.props.shared || this.props.shareInfluencerCost) && (
                <span>
                  {window.translate(
                    "",
                    `money_symbol_${this.props.summaryData.campaign_details.currency}`,
                  )}
                  <span>{numberWithCommas(details.price)}</span>
                </span>
              )}
            </div>
            {this.state.pixelEnabled ? (
              <div
                style={{ color: "#333333", width: "20%", fontSize: 17 }}
                className="blurable-large"
              >
                {(!this.props.shared || this.props.shareInfluencerCost) && (
                  <span>
                    {window.translate(
                      "",
                      `money_symbol_${this.props.summaryData.campaign_details.currency}`,
                    )}
                    <span>{numberWithCommas(commission)}</span>
                  </span>
                )}
              </div>
            ) : null}
            {!this.hideImpressions && (
              <div style={{ color: "#333333", width: "20%", fontSize: 17 }}>
                <span>{cpm.toFixed(2)}</span>
              </div>
            )}
            <div style={{ color: "#333333", width: "20%", fontSize: 17 }}>
              <span>{cpv.toFixed(2)}</span>
            </div>

            <div style={{ color: "#333333", fontSize: 17, width: "20%" }}>
              <span>{cpe.toFixed(2)}</span>
            </div>

            <div style={{ color: "#333333", fontSize: 17, width: "20%" }}>
              <span>{cpc.toFixed(2)}</span>
            </div>

            {this.props.summaryData?.pixel_conversions
              ?.total_products_purchases ? (
              <>
                <div style={{ color: "#333333", fontSize: 17, width: "20%" }}>
                  <span>{cpa.toFixed(2)}</span>
                </div>
                <div style={{ color: "#333333", fontSize: 17, width: "20%" }}>
                  <span>{roas.toFixed(2)}</span>
                </div>
              </>
            ) : (
              <div style={{ color: "#333333", fontSize: 17, width: "20%" }}>
                <span>{ctr.toFixed(2)}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  sortBy = (type) => {
    if (this.state.sortBy === type) this.setState({ sortBy: null });
    else this.setState({ sortBy: type });
  };

  getTotalPrice = (influencer) => {
    const price = influencer.influencerDetails.price || 0;
    const { total_value } = influencer.conversions_data;
    const net_value = influencer?.conversions_data?.net_sales_percentage
      ? (influencer.conversions_data.net_sales_percentage * total_value) / 100
      : total_value;
    const commission =
      !this.props.summaryData.campaign_details.affiliation_added_at &&
      influencer?.conversions_data?.commission_percentage &&
      net_value
        ? influencer.influencerDetails?.insights.pixel_conversions
            ?.total_influencer_commission || 0
        : 0;
    return price + commission;
  };

  sortInfluencers = (a, b) => {
    if (!this.state.sortBy) return 0;
    const { sortBy, selectedSocial } = this.state;
    let valA = 0;
    let valB = 0;
    switch (sortBy) {
      case "price":
        valA = a.influencerDetails.price;
        valB = b.influencerDetails.price;
        break;
      case "likes":
        valA = this.getCounts(selectedSocial, a.influencerDetails).likesCount;
        valB = this.getCounts(selectedSocial, b.influencerDetails).likesCount;
        break;
      case "comments":
        valA = this.getCounts(
          selectedSocial,
          a.influencerDetails,
        ).commentsCount;
        valB = this.getCounts(
          selectedSocial,
          b.influencerDetails,
        ).commentsCount;
        break;
      case "retweets":
        valA = this.getCounts(selectedSocial, a.influencerDetails).retweets;
        valB = this.getCounts(selectedSocial, b.influencerDetails).retweets;
        break;
      case "story_views":
        valA = this.getCounts(selectedSocial, a.influencerDetails).storyCount;
        valB = this.getCounts(selectedSocial, b.influencerDetails).storyCount;
        break;
      case "video_views":
        valA = this.getCounts(selectedSocial, a.influencerDetails).viewsCount;
        valB = this.getCounts(selectedSocial, b.influencerDetails).viewsCount;
        break;
      case "story_engagements":
        valA = this.getCounts(
          selectedSocial,
          a.influencerDetails,
        ).storyEngagements;
        valB = this.getCounts(
          selectedSocial,
          b.influencerDetails,
        ).storyEngagements;
        break;
      case "followers":
        valA = this.getCounts(selectedSocial, a.influencerDetails).followers;
        valB = this.getCounts(selectedSocial, b.influencerDetails).followers;
        break;
      case "cpm":
        valA =
          this.getTotalPrice(a) && a.kpis.total_impressions
            ? (this.getTotalPrice(a) / a.kpis.total_impressions) * 1000
            : 0;
        valB =
          this.getTotalPrice(b) && b.kpis.total_impressions
            ? (this.getTotalPrice(b) / b.kpis.total_impressions) * 1000
            : 0;
        break;
      case "cpe":
        valA =
          this.getTotalPrice(a) && a.kpis.total_engagements
            ? (this.getTotalPrice(a) / a.kpis.total_engagements) * 1000
            : 0;
        valB =
          this.getTotalPrice(a) && b.kpis.total_engagements
            ? (this.getTotalPrice(b) / b.kpis.total_engagements) * 1000
            : 0;
        break;
      case "cpv":
        valA =
          this.getTotalPrice(a) && a.kpis.total_views
            ? this.getTotalPrice(a) / a.kpis.total_views
            : 0;
        valB =
          this.getTotalPrice(b) && b.kpis.total_views
            ? this.getTotalPrice(b) / b.kpis.total_views
            : 0;
        break;
      case "cpc":
        let clicks = a.clicks_segmentations;
        if (
          this.getTotalPrice(a) &&
          clicks &&
          clicks.clicksDates &&
          clicks.clicksDates.total &&
          clicks.clicksDates.total !== "0"
        ) {
          valA =
            this.getTotalPrice(a) /
            parseFloat(clicks.clicksDates.total.replace(/,/g, ""));
        }

        clicks = b.clicks_segmentations;
        if (
          this.getTotalPrice(b) &&
          clicks &&
          clicks.clicksDates &&
          clicks.clicksDates.total &&
          clicks.clicksDates.total !== "0"
        ) {
          valB =
            this.getTotalPrice(b) /
            parseFloat(clicks.clicksDates.total.replace(/,/g, ""));
        }
        break;
      case "ctr":
        {
          let clicks = a.clicks_segmentations;
          if (
            this.getTotalPrice(a) &&
            clicks &&
            clicks.clicksDates &&
            clicks.clicksDates.total &&
            clicks.clicksDates.total !== "0" &&
            a.kpis.total_impressions
          ) {
            valA =
              parseFloat(clicks.clicksDates.total.replace(/,/g, "")) /
              a.kpis.total_impressions;
          }
          clicks = b.clicks_segmentations;
          if (
            this.getTotalPrice(b) &&
            clicks &&
            clicks.clicksDates &&
            clicks.clicksDates.total &&
            clicks.clicksDates.total !== "0" &&
            b.kpis.total_impressions
          ) {
            valB =
              parseFloat(clicks.clicksDates.total.replace(/,/g, "")) /
              b.kpis.total_impressions;
          }
        }
        break;
      case "cpa":
        valA = a.conversions_data?.total_purchases || 0;
        valB = b.conversions_data?.total_purchases || 0;
        valA = this.getTotalPrice(a) / valA;
        valB = this.getTotalPrice(b) / valB;
        break;
      case "roas":
        valA = a.conversions_data?.total_value || 0;
        valB = b.conversions_data?.total_value || 0;
        valA /= this.getTotalPrice(a);
        valB /= this.getTotalPrice(b);
        break;
      case "commission":
        valA =
          a.influencerDetails?.insights.pixel_conversions
            ?.total_influencer_commission || 0;
        valB =
          b.influencerDetails?.insights.pixel_conversions
            ?.total_influencer_commission || 0;
        break;
      case "purchases":
        valA = a.conversions_data.total_purchases;
        valB = b.conversions_data.total_purchases;
        break;
      case "uniquePurchases":
        valA =
          a.influencerDetails?.insights.pixel_conversions?.total_purchases ?? 0;
        valB =
          b.influencerDetails?.insights.pixel_conversions?.total_purchases ?? 0;
        break;
      case "net_value":
        valA =
          (a.conversions_data.net_sales_percentage / 100) *
          a.conversions_data.total_value;
        valB =
          (b.conversions_data.net_sales_percentage / 100) *
          b.conversions_data.total_value;
        break;
      case "total_value":
        valA = a.conversions_data.total_value;
        valB = b.conversions_data.total_value;
        break;
      case "coupons":
        valA = a.conversions_data.total_coupons_redeem;
        valB = b.conversions_data.total_coupons_redeem;
        break;
      default:
        break;
    }
    valA = valA || 0;
    valB = valA ? valB : 0;
    return valB - valA;
  };

  renderInfluencersTabs = (tab, summaryData, influencersData) => {
    const influencers = influencersData.filter((x) =>
      x.influencerDetails.contents.find(
        (y) =>
          y.post_type === this.props.TaskTypes[tab] ||
          (tab === "instagram" && y.post_type === "ig_story"),
      ),
    );
    const { sortBy } = this.state;
    return (
      <TabPane
        tab={
          <span className="uppercase">
            {tab}{" "}
            <span className="optional-title">
              {summaryData.socialsData[tab] && ` (${influencers.length})`}
            </span>
          </span>
        }
        disabled={!summaryData.socialsData[tab]}
        key={tab}
      >
        {influencers.length > 0 && this.state.selectedSocial === tab && (
          <div className="roi-influencers" style={{ marginTop: 20 }}>
            <div
              style={{
                width: "100%",
                color: "#aaaaaa",
                textAlign: "center",
                fontSize: 13,
                marginBottom: 10,
                paddingLeft: 100,
                paddingRight: 20,
                display: "flex",
              }}
            >
              <div
                style={{ width: "40%", textAlign: "left", color: "#555555" }}
              >
                INFLUENCERS
              </div>
              <div className="roi-table-header-box" style={{ paddingLeft: 10 }}>
                {!this.props.shared ||
                  (this.props.shareInfluencerCost && (
                    <>
                      <span>Price</span>{" "}
                      <SortBtn
                        type="price"
                        selected={sortBy}
                        onClick={this.sortBy}
                      />
                    </>
                  ))}
              </div>
              <div className="roi-table-header-box">
                Likes{" "}
                <SortBtn type="likes" selected={sortBy} onClick={this.sortBy} />
              </div>
              <div className="roi-table-header-box">
                Comments{" "}
                <SortBtn
                  type="comments"
                  selected={sortBy}
                  onClick={this.sortBy}
                />
              </div>
              {tab === "twitter" && (
                <div className="roi-table-header-box">
                  retweets{" "}
                  <SortBtn
                    type="retweets"
                    selected={sortBy}
                    onClick={this.sortBy}
                  />
                </div>
              )}
              {tab === "instagram" && (
                <div className="roi-table-header-box">
                  Story Views{" "}
                  <SortBtn
                    type="story_views"
                    selected={sortBy}
                    onClick={this.sortBy}
                  />
                </div>
              )}
              {tab !== "twitter" && (
                <div className="roi-table-header-box">
                  Video Views{" "}
                  <SortBtn
                    type="video_views"
                    selected={sortBy}
                    onClick={this.sortBy}
                  />
                </div>
              )}
              {tab === "instagram" && (
                <div className="roi-table-header-box">
                  Story Engagements{" "}
                  <SortBtn
                    type="story_engagements"
                    selected={sortBy}
                    onClick={this.sortBy}
                  />
                </div>
              )}
              <div className="roi-table-header-box" style={{ paddingLeft: 10 }}>
                Followers{" "}
                <SortBtn
                  type="followers"
                  selected={sortBy}
                  onClick={this.sortBy}
                />
              </div>
            </div>
            <div className="inf-roi-container">
              {influencers
                .sort(this.sortInfluencers)
                .map((x) => this.renderInfluencer(x))}
            </div>
          </div>
        )}
      </TabPane>
    );
  };

  renderInfluencer(data) {
    const { selectedSocial } = this.state;
    const details = data.influencerDetails;
    const isOpen = this.state.openInfluencer === details.influencer_id;
    const {
      storyCount,
      storyEngagements,
      likesCount,
      commentsCount,
      followers,
      retweets,
      viewsCount,
    } = this.getCounts(selectedSocial, details);
    const noteData = this.props.notesSummary[details.influencer_id];

    const handleOpenInfSeg = (e) =>
      this.handleOpenInfSeg(e, details.influencer_id);

    return (
      <div
        key={details.influencer_id}
        className={`roi-influencer-container ${isOpen}`}
      >
        <div
          style={{
            width: "100%",
            cursor: "pointer",
            display: "flex",
            background: isOpen ? "var(--main-baby-blue-color)" : "transparent",
          }}
          onClick={() => this.openInfluencer(details.influencer_id)}
        >
          <div>
            <img
              className="influencer-pic avatar blurable"
              onClick={handleOpenInfSeg}
              src={
                details.picture_url &&
                details.picture_url.replace(".jpg", "_small.jpg")
              }
              role="presentation"
            />
            {noteData?.seen === false ? (
              <div
                style={{
                  background: "red",
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  position: "absolute",
                  marginTop: -60,
                  marginLeft: 10,
                }}
              />
            ) : null}
          </div>
          <div
            style={{
              width: "calc(100% - 75px)",
              textAlign: "center",
              height: 60,
              padding: "20px 20px",
              display: "flex",
              background: isOpen ? "var(--main-baby-blue-color)" : "white",
            }}
          >
            <span
              className="blurable"
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: "13pt",
                color: isOpen ? "white" : "var(--main-baby-blue-color)",
                cursor: "pointer",
              }}
            >
              <strong
                className="inf-roi-name"
                style={{
                  width: 160,
                }}
              >
                {details.name}
              </strong>
            </span>
            <div
              style={{
                color: isOpen ? "white" : "#333333",
                width: "20%",
                fontSize: 17,
              }}
              className="blurable-large"
            >
              {(!this.props.shared || this.props.shareInfluencerCost) && (
                <span>
                  {window.translate(
                    "",
                    `money_symbol_${this.props.summaryData.campaign_details.currency}`,
                  )}
                  <span>{numberWithCommas(details.price)}</span>
                </span>
              )}
            </div>
            <div
              style={{
                color: isOpen ? "white" : "#333333",
                width: "20%",
                fontSize: 17,
              }}
            >
              <span>{formatBigNumbers(likesCount)}</span>
            </div>
            <div
              style={{
                color: isOpen ? "white" : "#333333",
                width: "20%",
                fontSize: 17,
              }}
            >
              <span>{numberWithCommas(commentsCount)}</span>
            </div>
            {selectedSocial === "instagram" && (
              <div
                style={{
                  color: isOpen ? "white" : "#333333",
                  fontSize: 17,
                  width: "20%",
                }}
              >
                <span>{numberWithCommas(storyCount)}</span>
              </div>
            )}
            {(selectedSocial === "instagram" ||
              selectedSocial === "youtube" ||
              selectedSocial === "tiktok") && (
              <div
                style={{
                  color: isOpen ? "white" : "#333333",
                  fontSize: 17,
                  width: "20%",
                }}
              >
                <span>{numberWithCommas(viewsCount)}</span>
              </div>
            )}
            {selectedSocial === "twitter" && (
              <div
                style={{
                  color: isOpen ? "white" : "#333333",
                  fontSize: 17,
                  width: "20%",
                }}
              >
                <span>{numberWithCommas(retweets)}</span>
              </div>
            )}
            {selectedSocial === "instagram" && (
              <div
                style={{
                  color: isOpen ? "white" : "#333333",
                  fontSize: 17,
                  width: "20%",
                }}
              >
                <span>{numberWithCommas(storyEngagements)}</span>
              </div>
            )}
            <div
              style={{
                color: isOpen ? "white" : "#333333",
                width: "20%",
                fontSize: 17,
              }}
            >
              <span>{numberWithCommas(followers)}</span>
            </div>
            <div
              style={{
                color: isOpen ? "white" : "var(--main-baby-blue-color)",
                transform: isOpen ? "rotate(90deg)" : "",
                marginTop: isOpen ? 0 : 2,
                marginLeft: -5,
                marginRight: -10,
              }}
            >
              <span className="moi-arrow-left" />
            </div>
          </div>
        </div>

        {this.renderInfluencerInner(isOpen, data, details, selectedSocial)}
      </div>
    );
  }

  renderInfluencerInner = (isOpen, data, details, selectedSocial) => {
    if (!isOpen) return null;
    let insightsCharts = null;
    let clicksCharts = null;
    if (data.youtube_original_age_insights) {
      insightsCharts = youtubeCharts(data.youtube_original_age_insights);
    } else if (
      data.engagment_segmentations[selectedSocial] ||
      data.clicks_segmentations
    ) {
      insightsCharts = semiMode
        ? null
        : roiCharts(
            this.props.storyOnly
              ? null
              : data.engagment_segmentations[selectedSocial],
            true,
          );
      clicksCharts = conversionsCharts(
        data.clicks_segmentations,
        this.props.summaryData.countPerLink,
        true,
      );
    }
    const noteData = this.props.notesSummary[details.influencer_id];

    const reachFrequencyData =
      selectedSocial === "instagram" &&
      (details?.social_network_data?.instagram?.reach_and_frequency ||
        this.props.reachFrequency?.influencers?.[details.influencer_id]
          ?.reach_frequency);

    return (
      <div>
        {reachFrequencyData ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <KpiCube
              label="Unique Reach"
              value={reachFrequencyData.unique_reach || 0}
              width={205}
              height={100}
              infoText={
                "Unique Reach estimates the total number of distinct individuals who have seen your content at least once.\nThis metric counts each person only once, providing insight into the breadth of your audience."
              }
            />
            <KpiCube
              label="Frequency"
              value={reachFrequencyData.frequency || 0}
              width={205}
              height={100}
              decimals={2}
              formattingFn={null}
              infoText={
                "Frequency estimates the average number of times each individual has seen your content.\nThis metric provides insight into how often your audience is being exposed to your posts and stories."
              }
            />
          </div>
        ) : null}
        <Tabs
          renderTabBar={() => <InkTabBar />}
          renderTabContent={() => <TabContent />}
        >
          {(insightsCharts || clicksCharts) && (
            <TabPane tab={<span>INSIGHTS</span>} key="1">
              <>
                {insightsCharts}
                {!data.youtube_original_age_insights && clicksCharts}
              </>
            </TabPane>
          )}
          <TabPane tab={<span>CONTENTS</span>} key="2">
            {this.renderInfluencerContents(
              selectedSocial,
              details,
              data.clicks_segmentations,
            )}
          </TabPane>
        </Tabs>
        {this.props.notesSummary.shared_guid && this.props.fromCampaign ? (
          <div>
            <i
              onClick={() => this.props.openNotes(details.influencer_id)}
              className="fa fa-comment"
              style={{
                color:
                  noteData?.amount_notes > 0
                    ? "var(--main-baby-blue-color)"
                    : "#aaa",
                marginTop: 10,
                fontSize: 18,
                cursor: "pointer",
              }}
            />
            {noteData?.seen === false ? (
              <div
                style={{
                  position: "absolute",
                  background: "red",
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  marginTop: -20,
                  marginLeft: 10,
                }}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  renderInfluencerContents = (selectedSocial, details) => {
    return (
      <div>
        {/* Influencer - Top posts */}
        {details.contents && (
          <div>
            <div
              style={{
                fontSize: "16pt",
                marginBottom: 15,
              }}
            >
              Top{" "}
              {selectedSocial === "tiktok" || selectedSocial === "youtube"
                ? "Videos"
                : "Posts"}
            </div>
            <div className="post-block">
              {selectedSocial === "instagram" &&
                details.contents
                  .filter((x) => x.post_type === "ig_post")
                  .map((x, index) => {
                    return (
                      <div
                        className="blurable-frame"
                        style={{
                          display: "inline-block",
                          width: 290,
                          height: 535,
                          overflow: "hidden",
                        }}
                        key={index}
                      >
                        <iframe
                          src={`https://www.instagram.com/p/${x.linked_media}/embed/captioned`}
                          width="288"
                          height="535"
                          frameBorder="0"
                          scrolling="no"
                        ></iframe>
                      </div>
                    );
                  })}

              {selectedSocial === "instagram" &&
                details.contents
                  .filter((x) => x.post_type === "ig_story")
                  .map((x, idx) => {
                    return (
                      <div className="story-image-container" key={idx}>
                        <div>
                          Views Count: {formatBigNumbers(x.engagemnts.views)}
                        </div>
                        {x.linked_media ? (
                          x.mime_type && x.mime_type.includes("video") ? (
                            <video controls width="288" height="535">
                              <source src={x.linked_media}></source>
                            </video>
                          ) : (
                            <img className="blurable" src={x.linked_media} />
                          )
                        ) : (
                          <img className="no-image" src={NoImageFound} />
                        )}
                      </div>
                    );
                  })}
              {selectedSocial === "twitter" &&
                details.contents
                  .filter((x) => x.post_type === "tw_tweet")
                  .map((x, index) => {
                    return (
                      <div
                        className="blurable-frame"
                        style={{
                          display: "inline-block",
                          width: 290,
                          height: 535,
                          overflow: "hidden",
                        }}
                        key={index}
                      >
                        <TwitterTweetEmbed tweetId={x.linked_media} />
                      </div>
                    );
                  })}
              {selectedSocial === "youtube" &&
                details.contents
                  .filter((x) => x.post_type === "yt_video")
                  .map((x, index) => {
                    return (
                      <div
                        className="blurable-frame"
                        style={{
                          display: "inline-block",
                          width: 290,
                          overflow: "hidden",
                          marginTop: "10px",
                        }}
                        key={index}
                      >
                        <YouTube
                          videoId={x.linked_media}
                          opts={{ width: "290" }}
                        />
                      </div>
                    );
                  })}
              {selectedSocial === "tiktok" &&
                details.contents
                  .filter((x) => x.post_type === "tk_video")
                  .map((x, index) => {
                    return (
                      <div
                        className="blurable-frame"
                        style={{
                          display: "inline-block",
                          width: 300,
                          height: 500,
                          overflow: "hidden",
                          marginTop: 10,
                        }}
                        key={index}
                      >
                        <iframe
                          style={{
                            display: "inline-block",
                            width: 300,
                            height: 500,
                            overflow: "hidden",
                          }}
                          src={`https://www.tiktok.com/embed/${x.linked_media}`}
                          frameBorder={"0"}
                          allow={
                            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          }
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        )}
      </div>
    );
  };
}

const SortBtn = (props) => {
  return (
    <i
      className={`fas fa-sort-down inf-sort-btn ${
        props.selected === props.type
      }`}
      onClick={() => props.onClick(props.type)}
    />
  );
};

export default function RoiInfluencers(props) {
  const context = useContext(RoiContext);

  return <RoiInfluencersInner {...props} {...context} />;
}
