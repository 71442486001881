import { Box } from "@material-ui/core";
import Tooltip from "rc-tooltip";
import { CommunityBadge } from "src/api/types";
import community from "../../../../images/svgs/community.svg";

interface ICampaignCommunityBadge {
  communityBadge: CommunityBadge;
  badgeStyleType: "row" | "segmentation";
}

const CampaignCommunityBadge = ({
  communityBadge,
  badgeStyleType,
}: ICampaignCommunityBadge) => {
  return (
    <Box>
      <Tooltip
        overlay={
          <Box className="community-badge__tooltip">
            <img
              src={communityBadge.picture}
              className={"community-badge-tooltip__picture"}
            />
            <p className="community-badge-tooltip__text">
              {communityBadge.name}
            </p>
          </Box>
        }
        placement="bottom"
      >
        <Box className={`community-badge__container_${badgeStyleType}`}>
          <img src={community} className="community-badge__icon" />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CampaignCommunityBadge;
