import { Component } from "react";
import { connect } from "react-redux";
import { declineEnterpriseInvite } from "../../actions/enterpriseActions";

interface OwnProps {
  invite_id: string;
  declineEnterpriseInvite: any;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{
  declined: false;
}>;

class DeclineInvite extends Component<Props, State> {
  readonly state: State = {
    declined: false,
  };

  componentDidMount(): void {
    this.props.declineEnterpriseInvite(this.props.invite_id, () => {
      setTimeout((res: any) => {
        console.log(res);
        window.location.href = "/";
      }, 1000);
    });
  }

  render() {
    return (
      <>
        {this.state.declined ? (
          <strong>The invite has been declined, redirecting you...</strong>
        ) : (
          <strong>Sending response...</strong>
        )}
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => ({
  declineEnterpriseInvite: (invite_id: string, callback: any) =>
    dispatch(declineEnterpriseInvite(invite_id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeclineInvite);
