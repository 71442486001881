import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";

export const AppChakraProvider: React.FC = ({ children }: any) => {
  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      {children}
    </ChakraProvider>
  ) as any;
};
