import { toast } from "react-toastify";
import { deleteMoiInboxMessageMessage_guid } from "../services";

export const DeleteChatMessage = (guid: string, callback?: any) => {
  return (dispatch: any) => {
    deleteMoiInboxMessageMessage_guid(guid)
      .then((res: any) => {
        callback(res);
        toast.success("Message was deleted");
        dispatch({
          type: "CHAT_MESSAGE_DELETED",
          payload: { message_guid: guid },
        });
      })
      .catch(() => {
        toast.error("Error deleting message");
        callback(false);
        dispatch({ type: "CHAT_MESSAGE_DELETE_ERROR" });
      });
  };
};
