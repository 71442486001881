import { LocalTask } from "src/api/ExtendedTypes";
import {
  InfluencerInManageAuctionData,
  LinkedTaskPost,
  ManualPostStats,
  PostTypes,
  TaskManageData,
} from "src/api/types";
import { TasksTypes } from "src/helpers/SocialNetworksHelper";

export interface LinkedTaskPostExtended extends LinkedTaskPost {
  id: number;
  completed?: boolean;
  value?: string;
  views?: number;
}

export interface AttachLinksProps {
  data: { influencer: InfluencerInManageAuctionData; boardId: number };
  onDataChange: (newData: AttachLinksProps["data"]) => void;
  changeStoriesCount: any;
  attachLinkMedia: any;
  board: any;
  handleClose: any;
  influencers: Array<InfluencerInManageAuctionData>;
  revertLinkedMedia: any;
  revertLinkedStory: any;
  updateInfluencerOnBoard: any;
}

export interface StoryInput extends TaskManageData {
  base64?: string | ArrayBuffer | null;
  views?: number | string | null;
  story_engagements?: number | string | null;
  url?: string | null;
  story_id?: string | null;
  e?: any;
  type?: string | null;
  new?: boolean | null;
  progress?: number | null;
  uploading?: boolean | null;
  media_creation_time?: string;
  failed?: boolean | null;
}

export interface SaveData {
  post_uri?: string;
  task_id?: number;
  post_type?: string;
  boosted_impressions?: number;
  manual_post_stats?: ManualPostStats;
  media_uid?: string;
  internal_media_id?: number;
}

export interface ManualPostInput {
  name: string;
  field: string;
  defaultValue?: number;
  social?: "instagram" | "twitter" | "youtube" | "tiktok" | null;
  tooltip?: string;
  disabled?: boolean;
}

export type StoryData = {
  mediaUrl: string;
  mediaType: "video" | "image";
  views?: number;
  engagements?: number;
  saved: number;
  shares: number;
  reach: number;
  taps_back: number;
  taps_forward: number;
  swipes_forward: number;
  media_creation_time: string;
};

const tasksTypesWithoutStory = TasksTypes.filter(
  (type) => type !== PostTypes.ig_story,
);

export const tasksAttachLinksArray = (tasks: Array<LocalTask>) => {
  const attachLinksArray: Array<
    LinkedTaskPostExtended | StoryInput | SaveData
  > = [];
  tasks.forEach((task: any) => {
    if (
      tasksTypesWithoutStory.includes(task.post_type) &&
      task.post_type !== PostTypes.ig_story &&
      task.post_type !== PostTypes.influencer_content
    ) {
      const newEngagemnts = task?.engagemnts?.manual_post_stats_last_update_time
        ? {
            ...task.engagemnts,
          }
        : {
            media_creation_time: null,
          };
      attachLinksArray.push({
        id: task.id,
        completed: task.completed,
        value: "",
        boosted_impressions: task?.engagemnts?.boosted_impressions ?? null,
        internal_media_id: task.internal_media_id,
        media_uid: task.linked_media_id,
        manual_post_stats: newEngagemnts,
        type: task.post_type,
      });
    } else if (task.post_type === PostTypes.ig_story) {
      attachLinksArray.push({
        id: task.id,
        views: task.engagemnts ? task.engagemnts.views : "",
        e: {},
        story_id: task.linked_media_id,
        url: task.linked_media,
        mime_type: task.mime_type,
        base64: null,
        type: "ig_story",
        story_engagements: task.engagemnts
          ? task.engagemnts.story_engagements
          : 0,
        media_creation_time: task.engagemnts?.media_creation_time,
      });
    }
  });
  return attachLinksArray;
};

export const isEmptyPostStats = (stats: TaskManageData["engagemnts"]) => {
  if (!stats) {
    return true;
  }
  const values = Object.values(stats);
  for (let i = 0; i < values.length; i++) {
    if (values[i]) {
      return false;
    }
  }
  return true;
};
