// TODO - lint
/* eslint-disable no-nested-ternary, no-plusplus, consistent-return, default-case, global-require, react/no-unescaped-entities, no-plusplus, no-unused-vars, import/no-default-export */
// General Imports
import React from "react";
import Dialog from "rc-dialog";
import { connect } from "react-redux";

// Actions imports
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Infinite from "react-infinite";
import YouTube from "react-youtube";
import moment from "moment";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { TikTokVideoPrefix } from "../../../utilities/general";
import { MessageDialog } from "../../../dialogs/MessageDialog";
import {
  deleteAmbassadorMedias,
  getAmbassadorMedias,
} from "../../../../../actions/ambassadorReportsActions";

class AmbassadorAllMedias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onLoad() {
    const { influencer, reportId, type } = this.props;
    this.setState({ loading: true }, () => {
      this.props.getAmbassadorMedias(reportId, influencer.id, type, (data) => {
        this.setState({ posts: data ? data.medias : [], loading: false });
      });
    });
  }

  componentDidMount() {
    this.onLoad();
  }

  renderAllContentsTab() {
    const allContents = this.state.posts;
    if (!allContents || !allContents.length) {
      return <div>No content to display right now.</div>;
    }
    const instagramPosts = allContents.filter((x) => x.post_type === "ig_post");
    const instagramStories = allContents.filter(
      (x) => x.post_type === "ig_story" && x.linked_media,
    );
    const twitterTweets = allContents.filter((x) => x.post_type === "tw_tweet");
    const youtubeVideos = allContents.filter((x) => x.post_type === "yt_video");
    const tiktokVideos = allContents.filter((x) => x.post_type === "tk_video");
    // let currentTab = this.getContentsTab(instagramPosts, instagramStories, twitterTweets, youtubeVideos, tiktokVideos);
    return (
      <div className="roi-main-section">
        {(instagramPosts.length > 0 ||
          instagramStories.length ||
          tiktokVideos.length > 0 ||
          youtubeVideos.length ||
          twitterTweets.length > 0) && (
          <Tabs
            renderTabBar={() => <InkTabBar />}
            renderTabContent={() => <TabContent />}
            // activeKey={currentTab}
            // onChange={t => this.setState({ contentsTab: t })}
          >
            {instagramPosts.length > 0 && (
              <TabPane
                tab={
                  <span>
                    INSTAGRAM POSTS{" "}
                    <span className="optional-title">{` (${instagramPosts.length})`}</span>
                  </span>
                }
                key="ig_post"
              >
                <Infinite
                  elementHeight={565}
                  className="campaign-influencers-table-container"
                  useWindowAsScrollContainer={true}
                >
                  {this.renderMediaCubeRow(instagramPosts)}
                </Infinite>
              </TabPane>
            )}
            {instagramStories.length > 0 && (
              <TabPane
                tab={
                  <span>
                    INSTAGRAM STORIES
                    <span className="optional-title">{` (${instagramStories.length})`}</span>
                  </span>
                }
                key="ig_story"
              >
                <Infinite
                  elementHeight={565}
                  className="campaign-influencers-table-container"
                  useWindowAsScrollContainer={true}
                >
                  {this.renderMediaCubeRow(instagramStories)}
                </Infinite>
              </TabPane>
            )}
            {tiktokVideos.length > 0 && (
              <TabPane
                tab={
                  <span>
                    TIKTOK VIDEOS
                    <span className="optional-title">{` (${tiktokVideos.length})`}</span>
                  </span>
                }
                key="tk_video"
              >
                <Infinite
                  elementHeight={565}
                  className="campaign-influencers-table-container"
                  useWindowAsScrollContainer={true}
                >
                  {this.renderMediaCubeRow(tiktokVideos)}
                </Infinite>
              </TabPane>
            )}
            {twitterTweets.length > 0 && (
              <TabPane
                tab={
                  <span>
                    TWITTER TWEETS
                    <span className="optional-title">{` (${twitterTweets.length})`}</span>
                  </span>
                }
                key="tw_tweet"
              >
                <Infinite
                  elementHeight={565}
                  className="campaign-influencers-table-container"
                  useWindowAsScrollContainer={true}
                >
                  {this.renderMediaCubeRow(twitterTweets)}
                </Infinite>
              </TabPane>
            )}
            {youtubeVideos.length > 0 && (
              <TabPane
                tab={
                  <span>
                    YOUTUBE VIDEOS
                    <span className="optional-title">{` (${youtubeVideos.length})`}</span>
                  </span>
                }
                key="yt_video"
              >
                <Infinite
                  elementHeight={565}
                  className="campaign-influencers-table-container"
                  useWindowAsScrollContainer={true}
                >
                  {this.renderMediaCubeRow(youtubeVideos)}
                </Infinite>
              </TabPane>
            )}
          </Tabs>
        )}
      </div>
    );
  }

  renderMediaCubeRow = (influencers) => {
    const CUBES_PER_ROW = 3;
    let numberOfRows = influencers.length / CUBES_PER_ROW;
    if (influencers % CUBES_PER_ROW !== 0) {
      numberOfRows += 1;
    }
    const groups = [];
    for (let i = 0; i < numberOfRows; i++) {
      const baseNumber = i * CUBES_PER_ROW;
      const group = [];
      for (
        let j = 0;
        j + baseNumber < influencers.length && j < CUBES_PER_ROW;
        j++
      ) {
        group.push(influencers[baseNumber + j]);
      }
      if (group.length > 0) {
        groups.push(group);
      }
    }

    return groups.map(this.renderMediaGroup);
  };

  renderMediaGroup = (group, idx) => {
    return (
      <div
        style={{ display: "flex", justifyContent: "sapce-between" }}
        key={idx}
      >
        {group.map(this.renderMediaCube)}
      </div>
    );
  };

  renderCubeHeader = (media) => {
    return (
      <div
        className="media-header"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div style={{ flex: 1 }}>
          <span>
            Attached at:{" "}
            {moment(media.attached_at).format(window.time.date_with_hour)}
          </span>
          {media.is_manual ? (
            <span className={"links-label"}>Attached manually</span>
          ) : (
            ""
          )}
        </div>
        <span style={{ justifySelf: "flex-end" }}>
          <button
            className={"btn"}
            onClick={() => this.setState({ currentMediaToDelete: media })}
          >
            <i className="fas fa-trash" />
          </button>
        </span>
      </div>
    );
  };

  renderMediaCube = (media, idx) => {
    switch (media.post_type) {
      case "ig_post":
        return (
          <div className="blurable-frame ig-frame" key={idx}>
            {this.renderCubeHeader(media)}
            <iframe
              src={`https://www.instagram.com/p/${media.media_id}/embed/captioned`}
              width="288"
              height="535"
              frameBorder="0"
              scrolling="no"
            ></iframe>
          </div>
        );
      case "ig_story":
        return (
          <div
            className="story-image-container"
            key={idx}
            style={{ marginTop: 30 }}
          >
            {media.linked_media ? (
              media.mime_type && media.mime_type.includes("video") ? (
                <video controls width="288" height="535">
                  <source src={media.linked_media}></source>
                </video>
              ) : (
                <img className="blurable" src={media.linked_media} />
              )
            ) : (
              <img
                className="no-image"
                src={require("../../../../../images/no-image.png")}
              />
            )}
          </div>
        );
      case "tw_tweet":
        return (
          <div className="blurable-frame ig-frame" key={idx}>
            {this.renderCubeHeader(media)}
            <TwitterTweetEmbed tweetId={media.media_id} />
          </div>
        );
      case "yt_video":
        return (
          <div className="blurable-frame ig-frame" key={idx}>
            {this.renderCubeHeader(media)}
            <YouTube
              videoId={`https://www.youtube.com/watch?v=${media.media_id}`}
              opts={{ width: "290", height: 535 }}
            />
          </div>
        );
      case "tk_video":
        return (
          <div
            className="blurable-frame"
            style={{
              display: "inline-block",
              width: 290,
              height: 535,
              overflow: "hidden",
              paddingLeft: 20,
            }}
            key={idx}
          >
            {this.renderCubeHeader(media)}
            <iframe
              style={{
                display: "inline-block",
                width: 290,
                height: 535,
                overflow: "hidden",
              }}
              src={`${TikTokVideoPrefix}${media.media_id}`}
              frameBorder={"0"}
              allow={
                "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              }
            />
          </div>
        );
    }
  };

  deleteMedia = (media) => {
    this.setState({ currentMediaToDelete: media });
    this.props.deleteAmbassadorMedias(
      media.media_id,
      this.props.influencer.id,
      this.props.reportId,
      this.props.type,
      () => {
        this.props.influencer.medias_amount--;
        this.setState({ currentMediaToDelete: null }, this.onLoad);
      },
    );
  };

  renderConfirmDialog() {
    const media = this.state.currentMediaToDelete;
    return (
      <MessageDialog
        handleClose={() => {
          this.setState({ currentMediaToDelete: null });
        }}
        icon="fas fa-user-circle"
      >
        <div style={{ textAlign: "center", padding: 20 }}>
          <p>
            {this.props.message ||
              "Are you sure you want to delete this media?"}
          </p>
          <div
            style={{ paddingBottom: 5, paddingTop: 10, textAlign: "center" }}
          >
            <button
              onClick={() => this.deleteMedia(media)}
              className="btn btn-primry"
              style={{
                background: "var(--main-baby-blue-color)",
                color: "white",
                borderRadius: "40px",
                width: 100,
                marginTop: 15,
              }}
            >
              DELETE
            </button>
            <button
              onClick={() => this.setState({ currentMediaToDelete: null })}
              className="btn btn-primry cancel"
              style={{
                background: "white",
                color: "var(--main-baby-blue-color)",
                borderRadius: "40px",
                width: 100,
                marginTop: 15,
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </MessageDialog>
    );
  }

  render() {
    const influencer = this.props.influencer.name;
    return (
      <Dialog
        onClose={() => this.props.handleClose()}
        visible={true}
        style={{ marginTop: 100, width: "90%" }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">All posts by {influencer}</div>

          <div
            className="main-middle-container-medias"
            style={{ position: "relative" }}
          >
            {!this.state.loading && this.state.posts ? (
              this.renderAllContentsTab()
            ) : (
              <div
                style={{ textAlign: "center", marginTop: 200, minHeight: 500 }}
              >
                <p>
                  {this.state.loading &&
                    "Please wait while we load the media from social networks..."}
                  {!this.state.loading &&
                  (!this.state.posts || this.state.posts.length === 0)
                    ? "There are currently no posts to display for this user."
                    : ""}
                </p>
              </div>
            )}
          </div>
        </div>
        {this.state.currentMediaToDelete && this.renderConfirmDialog()}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAmbassadorMedias: (reportId, groupId, type, callback) =>
      dispatch(getAmbassadorMedias(reportId, groupId, type, callback)),
    deleteAmbassadorMedias: (mediaId, groupId, reportId, type, callback) =>
      dispatch(
        deleteAmbassadorMedias(mediaId, groupId, reportId, type, callback),
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AmbassadorAllMedias);
