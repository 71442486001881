// TODO - lint
/* eslint-disable import/no-default-export */
// General Imports
import React from "react";
import Dialog from "rc-dialog";
import { connect } from "react-redux";

// Components Import
import { mimicSpecialUser, mimicUser } from "../../../actions/userActions";

class MimicUserDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      email: "",
      userId: undefined,
      auctionId: undefined,
    };
  }

  onSave = () => {
    if (!this.state.email && !this.state.userId && !this.state.auctionId) {
      return;
    }
    this.setState({ saving: true });
    if (this.state.saving) return;

    this.props.mimicUser(
      this.state.email?.trim(),
      this.state.userId?.trim(),
      this.state.auctionId?.trim(),
      (success) => {
        if (success) {
          if (this.state.auctionId?.trim()) {
            window.location = `/campaign/${this.state.auctionId?.trim()}`;
          } else {
            window.location.reload();
          }
        } else {
          this.setState({ saving: false });
        }
      },
    );
  };

  mimicSpecialUser = (type) => {
    this.props.mimicSpecialUser(type, () => {
      window.location.reload();
    });
  };

  render() {
    const { email, userId, auctionId } = this.state;
    return (
      <Dialog
        onClose={this.props.handleClose}
        className="edit-tasks-dialog"
        visible={true}
        style={{ marginTop: 50 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <>
          <div className="edit-tasks-header">Mimic User</div>
          <div className="mimic-form">
            <div className="mimic-form-field" style={{ marginBottom: 20 }}>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 300,
                  marginTop: 20,
                }}
              >
                Mimic user
              </div>
              <div style={{ fontSize: 14, fontWeight: 300 }}>
                Enter email OR User id for mimicing{" "}
              </div>
              <button
                style={{ marginRight: 5 }}
                className={"btn btn-primary"}
                onClick={() => this.mimicSpecialUser("sales")}
              >
                SALES
              </button>
              <button
                className={"btn btn-primary"}
                onClick={() => this.mimicSpecialUser("tagger")}
              >
                TAGGER
              </button>
            </div>
            <div className="mimic-form-field">
              <div>Email:</div>
              <input
                value={email}
                onKeyPress={(event) => {
                  if (event.keyCode === 13) {
                    this.onSave.bind(this);
                  }
                }}
                onChange={(e) => this.setState({ email: e.target.value })}
                className="input-line"
              ></input>
            </div>
            <div className="mimic-form-field">
              <div>UserId:</div>
              <input
                value={userId}
                onKeyPress={(event) => {
                  if (event.keyCode === 13) {
                    this.onSave.bind(this);
                  }
                }}
                type="number"
                onChange={(e) => this.setState({ userId: e.target.value })}
                className="input-line"
              ></input>
            </div>
            <div className="mimic-form-field">
              <div>Auction ID:</div>
              <input
                value={auctionId}
                onKeyPress={(event) => {
                  if (event.keyCode === 13) {
                    this.onSave.bind(this);
                  }
                }}
                type="number"
                onChange={(e) => this.setState({ auctionId: e.target.value })}
                className="input-line"
              ></input>
            </div>
          </div>
          <div
            style={{ paddingBottom: 15, paddingTop: 15, textAlign: "center" }}
          >
            <button
              className={`attach-links-btn btn btn-primary cancel ${
                this.state.saving && "disabled"
              }`}
              onClick={() => this.props.handleClose()}
            >
              <span>Cancel</span>
            </button>
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn btn btn-primary ${
                this.state.saving && "disabled"
              }`}
              onClick={this.onSave.bind(this)}
            >
              <span>Save</span>
            </button>
          </div>
        </>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    mimicUser: (email, userId, auctionId, callback) => {
      dispatch(mimicUser(email, userId, auctionId, callback));
    },
    mimicSpecialUser: (type, callback) => {
      dispatch(mimicSpecialUser(type, callback));
    },
  };
};

export default connect(null, mapDispatchToProps)(MimicUserDialog);
