import { Box, Button, Text } from "@chakra-ui/react";
import { useState } from "react";

type Props = {
  iconOpen: string;
  iconClose: string;
  header: string;
  description: string;
  onChange: () => void;
};

const IndicatorToggle = (props: Props) => {
  const [isOpen, setOpen] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            background: isFocused ? "#249FF0" : "#EBEBEB",
            height: "auto",
            marginRight: "8px",
            width: "1px",
          }}
        ></Box>
        <Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "auto",
                padding: 0,
                color: "#222325",
              }}
            >
              {props.header}
            </Text>
            {props.description && (
              <Text
                sx={{
                  fontSize: "12px",
                  marginLeft: "8px",
                  fontWeight: 400,
                  color: "#AAAAAA",
                }}
              >
                {props.description}
              </Text>
            )}
          </Box>
          <Box
            sx={{ display: "flex" }}
            onClick={() => {
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
          >
            <Button
              sx={{
                background: isOpen ? "#249FF0" : "white",
                color: isOpen ? "white" : "grey",
                "&:hover": {
                  background: isOpen ? "#249FF0" : "white",
                  color: isOpen ? "white" : "grey",
                },
              }}
              onClick={() => {
                setOpen(!isOpen);
                props.onChange();
              }}
            >
              <i className={props.iconOpen}></i>
            </Button>
            <Button
              sx={{
                background: isOpen ? "white" : "#249FF0",
                color: isOpen ? "grey" : "white",
                "&:hover": {
                  background: isOpen ? "white" : "#249FF0",
                  color: isOpen ? "grey" : "white",
                },
              }}
              onClick={() => {
                setOpen(!isOpen);
                props.onChange();
              }}
            >
              <i className={props.iconClose}></i>
            </Button>
          </Box>
        </Box>
      </Box>
      {/* if we want to add an error massage replace false with "error" */}
      {false && !isFocused && (
        <div>
          <i className="error-mark" />
          <span className="error-text">
            Whoops! This field can not be empty
          </span>
        </div>
      )}
    </>
  );
};

export default IndicatorToggle;
