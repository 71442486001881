// TODO - lint
/* eslint-disable no-param-reassign, react/no-direct-mutation-state, class-methods-use-this, camelcase, camelcase, class-methods-use-this, import/no-default-export */
import React from "react";
import Dialog from "rc-dialog";
import Rating from "react-rating";
import { getInfluencerRating } from "../../../actions/premiumActions";

class InfluencerRatingDialog extends React.PureComponent {
  state = {
    influencer: null,
    reviews: null,
    loading: false,
    hasMore: false,
  };

  componentDidMount() {
    this.setState({ loading: true, influencer: this.props.influencer });
    getInfluencerRating(this.props.influencer.influencer_id, null, (data) => {
      const reviews = data && data.reviews ? data.reviews : [];
      this.setState({
        reviews,
        loading: false,
        hasMore: reviews.length > 9,
      });
    });
  }

  handleLoadMore = () => {
    if (this.state.loadingMore) return;

    const { reviews } = this.state;

    const lastTime = reviews[reviews.length - 1].review_time;

    this.setState({ loadingMore: true });
    getInfluencerRating(
      this.state.influencer.influencer_id,
      lastTime,
      (newReviews) => {
        newReviews = newReviews && newReviews.reviews ? newReviews.reviews : [];
        this.state.reviews = reviews.concat(newReviews);
        this.setState({ loadingMore: false, hasMore: newReviews.length > 9 });
      },
    );
  };

  render() {
    if (!this.state.influencer) return null;
    const { influencer } = this.state;
    return (
      <Dialog
        className={"reviews-dialog "}
        title={""}
        onClose={this.props.onClose}
        visible={true}
        bodyStyle={{ height: "100%", overflowY: "hidden", overflowX: "hidden" }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="rating-title">Influencer Reviews</div>

          <div
            style={{
              textAlign: "center",
              marginTop: 10,
              borderBottom: "1px solid #eee",
            }}
          >
            <img
              rol={"presentation"}
              style={{ width: 50, height: 50, borderRadius: "50%" }}
              src={influencer.image}
            />
            <br />
            <strong>{influencer.name}</strong>
          </div>

          <div className="rating-container">
            {this.renderStars(
              "OVERALL",
              influencer.stars,
              influencer.stars_amount,
            )}
            {this.renderStars(
              "CONTENT",
              influencer.content_stars,
              influencer.content_stars_amount,
            )}
            {this.renderStars(
              "PROFESSIONAL",
              influencer.professional_stars,
              influencer.professional_stars_amount,
            )}
            {this.renderStars(
              "RESULTS",
              influencer.results_stars,
              influencer.results_stars_amount,
            )}
          </div>

          {this.renderReviews()}
        </div>
      </Dialog>
    );
  }

  renderStars(label, stars, stars_amount) {
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <strong>{label}</strong>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <Rating
            emptySymbol="fa fa-star-o fa-2x"
            fullSymbol="fa fa-star fa-2x"
            className="seg-stars"
            initialRating={stars}
            readonly={true}
          />
          <a style={{ marginLeft: 3, cursor: "default" }}>({stars_amount})</a>
        </div>
      </div>
    );
  }

  renderReviews() {
    const { reviews } = this.state;
    if (!reviews) return null;
    return (
      <>
        <div
          style={{
            borderBottom: "1px solid #eee",
            padding: 10,
            fontSize: 18,
            paddingBottom: 0,
            width: "100%",
            marginBottom: 10,
          }}
        >
          <strong>Reviews</strong>
        </div>
        <div className="reviews-container">
          {reviews.map(this.renderReview)}
        </div>
        {this.renderLoader()}
      </>
    );
  }

  renderLoader = () => {
    if (this.state.loadingMore) {
      return (
        <div style={{ textAlign: "center" }}>
          <div className="send-buttons-loader" />
        </div>
      );
    }
    if (this.state.hasMore) {
      return (
        <div style={{ textAlign: "center" }} onClick={this.handleLoadMore}>
          <strong
            style={{ color: "var(--main-baby-blue-color)", cursor: "pointer" }}
          >
            Load More
          </strong>
        </div>
      );
    }

    return null;
  };

  renderReview = (review, idx) => {
    return (
      <div className="review-container" key={idx}>
        <div>
          <img src={review.reviewer_image} role="presentation"></img>
        </div>
        <div className="review-details">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <strong>{review.reviewer_name}</strong>
            </div>
            <Rating
              emptySymbol="fa fa-star-o fa-2x"
              fullSymbol="fa fa-star fa-2x"
              className="seg-stars"
              initialRating={review.stars}
              readonly={true}
            />
          </div>
          <div>{review.description}</div>
        </div>
      </div>
    );
  };
}

export default InfluencerRatingDialog;
