/* eslint-disable no-nested-ternary */
import { Box, Flex, Text } from "@chakra-ui/react";

type Type = "affiliation" | "monthly" | "one_time";
type Props = {
  type: Type;
  active: boolean;
  style?: any;
  onClick?: () => void;
  disabled?: boolean;
};

const CampaignType = (props: Props) => {
  const header =
    props.type === "affiliation"
      ? "Sales campaign"
      : props.type === "monthly"
      ? "Monthly campaign"
      : "One time campaign";
  const icon = (
    <i
      className={` fa-duotone fa-${
        props.type === "affiliation"
          ? "badge-percent"
          : props.type === "monthly"
          ? "calendar-alt"
          : "megaphone"
      }`}
      style={{
        fontSize: "54px",
        color: props.active ? "#249FF0" : "#808080",
      }}
    />
  );

  const description =
    props.type === "affiliation"
      ? "In this type of campaign influencers promote sales by generating links or using discount coupons."
      : props.type === "monthly"
      ? "This campaign can run for several months. You can set up tasks that renew monthly for influencers."
      : "This campaign is restricted to certain dates. You can not create renewing tasks.";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        background: props.active ? "#F2F8FC" : "#FAFAFA",
        padding: "20px",
        borderRadius: "8px",
        cursor: props.disabled ? "default" : "pointer",
        className: props.disabled ? "disabled" : "",
        border: props.active
          ? "2px solid rgba(36, 159, 240, 0.20)"
          : "2px solid transparent",
        ...props.style,
      }}
      onClick={props.disabled ? null : props.onClick}
    >
      <Flex justifyContent={"flex-start"} alignItems="center" gap={5}>
        <Box sx={{ margin: "20px", width: "64px" }}>{icon}</Box>
        <Text
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            textAlign: "left",
            marginRight: 10,
          }}
        >
          {header}
        </Text>
        <Text
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "300px",
            textAlign: "left",
            fontSize: "14px",
            color: "#95979D",
          }}
        >
          {description}
        </Text>
      </Flex>
      {/* checkbox icon */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50px",
          height: "50px",
          color: !props.active ? "gray" : "#249FF0",
          marginRight: 3,
        }}
      >
        <i
          className={` fa-duotone fa-${
            props.active ? "check-square" : "square"
          }`}
          style={{
            fontSize: "36px",
          }}
        />
      </Box>
    </Box>
  );
};

export default CampaignType;
