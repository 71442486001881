import { type ComponentMultiStyleConfig } from "@chakra-ui/react";

export const FormTheme: ComponentMultiStyleConfig = {
  parts: ["container"],
  variants: {
    oneline: {
      container: {
        display: "flex",
        flexDir: "row",
      },
    },
  },
};
