/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */

import {
  Avatar,
  Box,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useCallback, useContext, useState } from "react";
import { useStopAffiliation } from "src/api/actions/auctionActions";
import { numberWithCommas } from "src/components/feed_components/utilities/general";
import {
  CampaignPaymentsContext,
  CampaignPaymentsContextType,
} from "../CampaignPaymentsContext";

type Props = {
  onClose: any;
  influencerId: number;
  auctionId: number;
  influencerDetails?: any;
  influTotals?: any;
};

const StopAffiliationModal = (props: Props) => {
  const [mode, setMode] = useState(null);
  const context = useContext<CampaignPaymentsContextType | any>(
    CampaignPaymentsContext,
  );

  const stopAffiliation = useStopAffiliation();
  const stopImmediately = useCallback(async () => {
    await stopAffiliation.mutateAsync({
      auction_id: props.auctionId,
      influencer_id: props.influencerId,
      requestBody: {
        from_now: true,
        stop: false,
      },
    });
    props.onClose();
    context.getPaymentData();
  }, [stopAffiliation]);

  const stopNextMonth = useCallback(async () => {
    await stopAffiliation.mutateAsync({
      auction_id: props.auctionId,
      influencer_id: props.influencerId,
      requestBody: {
        from_now: false,
        stop: true,
      },
    });
    props.onClose();
    context.getPaymentData();
  }, []);

  return (
    <Modal variant={"clean"} size="2xl" isOpen={true} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={4} pb={0} mb={0}>
          {mode ? (
            <Box className="clickable" onClick={() => setMode(null)}>
              <i className="fal fa-arrow-left" /> Go back
            </Box>
          ) : null}
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody>
          <Container pt={6} mb={6}>
            {mode === "now" ? (
              <>
                <Text fontSize="xl" pb={3}>
                  Stop immediately
                </Text>
                <Text>
                  If you stop immediately, the influencer will be paid the
                  amount he has made so far in this month:
                  <Tag color={"brand.500"}>
                    {numberWithCommas(props?.influTotals.media_total)}
                  </Tag>
                  <Box>the payment will be made in 45 days.</Box>
                </Text>

                <ModalFooter
                  style={{
                    justifyContent: "space-between",
                    padding: 0,
                    paddingTop: 20,
                  }}
                >
                  <Button
                    type="button"
                    colorScheme={"gray"}
                    onClick={props.onClose}
                  >
                    Cancel
                  </Button>
                  <Box display={"inline-flex"} gap={2}>
                    <Button onClick={stopImmediately} colorScheme="red">
                      Stop immediately
                    </Button>
                  </Box>
                </ModalFooter>
              </>
            ) : mode === "next_month" ? (
              <>
                <Text fontSize="xl" pb={3}>
                  Stop from next month
                </Text>
                <Text>
                  This action will stop the monitoring of the influencer links
                  and personal coupons from the following month.
                </Text>

                <ModalFooter
                  style={{
                    justifyContent: "space-between",
                    padding: 0,
                    paddingTop: 20,
                  }}
                >
                  <Button
                    type="button"
                    colorScheme={"gray"}
                    onClick={props.onClose}
                  >
                    Cancel
                  </Button>
                  <Button onClick={stopNextMonth}>Stop from next month</Button>
                </ModalFooter>
              </>
            ) : (
              <>
                <Text fontSize="xl" pb={3}>
                  Stop working
                </Text>
                <Text>
                  This action will stop the monitoring of the influencer links
                  and personal coupons, and move the influencer to the "Trash"
                  section.
                </Text>
                <Box
                  background={"#F0F0F0"}
                  borderRadius={4}
                  p={2}
                  m={2}
                  maxWidth={350}
                  border={"1px solid #EAE8E8"}
                  display={"flex"}
                  gap={2}
                  alignItems="center"
                >
                  <Box>
                    <Avatar src={props?.influencerDetails?.image_url} />
                  </Box>
                  <Box>
                    <Box>{props?.influencerDetails?.name}</Box>
                    <Box>
                      <Tag color={"brand.500"}>
                        {numberWithCommas(props?.influTotals.media_total)}
                      </Tag>
                    </Box>
                  </Box>
                </Box>

                <ModalFooter
                  style={{
                    justifyContent: "space-between",
                    padding: 0,
                    paddingTop: 20,
                  }}
                >
                  <Button
                    type="button"
                    colorScheme={"gray"}
                    onClick={props.onClose}
                  >
                    Cancel
                  </Button>
                  <Box display={"inline-flex"} gap={2}>
                    <Button
                      rightIcon={<i className="fal fa-arrow-right" />}
                      onClick={() => setMode("now")}
                      colorScheme="red"
                    >
                      Stop immediately
                    </Button>
                    <Button
                      rightIcon={<i className="fal fa-arrow-right" />}
                      onClick={() => setMode("next_month")}
                    >
                      Stop from next month
                    </Button>
                  </Box>
                </ModalFooter>
              </>
            )}
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StopAffiliationModal;
