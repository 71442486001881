// TODO - lint
/* eslint-disable no-unused-vars, no-unused-vars, import/extensions, no-unused-vars, import/extensions, func-style, no-param-reassign, no-shadow, no-unused-vars, no-unused-vars, no-param-reassign, no-param-reassign, no-param-reassign, react/no-string-refs, react/no-string-refs, class-methods-use-this, no-plusplus, no-param-reassign, class-methods-use-this, camelcase, camelcase, no-param-reassign, no-unused-vars, no-param-reassign, no-param-reassign, no-param-reassign, no-unused-vars, import/no-default-export */
// General Imports
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import uuidv4 from "uuid/v4";
import {
  attachContentToInfInList,
  searchContents,
} from "../../../actions/premiumActions.js";
import SearchItem from "../search_components/SearchItem";
import { updateSearchFields } from "../utilities/searchUtils.js";
import { InfluContent } from "./InfluContent";

// Components Import

// Actions imports

// Const
function getContentsSearchFields() {
  return [
    {
      name: "social_network",
      label: "Social Network",
      type: "social",
      selected: "instagram",
      row: "content",
      placeholder: "",
      isOpen: true,
    },
    {
      name: "caption",
      label: "Caption",
      type: "text",
      value: "",
      row: "content",
      placeholder: "Text in Caption",
      isOpen: true,
      share: true,
    },
    {
      name: "location_name",
      label: "Location Name",
      type: "text",
      value: "",
      row: "content",
      placeholder: "Locations of the influencers posts",
      isOpen: true,
    },
    {
      name: "hashtags",
      label: "HashTags",
      type: "tags",
      tags: [],
      tag: "",
      value: "",
      row: "content",
      placeholder: "Press enter to add a new hashtag",
      isOpen: true,
      share: true,
    },
    {
      name: "mentions",
      label: "Mentions",
      type: "tags",
      tags: [],
      tag: "",
      value: "",
      row: "content",
      placeholder: "Press enter to add a new mention",
      isOpen: true,
      share: true,
    },
    { name: "space", type: "space" },
    {
      name: "influencers",
      label: "Specific Influencer",
      type: "select",
      value: "",
      row: "content",
      placeholder: "",
      isOpen: true,
    },
    {
      name: "min_likes",
      label: "Minimum Likes",
      small: true,
      type: "number",
      value: "",
      row: "content",
      placeholder: "",
      isOpen: true,
      share: true,
    },
    {
      name: "min_comments",
      label: "Minimum Comments",
      small: true,
      type: "number",
      value: "",
      row: "content",
      placeholder: "",
      isOpen: true,
      share: true,
    },
  ];
}

class SearchContentSection extends React.Component {
  constructor(props) {
    super(props);
    const searchFields = getContentsSearchFields();
    this.state = {
      searchFields,
      filteredTags: [],
      loading: false,
      results: [],
    };
  }

  handleTagsChange = (item, tags) => {
    item.tags = tags;
    this.updateSearchField(item.name, tags);
  };

  filterBySearch = (value, name) => {
    const field = this.state.searchFields.find((x) => x.name === name);
    field.value = value;
    this.setState({ loading: true });
    window.setTimeout(
      function () {
        const field = this.state.searchFields.find((x) => x.name === name);
        if (field.value === value) {
          this.search();
        }
      }.bind(this),
      1000,
    );
  };

  onChangeTags = (tags) => {
    const item = this.state.searchFields.find(
      (x) => x.name === "influencer_tags",
    );
    if (this.state.filteredTags) {
      this.search();
    } else {
      this.setState({ searchFields: this.state.searchFields });
    }
  };

  updateTreeField = (parent, child) => {
    // if (this.isSearchsOver()) {
    //     this.setState({ showTrialModal: true });
    //     return;
    // }

    if (child) {
      if (parent.selected.includes("all")) {
        parent.selected = parent.childs.filter((x) => x !== child);
      } else {
        parent.selected.addOrRemove(child);
      }
      if (parent.selected.length === parent.childs.length) {
        parent.selected = ["all"];
      }
    } else {
      parent.selected = parent.selected.includes("all") ? [] : ["all"];
    }

    this.search();
  };

  updateSearchField = (name, selected) => {
    // if (this.isSearchsOver()) {
    //     this.setState({ showTrialModal: true });
    //     return
    // }
    const searchFields = updateSearchFields(
      this.state.searchFields,
      name,
      selected,
    );
    if (name === "influencer_scope") {
      this.props.setLoadingData(false);
      return;
    }

    if (name === "clearall") {
      if (this.props.type === "discovery") {
        let geo = this.props.user.userDetails.company_details.country;
        geo = geo || localStorage.getItem("MOI_GEO");
        geo = geo || "All";
        const field = searchFields.find(
          (x) => x.name === "influencer_countries",
        );
        if (field && field.values && field.values.indexOf(geo) > 0) {
          field.selected = [field.values.indexOf(geo)];
        }
      }

      this.setState({ searchFields, socialDataFilter: ["all"] });

      if (this.refs.slider) this.refs.slider.onChange([0]);
      else this.search();
    } else {
      this.setState({ searchFields: this.state.searchFields });
      this.search();
    }
  };

  valueCityChange = () => {};

  createQuery() {
    const currentSearchFields = this.state.searchFields;
    const queryObject = {
      social_account: currentSearchFields.find((x) => x.type === "social")
        .selected,
    };
    const fieldsWithValue = currentSearchFields.filter(
      (x) =>
        (x.type !== "header" &&
          ((x.type === "text" && x.value) ||
            (x.type === "number" && x.value) ||
            (x.type === "select" && x.value) ||
            (x.type === "tags" && x.tags) ||
            (x.type === "tags-choice" && x.tags) ||
            (x.type === "multiple_field" &&
              (x.selected[0] > -1 || x.selected[1] > -1)) ||
            (!x.isMulti && x.selected > -1) ||
            (x.isMulti && x.type !== "slider" && x.selected.length > 0) ||
            (x.type === "slider" && x.selected[0] > 0))) ||
        (x.type === "tree" && x.values.find((y) => y.selected.length > 0)),
    );
    for (let i = 0; i < fieldsWithValue.length; i++) {
      const fieldWithValue = fieldsWithValue[i];
      switch (fieldWithValue.name) {
        case "caption":
          queryObject.caption = fieldWithValue.value;
          break;
        case "location_name":
          queryObject.location_name = fieldWithValue.value;
          break;
        case "hashtags":
          if (fieldWithValue.tags && fieldWithValue.tags.length > 0) {
            queryObject.hashtag = fieldWithValue.tags;
          }
          break;
        case "mentions":
          if (fieldWithValue.tags && fieldWithValue.tags.length > 0) {
            queryObject.mention = fieldWithValue.tags;
          }
          break;
        case "min_likes":
          queryObject.min_likes = fieldWithValue.value;
          break;
        case "min_comments":
          queryObject.min_comments = fieldWithValue.value;
          break;
        case "influencers":
          queryObject.specific_influencers = [fieldWithValue.value];
          break;
        default:
          console.log(`unknown field ${fieldWithValue.name}`);
          break;
      }
    }
    return queryObject;
  }

  search = () => {
    const data = this.createQuery();
    this.setState({ loading: true });
    searchContents(this.props.listId, data, (results) => {
      const newResults = [];
      if (results) {
        const tempResults = [];
        results.forEach((result) => {
          tempResults[result.influencer_id] = result.medias;
        });
        this.props.influencers.forEach((i) => {
          if (tempResults[i.id]) {
            const result = { ...i, posts: [] };
            tempResults[i.id].forEach((x) => {
              x.exists = !!i.posts.find((p) => p.media_id === x.media_id);
              result.posts.push(x);
            });
            newResults.push(result);
          }
        });
        // results.forEach(result => {
        //     let inf;
        //     if (inf = this.props.influencers.find(i => i.id == result.influencer_id)) {
        //         newResults.push({ ...inf, posts: result.medias });
        //     }
        // });
      }
      this.setState({ loading: false, results: newResults });
    });
  };

  renderLoader = () => {
    return (
      <div className="logo-loader" style={{ marginTop: 30 }}>
        <div className="loader" />
        <img src={"/images/humanz-logo.png"} />
      </div>
    );
  };

  attachToInfInList = (influencer_id, media) => {
    const influencer = this.props.influencers.find(
      (x) => x.id === influencer_id,
    );
    if (!influencer.posts) {
      influencer.posts = [];
    }
    let { posts } = influencer; // getting saved posts
    const id = uuidv4();
    let deleteIndex = null;
    let post = null;
    if (media.exists) {
      // Need to remove
      deleteIndex = posts.findIndex((x) => x.media_id === media.media_id);
      if (deleteIndex > -1) {
        posts[deleteIndex].is_deleted = true;
      }
    } else {
      // Need to add
      post = {
        id,
        media_id: media.media_id,
        post_type: media.post_type,
        is_deleted: false,
      };
      posts.push(post);
    }

    // Reordering the posts
    posts = posts.map((p, idx) => {
      return { ...p, order: idx, is_deleted: !!p.is_deleted };
    });
    media.saving = true;
    this.setState({});
    attachContentToInfInList(
      this.props.listId,
      influencer_id,
      posts,
      (success, failures) => {
        if (success) {
          if (deleteIndex != null && deleteIndex > -1) {
            influencer.posts.splice(deleteIndex, 1);
            media.exists = false;
          } else if (post) {
            media.exists = true;
          }
        } else if (deleteIndex != null && deleteIndex > -1) {
          posts[deleteIndex].is_deleted = false;
        }
        media.saving = false;
        this.setState({});
      },
    );
  };

  render() {
    const { searchFields, results } = this.state;
    return (
      <div style={{ marginBottom: 20 }}>
        <h3>Search Content</h3>
        <div className="content-search-items">
          {searchFields.map(this.renderSearchItem)}
        </div>

        <h3>{results && results.length > 0 ? "Results" : "No Results"}</h3>
        {this.state.loading
          ? this.renderLoader()
          : results.map(this.renderInfluencer)}
      </div>
    );
  }

  renderSearchSelectItem = (item, index) => {
    return (
      <div style={{ marginTop: 5 }}>
        <div className="small-title">
          <strong className="upper-case">{item.label}</strong>
        </div>
        <div key={index} className="searchable-input" style={{ marginTop: 10 }}>
          <Select
            placeholder={item.placeholder}
            valueKey="id"
            labelKey="name"
            value={item.value}
            simpleValue
            onChange={(value) => {
              this.updateSearchField(item.name, value);
            }}
            options={this.props.influencers}
            defaultOptions
          />
        </div>
      </div>
    );
  };

  renderSocialNetwork = (item, key) => {
    const canViewTiktok = this.props.user.userDetails
      ? this.props.user.userDetails.sidebar_data.can_view_tiktok
      : true;
    return (
      <div key={key}>
        <div>
          <strong className="small-title">SOCIAL NETWORK</strong>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
            width: 200,
          }}
        >
          <div className="filter-social">
            <div
              className={`filter-social-cube ${item.selected === "instagram"}`}
              onClick={() => this.updateSearchField(item.name, "instagram")}
            >
              <li className="fa fa-instagram" />
            </div>
          </div>
          {canViewTiktok && (
            <div className="filter-social">
              <div
                className={`filter-social-cube ${item.selected === "tiktok"}`}
                onClick={() => this.updateSearchField(item.name, "tiktok")}
              >
                {" "}
                <div className="tiktok-logo">
                  <div className="fa-brands fa-tiktok"></div>
                </div>
              </div>
            </div>
          )}

          <div className="filter-social">
            <div
              className={`filter-social-cube ${item.selected === "twitter"}`}
              onClick={() => this.updateSearchField(item.name, "twitter")}
            >
              <li className="fa fa-twitter" />
            </div>
          </div>
          <div className="filter-social">
            <div
              className={`filter-social-cube ${item.selected === "youtube"}`}
              onClick={() => this.updateSearchField(item.name, "youtube")}
            >
              <li className="fa fa-youtube-play" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSearchItem = (item, index) => {
    if (item.type === "select") return this.renderSearchSelectItem(item, index);
    if (item.type === "social") return this.renderSocialNetwork(item, index);
    if (item.type === "space") return <div />;
    return (
      <div>
        <SearchItem
          key={item.name + index}
          item={item}
          filteredTags={this.state.filteredTags}
          updateState={(callback) => this.setState({}, callback)}
          updateSearchField={this.updateSearchField}
          updateTreeField={this.updateTreeField}
          onChangeTags={this.onChangeTags}
          handleTagsChange={this.handleTagsChange}
          filterBySearch={this.filterBySearch}
          valueCityChange={this.valueCityChange}
          citiesOptions={[]}
          selectedSocials={
            this.state.searchFields?.find((x) => x.type === "social")?.selected
          }
        />
      </div>
    );
  };

  renderInfluencer = (data, index) => {
    return (
      <div key={index}>
        <div
          className={"search-influencer-row list-influencer-row "}
          style={{ height: "auto", display: "block", overflow: "initial" }}
        >
          <div
            className="flex-row"
            style={{ borderBottom: "1px solid #ddd", marginBottom: -10 }}
          >
            <img className="avatar" src={data.picture_url} />
            <strong style={{ fontSize: 17 }}>{data.name}</strong>
          </div>
          <div className="influ-content">
            <InfluContent
              searchMode={true}
              influencer={data}
              attachToInfInList={this.attachToInfInList}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
    lists: state.listsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchContentSection);
