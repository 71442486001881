// TODO - lint
/* eslint-disable import/order, react/no-deprecated, react/no-unescaped-entities, import/no-default-export */
// General Imports
import React from "react";
import Dialog from "rc-dialog";
import DatePicker from "react-datepicker";
import Toggle from "react-toggle";
import Clipboard from "react-clipboard.js";
import { connect } from "react-redux";
import { MarkDownEditor } from "../general_components/MarkDownEditor";

import "../../../css/share-dialog.css";
// Components Import
import CheckBox from "../general_components/CheckBox";

// Actions imports

// Functions import
import moment from "moment";

import {
  getSharedList,
  shareList,
  disableShareList,
} from "../../../actions/premiumActions";

class ShareListDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareList: false,
      shareNotes: true,
      shareSegmentations: true,
      shareTags: false,
      shareLink: null,
      generalComment: "",
      loading: false,
      shareEndTime: moment(new Date()).add(1, "M"),
      sharedDataSelected: [
        "engagement",
        "impressions",
        "followers",
        "influencers",
      ],
      showListSegmentation: false,
      shareInfluencerContact: false,
      listSegmentationSelected: ["gender", "age", "country"],
      sharedSocialNetworks: [],
    };
    this.checkboxes = [
      { label: "Followers", value: "followers" },
      { label: "Cost per engagement", value: "cpe" },
      { label: "Engagement", value: "engagement" },
      { label: "influencers", value: "influencers" },
      { label: "Impressions", value: "impressions" },
      { label: "Estimated Budget", value: "est_budget" },
      { label: "Instagram Views", value: "views" },
    ];

    this.listSegmentationOptions = [
      { label: "genders", value: "gender" },
      { label: "ages", value: "age" },
      { label: "countries", value: "country" },
      { label: "Frauds", value: "fraud" },
    ];
  }

  componentWillMount() {
    this.setState({ loading: true });
    const listId = this.props.list.list_summary_data.id;
    this.props.getSharedList(listId, (response) => {
      if (response) {
        const newState = { loading: false };
        // the second clause if for endtime components
        if (
          response.share_enabled ||
          (!response.share_enabled && response.link)
        ) {
          newState.shareList = response.share_enabled;
          newState.shareLink = response.link;
          newState.shareNotes = response.share_comments;
          newState.shareEndTime = moment(response.sharing_end_time);
          newState.sharedDataSelected = response.shared_data;
          newState.shareSegmentations = response.share_segmentation;
          newState.shareTags = response.share_tags;
          newState.generalComment = response.general_comment;
          newState.shareEngagments = response.share_engagments_rate;
          newState.showListSegmentation = response.show_segementation;
          newState.shareInfluencerContact = response.share_contact_details;
          newState.listSegmentationSelected =
            response.show_segementation_options
              ? response.show_segementation_options
              : [];
          newState.sharedSocialNetworks = response.select_social_networks
            ? response.select_social_networks
            : [];
        }
        this.setState(newState);
      }
    });
    // let shareData = this.props.list.list_summary_data.shared_data;
    // this.setState({ shareList: shareData.shared, shareNotes: shareData.share_notes ? true : false, shareLink: shareData.shared_link });
  }

  onChangeShare = () => {
    this.setState({ loading: true });
    const listId = this.props.list.list_summary_data.id;
    if (!this.state.shareList) {
      // on first time default parameters taken from share for first time sharing
      const dataToSend = {
        share_enabled: true,
        share_comments: this.state.shareNotes,
        sharing_end_time: this.state.shareEndTime,
        shared_data: this.state.sharedDataSelected,
        share_segmentation: this.state.shareSegmentations,
        share_tags: this.state.shareTags,
        general_comment: this.state.generalComment,
        show_segementation: this.state.showListSegmentation,
        show_segementation_options: this.state.listSegmentationSelected,
        share_contact_details: this.state.shareInfluencerContact,
        select_social_networks: this.state.sharedSocialNetworks,
      };
      this.props.shareList(listId, dataToSend, (data) => {
        if (data) {
          this.setState({
            loading: false,
            shareList: true,
            shareLink: data.shared_link,
            shareEndTime: moment(data.share_end_time),
          });
        }
      });
    } else {
      this.props.disableShareList(listId, () => {
        this.setState({ loading: false, shareList: false });
      });
    }
  };

  handleUpdate = () => {
    if (!this.state.shareList) {
      this.props.handleClose();
      return;
    }
    this.setState({ loading: true });
    const listId = this.props.list.list_summary_data.id;
    const dataToSend = {
      share_enabled: true,
      share_comments: this.state.shareNotes,
      sharing_end_time: this.state.shareEndTime,
      shared_data: this.state.sharedDataSelected,
      share_segmentation: this.state.shareSegmentations,
      share_tags: this.state.shareTags,
      general_comment: this.state.generalComment,
      show_segementation: this.state.showListSegmentation,
      show_segementation_options: this.state.listSegmentationSelected,
      share_contact_details: this.state.shareInfluencerContact,
      select_social_networks: this.state.sharedSocialNetworks,
      share_engagments_rate: this.state.shareEngagments,
    };
    this.props.shareList(listId, dataToSend, (data) => {
      if (data) {
        this.props.handleClose();
      }
    });
  };

  renderCheckboxItem = (item, items) => {
    const checked = items.indexOf(item.value) > -1;
    const style = { marginTop: 5 };
    // ui patch
    if (item.value === "unique_followers_dist") {
      style.marginRight = 33;
    }
    return (
      <CheckBox
        style={style}
        checked={checked}
        onChange={() => {
          items.addOrRemove(item.value);
          this.setState({ updated: true });
        }}
        label={item.label}
        key={item.label}
      />
    );
  };

  render() {
    const disabledByTime = this.state.shareEndTime < moment(new Date());
    const allowedShareContactDetails = this.props.user.userDetails
      ? this.props.user.userDetails.sidebar_data.allowed_share_contact_details
      : false;
    return (
      <Dialog
        onClose={this.props.handleClose}
        className="share-dialog "
        visible={true}
        style={{ marginTop: 25 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">Share List</div>
          <div
            style={{
              fontSize: 18,
              fontWeight: 300,
              marginTop: 20,
              borderBottom: "1px solid #dedede",
              paddingBottom: 10,
            }}
          >
            Share - {this.props.list.list_summary_data.title}
          </div>

          {this.state.loading ? (
            <div
              className="spinner small"
              style={{ marginTop: 40, marginBottom: 0 }}
            >
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          ) : (
            <div style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}>
              <div style={{ marginTop: 10 }}>
                <strong className="label-title" style={{ marginBottom: 15 }}>
                  share list
                </strong>
                <div className="toggle-input">
                  <label>
                    <div className="toggle-label">
                      <div className="fa fa-times" />
                      <div className="fa fa-check" />
                    </div>
                    <Toggle
                      checked={this.state.shareList}
                      icons={false}
                      onChange={this.onChangeShare}
                    />
                  </label>
                </div>
              </div>
              <div className={this.state.shareList ? "" : "disabled"}>
                {this.state.shareLink && (
                  <div>
                    <div className="input-row" style={{ marginTop: 20 }}>
                      <strong className="label-title">LINK</strong>
                      <div style={{ display: "flex" }}>
                        <input
                          value={this.state.shareLink}
                          className="input-line"
                          readOnly={true}
                        />
                        <Clipboard
                          style={{ background: "transparent", border: "none" }}
                          data-clipboard-text={this.state.shareLink}
                        >
                          <i className="far fa-copy copy-btn" />
                        </Clipboard>
                      </div>
                    </div>

                    <div>
                      <strong
                        className="label-title"
                        style={{ marginBottom: 15 }}
                      >
                        SHARING UNTIL
                      </strong>
                      <div style={{ display: "flex" }}>
                        <div>
                          <div
                            style={{
                              width: 200,
                              marginLeft: 5,
                              zIndex: 11,
                              position: "relative",
                            }}
                            className="searchable-input"
                          >
                            <DatePicker
                              error={true}
                              selected={this.state.shareEndTime}
                              isClearable={true}
                              placeholderText="Share end date"
                              dateFormat={window.time.date_with_hour}
                              onChange={(date) => {
                                this.setState({
                                  updated: true,
                                  shareEndTime: date,
                                  shareList: true,
                                });
                              }}
                              className={`input-line ${
                                disabledByTime ? "disabled-by-time" : ""
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <strong
                        className="label-title"
                        style={{ marginBottom: 15, marginTop: 15 }}
                      >
                        Share comments
                      </strong>
                      <div className="toggle-input">
                        <label>
                          <div className="toggle-label">
                            <div className="fa fa-times" />
                            <div className="fa fa-check" />
                          </div>
                          <Toggle
                            defaultChecked={this.state.shareNotes}
                            icons={false}
                            onChange={() => {
                              this.setState({
                                shareNotes: !this.state.shareNotes,
                              });
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    {this.state.shareNotes && (
                      <div style={{ width: "100%", marginTop: 20 }}>
                        <strong className="label-title">General comment</strong>
                        <div className="blurable-large">
                          <MarkDownEditor
                            value={this.state.generalComment}
                            setValue={(text) => {
                              this.setState({ generalComment: text });
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div style={{ marginTop: 20 }}>
                      <strong
                        className="label-title"
                        style={{ marginBottom: 15 }}
                      >
                        share tags
                      </strong>
                      <div className="toggle-input">
                        <label>
                          <div className="toggle-label">
                            <div className="fa fa-times" />
                            <div className="fa fa-check" />
                          </div>
                          <Toggle
                            defaultChecked={this.state.shareTags}
                            icons={false}
                            onChange={() => {
                              this.setState({
                                shareTags: !this.state.shareTags,
                              });
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <strong
                        className="label-title"
                        style={{ marginBottom: 15 }}
                      >
                        share influencer followers' segmentations
                      </strong>
                      <div className="toggle-input">
                        <label>
                          <div className="toggle-label">
                            <div className="fa fa-times" />
                            <div className="fa fa-check" />
                          </div>
                          <Toggle
                            defaultChecked={this.state.shareSegmentations}
                            icons={false}
                            onChange={() => {
                              this.setState({
                                shareSegmentations:
                                  !this.state.shareSegmentations,
                              });
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    {allowedShareContactDetails && (
                      <div style={{ marginTop: 20 }}>
                        <strong
                          className="label-title"
                          style={{ marginBottom: 15 }}
                        >
                          Share influencer contact details
                        </strong>
                        <div className="toggle-input">
                          <label>
                            <div className="toggle-label">
                              <div className="fa fa-times" />
                              <div className="fa fa-check" />
                            </div>
                            <Toggle
                              defaultChecked={this.state.shareInfluencerContact}
                              icons={false}
                              onChange={() => {
                                this.setState({
                                  shareInfluencerContact:
                                    !this.state.shareInfluencerContact,
                                });
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    )}
                    <div style={{ marginTop: 20 }}>
                      <strong
                        className="label-title"
                        style={{ marginBottom: 15 }}
                      >
                        Share influencers' engagement rate
                      </strong>
                      <div className="toggle-input">
                        <label>
                          <div className="toggle-label">
                            <div className="fa fa-times" />
                            <div className="fa fa-check" />
                          </div>
                          <Toggle
                            defaultChecked={this.state.shareEngagments ?? true}
                            icons={false}
                            onChange={() => {
                              this.setState({
                                shareEngagments: !this.state.shareEngagments,
                              });
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <strong
                        className="label-title"
                        style={{ marginBottom: 15 }}
                      >
                        SHARE SPECIFIC SOCIALS
                      </strong>
                      {this.renderSocialNetwork()}
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <strong
                        className="label-title"
                        style={{ marginBottom: 15 }}
                      >
                        KPIS TO SHARE
                      </strong>
                      <div className="checkbox-container">
                        {this.checkboxes.map((item) =>
                          this.renderCheckboxItem(
                            item,
                            this.state.sharedDataSelected,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              paddingBottom: 5,
              paddingTop: 10,
              marginTop: 10,
              textAlign: "center",
              borderTop: "1px solid #dedede",
            }}
          >
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn share-save-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={this.handleUpdate}
            >
              <span>Save</span>
            </button>
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={() => this.props.handleClose()}
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  chooseSocialNetwork = (social) => {
    this.state.sharedSocialNetworks.addOrRemove(social);
    this.setState({});
  };

  renderSocialNetwork = () => {
    const canViewTikTok = this.props.user.userDetails
      ? this.props.user.userDetails.sidebar_data.can_view_tiktok
      : true;
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
            width: 200,
          }}
        >
          <div className="filter-social">
            <div
              className={`filter-social-cube small ${this.state.sharedSocialNetworks.includes(
                "instagram",
              )}`}
              onClick={() => this.chooseSocialNetwork("instagram")}
            >
              <li className="fa fa-instagram" />
            </div>
          </div>
          <div className="filter-social">
            <div
              className={`filter-social-cube small ${this.state.sharedSocialNetworks.includes(
                "twitter",
              )}`}
              onClick={() => this.chooseSocialNetwork("twitter")}
            >
              <li className="fa fa-twitter" />
            </div>
          </div>
          <div className="filter-social">
            <div
              className={`filter-social-cube small ${this.state.sharedSocialNetworks.includes(
                "youtube",
              )}`}
              onClick={() => this.chooseSocialNetwork("youtube")}
            >
              <li className="fa fa-youtube-play" />
            </div>
          </div>
          {canViewTikTok ? (
            <div className="filter-social">
              <div
                className={`filter-social-cube small ${this.state.sharedSocialNetworks.includes(
                  "tiktok",
                )}`}
                onClick={() => this.chooseSocialNetwork("tiktok")}
              >
                {" "}
                <div className="tiktok-logo">
                  <div className="fa-brands fa-tiktok"></div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ width: 40 }} />
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSharedList: (id, callback) => {
      dispatch(getSharedList(id, callback));
    },
    shareList: (id, data, callback) => {
      dispatch(shareList(id, data, callback));
    },
    disableShareList: (id, callback) => {
      dispatch(disableShareList(id, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShareListDialog);
