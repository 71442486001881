interface Props {
  width: number | string;
  height: number | string;
  url: string;
  className?: string;
}

export const InvoicePreview = ({ className, width, height, url }: Props) => {
  if (!url) {
    return null;
  }

  return (
    <embed
      className={className || ""}
      width={width}
      height={height}
      src={url}
    />
  );
};
