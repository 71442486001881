import { getShareKeys } from "src/actions/shareActions";
import {
  getSocialLoginAgentInvite_influencerAgent_uuid,
  getSocialLoginExternal_registerLink_apiInvite,
  postSocialLoginAgentInvite_influencerAgent_uuid,
  postSocialLoginExternal_registerLink_api,
} from "src/api/services";
import {
  PostSocialLoginAgentInvite_influencerAgent_uuidQueryParams,
  RequestBodySocialRegisteredLinkData,
} from "src/api/types";

type PosSocialNetworkApiFromLink = Parameters<
  typeof postSocialLoginExternal_registerLink_api
>;

export const postSocialNetworkApiFromLink = (
  requestBody: PosSocialNetworkApiFromLink[0],
  queryParams: PosSocialNetworkApiFromLink[1],
) =>
  postSocialLoginExternal_registerLink_api(
    requestBody,
    queryParams,
    getShareKeys(),
  );

export const getSocialNetworkInvitationLinkData = () =>
  getSocialLoginExternal_registerLink_apiInvite(getShareKeys());

export const getSocialNetworkApiAgentLink = () =>
  getSocialLoginAgentInvite_influencerAgent_uuid(getShareKeys()["auth-key"]);

export const postSocialNetworkApiAgentLink = (
  requestBody: RequestBodySocialRegisteredLinkData,
  queryParams: PostSocialLoginAgentInvite_influencerAgent_uuidQueryParams,
) =>
  postSocialLoginAgentInvite_influencerAgent_uuid(
    getShareKeys()["auth-key"],
    requestBody,
    queryParams,
  );
