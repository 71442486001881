// TODO - lint
/* eslint-disable react/no-deprecated, no-param-reassign, global-require, no-param-reassign, no-param-reassign, consistent-return, import/no-default-export */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Dialog from "rc-dialog";
import { connect } from "react-redux";
import moment from "moment";
import CreditCardForm from "./CreditCardForm";
import { getPaymentsMethods } from "../../../actions/paymentActions";
import { getUserBillingAddress } from "../../../actions/userActions";

class PaymentsDialog extends Component {
  state = { payment_mode: 1, cards: [], loadingPayments: true };

  PRICE_PER_MONTH = {
    USD: "1,500",
    NIS: "5,500",
    GBP: "1,150",
    EUR: "1,350",
    ZAR: "19,950",
  };

  creditCardFormRef = React.createRef();

  componentWillMount() {
    if (!this.props.payPerAmbassador) {
      this.setState({ payment_mode: 2, loadingPayments: false });
    } else {
      this.props.getUserBillingAddress((data) => {
        if (data) {
          const details = this.props.user.userDetails;
          if (!data.country) {
            data.country = details.company_details.country;
          }
          if (!data.phone) {
            const codeCountries = require("../utilities/countries-codes.json");
            let code = data.country
              ? codeCountries.find((x) => x.code === data.country)
              : null;
            code = code ? code.dialCode : "972";
            data.phone = `+${code}`;
          }
          this.setState({ cardAddress: data });
          this.props.getPaymentsMethods((cards) => {
            if (!cards || cards.length === 0) {
              this.setState({ payment_mode: 1, loadingPayments: false });
            } else this.setPaymentStatus(cards);
          });
        }
      });
    }
  }

  setPaymentStatus(cards) {
    if (!cards) {
      this.setState({ payment_mode: 1, loadingPayments: false });
      return;
    }
    cards = cards.cards;
    if (cards && cards.length === 0) {
      this.setState({ payment_mode: 1, loadingPayments: false });
    } else {
      this.setState({ cards, payment_mode: 2, loadingPayments: false });
    }
  }

  handleAddedCreditCard = (response) => {
    const { cards } = response;
    if (cards && cards.length === 0) {
      this.setState({ payment_mode: 0 });
    } else if (this.state.selectedPackage === "premium") {
      this.props.upgradeToPremium(cards[0].id, () => {
        this.setState({ cards, payment_mode: 2 });
      });
    } else {
      this.setState({ cards, payment_mode: 2 });
    }
  };

  goBack = () => {
    this.props.handleClose();
  };

  tokenizeCreditCard = () => {
    this.creditCardFormRef.current.tokenizeCreditCard();
  };

  render() {
    return (
      <Dialog
        className="payments-dialog"
        visible={true}
        style={{}}
        animation="slide-fade"
        onClose={this.props.handleClose}
        maskAnimation="fade"
      >
        <div style={{ marginTop: 30 }}>
          {this.state.loadingPayments ? (
            <div className="spinner" style={{ marginTop: 100 }}>
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          ) : (
            <>
              {this.state.payment_mode === 1 && (
                <CreditCardForm
                  showButtons={false}
                  state={this.props.state}
                  onSuccess={this.handleAddedCreditCard}
                  goBack={() => this.setState({ payment_mode: 0 })}
                  ref={this.creditCardFormRef}
                />
              )}

              {this.state.payment_mode === 2 && (
                <>
                  {this.renderPaymentsSummary()}
                  {this.state.cards.map(this.renderPaymentsDetails)}
                </>
              )}
            </>
          )}
        </div>
        <div className="create-buttons-row">
          <button className="btn btn-primry" onClick={this.goBack}>
            Close
          </button>
          {this.state.payment_mode === 1 && (
            <button
              className={"btn btn-primry "}
              onClick={this.tokenizeCreditCard}
            >
              Next
            </button>
          )}
          {this.state.payment_mode === 2 && (
            <button
              className={`btn btn-primry ${this.props.saving && "disabled"}`}
              disabled={this.props.saving || this.state.loadingPayments}
              onClick={() => {
                this.setState({ loadingPayments: true }, this.props.onBuy);
              }}
            >
              Buy
            </button>
          )}
        </div>
      </Dialog>
    );
  }

  renderPaymentsSummary() {
    if (this.props.renderPaymentsSummary) {
      return (
        <div className="payment-details-container" style={{ marginBottom: 40 }}>
          <div
            style={{
              marginTop: 10,
              background: "var(--main-baby-blue-color)",
              borderRadius: 6,
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 15px",
              fontSize: 25,
            }}
          >
            <div>Order Summary</div>
          </div>
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            {this.props.renderPaymentsSummary()}
          </div>
        </div>
      );
    }
  }

  renderPaymentsDetails = (card) => {
    const cardDate = moment(card.expiry_date).utc();
    const { cardAddress } = this.state;
    return (
      <div key={card.id} style={{ marginTop: 20 }}>
        <div className="payment-details-container">
          <div
            style={{
              marginTop: 10,
              background: "var(--main-baby-blue-color)",
              borderRadius: 6,
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 15px",
              fontSize: 25,
            }}
          >
            <div>Credit Card Details</div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 20,
              marginTop: 40,
            }}
          >
            <div style={{ fontSize: 13, width: "30%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                card holder name
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {card.name_on_card}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "23%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                card number
              </strong>
              <div
                style={{ color: "var(--main-baby-blue-color)", paddingLeft: 2 }}
              >
                XXXX-{card.last_digits}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "23%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                expire date
              </strong>
              <div
                style={{ color: "var(--main-baby-blue-color)", paddingLeft: 2 }}
              >
                {`${cardDate.month() + 1}/${cardDate
                  .year()
                  .toString()
                  .substring(2, 4)}`}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "25%" }}></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 20,
              marginTop: 40,
              marginBottom: 25,
            }}
          >
            <div style={{ fontSize: 13, width: "30%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                address line
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cardAddress.address}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "23%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                city
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cardAddress.city}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "23%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                Country
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cardAddress.country}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "25%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                zip code
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cardAddress.zip}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 5, fontSize: 13, marginLeft: 5 }}>
          In order to change your credit card go to{" "}
          <Link to={"/edit-details/payment-method"}>settings</Link>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentsMethods: (callback) => {
      dispatch(getPaymentsMethods(callback));
    },
    getUserBillingAddress: (callback) => {
      dispatch(getUserBillingAddress(callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsDialog);

PaymentsDialog.defaultProps = {};
