// TODO - lint
/* eslint-disable import/no-default-export, react/no-deprecated, class-methods-use-this, class-methods-use-this */
import MobileDetect from "mobile-detect";
import React from "react";

const md = new MobileDetect(window.navigator.userAgent);

export default class MoveToMainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const os = md.os();
    if (os === null) {
      window.location = "https://www.humanz.ai";
    }
    if (os === "AndroidOS") {
      window.location =
        "intent://humanz.ai/#Intent;scheme=http;package=com.android.chrome;end";
    }
    if (os === "iOS") {
      window.location =
        "intent://humanz.ai/#Intent;scheme=http;package=com.apple.mobilesafari;end";
    }
  }

  render() {
    const os = md.os();
    return (
      <section className="welcome" id="welcome-container">
        <h1>OS: {os}</h1>
      </section>
    );
  }
}
