import { Button } from "@chakra-ui/react";

const NewTemplateButton = (props: any) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        display: "flex",
        padding: "6px 12px 6px 12px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "20px",
        background: "var(--white, #FFF)",
        boxShadow:
          "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
        color: "#249FF0",
        marginBottom: "5px",
        marginTop: "15px",
        width: "50%",
        transition: "background-color 0.3s",
        "&:hover": {
          background: "#E7F3FB",
          color: "#249FF0",
          textDecoration: "none",
        },
        fontWeight: 400,
      }}
    >
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
      >
        <circle cx="5.5" cy="5" r="4.5" fill="white" stroke="#249FF0" />
        <path
          d="M7.5 5.00002H5.5M5.5 5.00002H3.5M5.5 5.00002L5.5 3M5.5 5.00002L5.5 7"
          stroke="#249FF0"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg> */}
      <i className="fa-sharp fa-solid fa-plus fa-xs"></i>
      <div style={{ marginLeft: "5px", marginBottom: "3px" }}>new template</div>
    </Button>
  );
};

export default NewTemplateButton;
