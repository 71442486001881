const DateIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <path
          d="M2.875 0.37037C2.875 0.16582 2.70711 0 2.5 0C2.29289 0 2.125 0.16582 2.125 0.37037V1.15025C1.40533 1.20717 0.932886 1.34685 0.585786 1.68967C0.238687 2.03248 0.0972566 2.4991 0.0396286 3.20988H9.96037C9.90274 2.4991 9.76131 2.03248 9.41421 1.68967C9.06711 1.34685 8.59466 1.20717 7.875 1.15025V0.37037C7.875 0.16582 7.70711 0 7.5 0C7.29289 0 7.125 0.16582 7.125 0.37037V1.11748C6.79237 1.11111 6.41952 1.11111 6 1.11111H4C3.58048 1.11111 3.20763 1.11111 2.875 1.11748V0.37037Z"
          fill="#CFCFCF"
        />
        <path
          fillRule="evenodd"
          strokeWidth="evenodd"
          d="M0 5.06173C0 4.64739 0 4.27914 0.00645101 3.95062H9.99355C10 4.27914 10 4.64739 10 5.06173V6.04938C10 7.91172 10 8.84289 9.41421 9.42144C8.82843 10 7.88562 10 6 10H4C2.11438 10 1.17157 10 0.585786 9.42144C0 8.84289 0 7.91172 0 6.04938V5.06173ZM7.5 6.04938C7.77614 6.04938 8 5.82829 8 5.55556C8 5.28282 7.77614 5.06173 7.5 5.06173C7.22386 5.06173 7 5.28282 7 5.55556C7 5.82829 7.22386 6.04938 7.5 6.04938ZM7.5 8.02469C7.77614 8.02469 8 7.8036 8 7.53086C8 7.25813 7.77614 7.03704 7.5 7.03704C7.22386 7.03704 7 7.25813 7 7.53086C7 7.8036 7.22386 8.02469 7.5 8.02469ZM5.5 5.55556C5.5 5.82829 5.27614 6.04938 5 6.04938C4.72386 6.04938 4.5 5.82829 4.5 5.55556C4.5 5.28282 4.72386 5.06173 5 5.06173C5.27614 5.06173 5.5 5.28282 5.5 5.55556ZM5.5 7.53086C5.5 7.8036 5.27614 8.02469 5 8.02469C4.72386 8.02469 4.5 7.8036 4.5 7.53086C4.5 7.25813 4.72386 7.03704 5 7.03704C5.27614 7.03704 5.5 7.25813 5.5 7.53086ZM2.5 6.04938C2.77614 6.04938 3 5.82829 3 5.55556C3 5.28282 2.77614 5.06173 2.5 5.06173C2.22386 5.06173 2 5.28282 2 5.55556C2 5.82829 2.22386 6.04938 2.5 6.04938ZM2.5 8.02469C2.77614 8.02469 3 7.8036 3 7.53086C3 7.25813 2.77614 7.03704 2.5 7.03704C2.22386 7.03704 2 7.25813 2 7.53086C2 7.8036 2.22386 8.02469 2.5 8.02469Z"
          fill="#CFCFCF"
        />
      </svg>
    </div>
  );
};

export default DateIcon;
