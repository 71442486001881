// TODO - lint
/* eslint-disable import/named, no-unused-vars, no-unused-vars, func-style, no-unused-vars, no-unused-vars, consistent-return, import/no-default-export, radix, no-underscore-dangle, radix, no-underscore-dangle, radix, no-underscore-dangle, radix, no-underscore-dangle, no-shadow, no-shadow, no-param-reassign, radix, no-param-reassign, radix, no-param-reassign, radix, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, radix, no-param-reassign, no-param-reassign, no-param-reassign, no-unreachable, react/no-unescaped-entities, react/no-unescaped-entities, no-return-assign, no-underscore-dangle, no-return-assign, no-underscore-dangle, no-return-assign, no-underscore-dangle, no-return-assign, no-underscore-dangle, consistent-return, consistent-return, consistent-return, class-methods-use-this, no-unused-vars, react/jsx-no-target-blank, class-methods-use-this, no-unused-vars, react/jsx-no-target-blank, class-methods-use-this, consistent-return, react/jsx-no-target-blank */
import { Button } from "@chakra-ui/react";
import HtmlParser from "html-react-parser";
import MarkdownIt from "markdown-it";
import insert from "markdown-it-ins";
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tooltip from "rc-tooltip";
import React, { useContext } from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import Toggle from "react-toggle";
import { TwitterTweetEmbed } from "react-twitter-embed";
import YouTube from "react-youtube";
import { InfiniteLoader } from "src/components/feed_components/general_components/InfiniteLoader";
import presentationBtn from "../../../../../images/add_presentation.png";
import { InfoToolTip } from "../../../general_components/InfoToolTip";
import NoDataMessage from "../../../general_components/NoDataMessage";
import { formatBigNumbers, numberWithCommas } from "../../../utilities/general";
import { ClicksOverTimeChart } from "./ClicksOverTimeChart";
import PresentationPopup from "./PresentationPopup";
import { RoiContext } from "./RoiContext";
import {
  RenderDistributionChart,
  conversionsCharts,
  roiCharts,
  youtubeCharts,
} from "./RoiUtils";

const countUpFormat = (number) => formatBigNumbers(number, { format: "0.00a" });

function CommentsSection({ open, title, text, onToggle }) {
  return React.useMemo(() => {
    const mdParser = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      highlight(str, lang) {},
    }).use(insert);

    return (
      <div
        className={`markdown-comments roi-comments-sectoin ${open && "open"}`}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <i className="fas fa-comment-alt"></i>
            <strong className="label-title">{title}</strong>
          </div>
          <i
            className="fas fa-angle-down"
            onClick={() => {
              onToggle();
            }}
          ></i>
        </div>
        <div
          className="roi-comments-content"
          style={{ marginTop: 5, fontSize: 15 }}
        >
          {HtmlParser(mdParser.render(text), {
            replace: (domNode) => {
              if (domNode?.tagName === "script") {
                return <></>;
              }
            },
          })}
        </div>
      </div>
    );
  }, [text]);
}

class RoiSummaryInner extends React.Component {
  _impressionsInput = null;

  _engagementsInput = null;

  _conversionsInput = null;

  _budgetInput = null;

  state = {
    editKpiOpen: false,
    tabs: [],
    byBudgetToggle: false,
    openComments: ["kpi", "tasks", "funnel", "segmentation"],
    selectedSocial: "",
    showPopup: false,
  };

  hideImpressions = this.props.shareHideImpressions;

  handleClosePopup = () => {
    this.setState({ showPopup: false });
  };

  handleButtonClick = () => {
    this.setState({ showPopup: true });
  };

  componentDidMount() {
    this.setState({
      selectedSocial: this.props.selectedSocial,
      byBudgetToggle: this.props.byBudgetToggle,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSocial !== prevProps.selectedSocial) {
      this.setState({ selectedSocial: this.props.selectedSocial });
    }
    if (this.props.byBudgetToggle !== prevProps.byBudgetToggle) {
      this.setState({ byBudgetToggle: this.props.byBudgetToggle });
    }
    if (this.hideImpressions !== this.props.shareHideImpressions) {
      this.hideImpressions = this.props.shareHideImpressions;
      this.setState({});
    }
  }

  openAddToList(data) {
    const influencer = {
      id: data.influencer_id,
      picture_url: data.image,
      name: data.instagram_username,
    };
    this.props.openAddToList([influencer]);
  }

  editKpis(summaryData) {
    const kpis = {
      impressions: parseInt(this._impressionsInput.value) || 0,
      engagement: parseInt(this._engagementsInput.value) || 0,
      conversions: parseInt(this._conversionsInput.value) || 0,
      budget: parseInt(this._budgetInput.value) || 0,
    };

    // const newState = { ...this.state };
    this.props.fields.kpis = kpis;
    // this.setState(newState);

    document.getElementById("edit_kpi_btn").classList.add("disabled");

    const setKpisData = (summaryData, kpis) => {
      summaryData.kpis.conversions.target = parseInt(kpis.conversions);
      summaryData.kpis.engagements.target = parseInt(kpis.engagement);
      summaryData.kpis.impressions.target = parseInt(kpis.impressions);
      summaryData.kpis.impressions.precent =
        (summaryData.kpis.impressions.current /
          summaryData.kpis.impressions.target) *
          100 -
        100;
      summaryData.kpis.engagements.precent =
        (summaryData.kpis.engagements.current /
          summaryData.kpis.engagements.target) *
          100 -
        100;
      summaryData.kpis.conversions.precent = summaryData.kpis.conversions
        ? (summaryData.kpis.conversions.current /
            summaryData.kpis.conversions.target) *
            100 -
          100
        : null;
      summaryData.campaign_details.budget = parseInt(kpis.budget);
      summaryData.kpis.conversions.cost_by_budget = (
        summaryData.campaign_details.budget /
        summaryData.kpis.conversions.current
      ).toFixed(2);
      summaryData.kpis.engagements.cost_by_budget = (
        summaryData.campaign_details.budget /
        summaryData.kpis.engagements.current
      ).toFixed(2);
      summaryData.kpis.impressions.cost_by_budget = (
        summaryData.campaign_details.budget /
        (summaryData.kpis.impressions.current / 1000)
      ).toFixed(2);
      document.getElementById("edit_kpi_btn").classList.remove("disabled");
      this.setState({ editKpiOpen: false });
    };

    if (this.props.isAmbassador) {
      this.props.editAmbassadorReport(
        this.props.id,
        {
          report: this.props.fields,
          updated_data: { stopped: [], restored: [], replaced: [] },
        },
        () => {
          setKpisData(summaryData, kpis);
        },
      );
    } else {
      this.props.editKpis(this.props.id, kpis, () => {
        setKpisData(summaryData, kpis);
      });
    }
  }

  render() {
    const { summaryData, user } = this.props;
    const { selectedSocial } = this.state;
    const summarySocial =
      summaryData && summaryData.socialsData
        ? summaryData.socialsData[selectedSocial]
        : null;

    return (
      <>
        <div className="main-middle-container">
          {
            this.props.loaded && summaryData ? (
              <div className="roi-main-section">
                {
                  <this.props.Header
                    extraButton={
                      !this.props.fromMultiReport &&
                      (summaryData?.socialsData?.instagram ||
                        summaryData.socialsData?.tiktok) ? (
                        <div aria-label="Presentations">
                          <Button
                            className="presentation-button"
                            onClick={this.handleButtonClick}
                          >
                            <img
                              src={presentationBtn}
                              alt=""
                              width="16"
                              height="19"
                            />
                          </Button>
                        </div>
                      ) : null
                    }
                  />
                }

                {/* KPI's */}
                {this.renderKpis()}
                {/* End KPI's */}

                {/* Auctions Distraction */}
                {this.renderAuctionsDistraction(summaryData)}
                {/* End Auctions Distraction */}

                {/* Tasks */}
                {this.renderTasks(summaryData)}
                {/* End Tasks */}

                {!summaryData?.campaign_details?.pixel_enabled ? (
                  <ClicksOverTimeChart
                    clicksPerDay={summaryData.clicksPerDay}
                  />
                ) : null}

                {/* Funnel */}
                <div style={{ marginTop: 40 }}>
                  <Tabs
                    defaultActiveKey="1"
                    renderTabBar={() => <InkTabBar />}
                    renderTabContent={() => <TabContent />}
                    onChange={(key) => this.setState({ selectedSocial: key })}
                    activeKey={selectedSocial}
                  >
                    {this.props.tabs.map((t) => (
                      <TabPane
                        tab={<span className="uppercase">{t}</span>}
                        className="funnel-tab"
                        disabled={!summaryData.socialsData[t]}
                        key={t}
                      >
                        {selectedSocial === t &&
                          this.renderSummaryData(summarySocial)}
                      </TabPane>
                    ))}
                  </Tabs>
                </div>
                {/* Funnel Comments */}
                {this.props.shareComments.funnel && (
                  <CommentsSection
                    open={this.state.openComments.includes("funnel")}
                    title={"funnel comments"}
                    text={this.props.shareComments.funnel}
                    onToggle={() => {
                      this.state.openComments.addOrRemove("funnel");
                      this.setState({});
                    }}
                  />
                )}
                {/* End Funnel */}

                {/* Segmentations */}
                <div style={{ marginTop: 20 }}>
                  {this.renderSegmentationsTab(summaryData, summarySocial)}
                </div>
                {/* Segmentations Comments  */}
                {this.props.shareComments &&
                  this.props.shareComments.segmentation && (
                    <CommentsSection
                      open={this.state.openComments.includes("segmentation")}
                      title={"segmentations comments"}
                      text={this.props.shareComments.segmentation}
                      onToggle={() => {
                        this.state.openComments.addOrRemove("segmentation");
                        this.setState({});
                      }}
                    />
                  )}
                {/* End Segmentations */}

                {/* Second row - top posts */}
                {this.renderTopPosts()}

                {/* Second row - top stories */}

                {/* {this.renderTopStories} */}

                {/* Fourth row - Recommendations */}
                {this.renderRecommendations(summaryData)}

                {this.state.showPopup && (
                  <PresentationPopup
                    onClose={this.handleClosePopup}
                    auctionId={
                      this.props.summaryData?.campaign_details?.auction_id
                    }
                    generateSlidesData={this.props.generateSlidesData}
                  />
                )}
              </div>
            ) : (
              /* Loader */
              <div className="logo-loader">
                <div className="loader" />
                <img src={"/images/humanz-logo.png"} />
              </div>
            )
            /* End Loader */
          }
        </div>
      </>
    );
  }

  renderSegmentationsTab = (summaryData, summarySocial) => {
    // if (semiMode) {
    //   return null;
    // }
    const tabs = [];
    // const impressions = this.renderImpressions(summaryData, summarySocial);
    // if (!this.hideImpressions && impressions) {
    //   tabs.push(impressions);
    // }
    // const engagements = this.renderEngagements(summaryData, summarySocial);
    // if (engagements) {
    //   tabs.push(engagements);
    // }
    const conversions = this.renderConversionsTab(summaryData, summarySocial);
    if (conversions) {
      tabs.push(conversions);
    }
    if (tabs.length) {
      return (
        <Tabs
          renderTabBar={() => <InkTabBar />}
          renderTabContent={() => <TabContent />}
        >
          {conversions}
        </Tabs>
      );
    }
    return null;
  };

  renderImpressions(summaryData, summarySocial) {
    if (
      summaryData &&
      summarySocial &&
      this.state.selectedSocial !== "tiktok"
    ) {
      return (
        <TabPane tab="Impressions" key="1">
          <div className="roi-charts-container">
            {summarySocial.youtube_original_age_insights
              ? youtubeCharts(summarySocial.youtube_original_age_insights)
              : roiCharts(summarySocial.impressions, false)}
          </div>
        </TabPane>
      );
    }

    return null;
  }

  renderAuctionsDistraction = (summaryData) => {
    if (!this.props.fromMultiReport || !summaryData?.auctions_distribution) {
      return null;
    }
    const auctionsData = summaryData.auctions_data;
    return (
      <div style={{ marginTop: 30 }}>
        <strong className="small-title">Contribution From Each Campaign</strong>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
          {!this.hideImpressions && (
            <RenderDistributionChart
              data={summaryData?.auctions_distribution?.impressions}
              color={"#23a086"}
              label={"IMPRESSIONS"}
              auctionsData={auctionsData}
            />
          )}
          <RenderDistributionChart
            data={summaryData?.auctions_distribution?.engagements}
            color={"rgb(52, 118, 189)"}
            label={"ENGAGEMENTS"}
            auctionsData={auctionsData}
          />
          <RenderDistributionChart
            data={summaryData?.auctions_distribution?.conversions}
            color={"rgb(226, 140, 55)"}
            label={"CLICKS"}
            auctionsData={auctionsData}
          />
        </div>
      </div>
    );
  };

  renderTasks = (summaryData) => {
    return (
      <>
        <div style={{ marginTop: 30 }}>
          <strong className="small-title">Tasks</strong>
          <div style={{ display: "flex", width: "100%" }}>
            {!!summaryData.tasks.ig_post && (
              <div className="kpi-cubes task-cube">
                <div>
                  <strong style={{ color: "#333333" }}>
                    Instagram Posts / Reels
                  </strong>
                </div>
                <div className="task-kube-inner">
                  <div style={{ color: "#333333" }}>
                    <CountUp
                      end={summaryData.tasks.ig_post || 0}
                      separator=","
                    />
                  </div>
                  <div style={{ color: "#333333" }}>
                    <li className="fa fa-instagram" />
                  </div>
                </div>
              </div>
            )}
            {!!summaryData.tasks.ig_story && (
              <div className="kpi-cubes task-cube">
                <div>
                  <strong style={{ color: "#333333" }}>
                    Instagram Stories
                  </strong>
                </div>
                <div className="task-kube-inner">
                  <div style={{ color: "#333333" }}>
                    <CountUp
                      end={summaryData.tasks.ig_story || 0}
                      separator=","
                    />
                  </div>
                  <div style={{ color: "#333333" }}>
                    <li className="fa moi-stories" />
                  </div>
                </div>
              </div>
            )}
            {!!summaryData.tasks.tw_tweet && (
              <div className="kpi-cubes task-cube">
                <div>
                  <strong style={{ color: "#333333" }}>Twitter Tweets</strong>
                </div>
                <div className="task-kube-inner">
                  <div style={{ color: "#333333" }}>
                    <CountUp
                      end={summaryData.tasks.tw_tweet || 0}
                      separator=","
                    />
                  </div>
                  <div style={{ color: "#333333" }}>
                    <li className="fa fa-twitter" />
                  </div>
                </div>
              </div>
            )}
            {!!summaryData.tasks.yt_video && (
              <div className="kpi-cubes task-cube">
                <div>
                  <strong style={{ color: "#333333" }}>YouTube Videos</strong>
                </div>
                <div className="task-kube-inner">
                  <div style={{ color: "#333333" }}>
                    <CountUp
                      end={summaryData.tasks.yt_video || 0}
                      separator=","
                    />
                  </div>
                  <div style={{ color: "#282828" }}>
                    <li className="fa fa-youtube-play" />
                  </div>
                </div>
              </div>
            )}
            {!!summaryData.tasks.tk_video && (
              <div className="kpi-cubes task-cube">
                <div>
                  <strong style={{ color: "#333333" }}>Tiktok Videos</strong>
                </div>
                <div className="task-kube-inner">
                  <div style={{ color: "#333333" }}>
                    <CountUp
                      end={summaryData.tasks.tk_video || 0}
                      separator=","
                    />
                  </div>
                  <div style={{ color: "#333333" }}>
                    <li className="fa-brands fa-tiktok" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Share Comments */}
        {this.props.shareComments.tasks && (
          <CommentsSection
            open={this.state.openComments.includes("tasks")}
            title={"tasks comments"}
            text={this.props.shareComments.tasks}
            onToggle={() => {
              this.state.openComments.addOrRemove("tasks");
              this.setState({});
            }}
          />
        )}
      </>
    );
  };

  renderKpis = () => {
    const { editKpiOpen } = this.state;
    const { summaryData } = this.props;
    const onlyYoutube =
      this.props.tabs.length === 1 && this.props.tabs.includes("youtube");
    const estimatedImpressions =
      (summaryData.kpis.impressions.verified
        ? (summaryData.kpis.impressions?.current || 0) -
          (summaryData.kpis.impressions?.verified || 0)
        : 0) || 0;
    return (
      <>
        <div style={{ marginTop: 10 }}>
          <div style={{ float: "left", display: "flex" }}>
            <strong className="small-title">KPI's</strong>
            {!this.props.shared &&
              (this.props.fromCampaign || this.props.isAmbassador) && (
                <Tooltip
                  visible={editKpiOpen}
                  onVisibleChange={() =>
                    this.setState({ editKpiOpen: !editKpiOpen })
                  }
                  trigger="click"
                  placement="top"
                  overlayClassName={"edit-popup kpi-popup"}
                  overlay={
                    editKpiOpen && (
                      <div>
                        <div className="edit-popup-title">Edit KPI'S</div>
                        <div>
                          <div className="edit-kpi">
                            <label className="small-title">IMPRESSIONS</label>
                            <div>
                              <input
                                defaultValue={
                                  summaryData.kpis.impressions.target
                                }
                                ref={(e) => (this._impressionsInput = e)}
                                className="input-line"
                                type={"number"}
                              />
                            </div>
                          </div>
                          <div className="edit-kpi">
                            <label className="small-title">ENGAGEMENTS</label>
                            <div>
                              <input
                                defaultValue={
                                  summaryData.kpis.engagements.target
                                }
                                ref={(e) => (this._engagementsInput = e)}
                                className="input-line"
                                type={"number"}
                              />
                            </div>
                          </div>
                          <div className="edit-kpi">
                            <label className="small-title">CLICKS</label>
                            <div>
                              <input
                                defaultValue={
                                  summaryData.kpis.conversions.target
                                }
                                ref={(e) => (this._conversionsInput = e)}
                                className="input-line"
                                type={"number"}
                              />
                            </div>
                          </div>
                          <div className="edit-kpi">
                            <label className="small-title">Total Budget</label>
                            <div>
                              <input
                                defaultValue={
                                  summaryData.campaign_details.budget
                                }
                                ref={(e) => (this._budgetInput = e)}
                                className="input-line"
                                type={"number"}
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ paddingBottom: 10, textAlign: "center" }}>
                          <button
                            className="attach-links-btn btn btn-primary cancel"
                            onClick={() =>
                              this.setState({ editKpiOpen: false })
                            }
                          >
                            <span>Cancel</span>
                          </button>
                          <button
                            style={{ marginLeft: 10 }}
                            className="attach-links-btn btn btn-primary"
                            id="edit_kpi_btn"
                            onClick={() => this.editKpis(summaryData)}
                          >
                            <span>Save</span>
                          </button>
                        </div>
                      </div>
                    )
                  }
                >
                  <i
                    style={{
                      cursor: "pointer",
                      fontSize: 12,
                      marginTop: 1,
                      color: "var(--main-baby-blue-color)",
                      marginLeft: 5,
                    }}
                    className="fas fa-edit"
                  ></i>
                </Tooltip>
              )}
          </div>
          {!this.props.shared && (
            <div style={{ float: "right" }}>
              <span className="by-budget-toggle">
                <strong className="small-title">By Budget</strong>
                <Toggle
                  defaultChecked={this.state.byBudgetToggle}
                  icons={false}
                  onChange={() =>
                    this.setState({
                      byBudgetToggle: !this.state.byBudgetToggle,
                    })
                  }
                />
                <strong className="small-title">Money Spent</strong>
              </span>
            </div>
          )}
          <div style={{ display: "flex", width: "100%" }}>
            {!this.hideImpressions ? (
              <div className="kpi-cubes">
                <div>
                  <strong style={{ color: "#17a086" }}>
                    {onlyYoutube ? (
                      "VIEWS"
                    ) : (
                      <span>
                        IMPRESSIONS / VIEWS{" "}
                        {!this.props.shared &&
                          summaryData.kpis.impressions.verified !== null && (
                            <InfoToolTip
                              overlay={
                                <div>
                                  <div>
                                    Verified Impressions / Views:{" "}
                                    {formatBigNumbers(
                                      summaryData.kpis.impressions.verified,
                                    )}
                                  </div>
                                  {estimatedImpressions ? (
                                    <div>
                                      Estimated Impressions:{" "}
                                      {formatBigNumbers(estimatedImpressions)}
                                    </div>
                                  ) : null}
                                </div>
                              }
                            />
                          )}
                      </span>
                    )}
                  </strong>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    color:
                      !summaryData.kpis.impressions.precent ||
                      summaryData.kpis.impressions.precent >= 100
                        ? "#17a086"
                        : "#cf4f4e",
                  }}
                >
                  <span style={{ fontSize: 28 }}>
                    <CountUp
                      end={summaryData.kpis.impressions.current || 0}
                      formattingFn={countUpFormat}
                    />
                    &nbsp;
                  </span>
                  {summaryData.kpis.impressions.precent ? (
                    <span>
                      (
                      <CountUp
                        end={Math.abs(
                          summaryData.kpis.impressions.precent,
                        ).toFixed(0)}
                        suffix={"%"}
                      />
                      )
                    </span>
                  ) : null}
                  {/* {summaryData.kpis.impressions.precent >= 100 &&
                      <i style={{ fontSize: 21, marginTop: 11, marginLeft: 5 }}
                          className={"fas fa-arrow-up"} />} */}
                </div>
                <div style={{ color: "#17a086" }}>
                  <li className="fa fa-eye" />
                </div>
                <div>
                  <span>target</span>
                  <span>
                    {formatBigNumbers(summaryData.kpis.impressions.target || 0)}
                  </span>
                </div>
                <div>
                  <span>CPM</span>
                  <span>
                    {this.state.byBudgetToggle
                      ? summaryData.kpis.impressions.cost_by_money_spent
                      : summaryData.kpis.impressions.cost_by_budget}
                  </span>
                </div>
              </div>
            ) : null}
            <div className="kpi-cubes">
              <div>
                <strong style={{ color: "rgb(52, 118, 189)" }}>
                  {onlyYoutube ? "Likes & Comments" : "ENGAGEMENTS"}
                </strong>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  color:
                    !summaryData.kpis.engagements.precent ||
                    summaryData.kpis.engagements.precent >= 100
                      ? "#17a086"
                      : "#cf4f4e",
                }}
              >
                <span style={{ fontSize: 28 }}>
                  <CountUp
                    end={summaryData.kpis.engagements.current || 0}
                    formattingFn={countUpFormat}
                  />
                  &nbsp;
                </span>
                {summaryData.kpis.engagements.precent ? (
                  <span>
                    (
                    <CountUp
                      end={Math.abs(
                        summaryData.kpis.engagements.precent,
                      ).toFixed(0)}
                      suffix={"%"}
                    />
                    )
                  </span>
                ) : null}
              </div>
              <div style={{ color: "rgb(52, 118, 189)" }}>
                <li className="fa fa-signal" />
              </div>
              <div>
                <span>target</span>
                <span>
                  {formatBigNumbers(summaryData.kpis.engagements.target || 0)}
                </span>
              </div>
              <div>
                <span>CPE</span>
                <span>
                  {this.state.byBudgetToggle
                    ? summaryData.kpis.engagements.cost_by_money_spent
                    : summaryData.kpis.engagements.cost_by_budget}
                </span>
              </div>
            </div>
            <div className="kpi-cubes">
              <div>
                <strong style={{ color: "rgb(226, 140, 55)" }}>
                  {"Link clicks"}
                </strong>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  color:
                    !summaryData.kpis.conversions.precent ||
                    summaryData.kpis.conversions.precent >= 100
                      ? "#17a086"
                      : "#cf4f4e",
                }}
              >
                <span style={{ fontSize: 30 }}>
                  <CountUp
                    end={summaryData.kpis.conversions.current || 0}
                    formattingFn={countUpFormat}
                  />
                  &nbsp;
                </span>
                {summaryData.kpis.conversions.precent ? (
                  <span>
                    (
                    <CountUp
                      end={Math.abs(
                        summaryData.kpis.conversions.precent,
                      ).toFixed(0)}
                      suffix={"%"}
                    />
                    )
                  </span>
                ) : null}
              </div>
              <div style={{ color: "rgb(226, 140, 55)" }}>
                <li className="fas fa-arrow-right-arrow-left" />
              </div>
              <div style={{ display: "flex" }}>
                <span>target </span>
                <span>
                  {" "}
                  {formatBigNumbers(summaryData.kpis.conversions.target || 0)}
                </span>
              </div>
              <div>
                <span>CPC</span>
                <span>
                  {this.state.byBudgetToggle
                    ? summaryData.kpis.conversions.cost_by_money_spent
                    : summaryData.kpis.conversions.cost_by_budget}
                </span>
              </div>
            </div>
          </div>
        </div>

        {this.props.shareComments.kpi && (
          <CommentsSection
            open={this.state.openComments.includes("kpi")}
            title={"kpi's comments"}
            text={this.props.shareComments.kpi}
            onToggle={() => {
              this.state.openComments.addOrRemove("kpi");
              this.setState({});
            }}
          />
        )}
      </>
    );
  };

  renderSummaryData(summarySocial) {
    if (!summarySocial || !summarySocial.funnel) return;

    return (
      <div>
        <div className="funnel-cubes">
          {this.state.selectedSocial === "instagram" &&
          (this.props.reachFrequencyLoader || this.props.reachFrequency) ? (
            <>
              <div>
                <div>
                  Unique Reach{" "}
                  <InfoToolTip
                    overlay={
                      <div>
                        {this.props.reachFrequency?.campaign?.is_partial ? (
                          <div>
                            Data is partial and based on a sample amount of
                            posts and stories.
                          </div>
                        ) : null}
                        <div>
                          Unique Reach estimates the total number of distinct
                          individuals who have seen your content at least once.
                          <br />
                          This metric counts each person only once, providing
                          insight into the breadth of your audience
                        </div>
                      </div>
                    }
                  />
                </div>
                <div>
                  {this.props.reachFrequencyLoader ? (
                    <InfiniteLoader style={{ marginBottom: "auto" }} />
                  ) : (
                    <CountUp
                      end={this.props.reachFrequency.campaign.unique_reach || 0}
                      formattingFn={countUpFormat}
                    />
                  )}
                </div>
              </div>
              <div>
                <div>
                  Frequency
                  <InfoToolTip
                    overlay={
                      <div>
                        Frequency estimates the average number of times each
                        individual has seen your content.
                        <br />
                        This metric provides insight into how often your
                        audience is being exposed to your posts and stories.
                      </div>
                    }
                  />
                </div>
                <div>
                  {this.props.reachFrequencyLoader ? (
                    <InfiniteLoader style={{ marginBottom: "auto" }} />
                  ) : (
                    <CountUp
                      end={this.props.reachFrequency.campaign.frequency || 0}
                      decimals={2}
                    />
                  )}
                </div>
              </div>
            </>
          ) : null}
          {summarySocial.funnel.followers ? (
            <div>
              <div>Total Followers</div>
              <div>
                <CountUp
                  end={summarySocial.funnel.followers.total_followers || 0}
                  formattingFn={countUpFormat}
                />
              </div>
            </div>
          ) : null}
          {summarySocial.funnel.stories ? (
            <div>
              <div>Story Views</div>
              <div>
                <CountUp
                  end={summarySocial.funnel.stories.total_views || 0}
                  formattingFn={countUpFormat}
                />
              </div>
            </div>
          ) : null}
          {summarySocial.funnel.total?.video_views ? (
            <div>
              <div>Video Views</div>
              <div>
                <CountUp
                  end={summarySocial.funnel.total.video_views || 0}
                  formattingFn={countUpFormat}
                />
              </div>
            </div>
          ) : null}
        </div>
        {this.renderFunnel(summarySocial.funnel)}
      </div>
    );
  }

  renderFunnel(funnel) {
    const { summaryData } = this.props;
    if (!funnel) return;
    const videosPlatform =
      this.state.selectedSocial === "youtube" ||
      this.state.selectedSocial === "tiktok";
    if (this.hideImpressions || videosPlatform) {
      const impressions = videosPlatform
        ? funnel.total.impressions + funnel.total.boosted_impressions || 0
        : (funnel?.stories?.total_views || 0) +
            (funnel?.total?.video_views || 0) || 0;
      return (
        <div className="funnel-container">
          <div className="taper first">
            <div className="taper-text first">
              <div>Views </div>
              <div className="tapper-value">
                <div className="fa fa-eye" style={{ marginRight: 5 }} />
                <CountUp end={impressions} separator="," />
              </div>
            </div>
          </div>
          <div className="taper-spacer" />
          <div className="taper second">
            <div className="taper-text second">
              <div>
                {videosPlatform ? "Likes & Comments" : "Engagement"}{" "}
                <Tooltip
                  placement="top"
                  trigger={["hover", "click"]}
                  overlay={
                    <span>
                      Engagement - Total engagement in this campaign. The
                      percentage is calculated out of impressions{" "}
                    </span>
                  }
                >
                  <i className="fa-solid fa-info-circle" />
                </Tooltip>
              </div>
              <div className="tapper-value">
                <div className="fa fa-signal" style={{ marginRight: 5 }} />
                <CountUp end={funnel.total.engagement || 0} separator="," />
                {impressions ? (
                  <span className="tapper-small-text">
                    (
                    {((funnel.total.engagement / impressions) * 100).toFixed(2)}
                    %)
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="taper-spacer second" />
          <div className="taper third">
            <div className="taper-text third">
              <div>
                Link Clicks{" "}
                <Tooltip
                  placement="top"
                  trigger={["hover", "click"]}
                  overlay={
                    <span>Clicks - Users that clicked on your link</span>
                  }
                >
                  <i className="fa-solid fa-info-circle" />
                </Tooltip>
              </div>
              <div className="tapper-value">
                <div
                  className="fas fa-arrow-right-arrow-left"
                  style={{ marginRight: 5 }}
                />
                <CountUp end={funnel.total.conversions || 0} separator="," />
              </div>
            </div>
          </div>
        </div>
      );
    }
    const hideEngagements =
      !summaryData.tasks.ig_post &&
      !summaryData.tasks.tk_video &&
      !summaryData.tasks.yt_video &&
      !funnel.total.engagement;
    const humanzImpressionsDesc =
      "Estimated impressions provide an estimate of the organic lifetime impressions of static posts for influencers who are not verified via the Humanz app, excluding paid boosts, bots and fake followers.";
    const verifiedImpressions = funnel?.total?.verified_impressions || 0;
    const estimatedImpressions =
      (funnel?.total?.impressions || 0) - verifiedImpressions;
    return (
      <>
        <div className="funnel-container">
          <div className="taper first">
            <div className="taper-text first">
              <div className="taper-text-inner">
                <div>
                  Impressions / Views{" "}
                  {!this.props.shared && (
                    <InfoToolTip
                      overlayStyle={{ maxWidth: 500 }}
                      overlay={
                        <span>
                          {
                            <span>
                              {funnel?.total?.verified_impressions !== null ? (
                                <div>
                                  <div>
                                    Verified Impressions / Views:{" "}
                                    {formatBigNumbers(verifiedImpressions)}
                                  </div>
                                  {estimatedImpressions ? (
                                    <div>
                                      Estimated Impressions:{" "}
                                      {formatBigNumbers(estimatedImpressions)}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              {!this.props.shared &&
                                funnel.total.boosted_impressions && (
                                  <span>
                                    Total of Humanz Impressions (
                                    {numberWithCommas(funnel.total.impressions)}
                                    ) and Boosted Impressions (
                                    {numberWithCommas(
                                      funnel.total.boosted_impressions,
                                    )}
                                    ).
                                    <br />
                                  </span>
                                )}
                              {humanzImpressionsDesc}
                            </span>
                          }
                        </span>
                      }
                    />
                  )}
                </div>
                <div className="tapper-value">
                  <div className="fa fa-eye" style={{ marginRight: 5 }} />
                  {numberWithCommas(
                    funnel.total.impressions + funnel.total.boosted_impressions,
                  )}
                </div>
              </div>
              {funnel.total.boosted_impressions && !this.props.shared && (
                <div className="impressions-split">
                  <div className="boosted-impressions-taper">
                    <div className="taper-text-inner">
                      <div>
                        Boosted Impressions{" "}
                        <Tooltip
                          placement="top"
                          trigger={["hover", "click"]}
                          overlay={
                            <span>
                              {
                                "Boosted Impressions are the sum of the impressions of external campaigns"
                              }
                            </span>
                          }
                        >
                          <i className="fa-solid fa-info-circle" />
                        </Tooltip>
                      </div>
                      <div className="tapper-value">
                        {numberWithCommas(funnel.total.boosted_impressions)}
                      </div>
                      <i className="boosted-icon fa-duotone fa-rocket-launch" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="taper-spacer" />
          {/* 2 sections taper */}
          {/* <div className="taper-multi second">
                <div className="taper taper-background" />
                <div className="taper-left">
                    <div className="taper" />
                    <div className="taper-text">
                        <div>Unique Feed</div>
                        <div className="tapper-value"><div className="fa fa-user" style={{ marginRight: 5 }} />{summaryData && numberWithCommas(summaryData.summary.impressions)}</div>
                    </div>
                    <div className="taper" />
                    <div className="taper-text">
                        <div>Frequency</div>
                        <div className="tapper-value"><div className="fa fa-signal" style={{ marginRight: 5 }} />{summaryData && numberWithCommas(summaryData.summary.impressions)}</div>
                    </div>
                </div>
                <div className="taper-right">
                    <div className="taper right" />
                    <div className="taper-text">
                        <div>Unique Story</div>
                        <div className="tapper-value"><div className="fa moi-stories" style={{ marginRight: 5, marginTop: 4 }} />{summaryData && numberWithCommas(summaryData.summary.impressions)}</div>
                    </div>
                </div>
            </div> */}
          <div className="taper second" />
          <div className="taper-text second">
            <div>
              Qualified Impressions &nbsp;
              <Tooltip
                placement="top"
                trigger={["hover", "click"]}
                overlay={
                  <span>
                    Qualified impressions refers to the portion of Humanz
                    impressions that is likely to come specifically from the
                    target audience specified in the brief.
                  </span>
                }
              >
                <i className="fa-solid fa-info-circle" />
              </Tooltip>
            </div>
            <div
              className="tapper-value"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="fa moi-influencer-circles"
                style={{ marginRight: 5, marginTop: 3 }}
              />
              <CountUp
                end={funnel.total.quality_impressions || 0}
                separator=","
              />
            </div>
          </div>
          {hideEngagements ? null : (
            <>
              <div className="taper-spacer second" />
              <div className="taper third" />
              <div className="taper-text third">
                <div>
                  Engagement{" "}
                  <Tooltip
                    placement="top"
                    trigger={["hover", "click"]}
                    overlay={
                      <span>
                        Engagement - Total engagement in this campaign. The
                        percentage is calculated out of impressions{" "}
                      </span>
                    }
                  >
                    <i className="fa-solid fa-info-circle" />
                  </Tooltip>
                </div>
                <div className="tapper-value">
                  <div className="fa fa-signal" style={{ marginRight: 5 }} />
                  <CountUp end={funnel.total.engagement || 0} separator="," />
                  <span className="tapper-small-text">
                    (
                    {(
                      (funnel.total.engagement /
                        (funnel.total.impressions +
                          funnel.total.boosted_impressions)) *
                      100
                    ).toFixed(2)}
                    %)
                  </span>
                </div>
              </div>
            </>
          )}
          {funnel.total.conversions ? (
            <>
              <div
                className={`taper-spacer ${
                  // eslint-disable-next-line no-nested-ternary
                  hideEngagements ? "second" : "third"
                }`}
              />
              <div
                className={`taper ${
                  // eslint-disable-next-line no-nested-ternary
                  hideEngagements ? "third" : "fourth"
                }`}
              />
              <div
                className={`taper-text ${
                  // eslint-disable-next-line no-nested-ternary
                  hideEngagements ? "third" : "fourth"
                }`}
              >
                <div>
                  Clicks{" "}
                  <Tooltip
                    placement="top"
                    trigger={["hover", "click"]}
                    overlay={
                      <span>Clicks - Users that clicked on your link</span>
                    }
                  >
                    <i className="fa-solid fa-info-circle" />
                  </Tooltip>
                </div>
                <div className="tapper-value">
                  <div
                    className="fas fa-arrow-right-arrow-left"
                    style={{ marginRight: 5 }}
                  />
                  <CountUp end={funnel.total.conversions || 0} separator="," />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }

  renderEngagements = (summaryData, summarySocial) => {
    if (
      this.state.selectedSocial === "youtube" ||
      this.state.selectedSocial === "tiktok" ||
      !summaryData ||
      !summarySocial
    ) {
      return null;
    }
    const Charts = roiCharts(summarySocial.engagment, false);
    if (!Charts) return null;
    return (
      <TabPane tab="Engagements" key="2">
        <div
          className="roi-charts-container"
          style={{
            height: summarySocial.engagers_insights ? "560px" : "280px",
          }}
        >
          {Charts}
          {summarySocial.engagers_insights && (
            <div style={{ padding: "10px", overflow: "hidden" }}>
              <div
                className="roi-eng-cube engagers-top-likers"
                style={{ marginLeft: 0, width: 270, height: 260 }}
              >
                <div className="cube-title">
                  Top Likers{" "}
                  <i className=" top-likers-icon fa fa-thumbs-up"></i>
                </div>
                <div className="top-likers-list">
                  {summarySocial.engagers_insights.top_likers
                    .slice(0, 5)
                    .map((x, idx) => this.renderLiker(x, idx))}
                </div>
              </div>
              <div
                className="roi-eng-cube engagers-top-commenters"
                style={{ marginLeft: 10, width: 395, height: 260 }}
              >
                <div className="cube-title top-commenter-title">
                  Top Commenters{" "}
                  <i className=" top-likers-icon fas fa-comment-alt"></i>
                </div>
                <div className="top-likers-list">
                  {summarySocial.engagers_insights.comments.top_commenters
                    .slice(0, 5)
                    .map((x, idx) => this.renderCommenter(x, idx))}
                </div>
              </div>
              <div
                className="funnel-cubes engagers-insights-cubes"
                style={{ float: "right" }}
              >
                <div style={{ height: 123, marginTop: 0 }}>
                  <div>Suspicious Engagers</div>
                  <div className="engagers-insights-data">
                    {summarySocial.engagers_insights.frauds_percentage}%
                  </div>
                </div>
                {summarySocial.engagers_insights.comments && (
                  <div style={{ height: 123 }}>
                    <div>Unique Commenters</div>
                    <div className="engagers-insights-data">
                      {summarySocial.engagers_insights.comments.unique_users}%
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </TabPane>
    );
  };

  renderLiker(liker, idx) {
    return (
      <li key={liker.liker_id} className={"roi-top-liker"}>
        <img src={liker.image_url} className={"top-liker-image"} />
        <div className="top-liker-username">
          <a
            href={`https://www.instagram.com/${liker.username}`}
            target="_blank"
          >
            {liker.username}
          </a>
        </div>
        {/* <span className="top-liker-different-posts">{liker.different_posts}</span> */}
      </li>
    );
  }

  renderCommenter(commenter, idx) {
    return (
      <li key={commenter.commenter_id} className={"roi-top-commenter"}>
        <img src={commenter.image_url} className={"top-liker-image"} />
        <div className="top-liker-username top-comment">
          <a
            href={`https://www.instagram.com/${commenter.username}`}
            target="_blank"
          >
            {commenter.username}
          </a>
          <span className="top-commenter-comment">{commenter.comment}</span>
        </div>
      </li>
    );
  }

  renderConversionsTab = (summaryData, summarySocial) => {
    if (!summaryData?.kpis?.conversions?.current) return null;
    const Charts = conversionsCharts(
      summarySocial?.conversions || {},
      summaryData.countPerLink,
      false,
      this.props.fromMultiReport,
      summarySocial?.clicks_per_auction
        ? summarySocial.clicks_per_auction
        : null,
    );
    if (!Charts) return null;
    return (
      <TabPane tab="Clicks" key="3">
        <div className="roi-charts-container">{Charts}</div>
      </TabPane>
    );
  };

  renderSalesTab = (summaryData, summarySocial) => {
    if (!summaryData || !summarySocial) return null;
    return (
      <TabPane tab="Sales" key="4">
        <div className="roi-charts-container"></div>
      </TabPane>
    );
  };

  renderTopPosts = () => {
    const { selectedSocial } = this.state;
    if (
      !this.props.selectedPosts[selectedSocial] ||
      this.props.selectedPosts[selectedSocial].length === 0
    ) {
      return (
        <NoDataMessage>
          {" "}
          There is insufficient data to show detailed segmentation
        </NoDataMessage>
      );
    }

    return (
      <div style={{ marginTop: 30 }}>
        <div style={{ fontSize: "16px", marginBottom: 15 }}>TOP POSTS</div>
        <div className="post-block">
          {selectedSocial === "instagram" &&
            this.props.selectedPosts[selectedSocial].map((x, index) => {
              return (
                <div
                  className="blurable-frame"
                  style={{
                    display: "inline-block",
                    width: 300,
                    height: 550,
                    overflow: "hidden",
                  }}
                  key={index}
                >
                  <iframe
                    src={`https://www.instagram.com/p/${x}/embed/captioned`}
                    width="298"
                    height="680"
                    frameBorder="0"
                    scrolling="no"
                  ></iframe>
                </div>
              );
            })}
          {selectedSocial === "twitter" &&
            this.props.selectedPosts[selectedSocial].map((x, index) => {
              return (
                <div
                  className="blurable-frame"
                  style={{
                    display: "inline-block",
                    width: 300,
                    height: 500,
                    overflow: "hidden",
                  }}
                  key={index}
                >
                  <TwitterTweetEmbed tweetId={x} />
                </div>
              );
            })}
          {selectedSocial === "youtube" &&
            this.props.selectedPosts[selectedSocial].map((x, index) => {
              return (
                <div
                  className="blurable-frame"
                  style={{
                    display: "inline-block",
                    width: 300,
                    height: 500,
                    overflow: "hidden",
                  }}
                  key={index}
                >
                  <YouTube videoId={x} opts={{ width: 300, height: 500 }} />
                </div>
              );
            })}
          {selectedSocial === "tiktok" &&
            this.props.selectedPosts[selectedSocial].map((x, index) => {
              return (
                <div
                  className="blurable-frame"
                  style={{
                    display: "inline-block",
                    width: 300,
                    height: 500,
                    overflow: "hidden",
                    marginTop: 10,
                  }}
                  key={index}
                >
                  <iframe
                    style={{
                      display: "inline-block",
                      width: 300,
                      height: 500,
                      overflow: "hidden",
                    }}
                    src={`https://www.tiktok.com/embed/${x}`}
                    frameBorder={"0"}
                    allow={
                      "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  // renderTopStories = () => {
  //     return (
  //         summaryData.storyImages.length > 0 ? <div style={{ marginTop: 30 }}>
  //             <div style={{ fontSize: '16px', marginBottom: 15 }}>Top Stories</div>
  //             <div className="post-block">
  //                 {summaryData.storyImages.map((x, index) => {
  //                     return (<div style={{ display: 'inline-block', width: 298, height: 530, overflow: 'hidden' }} key={index}>
  //                         <img src={x.image_url} className="story-image" />
  //                     </div>)
  //                 })}
  //             </div>
  //         </div>
  // :
  // null
  //     )
  // }

  renderRecommendations(summaryData) {
    if (summaryData.lookalikes.length === 0) return;
    return null;
    // return (
    //   <div className="recommendations-container">
    //     <div style={{ fontSize: "16px", marginBottom: 15 }}>
    //       Recommendations
    //     </div>
    //     <div className="rec-slider">
    //       <Slider isInfinite={false} delay={0} visibleItems={4}>
    //         {summaryData.lookalikes.map((i, key) => (
    //           <div className="rec-cube" key={key} style={{ height: 250 }}>
    //             <div className="rec-ribbon">{i.moi_grade}</div>
    //             <div>
    //               <a
    //                 href={`https://www.instagram.com/${i.instagram_username}`}
    //                 target="_blank"
    //               >
    //                 {i.instagram_username}
    //               </a>
    //             </div>
    //             <img className="avatar" src={i.image} />
    //             <div
    //               style={{
    //                 textAlign: "center",
    //                 marginTop: 5,
    //                 color: "#757575",
    //                 overflow: "hidden",
    //                 height: 35,
    //                 fontSize: 13,
    //               }}
    //             >
    //               {i.top_categories && i.top_categories.map((x) => x)}
    //             </div>
    //             <div style={{ marginTop: 5 }}>
    //               <div
    //                 style={{
    //                   marginLeft: "10%",
    //                   width: "50%",
    //                   display: "flex",
    //                   float: "left",
    //                 }}
    //               >
    //                 <div
    //                   className="moi-instagram-icon"
    //                   style={{ marginTop: 2, marginRight: 3 }}
    //                 />
    //                 <strong>{formatBigNumbers(i.followers)}</strong>
    //               </div>
    //               <div style={{ width: "40%", display: "flex", float: "left" }}>
    //                 <div
    //                   className="moi-heart-full"
    //                   style={{ marginTop: 2, marginRight: 3 }}
    //                 />
    //                 <strong>{formatBigNumbers(i.avg_likes)}</strong>
    //               </div>
    //             </div>
    //             {!this.props.shared && (
    //               <div style={{ marginTop: 30 }}>
    //                 <button
    //                   className="btn btn-primry"
    //                   style={{
    //                     width: "100%",
    //                     padding: 3,
    //                     fontSize: "12pt",
    //                     background: "var(--main-baby-blue-color)",
    //                     color: "white",
    //                   }}
    //                   onClick={() => this.props.openAddToList(i)}
    //                 >
    //                   Invite
    //                 </button>
    //               </div>
    //             )}
    //           </div>
    //         ))}
    //       </Slider>
    //     </div>
    //   </div>
    // );
  }
}

export default function RoiSummary(props) {
  const context = useContext(RoiContext);
  const user = useSelector((state) => state.userReducer);
  return <RoiSummaryInner user={user} {...context} {...props} />;
}
