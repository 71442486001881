import React from "react";
import Select from "react-select";
import ToggleButton from "react-toggle-button";
import Clipboard from "react-clipboard.js";
import moment from "moment";
import { ALLOW_COUNTRIES } from "../../../actions/commonActionsConf";
import {
  getAgencyBankDetails,
  getFullAgencyBankDetails,
  getUKAgencyBankDetails,
  getUSAgencyBankDetails,
} from "../../../actions/userActions";
import { usStates } from "../utilities/general";

// eslint-disable-next-line class-methods-use-this
export const getTaxNumberLabel = (country) => {
  switch (country) {
    case ALLOW_COUNTRIES.Israel:
      return "ח.פ / מספר עוסק";
    case ALLOW_COUNTRIES.Nigeria:
      return "Tax Identification Number";
    case ALLOW_COUNTRIES["United States"]:
      return "EIN";
    case ALLOW_COUNTRIES.India:
      return "PAN/GSTIN";
    default:
      return "VAT / TAX Number";
  }
};

export class AgencyBankDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      allowedToEditAgent: this.props.allowedToEditAgent,
      banks: [],
      bankDetails: null,
      showNewBankDetails: false,
      isIdenticalBranch: false,
      use_iban: false,
      newBankDetails: {
        full_name: "",
        identity_number: "",
        branch_number: "",
        account_number: "",
        bank_number: "",
        bank: "",
        tax_number: "",
        account_type: "",
        iban: "",
      },
      billingDetails: {
        email: this.props.agentEmail,
        address: {
          address: "",
          address_line_2: "",
          city: "",
          country: this.props.user.userDetails.company_details.country,
          zip: "",
          phone: this.props.agentPhone,
        },
      },
      revealBankDetails: false,
      error: false,
      errors: [],
      selectedAccountType: 0,
      country: "",
      indiaType: 0,
    };

    this.fieldsErrors = [
      "accountNumber",
      "bankNumber",
      "alreadyExist",
      "taxNumber",
      "noBankDetails",
      "branchNumber",
      "invalidBankDetails",
      "invalidNigeriaBank",
      "invalidIban",
    ];
    this.ACCOUNT_TYPES = [
      { value: "checking", label: "Checking" },
      { value: "savings", label: "Savings" },
    ];
    this.INDIA_ACCOUNT_TYPES = ["IFSC", "UPI"];
    // eslint-disable-next-line global-require
    this.identicalBranchesZa = require("../utilities/banks/za_identical_banks_branches.json");
    // eslint-disable-next-line global-require
    this.errorsTranslations = require("./bank_errors.json");
    this.country = this.props.user.userDetails.company_details.country;
  }

  loadBanks() {
    const countries = {};
    countries[
      ALLOW_COUNTRIES.Israel
      // eslint-disable-next-line global-require
    ] = require("../utilities/banks/il_banks.json");
    countries[
      ALLOW_COUNTRIES["South Africa"]
      // eslint-disable-next-line global-require
    ] = require("../utilities/banks/za_banks.json");
    countries[
      ALLOW_COUNTRIES.Australia
      // eslint-disable-next-line global-require
    ] = require("../utilities/banks/au_banks.json");
    countries[
      ALLOW_COUNTRIES["New Zealand"]
      // eslint-disable-next-line global-require
    ] = require("../utilities/banks/nz_banks.json");
    countries[
      ALLOW_COUNTRIES.Turkey
      // eslint-disable-next-line global-require
    ] = require("../utilities/banks/tr_banks.json");
    countries[
      ALLOW_COUNTRIES.Nigeria
      // eslint-disable-next-line global-require
    ] = require("../utilities/banks/ng_banks.json");
    countries[
      ALLOW_COUNTRIES.Brazil
      // eslint-disable-next-line global-require
    ] = require("../utilities/banks/br_banks.json");
    countries[ALLOW_COUNTRIES["United Kingdom"]] = [];
    countries[
      ALLOW_COUNTRIES.India
      // eslint-disable-next-line global-require
    ] = require("../utilities/banks/in_banks.json");
    const { country } = this.props.user.userDetails.company_details;
    if (country) {
      if (country === "United States") {
        this.setState({ showNewBankDetails: true });
      }
      if (countries[country]) {
        const { newBankDetails } = this.state;
        if (country === ALLOW_COUNTRIES.Nigeria) {
          // Default Branch number to Nigeria since not using it.
          newBankDetails.branch_number = -1;
        } else if (country === ALLOW_COUNTRIES["United Kingdom"]) {
          newBankDetails.bank_number = -44;
        }

        this.setState({ banks: countries[country], country, newBankDetails });
      } else {
        // Alert.alert("Country problem", "Sorry, at the moment we are not supporting payments in your country, please contact us at info@humanz.ai for more info",
        //     [
        //         { text: 'Back', onPress: () => this.props.navigation.goBack() },
        //         { text: 'Settings', onPress: () => { this.props.navigation.navigate('EditDetailsPage'); this.setState({ moved: true }) } },
        //     ],
        //     { cancelable: false }
        // )
      }
    } else {
      // Alert.alert("Missing country", "In order to continue fill up your phone number and country",
      //     [
      //         { text: 'Go to settings', onPress: () => { this.props.navigation.navigate('EditDetailsPage'); this.setState({ moved: true }) } },
      //         { text: 'Back', onPress: () => this.props.navigation.goBack() },
      //     ],
      //     { cancelable: false }
      // )
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getAgencyBankDetailsByCountry = (country) => {
    switch (country) {
      case "United Kingdom":
        return getUKAgencyBankDetails;
      case "United States":
        return getUSAgencyBankDetails;
      default:
        return getAgencyBankDetails;
    }
  };

  loadBankDetails = () => {
    const newState = { loading: true };
    if (this.props.agentId) {
      const country = this.props.user.userDetails?.company_details?.country;
      const func = this.getAgencyBankDetailsByCountry(country);
      func(this.props.agentId, (banks) => {
        if (banks && banks.length > 0) {
          this.setState({ loading: false, bankDetails: banks[0] });
          if (this.state.revealBankDetails) {
            if (banks[0]) {
              getFullAgencyBankDetails(
                this.props.agentId,
                banks[0].id,
                this.state.revealBankDetails,
                (fullBankDetails) => {
                  this.setState({
                    use_iban: !!banks[0]?.turkey?.iban,
                    bankDetails: fullBankDetails,
                  });
                },
              );
            }
          }
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      newState.loading = false;
    }
    this.setState(newState);
  };

  componentDidMount() {
    this.loadBanks();
    this.loadBankDetails();
  }

  checkBankDetails(details) {
    const { country } = this;
    const errors = [];
    if (country === "United States") {
      if (!details.ein) {
        errors.push("ein");
      }
      if (errors.length > 0) {
        this.setState({ errors });
        return false;
      }
      return true;
    }
    if (!details.full_name) errors.push("fullName");
    if (!details.identity_number) errors.push("identityNumber");
    if (country === "Turkey" && this.state.use_iban && !details.iban) {
      errors.push("invalidIban");
    }
    if (country === ALLOW_COUNTRIES.India && this.state.indiaType === 1) {
      if (!details.upi) errors.push("upi");
    } else {
      if (
        !details.bank_number &&
        country !== ALLOW_COUNTRIES.India &&
        !this.state.use_iban
      ) {
        errors.push("bankName");
      }
      if (!details.branch_number && !this.state.use_iban) {
        errors.push("branchNumber");
      }
      if (!details.account_number && !this.state.use_iban) {
        errors.push("accountNumber");
      }
      if (country === "Brazil" && !details.account_type) {
        errors.push("accountType");
      }
    }
    if (errors.length > 0) {
      this.setState({ errors });
      return false;
    }
    return true;
  }

  checkIdenticalBranchZa(bankNumber) {
    if (
      this.props.user.userDetails.company_details.country !== "South Africa"
    ) {
      return;
    }
    const newState = { ...this.state };
    // eslint-disable-next-line radix
    if (this.identicalBranchesZa.includes(parseInt(bankNumber))) {
      newState.isIdenticalBranch = true;
      newState.newBankDetails.branch_number = String(bankNumber);
      this.setState(newState);
    } else if (
      newState.newBankDetails.branch_number !==
      newState.newBankDetails.bank_number
    ) {
      newState.newBankDetails.branch_number = "";
      newState.isIdenticalBranch = false;
      this.setState(newState);
    }
  }

  saveBankDetails = (id) => {
    if (this.state.bankDetails || !this.state.showNewBankDetails) {
      this.props.callback(true);
      return true;
    }
    if (this.state.saving) return false;
    this.setState({ saving: true, error: false, errors: [] });
    if (id) {
      this.props.deleteBankAccount(
        id,
        () => this.setState({ saving: false, bankDetails: null }),
        () => this.setState({ saving: false }),
      );
    } else {
      const { newBankDetails } = this.state;
      newBankDetails.tax_number = newBankDetails.tax_number
        ? newBankDetails.tax_number
        : null;

      // Set ein for USA to check fields
      if (this.country === "United States") {
        newBankDetails.ein = this.props.companyId;
      }

      if (!this.checkBankDetails(newBankDetails)) {
        this.setState({ saving: false, error: true });
        this.props.callback(false);

        return false;
      }

      // United kingdom
      if (this.country === "United Kingdom") {
        const ukDetails = {
          ...this.state.newBankDetails,
          ...this.state.billingDetails,
        };
        this.props.addBankAccountUK(
          this.props.agentId,
          ukDetails,
          this.successCallback,
          this.failCallback,
        );

        // Turkey
      } else if (this.country === "Turkey") {
        this.props.addTurkeyBankAccount(
          this.props.agentId,
          newBankDetails,
          this.successCallback,
          this.failCallback,
        );

        // United states
      } else if (this.country === "United States") {
        const usDetails = {
          ...newBankDetails,
          ...this.state.billingDetails,
        };
        this.props.addUSBankAccount(
          this.props.agentId,
          usDetails,
          this.usaSuccessCallback,
          this.failCallback,
        );
        // Any other country
      } else {
        this.props.addBankAccount(
          this.props.agentId,
          newBankDetails,
          this.successCallback,
          this.failCallback,
        );
      }
    }

    return true;
  };

  usaSuccessCallback = (data) => {
    this.setState({ saving: false, tipaltiDetails: data });
  };

  successCallback = (data) => {
    this.setState({ saving: false, bankDetails: data });
    this.props.callback(true);
  };

  failCallback = (data) => {
    if (data && (data.errors || data.inner_error_code)) {
      if (data.errors) {
        this.setState({
          saving: false,
          errors: data.errors.map((x) => x.field),
        });
      } else if (data.inner_error_code) {
        this.setState({
          saving: false,
          errors: [this.fieldsErrors[data.inner_error_code]],
        }); // Get from the BE the number of the error
      }
    } else {
      this.setState({ saving: false, error: true });
    }

    this.props.callback(false);
  };

  // eslint-disable-next-line class-methods-use-this
  getBranchLabel(country) {
    if (
      country === ALLOW_COUNTRIES.Australia ||
      country === ALLOW_COUNTRIES["New Zealand"]
    ) {
      return "BSB";
    }
    if (country === ALLOW_COUNTRIES["United Kingdom"]) return "Sort Code";
    if (country === ALLOW_COUNTRIES["United States"]) return "Routing Number";
    if (country === ALLOW_COUNTRIES.India) return "IFSC";

    return "Branch Number";
  }

  // eslint-disable-next-line class-methods-use-this
  getAccountNumberLabel(country) {
    if (country === ALLOW_COUNTRIES.Nigeria) return "NUBAN";

    return "Account Number";
  }

  changeBankNumber = (value) => {
    if (!value) return;
    this.setState({
      newBankDetails: { ...this.state.newBankDetails, bank_number: value },
    });
    this.checkIdenticalBranchZa(value);
  };

  deleteBankDetails = () => {
    this.setState({ saving: true, loading: true });
    this.props.deleteBankDetails(
      this.props.agentId,
      this.state.bankDetails.id,
      (success) => {
        const newState = { saving: false, loading: false };
        if (success) {
          newState.bankDetails = null;
        } else {
          newState.error = true;
        }

        this.setState(newState);
      },
    );
  };

  render() {
    return (
      <>
        <h4>Agency Bank Details</h4>
        {this.country === ALLOW_COUNTRIES["United States"] &&
          !this.state.bankDetails &&
          !this.state.loading && (
            <div className="alert alert-warning">
              Please fill in the agent address details in order to create a form
              for the agent
            </div>
          )}
        {this.renderAgencyBankDetails()}
        {this.state.errors.length > 0 ? (
          <div style={{ color: "#f15b23" }}>
            {this.errorsTranslations[this.state.errors[0]]}
          </div>
        ) : null}
        {this.state.error ? (
          <div style={{ color: "#f15b23" }}>Something went wrong</div>
        ) : null}
      </>
    );
  }

  renderTipaltiDetails = () => {
    const tipaltiDetails = { ...this.state.tipaltiDetails };
    return (
      <div className="alert-info">
        <p>
          Please copy the link and share it with the agent so they can fill in
          their bank details.
        </p>
        <p>
          The link will be valid until{" "}
          <strong>
            {moment(tipaltiDetails.expiration_time).format(
              window.time.date_with_hour,
            )}
          </strong>
        </p>
        <p>
          Please notice - this link will appear only once. Make sure you copy it
          before you close this window or you will need to re-fill the agent
          address details.
        </p>
        <div>
          <div className="input-row" style={{ marginTop: 20 }}>
            <strong className="label-title">LINK</strong>
            <div style={{ display: "flex" }}>
              <input
                value={tipaltiDetails.redirect_to}
                className="input-line"
                readOnly={true}
              />
              <Clipboard
                style={{ background: "transparent", border: "none" }}
                data-clipboard-text={tipaltiDetails.redirect_to}
              >
                <i className="far fa-copy copy-btn" />
              </Clipboard>
            </div>
          </div>
          <button
            className={"btn btn-primary"}
            onClick={() => this.successCallback({})}
          >
            Close this window
          </button>
        </div>
      </div>
    );
  };

  renderAddressDetails = () => {
    const { billingDetails } = this.state;
    const { address } = this.state.billingDetails;
    return (
      <div
        className={`flex-row-space-around ${
          this.state.saving || this.props.saving ? "disabled" : ""
        }`}
        style={{ marginTop: 25 }}
      >
        {/* Email */}
        <div style={{ width: "45%" }}>
          <strong className="label-title">Email</strong>
          <div>
            <input
              onFocus={() => {
                if (this.props.agentEmail) {
                  this.setState({
                    billingDetails: {
                      ...billingDetails,
                      email: this.props.agentEmail,
                    },
                  });
                }
              }}
              className="input-line"
              value={billingDetails.email}
              onChange={(e) =>
                this.setState({
                  billingDetails: { ...billingDetails, email: e.target.value },
                })
              }
            />
          </div>
        </div>

        <div style={{ width: "45%" }}>
          <strong className="label-title">Phone</strong>
          <div>
            <input
              className="input-line"
              value={address.phone}
              onFocus={() => {
                if (this.props.agentPhone) {
                  this.setState({
                    billingDetails: {
                      ...billingDetails,
                      address: { ...address, phone: this.props.agentPhone },
                    },
                  });
                }
              }}
              onChange={(e) =>
                this.setState({
                  billingDetails: {
                    ...billingDetails,
                    address: { ...address, phone: e.target.value },
                  },
                })
              }
            />
          </div>
        </div>

        <div style={{ width: "45%" }}>
          <strong className="label-title">Address line 1</strong>
          <div>
            <input
              className="input-line"
              value={address.address}
              onChange={(e) =>
                this.setState({
                  billingDetails: {
                    ...billingDetails,
                    address: { ...address, address: e.target.value },
                  },
                })
              }
            />
          </div>
        </div>

        <div style={{ width: "45%" }}>
          <strong className="label-title">Address line 2 (optional)</strong>
          <div>
            <input
              className="input-line"
              value={address.address_line_2}
              onChange={(e) =>
                this.setState({
                  billingDetails: {
                    ...billingDetails,
                    address: { ...address, address_line_2: e.target.value },
                  },
                })
              }
            />
          </div>
        </div>
        {this.country === "United States" && (
          <div style={{ width: "45%" }}>
            <strong className="label-title">State</strong>
            <div>
              <Select
                options={usStates}
                placeholder={""}
                value={address.state}
                simpleValue
                clearable={false}
                onChange={(v) =>
                  this.setState({
                    billingDetails: {
                      ...billingDetails,
                      address: { ...address, state: v },
                    },
                  })
                }
                name="selected-state"
                disabled={false}
                searchable={true}
              />
            </div>
          </div>
        )}
        <div style={{ width: "45%" }}>
          <strong className="label-title">City</strong>
          <div>
            <input
              className="input-line"
              value={address.city}
              onChange={(e) =>
                this.setState({
                  billingDetails: {
                    ...billingDetails,
                    address: { ...address, city: e.target.value },
                  },
                })
              }
            />
          </div>
        </div>
        <div style={{ width: "45%" }} className="disabled">
          <strong className="label-title">Country</strong>
          <div>
            <input
              className="input-line"
              disabled={true}
              value={address.country}
            />
          </div>
        </div>
        <div style={{ width: "45%" }}>
          <strong className="label-title">ZIP</strong>
          <div>
            <input
              className="input-line"
              value={address.zip}
              onChange={(e) =>
                this.setState({
                  billingDetails: {
                    ...billingDetails,
                    address: { ...address, zip: e.target.value },
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  renderBankDetails = () => {
    const { bankDetails } = this.state;
    const { country } = this;

    return (
      <>
        {bankDetails.status === "pending" ? (
          <div className="agent-bank-details">PENDING</div>
        ) : (
          <div className="agent-bank-details">
            {country !== ALLOW_COUNTRIES["United States"] &&
              this.state.allowedToEditAgent && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState(
                      { revealBankDetails: !this.state.revealBankDetails },
                      () => this.loadBankDetails(),
                    );
                  }}
                >
                  {this.state.revealBankDetails
                    ? "Hide Details"
                    : "Reveal Details"}
                </button>
              )}
            <div>
              <strong>Owner Name:</strong> {bankDetails.full_name}
            </div>
            <div>
              <strong>{getTaxNumberLabel(country)}:</strong>{" "}
              {bankDetails.tax_number}
            </div>
            {country !== ALLOW_COUNTRIES["United States"] && (
              <div>
                {bankDetails.bank_number?.label}
                <strong>Bank:</strong>{" "}
                {
                  this.state.banks.find(
                    (x) => String(x.key) === String(bankDetails.bank_number),
                  )?.label
                }{" "}
                ({bankDetails.bank_number})
              </div>
            )}
            <div>
              <strong>Account number:</strong> {bankDetails.bank_account_number}
            </div>
            {country === "Turkey" && (
              <div>
                <strong>IBAN:</strong> {bankDetails?.turkey?.iban}
              </div>
            )}
            <div>
              <strong>{this.getBranchLabel(country)}:</strong>{" "}
              {bankDetails.bank_branch}
            </div>
            {this.state.allowedToEditAgent && (
              <button
                style={{ marginBottom: 20 }}
                onClick={this.deleteBankDetails}
                className="btn btn-primary delete"
              >
                {" "}
                Delete Bank Details{" "}
              </button>
            )}
          </div>
        )}
      </>
    );
  };

  renderNewDetails = () => {
    const { country } = this;
    if (country === ALLOW_COUNTRIES["United States"]) {
      return null;
    }
    if (!this.state.showNewBankDetails) {
      return (
        <button
          onClick={() => this.setState({ showNewBankDetails: true })}
          className={`btn btn-primary ${
            this.state.saving || this.props.saving ? "disabled" : ""
          }`}
          disabled={!this.state.allowedToEditAgent}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          {" "}
          Add Bank Details{" "}
        </button>
      );
    }

    const { newBankDetails } = this.state;
    return (
      <div
        className={`flex-row-space-around ${
          this.state.saving || this.props.saving ? "disabled" : ""
        }`}
        style={{ marginTop: 25 }}
      >
        {/* Company Name */}
        <div style={{ width: "45%" }}>
          <strong className="label-title">Company Name </strong>
          <div>
            <input
              className="input-line"
              value={newBankDetails.full_name}
              onChange={(e) =>
                this.setState({
                  newBankDetails: {
                    ...newBankDetails,
                    full_name: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        {/* Vat Number - Company ID */}
        <div style={{ width: "45%" }}>
          <strong className="label-title">{getTaxNumberLabel(country)} </strong>
          <div>
            <input
              className="input-line"
              value={newBankDetails.identity_number}
              onChange={(e) =>
                this.setState({
                  newBankDetails: {
                    ...newBankDetails,
                    identity_number: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        {/* Bank Name */}
        {country === ALLOW_COUNTRIES["United Kingdom"] ? null : ( // If Uk no bank name
          <div style={{ width: "45%" }}>
            <strong className="label-title">Bank</strong>
            <div className="searchable-input" style={{ marginTop: -5 }}>
              <Select
                // eslint-disable-next-line react/no-string-refs
                ref="stateSelect"
                options={this.state.banks}
                value={this.state.newBankDetails.bank_number}
                valueKey={"key"}
                onChange={this.changeBankNumber}
                placeholder={""}
                simpleValue
                clearable={false}
                disabled={this.state.use_iban}
                searchable={true}
              />
            </div>
          </div>
        )}
        {/* Branch */}
        {country === ALLOW_COUNTRIES.Nigeria ? null : ( // If Nigeria no branch
          <div style={{ width: "45%" }}>
            <strong className="label-title">
              {this.getBranchLabel(country)}{" "}
            </strong>
            <div>
              <input
                className="input-line"
                disabled={this.state.isIdenticalBranch || this.state.use_iban}
                value={newBankDetails.branch_number}
                onChange={(e) =>
                  this.setState({
                    newBankDetails: {
                      ...newBankDetails,
                      branch_number: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        )}
        {/* Account Number */}
        <div style={{ width: "45%" }}>
          <strong className="label-title">
            {this.getAccountNumberLabel(country)}{" "}
          </strong>
          <div>
            <input
              disabled={this.state.use_iban}
              className="input-line"
              value={newBankDetails.account_number}
              onChange={(e) =>
                this.setState({
                  newBankDetails: {
                    ...newBankDetails,
                    account_number: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        {/* Account Type */}
        {/* Only for Brazil */}
        {country === ALLOW_COUNTRIES.Brazil ? (
          <div style={{ width: "45%" }}>
            <strong className="label-title">Account Type</strong>
            <div className="searchable-input" style={{ marginTop: -5 }}>
              <Select
                // eslint-disable-next-line react/no-string-refs
                ref="stateSelect"
                options={this.ACCOUNT_TYPES}
                value={newBankDetails.account_type}
                onChange={(value) =>
                  this.setState({
                    newBankDetails: { ...newBankDetails, account_type: value },
                  })
                }
                placeholder={""}
                simpleValue
                clearable={false}
                disabled={false}
                searchable={false}
              />
            </div>
          </div>
        ) : null}
        {/* IBAN */}
        {/* Only for Turkey */}
        {country === ALLOW_COUNTRIES.Turkey ? (
          <div style={{ width: "45%", display: "flex" }}>
            <div style={{ width: "20%" }}>
              <strong className="label-title">Use IBAN</strong>
              <ToggleButton
                value={this.state.use_iban}
                onToggle={() =>
                  this.setState({ use_iban: !this.state.use_iban })
                }
                inactiveLabel={"No"}
                activeLabel={"Yes"}
              />
            </div>
            <div style={{ width: "80%" }}>
              {this.state.use_iban && (
                <>
                  <strong className="label-title"> IBAN</strong>
                  <div>
                    <input
                      disabled={!this.state.use_iban}
                      className="input-line"
                      value={newBankDetails.iban}
                      onChange={(e) =>
                        this.setState({
                          newBankDetails: {
                            ...newBankDetails,
                            iban: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ) : null}
        <div style={{ width: "45%" }} />
      </div>
    );
  };

  renderAgencyBankDetails() {
    if (this.state.loading) {
      return (
        <div className="spinner" style={{ marginTop: 10 }}>
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      );
    }

    if (this.state.bankDetails) {
      return this.renderBankDetails();
    }

    return (
      <>
        {["United Kingdom", "United States"].includes(this.country) &&
          this.renderAddressDetails()}
        {this.renderNewDetails()}
        {this.state.tipaltiDetails && this.renderTipaltiDetails()}
      </>
    );
  }
}
