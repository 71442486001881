/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import { Box, Button, Flex, Tag, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EditModeProps, LocalTask, MixedTasks } from "src/api/ExtendedTypes";
import { BundleAttributes, TaskModification } from "src/api/types";
import {
  ButtonSingleAction,
  HumanzActionsMenu,
} from "src/ui/humanz/HumanzActionsMenu";
import SingleTaskRow from "../../campaign_components/Tasks/SingleTaskRow";
import BundleAccordion from "./BundleAccordion";
import EditMode from "./EditMode";

const CurrentTasks = ({
  tasks,
  onToggleTask,
  taskUpdated,
  month,
  editMode,
  setEditMode,
  locked,
  offer,
  isMultiple,
  mode,
}: {
  tasks: MixedTasks;
  onToggleTask: any;
  taskUpdated: any;
  month: string;
  editMode: EditModeProps;
  setEditMode: any;
  locked?: string;
  offer?: TaskModification;
  isMultiple?: boolean;
  mode?: string;
}) => {
  const [selectedFilter, setSelectedFilter] = useState("All");

  useEffect(() => {
    if (isMultiple) {
      setEditMode({ mode: "insert", tasks: [] });
    }
  }, [isMultiple]);

  const totalTasks = useCallback(() => {
    return (
      tasks?.single?.length +
      tasks?.bundles?.reduce(
        (acc: number, bundle: BundleAttributes) => acc + bundle?.tasks?.length,
        0,
      )
    );
  }, [tasks]);

  const renderTaskRow = useMemo(
    () => (task: LocalTask, index: number) =>
      (
        <SingleTaskRow
          key={index}
          task={task}
          index={index}
          isOpen={task.new && !!editMode}
          taskUpdated={taskUpdated}
          onToggleTask={onToggleTask}
          editMode={editMode}
          setEditMode={setEditMode}
          locked={!!locked}
          modifications={{ deleted: offer?.excluded?.includes(task.id) }}
          showPrice={task.new}
          isOffer={task.new && !!offer}
        />
      ),

    [tasks, editMode],
  );

  const editBundle = (bundle: any) => {
    setEditMode(bundle);
  };

  const tasksRef = useRef<any>();
  const addNewRef = useRef<any>();
  const animateToCreateMode = () => {
    if (tasksRef?.current) {
      tasksRef.current?.classList?.add("animated", "fadeOut", "fast-animation");
      addNewRef.current?.classList?.add(
        "animated",
        "fadeOut",
        "fast-animation",
      );
      setTimeout(() => {
        setEditMode({ mode: "insert", tasks: [] });
      }, 300);
    }
  };

  const renderEditMode = () => {
    return (
      <Box className="animated fast-animation fadeIn">
        <Box>
          {editMode ? (
            <EditMode
              data={editMode}
              type={editMode.tasks ? "bundle" : "task"}
              taskUpdated={taskUpdated}
              setEditMode={setEditMode}
              onToggleTask={onToggleTask}
              editMode={editMode}
              insertMode={editMode?.mode === "insert"}
              month={month}
              isMultiple={isMultiple}
              mode={mode}
            />
          ) : null}
        </Box>
      </Box>
    );
  };

  const tasksFilters: ButtonSingleAction[] = [
    {
      text: "All",
      action: () => setSelectedFilter("All"),
    },
    {
      text: "Recurring",
      action: () => setSelectedFilter("Recurring"),
    },
    {
      text: "One time",
      action: () => setSelectedFilter("One time"),
    },
  ];

  return editMode ? (
    renderEditMode()
  ) : (
    <Box overflow={"hidden"}>
      <Box className="animated fast-animation fadeIn" ref={tasksRef}>
        <div className="edit-tasks-container" style={{ padding: "0 20px" }}>
          <Flex
            p={4}
            justifyContent={"space-between"}
            alignContent="center"
            alignItems={"center"}
          >
            <Text fontWeight={500} fontSize={14} textAlign={"left"}>
              Influencer tasks {totalTasks() ? `(${totalTasks()})` : ""}
              {selectedFilter !== "All" && (
                <Tag ml={2} colorScheme={"blue"}>
                  {selectedFilter === "Recurring"
                    ? "Showing Recurring tasks only"
                    : "Showing One time only"}
                </Tag>
              )}
            </Text>
            <Box>
              <HumanzActionsMenu
                buttontype="button"
                leftIcon={
                  <Text
                    className="fas fa-filter"
                    style={{
                      color:
                        selectedFilter === "All"
                          ? "#808080"
                          : "var(--brand-500)",
                    }}
                  />
                }
                variant="small-action-white"
                borderRadius={20}
                actions={tasksFilters}
                buttontext={
                  <span
                    style={{
                      color:
                        selectedFilter === "All"
                          ? "#808080"
                          : "var(--brand-500)",
                    }}
                  >
                    {selectedFilter}
                  </span>
                }
              />
            </Box>
          </Flex>
          {tasks?.bundles
            ?.filter(
              (b: BundleAttributes) =>
                b.tasks.length &&
                (selectedFilter === "Recurring"
                  ? b.renews
                  : selectedFilter === "One time"
                  ? !b.renews
                  : true),
            )
            .map((bundle, i) => (
              <BundleAccordion
                key={i}
                bundleIndex={i}
                bundle={bundle}
                editBundle={editBundle}
                editMode={editMode}
                setEditMode={setEditMode}
                taskUpdated={taskUpdated}
                onToggleTask={onToggleTask}
                locked={!!locked}
                modifications={offer?.bundles_attributes?.find(
                  (b) => b?.bundle_id === bundle?.bundle_id,
                )}
                isOffer={!!offer}
              />
            ))}
        </div>
        <div className="edit-tasks-container" style={{ padding: "0 20px" }}>
          {tasks?.single
            .filter((t: any) => !t.subtask_of)
            .filter((t) =>
              selectedFilter === "Recurring"
                ? t.renews
                : selectedFilter === "One time"
                ? !t.renews
                : true,
            )
            .map(renderTaskRow)}
        </div>
      </Box>

      {!locked && !isMultiple && (
        <Box ref={addNewRef} className="add-task-button-wrapper">
          <Button
            variant="small-action-white"
            style={{
              maxHeight: "40px",
              fontSize: 14,
              animationDuration: "0.2s",
            }}
            leftIcon={<i className="fas fa-plus" />}
            onClick={animateToCreateMode}
          >
            Add tasks
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CurrentTasks;
