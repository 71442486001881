// General Imports
import React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Select from "react-select";
import uuidv4 from "uuid/v4";
import {
  checkLinkAvailable,
  uploadImageToBE,
} from "../../../../../actions/auctionsActions";
import { getUserProfile } from "../../../../../actions/brandReportsActions";
import { MultiReportActions } from "../../../../../actions/multiReportActions";
import { getUserSideDetails } from "../../../../../actions/userActions";
import {
  AuctionTargetAudience,
  EditedKPIS,
  MultiReport,
  MultiReportFilterParameters,
} from "../../../../../models/Api";
import { MessageDialog } from "../../../dialogs/MessageDialog";
import CheckBox from "../../../general_components/CheckBox";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SingleImageDropZone from "../../../general_components/SingleImageDropZone";
import {
  getDefaultShortLink,
  getUserCurrencies,
} from "../../../utilities/general";
import { orientation } from "../../../utilities/RotateImageHelper";
import CampaignsSection from "../../CampaignsSection";
import { getMultiReportJson, initAudience } from "./CreateReportObject";

// Consts

interface OwnProps {
  handleClose: any;
  user: any;
  summaryData: any;
  insightData: any;
  refreshData: any;
  checkLinkAvailable: any;
  id: any;
  uploadImageToBE: any;
  getUserProfile: any;
  getUserSideDetails: any;
  getMultiReport: any;
  createMultiReport: any;
  updateMultiReport: any;
  suggestMultiReportKpis: any;
  getMultiReportWithFilters: any;
  getMultiReportShareParams: any;
  setMultiReportShareParams: any;
  shareMultiReport: any;
  campaigns: any;
}

type Props = OwnProps & { navigate: NavigateFunction; params: any };

interface State {
  user: any;
  audienceFields: any;
  form_is_valid: any;
  fields: any;
  editMode: any;
  waitingForUpload: any;
  failedUploads: any;
  campaignId: any;
  generalError: any;
  profileSearch: any;
  searchLoading: any;
  errorFields: any;
  createMultiReport: any;
  id: any;
  saving: boolean;
  formDidChanged: boolean;
  updated: boolean;
  shortLinkAvaliable: any;
  shortLinkLoading: any;
  image_uploaded: string | null;
  activeTab: number;
  DEFAULT_LINK: any;
  shortLink: any;
  showSuggestDialog?: number[];
}

class CreateEditMultiReportInner extends React.Component<Props, State> {
  private DEFAULT_LINK: string;

  constructor(props: Props) {
    super(props);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.state = {
      audienceFields: initAudience(),
      form_is_valid: true,
      fields: getMultiReportJson(),
      editMode: false,
      waitingForUpload: [],
      failedUploads: [],
      campaignId: -1,
      generalError: "",
      profileSearch: "",
      searchLoading: false,
      errorFields: [],
      id: null,
      image_uploaded: null,
      updated: false,
      saving: false,
    };

    this.DEFAULT_LINK = getDefaultShortLink(props.user);
  }

  CURRENCIES = getUserCurrencies(
    this.props.user?.userDetails?.sidebar_data?.extra_currencies,
  );

  loadReport = () => {
    const newState = { ...this.state };
    newState.id = this.props.id;
    if (newState.id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newState.fields = this.props.editFields;
      newState.editMode = true;
      this.setState(newState);
    } else {
      newState.fields = getMultiReportJson();
      newState.editMode = false;
      this.setState(newState);
    }
  };

  componentDidMount() {
    this.loadReport();
  }

  /* ###### Functions ##### */

  SaveData = () => {
    this.setState({}, () => {
      const postData: MultiReport = this.state.fields;
      this.props.createMultiReport(postData, this.createCallback);
    });
  };

  editData = () => {
    this.setState({}, () => {
      const postData: MultiReport = this.state.fields;
      this.props.updateMultiReport(
        this.state.id,
        postData,
        this.createCallback,
      );
      this.props.navigate(`/multi_report/${this.props.id}`);
      this.props.refreshData();
    });
  };

  createCallback = (success: any, errors: any) => {
    if (success) {
      this.setState({ saving: false });
      if (!this.state.editMode) this.props.navigate("/insights");
    } else if (errors) {
      this.setState({
        generalError:
          "Something went wrong while saving the report. Please check the report and try again.",
        saving: false,
      });
    }
  };

  async addPicture(accepted: any, objWithImage: any, multiple: any) {
    for (let i = 0; i < accepted.length; i += 1) {
      const id = uuidv4();
      const file = accepted[i];
      if (multiple) {
        objWithImage.images_ids.push(id);
      } else {
        // eslint-disable-next-line no-param-reassign
        objWithImage.image_url = file.preview;
        // eslint-disable-next-line no-param-reassign
        objWithImage.image = id;
      }

      // eslint-disable-next-line no-await-in-loop
      await orientation(id, file, (oId: number, oFile: any) =>
        this.updateImages(oId, "standalone_report", oFile),
      );
    }
  }

  updateImages(id: number, objectType: string, file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadImageToBe(id, objectType, file.type, reader.result as any);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    this.setState({ updated: true });
  }

  uploadImageToBe(
    id: number,
    objectType: string,
    type: string,
    base64: string,
  ) {
    const image = {
      id,
      image: { data: base64.split(",")[1], mime_type: type },
    };
    const { waitingForUpload } = this.state;
    waitingForUpload.push(id);
    this.setState({ waitingForUpload, updated: true });
    this.props.uploadImageToBE(image, objectType, (success: any) =>
      this.afterUploaded(id, success),
    );
  }

  afterUploaded(id: number, success: boolean) {
    const { waitingForUpload, failedUploads } = this.state;
    if (!success) failedUploads.push(id);

    waitingForUpload.splice(waitingForUpload.indexOf(id), 1);
    this.setState({ waitingForUpload });
    this.setState({ updated: true });
  }

  addOrRemoveError(name: string, type?: string) {
    const { errorFields } = this.state;
    if (type === "add") {
      if (!errorFields.includes(name)) errorFields.push(name);
    } else if (errorFields.includes(name)) {
      errorFields.addOrRemove(name);
    }
    this.setState({ errorFields });
    this.setState({ updated: true });
  }

  linkIsGood() {
    if (!this.state.fields?.link) return true;
    const link = this.state.fields?.link;
    if (!link.long_link && !link.short_link) return true;
    if (link.long_link && link.short_link && this.state.shortLinkAvaliable)
      return true;
    return false;
  }

  changeShortLink = (e: any) => {
    let { value } = e.target;
    if (!value.startsWith(this.DEFAULT_LINK) || value === this.DEFAULT_LINK) {
      return;
    }
    value = value.replace(/\s/g, "-");
    value =
      this.DEFAULT_LINK +
      value.substring(this.DEFAULT_LINK.length).replace(/[/\\.,]/g, "");
    const shortLink = value === this.state.DEFAULT_LINK ? "" : value;
    // if (this.state.fields.link.short_link  === shortLink) {
    //     //its like former dont check
    //     return;
    // }
    this.setState({ shortLinkLoading: true, shortLink });
    this.props.checkLinkAvailable(
      this.state.editMode ? null : this.state.id,
      "ambassador_report",
      shortLink,
      (data: any) => {
        if (!data.available) {
          this.addOrRemoveError("short_link", "add");
        } else {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.fields.link.short_link = shortLink;
          this.addOrRemoveError("short_link");
        }
        this.setState({
          shortLinkAvaliable: data.available,
          shortLinkLoading: false,
          updated: true,
        });
      },
      () => {
        this.setState({ shortLinkLoading: false });
      },
    );

    this.validateForm();
  };

  validateForm = () => {
    const valid = (() => {
      if (
        !this.state.fields ||
        !this.linkIsGood() ||
        !this.state.fields.title ||
        this.state.saving ||
        this.state.errorFields.length > 0
      )
        return false;
      return true;
    })();
    this.setState({ form_is_valid: valid });
  };

  /* ###### End Functions ##### */

  onCampaignClick = (_: any, selectedItems: Array<any>) => {
    const newState = { ...this.state };
    newState.fields.auction_ids = selectedItems;
    this.setState(newState);
    if (selectedItems.length > 0) this.showSuggestedKpis(selectedItems);
  };

  showSuggestedKpis = (items: number[]) => {
    this.setState({ showSuggestDialog: items });
  };

  loadSuggestedKpis = () => {
    if (this.state.saving) return;
    this.setState({ saving: true });
    this.props.suggestMultiReportKpis(
      { auction_ids: this.state.showSuggestDialog },
      (data?: { target_audience: AuctionTargetAudience; kpi: EditedKPIS }) => {
        if (data && data.target_audience && data.kpi) {
          this.setState({
            fields: {
              ...this.state.fields,
              kpi: data.kpi,
              target_audience: data.target_audience,
            },
            showSuggestDialog: undefined,
            saving: false,
          });
        } else {
          this.setState({ showSuggestDialog: undefined, saving: false });
        }
      },
    );
  };

  /* ###### Renders ##### */

  renderSelectAuctions = () => {
    return (
      <div className={"select-multi-campaigns"}>
        <strong className="label-title">Select new campaigns</strong>
        <CampaignsSection
          selectedAuctions={this.state.fields.auction_ids}
          onCampaignClick={this.onCampaignClick}
          loading={false}
          fromMultiReport={true}
        />
      </div>
    );
  };

  renderSelectedAuctions = () => {
    const auctions = this.state.fields.auctions_data;
    const selected = this.state.fields.auction_ids;
    return (
      <div className={"select-multi-campaigns"} style={{ marginBottom: 20 }}>
        <strong className="label-title">Active Campaigns</strong>
        <div
          className="lists-rows"
          style={{ overflowY: "auto", maxHeight: 240 }}
        >
          {auctions.map((a: { id: number; image: string; title: string }) => {
            return (
              <div
                key={a.id}
                className="campaign-details-container campaign-details-selectable"
                onClick={() => {
                  selected.addOrRemove(a.id);
                  this.onCampaignClick(a, selected);
                }}
              >
                <div
                  className="checkbox-container"
                  style={{ height: 35, marginTop: -7 }}
                >
                  <CheckBox
                    checked={selected.includes(a.id)}
                    onChange={() => {}}
                  />
                </div>
                <img className="blurable" src={a.image} />
                <div className="campaign-details-rows">
                  <div
                    className="campaign-details-row"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong
                      className="campaign-name blurable"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                      }}
                    >
                      {a.title}
                    </strong>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const { fields } = this.state;
    if (!fields || typeof fields.title !== "string") return <></>;
    const reportName = fields.title;
    return (
      <div className="main-middle-container">
        <div className="brief-overview-section">
          <div
            className="campaigns-title"
            style={{ marginTop: 55, marginBottom: 15 }}
          >
            <span>
              {this.state.editMode ? "Edit" : "New"} Combined Report{" "}
              <strong>{fields.title}</strong>
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ width: "56%", float: "left", marginRight: "24%" }}>
              <div className="input-row"></div>
              <div className="input-row">
                <strong className="label-title">Report name</strong>
                <div className="blurable-large">
                  <input
                    className={"input-line "}
                    value={fields.title}
                    onChange={(e) => {
                      fields.title = e.target.value;
                      this.validateForm();
                    }}
                  />
                </div>
              </div>
            </div>
            <SingleImageDropZone
              upload_title={"Logo / image"}
              image_uploaded={fields.image_url}
              callback={(image_url: string) => {
                fields.image_url = image_url;
                this.setState({ image_uploaded: image_url }, () => {
                  if (this.state.editMode) {
                    this.editData();
                  } else {
                    this.SaveData();
                  }
                });
              }}
            />
          </div>

          {this.renderSelectedAuctions()}
          {this.renderSelectAuctions()}

          {/* Call to action link */}
          {fields.link && this.renderReportLink(fields)}

          {/* KPI'S */}
          {fields.kpi && this.renderKpis(fields)}

          {/* Target Audience */}
          <div className="create-section">
            <div className="campaigns-title" style={{ marginBottom: 15 }}>
              <span style={{ fontSize: 24 }}>Target Audience</span>
            </div>

            {this.state.audienceFields.map((x: any, i: number) =>
              this.renderAudienceOptins(x, i),
            )}
          </div>

          <div className="report-save-error">{this.state.generalError}</div>

          {/* Save buttons */}
          <div className="create-buttons-row" style={{ marginBottom: 40 }}>
            <button
              className={`btn btn-primry ${
                this.state.saving ? "disabled" : ""
              }`}
              onClick={() => {
                (this.props as any).changeTab("2");
              }}
            >
              BACK
            </button>
            {this.state.editMode ? (
              <button
                className={`btn btn-primry ${
                  this.state.form_is_valid ? "" : "disabled"
                }`}
                onClick={this.editData}
              >
                Save
              </button>
            ) : (
              <button
                disabled={this.state.saving}
                className={`btn btn-primry ${
                  (!this.linkIsGood() || this.state.saving || !reportName) &&
                  "disabled"
                }`}
                onClick={this.SaveData}
              >
                Done
              </button>
            )}
          </div>
        </div>
        {this.state.showSuggestDialog && this.renderSuggestedDialog()}
      </div>
    );
  }

  renderSuggestedDialog = () => {
    return (
      <MessageDialog
        handleClose={() => this.setState({ showSuggestDialog: undefined })}
        icon="far fa-bullseye-arrow"
      >
        <div style={{ padding: 10 }}>
          <div style={{ margin: 10, fontSize: 17 }}>
            <span>
              Do you want to load KPIS and Target Audience from the campaigns?
            </span>
          </div>
          <div
            style={{ paddingBottom: 5, paddingTop: 10, textAlign: "center" }}
          >
            <button
              onClick={() => this.setState({ showSuggestDialog: undefined })}
              disabled={this.state.saving}
              className="btn btn-primry cancel"
              style={{
                background: "white",
                color: "var(--main-baby-blue-color)",
                borderRadius: "40px",
                width: 100,
                marginTop: 15,
                marginRight: 30,
              }}
            >
              NO
            </button>
            <button
              onClick={this.loadSuggestedKpis}
              disabled={this.state.saving}
              className="btn btn-primry"
              style={{
                background: "var(--main-baby-blue-color)",
                color: "white",
                borderRadius: "40px",
                width: 100,
                marginTop: 15,
              }}
            >
              YES
            </button>
          </div>
        </div>
      </MessageDialog>
    );
  };

  renderReportLink = (fields: any) => {
    const shortLink = fields.link.short_link;
    return (
      <div className="create-section">
        <div className="campaigns-title">
          <span style={{ fontSize: 24 }}>
            Links{" "}
            <span className="optional-title">
              (Optional, both fields should be filled if used)
            </span>
          </span>
        </div>
        <div className="input-row">
          <strong className="label-title">short link</strong>
          <div className="blurable-large">
            {/* {shortLinkLoading ? <div className="loading-input-field"></div> : <input className="input-line" value={shortLink} placeholder={this.DEFAULT_LINK + "campaign-name"} type="text" onFocus={() => { this.setState({ shortLink: shortLink ? shortLink : this.DEFAULT_LINK }) }} */}
            <input
              className="input-line"
              value={shortLink}
              placeholder={`${this.DEFAULT_LINK}campaign-name`}
              type="text"
              onFocus={() => {
                if (!shortLink) {
                  // eslint-disable-next-line no-param-reassign
                  fields.link.short_link = this.DEFAULT_LINK;
                  this.setState({ formDidChanged: true });
                }
              }}
              onChange={(e) => {
                const { value } = e.target;
                if (value.startsWith(this.DEFAULT_LINK)) {
                  // eslint-disable-next-line react/no-direct-mutation-state
                  this.state.fields.link.short_link = value;
                  this.setState({});
                }
              }}
              onBlur={(e) => {
                this.changeShortLink(e);
                if (shortLink === this.DEFAULT_LINK) {
                  // eslint-disable-next-line no-param-reassign
                  fields.link.short_link = "";
                  this.setState({ formDidChanged: true });
                }
              }}
            />
            {this.state.errorFields.includes("short_link") && (
              <div>
                <i className="error-mark" />
                <span className="error-text">
                  This short link not avaliable try something else
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="input-row">
          <strong className="label-title">redirect to</strong>
          <div className="blurable-large">
            <input
              className="input-line"
              defaultValue={fields.link.long_link}
              onChange={(e) => {
                // eslint-disable-next-line no-param-reassign
                fields.link.long_link = e.target.value;
                this.setState({ formDidChanged: true });
              }}
              placeholder="Your website url"
            />
          </div>
        </div>
      </div>
    );
  };

  renderKpis = (fields: any) => {
    console.log(fields);
    return (
      <>
        <div className="campaigns-title">
          <span style={{ fontSize: 24 }}>KPI&#39;S</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Impressions</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={fields.kpi.impressions ? fields.kpi.impressions : ""}
                onChange={(e) => {
                  // eslint-disable-next-line no-param-reassign
                  fields.kpi.impressions = e.target.value;
                  this.setState({ updated: true, formDidChanged: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Engagements</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={fields.kpi.engagement ? fields.kpi.engagement : ""}
                onChange={(e) => {
                  // eslint-disable-next-line no-param-reassign
                  fields.kpi.engagement = e.target.value;
                  this.setState({ updated: true, formDidChanged: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Conversions</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={fields.kpi.conversions ? fields.kpi.conversions : ""}
                onChange={(e) => {
                  // eslint-disable-next-line no-param-reassign
                  fields.kpi.conversions = e.target.value;
                  this.setState({ updated: true, formDidChanged: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Total Budget</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={fields.kpi.budget}
                onChange={(e) => {
                  // eslint-disable-next-line no-param-reassign
                  fields.kpi.budget = e.target.value;
                  this.setState({ updated: true, formDidChanged: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Currency</strong>
            <div className="blurable-large">
              <div className="searchable-input" style={{ marginTop: -6 }}>
                <Select
                  // eslint-disable-next-line react/no-string-refs
                  ref="stateSelect"
                  options={this.CURRENCIES}
                  placeholder={""}
                  value={fields.kpi.currency}
                  simpleValue
                  clearable={false}
                  onChange={(value) => {
                    // eslint-disable-next-line no-param-reassign
                    fields.kpi.currency = value;
                    this.setState({ updated: true, formDidChanged: true });
                  }}
                  name="selected-state"
                  disabled={false}
                  searchable={true}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderAudienceOptins(item: any, index: number) {
    const targetAudience = this.state?.fields?.target_audience;
    return (
      <div key={item.name + index} style={{ marginTop: 20 }}>
        <div>
          <strong className="label-title upper-case">{item.label}</strong>
        </div>
        {item.isOpen && (
          <div>
            {item.type === "checkbox" && (
              <div className="checkbox-container">
                {targetAudience &&
                  item.values.map((x: any, vIndex: number) => {
                    const checked = item.isMulti
                      ? targetAudience[item.name].includes(x)
                      : targetAudience[item.name] === x;
                    let label = x;
                    if (item.labels) {
                      label = item.labels[vIndex];
                    }
                    return (
                      <CheckBox
                        style={{ marginTop: 5 }}
                        checked={checked}
                        onChange={() => {
                          if (
                            targetAudience[item.name].length === 1 &&
                            targetAudience[item.name].includes(x)
                          )
                            return;
                          targetAudience[item.name].addOrRemove(x);
                          this.setState({
                            updated: true,
                            formDidChanged: true,
                          });
                        }}
                        label={label}
                        key={x}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    uploadImageToBE: (image: string, type: string, succs_callBack: any) => {
      dispatch(uploadImageToBE(image, type, succs_callBack));
    },
    getUserProfile: (
      userName: string,
      social_network: string,
      callback: any,
    ) => {
      dispatch(getUserProfile(userName, social_network, callback));
    },
    getUserSideDetails: () => {
      dispatch(getUserSideDetails());
    },
    checkLinkAvailable: (
      reportId: number,
      shortLink: string,
      type: string,
      callback: any,
      errorCallbak: any,
    ) => {
      dispatch(
        checkLinkAvailable(reportId, type, shortLink, callback, errorCallbak),
      );
    },
    getMultiReport: (report_id: number, callback: any) => {
      dispatch(MultiReportActions.getMultiReport(report_id, callback));
    },
    createMultiReport: (data: MultiReport, callback: any) => {
      dispatch(MultiReportActions.createMultiReport(data, callback));
    },
    updateMultiReport: (
      report_id: number,
      data: MultiReport,
      callback: any,
    ) => {
      dispatch(MultiReportActions.updateMultiReport(report_id, data, callback));
    },
    suggestMultiReportKpis: (data: any, callback: any) => {
      dispatch(MultiReportActions.suggestMultiReportKpis(data, callback));
    },
    getMultiReportWithFilters: (
      report_id: number,
      filters: MultiReportFilterParameters,
      callback: any,
    ) => {
      dispatch(
        MultiReportActions.getMultiReportWithFilters(
          report_id,
          filters,
          "",
          callback,
        ),
      );
    },
  };
};

const CreateEditMultiReportConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(CreateEditMultiReportInner);

export const CreateEditMultiReport = React.forwardRef<
  CreateEditMultiReportInner,
  OwnProps
>((props, ref) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <CreateEditMultiReportConnected
      {...props}
      navigate={navigate}
      params={params}
      ref={ref}
    />
  );
});
