import { Box } from "@chakra-ui/react";
import { Droppable } from "react-beautiful-dnd";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import Task from "./Task";

const Tasks = (props: any) => {
  const { displayTasks, setTasks, tasks, disableCheckBox } = props;
  return (
    <>
      {props.dragDropEnabled ? (
        <Droppable droppableId="task-0">
          {(provided) => (
            <Box
              as="div"
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                maxHeight: props.maxHeight,
                overflowY: "auto",
                overflowX: "hidden",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#EBEBEB",
                }}
              ></div>
              {displayTasks.map((task: AuctionToDoItem, index: number) => (
                <Task
                  id={task.id}
                  key={task.id}
                  task={task}
                  setTasks={setTasks}
                  index={index}
                  displayTasks={displayTasks}
                  deleteTask={props.deleteTask}
                  tasks={tasks}
                  setCompleted={props.setCompleted}
                  campaignId={props.campaignId}
                  dragDropEnabled={props.dragDropEnabled}
                  handleEditTask={props.handleEditTask}
                  todoState={props.todoState}
                  disableCheckBox={disableCheckBox}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      ) : (
        <Box marginTop={"10px"}>
          {displayTasks.map((task: AuctionToDoItem, index: number) => (
            <Task
              id={task.id}
              key={task.id}
              task={task}
              setTasks={setTasks}
              index={index}
              displayTasks={displayTasks}
              deleteTask={props.deleteTask}
              tasks={tasks}
              setCompleted={props.setCompleted}
              campaignId={props.campaignId}
              dragDropEnabled={props.dragDropEnabled}
              handleEditTask={props.handleEditTask}
              todoState={props.todoState}
              disableCheckBox={disableCheckBox}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default Tasks;
