import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { MiniUsers } from "src/actions/enterpriseActions";
import { MetaTag } from "src/api/ExtendedTypes";
import {
  ExternalInfluencerInviteDetails,
  MinimalListSummary,
} from "src/api/types";
import { addOrRemove } from "src/utility";

const initialState = {
  lists: undefined as undefined | MinimalListSummary[],
  externalInvitations: undefined as
    | undefined
    | ExternalInfluencerInviteDetails[],
  lastSeen: undefined as string | undefined,
  filter: {
    search: "",
    tags: [] as MetaTag[],
  },
};

const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    setExternalInvitations(draftState, { payload: externalInvitationsData }) {
      draftState.externalInvitations = externalInvitationsData;
    },
    addToLists(
      draftState,
      { payload: listsToAdd }: PayloadAction<MinimalListSummary[]>,
    ) {
      draftState.lists = (draftState.lists ?? []).concat(listsToAdd);
    },
    removeFromList(draftState, { payload: listId }: PayloadAction<number>) {
      if (draftState.lists) {
        const indexToDelete = draftState.lists.findIndex(
          (x) => x.id === listId,
        );
        if (indexToDelete !== -1) {
          draftState.lists.splice(indexToDelete, 1);
        }
      }
    },
    toggleIsFavorite(draftState, { payload: id }: PayloadAction<number>) {
      if (draftState.lists) {
        const listIndex = draftState.lists.findIndex((x) => x.id === id);
        if (listIndex !== -1) {
          draftState.lists[listIndex].is_favorite =
            !draftState.lists[listIndex].is_favorite;
        }
      }
    },
    resetLists(draftState) {
      draftState.lists = undefined;
      draftState.lastSeen = undefined;
    },
    setLastSeen(draftState, { payload: newLastSeen }: PayloadAction<string>) {
      draftState.lastSeen = newLastSeen;
    },
    setFilterSearch(draftState, { payload: newSearch }: PayloadAction<string>) {
      draftState.filter.search = newSearch;
    },
    addOrRemoveFilterTag(draftState, { payload: tag }: PayloadAction<MetaTag>) {
      draftState.filter.tags = addOrRemove(
        current(draftState).filter.tags,
        [tag],
        (obj) => obj.id,
      );
    },
    resetFilter(draftState) {
      draftState.filter = initialState.filter;
    },
    updateListMiniUsers(
      draftState,
      {
        payload: { id, mini_users_data },
      }: PayloadAction<{
        id: bigint | number | string;
        mini_users_data: MiniUsers;
      }>,
    ) {
      if (draftState.lists) {
        const listIndex = draftState.lists.findIndex((x) => x.id === id);
        if (listIndex !== -1) {
          const miniUserData = mini_users_data;
          draftState.lists[listIndex].all_sub_users_allowed =
            miniUserData.all_mini_allowed;
          draftState.lists[listIndex].sub_users_allowed =
            miniUserData.allowed_mini_users;
        }
      }
    },
  },
});

export const { reducer: listsReducer, actions: listsActions } = listsSlice;
