// TODO - lint
/* eslint-disable no-unused-vars, func-style, func-style, vars-on-top, no-var, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, block-scoped-var, func-style, camelcase, camelcase, no-plusplus, camelcase, prefer-destructuring, camelcase, camelcase, camelcase, camelcase */
import numeral from "numeral";
import { semiMode } from "../../../actions/commonActionsConf";

export function getSearchFields(storageName) {
  if (storageName && window.sessionStorage.getItem(storageName)) {
    return JSON.parse(window.sessionStorage.getItem(storageName));
  }
  return [
    {
      name: "influencer_scope",
      label: "Scope",
      type: "checkbox",
      values: ["A list", "B list", "Micro"],
      selected: [],
      isOpen: false,
      isMulti: true,
      row: "influencer",
    },
    {
      name: "refine_by_audience",
      label: "Refine by Audience",
      type: "header",
      icon: "moi-group",
    },
    {
      name: "followers_gender",
      label: "Gender",
      type: "checkbox",
      values: ["Female", "Male"],
      selected: -1,
      isOpen: false,
      isMulti: false,
      row: "audience",
    },
    {
      name: "followers_ages",
      label: "Age",
      type: "checkbox",
      values: ["Children", "Gen Z", "Gen Y", "Gen X"],
      selected: [],
      isOpen: false,
      isMulti: true,
      row: "audience",
    },
    {
      name: "influencer_tags",
      label: "Tags",
      labels: [],
      type: "tags-choice",
      tags: [],
      selected: [],
      isOpen: false,
      row: "influencer",
      share: true,
    },
    {
      name: "api_connected",
      label: "API connected",
      type: "checkbox",
      isOpen: false,
      values: ["Include Only API connected"],
      selected: -1,
      row: "influencer",
    },
    {
      name: "followers_location",
      label: "Location",
      type: "tree",
      values: [
        {
          name: "Australia",
          selected: [],
          open: false,
          childs: [
            "Northern Territory",
            "Western Australia",
            "Australian Capital Territory",
            "Victoria",
            "New South Wales",
            "Queensland",
            "Tasmania",
            "South Australia",
          ],
        },
        {
          name: "Brazil",
          selected: [],
          open: false,
          childs: [
            "Estado De Rondônia",
            "Estado De Goiás",
            "Federal",
            "Rio De Janeiro",
            "Estado De Sergipe",
            "Estado De Mato Grosso",
            "Estado De São Paulo",
            "Estado Do Maranhão",
            "Estado De Tocantins",
            "Estado De Alagoas",
            "Estado Do Paraná",
            "Amazonas",
            "Estado Do Rio Grande Do Sul",
            "Estado De Pernambuco",
            "Estado Do Rio Grande Do Norte",
            "Mato Grosso Do Sul",
            "Estado Do Ceará",
            "Estado Do Piauí",
            "Bahia",
            "Estado Da Paraíba",
            "Estado Do Pará",
            "Estado De Minas Gerais",
            "Estado De Santa Catarina",
            "Estado Do Amapá",
            "Estado De Roraima",
            "Estado Do Acre",
            "Estado Do Espírito Santo",
          ],
        },
        {
          name: "India",
          selected: [],
          open: false,
          childs: [
            "Arunachal Pradesh",
            "Bengal",
            "Bihar",
            "Chandigarh",
            "Dadra and Nagar Haveli",
            "Goa",
            "Haryana",
            "Karnataka",
            "Kashmir",
            "Kerala",
            "Madhya Pradesh",
            "Maharashtra",
            "Meghalaya",
            "NCT",
            "Nagaland",
            "Rajasthan",
            "State of Andhra Pradesh",
            "State of Assam",
            "State of Chhattīsgarh",
            "State of Gujarāt",
            "State of Himāchal Pradesh",
            "State of Jharkhand",
            "State of Manipur",
            "State of Mizoram",
            "State of Odisha",
            "State of Punjab",
            "State of Sikkim",
            "State of Tripura",
            "Tamil Nadu",
            "Union Territory of Andaman and Nicobar Islands",
            "Union Territory of Puducherry",
            "Uttar Pradesh",
            "Uttarakhand",
          ],
        },
        {
          name: "Israel",
          selected: [],
          open: false,
          childs: ["Central", "North", "South", "Jerusalem", "West Bank"],
        },
        {
          name: "New Zealand",
          selected: [],
          open: false,
          childs: [
            "Auckland",
            "Otago",
            "Taranaki",
            "Northland",
            "Bay of Plenty",
            "Wellington",
            "Waikato",
            "Gisborne",
            "Canterbury",
            "Manawatu-Wanganui",
            "Southland",
            "West Coast Region",
            "Hawke's Bay",
            "Marlborough Region",
            "Nelson",
          ],
        },
        {
          name: "Nigeria",
          selected: [],
          open: false,
          childs: [
            "Ebonyi State",
            "Ekiti State",
            "Akwa Ibom State",
            "Kano State",
            "Kwara State",
            "Taraba State",
            "Imo State",
            "Borno State",
            "Edo",
            "Enugu State",
            "Oyo State",
            "Plateau State",
            "Cross River State",
            "Yobe State",
            "Lagos",
            "Delta",
            "Federal Capital Territory",
            "Zamfara State",
            "Kogi State",
            "Jigawa State",
            "Kaduna State",
            "Bauchi",
            "Adamawa",
            "Bayelsa State",
            "Abia State",
            "Niger State",
            "Gombe State",
            "Nasarawa State",
            "Anambra",
            "Katsina State",
            "Benue State",
            "Ogun State",
            "Ondo State",
            "Sokoto State",
            "Rivers State",
            "Osun State",
            "Kebbi State",
          ],
        },
        {
          name: "South Africa",
          selected: [],
          open: false,
          childs: [
            "Gauteng",
            "Province of Eastern Cape",
            "Limpopo",
            "Province of the Western Cape",
            "Northern Cape",
            "Orange Free State",
            "Mpumalanga",
            "Province of North West",
            "KwaZulu-Natal",
          ],
        },
        {
          name: "Turkey",
          selected: [],
          open: false,
          childs: [
            "Adana",
            "Adıyaman",
            "Afyonkarahisar",
            "Ağrı",
            "Aksaray",
            "Amasya",
            "Ankara",
            "Antalya",
            "Ardahan",
            "Artvin",
            "Aydın",
            "Balıkesir",
            "Bartın",
            "Batman",
            "Bayburt",
            "Bilecik",
            "Bingöl",
            "Bitlis",
            "Bolu",
            "Burdur",
            "Bursa",
            "Çanakkale",
            "Çankırı",
            "Çorum",
            "Denizli",
            "Diyarbakır",
            "Düzce",
            "Edirne",
            "Elazığ",
            "Erzincan",
            "Erzurum",
            "Eskişehir",
            "Gaziantep",
            "Giresun",
            "Gümüşhane",
            "Hakkâri",
            "Hatay",
            "Iğdır",
            "Isparta",
            "Istanbul",
            "İzmir",
            "Kahramanmaraş",
            "Karabük",
            "Karaman",
            "Kars",
            "Kastamonu",
            "Kayseri",
            "Kilis",
            "Kırıkkale",
            "Kırklareli",
            "Kırşehir",
            "Kocaeli",
            "Konya",
            "Kütahya",
            "Malatya",
            "Manisa",
            "Mardin",
            "Mersin",
            "Muğla",
            "Muş",
            "Nevşehir",
            "Niğde",
            "Ordu",
            "Osmaniye",
            "Rize",
            "Sakarya",
            "Samsun",
            "Şanlıurfa",
            "Siirt",
            "Sinop",
            "Sivas",
            "Şırnak",
            "Tekirdağ",
            "Tokat",
            "Trabzon",
            "Tunceli",
            "Uşak",
            "Van",
            "Yalova",
            "Yozgat",
            "Zonguldak",
          ],
        },
        { name: "United Kingdom", selected: [], open: false, childs: [] },
        {
          name: "United States",
          selected: [],
          open: false,
          childs: [
            "Alabama",
            "Alaska",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "Washington, D.C.",
            "West Virginia",
            "Wisconsin",
            "Wyoming",
          ],
        },
      ],
      isOpen: false,
      row: "audience",
    },
    {
      name: "followers_categories",
      label: "Categories",
      type: "checkbox",
      selected: [],
      isOpen: false,
      isMulti: true,
      labels: [
        "Art",
        "Beauty",
        "Bloggers",
        "Books",
        "Coaching",
        "Family",
        "Fashion",
        "Finance",
        "Fitness",
        "Food & Drink",
        "Funny",
        "Gaming",
        "Health",
        "Home&Garden",
        "Lifestyle",
        "Motherhood",
        "Music",
        "News&TV",
        "Nightlife",
        "Pets",
        "Photography",
        "Science",
        "Soldiers",
        "Sports",
        "Tech",
        "Travel",
        "Vegan",
      ],
      values: [
        "art",
        "beauty",
        "bloggers",
        "books",
        "coaching",
        "family",
        "fashion",
        "finance",
        "fitness",
        "food",
        "funny",
        "gaming",
        "health",
        "home",
        "lifestyle",
        "motherhood",
        "music",
        "news",
        "nightlife",
        "pets",
        "photography",
        "science",
        "soldiers",
        "sports",
        "tech",
        "travel",
        "vegan",
      ],
      row: "audience",
    },
    {
      name: "refine_by_influencer",
      label: "Refine by Influencer",
      type: "header",
      icon: "moi-influencer",
    },
    {
      name: "influencer_location",
      label: "Location",
      type: "tree",
      values: [
        {
          name: "Australia",
          selected: [],
          open: false,
          childs: [
            "Northern Territory",
            "Western Australia",
            "Australian Capital Territory",
            "Victoria",
            "New South Wales",
            "Queensland",
            "Tasmania",
            "South Australia",
          ],
        },
        {
          name: "Australia",
          selected: [],
          open: false,
          childs: [
            "Northern Territory",
            "Western Australia",
            "Australian Capital Territory",
            "Victoria",
            "New South Wales",
            "Queensland",
            "Tasmania",
            "South Australia",
          ],
        },
        {
          name: "Brazil",
          selected: [],
          open: false,
          labels: [
            "Acre",
            "Alagoas",
            "Amapá",
            "Amazonas",
            "Bahia",
            "Ceará",
            "Distrito Federal",
            "Espírito Santo",
            "Goiás",
            "Maranhão",
            "Mato Grosso",
            "Mato Grosso do Sul",
            "Minas Gerais",
            "Pará",
            "Paraíba",
            "Paraná",
            "Pernambuco",
            "Piauí",
            "Rio de Janeiro",
            "Rio Grande do Sul",
            "Rio Grande do Norte",
            "Rondônia",
            "Roraima",
            "Santa Catarina",
            "São Paulo",
            "Sergipe",
            "Tocantins",
          ],

          childs: [
            "Estado do Acre",
            "Estado de Alagoas",
            "Estado do Amapá",
            "Amazonas",
            "Bahia",
            "Estado do Ceará",
            "Federal",
            "Estado do Espírito Santo",
            "Estado de Goiás",
            "Estado do Maranhão",
            "Estado de Mato Grosso",
            "Mato Grosso do Sul",
            "Estado de Minas Gerais",
            "Estado do Pará",
            "Estado da Paraíba",
            "Estado do Paraná",
            "Estado de Pernambuco",
            "Estado do Piauí",
            "Rio de Janeiro",
            "Estado do Rio Grande do Sul",
            "Estado do Rio Grande do Norte",
            "Estado de Rondônia",
            "Estado de Roraima",
            "Estado de Santa Catarina",
            "Estado de São Paulo",
            "Estado de Sergipe",
            "Estado de Tocantins",
          ],
        },
        { name: "Canada", selected: [], open: false, childs: [] },
        {
          name: "India",
          selected: [],
          open: false,
          childs: [
            "Arunachal Pradesh",
            "Bengal",
            "Bihar",
            "Chandigarh",
            "Dadra and Nagar Haveli",
            "Goa",
            "Haryana",
            "Karnataka",
            "Kashmir",
            "Kerala",
            "Madhya Pradesh",
            "Maharashtra",
            "Meghalaya",
            "NCT",
            "Nagaland",
            "Rajasthan",
            "State of Andhra Pradesh",
            "State of Assam",
            "State of Chhattīsgarh",
            "State of Gujarāt",
            "State of Himāchal Pradesh",
            "State of Jharkhand",
            "State of Manipur",
            "State of Mizoram",
            "State of Odisha",
            "State of Punjab",
            "State of Sikkim",
            "State of Tripura",
            "Tamil Nadu",
            "Union Territory of Andaman and Nicobar Islands",
            "Union Territory of Puducherry",
            "Uttar Pradesh",
            "Uttarakhand",
          ],
        },
        {
          name: "Israel",
          selected: [],
          open: false,
          childs: ["Central", "North", "South", "Jerusalem", "West Bank"],
        },
        { name: "Kenya", selected: [], open: false, childs: [] },
        { name: "Lebanon", selected: [], open: false, childs: [] },
        {
          name: "New Zealand",
          selected: [],
          open: false,
          childs: [
            "Auckland",
            "Otago",
            "Taranaki",
            "Northland",
            "Bay of Plenty",
            "Wellington",
            "Waikato",
            "Gisborne",
            "Canterbury",
            "Manawatu-Wanganui",
            "Southland",
            "West Coast Region",
            "Hawke's Bay",
            "Marlborough Region",
            "Nelson",
          ],
        },
        {
          name: "Nigeria",
          selected: [],
          open: false,
          childs: [
            "Ebonyi State",
            "Ekiti State",
            "Akwa Ibom State",
            "Kano State",
            "Kwara State",
            "Taraba State",
            "Imo State",
            "Borno State",
            "Edo",
            "Enugu State",
            "Oyo State",
            "Plateau State",
            "Cross River State",
            "Yobe State",
            "Lagos",
            "Delta",
            "Federal Capital Territory",
            "Zamfara State",
            "Kogi State",
            "Jigawa State",
            "Kaduna State",
            "Bauchi",
            "Adamawa",
            "Bayelsa State",
            "Abia State",
            "Niger State",
            "Gombe State",
            "Nasarawa State",
            "Anambra",
            "Katsina State",
            "Benue State",
            "Ogun State",
            "Ondo State",
            "Sokoto State",
            "Rivers State",
            "Osun State",
            "Kebbi State",
          ],
        },
        { name: "Russia", selected: [], open: false, childs: [] },
        {
          name: "South Africa",
          selected: [],
          open: false,
          childs: [
            "Gauteng",
            "Province of Eastern Cape",
            "Limpopo",
            "Province of the Western Cape",
            "Northern Cape",
            "Orange Free State",
            "Mpumalanga",
            "Province of North West",
            "KwaZulu-Natal",
          ],
        },
        {
          name: "Turkey",
          selected: [],
          open: false,
          childs: [
            "Adana",
            "Adıyaman",
            "Afyonkarahisar",
            "Ağrı",
            "Aksaray",
            "Amasya",
            "Ankara",
            "Antalya",
            "Ardahan",
            "Artvin",
            "Aydın",
            "Balıkesir",
            "Bartın",
            "Batman",
            "Bayburt",
            "Bilecik",
            "Bingöl",
            "Bitlis",
            "Bolu",
            "Burdur",
            "Bursa",
            "Çanakkale",
            "Çankırı",
            "Çorum",
            "Denizli",
            "Diyarbakır",
            "Düzce",
            "Edirne",
            "Elazığ",
            "Erzincan",
            "Erzurum",
            "Eskişehir",
            "Gaziantep",
            "Giresun",
            "Gümüşhane",
            "Hakkâri",
            "Hatay",
            "Iğdır",
            "Isparta",
            "Istanbul",
            "İzmir",
            "Kahramanmaraş",
            "Karabük",
            "Karaman",
            "Kars",
            "Kastamonu",
            "Kayseri",
            "Kilis",
            "Kırıkkale",
            "Kırklareli",
            "Kırşehir",
            "Kocaeli",
            "Konya",
            "Kütahya",
            "Malatya",
            "Manisa",
            "Mardin",
            "Mersin",
            "Muğla",
            "Muş",
            "Nevşehir",
            "Niğde",
            "Ordu",
            "Osmaniye",
            "Rize",
            "Sakarya",
            "Samsun",
            "Şanlıurfa",
            "Siirt",
            "Sinop",
            "Sivas",
            "Şırnak",
            "Tekirdağ",
            "Tokat",
            "Trabzon",
            "Tunceli",
            "Uşak",
            "Van",
            "Yalova",
            "Yozgat",
            "Zonguldak",
          ],
        },
        { name: "United Kingdom", selected: [], open: false, childs: [] },
        {
          name: "United States",
          selected: [],
          open: false,
          childs: [
            "Alabama",
            "Alaska",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "Washington, D.C.",
            "West Virginia",
            "Wisconsin",
            "Wyoming",
          ],
        },
      ],
      isOpen: false,
      row: "influencer",
    },
    {
      name: "influencer_gender",
      label: "Gender",
      type: "checkbox",
      values: ["Female", "Male"],
      selected: -1,
      isOpen: false,
      isMulti: false,
      row: "influencer",
    },
    {
      name: "influencer_ages",
      label: "Age",
      type: "checkbox",
      tooltips: semiMode
        ? ["Others", "13-18", "19-35", "35+"]
        : ["Under 14", "14-18", "19-35", "35+"],
      values: ["Others", "Gen Z", "Gen Y", "Gen X"],
      selected: [],
      isOpen: false,
      isMulti: true,
      row: "influencer",
    },
    {
      name: "influencer_categories",
      label: "Categories",
      type: "checkbox",
      selected: [],
      isOpen: false,
      isMulti: true,
      labels: [
        "Art",
        "Beauty",
        "Bloggers",
        "Books",
        "Coaching",
        "Family",
        "Fashion",
        "Finance",
        "Fitness",
        "Food & Drink",
        "Funny",
        "Gaming",
        "Health",
        "Home&Garden",
        "Lifestyle",
        "Motherhood",
        "Music",
        "News&TV",
        "Nightlife",
        "Pets",
        "Photography",
        "Science",
        "Soldiers",
        "Sports",
        "Tech",
        "Travel",
        "Vegan",
      ],
      values: [
        "art",
        "beauty",
        "bloggers",
        "books",
        "coaching",
        "family",
        "fashion",
        "finance",
        "fitness",
        "food",
        "funny",
        "gaming",
        "health",
        "home",
        "lifestyle",
        "motherhood",
        "music",
        "news",
        "nightlife",
        "pets",
        "photography",
        "science",
        "soldiers",
        "sports",
        "tech",
        "travel",
        "vegan",
      ],
      row: "influencer",
    },
    {
      name: "influencer_followers",
      label: "Followers Amount",
      labels: ["Min Followers", "Max Followers"],
      type: "multiple_field",
      values: [
        [
          { value: -1, label: "All" },
          { value: 1000, label: "1k" },
          { value: 5000, label: "5k" },
          { value: 10000, label: "10k" },
          { value: 25000, label: "25k" },
          { value: 50000, label: "50k" },
          { value: 100000, label: "100k" },
        ],
        [
          { value: -1, label: "All" },
          { value: 10000, label: "10k" },
          { value: 50000, label: "50k" },
          { value: 100000, label: "100k" },
          { value: 250000, label: "250k" },
          { value: 1000 * 1000, label: "1m" },
        ],
      ],
      selected: [-1, -1],
      isOpen: false,
      row: "influencer",
      share: true,
    },
    {
      name: "influencer_avg_views",
      label: "Average Views",
      label_tooltip: "Average views is available only on YouTube or TikTok",
      restricted_social_networks: ["youtube", "tiktok"],
      labels: ["Min views", "Max Views"],
      type: "multiple_field",
      values: [
        [
          { value: -1, label: "All" },
          { value: 1000, label: "1k" },
          { value: 5000, label: "5k" },
          { value: 10000, label: "10k" },
          { value: 25000, label: "25k" },
          { value: 50000, label: "50k" },
          { value: 100000, label: "100k" },
        ],
        [
          { value: -1, label: "All" },
          { value: 10000, label: "10k" },
          { value: 50000, label: "50k" },
          { value: 100000, label: "100k" },
          { value: 250000, label: "250k" },
          { value: 1000 * 1000, label: "1m" },
        ],
      ],
      selected: [-1, -1],
      isOpen: false,
      row: "influencer",
      share: true,
    },
    {
      name: "pages_radio",
      label: "PAGE STYLE",
      type: "checkbox",
      isOpen: false,
      values: ["Include business pages"],
      selected: -1,
      row: "influencer",
    },
    {
      name: "refine_by_instagram",
      label: "Instagram Search",
      type: "header",
      icon: "moi-instagram-icon",
      row: "instagram",
    },
    {
      name: "influencer_username",
      label: "User Name",
      type: "text",
      value: "",
      row: "instagram",
      social: "instagram",
    },
    {
      name: "influencer_gpt",
      label: "Search by Ai",
      type: "text",
      value: "",
      row: "",
      placeholder: "Describe Your Influencer",
      isOpen: false,
      share: true,
    },
    {
      name: "influencer_post_location",
      label: "Posts From City",
      type: "city-search",
      value: "",
      row: "content",
      placeholder: "The City The Influencer Posts From",
      isOpen: false,
    },
    {
      name: "influencer_hashtags",
      label: "HashTags",
      type: "tags",
      tags: [],
      tag: "",
      value: "",
      row: "content",
      placeholder: "Fill and press enter to add a new hashtag",
      isOpen: false,
      share: true,
    },
    {
      name: "influencer_mentions",
      label: "Mentions",
      type: "tags",
      tags: [],
      tag: "",
      value: "",
      row: "content",
      placeholder: "Fill and press enter to add a new mention",
      isOpen: false,
      share: true,
    },
    {
      name: "influencer_caption",
      label: "Caption",
      type: "text",
      value: "",
      row: "content",
      placeholder: "Text in Caption",
      isOpen: false,
      share: true,
    },
    {
      name: "influencer_bio",
      label: "Bio",
      type: "text",
      value: "",
      row: "content",
      placeholder: "Text in bio",
      isOpen: false,
      share: true,
    },
    {
      name: "website",
      label: "WEBSITE",
      label_tooltip: "Website is available only on instagram",
      type: "text",
      restricted_social_networks: ["instagram"],
      isOpen: false,
      value: "",
      row: "content",
    },
    {
      name: "influencer_highlight",
      label: "Highlight",
      type: "text",
      value: "",
      row: "content",
      placeholder: "Text in highlight headlines",
      isOpen: false,
      share: true,
    },
    {
      name: "influencer_min_score",
      label: "Minimum Score",
      type: "slider",
      values: [0, 100],
      selected: [0],
      isMulti: true,
      isOpen: false,
      row: "influencer",
    },
    {
      name: "max_suspicious_followers_rate",
      label: "Max suspicious followers",
      type: "slider",
      values: [0, 100],
      selected: [100],
      isMulti: true,
      isOpen: false,
      row: semiMode ? "" : "influencer",
    },
    {
      name: "influencer_min_engagement",
      label: "Minimum Engagement",
      type: "checkbox",
      values: ["Low", "Good", "High", "Very High"],
      selected: -1,
      isOpen: false,
      isMulti: false,
      row: "influencer",
    },
  ];
}
export function updateSearchFields(currentSearchFields, name, selected) {
  if (name === "clearall") {
    var searchFields = currentSearchFields.map((f) => {
      if (f.type === "radio") {
        f.value = false;
      } else if (f.type === "tree") {
        f.values.forEach((x) => {
          x.selected = [];
          x.open = false;
        });
      } else if (f.type === "text") {
        f.value = "";
      } else if (f.name === "influencer_followers") {
        f.selected = [-1, -1];
      } else if (f.name === "influencer_avg_views") {
        f.selected = [-1, -1];
      } else if (f.type === "slider") {
        f.selected = [0];
      } else {
        f.selected = f.isMulti ? [] : -1;
      }
      return f;
    });
    return searchFields;
  }
  const searchField = currentSearchFields.find((x) => x.name === name);
  if (
    searchField.type === "text" ||
    searchField.type === "city-search" ||
    searchField.type === "select"
  ) {
    searchField.value = selected;
  } else if (searchField.type === "checkbox") {
    if (searchField.isMulti) {
      if (searchField.selected.indexOf(selected) > -1) {
        searchField.selected.splice(searchField.selected.indexOf(selected), 1);
      } else {
        searchField.selected.push(selected);
      }
    } else {
      searchField.selected = searchField.selected === selected ? -1 : selected;
    }
  } else if (searchField.type === "tags") {
    if (Array.isArray(selected)) {
      searchField.selected = selected;
    } else {
      searchField.tags.addOrRemove(selected);
    }
  } else if (searchField.type === "tags-choice") {
    if (Array.isArray(selected)) {
      searchField.selected = selected;
    } else {
      searchField.selected.addOrRemove(selected);
    }
  } else {
    searchField.selected = selected;
  }
  return searchFields;
}

export function createQuery(
  currentSearchFields,
  currentSocialDataFilter,
  dontAllowChildrenOnSearch,
  showTagger,
) {
  const ageGroupNumberToName = {
    0: "unknown",
    1: "child",
    2: "teen",
    3: "adult",
    4: "elderlies",
  };
  // [south,north,central,jerusalem,judea_samaria]
  const queryObject = {
    influencer_details: { social_accounts: currentSocialDataFilter },
    audience_details: {},
  };
  const usernamefield = currentSearchFields.find(
    (x) => x.name === "influencer_username",
  );
  if (usernamefield && usernamefield.value) {
    queryObject.influencer_details.username = usernamefield.value
      .replace("@", "")
      .replace("#", "");
    queryObject.influencer_details.social_accounts = [usernamefield.social];
    const influencer_countries = currentSearchFields.find(
      (x) => x.name === "influencer_location",
    );
    const locationSelected = influencer_countries.values.map((x) => {
      if (x.selected.length > 0) {
        return {
          country: x.name,
          districts: x.selected.includes("all") ? [] : x.selected,
        };
      }
      return undefined;
    });

    if (locationSelected && locationSelected.length > 0) {
      queryObject.influencer_details.countries = locationSelected;
    }
    return { query: queryObject, filterActive: false, defaultSearch: false };
  }

  const fieldsWithValue = currentSearchFields.filter(
    (x) =>
      (x.type !== "header" &&
        ((x.type === "text" && x.value) ||
          (x.type === "city-search" && x.value) ||
          (x.type === "tags" && x.tags) ||
          (x.type === "tags-choice" && x.tags) ||
          (x.type === "multiple_field" &&
            (x.selected[0] > -1 || x.selected[1] > -1)) ||
          (!x.isMulti && x.selected > -1) ||
          (x.isMulti && x.type !== "slider" && x.selected.length > 0) ||
          (x.type === "slider" && x.selected[0] > 0))) ||
      (x.type === "tree" && x.values.find((y) => y.selected.length > 0)) ||
      (x.type === "stars" && x.value),
  );

  for (let i = 0; i < fieldsWithValue.length; i++) {
    const fieldWithValue = fieldsWithValue[i];
    let gender;
    let ageGroupSelected;
    let locationSelected;
    let category_name;
    switch (fieldWithValue.name) {
      case "avg_review":
        if (fieldWithValue.selected) {
          queryObject.influencer_details.avg_review = numeral(
            fieldWithValue.selected,
          ).value();
        }
        break;
      case "influencer_followers":
        if (fieldWithValue.selected[0] !== -1) {
          queryObject.influencer_details.min_followers = numeral(
            fieldWithValue.selected[0],
          ).value();
        }
        if (fieldWithValue.selected[1] !== -1) {
          queryObject.influencer_details.max_followers = numeral(
            fieldWithValue.selected[1],
          ).value();
        }
        break;
      case "influencer_avg_views":
        if (fieldWithValue.selected[0] !== -1) {
          queryObject.influencer_details.min_views = numeral(
            fieldWithValue.selected[0],
          ).value();
        }
        if (fieldWithValue.selected[1] !== -1) {
          queryObject.influencer_details.max_views = numeral(
            fieldWithValue.selected[1],
          ).value();
        }
        break;
      case "influencer_min_score":
        queryObject.influencer_details.min_score = fieldWithValue.selected[0];
        break;
      case "influencer_min_engagement":
        queryObject.influencer_details.min_engagement_label =
          fieldWithValue.values[fieldWithValue.selected].toLowerCase();
        break;
      case "influencer_gender":
        gender = fieldWithValue.selected === 1 ? "male" : "female";
        // only for tagger an unknown option.
        if (showTagger) {
          gender = fieldWithValue.selected === 2 ? "unknown" : gender;
        }
        queryObject.influencer_details.gender = gender;
        break;
      case "influencer_ages":
        // no childs on influencers so add +1
        ageGroupSelected = fieldWithValue.selected.map((x) => {
          return ageGroupNumberToName[dontAllowChildrenOnSearch ? x + 1 : x];
        });
        queryObject.influencer_details.age_ranges = ageGroupSelected;
        break;
      case "influencer_location":
        locationSelected = fieldWithValue.values.filter(
          (x) => x.selected.length > 0,
        );
        queryObject.influencer_details.location = locationSelected.map((x) => {
          return {
            country: x.name,
            districts: x.selected.includes("all") ? [] : x.selected,
          };
        });
        break;
      // case "influencer_location":
      //     locationSelected = fieldWithValue.selected.map(x => { return locationNumberToName[x] });
      //     queryObject["influencer_details"]["counties"] = locationSelected
      //     break;
      case "max_suspicious_followers_rate":
        {
          const suspiciousRate = fieldWithValue.selected[0];
          if (suspiciousRate && suspiciousRate !== 100) {
            queryObject.audience_details.max_suspicious_followers_rate =
              suspiciousRate;
          }
        }
        break;
      case "followers_gender":
        gender = fieldWithValue.selected === 1 ? "male" : "female";
        queryObject.audience_details.preferred_gender = gender;
        break;
      case "followers_ages":
        ageGroupSelected = fieldWithValue.selected.map((x) => {
          return ageGroupNumberToName[dontAllowChildrenOnSearch ? x + 1 : x];
        });
        queryObject.audience_details.age_ranges = ageGroupSelected;
        break;
      case "followers_location":
        locationSelected = fieldWithValue.values.filter(
          (x) => x.selected.length > 0,
        );
        queryObject.audience_details.location = locationSelected.map((x) => {
          return {
            country: x.name,
            districts: x.selected.includes("all") ? [] : x.selected,
          };
        });
        break;
      case "followers_categories":
        category_name = fieldWithValue.selected.map((x) => {
          return fieldWithValue.values[x];
        });
        queryObject.audience_details.categories = category_name;
        break;
      case "influencer_categories":
        category_name = fieldWithValue.selected.map((x) => {
          return fieldWithValue.values[x];
        });
        queryObject.influencer_details.categories = category_name;
        break;
      case "influencer_username":
        if (fieldWithValue.value && fieldWithValue.value !== "") {
          queryObject.influencer_details.username = fieldWithValue.value;
        }
        break;
      case "api_connected":
        queryObject.influencer_details.api_connected = true;
        break;
      case "influencer_hashtags":
        if (fieldWithValue.tags && fieldWithValue.tags.length > 0) {
          queryObject.influencer_details.hashtag = fieldWithValue.tags;
        }
        break;
      case "influencer_mentions":
        if (fieldWithValue.tags && fieldWithValue.tags.length > 0) {
          queryObject.influencer_details.mention = fieldWithValue.tags;
        }
        break;
      case "influencer_caption":
        if (fieldWithValue.value && fieldWithValue.value !== "") {
          queryObject.influencer_details.caption = fieldWithValue.value;
        }
        break;
      case "influencer_post_location":
        if (fieldWithValue.value && fieldWithValue.value !== "") {
          queryObject.influencer_details.post_city = fieldWithValue.value;
        }
        break;
      case "influencer_bio":
        if (fieldWithValue.value && fieldWithValue.value !== "") {
          queryObject.influencer_details.bio_text = fieldWithValue.value;
        }
        break;
      case "influencer_highlight":
        if (fieldWithValue.value && fieldWithValue.value !== "") {
          queryObject.influencer_details.highlight = fieldWithValue.value;
        }
        break;
      case "pages_radio":
        queryObject.influencer_details.show_pages = true;
        break;
      case "website":
        if (fieldWithValue.value && fieldWithValue.value !== "") {
          queryObject.influencer_details.website = fieldWithValue.value;
        }
        break;
      case "registered_radio":
        queryObject.influencer_details.only_registered = true;
        break;
      case "sort_by_followers_radio":
        queryObject.influencer_details.order_by_followers =
          fieldWithValue.value;
        break;
      case "influencer_tags":
        queryObject.influencer_details.influencer_tags =
          fieldWithValue.selected;
        break;
      case "has_contact_details":
        queryObject.influencer_details.has_contact_details = true;
        break;
      case "influencer_gpt":
        if (fieldWithValue.value && fieldWithValue.value !== "") {
          queryObject.influencer_details.embedding_search = {
            search_text: fieldWithValue.value,
            search_type: "content",
          };
        }
        break;
      default:
        console.log(`unknown field ${fieldWithValue.name}`);
    }
  }

  const activeAudienceFilters = Object.keys(queryObject.audience_details);
  const defaultInfluencerFilters = [
    "social_accounts",
    "location",
    "only_registered",
  ];
  const activeInfluencerFilters = Object.keys(
    queryObject.influencer_details,
  )?.filter((object) => !defaultInfluencerFilters.includes(object));

  const activeCountries =
    queryObject?.influencer_details?.location?.length > 1 ||
    queryObject?.influencer_details?.location?.[0].districts?.length > 0;

  return {
    query: queryObject,
    filterActive:
      fieldsWithValue.length > 0 || !currentSocialDataFilter.includes("all"),
    defaultSearch:
      !activeAudienceFilters?.length &&
      !activeInfluencerFilters?.length &&
      !activeCountries,
  };
}
