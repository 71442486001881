import { Box } from "@chakra-ui/react";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import DisplayTaskDescription from "../Task/DisplayTask/DisplayTaskDescription";
import DisplayTaskTag from "../Task/DisplayTask/DisplayTaskTag";
import DisplayTaskTitle from "../Task/DisplayTask/DisplayTaskTitle";

const TemplateTask = (props: any) => {
  const EditToDoActions = [
    {
      action: () => {
        props.handleDeleteTemplateTask(props.task.id);
      },
      text: "Delete",
      icon: "fa-thin fa-trash-can",
    },
  ];

  return (
    <>
      <Box
        key={props.index}
        sx={{
          minHeight: "91px",
          display: "flex",
          background: "white",
          marginBottom: "2px",
          width: "100%",
          padding: "10px 0",
        }}
      >
        <Box
          sx={{
            width: "55%",
            // alignItems: "center",
            display: "flex",
            paddingLeft: "20px",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
            }}
          >
            <DisplayTaskTitle
              disable={props.isDisable}
              task={props.task}
              campaignId={props.campaignId}
              handleEditTask={props.handleEditTask}
            />
            <DisplayTaskDescription
              disable={props.isDisable}
              task={props.task}
              campaignId={props.campaignId}
              handleEditTask={props.handleEditTask}
            />
          </Box>
        </Box>

        <div
          style={{
            width: "1px",
            height: "70px",
            background: "#EBEBEB",
            margin: "5px",
          }}
        ></div>
        <Box flexDirection="column" width={"45%"} alignItems="left">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DisplayTaskTag
              task={props.task}
              campaignId={props.campaignId}
              handleEditTask={props.handleEditTask}
              disable={props.isDisable}
            />

            {!props.isDisable && (
              <HumanzActionsMenu
                buttontype="dots"
                actions={EditToDoActions}
                buttontext="hi"
              />
            )}
          </Box>
        </Box>
      </Box>
      <div
        style={{
          width: "450px",
          height: "1px",
          background: "#EBEBEB",
        }}
      ></div>
    </>
  );
};

export default TemplateTask;
