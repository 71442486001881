import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";
import {
  addShareNotes,
  getListShareNotes,
} from "../../../actions/premiumActions";
import {
  addShareNotesFromShare,
  getShareNotes,
} from "../../../actions/shareActions";
import { Message } from "../../../models/Api";

const NOTES_USERNAME = "notes_username";

type ObjectType = "campaign" | "list";

interface OwnProps {
  objectType: ObjectType;
  handleClose: any;
  name: string;
  guid: string;
  notesType: string;
  isFromShare: boolean;
  itemId: number;
  sideNotesData?: any;
  containerClassName: string;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{
  loading: boolean;
  name?: string | null;
  message?: string;
  messages?: Message[];
  sendingMessage?: string | null;
  itemId: number;
}>;

class SideNotesInner extends Component<Props, State> {
  messagesBox: any;

  pollingInterval: any;

  pollingIntervalInMilSec = 5000;

  pollingActivated = false;

  debugEnabled = true;

  readonly state: State = {
    loading: false,
    itemId: 1,
  };

  init() {
    this.setState({ loading: true });
    if (this.debugEnabled)
      console.debug("SideNotesData", this.props.sideNotesData);
    this.setState(
      {
        name: this.getNameFromLocalStorage(),
        itemId: this.props.sideNotesData
          ? Number(this.props.sideNotesData.id)
          : this.props.itemId,
      },
      () => {
        this.getMessages();
        this.pollingInterval = this.pollingActivated
          ? setInterval(this.getMessages, this.pollingIntervalInMilSec)
          : null;
      },
    );
  }

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.itemId !== prevProps.itemId) {
      this.init();
    }
  }

  componentWillUnmount(): void {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  getNameFromLocalStorage = () => {
    return localStorage.getItem(NOTES_USERNAME) || this.props.name || null;
  };

  saveNameInLocalStorage = (name: string) => {
    localStorage.setItem(NOTES_USERNAME, name);
    this.setState({ name });
  };

  getMessages = () => {
    if (this.debugEnabled) console.debug("Getting messages...");
    if (this.props.isFromShare) {
      this.props.getShareNotes(
        this.props.objectType,
        this.props.notesType,
        this.state.itemId,
        (messages: any) => this.getMessagesCallback(messages),
      );
    } else {
      this.props.getListShareNotes(
        this.props.guid,
        this.props.notesType,
        this.state.itemId,
        (messages: any) => this.getMessagesCallback(messages),
      );
    }
  };

  getMessagesCallback = (messages: any) => {
    if (messages && messages.notes) {
      this.setState({
        messages: messages.notes,
        sendingMessage: null,
        loading: false,
      });
      if (this.messagesBox) this.messagesBox.scrollTop = 0;
    }
  };

  addNewMessage = () => {
    if (this.state.name && this.state.message) {
      if (this.debugEnabled)
        console.debug("Sending message...", {
          message_guid: uuidv4(),
          message: this.state.message,
          message_content_type: "text",
          from_name: this.state.name,
        });

      this.setState({ sendingMessage: this.state.message });

      const message = {
        message_guid: uuidv4(),
        message: this.state.message,
        message_content_type: "text",
        from_name: this.state.name,
      };

      const callback = () => {
        this.setState({ message: "" });
        this.getMessages();
      };

      if (this.props.isFromShare) {
        this.props.addShareNotesFromShare(
          this.props.objectType,
          this.props.notesType,
          this.state.itemId,
          message,
          callback,
        );
      } else {
        this.props.addShareNotes(
          this.props.guid,
          this.props.notesType,
          this.state.itemId,
          message,
          callback,
        );
      }
    } else {
      alert("You have to enter your name and a message");
    }
  };

  render() {
    return (
      <div
        style={{ width: 750, marginLeft: "calc(100% - 970px)" }}
        className={
          this.props.containerClassName ||
          "import-from-list-container animated fadeInRight faster"
        }
        id="side_notes"
      >
        {this.state.loading ? (
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        ) : (
          this.renderNotes()
        )}
      </div>
    );
  }

  renderNotes() {
    return (
      <div>
        <div
          className="notes-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ fontSize: 22, marginBottom: 8 }}>
            Notes{" "}
            {this.props.sideNotesData
              ? `of ${this.props.sideNotesData.name}`
              : ""}
          </div>
          <div
            className="import-list-close-button"
            style={{ position: "relative", margin: 0 }}
            onClick={() => this.props.handleClose()}
          >
            <span className="moi-ximage" />
          </div>
        </div>
        <div className="inner-sidenotes-content flex">
          <div className="sidenotes-messages" ref={this.messagesBox}>
            {this.state.sendingMessage && (
              <div className={"single-sidenote-message mine loading-message"}>
                <div className="message-text" dir="auto">
                  {this.state.sendingMessage}
                </div>
                <div className="meta">
                  <div className="message-time">
                    {moment(Date.now()).format(
                      `HH:mm:ss ${window.time.short_date}`,
                    )}
                  </div>
                  <CircularProgress size={16} />
                </div>
              </div>
            )}
            {this.state.messages &&
              this.state.messages.map((message: Message, index: number) => {
                return (
                  <div key={message.id || index}>
                    <div
                      className={`single-sidenote-message ${
                        message.mine ? "mine" : "not_mine"
                      }`}
                    >
                      <div className="sender-name">
                        <strong>
                          {(message as any).from_name || "Advertiser"}
                        </strong>
                      </div>
                      <div className="message-text" dir="auto">
                        {message.message}
                      </div>
                    </div>
                    <div className="meta">
                      <div className="message-time">
                        {moment(message.creation_time).format(
                          `HH:mm:ss ${window.time.short_date}`,
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="write-message">
            <div className={"flex"}>
              <div className="user-circle">
                <i className="fa fa-user-circle-o" />
              </div>
              <input
                placeholder={"Your name"}
                value={this.state.name as any}
                onChange={(e) => this.saveNameInLocalStorage(e.target.value)}
                style={{ width: 200, marginBottom: 7 }}
                type="text"
                name={"name"}
                id={"name"}
              />
            </div>
            <div className={"flex"}>
              <textarea
                placeholder={"Your message"}
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
                style={{ marginRight: 3, flex: 1 }}
                name={"message"}
                id={"message"}
              />
              <button
                disabled={this.state.sendingMessage as any}
                onClick={() => this.addNewMessage()}
                className={"btn btn-primary"}
                type={"submit"}
              >
                {this.state.sendingMessage ? (
                  <CircularProgress size={16} />
                ) : (
                  <i className="fa fa-send-o" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => ({
  getListShareNotes: (
    share_guid: string,
    item_type: string,
    item_id: number,
    callback: any,
  ) => dispatch(getListShareNotes(share_guid, item_type, item_id, callback)),
  getShareNotes: (
    object_type: ObjectType,
    item_type: string,
    item_id: number,
    callback: any,
  ) => dispatch(getShareNotes(object_type, item_type, item_id, callback)),
  addShareNotes: (
    share_guid: string,
    item_type: string,
    item_id: number,
    message: any,
    callback: any,
  ) =>
    dispatch(addShareNotes(share_guid, item_type, item_id, message, callback)),
  addShareNotesFromShare: (
    object_type: ObjectType,
    item_type: string,
    item_id: number,
    message: any,
    callback: any,
  ) =>
    dispatch(
      addShareNotesFromShare(
        object_type,
        item_type,
        item_id,
        message,
        callback,
      ),
    ),
});

export const SideNotes = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideNotesInner);
