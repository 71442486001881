/**
 * Notice: You should add more locales when adding a new language
 */
import moment from "moment-timezone";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import gbLocale from "moment/dist/locale/en-gb";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ilLocale from "moment/dist/locale/en-il";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import tkLocale from "moment/dist/locale/tk";

const locales = [ilLocale, tkLocale, gbLocale, "en-us"];

/**
 * Language call function
 * @param fallback_string
 * @param property
 * @returns {string}
 */

export {};

declare global {
  interface Window {
    translate(fallback_string: string, property: string): string;
    geoLocations(): void;
    setLocations(data: any): void;
    lang: any;
    time: any;
    open_in_new_tab(url: string, focus?: boolean): void;
    SERVER_CONF: {
      BACKEND_URI: string;
      YAAD_MASOF_NUMBER: string;
      INFLU_PAGE_URI: string;
      NODE_ENV: string;
    };
    pixel_enabled?: boolean;
  }

  interface Array<T> {
    addOrRemove(...args: any[]): Array<T>;
  }

  interface String {
    format: (...args: any[]) => string;
    capitalize: (...args: any[]) => string;
  }
}

// Init moment locale
try {
  const tz = moment.tz.guess();
  let locale = "en-gb";
  if (tz.includes("America/")) {
    locale = "en-us";
  }
  moment.locale(locale, locales);
  // eslint-disable-next-line no-underscore-dangle
  const ldf = (moment().tz(tz).localeData() as any)._longDateFormat;
  window.time = {
    short_date: ldf.L,
    hours_minutes: "h:mm",
    hours_seconds: "h:mm:ss",
    date_with_time: ldf.LLL,
    date_with_hour: `${ldf.L} hh:mm a`,
  };
} catch {
  window.time = {
    short_date: "DD/MM/YYYY",
    hours_minutes: "h:mm",
    hours_seconds: "h:mm:ss",
    date_with_time: "DD/MM/YYYY hh:mm:ss a",
    date_with_hour: "DD/MM/YYYY hh:mm a",
  };
}

// Function to generate an ISO string for the 1st day of a month
export const getFirstDayOfMonthISOString = (year: any, month: any) => {
  return `${year}-${month < 10 ? `0${month}` : month}-01T22:00:00.000Z`;
};

export const get12Months = () => {
  const currentDate = new Date();
  // Generate options for the previous 12 months
  const monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() - i + 1;

    // Adjust year if month goes negative
    if (month < 0) {
      month += 12;
      year -= 1;
    }

    const monthDate = new Date(year, month, 1);
    const monthName = monthDate.toLocaleString("default", { month: "long" });
    const yearName = year;
    const isoString = getFirstDayOfMonthISOString(year, month + 1);
    monthOptions.unshift({
      name: `${monthName} ${yearName}`,
      value: isoString,
    });
  }
  return monthOptions;
};

window.translate = (fallback_string: string, property: string) => {
  if (property === undefined) return fallback_string;
  if (window.lang === undefined) return fallback_string;
  if (window.time === undefined) return fallback_string;
  // eslint-disable-next-line no-prototype-builtins
  if (window.lang.hasOwnProperty(property)) return window.lang[property];
  return fallback_string;
};

window.setLocations = function (data: any) {
  window.geoLocations = data;
};

window.open_in_new_tab = function (url: any, focus: any) {
  const win = window.open(url, "_blank");
  if (win && focus) {
    win.focus();
  }
};

// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
  // eslint-disable-next-line prefer-rest-params
  const args = [].slice.call(arguments);
  return this.replace(/(\{\d+\})/g, function (a) {
    return args[+a.substr(1, a.length - 2) || 0];
  });
};

// eslint-disable-next-line no-extend-native
Array.prototype.addOrRemove = function () {
  // eslint-disable-next-line prefer-rest-params
  const args = [].slice.call(arguments);
  for (let i = 0; i < args.length; i += 1) {
    if (this.includes(args[i])) {
      this.splice(this.indexOf(args[i]), 1);
    } else {
      this.push(args[i]);
    }
  }
  return this;
};

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

document.addEventListener("wheel", function () {
  if ((document.activeElement as HTMLInputElement)?.type === "number") {
    (document.activeElement as HTMLInputElement).blur();
  }
});

if (typeof console === "undefined") {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-global-assign
  console = {};
} else if (typeof console.log !== "undefined") {
  try {
    console.log(
      // eslint-disable-next-line no-multi-str
      `%c\
                                                  ┌╗▒╓
                                                  ╙╝╝╙
      ▄▄─     ╓▄╓  ▄▄      ╓▄╕  ▄▌╓       ▄▄       ╔╦       ▄▄      ▄▄  ▄▓▓▓▓▓▓▓▓▄
      ▓█▌     ▓█▌  ██▒     ╫█▌  ▓██▌    ╔███░     ╠╬╬╠     ┌███▄    ██▒ └╙╙╙╙╠██▓└
      ▓█▌╓╓╓╓╓▓█▌  ██▒     ╫█▌  ▓█▓██▄┌▓█▓██░    ╠╬╙╙╬╬    ┌██▀██▄  ██▒     ▄██╨
      ▓██▓▓▓▓▓██▌  ██▒     ╫█▌  ▓█▌└▓███╨ ██░  ┌╬╬┘  └╢╬┐  ┌██ └▀█▓╖██▒   ╓▓█▀
      ▓█▌     ▓█▌  ▓█▌    ╓▓█▌  ▓█▌  ╙╙   ██░ ╓╬╬┘    └╬╬╕ ┌██   ╙▓███▒  ▀█▓─
      ▓█▒     ╫█▌   ▀▓██▓██▓╜   ▓█▒       ▓█─ ╬╬└      └╬╬─ ██     ╙██░ ▓████████▓

    `,
      "color: #249ff0",
    );
  } catch (e: any) {
    console.log("============= HUMANZ LTD =============");
  }
}

if (window.SERVER_CONF?.NODE_ENV === "production") {
  try {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
    console.info = () => {};
  } catch (e) {
    /* empty */
  }
}
