import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAgents } from "src/actions/userActions";
import NoImage from "../../../images/svgs/placeholder.svg";
import { FormFooterButtonGroup } from "../../../ui/FormFooterButtonGroup";
import { InfiniteLoader } from "../general_components/InfiniteLoader";
import { AgentSelect } from "./AgentSelect";

interface OwnProps {
  onClose: any;
  onApprove: any;
  agentsData: InfluencerAgentEntity[];
}

interface InfluencerAgent {
  agent_id: string;
  agent_name: string;
}

interface InfluencerAgentEntity {
  influencer_id: string;
  name: string;
  picture_url?: string;
  agents: InfluencerAgent[];
  prev_agent_id?: string;
}

interface SelectedInfluencerAgent {
  influencer: string;
  agent: string;
}

export const AgentSuggestionsDialog = (props: OwnProps) => {
  const { agentsData } = { ...props };
  const [selectedAgents, setSelectedAgents] = useState<
    SelectedInfluencerAgent[]
  >([]);
  const [loading, setLoading] = useState(true as boolean);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAgents(null, () => {
        setLoading(false);
      }),
    );
  }, [dispatch]);

  const prepareSelectedAgents = (influencers: InfluencerAgentEntity[]) => {
    const tmpAgents: SelectedInfluencerAgent[] = [];
    influencers?.forEach((i: InfluencerAgentEntity) => {
      if (i && i?.agents?.length) {
        tmpAgents.push({
          influencer: i.influencer_id,
          agent: i?.prev_agent_id || i?.agents[0]?.agent_id,
        });
      }
    });
    setSelectedAgents(tmpAgents);
  };

  const handleApprove = () => {
    props.onApprove(selectedAgents);
  };

  const updateSelectedAgents = (influencerId: string, agentId: string) => {
    const selectedAgentsTmp = [...selectedAgents];
    selectedAgentsTmp.forEach(
      (selectedAgent: SelectedInfluencerAgent, i: number) => {
        if (selectedAgent.influencer === influencerId) {
          selectedAgentsTmp[i].agent = agentId;
        }
      },
    );
    setSelectedAgents(selectedAgentsTmp);
  };

  useEffect(() => {
    prepareSelectedAgents(agentsData);
  }, [agentsData]);

  const renderAgentsOptions = (influencerId: string, agentId: number) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 200, marginLeft: 5, marginRight: 10 }}>
          <AgentSelect
            value={agentId}
            editDisabled={true}
            onChange={(value: any) => updateSelectedAgents(influencerId, value)}
          />
        </div>
      </div>
    );
  };
  const renderInfluencerLine = (influencer: InfluencerAgentEntity) => {
    const suggestedId = Number(influencer.agents[0]?.agent_id);
    const prevId = influencer.prev_agent_id
      ? Number(influencer.prev_agent_id)
      : null;
    const selectedAgent = prevId || suggestedId;
    const warning = prevId && prevId !== suggestedId;
    return (
      <Box>
        <Flex justifyItems={"center"} mb={1} justifyContent={"center"}>
          <Box mr={2} alignSelf={"center"}>
            <img
              style={{ width: 35, height: 35 }}
              className="avatar"
              src={
                influencer.picture_url
                  ? influencer.picture_url.replace(".jpg", "_small.jpg")
                  : NoImage
              }
            />
          </Box>

          <Box flex={2} alignSelf={"center"}>
            {influencer.name}
          </Box>
          <Box flex={3} alignSelf={"center"}>
            {renderAgentsOptions(influencer.influencer_id, selectedAgent)}
          </Box>
        </Flex>
        {warning ? (
          <Text
            color={"tomato"}
            display={"flex"}
            pl={"62px"}
            flexWrap={"wrap"}
            mb={5}
            mt={-2}
          >
            <Text>Our records found a different agent: </Text>
            <Text textDecorationLine={"underline"} ml={1} fontWeight={700}>
              {influencer.agents[0].agent_name}.
            </Text>
            {/* <Text>Please check again if you selected the right agent.</Text> */}
          </Text>
        ) : null}
      </Box>
    );
  };
  return (
    <Modal size="xl" isOpen={true} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select agents</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mt={1}>
            <Text fontSize="lg">
              Our records shows that the following influencers used to work with
              an agent
            </Text>
          </Box>
          <Box py="3">
            <Text>
              Please confirm, change or remove the selected agents for these
              influencers.
            </Text>
          </Box>
          {loading ? (
            <InfiniteLoader />
          ) : (
            <>
              {selectedAgents.length &&
                agentsData.map((influ: InfluencerAgentEntity, i: number) => {
                  return <Box key={i}>{renderInfluencerLine(influ)}</Box>;
                })}
              <FormFooterButtonGroup py="3">
                <Button
                  autoFocus
                  variant="solid"
                  type="button"
                  onClick={() => handleApprove()}
                  tabIndex={1}
                >
                  Confirm and invite
                </Button>
                <Button
                  variant="ghost"
                  type="button"
                  onClick={() => props.onClose()}
                  tabIndex={1}
                >
                  Cancel
                </Button>
              </FormFooterButtonGroup>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
