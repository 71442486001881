import {
  getMoiUser_feature_feedback,
  postMoiUser_feature_feedback,
} from "../services";

export {
  useDeleteMoiMeta_tagsTag_typeTag_id as useDeleteMetaTags,
  usePostMoiMeta_tags as useEditMetaTags,
} from "../hooks";

export const addFeatureFeedback = postMoiUser_feature_feedback;

export const checkIfUserFeedback = getMoiUser_feature_feedback;
