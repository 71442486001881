import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { getExternalInvitations } from "src/api/actions/listActions";
import { ExternalInfluencerInviteDetails } from "src/api/types";
import { CopyToClipboard } from "src/ui/CopyToClipboard";
import DataTable from "../general_components/DataTable";
import {
  getSocialNetworkUsernameUrl,
  maxWidthTextStyle,
} from "../utilities/general";

type Filters = {
  [P in keyof ExternalInfluencerInviteDetails]?: string | undefined;
};

const FilterData = (
  data: ExternalInfluencerInviteDetails[],
  filters: Filters,
) => {
  let newData = data;
  if (filters.connected_username) {
    newData = newData.filter((x) =>
      x.connected_username
        ?.toLowerCase()
        .includes(filters?.connected_username?.toLowerCase()?.trim()),
    );
  }
  if (filters.provider) {
    newData = newData.filter((x) =>
      x.provider
        ?.toLowerCase()
        .includes(filters?.provider?.toLowerCase()?.trim()),
    );
  }
  if (filters.invited_username) {
    newData = newData.filter((x) =>
      x.invited_username
        ?.toLowerCase()
        ?.includes(filters?.invited_username?.toLowerCase()?.trim()),
    );
  }
  if (filters.shared_uuid) {
    newData = newData.filter((x) =>
      x.shared_uuid
        ?.toLowerCase()
        ?.includes(
          filters.shared_uuid
            ?.toLowerCase()
            ?.trim()
            ?.replace(`${window.location?.origin}/connect-influencer/`, "")
            ?.replace("/", ""),
        ),
    );
  }

  return newData;
};

export const ExternalInvitationPage = () => {
  const [data, setData] = useState([] as ExternalInfluencerInviteDetails[]);
  const [filters, setFilters] = useState({} as Filters);

  useEffect(() => {
    getExternalInvitations()
      .then((res) => setData(res.data || []))
      .catch(() => {
        setData([]);
      });
  }, []);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<(typeof data)[0]>();
    return [
      columnHelper.accessor("invited_username", {
        cell: (info) => (
          <div
            style={{ ...maxWidthTextStyle, width: 150, maxWidth: 150 }}
            title={info.getValue()}
          >
            {info.getValue()}
          </div>
        ),
        header: () => "Username requested on",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, invited_username: value });
          },
        },
      }),
      columnHelper.accessor("provider", {
        cell: (info) => (
          <div
            style={{ ...maxWidthTextStyle, width: 100, maxWidth: 100 }}
            title={info.getValue()?.toUpperCase() || ""}
          >
            {info.getValue()?.toUpperCase() || ""}
          </div>
        ),
        header: () => "Social Network",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, provider: value });
          },
        },
      }),
      columnHelper.accessor("shared_uuid", {
        cell: (info) => {
          const link = `${
            window.location.origin
          }/connect-influencer/${info.getValue()}`;
          return (
            <div
              style={{
                ...maxWidthTextStyle,
                maxWidth: 500,
              }}
            >
              <CopyToClipboard value={link} />
            </div>
          );
        },
        header: "Link",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, shared_uuid: value });
          },
        },
      }),
      columnHelper.accessor("connected_username", {
        cell: (info) => (
          <div
            style={{ ...maxWidthTextStyle, width: 150, maxWidth: 150 }}
            title={info.getValue()}
          >
            <a
              href={getSocialNetworkUsernameUrl(
                info.row.original.provider,
                info.getValue(),
              )}
              target="_blank"
              rel="noreferrer"
            >
              {info.getValue()}
            </a>
          </div>
        ),
        header: "Connected username",
        meta: {
          onTextFilterChange: (value: string) => {
            setFilters({ ...filters, connected_username: value });
          },
        },
      }),
    ];
  }, [filters]);

  const filteredData = useMemo(
    () => FilterData(data, filters),
    [data, filters],
  );

  return (
    <div className={"main-lists-container "} style={{ paddingTop: 0 }}>
      <div
        className="list-title blurable-large"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Your Links
      </div>
      <DataTable
        showInputFilter
        containerClassName="invoice-table"
        containerProps={{
          dir: "auto",
          bg: "white",
          maxWidth: "100%",
          boxShadow: "0 5px 10px rgba(0, 0, 0, 0.15)",
          width: "fit-content",
          borderRadius: 10,
        }}
        thStyle={{ padding: "10px 7px" }}
        data={filteredData}
        columns={columns}
        pageSizeAmount={10000}
      />
    </div>
  );
};
