// TODO - lint
/* eslint-disable react/no-deprecated, no-unused-vars, no-unused-vars, no-return-assign, no-underscore-dangle, react/no-string-refs, react/no-direct-mutation-state, class-methods-use-this, camelcase, camelcase, default-case, no-param-reassign, consistent-return, import/no-default-export */
// General Imports
// Components Import
import Dialog from "rc-dialog";
import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
// Actions imports
import { stopReport } from "../../../actions/ambassadorReportsActions";
import { getStandaloneReports } from "../../../actions/reportsActions";
import {
  disableShareInsight,
  shareInsight,
} from "../../../actions/shareInsightActions";
import NoImageFound from "../../../images/svgs/placeholder.svg";
import ShareAmbassadorRoiDialog from "../dialogs/ShareAmbassadorRoiDialog";
import ShareInsightDialog from "../dialogs/ShareInsightDialog";
import { EnterpriseSelectCampaignUsers } from "../side_menus/EnterpriseSelectCampaignUsers";
import { formatBigNumbers } from "../utilities/general";

const reportTypes = [
  { label: "Combined Report", value: "multi_report" },
  { label: "Ambassador report", value: "ambassador_report" },
  { label: "Ambassador report (brief)", value: "ambassador_report_unfinished" },
  { label: "Post report", value: "standalone_roi" },
];

class ReportsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectedInsight: null,
      createReportDialogOpened: false,
      deleteReportDialogOpened: false,
      openShareInsight: false,
      reportToStop: null,
      selectedReportType: null,
      ambassadorEnabled: false,
      selectedCampaign: null,
    };
  }

  /* ###### Functions ##### */
  componentWillMount() {
    this.setState({
      ambassadorEnabled:
        this.props.user.userDetails.sidebar_data.allowed_ambassador_type,
    });
    this.props.getStandaloneReports();
  }

  /* ###### End Functions ##### */

  onShareInsight = (share, allowNotes, callback) => {
    if (share) {
      this.props.shareInsight(
        this.state.selectedInsight.id,
        allowNotes,
        callback,
      );
    } else {
      this.props.disableShareInsight(this.state.selectedInsight.id, callback);
    }
  };

  createReportDialog(canCreateInsight, canCreateAmbassador) {
    return (
      <Dialog
        onClose={() => {
          this.setState({ createReportDialogOpened: false });
        }}
        className="new-report-dialog"
        visible={true}
        style={{ marginTop: 50 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">New Report</div>
        </div>

        <div style={{ paddingBottom: 5, paddingTop: 10, textAlign: "center" }}>
          <Link to={"/multi_report"} className={"bigButton "}>
            <i className="fal fa-ballot" />
            <span className="bigButtonTitle">Combined Report</span>
            <span className={"bigButtonDescription"}>
              Create new Combined Report
            </span>
          </Link>
          <button
            className={`attach-links-btn btn btn-primary cancel ${
              this.state.saving && "disabled"
            }`}
            onClick={() => this.setState({ createReportDialogOpened: false })}
          >
            <span>Cancel</span>
          </button>
        </div>
      </Dialog>
    );
  }

  openNewAmbassadorReport = () => {
    if (this.state.ambassadorEnabled) {
      this.props.navigate("/ambassador/");
    } else {
      this.setState({ showCantOpenReport: true });
    }
  };

  render() {
    const { reportsReducer, user } = this.props;
    let canCreateInsight = false;

    if (user && user.userDetails && user.userDetails.sidebar_data) {
      const sbData = user.userDetails.sidebar_data;
      canCreateInsight =
        sbData.allowed_insights_report &&
        (sbData.max_number_of_reports_posts === -1 ||
          sbData.current_number_of_reports_posts <
            sbData.max_number_of_reports_posts);
    }

    return (
      <div className="campaigns-section-main">
        {this.state.selectedCampaign && (
          <EnterpriseSelectCampaignUsers
            campaign={this.state.selectedCampaign}
            all_users={user.userDetails.sidebar_data.sub_users}
            handleClose={(miniUsers) => {
              this.setState({ selectedCampaign: null });
              // this.init();
            }}
            is_report={true}
          />
        )}
        {/* Top bar */}
        <div className="section-top-bar"></div>
        {/* End top bar */}

        <div
          className="campaign-section-middle"
          ref={(e) => (this._cubesContainer = e)}
        >
          <div style={{ width: "var(--main-inner-size)", margin: "0 auto" }}>
            <div className="campaigns-title">
              <span>INSIGHTS & REPORTS</span>
              <div
                className={"create-buttons-row pull-right"}
                style={{ paddingTop: 20 }}
              >
                <button
                  // todo: fix can create
                  // disabled={!canCreate}
                  onClick={() => {
                    this.setState({ createReportDialogOpened: true });
                  }}
                  className={"btn btn-primry"}
                >
                  <span
                    className="moi-plus"
                    style={{
                      color: "var(--main-baby-blue-color)",
                      fontSize: 11,
                    }}
                  />{" "}
                  Add new
                </button>
              </div>
            </div>
            <div className="inner-toolbar" style={{ marginTop: 10 }}>
              <div className="inner-toolbar-search-input">
                <div className="moi-search-bold" />
                <input
                  onChange={(e) =>
                    this.setState({ search: e.target.value.toLowerCase() })
                  }
                  className="form-control"
                />
              </div>
              <div className="select-wrapper">
                <Select
                  ref="stateSelect"
                  options={reportTypes}
                  placeholder={"Report type"}
                  value={this.state.selectedReportType}
                  simpleValue
                  clearable={true}
                  onChange={(value) => {
                    this.state.selectedReportType = value;
                    this.setState({ updated: true });
                  }}
                  name="selected-report-type"
                  disabled={false}
                  searchable={true}
                />
              </div>
            </div>
            {/* Rows */}
            <div className="campaigns-rows">
              {reportsReducer.reports
                .filter((x) =>
                  (x.name ? x.name : "")
                    .toLowerCase()
                    .includes(this.state.search),
                )
                .map((x, index) => {
                  return this.renderReportsRows(x, index);
                })}
            </div>

            {/* End Rows */}
            {this.stopReport()}
            {this.state.createReportDialogOpened &&
              this.createReportDialog(
                canCreateInsight,
                this.state.ambassadorEnabled,
              )}
          </div>
        </div>
        {this.state.openShareInsight &&
        this.state.selectedInsight &&
        this.state.selectedInsight.insight_type === "ambassador_report" ? (
          <ShareAmbassadorRoiDialog
            insight={this.state.selectedInsight}
            onShareInsight={this.onShareInsight}
            handleClose={() => this.setState({ openShareInsight: false })}
          />
        ) : (
          ""
        )}
        {this.state.openShareInsight &&
        this.state.selectedInsight &&
        this.state.selectedInsight.insight_type === "standalone_roi" ? (
          <ShareInsightDialog
            insight={this.state.selectedInsight}
            onShareInsight={this.onShareInsight}
            handleClose={() => this.setState({ openShareInsight: false })}
          />
        ) : (
          ""
        )}
        {this.state.showCantOpenReport && this.createReportDialog()}
      </div>
    );
  }

  /* ###### Renders ##### */
  getReportTypeName(insight_type) {
    switch (insight_type) {
      case "ambassador_report_unfinished":
        return "Ambassador report (brief)";
      case "ambassador_report":
        return "Ambassador report";
      case "standalone_roi":
        return "Post report";
      case "multi_report":
        return "Combined Report";
      default:
        return "report";
    }
  }

  getReportLink = (c, suf) => {
    let url = "insight";
    switch (c.insight_type) {
      case "ambassador_report_unfinished":
        url = "create_ambassador";
        suf = "edit";
        break;
      case "ambassador_report":
        url = "ambassador";
        break;
      case "multi_report":
        url = "multi_report";
        break;
    }
    this.props.navigate(`/${url}/${c.id}${suf ? `/${suf}` : ""}`);
  };

  stopReport() {
    if (!this.state.reportToStop || !this.state.deleteReportDialogOpened) {
      return;
    }
    return (
      <Dialog
        onClose={() => {
          this.setState({ deleteReportDialogOpened: false });
        }}
        className="new-report-dialog"
        visible={true}
        style={{ marginTop: 50 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">Stop report</div>
        </div>

        <div style={{ paddingBottom: 5, paddingTop: 10, textAlign: "center" }}>
          <p>
            Are you sure you want to stop this report? This action is
            irreversible.{" "}
          </p>
          <div className={"preview-footer-buttons"}>
            <button
              className={`attach-links-btn btn btn-primary ${
                this.state.saving && "disabled"
              }`}
              onClick={() => {
                this.setState({ deleteReportDialogOpened: false });
                this.props.stopReport(
                  this.state.reportToStop,
                  this.props.getStandaloneReports,
                );
              }}
            >
              <span>STOP</span>
            </button>
            <button
              className={`attach-links-btn btn${
                this.state.saving && "disabled"
              }`}
              onClick={() => this.setState({ deleteReportDialogOpened: false })}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  renderReportsRows = (c, index) => {
    const { user } = this.props;
    const isEnterprise = this.props.user?.enableShareWithSubUsers;
    const unfinishedCampaign = c.campaign_type === "unfinished";
    return !this.state.selectedReportType ||
      this.state.selectedReportType === c.insight_type ? (
      <div
        className={`campaign-details-container ${
          c.stopped_at ? "report-stopped" : ""
        }`}
        key={index}
        style={{ marginBottom: 20, cursor: "pointer" }}
      >
        <img className="blurable" src={c.image ? c.image : NoImageFound} />
        <div className="campaign-details-rows">
          <div
            className={"campaign-details-row "}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <strong
              className="campaign-name blurable"
              style={{
                width: 250,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
              onClick={() => this.getReportLink(c)}
            >
              {c.name}
            </strong>
            <span className={`report-type ${c.insight_type}`}>
              {this.getReportTypeName(c.insight_type)}
            </span>

            {!unfinishedCampaign && isEnterprise && (
              <button
                style={{ marginRight: 15 }}
                className={"btn inf-row-button"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ selectedCampaign: c });
                }}
              >
                <div style={{ display: "flex" }}>
                  <li>
                    <i
                      className="fas fa-user-alt"
                      style={{
                        transform: "translateY(-2px)",
                        color: "white",
                      }}
                    />
                  </li>
                  <span> Users</span>
                </div>
              </button>
            )}
            {user.parent ? null : (
              <div
                className="campaign-details-actions"
                style={{ width: 200, justifyContent: "flex-end" }}
              >
                {c.insight_type === "ambassador_report" && !c.stopped_at ? (
                  <div
                    className="fas fa-hand-stop-o"
                    style={{ fontSize: 17 }}
                    onClick={() => {
                      this.setState({
                        reportToStop: c.id,
                        deleteReportDialogOpened: true,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
                {c.insight_type !== "ambassador_report_unfinished" && (
                  <div
                    className="moi-forward-arrow2"
                    style={{ color: "#aaaaaa" }}
                    onClick={() => {
                      this.setState({ selectedInsight: c });
                      this.setState({ openShareInsight: true });
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div>
            <div
              className="campaign-details-rows"
              onClick={() => this.getReportLink(c)}
            >
              <div style={{ textAlign: "left", display: "inline-block" }}>
                <div style={{ fontSize: 18, marginTop: 8, marginBottom: 8 }}>
                  {formatBigNumbers(c.media_count)}
                </div>
                <div style={{ width: 70 }}>
                  <strong className="list-influencer-details-desc">
                    Posts
                  </strong>
                </div>
              </div>
              <div
                style={{
                  textAlign: "left",
                  display: "inline-block",
                  marginLeft: 30,
                }}
              >
                {c.influencers_count !== undefined ? (
                  <div style={{ fontSize: 18, marginTop: 8, marginBottom: 8 }}>
                    {formatBigNumbers(c.influencers_count)}
                  </div>
                ) : (
                  <div style={{ height: 40 }} />
                )}
                <div style={{ width: 130 }}>
                  <strong className="list-influencer-details-desc">
                    Total Influencers
                  </strong>
                </div>
              </div>
              {this.props.user.parent && c.owner ? (
                <div
                  style={{
                    textAlign: "left",
                    display: "inline-block",
                    marginLeft: 30,
                  }}
                >
                  <div
                    style={{
                      fontSize: 18,
                      marginTop: 8,
                      marginBottom: 8,
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {c.owner.image ? (
                      <img
                        alt={c.owner.name}
                        src={c.owner.image}
                        style={{
                          width: 35,
                          height: 35,
                          borderRadius: "50%",
                          marginRight: 5,
                        }}
                      />
                    ) : null}
                    {c.owner.name}
                  </div>
                  <div>
                    <strong className="list-influencer-details-desc">
                      Managed By
                    </strong>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  /* ###### End Renders ##### */
}

const mapStateToProps = (state) => {
  return {
    reportsReducer: state.reportsReducer,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStandaloneReports: () => {
      dispatch(getStandaloneReports());
    },
    shareInsight: (listId, shareNotes, callback) => {
      dispatch(shareInsight(listId, shareNotes, callback));
    },
    disableShareInsight: (listId, callback) => {
      dispatch(disableShareInsight(listId, callback));
    },
    stopReport: (listId, callback) => {
      dispatch(stopReport(listId, callback));
    },
  };
};

const ReportsSectionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsSection);

export default (props) => {
  const navigate = useNavigate();

  return <ReportsSectionConnected {...props} navigate={navigate} />;
};
