// TODO - lint
/* eslint-disable import/extensions, import/extensions, import/order, import/no-default-export, eqeqeq, no-restricted-syntax, no-param-reassign, no-shadow, no-empty, no-empty, no-unused-vars, no-unused-vars, react/no-direct-mutation-state, no-param-reassign, no-param-reassign, no-param-reassign, react/no-string-refs, react/no-string-refs, array-callback-return, consistent-return, no-param-reassign, no-param-reassign, react/jsx-key, consistent-return, no-nested-ternary, no-restricted-syntax, no-nested-ternary, no-restricted-syntax */
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tooltip from "rc-tooltip";
import React from "react";
import "react-tagsinput/react-tagsinput.css";
import { taggerUserId } from "../../../actions/commonActionsConf";
import {
  filterCities,
  getSavedQueries,
  removeSavedQuery,
  saveNewQuery,
} from "../../../actions/premiumActions.js";
import { numberWithCommas } from "../utilities/general";
import {
  createQuery,
  getSearchFields,
  updateSearchFields,
} from "../utilities/searchUtils.js";
import SearchItem from "./SearchItem";

const SESSION_STORAGE_NAME = "H_SEARCH_FIELDS";

export default class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    let searchFields;

    if (props.type === "discovery") {
      searchFields = getSearchFields(SESSION_STORAGE_NAME).filter(
        (x) => x.name !== "influencer_scope",
      );
      if (!sessionStorage.getItem(SESSION_STORAGE_NAME)) {
        // searchFields.find((x) => x.name === "influencer_followers").selected = [
        //   5000, 100000,
        // ];
      }
      const showTagger = this.props.user.id === taggerUserId;
      if (showTagger) {
        searchFields
          .find((x) => x.name === "influencer_gender")
          .values.push("Unknown");
        searchFields
          .find((x) => x.name === "influencer_ages")
          .values.push("Unknown");
      }

      if (
        props.user.userDetails &&
        props.user.userDetails.sidebar_data.allowed_has_contact_details &&
        !searchFields.find((x) => x.name === "has_contact_details")
      ) {
        searchFields.push({
          name: "has_contact_details",
          label: "Influencer contact details",
          type: "checkbox",
          isOpen: false,
          values: ["Has contact details"],
          selected: -1,
          row: "influencer",
        });
      }
      searchFields = searchFields.filter(
        (x) => x.name !== "influencer_tags" && x.name !== "influencer_scope",
      );
    } else if (props.type === "list") {
      searchFields = getSearchFields().filter(
        (x) => !x.name.includes("categories"),
      );
      if (props.share) {
        searchFields = searchFields.filter((x) => x.share);
      }
    } else if (props.type === "manage") {
      searchFields = getSearchFields().filter(
        (x) =>
          !x.name.includes("categories") &&
          x.name !== "influencer_scope" &&
          x.name !== "influencer_tags",
      );
      if (props.share) {
        searchFields = searchFields.filter((x) => x.share);
      }
    }

    this.state = {
      citiesOptions: [],
      socialDataFilter: ["all"],
      searchFields,
      showFilter: props.type === "discovery",
      filterTab: "1",
      savedQueriesOpen: 0,
      savedQueries: [],
      filteredTags: [],
    };
  }

  componentDidMount() {
    if (this.props.tags) {
      const tags = this.state.searchFields.find(
        (x) => x.name === "influencer_tags",
      );
      tags.tags = this.props.tags;
    }

    if (this.props.type === "discovery") {
      if (!window.sessionStorage.getItem(SESSION_STORAGE_NAME)) {
        const { country } = this.props.user.userDetails.company_details;
        const field = this.state.searchFields.find(
          (x) => x.name === "influencer_location",
        );
        if (country == "South Africa") {
          const followersAges = this.state.searchFields.find(
            (x) => x.name === "followers_ages",
          );
          followersAges.values = ["Gen Z", "Gen Y", "Gen X"];
          followersAges.tooltips = ["13-18", "19-35", "35+"];

          const influencerAges = this.state.searchFields.find(
            (x) => x.name === "influencer_ages",
          );
          influencerAges.values = ["Gen Z", "Gen Y", "Gen X"];
          influencerAges.tooltips = ["13-18", "19-35", "35+"];
        }
        const countryFilter = field.values.find((x) => x.name === country);
        if (countryFilter) {
          countryFilter.selected = ["all"];
        }
      }

      window.setTimeout(
        function () {
          this.props.searchInfluencers();
        }.bind(this),
        10,
      );
    }
  }

  componentWillUnmount() {
    if (this.props.type === "discovery") {
      window.sessionStorage.removeItem(SESSION_STORAGE_NAME);
    }
  }

  getSearchQuery = (dontAllowChildren, showTagger) => {
    return createQuery(
      this.state.searchFields,
      this.state.socialDataFilter,
      dontAllowChildren,
      showTagger,
    );
  };

  saveSession = () => {
    window.sessionStorage.setItem(
      SESSION_STORAGE_NAME,
      JSON.stringify(this.state.searchFields),
    );
  };

  getSearchSocial = () => {
    if (this.props.type === "discovery") {
      return this.state.searchFields.find(
        (x) => x.name === "influencer_username",
      ).social;
    }
    return this.state.socialDataFilter;
  };

  getScopeFilter = () => {
    const field = this.state.searchFields.find(
      (x) => x.name === "influencer_scope",
    );
    if (field) {
      return field.selected.map((x) => field.values[x]);
    }
    return [];
  };

  valueCityChange = (inputValue) => {
    if (inputValue) {
      filterCities(inputValue, (data) => {
        const results = [];
        for (const city of data) {
          results.push({ label: city, value: city });
        }
        this.setState({ citiesOptions: results });
      });
    }
  };

  handleTagsChange = (item, tags) => {
    item.tags = tags;
    this.updateSearchField(item.name, tags);
  };

  filterBySearch = (value, name) => {
    const field = this.state.searchFields.find((x) => x.name === name);
    if (field?.value?.trim() !== value?.trim()) {
      this.props.setLoadingData(true);
      field.value = value;
      window.setTimeout(
        function () {
          const field = this.state.searchFields.find((x) => x.name === name);
          if (field.value === value) {
            this.props.searchInfluencers();
          }
        }.bind(this),
        1000,
      );
    }
  };

  toggleFilterMenu = (callback, close) => {
    try {
      if (this.state.showFilter) {
        const element = document.getElementById("search_filters");
        element.classList.remove("fadeInRight");
        element.classList.add("fadeOutRight");
        document.getElementById("search_shadow")?.classList.add("hidden");

        window.setTimeout(
          function () {
            this.setState({ showFilter: false });
          }.bind(this),
          500,
        );
      } else if (close === true) {
      } else {
        if (callback) callback();
        this.setState({ showFilter: true, filterTab: "1" });
      }
    } catch {}
  };

  onChangeUserNameSocial = (social) => {
    const item = this.state.searchFields.find(
      (x) => x.name === "influencer_username",
    );
    if (item.social !== social) {
      item.social = social;
      if (item.value) {
        this.props.searchInfluencers();
      } else {
        this.setState({ searchFields: this.state.searchFields });
      }
    }
  };

  onChangeTags = (tags) => {
    const item = this.state.searchFields.find(
      (x) => x.name === "influencer_tags",
    );
    if (this.state.filteredTags) {
      this.props.searchInfluencers();
    } else {
      this.setState({ searchFields: this.state.searchFields });
    }
  };

  changeSocialFilter = (provider) => {
    const { socialDataFilter } = this.state;
    if (provider === "all") {
      this.state.socialDataFilter = ["all"];
      this.setState({ socialDataFilter: this.state.socialDataFilter });
    } else {
      if (socialDataFilter.indexOf(provider) > -1) {
        socialDataFilter.splice(socialDataFilter.indexOf(provider), 1);
      } else {
        socialDataFilter.push(provider);
      }

      if (socialDataFilter.length === 0) {
        socialDataFilter.push("all");
      } else if (socialDataFilter.indexOf("all") > -1) {
        socialDataFilter.splice(socialDataFilter.indexOf("all"), 1);
      }
      this.setState({ socialDataFilter });
    }

    this.props.searchInfluencers();
  };

  updateTreeField = (parent, child) => {
    // if (this.isSearchsOver()) {
    //     this.setState({ showTrialModal: true });
    //     return;
    // }

    if (child) {
      if (parent.selected.includes("all")) {
        parent.selected = parent.childs.filter((x) => x !== child);
      } else {
        parent.selected.addOrRemove(child);
      }
      if (parent.selected.length === parent.childs.length) {
        parent.selected = ["all"];
      }
    } else {
      parent.selected = parent.selected.includes("all") ? [] : ["all"];
    }

    this.props.searchInfluencers();
  };

  updateSearchField = (name, selected) => {
    // if (this.isSearchsOver()) {
    //     this.setState({ showTrialModal: true });
    //     return
    // }
    const searchFields = updateSearchFields(
      this.state.searchFields,
      name,
      selected,
    );
    if (name === "influencer_scope") {
      this.props.setLoadingData(false);
      return;
    }

    if (name === "clearall") {
      if (this.props.type === "discovery") {
        let geo = this.props.user.userDetails.company_details.country;
        geo = geo || localStorage.getItem("MOI_GEO");
        geo = geo || "All";
        const field = searchFields.find(
          (x) => x.name === "influencer_countries",
        );
        if (field && field.values && field.values.indexOf(geo) > 0) {
          field.selected = [field.values.indexOf(geo)];
        }
      }

      this.setState({ searchFields, socialDataFilter: ["all"] });

      if (this.refs.slider) this.refs.slider.onChange([0]);
      else this.props.searchInfluencers();
    } else {
      this.setState({ searchFields: this.state.searchFields });
      this.props.searchInfluencers();
    }
  };

  checkIfFilterTypeActive = (type) => {
    const filters = this.state.searchFields.filter(
      (x) => x.type !== "header" && x.row === type,
    );
    const filterIsActive = filters.find((filter) => {
      if (
        filter.type === "checkbox" &&
        !filter.isMulti &&
        filter.selected > -1
      ) {
        return true;
      }
      if (
        filter.type === "checkbox" &&
        filter.isMulti &&
        filter.selected.length > 0
      ) {
        return true;
      }
      if (
        (filter.type === "text" || filter.type === "city-search") &&
        filter.value
      ) {
        return true;
      }
      if (filter.type === "tree") {
        if (
          filter.values.find((x) => {
            if (x.selected.length > 0) {
              return true;
            }
            return false;
          })
        ) {
          return true;
        }
      } else if (filter.type === "multiple_field") {
        const from = filter.selected[0];
        const to = filter.selected[1];
        if (from !== -1) {
          return true;
        }
        if (to !== -1) {
          return true;
        }
      }
    });
    return filterIsActive;
  };

  toggleSavedQueries = () => {
    this.setState({
      savedQueriesOpen: this.state.savedQueriesOpen > 0 ? 0 : 1,
      loadingQueries: true,
    });
    if (this.state.savedQueriesOpen === 0) {
      getSavedQueries((data) => {
        if (data) {
          this.setState({
            savedQueries: data.map((x) => {
              x.data = { ...JSON.parse(x.data) };
              x.data.id = x.id;
              return x.data;
            }),
            loadingQueries: false,
          });
        } else {
          this.setState({ loadingQueries: false });
        }
      });
    }
  };

  saveNewQuery = () => {
    const name = document.getElementById("newQueryName").value;
    if (!name || this.state.savingQuery) return;
    this.setState({ savingQuery: true });

    const newQuery = {
      name,
      queries: this.state.searchFields,
    };

    saveNewQuery(JSON.stringify(newQuery), (newId) => {
      if (newId !== false && newId !== null) {
        newQuery.id = newId;
        this.state.savedQueries.push(newQuery);

        this.setState({ savedQueriesOpen: 1, savingQuery: false });
      }
      this.setState({ savedQueriesOpen: 1, savingQuery: false });
    });
  };

  removeSavedFilter(id, idx) {
    this.state.savedQueries.splice(idx, 1);
    this.setState({ savingQuery: true });
    removeSavedQuery(id, () => {
      this.setState({ savingQuery: false });
    });
  }

  handleSavedQueryClick(query) {
    this.setState({ searchFields: query.queries });
    window.setTimeout(
      function () {
        this.props.searchInfluencers();
      }.bind(this),
      100,
    );
  }

  render() {
    const { searchFields, socialDataFilter, filterTab } = this.state;
    if (!this.state.showFilter) return null;
    const canViewTiktok =
      this.props.user && this.props.user.userDetails
        ? this.props.user.userDetails.sidebar_data.can_view_tiktok
        : this.props.share;
    return (
      <>
        <div
          className="side-shadow"
          id="search_shadow"
          onClick={this.toggleFilterMenu}
        />
        <div
          className="influencer-search-filters animated fadeInRight faster"
          id="search_filters"
        >
          <div>
            <div style={{ fontSize: 22 }}>Filter</div>
            <div
              className="filter-close-button"
              onClick={this.toggleFilterMenu}
            >
              <span className="moi-ximage" />
            </div>
          </div>

          {this.props.results !== undefined && (
            <div style={{ fontSize: 16, marginTop: 5, marginBottom: 5 }}>
              {this.props.results} influencers
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={() => this.updateSearchField("clearall")}
              className="btn clear-btn btn-primary"
            >
              <strong>CLEAR FILTERS</strong>
            </button>
            <Tooltip
              visible={this.state.savedQueriesOpen > 0}
              onVisibleChange={this.toggleSavedQueries}
              trigger={["click"]}
              placement="bottom"
              overlayClassName={"edit-popup"}
              overlay={
                <div className="saved-queries-popup">
                  <div>
                    <div>Saved Queries</div>{" "}
                    <div
                      onClick={this.toggleSavedQueries}
                      style={{ cursor: "pointer", fontSize: 17 }}
                      className="fal fa-times"
                    ></div>
                  </div>
                  {this.state.loadingQueries &&
                    this.state.savedQueries.length === 0 && (
                      <div style={{ width: 210 }}>
                        <div className="send-buttons-loader"></div>
                      </div>
                    )}
                  {this.state.savedQueriesOpen > 1 ? (
                    <>
                      <div style={{ height: 100 }}>
                        <div style={{ marginTop: 20 }}>
                          <strong className="label-title">name</strong>
                          <input className="input-line" id="newQueryName" />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          className={`btn btn-primary ${
                            this.state.savingQuery && "disabled"
                          }`}
                          onClick={() => this.setState({ savedQueriesOpen: 1 })}
                          style={{
                            background: "#249ff0",
                            borderColor: "#22B573",
                          }}
                        >
                          {" "}
                          BACK{" "}
                        </button>
                        <button
                          className={`btn btn-primary ${
                            this.state.savingQuery && "disabled"
                          }`}
                          onClick={() => this.saveNewQuery()}
                          style={{
                            background: "#22B573",
                            borderColor: "#22B573",
                          }}
                        >
                          {" "}
                          SAVE{" "}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="saved-queries-section">
                        {this.state.savedQueries.map((x, idx) => (
                          <div>
                            <div
                              title={x.name}
                              key={x.id}
                              onClick={() => this.handleSavedQueryClick(x)}
                            >
                              {x.name}
                            </div>
                            <i
                              onClick={() => this.removeSavedFilter(x.id, idx)}
                              className="fal fa-trash-alt"
                            ></i>
                          </div>
                        ))}
                      </div>
                      <button
                        className={`btn btn-primary ${
                          this.state.savingQuery && "disabled"
                        }`}
                        onClick={() => this.setState({ savedQueriesOpen: 2 })}
                        style={{
                          background: "#22B573",
                          borderColor: "#22B573",
                        }}
                      >
                        <i className="far fa-plus"></i> SAVE CURRENT FILTERS
                      </button>
                    </>
                  )}
                </div>
              }
            >
              <button
                onClick={this.toggleSavedQueries}
                style={{ background: "#22B573", borderColor: "#22B573" }}
                className="btn clear-btn btn-primary"
              >
                <strong>SAVED FILTERS </strong>{" "}
                <i
                  className={`far fa-chevron-${
                    this.state.savedQueriesOpen ? "up" : "down"
                  }`}
                ></i>
              </button>
            </Tooltip>
          </div>

          {/* Filter by social */}
          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <strong className="small-title">SOCIAL</strong>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="filter-social">
              <div
                className={`filter-social-cube ${
                  socialDataFilter.indexOf("all") > -1
                }`}
                onClick={() => this.changeSocialFilter("all")}
              >
                All
              </div>
              <div className="checkbox" style={{ padding: 0 }}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => this.changeSocialFilter("all")}
                    checked={socialDataFilter.indexOf("all") > -1}
                  />
                  <span className="checkbox-material">
                    <span className="check"></span>
                  </span>
                </label>
              </div>
            </div>
            <div className="filter-social">
              <div
                className={`filter-social-cube ${
                  socialDataFilter.indexOf("instagram") > -1
                }`}
                onClick={() => this.changeSocialFilter("instagram")}
              >
                <li className="fa fa-instagram" />
              </div>
              <div className="checkbox" style={{ padding: 0 }}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => this.changeSocialFilter("instagram")}
                    checked={socialDataFilter.indexOf("instagram") > -1}
                  />
                  <span className="checkbox-material">
                    <span className="check"></span>
                  </span>
                </label>
              </div>
            </div>

            {canViewTiktok && (
              <div className="filter-social">
                <div
                  className={`filter-social-cube ${
                    socialDataFilter.indexOf("tiktok") > -1
                  }`}
                  onClick={() => this.changeSocialFilter("tiktok")}
                >
                  {" "}
                  <div className="tiktok-logo">
                    <div className="fa-brands fa-tiktok"></div>
                  </div>
                </div>
                <div className="checkbox" style={{ padding: 0 }}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={() => this.changeSocialFilter("tiktok")}
                      checked={socialDataFilter.indexOf("tiktok") > -1}
                    />
                    <span className="checkbox-material">
                      <span className="check"></span>
                    </span>
                  </label>
                </div>
              </div>
            )}

            <div className="filter-social">
              <div
                className={`filter-social-cube ${
                  socialDataFilter.indexOf("twitter") > -1
                }`}
                onClick={() => this.changeSocialFilter("twitter")}
              >
                <li className="fa fa-twitter" />
              </div>
              <div className="checkbox" style={{ padding: 0 }}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => this.changeSocialFilter("twitter")}
                    checked={socialDataFilter.indexOf("twitter") > -1}
                  />
                  <span className="checkbox-material">
                    <span className="check"></span>
                  </span>
                </label>
              </div>
            </div>
            <div className="filter-social">
              <div
                className={`filter-social-cube ${
                  socialDataFilter.indexOf("youtube") > -1
                }`}
                onClick={() => this.changeSocialFilter("youtube")}
              >
                <li className="fa fa-youtube-play" />
              </div>
              <div className="checkbox" style={{ padding: 0 }}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => this.changeSocialFilter("youtube")}
                    checked={socialDataFilter.indexOf("youtube") > -1}
                  />
                  <span className="checkbox-material">
                    <span className="check"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          {/* End Filter by social */}

          <div className="filter-spacer" />

          {/* Refine by */}
          {this.props.share ? (
            this.renderShareFilters(searchFields)
          ) : (
            <div className="refine-container">
              <Tabs
                activeKey={filterTab}
                renderTabBar={() => <InkTabBar />}
                renderTabContent={() => <TabContent />}
                onChange={(tab) => this.setState({ filterTab: tab })}
              >
                <TabPane
                  tab={
                    <div>
                      PROFILE{" "}
                      {this.checkIfFilterTypeActive("influencer") && (
                        <div className="filter-on" />
                      )}
                    </div>
                  }
                  key="1"
                >
                  <div
                    className="filter-refine-tab"
                    style={filterTab !== "1" ? { display: "none" } : {}}
                  >
                    {this.props.renderProfileSearchItems()}
                    {searchFields
                      .filter((x) => x.row === "influencer")
                      .map(this.renderSearchItems)}
                  </div>
                </TabPane>
                {socialDataFilter.includes("twitter") && (
                  <TabPane
                    tab={
                      <div>
                        AUDIENCE{" "}
                        {this.checkIfFilterTypeActive("audience") && (
                          <div className="filter-on" />
                        )}
                      </div>
                    }
                    key="2"
                  >
                    <div
                      className="filter-refine-tab"
                      style={filterTab !== "2" ? { display: "none" } : {}}
                    >
                      {searchFields
                        .filter((x) => x.row === "audience")
                        .map(this.renderSearchItems)}
                    </div>
                  </TabPane>
                )}
                <TabPane
                  tab={
                    <div>
                      CONTENT{" "}
                      {this.checkIfFilterTypeActive("content") && (
                        <div className="filter-on" />
                      )}
                    </div>
                  }
                  key="3"
                >
                  <div
                    className="filter-refine-tab"
                    style={filterTab !== "3" ? { display: "none" } : {}}
                  >
                    {searchFields
                      .filter((x) => x.row === "content")
                      .map(this.renderSearchItems)}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          )}

          {/* End Refine by */}
        </div>
      </>
    );
  }

  renderShareFilters = (searchFields) => {
    return (
      <div style={{ marginTop: 30 }}>
        {searchFields.map(this.renderSearchItems)}
      </div>
    );
  };

  getActiveFilter = (type) => {
    const filters = this.state.searchFields.filter(
      (x) => x.type !== "header" && x.row === type,
    );
    let values = [];
    filters.forEach((filter) => {
      const { name } = filter;
      if (
        filter.type === "checkbox" &&
        !filter.isMulti &&
        filter.selected > -1
      ) {
        values.push({
          label: filter.values[filter.selected],
          name,
          selected: filter.selected,
        });
      } else if (
        filter.type === "checkbox" &&
        filter.isMulti &&
        filter.selected.length > 0
      ) {
        values = values.concat(
          filter.values
            .map((t, idx) => {
              return { label: t, name, selected: idx };
            })
            .filter((x) => filter.selected.includes(x.selected)),
        );
      } else if (
        (filter.type === "text" || filter.type === "city-search") &&
        filter.value
      ) {
        values.push({
          label:
            (filter.name === "influencer_hashtags"
              ? "#"
              : filter.name === "influencer_mentions"
              ? "@"
              : "") + filter.value,
          name,
          selected: "",
        });
      } else if (filter.type === "tree") {
        filter.values.forEach((x) => {
          if (x.selected.length > 0) {
            values.push({ label: x.name, name, parent: x });
            if (!x.selected.includes("all")) {
              values = values.concat(
                x.selected.map((c) => {
                  return { label: c, filter, parent: x, child: c };
                }),
              );
            }
          }
        });
      } else if (filter.type === "multiple_field") {
        const from = filter.selected[0];
        const to = filter.selected[1];
        if (from !== -1) {
          values.push({
            label: `>${numberWithCommas(from)}`,
            name: "influencer_followers",
            selected: [-1, to],
          });
        }
        if (to !== -1) {
          values.push({
            label: `<${numberWithCommas(to)}`,
            name: "influencer_followers",
            selected: [from, -1],
          });
        }
      } else if (
        filter.type === "tags" &&
        filter.tags &&
        filter.tags.length > 0
      ) {
        for (const tag of filter.tags) {
          values.push({
            label:
              (filter.name === "influencer_hashtags"
                ? "#"
                : filter.name === "influencer_mentions"
                ? "@"
                : "") + tag,
            name,
            selected: tag,
          });
        }
      } else if (
        filter.type === "tags-choice" &&
        filter.tags &&
        filter.tags.length > 0
      ) {
        if (filter.selected && filter.selected.length) {
          for (const tag of filter.selected) {
            values.push({
              label: `tag: ${filter.tags.find((t) => t.id === tag).label}`,
              name,
              selected: tag,
            });
          }
        }
      }
    });

    if (values.length > 0) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "5px",
          }}
        >
          <span
            style={{
              color: "#888999",
              fontWeight: "bold",
              fontSize: 13.5,
              textTransform: "uppercase",
            }}
          >
            {type}:
          </span>
          <div
            style={{
              height: 22,
              overflow: "hidden",
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
              flexDirection: "row",
              overflowY: "auto",
            }}
          >
            {values.map((x, idx) => {
              return (
                <div key={idx} className="filter-small-cube">
                  <div>{x.label}</div>
                  <a
                    className="fas fa-times"
                    onClick={() => {
                      if (x.parent) {
                        this.updateTreeField(x.parent, x.child);
                      } else {
                        this.updateSearchField(x.name, x.selected);
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  renderSearchItems = (item, index) => {
    return (
      <SearchItem
        key={item.name + index}
        item={item}
        filteredTags={this.state.filteredTags}
        updateState={(callback) => this.setState({}, callback)}
        updateSearchField={this.updateSearchField}
        updateTreeField={this.updateTreeField}
        onChangeTags={this.onChangeTags}
        handleTagsChange={this.handleTagsChange}
        filterBySearch={this.filterBySearch}
        valueCityChange={this.valueCityChange}
        citiesOptions={this.state.citiesOptions}
        selectedSocials={this.state.socialDataFilter}
      />
    );
  };
}

SearchFilter.defaultProps = {
  renderProfileSearchItems: () => {},
};
