import { Box, Flex, Skeleton, SkeletonCircle, Text } from "@chakra-ui/react";
import CountUp from "react-countup";
import { formatBigNumbers } from "../../utilities/general";
import DashboardPaper from "./DashboardPaper";

export type DashboardTileProps = {
  value?: any;
  title: string;
  icon: string; // Font awesome icon
  customIcon?: any; // Override icon
  color: string;
  variant?: "full" | "small";
  loading?: boolean;
  noBackground?: boolean;
  suffix?: any;
};

const hexToRGBA = (ihex: string, opacity: number) => {
  if (!ihex.length) return `rgba(0,0,0,${opacity})`;
  const hex = ihex.replace(/^#/, "");
  const [r, g, b] = hex.match(/.{1,2}/g).map((val) => parseInt(val, 16));
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const DashboardTile = ({
  value,
  title,
  icon,
  customIcon,
  color,
  variant = "full",
  loading = false,
  noBackground = false,
  suffix,
}: DashboardTileProps) => {
  return (
    <DashboardPaper
      noBackground={noBackground}
      padding={variant === "full" ? 4 : 3}
      display={variant === "full" ? "block" : "flex"}
      maxWidth={variant === "full" ? "100%" : "216px"}
      minWidth={150}
    >
      {loading ? (
        <Box display={"flex"}>
          <SkeletonCircle height={10} width={10} mr={5} />
          <Skeleton height={10} minWidth={120} flex={3} />
        </Box>
      ) : (
        <>
          <Flex
            gap={variant === "full" ? 3 : 4}
            mb={variant === "full" ? 4 : 0}
          >
            <Box
              sx={{
                width: "44px",
                height: "44px",
                borderRadius: variant === "full" ? "10px" : "44px",
                backgroundColor: hexToRGBA(color, 0.2),
                fontSize: "20px",
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                placeSelf: "center",
              }}
            >
              {customIcon || <i className={`${icon}`} style={{ color }} />}
            </Box>
            <Box
              alignItems="center"
              alignSelf={"center"}
              display={variant === "full" ? "flex" : "block"}
            >
              <Box>
                <Text fontSize={16} color={"#434D56"} fontWeight={600}>
                  {title}
                </Text>
              </Box>
              {variant === "small" && value !== undefined && (
                <Box>
                  <Text fontSize="24px" fontWeight={"500"}>
                    {formatBigNumbers(value)}
                  </Text>
                </Box>
              )}
            </Box>
          </Flex>
          {variant === "full" && value !== undefined && (
            <Box
              mt={variant === "full" ? 4 : 0}
              alignItems="flex-end"
              display="flex"
              justifyContent="space-between"
            >
              <Text fontSize="32px" as="span" fontWeight={"500"}>
                <CountUp
                  decimalPlaces={2}
                  formattingFn={formatBigNumbers}
                  end={Math.abs(value)}
                />
              </Text>
              {suffix && (
                <Text
                  fontSize="26px"
                  color="#CACACA"
                  as="span"
                  fontWeight={"300"}
                >
                  {suffix}
                </Text>
              )}
            </Box>
          )}
        </>
      )}
    </DashboardPaper>
  );
};

export default DashboardTile;
