import produce, { Draft } from "immer";

export const addOrRemove = <T>(
  arr: T[],
  items: T[],
  keyMapFn?: (t: T) => number | string,
): T[] =>
  produce(arr, (draftArr) => {
    items.forEach((item) => {
      let keyArr: Array<T | number | string> = draftArr as T[];
      let itemKey: T | number | string = item;
      if (keyMapFn) {
        keyArr = (draftArr as T[]).map(keyMapFn);
        itemKey = keyMapFn(item);
      }
      const indexOfItem = keyArr.indexOf(itemKey);
      if (indexOfItem === -1) {
        draftArr.push(item as Draft<T> | any);
      } else {
        draftArr.splice(indexOfItem, 1);
      }
    });
  });
