import {
  Button,
  ButtonProps,
  Flex,
  Text,
  Textarea,
  TextareaProps,
  TextProps,
} from "@chakra-ui/react";
import Tooltip from "rc-tooltip";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

export interface ExpendedInputInnerProps {
  visible: boolean;
  textAreaProps?: TextareaProps;
  multiLine?: boolean;
  onSave: (value: string) => void;
  live?: boolean;
  defaultValue?: string;
}

const ExpendedInputInner = ({
  visible,
  textAreaProps,
  multiLine,
  onSave,
  live,
  defaultValue,
}: ExpendedInputInnerProps) => {
  const inputRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (visible && inputRef?.current) {
      inputRef.current.value = defaultValue || "";
      inputRef.current.focus();
    } else if (!inputRef.current.value) {
      onSave("");
    }
  }, [visible]);

  const handleSave = () => {
    onSave(inputRef.current.value);
  };

  return (
    <Flex
      background="white"
      width={multiLine ? "400px" : "300px"}
      flexDirection="row"
      alignItems={"center"}
    >
      <Textarea
        flex={1}
        color={"black"}
        ref={inputRef}
        paddingTop={"10px"}
        minHeight={"initial"}
        border={"none"}
        rows={multiLine ? 4 : 1}
        boxShadow={"none"}
        resize={"none"}
        _focus={{ border: "none", boxShadow: "none" }}
        {...textAreaProps}
        onKeyDown={
          multiLine
            ? null
            : (e) => {
                if (e.keyCode === 13 || live) {
                  e.preventDefault();
                  handleSave();
                }
              }
        }
      />

      <Button
        borderRadius={"100px"}
        fontSize={"18px"}
        style={{ width: 36, height: 36 }}
        padding={"5px"}
        alignSelf="flex-end"
        marginEnd={"10px"}
        marginBottom={"5px"}
        marginTop={"5px"}
        _hover={{
          color: "white",
          background: "var(--main-baby-blue-color)",
        }}
        onClick={handleSave}
      >
        <i className="fa-regular fa-paper-plane"></i>
      </Button>
    </Flex>
  );
};

export default function HumanzExpendedInput({
  containerProps,
  innerInput,
  onChange,
  live,
  placeHolder,
  rightComponent,
  defaultValue,
  buttonLabelProps,
}: {
  containerProps?: ButtonProps;
  innerInput?: Partial<ExpendedInputInnerProps>;
  onChange: (value: string) => void;
  live?: boolean;
  placeHolder: string;
  rightComponent?: ReactNode;
  defaultValue?: string;
  buttonLabelProps?: TextProps;
}) {
  const [visible, setVisible] = useState(false as boolean);
  const [label, setLabel] = useState(defaultValue || "");

  useEffect(() => {
    setLabel(defaultValue);
  }, [defaultValue]);

  const handleSave = useCallback(
    (value: string) => {
      onChange(value);
      if (!live) setVisible(false);
      setLabel(value);
    },
    [onChange, setLabel, live],
  );

  return (
    <Tooltip
      placement={"bottomLeft"}
      trigger={["click"]}
      overlayClassName="e-i-o"
      onVisibleChange={(v) => setVisible(v)}
      visible={visible}
      overlay={
        <ExpendedInputInner
          {...innerInput}
          visible={visible}
          onSave={handleSave}
          live={live}
          defaultValue={label}
        />
      }
    >
      <Button
        aria-selected
        display={"flex"}
        alignItems={"center"}
        padding={"10px"}
        width={"100%"}
        variant={"roundedWhite"}
        justifyContent={"initial"}
        boxShadow={visible ? "outline" : "base"}
        {...containerProps}
      >
        <i className="fa-regular fa-magnifying-glass"></i>
        <Text
          color={label ? "var(--main-baby-blue-color)" : "#aaa"}
          textAlign={"start"}
          textOverflow={"ellipsis"}
          whiteSpace="nowrap"
          overflow={"hidden"}
          fontWeight={"normal"}
          flex={1}
          marginLeft={"10px"}
          {...buttonLabelProps}
        >
          {label || placeHolder}
        </Text>
        {rightComponent}
      </Button>
    </Tooltip>
  );
}
