import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Cell, Pie, PieChart } from "recharts";

interface Props {
  value: number;
  title: string;
}

const colors = ["#e66969", "#e6a969", "#D6D12C", "#a0d062", "#60d95f"];

const data = [
  { name: "Very Poor", value: 20 },
  { name: "Poor", value: 20 },
  { name: "Fair", value: 20 },
  { name: "Good", value: 20 },
  { name: "Excellent", value: 20 },
];

const showcustomlabel = false;

const RADIAN = Math.PI / 180;
const cx = 55;
const cy = 50;
const iR = 20;
const oR = 50;

const needle = (
  nvalue: number,
  ndata: any,
  ncx: number,
  ncy: number,
  niR: number,
  noR: number,
  color: string,
) => {
  let total = 0;
  ndata.forEach((v: any) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - nvalue / total);
  const length = (niR + 2 * noR) / 4;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = ncx + 5;
  const y0 = ncy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key={0} cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      key={2}
      d={`M${xba},${yba}L${xbb},${ybb},L${xp},${yp},L${xba},${yba}`}
      stroke="none"
      fill={color}
    />,
  ];
};

const renderCustomizedLabel = ({
  clcx,
  clcy,
  midAngle,
  outerRadius,
  index,
}: any) => {
  const radius = outerRadius * 1.1;
  const x = clcx + radius * Math.cos(-midAngle * RADIAN);
  const y = clcy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={colors[index]}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${data[index].name}`}
    </text>
  );
};

const getScoreIndex = (val: number) => {
  if (!val) return 0;
  if (val === 100) return colors.length - 1;
  const step = 100 / colors.length;
  return Math.floor(val / step);
};

export const BenchmarkChart: React.FC<Props> = (props) => {
  const { value, title } = { ...props };
  return (
    <div className="benchmark-cube">
      <div style={{ display: "flex", flexFlow: "column" }}>
        <PieChart
          style={{ alignSelf: "center", justifyContent: "center" }}
          width={120}
          height={70}
        >
          <Pie
            data={data}
            cx={cx}
            cy={cy}
            startAngle={180}
            endAngle={0}
            innerRadius={37}
            outerRadius={45}
            paddingAngle={0}
            dataKey="value"
            labelLine={false}
            label={showcustomlabel && renderCustomizedLabel}
            cornerRadius={4}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}-${title}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          {needle(value, data, cx, cy, iR, oR, "#4d4d4d")}
        </PieChart>
        <div
          style={{
            textAlign: "center",
            fontSize: 14,
          }}
        >
          <span>{title}</span>
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: 14,
            color: colors[getScoreIndex(value)],
          }}
        >
          <strong style={{}}>{data[getScoreIndex(value)]?.name}</strong>
        </div>
      </div>
    </div>
  );
};
