// TODO - lint
/* eslint-disable default-param-last, no-param-reassign, no-param-reassign, import/no-default-export, ,  */
const searchReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_SHARED_USER_DETAILS":
      state = { ...action.payload };
      break;
    case "SHARE_LIST_GET_LIST_REJECTED":
      state = { ...action.payload, generalError: true };
      break;
    default:
      break;
  }
  return state;
};
export default searchReducer;
