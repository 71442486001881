// TODO - lint
/* eslint-disable @typescript-eslint/no-unused-vars, import/no-default-export, @typescript-eslint/no-unused-vars, no-nested-ternary, react/jsx-no-target-blank */
import produce from "immer";
import React from "react";
import { LocalTask, TaskStage } from "src/api/ExtendedTypes";
import TaskReferences from "../campaign_components/CreateCampaignSection/FormUI/TaskReferences";

interface Props {
  task: TaskStage;
  onClose(): void;
  onChanged?: () => void;
  onTaskChanged?: (newTask: TaskStage | LocalTask) => void;
  isShare?: boolean;
  disabled?: boolean;
}

export default class TaskReferencesPopup extends React.PureComponent<Props> {
  state = {
    mode: 0,
    newReference: "",
    loading: false,
    progress: 0,
  };

  addReference = () => {
    if (this.state.loading) return;
    if (!this.state.newReference) return;

    const { task, onChanged, onTaskChanged } = this.props;

    if (onTaskChanged) {
      onTaskChanged(
        produce(task, (draftTask) => {
          if (!draftTask.references) draftTask.references = [];
          draftTask.references.push(this.state.newReference);
        }),
      );
    } else {
      if (!task.references) task.references = [];
      task.references.push(this.state.newReference);
      this.setState({ newReference: "", mode: 0 });
      if (onChanged) {
        onChanged();
      }
    }
  };

  deleteReference = (idx: number) => {
    const { task, onChanged, onTaskChanged } = this.props;

    if (onTaskChanged) {
      onTaskChanged(
        produce(task, (draftTask) => {
          draftTask.references?.splice(idx, 1);
        }),
      );
    } else {
      task.references?.splice(idx, 1);
      if (onChanged) {
        onChanged();
      }
      this.forceUpdate();
    }
  };

  render() {
    const { task } = this.props;

    return (
      <>
        <TaskReferences
          addReference={this.addReference.bind(this)}
          deleteReference={this.deleteReference.bind(this)}
          setState={this.setState.bind(this)}
          references={task.references}
          isShare={this.props.isShare}
          disabled={this.props.disabled}
        />
      </>
    );
  }
}
