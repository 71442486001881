import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  useDeleteMoiAuctionManageAuction_idInfluencer_idExtra_payoutExtra_payout_id,
  usePostMoiAdvertiserMini_users,
  usePostMoiAuctionManageAuction_idInfluencer_idExtra_payout,
  usePostMoiAuctionManageAuction_idPaymentsInfluencer_idStop_affiliation,
  usePutMoiPremiumAuctionAuction_idShareInvite_influencers,
} from "../hooks";
import {
  deleteMoiAuctionManageAuction_idFilesInfluencer_id,
  deleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront,
  deleteMoiAuctionsAuction_idCoupons,
  deleteMoiAuctionsAuction_idUpload_invoice,
  deleteMoiAuctionsRoiSlides,
  deleteMoiDetailsBrandBrand_id,
  deleteMoiPremiumAuctionAuction_idTodoTo_do_id,
  deleteMoiPremiumTodo_templatesTemplate_id,
  deleteMoiPremiumTodo_templatesTemplate_idTodoTo_do_id,
  getMoiAuctionAuction_idTargets,
  getMoiAuctionManageAuction_idFilesInfluencer_id,
  getMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid,
  getMoiAuctionsAuction_idLast_month_for_roi,
  getMoiAuctionsRoiSlides,
  getMoiDetailsBrand,
  getMoiPremiumAuctionAuction_idTodo,
  getMoiPremiumTodo_templates,
  postMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid,
  postMoiAuctionManageAuction_idPaymentsInfluencer_idChange_recurring_payment,
  postMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront,
  postMoiAuctionManageAuction_idToggle_influencers_next_month,
  postMoiAuctionManageBoard_idInfluencer_idPost_link,
  postMoiAuctionManageBoard_idInfluencer_idTask_create_link,
  postMoiAuctionManageBoard_idStory_view_countInfluencer_id,
  postMoiAuctionsAuction_idCouponsInfluencer_idPixel_inject,
  postMoiAuctionsIdProducts_sales_roi,
  postMoiAuctionsRoiMedia,
  postMoiAuctionsRoiSlides,
  postMoiAuctionsRoi_reach_frequencies,
  postMoiDetailsBrand,
  postMoiInvitesExternal,
  postMoiPremiumAuctionAuction_idTodo,
  postMoiPremiumAuctionAuction_idTodoTo_do_idComplete,
  postMoiPremiumAuctionAuction_idTodo_from_template,
  postMoiPremiumAuctionAuction_idTodo_reorder,
  postMoiPremiumAuctionsAuction_idSearch_content,
  postMoiPremiumTodo_templates,
  putMoiDetailsBrandBrand_id,
  putMoiPremiumAuctionAuction_idTodoTo_do_id,
  putMoiPremiumTodo_templatesTemplate_idTodoTo_do_id,
} from "../services";

export const useAddExtraPayout = () => {
  const dispatch = useDispatch();
  const query = usePostMoiAuctionManageAuction_idInfluencer_idExtra_payout();

  const mutateAsync = useCallback<typeof query.mutateAsync>(
    async (arg: any) => {
      const res = await query.mutateAsync(arg);
      dispatch({
        type: "ADD_EXTRA_PAYOUT",
        payload: {
          influencer_id: arg.influencer_id,
          item: {
            id: res.data.id,
            influencer_id: arg.influencer_id,
            amount: arg.requestBody.amount,
            comment: arg.requestBody.comment,
            month_relevance: arg.requestBody.month_relevance,
          },
        },
      });

      return res;
    },
    [query, dispatch],
  );
  return {
    ...query,
    mutateAsync,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mutate: undefined,
  };
};

export const useDeleteExtraPayout = () => {
  const dispatch = useDispatch();
  const query =
    useDeleteMoiAuctionManageAuction_idInfluencer_idExtra_payoutExtra_payout_id();

  const mutateAsync = useCallback<typeof query.mutateAsync>(
    async (arg: any) => {
      const res = await query.mutateAsync(arg);
      dispatch({ type: "DELETE_EXTRA_PAYOUT", payload: arg });

      return res;
    },
    [query, dispatch],
  );
  return {
    ...query,
    mutateAsync,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mutate: undefined,
  };
};

export const useShareInviteInfluencers =
  usePutMoiPremiumAuctionAuction_idShareInvite_influencers;

export const getProductsSalesROI = postMoiAuctionsIdProducts_sales_roi;
export const postInjectCoupons =
  postMoiAuctionsAuction_idCouponsInfluencer_idPixel_inject;
export const deleteCoupon = deleteMoiAuctionsAuction_idCoupons;

export const getInvitesLinkSocialNetworks = postMoiInvitesExternal;

/* =============== Todo List ================ */
export const creatTodoItem = postMoiPremiumAuctionAuction_idTodo;
export const getTodoItems = getMoiPremiumAuctionAuction_idTodo;
export const deleteTodoItem = deleteMoiPremiumAuctionAuction_idTodoTo_do_id;
export const editTodoItem = putMoiPremiumAuctionAuction_idTodoTo_do_id;
export const setTodoItemCompleted =
  postMoiPremiumAuctionAuction_idTodoTo_do_idComplete;
export const editReorder = postMoiPremiumAuctionAuction_idTodo_reorder;
export const saveToTemplate = postMoiPremiumTodo_templates;
export const getTemplates = getMoiPremiumTodo_templates;
export const importTemlate = postMoiPremiumAuctionAuction_idTodo_from_template;
export const deleteTemplate = deleteMoiPremiumTodo_templatesTemplate_id;
export const deleteTaskInTemplate =
  deleteMoiPremiumTodo_templatesTemplate_idTodoTo_do_id;
export const editTodoItemTemplate =
  putMoiPremiumTodo_templatesTemplate_idTodoTo_do_id;
/* =============== End Todo List ================ */

export const attachFileToInfluencer =
  postMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid;

export const getUploadAuctionFilesUrl =
  getMoiAuctionManageAuction_idFilesInfluencer_id;

export const getFileUrlFromUUID =
  getMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid;

export const deleteFilesFromInfluencer =
  deleteMoiAuctionManageAuction_idFilesInfluencer_id;

export const deleteInvoice = deleteMoiAuctionsAuction_idUpload_invoice;

export const getBrandsList = getMoiDetailsBrand;
export const deleteBrandFromList = deleteMoiDetailsBrandBrand_id;
export const createNewBrand = postMoiDetailsBrand;
export const editBrandName = putMoiDetailsBrandBrand_id;

export const getInfluencerUploads =
  postMoiPremiumAuctionsAuction_idSearch_content;

export const attachPost = postMoiAuctionManageBoard_idInfluencer_idPost_link;
export const attachStory =
  postMoiAuctionManageBoard_idStory_view_countInfluencer_id;

export const attachNewTask =
  postMoiAuctionManageBoard_idInfluencer_idTask_create_link;
export const changeBasePrice =
  postMoiAuctionManageAuction_idPaymentsInfluencer_idChange_recurring_payment;

export const addUpfrontPayment =
  postMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront;

export const deleteUpfrontPayment =
  deleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront;

export const getAuctionMinData = getMoiAuctionsAuction_idLast_month_for_roi;

export const useStopAffiliation =
  usePostMoiAuctionManageAuction_idPaymentsInfluencer_idStop_affiliation;

export const toggleInfluencersNextMonth =
  postMoiAuctionManageAuction_idToggle_influencers_next_month;
export const postAuctionsRoiSlides = postMoiAuctionsRoiSlides;
export const getAuctionsRoiSlides = getMoiAuctionsRoiSlides;
export const deleteAuctionsRoiSlides = deleteMoiAuctionsRoiSlides;

export const extractAuctionsRoiMedia = postMoiAuctionsRoiMedia;

export const getAuctionGoals = getMoiAuctionAuction_idTargets;

export const useUpdateSubUserPermission = () => {
  const dispatch = useDispatch();
  const query = usePostMoiAdvertiserMini_users();

  const mutateAsync = useCallback<typeof query.mutateAsync>(
    async (arg: any) => {
      const res = await query.mutateAsync(arg);
      dispatch({
        type: "SET_SUB_USERS_PERMISSIONS",
        payload: {},
      });
      return res;
    },
    [query, dispatch],
  );
  return {
    ...query,
    mutateAsync,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mutate: undefined,
  };
};

export const getAuctionReachFrequency = postMoiAuctionsRoi_reach_frequencies;
