import {
  Avatar,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { RequiredBy, RequireOnlyOne } from "src/declarations/UtilityTypes";

type Params = RequireOnlyOne<
  {
    title?: React.ReactNode;
    titleIconClassName?: string;
    body: React.ReactNode;
    overlay?: boolean;
    onClose?: () => void;
  },
  "title" | "titleIconClassName"
>;
type ParamsWithDefaults = RequiredBy<Params, "overlay">;

export const PromptContext = React.createContext<{
  open: (params: Params) => () => void;
}>({ open: () => () => {} });

export const PromptProvider: React.FC = ({ children }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [params, setParams] = useState<ParamsWithDefaults | undefined>();
  const handleClose = useCallback(() => {
    onClose();
    if (params?.onClose) {
      params.onClose();
    }
  }, [onClose, params]);
  const handleOpen = useCallback(
    (innerParams: Params) => {
      const paramsWithDefaults: ParamsWithDefaults = {
        ...innerParams,
        overlay: innerParams.overlay ?? true,
      };
      setParams(paramsWithDefaults);
      onOpen();
      return onClose;
    },
    [onOpen, onClose],
  );

  return (
    <PromptContext.Provider
      value={{
        open: handleOpen,
      }}
    >
      {children}
      {params ? (
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          variant={params.titleIconClassName ? "iconTitle" : undefined}
        >
          {params.overlay ? <ModalOverlay /> : undefined}
          <ModalContent>
            <ModalHeader>
              {params.title ?? (
                <Center py="2">
                  <Avatar
                    bg="white"
                    size="xl"
                    color="brand.500"
                    icon={
                      <i
                        className={params.titleIconClassName}
                        style={{ fontSize: "56px" }}
                      />
                    }
                  />
                </Center>
              )}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>{params.body}</ModalBody>
          </ModalContent>
        </Modal>
      ) : undefined}
    </PromptContext.Provider>
  );
};
