/* eslint-disable no-nested-ternary */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";

interface HumanzDismissableAlertProps extends AlertProps {
  /**
   * @default false
   */
  title: string;
  description: string;
  status?: "info" | "warning" | "success" | "error" | "loading";
  icon?: any;
  open?: boolean;
  dismissable?: boolean;
}

const HumanzDismissableAlert = ({
  title,
  description,
  status,
  icon,
  open,
  dismissable,
  ...props
}: HumanzDismissableAlertProps) => {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: dismissable ? true : open });

  return isVisible ? (
    <Alert
      {...props}
      className="clickable"
      onClick={onClose}
      status={status || "info"}
    >
      <Flex flex={1} justifyContent={"space-between"}>
        <Flex>
          {icon ? (
            <AlertIcon alignItems={"center"} justifyContent="center">
              {icon}
            </AlertIcon>
          ) : (
            <AlertIcon />
          )}
          <Box>
            <AlertTitle fontWeight={600}>{title}</AlertTitle>
            <AlertDescription>{description}</AlertDescription>
          </Box>
        </Flex>
        <Button variant={"unstyled"} position="relative" right={-1} top={-1}>
          <i className={!dismissable ? "fal fa-chevron-up" : "fal fa-times"} />
        </Button>
      </Flex>
    </Alert>
  ) : dismissable ? null : (
    <Alert {...props} onClick={onOpen} status={status || "info"} p={0}>
      <Button variant={"unstyled"} w={"100%"}>
        <Flex>
          <Flex flex={1}>
            <AlertIcon alignItems={"center"} justifyContent="center">
              {icon}
            </AlertIcon>
            <AlertTitle fontWeight={500}>{title}</AlertTitle>
          </Flex>
          <Box>
            <i className="fal fa-chevron-down" />
          </Box>
        </Flex>
      </Button>
    </Alert>
  );
};

export default HumanzDismissableAlert;
