import axios from "axios";
import { toast } from "react-toastify";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";
import { store } from "../store";
import { setShareKey } from "./shareActions";

const ambassadorUrlBase = () => `${getBackendUri()}/moi/ambassador_reports`;
const ambassadorSharesUrl = () => `${getBackendUri()}/shares/ambassador_report`;

export const getSharedAmbassador = (ambassadorId, callback) => {
  return () => {
    axios
      .get(`${ambassadorUrlBase()}/${ambassadorId}/share`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        callback();
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get shared list data",
          "system_notifications_failure_ambassador_share_get",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getAmbassadorShareGeneral = (authKey, callback) => {
  axios
    .get(`${ambassadorSharesUrl()}/share_details`, {
      withCredentials: true,
      headers: { "auth-key": authKey },
    })
    .then((response) => {
      if (callback) callback(response.data);
    })
    .catch(() => {
      if (callback) callback(false);
    });
};
export const getAmbassadorShareDetails = (
  ambassadorId,
  key,
  password,
  callback,
) => {
  axios
    .get(ambassadorSharesUrl(), {
      withCredentials: true,
      headers: { "auth-key": key, "auth-key-password": password },
    })
    .then((response) => {
      setShareKey(key, password);
      if (response.data.owner_details) {
        store.dispatch({
          type: "SET_SHARED_USER_DETAILS",
          payload: response.data.owner_details,
        });
      }
      store.dispatch({
        type: "SET_AMBASSADOR_SHARE_DETAILS",
        payload: response.data,
      });
      callback(response.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const shareAmbassador = (ambassadorId, sharedData, callback) => {
  return (dispatch) => {
    axios
      .put(`${ambassadorUrlBase()}/${ambassadorId}/share`, sharedData, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "SHARE_AMBASSADOR_REPORT_FULFILLED",
          payload: {
            list_id: ambassadorId,
            share_notes: sharedData.share_comments,
            shared_link: response.data.shared_link,
          },
        });
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        callback();
        dispatch({ type: "SHARE_AMBASSADOR_REPORT_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to share list",
          "system_notifications_failure_ambassador_share",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const updateShareAmbassador = (
  ambassadorId,
  shareGiud,
  data,
  callback,
) => {
  return (dispatch) => {
    axios
      .put(`${ambassadorUrlBase()}/${ambassadorId}/share/${shareGiud}`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        dispatch({
          type: "UPDATE_SHARE_AMBASSADOR_REPORT_FULFILLED",
          payload: { ambs_id: ambassadorId },
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        callback();
        dispatch({
          type: "UPDATE_SHARE_AMBASSADOR_REPORT_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to update shared ambassador",
          "system_notifications_failure_ambassador_share_update",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};
