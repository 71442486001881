/* eslint-disable no-nested-ternary */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Link,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import { StagesAccordion } from "../StagesAccordion";

interface Props {
  isLoading: boolean;
  nextStep: any;
  platform: any;
  website: string;
  checkEvent: any;
  campaignEvent: any;
  randomUtmStr?: any;
}

export const InitScreen = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isIniting, setIsIniting] = useState(false);
  const utmsUrl = `${props?.website}?hmz_influencer=${props.randomUtmStr}`;

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const steps: any = [
    {
      id: 0,
      title: "Open your website URL",
      body: (
        <Flex>
          <div className="preview-description">
            <Box>
              <p>
                <Flex justifyContent={"space-between"}>
                  <span>
                    We need you to open your website on this specific URL so we
                    can detect that the campaign event is being sent and we are
                    getting the relevant data from it.
                    <br /> Please wait until your website will be fully loaded.
                    <br />
                    <br />
                  </span>
                  <span style={{ fontSize: 40, marginLeft: "20px" }}>
                    <i className="fal fa-stars" />
                  </span>
                </Flex>
                <Alert status="info">
                  <AlertIcon />
                  You are going to open your website with those url parameters:{" "}
                  <br />
                  {utmsUrl}
                </Alert>
                <Link
                  href={utmsUrl}
                  target={"_blank"}
                  onClick={() => setCurrentStep(1)}
                >
                  <Center>
                    <Button m={5} size="lg" colorScheme={"green"}>
                      Open my website &nbsp;
                      <i className="far fa-external-link" />
                    </Button>
                  </Center>
                </Link>
              </p>
            </Box>
          </div>
        </Flex>
      ),
    },
    {
      id: 1,
      title: "Validate campaign event",
      body: (
        <>
          <Flex>
            <div className="preview-description">
              <Box>
                <p>
                  When your website is fully loaded, please click the button
                  below:
                  <br />
                  <Center>
                    <Button
                      colorScheme={"green"}
                      variant={
                        props.campaignEvent?.is_valid ? "outline" : "solid"
                      }
                      size="lg"
                      disabled={props.isLoading}
                      isLoading={props.isLoading}
                      m={5}
                      rightIcon={<i className="far fa-play" />}
                      onClick={() => {
                        setIsIniting(true);
                        props.checkEvent("campaign", {}, () => {});
                      }}
                    >
                      Check for a campaign event
                    </Button>
                  </Center>
                </p>
              </Box>
              {isIniting && (
                <Box>
                  {props.campaignEvent?.is_valid ? (
                    <>
                      <Alert status="success" justifyContent={"center"}>
                        <AlertIcon />
                        <span>Campaign event Found!</span>
                      </Alert>
                      <Box mt={5}>Please proceed to check the next event.</Box>
                    </>
                  ) : props.isLoading ? (
                    <>
                      <Alert status="info">
                        <Spinner />{" "}
                        <span>&nbsp;Checking for campaign event...</span>
                      </Alert>
                    </>
                  ) : (
                    <Alert status="error">
                      <AlertIcon />{" "}
                      <span>
                        We didn&apos;t found the campaign event. Please try
                        again.
                      </span>
                    </Alert>
                  )}{" "}
                </Box>
              )}
            </div>
          </Flex>
        </>
      ),
    },
  ];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <StagesAccordion
      currentStep={currentStep}
      isLoading={props.isLoading}
      nextStep={nextStep}
      prevStep={prevStep}
      steps={steps}
      completeStage={props.nextStep}
      description={
        <>
          We will now check for the initialization of the Humanz pixel. <br />{" "}
          Please follow the instructions below
        </>
      }
      eventValidation={props.campaignEvent?.is_valid}
      setStep={(step: any) => () => setCurrentStep(step.id)}
    />
  );
};
