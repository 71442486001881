// TODO - lint
/* eslint-disable class-methods-use-this, class-methods-use-this, camelcase, camelcase, camelcase, camelcase, camelcase, import/no-default-export */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import mime from "mime";
import React from "react";
import ReactImageMagnify from "react-image-magnify";
import { toast } from "react-toastify";
import { getFileUrlFromUUID } from "src/api/actions/auctionActions";
import { InfiniteLoader } from "../../general_components/InfiniteLoader";

interface ViewFileDialogProps {
  uuid: string;
  influencerId: number;
  auctionId: number;
  onClose: () => void;
}

interface FilePreviewProps {
  width: number | string;
  height: number | string;
  url: string;
  imageZoomWidth?: number;
  imageZoomHeight?: number;
}

export const FilePreview = ({
  width,
  height,
  url,
  imageZoomWidth,
  imageZoomHeight,
}: FilePreviewProps) => {
  const [type, setType] = React.useState("" as string);
  React.useEffect(() => {
    if (url) {
      const newType = mime.getType(url?.split("?")[0]);
      if (typeof newType === "string") {
        setType(newType);
      }
    }
  }, [url]);

  if (!url) {
    return null;
  }

  if (type.includes("image")) {
    return (
      <div className="card" style={{ width, height, overflow: "auto" }}>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: "Invoice",
              isFluidWidth: true,
              src: url,
            },
            largeImage: {
              src: url,
              width: imageZoomWidth || 0,
              height: imageZoomHeight || 0,
            },
          }}
          enlargedImagePosition={"over"}
        />
      </div>
    );
  }

  return <embed type={type} width={width} height={height} src={url} />;
};

export const ViewFileDialog = (props: ViewFileDialogProps) => {
  const [url, setUrl] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    getFileUrlFromUUID(props.influencerId, props.auctionId, props.uuid)
      .then((res) => {
        setLoading(false);
        setUrl(res.data.url);
      })
      .catch(() => {
        toast.error("Failed to open the file");
      });
  }, [props.uuid]);

  return (
    <Modal isOpen={true} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent maxW={650} maxH={700}>
        <ModalHeader>File</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={6}>
          {loading ? (
            <InfiniteLoader />
          ) : (
            <>
              <FilePreview
                width={600}
                height={700}
                imageZoomWidth={1200}
                imageZoomHeight={1800}
                url={url}
              />
              <a href={url} target="_blank" className="btn" rel="noreferrer">
                Open in a new window <i className="far fa-external-link" />
              </a>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
