import { useGetMoiPremiumLists_with_scrolling } from "../hooks";
import {
  deleteMoiShadow_influencersShadow_influencer_id,
  getMoiInvitesExternal,
  getMoiPremiumListsList_idForm_data,
  getMoiPremiumLists_with_scrolling,
  getMoiShadow_influencers,
  postMoiCheck_influencers_agents,
  postMoiPremiumListsList_idForm_dataMore_answers,
  postMoiShadow_influencers,
  postMoiShadow_influencersShadow_influencer_id,
} from "../services";

export const getListsScrolling = {
  key: getMoiPremiumLists_with_scrolling.key,
  useQuery: (params: {
    archive?: boolean;
    favorites?: boolean;
    tagIds?: number[];
    search?: string;
    lastSeenTime?: string;
    childId?: number;
  }) =>
    useGetMoiPremiumLists_with_scrolling({
      archived_lists: params.archive,
      only_favorite_lists: params.favorites,
      filter: params.search,
      tags: params.tagIds,
      last_seen_time: params.lastSeenTime,
      child_id: params.childId,
    }),
};

export const getExternalInvitations = getMoiInvitesExternal;
export const getShadowInfluencers = getMoiShadow_influencers;
export const createShadowInfluencer = postMoiShadow_influencers;
export const editShadowInfluencer =
  postMoiShadow_influencersShadow_influencer_id;
export const deleteShadowInfluencer =
  deleteMoiShadow_influencersShadow_influencer_id;

export const getListFormData = getMoiPremiumListsList_idForm_data;
export const getMoreListAnswers =
  postMoiPremiumListsList_idForm_dataMore_answers;

export const CheckForAutoAgents = (agents: number[], callback: any) => {
  return (dispatch: any) => {
    postMoiCheck_influencers_agents({ influencers: agents }, callback)
      .then((res: any) => {
        callback(res);
        dispatch({
          type: "AGENTS_RECIEVED",
          payload: { data: res },
        });
      })
      .catch(() => {
        callback(false);
        dispatch({ type: "AGENTS_ERROR" });
      });
  };
};
