import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { writeLog } from "src/actions/utilsActions";
import {
  attachNewTask,
  attachPost,
  attachStory,
} from "src/api/actions/auctionActions";
import {
  InfluencerInManageAuctionData,
  LinkedTaskPost,
  PostTypes,
  StoryViewUpload,
  TaskManageData,
  TaskWithPost,
} from "src/api/types";
import { getSocialMediaLinkByContentType } from "src/helpers/SocialNetworksHelper";
import uuid4 from "uuid";
import {
  getLabelFromValue,
  get_post_type_icon,
} from "../../../utilities/providers";
import AttachTasksList from "./AttachTasksList";
import MediaFrame from "./MediaFrame";
import NewTaskDialog from "./NewTaskDialog";

type Props = {
  key: number;
  postType: PostTypes;
  id: string;
  post: any;
  influencerTasks: TaskManageData[];
  influencer: InfluencerInManageAuctionData;
  board_id: number;
  removeMedia: (id: string) => void;
  month: string;
};

const AttachMediaFrame = (props: Props) => {
  const [showTasks, setShowTasks] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [shouldRender, setShouldRender] = useState(true);
  const fadeOutRef = useRef(null);
  const dispatch = useDispatch();
  const attachTaskRef = useRef(null);
  const board = useSelector((store) => store.boardReducer.board);
  const [showTaskDialog, setOpenTaskDialog] = useState(false);
  const [views, setViews] = useState<StoryViewUpload[]>([]);

  const handleClickOutside = (event: any) => {
    if (
      attachTaskRef.current &&
      !attachTaskRef.current.contains(event.target)
    ) {
      setShowTasks(false);
    }
  };
  const updateBoard = (requestBody: any) => {
    const influencerIndex = board.states.approval.influencers.findIndex(
      (inf: InfluencerInManageAuctionData) => {
        return inf.influencer_id === props.influencer.influencer_id;
      },
    );
    const taskIndex = board.states.approval.influencers[
      influencerIndex
    ].tasks.findIndex((t: TaskManageData) => {
      return t.id === requestBody[0].task_id;
    });
    const payload = {
      ...board,
      states: {
        ...board.states,
        approval: {
          ...board.states.approval,
          influencers: [
            ...board.states.approval.influencers.map(
              (influencer: InfluencerInManageAuctionData, i: number) =>
                i === influencerIndex
                  ? {
                      ...influencer,
                      tasks: [
                        ...influencer.tasks_details.tasks.map(
                          (tsk: TaskManageData, j: number) =>
                            j === taskIndex
                              ? {
                                  ...tsk,
                                  linked_media_id: props.id,
                                }
                              : tsk,
                        ),
                      ],
                    }
                  : influencer,
            ),
          ],
        },
      },
    };
    dispatch({
      type: "UPDATE_BOARD_DATA_FULLFILLED",
      payload,
    });
  };

  const getAllStorySubtasks = (id: number) => {
    const igStoryTasks = props.influencer.tasks_details.tasks.filter(
      (task: TaskManageData) => task.post_type === "ig_story",
    );
    const storySubtasks: StoryViewUpload[] = [];

    igStoryTasks.forEach((task: TaskManageData) => {
      if ((task.id === id || task.subtask_of === id) && task.fb_id) {
        if (task.id === id) {
          storySubtasks.push({
            fb_id: task.fb_id,
            id: task.linked_media_id,
            is_subtask: false,
            task_id: id,
          });
        } else {
          storySubtasks.push({
            fb_id: task.fb_id,
            id: task.linked_media_id,
            is_subtask: true,
            task_id: id,
          });
        }
      }
    });
    setViews(storySubtasks);
    return storySubtasks;
  };

  const fadeOutPost = () => {
    setFadeOut(true);
    setTimeout(() => {
      setFadeOut(false);

      setShouldRender(false);
    }, 500);
  };

  const handleAttachPost = (task: TaskManageData) => {
    writeLog({
      message: "handleAttachPost",
      event_name: "website_attach_tasks_from_search",
      log_level: "info",
    });
    const requestBody: LinkedTaskPost[] = [
      {
        post_uri: getSocialMediaLinkByContentType(props.postType, props.id),
        post_type: props.postType,
        internal_media_id: props.id,
        task_id: task.id,
      },
    ];
    if (task.post_type === "ig_story") {
      const storySubtasks = getAllStorySubtasks(task.id);
      attachStory(props.board_id, props.influencer.influencer_id, {
        views: [
          ...views,
          {
            fb_id: props.post.media_id,
            id: uuid4(),
            is_subtask: storySubtasks.length > 0,
            sub_task_of: storySubtasks.length > 0 ? task.id : null,
            task_id: storySubtasks.length > 0 ? null : task.id,
          },
        ],
      })
        .then(() => {
          setViews([
            ...views,
            {
              fb_id: props.post.media_id,
              id: uuid4(),
              is_subtask: storySubtasks.length > 0,
              task_id: task.id,
            },
          ]);
          props.removeMedia(props.id);
        })
        .catch(() => {
          console.log("fail attach story");
        });
    } else {
      attachPost(props.influencer.influencer_id, props.board_id, requestBody)
        .then(() => {
          updateBoard(requestBody);
          fadeOutPost();
          props.removeMedia(props.id);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleNewTask = (
    influencer_id: number,
    board_id: number,
    post: any,
  ) => {
    writeLog({
      message: "handleNewTask",
      event_name: "website_attach_new_task_from_search",
      log_level: "info",
    });
    const postUri = getSocialMediaLinkByContentType(
      post.post_type,
      post.media_id,
    );
    const requestBody: TaskWithPost = {
      tasks_modifications: {
        month_relevance: props.month,
        excluded: [],
        new: [
          {
            post_type: post.post_type,
            tags: ["#ad"],
            due_date: null,
            content_due_date: null,
            description: "",
            additonal_price: 0,
            month_relevance: props.month,
          },
        ],
      },
      ...(props.post.post_type === "ig_story"
        ? {
            views: {
              views: [
                {
                  fb_id: post.media_id,
                  influencer_fk: influencer_id,
                  image_url: post.media_url,
                  id: uuid4(),
                  is_subtask: false,
                  task_id: 0,
                },
              ],
            },
          }
        : {
            linked_post: {
              manual_post_stats: null,
              boosted_impressions: 0,
              internal_media_id: post.media_id,
              media_uid: post.media_id,
              post_type: post.post_type,
              post_uri:
                props.post.post_type !== "ig_story"
                  ? postUri
                  : props.post.media_url,
            },
          }),
    };

    attachNewTask(influencer_id, board_id, requestBody)
      .then(() => {
        fadeOutPost();
        props.removeMedia(post.media_id);
      })
      .catch((e) => console.log("cant attach", e));
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return shouldRender ? (
    <div
      key={props.key}
      ref={fadeOutRef}
      style={{
        background: "#EBEBEB",
        padding: 5,
        minHeight: "342px",
        borderRadius: "8px",
        position: "relative",
        transition: "opacity 0.5s",
        opacity: fadeOut ? 0 : 1,
        width: 270,
        display: "inline-block",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 10,
          background: "#F3F6F9",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "5px",
          flexGrow: 1,
        }}
      >
        <i className={get_post_type_icon(props.postType)} />
        <span>{getLabelFromValue(props.postType)}</span>
      </div>
      <MediaFrame post_type={props.postType} id={props.id} post={props.post} />
      {showTasks && (
        <AttachTasksList
          influencer={props.influencer}
          setShowTasks={setShowTasks}
          handleAttachPost={handleAttachPost}
          showTasks={showTasks}
          board_id={props.board_id}
          post={props.post}
          removeMedia={props.removeMedia}
          setOpenTaskDialog={setOpenTaskDialog}
        />
      )}
      {showTaskDialog && (
        <NewTaskDialog
          handleClose={() => setOpenTaskDialog(false)}
          confirmLabel="create"
          confirm={() => {
            setOpenTaskDialog(false);
            handleNewTask(
              props.influencer.influencer_id,
              props.board_id,
              props.post,
            );
          }}
          post={props.post}
          influencer={props.influencer}
        />
      )}
      <div
        ref={attachTaskRef}
        className="attach-task hover-effect-attach"
        style={{
          position: "absolute",
          bottom: 5,
          width: "260px",
        }}
        onClick={() => setShowTasks(!showTasks)}
      >
        <i
          className="fa-solid fa-plus fa-sm"
          style={{ marginRight: "3px" }}
        ></i>
        <span>Attach Task</span>
      </div>
    </div>
  ) : null;
};

export default AttachMediaFrame;
