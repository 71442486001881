import {
  theme,
  type Button,
  type ComponentSingleStyleConfig,
} from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/theme-tools";
import React from "react";

export const ButtonTheme: ComponentSingleStyleConfig = {
  baseStyle: {
    maxW: "100%",
    whiteSpace: "normal",
    wordWrap: "break-word",
    wordBreak: "break-all",
    height: "auto",
    padding: "0",
    lineHeight: "initial",
    color: "inherit",
    cursor: "pointer",
    backgroundColor: "transparent",
    textTransform: "none",
    outline: "none",
    border: "0",
    _disabled: {
      cursor: "initial",
      opacity: 0.6,
      pointerEvents: "none",
    },
  },
  variants: {
    shadowed: (
      props: StyleFunctionProps & React.ComponentProps<typeof Button>,
    ) => ({
      ...theme.components.Button.variants.solid({
        ...props,
        colorScheme: props.type === "button" ? "gray" : props.colorScheme,
      }),
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: 10,
    }),
    form: (
      props: StyleFunctionProps & React.ComponentProps<typeof Button>,
    ) => ({
      ...theme.components.Button.variants.solid({
        ...props,
        colorScheme: props.type === "button" ? "gray" : props.colorScheme,
      }),
      minW: 36,
      borderRadius: "full",
    }),
    outline: (
      props: StyleFunctionProps & React.ComponentProps<typeof Button>,
    ) => ({
      ...theme.components.Button.variants.outline(props),
      borderColor: `${props.colorScheme}.500`,
      color: `${props.colorScheme}.500`,
    }),
    roundedWhite: (
      props: StyleFunctionProps & React.ComponentProps<typeof Button>,
    ) => ({
      ...theme.components.Button.variants.solid(props),
      background: "white",
      backgroundColor: "white",
      color: "gray",
      textWrap: "nowrap",
      _hover: {
        color: `${props.colorScheme}.500`,
        _disabled: {
          color: "gray",
        },
      },
      _selected: {
        color: `${props.colorScheme}.500`,
      },
      _active: {
        color: `${props.colorScheme}.500`,
      },
      borderRadius: 200,
    }),
    "ghost-non-focusable": (
      props: StyleFunctionProps & React.ComponentProps<typeof Button>,
    ) => ({
      ...theme.components.Button.variants.ghost(props),
      _focus: {
        boxShadow: "none",
      },
    }),
    "small-action": (
      props: StyleFunctionProps & React.ComponentProps<typeof Button>,
    ) => ({
      ...theme.components.Button.variants.outline(props),
      background: "#FFFFFF",
      boxShadow: "0px 1.33469px 2.33571px rgba(0, 0, 0, 0.17)",
      borderRadius: "6px",
      border: "none",
      color: `${props.colorScheme}.500`,
      padding: "4px",
      wordBreak: "break-word",
      fontSize: "10px",
    }),
    "small-action-white": (
      props: StyleFunctionProps & React.ComponentProps<typeof Button>,
    ) => ({
      ...theme.components.Button.variants.outline(props),
      background: "#FFFFFF",
      boxShadow: "0px 1.33469px 2.33571px rgba(0, 0, 0, 0.17)",
      borderRadius: "6px",
      border: "none",
      color: `${props.colorScheme}.500`,
      padding: "2px",
      wordBreak: "break-word",
      fontSize: "12px",
      fontWeight: 500,
      maxHeight: "25px",
      "&:hover": {
        background: "var(--chakra-colors-brand-50)",
      },
    }),
  },
  defaultProps: {
    colorScheme: "brand",
  },
};
