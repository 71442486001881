import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";

interface Props {
  currentStep: any;
  steps: Array<any> | null;
  prevStep: any;
  nextStep: any;
  setStep: any;
  completeStage: any;
  isLoading: boolean;
  eventValidation: boolean;
  description: any | null;
  allowNextSteps?: boolean;
}

export const StagesAccordion = (props: Props) => {
  const accordElem = useRef(null);
  const { currentStep, steps, prevStep, nextStep } = props;
  useEffect(() => {
    setTimeout(() => {
      if (accordElem?.current?.children[currentStep]) {
        accordElem.current.children[currentStep].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 200);
  }, [currentStep]);
  return (
    <Box>
      <p style={{ textAlign: "center", fontSize: "15px" }}>
        {props.description}
      </p>
      <Accordion animation={0} index={[currentStep]} ref={accordElem}>
        {steps.map((step: any) => (
          <AccordionItem key={`acc_${step.id}`}>
            <strong>
              <AccordionButton onClick={props.setStep(step)}>
                <Box flex="1" textAlign="left" fontSize={15}>
                  {step.title}
                </Box>
                <span style={{ fontSize: 24 }}>
                  {step.id < currentStep ? (
                    <span style={{ color: "#38a169" }}>
                      <i className="fa fa-check-circle" />
                    </span>
                  ) : (
                    <i className="fa fa-circle-o" />
                  )}{" "}
                </span>
              </AccordionButton>
            </strong>
            <AccordionPanel pb={4}>
              {step.body}
              <Flex justifyContent={"space-between"} mt={5}>
                <Button
                  isDisabled={currentStep === 0}
                  variant={"outline"}
                  onClick={() => prevStep()}
                >
                  Previous Step
                </Button>
                <Button
                  isDisabled={props.isLoading || !props.eventValidation}
                  colorScheme={
                    currentStep === steps.length - 1 ? "green" : null
                  }
                  onClick={() =>
                    currentStep === steps.length - 1
                      ? props.completeStage()
                      : nextStep()
                  }
                >
                  {currentStep === steps.length - 1
                    ? "Complete Stage"
                    : "Next Step"}
                </Button>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};
