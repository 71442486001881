// TODO - lint
/* eslint-disable  */
// General Imports
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  deletePaymentMethod, finalizePeachTokenize, getPaymentsMethods, purchaseInsightsPackage, tokenizeCreditCard, upgradeToPremium
} from "../../../actions/paymentActions";
// Actions imports
import {
  editUserBillingAddress, getUserBillingAddress
} from "../../../actions/userActions";
import { MessageDialog } from "../dialogs/MessageDialog";
import CreditCardForm from "../payments_components/CreditCardForm";
// Components Import
import { Currencies, numberWithCommas } from "../utilities/general";



class PaymentMethodTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardDetails: {
        number: "",
        name: "",
        expiry: "",
        cvc: "",
        currency: "USD",
        focused: null,
      },
      cardAddress: {
        address: "",
        address_line_2: "",
        city: "",
        country: "",
        zip: "",
        phone: "",
        tax_number: "",
      },
      cards: [],
      payment_mode: -1,
      card_saving: false,
      paymentDetails: {
        currency: "USD",
        package: -1,
      },
      selectedPackage: null,
      insightsPricing: null,
      showInsightsPaymentsDialog: null,
      ccAuthRedirectPage: false,
      ccAuthRedirectData: null,
    };

    this.countries = require("../utilities/countries.json");
    this.countries = this.countries.data;

    this.PRICE_PER_MONTH = {
      USD: "1,500",
      NIS: "5,500",
      GBP: "1,150",
      EUR: "1,350",
      ZAR: "19,950",
      AUD: "2,200",
      NZD: "2,300",
      TRY: "8,500",
    };

    this.PRICE_PER_POSTS = {
      USD: {
        0: 200,
        1: 380,
        2: 950,
      },
      AUD: {
        0: 280,
        1: 530,
        2: "1,300",
      },
      NZD: {
        0: 300,
        1: 560,
        2: "1,400",
      },
      NIS: {
        0: 725,
        1: "1,350",
        2: "3,400",
      },
      GBP: {
        0: 150,
        1: 290,
        2: 720,
      },
      EUR: {
        0: 175,
        1: 330,
        2: 830,
      },
      ZAR: {
        0: "2,800",
        1: "5,300",
        2: "13,200",
      },
      TRY: {
        0: "1,100",
        1: "2,000",
        3: "4,800",
      },
    };

    this.POSTS_PACKEGES = [10, 20, 50];
  }

  componentWillMount() {
    this.props.getUserBillingAddress((data) => {
      if (data) {
        const details = this.props.user.userDetails;
        if (!data.country) {
          data.country = details.company_details.country;
        }
        if (!data.phone) {
          const codeCountries = require("../utilities/countries-codes.json");
          let code = data.country
            ? codeCountries.find((x) => x.code === data.country)
            : null;
          code = code ? code.dialCode : "972";
          data.phone = `+${code}`;
        }
        this.setState({ cardAddress: data });
        this.props.getPaymentsMethods((cards) => {
          if (!cards || cards.length === 0) {
            if (this.state.payment_mode === -1)
              this.setState({ payment_mode: 0 });
          } else this.setPaymentStatus(cards);
        });
      }
    });
  }

  componentDidMount() {
    if (sessionStorage.getItem("PEACH_PAYMENTS_DETAILS")) {
      this.setState({ payment_mode: 1 });
    } else if (sessionStorage.getItem("PAYMENTS_PAGE")) {
      try {
        const paymentPage = JSON.parse(
          sessionStorage.getItem("PAYMENTS_PAGE"),
        ).page;
        if (paymentPage === window.location.pathname)
          sessionStorage.removeItem("PAYMENTS_PAGE");
      } catch (e) {}
    }
  }

  setPaymentStatus(cards) {
    if (!cards) {
      this.setState({ payment_mode: -1 });
      return;
    }
    cards = cards.cards;
    if (cards && cards.length === 0) {
      this.setState({ payment_mode: 0 });
    } else {
      this.setState({ cards, payment_mode: 2 });
    }
  }

  deletePaymentMethod = (id) => {
    if (this.state.card_saving) return;
    this.setState({ card_saving: true });
    this.props.deletePaymentMethod(id, (succss) => {
      if (succss) {
        const { cards } = this.state;
        const idx = cards.indexOf(cards.find((x) => x.id === id));
        cards.splice(idx, 1);
        this.setState({ card_saving: false, cards, payment_mode: 0 });
      } else {
        this.setState({ card_saving: false });
      }
    });
    this.props.closeDeleteDialog();
  };

  upgradeToPremium() {
    if (this.state.card_saving) return;
    if (!this.state.cards || this.state.cards.length < 1) return;
    this.setState({ card_saving: true });
    this.props.upgradeToPremium(this.state.cards[0].id, () => {
      this.setState({ card_saving: false });
    });
  }

  buyInsightsPackage(currentInsightsPackagePrice, insightsPackage) {
    if (this.state.payment_mode !== 2) {
      this.setState({ payment_mode: 1 });
    } else {
      this.setState({
        showInsightsPaymentsDialog: true,
        paymentPackageToPurchase: {
          ...currentInsightsPackagePrice,
          posts_amount: this.POSTS_PACKEGES[insightsPackage],
        },
      });
    }
  }

  payInsightPackage = () => {
    const methodId = this.state.cards[0].id;
    const posts = this.state.paymentPackageToPurchase.posts_amount;
    const { currency } = this.state.paymentPackageToPurchase;
    this.props.purchaseInsightPackage(methodId, posts, currency, () => {
      this.setState({});
    });
    this.setState({ showInsightsPaymentsDialog: null });
  };

  handleAddedCreditCard = (response) => {
    const { cards } = response;
    if (cards && cards.length === 0) {
      this.setState({ payment_mode: 0 });
    } else if (this.state.selectedPackage === "premium") {
      this.props.upgradeToPremium(cards[0].id, () => {
        this.setState({ cards, payment_mode: 2, card_saving: false });
      });
    } else {
      this.setState({ cards, payment_mode: 2, card_saving: false });
    }
  };

  render() {
    return (
      <div style={{ marginTop: 30 }}>
        {this.state.payment_mode === -1 && (
          <div
            className="spinner small"
            style={{ marginTop: 40, marginBottom: 0 }}
          >
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        )}
        {/* {(this.state.payment_mode === 0 || this.state.payment_mode === 2) && this.renderPackagesTable(this.state.payment_mode === 2)} */}
        {(this.state.payment_mode === 0 || this.state.payment_mode === 2) &&
          this.renderFeautersScreen(this.state.payment_mode === 2)}
        {this.state.payment_mode === 1 && (
          <CreditCardForm
            showButtons={true}
            searchParams={this.props.searchParams}
            selectedPackage={this.state.selectedPackage}
            onSuccess={this.handleAddedCreditCard}
            goBack={() => {
              this.setState({ payment_mode: 0 });
              window.scrollTo(0, 0);
            }}
          />
        )}
        {this.state.payment_mode === 2 && this.state.cards && (
          <div className="create-tab-content" style={{ paddingTop: 0 }}>
            <div className="payments-method-section">
              <div className="campaigns-title">
                <span>Payment method</span>
              </div>
            </div>
            {this.state.cards.map(this.renderPaymentsDetails)}
          </div>
        )}
        {this.state.showInsightsPaymentsDialog &&
          this.renderInsightPaymentDialog()}
      </div>
    );
  }

  renderFeautersScreen(hasPayment) {
    if (hasPayment) return;
    const { paymentDetails, cardDetails, insightsPricing } = this.state;
    let currentInsightsPackagePrice = null;
    if (
      paymentDetails.package !== -1 &&
      insightsPricing &&
      cardDetails.currency in insightsPricing
    ) {
      const amountOfPosts = this.POSTS_PACKEGES[paymentDetails.package];
      currentInsightsPackagePrice =
        insightsPricing[cardDetails.currency][amountOfPosts];
    }
    const premium = this.props.user.userDetails.sidebar_data.is_premium;
    const postsLeft =
      this.props.user.userDetails.sidebar_data.max_number_of_reports_posts -
      this.props.user.userDetails.sidebar_data.current_number_of_reports_posts;
    return (
      <div className="main-lists-container">
        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary round-btn"
            onClick={() => {
              this.setState({
                payment_mode: 1,
                selectedPackage: "marketplace",
              });
            }}
            style={{ width: 265, fontSize: 20, height: 40, borderRadius: 4 }}
          >
            Add Payment Method
          </button>
        </div>
        {/* <PlatformPackages clickOnInsights={() => this.buyInsightsPackage(currentInsightsPackagePrice, paymentDetails.package)} /> */}
      </div>
    );
  }

  renderInsightPaymentDialog() {
    const paymentPackage = this.state.paymentPackageToPurchase;
    const { currency } = this.state.cardDetails;
    const total_price =
      paymentPackage.price +
      paymentPackage.price * (paymentPackage.vat / 100.0);
    return (
      <MessageDialog
        handleClose={() => this.setState({ showInsightsPaymentsDialog: null })}
        icon="fal fa-money-bill"
      >
        <div style={{ textAlign: "center", padding: 20 }}>
          <strong>Notice</strong>
          <div>
            You are about to pay to{" "}
            <strong>{paymentPackage.posts_amount}</strong> posts insights
            pacakge
            <div className="dialog-payment-details">
              <div>
                <div>PRICE</div>
                <div>VAT</div>
                <strong style={{ fontSize: 18 }}>TOTAL PRICE</strong>
              </div>
              <div>
                <div>
                  {window.translate("", `money_symbol_${currency}`)}
                  {numberWithCommas(paymentPackage.price)}{" "}
                </div>
                <div>{paymentPackage.vat}%</div>
                <strong style={{ fontSize: 18 }}>
                  {window.translate("", `money_symbol_${currency}`)}
                  {numberWithCommas(total_price)}{" "}
                </strong>
              </div>
            </div>
          </div>
          <button
            style={{ marginTop: 15, fontSize: 18, height: 35, width: 230 }}
            className={"show-content-btn btn btn-primary"}
            onClick={this.payInsightPackage.bind(this)}
          >
            PAY NOW
          </button>
        </div>
      </MessageDialog>
    );
  }

  renderUpgradeToPremium() {
    return (
      <div className="create-tab-content">
        <div className="payments-method-section">
          <div className="campaigns-title">
            <span style={{ color: "var(--main-baby-blue-color)" }}>
              Upgrade to <span style={{ fontWeight: "bold" }}>PREMIUM!</span>
            </span>
          </div>
          <div
            style={{ fontSize: 18, fontWeight: 300, marginTop: 25 }}
          >
            Premium users can access Unlimited data and create as many searches
            as possible
          </div>
        </div>
        <div className="upgrade-pre-box" style={{ marginTop: 25 }}>
          <div>
            <strong>Manage influencers lists and collaborate at scale</strong>
            <div style={{ marginTop: 15 }}>
              <span>Work with every influencer in your market</span>
            </div>
          </div>
          <div>
            <i className="fa fa-check" />
          </div>
        </div>

        <div className="upgrade-pre-box">
          <div>
            <strong>Manage content in campaings</strong>
            <div style={{ marginTop: 15 }}>
              <span>support every social platform</span>
            </div>
          </div>
          <div>
            <i className="fa fa-check" />
          </div>
        </div>
        <div className="upgrade-pre-box">
          <div>
            <strong>Detailed measurements for your campaings</strong>
            <div style={{ marginTop: 15 }}>
              <span>
                real impressions, engagement with your target audience
              </span>
            </div>
          </div>
          <div>
            <i className="fa fa-check" />
          </div>
        </div>
        <div
          style={{
            marginTop: 25,
            background: "var(--main-baby-blue-color)",
            borderRadius: 6,
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 40px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: 30, fontWeight: 300 }}>
              PREMIUM
            </div>
            <div style={{ fontSize: 18 }}>Subscription</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: 30, fontWeight: 300 }}>12</div>
            <div style={{ fontSize: 18 }}>Month</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: 30, fontWeight: 300 }}>
              ${this.PRICE_PER_MONTH.USD}
            </div>
            <div style={{ fontSize: 18 }}>Per month</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: 30, fontWeight: 300 }}>10%</div>
            <div style={{ fontSize: 18 }}>Media fee*</div>
          </div>
        </div>
        <div
          style={{
            color: "var(--main-baby-blue-color)",
            marginTop: 25,
            fontSize: 13,
          }}
        >
          <div>
            * A payment you make to influencers. Requires selecting and
            approving campaigns media{" "}
          </div>
          <div>** The first charge is made once you sign up </div>
          <div>
            ** You can unsubscribe at any time, canceling the subscription at
            the end of that month{" "}
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 25 }}
        >
          <button
            onClick={() => this.setState({ payment_mode: 1 })}
            className="btn btn-primary"
            style={{
              background: "rgb(37, 189, 126)",
              marginLeft: 10,
              border: "none",
              padding: "10px 40px",
              fontSize: 20,
            }}
          >
            Upgrade to Premium
          </button>
        </div>
      </div>
    );
  }

  renderPaymentsDetails = (card) => {
    const cardDate = moment(card.expiry_date).utc();
    const { cardAddress } = this.state;
    const premium = this.props.user.userDetails.sidebar_data.is_premium;
    return (
      <div key={card.id}>
        <div className="payment-details-container">
          <div
            style={{
              marginTop: 25,
              background: "var(--main-baby-blue-color)",
              borderRadius: 6,
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 40px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 30, fontWeight: 300 }}>
                {premium ? "PREMIUM" : "MARKETPLACE"}
              </div>
              <div style={{ fontSize: 18 }}>Subscription</div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 30, fontWeight: 300 }}>
                {premium ? "12" : "-"}
              </div>
              <div style={{ fontSize: 18 }}>Month</div>
            </div>
            <div style={{ textAlign: "center" }}>
              {premium ? (
                <div style={{ fontSize: 30, fontWeight: 300 }}>
                  {window.translate("", `money_symbol_${card.currency}`)}{" "}
                  {this.PRICE_PER_MONTH[card.currency]}
                </div>
              ) : (
                <div style={{ fontSize: 30, fontWeight: 300 }}>
                  FREE
                </div>
              )}
              <div style={{ fontSize: 18 }}>Per month</div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 30, fontWeight: 300 }}>
                {premium ? 10 : 15}%
              </div>
              <div style={{ fontSize: 18 }}>Media fee*</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 20,
              marginTop: 40,
            }}
          >
            <div style={{ fontSize: 13, width: "30%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                card holder name
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {card.name_on_card}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "23%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                card number
              </strong>
              <div
                style={{ color: "var(--main-baby-blue-color)", paddingLeft: 2 }}
              >
                XXXX-{card.last_digits}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "23%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                expire date
              </strong>
              <div
                style={{ color: "var(--main-baby-blue-color)", paddingLeft: 2 }}
              >
                {`${cardDate.month() + 1}/${cardDate
                  .year()
                  .toString()
                  .substring(2, 4)}`}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "25%" }}></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 20,
              marginTop: 40,
              marginBottom: 25,
            }}
          >
            <div style={{ fontSize: 13, width: "30%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                address line
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cardAddress.address}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "23%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                city
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cardAddress.city}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "23%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                Country
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cardAddress.country}
              </div>
            </div>
            <div style={{ fontSize: 13, width: "25%" }}>
              <strong className="label-title" style={{ paddingLeft: 0 }}>
                zip code
              </strong>
              <div
                style={{
                  color: "var(--main-baby-blue-color)",
                  paddingLeft: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cardAddress.zip}
              </div>
            </div>
          </div>
        </div>
        <div
          className="create-buttons-row"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 0,
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          <button
            className={`btn btn-primry ${this.state.card_saving && "disabled"}`}
            style={{ width: "auto" }}
            onClick={() =>
              this.props.openDeleteDialog(() =>
                this.deletePaymentMethod(card.id),
              )
            }
          >
            Remove card{" "}
          </button>
        </div>
      </div>
    );
  };

  renderPackagesTable(hasPayment) {
    const { paymentDetails, cardDetails, insightsPricing } = this.state;
    let currentInsightsPackagePrice = null;
    if (
      paymentDetails.package !== -1 &&
      insightsPricing &&
      cardDetails.currency in insightsPricing
    ) {
      const amountOfPosts = this.POSTS_PACKEGES[paymentDetails.package];
      currentInsightsPackagePrice =
        insightsPricing[cardDetails.currency][amountOfPosts];
    }
    const premium = this.props.user.userDetails.sidebar_data.is_premium;
    const postsLeft =
      this.props.user.userDetails.sidebar_data.max_number_of_reports_posts -
      this.props.user.userDetails.sidebar_data.current_number_of_reports_posts;
    return (
      <div className="package-table-container">
        <table>
          <thead>
            <tr>
              <th>
                <span>CURRENCY</span>
                <br />
                <br />
                <Select
                  ref="stateSelect"
                  options={Currencies}
                  placeholder={""}
                  value={cardDetails.currency}
                  simpleValue
                  clearable={false}
                  onChange={(value) => {
                    cardDetails.currency = value;
                    this.setState({});
                  }}
                  name="selected-state"
                  disabled={false}
                  searchable={true}
                />
              </th>
              <th>
                <span>MARKETPLACE USER</span>
                <div>
                  <strong>FREE</strong>
                </div>
                <div />
                {hasPayment && !premium && (
                  <div className="fas fa-check payment-table-chosen" />
                )}
              </th>

              <th>
                <span>INSIGHTS PACKAGE</span>
                <div>
                  <strong>
                    {currentInsightsPackagePrice
                      ? `${window.translate(
                          "",
                          `money_symbol_${cardDetails.currency}`,
                        )} ${currentInsightsPackagePrice.price}`
                      : "-"}
                  </strong>
                </div>
                <span>ONE TIME PAYMENT</span>
                {postsLeft > 0 && (
                  <div className="payment-table-chosen chosen-insights">
                    {" "}
                    <span className="fas fa-check " />{" "}
                    <span className="insights-posts-left">
                      {postsLeft} posts left{" "}
                    </span>
                  </div>
                )}
              </th>
              <th>
                <span>
                  AGENCY <br /> USER
                </span>
                <div>
                  <strong>
                    {window.translate(
                      "",
                      `money_symbol_${cardDetails.currency}`,
                    )}{" "}
                    {this.PRICE_PER_MONTH[cardDetails.currency]}
                  </strong>
                </div>
                <span>MONTHLY LICENSE</span>
                {premium && (
                  <div className="fas fa-check payment-table-chosen" />
                )}
              </th>
              <th>
                <span>ENTERPRISE USER</span>
                <div>
                  <strong>Price on request</strong>
                </div>
                <div />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Transaction fees</td>
              <td>15%</td>
              <td></td>
              <td>10%</td>
              <td></td>
            </tr>
            <tr>
              <td>Influencer recruitment</td>
              <td>via bids</td>
              <td></td>
              <td>Bids & search</td>
              <td>Bids & search</td>
            </tr>
            <tr>
              <td>Influencer data</td>
              <td>Bidding influencers</td>
              <td></td>
              <td>All influencers</td>
              <td>All influencers</td>
            </tr>
            <tr>
              <td>Campaign reports</td>
              <td>
                <i className="fas fa-check-circle with-color" />
              </td>
              <td></td>
              <td>
                <i className="fas fa-check-circle with-color" />
              </td>
              <td>
                <i className="fas fa-check-circle with-color" />
              </td>
            </tr>
            <tr>
              <td>End-to-end management</td>
              <td>
                <i className="fas fa-check-circle with-color" />
              </td>
              <td></td>
              <td>
                {this.props.user.userDetails.sidebar_data
                  .can_open_campaings && (
                  <i className="fas fa-check-circle with-color" />
                )}
              </td>
              <td>
                <i className="fas fa-check-circle with-color" />
              </td>
            </tr>
            <tr>
              <td>Insights reports</td>
              <td></td>
              <td>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    paymentDetails.package =
                      paymentDetails.package === 0 ? -1 : 0;
                    this.setState({});
                  }}
                >
                  <i
                    className={
                      paymentDetails.package === 0
                        ? "far fa-dot-circle"
                        : "far fa-circle"
                    }
                  />
                  10
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    paymentDetails.package =
                      paymentDetails.package === 1 ? -1 : 1;
                    this.setState({});
                  }}
                >
                  <i
                    className={
                      paymentDetails.package === 1
                        ? "far fa-dot-circle"
                        : "far fa-circle"
                    }
                  />
                  20
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    paymentDetails.package =
                      paymentDetails.package === 2 ? -1 : 2;
                    this.setState({});
                  }}
                >
                  <i
                    className={
                      paymentDetails.package === 2
                        ? "far fa-dot-circle"
                        : "far fa-circle"
                    }
                  />
                  50
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>API integration & custom features</td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <i className="fas fa-check-circle with-color" />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="package-table-footer">
          <div></div>
          <div
            className={hasPayment ? "disabled" : ""}
            onClick={() => {
              if (!hasPayment)
                this.setState({
                  payment_mode: 1,
                  selectedPackage: "marketplace",
                });
            }}
          >
            UPGRADE
          </div>
          <div
            className={paymentDetails.package === -1 ? "disabled" : ""}
            onClick={() =>
              this.buyInsightsPackage(
                currentInsightsPackagePrice,
                paymentDetails.package,
              )
            }
          >
            PURCHASE
          </div>
          <div
            className={hasPayment && premium ? "disabled" : ""}
            onClick={() => {
              if (premium && hasPayment) return;
              if (!hasPayment)
                this.setState({ payment_mode: 1, selectedPackage: "premium" });
              else this.upgradeToPremium();
            }}
          >
            UPGRADE
          </div>
          <div>
            <a style={{ color: "#333333" }} href="mailto:info@humanz.ai">
              Contact us
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tokenizeCreditCard: (
      userDetails,
      cardDetails,
      billingAddress,
      callback,
    ) => {
      dispatch(
        tokenizeCreditCard(userDetails, cardDetails, billingAddress, callback),
      );
    },
    finalizePeachTokenize: (humanzTransactionId, peachId, callback) => {
      dispatch(finalizePeachTokenize(humanzTransactionId, peachId, callback));
    },
    getPaymentsMethods: (callback) => {
      dispatch(getPaymentsMethods(callback));
    },
    getUserBillingAddress: (callback) => {
      dispatch(getUserBillingAddress(callback));
    },
    editUserBillingAddress: (data, callback) => {
      dispatch(editUserBillingAddress(data, callback));
    },
    deletePaymentMethod: (id, callback) => {
      dispatch(deletePaymentMethod(id, callback));
    },
    upgradeToPremium: (paymentMethodId, callback) => {
      dispatch(upgradeToPremium(paymentMethodId, callback));
    },
    purchaseInsightPackage: (
      paymentMethodId,
      postAmount,
      currency,
      callback,
    ) => {
      dispatch(
        purchaseInsightsPackage(
          paymentMethodId,
          postAmount,
          currency,
          callback,
        ),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodTab);
