import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getBrandsList } from "src/api/actions/auctionActions";
import ConfirmActionDialog from "src/components/feed_components/dialogs/ConfirmActionDialog";
import {
  FileTypes,
  FileUpload,
  uploadFileSizeValidator,
} from "src/ui/FileUpload";

const EditBrandModal = (props: any) => {
  const [brandName, setBrandName] = useState("");
  const [brandImg, setBrandImg] = useState(props.brand.image_url);
  const [brandId, setBrandId] = useState(-1);
  const [isConfirmationOpen, setConfirmation] = useState(false);

  const findBrandIdByName = (brandsArray: any, i_brandName: string) => {
    const foundBrand = brandsArray.find(
      (brand: any) => brand.brand_name === i_brandName,
    );
    return foundBrand ? foundBrand.brand_id : null;
  };
  useEffect(() => {
    getBrandsList().then((res) => {
      const id: number = findBrandIdByName(res.data, props.brand.brand_name);
      setBrandId(id);
    });
    // eslint-disable-next-line
  }, []);
  const handleBrandNameChange = (event: any) => {
    const newValue = event.target.value;
    setBrandName(newValue);
  };
  return (
    <>
      {!isConfirmationOpen ? (
        <Modal
          size="sm"
          isOpen={true}
          onClose={() => {
            props.onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Brand</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
            >
              <Input
                onChange={handleBrandNameChange}
                sx={{ "::placeholder": { color: "#AAAAAA" }, border: "none" }}
                placeholder={brandName || props.brand.brand_name}
                autoFocus
              />
              <div
                style={{
                  display: "flex",
                  flex: "0 0 200px",
                  height: "200px",
                  width: "200px",
                  marginTop: "10px",
                }}
              >
                <FileUpload
                  placeholder="Brand logo/image"
                  fileType={FileTypes.image}
                  previewFileUrl={brandImg || undefined}
                  onFileUploaded={(image_url) => {
                    setBrandImg(image_url);
                  }}
                  styleImg={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                    borderRadius: "50%",
                    border: "3px solid #eae8e8",
                    padding: "4px",
                  }}
                  circle
                  validator={uploadFileSizeValidator}
                />
              </div>
              <Button
                mt={4}
                onClick={() => {
                  setConfirmation(true);
                }}
                isDisabled={
                  !brandName?.trim() && brandImg === props.brand.image_url
                }
              >
                Save
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <ConfirmActionDialog
          actionTitle="Edit brand name"
          actionDescription="Notice, changing brand name will change the brand name in all of your campaigns"
          handleClose={() => setConfirmation(false)}
          confirm={() => {
            props.handleEditBrand(
              brandId,
              brandName,
              props.brand.brand_name,
              brandImg,
            );
            props.onClose();
          }}
        />
      )}
    </>
  );
};

export default EditBrandModal;
