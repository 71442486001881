/* eslint-disable import-access/jsdoc */
// TODO - lint
/* eslint-disable camelcase, react/no-deprecated, no-unused-vars, no-nested-ternary, no-nested-ternary, no-param-reassign, no-param-reassign, prefer-regex-literals, no-useless-escape, no-param-reassign, eqeqeq, react/no-direct-mutation-state, no-param-reassign, no-restricted-syntax, no-shadow, no-param-reassign, consistent-return, react/no-string-refs, no-param-reassign, no-param-reassign, no-param-reassign, react/no-string-refs, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, react/no-unescaped-entities, eqeqeq, radix, no-underscore-dangle, no-param-reassign, no-param-reassign, consistent-return, no-return-assign, no-underscore-dangle, no-param-reassign, consistent-return, import/no-default-export */
// General Imports
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import moment from "moment";
import Dialog from "rc-dialog";
import React from "react";
// use connect
import { connect } from "react-redux";
import { getBoardDetails } from "src/actions/auctionsActions";
import {
  BundledTasks,
  EditModeProps,
  LocalTask,
  MixedTasks,
} from "src/api/ExtendedTypes";
import {
  deleteMoiTask_offersTask_offer_id,
  getMoiTask_offersTask_offer_id,
  postMoiAuctionsAuction_idMultiple_tasks,
} from "src/api/services";
import { TaskModification } from "src/api/types";
import { createMixedTasks } from "src/helpers/ManageDataHelper";
import { InfiniteLoader } from "../../general_components/InfiniteLoader";
import ConfirmActionDialog from "../ConfirmActionDialog";
import CurrentTasks from "./CurrentTasks";
import { EditTasksContext } from "./EditTasksContext";
import ManageModalHeader from "./ManageModalHeader";
import TasksStatusBanner from "./TasksStatusBanner";

interface Props {
  influencer?: any;
  markSeenTaskInvites?: any;
  onSave?: any;
  handleClose?: any;
  sendInviteCounterResponse?: any;
  editMode?: any;
  currencyLabel?: any;
  month: string;
  board?: any;
  getBoardDetails?: any;
  mode?: string;
}

interface State {
  tags: any;
  tagInputs: any;
  tasks: LocalTask[];
  saving: any;
  loadingOffer: boolean;
  tasksInvites: any;
  negotiate: any;
  fields?: any;
  updated?: any;
  mixedTasks: MixedTasks;
  editMode: EditModeProps;
  offer: TaskModification;
  showConfirmCancel?: boolean;
  showConfirmSave?: boolean;
}

class EditTasksDialog extends React.Component<Props, State> {
  INVITES_STATUS: any;

  _negInput: any;

  isMultiple: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      tags: [],
      tagInputs: [],
      tasks: [],
      mixedTasks: {
        bundles: [],
        single: [],
      },
      saving: false,
      loadingOffer: false,
      tasksInvites: {
        wait_influ: [],
        wait_adv: [],
        influ_rejected: [],
      },
      negotiate: {},
      editMode: null,
      offer: null,
    };

    this.INVITES_STATUS = [
      { label: "Waiting for you", value: "wait_adv" },
      { label: "Waiting influencer", value: "wait_influ" },
      { label: "Influencer rejected", value: "influ_rejected" },
    ];
    this.isMultiple =
      typeof props?.influencer === "object" && !!props.influencer?.length;
  }

  // initial
  populateMixedTasks = () => {
    this.setState({
      mixedTasks: createMixedTasks(
        this.props?.influencer,
        this.state?.tasks,
        this.state?.offer,
      ),
    });
  };

  updateMixedTasks = (mixedTasks: MixedTasks) => {
    const allTasks = mixedTasks?.bundles?.length
      ? mixedTasks?.single?.concat(
          mixedTasks?.bundles?.map((b) => b?.tasks).flat(),
        )
      : mixedTasks?.single;
    this.setState({ mixedTasks, tasks: allTasks });
  };

  componentWillMount() {
    if (!this.isMultiple) {
      this.initStateFromInfluencer(this.props.influencer);
    }
  }

  initStateFromInfluencer(influencer: any) {
    const tagInputs = influencer?.tasks_details?.tasks.map(() => "");
    const tasks = influencer?.tasks_details?.tasks.map(
      (t: LocalTask, i: number) => {
        return { ...t, index: i };
      },
    );
    let tasksInvites: any = {};
    if (influencer?.tasks_details?.tasks_invites) {
      tasksInvites = {
        wait_influ: influencer?.tasks_details?.tasks_invites.filter(
          (x: any) => x.price_offered_state === "wait_influencer",
        ),
        wait_adv: influencer?.tasks_details?.tasks_invites.filter(
          (x: any) => x.price_offered_state === "wait_advertiser",
        ),
        influ_rejected: influencer?.tasks_details?.tasks_invites.filter(
          (x: any) => x.price_offered_state === "influencer_rejected",
        ),
      };
    }

    const tasksTab: any =
      tasksInvites.wait_adv && tasksInvites.wait_adv.length > 0
        ? "wait_adv"
        : tasksInvites.wait_influ &&
          tasksInvites.wait_influ.find((x: any) => !x.seen)
        ? "wait_influ"
        : tasksInvites.influ_rejected &&
          tasksInvites.influ_rejected.find((x: any) => !x.seen)
        ? "influ_rejected"
        : "current";

    if (tasksTab !== "current") {
      this.props.markSeenTaskInvites(this.props.influencer.influencer_id);
    }
    const bundles = influencer?.tasks_details?.bundles_attributes;
    if (influencer?.offer_details) {
      console.log("HAS OFFER");
      this.setState({ loadingOffer: true });
      getMoiTask_offersTask_offer_id(influencer.offer_details.offer_id)
        .then((res) => {
          console.log("OFFER", res);
          this.setState(
            { offer: res.data.tasks_modification },
            this.populateMixedTasks,
          );
        })
        .finally(() => {
          this.setState({ loadingOffer: false });
        });
    }
    this.setState(
      {
        tagInputs,
        tasks,
        tasksInvites,
        negotiate: {},
        mixedTasks: {
          bundles,
          single: tasks,
        },
      },
      this.populateMixedTasks,
    );
  }

  handleTagsChange(index: number, task: any, tags: any) {
    task.changed = true;
    task.tags = tags;
    this.setState({ fields: this.state.fields });
    window.setTimeout(() => {
      const objDiv = document.getElementById(`tag-input${index}`);
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 1);
  }

  handleChangeTagInput(index: number, tag: any) {
    const reg = new RegExp(/^[#?|@]?[^\s!@#$%^&*()=+./,\[{\]};:'"?><]+$/);
    tag = tag.replace(" ", "_");
    if (
      tag === "" ||
      tag === "#" ||
      tag == "@" ||
      ((tag.startsWith("#") || tag.startsWith("@")) && reg.test(tag))
    ) {
      this.state.tagInputs[index] = tag;
      this.setState({ tagInputs: this.state.tagInputs });
    }
  }

  onToggleTask = (index: number, type?: any, bundleId?: string) => {
    const tasks = this.state.tasks.filter((x: any) => !x.subtask_of);
    // Get index in filtered tasks array
    const filteredIndex = tasks.findIndex((x: any) => x.index === index);
    if (index === null) {
      tasks.push({
        post_type: type,
        tags: ["#ad"],
        content_due_date: null,
        due_date: null,
        description: "",
        changed: true,
        new: true,
        additonal_price: null,
        references: [],
        renews: false,
        bundle_id: bundleId,
        complete_at_end_of_month: false,
      });
      this.state.tagInputs.push("");
    } else {
      if (tasks[filteredIndex]) tasks[filteredIndex].deleted = true;
    }

    console.log(index, type, bundleId, tasks[index]);
    this.setState({ tasks, updated: true }, this.populateMixedTasks);
  };

  private _onSave = () => {
    if (this.cantSave()) return;
    this.setState({ showConfirmSave: false });
    let id = null;
    let excluded: any[] = [];
    if (!this.isMultiple) {
      // Get influencer id
      id = this.props.influencer.id
        ? this.props.influencer.id
        : this.props.influencer.influencer_id;
      // Excluded tasks
      excluded = this.state?.mixedTasks?.single
        ?.filter(
          (x: any) => (x.deleted || x.changed || x.changed_mandatory) && x.id,
        )
        ?.map((x: any) => x?.id);
      // Add excluded tasks from this.state.mixedTasks.bundles
      this.state?.mixedTasks?.bundles?.forEach((bundle: BundledTasks) => {
        excluded = excluded
          ?.concat(
            bundle?.tasks
              ?.filter(
                (x: any) =>
                  (x.deleted || x.changed || x.changed_mandatory) && x.id,
              )
              ?.map((x: any) => x.id),
          )
          .filter((x: any) => x); // remove null values;
      });

      // Tasks invites
      if (this.state.tasksInvites) {
        const values: any = Object.values(this.state.tasksInvites);
        for (const inviteTasks of values) {
          const deleted: any = inviteTasks
            .filter((x: any) => x.deleted)
            .map((x: any) => x.id);
          if (deleted.length > 0) {
            excluded = excluded.concat(deleted);
          }
        }
      }
    }

    // New tasks
    let newTasks = [...this.state.mixedTasks.single];
    this.state.mixedTasks?.bundles?.forEach((bundle: BundledTasks) => {
      if (bundle?.tasks?.length) {
        newTasks?.push(...bundle.tasks);
      }
    });
    newTasks = newTasks
      ?.filter((x: any) => (x.changed || x.changed_mandatory) && !x.deleted)
      ?.map((x: any) => {
        return {
          id: x.changed_mandatory ? null : x.id,
          post_type: x.post_type,
          tags: x.tags,
          due_date: x.due_date,
          content_due_date: x.content_due_date,
          description: x.description,
          additonal_price: x.additonal_price,
          post_sub_type: x.post_sub_type,
          references: x.references,
          renews: x.renews,
          bundle_id: x.bundle_id,
          complete_at_end_of_month: x.complete_at_end_of_month,
          for_ads: x.for_ads,
          ads_long_link: x.ads_long_link,
        };
      });

    // New bundles
    const newBundles = this.state.mixedTasks?.bundles?.map(
      (bundle: BundledTasks) => {
        return bundle?.bundle_id
          ? {
              bundle_id: bundle.bundle_id,
              price: bundle.price,
              renews: bundle.renews,
              complete_at_end_of_month: bundle.complete_at_end_of_month,
              task_ids: bundle.tasks?.map((t) => t?.id).filter((t) => t) || [],
            }
          : null;
      },
    );

    const data = {
      excluded,
      new: newTasks,
      bundles_attributes: newBundles,
      month_relevance: this.props.month
        ? moment(this.props.month).format("YYYY-MM-DD")
        : null,
    };

    const { influencer } = this.props;
    this.setState({ saving: true });

    const callback = () => {
      if (this.props.mode !== "list") {
        console.log("Saving");
        this.props.getBoardDetails(
          this.props?.board?.board_id,
          this.props.mode === "next_month" ? "next" : null,
          () => {
            this.setState({ saving: false });
            console.log("SAVED");
            this.props.handleClose();
          },
        );
      } else {
        this.setState({ saving: false });
        this.props.handleClose();
      }
    };

    if (this.isMultiple) {
      postMoiAuctionsAuction_idMultiple_tasks(this.props?.board?.board_id, {
        tasks: newTasks,
        influencers: this.props.influencer.map(
          (x: any) => x.id || x.influencer_id,
        ),
        month_relevance: this.props.month
          ? moment(this.props.month).format("YYYY-MM-DD")
          : null,
        price: newBundles?.[0]?.price || null,
      })
        .then((_res) => callback())
        .catch((_e) => {
          console.log("Someting went wrong");
          this.props.handleClose();
        })
        .finally(() => {
          this.setState({ saving: false });
        });
    } else {
      this.props.onSave(id, data, (res: any) => {
        const { tasks } = res;
        this.setState({ tasks });
        if (!res) {
          console.log("Someting went wrong");
          this.props.handleClose();
          return;
        }
        influencer.tasks_details.tasks = influencer.tasks_details.tasks.filter(
          (x: any) => !excluded.includes(x.id),
        );
        influencer.tasks_details.tasks =
          influencer.tasks_details.tasks.concat(tasks);
        if (res.influencer) {
          this.initStateFromInfluencer(res.influencer);
        }
        callback();
      });
    }
  };

  public get onSave() {
    return this._onSave;
  }

  public set onSave(value) {
    this._onSave = value;
  }

  sendInviteCounterResponse = (
    influencer: any,
    task: any,
    counterType: any,
  ) => {
    let price = null;
    if (counterType == "negotiate") {
      price = parseInt(this._negInput.value);
    }
    task.loadingNegotiate = true;
    this.setState({});
    this.props.sendInviteCounterResponse(
      influencer.influencer_id,
      task.id,
      counterType,
      price,
      (data: any) => {
        task.loadingNegotiate = false;
        this.initStateFromInfluencer(data);
      },
    );
  };

  toggleNegotiation = (task: any) => {
    const { negotiate } = this.state;
    if (task.id === negotiate?.id) {
      this.setState({ negotiate: {} });
    } else {
      this.setState({ negotiate: task });
    }
  };

  hasTasksOrBundlesChanges = () => {
    let changes = false;
    this.state?.mixedTasks?.single?.forEach((t) => {
      if (t?.changed || t?.new || t?.deleted) {
        changes = true;
      }
    });
    this.state?.mixedTasks?.bundles?.forEach((b) => {
      if (b?.new || b?.changed || b?.deleted) {
        changes = true;
      }
    });
    return changes;
  };

  confirmClose = () => {
    if (!this.hasTasksOrBundlesChanges()) {
      this.props.handleClose();
    } else {
      this.setState({ showConfirmCancel: true });
    }
  };

  confirmSave = () => {
    let changes = false;
    const influ = this.props.influencer;
    this.state?.mixedTasks?.bundles?.forEach((b) => {
      // check if price is lower than the price before the change in board reducer bundle attributes
      const bundle = influ?.tasks_details?.bundles_attributes?.find(
        (x: any) => x.bundle_id === b.bundle_id,
      );
      if (bundle) {
        if (b.price < bundle.price) {
          changes = true;
        }
        b?.tasks?.forEach((t) => {
          if (t?.new || t?.changed) {
            changes = true;
          }
        });
      }
    });
    if (!changes || !this.props?.influencer.user_registered) {
      this.onSave();
    } else {
      this.setState({ showConfirmSave: true });
    }
  };

  renderCurrentTasks = (tasks: MixedTasks) => {
    return (
      <CurrentTasks
        tasks={tasks}
        offer={this.state.offer}
        locked={this.props?.influencer?.offer_details}
        onToggleTask={this.onToggleTask}
        taskUpdated={() => {
          this.setState({ updated: true }, this.populateMixedTasks);
        }}
        editMode={this.state.editMode}
        setEditMode={(m: EditModeProps) => this.setState({ editMode: m })}
        month={this.props.month}
        isMultiple={this.isMultiple}
        mode={this.props.mode}
      />
    );
  };

  render() {
    const { mixedTasks } = this.state;

    return (
      <EditTasksContext.Provider
        value={{
          mixedTasks: this.state.mixedTasks,
          setMixedTasks: this.updateMixedTasks,
          influencer: this.props.influencer,
        }}
      >
        {this.state.showConfirmCancel && (
          <ConfirmActionDialog
            actionTitle="Are you sure you want to cancel?"
            actionDescription={"All changes will be lost"}
            confirm={this.props.handleClose}
            handleClose={() => this.setState({ showConfirmCancel: false })}
          />
        )}
        {this.state.showConfirmSave && (
          <ConfirmActionDialog
            actionTitle="You will create an offer to the influencer"
            actionDescription={
              "By changing bundle price or tasks, the influencer will get a new offer which he can decline or accept. This will lock tasks editing until the offer is accepted, rejected or canceled. You can cancel the offer any time."
            }
            confirm={this.onSave}
            handleClose={() => this.setState({ showConfirmSave: false })}
          />
        )}

        <Dialog
          onClose={this.props.handleClose}
          className="edit-tasks-dialog"
          visible={true}
          animation="slide-fade"
          maskAnimation="fade"
          // closable={false}
          maskClosable={false}
          // keyboard={false}
        >
          {this.state.saving ? (
            <Box mb={10}>
              <Text
                fontSize={20}
                color={"brand.500"}
                fontWeight={500}
                textAlign={"center"}
                m={10}
              >
                Saving your changes
              </Text>
              <InfiniteLoader />
            </Box>
          ) : (
            <>
              <Box>
                <ManageModalHeader
                  image={this.props.influencer?.picture_url}
                  name={this.props.influencer?.name}
                  month={this.props.month}
                  isMultiple={this.isMultiple}
                  OneTimeCampaign={this.props.board?.one_month_campaign}
                />
                {this.props?.influencer?.offer_details ? (
                  <>
                    <TasksStatusBanner
                      status={
                        this.props?.influencer?.offer_details?.offer_status
                      }
                      action={() =>
                        deleteMoiTask_offersTask_offer_id(
                          this.props?.influencer.offer_details.offer_id,
                        ).then(() => {
                          this.props.getBoardDetails(
                            this.props?.board?.board_id,
                            moment(this.props.month).format("YYYY-MM-DD"),
                            () => this.props.handleClose(),
                          );
                        })
                      }
                    />
                    <Box border={"2px dashed #808080"} m={10} borderRadius={10}>
                      <Box color="white" background="#808080" p={5}>
                        <Flex justifyContent={"space-between"}>
                          <Box>
                            <Text className="fad fa-lock" /> Locked for editing{" "}
                          </Box>
                          {this.state.loadingOffer && (
                            <Flex gap={2}>
                              <Spinner />
                              <Text>Loading offer</Text>
                            </Flex>
                          )}
                        </Flex>
                      </Box>
                      {this.renderCurrentTasks(mixedTasks)}
                    </Box>
                  </>
                ) : (
                  this.renderCurrentTasks(mixedTasks)
                )}
              </Box>
              {!this.state.editMode && (
                <Flex
                  justifyContent={"space-between"}
                  paddingX={5}
                  paddingY={2}
                  position="sticky"
                  bottom={0}
                  background="white"
                >
                  <Button
                    isLoading={this.state.saving}
                    className={`cancel ${this.state.saving && "disabled"}`}
                    onClick={() => this.confirmClose()}
                  >
                    <span>Cancel</span>
                  </Button>
                  {!this.props?.influencer?.offer_details && (
                    <Button
                      isLoading={this.state.saving}
                      disabled={this.cantSave()}
                      style={{ marginLeft: 10 }}
                      className={`${this.cantSave() && "disabled"}`}
                      id="tasks_save_btn"
                      onClick={this.confirmSave}
                      leftIcon={
                        // save icon
                        <i
                          className="far fa-save"
                          style={{ fontSize: "18px" }}
                        />
                      }
                    >
                      <span>Save all changes</span>
                    </Button>
                  )}
                </Flex>
              )}
            </>
          )}
        </Dialog>
      </EditTasksContext.Provider>
    );
  }

  cantSave = () => {
    const { tasks } = this.state;
    if (
      !tasks ||
      !tasks.length ||
      !this.hasTasksOrBundlesChanges() ||
      (tasks.filter((t) => t.deleted).length === tasks.length &&
        !this.props.board?.summary?.pixel_enabled)
    )
      return true;
    return this.state.saving;
  };
}

const mapDispatchToProps = (dispatch: any) => {
  // map dispatch actions to props
  return {
    getBoardDetails: (id: number, month: string, callback: any) => {
      dispatch(getBoardDetails(id, month, callback));
    },
  };
};

export default connect(null, mapDispatchToProps)(EditTasksDialog);
