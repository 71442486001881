import { Avatar, Box, Button, Flex } from "@chakra-ui/react";
import { InfluencerInManageAuctionData } from "src/api/types";
import { MessageDialog } from "../../dialogs/MessageDialog";

interface OwnProps {
  onClose: any;
  callback: any;
  influencers: InfluencerInManageAuctionData[];
  influencersSelected: number[];
  sendToTrash: any;
}
export default function CampaignTrashMultiDialog(props: OwnProps) {
  console.log("PROPS IJNFLu", props.influencers);
  const influencers = props.influencers.filter((influencer) =>
    props.influencersSelected.includes(influencer.influencer_id),
  );
  return (
    <MessageDialog handleClose={props.onClose} icon="moi-trash">
      <div style={{ textAlign: "center", padding: 20 }}>
        <strong>Notice</strong>
        <p style={{ marginTop: 15 }}>
          Are you sure you want to trash all of those influencers from next
          month?{" "}
        </p>
        <Flex
          style={{ marginTop: 15, marginBottom: 15 }}
          justifyContent="flex-start"
          flexWrap="wrap"
          flexDirection={"column"}
          textAlign={"left"}
          gap={5}
        >
          {influencers.map((influencer) => (
            <Box
              key={influencer.influencer_id}
              alignItems="center"
              justifyContent={"flex-start"}
              display={"flex"}
              gap={2}
            >
              <Avatar src={influencer.picture_url} />
              <span>{influencer.name}</span>
            </Box>
          ))}
        </Flex>
        <Flex justifyContent={"space-between"}>
          <Button onClick={props.sendToTrash}>Trash</Button>
          <Button onClick={props.onClose} variant={"outline"}>
            Cancel
          </Button>
        </Flex>
      </div>
    </MessageDialog>
  );
}
