import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { usePrevious } from "src/hooks/coreHooks";
import produce from "immer";
import { assertUnreachable } from "src/declarations/UtilityTypes";
import { InfluencerSegmentations } from "../side_menus/InfluencerSegmentations";
import { ListSection } from "../lists_components/ListSection";
import RoiSection from "../campaign_components/roi/RoiSection";
import CreateCampaignSection from "../campaign_components/CreateCampaignSection";
import { CampaignInfluencersShared } from "../campaign_components/CampaignInfluencersShared";
import { BrandReportKeywords } from "../brand_report/BrandReportKeywords";
import { TopUserDetails } from "../TopUserDetails";
import {
  getMultiShareDetails as getMultiShareDetailsAction,
  getShareDetails,
  getSharedMultiReport as getSharedMultiReportAction,
  getShareGeneral,
  setShareKey,
} from "../../../actions/shareActions";
import {
  getAmbassadorShareDetails,
  getAmbassadorShareGeneral,
} from "../../../actions/shareAmbassadorROIActions";
import ShareContentsPage from "./ShareContentsPage";
import ShareTracker from "./ShareTracker";
import { ShareInvite } from "./ShareInvite";

enum ShareTypes {
  brief = "brief",
  list = "list",
  content = "content",
  roi = "roi",
  standaloneRoi = "standalone_roi",
  brandReportKeywords = "brand_report_keywords",
  ambassadorReport = "ambassador_report",
  multiReport = "multi_report",
  tracker = "tracker",
  report = "report",
  invite = "invite",
}

const mobileShareTypes = [
  {
    type: ShareTypes.content,
    checkWidthBeforeApply: true,
  },
  {
    type: ShareTypes.tracker,
    checkWidthBeforeApply: true,
  },
  {
    type: ShareTypes.invite,
    checkWidthBeforeApply: false,
  },
];

const noTopbarTypes = [ShareTypes.invite];

const tabs = [
  ShareTypes.brief,
  ShareTypes.list,
  ShareTypes.content,
  ShareTypes.roi,
  ShareTypes.standaloneRoi,
  ShareTypes.brandReportKeywords,
  ShareTypes.ambassadorReport,
  ShareTypes.multiReport,
  ShareTypes.tracker,
  ShareTypes.report,
];

export const SharedContainer: React.FC = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const loaders = useSelector(
    (state) => state.loadersReducer as { general: boolean },
  );
  const share = useSelector(
    (state) => state.sharedReducer as { generalError: boolean },
  );

  const dispatch = useDispatch();
  const getSharedMultiReport = useCallback<
    (pass: false | string, callback: (data: any) => void) => void
  >(
    (pass, cb) => {
      dispatch(getSharedMultiReportAction(pass, cb));
    },
    [dispatch],
  );
  const getMultiShareDetails = useCallback<
    (auth: string, callback: (data: any) => void) => void
  >(
    (auth, cb) => {
      dispatch(getMultiShareDetailsAction(auth, cb));
    },
    [dispatch],
  );

  const isCampaign = useMemo(
    () => window.location.href.includes("/campaign/"),
    [],
  );
  const desktop = window.innerWidth > 800;

  const shareType = params.share_type as ShareTypes | "campaign";
  const auth = params.auth as string;
  const { id } = params;
  const previousId = usePrevious(id);

  const setCounter = useState(0)[1];
  const forceUpdate = useCallback(() => {
    setCounter((x) => x + 1);
  }, [setCounter]);

  const [state, setState] = useState({
    mode: undefined as undefined | ShareTypes,
    tab: null as null | ShareTypes,
    campaingsLoaded: false,
    influencersLoaded: false,
    listsLoaded: false,
    influencerSegmentationsData: null as any,
    enable: [] as ShareTypes[],
    passwordDialogData: null as any,
    loading: true,
    passwordError: false,
    password: null as null | string,
    shareData: {} as any,
    keywordsCompareBy: null as any,
    generalError: false as any,
  });

  const isMobile =
    state.mode && mobileShareTypes.find((x) => x.type === state.mode);
  const noTopBar = state.mode && noTopbarTypes.includes(state.mode);

  const setGeneralError = useCallback(() => {
    setState(
      produce((draftState) => {
        draftState.generalError = true;
        draftState.loading = false;
      }),
    );
  }, []);

  const changeTab = useCallback(
    (mode: ShareTypes) => {
      navigate(
        `/shares/${isCampaign ? "campaign/" : ""}${mode}/${auth}${
          id ? `/${id}` : ""
        }`,
      );
    },
    [auth, id, isCampaign, navigate],
  );

  const openInfluencerSegmentations = useCallback<
    (id: string, hideFollowers: boolean) => void
  >((innerId, hideFollowers) => {
    setState(
      produce((draftState) => {
        draftState.influencerSegmentationsData = { id: innerId, hideFollowers };
      }),
    );
  }, []);

  const closeInfluencerSegmentations = useCallback(() => {
    setState(
      produce((draftState) => {
        draftState.influencerSegmentationsData = null;
      }),
    );
  }, []);

  const updateRoute = useCallback(() => {
    if (shareType !== "campaign") {
      const tab = shareType;
      const index = tabs.indexOf(tab);
      setState(
        produce((draftState) => {
          draftState.mode = index > -1 ? tab : undefined;
          draftState.tab = tab;
          draftState.influencerSegmentationsData = null;
        }),
      );
    }
  }, [shareType]);

  const sharePasswordInputRef = useRef<HTMLInputElement>(null);

  const enterSharePassword = useCallback<
    NonNullable<React.ComponentProps<"form">["onSubmit"]>
  >(
    (e) => {
      e.preventDefault();
      if (
        sharePasswordInputRef.current &&
        sharePasswordInputRef.current.value
      ) {
        const password = sharePasswordInputRef.current.value;
        setShareKey(auth, password);
        if (shareType === ShareTypes.multiReport) {
          getSharedMultiReport(password, (data) => {
            if (!data) {
              setState(
                produce((draftState) => {
                  draftState.passwordError = true;
                  draftState.loading = false;
                }),
              );
              return;
            }
            setState(
              produce((draftState) => {
                draftState.shareData = data;
                draftState.mode = ShareTypes.multiReport;
                draftState.loading = false;
                draftState.password = password;
                draftState.passwordDialogData = null;
              }),
            );
          });
        } else if (shareType === ShareTypes.ambassadorReport) {
          getAmbassadorShareDetails(
            (props as any).id,
            auth,
            password,
            (data: any) => {
              if (!data) {
                setState(
                  produce((draftState) => {
                    draftState.passwordError = true;
                    draftState.loading = false;
                  }),
                );
                return;
              }
              setState(
                produce((draftState) => {
                  draftState.shareData = data;
                  draftState.mode = ShareTypes.ambassadorReport;
                  draftState.loading = false;
                  draftState.password = password;
                  draftState.passwordDialogData = null;
                }),
              );
            },
          );
        } else {
          getShareDetails((data: any) => {
            if (!data) {
              setState(
                produce((draftState) => {
                  draftState.passwordError = true;
                }),
              );
              return;
            }

            let enable = [] as ShareTypes[];
            if (shareType === ShareTypes.tracker) {
              enable = [ShareTypes.tracker];
            } else if (shareType === ShareTypes.report) {
              enable = [ShareTypes.report];
              changeTab(ShareTypes.report);
            } else {
              if (data.campaign && data.campaign.share_brief) {
                enable.push(ShareTypes.brief);
              }
              if (data.list && data.list.share_enabled) {
                enable.push(ShareTypes.list);
              }
              if (data.campaign && data.campaign.share_content) {
                enable.push(ShareTypes.content);
              }
              if (data.roi && data.roi.share_enabled) {
                enable.push(ShareTypes.roi);
              }
              if (data.roi && data.roi.share_tracker) {
                enable.push(ShareTypes.tracker);
              }
            }

            setState(
              produce((draftState) => {
                draftState.enable = enable;
                draftState.loading = false;
                draftState.password = password;
                draftState.passwordDialogData = null;
              }),
            );
            if (enable.length) {
              changeTab(enable[enable.length - 1]);
            } else {
              setGeneralError();
            }
          });
        }
      }
    },
    [auth, changeTab, getSharedMultiReport, props, setGeneralError, shareType],
  );

  useEffect(() => {
    document.getElementById("body")!.classList.add("main-body");

    return () => {
      document.getElementById("body")!.classList.remove("main-body");
    };
  }, []);

  useEffect(() => {
    if (
      isMobile &&
      (!isMobile.checkWidthBeforeApply || window.outerWidth < 800)
    ) {
      const viewport = document.querySelector("meta[name=viewport]")!;
      const content = "initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
      viewport.setAttribute("content", content);
    }
  }, [isMobile]);

  useEffect(() => {
    if (shareType !== state.tab) {
      updateRoute();
      window.scrollTo(0, 0);
    }
    if (previousId !== id) {
      forceUpdate();
    }
  }, [
    forceUpdate,
    id,
    previousId,
    shareType,
    state.passwordDialogData,
    state.tab,
    updateRoute,
  ]);

  useEffect(() => {
    if (state.passwordDialogData !== null || state.password !== null) {
      return;
    }
    setShareKey(auth, null);
    if (shareType === ShareTypes.list && !isCampaign) {
      setState(
        produce((draftState) => {
          draftState.enable = [ShareTypes.list];
          draftState.loading = false;
          draftState.shareData = {};
          draftState.mode = ShareTypes.list;
        }),
      );
    } else {
      switch (shareType) {
        case "campaign":
        case ShareTypes.list:
        case ShareTypes.brief:
        case ShareTypes.content:
        case ShareTypes.roi:
        case ShareTypes.report: {
          getShareGeneral(auth, (data: any) => {
            if (data) {
              if (data.is_password_protected) {
                setState(
                  produce((draftState) => {
                    draftState.passwordDialogData = data;
                    draftState.loading = false;
                  }),
                );
              } else {
                getShareDetails((dataInner: any) => {
                  if (!dataInner) return;
                  const enable = [] as ShareTypes[];
                  let mode: ShareTypes | undefined;
                  if (shareType !== ShareTypes.report) {
                    if (dataInner.campaign && dataInner.campaign.share_brief) {
                      enable.push(ShareTypes.brief);
                      if (shareType === ShareTypes.brief)
                        mode = ShareTypes.brief;
                    }
                    if (dataInner.list && dataInner.list.share_enabled) {
                      enable.push(ShareTypes.list);
                      if (shareType === ShareTypes.list) mode = ShareTypes.list;
                    }
                    if (
                      dataInner.campaign &&
                      dataInner.campaign.share_content
                    ) {
                      enable.push(ShareTypes.content);
                      if (shareType === ShareTypes.content)
                        mode = ShareTypes.content;
                    }
                    if (dataInner.roi && dataInner.roi.share_enabled) {
                      enable.push(ShareTypes.roi);
                      if (shareType === ShareTypes.roi) mode = ShareTypes.roi;
                    }
                    if (
                      dataInner.campaign &&
                      dataInner.campaign.share_tracker
                    ) {
                      enable.push(ShareTypes.tracker);
                    }
                  } else if (dataInner.roi && dataInner.roi.share_enabled) {
                    enable.push(ShareTypes.roi);
                    if (shareType === ShareTypes.report)
                      mode = ShareTypes.report;
                  }
                  if (mode) {
                    changeTab(mode);
                  } else if (enable.length) {
                    changeTab(enable[enable.length - 1]);
                  } else {
                    setGeneralError();
                  }
                  setState(
                    produce((draftState) => {
                      draftState.mode = mode;
                      draftState.enable = enable;
                      draftState.loading = false;
                      draftState.shareData = dataInner;
                    }),
                  );
                });
              }
            } else {
              setGeneralError();
            }
          });
          break;
        }
        case ShareTypes.tracker: {
          getShareGeneral(auth, (data: any) => {
            if (!data) {
              setGeneralError();
            } else if (data.is_password_protected) {
              setState(
                produce((draftState) => {
                  draftState.passwordDialogData = data;
                  draftState.loading = false;
                }),
              );
            } else {
              setState(
                produce((draftState) => {
                  draftState.enable.push(ShareTypes.tracker);
                  draftState.loading = false;
                  draftState.shareData = {};
                  draftState.mode = ShareTypes.tracker;
                }),
              );
            }
          });
          break;
        }
        case ShareTypes.ambassadorReport: {
          getAmbassadorShareGeneral(auth, (data: any) => {
            if (!data) {
              setGeneralError();
            } else if (data.is_password_protected) {
              setState(
                produce((draftState) => {
                  draftState.passwordDialogData = data;
                  draftState.loading = false;
                }),
              );
            } else {
              setState(
                produce((draftState) => {
                  draftState.enable = [ShareTypes.ambassadorReport];
                  draftState.loading = false;
                  draftState.shareData = {};
                  draftState.mode = ShareTypes.ambassadorReport;
                }),
              );
            }
          });
          break;
        }
        case ShareTypes.multiReport: {
          getMultiShareDetails(auth, (data) => {
            if (data.is_password_protected) {
              setState(
                produce((draftState) => {
                  draftState.loading = false;
                  draftState.passwordDialogData = data;
                }),
              );
            } else {
              setState(
                produce((draftState) => {
                  draftState.enable = [ShareTypes.multiReport];
                  draftState.loading = false;
                  draftState.shareData = {};
                  draftState.mode = ShareTypes.multiReport;
                }),
              );
              getSharedMultiReport(false, (dataInner) => {
                if (!dataInner) {
                  setGeneralError();
                }
              });
            }
          });
          break;
        }
        case ShareTypes.brandReportKeywords:
        case ShareTypes.standaloneRoi: {
          setState(
            produce((draftState) => {
              draftState.enable = [ShareTypes.standaloneRoi];
              draftState.loading = false;
              draftState.shareData = {};
              draftState.mode = ShareTypes.standaloneRoi;
              if (shareType === ShareTypes.brandReportKeywords) {
                const compareBy = searchParams.get("compare_by");
                draftState.keywordsCompareBy = compareBy;
              }
            }),
          );
          break;
        }
        case ShareTypes.invite: {
          setState(
            produce((draftState) => {
              draftState.mode = shareType;
            }),
          );
          break;
        }
        default: {
          assertUnreachable(shareType);
          break;
        }
      }
    }
  }, [
    auth,
    changeTab,
    getMultiShareDetails,
    getSharedMultiReport,
    isCampaign,
    searchParams,
    setGeneralError,
    shareType,
    state.passwordDialogData,
    state.password,
  ]);

  const content = useMemo(() => {
    if (state.passwordDialogData) {
      return (
        <div className="share-password-form">
          <div>
            Shared{" "}
            {state.passwordDialogData.campaign_title ? "campaign" : "report"}
          </div>
          <div>
            <div>
              {state.passwordDialogData.campaign_title ||
                state.passwordDialogData.report_title}
            </div>
            <div>By {state.passwordDialogData.company_name}</div>
          </div>
          <form onSubmit={enterSharePassword}>
            <strong
              className={"label-title "}
              style={{
                color: state.passwordError ? "#f15a24" : "#333333",
              }}
            >
              Enter password
            </strong>
            <div>
              <input
                className="input-line"
                type="password"
                ref={sharePasswordInputRef}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              VIEW
            </button>
          </form>
        </div>
      );
    }
    if (state.mode) {
      switch (state.mode) {
        case ShareTypes.brief:
          return <CreateCampaignSection id={auth} share={true} />;
        case ShareTypes.list:
          return (
            <ListSection
              closeInfluencerSegmentations={closeInfluencerSegmentations}
              id={auth}
              openInfluencerSegmentations={openInfluencerSegmentations}
              shared={true}
            />
          );
        case ShareTypes.content:
          return id ? (
            <ShareContentsPage id={id} />
          ) : (
            <CampaignInfluencersShared
              id={auth}
              openInfluencerSegmentations={openInfluencerSegmentations}
              closeInfluencerSegmentations={closeInfluencerSegmentations}
              showCosts={
                state.shareData.campaign
                  ? state.shareData.campaign.share_cost_per_influencer
                  : false
              }
            />
          );
        case ShareTypes.roi:
        case ShareTypes.report:
          return (
            <RoiSection
              shared={true}
              fromCampaign={true}
              closeInfluencerSegmentations={closeInfluencerSegmentations}
              id={auth}
              openInfluencerSegmentations={openInfluencerSegmentations}
            />
          );
        case ShareTypes.standaloneRoi:
        case ShareTypes.ambassadorReport:
        case ShareTypes.multiReport:
          return (
            <RoiSection
              isMultiReport={state.mode === ShareTypes.multiReport}
              isAmbassador={state.mode === ShareTypes.ambassadorReport}
              shared={true}
              fromCampaign={false}
              closeInfluencerSegmentations={closeInfluencerSegmentations}
              id={auth}
              openInfluencerSegmentations={openInfluencerSegmentations}
            />
          );
        case ShareTypes.brandReportKeywords:
          return (
            <BrandReportKeywords
              compareBy={state.keywordsCompareBy}
              id={auth}
              shared={true}
            />
          );
        case ShareTypes.tracker:
          return <ShareTracker id={auth} loader={undefined} />;
        case ShareTypes.invite:
          return <ShareInvite auth={auth} onError={setGeneralError} />;
        default:
          return assertUnreachable(state.mode);
      }
    } else {
      return null;
    }
  }, [
    auth,
    closeInfluencerSegmentations,
    enterSharePassword,
    id,
    openInfluencerSegmentations,
    setGeneralError,
    state.keywordsCompareBy,
    state.mode,
    state.passwordDialogData,
    state.passwordError,
    state.shareData.campaign,
  ]);

  return (
    <div className="main-shared">
      {((share && share.generalError) || state.generalError) && (
        <div className={"share-error"}>
          We are sorry but this link has expired, please ask the person who sent
          it to you to extend it or provide you with a new one
        </div>
      )}
      {loaders.general && <div className="top-loader"></div>}
      {desktop ||
        (isMobile ? null : (
          <div className="logo">
            <a onClick={() => changeTab(state.enable[0] ?? tabs[0])}>
              <img src="/images/humanz-logo.png" />
            </a>
          </div>
        ))}
      {noTopBar ? (
        content
      ) : (
        <>
          <TopUserDetails
            shared={true}
            userDetails={share}
            openNotifications={() => {
              throw new Error("Not implemented");
            }}
          />
          <div className="main-container">
            {desktop || !isMobile ? (
              <div className="main-side-container">
                {state.enable.includes(ShareTypes.brief) && (
                  <div
                    className={`${
                      state.mode === ShareTypes.brief
                    } main-side-row`}
                    onClick={() => changeTab(ShareTypes.brief)}
                  >
                    <div className="far fa-file-alt"></div>
                    <span className="share-menu-items">BRIEF</span>
                  </div>
                )}
                {state.enable.includes(ShareTypes.list) && (
                  <div
                    className={`${
                      state.mode === ShareTypes.list
                    } main-side-row`}
                    onClick={() => changeTab(ShareTypes.list)}
                  >
                    <i className="moi-list"></i>
                    <span className="share-menu-items">LIST</span>
                  </div>
                )}
                {state.enable.includes(ShareTypes.content) && (
                  <div
                    className={`${
                      state.mode === ShareTypes.content
                    } main-side-row`}
                    onClick={() => changeTab(ShareTypes.content)}
                  >
                    <div className="fa moi-manage"></div>
                    <span className="share-menu-items">CONTENT</span>
                  </div>
                )}
                {(state.enable.includes(ShareTypes.roi) ||
                  state.enable.includes(ShareTypes.report)) && (
                  <div
                    className={`${
                      (state.enable.includes(ShareTypes.report) ||
                        state.enable.includes(ShareTypes.roi)) &&
                      (state.mode === ShareTypes.roi ||
                        state.mode === ShareTypes.report)
                    } main-side-row `}
                    onClick={() =>
                      changeTab(
                        state.enable.includes(ShareTypes.report)
                          ? ShareTypes.report
                          : ShareTypes.roi,
                      )
                    }
                  >
                    <div className="fa fa-chart-area"></div>
                    <span className="share-menu-items">ROI</span>
                  </div>
                )}
                {state.enable.includes(ShareTypes.standaloneRoi) && (
                  <div
                    className={`${
                      state.mode === ShareTypes.standaloneRoi
                    } main-side-row`}
                    onClick={() => changeTab(ShareTypes.standaloneRoi)}
                  >
                    <div className="fa fa-chart-area"></div>
                    <span className="share-menu-items">INSIGHT</span>
                  </div>
                )}
                {state.enable.includes(ShareTypes.ambassadorReport) && (
                  <div
                    className={`${
                      state.mode === ShareTypes.standaloneRoi
                    } main-side-row`}
                  >
                    <div className="fa fa-chart-area"></div>
                    <span className="share-menu-items">Report</span>
                  </div>
                )}
                {state.enable.includes(ShareTypes.tracker) && (
                  <div
                    className={`${
                      state.mode === ShareTypes.tracker
                    } main-side-row`}
                    onClick={() => changeTab(ShareTypes.tracker)}
                  >
                    <div className="fa fa-chart-line"></div>
                    <span className="share-menu-items">Tracker</span>
                  </div>
                )}
              </div>
            ) : null}

            <div className="main-middle">
              {state.influencerSegmentationsData && (
                <InfluencerSegmentations
                  auth={auth}
                  shared={true}
                  shareType={shareType}
                  id={state.influencerSegmentationsData.id}
                  hideFollowers={
                    state.influencerSegmentationsData.hideFollowers
                  }
                  closeInfluencerSegmentations={closeInfluencerSegmentations}
                />
              )}
              {content}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
