import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GOOGLE_CLIENT_ID } from "src/actions/commonActionsConf";
import { setShareKey } from "src/actions/shareActions";
import {
  AgentExternalInfluencerInviteDetails,
  ExternalInfluencerInviteDetails,
} from "src/api/types";
import {
  getSocialNetworkApiAgentLink,
  getSocialNetworkInvitationLinkData,
  postSocialNetworkApiAgentLink,
  postSocialNetworkApiFromLink,
} from "../../../../api/actions/shareActions/generalActions";
import Logo from "../../../../images/svgs/humanz_text.svg";
import { InfiniteLoader } from "../../general_components/InfiniteLoader";
import { FacebookButton } from "./FacebookButton";
import { GoogleButton } from "./GoogleButton";
import { TikTokButton } from "./TikTokButton";
import { TwitterButton } from "./TwitterButton";
import "./connect-api.css";
import { ApiCallbackMessage } from "./types";

const ConnectSocialApi = () => {
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [message, setMessage] = React.useState(null as ApiCallbackMessage);
  const [mode] = useState(
    window.location.href.includes("talent-manager")
      ? "talent-manager"
      : ("advertiser" as "talent-manager" | "advertiser"),
  );

  const [isLandscape] = useMediaQuery("(min-aspect-ratio: 1/1)");
  const [data, setData] = useState(
    {} as ExternalInfluencerInviteDetails &
      AgentExternalInfluencerInviteDetails,
  );
  const params = useParams();

  const { provider } = data;
  // const provider = "youtube";

  useEffect(() => {
    if (window.outerWidth < 800) {
      const viewport = document.querySelector("meta[name=viewport]")!;
      const content = "initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
      viewport.setAttribute("content", content);
    }
  }, []);

  useEffect(() => {
    if (params.auth === "meta") {
      if (sessionStorage.getItem("shareKey")) {
        setShareKey(sessionStorage.getItem("shareKey") as string);
      }
    } else {
      setShareKey(params.auth);
      sessionStorage.setItem("shareKey", params.auth);
    }
    if (mode === "advertiser") {
      getSocialNetworkInvitationLinkData()
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch(() => {
          setData({});
          setLoading(false);
        });
    } else {
      getSocialNetworkApiAgentLink()
        .then((res) => {
          setData({ ...res.data, provider: "instagram" });
          setLoading(false);
        })
        .catch(() => {
          setData({});
          setLoading(false);
        });
    }
  }, [mode, params]);

  const tokenCallback = (token: string, profileId?: string) => {
    setSaving(true);
    setMessage(null);
    const providerLabel = provider.charAt(0).toUpperCase() + provider.slice(1);

    if (mode === "advertiser") {
      postSocialNetworkApiFromLink(
        {
          profile_id: profileId,
          access_token: token,
          provider,
        },
        { social_network: provider },
      )
        .then((r) => {
          setSaving(false);
          if (
            r.data.username?.toLowerCase() !==
            data.invited_username?.toLowerCase()
          ) {
            setMessage({
              status: provider === "youtube" ? "success" : "warning",
              text: `${
                provider === "youtube" ? "" : "Warning! "
              }The ${providerLabel} account you connected does not seem to be the correct one. You connected @${
                r.data.username
              } but the invite you received is for @${data.invited_username}.`,
            });
          } else {
            setMessage({
              status: "success",
              text: `Congratulations 💪 You have successfully verified the ${providerLabel} account @${r.data.username} on Humanz`,
            });
          }
        })
        .catch((e: any) => {
          let reason;
          try {
            reason = e?.response?.data?.error
              ? JSON.parse(e.response.data.error)?.detail
              : "";
          } catch (_) {
            /* */
          }
          setMessage({
            status: "error",
            text: `Error - ${reason ? `${reason}` : "Something went wrong."}\n`,
          });
          setSaving(false);
        });
    } else {
      postSocialNetworkApiAgentLink(
        {
          access_token: token,
          provider: provider || "instagram",
          profile_id: profileId,
        },
        { social_network: provider || "instagram" },
      )
        .then((r) => {
          setSaving(false);
          setMessage({
            status: "success",
            text: `Congratulations 💪 You have successfully verified the ${providerLabel} account @${r.data.username} on Humanz`,
          });
        })
        .catch((e: any) => {
          let reason;
          try {
            reason = e?.response?.data?.error
              ? JSON.parse(e.response.data.error)?.detail
              : "";
          } catch (_) {
            /* */
          }
          if (!reason && e?.response?.status === 409) {
            setMessage({
              status: "warning",
              text: "We couldn't connect you to this agent, your profile is already linked to another agent on our platform. For more information please contact us at info@humanz.ai",
            });
          } else {
            setMessage({
              status: "error",
              text: `Error - ${
                reason ? `${reason}` : "Something went wrong."
              }\n`,
            });
          }
          setSaving(false);
        });
    }
  };

  const renderActionButton = () => {
    switch (provider) {
      case "tiktok":
        return <TikTokButton tokenCallback={tokenCallback} />;
      case "twitter":
        return <TwitterButton tokenCallback={tokenCallback} />;
      case "youtube":
        return (
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleButton tokenCallback={tokenCallback} />
          </GoogleOAuthProvider>
        );
      case "instagram":
      default:
        return (
          <FacebookButton
            tokenCallback={tokenCallback}
            initialUsername={data?.invited_username}
            agentId={data?.agent_fk}
          />
        );
    }
  };

  const renderMessage = () => {
    if (!message) return null;
    return (
      <Alert marginTop={"10px"} marginBottom={"20px"} status={message.status}>
        <AlertIcon />
        <div>
          {message.text}
          {message.status === "error" && provider === "instagram" && (
            <div style={{ marginTop: 10 }}>
              Please make sure:
              <div>
                <ul className="ul">
                  <li>
                    The Instagram account you are connecting is flagged as a
                    creator or business account
                  </li>
                  <li>
                    The Instagram account is linked to a Facebook page that you
                    own
                  </li>
                  <li>
                    You have accepted all the permissions needed by humanz
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </Alert>
    );
  };

  if (loading) {
    return (
      <div>
        <InfiniteLoader />
      </div>
    );
  }

  if (!data?.provider && !data?.agent_fk) {
    return (
      <Flex
        background={"white"}
        justifyContent="center"
        alignItems={"center"}
        width="100vw"
        height={"100vh"}
      >
        <Text fontSize={18}>
          {mode === "advertiser"
            ? "The link has expired. Please ask the advertiser to create you a new link"
            : "The link has expired. Please ask the talent agent to send you a new link"}
        </Text>
      </Flex>
    );
  }

  const providerLabel = provider.charAt(0).toUpperCase() + provider.slice(1);

  return (
    <>
      <Flex w="full" h="full" position={"absolute"} background={"#fbfbfb"} />
      <Flex
        w="full"
        justifyContent="center"
        background={"#fbfbfb"}
        padding="10px"
        className="connect-api-container"
      >
        <Box
          position="relative"
          h="full"
          w={isLandscape ? "5xl" : "full"}
          overflow="hidden"
        >
          <Flex
            position="relative"
            h="full"
            w="full"
            overflow="auto"
            paddingTop={"50px"}
            height="auto"
          >
            <Flex
              flex="1 0"
              w="full"
              flexDir="row"
              flexWrap={"wrap"}
              className="connect-api-box-container"
            >
              {/* <Icon as={Logo} w="48" h="auto" alignSelf="center" pt="4" /> */}
              <Box
                p="8"
                pt="6"
                w="full"
                border="1px solid #ddd"
                borderRadius={"10px"}
                flex="2"
                background={"white"}
              >
                {(!message || message?.status === "error") && (
                  <Box borderRadius={10} background="white">
                    <h3 style={{ textAlign: "left", color: "#666" }}>
                      <strong>{`Hello ${
                        data.influencer_name || data.invited_username || ""
                      }`}</strong>
                    </h3>

                    <div style={{ marginBottom: 10 }}>
                      {mode === "talent-manager" ? (
                        <>
                          <strong>{data.agent_name}</strong>{" "}
                          <span>
                            has invited you to be part of their network and be
                            managed by them. By verifying your profile below,
                            this talent manager will be able to offer you
                            campaigns and negotiate on your behalf with
                            advertisers on the Humanz platform. Additionally,
                            advertisers will be able to see and understand more
                            about your profile.
                          </span>
                        </>
                      ) : (
                        <>
                          <strong>{data.company_name}</strong> has invited you
                          to connect with them on Humanz and share information
                          from your {providerLabel} profile. By enabling this
                          access, you will allow brands and agencies who may
                          want to work with you on Humanz to understand more
                          about your audience and the performance of your
                          content.
                          <br />
                          <br />
                        </>
                      )}
                      This is read-only, meaning you retain control of your
                      account and no-one can change anything or post on your
                      behalf. You can also disable this connection at any time,
                      although some features on Humanz may and the campaigns you
                      work on may no longer work if you do so.
                      <br />
                      <br />
                      You can consult the Humanz{" "}
                      <a href="https://www.humanz.ai/termsAndConditions.html">
                        Terms of Use
                      </a>{" "}
                      &{" "}
                      <a href="https://www.humanz.ai/PrivacyPolicy.html">
                        Privacy Policy
                      </a>{" "}
                      {provider === "instagram" && (
                        <>
                          for more information if you’d like.
                          <br />
                          <br />
                          Please note, this functionality is only available to
                          you if you have an Instagram business or creator
                          account connected to a Facebook page. You can learn
                          more about Business accounts{" "}
                          <a
                            href="https://help.instagram.com/502981923235522"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            here
                          </a>
                        </>
                      )}
                    </div>
                  </Box>
                )}
                {renderMessage()}
                {(!message || message?.status === "error") &&
                  (saving ? <InfiniteLoader /> : renderActionButton())}
              </Box>
              <Box
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  marginLeft: 10,
                  flex: 1,
                  padding: "15px",
                }}
                background={"white"}
              >
                <Flex alignItems={"start"}>
                  <img
                    src="/images/logo-round-new.png"
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                      marginTop: 10,
                    }}
                  />
                  <div>
                    <h5>Be Worth Following</h5>
                    <span>
                      Humanz helps you connect with brands and other creators
                      for work and growth opportunities, along with all the
                      tools and content you need to succeed.
                    </span>
                  </div>
                </Flex>

                <div
                  style={{ height: 1, background: "#ddd", margin: "30px 0px" }}
                />
                {provider === "instagram" ? (
                  <>
                    <h4>In order to connect, you will need</h4>
                    <div>
                      <div style={{ marginTop: 15 }}>
                        ✓ An Instagram creator or business account
                      </div>
                      <div style={{ marginTop: 15 }}>
                        ✓ A Facebook page, linked to your IG account
                      </div>
                      <div style={{ marginTop: 15 }}>
                        ✓ To Accept all permissions needed by Humanz
                      </div>
                    </div>
                  </>
                ) : null}
                {/* <div style={{ marginTop: 50 }}>
                  <h4>Having trouble?</h4>
                  <span>
                    Watch a short how-to video <a>here</a> or consult our
                    troubleshoot guide <a>here</a>
                  </span>
                </div> */}
              </Box>
            </Flex>
          </Flex>
          <Image
            src={Logo}
            margin={"10px auto"}
            w="28"
            h="auto"
            alignSelf="center"
            pt="4"
          />
        </Box>
      </Flex>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default ConnectSocialApi;
