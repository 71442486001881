// TODO - lint
/* eslint-disable import/no-default-export */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface Props {
  actionTitle: string;
  cancelLabel?: string;
  handleClose(): void;
  actionDescription: any;
  confirm(): void;
  confirmLabel?: string;
}

const ConfirmActionDialog = (props: Props) => {
  return (
    <Modal onClose={props.handleClose} isOpen={true} isCentered>
      <ModalOverlay />
      <ModalContent padding={0}>
        <ModalHeader>{props.actionTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <label style={{ padding: "10px 25px " }}>
            {props.actionDescription}
          </label>
          <ModalFooter
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Button
              onClick={props.handleClose}
              variant={"outline"}
              style={{ marginRight: "20px" }}
            >
              {props.cancelLabel !== undefined && props.cancelLabel !== null
                ? props.cancelLabel
                : window.translate("Cancel", "action_dialog_cancel")}
            </Button>
            <Button onClick={props.confirm}>
              {props.confirmLabel !== undefined && props.confirmLabel !== null
                ? props.confirmLabel
                : window.translate("Confirm", "action_dialog_confirm")}
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmActionDialog;
