// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import moment from "moment";
import Tooltip from "rc-tooltip";
import React, { useEffect, useState } from "react";
import Timeline, {
  CursorMarker,
  DateHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";

interface OwnProps {
  data: any;
  auctionColor: string;
  auction: any;
}

export const PhaseTimeline: React.FC<OwnProps> = (props: OwnProps) => {
  const [state, setState] = useState<any>({
    groups: [],
    minZoom: null,
    maxZoom: null,
    data: [],
    timelineData: [],
    auction: {},
    auctionColor: "var(--main-baby-blue-color)",
  });
  const convertEventToTimelineItem = (event: any, index: number) => {
    // docs:
    // https://github.com/namespace-ee/react-calendar-timeline
    return {
      id: event.id,
      group: index,
      title: event.title,
      commission: event.commission,
      start_time: moment(event.start_time).startOf("day"),
      end_time: moment(event.end_time).endOf("day"),
      className: event?.className || undefined,
      itemProps: {
        style: {
          background:
            event.color ||
            (index !== 0 ? "var(--main-baby-blue-color)" : undefined),
        },
      },
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    const { data } = props;
    if (data && data.length) {
      const timelineData = [...data];
      const mainIndex = timelineData.findIndex((x) => x.title === "Main");
      if (props.auction) {
        timelineData[mainIndex].className = "stripe";
        timelineData[mainIndex].color = undefined;
      }
      const groups: { id: number; title: string; stackItems: boolean }[] = [];
      timelineData.forEach((_: any, i: number) => {
        timelineData[i] = convertEventToTimelineItem(timelineData[i], i);
        groups.push({ id: i, title: "", stackItems: true });
      });
      const minZoom = moment(timelineData[mainIndex].start_time).add(
        "- 1 month",
      );
      const maxZoom = moment(timelineData[mainIndex].end_time).add("1 month");
      setState({
        data,
        timelineData,
        groups,
        minZoom,
        maxZoom,
      });
    }
  }, [props, setState]);

  const itemRenderer = ({
    item,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemContext,
    getItemProps,
  }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isEnded = moment().isAfter(moment(item.end_time));
    return (
      <div {...getItemProps(item.itemProps)} title={""}>
        <Tooltip
          placement={"bottom"}
          overlay={`${isEnded ? "[Finished] " : ""} [${
            Math.abs(
              moment(item.start_time).diff(moment(item.end_time), "days"),
            ) + 1
          } days] ${itemContext.title}`}
        >
          <div>
            {/* {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''} */}
            <div
              className={"rct-item-content-lite"}
              style={{ maxHeight: `${itemContext.dimensions.height}` }}
            >
              {itemContext.title} {itemContext.commission}
              {itemContext.commission ? "%" : null}
            </div>
            {/* {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''} */}
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className="phases-timeline">
      {state.timelineData &&
      state.timelineData.length &&
      state.minZoom &&
      state.maxZoom ? (
        <div style={{ padding: 20 }}>
          <Timeline
            groups={state.groups}
            items={state.timelineData}
            sidebarWidth={0}
            lineHeight={39}
            canMove={false}
            canResize={false}
            canChangeGroup={false}
            stackItems={false}
            defaultTimeStart={state.minZoom}
            defaultTimeEnd={state.maxZoom}
            traditionalZoom={false}
            visibleTimeStart={moment(state.minZoom).toDate().getTime()}
            // visibleTimeEnd={moment(state.maxZoom).toDate().getTime()}
            itemRenderer={itemRenderer}
          >
            <TimelineHeaders>
              <DateHeader unit="primaryHeader" />
              <DateHeader />
            </TimelineHeaders>
            <TimelineMarkers>
              <CursorMarker />
              <TodayMarker date={Date.now()}>
                {({ styles }: any) => (
                  // date is value of current date. Use to render special styles for the marker
                  // or any other custom logic based on date:
                  // e.g. styles = {...styles, backgroundColor: isDateInAfternoon(date) ? 'red' : 'limegreen'}
                  <div
                    style={{
                      ...styles,
                      backgroundColor: "var(--main-baby-blue-color)",
                    }}
                  />
                )}
              </TodayMarker>
            </TimelineMarkers>
          </Timeline>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
