import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import TagsInput from "react-tagsinput";

type Props = {
  header: string;
  value: any;
  onChange: (tags: string[]) => void;
  onChangeInput: (newValue: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  description?: string;
  required?: true;
  width?: string;
  height?: string;
  disabled?: boolean;
  inputValue: string;
};

const IndicatorTags = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            background: isFocused ? "#249FF0" : "#EBEBEB",
            height: "auto",
            marginRight: "8px",
            width: "1px",
          }}
        ></Box>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "auto",
                padding: 0,
                color: "#222325",
              }}
            >
              {props.header}
            </Text>
            {props.description && (
              <Text
                sx={{
                  fontSize: "12px",
                  marginLeft: "8px",
                  fontWeight: 400,
                  color: "#AAAAAA",
                }}
              >
                {props.description}
              </Text>
            )}
          </Box>
          <Box
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ width: "469px" }}
          >
            <TagsInput
              value={props.value}
              onChange={props.onChange}
              inputValue={props.inputValue}
              onChangeInput={props.onChangeInput}
              addOnBlur={true}
              inputProps={{
                placeholder: "#hashtag @mention",
                className: "tasks-tags-input main-tags",
                style: {
                  background: "white",
                  padding: "10px",
                  height: "34px",
                  border: " 1px solid #EAE8E8",
                  borderRadius: "4px",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IndicatorTags;
