import React from "react";
import { connect } from "react-redux";
import Tooltip from "rc-tooltip";
import { getSharedBrandReportTopTags } from "../../../actions/shareActions";

class BrandReportKeywordsInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      search: "",
    };
  }

  loadBrandReportTags() {
    this.props.getSharedBrandReportTopTags(
      this.props.id,
      this.props.compareBy,
      (data) => {
        if (data) {
          data.sort((a, b) => {
            if (a.posts_amount > b.posts_amount) return -1;

            if (b.posts_amount > a.posts_amount) {
              return 1;
            }
            return 0;
          });
          this.setState({ isLoaded: true, word_and_posts: data });
        }
      },
    );
  }

  componentDidMount() {
    this.loadBrandReportTags();
  }

  renderPostsRows(posts, word) {
    const CUBES_PER_ROW = 3;
    let numberOfRows = posts.length / CUBES_PER_ROW;
    if (posts.length % CUBES_PER_ROW !== 0) {
      numberOfRows += 1;
    }
    const groups = [];
    for (let i = 0; i < numberOfRows; i += 1) {
      const baseNumber = i * CUBES_PER_ROW;
      const group = [];
      for (
        let j = 0;
        j + baseNumber < posts.length && j < CUBES_PER_ROW;
        j += 1
      ) {
        group.push(posts[baseNumber + j]);
      }
      if (group.length > 0) {
        groups.push(group);
      }
    }

    return groups.map((group, idx) => this.renderPostsGroup(group, idx, word));
  }

  renderPostsGroup(group, idx, word) {
    return (
      <div className="row" key={word + idx}>
        {group.map((post) => this.renderPost(post, word))}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPost(post, wordToBold) {
    const textToDisplay = `<p>${post.text.replace(
      new RegExp(`(${wordToBold})`, "ig"),
      '<span class="matched-word">$1</span>',
    )}</p>`;
    const usernameLink = `https://www.instagram.com/${post.instagram_username}`;
    const postLink = `https://www.instagram.com/p/${post.media_id}`;
    return (
      <div className="post" key={post.media_id}>
        <div className="post-author">
          <div className="user_image">
            <a target="_blank" href={usernameLink} rel="noreferrer">
              <img src={post.instagram_profile_pic_url} />
            </a>
          </div>
          <div className="username">
            <a target="_blank" href={usernameLink} rel="noreferrer">
              {post.instagram_username}
            </a>
          </div>
          <div className="watch-post">
            <a target="_blank" href={postLink} rel="noreferrer">
              Watch on <div className="fa fa-instagram"></div>
            </a>
          </div>
        </div>
        <div
          className="post-description"
          dangerouslySetInnerHTML={{ __html: textToDisplay }}
        ></div>
      </div>
    );
  }

  renderWordToPosts(wordAndPosts) {
    return (
      <div className="word-and-posts" key={wordAndPosts.word_id}>
        <div
          className="word-title"
          onClick={() => {
            // eslint-disable-next-line no-param-reassign
            wordAndPosts.isOpen = !wordAndPosts.isOpen;
            this.setState({ updated: true });
          }}
        >
          <div className="inf_section">
            <div className="inf_left">{wordAndPosts.word}</div>
            <div className="inf_right">
              <div className="followers-label">Posts</div>
              <div className="followers-num posts-amount">
                {wordAndPosts.posts_amount}
              </div>
              <div className="clearfix"></div>
            </div>
            <div
              onClick={() => {
                // eslint-disable-next-line no-param-reassign
                wordAndPosts.isOpen = !wordAndPosts.isOpen;
                this.setState({ updated: true });
              }}
              className={"clickable collapse-icon "}
            >
              <Tooltip
                placement="top"
                trigger={["hover"]}
                overlay={<span>{"Show Sample Posts"}</span>}
              >
                <i
                  className={`fa ${
                    wordAndPosts.isOpen ? "fa-chevron-down" : "fa-chevron-right"
                  }`}
                />
              </Tooltip>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        {wordAndPosts.isOpen && (
          <div
            className={`${
              wordAndPosts.isOpen ? "opened" : "closed"
            } posts-container`}
          >
            {this.renderPostsRows(wordAndPosts.posts, wordAndPosts.word)}
          </div>
        )}
      </div>
    );
  }

  render() {
    const loaded = this.state.isLoaded;
    const { search } = this.state;
    return loaded ? (
      <div className="word-to-posts-container">
        <div className="campaigns-title">
          <span>Top Keywords</span>
        </div>
        <div className="inner-toolbar" style={{ marginTop: 10 }}>
          <div className="inner-toolbar-search-input">
            <div className="moi-search-bold" />
            <input
              onChange={(e) =>
                this.setState({ search: e.target.value.toLowerCase() })
              }
              className="form-control"
            />
          </div>
        </div>
        <div className="words-container">
          {this.state.word_and_posts
            .filter((word) =>
              (word.word ? word.word : "").toLowerCase().includes(search),
            )
            .map((x) => this.renderWordToPosts(x))}
        </div>
      </div>
    ) : (
      <div className="main-middle-container">
        <div className="logo-loader">
          <div className="loader" />
          <img src={"/images/humanz-logo.png"} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSharedBrandReportTopTags: (authKey, compareBy, callback) => {
      dispatch(getSharedBrandReportTopTags(authKey, compareBy, callback));
    },
  };
};

export const BrandReportKeywords = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandReportKeywordsInner);
