import axios from "axios";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { AuctionPhase } from "../api/types";
import { getBackendUri, getSavedJwtToken } from "./commonActionsConf";

const headers: any = {
  withCredentials: true,
  headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
};

const auctionsBaseUrl = () => `${getBackendUri()}/moi/auctions`;

const getErrorTranslation = (msg: string) => {
  switch (msg) {
    case "overlapping_phase_exists":
      return "Overlapping phase exists";
    case "cant edit phase that all equal":
      return "No changes were made";
    case "can_edit":
      return "Something went wrong saving this rule. Please make sure your form is currect.";
    case "cant edit phase that already started":
      return "Can't edit rule that have been started.";
    default:
      return msg.replaceAll("_", " ");
  }
};

export const getPixelPhases = (
  auction_id: number,
  successCallBack: any,
  failCallBack: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(`${auctionsBaseUrl()}/${auction_id}/pixel_phases`, headers)
      .then((response) => {
        dispatch({ type: "GET_PIXEL_PHASES_COMPLETE", payload: response.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        successCallBack(response.data);
      })
      .catch((err) => {
        dispatch({ type: "GET_PIXEL_PHASES_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.log(err);
        failCallBack();
      });
  };
};

export const createPixelPhase = (
  auction_id: number,
  data: AuctionPhase,
  isMainPhase: boolean,
  successCallBack: any,
  failCallBack: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${auctionsBaseUrl()}/${auction_id}/pixel_phases`, data, {
        ...headers,
        params: { main_split: isMainPhase },
      })
      .then((response) => {
        dispatch({ type: "GET_PIXEL_PHASES_COMPLETE", payload: response.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        toast.success("Rule was created successfully");
        successCallBack(response.data);
      })
      .catch((err) => {
        dispatch({ type: "GET_PIXEL_PHASES_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        let msg = "Error creating rule";
        if (err?.response?.data?.error)
          msg = getErrorTranslation(err.response.data.error);
        toast.error(msg);
        failCallBack();
      });
  };
};

export const editPixelPhase = (
  auction_id: number,
  data: AuctionPhase,
  phase_id: number,
  successCallBack: any,
  failCallBack: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${auctionsBaseUrl()}/${auction_id}/pixel_phases/${phase_id}`,
        data,
        headers,
      )
      .then((response) => {
        dispatch({ type: "GET_PIXEL_PHASES_COMPLETE", payload: response.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        toast.success("Rule was edited successfully");
        successCallBack(response.data);
      })
      .catch((err) => {
        dispatch({ type: "GET_PIXEL_PHASES_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        let msg = "Error editing rule";
        if (err?.response?.data?.error)
          msg = getErrorTranslation(err.response.data.error);
        toast.error(msg);
        console.log(err);
        failCallBack();
      });
  };
};

export const deletePixelPhase = (
  auction_id: number,
  phase_id: number,
  successCallBack: any,
  failCallBack: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(
        `${auctionsBaseUrl()}/${auction_id}/pixel_phases/${phase_id}`,
        headers,
      )
      .then((response) => {
        dispatch({ type: "GET_PIXEL_PHASES_COMPLETE", payload: response.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        toast.success("Rule was deleted successfully");
        successCallBack(response.data);
      })
      .catch((err) => {
        dispatch({ type: "GET_PIXEL_PHASES_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        let msg = "Error deleting rule";
        if (err?.response?.data?.error)
          msg = getErrorTranslation(err.response.data.error);
        toast.error(msg);
        console.log(err);
        failCallBack();
      });
  };
};

export const autocompleteSales = (
  search_type: string,
  app_id: number,
  query: string,
  successCallBack?: any,
  failCallBack?: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${getBackendUri()}/moi/autocomplete_sales`,
        { search_type, app_id, query },
        headers,
      )
      .then((response) => {
        dispatch({ type: "GET_PIXEL_PHASES_COMPLETE", payload: response.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        successCallBack(response.data);
      })
      .catch((err) => {
        dispatch({ type: "GET_PIXEL_PHASES_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        failCallBack();
      });
  };
};
