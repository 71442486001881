import React from "react";

// eslint-disable-next-line import/no-default-export
export default class MoveToMainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  componentDidMount() {
    this.jumpToApp();
  }

  // eslint-disable-next-line class-methods-use-this
  jumpToApp = () => {
    if (window.location.href.includes("code")) {
      const url = `intent://${window.location.href}/#Intent;scheme=humanz;end`;
      window.location.replace(url);
    }
  };

  render() {
    return (
      <section className="welcome" id="youtube-login">
        <div className="youtube-login-container">
          <div onClick={this.jumpToApp}>
            <img
              style={{ width: 300, height: 300 }}
              src={"/images/humanz-logo.png"}
            />
            <div>
              <button
                className="btn btn-primary"
                style={{
                  fontSize: 80,
                  marginTop: 100,
                  borderRadius: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                Back to Humanz
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
