export default function ShareRoiButton({
  summaryData,
  shareReport,
  shareMode,
}: {
  summaryData: any;
  shareReport: any;
  shareMode: boolean;
}) {
  if (shareMode) {
    return null;
  }
  return (
    <div
      aria-label="Share"
      style={{
        color: "white",
        background: "var(--main-baby-blue-color)",
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        width: 30,
        borderRadius: 20,
      }}
      onClick={() => shareReport(summaryData)}
      className="small-title"
      id="share-campaign"
    >
      <i
        style={{ textTransform: "none", fontSize: 17, marginTop: 2 }}
        className="moi-forward-arrow2"
      />
    </div>
  );
}
