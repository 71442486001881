/* eslint-disable no-nested-ternary */
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateBoardDetails } from "src/actions/auctionsActions";
import {
  AuctionDataExtended,
  InfluencerLineTasks,
  UserDetails,
} from "src/api/ExtendedTypes";
import { deleteFilesFromInfluencer } from "src/api/actions/auctionActions";
import {
  InfluencerExtendedData,
  InfluencerInManageAuctionData,
  PostTypes,
  TaskManageData,
} from "src/api/types";
import { RootState } from "src/declarations/react-redux";
import {
  AllSocialIcons,
  TasksTypes,
  getSocialMediaLinkByContentType,
} from "src/helpers/SocialNetworksHelper";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import NoImage from "../../../../images/svgs/placeholder.svg";
import { CampaignInfluencerPrice } from "../CampaignInfluencerPrice/CampaignInfluencerPrice";
import AttachAgentDialog from "../dialogs/AttachAgentDialog";
import { isEmptyPostStats } from "../dialogs/AttachLinksDialog/AttachLinksHelpers";
import { FilesInnerToolTip } from "../dialogs/FilesInnerTooltip";
import { UploadFileDialog } from "../dialogs/UploadFileDialog";
import { ViewFileDialog } from "../dialogs/ViewFileDialog";
import CampaignCommunityBadge from "./CampaignCommunityBadge";
import { CampaignInfluencerFollowersNumbers } from "./CampaignInfluencerFollowersNumbers";
import { CampaignInfluencerTags } from "./CampaignInfluencerTags";
import { CampaignInfluencerTagsList } from "./CampaignInfluencerTagsList";
import { Columns } from "./CampaignManagerInterfaces";

declare global {
  interface Window {
    openInfluencerRating: any;
  }
}

interface OwnProps {
  DAYS_EXPIRE_BID: number;
  influencer: InfluencerInManageAuctionData;
  selectedColumn: Columns;
  board?: AuctionDataExtended;
  share: any;
  negotiate: any;
  setNegotiate: any;
  user?: UserDetails;
  showCost: any;
  selectedInfluencers: number[];
  renderQuestionsToolTip: any;
  openChatsAndContent: any;
  sendToTrash: any;
  openAlarmDialog: any;
  setShowPaymentsDialog: any;
  setOpenAttachLinks: any;
  negotiateInfluencerBid: any;
  toggleInviteMenu: any;
  selectInfluencer: any;
  setEditTasksData: any;
  waitingNotes: any;
  // Apis
  acceptInfluencerBid?: any;
  changeInfluencerNoteOnBoard?: any;
  getInfluencerData: any;
  openInfluencerSegmentations?: any;
  responseForInfluencerCounterOffer?: any;
  updateBoardDetails?: any;
  influencerRating: any;
  updateColumns: any;
  unreadChatsInfluencers?: Array<number>;
  nextMonthMode?: boolean;
  setSelectedBid?: any;
  lastMonth: boolean;
  openTargetsModal: any;
  month: string;
}

const CampaignInfluencerRowComment = ({
  userNote,
  onEditNoteToggle,
  influencer,
  auctionId,
  editableNote,
  refreshBoard,
}: {
  userNote: string;
  onEditNoteToggle: (influencer: OwnProps["influencer"]) => void;
  influencer: OwnProps["influencer"];
  auctionId: OwnProps["board"]["board_id"];
  editableNote: OwnProps["influencer"];
  refreshBoard: () => void;
}) => {
  const [openFileUpload, setOpenFileUpload] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const openUploadFile = () => {
    setOpenFileUpload((prev) => !prev);
  };

  const handleDeleteFiles = (files: string[]) => {
    if (files?.length) {
      deleteFilesFromInfluencer(influencer.influencer_id, auctionId, {
        uuids: files,
      })
        .then(() => {
          refreshBoard();
        })
        .catch(() => {
          toast.error("Failed to delete files");
        });
    }
  };

  return (
    <>
      <div className="comments-row-cube">
        <span
          onClick={() =>
            influencer.note ? onEditNoteToggle(influencer) : null
          }
        >
          Comment{" "}
          {influencer.note && (
            <i
              className="fa-solid fa-pen-to-square"
              style={{ marginLeft: 2 }}
            />
          )}
        </span>
        {editableNote?.influencer_id === influencer?.influencer_id ? (
          <textarea
            id="user-note"
            ref={userNote}
            defaultValue={influencer.note}
            onBlur={() => onEditNoteToggle(influencer)}
            dir="auto"
          />
        ) : influencer?.note ? (
          <Tooltip
            overlay={influencer.note || ""}
            trigger={["click"]}
            placement="top"
            overlayStyle={{ maxWidth: 350 }}
          >
            <div
              className="ellipsis"
              onClick={() => onEditNoteToggle(influencer)}
            >
              {influencer.note}
            </div>
          </Tooltip>
        ) : (
          <>
            <div
              onClick={() => onEditNoteToggle(influencer)}
              style={{
                fontSize: "24px",
                justifyContent: "center",
                margin: "11px 0px",
                color: "grey",
              }}
            >
              <i className="fa-regular fa-comment-medical" />
            </div>
          </>
        )}
        {editableNote?.influencer_id === influencer?.influencer_id ? null : (
          <>
            {influencer?.files_attached?.length ? (
              <Tooltip
                trigger={["click"]}
                overlayClassName="upload-files-tooltip"
                placement={"bottom"}
                onVisibleChange={(visible) => setTooltipOpen(visible)}
                overlay={
                  tooltipOpen ? (
                    <FilesInnerToolTip
                      openFile={(uuid) => setOpenFile(uuid)}
                      openUploadFile={openUploadFile}
                      files={influencer.files_attached}
                      onDelete={handleDeleteFiles}
                    />
                  ) : null
                }
              >
                <div
                  className="upload-file-container"
                  style={{ color: "black" }}
                >
                  {influencer.files_attached.length}
                </div>
              </Tooltip>
            ) : (
              <Tooltip overlay={"Upload file to influencer"}>
                <div className="upload-file-container" onClick={openUploadFile}>
                  <i className="fas fa-file-arrow-up" />
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>
      {openFileUpload && (
        <UploadFileDialog
          auctionId={auctionId}
          influencerId={influencer.influencer_id}
          onClose={openUploadFile}
          onFileUploaded={refreshBoard}
        />
      )}
      {openFile ? (
        <ViewFileDialog
          uuid={openFile}
          influencerId={influencer.influencer_id}
          auctionId={auctionId}
          onClose={() => setOpenFile("")}
        />
      ) : null}
    </>
  );
};

export const CampaignInfluencerLine = (props: OwnProps) => {
  const {
    influencer,
    selectedColumn,
    board,
    share,
    user,
    selectedInfluencers,
    showCost,
    setEditTasksData,
    waitingNotes,
    setSelectedBid,
    openTargetsModal,
  } = props;

  // States
  const [editableNote, setEditableNote] = useState<any>();
  const [ratingLoading, setRatingLoading] = useState(false);
  const [unread, setUnread] = useState<boolean>(false);
  const [ongoingTargets, setOngoingTargets] = useState<number | null>();
  const [buttonBackground, setButtonBackground] = useState("white");
  const [buttonColor, setButtonColor] = useState("");

  // Refs
  const userNote: any = useRef<any>();
  const negInputInvite: any = useRef<any>();

  const dispatch = useDispatch();
  const id = influencer.influencer_id;
  const negotiationOpen = props.negotiate?.influencer_id === id;
  const proposals =
    selectedColumn === Columns.approval && influencer?.tasks_details?.tasks
      ? influencer?.tasks_details?.tasks
          ?.map((t) => t.proposal)
          ?.filter((t) => t)
      : [];

  const isCurrentMonth = moment(board?.current_manage_month).isSame(
    moment(),
    "month",
  );

  const { board_targets } = useSelector(
    (state: RootState) => state.boardReducer,
  );

  const handleGetModalButtonColor = (
    icon: boolean,
    ongoing: number,
    completed: boolean,
  ) => {
    if (completed) {
      return icon ? "#000" : "#99DDD8";
    }
    if (ongoing) {
      return icon ? "#249FF0" : "white";
    }

    return icon ? "#808080" : "white";
  };

  const getTasks = (): InfluencerLineTasks => {
    const influTasks: TaskManageData[] =
      influencer?.tasks_details?.tasks?.filter((x) => !x.subtask_of);
    const data: InfluencerLineTasks = {};
    const good: any[] = [];
    const other: any[] = [];
    const canViewTikTok = user?.userDetails
      ? user.userDetails.sidebar_data.can_view_tiktok
      : true;
    if (selectedColumn === Columns.approval) {
      TasksTypes.forEach((t) => {
        if (!canViewTikTok && t === "tk_video") return;
        const tempTasks = influTasks?.filter(
          (x: TaskManageData) => x.post_type === t,
        );
        if (tempTasks?.length > 0) {
          data[t] = tempTasks;
          data[`${t}_done`] = influTasks?.filter(
            (x) =>
              x.post_type === t &&
              ((x.proposal && x.proposal.approved) || x.completed),
          );
          good.push(t);
        }
      });
    } else if (selectedColumn === Columns.publish) {
      TasksTypes.forEach((t) => {
        if (!canViewTikTok && t === "tk_video") return;
        const tempTasks = influTasks?.filter((x) => x.post_type === t);
        if (tempTasks?.length > 0) {
          data[t] = tempTasks;
          data[`${t}_done`] = influTasks.filter(
            (x) => x.post_type === t && x.completed,
          );
          good.push(t);
        }
      });
    }
    data.order = good.concat(other);
    return data;
  };

  useEffect(() => {
    setUnread(
      (influencer.user_registered &&
        influencer.chat_status &&
        !influencer.chat_status.no_messages &&
        !influencer.chat_status.last_message_seen &&
        !influencer.chat_status.last_message_mine) ||
        props.unreadChatsInfluencers?.includes(influencer.humanz_fk),
    );
  }, [
    influencer.chat_status,
    influencer.humanz_fk,
    influencer.user_registered,
    props.unreadChatsInfluencers,
  ]);

  const openChatsAndContent = useCallback(() => {
    if (unread) {
      setUnread(false);
    }
    props.openChatsAndContent(influencer);
  }, [influencer, props, unread]);

  let tasks: InfluencerLineTasks = getTasks();
  let invitesToRead: "half" | "full" | null = null;
  const allTasksCompleted =
    influencer?.tasks_details?.tasks?.filter((x) => x.completed)?.length ===
      influencer?.tasks_details?.tasks?.length &&
    influencer?.tasks_details?.tasks?.length > 0;
  if (influencer.tasks_invites && influencer.tasks_invites.length > 0) {
    const tmpTasks: InfluencerLineTasks = { ...tasks };
    // eslint-disable-next-line no-restricted-syntax
    for (const taskInvite of influencer.tasks_invites) {
      // eslint-disable-next-line no-continue
      if (taskInvite?.price_offered_state.includes("rejected")) continue;
      if (taskInvite?.post_type === "influencer_content") {
        invitesToRead = "half";
      } else if (!invitesToRead) {
        invitesToRead = "full";
      }
      if (!(taskInvite?.post_type in tmpTasks)) {
        tmpTasks[taskInvite.post_type] = [];
      }
      tmpTasks[taskInvite.post_type].push(taskInvite);
    }
    tasks = tmpTasks;
  }
  let newProposals = 0;
  let overResponseTime = false;
  let canRate = false;

  if (selectedColumn === Columns.bids) {
    const campaignEndTime = board?.campaign_end_time || null;
    const auctionEnded =
      campaignEndTime && new Date(campaignEndTime) <= new Date();
    if (auctionEnded) {
      const pastWeekDate = new Date(campaignEndTime);
      pastWeekDate.setDate(pastWeekDate.getDate() + props.DAYS_EXPIRE_BID);
      overResponseTime = new Date() > pastWeekDate;
    }
  } else if (selectedColumn === Columns.approval) {
    newProposals = share
      ? proposals.filter(
          (x) => x.shared_approved === null && x.approved === null,
        ).length
      : proposals.filter((x) => x.approved === null).length;
    canRate = true;
  } else if (selectedColumn === Columns.publish && !share) {
    canRate = true;
  }

  const reminders = influencer?.tasks_details?.tasks.filter(
    (x) =>
      !x.subtask_of &&
      x.reminder_data &&
      x.reminder_data.reminde_at &&
      !x.reminder_data.is_reminded,
  );

  const blurComment = (selectedInfluencer: InfluencerInManageAuctionData) => {
    const infId = selectedInfluencer?.influencer_id;
    const newNote: string = userNote?.current?.value;
    if (selectedInfluencer.note !== newNote) {
      const oldNote = selectedInfluencer.note;
      // eslint-disable-next-line no-param-reassign
      selectedInfluencer.note = newNote;
      props.changeInfluencerNoteOnBoard(
        board.board_id,
        infId,
        { new_note: newNote },
        () => {
          if (selectedInfluencer.note === oldNote) {
            // eslint-disable-next-line no-param-reassign
            selectedInfluencer.note = newNote;
          }
        },
      );
    }
    setEditableNote({});
  };

  // eslint-disable-next-line
  const {
    isOpen: isAddAgentOpen,
    onOpen: setAddAgentOpenInner,
    onClose: setAddAgentClose,
    // eslint-disable-next-line
  } = useDisclosure();

  // eslint-disable-next-line
  const setAddAgentOpen = useCallback(() => {
    setAddAgentOpenInner();
  }, [setAddAgentOpenInner]);

  const onEditNoteToggle = (
    currentInfluencer: InfluencerInManageAuctionData,
  ) => {
    if (editableNote?.influencer_id === currentInfluencer?.influencer_id) {
      blurComment(currentInfluencer);
    } else {
      setEditableNote({
        influencer_id: currentInfluencer?.influencer_id,
        note: currentInfluencer?.note,
      });
    }
  };

  useEffect(() => {
    // count all steps in targets for this influencer
    const allStepsForThisInfluencer = board_targets?.influencers[
      influencer.influencer_id
    ]
      ?.map((target) => {
        return target.steps;
      })
      ?.flat()?.length;

    const completedInfluencerStepsForThisMonth = board_targets?.influencers[
      influencer.influencer_id
    ]
      ?.map((target) => {
        return target.steps?.filter((step) => {
          return !board.one_month_campaign
            ? step?.completed_at &&
                !board.one_month_campaign &&
                !!props?.month &&
                moment(step?.month_relevance).isSame(props?.month)
            : !!step.completed_at;
        });
      })
      ?.flat()?.length;
    const completed =
      allStepsForThisInfluencer > 0 &&
      allStepsForThisInfluencer === completedInfluencerStepsForThisMonth;

    const ongoing = board_targets?.influencers[
      influencer.influencer_id
    ]?.filter(
      (target) =>
        target.steps?.filter((step) =>
          !board.one_month_campaign
            ? props?.month
              ? moment(step?.month_relevance).isSame(props?.month)
              : true
            : true,
        ).length,
    ).length;

    setButtonBackground(handleGetModalButtonColor(false, ongoing, completed));
    setButtonColor(handleGetModalButtonColor(true, ongoing, completed));
    setOngoingTargets(ongoing);
  }, [board_targets]);

  useEffect(() => {
    if (editableNote?.influencer_id && userNote?.current) {
      userNote?.current?.focus();
      userNote.current.selectionStart = userNote?.current?.value?.length;
    }
  }, [editableNote]);

  const AttachLinksButton: any = (p: any) => {
    return useMemo(() => {
      const { linkTasks } = p;
      if (!linkTasks) return null;
      const waitingStories =
        linkTasks.ig_story_done && linkTasks.ig_story_done.length > 0
          ? influencer?.tasks_details?.tasks
            ? influencer?.tasks_details?.tasks.filter(
                (x) =>
                  x.post_type === "ig_story" &&
                  x.completed &&
                  !x.fb_id &&
                  !x?.engagemnts.views,
              ).length
            : 0
          : 0;

      const waitingPosts =
        linkTasks.ig_post_done && linkTasks.ig_post_done.length > 0
          ? influencer?.tasks_details?.tasks
            ? influencer?.tasks_details?.tasks.filter(
                (x) =>
                  x.post_type === "ig_post" &&
                  x.completed &&
                  influencer?.social_network_data.instagram
                    ?.verification_type !== "api" &&
                  isEmptyPostStats(x.engagemnts),
              ).length
            : 0
          : 0;

      return (
        <>
          <span>Attach social media links</span>
          <button
            className={"btn btn-primary show-content-btn "}
            disabled={influencer.start_from && isCurrentMonth}
            onClick={() =>
              props.setOpenAttachLinks({
                influencer,
                boardId: board.board_id,
              })
            }
          >
            <span>ATTACH LINKS</span>
            <i className="fas fa-link" />
            {waitingStories > 0 || waitingPosts > 0 ? (
              <div
                className="inf-cube-badge"
                style={{ marginLeft: 105, marginTop: -30 }}
              >
                <Tooltip overlay={"Missing story views or posts data"}>
                  <span>{(waitingStories || 0) + (waitingPosts || 0)}</span>
                </Tooltip>
              </div>
            ) : null}
          </button>
        </>
      );
    }, [p]);
  };

  const renderReminderTag = (_: TaskManageData, idx: number) => {
    return (
      <div
        style={{ background: "#17a086", cursor: "pointer" }}
        key={idx}
        dir="auto"
        onClick={() => props.openAlarmDialog(influencer)}
      >
        <i className="far fa-alarm-clock" style={{ color: "white" }} /> Task #
        {idx + 1}
      </div>
    );
  };

  const renderAcademyBadge = () => {
    return (
      <Tooltip
        placement={"bottom"}
        overlay={"Graduate of the Humanz Influencer Marketing Academy"}
      >
        <div className={"academy-badge badge-hidden"}>
          <img height={40} src={"/images/academy_badge.svg"} alt="" />
        </div>
      </Tooltip>
    );
  };

  const openInfluencerRatings = () => {
    setRatingLoading(true);
    props.getInfluencerData(
      influencer.influencer_id,
      {},
      (data: { influencer_data: InfluencerExtendedData }) => {
        if (data) {
          const general = data.influencer_data;
          const name = influencer.name || general.user_name || "";
          const image = influencer.picture_url || null;

          const ratingData = {
            ...general.reviews,
            name,
            image,
            influencer_id: influencer.influencer_id,
          };
          window.openInfluencerRating(ratingData);
        }
        setRatingLoading(false);
      },
    );
  };

  const currencyLabel = () => {
    if (!board) {
      return null;
    }
    return window.translate("", `money_symbol_${board?.summary.currency}`);
  };

  const renderTooltipInner = (type: PostTypes, ownTasks: any) => {
    return selectedColumn === Columns.publish ? (
      <div>
        {type === "influencer_content" &&
          ownTasks.map((t: TaskManageData, idx: number) => {
            return t.all_task_content.map((x: any) => (
              <div key={idx} style={{ color: "white", display: "flex" }}>
                <i
                  className="fas fa-link"
                  style={{ marginRight: 5, marginTop: 3 }}
                />
                <a
                  href={x}
                  target="_blank"
                  style={{ color: "white", cursor: "pointer" }}
                  rel="noreferrer"
                >
                  {x}
                </a>
              </div>
            ));
          })}

        {type === "ig_post" &&
          ownTasks.map((x: TaskManageData, idx: number) => {
            return (
              <div key={idx} style={{ color: "white", display: "flex" }}>
                <i
                  className="fas fa-link"
                  style={{ marginRight: 5, marginTop: 3 }}
                />
                <a
                  href={getSocialMediaLinkByContentType(type, x.linked_media)}
                  target="_blank"
                  style={{ color: "white", cursor: "pointer" }}
                  rel="noreferrer"
                >
                  {getSocialMediaLinkByContentType(type, x.linked_media)}
                </a>
              </div>
            );
          })}
        {type === "tw_tweet" &&
          ownTasks.map((x: TaskManageData, idx: number) => {
            return (
              <div key={idx} style={{ color: "white", display: "flex" }}>
                <i
                  className="fas fa-link"
                  style={{ marginRight: 5, marginTop: 3 }}
                />
                <a
                  href={getSocialMediaLinkByContentType(type, x.linked_media)}
                  target="_blank"
                  style={{ color: "white", cursor: "pointer" }}
                  rel="noreferrer"
                >
                  {getSocialMediaLinkByContentType(type, x.linked_media)}
                </a>
              </div>
            );
          })}
        {type === "yt_video" &&
          ownTasks.map((x: TaskManageData, idx: number) => {
            return (
              <div key={idx} style={{ color: "white", display: "flex" }}>
                <i
                  className="fas fa-link"
                  style={{ marginRight: 5, marginTop: 3 }}
                />
                <a
                  href={getSocialMediaLinkByContentType(type, x.linked_media)}
                  target="_blank"
                  style={{ color: "white", cursor: "pointer" }}
                  rel="noreferrer"
                >
                  {getSocialMediaLinkByContentType(type, x.linked_media)}
                </a>
              </div>
            );
          })}
        {type === "tk_video" &&
          ownTasks.map((x: TaskManageData, idx: number) => {
            return (
              <div key={idx} style={{ color: "white", display: "flex" }}>
                <i
                  className="fas fa-link"
                  style={{ marginRight: 5, marginTop: 3 }}
                />
                <a
                  href={getSocialMediaLinkByContentType(type, x.linked_media)}
                  target="_blank"
                  style={{ color: "white", cursor: "pointer" }}
                  rel="noreferrer"
                >
                  {getSocialMediaLinkByContentType(type, x.linked_media)}
                </a>
              </div>
            );
          })}
      </div>
    ) : null;
  };

  const renderWarningTasks = () => {
    return (
      <Tooltip
        placement="top"
        trigger={["hover"]}
        overlay={
          <div className="tasks-alert-container">
            <span
              style={{ fontSize: 13 }}
            >{`Notice! This influencer has one or more tasks awaiting for their approval. As long as the tasks are not approved, the system will not count them.${
              invitesToRead === "full"
                ? " You can attach links to these ​tasks that will automatically approve the tasks and mark them as completed."
                : ""
            }`}</span>
          </div>
        }
      >
        <div
          className="tasks-warning"
          style={{ color: "var(--chakra-colors-red-400)", fontSize: 11 }}
        >
          <i className="fas fa-exclamation-triangle" /> Pending &nbsp;
        </div>
      </Tooltip>
    );
  };

  const renderCompletedTasks = () => {
    return (
      <Tooltip
        placement="top"
        trigger={["hover"]}
        overlay={"All tasks were completed"}
      >
        <div
          className="tasks-completed"
          style={{ color: "#00A99D", fontSize: 11 }}
        >
          <i className="far fa-badge-check" /> <span>Done</span>
        </div>
      </Tooltip>
    );
  };

  const renderTasksCubes = () => {
    const isSurvey = board?.summary.campaign_type === "survey";
    const showEditTasksText = allTasksCompleted;
    const hasOffers =
      influencer?.offer_details?.offer_status === "wait_influencer";
    return (
      (selectedColumn === Columns.approval ||
        selectedColumn === Columns.publish) && (
        <Box style={{ width: 180 }}>
          <div
            style={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              alignItems: "center",
              minHeight: 88,
              justifyContent: "center",
              padding: 0,
            }}
            className={"tasks-container"}
          >
            {!isSurvey && !share && (
              <>
                <Flex style={{ alignContent: "center" }}>
                  {hasOffers ? renderWarningTasks() : null}
                  {allTasksCompleted && !hasOffers && !invitesToRead
                    ? renderCompletedTasks()
                    : null}
                  <Tooltip
                    trigger={[showEditTasksText ? "hover" : null]}
                    overlay={"Edit tasks"}
                    placement={"top"}
                  >
                    <Button
                      variant={"small-action"}
                      isDisabled={
                        !props.nextMonthMode &&
                        influencer.start_from &&
                        isCurrentMonth
                      }
                      onClick={() => setEditTasksData(influencer)}
                      style={{
                        height: "20px",
                        width: !showEditTasksText ? 68 : 20,
                        padding: "4px 0",
                        marginBottom: "5px",
                        marginLeft: !showEditTasksText ? 0 : 5,
                      }}
                    >
                      {!showEditTasksText ? <strong>Edit tasks</strong> : null}
                      <i
                        className="fa-solid fa-pen-to-square"
                        style={{ marginLeft: 2 }}
                      />
                    </Button>
                  </Tooltip>
                  {!props.nextMonthMode && (
                    <Box sx={{ position: "relative", marginBottom: "5px" }}>
                      {!!ongoingTargets && (
                        <Box className="campaign-influencer-row__targets-button-indicator">
                          <Text>{ongoingTargets}</Text>
                        </Box>
                      )}
                      <Tooltip
                        trigger={[showEditTasksText ? "hover" : null]}
                        overlay={"KPIs targets"}
                        placement={"top"}
                      >
                        <Button
                          variant={"small-action"}
                          sx={{
                            height: "20px",
                            backgroundColor: buttonBackground,
                            boxShadow: "0px 1.33px 2.34px 0px #0000002B",
                            padding: "4px 2px",
                            marginLeft: "5px",
                            marginTop: -1,
                            color: buttonColor,
                          }}
                          style={{
                            minWidth: showEditTasksText ? "30px" : "40px",
                          }}
                          onClick={() => openTargetsModal(influencer)}
                        >
                          {!showEditTasksText ? "KPIs " : null}
                          <i
                            style={{ marginLeft: 1, fontSize: 11 }}
                            className="fa-solid fa-bullseye-arrow fa-sm"
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                  )}
                </Flex>
              </>
            )}
            <div className="influencer-cube-tasks">
              {tasks?.order?.map((t: any) => {
                return (
                  <Tooltip
                    key={t}
                    trigger={
                      selectedColumn === Columns.publish &&
                      t !== "ig_story" &&
                      tasks[`${t}_done`].length > 0
                        ? ["hover"]
                        : []
                    }
                    placement={"top"}
                    overlayClassName={"tasks-tooltip"}
                    overlay={renderTooltipInner(t, tasks[`${t}_done`]) || ""}
                  >
                    <div
                      className={`${
                        // eslint-disable-next-line no-nested-ternary
                        tasks[t].length > 0
                          ? tasks[`${t}_done`].length === tasks[t].length
                            ? "done"
                            : "pending"
                          : ""
                      }`}
                    >
                      <li
                        className={
                          AllSocialIcons[t as keyof typeof AllSocialIcons]
                        }
                      />{" "}
                      <div>
                        {tasks[`${t}_done`].length}/{tasks[t].length}
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        </Box>
      )
    );
  };

  // const toggleNegotiationBid = () => {
  //   if (influencer.influencer_id === props.negotiate?.influencer_id) {
  //     props.setNegotiate({});
  //   } else {
  //     props.setNegotiate(influencer);
  //   }
  // };

  const renderInfluencerRatingBadge = () => {
    if (!influencer.avg_review) return null;
    return (
      <>
        {ratingLoading && (
          <div className="rating-loader logo-loader logo-loader-small">
            <div className="loader" />
          </div>
        )}
        <Tooltip
          placement={"bottom"}
          overlay={`Show all reviews (Average ${influencer.avg_review} stars)`}
        >
          <div
            onClick={() => {
              openInfluencerRatings();
            }}
            className={"star-badge"}
          >
            <i className={"fas fa-star"} />
            <span>{influencer.avg_review}</span>
          </div>
        </Tooltip>
      </>
    );
  };

  const renderInfluencerActions = () => {
    const actions = [];
    if (selectedColumn < Columns.publish && !influencer.stop_next_month) {
      actions.push({
        action: () => props.sendToTrash(influencer, true),
        text:
          (props.nextMonthMode && !influencer.start_from) ||
          (!board.one_month_campaign &&
            influencer.tasks_details?.tasks?.some((t) => t.completed))
            ? "Stop next month"
            : "Send to trash",
        icon: "fad fa-trash-alt",
      });
    }
    if (!props.nextMonthMode && canRate) {
      actions.push({
        action: () => {
          props.influencerRating?.current?.show(influencer, board?.summary.id);
        },
        text: "Rate",
        icon: `fad fa-star ${!!influencer.review}`,
      });
    }
    if (
      !props.nextMonthMode &&
      selectedColumn > Columns.invite &&
      selectedColumn < Columns.trash
    ) {
      actions.push({
        action: () => props.openAlarmDialog(influencer),
        text: "Add reminder",
        icon: `fad fa-alarm-clock`,
      });
    }
    if (!share) {
      if (
        !props.nextMonthMode &&
        influencer.questions &&
        influencer.questions.length > 0
      ) {
        actions.push({
          action: () => {},
          text: "Questions and Answers",
          icon: `fa moi-QA`,
          children: (
            <Tooltip
              overlayClassName={"edit-popup"}
              overlay={() =>
                props.renderQuestionsToolTip(influencer.questions) || ""
              }
              trigger={["hover"]}
              placement="left"
            >
              <div>
                <i className="fa moi-QA" />
                <span> Questions and Answers</span>
              </div>
            </Tooltip>
          ),
        });
      }
      if (
        !props.nextMonthMode &&
        influencer.user_registered &&
        selectedColumn !== Columns.approval &&
        selectedColumn !== Columns.trash
      ) {
        actions.push({
          action: () => {},
          text: "Chat and content",
          icon: `far fa-envelope`,
          children: (
            <div onClick={openChatsAndContent}>
              <i className="far fa-envelope" />
              <span> Chat </span>
            </div>
          ),
        });
      }
      if (
        !influencer.influencer_agent &&
        user?.userDetails?.sidebar_data?.allowed_influencer_agent
      ) {
        actions.push({
          action: setAddAgentOpen,
          text: "Add agent",
          icon: `fad fa-user-tie`,
        });
      }
    }
    return (
      <Box>
        <HumanzActionsMenu
          buttontype="dots"
          placement="bottomRight"
          actions={actions}
        />
        {Columns.approval !== selectedColumn && unread ? (
          <div className="new-message-icon" onClick={openChatsAndContent}>
            <div className="indicator fa-beat" />
            <i className="fa-light fa-envelope" />
          </div>
        ) : null}
      </Box>
    );
  };

  const renderDispute = () => {
    return (
      influencer.dispute && (
        <div className="dispute-pending-container">
          <div className="dispute-button">
            <i className="fa-solid fa-info-circle" /> Dispute
          </div>
          <div className="dispute-more-data">
            {influencer.dispute.name && (
              <div>
                <strong>Name: </strong>
                <span>{influencer.dispute.name}</span>
              </div>
            )}
            {influencer.dispute.custom_message && (
              <div>
                <strong>Message: </strong>
                <span>{influencer.dispute.custom_message}</span>
              </div>
            )}
            <div>
              <strong>Date: </strong>
              <span>{influencer.dispute.creation_time}</span>
            </div>
            {influencer.dispute.response && (
              <div>
                <strong>Response: </strong>
                <span>{influencer.dispute.response}</span>
              </div>
            )}
            {influencer.dispute.response_time && (
              <div>
                <strong>Response time: </strong>
                <span>{influencer.dispute.response_time}</span>
              </div>
            )}
          </div>
        </div>
      )
    );
  };

  const RespondButton = (negprops: any) => {
    return (
      <Button
        variant={"shadowed"}
        onClick={negprops.action}
        className={"show-content-btn"}
        style={{
          height: 40,
          // width: 100,
          // padding: "4px 0px",
          // marginTop: 7,
          // background: "white",
        }}
      >
        <span style={{ fontWeight: 400, textTransform: "uppercase" }}>
          Respond
        </span>

        <i className="fa-solid fa-comment-dollar" style={{ marginLeft: 5 }} />
      </Button>
    );
  };

  const TimeBadge = ({ type }: { type: string }) => {
    const now = new Date();
    let text = "";
    let bgcolor = "red.500";
    if (type === "starts_from") {
      bgcolor = "blue.300";
      text = "Starts next month";
    }
    if (type === "stops_next_month") {
      const nextMonth = moment(
        new Date(now.getFullYear(), now.getMonth() + 1, 1),
      );
      const days = nextMonth.diff(now, "days") + 1;
      text = days > 0 ? `Ends in ${days} days` : `Ended`;
    }

    return (
      <Box
        sx={{
          position: "absolute",
          right: type === "starts_from" ? "auto" : 0,
          left: type === "starts_from" ? 0 : "auto",
          bottom: 0,
          background: bgcolor,
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
          padding: "2px 4px",
          borderRadius:
            type === "starts_from" ? "0 6px 0px 6px" : "6px 0 6px 0",
        }}
      >
        <i className="fa-duotone fa-clock" style={{ marginRight: "5px" }}></i>{" "}
        <span style={{ color: "white", fontSize: "13px" }}>{text}</span>
      </Box>
    );
  };

  const refreshBoard = (month?: string, callback?: any) => {
    dispatch(
      updateBoardDetails(
        board.board_id,
        board?.etag,
        month || board.current_manage_month,
        () => {
          props.updateColumns();
          if (callback) callback();
        },
      ),
    );
  };

  return influencer ? (
    <div key={`${id}`} className="influencer-row-container">
      <div
        className={`influencer-row${
          influencer.dispute ? " dispute-pending" : ""
        } ${influencer.start_from && isCurrentMonth ? "starts-from" : ""} `}
      >
        {influencer.stop_next_month &&
          (selectedColumn === Columns.approval ||
            (selectedColumn === Columns.publish && isCurrentMonth && (
              <TimeBadge type="stops_next_month" />
            )))}
        {influencer.start_from &&
          isCurrentMonth &&
          (selectedColumn === Columns.approval ||
            selectedColumn === Columns.publish) && (
            <TimeBadge type="starts_from" />
          )}

        {renderDispute()}
        <div>
          <div className="influencer-side-actions">
            {renderInfluencerActions()}
          </div>
          <div
            className="tags-row"
            style={{ height: "auto", overflow: "initial" }}
          >
            <CampaignInfluencerTags
              board={board}
              influencer={influencer}
              influencerId={influencer.influencer_id}
              selectedInfluencers={selectedInfluencers}
              boardTags={board.tags}
              onUpdate={() => props.updateColumns()}
            />
            {reminders?.map(renderReminderTag)}
            <CampaignInfluencerTagsList
              tags={board?.tags?.filter((t) =>
                t.influencers.includes(influencer.influencer_id),
              )}
            />
          </div>
        </div>
        <Flex>
          <Flex flex={1} style={{ alignItems: "center" }}>
            <Box
              display={"inline-flex"}
              alignItems={"center"}
              position={"relative"}
              w={50}
              h={50}
              mr={5}
              className={"img blurable clickable"}
              style={{
                background: influencer?.picture_url
                  ? "none"
                  : `url(${NoImage})`,
                backgroundSize: "cover",
                backgroundPosition: "0 0",
              }}
            >
              {influencer.picture_url && (
                <img
                  className="img"
                  src={influencer.picture_url}
                  onClick={() =>
                    props.openInfluencerSegmentations(
                      `${influencer.shadow_influencer ? "shadow_" : ""}${id}`,
                    )
                  }
                />
              )}
              {influencer.academy_badge && renderAcademyBadge()}
              {influencer.avg_review && renderInfluencerRatingBadge()}
              {influencer.community_badge && (
                <CampaignCommunityBadge
                  communityBadge={influencer.community_badge}
                  badgeStyleType="row"
                />
              )}
            </Box>
            <Box display={"inline-flex"}>
              <div className="inf-row-name">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {influencer.influencer_agent && (
                    <div className={`humanz-indicator`}>
                      <div
                        className="fas fa-user-tie"
                        aria-label={`Agency - ${influencer.influencer_agent.name}`}
                      ></div>
                    </div>
                  )}
                  <div
                    className={`humanz-indicator influencer-app-indicator ${
                      influencer.user_registered ? "active" : ""
                    }`}
                  >
                    <Tooltip
                      placement={"top"}
                      overlay={`Humanz App ${
                        influencer.user_registered ? "" : "not"
                      } installed`}
                    >
                      <div className="fas fa-mobile-alt" />
                    </Tooltip>
                  </div>
                  <span
                    className={`influencer-name ${
                      influencer.user_registered ? "active" : ""
                    } blurable`}
                    onClick={() =>
                      props.openInfluencerSegmentations(
                        `${influencer.shadow_influencer ? "shadow_" : ""}${id}`,
                      )
                    }
                  >
                    {influencer.name}
                  </span>
                </div>
                {<CampaignInfluencerFollowersNumbers influencer={influencer} />}
              </div>
            </Box>
          </Flex>

          {selectedColumn < Columns.trash && showCost && (
            <Box>
              <AttachAgentDialog
                isOpen={isAddAgentOpen}
                onClose={setAddAgentClose}
                auctionId={board?.board_id}
                influencer={{
                  id: influencer?.influencer_id,
                  name: influencer?.name,
                }}
              />
              <div className="price-row-trigger">
                <span
                  style={{
                    marginTop:
                      selectedColumn === Columns.bids ||
                      selectedColumn === Columns.invite
                        ? "20px"
                        : 0,
                  }}
                >
                  {props.nextMonthMode ? "Total" : "Base price"}
                </span>
                <CampaignInfluencerPrice
                  nextMonthMode={props.nextMonthMode}
                  selectedColumn={selectedColumn}
                  influencerData={influencer}
                  month={board?.current_manage_month}
                  bidMoreThanMax={
                    selectedColumn === Columns.bids &&
                    influencer?.bid_status?.bid &&
                    influencer?.passed_max_bid
                  }
                  enableActions={{
                    addExtraPayout: [0, 1].indexOf(selectedColumn) === -1,
                    auctionId: id,
                    boardId: board?.board_id,
                    enableAgentAdd:
                      !influencer.influencer_agent &&
                      user?.userDetails?.sidebar_data?.allowed_influencer_agent,
                    onUploadInvoice: () => {
                      if (influencer?.approved_at) {
                        refreshBoard();
                      }
                    },
                  }}
                />
              </div>
            </Box>
          )}

          {renderTasksCubes()}

          {!share && (
            <CampaignInfluencerRowComment
              onEditNoteToggle={onEditNoteToggle}
              editableNote={editableNote}
              auctionId={props.board?.board_id}
              influencer={influencer}
              userNote={userNote}
              refreshBoard={refreshBoard}
            />
          )}

          {/* Questions button */}
          {(selectedColumn === Columns.invite ||
            selectedColumn === Columns.bids) &&
            influencer.questions &&
            influencer.questions.length > 0 && (
              <Box className="questions-answers">
                <Tooltip
                  overlayClassName={"edit-popup"}
                  overlay={
                    props.renderQuestionsToolTip(influencer.questions) || ""
                  }
                  trigger={["click", "hover"]}
                  placement="top"
                >
                  <button
                    style={{
                      color: "#00A99D",
                      width: 50,
                      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
                      background: "#FFFFFF",
                    }}
                    className={"show-content-btn btn btn-primary "}
                  >
                    Q&A
                  </button>
                </Tooltip>
              </Box>
            )}

          {selectedColumn === Columns.bids ? (
            <Box className="content-and-links-row">
              <>
                <RespondButton action={() => setSelectedBid(influencer)} />
              </>
            </Box>
          ) : null}

          {selectedColumn === Columns.invite && (
            <Box className="content-and-links-row">
              {negotiationOpen ? (
                <div className="manage-bid">
                  <span
                    style={{
                      marginLeft: 5,
                      marginTop: 6,
                      color: "var(--main-baby-blue-color)",
                      position: "absolute",
                    }}
                  >
                    {currencyLabel()}
                  </span>
                  <input
                    className="change-bid-input"
                    defaultValue={influencer.invite_status.price_offered}
                    type="number"
                    ref={negInputInvite}
                  />
                  <li
                    className="fa fa-times cancel"
                    onClick={() => setSelectedBid(influencer)}
                  />
                  <RespondButton action={() => setSelectedBid(influencer)} />
                </div>
              ) : (
                <>
                  <span>Approval actions</span>
                  <div>
                    {influencer.invite_status.price_offered_state ===
                      "wait_advertiser" && (
                      <>
                        <RespondButton
                          action={() => setSelectedBid(influencer)}
                        />
                      </>
                    )}
                    {influencer.invite_status.price_offered_state ===
                      "wait_influencer" &&
                      (influencer.user_registered ? (
                        <span className="approval-status-text">
                          {" "}
                          Waiting for influencer
                        </span>
                      ) : (
                        <>
                          <RespondButton
                            action={() => setSelectedBid(influencer)}
                          />
                        </>
                      ))}

                    {influencer.invite_status.price_offered_state ===
                      "influencer_rejected" && (
                      <>
                        <span
                          className="approval-status-text"
                          style={{
                            color: "#cf4f4e",
                          }}
                        >
                          Rejected by influencer
                        </span>
                      </>
                    )}
                  </div>

                  {!negotiationOpen &&
                    influencer.invite_status.price_offered_state ===
                      "wait_advertiser" &&
                    overResponseTime && (
                      <RespondButton
                        action={() => setSelectedBid(influencer)}
                      />
                    )}
                </>
              )}
            </Box>
          )}

          {!share &&
            selectedColumn === Columns.approval &&
            !props.nextMonthMode && (
              <Box className="content-and-links-row">
                <span>Review influencer content</span>
                <button
                  className={"btn btn-primary show-content-btn "}
                  onClick={openChatsAndContent}
                  disabled={influencer.start_from && isCurrentMonth}
                >
                  {influencer.user_registered && "CHAT & "} REVIEW{" "}
                  <i
                    className="fa-duotone fa-arrow-up-from-line"
                    style={{ marginLeft: 0 }}
                  />
                  {newProposals ? (
                    <div
                      className="inf-cube-badge"
                      onClick={openChatsAndContent}
                    >
                      {newProposals}
                    </div>
                  ) : null}
                  {unread || waitingNotes?.length ? (
                    <div
                      className={`inf-cube-badge ${
                        newProposals ? "message" : ""
                      }`}
                    >
                      <i className="far fa-envelope" />
                    </div>
                  ) : null}
                </button>
              </Box>
            )}

          {selectedColumn === Columns.publish && (
            <Box className="content-and-links-row">
              <AttachLinksButton
                influencer={influencer}
                linkTasks={tasks}
                boardId={board?.summary.id}
              />
            </Box>
          )}
          {selectedColumn === Columns.trash && (
            <Box className="content-and-links-row">
              <button
                className={"show-content-btn btn btn-primary"}
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                }}
                onClick={() => props.toggleInviteMenu(influencer)}
              >
                <span>REINVITE</span>
                <i className="fas fa-undo" />
              </button>
            </Box>
          )}
        </Flex>
      </div>
      {!share && (
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={selectedInfluencers.includes(id)}
              onChange={() => props.selectInfluencer([id])}
            />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
          </label>
        </div>
      )}
    </div>
  ) : null;
};
