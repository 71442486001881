import { Avatar, Box, Flex, Tag, Text } from "@chakra-ui/react";
import moment from "moment";

type Props = {
  image?: string;
  name?: string;
  month?: string;
  label?: string;
  isMultiple?: boolean;
  OneTimeCampaign?: boolean;
};

const ManageModalHeader = ({
  image,
  name,
  month,
  label,
  isMultiple,
  OneTimeCampaign,
}: Props) => {
  return (
    <Box className="modal-header" paddingTop={0}>
      <Flex alignItems={"center"} gap={3}>
        <Box>
          {isMultiple ? (
            <Tag mr={2} variant={"subtle"}>
              Multiple influencers
            </Tag>
          ) : (
            <Flex alignItems={"center"} gap={3}>
              {image && <Avatar src={image} />}
              <Box>
                <Text fontWeight={500} color={"#333"} fontSize={"16px"}>
                  {name}
                </Text>
                {!OneTimeCampaign && (
                  <Tag variant={"outline"}>
                    {label ||
                      `${
                        month ? moment(month).format("MMMM YYYY") : "Edit"
                      } tasks`}
                  </Tag>
                )}
              </Box>
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ManageModalHeader;
