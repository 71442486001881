import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Radio,
  SlideFade,
} from "@chakra-ui/react";
import Tooltip, { RCTooltip } from "rc-tooltip";
import { useState } from "react";

interface OwnProps extends ButtonProps {
  buttontype?: "dots" | "button";
  buttontext?: string | any;
  placement?: RCTooltip.Placement;
  actions: Array<ButtonSingleAction>;
  trigger?: RCTooltip.Trigger[] | undefined;
  disabled?: boolean;
}

export interface ButtonSingleAction {
  action?: any;
  text?: string;
  icon?: string;
  disabled?: boolean;
  type?: "link" | "checkbox" | "radio";
  checked?: boolean;
  children?: any;
}

const BUTTON_HEIGHT = 27;

export const HumanzActionsMenu = (props: OwnProps) => {
  const { buttontype, buttontext, placement, actions } = props;
  const [isOpen, setIsOpen] = useState(false);

  const renderSingleAction = (
    {
      action,
      text,
      icon,
      disabled,
      type,
      checked,
      children,
    }: ButtonSingleAction,
    key: number,
  ) => {
    return (
      <div className="single-action" key={key}>
        <Button
          isDisabled={disabled}
          minHeight={BUTTON_HEIGHT}
          onClick={(e) => {
            if (action) {
              e.preventDefault();
              e.stopPropagation();
              action();
            }
            setTimeout(() => {
              setIsOpen(false);
            }, 100);
          }}
          variant="ghost"
          className="actions-menu-single-action"
        >
          {children || (
            <>
              {type === "checkbox" && <Checkbox checked={checked} />}
              {type === "radio" && <Radio checked={checked} />}
              {icon ? (
                <i style={{ height: 20 }} className={`${icon}`} />
              ) : (
                ""
              )}{" "}
              {text}
            </>
          )}
        </Button>
      </div>
    );
  };

  const renderButton = () => {
    return (
      <Button
        variant={"unstyled"}
        className={`${
          buttontype === "dots" ? "humanz-button-dots" : "humanz-button"
        } ${isOpen ? "active" : ""} ${props.disabled ? "disabled" : ""}`}
        style={buttontype === "button" ? null : { borderRadius: 50 }}
        onClick={(e) => {
          if (!props.disabled) {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          }
        }}
        {...props}
      >
        {buttontype === "button" ? buttontext || "Actions " : ""}
        {buttontype === "button" ? (
          <i
            className={`fas fa-chevron-down ${
              isOpen ? "fa-flip-vertical" : ""
            }`}
            style={{ transition: "all 0.3s", marginLeft: 8 }}
          />
        ) : (
          <i
            className={`fas fa-ellipsis-vertical ${isOpen ? "active" : ""}`}
            style={{ transition: "all 0.3s" }}
          />
        )}
      </Button>
    );
  };

  const renderMenu = () => {
    return (
      <>
        {actions?.length && (
          <SlideFade in={isOpen} offsetY="-20px">
            <Box minHeight={BUTTON_HEIGHT * actions.filter((a) => a).length}>
              {actions.map(
                (action: ButtonSingleAction, key: number) =>
                  action && renderSingleAction(action, key),
              )}
            </Box>
          </SlideFade>
        )}
      </>
    );
  };

  return actions?.length ? (
    <Tooltip
      overlayClassName="action-menu-style"
      placement={placement || "bottomLeft"}
      trigger={props.trigger || ["click"]}
      visible={isOpen && !props.disabled}
      defaultVisible={false}
      overlay={() => renderMenu()}
      destroyTooltipOnHide={true}
      onVisibleChange={(v) => setIsOpen(v)}
    >
      <div>{renderButton()}</div>
    </Tooltip>
  ) : null;
};
