import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import produce from "immer";
import React, { useCallback, useState } from "react";
import {
  useAddExtraPayout,
  useDeleteExtraPayout,
} from "src/api/actions/auctionActions";
import { InfluencerInManageAuctionData } from "src/api/types";
import { useDeletePrompt } from "src/hooks/promt";
import { Form } from "src/ui/Form";
import { FormFooterButtonGroup } from "src/ui/FormFooterButtonGroup";
import { MoneyInput } from "src/ui/MoneyInput";
import { formatBigNumbers } from "../../utilities/general";

const BreakdownRow: React.FC<{
  name: string;
  value: string;
  bold?: boolean;
  onDelete?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltip?: boolean;
  highlight?: boolean;
  number?: "secondLast" | "last";
  last?: boolean;
}> = ({
  name,
  value,
  bold,
  onDelete,
  isLoading,
  isDisabled,
  tooltip,
  highlight,
  number,
}) => (
  <>
    <GridItem overflow="hidden">
      <Flex
        alignItems="center"
        bg={highlight ? "green.200" : undefined}
        borderRadius="md"
        borderTopEndRadius="none"
        borderBottomEndRadius="none"
        h="12"
        ps="3"
        pe="1"
        borderColor="gray.200"
        borderBottomWidth="1px"
        borderBottomStyle={
          (number
            ? {
                secondLast: "solid",
                last: "hidden",
              }[number]
            : "dashed") as NonNullable<
            React.ComponentProps<typeof Box>["borderBottomStyle"]
          >
        }
      >
        {tooltip ? (
          <Tooltip label={name}>
            <Text
              color="#888888"
              fontWeight={bold ? "bold" : undefined}
              isTruncated
            >
              {name}
            </Text>
          </Tooltip>
        ) : (
          <Text
            color="#888888"
            fontWeight={bold ? "bold" : undefined}
            isTruncated
          >
            {name}
          </Text>
        )}
      </Flex>
    </GridItem>
    <GridItem>
      <Flex
        justify="space-between"
        alignItems="center"
        bg={highlight ? "green.200" : undefined}
        borderRadius="md"
        borderTopStartRadius="none"
        borderBottomStartRadius="none"
        h="12"
        pe="3"
        borderColor="gray.200"
        borderBottomWidth="1px"
        borderBottomStyle={
          (number
            ? {
                secondLast: "solid",
                last: "hidden",
              }[number]
            : "dashed") as NonNullable<
            React.ComponentProps<typeof Box>["borderBottomStyle"]
          >
        }
        whiteSpace="nowrap"
      >
        <Text fontWeight="semibold">{value}</Text>
        {onDelete ? (
          <IconButton
            tabIndex={-1}
            disabled={isDisabled}
            isLoading={isLoading}
            icon={<div className="fa fa-times-circle" />}
            aria-label="Delete added amount"
            variant="ghost-non-focusable"
            onClick={onDelete}
          />
        ) : null}
      </Flex>
    </GridItem>
  </>
);

type Props = {
  currencyLabel?: string;
  influencerData: InfluencerInManageAuctionData;
  isTotalWithTax?: boolean;
  enableActions?: {
    addExtraPayout: boolean;
    auctionId: number;
    enableAgentAdd?: boolean;
    onUploadInvoice?: () => void;
    onAgentAdd?: () => void;
  };
};

export const CampaignInfluencerPriceModelContent: React.FC<Props> = ({
  currencyLabel,
  influencerData,
  enableActions,
}) => {
  const {
    influencer_id: influencerId,
    price_details: priceDetails,
    payment_status: paymentStatus,
    influencer_agent: influencerAgent,
    payment_due_date: paymentDue,
    payment_details: paymentDetails,
  } = influencerData;
  const agentName = influencerAgent?.name;

  const currencySymbol = window.translate(
    "",
    `money_symbol_${priceDetails.currency}`,
  );

  const [extraPayout, setExtraPayouts] = useState<
    { amount: number; comment: string } | undefined
  >();
  const openExtraPayout = useCallback(() => {
    setExtraPayouts({
      amount: 0,
      comment: "",
    });
  }, [setExtraPayouts]);
  const closeExtraPayout = useCallback(() => {
    setExtraPayouts(undefined);
  }, [setExtraPayouts]);

  const handleAmountChange = useCallback<
    NonNullable<React.ComponentProps<typeof MoneyInput>["onValueChange"]>
  >(
    (newValue) => {
      setExtraPayouts(
        produce((draft) => {
          if (draft) {
            draft.amount = newValue;
          }
        }),
      );
    },
    [setExtraPayouts],
  );
  const handleCommentChange = useCallback<
    NonNullable<React.ComponentProps<typeof Textarea>["onChange"]>
  >(
    (e) => {
      setExtraPayouts(
        produce((draft) => {
          if (draft) {
            draft.comment = e.target.value.substring(0, 140);
          }
        }),
      );
    },
    [setExtraPayouts],
  );

  const addExtraPayout = useAddExtraPayout();

  const handleFormSubmit = useCallback(async () => {
    if (enableActions && extraPayout) {
      await addExtraPayout.mutateAsync({
        auction_id: enableActions.auctionId,
        influencer_id: influencerId,
        requestBody: {
          amount: extraPayout.amount,
          comment: extraPayout.comment,
        },
      });
      closeExtraPayout();
    }
  }, [
    extraPayout,
    addExtraPayout,
    closeExtraPayout,
    enableActions,
    influencerId,
  ]);

  const deleteExtraPayout = useDeleteExtraPayout();
  const deletePrompt = useDeletePrompt();

  const handleExtraPayoutDelete = useCallback(
    async (id: number) => {
      if (enableActions) {
        const answer = await deletePrompt.askToDelete({
          text: "Are you sure you want to remove this extra payment for the influencer?",
        });
        if (answer) {
          await deleteExtraPayout.mutateAsync({
            auction_id: enableActions.auctionId,
            influencer_id: influencerId,
            extra_payout_id: id,
          });
        }
      }
    },
    [deleteExtraPayout, influencerId, enableActions, deletePrompt],
  );

  let paymentDueFormatted: string | undefined;
  if (paymentDue) {
    const paymentDueDate = new Date(paymentDue);
    paymentDueFormatted = `${paymentDueDate.getDate()}-${
      paymentDueDate.getMonth() + 1
    }-${paymentDueDate.getFullYear()}`;
  }

  const isValid = extraPayout
    ? extraPayout?.amount > 0 && extraPayout.comment !== ""
    : true;

  return (
    <ModalContent>
      <ModalHeader>Total price</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text fontSize="2xl" my={3}>
          Payment details
        </Text>
        <Container variant="card" mb="6">
          <Box px={3} py={1}>
            <Grid templateColumns="repeat(2, auto)">
              {agentName ? (
                <BreakdownRow name="Agency" value={agentName} />
              ) : null}
              {paymentDueFormatted && paymentStatus !== "paid" ? (
                <BreakdownRow name="Payment due" value={paymentDueFormatted} />
              ) : null}
              <BreakdownRow
                name="Price"
                value={`${currencySymbol} ${formatBigNumbers(
                  priceDetails.price,
                  {
                    minForFormat: 1e15,
                  },
                )}`}
              />
              {{
                use_total: () => (
                  <BreakdownRow
                    name="Extra payout"
                    value={`${currencySymbol} ${formatBigNumbers(
                      priceDetails.extra_payouts.total,
                      {
                        minForFormat: 1e15,
                      },
                    )}`}
                  />
                ),
                use_list: () =>
                  (
                    priceDetails.extra_payouts
                      .extra_payouts_list as NonNullable<
                      typeof priceDetails.extra_payouts.extra_payouts_list
                    >
                  ).map((extraPayoutItem) => (
                    <BreakdownRow
                      key={extraPayoutItem.id}
                      name={extraPayoutItem.comment}
                      value={`${currencySymbol} ${formatBigNumbers(
                        extraPayoutItem.amount,
                        {
                          minForFormat: 1e15,
                        },
                      )}`}
                      onDelete={
                        enableActions
                          ? () => {
                              handleExtraPayoutDelete(
                                extraPayoutItem.id as number,
                              );
                            }
                          : undefined
                      }
                      isDisabled={deleteExtraPayout.isPending}
                      isLoading={deleteExtraPayout.isPending}
                      tooltip
                    />
                  )),
                dont_use: (): any => undefined,
              }[priceDetails.extra_payouts.display_status]()}
              {priceDetails.calculated_fields.upfront_payment_sum ? (
                <BreakdownRow
                  name="Upfront"
                  value={`${currencySymbol} ${formatBigNumbers(
                    priceDetails.calculated_fields.upfront_payment_sum,
                    {
                      minForFormat: 1e15,
                    },
                  )}`}
                  highlight
                />
              ) : null}
              <BreakdownRow
                name="Commission"
                value={`${currencySymbol} ${formatBigNumbers(
                  priceDetails.calculated_fields.commission_sum,
                  {
                    minForFormat: 1e12,
                    lowFormat: "0,0[.]00",
                  },
                )}`}
              />
              <BreakdownRow
                name="VAT"
                number="secondLast"
                value={`${currencySymbol} ${formatBigNumbers(
                  priceDetails.calculated_fields.vat_sum,
                  {
                    minForFormat: 1e8,
                    lowFormat: "0,0[.]00",
                  },
                )} (${priceDetails.vat}%)`}
              />
              <BreakdownRow
                name="Total"
                bold
                number="last"
                value={`${currencySymbol} ${formatBigNumbers(
                  priceDetails.calculated_fields.total,
                  {
                    minForFormat: 1e12,
                    lowFormat: "0,0[.]00",
                  },
                )}`}
              />
            </Grid>
          </Box>
        </Container>
        {enableActions ? (
          <>
            <Flex pb={6}>
              <Button
                isDisabled={!enableActions.enableAgentAdd}
                autoFocus
                flex={4}
                mx={1}
                leftIcon={<i className={"fas fa-user-tie"} />}
                onClick={enableActions.onAgentAdd}
              >
                Add Agent
              </Button>
              {enableActions.onUploadInvoice ? (
                <>
                  <Box flex={1} />
                  <Tooltip
                    label={
                      influencerData.invoices_urls?.length === 5
                        ? "You cannot upload more than 5 invoices."
                        : ""
                    }
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <Button
                        autoFocus
                        flex={4}
                        mx={1}
                        leftIcon={<i className={"fas fa-upload"} />}
                        onClick={enableActions.onUploadInvoice}
                        isDisabled={
                          influencerData.invoices_urls?.length > 4 ||
                          paymentDetails?.invoice_by_influencer
                        }
                      >
                        Upload invoice
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip
                    label={
                      paymentDetails?.invoice_by_influencer
                        ? "Invoices have been uploaded by the influencer"
                        : ""
                    }
                    placement="top"
                    style={{ width: 20 }}
                  >
                    <div>
                      {paymentDetails?.invoice_by_influencer && (
                        <i
                          style={{
                            position: "relative",
                            left: "-10px",
                            top: "-4px",
                            color: "#F56565",
                          }}
                          className="fa-sharp fa-solid fa-circle-exclamation fa-lg"
                        ></i>
                      )}
                    </div>
                  </Tooltip>
                </>
              ) : null}
            </Flex>
            {enableActions.addExtraPayout &&
            !(["paid", "early_payout"] as (typeof paymentStatus)[]).includes(
              paymentStatus,
            ) ? (
              <Container variant="card" mb={6}>
                <Text fontSize="xl" pb={3}>
                  Add amount
                </Text>
                {extraPayout ? (
                  <Form onSubmit={handleFormSubmit}>
                    <Flex alignItems="center" py={3}>
                      <FormControl>
                        <Flex align="baseline">
                          <FormLabel flex={1}>Amount to add</FormLabel>
                          <MoneyInput
                            flex={1}
                            autoFocus
                            currencyLabel={currencyLabel}
                            value={extraPayout.amount}
                            onValueChange={handleAmountChange}
                          />
                        </Flex>
                      </FormControl>
                    </Flex>
                    <Box py={3}>
                      <Textarea
                        placeholder="Add comment..."
                        value={extraPayout.comment}
                        onChange={handleCommentChange}
                      />
                    </Box>
                    <FormFooterButtonGroup py={3}>
                      <Button
                        isDisabled={!isValid}
                        isLoading={addExtraPayout.isPending}
                        type="submit"
                      >
                        Save
                      </Button>
                      <Button type="button" onClick={closeExtraPayout}>
                        Cancel
                      </Button>
                    </FormFooterButtonGroup>
                  </Form>
                ) : (
                  <Button
                    autoFocus
                    w="full"
                    colorScheme="green"
                    leftIcon={<i className="fas fa-dollar-sign" />}
                    onClick={openExtraPayout}
                  >
                    Add amount to pay
                  </Button>
                )}
              </Container>
            ) : undefined}
          </>
        ) : undefined}
      </ModalBody>
    </ModalContent>
  );
};
