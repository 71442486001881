// TODO - lint
/* eslint-disable func-style, func-style, no-unused-vars, no-unused-vars, consistent-return, radix, eqeqeq, no-unused-vars, eqeqeq, no-underscore-dangle, no-underscore-dangle, camelcase, no-param-reassign, no-param-reassign, camelcase, camelcase, radix, radix, camelcase, radix, radix, radix, radix, radix, radix, radix, radix, camelcase, radix, radix, camelcase, radix, radix, camelcase, radix, radix, camelcase, radix, radix, camelcase, radix, radix, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, no-param-reassign, no-param-reassign, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, no-param-reassign, radix, camelcase, class-methods-use-this, no-param-reassign, no-param-reassign, no-shadow, consistent-return, react/no-direct-mutation-state, no-param-reassign, no-unused-vars, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, no-unused-vars, class-methods-use-this, no-param-reassign, no-param-reassign, no-nested-ternary, no-nested-ternary, no-nested-ternary, no-nested-ternary, no-return-assign, no-unused-vars, consistent-return, class-methods-use-this, no-cond-assign, camelcase, camelcase, consistent-return, no-nested-ternary, camelcase, camelcase, camelcase, camelcase, no-useless-escape, camelcase, react/no-unescaped-entities, no-return-assign, no-underscore-dangle, no-unused-vars, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, import/no-default-export */
// General Imports
import { Button } from "@chakra-ui/react";
import HtmlParser from "html-react-parser";
import MarkdownIt from "markdown-it";
import insert from "markdown-it-ins";
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tooltip from "rc-tooltip";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import uuidv4 from "uuid/v4";
// Actions imports
import {
  addListTag,
  addNoteOnUser,
  attachContentToInfInList,
  changeInfluencerPrice,
  changeListOrder,
  deleteTagInList,
  disableShareList,
  editTagInList,
  editTasks,
  getListById,
  getListFrequencies,
  getListsNew,
  getShareNotesSummary,
  hideOrUnhideOnList,
  removeFromList,
  searchOnList,
  shareList,
} from "../../../actions/premiumActions";
import {
  addShareNotesFromShare,
  getListNotesSummary,
  getSharedList,
  getSharedListFrequencies,
  listUserNote,
  listUserResponse,
  searchOnSharedList,
} from "../../../actions/shareActions";
import NoImage from "../../../images/svgs/placeholder.svg";
import { ConditionalWrapper } from "../../UtilityComponents";
import EditInfluencerNoteDialog from "../dialogs/EditInfluencerNoteDialog";
import EditListKpisDialog from "../dialogs/EditListKpisDialog";
import EditTasksDialog from "../dialogs/EditTasksDialog/EditTasksDialog";
// Components Import
import { MessageDialog } from "../dialogs/MessageDialog";
import ShareListDialog from "../dialogs/ShareListDialog";
import CheckBox from "../general_components/CheckBox";
import SortBox from "../general_components/SortBox";
import SearchFilter from "../search_components/SearchFilter";
import { SideNotes } from "../shared_components/SideNotes";
import ImportFromListMenu from "../side_menus/ImportFromListMenu";
import InviteToCampaign from "../side_menus/InviteToCampaign";
import { ListSuggestions } from "../side_menus/ListSuggestions/ListSuggestions";
import TasksPricesSide from "../side_menus/TasksPricesSide";
import {
  formatBigNumbers,
  getAgeGroup,
  getCostString,
  tagColors,
} from "../utilities/general";
import AddPostDialog from "./AddPostDialog";
import { InfluContent } from "./InfluContent";
import { InfluencersListForm } from "./InfluencersListForm";
import { ListSummaryData } from "./ListSummaryData";
import SearchContentSection from "./SearchContentSection";

function getMaxOfSocials(data, field) {
  const array = [];
  if (data.instagram_data) {
    array.push(data.instagram_data[field]);
  }
  if (data.youtube_data) {
    array.push(data.youtube_data[field]);
  }
  if (data.twitter_data) {
    array.push(data.twitter_data[field]);
  }
  if (data.tiktok_data) {
    array.push(data.tiktok_data[field]);
  }

  return Math.max(...array);
}

function GeneralCommentSection({ text }) {
  return React.useMemo(() => {
    const mdParser = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      highlight(str, lang) {},
    }).use(insert);
    return (
      <div
        className="markdown-comments single-comment"
        style={{ marginTop: 10, marginBottom: 10, fontSize: 15 }}
      >
        {HtmlParser(mdParser.render(text), {
          replace: (domNode) => {
            if (domNode?.tagName === "script") {
              return <></>;
            }
          },
        })}
      </div>
    );
  }, [text]);
}

class ListSectionInner extends React.Component {
  isIL = this.props?.user?.userDetails?.company_details?.country === "Israel";

  constructor(props) {
    super(props);
    this.tagColors = tagColors;
    this.state = {
      selectedList: -1,
      loaded: false,
      selected: [],
      updated: true,
      influencersToDelete: null,
      selectedSocial: "",
      notApprovedInfluencers: [],
      editable: {},
      showCommentDialog: -1,
      importFromListOpen: false,
      editableNote: null,
      editableTag: {},
      campaign: false,
      editTasksData: false,
      shareListOpen: false,
      cubesToDisplay: [
        "engagement",
        "impressions",
        "quality_impressions",
        "followers",
        "influencers",
        "est_budget",
        "cpe",
      ],
      hideFollowersSegm: false,
      filteredResult: null,
      searchLoader: false,
      headerHeight: 110,
      tags: [],
      currentTab: "0",
      showInfluencers: true,
      overScrolled: false,
      showFollowersData: false,
    };

    this.sortOptions = {
      order: null,
      data: [
        { name: "name", label: "Name", selected: false },
        { name: "user_registered", label: "App", selected: false },
        { name: "followers", label: "Followers", selected: false },
        {
          name: "avg_engagement_percetnage",
          label: "Avg. Engagement",
          selected: false,
        },
      ],
      socials: [],
    };

    this.sideSearchRef = React.createRef();
  }

  /* #################### Functions #################### */
  loadList(frequenciesTime = 0) {
    if (!this.props.id) return;

    this.setState({ loaded: false });
    const { id } = this.props;
    if (id) {
      this.setState({
        selectedList: parseInt(id),
        campaign: !!this.props.campaign,
      });

      if (this.props.shared) {
        this.props.getSharedList((data) => {
          this.setState({
            sharedParameters: data.shared_parameters,
            cubesToDisplay: data.shared_parameters.shared_data,
            hideFollowersSegm: !data.shared_parameters.share_segmentation,
          });
          this.callback(data?.list);
        });
      } else {
        this.props.getListById(id, this.callback);
      }
      window.setTimeout(
        function () {
          if (this.props.shared) {
            this.props.getSharedListFrequencies();
          } else if (
            this.props.user.userDetails?.company_details?.country === "Israel"
          ) {
            this.props.getListFrequencies(this.props.id);
          }
        }.bind(this),
        frequenciesTime,
      );
    } else {
      this.setState({ selectedList: id });
    }
  }

  componentDidMount() {
    if (!this.isIL) {
      this.state.cubesToDisplay.push("unique_followers_dist");
    }
    if (!this.props.shared) {
      this.props.getListsNew();
    }

    this.loadList(2000);
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentDidUpdate() {
    if (this.props.tab !== "list") return;
    if (
      !this.props.shared &&
      this.props.id &&
      this.props.id != this.state.selectedList &&
      this.state.selectedList !== -1
    ) {
      this.loadList(100);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = (e) => {
    if (this.props.shared) return;
    const minHeight = this.props.campaign ? 70 : 110;
    const overScrolled =
      this.state.headerHeight - document.documentElement.scrollTop < minHeight;
    if (
      overScrolled != this.state.overScrolled &&
      !this.state.showFollowersData
    ) {
      this.setState({ overScrolled });
    }
  };

  setLoadingData = (state = true) => {
    this.setState({ searchLoader: state });
  };

  shareNotesSummaryCallback = (shareNotesSummaryData) => {
    this.setState({ shareNotesSummaryData });
  };

  callback = (data) => {
    this.changeHeaderHeight();
    if (this.ListSummaryData) this.ListSummaryData.getListSeg();
    try {
      const shareGuid = this.getShareGuid();
      if (shareGuid) {
        if (this.props.shared) {
          getListNotesSummary(this.shareNotesSummaryCallback);
        } else {
          getShareNotesSummary(shareGuid, this.shareNotesSummaryCallback);
        }
      }

      this.setState(
        {
          loaded: true,
          tags: data?.tags || this.props.listReducer?.list?.tags,
          listId:
            data?.list_summary_data?.id ||
            this.props.listReducer?.list?.list_summary_data?.id,
        },
        () => {
          //nothing
        },
      );
    } catch (e) {
      console.log(e);
    }
  };

  changeHeaderHeight() {
    let height = 280;
    const data = this.props.listReducer?.list?.list_summary_data;
    ["instagram", "twitter", "youtube", "tiktok"].forEach((provider) => {
      if (data && data[provider] && data[provider].influencers_count) {
        height += 15;
      }
    });
    this.setState({ headerHeight: height });
  }

  onSelect(id, influencers) {
    let { selected } = this.state;
    if (id === "all") {
      if (selected.length === influencers.length) {
        selected = [];
      } else {
        selected = influencers.map((x) => x.id);
      }
    } else {
      selected.addOrRemove(id);
    }

    this.setState({ selected });
  }

  hideOrUnhideOnList = () => {
    const { selected, selectedList } = this.state;
    this.props.hideOrUnhideOnList(
      selectedList,
      selected.map((id) => {
        return { influencer_id: id };
      }),
      () => {
        this.setState({ selected: [] });
        this.callback();
      },
    );
  };

  openDeleteDialog = () => {
    this.setState({ influencersToDelete: this.state.selected });
  };

  handleDeleteInfluencer = () => {
    const influencersToDelete = this.state.selected.map((x) => {
      return { influencer_id: x };
    });
    this.props.removeFromList(
      this.state.selectedList,
      influencersToDelete,
      () => {
        this.callback();
      },
    );
    this.setState({ influencersToDelete: null, selected: [] });
  };

  openSegmentations = (id, hideFollowersSeg) => {
    this.props.openInfluencerSegmentations(id, hideFollowersSeg);
  };

  changeSocial(social) {
    this.setState({ selectedSocial: social });
  }

  onSort = (row) => {
    const { name } = row;
    this.props.listReducer.list.influencers_data.sort((a, b) => {
      switch (name) {
        case "name":
          return a[name] > b[name] ? 1 : -1;
        case "user_registered":
          return a[name] ? -1 : 1;
        case "followers":
        case "avg_engagement_percetnage":
        default:
          return getMaxOfSocials(b, name) - getMaxOfSocials(a, name);
      }
    });
    this.setState({ updated: true });
  };

  changeApprovedInfluencers(id, approved) {
    if (approved) this.setState({ showCommentDialog: id });
    else this.props.listUserResponse(id, true);
  }

  addRejectComment() {
    if (this._rejectNote.value) {
      this.props.listUserResponse(this.state.showCommentDialog, false);
      const message = {
        message_guid: uuidv4(),
        message: this._rejectNote.value,
        message_content_type: "text",
        from_name: "Rejection Message",
      };
      this.props.addShareNotesFromShare(
        "list",
        "list_influencer_note",
        this.state.showCommentDialog,
        message,
        () => this.setState({ showCommentDialog: -1 }),
      );
    }
  }

  toggleFilterMenu = () => {
    this.sideSearchRef.current.toggleFilterMenu(() => {
      this.props.closeInfluencerSegmentations();
    });
  };

  toggleImportMenu = () => {
    if (this.state.importFromListOpen) {
      const element = document.getElementById("import-from-list");
      element.classList.remove("fadeInRight");
      element.classList.add("fadeOutRight");
      window.setTimeout(
        function () {
          this.setState({ importFromListOpen: false });
        }.bind(this),
        500,
      );
    } else {
      this.props.closeInfluencerSegmentations();
      this.setState({ importFromListOpen: true, inviteToCampaignOpen: false });
    }
  };

  onChangePrice = (prices, amounts, set_as_default) => {
    // avoid nulls
    if (prices) {
      [
        "instagramPrice",
        "twitterPrice",
        "youtubePrice",
        "tiktokPrice",
        "instagramStoryPrice",
        "instagramReelPrice",
      ].forEach((price) => {
        if (prices[price]) prices[price].value = prices[price].value || 0;
      });
    }
    if (amounts) {
      [
        "ig_post",
        "ig_story",
        "tw_tweet",
        "yt_video",
        "tk_video",
        "ig_reel",
      ].forEach((amount) => {
        if (amounts[amount]) {
          amounts[amount].value = amounts[amount].value || 0;
        }
      });
    }

    const prices_obj = {};
    const amounts_obj = {};
    const { editable } = this.state;
    const instagram =
      editable.instagram_data &&
      parseInt(prices.instagramPrice.value) !==
        editable.instagram_data.cost_per_post.high
        ? parseInt(prices.instagramPrice.value)
        : null;
    const instagram_story =
      editable.instagram_data &&
      parseInt(prices.instagramStoryPrice.value) !==
        editable.instagram_data.cost_per_story.high
        ? parseInt(prices.instagramStoryPrice.value)
        : null;
    const instagram_reel =
      editable.instagram_data &&
      parseInt(prices.instagramReelPrice.value) !==
        editable.instagram_data.cost_per_reel.high
        ? parseInt(prices.instagramReelPrice.value)
        : null;
    const twitter =
      editable.twitter_data &&
      parseInt(prices.twitterPrice.value) !==
        editable.twitter_data.cost_per_post.high
        ? parseInt(prices.twitterPrice.value)
        : null;
    const youtube =
      editable.youtube_data &&
      parseInt(prices.youtubePrice.value) !==
        editable.youtube_data.cost_per_post.high
        ? parseInt(prices.youtubePrice.value)
        : null;
    const tiktok =
      editable.tiktok_data &&
      parseInt(prices.tiktokPrice.value) !==
        editable.tiktok_data.cost_per_post.high
        ? parseInt(prices.tiktokPrice.value)
        : null;

    const instagram_amount =
      editable.tasks_amount &&
      amounts.ig_post &&
      parseInt(amounts.ig_post.value) !== editable.tasks_amount.ig_post
        ? parseInt(amounts.ig_post.value)
        : null;
    const instagram_story_amount =
      editable.tasks_amount &&
      amounts.ig_story &&
      parseInt(amounts.ig_story.value) !== editable.tasks_amount.ig_story
        ? parseInt(amounts.ig_story.value)
        : null;
    const instagram_reel_amount =
      editable.tasks_amount &&
      amounts.ig_reel &&
      parseInt(amounts.ig_reel.value) !== editable.tasks_amount.ig_reel
        ? parseInt(amounts.ig_reel.value)
        : null;
    const twitter_amount =
      editable.tasks_amount &&
      amounts.tw_tweet &&
      parseInt(amounts.tw_tweet.value) !== editable.tasks_amount.tw_tweet
        ? parseInt(amounts.tw_tweet.value)
        : null;
    const youtube_amount =
      editable.tasks_amount &&
      amounts.yt_video &&
      parseInt(amounts.yt_video.value) !== editable.tasks_amount.yt_video
        ? parseInt(amounts.yt_video.value)
        : null;
    const tiktok_amount =
      editable.tasks_amount &&
      amounts.tk_video &&
      parseInt(amounts.tk_video.value) !== editable.tasks_amount.tk_video
        ? parseInt(amounts.tk_video.value)
        : null;

    if (instagram || instagram === 0) prices_obj.ig_post = instagram;
    if (twitter || twitter === 0) prices_obj.tw_tweet = twitter;
    if (youtube || youtube === 0) prices_obj.yt_video = youtube;
    if (tiktok || tiktok === 0) prices_obj.tk_video = tiktok;
    if (instagram_story || instagram_story === 0) {
      prices_obj.ig_story = instagram_story;
    }
    if (instagram_reel || instagram_reel === 0) {
      prices_obj.ig_reel = instagram_reel;
    }

    if (instagram_amount || instagram_amount === 0) {
      amounts_obj.ig_post = instagram_amount;
    }
    if (twitter_amount || twitter_amount === 0) {
      amounts_obj.tw_tweet = twitter_amount;
    }
    if (youtube_amount || youtube_amount === 0) {
      amounts_obj.yt_video = youtube_amount;
    }
    if (tiktok_amount || tiktok_amount === 0) {
      amounts_obj.tk_video = tiktok_amount;
    }
    if (instagram_story_amount || instagram_story_amount === 0) {
      amounts_obj.ig_story = instagram_story_amount;
    }
    if (instagram_reel_amount || instagram_reel_amount === 0) {
      amounts_obj.ig_reel = instagram_reel_amount;
    }
    // check for not empty amounts
    if (
      this.state.campaign ||
      !amounts ||
      (Object.keys(amounts).length === 0 && amounts.constructor === Object)
    ) {
      amounts = null;
    } else amounts = amounts_obj;
    document.getElementById("savePriceButton").classList.add("disabled");
    this.props.changeInfluencerPrice(
      this.props.id,
      editable.id,
      set_as_default,
      { prices: prices_obj, amounts },
      () => {
        if (
          Object.keys(prices_obj).length !== 0 &&
          prices_obj.constructor === Object
        ) {
          const influencer = this.props.listReducer.list.influencers_data.find(
            (x) => x.id === editable.id,
          );
          if (instagram || instagram === 0) {
            influencer.instagram_data.cost_per_post.high = instagram;
            influencer.instagram_data.cost_per_post.low = instagram;
          }
          if (twitter || twitter === 0) {
            influencer.twitter_data.cost_per_post.high = twitter;
            influencer.twitter_data.cost_per_post.low = twitter;
          }
          if (youtube || youtube === 0) {
            influencer.youtube_data.cost_per_post.high = youtube;
            influencer.youtube_data.cost_per_post.low = youtube;
          }
          if (tiktok || tiktok === 0) {
            influencer.tiktok_data.cost_per_post.high = tiktok;
            influencer.tiktok_data.cost_per_post.low = tiktok;
          }
          if (instagram_story || instagram_story === 0) {
            influencer.instagram_data.cost_per_story.high = instagram_story;
            influencer.instagram_data.cost_per_story.low = instagram_story;
          }
          if (instagram_story || instagram_story === 0) {
            influencer.instagram_data.cost_per_story.high = instagram_story;
            influencer.instagram_data.cost_per_story.low = instagram_story;
          }
          if (instagram_reel || instagram_reel === 0) {
            influencer.instagram_data.cost_per_reel.high = instagram_reel;
            influencer.instagram_data.cost_per_reel.low = instagram_reel;
          }
        }

        // update amounts
        if (amounts_obj) {
          const influencer = this.props.listReducer.list.influencers_data.find(
            (x) => x.id === editable.id,
          );
          [
            "ig_post",
            "ig_story",
            "tw_tweet",
            "yt_video",
            "tk_video",
            "ig_reel",
          ].forEach((social_amount) => {
            if (amounts_obj[social_amount]) {
              influencer.tasks_details.tasks_amount[social_amount] =
                amounts_obj[social_amount];
            }
          });
        }

        this.setState({ editable: {} });
      },
    );
  };

  onEditNoteToggle(influencer, note) {
    const { editableNote } = this.state;
    if (editableNote?.id === influencer.id) {
      influencer.note = note;
      this.props.addNoteOnUser(this.props.id, influencer.id, note);
      this.setState({ editableNote: null });
    } else {
      this.setState({ editableNote: influencer });
    }
  }

  toggleInviteMenu = (unMark) => {
    if (this.state.inviteToCampaignOpen) {
      const element = document.getElementById("invite-to-campaign");
      element.classList.remove("fadeInRight");
      element.classList.add("fadeOutRight");
      window.setTimeout(
        function () {
          this.setState({ inviteToCampaignOpen: false });
        }.bind(this),
        500,
      );
      if (unMark) {
        this.setState({ selected: [] });
      }
    } else {
      if (this.state.selected.length === 0) return;

      this.props.closeInfluencerSegmentations();
      this.setState({ inviteToCampaignOpen: true, importFromListOpen: false });
    }
  };

  filterInfluencer = (influencer, scopeFilters) => {
    if (!this.props.shared && scopeFilters.length > 0) {
      const instagramData =
        influencer.instagram_data && influencer.instagram_data.username
          ? influencer.instagram_data
          : {};
      const influencerTypeLabel = this.getInfluencerType(instagramData);
      if (!scopeFilters.includes(influencerTypeLabel)) return true;
    }
    if (this.state.filteredResult) {
      return !this.state.filteredResult.includes(parseInt(influencer.id));
    }
    return false;
  };

  editTasks = (influencer_id, tasks, callback) => {
    this.props.editTasks(
      this.state.selectedList,
      influencer_id,
      tasks,
      (data) => {
        this.props.getListById(this.props.id, this.callback, false);

        callback(data);
      },
    );
  };

  onShareList = (share, allowNotes, callback) => {
    if (share) {
      this.props.shareList(this.state.selectedList, allowNotes, callback);
    } else {
      this.props.disableShareList(this.state.selectedList, callback);
    }
  };

  openShareList = () => {
    if (this.state.campaign) {
      this.props.openShareCampaign("list");
    } else {
      this.setState({ shareListOpen: true });
    }
  };

  /* #################### End Functions #################### */

  /* #################### Help Renders #################### */

  renderFilter(x) {
    return (
      <div key={x.name}>
        <strong
          className="small-title"
          style={{ marginLeft: 0, marginBottom: 10 }}
        >
          {x.label}
        </strong>
        {x.values.map((v, index) => {
          return (
            <div
              className="checkbox-container"
              style={{ height: 35 }}
              key={x.name + index}
            >
              <CheckBox
                checked={x.selected.includes(v)}
                onChange={() => this.changeFilter(x.name, v)}
                label={v}
              />
            </div>
          );
        })}
        <div
          className="filter-refine-tab-spacer"
          style={{ marginBottom: 20 }}
        />
      </div>
    );
  }

  getInfluencerType(instagramData) {
    let influencerTypeLabel = null;
    if (instagramData && instagramData.followers) {
      if (instagramData.followers < 20000) {
        influencerTypeLabel = "Micro";
      } else if (instagramData.followers < 50000) {
        influencerTypeLabel = "B list";
      } else {
        influencerTypeLabel = "A list";
      }
      // influencerTypeLabel += " Influencer";
    }

    return influencerTypeLabel;
  }

  generateCurrencyLabel = () => {
    return window.translate("", this.currencyLabel());
  };

  renderTagsPopup(editableTag, influencerId, influencerTags) {
    if (editableTag.influencer_id !== influencerId) return;
    const tags = this.state.tags ? this.state.tags : [];
    if (influencerId === -1) {
      influencerTags = this.state.selected;
      influencerTags =
        influencerTags && tags && tags.length
          ? tags
              .filter((x) =>
                x.influencers.find((x) => influencerTags.includes(x)),
              )
              .map((x) => x.id)
          : [];
    }
    return (
      <>
        <div className="edit-tags-popup-title">
          {editableTag.tagInputOpen ? (
            <div
              className="far fa-arrow-left"
              onClick={() =>
                this.setState({
                  editableTag: { ...editableTag, tagInputOpen: false },
                })
              }
            />
          ) : (
            <div />
          )}
          <span>Tags</span>
          <div
            className="far fa-times"
            onClick={() => this.setState({ editableTag: {} })}
          />
        </div>

        <div style={{ padding: "5px 10px", width: 280 }}>
          {editableTag.tagInputOpen ? (
            this.renderCreateOrEditTag()
          ) : (
            <>
              {tags.map((x) => {
                const check = this.state.selected.every((strId) =>
                  x.influencers.includes(Number(strId)),
                );

                return (
                  <div className="inf-tags-container" key={x.id}>
                    <div
                      style={{ backgroundColor: x.color }}
                      onClick={() =>
                        this.addOrRemoveInfluencerInTag(
                          x.id || x.temp_id,
                          influencerId,
                        )
                      }
                    >
                      <div title={x.label} className="campaign-tag" dir="auto">
                        {x.label || (
                          <span style={{ userSelect: "none" }}>&nbsp;</span>
                        )}
                      </div>
                      {check && <div className="fas fa-check" />}
                    </div>
                    <div
                      className="fal fa-pen"
                      onClick={() =>
                        this.setState({
                          editableTag: {
                            ...editableTag,
                            tagInputOpen: true,
                            tag: { ...x },
                            edit: true,
                          },
                        })
                      }
                    />
                  </div>
                );
              })}

              <div
                className="new-tag-button"
                onClick={() =>
                  this.setState({
                    editableTag: {
                      ...editableTag,
                      tagInputOpen: true,
                      tag: {
                        label: "",
                        color: this.tagColors[0],
                        influencers:
                          influencerId === -1
                            ? this.state.selected
                            : [influencerId],
                      },
                      edit: false,
                    },
                  })
                }
              >
                Add new tag
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  renderCreateOrEditTag = () => {
    const { editableTag } = this.state;

    return (
      <>
        <input
          className="new-tag-input"
          defaultValue={editableTag.tag.label}
          onChange={(e) => {
            editableTag.tag.label = e.target.value.trim().substring(0, 49);
            this.setState({});
          }}
          onKeyPress={this.handleKeyPress}
        />
        <div className="tag-color-pick">
          {this.tagColors.map((x) => (
            <div
              key={x}
              style={{ background: x }}
              onClick={() => {
                editableTag.tag.color = x;
                this.setState({});
              }}
            >
              {editableTag.tag.color === x && <i className="fas fa-check" />}
            </div>
          ))}
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary add-tag-btn"
            onClick={() =>
              editableTag.edit ? this.editTagInList() : this.addNewTag()
            }
          >
            {editableTag.edit ? "Save" : "Create"}
          </button>
          {editableTag.edit && (
            <button
              className="btn btn-primary add-tag-btn"
              style={{ background: "#cf513d", marginLeft: 50 }}
              onClick={() => this.deleteTag(editableTag.tag.id)}
            >
              Delete
            </button>
          )}
        </div>
      </>
    );
  };

  onEditTagToggle = (id) => {
    const { editableTag } = this.state;
    if (editableTag.influencer_id === id) {
      editableTag.tagInputOpen = false;
      editableTag.tagsChagned = false;
      this.setState({ editableTag: {} });
    } else {
      this.setState({ editableTag: { influencer_id: id } });
    }
  };

  addNewTag() {
    const { editableTag } = this.state;
    const { tag } = editableTag;
    tag.temp_id = uuidv4();

    if (this.state.tags) this.state.tags.push(tag);
    else this.state.tags = [tag];

    addListTag(this.state.listId, tag, (id) => {
      if (id) this.state.tags.find((x) => x.temp_id === tag.temp_id).id = id;
    });

    editableTag.tagInputOpen = false;
    editableTag.tag = {};
    this.setState({ editableTag });
  }

  addOrRemoveInfluencerInTag = (tagId, influencerId) => {
    const tag = this.state.tags.find((x) => x.id === tagId);
    if (!tag) return;
    if (influencerId === -1) {
      const selectedIds = this.state.selected.map((id) => Number(id));
      const selectedIdsNotInInfluencers = selectedIds.filter(
        (id) => tag.influencers.indexOf(id) === -1,
      );
      const influencersNotInSelectedIds = tag.influencers.filter(
        (id) => selectedIds.indexOf(id) === -1,
      );
      if (selectedIdsNotInInfluencers.length > 0) {
        tag.influencers = [...tag.influencers, ...selectedIdsNotInInfluencers];
      } else {
        tag.influencers = influencersNotInSelectedIds;
      }
    } else {
      influencerId = Number(influencerId);
      if (tag.influencers) tag.influencers.addOrRemove(influencerId);
      else tag.influencers = [influencerId];
    }
    editTagInList(this.state.listId, tagId, tag, (success) => {});
    this.setState({});
  };

  deleteTag = (tagId) => {
    const { tags } = this.state;
    tags.splice(
      tags.findIndex((x) => x.id === tagId),
      1,
    );
    this.state.editableTag.tagInputOpen = false;
    this.state.editableTag.tag = {};
    this.setState({});
    deleteTagInList(this.state.listId, tagId, () => {});
  };

  editTagInList = () => {
    const { editableTag } = this.state;
    const { tag } = editableTag;
    if (!tag) return;
    const index = this.state.tags.findIndex((x) => x.id === tag.id);

    if (index > -1) {
      this.state.tags[index] = tag;
    }

    editTagInList(this.state.listId, tag.id, tag, (success) => {});

    editableTag.tagInputOpen = false;
    editableTag.tag = {};

    this.setState({});
  };

  renderTagOnRow = (t, idx) => {
    return (
      <div style={{ background: t.color }} key={idx} dir="auto">
        {t.label}
      </div>
    );
  };

  removePostFromInfluencer = (id, post) => {
    const influencers = this.props.listReducer.list.influencers_data;
    const influencer = influencers.find((x) => x.id === id);
    const posts = influencer.posts.map((p, idx) => {
      return { ...p, order: idx, is_deleted: p.id === post.id };
    });
    post.saving = true;
    this.setState({});

    attachContentToInfInList(
      this.state.listId,
      influencer.id,
      posts,
      (success) => {
        if (success) {
          const index = influencer.posts.findIndex((x) => x.id === post.id);
          if (index > -1) {
            influencer.posts.splice(index, 1);
          }
        } else {
          post.saving = false;
        }
        this.setState({});
      },
    );
  };

  moveInf = (direction, id) => {
    const { order } = this.state;
    const currentIndx = order.indexOf(id);
    switch (direction) {
      case "up":
        if (currentIndx > 0) {
          order.splice(currentIndx, 1);
          order.splice(currentIndx - 1, 0, id);
        }
        break;
      case "down":
        if (currentIndx < order.length - 1) {
          order.splice(currentIndx, 1);
          order.splice(currentIndx + 1, 0, id);
        }
        break;
      default:
        break;
    }

    this.setState({ order });
  };

  getEngagementsCubes(
    tasks,
    instagramData,
    twitterData,
    tiktokData,
    youtubeData,
  ) {
    const cubes = [];
    if (
      !this.props.shared ||
      this.state.sharedParameters?.share_engagments_rate
    ) {
      if (instagramData.engagement_label && tasks.ig_post) {
        cubes.push(
          <span aria-label={`${instagramData.avg_engagement_percetnage}%`}>
            {" "}
            <div
              className={`list-social-followers ${instagramData.engagement_label}`}
            >
              <li className="fa fa-instagram" />
              {instagramData.engagement_label}
            </div>
          </span>,
        );
      }
      if (twitterData.engagement_label) {
        cubes.push(
          <span aria-label={`${twitterData.avg_engagement_percetnage}%`}>
            {" "}
            <div
              className={`list-social-followers ${twitterData.engagement_label}`}
            >
              <li className="fa fa-twitter" />
              <span aria-label={`${twitterData.avg_engagement_percetnage}%`}>
                {twitterData.engagement_label}
              </span>
            </div>{" "}
          </span>,
        );
      }
      if (youtubeData.engagement_label) {
        cubes.push(
          <span aria-label={`${youtubeData.avg_engagement_percetnage}%`}>
            {" "}
            <div
              className={`list-social-followers ${youtubeData.engagement_label}`}
            >
              <li className="fa fa-youtube-play" />
              <span aria-label={`${youtubeData.avg_engagement_percetnage}%`}>
                {youtubeData.engagement_label}
              </span>
            </div>{" "}
          </span>,
        );
      }
      if (tiktokData.engagement_label) {
        cubes.push(
          <span aria-label={`${tiktokData.avg_engagement_percetnage}%`}>
            {" "}
            <div
              className={`list-social-followers ${tiktokData.engagement_label}`}
            >
              <li className="fa-brands fa-tiktok" />
              <span aria-label={`${tiktokData.avg_engagement_percetnage}%`}>
                {tiktokData.engagement_label}
              </span>
            </div>{" "}
          </span>,
        );
      }
    }
    return cubes;
  }

  renderInfluencer = (influencer, index) => {
    const id = Number(influencer.id);
    const { editableTag } = this.state;
    const shared =
      this.props.listReducer.list.list_summary_data.shared_data.shared_link !==
      null;
    const { tasks_amount } = influencer;
    const instagramData =
      influencer.instagram_data &&
      influencer.instagram_data.username &&
      (tasks_amount.ig_post || tasks_amount.ig_story)
        ? influencer.instagram_data
        : {};
    // const storyData = influencer.instagram_story && influencer.instagram_story.username ? influencer.instagram_story : {};
    const influencerTypeLabel =
      this.props.shared || !this.isIL
        ? ""
        : this.getInfluencerType(instagramData);
    const twitterData =
      influencer.twitter_data &&
      influencer.twitter_data.username &&
      (tasks_amount.tw_tweet || tasks_amount.tw_tweet)
        ? influencer.twitter_data
        : {};
    const youtubeData =
      influencer.youtube_data &&
      influencer.youtube_data.username &&
      (tasks_amount.yt_video || tasks_amount.yt_video)
        ? influencer.youtube_data
        : {};
    const tiktokData =
      influencer.tiktok_data &&
      influencer.tiktok_data.username &&
      (tasks_amount.tk_video || tasks_amount.tk_video)
        ? influencer.tiktok_data
        : {};
    const score = {
      instagram: instagramData.score ? instagramData.score : null,
      twitter: twitterData.score ? twitterData.score : null,
      youtube: youtubeData.score ? youtubeData.score : null,
      tiktok: tiktokData.score ? tiktokData.score : null,
    };
    const faded =
      influencer.hidden ||
      (influencer.shared_response_approved === false && this.props.shared);
    const name =
      influencer.name ||
      influencer?.instagram_data?.username ||
      influencer?.tiktok_data?.username ||
      influencer?.twitter_data?.username ||
      influencer?.youtube_data?.username ||
      "Unknown";
    const tags =
      this.state.tags && this.state.tags.length
        ? this.state.tags.filter((x) => x.influencers.includes(id))
        : [];

    const microInfluencer = influencerTypeLabel === "Micro";
    let canEdit = true;
    if (influencer.column_in_campaign) {
      canEdit = false;
    }

    const engagementCubes = this.getEngagementsCubes(
      tasks_amount,
      instagramData,
      twitterData,
      tiktokData,
      youtubeData,
    );

    return (
      <ConditionalWrapper
        key={influencer.id}
        condition={this.state.sortMode}
        wrapper={(children) => (
          <Draggable
            key={influencer.id}
            draggableId={influencer.id}
            index={index}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {children}
              </div>
            )}
          </Draggable>
        )}
      >
        <div key={influencer.id}>
          <div
            className={`search-influencer-row list-influencer-row ${
              faded ? "faded" : ""
            }`}
          >
            {/* Left Side */}
            <div className="list-influencer-left search-influencer-left ">
              <div
                className={`account-image ${
                  this.state.campaign && influencer.column_in_campaign
                    ? "in-campaign"
                    : ""
                } blurable`}
                style={{
                  backgroundImage: `url(${influencer.picture_url})`,
                  backgroundSize: "cover",
                }}
              >
                {this.state.campaign && influencer.column_in_campaign && (
                  <div className="is-in-campaign">In Campaign</div>
                )}
              </div>
              <div className="accounts-list ">
                {instagramData.username && (
                  <div
                    className="account-list-item-wrapper"
                    style={{ display: "flex" }}
                  >
                    <div className="fa fa-instagram"></div>
                    <a
                      className="list-username blurable"
                      href={`https://www.instagram.com/${instagramData.username}/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {instagramData.username}
                    </a>{" "}
                  </div>
                )}
                {twitterData.username && (
                  <div
                    className="account-list-item-wrapper"
                    style={{ display: "flex" }}
                  >
                    <div className="fa fa-twitter"></div>
                    <a
                      className="list-username blurable"
                      href={`https://twitter.com/${twitterData.username}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {twitterData.username}
                    </a>{" "}
                  </div>
                )}
                {youtubeData.social_id && (
                  <div
                    className="account-list-item-wrapper"
                    style={{ display: "flex" }}
                  >
                    <div className="fa fa-youtube-play"></div>
                    <a
                      className="list-username blurable"
                      href={`https://www.youtube.com/channel/${youtubeData.social_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {youtubeData.username ? youtubeData.username : ""}
                    </a>{" "}
                  </div>
                )}
                {tiktokData.username && (
                  <div
                    className="account-list-item-wrapper"
                    style={{ display: "flex" }}
                  >
                    <div className="fa-brands fa-tiktok"></div>
                    <a
                      className="list-username blurable"
                      href={`https://www.tiktok.com/@${tiktokData.username}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {tiktokData.username ? tiktokData.username : ""}
                    </a>{" "}
                  </div>
                )}
              </div>
            </div>
            {/* End Left Side */}

            <div className="list-influencer-details ">
              {/* Actions Row */}
              <div className="list-influencer-details-first ">
                <div>
                  <div
                    className="list-influencer-actions "
                    style={{ marginRight: 10 }}
                  >
                    <div style={{ display: "flex" }}>
                      {tags && editableTag && !this.props.shared && (
                        <Tooltip
                          visible={editableTag.influencer_id === id}
                          onVisibleChange={() => this.onEditTagToggle(id)}
                          trigger={["click"]}
                          placement="top"
                          overlayClassName={"edit-popup"}
                          overlay={
                            this.renderTagsPopup(
                              editableTag,
                              id,
                              tags.map((x) => x.id),
                            ) || ""
                          }
                        >
                          <Tooltip
                            overlay={<>Tags</>}
                            trigger={["hover"]}
                            placement="top"
                          >
                            <a
                              className="fa fa-tags"
                              style={{
                                color:
                                  tags.length > 0
                                    ? "var(--main-baby-blue-color)"
                                    : "#333333",
                              }}
                            />
                          </Tooltip>
                        </Tooltip>
                      )}
                      {influencerTypeLabel && (
                        <div
                          className={`indication-icon influencer-type-label ${
                            microInfluencer ? "micro" : ""
                          }`}
                        >
                          <span>{influencerTypeLabel}</span>
                        </div>
                      )}

                      {/* <Tooltip placement="top" trigger={['hover']} overlay={<span>Segmentations</span>}><div className="moi-signal-bars" onClick={() => this.openSegmentations(influencer.id)}></div></Tooltip> */}
                      {!this.props.shared && (
                        <Tooltip
                          placement="top"
                          trigger={["hover"]}
                          overlay={<span>Add Posts To the Influencer</span>}
                        >
                          <div
                            style={{ color: "#249ff0" }}
                            onClick={() =>
                              this.setState({ addPostData: influencer })
                            }
                            className="fa fa-image"
                          />
                        </Tooltip>
                      )}

                      {this.props.shared ? (
                        <>
                          <button
                            className="btn btn-primary flex-row"
                            style={{
                              marginLeft: 10,
                              height: 26,
                              width: 80,
                              marginTop: -5,
                            }}
                            onClick={() => {
                              this.openNotes(influencer);
                            }}
                          >
                            <i
                              style={{
                                marginRight: 5,
                                marginTop: -4,
                                marginBottom: -2,
                                position: "relative",
                              }}
                              className="fa fa-comment"
                            >
                              {this.getNotesSeenByItemId(influencer.id) && (
                                <span className="indication-dot" />
                              )}
                            </i>{" "}
                            Notes
                          </button>
                        </>
                      ) : (
                        <div
                          className={`fa fa-pencil ${!!influencer.note}`}
                          onClick={() => this.onEditNoteToggle(influencer)}
                        />
                      )}
                      {shared && (
                        <>
                          <div className="list-actions-spacer"></div>
                          <Tooltip
                            placement="top"
                            trigger={["hover"]}
                            overlay={
                              <span>
                                {influencer.shared_response_approved !== false
                                  ? "Accepted"
                                  : "Rejected"}
                              </span>
                            }
                          >
                            <div
                              className={`fa indication-icon  ${
                                influencer.shared_response_approved !== false
                                  ? "fa-check"
                                  : "fa-times"
                              }`}
                              style={{
                                color:
                                  influencer.shared_response_approved !== false
                                    ? "#22b573"
                                    : "#f15d28",
                              }}
                            />
                          </Tooltip>
                          {!this.props.shared && (
                            <Tooltip
                              placement="top"
                              trigger={["hover"]}
                              overlay={"Notes"}
                            >
                              <div
                                className="fa fa-comment indication-icon"
                                onClick={() => this.openNotes(influencer)}
                              >
                                {this.getNotesSeenByItemId(influencer.id) && (
                                  <span className="indication-dot" />
                                )}
                              </div>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <strong
                      className="blurable"
                      style={{ width: "auto" }}
                      onClick={() =>
                        this.openSegmentations(
                          `${influencer.shadow_influencer ? "shadow_" : ""}${
                            influencer.id
                          }`,
                          this.state.hideFollowersSegm,
                        )
                      }
                    >
                      {name}
                    </strong>
                    <div className="tags-row">
                      {tags.map(this.renderTagOnRow)}
                    </div>
                  </div>
                </div>
              </div>
              {/* End Actions Row */}

              <div className="list-influencer-details-second">
                <div style={{ textAlign: "center", display: "inline-block" }}>
                  <div
                    className="moi-mobile"
                    style={{
                      color: influencer.user_registered ? "#22b573" : "#ebebeb",
                    }}
                  ></div>
                  <strong className="list-influencer-details-desc">APP</strong>
                </div>

                <div style={{ textAlign: "center", display: "inline-block" }}>
                  <div
                    className="list-socials"
                    style={{ padding: "2px 8px", textAlign: "left" }}
                  >
                    {instagramData.followers ? (
                      <div
                        className={`list-social-followers ${
                          instagramData?.verification_type === "api" &&
                          !this.props.shared
                            ? "verified-social"
                            : null
                        }`}
                      >
                        <li className="fa fa-instagram" />
                        {formatBigNumbers(instagramData.followers)}
                      </div>
                    ) : (
                      ""
                    )}
                    {twitterData.followers ? (
                      <div
                        className={`list-social-followers ${
                          !this.props.shared ? "verified-social" : null
                        }`}
                      >
                        <li className="fa fa-twitter" />
                        {formatBigNumbers(twitterData.followers)}
                      </div>
                    ) : (
                      ""
                    )}
                    {youtubeData.followers ? (
                      <div
                        className={`list-social-followers ${
                          !this.props.shared ? "verified-social" : null
                        }`}
                      >
                        <li className="fa fa-youtube-play" />
                        {formatBigNumbers(youtubeData.followers)}
                      </div>
                    ) : (
                      ""
                    )}
                    {tiktokData.followers ? (
                      <div
                        className={`list-social-followers ${
                          tiktokData?.verification_type === "api" &&
                          !this.props.shared
                            ? "verified-social"
                            : null
                        }`}
                      >
                        <li className="fa-brands fa-tiktok" />
                        {formatBigNumbers(tiktokData.followers)}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <strong className="list-influencer-details-desc">
                      FOLLOWERS
                    </strong>
                  </div>
                </div>

                {influencer.user_registered && !this.props.shared && (
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      marginLeft: 15,
                    }}
                  >
                    <div className="list-socials editable">
                      <div
                        className="moi-pencil-edit-button"
                        onClick={() => {
                          this.setState({
                            editable:
                              this.state.editable.id === influencer.id
                                ? {}
                                : influencer,
                          });
                        }}
                      />
                      {instagramData.cost_per_post && (
                        <div className="list-social-followers">
                          <li className="fa fa-instagram" />
                          {getCostString(instagramData.cost_per_post)}
                        </div>
                      )}
                      {twitterData.cost_per_post && (
                        <div className="list-social-followers">
                          <li className="fa fa-twitter" />
                          {getCostString(twitterData.cost_per_post)}
                        </div>
                      )}
                      {youtubeData.cost_per_post && (
                        <div className="list-social-followers">
                          <li className="fa fa-youtube-play" />
                          {getCostString(youtubeData.cost_per_post)}
                        </div>
                      )}
                      {tiktokData.cost_per_post && (
                        <div className="list-social-followers">
                          <li className="fa-brands fa-tiktok" />
                          {getCostString(tiktokData.cost_per_post)}
                        </div>
                      )}
                    </div>
                    <div>
                      <strong className="list-influencer-details-desc">
                        EST COST PER POST (
                        {window.translate("", this.currencyLabel())})
                      </strong>
                    </div>
                  </div>
                )}

                {this.props.campaign && influencer.tasks_details && (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="row-cube-number"
                      style={{
                        padding: 2,
                        textTransform: "capitalize",
                        borderRadius: 4,
                        marginLeft: 10,
                        marginTop: -5,
                        height: 55,
                        textAlign: "center",
                      }}
                    >
                      <Tooltip
                        overlayClassName="edit-popup"
                        placement="top"
                        trigger={["hover"]}
                        overlay={
                          <div
                            style={{
                              padding: "5px 10px",
                              paddingBottom: 10,
                            }}
                          >
                            <span style={{ color: "#bbbbbb" }}>
                              Total tasks
                            </span>
                            <div className="list-tasks-row">
                              <div
                                style={{ background: "#bbbbbb" }}
                                className="list-social-followers"
                              >
                                <li className="fa fa-instagram" />
                                {
                                  influencer.tasks_details?.tasks.filter(
                                    (x) => x.post_type === "ig_post",
                                  ).length
                                }
                              </div>
                              <div
                                className="list-social-followers"
                                style={{
                                  textTransform: "none",
                                  display: "flex",
                                  background: "#bbbbbb",
                                }}
                              >
                                <li
                                  style={{ marginTop: 2 }}
                                  className="fa moi-stories"
                                />
                                {
                                  influencer.tasks_details?.tasks.filter(
                                    (x) => x.post_type === "ig_story",
                                  ).length
                                }
                              </div>
                              <div
                                style={{ background: "#bbbbbb" }}
                                className="list-social-followers"
                              >
                                <li className="fa fa-twitter" />
                                {
                                  influencer.tasks_details?.tasks.filter(
                                    (x) => x.post_type === "tw_tweet",
                                  ).length
                                }
                              </div>
                              <div
                                style={{ background: "#bbbbbb" }}
                                className="list-social-followers"
                              >
                                <li className="fa fa-youtube-play" />
                                {
                                  influencer.tasks_details?.tasks.filter(
                                    (x) => x.post_type === "yt_video",
                                  ).length
                                }
                              </div>
                              <div
                                style={{ background: "#bbbbbb" }}
                                className="list-social-followers"
                              >
                                <li className="fa-brands fa-tiktok" />
                                {
                                  influencer.tasks_details?.tasks.filter(
                                    (x) => x.post_type === "tk_post",
                                  ).length
                                }
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <p style={{ marginTop: 4, marginBottom: 0 }}>
                          {influencer.tasks_details?.tasks &&
                            influencer.tasks_details?.tasks.length}
                        </p>
                      </Tooltip>
                      {canEdit && (
                        <Button
                          variant={"small-action"}
                          onClick={() =>
                            this.setState({ editTasksData: influencer })
                          }
                          style={{
                            height: "20px",
                            padding: "4px 2px",
                            marginBottom: "5px",
                          }}
                        >
                          <strong>Edit tasks</strong>
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ marginLeft: 2 }}
                          />
                        </Button>
                      )}
                    </div>
                    <div style={{ width: 60 }}>
                      <strong className="list-influencer-details-desc">
                        Tasks
                      </strong>
                    </div>
                  </div>
                )}

                {influencer.user_registered ? (
                  <div style={{ textAlign: "center", display: "inline-block" }}>
                    <div
                      className="row-cube-number"
                      style={{ textTransform: "capitalize" }}
                    >
                      {influencer.age
                        ? influencer.age
                        : getAgeGroup(influencer.age_group)}
                    </div>
                    <div style={{ width: this.props.campaign ? 60 : 80 }}>
                      <strong className="list-influencer-details-desc">
                        Age
                      </strong>
                    </div>
                  </div>
                ) : null}

                {influencer.user_registered ? (
                  <div style={{ textAlign: "center", display: "inline-block" }}>
                    <div className="row-cube-number" style={{ marginLeft: -5 }}>
                      <div className="cube-score">
                        {score.instagram && <span>{score.instagram}</span>}
                        {score.instagram && score.twitter && "|"}
                        {score.twitter && <span>{score.twitter}</span>}
                        {(score.instagram || score.twitter) &&
                          score.youtube &&
                          "|"}
                        {score.youtube && <span> {score.youtube}</span>}
                        {(score.instagram || score.twitter || score.youtube) &&
                          score.tiktok &&
                          "|"}
                        {score.tiktok && <span> {score.tiktok}</span>}
                      </div>
                      <div>
                        {score.instagram && (
                          <span className="fa fa-instagram" />
                        )}
                        {score.twitter && <span className="fa fa-twitter" />}
                        {score.youtube && (
                          <span className="fa fa-youtube-play" />
                        )}
                        {score.tiktok && (
                          <span className="fa-brands fa-tiktok" />
                        )}
                      </div>
                    </div>
                    <div style={{ width: 100 }}>
                      <strong className="list-influencer-details-desc">
                        Score
                      </strong>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {/* Checkbox right */}
            {this.props.shared ? (
              <div className="share-toggle-approve">
                <ToggleButton
                  value={
                    influencer.shared_response_approved !== false &&
                    this.state.showCommentDialog !== influencer.id
                  }
                  onToggle={() =>
                    this.changeApprovedInfluencers(
                      influencer.id,
                      influencer.shared_response_approved !== false,
                    )
                  }
                  inactiveLabel={"No"}
                  activeLabel={"Yes"}
                />
              </div>
            ) : this.state.sortMode ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <div
                  className={`fa fa-arrow-up ${index === 0 ? "disabled" : ""}`}
                  onClick={() => this.moveInf("up", influencer.id)}
                  style={{
                    marginBottom: 20,
                    color: "var(--main-baby-blue-color)",
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                />
                <div
                  className="fa fa-arrow-down"
                  onClick={() => this.moveInf("down", influencer.id)}
                  style={{
                    marginTop: 20,
                    color: "var(--main-baby-blue-color)",
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                />
              </div>
            ) : (
              <div
                className={`${
                  this.state.campaign && influencer.column_in_campaign
                    ? "in-campaign"
                    : null
                } checkbox`}
              >
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.selected.includes(influencer.id)}
                    onChange={() => this.onSelect(influencer.id)}
                  />
                  <span className="checkbox-material">
                    <span className="check"></span>
                  </span>
                </label>
              </div>
            )}
            {/* End Checkbox right */}
          </div>
          {influencer?.posts?.length ? (
            <div className="influ-content">
              <InfluContent
                shared={this.props.shared}
                attachToInfInList={this.removePostFromInfluencer}
                influencer={influencer}
              />
            </div>
          ) : null}
          {this.props.shared && influencer.note && influencer.note.length && (
            <div className="influencer_note">
              <i className="fas fa-comment-alt" /> {influencer.note}
            </div>
          )}
        </div>
      </ConditionalWrapper>
    );
  };

  currencyLabel() {
    const { list } = this.props.listReducer;
    if (!list) {
      return null;
    }
    return `money_symbol_${list.list_summary_data.currency}`;
  }

  renderSummaryData = () => {
    return (
      <ListSummaryData
        listId={this.props.id}
        toggleEditKpis={() => {
          this.setState({ editListKpisDialog: !this.state.editListKpisDialog });
        }}
        shared={this.props.shared}
        campaign={this.state.campaign}
        search={this.sideSearchRef ? this.sideSearchRef.current : null}
        onShowMore={(value) => this.setState({ showFollowersData: value })}
        ref={(r) => (this.ListSummaryData = r)}
        sharedParameters={this.state.sharedParameters}
        cubesToDisplay={this.state.cubesToDisplay}
      />
    );
  };

  /* #################### End Help Renders #################### */

  resultsCallback = (filterResult) => {
    this.setState({ searchLoader: false });
    this.setState({ filteredResult: filterResult.data });
  };

  searchInfluencers = (callback) => {
    this.setLoadingData();

    const result = this.sideSearchRef.current.getSearchQuery(false, false);

    this.setState({ filterActive: result.filterActive });

    if (this.props.shared) {
      this.props.searchOnSharedList(result.query, this.resultsCallback);
    } else {
      this.props.searchOnList(
        this.state.selectedList,
        result.query,
        this.resultsCallback,
      );
    }
  };

  getActiveFilter = (type) => {
    if (this.sideSearchRef && this.sideSearchRef.current) {
      return this.sideSearchRef.current.getActiveFilter(type);
    }
  };

  headerHeight() {
    let element;
    let height;
    if ((element = document.getElementById("list_header"))) {
      height = element.clientHeight;
    }
    return height || 200;
  }

  getFilteredInfluencers = () => {
    const { list } = this.props.listReducer;
    let scopeFilters = [];
    if (this.sideSearchRef && this.sideSearchRef.current) {
      scopeFilters = this.sideSearchRef.current.getScopeFilter();
    }
    let influencers = list.influencers_data
      ? list.influencers_data.filter(
          (x) => !this.filterInfluencer(x, scopeFilters),
        )
      : [];
    if (this.sideSearchRef && this.sideSearchRef.current && this.state.tags) {
      const { filteredTags } = this.sideSearchRef.current.state;
      let taggedInfluencers = [];
      this.state.tags.forEach((tag) => {
        if (filteredTags.includes(tag.id)) {
          taggedInfluencers = taggedInfluencers.concat(tag.influencers);
        }
      });
      if (filteredTags && filteredTags.length) {
        influencers = influencers.filter((inf) =>
          taggedInfluencers.includes(Number(inf.id)),
        );
      }
    }
    if (this.state.influencer_name_filter?.length) {
      influencers = influencers.filter((inf) => {
        return inf?.name
          ?.trim()
          ?.toLowerCase()
          ?.includes(this.state.influencer_name_filter?.trim()?.toLowerCase());
      });
    }
    return influencers;
  };

  enterSortingMode = () => {
    const influencers = this.getFilteredInfluencers();
    if (
      influencers.length !==
      this.props.listReducer.list.influencers_data?.length
    ) {
      return;
    }
    const order = influencers.map((x) => x.id);
    this.setState({ sortMode: !this.state.sortMode, order });
  };

  sortInfluencersByOrder = (a, b) => {
    const aPosition = this.state.order.indexOf(a.id);
    const bPosition = this.state.order.indexOf(b.id);
    return aPosition - bPosition;
  };

  saveListOrder = () => {
    this.setState({ savingSort: true });
    if (this.state.savingSort) return;

    const data = this.state.order.map((inf_id, idx) => {
      return {
        influencer_id: inf_id,
        position: idx,
      };
    });
    this.props.changeListOrder(this.state.listId, data, (success) => {
      if (success) {
        this.props.listReducer.list.influencers_data =
          this.props.listReducer.list.influencers_data.sort(
            this.sortInfluencersByOrder,
          );
      }
      this.setState({ sortMode: false, savingSort: false });
    });
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { order } = this.state;
    const items = [...order];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState({ order: items });
  };

  renderInfluencersList = (influencers) => {
    const { selected, searchLoader } = this.state;
    const { list } = this.props.listReducer;
    const { shared } = this.props;
    const areAnySelected = selected.length > 0;
    const filtersActive =
      this.props.listReducer?.list?.influencers_data?.length !==
      influencers.length;
    const feeList =
      this.props.listReducer?.list?.list_summary_data?.list_type === "list_fee";
    if (!list) return;

    return (
      <>
        <div
          className="middle-header"
          id={"list_header"}
          style={{
            position: this.state.overScrolled ? "fixed" : "initial",
            paddingTop: this.state.overScrolled
              ? this.props.campaign
                ? 50
                : 25
              : 70,
          }}
        >
          {this.state.overScrolled ? null : this.renderSummaryData()}

          {/* Actions Row */}

          {this.state.sortMode ? (
            <div
              className="inner-toolbar"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.state.savingSort ? (
                <div
                  className="send-buttons-loader"
                  style={{ marginLeft: 0, marginTop: -5 }}
                />
              ) : (
                <>
                  <button
                    className="btn btn-primary cancel"
                    style={{
                      height: 30,
                      marginTop: -5,
                      paddingTop: 5,
                      marginRight: 50,
                      width: 75,
                    }}
                    onClick={this.enterSortingMode}
                  >
                    CANCEL
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{
                      height: 30,
                      marginTop: -5,
                      paddingTop: 5,
                      width: 75,
                    }}
                    onClick={this.saveListOrder}
                  >
                    SAVE
                  </button>
                </>
              )}
            </div>
          ) : (
            <>
              <div className="inner-toolbar">
                {!shared && !feeList ? (
                  <>
                    <span
                      aria-label={
                        this.props.listReducer?.list?.influencers_data?.length <
                        5
                          ? "Add at least 5 influencers to use AI assistant"
                          : undefined
                      }
                    >
                      <Button
                        rounded="full"
                        variant={"roundedWhite"}
                        height={23}
                        isActive={
                          this.props.listReducer?.list?.influencers_data
                            ?.length > 4
                        }
                        isDisabled={
                          this.props.listReducer?.list?.influencers_data
                            ?.length < 5
                        }
                        onClick={() =>
                          this.setState({ listSuggestionsOpen: true })
                        }
                      >
                        <i
                          aria-label="hello"
                          class="fa-duotone fa-wand-magic-sparkles"
                        />
                        &nbsp; AI Assistant
                      </Button>
                    </span>
                    <Button
                      height={23}
                      rounded="full"
                      onClick={this.toggleImportMenu}
                    >
                      <div
                        style={{ marginTop: 2, marginRight: 5 }}
                        className="moi-add-list"
                      />{" "}
                      Import influencers
                    </Button>
                  </>
                ) : null}
                <div
                  className={
                    this.sideSearchRef?.current?.getActiveFilter(
                      "influencer",
                    ) ||
                    this.sideSearchRef?.current?.getActiveFilter("audience") ||
                    this.sideSearchRef?.current?.getActiveFilter("content")
                      ? "active-filter"
                      : ""
                  }
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={this.toggleFilterMenu}
                >
                  <li
                    className="moi-filter"
                    style={{ marginTop: -9, marginLeft: 25, marginRight: 5 }}
                  />{" "}
                  <strong>FILTER</strong>
                </div>
                <SortBox
                  active={this.sortOptions.order}
                  options={this.sortOptions}
                  onSort={this.onSort}
                />
              </div>

              {!shared && (
                <div className="list-actions-row">
                  <div className="list-right-actions-row">
                    <div
                      style={{
                        display: "flex",
                        marginRight: 5,
                        alignItems: "center",
                      }}
                    >
                      <div className="lists-search">
                        <div
                          className="moi-search-bold"
                          aria-label="Search"
                          style={{
                            zIndex: 2,
                          }}
                          onClick={() => {
                            this.searchInput.value = null;
                            this.setState(
                              {
                                search: "",
                                searchClicked: !this.state.searchClicked,
                              },
                              () => {
                                if (this.state.searchClicked) {
                                  this.searchInput?.focus();
                                }
                              },
                            );
                          }}
                        />
                        <input
                          value={this.state.influencer_name_filter}
                          className={`form-control campaign-manage-search ${
                            this.state.searchClicked ? "clicked" : ""
                          }`}
                          onChange={(e) =>
                            this.setState({
                              influencer_name_filter: e.target.value,
                            })
                          }
                          ref={(el) => (this.searchInput = el)}
                        />
                      </div>

                      {this.state.campaign && (
                        <div
                          aria-label="Invite to Campaign"
                          className="moi-megaphone"
                          style={{
                            color: areAnySelected
                              ? "var(--main-baby-blue-color)"
                              : "#aaaaaa",
                          }}
                          onClick={this.toggleInviteMenu}
                        />
                      )}
                      <div
                        aria-label={
                          filtersActive ? "Can't sort filtered list" : "Sort"
                        }
                        style={{
                          color: filtersActive
                            ? "#aaa"
                            : "var(--main-baby-blue-color)",
                        }}
                        className={"moi-sort "}
                        onClick={this.enterSortingMode}
                      />
                      <div
                        aria-label="Hide"
                        className="moi-eye"
                        style={{
                          color: areAnySelected
                            ? "var(--main-baby-blue-color)"
                            : "#aaaaaa",
                        }}
                        disabled={!areAnySelected}
                        onClick={this.hideOrUnhideOnList}
                      />
                      <div
                        aria-label="Remove from list"
                        className="fas fa-trash "
                        style={{
                          color: areAnySelected
                            ? "var(--main-baby-blue-color)"
                            : "#aaaaaa",
                        }}
                        disabled={!areAnySelected}
                        onClick={this.openDeleteDialog}
                      />
                      <Tooltip
                        visible={this.state.editableTag.influencer_id === -1}
                        onVisibleChange={() => this.onEditTagToggle(-1)}
                        trigger={["click"]}
                        placement="top"
                        overlayClassName={"edit-popup"}
                        overlay={
                          this.renderTagsPopup(
                            this.state.editableTag,
                            -1,
                            [],
                          ) || ""
                        }
                      >
                        <div
                          aria-label="Tags"
                          className="fas fa-tags clickable"
                          style={{
                            color: areAnySelected
                              ? "var(--main-baby-blue-color)"
                              : "#aaaaaa",
                          }}
                          disabled={!areAnySelected}
                        />
                      </Tooltip>

                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={
                              influencers.length > 0 &&
                              selected.length === influencers.length
                            }
                            onChange={() => this.onSelect("all", influencers)}
                          />
                          <span className="checkbox-material">
                            <span className="check"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {/* End Actions Row */}
          <div
            style={{
              marginTop: shared ? 5 : 35,
              display: "flex",
              justifyContent: "space-between",
              marginBottom: -20,
            }}
          >
            {this.getActiveFilter("influencer")}
            {this.getActiveFilter("audience")}
            {this.getActiveFilter("content")}
          </div>
        </div>

        {searchLoader ? (
          <div className="main-middle-container">
            <div className="logo-loader">
              <div className="loader" />
              <img src={"/images/humanz-logo.png"} />
            </div>
          </div>
        ) : (
          <div
            className="list-rows"
            style={{
              paddingBottom: 20,
              marginTop: this.props.shared ? 40 : 10,
            }}
          >
            {/* General comment */}
            {this.state.sharedParameters &&
              this.state.sharedParameters &&
              this.state.sharedParameters.share_comments &&
              this.state.sharedParameters.general_comment && (
                <GeneralCommentSection
                  text={this.state.sharedParameters.general_comment}
                />
              )}
            {/* Influencers rows */}
            {this.state.overScrolled && (
              <div style={{ height: this.state.headerHeight - 30 }} />
            )}
            {this.state.sortMode ? (
              <DragDropContext onDragEnd={this.handleOnDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {influencers
                        .sort(this.sortInfluencersByOrder)
                        .map(this.renderInfluencer)}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              influencers.map(this.renderInfluencer)
            )}
            {/* End Influencers rows */}
          </div>
        )}
      </>
    );
  };

  getNotesSeenByItemId = (item_id) => {
    if (
      !this.state.shareNotesSummaryData ||
      !this.state.shareNotesSummaryData.type_and_notes_summary ||
      !this.state.shareNotesSummaryData.type_and_notes_summary[0]
    ) {
      return false;
    }
    const notes_summary =
      this.state.shareNotesSummaryData.type_and_notes_summary[0].notes_summary.find(
        (n) => Number(n.item_id) === Number(item_id),
      );
    if (notes_summary && notes_summary.seen === false) return true;
    return false;
  };

  renderHeader = () => {
    const { list } = this.props.listReducer;
    const { shared } = this.props;
    return (
      <div
        className="list-title list-title-new"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="blurable"
          style={{ display: "flex", overflow: "hidden" }}
        >
          <div>
            <img
              style={{ width: "46px", height: "auto" }}
              src={list?.list_summary_data?.image_url || NoImage}
            />
          </div>
          <span
            style={{
              whiteSpace: "nowrap",
              display: "block",
              textOverflow: "ellipsis",
              overflow: "hidden",
              marginLeft: "13px",
              paddingTop: "4px",
            }}
          >
            {list.list_summary_data?.title}
          </span>
          {!shared && !this.props.campaign && (
            <i
              style={{
                fontSize: 13,
                color: "var(--main-baby-blue-color)",
                margin: "12px 10px",
                cursor: "pointer",
              }}
              onClick={() =>
                this.props.navigate(`/edit-list/${this.state.selectedList}`)
              }
              className="far fa-edit"
            />
          )}
        </div>
        <div style={{ display: "flex" }}>
          {!shared && (
            <button
              className="btn btn-primary flex-row"
              onClick={this.openShareList}
            >
              Share{" "}
              <i
                style={{ marginLeft: 5, marginTop: 2, marginBottom: -2 }}
                className="moi-forward-arrow2"
              />
            </button>
          )}
        </div>
      </div>
    );
  };

  openNotes = (data) => {
    this.setState({ sideNotesOpen: true, sideNotesData: data });
  };

  getShareGuid = () => {
    const { list } = this.props.listReducer;
    return this.props.shared
      ? this.props.id
      : list?.list_summary_data?.shared_data?.shared_link?.match(
          /\/([^\/]+)\/?$/,
        )[1];
  };

  render() {
    const { loaded, influencersToDelete, selected } = this.state;
    const { list } = this.props.listReducer;
    const influencers = this.getFilteredInfluencers();
    const shareGuid = this.getShareGuid();
    return (
      <>
        {this.props.shared ? null : (
          <ListSuggestions
            visible={this.state.listSuggestionsOpen}
            setVisible={(status) => {
              this.setState({ listSuggestionsOpen: status });
            }}
            listId={this.props.id}
            openInfluencer={this.props.openInfluencerSegmentations}
            openAddToList={this.props.openAddToList}
            refreshList={() =>
              this.props.getListById(this.props.id, this.callback)
            }
          />
        )}
        {loaded ? (
          <div
            style={{ background: "#fafafa", height: "100%", paddingBottom: 20 }}
          >
            {/* side notes */}
            {this.state.sideNotesOpen && (
              <SideNotes
                isFromShare={this.props.shared}
                name={
                  this.props.user && this.props.user.userDetails
                    ? this.props.user.userDetails.full_name
                    : null
                }
                notesType="list_influencer_note"
                guid={shareGuid}
                sideNotesData={this.state.sideNotesData}
                objectType={"list"}
                handleClose={() => {
                  this.setState({ sideNotesOpen: false });
                }}
              />
            )}
            {/* Top bar */}
            <div className="section-top-bar"></div>
            {(this.state.importFromListOpen ||
              this.state.inviteToCampaignOpen) && (
              <div
                className="nav-black-space"
                onClick={() =>
                  this.setState({
                    inviteToCampaignOpen: false,
                    importFromListOpen: false,
                  })
                }
              ></div>
            )}
            {/* End top bar */}
            {this.state.importFromListOpen && (
              <ImportFromListMenu
                addCallback={this.callback}
                id={this.state.selectedList}
                listsLoaded={this.props.listsLoaded}
                closeImportMenu={this.toggleImportMenu}
              />
            )}
            {this.state.inviteToCampaignOpen && (
              <InviteToCampaign
                currency={list ? list.list_summary_data.currency : ""}
                needToUntrash={false}
                campaignId={this.props.campaign}
                openEditTasksDialog={(data) =>
                  this.setState({ editTasksData: data })
                }
                influencers={list.influencers_data.filter((x) =>
                  selected.includes(x.id),
                )}
                id={this.state.selectedList}
                campaign={this.state.campaign}
                listsLoaded={this.props.listsLoaded}
                closeMenu={this.toggleInviteMenu}
              />
            )}
            <SearchFilter
              tags={this.state.tags}
              results={influencers.length}
              share={this.props.shared}
              type="list"
              searchInfluencers={this.searchInfluencers}
              setLoadingData={this.setLoadingData}
              user={this.props.user}
              ref={this.sideSearchRef}
            />
            {!this.props.shared && this.state.editable.id && (
              <TasksPricesSide
                campaign={this.state.campaign}
                save={(prices, amount, set_as_default) =>
                  this.onChangePrice(prices, amount, set_as_default)
                }
                closeCallback={() => this.setState({ editable: {} })}
                data={this.state.editable}
              />
            )}
            {this.state.addPostData && (
              <AddPostDialog
                influencer={this.state.addPostData}
                handleClose={() => this.setState({ addPostData: null })}
                listId={this.state.listId}
              />
            )}
            {this.state.editableNote ? (
              <EditInfluencerNoteDialog
                handleClose={() => this.setState({ editableNote: null })}
                handleSubmit={(note) =>
                  this.onEditNoteToggle(this.state.editableNote, note)
                }
                {...this.state.editableNote}
              />
            ) : null}
            {this.state.campaign || this.props.shared ? (
              <>
                <div
                  className={"main-lists-container"}
                  style={this.props.shared ? { paddingTop: 73 } : {}}
                >
                  {this.renderHeader()}
                  {this.renderInfluencersList(influencers)}
                </div>
              </>
            ) : (
              <>
                <Tabs
                  renderTabBar={() => <InkTabBar />}
                  renderTabContent={() => <TabContent />}
                  activeKey={this.state.currentTab}
                  onChange={(e) => this.setState({ currentTab: e })}
                  className="campaign-top-bar list-top-bar"
                >
                  <TabPane tab={<span>List</span>} key="0"></TabPane>
                  <TabPane tab={<span>Search Contents</span>} key="2"></TabPane>
                  {list?.list_summary_data?.linked_form_uuid && (
                    <TabPane tab={<span>Form entries</span>} key="3"></TabPane>
                  )}
                </Tabs>

                <div className="main-lists-container list-container">
                  {this.renderTabContent(influencers, list)}
                </div>
              </>
            )}

            {influencersToDelete && (
              <MessageDialog
                handleClose={() => {
                  this.setState({ influencersToDelete: null });
                }}
                icon="fas fa-trash"
              >
                <div style={{ textAlign: "center", padding: 20 }}>
                  <strong style={{ fontSize: 16 }}>Notice</strong>
                  <p style={{ marginTop: 15, fontSize: 16 }}>
                    You are going to delete the selected influencers from the
                    list
                  </p>
                  <button
                    onClick={this.handleDeleteInfluencer}
                    className="btn btn-primry"
                    style={{
                      background: "var(--main-baby-blue-color)",
                      color: "white",
                      borderRadius: "40px",
                      width: 100,
                      marginTop: 15,
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </MessageDialog>
            )}
            {this.state.showCommentDialog > -1 && (
              <MessageDialog
                handleClose={() => {
                  this.state.notApprovedInfluencers.addOrRemove(
                    this.state.showCommentDialog,
                  );
                  this.setState({ showCommentDialog: -1 });
                }}
              >
                <div style={{ textAlign: "center", padding: 20 }}>
                  <strong>Please Remember</strong>
                  <p style={{ marginTop: 15 }}>
                    When you don't approve a influencer from the list, you are
                    required to leave a comment.
                  </p>
                  <textarea
                    ref={(e) => (this._rejectNote = e)}
                    style={{
                      height: 120,
                      padding: "5px 10px",
                      width: "100%",
                      borderColor: "#bbbbbb",
                    }}
                  />
                  <button
                    onClick={() => this.addRejectComment()}
                    className="btn btn-primry"
                    style={{
                      background: "var(--main-baby-blue-color)",
                      color: "white",
                      borderRadius: "40px",
                      width: 100,
                      marginTop: 15,
                    }}
                  >
                    OK
                  </button>
                </div>
              </MessageDialog>
            )}
            {this.state.editTasksData && (
              <EditTasksDialog
                showTikTok={
                  this.props.user.userDetails.sidebar_data.can_view_tiktok
                }
                markSeenTaskInvites={(influencerId) => {
                  return true;
                }}
                currencyLabel={this.generateCurrencyLabel}
                onSave={this.editTasks}
                influencer={this.state.editTasksData}
                handleClose={() => this.setState({ editTasksData: null })}
                month={null}
                mode={"list"}
              />
            )}
            {this.state.editListKpisDialog && (
              <EditListKpisDialog
                listId={this.state.selectedList}
                tasks={list.tasks_amount}
                showTikTok={
                  this.props.user.userDetails.sidebar_data.can_view_tiktok
                }
                onSave={this.editTasks}
                handleClose={() => this.setState({ editListKpisDialog: false })}
              />
            )}
            {this.state.shareListOpen && (
              <ShareListDialog
                onShareList={this.onShareList}
                list={list}
                handleClose={() => this.setState({ shareListOpen: false })}
              />
            )}
          </div>
        ) : (
          <div className="main-middle-container">
            <div className="logo-loader">
              <div className="loader" />
              <img src={"/images/humanz-logo.png"} />
            </div>
          </div>
        )}
      </>
    );
  }

  renderListSummaryAndInfluencers = (influencers) => {
    return (
      <>
        {this.renderHeader()}
        <div>
          {this.state.showInfluencers
            ? this.renderInfluencersList(influencers)
            : null}
        </div>
      </>
    );
  };

  renderTabContent = (influencers, list) => {
    switch (this.state.currentTab) {
      case "0":
        return this.renderListSummaryAndInfluencers(influencers);
      case "2":
        return (
          <SearchContentSection
            influencers={list.influencers_data}
            listId={this.state.listId}
            searchInfluencers={() => {}}
            setLoadingData={() => {}}
          />
        );
      case "3":
        return (
          <InfluencersListForm
            influencers={list.influencers_data}
            listId={this.state.listId}
            formId={list?.list_summary_data?.linked_form_uuid}
          />
        );
      default:
        return null;
    }
  };
}

const mapStateToProps = (state) => {
  return {
    listReducer: state.listsReducer,
    loaders: state.loadersReducer,
    user: state.userReducer,
    board: state.boardReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListById: (id, callback) => {
      dispatch(getListById(id, callback));
    },
    hideOrUnhideOnList: (listId, influencers, callback) => {
      dispatch(hideOrUnhideOnList(listId, influencers, callback));
    },
    removeFromList: (listId, influencers, callback) => {
      dispatch(removeFromList(listId, influencers, callback));
    },
    getSharedList: (callback) => {
      dispatch(getSharedList(callback));
    },
    getListFrequencies: (id, callback) => {
      dispatch(getListFrequencies(id, callback));
    },
    getSharedListFrequencies: (callback) => {
      dispatch(getSharedListFrequencies(callback));
    },
    listUserNote: (influencerId, note, callBack) => {
      dispatch(listUserNote(influencerId, note, callBack));
    },
    listUserResponse: (influencerId, response, callBack) => {
      dispatch(listUserResponse(influencerId, response, callBack));
    },
    changeInfluencerPrice: (
      listId,
      influencerId,
      replace_default,
      array,
      callback,
    ) => {
      dispatch(
        changeInfluencerPrice(
          listId,
          influencerId,
          replace_default,
          array,
          callback,
        ),
      );
    },
    addNoteOnUser: (listId, influencer_id, note, callback) => {
      dispatch(addNoteOnUser(listId, influencer_id, note, callback));
    },
    editTasks: (list_id, influencer_id, tasks, callBack) => {
      dispatch(editTasks(list_id, influencer_id, tasks, callBack));
    },
    shareList: (listId, shareNotes, callback) => {
      dispatch(shareList(listId, shareNotes, callback));
    },
    disableShareList: (listId, callback) => {
      dispatch(disableShareList(listId, callback));
    },
    getListsNew: (archive, callback) => {
      dispatch(getListsNew(archive, callback));
    },
    searchOnList: (listId, query, callback) => {
      dispatch(searchOnList(listId, query, callback));
    },
    searchOnSharedList: (searchParamns, callback) => {
      dispatch(searchOnSharedList(searchParamns, callback));
    },
    changeListOrder: (listId, data, callback) =>
      dispatch(changeListOrder(listId, data, callback)),
    addShareNotesFromShare: (
      object_type,
      list_type,
      item_id,
      message,
      callback,
    ) =>
      dispatch(
        addShareNotesFromShare(
          object_type,
          list_type,
          item_id,
          message,
          callback,
        ),
      ),
  };
};

const ListSectionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListSectionInner);

export const ListSection = (props) => {
  const navigate = useNavigate();

  return <ListSectionConnected {...props} navigate={navigate} />;
};
