import React, { useCallback } from "react";
import {
  ThemeConfig,
  extendTheme,
  ChakraProvider,
  Box,
} from "@chakra-ui/react";
import { theme as baseTheme } from "./theme";

export const theme = extendTheme(
  {
    components: {},
  } as ThemeConfig,
  baseTheme,
);

type Props = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
> &
  React.ComponentProps<typeof Box>;

export const Form: React.FC<Props> = ({
  children,
  onSubmit,
  ...otherProps
}) => {
  const handleSubmit = useCallback<
    NonNullable<React.ComponentProps<"form">["onSubmit"]>
  >(
    (e) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(e);
      }
    },
    [onSubmit],
  );
  return (
    <ChakraProvider theme={theme}>
      <Box {...otherProps}>
        <form onSubmit={handleSubmit}>{children}</form>
      </Box>
    </ChakraProvider>
  );
};
