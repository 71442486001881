import { Flex, IconButton } from "@chakra-ui/react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type Props = {
  slidesPerView: number;
  children: React.ReactNode;
};
export const Carousel = ({ slidesPerView, children }: Props) => {
  const slides =
    React.Children.map(children, (child) => (
      <SwiperSlide>{child}</SwiperSlide>
    )) ?? [];
  const enoughSlidesForNavigation = slides.length > slidesPerView;

  return (
    <Flex
      alignItems="center"
      css={{
        ".swiper": {
          marginLeft: "unset",
          marginRight: "unset",
          flex: 1,
        },
        ".swiper-slide": {
          textAlign: "center",
        },
        ".swiper-pagination": {
          position: "unset",
        },
        ".swiper-pagination-bullet-active": {
          backgroundColor: "brand.500",
        },
      }}
    >
      <IconButton
        display={enoughSlidesForNavigation ? undefined : "none"}
        fontSize="7xl"
        icon={<i className="fa fa-angle-left" aria-hidden="true" />}
        mx="2"
        px="4"
        py="2"
        h="unset"
        variant="ghost-non-focusable"
        aria-label="carousel previous"
        className="stories-swiper-prev"
      />
      <Swiper
        noSwiping
        noSwipingClass="swiper-slide"
        navigation={{
          prevEl: ".stories-swiper-prev",
          nextEl: ".stories-swiper-next",
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination]}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerView}
      >
        {slides}
      </Swiper>
      <IconButton
        display={enoughSlidesForNavigation ? undefined : "none"}
        className="stories-swiper-next"
        fontSize="7xl"
        icon={<i className="fa fa-angle-right" aria-hidden="true" />}
        mx="2"
        px="4"
        py="2"
        h="unset"
        variant="ghost-non-focusable"
        aria-label="carousel next"
      />
    </Flex>
  );
};
