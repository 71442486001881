/* eslint-disable no-nested-ternary */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { OfferDetails } from "src/api/types";

type Props = {
  status: OfferDetails["offer_status"];
  action: any;
};

const TasksStatusBanner = (props: Props) => {
  const [loading, setLoading] = useState(false);
  return (
    <Box m={10}>
      <Alert
        status={props.status === "wait_influencer" ? "info" : "error"}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <Box>
          <Flex alignItems={"center"} mb={2}>
            <AlertIcon alignItems={"center"} justifyContent="center">
              {props.status === "wait_influencer" ? (
                <i className="fad fa-hourglass" />
              ) : (
                <i className="fad fa-exclamation-triangle" />
              )}
            </AlertIcon>
            <AlertTitle fontWeight={"600"}>
              <Text>
                {props.status === "wait_influencer"
                  ? "Waiting for influencer"
                  : "The Influencer has rejected the offer"}
              </Text>
            </AlertTitle>
          </Flex>

          <AlertDescription>
            {props.status === "wait_influencer"
              ? "You can't edit tasks once you've sent the offer to the influencer"
              : "The influencer has declined your offer. You can dismiss this message in order to edit the tasks"}
          </AlertDescription>
        </Box>
        <Button
          variant={"shadowed"}
          colorScheme={
            props.status === "wait_influencer" ? "red" : "blackAlpha"
          }
          minW={140}
          isLoading={loading}
          onClick={() => {
            setLoading(true);
            props.action();
          }}
        >
          {props.status === "wait_influencer" ? "Cancel offer" : "Dismiss"}
        </Button>
      </Alert>
    </Box>
  );
};

export default TasksStatusBanner;
