/* eslint-disable @typescript-eslint/ban-ts-comment */
// General Imports
import { Alert, AlertIcon, Button, Flex } from "@chakra-ui/react";
import moment from "moment";
import Dialog from "rc-dialog";
// @ts-ignore
import Tabs, { TabPane } from "rc-tabs";
// @ts-ignore
import InkTabBar from "rc-tabs/lib/InkTabBar";
// @ts-ignore
import TabContent from "rc-tabs/lib/TabContent";
import React from "react";
import { Link } from "react-router-dom";
import { getSavedJwtToken } from "src/actions/commonActionsConf";
import {
  deleteCoupon,
  postInjectCoupons,
} from "src/api/actions/auctionActions";
import uuidv4 from "uuid/v4";
import {
  getAuctionTrackingCoupons,
  saveCampaignCoupons,
} from "../../../actions/auctionsActions";
// Components Import
import NoImage from "../../../images/svgs/placeholder.svg";
import {
  CouponInSales,
  InfluencerInManageAuctionData,
} from "../../../models/Api";
import { formattedDate } from "../utilities/general";

// Actions imports

type InfluencerData = Required<InfluencerInManageAuctionData>;

interface ExtendedCoupon extends CouponInSales {
  new?: boolean;
}

interface MyProps {
  influencers: InfluencerData[] | any;
  handleClose(): void;
  boardId: number | string;
  sales: any;
  salesStartTime: string;
  allowedMergerInjection?: boolean;
}

interface MyState {
  search: string;
  openToEdit: number;
  coupons: ExtendedCoupon[];
  couponsHistory: ExtendedCoupon[];
  loading: boolean;
  saving: boolean;
  showNewCoupon: boolean;
  tempCoupon: ExtendedCoupon;
  error: boolean;
  currentTab: string;
  generalCoupons: ExtendedCoupon[];
  conflictedCoupon: any;
  helpWithCoupons: boolean;
  hasError: any;
  influencerToFix: number;
}

// eslint-disable-next-line import/no-default-export
class CampaignCouponsSide extends React.Component<MyProps, MyState> {
  state: MyState = {
    search: "",
    openToEdit: -1,
    coupons: [],
    loading: true,
    saving: false,
    showNewCoupon: true,
    tempCoupon: { new: true },
    couponsHistory: [],
    error: false,
    currentTab: this.props.sales ? "individual" : "general",
    generalCoupons: [],
    conflictedCoupon: null,
    helpWithCoupons: false,
    hasError: false,
    influencerToFix: null,
  };

  init() {
    this.setState({ loading: true });
    getAuctionTrackingCoupons(this.props.boardId, (data: any) => {
      if (data) {
        this.setState({
          coupons: data.filter(
            (c: any) => c.influencers && c.influencers.length,
          ),
          generalCoupons: data.filter(
            (c: any) => !c.influencers || !c.influencers.length,
          ),
          loading: false,
          couponsHistory: [...data],
        });
      }
    });
  }

  componentDidMount() {
    this.init();
  }

  ConflictedCouponsDialog = () => {
    const onClose = () => {
      this.setState({ conflictedCoupon: null });
    };
    const coupon = this.state.conflictedCoupon.couponId;
    const couponUUID = this.state.conflictedCoupon.couponUuid;
    const campaignID: string | null =
      this.state.conflictedCoupon.conflictedAuctionId;
    const conflicatedInfluencer =
      this.state.conflictedCoupon.conflictedInfluencerUsername;
    const campaignName: string | null =
      this.state.conflictedCoupon.conflictedAuctionTitle;
    const allowedToOverwrite = this.state.conflictedCoupon?.allowedToOverwrite;
    const triggerDeleteCoupon = () => {
      deleteCoupon(
        Number(this.props.boardId),
        {
          coupon_auction_id: Number(campaignID),
          coupon_uuid: couponUUID,
        },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
        .then(() => {
          this.setState({ saving: false, conflictedCoupon: null });
          this.init();
        })
        .catch(() => {
          console.log("Error");
          this.setState({ saving: false, conflictedCoupon: null });
        });
    };
    return (
      <>
        <Dialog
          className={"campaign-preview-dialog"}
          title={`Conflicted coupon - ${coupon}`}
          onClose={onClose}
          visible={true}
          style={{ width: "700px", marginTop: 10 }}
          animation="slide-fade"
          maskAnimation="fade"
          closable={!this.state.saving}
        >
          <div
            style={{
              padding: 20,
            }}
          >
            The coupon <code>{coupon}</code>
            {allowedToOverwrite ? (
              <>
                of the influencer <code>{conflicatedInfluencer}</code>
              </>
            ) : null}
            is conflicted with campaign{" "}
            {allowedToOverwrite ? (
              <Link target={"_blank"} to={`/campaign/${campaignID}/manage`}>
                {campaignName} {campaignID ? ` (${campaignID})` : null}
              </Link>
            ) : (
              <strong>
                {campaignName} {campaignID ? ` (${campaignID})` : null}
              </strong>
            )}
            .
            {allowedToOverwrite ? (
              <p>
                Do you want to remove the coupon from{" "}
                <code>{campaignName}</code>? You will need to re-create this
                coupon.
              </p>
            ) : (
              <p>
                Please change the coupon or contact the campaign owner in order
                to remove it from that campaign.
              </p>
            )}
            <div className="footer-buttons center">
              {allowedToOverwrite && (
                <Button onClick={triggerDeleteCoupon}>
                  Yes, remove it from the other campaign
                </Button>
              )}
              <Button
                isLoading={this.state.saving}
                colorScheme="gray"
                onClick={onClose}
                ml={3}
              >
                {allowedToOverwrite ? (
                  <span>Do nothing</span>
                ) : (
                  <span>OK, got it</span>
                )}
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  HelpWithCouponsDialog = () => {
    const onClose = () => {
      this.setState({ helpWithCoupons: null });
    };
    const triggerInjectMerger = (influencerId: any) => {
      this.setState({ saving: true });
      postInjectCoupons(Number(this.props.boardId), influencerId, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
        .then(() => {
          this.setState({
            saving: false,
            helpWithCoupons: null,
            influencerToFix: null,
          });
          this.init();
          onClose();
        })
        .catch(() => {
          console.log("Error");
          this.setState({ saving: false, helpWithCoupons: null });
          onClose();
        });
    };

    const dayMinus30 = formattedDate(moment().subtract(30, "days").toDate());
    return (
      <>
        <Dialog
          className={"campaign-preview-dialog"}
          title={`Scan history`}
          onClose={onClose}
          visible={true}
          style={{ width: "700px", marginTop: 10 }}
          animation="slide-fade"
          maskAnimation="fade"
          closable={!this.state.saving}
        >
          <div
            style={{
              padding: 20,
            }}
          >
            <p>
              We have noticed that you entered new coupons after the campaign
              sales started. <br />
              Would you like to us to scan for old transactions from{" "}
              {dayMinus30} with this coupon and assign them to the influencer?
            </p>
            <Alert status="warning">
              <AlertIcon />
              Notice that this process can take up to an hour.
            </Alert>
            <div
              className="footer-buttons center"
              style={{ marginTop: 20, textAlign: "center" }}
            >
              <Button
                isLoading={this.state.saving}
                isDisabled={this.state.saving}
                onClick={() => triggerInjectMerger(this.state.influencerToFix)}
              >
                Scan and fix
              </Button>

              <Button
                isLoading={this.state.saving}
                colorScheme="gray"
                onClick={onClose}
                ml={3}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  removeGeneralCoupon = (uuid: string) => {
    const index = this.state.generalCoupons.findIndex(
      (x: any) => x.uuid === uuid,
    );
    if (this.state.generalCoupons[index].new) {
      this.state.generalCoupons.splice(index, 1);
    } else {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.generalCoupons[index].is_deleted = true;
    }
    this.setState({});
  };

  addGeneralCoupon = () => {
    this.state.generalCoupons.push({
      uuid: uuidv4(),
      coupon_id: "",
      name: "",
      influencers: null,
      is_deleted: false,
      new: true,
    });
    this.setState({});
  };

  render() {
    const influencers = this.props.influencers ? this.props.influencers : [];
    return (
      <div
        style={{ width: 750, marginLeft: "calc(100% - 970px)" }}
        className="import-from-list-container animated fadeInRight faster"
        id="campaign_coupons_menu"
      >
        {this.state.loading ? (
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        ) : (
          <div>
            <Flex
              style={{ position: "relative", placeContent: "space-between" }}
            >
              <div style={{ fontSize: 22, flex: 3 }}>Campaign Coupons</div>
              <div
                className="import-list-close-button"
                onClick={() => this.props.handleClose()}
                style={{
                  width: 33,
                  marginLeft: 30,
                  marginTop: 9,
                  position: "relative",
                }}
              >
                <span className="moi-ximage" />
              </div>
            </Flex>
            <div
              className="import-from-list-spacer"
              style={{ marginTop: 20, marginBottom: 5 }}
            />
            <Tabs
              renderTabBar={() => <InkTabBar />}
              renderTabContent={() => <TabContent style={{ marginTop: 10 }} />}
              activeKey={this.state.currentTab}
              onChange={(e: string) => this.setState({ currentTab: e })}
              style={{ height: "100%", paddingBottom: 10 }}
            >
              {this.props.sales && (
                <TabPane tab={<span>INDIVIDUAL COUPONS</span>} key="individual">
                  <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                  >
                    <Alert
                      status="info"
                      style={{ marginBottom: 10, width: "100%" }}
                    >
                      <Flex style={{ alignItems: "center", width: "100%" }}>
                        <div style={{ display: "flex", flex: 1 }}>
                          <AlertIcon />
                          <span>
                            Here you can assign individual coupons per
                            influencer (unique)
                          </span>
                        </div>
                      </Flex>
                    </Alert>
                  </Flex>
                  {influencers.length === 0 ? (
                    <div style={{ textAlign: "center", fontSize: 17 }}>
                      No influencers in progress (approval/publish) yet
                    </div>
                  ) : (
                    <div>
                      <div className="import-list-influencers-row">
                        <div
                          className="inner-toolbar-search-input"
                          style={{ width: 173 }}
                        >
                          <div
                            className="moi-search-bold"
                            style={{ fontSize: 12, marginTop: 4 }}
                          />
                          <input
                            type={"search"}
                            onChange={(e) =>
                              this.setState({
                                search: e.target.value.toLowerCase(),
                              })
                            }
                            style={{ height: 20, width: 114 }}
                            className="form-control"
                          />
                        </div>
                        <strong
                          className="small-title"
                          style={{ marginLeft: 20, flex: 1, textAlign: "left" }}
                        >
                          Coupons
                        </strong>
                        <strong
                          className="small-title"
                          style={{ width: 25, marginRight: 35 }}
                        ></strong>
                      </div>
                      <div
                        className="import-list-section no-infinite"
                        style={{
                          marginTop: 10,
                          height: "calc(100vh - 360px)",
                          overflowY: "auto",
                        }}
                      >
                        {influencers?.map((x: any, idx: number) =>
                          this.renderRow(x, idx),
                        )}
                      </div>
                    </div>
                  )}
                </TabPane>
              )}
              <TabPane tab={<span>GENERAL COUPONS</span>} key={"general"}>
                <div
                  style={{
                    height: "calc(100vh - 260px)",
                    overflowY: "auto",
                  }}
                >
                  <Alert status="info" style={{ marginBottom: 10 }}>
                    <AlertIcon />
                    Here you can assign general coupons for all influencers
                  </Alert>
                  <table className="sales-brief-table">
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th style={{ width: 20, cursor: "pointer" }}>
                        <div
                          className="far fa-plus"
                          onClick={this.addGeneralCoupon}
                        />
                      </th>
                    </tr>
                    {this.state.generalCoupons
                      .filter((x: any) => !x.is_deleted)
                      .map((coupon: any, idx: number) => {
                        return (
                          <tr
                            key={coupon.uuid || idx}
                            style={
                              coupon.new
                                ? { borderTop: "2px dotted #ddd" }
                                : null
                            }
                          >
                            <td>
                              <input
                                defaultValue={coupon.coupon_id}
                                onChange={(e) => {
                                  // eslint-disable-next-line no-param-reassign
                                  coupon.coupon_id = e.target.value;
                                  this.setState({});
                                }}
                                disabled={!coupon.new}
                                className="input-line"
                                placeholder="Coupon code"
                              />
                            </td>
                            <td>
                              <input
                                defaultValue={coupon.name}
                                onChange={(e) => {
                                  // eslint-disable-next-line no-param-reassign
                                  coupon.name = e.target.value;
                                  this.setState({});
                                }}
                                className="input-line"
                                placeholder="Label"
                                disabled={!coupon.new}
                              />
                            </td>
                            <td style={{ width: 20, cursor: "pointer" }}>
                              <div
                                className="far fa-trash"
                                onClick={() =>
                                  this.removeGeneralCoupon(coupon.uuid)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                  {this.state.error ? (
                    <strong className="error-text">
                      Coupon code is missing
                    </strong>
                  ) : (
                    ""
                  )}
                  <Button
                    isLoading={this.state.saving}
                    className="btn btn-primary"
                    isDisabled={this.state.saving}
                    style={{ marginTop: 15 }}
                    onClick={() => this.onSaveGeneralCoupons()}
                  >
                    Save General Coupons
                  </Button>
                </div>
              </TabPane>
            </Tabs>
          </div>
        )}
        {this.state.conflictedCoupon && this.ConflictedCouponsDialog()}
        {this.state.helpWithCoupons &&
          this.state.influencerToFix &&
          this.HelpWithCouponsDialog()}
      </div>
    );
  }

  addNewCoupon = () => {
    if (!this.state.showNewCoupon)
      this.setState({ showNewCoupon: true, tempCoupon: { new: true } });
  };

  onSaveCoupons = (influId: number) => {
    const newCoupons = this.state.coupons.filter((x) =>
      x?.influencers?.includes(influId),
    );

    let hasError = false;
    newCoupons.forEach((coupon: any) => {
      if (!coupon.coupon_id || coupon?.coupon_id?.trim() === "") {
        this.setState({ error: true, saving: false });
        hasError = true;
      }
    });

    if (!hasError) {
      this.setState({ coupons: newCoupons, saving: true, error: false });
      saveCampaignCoupons(
        this.props.boardId,
        newCoupons,
        (success: boolean) => {
          if (success) {
            if (
              this.props.salesStartTime &&
              moment(this.props.salesStartTime).isBefore(Date.now()) &&
              newCoupons.filter((c: any) => c.new).length &&
              this.props.allowedMergerInjection
            ) {
              this.setState({
                helpWithCoupons: true,
                influencerToFix: influId,
              });
            }
            newCoupons.forEach((c: any) => {
              // eslint-disable-next-line no-param-reassign
              c.new = false;
            });
            this.init();
          } else {
            this.setState({ coupons: [...this.state.couponsHistory] });
          }
          this.setState({ saving: false });
        },
        (err: any) => {
          if (err.response.status === 409) {
            this.setState({ conflictedCoupon: err.response.data });
          }
        },
      );
    }
  };

  onSaveGeneralCoupons = () => {
    this.setState({
      coupons: this.state.generalCoupons,
      saving: true,
      error: false,
    });
    let hasError = false;
    const tmpGeneralCoupons = this.state.generalCoupons.filter(
      (tmpCoupon: any) => {
        return tmpCoupon.coupon_id;
      },
    );
    tmpGeneralCoupons.forEach((coupon: any) => {
      if (coupon?.coupon_id?.trim() === "") {
        this.setState({ error: true, saving: false });
        hasError = true;
      }
    });
    this.setState({ generalCoupons: tmpGeneralCoupons }, () => {
      if (!hasError)
        saveCampaignCoupons(
          this.props.boardId,
          this.state.generalCoupons,
          (success: boolean) => {
            if (success) {
              this.state.generalCoupons.forEach((c: any) => {
                // eslint-disable-next-line no-param-reassign
                c.new = false;
              });
              this.setState(
                { generalCoupons: [...this.state.generalCoupons] },
                this.init,
              );
            }
            this.setState({ saving: false });
          },
        );
    });
  };

  renderRow = (data: InfluencerData, idx: number) => {
    const infCoupons = this.state.coupons.filter(
      (x) => !x.is_deleted && x.influencers?.includes(data.influencer_id),
    );
    return (
      (this.state.search &&
      this.state.search.length &&
      data.name &&
      data.name.length
        ? data?.name
            ?.toLowerCase()
            ?.trim()
            ?.includes(this.state?.search?.trim())
        : true) && (
        <div
          key={idx}
          className={`${
            this.state.openToEdit === data.influencer_id && "edit"
          }`}
          style={{ marginBottom: 10, marginTop: 10 }}
        >
          <div className="import-list-row">
            <div style={{ width: 45, height: 45 }}>
              <img
                className="blurable"
                style={{ width: 45, height: 45 }}
                src={data.picture_url ? data.picture_url : NoImage}
              />
            </div>
            <div
              className={"import-list-row-details "}
              style={{
                width: 650,
                marginLeft: 11,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <strong
                  style={{ width: 180 }}
                  className="import-list-name blurable-large"
                  title={data.name}
                >
                  {data.name}
                </strong>
                <div style={{ flex: 1, display: "flex", flexWrap: "wrap" }}>
                  {infCoupons.filter((x) => !x.new).map(this.renderCoupon)}
                </div>
                <div
                  className="far fa-edit"
                  style={{ cursor: "pointer", fontSize: 16 }}
                  onClick={() =>
                    this.setState({
                      openToEdit:
                        this.state.openToEdit === data.influencer_id
                          ? -1
                          : data.influencer_id,
                      showNewCoupon: true,
                      tempCoupon: { new: true },
                    })
                  }
                />
              </div>
              {this.state.openToEdit === data.influencer_id ? (
                <div>
                  <table
                    className="sales-brief-table"
                    style={{ marginTop: 20 }}
                  >
                    <tr>
                      <th>Code</th>
                      <th>Name (Optional)</th>
                      <th style={{ width: 20, cursor: "pointer" }}>
                        <div
                          className="far fa-plus"
                          onClick={this.addNewCoupon}
                        />
                      </th>
                    </tr>
                    {infCoupons.map(this.renderCouponRow)}
                    {this.state.showNewCoupon
                      ? this.renderCouponRow(this.state.tempCoupon)
                      : null}
                  </table>
                  {this.state.error ? (
                    <strong className="error-text">
                      Coupon code is missing
                    </strong>
                  ) : (
                    ""
                  )}
                  <Button
                    className="btn btn-primary"
                    isDisabled={this.state.saving || this.state.hasError}
                    style={{ marginTop: 15 }}
                    isLoading={this.state.saving}
                    onClick={() => this.onSaveCoupons(data.influencer_id)}
                  >
                    Save
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderCoupon = (coupon: CouponInSales) => {
    return (
      <div className="coupon-container" key={coupon.uuid}>
        {coupon.coupon_id}
      </div>
    );
  };

  onCouponBlur = (onBlur?: any) => {
    let newState = {};
    if (this.state.tempCoupon.coupon_id) {
      const coupon = {
        coupon_id: this.state.tempCoupon?.coupon_id?.trim(),
        ...this.state.tempCoupon,
      };
      coupon.uuid = uuidv4();
      coupon.influencers = [this.state.openToEdit];
      coupon.new = true;
      coupon.is_deleted = false;
      this.state.coupons.push(coupon);
      newState = { showNewCoupon: false, tempCoupon: { new: true } };
    }
    this.setState(newState);
    if (onBlur) {
      onBlur();
    }
  };

  trashCoupon = (coupon: ExtendedCoupon) => {
    if (coupon.uuid) {
      if (coupon.new) {
        const indx = this.state.coupons.findIndex(
          (x) => x.uuid === coupon.uuid,
        );
        if (indx > -1) this.state.coupons.splice(indx, 1);
      } else {
        if (coupon.influencers && coupon.influencers.length > 1) {
          coupon.influencers?.addOrRemove(this.state.openToEdit);
        }
        // eslint-disable-next-line no-param-reassign
        coupon.is_deleted = true;
      }
      this.setState({});
    } else {
      this.setState({ tempCoupon: { new: true }, showNewCoupon: true });
    }
  };

  renderCouponRow = (coupon: ExtendedCoupon) => {
    const isNew = coupon.new;
    const disabled =
      (coupon?.influencers?.length !== undefined &&
        coupon?.influencers?.length > 1) ||
      !isNew;
    return (
      <tr
        key={coupon?.uuid}
        style={isNew ? { borderTop: "2px dotted #ddd" } : null}
      >
        <td>
          <div>
            <div
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              className="coupon_auto_complete_container"
            >
              <input
                {...this.props}
                defaultValue={coupon?.coupon_id}
                disabled={disabled}
                className="input-line"
                placeholder="Coupon code"
                onChange={(e) => {
                  // eslint-disable-next-line no-param-reassign
                  coupon.coupon_id = e.target.value;
                  this.setState({});
                }}
                onBlur={() => this.onCouponBlur()}
              />
            </div>
          </div>
        </td>
        <td>
          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            disabled={disabled}
          >
            <input
              defaultValue={coupon?.name}
              onChange={(e) => {
                // eslint-disable-next-line no-param-reassign
                coupon.name = e.target.value;
                this.setState({});
              }}
              disabled={disabled}
              onBlur={() => this.onCouponBlur()}
              className="input-line"
              placeholder="Label"
            />
          </div>
        </td>
        {coupon.uuid ? (
          <td style={{ width: 20, cursor: "pointer" }}>
            <div
              className="far fa-trash"
              onClick={() => this.trashCoupon(coupon)}
            />
          </td>
        ) : null}
      </tr>
    );
  };
}
export default CampaignCouponsSide;
