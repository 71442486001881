import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getExternalInvitations,
  getListsScrolling,
} from "src/api/actions/listActions";
import { queryClient } from "src/queryClient";
import { listsActions } from "src/reducers/listsSlice";
import { useDebounce, usePrevious } from "./coreHooks";

export const useDebounceListSearch: () => [
  string,
  React.Dispatch<React.SetStateAction<string>>,
] = () => {
  const dispatch = useDispatch();
  const search = useSelector((store) => store.lists.filter.search);

  const handleDebounceChange = useCallback<(arg: string) => void>(
    (newSearch) => {
      dispatch(listsActions.setFilterSearch(newSearch));
    },
    [dispatch],
  );
  const [searchState, setSearchState] = useDebounce(
    search,
    handleDebounceChange,
    800,
  );

  return [searchState, setSearchState];
};

export const useListsScrolling = ({
  archive,
  favorites,
}: Omit<
  Parameters<typeof getListsScrolling.useQuery>[0],
  "childId" | "search" | "tagIds" | "lastSeenTime"
>) => {
  const childAccount = localStorage.getItem("child_account");
  const dispatch = useDispatch();
  const listsLastSeen = useSelector((store) => store.lists.lastSeen);
  const lists = useSelector((store) => store.lists.lists);
  const externalInvitations = useSelector(
    (store) => store.lists.externalInvitations,
  );
  const filter = useSelector((store) => store.lists.filter);

  const previousArchive = usePrevious(archive);
  const previousFavorites = usePrevious(favorites);
  const previousFilter = usePrevious(filter);

  const didTabChanged =
    previousArchive !== undefined &&
    previousFavorites !== undefined &&
    (archive !== previousArchive || favorites !== previousFavorites);
  const didFilterChanged =
    previousFilter !== undefined && filter !== previousFilter;

  useEffect(() => {
    if (didTabChanged) {
      dispatch(listsActions.resetFilter());
    }
    if (didTabChanged || didFilterChanged) {
      dispatch(listsActions.resetLists());
      queryClient.resetQueries({ queryKey: [getListsScrolling.key] });
    }
  }, [didTabChanged, didFilterChanged, dispatch]);

  const response = getListsScrolling.useQuery({
    archive,
    favorites,
    lastSeenTime: listsLastSeen,
    search: filter.search,
    tagIds: filter.tags.map((x) => x.id),
    childId: childAccount ? Number(childAccount) : undefined,
  });

  useEffect(() => {
    const resLists = response.data?.data;
    if (resLists) {
      if (!listsLastSeen) {
        dispatch(listsActions.resetLists());
        getExternalInvitations()
          .then((invRes) => {
            dispatch(listsActions.setExternalInvitations(invRes.data || []));
          })
          .catch(() => dispatch(listsActions.setExternalInvitations([])));
      }
      dispatch(listsActions.addToLists(resLists.normal ?? []));
    }
  }, [response.data?.data, listsLastSeen, dispatch]);

  const fetchMore = useCallback(() => {
    const resLists = response.data?.data;
    if (
      lists &&
      !response.isLoading &&
      resLists?.normal &&
      resLists.normal.length !== 0
    ) {
      dispatch(
        listsActions.setLastSeen(
          resLists.normal[resLists.normal.length - 1].creation_time as string,
        ),
      );
    }
  }, [dispatch, response, lists]);

  return {
    response,
    data: { lists, externalInvitations },
    fetchMore,
  };
};
