import React from "react";
import { Outlet } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SmartBanner from "react-smartbanner";

export const App: React.FC = () => {
  return (
    <div data-testid="app">
      <SmartBanner title={"Humanz"} />
      <Outlet />
    </div>
  );
};
