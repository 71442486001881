import { Button } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";

const TwitterSessionRedirect = "twitter-redirect-url";

export const TwitterButton = ({
  tokenCallback,
}: {
  tokenCallback(token: string | false): void;
}) => {
  const handleCallback = useCallback(
    (success?: string) => {
      if (success) {
        tokenCallback(success);
      }
    },
    [tokenCallback],
  );

  const twitterCallback = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("tw-code");
    window.history.pushState(
      null,
      "",
      window.location.origin + window.location.pathname,
    );
    handleCallback(code);
  }, [handleCallback]);

  useEffect(() => {
    if (window.location.href.includes("tw-code")) {
      twitterCallback();
    }
    sessionStorage.removeItem(TwitterSessionRedirect);
  }, [twitterCallback]);

  const handleContinueWith = async () => {
    const authorizationUrl = "https://twitter.com/i/oauth2/authorize";
    const responseType = "code";
    const clientId = "aEFQU1cxb2tEYXREQUF4cmpvZlo6MTpjaQ";
    const redirectUri = `${window.location.origin}/tw-callback.html`;
    const scope = "tweet.read users.read offline.access";
    const state = "state";
    const loginURL = `${authorizationUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&state=${state}&code_challenge=challenge&code_challenge_method=plain`;
    // eslint-disable-next-line prefer-template
    sessionStorage.setItem(TwitterSessionRedirect, window.location.href);
    window.location.href = loginURL;
  };

  return (
    <>
      <div style={{ textAlign: "center", marginTop: 40 }}>
        <Button
          fontSize={"xl"}
          padding={"20px"}
          className="tw-login-button"
          background="#1DA1F2"
          onClick={handleContinueWith}
        >
          <i className="fa fa-twitter" style={{ marginRight: 15 }} />
          Continue with Twitter
        </Button>
      </div>
    </>
  );
};
