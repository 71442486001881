// TODO - lint
/* eslint-disable func-style, func-style, func-style, import/no-default-export, no-param-reassign, no-param-reassign */
import React, { Component } from "react";
import Slider, {
  Rail,
  Handles,
  Tracks,
  Ticks,
} from "@humanz/react-compound-slider";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";

const sliderStyle = {
  position: "relative",
  width: "100%",
};

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 8,
  borderRadius: 7,
  cursor: "pointer",
  backgroundColor: "#ebebeb",
  pointerEvents: "none",
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
  handle: { id, value, percent, hideTooltip },
  getHandleProps,
}) {
  return (
    <Tooltip
      placement="top"
      trigger={hideTooltip ? [] : ["hover"]}
      overlay={<span>{value}</span>}
    >
      <div
        role="slider"
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: "absolute",
          marginLeft: "-11px",
          marginTop: "-9px",
          zIndex: 2,
          width: 24,
          height: 24,
          cursor: "pointer",
          borderRadius: "50%",
          boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
        }}
        {...getHandleProps(id)}
      />
    </Tooltip>
  );
}

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 8,
        zIndex: 1,
        backgroundColor: "#249ff0",
        borderRadius: 4,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, count, format }) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 14,
          width: 1,
          height: 5,
          backgroundColor: "rgb(200,200,200)",
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: 22,
          fontSize: 10,
          fontFamily: "Arial",
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
}

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func.isRequired,
};

Tick.defaultProps = {
  format: (d) => d,
};

export default class SliderStages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: this.props.defaultValues.slice(),
      update: this.props.defaultValues.slice(),
    };
  }

  onUpdate = (update) => {
    this.setState({ update });
    if (this.props.onUpdate) this.props.onUpdate(update);
  };

  onChange = (values) => {
    this.setState({ values });
    if (this.props.onChange) this.props.onChange(values);
  };

  render() {
    const { values } = this.state;
    const { domain, showEdges } = this.props;
    return (
      <div>
        <Slider
          mode={1}
          step={1}
          domain={domain}
          rootStyle={sliderStyle}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => (
              <div style={railStyle} {...getRailProps()} />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => {
              handles = showEdges
                ? handles
                : handles.slice(1, handles.length - 1);
              return (
                <div className="slider-handles">
                  {handles.map((handle) => {
                    handle.hideTooltip = this.props.hideTooltip;
                    return (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                      />
                    );
                  })}
                </div>
              );
            }}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks multi">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>

          <Ticks count={5}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map((tick) => (
                  <Tick key={tick.id} tick={tick} count={ticks.length} />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    );
  }
}

SliderStages.defaultProps = {
  domain: [0, 100],
  defaultValues: [0],
  showEdges: true,
  hideTooltip: false,
};
