type Props = {
  isShare: boolean;
  editMode: boolean;
  showBack: boolean;
  showNext: boolean;
  disabled?: boolean;
  backAction: any;
  nextAction: any;
  saveAction?: any;
  controlSaveEditButton?: boolean;
  briefType?: true;
};

const BreifTabButtons = ({
  backAction,
  editMode,
  isShare,
  nextAction,
  saveAction,
  showBack,
  showNext,
  disabled,
  controlSaveEditButton,
  briefType,
}: Props) => {
  return (
    !isShare && (
      <div
        className="create-buttons-row flex"
        style={{
          justifyContent:
            (!editMode && showNext && !showBack) || briefType
              ? "flex-end"
              : "space-between",
        }}
      >
        {showBack && (
          <button className="btn btn-primry" onClick={backAction}>
            BACK
          </button>
        )}
        {editMode &&
          (!briefType ? (
            <button className="btn btn-primry" onClick={saveAction}>
              SAVE
            </button>
          ) : (
            <button
              className="btn btn-primry"
              style={{
                background: "var(--main-baby-blue-color)",
                color: "white",
              }}
              onClick={saveAction}
            >
              {controlSaveEditButton ? "SAVE" : "NEXT"}
            </button>
          ))}
        {!editMode && !showNext && (
          <button
            onClick={nextAction}
            className={`btn btn-primry ${disabled && "disabled"}`}
          >
            START WORKING
          </button>
        )}
        {!editMode &&
          showNext &&
          (!briefType ? (
            <button className="btn btn-primry" onClick={nextAction}>
              NEXT
            </button>
          ) : (
            <button
              className="btn btn-primry"
              style={{
                justifyContent: "flex-end",
                background: "var(--main-baby-blue-color)",
                color: "white",
              }}
              onClick={nextAction}
            >
              NEXT
            </button>
          ))}
      </div>
    )
  );
};

export default BreifTabButtons;
