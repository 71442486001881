import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import CountUp from "react-countup";
import { formatBigNumbers } from "../../utilities/general";
import DashboardPaper from "./DashboardPaper";

type Props = {
  value: any;
  title1: string;
  title2: string;
  loading?: boolean;
};

const DashboardSingleTotalLine = (props: Props) => {
  return (
    <DashboardPaper width={"100%"} padding={8}>
      {props?.loading ? (
        <Flex gap={10}>
          <Skeleton width={"70%"} height={10} />
          <Skeleton width={"20%"} height={10} />
        </Flex>
      ) : (
        <Flex justifyContent={"space-between"}>
          <Box fontSize={16} alignSelf={"center"}>
            <strong>{props?.title1} </strong>
            <strong style={{ color: "var(--main-baby-blue-color)" }}>
              {props?.title2}
            </strong>
          </Box>
          <Box alignSelf={"center"}>
            <Text fontSize="24px" fontWeight={"500"}>
              <CountUp
                decimalPlaces={2}
                formattingFn={formatBigNumbers}
                end={Math.abs(props?.value)}
                delay={1}
              />
            </Text>
          </Box>
        </Flex>
      )}
    </DashboardPaper>
  );
};

export default DashboardSingleTotalLine;
