import React from "react";
import { AuctionPaymentsData } from "src/api/types";

export type CampaignPaymentsContextType = {
  search: any;
  setSearch: any;
  payments: AuctionPaymentsData;
  boardId: number;
  getPaymentData: () => void;
  disputeInfluencer?: any;
  setDisputeInfluencer?: any;
};
export const CampaignPaymentsContext =
  React.createContext<CampaignPaymentsContextType>({
    search: null,
    setSearch: null,
    payments: null,
    boardId: null,
    getPaymentData: () => {},
    disputeInfluencer: null,
    setDisputeInfluencer: null,
  });
