const STORAGE_NAME = "r-v-p";

export const addProfileToRecent = (id: number | string) => {
  const items: string[] = JSON.parse(
    localStorage.getItem(STORAGE_NAME) || "[]",
  );
  items.unshift(id.toString());
  const newItems = items.slice(0, 100);

  localStorage.setItem(STORAGE_NAME, JSON.stringify(newItems));
};

export const getRecentViewedProfiles = () => {
  return JSON.parse(localStorage.getItem(STORAGE_NAME) || "[]");
};
