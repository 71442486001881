import type { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle } = createMultiStyleConfigHelpers([]);

const payments = definePartsStyle({
  root: {
    boxShadow: "none",
  },
  container: {
    background: "gray.50",
    boxShadow: "none",
  },
  panel: {
    background: "gray.50",
    borderRadius: "2px",
    boxShadow: "none",
  },
  button: {
    border: "none",
    background: "#EAE8E8",
    color: "#333",
    fontSize: 18,
    _focus: {
      boxShadow: "none",
    },
    svg: {
      w: 7,
      height: "auto",
      color: "gray.400",
    },
    _expanded: {
      svg: {
        color: "gray.500",
      },
    },
  },
});

const paymentsInfluencer = definePartsStyle({
  root: {},
  container: {
    background: "white",
    boxShadow: "0px 0px 17px 0px rgba(0, 0, 0, 0.10)",
    margin: "4px",
    borderRadius: 6,
  },
  panel: {
    background: "white",
    borderRadius: "2px",
    boxShadow: "none",
  },
  button: {
    border: "none",
    background: "white",
    color: "#333",
    fontSize: 14,
    _focus: {
      boxShadow: "none",
    },
    svg: {
      w: 7,
      height: "auto",
      color: "gray.400",
    },
    _expanded: {
      svg: {
        color: "gray.500",
      },
    },
  },
});

export const AccordionTheme: ComponentMultiStyleConfig = {
  parts: ["container", "button", "panel", "icon", "root"],
  variants: {
    payments,
    paymentsInfluencer,
  },
  baseStyle: {
    root: {},
    container: {
      borderRadius: "xl",
      overflow: "hidden",
      backgroundColor: "white",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      mt: 3,
      mx: 1,
      _last: {
        mb: 3,
      },
    },
    button: {
      border: "none",
      _focus: {
        boxShadow: "none",
      },
      display: "flex",
      flexDir: "row",
      justifyContent: "space-between",
      background: "transparent",
      svg: {
        w: 12,
        height: "auto",
        color: "gray.400",
      },
      _expanded: {
        svg: {
          color: "blue.400",
        },
      },
    },
    panel: {
      borderTopStyle: "solid",
      borderTopWidth: 1,
      borderTopColor: "blackAlpha.50",
    },
  },
};
