import React from "react";

export const RoiContext = React.createContext({
  loaded: false,
  summaryData: null,
  shared: false,
  fromCampaign: false,
  shareIndividual: false,
  shareInfluencerCost: false,
  isAmbassador: false,
  reportId: -1,
  openNotes: (_id: number) => {},
  notesSummary: null,
  shareHideImpressions: false,
  filters: null,
  allowFilters: false,
  showFilters: () => {},
  fromContext: true,
  openInfluencerSegmentations: () => {},
  Header: null,
  shareReport: () => {},
  generateSlidesData: async () => "",
});
