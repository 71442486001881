import { TwitterTweetEmbed } from "react-twitter-embed";
import { RatioMedia } from "src/ui/RatioMedia";
import NoImageFound from "../../../../../images/no-image.png";

type Props = { id: any; post_type: string; post: any };

const MediaFrame = (props: Props) => {
  const { id, post_type: postType, post: task } = props;
  <style>{`
        iframe {
          margin-top: 0;
        }
      `}</style>;
  let iframe = null;
  if (!id && !task.linked_media) {
    iframe = (
      <img
        style={{ width: "250px", height: "auto" }}
        alt=""
        className="no-image"
        src={NoImageFound}
      />
    );
  } else if (postType === "tk_video") {
    iframe = (
      <iframe
        title="TikTok"
        width={"260"}
        height={"450"}
        src={`https://www.tiktok.com/embed/${id}`}
        frameBorder={"0"}
        allow={
          "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        }
      ></iframe>
    );
  } else if (postType === "ig_post") {
    iframe = (
      <iframe
        src={`https://www.instagram.com/p/${id}/embed/captioned`}
        width="260"
        height="450"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    );
  } else if (postType === "yt_video") {
    iframe = (
      <iframe
        width="260"
        height="430"
        src={`https://www.youtube.com/embed/${id}`}
      ></iframe>
    );
  } else if (postType === "tw_tweet") {
    iframe = (
      <div>
        <TwitterTweetEmbed tweetId={id} />
      </div>
    );
  } else if (postType === "ig_story") {
    iframe = (
      <RatioMedia
        type={task.video_url !== "null" ? "video" : "image"}
        w="100%"
        h="100%"
        src={task.video_url !== "null" ? task.video_url : task.media_url}
      />
    );
  } else if (postType === "influencer_content") {
    iframe = <RatioMedia type="image" w="100%" h="100%" src={task.media_url} />;
  }

  return (
    <div
      className="blurable-frame"
      style={{
        display: "inline-block",
        width: 260,
        height: 450,
        overflow: "hidden",
      }}
    >
      {iframe}
    </div>
  );
};

export default MediaFrame;
