import { Button } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";

const TikTokSessionRedirect = "tiktok-redirect-url";

export const TikTokButton = ({
  tokenCallback,
}: {
  tokenCallback(token: string): void;
}) => {
  const handleCallback = useCallback(
    (success?: string) => {
      if (success) {
        tokenCallback(success);
      }
    },
    [tokenCallback],
  );

  const twitterCallback = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("tk-code");
    window.history.pushState(
      null,
      "",
      window.location.origin + window.location.pathname,
    );
    handleCallback(code);
  }, [handleCallback]);

  useEffect(() => {
    if (window.location.href.includes("tk-code")) {
      twitterCallback();
    }
    sessionStorage.removeItem(TikTokSessionRedirect);
  }, [twitterCallback]);

  const handleContinueWith = async () => {
    sessionStorage.setItem(TikTokSessionRedirect, window.location.href);
    window.location.href = "/oauth-tk";
  };

  return (
    <div style={{ textAlign: "center", marginTop: 40 }}>
      <Button
        fontSize={"xl"}
        padding={"20px"}
        className="tk-login-button"
        background="black"
        onClick={handleContinueWith}
      >
        <i className="fa-brands fa-tiktok" style={{ marginRight: 15 }} />
        Sign in with TikTok
      </Button>
    </div>
  );
};
