// TODO - lint
/* eslint-disable react/no-deprecated, eqeqeq, class-methods-use-this, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, class-methods-use-this, no-unused-expressions, no-nested-ternary, no-nested-ternary, no-underscore-dangle, no-underscore-dangle, no-nested-ternary, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, import/no-default-export */
// General Imports
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GOOGLE_CLIENT_ID } from "../../actions/commonActionsConf";
// Actions imports
import {
  logInUser,
  recoverPassword,
  resetPassword,
  signUpUser,
  socialLogin,
} from "../../actions/userActions";
import CheckBox from "../feed_components/general_components/CheckBox";
import AgentDetectedModal from "./AgentDetectedModal";

const requiredPasswordLength = 8;
const requiredPasswordLengthWithoutValidation = 12;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remmberMe: true,
      page: "",
      captcha: false,
      validation: null,
      finishedSignUp: false,
      signupFail: null,
      forgotPassword: false,
      validLength: false,
      hasNumber: false,
      upperCase: false,
      lowerCase: false,
      match: false,
      specialChar: false,
      validLengthWithoutValidation: false,
      showAgentDetected: false,
    };
    this.state.isValidPass =
      this.state.validLengthWithoutValidation ||
      (this.state.validLength &&
        this.state.hasNumber &&
        this.state.upperCase &&
        this.state.lowerCase &&
        this.state.specialChar);
  }

  CAPTCHA_REF;

  /* ##### Functions ##### */
  componentWillMount() {
    this.changePage();
  }

  // componentDidMount() {
  //     hello.init({
  //         facebook: "258214757860620",
  //         instagram: "d540a5aeb6e7464c847a05a76a309fd5",
  //         google: "713544013543-uv52h4cmc2m011poh8j7ihe6v4v7cv1v.apps.googleusercontent.com"
  //     },
  //         {
  //             redirect_uri: 'index.html'
  //         });
  // }

  componentDidUpdate() {
    this.changePage();
    // console.log(this.validatePassword())
  }

  validatePassword = (value) => {
    return this.setState(
      {
        validLength: value.length >= requiredPasswordLength,
        validLengthWithoutValidation:
          value.length >= requiredPasswordLengthWithoutValidation,
        hasNumber: /\d/.test(value),
        upperCase: /[A-Z]/.test(value),
        lowerCase: /[a-z]/.test(value),
        specialChar: /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(value),
      },
      () => {
        return this.setState(
          {
            isValidPass:
              this.state.validLengthWithoutValidation ||
              (this.state.validLength &&
                this.state.hasNumber &&
                this.state.upperCase &&
                this.state.lowerCase &&
                this.state.specialChar),
          },
          () => {
            this.setState({
              passError: !this.state.isValidPass,
              passwordFocus: !this.state.isValidPass,
            });
            return this.state.isValidPass;
          },
        );
      },
    );
  };

  validateRecoverPassword = (value) => {
    return this.setState(
      {
        validLength: value.length >= requiredPasswordLength,
        validLengthWithoutValidation:
          value.length >= requiredPasswordLengthWithoutValidation,
        hasNumber: /\d/.test(value),
        upperCase: /[A-Z]/.test(value),
        lowerCase: /[a-z]/.test(value),
        specialChar: /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(value),
      },
      () => {
        return this.setState(
          {
            isValidPass:
              this.state.validLengthWithoutValidation ||
              (this.state.validLength &&
                this.state.hasNumber &&
                this.state.upperCase &&
                this.state.lowerCase &&
                this.state.specialChar),
          },
          () => {
            this.setState({
              passError: !this.state.isValidPass,
              passwordFocus: !this.state.isValidPass,
            });
            return this.state.isValidPass;
          },
        );
      },
    );
  };

  setFirst = (event) => {
    const { value } = event.target;
    this.setState({ _passwordInput: value });
    this.validatePassword(value);
  };

  setSecond = (event) => {
    const { value } = event.target;
    this.setState({ _rePasswordInput: value });
    this.validatePassword(value);
  };

  recoverySetFirst = (event) => {
    const { value } = event.target;
    this.setState({ _recoverNewPassword: value });
    this.validateRecoverPassword(value);
  };

  recoverySetSecond = (event) => {
    const { value } = event.target;
    this.setState({ _recoverRePassword: value });
    this.validateRecoverPassword(value);
  };

  changePage() {
    let page = this.props.params.tab;
    if (this.props.location.pathname === "/forgot-password/challenge") {
      page = "forgot-password";
    }
    if (this.props.location.pathname === "/email-validation/validation") {
      if (this.state.page != "login") {
        this.setState({
          page: "login",
          validation: this.props.searchParams.get("success") === "true",
        });
      }
    } else if (page !== this.state.page) {
      if (page === "login" || page === "signup") {
        document.getElementById("body").classList.add("login-body");
        const obj = { page };
        if (page === "login") {
          obj.signupFail = null;
        }
        this.setState({ signupFail: null, page });
      } else if (page === "forgot-password") {
        if (
          this.props.searchParams.has("email") &&
          this.props.searchParams.has("token")
        ) {
          document.getElementById("body").classList.add("login-body");
          this.setState({
            signupFail: null,
            page,
            resetEmail: this.props.searchParams.get("email"),
            token: this.props.searchParams.get("token"),
          });
        }
      }
      // else if (!process.env.NODE_ENV || process.env.NODE_ENV === 'DEV' || process.env.NODE_ENV === 'development') {
      //     window.location.href = "/login";
      // }
      else {
        window.location.href = "https://www.humanz.com";
      }
    }
  }

  componentWillUnmount() {
    document.getElementById("body").classList.remove("login-body");
  }

  // logInViaSocial(provider) {
  //     var token = "";
  //     var settings = {};
  //     var that = this;
  //     hello(provider).login(settings).then(function (authResponse) {
  //         token = authResponse.authResponse.access_token;
  //         return hello(provider).api('me');
  //     }).then(function (meResponse) {
  //         that.props.socialLogin(provider, token, meResponse.id, false);
  //     })
  // }

  onLogin(e) {
    e.preventDefault();
    const { page } = this.state;
    if (!this._emailInput.value) return;
    if (!this.state.captcha) {
      this.setState({ signupFail: "Fill the captcha" });
      return;
    }
    if (page === "login") {
      this.setState({ signupFail: null });
      this.props.logInUser(
        this._emailInput.value.trim(),
        this._passwordInput.value,
        this.state.captcha,
        false,
        this.state.remmberMe,
        (type) => this.afterFailedToLogin(type),
      );
    } else if (page === "signup") {
      if (this._rePasswordInput.value === this.state._passwordInput) {
        if (!this.state.captcha) {
          this.setState({ signupFail: "Fill the captcha" });
        } else {
          this.props.signUpUser(
            this._emailInput.value.trim(),
            this._passwordInput.value,
            this.state.captcha,
            false,
            this.state.remmberMe,
            () => this.afterSuccessSignUp(),
            (err) => this.afterFailedToSignup(err),
          );
        }
      } else {
        this.setState({ signupFail: "Mismatch passwords" });
      }
    }
  }

  afterFailedToLogin(type) {
    this.resetCaptcha();
    if (type === "email") {
      this.setState({ signupFail: "Verify Email" });
    } else {
      if (type === "agent") {
        this.setState({
          signupFail: "Talent manager",
          showAgentDetected: true,
        });
      }
      this.setState({ signupFail: "Bad Email / Password" });
    }
  }

  resetCaptcha = () => {
    this.CAPTCHA_REF?.reset();
  };

  afterFailedToSignup(err) {
    this.resetCaptcha();
    if (err && err.response && err.response.data) {
      this.setState({ signupFail: err.response.data.error });
    }
  }

  afterSuccessSignUp() {
    this.setState({ finishedSignUp: true });
  }

  onCaptchaChange(value) {
    if (value) {
      this.setState({ captcha: value });
    }
  }

  onResetPassword(e) {
    e.preventDefault();

    document.getElementById("forgot_password_label").textContent = "";
    if (!this._emailInput?.value?.length) {
      document.getElementById("forgot_password_label").textContent =
        "Failed to send mail. Please check your email address and try again";
      document.getElementById("forgot_password_label").style.color = "red";
      return;
    }
    this.props.recoverPassword(
      this._emailInput.value,
      this.state.captcha,
      () => {
        document.getElementById(
          "forgot_password_label",
        ).textContent = `Mail sent to ${this._emailInput.value}`;
        document.getElementById("forgot_password_label").style.color = "green";
      },
      () => {
        document.getElementById("forgot_password_label").textContent =
          "Failed to send mail. Please check your email address and try again";
        document.getElementById("forgot_password_label").style.color = "red";
        this.resetCaptcha();
      },
    );
  }

  sendNewPassword(e) {
    e.preventDefault();
    this.setState({ changePasswordError: "" });
    if (
      this._recoverNewPassword &&
      this._recoverRePassword &&
      this._recoverNewPassword.value &&
      this._recoverNewPassword.value === this._recoverRePassword.value
    ) {
      this.props.resetPassword(
        this.state.resetEmail,
        this._recoverNewPassword.value,
        this.state.token,
        this.state.captcha,
        () => {
          this.props.navigate("/login");
        },
        () => {
          this.setState({
            changePasswordError:
              "Failed to change password Please try again later",
          });
        },
      );
    } else {
      this.setState({ changePasswordError: "Passwords doesn't match" });
      this.resetCaptcha();
    }
  }

  /* ##### End Functions ##### */

  responseGoogle = (response) => {
    if (response?.clientId) {
      this.props.socialLogin("google", response.credential, response.clientId);
    }
  };

  validIcon(condition) {
    return condition ? (
      <span>
        <i className="fa fa-check-circle with-color" />
      </span>
    ) : (
      <span>
        <i className="fa fa-times-circle with-color" />
      </span>
    );
  }

  renderPasswordValidation() {
    return (
      <div
        className={`passwordValidation ${
          this.state.passwordFocus ? "active" : ""
        }`}
      >
        <div className="password-validation-title">Password validation</div>
        <ul>
          {!(
            this.state.validLength &&
            this.state.hasNumber &&
            this.state.upperCase &&
            this.state.lowerCase &&
            this.state.specialChar
          ) && (
            <li>
              {this.validIcon(this.state.validLengthWithoutValidation)} Length
              must be at least 12 characters
            </li>
          )}
          {!this.state.isValidPass && (
            <>
              <hr />
              <div style={{ textAlign: "center" }}>
                <strong>OR</strong>
              </div>
              <hr />
            </>
          )}
          {!this.state.validLengthWithoutValidation && (
            <>
              <li>
                {this.validIcon(
                  this.state.validLength ||
                    this.state.validLengthWithoutValidation,
                )}{" "}
                Length must be at least 8 characters
              </li>
              <li>
                {this.validIcon(this.state.hasNumber)} Must contain at least one
                number
              </li>
              <li>
                {this.validIcon(this.state.upperCase)} Must contain at least one
                uppercase letter
              </li>
              <li>
                {this.validIcon(this.state.lowerCase)} Must contain at least one
                lowercase letter
              </li>
              <li>
                {this.validIcon(this.state.specialChar)} Must contain at least
                one special character <br /> (~|!,;\-/@#$%^&+=)
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }

  renderCaptcha = () => {
    return (
      <div className="g-recaptcha">
        <ReCAPTCHA
          sitekey="6Lf6218UAAAAAKc2S6yYbX2rMZu9Ien58k13uA0-"
          onChange={(v) => this.onCaptchaChange(v)}
          // eslint-disable-next-line no-return-assign
          ref={(r) => (this.CAPTCHA_REF = r)}
        />
      </div>
    );
  };

  render() {
    const loading = this.props.loaders.general;
    const {
      page,
      validation,
      finishedSignUp,
      forgotPassword,
      showAgentDetected,
    } = this.state;
    return (
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        {page ? (
          <div className="login-page">
            {loading && <div className="top-loader"></div>}
            <div className="login-left-side">
              <div className="login-left-inner">
                <img
                  className="login-logo"
                  src={"/images/login/HumanzLogo.png"}
                  onClick={() => {
                    page === "login"
                      ? (window.location.href = "https://www.humanz.com/")
                      : this.props.navigate("/login");
                  }}
                />
                <div>
                  <div style={{ marginTop: 15, height: 120 }}>
                    <div style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontSize: "175%",
                          fontWeight: 300,
                        }}
                      >
                        {page === "singup"
                          ? "Sign Up to Humanz!"
                          : page === "forgot-password" || forgotPassword
                          ? "Recover Password"
                          : "Login to Humanz!"}{" "}
                      </span>
                    </div>
                    <div style={{ fontSize: "130%", fontWeight: 300 }}>
                      For Advertisers
                    </div>
                    {validation !== null && (
                      <div style={{ marginTop: 20 }}>
                        <div
                          style={{
                            fontSize: 25,
                            color: validation
                              ? "var(--main-baby-blue-color)"
                              : "#f36528",
                          }}
                        >
                          {validation
                            ? "Successfully valid email"
                            : "Failed to valid email"}
                        </div>
                        <div>
                          {validation
                            ? ""
                            : "Please try again or contact us in support@humanz.ai"}
                        </div>
                      </div>
                    )}
                  </div>
                  {page === "forgot-password" ? (
                    <div>
                      <form
                        className="login-form"
                        onSubmit={(e) => this.sendNewPassword(e)}
                      >
                        <div className="input-row">
                          <strong className="label-title">Email</strong>
                          <div>
                            <input
                              className="input-line"
                              disabled={true}
                              type="email"
                              defaultValue={this.state.resetEmail}
                            />
                          </div>
                        </div>
                        <div
                          className="input-row"
                          style={{ position: "relative" }}
                        >
                          <strong
                            className={`label-title${
                              this.state.passError ? " error-text" : ""
                            }`}
                          >
                            New Password
                          </strong>
                          <div>
                            <input
                              className={`input-line ${
                                page === "signup" && !this.state.isValidPass
                                  ? " input-error"
                                  : ""
                              }`}
                              type="password"
                              onFocus={() =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={() =>
                                this.setState({ passwordFocus: false })
                              }
                              onChange={this.recoverySetFirst}
                              ref={(e) => {
                                this._recoverNewPassword = e;
                              }}
                            />
                          </div>
                          {this.renderPasswordValidation()}
                        </div>
                        <div className="input-row">
                          <strong
                            className={`label-title${
                              this.state.passError ? " error-text" : ""
                            }`}
                          >
                            Repeat Password
                          </strong>
                          <div>
                            <input
                              className="input-line"
                              onChange={this.recoverySetSecond}
                              type="password"
                              ref={(e) => {
                                this._recoverRePassword = e;
                              }}
                            />
                          </div>
                        </div>
                        {this.state.changePasswordError && (
                          <div
                            style={{
                              marginTop: -10,
                              color: "#f36528",
                              marginBottom: "20px",
                            }}
                          >
                            {this.state.changePasswordError}
                          </div>
                        )}
                        <div
                          style={{
                            marginTop: 20,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {this.renderCaptcha()}
                          <button
                            id="reset-password-btn"
                            disabled={loading || !this.state.isValidPass}
                            type="submit"
                            className="login-button"
                          >
                            Reset Password
                          </button>
                        </div>
                        <a
                          onClick={() => this.props.navigate("/login")}
                          style={{
                            color: "var(--main-baby-blue-color)",
                            marginLeft: 3,
                            fontSize: 16,
                          }}
                        >
                          Go to Login
                        </a>
                      </form>
                    </div>
                  ) : forgotPassword ? (
                    <form
                      className="login-form"
                      onSubmit={(e) => this.onResetPassword(e)}
                    >
                      <div className="input-row">
                        <strong className="label-title">Email</strong>
                        <div>
                          <input
                            className="input-line"
                            type="email"
                            ref={(e) => {
                              this._emailInput = e;
                            }}
                          />
                        </div>
                      </div>
                      <div id="forgot_password_label"></div>
                      {this.renderCaptcha()}
                      <div
                        style={{
                          marginTop: 20,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          disabled={loading}
                          type="submit"
                          className="login-button"
                        >
                          Send Password
                        </button>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <a
                          onClick={() => {
                            this.setState({ forgotPassword: false });
                            document.getElementById(
                              "forgot_password_label",
                            ).textContent = "";
                          }}
                          style={{
                            color: "var(--main-baby-blue-color)",
                            marginLeft: 3,
                            fontSize: 16,
                          }}
                        >
                          Go back
                        </a>
                      </div>
                    </form>
                  ) : finishedSignUp ? (
                    <div>
                      <strong className="label-title"> Finish Signup </strong>
                      <div>
                        {" "}
                        <span style={{ fontSize: "125%" }}>
                          {" "}
                          A verification email has been sent to{" "}
                          {this._emailInput ? this._emailInput.value : ""}
                        </span>{" "}
                      </div>
                    </div>
                  ) : (
                    <form
                      className="login-form"
                      onSubmit={(e) => this.onLogin(e)}
                    >
                      {page !== "signup" && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            marginBottom: 10,
                          }}
                        >
                          <GoogleLogin
                            useOneTap
                            buttonText="Sign in with Google"
                            onSuccess={this.responseGoogle}
                            onError={this.responseGoogle}
                            flow="implicit"
                          />
                          <strong style={{ marginTop: 10 }}>OR</strong>
                        </div>
                      )}
                      <div className="input-row">
                        <strong className="label-title">Email</strong>
                        <div>
                          <input
                            className="input-line"
                            type="email"
                            ref={(e) => {
                              this._emailInput = e;
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input-row"
                          style={{ position: "relative" }}
                        >
                          <strong
                            className={`label-title${
                              page === "signup" && !this.state.isValidPass
                                ? " error-text"
                                : ""
                            }`}
                          >
                            Password
                          </strong>
                          <div>
                            <input
                              className={`input-line ${
                                page === "signup" && !this.state.isValidPass
                                  ? " input-error"
                                  : ""
                              }`}
                              onFocus={() =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={() =>
                                this.setState({ passwordFocus: false })
                              }
                              onChange={this.setFirst}
                              type="password"
                              ref={(e) => {
                                this._passwordInput = e;
                              }}
                            />
                          </div>
                          {page === "signup" && this.renderPasswordValidation()}
                        </div>
                        {page === "signup" && (
                          <div
                            className="input-row"
                            style={{ position: "relative" }}
                          >
                            <strong
                              className={`label-title${
                                this.state.passError ? " error-text" : ""
                              }`}
                            >
                              Confirm Password
                            </strong>
                            <div>
                              <input
                                className={`input-line ${
                                  this.state.passError ? " input-error" : ""
                                }`}
                                onChange={this.setSecond}
                                type="password"
                                ref={(e) => {
                                  this._rePasswordInput = e;
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {this.state.signupFail && (
                        <div
                          style={{
                            marginTop: -10,
                            color: "#f36528",
                            marginBottom: "20px",
                          }}
                        >
                          {this.state.signupFail}
                        </div>
                      )}
                      <div
                        style={{ width: "100%", height: 30, marginTop: -10 }}
                      >
                        <CheckBox
                          style={{ float: "left" }}
                          checked={this.state.remmberMe}
                          onChange={() =>
                            this.setState({ remmberMe: !this.state.remmberMe })
                          }
                          label={"Remember me"}
                        />
                        <div
                          className="forgot-password"
                          onClick={() =>
                            this.setState({ forgotPassword: true })
                          }
                        >
                          Forgot Password
                        </div>
                      </div>
                      <div style={{ fontSize: 13.2, paddingLeft: 5 }}>
                        <strong>
                          By Sign in/ Sign up you accept the{" "}
                          <a href="/termsAndConditions.html" target="_blank">
                            terms & conditions
                          </a>
                        </strong>
                      </div>

                      {this.renderCaptcha()}

                      <div
                        style={{
                          marginTop: 20,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {page === "signup" && (
                          <button
                            id="SignUp"
                            disabled={loading || !this.state.isValidPass}
                            type="submit"
                            className="login-button"
                          >
                            SignUp
                          </button>
                        )}
                        {page !== "signup" && (
                          <button
                            id="Login"
                            disabled={loading}
                            type="submit"
                            className="login-button"
                          >
                            Login
                          </button>
                        )}
                      </div>
                      {page === "signup" ? (
                        <div style={{ marginTop: 20 }}>
                          <span style={{ color: "#888" }}>
                            Already have an account on Humanz?
                          </span>{" "}
                          <Link
                            to={"/login"}
                            style={{
                              color: "var(--main-baby-blue-color)",
                              marginLeft: 3,
                              fontSize: 16,
                            }}
                          >
                            Login
                          </Link>
                        </div>
                      ) : (
                        <div style={{ marginTop: 20 }}>
                          <span style={{ color: "#888" }}>
                            Don&#39;t have an account yet?
                          </span>{" "}
                          <Link
                            to={"/signup"}
                            style={{
                              color: "var(--main-baby-blue-color)",
                              marginLeft: 3,
                              fontSize: 16,
                            }}
                          >
                            Sign up
                          </Link>
                        </div>
                      )}
                      <div style={{ marginTop: 20, color: "#888" }}>
                        Are you an influencer? Sign-up via our app.{" "}
                        <a
                          href="https://play.google.com/store/apps/details?id=com.moimedia.moi"
                          style={{ color: "var(--main-baby-blue-color)" }}
                        >
                          Android
                        </a>{" "}
                        /{" "}
                        <a
                          href="itms-apps://itunes.apple.com/us/app/moimedia/id1249804084"
                          style={{ color: "var(--main-baby-blue-color)" }}
                        >
                          IOS
                        </a>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
            <div className="login-right-side"></div>
            {showAgentDetected && (
              <AgentDetectedModal
                onClose={() => this.setState({ showAgentDetected: false })}
              />
            )}
          </div>
        ) : (
          <div></div>
        )}
      </GoogleOAuthProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
    loaders: state.loadersReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logInUser: (
      email,
      password,
      captcha,
      is_presenter,
      saveEmail,
      fail_callBack,
    ) => {
      dispatch(
        logInUser(
          email,
          password,
          captcha,
          is_presenter,
          saveEmail,
          fail_callBack,
        ),
      );
    },
    signUpUser: (
      email,
      password,
      captcha,
      is_presenter,
      saveEmail,
      succ_callBack,
      fail_callBack,
    ) => {
      dispatch(
        signUpUser(
          email,
          password,
          captcha,
          is_presenter,
          saveEmail,
          succ_callBack,
          fail_callBack,
        ),
      );
    },
    socialLogin: (provider, token, profileId, user_id) => {
      dispatch(socialLogin(provider, token, profileId, user_id));
    },
    recoverPassword: (email, captcha, succ_callBack, fail_callBack) => {
      dispatch(recoverPassword(email, captcha, succ_callBack, fail_callBack));
    },
    resetPassword: (
      email,
      password,
      challenge,
      captcha,
      succ_callback,
      fail_callback,
    ) => {
      dispatch(
        resetPassword(
          email,
          password,
          challenge,
          captcha,
          succ_callback,
          fail_callback,
        ),
      );
    },
  };
};

const LoginPageConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage);

export default (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return (
    <LoginPageConnected
      {...props}
      navigate={navigate}
      params={params}
      location={location}
      searchParams={searchParams}
    />
  );
};
