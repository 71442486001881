import { PureComponent } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider from "react-slick";

interface OwnProps {
  navigate_to_lesson: any;
  current_course: any;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class AcademyCourseProgressInner extends PureComponent<Props> {
  render() {
    return (
      <>
        {this.props.current_course ? (
          this.renderChaptersBar(this.props.current_course)
        ) : (
          <></>
        )}
      </>
    );
  }

  isAvailable = (chapter: any) =>
    this.props.current_course?.current_chapter?.ordinal_num >=
    chapter.ordinal_number;

  renderChaptersBar = (course: any): any | null => {
    if (!course) return null;
    const sliderSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      draggable: false,
    };

    return (
      <div className={"course-progress"}>
        {" "}
        {/* eslint-disable @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */}
        <Slider {...sliderSettings} className="seg-posts-slider">
          {course.all_chapters.map((chapter: any, key: number) => (
            <div className={"progress-slide"} key={chapter.id}>
              <div className={"current-chapter-state "}>
                <div
                  className={`chapter-on-progress${
                    chapter.is_completed ? " completed clickable" : ""
                  }${this.isAvailable(chapter) ? " available clickable" : ""}`}
                  onClick={() =>
                    this.isAvailable(chapter)
                      ? this.props.navigate_to_lesson(
                          course.current_chapter.id,
                          1,
                        )
                      : null
                  }
                >
                  <div className="chapter-circle">{chapter.ordinal_number}</div>
                  <div className="chapter-circle-status">
                    {chapter.is_completed || this.isAvailable(chapter) ? (
                      <i
                        className={`fas fa-${
                          chapter.is_completed ? "check" : "circle"
                        }`}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <Trans>CHAPTER</Trans> {chapter.ordinal_number}
                  </div>
                  {key + 1 !==
                    this.props.current_course.all_chapters.length && (
                    <div className="after-progress">
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    current_course: state.academyReducer.current_course,
  };
};

const mapDispatchToProps = () => ({});

export const AcademyCourseProgress = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcademyCourseProgressInner);
