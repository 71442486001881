/* eslint-disable react/no-string-refs */
/* eslint-disable no-param-reassign */
import { Flex, Input } from "@chakra-ui/react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { useRef } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Toggle from "react-toggle";
import CheckBox from "src/components/feed_components/general_components/CheckBox";
import { POST_TYPE_TO_SOCIAL } from "src/components/feed_components/utilities/general";
import SliderStages from "src/components/feed_components/utilities/SliderStages";
import uuidv4 from "uuid/v4";
import AccordionUI from "../FormUI/AccordionUI";
import AddButton from "../FormUI/AddButton";
import FormCard from "../FormUI/FormCard";
import Indicator from "../FormUI/Indicator";
import IndicatorInput from "../FormUI/IndicatorInput";
import IndicatorSelect from "../FormUI/IndicatorSelect";

type Props = {
  state: any;
  setState: any;
  errorFields: any;
  payments: any;
  CURRENCIES_LIST: any;
  addOrRemoveError: any;
  renderNotesBtn: any;
  isShare: any;
  lists: any;
  isSurvey: boolean;
  editMode: boolean;
  countries: any;
  changeSocial: any;
  children?: any;
};

const BriefPayments = ({
  state,
  setState,
  errorFields,
  payments,
  renderNotesBtn,
  CURRENCIES_LIST,
  addOrRemoveError,
  isShare,
  lists,
  isSurvey,
  editMode,
  countries,
  changeSocial,
  children,
}: Props) => {
  const user = useSelector((store: any) => store.userReducer);
  const sliderRef = useRef();

  const onToggleSpecialPrice = (index: any) => {
    const allowBids = state.fields.payments.allow_bids;
    const rows = [...allowBids.special_prices];
    const newState: any = { ...state };
    if (index === null) {
      let initalMinFollowers = allowBids.followers.min;
      let initalMaxBid = allowBids.price.max;
      if (rows.length > 0) {
        const lastRow = rows[rows.length - 1];
        initalMinFollowers = lastRow.min_followers;
        initalMaxBid = lastRow.max_bid;
      }
      rows.push({
        min_followers: initalMinFollowers,
        max_bid: initalMaxBid,
        key: uuidv4(),
      });
    } else {
      rows.splice(index, 1);
      // when removing say update
      newState.updated = true;
    }
    newState.fields.payments.allow_bids.special_prices = rows;
    setState(newState);
  };

  const renderSocialCube = (networks: any, social: any) => {
    const iconClassNames: any = {
      youtube: "fa fa-youtube-play colored",
      tiktok: "fa-brands fa-tiktok colored",
      twitter: "fa fa-twitter colored",
      instagram: "fa fa-instagram colored",
    };
    const iconClassName: any = social ? iconClassNames[social] : null;
    const selected = !!(networks?.indexOf(social) > -1);
    const enabled = state.fields.tasks.task_stages.some(
      (t: any) => POST_TYPE_TO_SOCIAL[t.post_type] === social,
    );
    return (
      <Tooltip
        trigger={enabled ? [] : ["hover"]}
        overlay={`No ${social} tasks added to the brief`}
        placement="top"
      >
        <div className={`filter-social`}>
          <div
            className={`filter-social-cube ${selected} ${
              !enabled && "disabled"
            }`}
            onClick={() => changeSocial(social)}
          >
            <li className={iconClassName} />
          </div>
          <div className="checkbox" style={{ padding: 0 }}>
            <label>
              <input
                type="checkbox"
                disabled={!enabled}
                onChange={() => changeSocial(social)}
                checked={selected}
              />
              <span className="checkbox-material">
                <span className="check"></span>
              </span>
            </label>
          </div>
        </div>
      </Tooltip>
    );
  };

  const noSocialSelected =
    payments?.allow_bids?.social_networks?.indexOf("all") !== -1;
  const SpecialPrice = ({ priceOffer, index }: any) => {
    return (
      <div
        className="specialOffer"
        style={{ display: "flex", alignItems: "center", marginTop: 10 }}
        key={priceOffer.key}
      >
        <div style={{ display: "inline-block", alignItems: "center" }}>
          {/* <strong className="label-title">minimum followers</strong> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="input-title">Minimum followers</span>
            <Input
              // header="Minimum followers"
              defaultValue={priceOffer.min_followers}
              onChange={(e) => {
                priceOffer.min_followers = e.target.value;
                // setState({ updated: true });
              }}
              type="number"
              sx={{ width: "150px" }}
            />
          </div>
        </div>

        <div style={{ marginLeft: 16, display: "inline-block" }}>
          {/* <strong className="label-title">
            {state.fields?.overview.ribbon !== "survey" && "maximum"} price
          </strong> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="input-title">Maximum price</span>
            <Input
              // header={
              //   state.fields?.overview.ribbon !== "survey"
              //     ? "Maximum price"
              //     : "price"
              // }
              disabled={state.fields?.overview.ribbon === "open"}
              defaultValue={
                state.fields?.overview.ribbon === "open"
                  ? 0
                  : priceOffer.max_bid
              }
              onChange={(e) => {
                priceOffer.max_bid = e.target.value;
                // setState({ updated: true });
              }}
              type="number"
              sx={{ width: "150px" }}
            />
          </div>
        </div>
        <div
          className="moi-ximage delete-row"
          style={{ display: "inline-block" }}
          onClick={() => onToggleSpecialPrice(index)}
        />
      </div>
    );
  };

  const maxBidBlur = (e: any) => {
    if (payments.allow_bids.price.max !== 0 && !payments.allow_bids.price.max) {
      payments.allow_bids.price.max = 0;
      e.target.value = 0;
      setState({ updated: true });
    }
  };

  const changeAllowBids = () => {
    if (
      !user.userDetails.sidebar_data.is_premium &&
      user.userDetails.sidebar_data.pay_per_bid
    ) {
      return;
    }
    const current = state.fields.payments.allow_bids;
    if (current) {
      state.fields.payments.allow_bids = null;
    } else {
      state.fields.payments.allow_bids = {
        gender: [],
        followers: {
          min: 0,
          max: 0,
        },
        price: {
          min: 0,
          max: 0,
        },
        country: user.userDetails.company_details.country,
        special_prices: [],
        social_networks: ["all"],
        api_restricted: false,
      };
    }
    setState({ updated: true });
  };
  const pixelEnabled = state.fields?.sales?.sales_enabled;
  return (
    <FormCard>
      <div className="products-overview-section">
        <div className="payments-overview-section">
          <div className="create-campaign-title">
            <span>Budget & Payments</span>
            {renderNotesBtn(2)}
          </div>
          <AccordionUI header={`General`} style={{ marginTop: "24px" }}>
            <div className="campaign-fields">
              <IndicatorSelect
                header={"Currency"}
                options={CURRENCIES_LIST}
                placeholder={""}
                simpleValue
                defaultValue={payments.currency}
                clearable={false}
                onChange={(value) => {
                  if (
                    value &&
                    state.fields.sales &&
                    !state.fields.sales.currency
                  ) {
                    state.fields.sales.currency = value;
                  }
                  payments.currency = value;
                  addOrRemoveError("currency", "remove");
                  setState({ updated: true });
                }}
                name="selected-state"
                disabled={editMode}
                searchable={true}
                valueKey={"value"}
                className={"blurable-large"}
              />
              {errorFields.includes("currency") && (
                <div>
                  <span className="error-text">
                    Whoops! This field can not be empty
                  </span>
                </div>
              )}

              <Indicator
                header="Payment terms"
                description="Maximum length of time influencers should expect to wait before
                receiving payment. The days will be counted from the date the payment is allowed."
              >
                {pixelEnabled ? (
                  <div className={"alert-info"} style={{ margin: "0px" }}>
                    <i className="fa fa-info-circle" />
                    &nbsp; Payment for influencers participating in the pixel
                    campaign will be structured on a monthly basis, including
                    performance-based commission and task-based payments.
                  </div>
                ) : (
                  state.campaignMode === "monthly" && (
                    <div className={"alert-info"} style={{ margin: "0px" }}>
                      <i className="fa fa-info-circle" />
                      &nbsp; Payment for influencers participating in the
                      monthly campaign will be structured on a monthly basis.
                    </div>
                  )
                )}
                <div style={{ width: 320 }} className="searchable-input">
                  <span
                    onClick={() => {
                      payments.payments_days_delay =
                        payments.payments_days_delay || 45;
                      payments.payments_date = null;
                      setState({ updated: true });
                    }}
                    className={`label-title selectable ${
                      payments.payments_days_delay ? " selected" : ""
                    }`}
                    style={{
                      textTransform: "none",
                      padding: "8px",
                      marginRight: "8px",
                    }}
                  >
                    By number of days{" "}
                    {payments.payments_days_delay
                      ? `(${payments.payments_days_delay})`
                      : ""}
                  </span>

                  <span
                    onClick={() => {
                      payments.payments_days_delay = null;
                      payments.payments_date =
                        payments.payments_date || moment().day(45);
                      setState({ updated: true });
                    }}
                    className={`label-title selectable ${
                      !payments.payments_days_delay ? " selected" : ""
                    }`}
                    style={{ textTransform: "none", padding: "8px" }}
                  >
                    By payment date
                  </span>

                  {payments.payments_days_delay && (
                    <div style={{ marginBottom: 25 }}>
                      <SliderStages
                        domain={[5, 100]}
                        defaultValues={[payments.payments_days_delay || 45]}
                        ref={sliderRef}
                        onChange={(value: any) => {
                          payments.payments_days_delay = value.length
                            ? value[0]
                            : value;
                          payments.payments_date = null;
                          setState({ updated: true });
                        }}
                      />
                    </div>
                  )}
                  <div>
                    {payments.payments_date && (
                      <div
                        style={{
                          width: 210,
                          marginLeft: 5,
                          marginTop: 0,
                          position: "relative",
                        }}
                        className="datepicker-box"
                      >
                        <DatePicker
                          isClearable={false}
                          placeholderText="Payment date"
                          dateFormat={window.time.short_date}
                          dropdownMode={"select"}
                          minDate={moment().add(5, "days")}
                          selected={
                            payments.payments_date
                              ? moment(payments.payments_date)
                              : moment()
                          }
                          onChange={(date) => {
                            if (
                              moment(date).diff(moment().add(4, "days")) < 0
                            ) {
                              setState({ updated: true });
                              return;
                            }
                            payments.payments_date = date;
                            payments.payments_days_delay = null;
                            setState({ updated: true });
                          }}
                          className="borderless-datepicker"
                          disabled={isShare}
                        />
                        <i
                          className="fa-duotone fa-calendar-days fa-lg"
                          style={{ color: "grey" }}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
              </Indicator>
              <IndicatorInput
                header="Campaign Budget"
                description="To optimize your ROI let us know your NET
                influencers budget"
                type="number"
                optional={true}
                value={payments.media_budget || 0}
                onChange={(value) => {
                  payments.media_budget = value;
                  setState({ updated: true });
                }}
                className="input-line"
                disabled={isShare}
              />

              <IndicatorInput
                header="Influencers amount target"
                description="To optimize your ROI let us know the target amount
                of influencers that will participate in the campaign"
                placeholder={"Influencers amount target"}
                type="number"
                optional={true}
                disabled={isShare}
                value={payments.influencers_amount_target || 0}
                onChange={(value) => {
                  payments.influencers_amount_target = Math.floor(
                    Number(value),
                  ); // Ensures only whole numbers
                  setState({ updated: true });
                }}
              />
            </div>
          </AccordionUI>
          <div className="campaign-fields">
            <AccordionUI header={`Bids`}>
              <div className="campaign-fields">
                <Indicator
                  header="Allow bids"
                  description="MARKETPLACE (This campaign will be public and you will get
                influencers price quotes)"
                >
                  <div className="toggle-input">
                    <label>
                      <div className="toggle-label">
                        <div className="fa fa-times" />
                        <div className="fa fa-check" />
                      </div>
                      <Toggle
                        checked={payments.allow_bids != null}
                        icons={false}
                        onChange={() => changeAllowBids()}
                      />
                    </label>
                  </div>
                </Indicator>
                {payments.allow_bids && (
                  <>
                    {lists.length ? (
                      <>
                        <IndicatorSelect
                          header={"Restricted Access"}
                          options={lists}
                          placeholder={"Select a list"}
                          description={
                            payments.allow_bids?.restricted_list ? (
                              <div className="alert-info">
                                <i className="fa fa-info-circle" /> Only
                                influencers in the list that have the Humanz App
                                will be able to bid
                              </div>
                            ) : (
                              <span>
                                Hide this campaign in the marketplace for any
                                influencer that is not in a specific list
                              </span>
                            )
                          }
                          defaultValue={
                            payments.allow_bids.restricted_list || ""
                          }
                          clearable={true}
                          onChange={(list: any) => {
                            payments.allow_bids.restricted_list = list?.id;
                            setState({ updated: true });
                          }}
                          name="selected-state"
                          disabled={isShare}
                          searchable={true}
                          valueKey={"id"}
                          labelKey="title"
                          className={"blurable-large"}
                          optional={true}
                        />
                      </>
                    ) : null}
                    <Indicator
                      header="Who can Bid?"
                      description="Get bids from a specific gender"
                    >
                      <div style={{ display: "flex" }}>
                        <CheckBox
                          style={{}}
                          label={"Female"}
                          checked={
                            payments.allow_bids.gender.indexOf("female") > -1
                          }
                          onChange={() => {
                            if (isShare) return;
                            payments.allow_bids.gender.addOrRemove("female");
                            setState({ updated: true });
                          }}
                          labelColor="black"
                        />
                        <CheckBox
                          style={{ marginLeft: 50 }}
                          label={"male"}
                          checked={
                            payments.allow_bids.gender.indexOf("male") > -1
                          }
                          onChange={() => {
                            if (isShare) return;
                            payments.allow_bids.gender.addOrRemove("male");
                            setState({ updated: true });
                          }}
                          labelColor="black"
                        />
                      </div>
                    </Indicator>

                    {isSurvey && (
                      <>
                        <Indicator
                          header="Ages"
                          optional={true}
                          description="Age range of the influencers"
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: 10,
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <input
                                placeholder="MIN"
                                defaultValue={
                                  payments.allow_bids.age_restriction
                                    ? payments.allow_bids.age_restriction
                                        .min_age
                                    : ""
                                }
                                onChange={(e) => {
                                  if (!payments.allow_bids.age_restriction) {
                                    payments.allow_bids.age_restriction = {};
                                  }
                                  e.target.value = Math.floor(
                                    Number(e.target.value),
                                  ).toString();
                                  payments.allow_bids.age_restriction.min_age =
                                    e.target.value;

                                  addOrRemoveError("age_restriction", "remove");
                                  setState({ updated: true });
                                }}
                                className="input-box"
                                type="number"
                              />
                            </div>
                            <strong>-</strong>
                            <div>
                              <input
                                placeholder="MAX"
                                defaultValue={
                                  payments.allow_bids.age_restriction
                                    ? payments.allow_bids.age_restriction
                                        .max_age
                                    : ""
                                }
                                onChange={(e) => {
                                  if (!payments.allow_bids.age_restriction) {
                                    payments.allow_bids.age_restriction = {};
                                  }
                                  e.target.value = Math.ceil(
                                    Number(e.target.value),
                                  ).toString();
                                  payments.allow_bids.age_restriction.max_age =
                                    e.target.value;
                                  addOrRemoveError("age_restriction", "remove");
                                  setState({ updated: true });
                                }}
                                className="input-box"
                                type="number"
                              />
                            </div>
                          </div>
                          {errorFields.includes("age_restriction") && (
                            <div>
                              <span className="error-text">
                                Whoops! Bad range{" "}
                              </span>
                            </div>
                          )}
                        </Indicator>

                        <Indicator
                          header="Maximum Participants"
                          optional={false}
                          description="How many influencers can join the survey"
                        >
                          <div style={{ width: 300 }}>
                            <input
                              defaultValue={
                                payments.allow_bids.max_participants
                              }
                              onChange={(e) => {
                                payments.allow_bids.max_participants =
                                  e.target.value;
                                addOrRemoveError("max_participants", "remove");
                                setState({ updated: true });
                              }}
                              className="input-box"
                              type="number"
                            />
                          </div>
                          {errorFields.includes("max_participants") && (
                            <div>
                              <span className="error-text">
                                Whoops! You must enter max participants{" "}
                              </span>
                            </div>
                          )}
                        </Indicator>
                      </>
                    )}

                    <IndicatorSelect
                      header={"Influencers country"}
                      description={
                        "Which influencers will see your campaign - choose WorldWide for all"
                      }
                      options={countries}
                      placeholder={"You must select a country"}
                      defaultValue={payments.allow_bids.country}
                      simpleValue={true}
                      clearable={false}
                      onChange={(value) => {
                        payments.allow_bids.country = value;
                        setState({ updated: true });
                      }}
                      name="selected-state"
                      disabled={false}
                      searchable={true}
                    />

                    <Indicator
                      header="Social Networks"
                      description="Only influencers with the selected social
                          networks attached will be able to bid on the campaign"
                      optional
                    >
                      <div className="bids-social">
                        {renderSocialCube(
                          payments.allow_bids.social_networks,
                          "instagram",
                        )}
                        {user.userDetails?.sidebar_data?.can_view_tiktok
                          ? renderSocialCube(
                              payments.allow_bids.social_networks,
                              "tiktok",
                            )
                          : null}
                        {renderSocialCube(
                          payments.allow_bids.social_networks,
                          "twitter",
                        )}
                        {renderSocialCube(
                          payments.allow_bids.social_networks,
                          "youtube",
                        )}
                      </div>
                    </Indicator>

                    <Indicator
                      header="Require API Data"
                      description="By checking this only influencers who supplied
                              API data which gives automated ROI on the chosen
                              social networks will be able to bid on the
                              campaigns"
                      optional
                      disabled={noSocialSelected}
                    >
                      <Tooltip
                        trigger={noSocialSelected ? ["hover"] : []}
                        placement={"topLeft"}
                        overlay="No social network selected"
                      >
                        <div className={noSocialSelected && "disabled"}>
                          <div className="toggle-input">
                            <label>
                              <div className="toggle-label">
                                <div className="fa fa-times" />
                                <div className="fa fa-check" />
                              </div>
                              <Toggle
                                checked={payments.allow_bids?.api_restricted}
                                icons={false}
                                onChange={() => {
                                  payments.allow_bids.api_restricted =
                                    !payments.allow_bids.api_restricted;
                                  setState({ updated: true });
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </Tooltip>
                    </Indicator>
                    <Indicator
                      header="Price of BID per amount of followers"
                      description="Minimum followers required by the influencers in order to BID on the campaign "
                      style={{ width: "400px" }}
                    >
                      <Flex gap={4} sx={{ marginTop: "16px" }}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span className="input-title">Minimum followers</span>
                          <Input
                            type="number"
                            // header="Minimum followers"
                            // description="Minimum followers required by the influencers in
                            //   order to bid on the campaign"
                            value={payments.allow_bids.followers.min}
                            onChange={(e) => {
                              payments.allow_bids.followers.min =
                                e.target.value;
                              setState({ updated: true });
                            }}
                            sx={{ width: "150px" }}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span className="input-title">Maximum price</span>
                          <Input
                            type="number"
                            // header="Maximum price"
                            // description="The maximum price influencers can suggest in
                            //   order to bid on the campaign"
                            value={
                              state.fields?.overview.ribbon === "open"
                                ? 0
                                : payments.allow_bids.price.max
                            }
                            onBlur={(e) => maxBidBlur(e)}
                            onChange={(e) => {
                              payments.allow_bids.price.max = e.target.value;
                              setState({ updated: true });
                            }}
                            disabled={state.fields?.overview.ribbon === "open"}
                            sx={{ width: "150px" }}
                          />
                        </div>
                      </Flex>
                      {/* 
                    {payments.allow_bids.special_prices.length > 0 && (
                      <span
                        // className="label-title"
                        style={{
                          color: "#333333",
                          fontSize: 14,
                          marginBottom: -10,
                        }}
                      >
                        Special Limits
                      </span>
                    )} */}
                      <div style={{}}>
                        {errorFields.includes("special_prices") && (
                          <div>
                            <span className="error-text">
                              Whoops! Minimum followers amounts must be unique{" "}
                            </span>
                          </div>
                        )}
                        {payments.allow_bids.special_prices.length > 0 &&
                          payments.allow_bids.special_prices.map(
                            (x: any, index: number) => (
                              <SpecialPrice
                                priceOffer={x}
                                index={index}
                                key={index}
                              />
                            ),
                          )}
                        <AddButton
                          style={{ marginTop: "15px" }}
                          type="Limitation"
                          size="sm"
                          onClick={() => onToggleSpecialPrice(null)}
                        />
                      </div>
                    </Indicator>
                  </>
                )}
              </div>
            </AccordionUI>
          </div>
        </div>
      </div>
      {children}
    </FormCard>
  );
};

export default BriefPayments;
