// TODO - lint
/* eslint-disable default-param-last, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, import/no-default-export */
const loadersReducer = (state = {}, action) => {
  switch (action.type) {
    case "SHOW_LIST_FREQUENCIES_LOADER_FULLFILED":
      state = {
        ...state,
        frequencies: true,
      };
      break;
    case "GET_LIST_FREQUENCIES_FULLFILED":
    case "GET_LIST_FREQUENCIES_REJECTED":
      state = {
        ...state,
        frequencies: false,
      };
      break;
    case "SWITCH_HIDE_USER_FROM_LIST_FULFILLED":
    case "ACTIVE_GENERAL_LOADER":
      state = {
        ...state,
        general: true,
      };
      break;
    case "UPDATE_LIST_GENERAL_DETAILS":
    case "SWITCH_HIDE_USER_FROM_LIST_REJECTED":
    case "REMOVE_INFLUENCER_FROM_BOARD":
    case "REMOVE_INFLUENCER_FROM_BOARD_REJECTED":
    case "ADD_USERS_TO_LIST_FULFILLED":
    case "ADD_USERS_TO_LISTS_FULFILLED":
    case "ADD_USERS_TO_LISTS_REJECTED":
    case "ADD_USERS_TO_LIST_REJECTED":
    case "DEACTIVE_GENERAL_LOADER":
      state = {
        ...state,
        general: false,
      };
      break;
    default:
      break;
  }
  return state;
};
export default loadersReducer;
