// TODO - lint
/* eslint-disable default-param-last, no-param-reassign, no-param-reassign, import/no-default-export */
const reportsReducer = (
  state = {
    reports: [],
    ambassador_share_data: false,
  },
  action,
) => {
  switch (action.type) {
    case "GET_STANDALONE_REPORTS":
      state = {
        ...state,
        reports: action.payload,
      };
      break;
    case "SET_AMBASSADOR_SHARE_DETAILS":
      state = {
        ...state,
        ambassador_share_data: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};
export default reportsReducer;
