const DetailsIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.631579C0 0.282768 0.172198 0 0.384615 0H9.61539C9.8278 0 10 0.282768 10 0.631579C10 0.98039 9.8278 1.26316 9.61539 1.26316H0.384615C0.172198 1.26316 0 0.98039 0 0.631579ZM0 4C0 3.65119 0.172198 3.36842 0.384615 3.36842H9.61539C9.8278 3.36842 10 3.65119 10 4C10 4.34881 9.8278 4.63158 9.61539 4.63158H0.384615C0.172198 4.63158 0 4.34881 0 4ZM0 7.36842C0 7.01961 0.172198 6.73684 0.384615 6.73684H3.97436C4.18678 6.73684 4.35897 7.01961 4.35897 7.36842C4.35897 7.71723 4.18678 8 3.97436 8H0.384615C0.172198 8 0 7.71723 0 7.36842Z"
          fill="#CFCFCF"
        />
      </svg>
    </div>
  );
};

export default DetailsIcon;
