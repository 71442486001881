// TODO - lint
/* eslint-disable camelcase, camelcase, camelcase, camelcase, class-methods-use-this, global-require, global-require, import/no-default-export */
import MobileDetect from "mobile-detect";
import React from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const md = new MobileDetect(window.navigator.userAgent);

class OpenAppLink extends React.Component {
  constructor(props) {
    super(props);
    let app_url = "";
    if (props.params.external) {
      app_url = `app-redirect/external-link/${props.params.external}`;
    } else if (props.params.id) {
      app_url = `post/${props.params.id}`;
    } else if (this.props.searchParams.get("success")) {
      app_url = `email_validation/${
        this.props.searchParams.get("success") === "true"
      }`;
    }
    this.state = {
      app_url,
      os: null,
    };
  }

  componentDidMount() {
    const viewport = document.createElement("meta");
    viewport.setAttribute("name", "viewport");
    viewport.content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0";
    document.getElementsByTagName("head")[0].appendChild(viewport);
    this.redirect();
  }

  redirect() {
    const os = md.os();
    if (os === null) {
      window.location = "https://www.humanz.com";
    }
    if (os === "AndroidOS") {
      window.location = `humanz://${this.state.app_url}`;
    }
    if (os === "iOS") {
      window.location = `humanzai://${this.state.app_url}`;
    }
    this.setState({ os });
  }

  gotoStore = () => {
    const os = md.os();
    if (os === null) {
      window.location = "https://www.humanz.com";
    }
    if (os === "AndroidOS") {
      window.location =
        "https://play.google.com/store/apps/details?id=com.moimedia.moi";
    }
    if (os === "iOS") {
      window.location =
        "itms-apps://itunes.apple.com/us/app/moimedia/id1249804084";
    }
  };

  render() {
    return (
      <section
        className="welcome"
        id="welcome-container"
        style={{ backgroundImage: "url(/images/watermark.svg)" }}
      >
        <div className="container">
          <a
            onClick={() => this.redirect()}
            className="btn btn-primary btn-open-in-app"
          >
            Open link in Humanz app
          </a>
          <h3 style={{ marginTop: 50 }}>
            Don&#39;t have humanz app yet? Download now!
          </h3>
          <div
            style={{ marginTop: 30, flexDirection: "column" }}
            onClick={this.gotoStore}
          >
            {this.state.os === "AndroidOS" ? null : (
              <div>
                <img
                  className="CToWUd"
                  style={{ width: "60%", maxWidth: 300 }}
                  src={require("../../images/Icons-App-Store.png")}
                />
              </div>
            )}
            {this.state.os === "iOS" ? null : (
              <div>
                <img
                  className="CToWUd"
                  style={{ width: "60%", maxWidth: 300 }}
                  src={require("../../images/Icons-Google-play.png")}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default (props) => {
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  return (
    <OpenAppLink
      {...props}
      params={params}
      location={location}
      searchParams={searchParams}
    />
  );
};
