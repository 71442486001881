import { semiMode } from "../actions/commonActionsConf";

export const lang = {
  app_name: "MOI",
  main_search_label: "Search",
  nav_in_progress_label: "In Progress",
  nav_history_label: "History",
  nav_explore_label: "Explore",
  sidebar_open_auctions_label: "Open auctions",
  sidebar_closed_auctions_label: "Closed auctions",
  sidebar_total_earned_label: "Total earned",
  auction_object_max_bid_label: "Max bid",
  auction_object_min_followers_label: "Min followers",
  auction_object_current_bids_label: "Bids",
  auction_object_close_flag_label: "Closed",
  auction_object_active_flag_label: "Active",
  auction_object_pending_flag_label: "Pending",
  auction_influencer_bid_range_label: "Enter bid between",
  auction_influencer_bid_range_and_label: "and",
  auction_influencer_bid_btn: "Bid",
  auction_influencer_after_bid_ribon: "APPLIED",
  presenter_wait_proposal_tooltip: "Please create post proposal",
  presenter_wait_approval_tooltip:
    "Waiting for advertiser's review and confirmation",
  advertiser_wait_proposal_tooltip: "Waiting for influencer post proposal",
  advertiser_wait_approval_tooltip: "You have a proposal to review",
  influencer_wait_choice_tooltip: "Waiting for advertiser's choice",
  advertiser_wait_post_link_tooltip:
    "Waiting for influencer Instagram post link",
  auction_tag_label: "Filtered by:",

  new_auction_object_label: "Create new auction",
  edit_auction_object_label: "Edit Auction",

  new_auction_object_title_label: "Campign title",
  new_auction_object_description_label: "Campaign description",

  new_auction_object_tags_label: "Tags",
  new_auction_object_min_followewrs_label: "Minimum followers",
  new_auction_object_max_price_label: "Maximum price",
  new_auction_object_get_product_label: "How to get the product",
  new_auction_object_image_upload_label: "Upload a photo",
  new_auction_object_file_upload_label: "Upload a brief",
  new_auction_object_add_btn_label: "Create",
  new_auction_suggestion_label: "Our suggestion",
  edit_auction_save_button: "Save",
  new_auction_object_cancel_btn_label: "Cancel",

  new_auction_object_title_placeholder:
    "Capture Influencers with cool auction title",
  new_auction_object_description_placeholder:
    "Describe how you want the post to look",
  new_auction_object_tags_placeholder: "Add #",
  new_auction_object_min_followers_tooltip:
    "This field determines which influencers will be notified on your auction",
  new_auction_object_get_product_placeholder:
    "Link to website / Pickup details",

  influencer_bidded_label: "Bid",
  influencer_age_label: "Age",
  influencer_adress_label: "Address",
  influencer_top5_tags_label: "Top 5 tags",
  influencer_followers_label: "Followers",
  influencer_avg_likes_label: "Average likes",
  influencer_avg_engagemnt_label: "Average engagement",

  select_influencer_dialog_title: "Influencer info",
  select_influencer_dialog_select_btn: "Select influencer",
  select_influencer_dialog_graph_likes_label: "Likes",
  select_influencer_dialog_graph_comments_label: "Comments",
  select_influencer_dialog_graph_posts_label: "Posts",
  select_influencer_dialog_influencer_statistics_label: "Influencer analytics",
  select_influencer_dialog_followers_statistics_label: "Followers analytics",
  select_influencer_dialog_back_to_details_btn: "Back to influencer details",
  select_influencer_dialog_final_inst_label: "Final instructions to -",
  select_influencer_dialog_instructions_label: "Remarks",
  select_influencer_dialog_instructions_placeholder:
    "Optional - Write instructions to the influencer",
  select_influencer_dialog_contact_details_label: "Contact details",
  select_influencer_dialog_contact_details_placeholder:
    "Write your updated contact details",
  select_influencer_dialog_choose_and_send_btn: "Choose and send to Influencer",
  influencer_chosen_ribbon_label: "Chosen",

  choose_influencers_window_title: "Manage relevant influencers",
  chosen_influencers_tree_title: "Chosen influencers",
  bidded_influencers_tree_title: "Bidded influencers",
  new_proposal_advertiser_review_label: "You have new proposals",
  new_proposal_advertiser_review_btn: "Review them",
  new_proposal_advertiser_review_single_btn: "Review",
  proposal_decline_label: "What went wrong",
  proposal_advertiser_review_title: "add",
  proposal_advertiser_review_confirm_btn: "Confirm",
  proposal_advertiser_review_decline_btn: "Decline",
  proposal_advertiser_review_modify_btn: "Modify",
  proposal_advertiser_review_send_changes_btn: "send",
  proposal_advertiser_review_send_changes_placeholder:
    "Please enter here comments about this post",
  proposal_influencer_use_auction_image_btn: "Use auction image",
  proposal_influencer_download_image_btn2: "Use & download auction image",
  proposal_influencer_description_placeholder:
    "Enter the description you would upload with the picture",

  influencer_bidded_floating_label: "Bidded",
  create_post_dialog_titel_label: "Create post & Send to confirmation",
  confirm_post_dialog_titel_label: "Confirm post",
  use_auction_image_btn: "Use auction image",
  download_image_btn: "Download",

  edit_details_form_title_label: "Edit details",
  edit_details_form_username_label: "User name",
  edit_details_form_fullname_label: "Full name",
  edit_details_form_adress_label: "Address",
  edit_details_form_aboutyou_label: "About you",
  edit_details_form_oldpassword_label: "Old password",
  edit_details_form_newpassword_label: "New password",
  edit_details_form_companyname_label: "Company",
  edit_details_form_contactname_label: "Contact",
  edit_details_form_contactphone_label: "Phone#",
  edit_details_form_companyadress_label: "Company address",
  edit_details_form_country_label: "Country",
  edit_details_form_categories_label: "Categories",
  edit_details_form_description_label: "About us",
  edit_details_form_birthdate_label: "Birth Date",
  save_btn: "save",
  influencer_status_label: "Status",
  influencer_link_label: "View post on Instagram",

  settings_label: "Settings",
  Notifications_label: "Notifications",
  Notifications_show_more_label: "Show more",
  settings_home_page_label: "Home",
  settings_edit_profile_label: "Edit details",
  settings_logout_label: "Logout",

  login_selection_page_slogan_label: "The new way to advertise on Instagram",
  login_selection_page_ImAdvertiser_label: "I'm an Advertiser",
  login_selection_page_ImInfluencer_label: "I'm an Influencer",
  login_Advertiser_loginwith_label: "Login with",
  login_Advertiser_or_label: "Or",
  login_Advertiser_email_placeholder: "Email",
  login_Advertiser_password_placeholder: "Password",
  login_Advertiser_passwordconfirm_placeholder: "Password confirmation",
  login_Advertiser_passwords_dontmatch_label:
    "The passwords are not the same! please try again",
  login_Advertiser_login_btn: "Login",
  login_Advertiser_signup_btn: "Sign Up",
  login_Advertiser_donthave_account_label: "Don't have an account?",
  login_Advertiser_alreadyhave_account_label: "Already have an account?",

  system_notifications_success: "Success!",
  system_notifications_info: "Info",
  system_notifications_failure: "Failed",
  system_notifications_success_new_auction: "The campaign created Successfully",
  system_notifications_failure_new_auction: "Failed to create auction",
  system_notifications_success_edit_auction: "The campaign was edited",
  system_notifications_failure_edit_auction: "Failed to edit auction",
  system_notifications_success_influencer_chosen: "Choice has been accepted",
  system_notifications_failure_influencer_chosen: "Failed to choose influencer",
  system_notifications_success_proposal_sent: "Sent proposal for confirmation",
  system_notifications_failure_proposal_sent: "Didn't send proposal",
  system_notifications_success_review_for_post_sent: "Review sent",
  system_notifications_failure_review_for_post_sent: "Failed to send review",
  system_notifications_failure_get_chats: "Failed to get chats",
  system_notifications_success_details_edit: "Details updated successfully",
  system_notifications_failed_details_edit: "Failed to edit details",
  system_dialog_not_allowed_create_post_confirm: "Edit details",
  system_dialog_not_allowed_create_post_cancel: "Dissmis",
  system_dialog_not_allowed_create_post_title:
    "Just a minute, did you fill in your details first?",
  system_dialog_not_allowed_create_post_desc:
    "In order to create a new auction, you need to fill in your details",
  action_dialog_cancel: "Cancel",
  action_dialog_confirm: "Confirm",
  auction_edit_dialog_title: "Are you sure you want to edit?",
  auction_edit_dialog_desc: "Editing the auction will delete all the bids!",
  system_dialog_not_allowed_bid_title: "Please fill your details",
  system_dialog_not_allowed_bid_desc:
    "In order to place a bid , you need to fill in your details first",
  system_dialog_not_allowed_bid_cancel: "Dismiss",
  system_dialog_not_allowed_bid_confirm: "Edit details",
  system_loading_label: "Loading...",
  system_notifications_success_contact_mail:
    "Thank you, we will contact you soon",
  system_notifications_failed_contact_mail:
    "We currently having problem ,feel free to contact support@humanz.ai",
  system_notifications_failure_fill_contact_details:
    "Please fill all contact details",

  days_of_week_saturday: "Saturday",
  days_of_week_sunday: "Sunday",
  days_of_week_monday: "Monday",
  days_of_week_tuesday: "Tuesday",
  days_of_week_wednesday: "Wednesday",
  days_of_week_thursday: "Thursday",
  days_of_week_friday: "Friday",

  ended_label: "Ended",
  left_label: "Left",
  more_then_week_label: "More than a week ago",
  today_label: "Today",

  money_symbol_shekel: "₪",
  money_symbol_NIS: "₪",
  money_symbol_USD: "$",
  money_symbol_NZD: "$",
  money_symbol_AUD: "$",
  money_symbol_EUR: "€",
  money_symbol_ZAR: "R",
  money_symbol_GBP: "£",
  money_symbol_TRY: "₺",
  money_symbol_NGN: "₦",
  money_symbol_BRL: "R$",
  money_symbol_KES: "KSh",
  money_symbol_INR: "₹",
  money_symbol_UL: "U",

  notif_new_auction_label: "Your campaign is live!",
  notif_new_bid_label: "{0} just bid on your auction",
  notif_new_proposal_from_inf: "{0} sent you a new proposal for review",
  notif_positive_feedback:
    "The advertiser approved your proposal, please upload the post to instagram, and add post-link to auction",
  notif_feedback_with_remark:
    "The advertiser turned down your proposal. Review the notes",
  notif_bid_deleted:
    "Advertiser edited the auction, your bid deleted, please review auction and bid again",
  notif_selected_for_campaign:
    "The advertiser has selected your bid for the advertising campaign!",
  notif_link_added: "{0} The influencer has added a link",
  notif_accepted_task_invite: "{0} accepted the new task",
  notif_declined_task_invite: "{0} declined the new task",
  notif_negotiation_task_invite: "{0} changed task price",
  notif_selected_for_campaign_express:
    '{0} chose you for an "Express" campaign',
  notif_accepted_influencer_shared_list: "{0} was accepted on shared list",
  notif_rejected_influencer_shared_list: "{0} was rejected on shared list",
  save_me_label: "Remember me",

  top_cities_label: "Posts amount",

  sidebar_money_left_label: "My budget",
  ribbon_text__label: "Applied",
  ribbon_text_wait_proposal_label: "Propose Post",
  ribbon_text_wait_approval_label: "Propose sent",
  ribbon_text_accepted_proposal_label: "Attach Link",
  ribbon_text_post_linked_label: "Done",

  recvoer_password_label: "Recover Password",
  new_password_label: "New password",
  change_password_button_label: "Change password",
  recover_failed_label:
    "Reset password failed. Please check your details and try again",
  recover_succsses_label: "The password changed!",
  forgot_password_label: "Forgot your password?",
  back_to_login_label: "Back to login",
  recover_password_label: "Recover password",
  recover_mail_sent_label: "Recover mail sent successfully",
  recover_mail_failed_sent_label:
    "Failed to sent the mail, check your mail address and try again",
  failed_happened:
    "An error has occurred. Please contact us at support@humanz.ai",
  pricing_error_label: "Price must be alteast 100₪",

  product_include_error_label:
    "Please enter product price and product description",

  new_instractions_influncer_name: "Selected influencer",
  express_option_label: "Make it Express",
  what_is_express: "What is Express?",
  express_help_description:
    "When enabled, the influencers will create the post and upload it to their account ASAP. When disabled, influencers have to get your confirmation before uploading.",
  start_conv_advertiser: "Here you can chat and set up advertisement details",
  start_conv_influencer: "Here you can chat and set up advertisement details",
  bank_account_details: "Bank account details",
  full_name_bank_details_label: "Full name",
  id_bank_details_label: "ID",
  bank_name_bank_details_label: "Bank name",
  branch_name_bank_details_label: "Branch number",
  account_number_bank_details_label: "Account number",
  add_bank_account_button: "Add account",
  delete_bank_account_button: "Delete account",
  add_bank_accout_link_label: "Add bank account",
  delete_bank_accout_link_label: "Delete bank account",
  no_bank_attached_title: "Missing details",
  no_bank_attached_description:
    "In order to upload the post and get the money you have to add your bank account details first",
  view_your_post: "Your proposal",
  about_us_title_label: "Who We Are",
  about_us_first_paragraph_text:
    "Companies all over the world are using the influencer marketing method to advertise their products / services. As a brand you need to have a strong presence in the social media (Instagram, Facebook etc'). In 2016, influencer marketing was picked as the most efficient way to keep & build your social media presence by most businesses. It's the new generation of by word of mouth method that proved to be the best in all times, only here its an authentic content, transferred to many devoted followers online.",
  about_us_second_paragraph_text:
    "We provide the smart platform for advertisers (brands) and influencers to connect and work together. With special followers segmentation you can target your advertising, instead of wasting time & money for no reason. Everyone can be an influencer with MOI, the more followers you have the more money you can make. Join now and get to work!",

  termsLabel: "By logging in you accept the",
  termsLabelEnd: "terms and conditions",
  children_label: semiMode ? "Others" : "Under 14",
  teens_label: semiMode ? "Gen Z (13-18)" : "Gen Z (14-18)",
  adults_label: semiMode ? "Gen Y (19-35)" : "Gen Y (19-35)",
  elderly_label: semiMode ? "Gen X(35+)" : "Gen X(35+)",
  followers_male_label: "Male",
  followers_female_label: "Female",
  followers_ages: "Ages",
  followers_locations: "Followers locations",
  email_valid: "Successfully validate email. You are know able able to login",
  go_to_login: "Go to login page",
  email_already_valid:
    "Your email already validated. If it wasnt you please conntact us at support@humanz.ai",
  cant_valid_email:
    "Failed to valid the email. Please contact us at support@humanz.ai",
  login_fail_error: "Failed to login, please check your details and try again",
  succ_signup: "Signup successfully, please check your email for confirmation",
  waiting_for_email_confirmation:
    "Failed to login, waiting for you to verify the email that sent to you",
  about_me_influencer_statistics: "About me",
  followers_countries_location: "Top countries",
  followers_cities_location: "Top cities",

  login_selection_page_Advertiser_Contact_label: "Advertiser? Contact us",
  rating_label: "Rating",
  rate_influencer_title: "Rate the influencer",
  rate_the_influencer_placeholder:
    "Tell us what you think about the influencer",
  rate_the_influencer_label: "Leave feedback",
  feedbacks_title: "Feedbacks",
  system_notifications_rating_falure:
    "Failed to send feeback, please try again later",
  calced_with_tax_explanation:
    "Pay attention\nThe final price you'll pay after choosing an influencer includes commission({0}{1}) and VAT\n The final price will be :",
  price_description: "Price Breakdown",
  price_description_commission: "Handling fee",
  price_description_bid: "Bid",
  price_description_vat: "VAT",
  price_description_total: "Total",
  price_description_bid_discount: "Coupon Discount",
  confirm_pay_with_paypal_label: "Confirm and pay with",

  followers_range_label: "Followers range",
  price_range_label: "Bids range",
  age_range_label: "Influencer's Ages range",
  done_campaigns_label: "Done",
  hidden_label: "Hidden",
  vip_users_label: "V.I.P Users",

  step_inf_chosen_label: "Chosen",
  step_inf_sent_proposal_label: "Sent proposal",
  step_adv_sent_proposal_label: "received proposal",
  step_sent_response_label: "Proposal approved",
  step_inf_linked_label: "Link attached",

  money_spent_label: "Invested",
  comments_label: "Comments",
  likes_label: "Likes",
  roi_total_label: "Summary",
  roi_influencers_label: "Influencers",
  roi_total_followers_label: "Total Followers",
  roi_engagement_percentage_label: "Engagement",
  coupon_label: "Coupon",
  vip_member_desc:
    "Congratulations! You are now a GOLD member of MOI !\nGold members can place a bid that is 4 times higher for each campaign.\nSo, what are you waiting for?",
  roi_title: "ROI",
  no_roi_data: "There is no enough data. Please try later",

  system_message_bid_on_auction_adv: "bid received",
  system_message_bid_on_auction_inf: "bid on campaign",
  system_message_selected_for_campaign_adv: "you chose for the campaign",
  system_message_selected_for_campaign_inf: "you were chosen for the campaign",
  system_message_selected_for_express_campaign_adv:
    "you chose for the express campaign",
  system_message_selected_for_express_campaign_inf:
    "you were chosen for the express campign",
  system_message_proposal_created_adv: "post proposal recieved",
  system_message_proposal_created_inf: "proposal created",
  system_message_proposal_approved_adv: "you approved the proposal",
  system_message_proposal_approved_inf: "your proposal was accepted",
  system_message_proposal_declined_adv: "you declined the proposal",
  system_message_proposal_declined_inf: "your proposal was declined",
  system_message_post_linked_adv: "post attached",
  system_message_post_linked_inf: "post attached",

  sort_influencers_title: "Sort by (Followers)",
  sort_followers_ages_placeholder: "Select age group",
  sort_followers_ages_label: "Sort by follower's ages ",
  sort_by_males_label: "Sort by males",
  sort_by_females_label: "Sort by females",
  children_sort_label: "Children",
  teens_sort_label: "Teens",
  adults_sort_label: "Adults",
  elderly_sort_label: "Elderlies",
  filter_influencer_title: "Filter by influencer data",
  filter_followers_title: "Filter by influencer's followers data",

  new_auction_product_description: "Product name / sort product description",
  apply_to_auction: "I would like to participate",
  product_only_label: "Product value",
  auction_object_product_description: "Product description",

  influencer_phone_label: "Phone #",
  influencer_email_label: "Email",

  new_campaign_title: "New campaign",
  new_auction_object_hashtags_label: "Instagram posts hashtag #",
  new_auction_campaign_details_title: "Campaign Details",
  new_auction_advanced_details_title: "Advance details",
  new_auction_object_mention_label: "Instagram mention",
  new_auction_object_influencer_gender_label: "Influencer gender",
  new_auction_object_influencer_gender_desc:
    "Only influencer's from the selected gender will be able to apply to the campaign",
  new_auction_object_instructions_label: "What to do",
  new_auction_object_instructions_placeholder:
    "Describe to the influencer what to do after you select one to the campaign",

  campaign_step_description: "Start",
  campaign_step_target: "Targeting",
  campaign_step_exposure: "Pricing ",
  campaign_step_submit: "Submit",
  "new-auction-next-step": "Next",
  "new-auction-back-step": "Back",

  new_auction_target_followers_title: "Target post audience",
  new_auction_object_audience_gender_label: "Audience Gender",
  new_auction_object_audience_gender_desc:
    "What most of the followers' gender should be",
  new_auction_object_categories_label: "Audience's interests",

  new_auction_object_campaign_times_label: "Campaign bidding time",
  new_auction_object_campaign_times_desc:
    "After this time period new influencer will no longer be able to bid",

  edit_campaign_title: "Edit campaign",

  wait_approval_column: "Wait approval",
  wait_influencer_column: "Wait influencer",
  work_in_progress_column: "Work in progress",
  done_column: "Done",

  notif_invited_for_campaign: "You have been invited to a new campaign by {0}",
  notif_accepted_campaign_invite: "Your campaign offer was accepted by {0}",
  notif_rejected_campaign_invite: "Your campaign offer was rejected by {0}",
  notif_negotiation_campaign_invite:
    "{0} sent you a new offer for the campaign",
  notif_accepted_campaign_negotiation:
    "{0} accepted your participation in the campaign ",
  notif_rejected_campaign_negotiation:
    "{0} rejected your participation in the campaign",

  "roi-engament-label": "Engagement Statistics",
  "roi-followers_ages": "Ages",
  "roi-followers_countries_location": "Countries",
  followers_categories: "Categories",
  "roi-followers_cities_location": "Cities",
  "roi-clicks-statistics-label": "Clicks Statistics",
  "roi-audience_statistics": "Audience Statistics",

  new_auction_clicks_details_title: "Campaign Link",
  new_auction_object_link_label: "Short link",
  new_auction_object_link_description: "Add your campaign link",
  new_auction_object_redirect_label: "Redirect to",
  new_auction_object_link_placeholder: "Company / Campaign short Name ",
  new_auction_object_redirect_placeholder: "Your website url",
  system_notifications_failure_pay:
    "Fail to pay to influencer, please contact us",
  notif_rejected_proposal_shared_campaign:
    "{0} poropsal has remarks on shared campaign",
  notif_accepted_proposal_shared_campaign:
    "{0} poropsal was accepted on shared campaign",

  bad_link: "Something wrong with the link. Please check it and try again.",
  already_attached: "This link is already attached in this campaign ",
  system_notifications_success_invoice_attached: "Invoice attached",
  system_notifications_failure_invoice_attached: "Invoice not attached",

  collaborate_new_match: "collaborate new match",
  collaborate_someone_liked_you: "collaborate someone liked you",
  deleted_message: "deleted message",
  feed_like_on_my_post: "feed like on my post",
  feed_comment_on_my_post: "feed comment on my post",
  feed_like_on_my_comment: "feed like on my comment",
  feed_comment_on_my_comment: "feed comment on my comment",
  feed_mentioned_you_on_a_comment: "feed mentioned you on a comment",
  feed_your_post_rejected: "feed your post rejected",
  feed_your_post_approved: "feed your post approved",
  user_endorsed_your_profile: "user endorsed your profile",
  users_watched_your_profile: "users watched your profile",
  feed_mentioned_you_on_a_post: "feed mentioned you on a post",
  waiting_multiple_notifications: "waiting multiple notifications",
  notif_task_reminder: "task reminder",
  notif_message_share: "You have a new note on your shared item - {0}",

  ACCEPTED_TASKS_OFFER: "{0} Accepted your changes offer in campaign {1}.",
  DECLINED_TASKS_OFFER:
    "{0} Declined your changes offer in campaign {1}. The tasks will remain as they were before.",
};
