import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { Moment } from "moment";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RequestError } from "src/api/config";
// eslint-disable-next-line import-access/jsdoc
import { useGetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id } from "src/api/hooks";
// eslint-disable-next-line import-access/jsdoc
import { getMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id } from "src/api/services";
import { useBoolean } from "src/hooks/coreHooks";

export const useFbStories = (
  {
    influencerId,
    since,
    until,
    caption,
  }: {
    influencerId: number;
    since: Moment;
    until: Moment;
    caption: string;
  },
  options?: { enabled?: boolean },
) => {
  const params = useParams();
  const [isRefreshing, setIsRefreshing] = useBoolean(false);
  const auctionId = Number(params.id);

  const { key } =
    useGetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.info(
      influencerId,
      auctionId,
      {
        since: since.toISOString(),
        until: until.toISOString(),
        caption,
      },
    );

  const res = useInfiniteQuery({
    queryKey: key,
    queryFn: ({ pageParam }) => {
      const ret =
        useGetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id
          .info(influencerId, auctionId, {
            refresh: isRefreshing,
            since: since.toISOString(),
            until: until.toISOString(),
            last_seen: pageParam ? String(pageParam) : null,
            caption,
          })
          .fun();
      return ret;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage: any) => {
      return lastPage.data.length === 0
        ? undefined
        : lastPage.data[lastPage.data.length - 1].media_post_time;
    },
    enabled: options?.enabled ?? true,
  });

  const queryClient = useQueryClient();

  const refresh = useCallback(() => {
    setIsRefreshing.on();
    queryClient.removeQueries({
      queryKey: [
        getMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.key,
      ],
    });
  }, [queryClient, setIsRefreshing]);

  useEffect(() => {
    if (isRefreshing && res.data) {
      setIsRefreshing.off();
    }
  }, [isRefreshing, res.data, setIsRefreshing]);

  return {
    ...res,
    isMissingPermissions:
      (res.error as RequestError | undefined)?.status === 412,
    data: res.data?.pages.map((x) => x.data).flat(),
    refresh,
    isRefreshing,
  };
};
