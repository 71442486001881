import { Button, Spinner } from "@chakra-ui/react";
import Tooltip from "rc-tooltip";
import React, { useEffect, useState } from "react";
import Infinite from "react-infinite";
import { toast } from "react-toastify";
import {
  deleteShadowInfluencer,
  getShadowInfluencers,
} from "src/api/actions/listActions";
import { ShadowInfluencerWithId } from "src/api/types";
import NoImage from "src/images/svgs/placeholder.svg";
import { MessageDialog } from "../dialogs/MessageDialog";
import { CreateExternalInfluencer } from "./CreateExternalInfluencer";

interface OwnProps {
  openAddToList: any;
}

export const ExternalInfluencersPage: React.FC<OwnProps> = (props) => {
  const [data, setData] = useState([] as ShadowInfluencerWithId[]);
  const [influToDelete, setInfluToDelete] = useState(null as number);
  const [loading, setLoading] = useState(true as boolean);
  const [editInfluencer, setEditInfluencer] = useState(
    null as ShadowInfluencerWithId,
  );

  const deleteInfluencer = () => {
    setLoading(true);
    deleteShadowInfluencer(influToDelete)
      .then(() => {
        setData(
          data.filter(
            (i: ShadowInfluencerWithId) => i.influencer_id !== influToDelete,
          ),
        );
      })
      .catch(() => {
        toast.error("Failed to delete influencer");
      })
      .finally(() => {
        setLoading(false);
        setInfluToDelete(null);
      });
  };

  const deleteInfluDialog = () => {
    return (
      !!influToDelete && (
        <MessageDialog
          handleClose={() => (loading ? false : setInfluToDelete(null))}
          icon="moi-trash"
        >
          <div style={{ textAlign: "center", padding: 20 }}>
            <strong>Notice</strong>
            <p style={{ marginTop: 15 }}>
              This Influencer will not be removed from active campaigns or
              lists. You won&apos;t be able to change it&apos;s information.{" "}
              <br /> Are you sure you want to <strong>delete</strong> this
              external influencer?
            </p>
            <Button
              variant={"outline"}
              onClick={() => setInfluToDelete(null)}
              isDisabled={loading}
              isLoading={loading}
              className={`btn btn-primary cancel ${loading ? "disable" : ""}`}
              style={{
                borderRadius: "40px",
                width: 100,
                marginTop: 15,
                marginRight: 30,
              }}
            >
              NO
            </Button>
            <Button
              onClick={deleteInfluencer}
              isDisabled={loading}
              isLoading={loading}
              className={`btn btn-primary ${loading ? "disable" : ""}`}
              style={{
                background: "var(--main-baby-blue-color)",
                color: "white",
                borderRadius: "40px",
                width: 100,
                marginTop: 15,
              }}
            >
              Yes
            </Button>
          </div>
        </MessageDialog>
      )
    );
  };

  const renderInflu = (influ: ShadowInfluencerWithId, openAddToList: any) => {
    return (
      <div
        key={influ.influencer_id}
        className="lists-influencer-details"
        style={{ marginBottom: 10 }}
      >
        {/* Left Side */}
        <div className="lists-influencer-left">
          <div>
            <img
              className="blurable"
              src={influ.image ? influ.image : NoImage}
            />
          </div>
        </div>
        <div className="list-influencer-details-first">
          <strong className="blurable">{influ.name}</strong>
        </div>
        <div className="influencer-note">{influ.note}</div>
        <div className="list-influencer-actions">
          <div className="flex">
            <Tooltip
              overlay={"Add to list"}
              trigger={["hover"]}
              placement="top"
            >
              <div>
                <i
                  className="moi-add-list"
                  style={{ display: "table-cell" }}
                  onClick={() => {
                    openAddToList([{ ...influ, id: influ.influencer_id }]);
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip overlay={"Edit"} trigger={["hover"]} placement="top">
              <div>
                <i
                  className="fas fa-pencil"
                  style={{ display: "table-cell" }}
                  onClick={() => {
                    setEditInfluencer(influ);
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip overlay={"Delete"} trigger={["hover"]} placement="top">
              <div>
                <i
                  className="far fa-trash"
                  style={{ display: "table-cell" }}
                  onClick={() => {
                    setInfluToDelete(influ.influencer_id);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  const init = () => {
    setLoading(true);
    getShadowInfluencers()
      .then((res: any) => {
        setData(res.data || []);
      })
      .catch(() => {
        setData([]);
        toast.error("Failed to retrive data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    init();
  }, []);

  if (editInfluencer) {
    return (
      <div className={"main-lists-container "}>
        <CreateExternalInfluencer
          onBack={(refresh: boolean) => {
            setEditInfluencer(null);
            if (refresh) {
              init();
            }
          }}
          existingInfluencer={editInfluencer}
        />
      </div>
    );
  }
  return (
    <div className={"main-lists-container "}>
      <div className="list-title" style={{ marginTop: 0 }}>
        <span>External Influencers</span>
        <div
          className={"create-buttons-row pull-right "}
          style={{ padding: "2.5px 0" }}
        >
          <button
            onClick={() => {
              setEditInfluencer({});
            }}
            className={"btn btn-primry"}
          >
            <span
              className="moi-plus"
              style={{
                color: "var(--main-baby-blue-color)",
                fontSize: 11,
              }}
            />{" "}
            Add new
          </button>
        </div>
      </div>
      {influToDelete && deleteInfluDialog()}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Infinite
            elementHeight={95}
            className="campaign-influencers-table-container"
            useWindowAsScrollContainer={true}
            infiniteLoadBeginEdgeOffset={300}
            //   onInfiniteLoad={props.listsFetchMore}
            loadingSpinnerDelegate={
              loading ? (
                <div className="infinite-list-item">
                  <div
                    className="spinner small"
                    style={{ marginTop: 10, marginBottom: 0 }}
                  >
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                  </div>
                </div>
              ) : null
            }
            isInfiniteLoading={loading}
          >
            {(data ?? []).map((influ: any) =>
              renderInflu(influ, props.openAddToList),
            )}
          </Infinite>
          <div style={{ height: 40 }} />
        </>
      )}
    </div>
  );
};
