import React from "react";

// eslint-disable-next-line import/no-default-export
export default class TipaltiAgentSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  render() {
    return (
      <section className="welcome" id="youtube-login">
        <div className="youtube-login-container">
          <div onClick={this.jumpToApp}>
            <img
              style={{ width: 300, height: 300 }}
              src={"/images/humanz-logo.png"}
            />
            <div
              style={{
                fontSize: 30,
                marginTop: 100,
                borderRadius: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              Success! Your details are now updated. You can close this page.
            </div>
          </div>
        </div>
      </section>
    );
  }
}
