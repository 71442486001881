// TODO - lint
/* eslint-disable no-unused-vars, import/extensions, import/extensions, global-require, no-restricted-syntax, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, vars-on-top, no-var, block-scoped-var, block-scoped-var, vars-on-top, no-var, no-redeclare, block-scoped-var, block-scoped-var, block-scoped-var, block-scoped-var, block-scoped-var, block-scoped-var, no-underscore-dangle, react/no-direct-mutation-state, no-unused-vars, react/no-direct-mutation-state, class-methods-use-this, no-empty, array-callback-return, prefer-destructuring, no-unused-vars, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, consistent-return, eqeqeq, no-unused-vars, react/no-string-refs, react/jsx-no-duplicate-props, no-shadow, no-return-assign, no-underscore-dangle, no-plusplus, no-plusplus, no-shadow, class-methods-use-this, no-unused-vars, no-unused-vars, no-unused-vars, react/jsx-no-duplicate-props, no-nested-ternary, no-nested-ternary, no-nested-ternary, no-nested-ternary, consistent-return, camelcase, camelcase, import/no-default-export */
// General Imports
import React from "react";
import Infinite from "react-infinite";
import { connect } from "react-redux";
import Select from "react-select";
import { semiMode, taggerUserId } from "../../../actions/commonActionsConf";
// Actions imports
import {
  changeAgeGroup,
  changeCategories,
  changeCountry,
  changeGender,
  changeUserConnection,
  filterCities,
  removeSocialConnection,
  searchInfluencers,
  setAsPage,
} from "../../../actions/premiumActions";
import { getRecentViewedProfiles } from "../../../actions/recentProfileActions";
import { FreeTrialDialog } from "../dialogs/FreeTrialDialog";
import { MessageDialog } from "../dialogs/MessageDialog";
import CheckBox from "../general_components/CheckBox";
// Components Import
import { formatBigNumbers } from "../utilities/general";
import SearchFilter from "./SearchFilter";
import { SearchLoader } from "./SearchLoader";
import SearchToolBar from "./SearchToolBar";
import UserNotFound from "./UserNotFound";

// consts
var influencerCategories = {
  name: "influencer_categories_social",
  label: "Categories",
  type: "checkbox",
  selected: [],
  isOpen: false,
  isMulti: true,
  labels: [
    "Art",
    "Beauty",
    "Bloggers",
    "Books",
    "Coaching",
    "Family",
    "Fashion",
    "Finance",
    "Fitness",
    "Food & Drink",
    "Funny",
    "Gaming",
    "Health",
    "Home&Garden",
    "Lifestyle",
    "Motherhood",
    "Music",
    "News&TV",
    "Nightlife",
    "Pets",
    "Photography",
    "Science",
    "Soldiers",
    "Sports",
    "Tech",
    "Travel",
    "Vegan",
  ],
  values: [
    "art",
    "beauty",
    "bloggers",
    "books",
    "coaching",
    "family",
    "fashion",
    "finance",
    "fitness",
    "food",
    "funny",
    "gaming",
    "health",
    "home",
    "lifestyle",
    "motherhood",
    "music",
    "news",
    "nightlife",
    "photography",
    "science",
    "soldiers",
    "sports",
    "tech",
    "travel",
    "vegan",
  ],
  row: "influencer",
};

const DEFAULT_SORT_OPTIONS = ["followers", "engagements", "score"];

class InfluencersSearch extends React.Component {
  constructor(props) {
    super(props);
    const showTagger = this.props.user.id === taggerUserId;
    this.state = {
      loadingData: true,
      influnecersToList: [],
      selected: [],
      filterActive: false,
      sortedBy: "",
      view: showTagger ? "rows" : "cubes",
      overCube: -1,
      showFreeTrial: true,
      showTagger,
      isPagingLoading: false,
      citiesOptions: [],
      searchByUsername: null,
      recentMode: false,
    };

    this.sortOptions = {
      order: null,
      selectedValue: "",
      selectedSocial: "",
      data: [],
      socials: [],
      manual: false,
    };

    this.countries = require("../utilities/countries.json");
    this.countries = this.countries.data;

    this.sideSearchRef = React.createRef();
  }

  /* ###### Functions ###### */

  toggleFilterMenu = () => {
    this.sideSearchRef.current.toggleFilterMenu(() => {
      this.closeAddToList();
      this.props.closeInfluencerSegmentations();
    });
  };

  resultsCallback(newSearch) {
    if (newSearch) this.setState({ loadingData: false });
    else this.setState({ isPagingLoading: false });
  }

  setLoadingData = (value) => {
    this.setState({ loadingData: value });
  };

  valueCityChange = (inputValue) => {
    if (inputValue) {
      this.props.filterCities(inputValue, (data) => {
        const results = [];
        for (const city of data) {
          results.push({ label: city, value: city });
        }
        this.setState({ citiesOptions: results });
      });
    }
  };

  onSelect = (id) => {
    const { selected } = this.state;
    if (selected.includes(id)) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }

    this.setState({ selected });
  };

  openAddToList = (influencers) => {
    this.props.openAddToList(influencers);
    this.props.closeInfluencerSegmentations();
    this.sideSearchRef.current.toggleFilterMenu(null, true);
  };

  closeAddToList = () => {
    this.props.closeAddToList();
  };

  changeGender(gender, influencer) {
    influencer.gender = gender;
    this.setState({});
    this.props.changeGender(influencer, gender, () => {
      influencer.gender = gender;
    });
  }

  updateInfluencerAgeGroup(age, influencer) {
    influencer.age_group = age;
    this.setState({});
    this.props.changeAgeGroup(influencer, age, () => {
      influencer.age_group = age;
    });
  }

  changeUserCountry(country, influencer) {
    influencer.country = country;
    this.setState({});
    this.props.changeCountry(influencer, country, () => {
      influencer.country = country;
    });
  }

  setAsPage = (influencer) => {
    const newTag = !influencer.is_page;
    influencer.is_page = newTag;
    this.props.setAsPage(influencer, newTag, () => {
      this.setState({});
    });
  };

  openEditCategories = (influencer, socialNetwork) => {
    if (influencer[`${socialNetwork}_data`]) {
      influencerCategories.selected =
        influencer[`${socialNetwork}_data`].categories;
      if (!influencerCategories.selected) {
        influencer[`${socialNetwork}_data`].categories = [];
        influencerCategories.selected =
          influencer[`${socialNetwork}_data`].categories;
      }

      this.setState({
        showCategoriesDialogFor: influencer,
        showCategoriesDialogNetwork: socialNetwork,
        showCategoriesDialog: true,
      });
    }
  };

  openTagSocialModal = (socialTypeFrom, socialType, influencer) => {
    let currentUsername = null;

    if (socialType === "youtube") {
      if (influencer.youtube_data) {
        currentUsername = influencer.youtube_data.username;
      }
    } else if (socialType === "twitter") {
      if (influencer.twitter_data) {
        currentUsername = influencer.twitter_data.username;
      }
    } else if (socialType === "tiktok") {
      if (influencer.tiktok_data) {
        currentUsername = influencer.tiktok_data.username;
      }
    } else if (influencer.instagram_data) {
      currentUsername = influencer.instagram_data.username;
    }

    // this.openInNewTab("https://www.instagram.com/"+influencer.username,false);
    let fromUsername = null;
    if (socialTypeFrom === "youtube") {
      if (influencer.youtube_data) {
        fromUsername = influencer.youtube_data.username;
      }
    } else if (socialTypeFrom === "twitter") {
      if (influencer.twitter_data) {
        fromUsername = influencer.twitter_data.username;
      }
    } else if (socialTypeFrom === "tiktok") {
      if (influencer.tiktok_data) {
        fromUsername = influencer.tiktok_data.username;
      }
    } else if (influencer.instagram_data) {
      fromUsername = influencer.instagram_data.username;
    }
    this.openInNewTab(
      `https://www.${socialTypeFrom}.com/${
        socialTypeFrom === "youtube"
          ? `channel/${influencer.youtube_data.social_id}`
          : fromUsername
      }`,
      false,
    );
    if (currentUsername) {
      if (socialType === "youtube") {
        currentUsername = influencer.youtube_data.social_id;
      }
      var url = `https://www.${socialType}.com/${
        socialType === "youtube" ? "channel/" : ""
      }${socialType === "tiktok" ? "@" : ""}${currentUsername}`;

      this.openInNewTab(url, true);
    } else {
      var url = "";
      if (socialType === "youtube") {
        url = `https://www.youtube.com/results?search_query=${fromUsername}&sp=EgIQAkIECAESAA%253D%253D`;
      } else if (socialType === "twitter") {
        url = `https://www.twitter.com/search?f=users&q=${fromUsername}`;
      } else if (socialType === "instagram") {
        url = `https://www.google.com/search?q=site:instagram.com ${fromUsername}`;
      } else {
        url = `https://www.google.com/search?q=site:tiktok.com ${fromUsername}`;
      }
      this.openInNewTab(url, true);
    }

    this.setState({
      socialMediaFormText: currentUsername,
      socialMediaCompareOpen: true,
      socialMediaCompareInfluencer: influencer,
      socialMediaCompareType: socialType,
      socialMediacurrent: currentUsername,
      socialMediaFrom: socialTypeFrom,
    });
  };

  saveConnection = () => {
    const changeTo = this._newSocial;
    document.getElementById("saveUserConnectionBtn").classList.add("disabled");
    const {
      socialMediaCompareInfluencer,
      socialMediaCompareType,
      socialMediaFrom,
      socialMediacurrent,
    } = this.state;
    if (
      changeTo &&
      changeTo.value !== "" &&
      changeTo.value !== socialMediacurrent
    ) {
      this.props.changeUserConnection(
        socialMediaCompareInfluencer.id,
        socialMediaCompareType,
        socialMediaFrom,
        changeTo.value,
        (success) => {
          document
            .getElementById("saveUserConnectionBtn")
            .classList.remove("disabled");
          if (success) {
            this.state.socialMediaCompareInfluencer[
              `${socialMediaCompareType}_data`
            ] = { username: changeTo.value };
            this.setState({ socialMediaCompareOpen: false });
          }
        },
      );
    } else {
      this.setState({ socialMediaCompareOpen: false });
    }
  };

  removeConnection = () => {
    const {
      socialMediaCompareInfluencer,
      socialMediaCompareType,
      socialMediaFrom,
      socialMediacurrent,
    } = this.state;
    if (!socialMediacurrent || socialMediacurrent === "") {
      this.setState({ socialMediaCompareOpen: false });
    } else {
      this.props.removeSocialConnection(
        socialMediaCompareInfluencer.id,
        socialMediaCompareType,
        () => {
          this.state.socialMediaCompareInfluencer[
            `${socialMediaCompareType}_data`
          ] = { username: null };
          this.setState({ socialMediaCompareOpen: false });
        },
      );
    }
  };

  openInNewTab = (url, focus) => {
    const win = window.open(url, "_blank");
    if (focus) {
      try {
        win.focus();
      } catch (e) {}
    }
  };

  getAndSetSorting(query) {
    let socials = ["instagram", "twitter", "youtube"];
    if (this.props.user.userDetails.sidebar_data.can_view_tiktok) {
      socials.push("tiktok");
    }

    if (query.influencer_details.embedding_search?.search_text) {
      this.sortOptions.selectedSocial = "";
      this.sortOptions.selectedValue = "";
      return {
        social_network: "instagram",
        sort_by: "query_score",
        category: null,
      };
    }

    if (
      query.influencer_details.social_accounts &&
      query.influencer_details.social_accounts[0] !== "all"
    ) {
      socials = query.influencer_details.social_accounts;
    }

    let sortValue = this.sortOptions.selectedValue;
    let sortSocial = this.sortOptions.selectedSocial;
    if (sortValue && !socials.includes(this.sortOptions.selectedSocial)) {
      sortSocial = socials[0];
    }

    const sorting = [];

    DEFAULT_SORT_OPTIONS.map((x) => {
      sorting.push({ label: x, value: x, type: x });
    });

    if (
      query &&
      query.audience_details &&
      query &&
      query.audience_details.categories
    ) {
      query.audience_details.categories.forEach((category) => {
        sorting.push({
          label: category,
          value: category,
          type: "audience_category",
        });
      });
    }

    if (!sortValue) {
      sortValue = sorting[0].value;
      sortSocial = socials[0];
    }

    // if (sortingBy.value === sorting[0].value && !this.sortOptions.manual) {
    //   if (sorting.length > DEFAULT_SORT_OPTIONS.length) {
    //     sorting[0].selected = false;
    //     const option = sorting[DEFAULT_SORT_OPTIONS.length];
    //     option.selected = true;
    //     sortingBy = option;
    //   }
    // }

    this.sortOptions.socials = socials;
    this.sortOptions.data = sorting;
    this.sortOptions.selectedSocial = sortSocial;
    this.sortOptions.selectedValue = sortValue;
    return {
      social_network: sortSocial,
      sort_by: sortValue,
      category: sorting.find((x) => x.value === sortSocial)?.type,
    };
  }

  searchInfluencers = (nextPageId, deepSearch = false) => {
    if (!this.sideSearchRef || !this.sideSearchRef.current) return;

    let newState = { searchByUsername: null };

    if (!nextPageId) {
      newState = {
        ...newState,
        selected: [],
        influnecersToList: [],
        sortedBy: "",
        loadingData: true,
      };
    }

    const user = this.props.user && this.props.user.userDetails.sidebar_data;
    const isTrial = user.account_type === "trial";

    const dontAllowChildren =
      semiMode ||
      isTrial ||
      this.props.user.userDetails.company_details.country === "South Africa";

    const result = this.sideSearchRef.current.getSearchQuery(
      dontAllowChildren,
      this.state.showTagger,
    );

    if (
      (semiMode &&
        result.query.influencer_details.only_registered === undefined) ||
      isTrial
    ) {
      result.query.influencer_details.only_registered = true;
    }

    const { query, defaultSearch } = result;

    query.sort_by = this.getAndSetSorting(query);
    if (this.state.recentMode) {
      query.influencer_ids = getRecentViewedProfiles();
    } else {
      query.influencer_ids = null;
    }
    this.props.searchInfluencers(
      query,
      nextPageId,
      user && user.is_premium,
      deepSearch,
      defaultSearch,
      () => this.resultsCallback(!nextPageId),
    );

    this.sideSearchRef.current.saveSession();

    if (query.influencer_details && query.influencer_details.username?.trim()) {
      newState = {
        ...newState,
        searchByUsername: {
          username: query.influencer_details.username?.trim(),
          social_network: query.influencer_details.social_accounts[0],
        },
      };
    } else {
      newState = {
        ...newState,
        searchByUsername: {
          username: "",
          social_network: this.sideSearchRef.current.getSearchSocial(),
        },
      };
    }

    newState.filterActive = result.filterActive;
    this.setState(newState);
  };

  onSort = (sortOptions) => {
    this.sortOptions = sortOptions;
    this.searchInfluencers();
  };

  filterBySearch = (value, item) => {
    if (this.isSearchsOver()) {
      this.setState({ showTrialModal: true });
      return;
    }

    this.sideSearchRef.current.filterBySearch(value, item);
  };

  onChangeUserNameSocial = (social) => {
    if (this.isSearchsOver()) {
      this.setState({ showTrialModal: true });
      return;
    }

    this.sideSearchRef.current.onChangeUserNameSocial(social);
    this.setState({
      searchByUsername: {
        ...this.state.searchByUsername,
        social_network: social,
      },
    });
  };

  isSearchsOver = () => {
    const { user } = this.props;
    const { sidebar_data } = user.userDetails;
    if (
      !user.is_premium &&
      sidebar_data.number_of_searches === sidebar_data.max_number_of_searches
    ) {
      return true;
    }
    return false;
  };

  isViewsOver = () => {
    const { user } = this.props;
    const { sidebar_data } = user.userDetails;
    if (
      !user.is_premium &&
      sidebar_data.number_of_segmentation_views ===
        sidebar_data.max_number_of_segmentation_views
    ) {
      return true;
    }
    return false;
  };

  handleSegmentationClick = (influencer) => {
    if (this.isViewsOver()) {
      this.setState({ showTrialModal: true });
      return;
    }
    if (!influencer.is_spam) {
      this.props.closeAddToList();
      this.props.openInfluencerSegmentations(
        `${influencer.shadow_influencer ? "shadow_" : ""}${influencer.id}`,
      );
    }
  };

  updateTreeField = (parent, child) => {
    if (this.isSearchsOver()) {
      this.setState({ showTrialModal: true });
      return;
    }

    this.sideSearchRef.current.updateSearchField(parent, child);
  };

  updateSearchField = (name, selected) => {
    if (this.isSearchsOver()) {
      this.setState({ showTrialModal: true });
      return;
    }
    this.sideSearchRef.current.updateSearchField(name, selected);
  };

  overCube = (id) => {
    this.setState({ overCube: id });
  };

  getActiveFilter = (type) => {
    if (this.sideSearchRef && this.sideSearchRef.current) {
      return this.sideSearchRef.current.getActiveFilter(type);
    }
  };

  handleInfiniteLoad = () => {
    const user = this.props.user && this.props.user.userDetails.sidebar_data;
    if (!user || !user.is_premium) return;
    const { search } = this.props;
    if (
      !this.state.loadingData &&
      search.nextPageId &&
      !this.state.isPagingLoading &&
      search.searchsCount != search.influencers.length
    ) {
      this.setState({ isPagingLoading: true });
      this.searchInfluencers(search.nextPageId);
    }
  };

  toggleRecentMode = () => {
    this.setState({ recentMode: !this.state.recentMode }, () =>
      this.searchInfluencers(),
    );
  };

  /* ###### End Functions ###### */

  /* ###### Renders ###### */

  renderInfluencerRow(influencer, index) {
    const instagramData =
      influencer.instagram_data && influencer.instagram_data.username
        ? influencer.instagram_data
        : {};
    const tiktokData =
      influencer.tiktok_data && influencer.tiktok_data.username
        ? influencer.tiktok_data
        : {};
    const twitterData =
      influencer.twitter_data && influencer.twitter_data.username
        ? influencer.twitter_data
        : {};
    const youtubeData =
      influencer.youtube_data && influencer.youtube_data.social_id
        ? influencer.youtube_data
        : {};
    const score = {
      instagram: instagramData.score_api || instagramData.score,
      twitter: twitterData.score ? twitterData.score : null,
      youtube: youtubeData.score ? youtubeData.score : null,
    };
    const { showTagger } = this.state;
    // for tagger use
    let ownedSocialNetwork = null;
    if (instagramData.username) {
      ownedSocialNetwork = "instagram";
    } else if (twitterData.username) {
      ownedSocialNetwork = "twitter";
    } else if (tiktokData.username) {
      ownedSocialNetwork = "tiktok";
    } else {
      ownedSocialNetwork = "youtube";
    }
    return (
      <div
        key={influencer.id}
        className="search-influencer-row list-influencer-row"
      >
        {/* Left Side */}
        <div className="list-influencer-left search-influencer-left">
          <div
            className={"account-image"}
            style={{
              backgroundImage: `url(${influencer.picture_url}),url(images/circle-person.svg)`,
              backgroundSize: "cover",
            }}
          />
          <div className="accounts-list">
            {instagramData.username && (
              <div
                className="account-list-item-wrapper"
                style={{ display: "flex" }}
              >
                <div className="fa fa-instagram"></div>
                <a
                  className="list-username"
                  href={`https://www.instagram.com/${instagramData.username}/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {instagramData.username}
                </a>{" "}
              </div>
            )}
            {twitterData.username && (
              <div
                className="account-list-item-wrapper"
                style={{ display: "flex" }}
              >
                <div className="fa fa-twitter"></div>
                <a
                  className="list-username"
                  href={`https://twitter.com/${twitterData.username}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {twitterData.username}
                </a>{" "}
              </div>
            )}
            {youtubeData.social_id && (
              <div
                className="account-list-item-wrapper"
                style={{ display: "flex" }}
              >
                <div className="fa fa-youtube-play"></div>
                <a
                  className="list-username"
                  href={`https://www.youtube.com/channel/${youtubeData.social_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {youtubeData.username ? youtubeData.username : ""}
                </a>{" "}
              </div>
            )}
            {tiktokData.username && (
              <div
                className="account-list-item-wrapper"
                style={{ display: "flex" }}
              >
                <div className="fa-brands fa-tiktok"></div>
                <a
                  className="list-username"
                  href={`https://www.tiktok.com/@${tiktokData.username}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {tiktokData.username ? tiktokData.username : ""}
                </a>{" "}
              </div>
            )}
          </div>
        </div>
        {/* End Left Side */}

        <div className="list-influencer-details">
          {/* Actions Row */}
          <div className="list-influencer-details-first">
            <div style={{ display: "flex", marginBottom: -20 }}>
              <strong
                onClick={() => {
                  this.handleSegmentationClick(influencer);
                }}
              >
                {influencer.name}
              </strong>
              {!influencer.is_spam && (
                <div className="inf-row-button">
                  <div
                    style={{ display: "flex" }}
                    onClick={() => this.openAddToList([influencer])}
                  >
                    <li className="moi-add-list" /> <span>add to list</span>
                  </div>
                </div>
              )}
            </div>
            <div className="list-influencer-actions">
              <div style={{ display: "flex" }}>
                {instagramData.username && (
                  <a
                    href={`https://www.instagram.com/${instagramData.username}/`}
                    className="fa fa-instagram"
                  ></a>
                )}
                {twitterData.username && (
                  <a
                    href={`https://twitter.com/${twitterData.username}`}
                    target="_blank"
                    className="fa fa-twitter"
                    rel="noreferrer"
                  ></a>
                )}
                {youtubeData.social_id && (
                  <a
                    href={`https://www.youtube.com/channel/${youtubeData.social_id}`}
                    target="_blank"
                    className="fa fa-youtube-play"
                    rel="noreferrer"
                  ></a>
                )}
                {tiktokData.username && (
                  <a
                    href={`https://www.tiktok.com/@${tiktokData.username}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="fa-brands fa-tiktok"></div>
                  </a>
                )}
                {/* <div className="moi-more"></div> */}
              </div>
            </div>
          </div>
          {/* End Actions Row */}
          {!showTagger && (
            <div className="list-influencer-details-second">
              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  marginLeft: 25,
                }}
              >
                <div className="list-socials">
                  {instagramData.followers > 0 && (
                    <div className="list-social-followers">
                      <li className="fa fa-instagram" />
                      {formatBigNumbers(instagramData.followers)}
                    </div>
                  )}
                  {twitterData.followers > 0 && (
                    <div className="list-social-followers">
                      <li className="fa fa-twitter" />
                      {formatBigNumbers(twitterData.followers)}
                    </div>
                  )}
                  {youtubeData.followers > 0 && (
                    <div className="list-social-followers">
                      <li className="fa fa-youtube-play" />
                      {formatBigNumbers(youtubeData.followers)}
                    </div>
                  )}
                  {tiktokData.followers > 0 && (
                    <div className="list-social-followers">
                      <li className="fa">
                        <div className="moi-tik-tok"></div>
                      </li>
                      {formatBigNumbers(tiktokData.followers)}
                    </div>
                  )}
                </div>

                <div>
                  <strong className="list-influencer-details-desc">
                    FOLLOWERS
                  </strong>
                </div>
              </div>

              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  marginLeft: 35,
                }}
              >
                <div className="list-socials">
                  {instagramData.engagement_label && (
                    <span
                      aria-label={`${instagramData.avg_engagement_percetnage}%`}
                    >
                      {" "}
                      <div
                        className={`list-social-followers ${instagramData.engagement_label}`}
                      >
                        <li className="fa fa-instagram" />
                        {instagramData.engagement_label}
                      </div>
                    </span>
                  )}

                  {twitterData.engagement_label && (
                    <span
                      aria-label={`${twitterData.avg_engagement_percetnage}%`}
                    >
                      {" "}
                      <div
                        className={`list-social-followers ${twitterData.engagement_label}`}
                      >
                        <li className="fa fa-twitter" />
                        <span
                          aria-label={`${twitterData.avg_engagement_percetnage}%`}
                        >
                          {twitterData.engagement_label}
                        </span>
                      </div>{" "}
                    </span>
                  )}

                  {youtubeData.engagement_label && (
                    <span
                      aria-label={`${youtubeData.avg_engagement_percetnage}%`}
                    >
                      {" "}
                      <div
                        className={`list-social-followers ${youtubeData.engagement_label}`}
                      >
                        <li className="fa fa-youtube-play" />
                        <span
                          aria-label={`${youtubeData.avg_engagement_percetnage}%`}
                        >
                          {youtubeData.engagement_label}
                        </span>
                      </div>{" "}
                    </span>
                  )}

                  {tiktokData.engagement_label && (
                    <span
                      aria-label={`${tiktokData.avg_engagement_percetnage}%`}
                    >
                      {" "}
                      <div
                        className={`list-social-followers ${tiktokData.engagement_label}`}
                      >
                        <li className="fas">
                          {" "}
                          <div className="moi-tik-tok"></div>
                        </li>
                        <span
                          aria-label={`${tiktokData.avg_engagement_percetnage}%`}
                        >
                          {tiktokData.engagement_label}
                        </span>
                      </div>{" "}
                    </span>
                  )}
                </div>
                <div>
                  <strong className="list-influencer-details-desc">
                    ENGAGEMENTS
                  </strong>
                </div>
              </div>

              <div style={{ textAlign: "center", display: "inline-block" }}>
                <div className="row-cube-number">
                  <div className="cube-score">
                    {score.instagram && <span>{score.instagram}</span>}
                    {score.instagram && score.twitter && "|"}
                    {score.twitter && <span>{score.twitter}</span>}
                    {(score.instagram || score.twitter) && score.youtube && "|"}
                    {score.youtube && <span> {score.youtube}</span>}
                  </div>
                  <div>
                    {score.instagram && <span className="fa fa-instagram" />}
                    {score.twitter && <span className="fa fa-twitter" />}
                    {score.youtube && <span className="fa fa-youtube-play" />}
                  </div>
                </div>
                <div style={{ width: 100 }}>
                  <strong className="list-influencer-details-desc">
                    Score
                  </strong>
                </div>
              </div>
            </div>
          )}
          {showTagger && (
            <div className="list-influencer-details-second">
              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  marginLeft: 25,
                }}
              >
                <div style={{ width: 150 }} className="searchable-input">
                  <Select
                    ref="stateSelect"
                    options={this.countries}
                    placeholder={""}
                    value={influencer.country}
                    simpleValue
                    clearable={false}
                    onChange={(value) =>
                      this.changeUserCountry(value, influencer)
                    }
                    name="selected-state"
                    disabled={false}
                    searchable={true}
                  />
                </div>
                <div>
                  <strong className="list-influencer-details-desc">
                    COUNTRY
                  </strong>
                </div>
              </div>

              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  marginLeft: 35,
                }}
              >
                <div className="list-socials">
                  <i
                    className="fa fa-male"
                    style={{
                      marginRight: 10,
                      fontSize: 25,
                      cursor: "pointer",
                      color: influencer.gender === "male" ? "#249ff0" : "gray",
                    }}
                    onClick={() => this.changeGender("male", influencer)}
                  />
                  <i
                    className="fa fa-female"
                    style={{
                      marginRight: 10,
                      fontSize: 25,
                      cursor: "pointer",
                      color:
                        influencer.gender === "female" ? "#249ff0" : "gray",
                    }}
                    onClick={() => this.changeGender("female", influencer)}
                  />
                </div>
                <div>
                  <strong className="list-influencer-details-desc">
                    GENDER
                  </strong>
                </div>
              </div>

              <div style={{ textAlign: "center", display: "inline-block" }}>
                <div
                  style={{
                    fontSize: 10,
                    width: 80,
                    marginBottom: 15,
                    marginLeft: 10,
                  }}
                >
                  <Select
                    options={[
                      { value: "child", label: "0-12" },
                      { value: "teen", label: "13-18" },
                      { value: "adult", label: "19-35" },
                      { value: "elderly", label: "35+" },
                    ]}
                    placeholder={"X"}
                    simpleValue
                    name="selected-age-group"
                    disabled={false}
                    value={influencer.age_group}
                    onChange={(selectedField) => {
                      this.updateInfluencerAgeGroup(selectedField, influencer);
                    }}
                    searchable={false}
                    clearable={false}
                    menuPlacement={"top"}
                    className={"open-up-select"}
                  />
                </div>
                <div style={{ width: 100 }}>
                  <strong className="list-influencer-details-desc">
                    Age Group
                  </strong>
                </div>
              </div>

              <div style={{ textAlign: "center", display: "inline-block" }}>
                <i
                  style={{
                    fontSize: 40,
                    height: 50,
                    cursor: "pointer",
                    color: influencer.is_page ? "red" : "black",
                  }}
                  className="fa fa-file"
                  onClick={() => this.setAsPage(influencer)}
                />
                <div style={{ width: 100 }}>
                  <strong className="list-influencer-details-desc">
                    IS PAGE
                  </strong>
                </div>
              </div>
              <div style={{ textAlign: "center", display: "inline-block" }}>
                <div style={{ height: 50 }}>
                  <i
                    className="fa fa-twitter"
                    style={{
                      marginRight: 10,
                      fontSize: 20,
                      cursor: "pointer",
                      color: "gray",
                    }}
                    onClick={() =>
                      this.openEditCategories(influencer, "twitter")
                    }
                  />
                  <i
                    className="fa fa-instagram"
                    style={{
                      marginRight: 10,
                      fontSize: 20,
                      cursor: "pointer",
                      color: "gray",
                    }}
                    onClick={() =>
                      this.openEditCategories(influencer, "instagram")
                    }
                  />
                  <i
                    className="moi-tik-tok"
                    style={{
                      marginRight: 10,
                      fontSize: 20,
                      cursor: "pointer",
                      color: "gray",
                    }}
                    onClick={() =>
                      this.openEditCategories(influencer, "tiktok")
                    }
                  />
                </div>
                <div style={{ width: 100 }}>
                  <strong className="list-influencer-details-desc">
                    CATEGORIES
                  </strong>
                </div>
              </div>
              <div style={{ textAlign: "center", display: "inline-block" }}>
                <div style={{ height: 50 }}>
                  <i
                    className="fa fa-twitter"
                    style={{
                      marginRight: 7,
                      fontSize: 13,
                      cursor: "pointer",
                      color: twitterData.username ? "#249ff0" : "gray",
                    }}
                    onClick={() =>
                      this.openTagSocialModal(
                        ownedSocialNetwork,
                        "twitter",
                        influencer,
                      )
                    }
                  />
                  <i
                    className="fa fa-instagram"
                    style={{
                      marginRight: 7,
                      fontSize: 13,
                      cursor: "pointer",
                      color: instagramData.username ? "#249ff0" : "gray",
                    }}
                    onClick={() =>
                      this.openTagSocialModal(
                        ownedSocialNetwork,
                        "instagram",
                        influencer,
                      )
                    }
                  />
                  <i
                    className="fa fa-youtube-play"
                    style={{
                      marginRight: 7,
                      fontSize: 13,
                      cursor: "pointer",
                      color: youtubeData.username ? "#249ff0" : "gray",
                    }}
                    onClick={() =>
                      this.openTagSocialModal(
                        ownedSocialNetwork,
                        "youtube",
                        influencer,
                      )
                    }
                  />
                  <i
                    className="moi-tik-tok"
                    style={{
                      marginRight: 7,
                      fontSize: 13,
                      cursor: "pointer",
                      color: tiktokData.username ? "#249ff0" : "gray",
                    }}
                    onClick={() =>
                      this.openTagSocialModal(
                        ownedSocialNetwork,
                        "tiktok",
                        influencer,
                      )
                    }
                  />
                </div>
                <div style={{ width: 100 }}>
                  <strong className="list-influencer-details-desc">LINK</strong>
                </div>
              </div>
              {this.state.showCategoriesDialog &&
                this.state.showCategoriesDialogFor &&
                this.state.showCategoriesDialogFor.id === influencer.id && (
                  <MessageDialog
                    handleClose={() => {
                      this.setState({ showCategoriesDialog: false });
                    }}
                  >
                    <div style={{ textAlign: "center", padding: 20 }}>
                      <strong>
                        {this.state.showCategoriesDialogNetwork} Categories
                      </strong>
                      <div
                        key={influencerCategories.name}
                        style={{ marginTop: 5, marginRight: -30 }}
                      >
                        <div className="checkbox-container">
                          {influencerCategories.values.map((x, index) => {
                            const checked =
                              influencerCategories.selected.includes(x);
                            return (
                              <CheckBox
                                style={{ marginTop: 5, marginRight: 50 }}
                                checked={checked}
                                onChange={() => {
                                  influencerCategories.selected.addOrRemove(x);
                                  this.setState({});
                                }}
                                label={influencerCategories.labels[index]}
                                key={x}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <button
                        onClick={() =>
                          this.props.changeCategories(
                            this.state.showCategoriesDialogFor,
                            influencerCategories.selected,
                            this.state.showCategoriesDialogNetwork,
                            () => {
                              this.setState({ showCategoriesDialog: false });
                            },
                          )
                        }
                        className="btn btn-primry"
                        style={{
                          background: "var(--main-baby-blue-color)",
                          color: "white",
                          borderRadius: "40px",
                          width: 100,
                          marginTop: 15,
                        }}
                      >
                        SAVE
                      </button>
                    </div>
                  </MessageDialog>
                )}
              {this.state.socialMediaCompareOpen &&
                this.state.socialMediaCompareInfluencer &&
                this.state.socialMediaCompareInfluencer.id ===
                  influencer.id && (
                  <MessageDialog
                    handleClose={() => {
                      this.setState({ socialMediaCompareOpen: false });
                    }}
                  >
                    <div style={{ textAlign: "center", padding: 20 }}>
                      <strong>New {this.state.socialMediaCompareType}</strong>
                      <div>
                        <input
                          type="text"
                          placeholer={this.state.socialMediacurrent}
                          className="form-control"
                          value={this.state.socialMediaFormText}
                          onChange={(e) =>
                            this.setState({
                              socialMediaFormText: e.target.value,
                            })
                          }
                          ref={(r) => (this._newSocial = r)}
                        />
                      </div>

                      <button
                        onClick={this.saveConnection}
                        className="btn btn-primry"
                        id="saveUserConnectionBtn"
                        style={{
                          background: "var(--main-baby-blue-color)",
                          color: "white",
                          borderRadius: "40px",
                          width: 100,
                          marginTop: 15,
                        }}
                      >
                        SAVE
                      </button>
                      <button
                        onClick={this.removeConnection}
                        className="btn btn-warning"
                        style={{
                          display: "block",
                          color: "white",
                          borderRadius: "40px",
                          width: 175,
                          margin: "15px auto",
                        }}
                      >
                        REMOVE CONNECTION
                      </button>
                    </div>
                  </MessageDialog>
                )}
            </div>
          )}
        </div>
        {/* Checkbox right */}
        <div className="checkbox" style={{ marginTop: 50, padding: 0 }}>
          <label>
            <input
              type="checkbox"
              checked={this.state.selected.includes(influencer.id)}
              onChange={() => this.onSelect(influencer.id)}
            />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
          </label>
        </div>
        {/* End Checkbox right */}
      </div>
    );
  }

  renderInfluencerCubeRow(influencers) {
    const CUBES_PER_ROW = 4;
    let numberOfRows = influencers.length / CUBES_PER_ROW;
    if (influencers % CUBES_PER_ROW !== 0) {
      numberOfRows += 1;
    }
    const groups = [];
    for (let i = 0; i < numberOfRows; i++) {
      const baseNumber = i * CUBES_PER_ROW;
      const group = [];
      for (
        let j = 0;
        j + baseNumber < influencers.length && j < CUBES_PER_ROW;
        j++
      ) {
        group.push(influencers[baseNumber + j]);
      }
      if (group.length > 0) {
        groups.push(group);
      }
    }

    return groups.map((group, idx) => this.renderInfluencerGroup(group, idx));
  }

  renderInfluencerGroup(group, idx) {
    return (
      <div className="row" key={idx}>
        {group.map((influencer, idx) =>
          this.renderInfluencerCube(influencer, idx),
        )}
      </div>
    );
  }

  renderNullScore = (provider) => {
    return provider.is_private ? (
      <div className="private-user" aria-label={"Private User"}>
        <i className="far fa-lock"></i>
      </div>
    ) : null;
  };

  renderInfluencerCube(influencer, idx) {
    const instagramData =
      influencer.instagram_data && influencer.instagram_data.username
        ? influencer.instagram_data
        : {};
    const tiktokData =
      influencer.tiktok_data && influencer.tiktok_data.username
        ? influencer.tiktok_data
        : {};
    const twitterData =
      influencer.twitter_data && influencer.twitter_data.username
        ? influencer.twitter_data
        : {};
    const youtubeData =
      influencer.youtube_data && influencer.youtube_data.social_id
        ? influencer.youtube_data
        : {};
    const score = {
      instagram:
        instagramData.score_api ||
        instagramData.score ||
        this.renderNullScore(instagramData),
      twitter: twitterData.score
        ? twitterData.score
        : this.renderNullScore(twitterData),
      youtube: youtubeData.score
        ? youtubeData.score
        : this.renderNullScore(youtubeData),
    };
    const { showTagger } = this.state;

    return (
      <div
        key={`influencercube${influencer.id}`}
        className="inf-search-cube"
        onMouseEnter={(e) => this.overCube(influencer.id)}
        onMouseLeave={(e) => this.overCube(-1)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: -7,
          }}
        >
          <div
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: 180,
              whiteSpace: "nowrap",
            }}
          >
            <strong
              onClick={() => {
                this.handleSegmentationClick(influencer);
              }}
              style={{
                cursor: "pointer",
                fontSize: 16,
                color: "var(--main-baby-blue-color)",
              }}
            >
              {influencer.name}
            </strong>
          </div>
          <CheckBox
            checked={this.state.selected.includes(influencer.id)}
            onChange={() => this.onSelect(influencer.id)}
          />
        </div>
        {this.state.overCube === influencer.id && (
          <div
            className="inf-cube-over"
            onClick={() => {
              if (!showTagger) this.handleSegmentationClick(influencer);
            }}
          >
            <div style={{ marginTop: 10, height: 70, display: "flex" }}>
              {(score.instagram ||
                score.youtube ||
                score.twitter ||
                score.tiktok) && (
                <div
                  style={{
                    textAlign: "center",
                    display: "inline-block",
                    marginLeft: 0,
                  }}
                >
                  <div
                    className="row-cube-number"
                    style={{ textAlign: "left", marginLeft: 25 }}
                  >
                    <div className="cube-score">
                      {score.instagram && <span>{score.instagram}</span>}
                      {score.instagram && score.twitter && "|"}
                      {score.twitter && <span>{score.twitter}</span>}
                      {(score.instagram || score.twitter) &&
                        score.youtube &&
                        "|"}
                      {score.youtube && <span> {score.youtube}</span>}
                    </div>
                    <div>
                      {score.instagram && <span className="fa fa-instagram" />}
                      {score.twitter && <span className="fa fa-twitter" />}
                      {score.youtube && <span className="fa fa-youtube-play" />}
                    </div>
                  </div>
                  <div style={{ width: 100 }}>
                    <strong className="list-influencer-details-desc inf-cube-details-desc">
                      Score
                    </strong>
                  </div>
                </div>
              )}
              {showTagger && (
                <div
                  style={{
                    textAlign: "center",
                    display: "inline-block",
                    fontSize: 22,
                    cursor: "pointer",
                    marginLeft: 10,
                  }}
                >
                  <div>
                    <i
                      className="fa fa-male"
                      style={{
                        marginRight: 10,
                        color:
                          influencer.gender === "male" ? "#249ff0" : "white",
                      }}
                      onClick={() => this.changeGender("male", influencer)}
                    />
                    <i
                      className="fa fa-female"
                      style={{
                        marginRight: 10,
                        color:
                          influencer.gender === "female" ? "#249ff0" : "white",
                      }}
                      onClick={() => this.changeGender("female", influencer)}
                    />
                    <i
                      className="fa fa-file"
                      onClick={() => this.setAsPage(influencer)}
                    />
                    <div style={{ fontSize: 10, width: 80, marginTop: 10 }}>
                      <Select
                        options={[
                          { value: "child", label: "0-12" },
                          { value: "teen", label: "13-18" },
                          { value: "adult", label: "19-35" },
                          { value: "elderly", label: "35+" },
                        ]}
                        placeholder={"X"}
                        simpleValue
                        name="selected-age-group"
                        disabled={false}
                        value={influencer.age_group}
                        onChange={(selectedField) => {
                          this.updateInfluencerAgeGroup(
                            selectedField,
                            influencer,
                          );
                        }}
                        searchable={false}
                        clearable={false}
                        menuPlacement={"top"}
                        className={"open-up-select"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div style={{ marginTop: 5 }}>
              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  marginLeft: 15,
                }}
              >
                <div className="list-socials">
                  {instagramData.followers > 0 && (
                    <span>
                      <div
                        className={`list-social-followers ${
                          instagramData?.verification_type === "api"
                            ? "verified-social"
                            : null
                        }`}
                      >
                        <li className="fa fa-instagram" />
                        {formatBigNumbers(instagramData.followers)}
                      </div>
                    </span>
                  )}
                  {twitterData.followers > 0 && (
                    <span>
                      {" "}
                      <div
                        className={`list-social-followers ${
                          twitterData?.verification_type === "api"
                            ? "verified-social"
                            : null
                        }`}
                      >
                        <li className="fa fa-twitter" />
                        {formatBigNumbers(twitterData.followers)}
                      </div>
                    </span>
                  )}
                  {youtubeData.followers > 0 && (
                    <span>
                      {" "}
                      <div
                        className={`list-social-followers ${
                          youtubeData?.verification_type === "api"
                            ? "verified-social"
                            : null
                        }`}
                      >
                        <li className="fa fa-youtube-play" />
                        {formatBigNumbers(youtubeData.followers)}
                      </div>
                    </span>
                  )}
                  {tiktokData.followers > 0 && (
                    <span>
                      <div
                        className={`list-social-followers ${
                          tiktokData?.verification_type === "api"
                            ? "verified-social"
                            : null
                        }`}
                      >
                        <li className="fa-brands fa-tiktok" />
                        {formatBigNumbers(tiktokData.followers)}
                      </div>
                    </span>
                  )}
                </div>
                <div>
                  <strong className="list-influencer-details-desc inf-cube-details-desc">
                    FOLLOWERS
                  </strong>
                </div>
              </div>

              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  marginLeft: 25,
                }}
              >
                <div>
                  <div className="list-socials">
                    {instagramData.engagement_label ? (
                      <div>
                        <span
                          aria-label={`${instagramData.avg_engagement_percetnage}%`}
                        >
                          {" "}
                          <div
                            className={`list-social-followers engagments ${instagramData.engagement_label}`}
                          >
                            <li className="fa fa-instagram" />
                            {instagramData.engagement_label}
                          </div>
                        </span>
                      </div>
                    ) : instagramData.followers > 0 ? (
                      <div style={{ height: 16 }} />
                    ) : null}
                    {twitterData.engagement_label ? (
                      <div>
                        {" "}
                        <span
                          aria-label={`${twitterData.avg_engagement_percetnage}%`}
                        >
                          {" "}
                          <div
                            className={`list-social-followers  engagments ${twitterData.engagement_label}`}
                          >
                            <li className="fa fa-twitter" />
                            <span
                              aria-label={`${twitterData.avg_engagement_percetnage}%`}
                            >
                              {twitterData.engagement_label}
                            </span>
                          </div>{" "}
                        </span>
                      </div>
                    ) : twitterData.followers > 0 ? (
                      <div style={{ height: 21 }} />
                    ) : null}
                    {youtubeData.engagement_label ? (
                      <div>
                        {" "}
                        <span
                          aria-label={`${youtubeData.avg_engagement_percetnage}%`}
                        >
                          {" "}
                          <div
                            className={`list-social-followers engagments ${youtubeData.engagement_label}`}
                          >
                            <li className="fa fa-youtube-play" />
                            <span
                              aria-label={`${youtubeData.avg_engagement_percetnage}%`}
                            >
                              {youtubeData.engagement_label}
                            </span>
                          </div>{" "}
                        </span>
                      </div>
                    ) : youtubeData.followers > 0 ? (
                      <div style={{ height: 21 }} />
                    ) : null}
                    {tiktokData.engagement_label ? (
                      <div>
                        {" "}
                        <span
                          aria-label={`${tiktokData.avg_engagement_percetnage}%`}
                        >
                          {" "}
                          <div
                            className={`list-social-followers  engagments ${tiktokData.engagement_label}`}
                          >
                            <li className="fa-brands fa-tiktok" />
                            <span
                              aria-label={`${tiktokData.avg_engagement_percetnage}%`}
                            >
                              {tiktokData.engagement_label}
                            </span>
                          </div>{" "}
                        </span>
                      </div>
                    ) : tiktokData.followers > 0 ? (
                      <div style={{ height: 21 }} />
                    ) : null}
                  </div>
                  <div>
                    <strong className="list-influencer-details-desc inf-cube-details-desc">
                      ENGAGEMENTS
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="inf-search-img">
          <img
            src={influencer.picture_url && influencer.picture_url}
            onError={(e) => {
              e.target.src = "/images/empty-person.png";
              e.target.onError = null;
            }}
          />
        </div>
        <div className="inf-cube-bottom">
          {!influencer.is_spam && (
            <div className="inf-cube-row-button">
              <div
                style={{
                  display: "flex",
                  marginLeft: 3,
                  marginRight: 3,
                  marginTop: -1,
                }}
                onClick={() => this.openAddToList([influencer])}
              >
                <li className="moi-add-list" style={{ marginRight: 5 }} />{" "}
                <span>add to list</span>
              </div>
            </div>
          )}
          {influencer.is_spam && (
            <div style={{ width: "100%", height: 27 }}></div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: 80,
              marginLeft: 15,
              justifyContent: "space-between",
            }}
          >
            <a
              style={{ marginRight: 5, marginTop: -1 }}
              className={`fa-brands fa-instagram cube-button ${
                !instagramData.username ? "disabled" : "colored"
              }`}
              href={`https://www.instagram.com/${instagramData.username}/`}
              target="_blank"
              title={instagramData.username}
              rel="noreferrer"
            />

            {this.props.user.userDetails.sidebar_data.can_view_tiktok && (
              <a
                className={`fa-brands fa-tiktok cube-button  ${
                  !tiktokData.username ? "disabled" : "colored"
                }`}
                style={{ marginRight: 5 }}
                target={"_blank"}
                href={`https://www.tiktok.com/@${tiktokData.username}`}
                rel="noreferrer"
              ></a>
            )}

            <a
              style={{ marginRight: 5 }}
              className={`fa-brands fa-twitter cube-button ${
                !twitterData.username ? "disabled" : "colored"
              }`}
              href={`https://www.twitter.com/${twitterData.username}`}
              target="_blank"
              title={twitterData.username}
              rel="noreferrer"
            />
            <a
              style={{ marginRight: 2 }}
              className={`fa-brands fa-youtube cube-button ${
                !youtubeData.social_id ? "disabled" : "colored"
              }`}
              href={`https://www.youtube.com/channel/${youtubeData.social_id}`}
              target="_blank"
              title={youtubeData.username ? youtubeData.username : ""}
              rel="noreferrer"
            />
          </div>
        </div>
      </div>
    );
  }

  /* ###### End Renders ###### */

  render() {
    const { loadingData } = this.state;

    const user = this.props.user && this.props.user.userDetails.sidebar_data;
    const { influencers } = this.props.search;
    const campaign =
      this.props.campaign && this.props.board
        ? this.props.board.summary.name
        : null;
    return (
      <div
        className={`influencers-search-main ${
          this.state.showTagger ? "tagger" : ""
        }`}
      >
        {this.state.showTrialModal && (
          <FreeTrialDialog
            user={user}
            handleClose={() => this.setState({ showTrialModal: false })}
          />
        )}
        {/* Top bar */}
        <div className="section-top-bar"></div>
        {/* End top bar */}
        <SearchFilter
          type="discovery"
          searchInfluencers={this.searchInfluencers}
          setLoadingData={this.setLoadingData}
          user={this.props.user}
          ref={this.sideSearchRef}
        />
        <div className={"main-middle-container "}>
          {/* Active buttons */}
          <div className="middle-header" style={{ position: "initial" }}>
            <div className="search-title">
              {campaign && (
                <div className="search-campaign-name blurable-large">
                  {campaign}
                </div>
              )}
              Discovery
              <div className="title-labels">
                {this.state.filterActive ? (
                  <div style={{ textAlign: "right", marginRight: 5 }}>
                    <div
                      style={{ marginTop: 1, marginRight: 5 }}
                      className="fa fa-exclamation-triangle"
                    />
                    There are active filters
                  </div>
                ) : (
                  <div></div>
                )}
                {influencers &&
                  !loadingData &&
                  !Number.isNaN(this.props.search.searchsCount) && (
                    <div style={{ marginRight: 5 }}>
                      {user.is_premium ? (
                        <label>
                          {this.props.search.searchsCount > 1000
                            ? "1,000+"
                            : this.props.search.searchsCount}
                        </label>
                      ) : (
                        <label
                          style={{
                            color: "#f36528",
                            marginRight: 5,
                            fontWeight: "bold",
                          }}
                        >
                          {this.props.search.inf_num} OF{" "}
                          {this.props.search.searchsCount > 1000
                            ? "1,000+"
                            : this.props.search.searchsCount}
                        </label>
                      )}{" "}
                      Found
                    </div>
                  )}
              </div>
            </div>
            <SearchToolBar
              sideBarData={user}
              onChangeUserNameSocial={this.onChangeUserNameSocial}
              filterBySearch={this.filterBySearch}
              toggleFilterMenu={this.toggleFilterMenu}
              selectedSearchByUsername={this.state.searchByUsername}
              view={this.state.view}
              setView={(view) => this.setState({ view })}
              isFiltersActive={
                this.sideSearchRef?.current?.getActiveFilter("influencer") ||
                this.sideSearchRef?.current?.getActiveFilter("audience") ||
                this.sideSearchRef?.current?.getActiveFilter("content")
              }
              onAddToList={() =>
                this.openAddToList(
                  influencers.filter((x) => this.state.selected.includes(x.id)),
                )
              }
              isInfluencersSelected={this.state.selected.length > 0}
              isRecentMode={this.state.recentMode}
              toggleRecentMode={this.toggleRecentMode}
              sortOptions={this.sortOptions}
              onSort={this.onSort}
            />

            <div
              style={{
                marginTop: 10,
                display: "flex",
                background: "#fafafa",
                justifyContent: "space-between",
                marginBottom: -20,
                gap: "10px",
              }}
            >
              {this.getActiveFilter("influencer")}
              {this.getActiveFilter("audience")}
              {this.getActiveFilter("content")}
            </div>
          </div>
          {/* Active buttons */}

          <div>
            {this.state.loadingData ? (
              <SearchLoader />
            ) : (
              // <div className="logo-loader">
              //     <div className="loader" />
              //     <img src={Logo} />
              // </div>
              <div>
                {/* Influencers rows Section */}
                {influencers &&
                  (this.state.view === "rows" ? (
                    <Infinite
                      elementHeight={145}
                      className="influencers-search-infinite"
                      useWindowAsScrollContainer={true}
                      infiniteLoadBeginEdgeOffset={300}
                      onInfiniteLoad={this.handleInfiniteLoad}
                      loadingSpinnerDelegate={this.elementInfiniteLoad()}
                      isInfiniteLoading={this.state.isPagingLoading}
                    >
                      {influencers.map((x, index) =>
                        this.renderInfluencerRow(x, index),
                      )}
                    </Infinite>
                  ) : (
                    <Infinite
                      elementHeight={310}
                      className="influencers-search-infinite-cubes"
                      useWindowAsScrollContainer={true}
                      infiniteLoadBeginEdgeOffset={300}
                      onInfiniteLoad={this.handleInfiniteLoad}
                      loadingSpinnerDelegate={this.elementInfiniteLoad()}
                      isInfiniteLoading={this.state.isPagingLoading}
                    >
                      {this.renderInfluencerCubeRow(influencers)}
                    </Infinite>
                  ))}
                {this.state.searchByUsername?.username ? (
                  <UserNotFound
                    searchInfluencer={() => this.searchInfluencers(null, true)}
                    resultsFound={influencers?.length > 0 || false}
                    {...this.state.searchByUsername}
                  />
                ) : null}
                {/* END Influencers rows Section */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  elementInfiniteLoad = () => {
    if (!this.state.isPagingLoading) return;
    return (
      <div className="infinite-list-item">
        <div
          className="spinner small"
          style={{ marginTop: 10, marginBottom: 0 }}
        >
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    search: state.searchReducer,
    user: state.userReducer,
    board: state.boardReducer.board,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchInfluencers: (
      searchParams,
      nextPageId,
      isPremium,
      deepSearch,
      defaultSearch,
      callback,
    ) => {
      dispatch(
        searchInfluencers(
          searchParams,
          nextPageId,
          isPremium,
          deepSearch,
          defaultSearch,
          callback,
        ),
      );
    },
    changeAgeGroup: (influencer, age, callback) => {
      dispatch(changeAgeGroup(influencer, age, callback));
    },
    changeGender: (influencer, gender, callback) => {
      dispatch(changeGender(influencer, gender, callback));
    },
    setAsPage: (influencer, pageTag, callback) => {
      dispatch(setAsPage(influencer, pageTag, callback));
    },
    changeCountry: (influencer, country, callback) => {
      dispatch(changeCountry(influencer, country, callback));
    },
    changeCategories: (influencer, categories, socialNetwork, callback) => {
      dispatch(
        changeCategories(influencer, categories, socialNetwork, callback),
      );
    },
    changeUserConnection: (
      user_id,
      socialType,
      socialTypeFrom,
      newUser,
      callback,
    ) => {
      dispatch(
        changeUserConnection(
          user_id,
          socialType,
          socialTypeFrom,
          newUser,
          callback,
        ),
      );
    },
    removeSocialConnection: (user_id, socialType, callback) => {
      dispatch(removeSocialConnection(user_id, socialType, callback));
    },
    filterCities: (cityName, callback) => {
      dispatch(filterCities(cityName, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfluencersSearch);
