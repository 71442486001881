import { Box, Text } from "@chakra-ui/react";
import { CSSProperties, useState } from "react";
import {
  FileTypes,
  FileUpload,
  UploadNumber,
  uploadFileSizeValidator,
} from "src/ui/FileUpload";

type Props = {
  header: string;
  onFileUploaded?: (imageUrl: any) => void;
  addPicture: (imageUrl: any) => void;
  createUnfinishedIfNeeded: (x: any) => void;
  deleteFile: (index: number, arr: number[]) => void;
  onBlur?: () => void;
  placeholder: string;
  description?: string;
  style?: CSSProperties;
  moodImages_id: any[];
  moodImages_urls: any[];
  isShare?: boolean;
};

const IndicatorImage = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const renderFileUpload = (index: number) => (
    <div
      key={index}
      style={{
        display: "flex",
        flex: "0 0 348px",
        height: "207px",
      }}
    >
      {props.moodImages_id.length + 1 > index ? (
        <FileUpload
          placeholder="Cover images"
          fileType={FileTypes.image}
          uploadNumber={UploadNumber.multiple}
          onFileUploaded={(imageUrl) => {
            props.createUnfinishedIfNeeded(() => {
              props.addPicture(imageUrl);
            });
          }}
          disabled={props.isShare}
          deleteFile={() => {
            if (!props.isShare)
              props.deleteFile(props.moodImages_id[index], props.moodImages_id);
          }}
          previewFileUrl={
            props.moodImages_urls
              ? props.moodImages_urls[index]?.url
              : props.moodImages_id[index]
          }
          styleImg={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
            border: "1.5px solid #EBEBEB",
            borderRadius: "6px",
          }}
          validator={uploadFileSizeValidator}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1.5px solid #EBEBEB",
            borderRadius: "6px",
            width: "348px",
            background: "#EBEBEB",
          }}
        >
          <i
            className="fa-regular fa-image"
            style={{ fontSize: "48px", color: "#cfcfcf" }}
          ></i>
        </div>
      )}
    </div>
  );
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            background: isFocused ? "#249FF0" : "#EBEBEB",
            height: "auto",
            marginRight: "8px",
            width: "1px",
          }}
        ></Box>
        <Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "auto",
                padding: 0,
                color: "#222325",
              }}
            >
              {props.header}
            </Text>
            {props.description && (
              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#AAAAAA",
                }}
              >
                {props.description}
              </Text>
            )}
          </Box>
          <Box onBlur={handleBlur} onFocus={handleFocus}>
            <div style={{ width: "300px" }}>
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  marginBottom: "5px",
                }}
              >
                {[0, 1].map((index) => renderFileUpload(index))}
              </div>
              <div style={{ display: "flex", gap: 5 }}>
                {[2, 3].map((index) => renderFileUpload(index))}
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IndicatorImage;
