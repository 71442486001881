/* eslint-disable import-access/jsdoc */
/* eslint-disable no-param-reassign */
import {
  Alert,
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  responseForInfluencerCounterOffer,
  sendToTrash,
} from "src/actions/auctionsActions";
import {
  postMoiAuctionManageBoard_idApprove_bidInfluencer_id,
  postMoiAuctionManageBoard_idNegotiate_bidInfluencer_id,
} from "src/api/services";
import { InfluencerInManageAuctionData, InviteStatus } from "src/api/types";
import { FormFooterButtonGroup } from "src/ui/FormFooterButtonGroup";
import { InfiniteLoader } from "../../general_components/InfiniteLoader";
import { NegotiateResponseType } from "./CampaignManagerInterfaces";

const ResponseToInfluencerBidDialog = ({
  isOpen,
  onClose,
  influencer,
  currencyLabel,
  updateColumns,
}: {
  isOpen: boolean;
  onClose: () => void;
  influencer: InfluencerInManageAuctionData;
  currencyLabel: any;
  updateColumns: any;
}) => {
  const board = useSelector((state) => state.boardReducer?.board);
  const dispatch = useDispatch();
  const [negotiationMode, setNegotiationMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inviteStatus = influencer?.invite_status;
  const isCurrentMonth = moment(board?.current_manage_month).isSame(
    moment(),
    "month",
  );
  const negInput: any = useRef<any>();

  useEffect(() => {
    setNegotiationMode(false);
  }, [isOpen]);

  const sendBidCounterResponse = (fromNextMonth: boolean) => {
    if (!influencer.bid_status) {
      return;
    }
    const value = parseInt(negInput?.current?.value, 10);
    if (value < 0) return;
    const response: any = {
      bid_id: influencer.bid_status.id,
      price: value,
      from_next_month: fromNextMonth,
    };
    setIsLoading(true);
    const inviteStatus: InviteStatus = {
      price_offered: value,
      price_offered_state: "wait_influencer",
    };

    postMoiAuctionManageBoard_idNegotiate_bidInfluencer_id(
      influencer.influencer_id,
      board.board_id,
      response,
    )
      .then(() => {
        influencer.invite_status = inviteStatus;
        updateColumns();
        onClose();
      })
      .catch(() => {
        toast.error("Failed to respond. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const approveBid = (fromNextMonth: boolean) => {
    setIsLoading(true);
    postMoiAuctionManageBoard_idApprove_bidInfluencer_id(
      influencer.influencer_id,
      board.board_id,
      { bid_id: influencer.bid_status.id, from_next_month: fromNextMonth },
    )
      .then(() => {
        updateColumns();
        onClose();
      })
      .catch(() => {
        toast.error("Failed to respond. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const declineBid = () => {
    setIsLoading(true);
    dispatch(
      sendToTrash(board.board_id, influencer.influencer_id, null, () => {
        updateColumns();
        setIsLoading(false);
        onClose();
      }),
    );
  };

  const sendInviteCounterResponse = (responseType: NegotiateResponseType) => {
    if (responseType === "decline") {
      declineBid();
    } else {
      const influencerId = influencer.influencer_id;
      const response: any = {
        response_type: ["accept_next_month", "accept_this_month"].includes(
          responseType,
        )
          ? "accept"
          : responseType,
        next_month: responseType === "accept_next_month",
      };
      if (
        responseType === "negotiate" ||
        responseType === "negotiate_next_month"
      ) {
        const value = parseInt(negInput?.current?.value, 10);
        if (value < 0) return;
        response.counter_offer = value;
        if (responseType === "negotiate_next_month") {
          response.next_month = true;
        }
        response.response_type = "negotiate";
      }
      setIsLoading(true);
      dispatch(
        responseForInfluencerCounterOffer(
          board.board_id,
          influencerId,
          response,
          () => {
            influencer.invite_status.price_offered_state = "wait_influencer";
            if (responseType === "negotiate") {
              influencer.invite_status.price_offered = response.counter_offer;
            }
            if (responseType === "decline") {
              influencer.invite_status.price_offered_state =
                "advertiser_rejected";
            }
            setIsLoading(false);
            updateColumns();
            onClose();
          },
        ),
      );
    }
  };

  const renderNegotiationMode = () => {
    return (
      <Flex gap={4} flexDir={"column"} justifyContent="center" p={10}>
        <InputGroup>
          <InputLeftAddon minW={"40px"}>
            <span style={{}}>{currencyLabel()}</span>
          </InputLeftAddon>
          <Input
            flex={1}
            defaultValue={
              inviteStatus
                ? influencer.invite_status?.price_offered
                : influencer?.bid_status?.bid
            }
            type="number"
            ref={negInput}
          />
        </InputGroup>

        <Button
          variant={"shadowed"}
          size={"lg"}
          colorScheme={"green"}
          leftIcon={<i className=" fa fa-calendar" />}
          isDisabled={influencer?.start_from && isCurrentMonth}
          onClick={() =>
            inviteStatus
              ? sendInviteCounterResponse("negotiate")
              : sendBidCounterResponse(false)
          }
        >
          Send offer for current month
        </Button>
        {!board?.one_month_campaign && (
          <Button
            variant={"shadowed"}
            size={"lg"}
            leftIcon={<i className=" fa fa-calendar" />}
            onClick={() =>
              inviteStatus
                ? sendInviteCounterResponse("negotiate_next_month")
                : sendBidCounterResponse(true)
            }
          >
            Send offer for next month
          </Button>
        )}
        <Button
          variant={"roundedWhite"}
          leftIcon={<i className="fa fa-arrow-left" />}
          onClick={() => setNegotiationMode(false)}
        >
          Go back
        </Button>
      </Flex>
    );
  };

  const renderAllOptions = () => {
    return (
      <>
        <Flex
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ padding: 6, gap: 4 }}
        >
          <Box
            sx={{
              border: "2px solid #EAE8E8",
              borderRadius: 8,
              textAlign: "center",
              padding: 4,
            }}
          >
            <Button
              size={"lg"}
              leftIcon={<i className=" fa fa-check" />}
              colorScheme={"green"}
              variant={"shadowed"}
              isDisabled={influencer?.start_from && isCurrentMonth}
              onClick={() =>
                inviteStatus
                  ? sendInviteCounterResponse("accept_this_month")
                  : approveBid(false)
              }
            >
              Accept and start this month
            </Button>
            {influencer?.start_from && isCurrentMonth ? (
              <Alert mt={2} status={"warning"} fontSize={12}>
                <i
                  className="fa-duotone fa-circle-exclamation "
                  style={{ marginTop: 0, fontSize: 16 }}
                ></i>
                <Text paddingX={2}>Influencer was invited for next month</Text>
              </Alert>
            ) : (
              <Alert variant={"ghost"} fontSize={12}>
                <i
                  className="fa-duotone fa-circle-info "
                  style={{ marginTop: 0, fontSize: 16 }}
                ></i>
                <Text paddingX={2}>
                  The influencer will be able to start working on the campaign
                  right away
                </Text>
              </Alert>
            )}
          </Box>

          {!board?.one_month_campaign && (
            <Box
              sx={{
                border: "2px solid #EAE8E8",
                borderRadius: 8,
                textAlign: "center",
                padding: 4,
              }}
            >
              <Button
                variant={"shadowed"}
                size={"lg"}
                leftIcon={<i className=" fa fa-calendar" />}
                onClick={() =>
                  inviteStatus
                    ? sendInviteCounterResponse("accept_next_month")
                    : approveBid(true)
                }
              >
                Accept and start next month
              </Button>
              <Alert colorScheme={"gray"} variant={"ghost"} fontSize={12}>
                <i
                  className="fa-duotone fa-circle-info "
                  style={{ marginTop: 0, fontSize: 16 }}
                ></i>
                <Text paddingX={2}>
                  The influencer will be able to start working in the campaign
                  from next month
                </Text>
              </Alert>
            </Box>
          )}
          <Button
            size={"lg"}
            leftIcon={<i className=" fa fa-handshake" />}
            variant={"shadowed"}
            colorScheme={"white"}
            color={"brand.500"}
            w={"293px"}
            onClick={() => setNegotiationMode(true)}
          >
            Negotiate different price
          </Button>
          <Button
            size={"lg"}
            leftIcon={<i className=" fa fa-close" />}
            colorScheme={"red"}
            variant={"shadowed"}
            w={"293px"}
            onClick={() => {
              declineBid();
            }}
          >
            Decline offer
          </Button>
        </Flex>
      </>
    );
  };

  return (
    <Modal size={"md"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader backgroundColor="white" color={"#333"} p={4}>
          <Text style={{ fontSize: "16px", fontWeight: 600 }}>
            Respond to offer
          </Text>
        </ModalHeader>
        <ModalCloseButton color="#808080" />
        <ModalBody background={"white"} padding={0}>
          <Box
            background={"#FAFAFA"}
            padding={6}
            sx={{
              borderBottom: "1px solid #EAE8E8",
              borderTop: "1px solid #EAE8E8",
            }}
            textAlign="center"
          >
            <Avatar
              size="lg"
              justifySelf={"center"}
              src={influencer?.picture_url}
              mb={1}
            />
            <Flex justifyContent="center" alignItems={"center"} gap={3}>
              <Box>
                <Text fontWeight={500} color={"#333"} fontSize={"16px"}>
                  {influencer?.name}
                </Text>
              </Box>
            </Flex>
            <Flex
              style={{
                gap: 8,
                marginTop: "0px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: "#555",
                }}
              >
                {inviteStatus &&
                (influencer.invite_status.price_offered_state ===
                  "wait_influencer" ||
                  !influencer.user_registered)
                  ? "Was invited with "
                  : "Is asking for"}{" "}
                <Tag colorScheme={"blackAlpha"}>
                  {(inviteStatus
                    ? influencer.invite_status?.price_offered
                    : influencer?.bid_status?.bid) || 0}
                  {currencyLabel()}
                </Tag>
              </Text>
            </Flex>
          </Box>
          {isLoading ? (
            <InfiniteLoader />
          ) : (
            <>
              {negotiationMode ? renderNegotiationMode() : renderAllOptions()}
            </>
          )}

          <FormFooterButtonGroup p={3} justifyContent={"center"}>
            <Button
              isLoading={isLoading}
              type="button"
              colorScheme={"gray"}
              variant={"unstyled"}
              onClick={onClose}
            >
              Cancel
            </Button>
          </FormFooterButtonGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResponseToInfluencerBidDialog;
