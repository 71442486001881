/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export var AllowedImageUploadTypes;
(function (AllowedImageUploadTypes) {
  AllowedImageUploadTypes["Auction"] = "auction";
  AllowedImageUploadTypes["Task"] = "task";
  AllowedImageUploadTypes["Proposal"] = "proposal";
  AllowedImageUploadTypes["Instruction"] = "instruction";
  AllowedImageUploadTypes["BrandLogo"] = "brand_logo";
  AllowedImageUploadTypes["Product"] = "product";
  AllowedImageUploadTypes["StandaloneReport"] = "standalone_report";
  AllowedImageUploadTypes["Feed"] = "feed";
  AllowedImageUploadTypes["Widget"] = "widget";
  AllowedImageUploadTypes["Image"] = "image";
  AllowedImageUploadTypes["Invoice"] = "invoice";
  AllowedImageUploadTypes["References"] = "references";
})(AllowedImageUploadTypes || (AllowedImageUploadTypes = {}));
export var Currency;
(function (Currency) {
  Currency["USD"] = "USD";
  Currency["NIS"] = "NIS";
  Currency["ZAR"] = "ZAR";
  Currency["EUR"] = "EUR";
  Currency["GBP"] = "GBP";
  Currency["AUD"] = "AUD";
  Currency["NZD"] = "NZD";
  Currency["TRY"] = "TRY";
  Currency["NGN"] = "NGN";
  Currency["BRL"] = "BRL";
  Currency["KES"] = "KES";
  Currency["INR"] = "INR";
  Currency["UL"] = "UL";
})(Currency || (Currency = {}));
export var CreditCardIssuer;
(function (CreditCardIssuer) {
  CreditCardIssuer["IsraCard"] = "IsraCard";
  CreditCardIssuer["VisaCAL"] = "Visa CAL";
  CreditCardIssuer["Diners"] = "Diners";
  CreditCardIssuer["AMEX"] = "AMEX";
  CreditCardIssuer["JCB"] = "JCB";
  CreditCardIssuer["LeumiCard"] = "Leumi Card";
})(CreditCardIssuer || (CreditCardIssuer = {}));
export var InfluencerCategory;
(function (InfluencerCategory) {
  InfluencerCategory["Home"] = "home";
  InfluencerCategory["News"] = "news";
  InfluencerCategory["Science"] = "science";
  InfluencerCategory["Coaching"] = "coaching";
  InfluencerCategory["Gaming"] = "gaming";
  InfluencerCategory["Funny"] = "funny";
  InfluencerCategory["Sports"] = "sports";
  InfluencerCategory["Finance"] = "finance";
  InfluencerCategory["Art"] = "art";
  InfluencerCategory["Beauty"] = "beauty";
  InfluencerCategory["Bloggers"] = "bloggers";
  InfluencerCategory["Books"] = "books";
  InfluencerCategory["Family"] = "family";
  InfluencerCategory["Fashion"] = "fashion";
  InfluencerCategory["Fitness"] = "fitness";
  InfluencerCategory["Food"] = "food";
  InfluencerCategory["Health"] = "health";
  InfluencerCategory["Lifestyle"] = "lifestyle";
  InfluencerCategory["Motherhood"] = "motherhood";
  InfluencerCategory["Music"] = "music";
  InfluencerCategory["Nightlife"] = "nightlife";
  InfluencerCategory["Pets"] = "pets";
  InfluencerCategory["Photography"] = "photography";
  InfluencerCategory["Soldiers"] = "soldiers";
  InfluencerCategory["Tech"] = "tech";
  InfluencerCategory["Travel"] = "travel";
  InfluencerCategory["Vegan"] = "vegan";
})(InfluencerCategory || (InfluencerCategory = {}));
export var BrandReportCategory;
(function (BrandReportCategory) {
  BrandReportCategory["News"] = "news";
  BrandReportCategory["Funny"] = "funny";
  BrandReportCategory["Sports"] = "sports";
  BrandReportCategory["Art"] = "art";
  BrandReportCategory["Family"] = "family";
  BrandReportCategory["Fashion"] = "fashion";
  BrandReportCategory["Fitness"] = "fitness";
  BrandReportCategory["Music"] = "music";
  BrandReportCategory["Nightlife"] = "nightlife";
  BrandReportCategory["Tech"] = "tech";
  BrandReportCategory["Travel"] = "travel";
})(BrandReportCategory || (BrandReportCategory = {}));
export var BrandReportLanguage;
(function (BrandReportLanguage) {
  BrandReportLanguage["He"] = "he";
  BrandReportLanguage["En"] = "en";
})(BrandReportLanguage || (BrandReportLanguage = {}));
export var PostTypes;
(function (PostTypes) {
  PostTypes["IgPost"] = "ig_post";
  PostTypes["IgStory"] = "ig_story";
  PostTypes["YtVideo"] = "yt_video";
  PostTypes["TwTweet"] = "tw_tweet";
  PostTypes["InfluencerContent"] = "influencer_content";
  PostTypes["TkVideo"] = "tk_video";
})(PostTypes || (PostTypes = {}));
export var PostSubTypes;
(function (PostSubTypes) {
  PostSubTypes["Post"] = "post";
  PostSubTypes["Reel"] = "reel";
  PostSubTypes["Igtv"] = "igtv";
  PostSubTypes["YtShort"] = "yt_short";
})(PostSubTypes || (PostSubTypes = {}));
export var AuctionTypes;
(function (AuctionTypes) {
  AuctionTypes["Unfinished"] = "unfinished";
  AuctionTypes["Widget"] = "widget";
  AuctionTypes["Premium"] = "premium";
  AuctionTypes["Normal"] = "normal";
  AuctionTypes["Barter"] = "barter";
  AuctionTypes["Express"] = "express";
  AuctionTypes["Survey"] = "survey";
  AuctionTypes["AutoApproveBids"] = "auto_approve_bids";
})(AuctionTypes || (AuctionTypes = {}));
export var QuestionTypes;
(function (QuestionTypes) {
  QuestionTypes["Checkbox"] = "checkbox";
  QuestionTypes["Open"] = "open";
  QuestionTypes["Select"] = "select";
  QuestionTypes["Numeric"] = "numeric";
  QuestionTypes["Verification"] = "verification";
})(QuestionTypes || (QuestionTypes = {}));
/**
 * relevant for Brazil only atm
 */
export var BankAccountType;
(function (BankAccountType) {
  BankAccountType["Checking"] = "checking";
  BankAccountType["Savings"] = "savings";
})(BankAccountType || (BankAccountType = {}));
export var SocialNetworkOptions;
(function (SocialNetworkOptions) {
  SocialNetworkOptions["Youtube"] = "youtube";
  SocialNetworkOptions["Twitter"] = "twitter";
  SocialNetworkOptions["Instagram"] = "instagram";
  SocialNetworkOptions["Tiktok"] = "tiktok";
})(SocialNetworkOptions || (SocialNetworkOptions = {}));
