import React from "react";

export class DottedMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.updateMap();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.selected) !== JSON.stringify(this.props.selected)
    ) {
      const { selected } = prevProps;
      if (selected && selected.length > 0) {
        selected.forEach((country) => {
          const element = document.getElementById(country);
          if (element) {
            element.classList.remove("selected-country");
            element.classList.remove(`a${selected.indexOf(country)}`);
          }
        });
      }
      this.updateMap();
    }
  }

  updateMap() {
    const { selected } = this.props;
    if (selected && selected.length > 0) {
      selected.forEach((country) => {
        const element = document.getElementById(country);
        if (element) {
          element.classList.add("selected-country");
          element.classList.add(`a${selected.indexOf(country)}`);
        }
      });
    }
  }

  showTooltip(evt) {
    if (this.props.onMouseMove) this.props.onMouseMove(evt);
  }

  hideTooltip() {
    if (this.props.onMouseOut) this.props.onMouseOut();
  }

  render() {
    return (
      <div className="dotted-map">
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width="931.978px"
          height="464.288px"
          viewBox="0 0 931.978 464.288"
        >
          <g id="North_America">
            <g id="Greenland_1_" fill="none">
              <g
                id="Greenland"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="407.529" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="399.734" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="391.939" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="391.939" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="384.144" cy="9.991" r="2.197" />
                <circle fill="#aaaaaa" cx="384.144" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="384.144" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="384.144" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="384.144" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="384.144" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="384.144" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="9.991" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="376.351" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="2.198" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="9.991" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="368.554" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="360.76" cy="2.198" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="360.76" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="2.198" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="352.963" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="2.198" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="2.198" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="306.195" cy="9.991" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="298.401" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="282.811" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="282.811" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="282.811" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="282.811" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="282.811" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="275.017" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="41.171" r="2.197" />
              </g>
              <path
                stroke="none"
                d="M330.922,4.8c4.084-2.698,2.62-6.34,8.064-7.011
                    c5.005-0.617,10.104-0.297,15.123-0.072c5.779,0.259,12.719-0.129,18.323,1.285c5.249,1.324,9.508,5.901,15,7.391
                    c3.061,0.83,5.687,2.703,8.691,3.65c2.864,0.903,5.548,0.422,8.438,0.715c4.098,0.416,10.18,1.516,8.795,6.95
                    c-1.278,5.017-6.49,4.381-10.471,5.438c-5.539,1.472-4.525,5.544-8.112,8.921c-6.739,6.345-2.438,17.85-6.758,25.957
                    c-1.702,3.196-4.894,5.695-5.894,9.271c-0.693,2.478-0.059,4.565,0.447,7.071c1.011,5.013,1.443,9.864-4.791,11.01
                    c-2.113,0.389-4.414,0.081-6.438,0.584c-2.945,0.733-5.038,2.717-7.557,4.198c-2.851,1.676-6.112,2.597-9.213,3.681
                    c-3.392,1.186-3.836,3.788-6.267,6.227c-2.146,2.152-3.722,1.722-6.423,2.831c-4.572,1.877-6.947,6.575-8.562,10.961
                    c-2.573,6.992-9.898,11.347-17.629,10.267c-5.532-0.772-8.999-3.521-12.185-7.833c-1.521-2.058-2.933-4.267-3.768-6.557
                    c-2.695-7.391-5.199-13.376-6.297-21.349c-1.125-8.188,2.264-20.62-4.261-27.085c-2.989-2.963-6.626-6.168-10.841-6.917
                    c-6.047-1.074-12.374,1.14-18.417-0.329c-3.116-0.757-3.71-2.172-5.806-4.19c-2.765-2.661-6.772-4.345-7.711-8.471
                    c-0.779-3.435-0.3-9.021,3.187-11.044c3.151-1.827,7.527,0.844,10.856-0.957c4.322-2.338,2.333-8.374,3.467-12.213
                    c1.609-5.449,11.437-1.678,15.949-2.628c1.38-0.291,2.465-0.936,3.98-1.088c3.678-0.368,7.374,0.745,11.08,0.383
                    c5.133-0.501,4.71-2.375,6.877-5.987c3.443-5.741,14.363-2.81,19.962-2.81C324.393,5.051,328.829,6.182,330.922,4.8z"
              />
            </g>
            <g id="United_States_1_">
              <g
                id="United_States"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="87.941" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="80.146" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="72.353" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="95.735" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="87.941" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="64.557" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="64.557" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="64.557" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="64.557" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="64.557" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="64.557" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="56.763" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="56.763" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="56.763" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="56.763" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="56.763" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="56.763" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="48.967" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="48.967" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="48.967" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="48.967" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="48.967" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="48.967" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="41.173" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="41.173" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="41.173" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="41.173" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="41.173" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="41.173" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="41.173" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="33.377" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="33.377" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="33.377" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="33.377" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="33.377" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="33.377" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="33.377" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="33.377" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="33.377" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="25.583" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="25.583" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="25.583" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="25.583" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="25.583" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="25.583" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="25.583" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="25.583" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="25.583" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="17.788" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="17.788" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="17.788" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="17.788" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="17.788" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="17.788" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="17.788" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="17.788" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="17.788" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="9.992" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="9.992" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="9.992" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="9.992" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="9.992" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="9.992" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="2.198" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="2.198" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="2.198" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="2.198" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="2.198" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="243.837" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="236.042" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="189.274" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M238.239,197.07c0,1.21-0.982,2.194-2.196,2.194s-2.198-0.984-2.198-2.194c0-1.214,0.984-2.2,2.198-2.2
                        S238.239,195.856,238.239,197.07z"
                />
                <path
                  fill="#aaaaaa"
                  d="M238.239,204.863c0,1.214-0.982,2.196-2.196,2.196s-2.198-0.982-2.198-2.196
                        c0-1.216,0.984-2.196,2.198-2.196S238.239,203.647,238.239,204.863z"
                />
                <circle fill="#aaaaaa" cx="228.249" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M230.446,204.863c0,1.214-0.985,2.196-2.197,2.196c-1.215,0-2.197-0.982-2.197-2.196
                        c0-1.216,0.982-2.196,2.197-2.196C229.461,202.667,230.446,203.647,230.446,204.863z"
                />
                <circle fill="#aaaaaa" cx="228.249" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="228.246" r="2.197" />
                <circle fill="#aaaaaa" cx="220.454" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="197.07" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M222.651,204.863c0,1.214-0.984,2.196-2.197,2.196c-1.215,0-2.198-0.982-2.198-2.196
                        c0-1.216,0.983-2.196,2.198-2.196C221.667,202.667,222.651,203.647,222.651,204.863z"
                />
                <circle fill="#aaaaaa" cx="220.454" cy="212.659" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="220.454" r="2.198" />
                <circle fill="#aaaaaa" cx="212.659" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M214.856,204.863c0,1.214-0.985,2.196-2.197,2.196c-1.215,0-2.197-0.982-2.197-2.196
                        c0-1.216,0.982-2.196,2.197-2.196C213.871,202.667,214.856,203.647,214.856,204.863z"
                />
                <circle fill="#aaaaaa" cx="212.659" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="204.864" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="197.07" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M207.062,204.863c0,1.214-0.984,2.196-2.198,2.196s-2.197-0.982-2.197-2.196
                        c0-1.216,0.983-2.196,2.197-2.196S207.062,203.647,207.062,204.863z"
                />
                <circle fill="#aaaaaa" cx="204.864" cy="212.659" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="220.454" r="2.198" />
                <circle fill="#aaaaaa" cx="197.068" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="189.274" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M199.266,197.07c0,1.21-0.984,2.194-2.196,2.194c-1.215,0-2.198-0.984-2.198-2.194
                        c0-1.214,0.983-2.2,2.198-2.2C198.281,194.87,199.266,195.856,199.266,197.07z"
                />
                <path
                  fill="#aaaaaa"
                  d="M199.266,204.863c0,1.214-0.984,2.196-2.196,2.196c-1.215,0-2.198-0.982-2.198-2.196
                        c0-1.216,0.983-2.196,2.198-2.196C198.281,202.667,199.266,203.647,199.266,204.863z"
                />
                <circle fill="#aaaaaa" cx="197.068" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="189.274" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="197.07" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M191.472,204.863c0,1.214-0.983,2.196-2.198,2.196c-1.213,0-2.197-0.982-2.197-2.196
                        c0-1.216,0.984-2.196,2.197-2.196C190.488,202.667,191.472,203.647,191.472,204.863z"
                />
                <circle fill="#aaaaaa" cx="189.274" cy="212.659" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="220.454" r="2.198" />
                <circle fill="#aaaaaa" cx="181.479" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="189.274" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M183.676,197.07c0,1.21-0.982,2.194-2.196,2.194c-1.213,0-2.198-0.984-2.198-2.194
                        c0-1.214,0.985-2.2,2.198-2.2C182.693,194.87,183.676,195.856,183.676,197.07z"
                />
                <path
                  fill="#aaaaaa"
                  d="M183.676,204.863c0,1.214-0.982,2.196-2.196,2.196c-1.213,0-2.198-0.982-2.198-2.196
                        c0-1.216,0.985-2.196,2.198-2.196C182.693,202.667,183.676,203.647,183.676,204.863z"
                />
                <circle fill="#aaaaaa" cx="181.479" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="228.246" r="2.197" />
                <circle fill="#aaaaaa" cx="173.684" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="197.07" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M175.882,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.213,0-2.197-0.982-2.197-2.196
                        c0-1.216,0.984-2.196,2.197-2.196C174.897,202.667,175.882,203.647,175.882,204.863z"
                />
                <circle fill="#aaaaaa" cx="173.684" cy="212.659" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="220.454" r="2.198" />
                <circle fill="#aaaaaa" cx="165.889" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="189.274" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M168.086,197.07c0,1.21-0.982,2.194-2.196,2.194c-1.213,0-2.198-0.984-2.198-2.194
                        c0-1.214,0.985-2.2,2.198-2.2C167.104,194.87,168.086,195.856,168.086,197.07z"
                />
                <path
                  fill="#aaaaaa"
                  d="M168.086,204.863c0,1.214-0.982,2.196-2.196,2.196c-1.213,0-2.198-0.982-2.198-2.196
                        c0-1.216,0.985-2.196,2.198-2.196C167.104,202.667,168.086,203.647,168.086,204.863z"
                />
                <circle fill="#aaaaaa" cx="165.889" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="158.094" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="197.07" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M160.292,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.213,0-2.197-0.982-2.197-2.196
                        c0-1.216,0.984-2.196,2.197-2.196C159.308,202.667,160.292,203.647,160.292,204.863z"
                />
                <circle fill="#aaaaaa" cx="158.094" cy="212.659" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="197.07" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M152.498,204.863c0,1.214-0.984,2.196-2.197,2.196c-1.214,0-2.198-0.982-2.198-2.196
                        c0-1.216,0.984-2.196,2.198-2.196C151.514,202.667,152.498,203.647,152.498,204.863z"
                />
                <circle fill="#aaaaaa" cx="150.3" cy="212.659" r="2.198" />
                <circle fill="#aaaaaa" cx="142.506" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M144.703,204.863c0,1.214-0.985,2.196-2.197,2.196c-1.215,0-2.197-0.982-2.197-2.196
                        c0-1.216,0.982-2.196,2.197-2.196C143.718,202.667,144.703,203.647,144.703,204.863z"
                />
                <circle fill="#aaaaaa" cx="142.506" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="134.71" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="197.07" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M136.908,204.863c0,1.214-0.984,2.196-2.197,2.196c-1.214,0-2.198-0.982-2.198-2.196
                        c0-1.216,0.984-2.196,2.198-2.196C135.924,202.667,136.908,203.647,136.908,204.863z"
                />
                <circle fill="#aaaaaa" cx="126.916" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M129.113,204.863c0,1.214-0.985,2.196-2.197,2.196c-1.215,0-2.197-0.982-2.197-2.196
                        c0-1.216,0.982-2.196,2.197-2.196C128.128,202.667,129.113,203.647,129.113,204.863z"
                />
                <circle fill="#aaaaaa" cx="119.121" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="197.07" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M-2.501,104.413c-0.343-1.273-0.147-2.878-0.15-4.022
                    c-0.007-2.65,0.273-5.425,0.494-8.075c0.168-2.029-0.092-4.561,1.058-6.355c1.471-2.297,5.61-1.529,7.941-2.199
                    c0.752-0.216,1.969-0.455,2.541-0.935c0.637-0.533,0.995-1.682,1.563-2.352c1.47-1.735,3.174-2.598,5.398-3.222
                    c1.648-0.463,2.027-0.951,3.116-2.479c1.068-1.501,2.025-3.168,3.173-4.512c1.477-1.73,4.732-1.352,6.965-1.125
                    c2.44,0.247,4.949-0.291,7.105,1.175c1.816,1.236,2.267,3.594,4.235,4.776c1.466,0.88,3.615,0.852,5.287,0.921
                    c1.209,0.05,2.389,0.359,3.6,0.471c2.253,0.208,4.519,0.278,6.79,0.278c2.686,0,5.62-0.724,8.25,0.08
                    c3.798,1.161,1.891,6.247,2.308,9.141c0.319,2.213,0.078,3.863-0.078,6c-0.179,2.464,0.903,4.881,0.678,7.386
                    c-0.338,3.751-0.785,7.381-0.398,11.179c0.124,1.223-0.084,2.689,0.693,3.747c1.069,1.453,1.863,1.467,3.692,1.604
                    c1.274,0.095,2.416,0.534,3.222,1.632c1,1.363,0.414,3.55,1.888,4.61c1.38,0.992,3.204,1.138,4.554,2.248
                    c1.764,1.451,2.087,4.197,3.927,5.756c1.763,1.494,4.281,1.487,5.39,3.627c0.8,1.544,0.858,3.688,2.412,4.751
                    c1.919,1.312,5.563,0.289,5.778,3.48c0.155,2.307-1.309,4.361-1.637,6.578c-0.626,4.234-0.733,9.286,1.631,13.046
                    c2.386,3.796,8.321,4.157,12.247,3.291c6.832-1.508,1.688-6.958,6.386-9.572c1.678-0.934,4.026-0.431,5.841-0.342
                    c2.612,0.127,5.251,0.218,7.867,0.217c9.604-0.061,19.206-0.122,28.81-0.183c7.389-0.047,14.778-0.094,22.168-0.141
                    c4.938-0.031,10.116-0.517,15.039-0.095c3.414,0.292,2.388,3.735,2.793,6.053c0.544,3.115,2.825,1.438,4.69,1.365
                    c1.023-0.04,1.631,0.668,2.542,0.85c2.209,0.439,4.444-0.711,6.638,0.312c2.231,1.041,2.013,2.975,2.456,4.984
                    c0.564,2.56,2.155,2.159,4.364,2.021c3.601-0.226,2.483,3.413,2.962,5.596c0.607,2.774,3.58,2.448,5.871,2.57
                    c4.139,0.222,9.579,1.093,10.87-3.788c0.387-1.46,0.992-3.594,2.571-4.171c1.952-0.715,4.968,0.098,7.094-0.211
                    c1.945-0.282,4.14-0.138,5.301-1.941c1.218-1.891,0.771-4.468,3.373-5.351c2.557-0.868,4.071,1.22,4.086,3.614
                    c0.017,2.776,0.393,6.287-0.286,9.024c-0.606,2.446-3.096,3.576-4.843,5.028c-3.014,2.504-5.208,6.109-8.051,8.917
                    c-2.181,2.154-4.627,4.04-6.5,6.487c-1.799,2.352-1.522,5.429-2.527,8.175c-1.92,5.246-8.928,5.632-8.921,11.883
                    c0.003,2.47,0.173,4.917,0.166,7.369c-0.005,1.643,0.439,4.19-0.321,5.673c-0.727,1.416-2.626,2.176-4.179,1.959
                    c-1.8-0.252-1.92-1.526-2.371-3.003c-1.286-4.212-6.46-3.95-10.064-3.95c-6.316,0-12.58,0.286-18.943,0.286
                    c-2.441,0-5.415-0.516-7.791,0c-1.479,0.321-1.842,1.237-2.565,2.463c-0.812,1.374-1.704,3.59-3.3,4.315
                    c-3.335,1.514-3.73-2.787-4.627-4.882c-0.793-1.854-1.491-1.977-3.403-2.142c-2.089-0.18-3.832-0.966-4.101-3.255
                    c-0.146-1.247,0.078-2.299-0.872-3.292c-2.645-2.766-7.364-2.041-10.795-2.041c-3.445,0-6.891,0-10.337,0
                    c-2.497,0-6.652,0.826-8.037-1.466c-0.937-1.552-0.695-3.635-2.205-4.905c-1.428-1.202-3.688-0.967-5.421-0.963
                    c-1.408,0.004-3.281,0.339-4.634,0.004c-2.406-0.596-4.874-3.008-6.82-4.491c-2.138-1.63-4.587-3.773-5.254-6.5
                    c-0.48-1.964-0.105-4.387-0.125-6.419c-0.019-1.924-0.584-3.51-0.5-5.427c0.183-4.161,1.383-8.253,0.05-12.355
                    c-0.493-1.517-1.229-2.46-2.654-3.194c-5.207-2.68-11.542-1.963-15.227-6.968c-1.351-1.833-2.308-3.91-2.741-6.15
                    c-0.327-1.692,0.116-3.605,0.03-5.375c-0.077-1.602-0.566-2.774-1.462-4.025c-0.691-0.966-1.419-0.793-2.452-0.773
                    c-1.105,0.021-1.4-0.111-2.365-0.625c-1.543-0.822-3.311-1.396-3.717-3.324c-0.46-2.18,1.267-4.761-0.069-6.77
                    c-1.135-1.706-3.207-3.406-4.804-4.707c-1.431-1.167-2.562-2.605-3.756-4.023c-0.933-1.107-1.625-2.515-2.812-3.156
                    c-2.483-1.341-5.203-1.608-8.062-1.472c-4.516,0.215-9.653,0.672-13.909,2.247c-3.044,1.126-5.186,4.142-7.815,5.984
                    c-1.885,1.321-3.137,2.261-4.295,4.241c-1.096,1.874-2.214,4.483-4.239,5.535c-1.272,0.661-3.072,1.056-4.481,1.41
                    c-1.879,0.474-3.502,0.584-4.919,1.896c-1.489,1.377-3.227,2.47-5.118,3.346c-1.414,0.655-2.856,1.231-4.358,1.75
                    c-1.454,0.503-2.783,1.315-4.245,1.801c-1.178,0.392-2.334,0.425-3.494,0.89c-1.7,0.681-3.476,1.425-5.368,1.525
                    c-2.264,0.12-3.333-0.254-3.784-2.5c-0.621-3.09-1.056-5.865-0.354-9.131c0.753-3.5,4.47-2.35,7.284-3.463
                    c1.747-0.691,3.498-1.665,4.666-3.187c1.331-1.735,1.144-4.07,1.057-6.231c-0.054-1.329-0.188-2.513-0.764-3.606
                    c-0.49-0.93-0.477-0.877-1.677-1.354c-0.813-0.323-1.742-0.574-2.501-1.027c-1.824-1.09-1.619-3.417-1.809-5.408
                    c-0.238-2.5-0.439-5.059-1.22-7.436c-0.369-1.127-0.884-2.354-1.937-3.002c-1.088-0.67-2.567-0.201-3.587-1.053
                    C-2.084,105.492-2.347,104.986-2.501,104.413z"
              />
            </g>
            <g id="Canada_1_">
              <g
                id="Canada"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="275.017" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="9.991" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="243.837" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="236.042" cy="9.991" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="9.991" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="220.454" cy="9.991" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="212.659" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="204.864" cy="17.786" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="197.068" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="189.274" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="181.479" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="173.684" cy="25.581" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="165.889" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="158.094" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="33.376" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="142.506" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="134.71" cy="41.171" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="126.916" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="119.121" cy="48.966" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="56.761" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="111.325" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="103.531" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="103.531" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="103.531" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="103.531" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="103.531" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="103.531" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="103.531" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="103.531" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="95.735" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="95.735" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="95.735" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="95.735" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="95.735" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="95.735" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="95.735" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="95.735" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="87.941" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="87.941" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="87.941" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="87.941" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="87.941" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="87.941" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="80.146" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="80.146" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="80.146" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="80.146" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="80.146" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="72.353" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="72.353" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="72.353" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="72.353" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="72.353" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="290.607" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="282.811" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="282.811" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="275.017" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="243.837" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="236.042" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="220.454" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="212.659" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="212.659" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="204.864" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="204.864" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="197.068" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="189.274" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="181.479" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="173.684" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="173.684" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="165.889" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="158.094" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="142.506" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="134.71" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="134.71" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="126.916" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="126.916" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="119.121" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="119.121" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="111.325" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="111.325" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="103.531" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="103.531" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="282.811" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="275.017" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="243.837" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="236.042" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="220.454" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="220.454" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="212.659" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="204.864" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="111.325" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="103.531" cy="158.094" r="2.198" />
                <path
                  fill="none"
                  stroke="none"
                  d="M298.723,169.501c3.397-0.415,3.324-4.068,1.95-6.249
                        c-2.257-3.578-5.024-6.085-6.019-10.424c-0.565-2.468-0.835-4.284-2.192-6.362c-1.531-2.343-3.343-2.999-5.676-4.515
                        c-4.069-2.645-6.797-7.606-9.626-11.313c-2.479-3.248-4.562-6.579-5.13-10.696c-0.621-4.506,1.381-7.643,2.123-11.916
                        c0.488-2.811,0.636-4.44,2.501-6.532c2.727-3.058,1.918-6.381,0.466-9.818c-1.486-3.521-4.688-4.78-7.32-7.416
                        c-3.82-3.829-7.789-7.471-11.57-11.378c-2.731-2.822-4.744-4.038-8.076-4.992c-2.625-0.751-5.143-1.264-8.049-1.257
                        c-3.232,0.007-6.021-0.175-7.943-3.062c-1.817-2.732-1.736-7.602-2.256-10.888c-0.73-4.613-0.345-8.42,0.318-12.925
                        c0.192-1.304,0.077-2.138,1.493-2.472c1.741-0.411,3.119,0.218,4.479-1.086c1.911-1.834,0.362-4.321,1.582-6.165
                        c0.895-1.353,3.771-1.117,5.23-1.402c1.379-0.27,3.081-0.747,4.49-0.64c0.972,0.074,1.773,0.615,2.808,0.35
                        c1.732-0.446,2.641-2.384,3.513-3.711c0.682-1.039,0.863-2.565,1.928-3.224c1.188-0.736,3.391-0.527,4.229-1.665
                        c0.845-1.146,0.651-3.222,1.541-4.53c1.532-2.253,5.987-3.284,8.486-2.885c2.414,0.386,4.894,1.271,6.391-1.378
                        c1.067-1.89,0.214-4.146-1.35-5.461c-3.557-2.991-10.601-2.303-14.867-1.793c-6.275,0.748-12.72,0.688-19.069,0.688
                        c-11.736,0-23.465-0.361-34.248,4.995c-5.618,2.791-11.422,3.761-17.587,4.567c-6.272,0.82-12.456,2.194-18.179,5
                        c-4.553,2.233-8.666,5.034-13.408,6.89c-3.665,1.435-8.333,2.264-11.529,4.61c-2.405,1.766-3.188,4.763-6.309,5.755
                        c-5.474,1.74-11.576-1.057-17.054,0.833c-6.165,2.126-8.113,10.557-10.091,15.96c-1.865,5.096-7.174,7.302-7.854,13.14
                        c-0.362,3.117,1.179,7.333-2.612,8.47c-3.369,1.011-7.166-0.405-10.399,1.78c-2.741,1.853-1.515,6.15-4.989,7.262
                        c-1.815,0.581-8.485,0.666-10.247-0.069c-3.218-1.34-2.984-7.71-7.502-6.637c-4.59,1.095-2.484,11.927-2.257,15.133
                        c0.28,3.952-0.075,7.898-0.297,11.844c-0.157,2.789-0.914,7.491,1.31,9.732c1.475,1.485,2.497,0.459,4.031,1.012
                        c2.868,1.035,2.329,4.073,4.146,6.041c1.066,1.155,2.182,1.075,3.387,1.856c3.607,2.338,2.825,6.207,7.434,7.326
                        c4.703,1.142,2.186,7.479,7.501,8c3.151,0.308,3.284,1.025,3.76,3.999c0.85,5.31-4.044,12.941,0.294,17.153
                        c2.876,2.793,8.541,3.515,12.076,1.69c3.146-1.623,1.364-6.837,5.107-7.397c1.605-0.24,3.397-0.043,5.02-0.045
                        c4.306-0.003,8.611-0.006,12.916-0.009c11.349-0.008,22.697-0.017,34.046-0.025c9.099-0.007,18.198-0.013,27.298-0.02
                        c1.794-0.001,3.21-0.266,4.216,1.459c0.655,1.125,0.675,1.767,0.664,3.025c-0.009,0.977-0.082,2.426,0.979,2.927
                        c1.46,0.689,3.16,0.265,4.678,0.125c1.999-0.185,3.949,0.271,5.942,0.369c1.862,0.091,3.698,1.06,4.056,2.986
                        c0.319,1.723-0.092,3.861,2.193,4.395c1.162,0.272,2.543-0.031,3.627,0.524c1.042,0.534,1.602,2.003,1.767,3.106
                        c0.222,1.477,0.129,3.15,1.707,4.001c1.439,0.777,3.542,0.413,5.115,0.491c1.73,0.085,3.684,0.492,5.357-0.127
                        c2.003-0.741,2.18-2.871,3.147-4.539c0.412-0.708,1.052-1.785,1.682-2.283c1.386-1.093,4.183-0.72,5.844-0.805
                        c1.104-0.057,2.255,0.046,3.314,0.097c1.667,0.081,3.785-1.176,4.23-2.891c0.393-1.514,0.273-3.31,1.698-4.319
                        c1.071-0.758,2.818-0.78,4.04-0.446c1.474,0.402,2.572,1.487,2.976,2.934c0.61,2.192,0.584,4.5,0.556,6.755
                        c-0.021,1.797-0.944,3.557,0.649,4.887c1.855,1.548,4.371,1.548,6.652,1.362c2.199-0.179,4.193,0.106,5.733-1.779
                        c1.446-1.771,0.585-4.288,2.753-5.399c1.454-0.746,3.292-1.156,4.91-1.363c3.348-0.429,6.84,0.294,10.196,0.393
                        C296.577,169.145,297.723,169.623,298.723,169.501z"
                />
              </g>
            </g>
          </g>
          <g id="South_America">
            <g id="Brazil_1_">
              <g
                id="Brazil"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="345.17" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="345.17" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="337.375" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="329.581" cy="360.759" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="306.196" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="321.786" cy="360.759" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="306.196" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="360.759" r="2.198" />
                <circle fill="#aaaaaa" cx="313.991" cy="368.556" r="2.198" />
                <circle fill="#aaaaaa" cx="306.195" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="313.989" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="329.579" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="352.966" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="360.759" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="368.556" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="376.349" r="2.197" />
                <circle fill="#aaaaaa" cx="306.195" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="298.401" cy="298.399" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M300.599,306.196c0,1.212-0.983,2.195-2.196,2.195c-1.215,0-2.199-0.983-2.199-2.195
                        c0-1.215,0.984-2.2,2.199-2.2C299.615,303.996,300.599,304.981,300.599,306.196z"
                />
                <circle fill="#aaaaaa" cx="298.401" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="360.759" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="368.556" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="376.349" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="384.144" r="2.198" />
                <circle fill="#aaaaaa" cx="298.401" cy="391.938" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="298.399" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="306.196" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="360.759" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="384.144" r="2.198" />
                <circle fill="#aaaaaa" cx="282.811" cy="298.399" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M285.008,306.196c0,1.212-0.983,2.195-2.195,2.195c-1.215,0-2.199-0.983-2.199-2.195
                        c0-1.215,0.984-2.2,2.199-2.2C284.024,303.996,285.008,304.981,285.008,306.196z"
                />
                <circle fill="#aaaaaa" cx="282.811" cy="313.989" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M285.008,321.786c0,1.211-0.983,2.195-2.195,2.195c-1.215,0-2.199-0.984-2.199-2.195
                        c0-1.215,0.984-2.199,2.199-2.199C284.024,319.587,285.008,320.571,285.008,321.786z"
                />
                <circle fill="#aaaaaa" cx="282.811" cy="329.579" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M285.008,337.376c0,1.211-0.983,2.195-2.195,2.195c-1.215,0-2.199-0.984-2.199-2.195
                        c0-1.214,0.984-2.199,2.199-2.199C284.024,335.177,285.008,336.162,285.008,337.376z"
                />
                <circle fill="#aaaaaa" cx="275.017" cy="290.606" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="298.399" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="306.196" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="298.399" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="306.196" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="298.399" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="306.196" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="313.989" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="321.786" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M347.381,325.533c-0.458,0.405-1.089,0.709-1.91,1.063
                    c-1.51,0.65-2.229,1.318-2.745,2.955c-0.939,2.976-0.566,6.176-0.396,9.232c0.153,2.733-0.798,5.827-1.774,8.407
                    c-0.629,1.661-2.119,2.911-2.848,4.552c-0.965,2.172-1.057,4.631-2.086,6.785c-3.112,6.513-11.706,7.191-17.005,11.215
                    c-2.331,1.77-4.938,4.633-6.149,7.312c-1.573,3.474-2.214,8.913-5.368,11.454c-1.369,1.104-3.237,1.312-4.29,2.746
                    c-0.918,1.25-0.93,2.554-2.331,3.513c-1.241,0.85-2.621,1.149-3.752,0.195c-1.625-1.369-0.667-3.606-1.455-5.407
                    c-1.16-2.649-9.193-1.971-7.528-6.314c1.078-2.813,5.144-1.344,6.992-3.224c1.215-1.242,0.421-3.45,0.669-4.991
                    c0.368-2.287-0.234-4.277-0.401-6.711c-0.083-1.207,0.517-2.545-0.412-3.582c-1.4-1.563-4.254-0.591-5.779-2.059
                    c-1.039-1-1.066-2.726-1.202-4.124c-0.404-4.198,0.503-8.427,0.131-12.589c-0.118-1.322-0.558-2.415-1.438-3.44
                    c-1.426-1.658-2.562-1.681-4.539-2.267c-3.896-1.155-1.247-5.948-4.254-7.245c-3.985-1.716-10.63,2.617-12.751-2.954
                    c-0.536-1.408-0.801-3.33-2.044-4.191c-1.156-0.802-2.966-0.663-4.286-0.61c-2.735,0.11-6.351,1.043-8.54-1.157
                    c-1.978-1.986-1.454-5.405-1.454-8.046c0-1.76-0.067-3.721,1.575-4.765c1.401-0.889,3.379,0.427,4.783-0.332
                    c1.87-1.01,0.639-4.021,0.979-5.748c0.405-2.048,0.028-4.756,0.495-6.988c1.444-6.905,11.639,0.039,14.288-4.005
                    c1.319-2.013,0.839-5.567,3.488-6.471c4.207-1.436,3.255,4.637,5.23,6.435c2.249,2.045,6.129,1.155,8.872,1.337
                    c2.871,0.189,5.746-0.132,8.624-0.129c1.739,0.002,5.2,0.032,6.189-1.594c1.258-2.067-0.452-5.173,2.027-6.701
                    c1.544-0.952,4.218-0.34,5.377,0.972c1.714,1.938,1.464,5.419,1.541,7.814c0.034,1.07-0.284,2.966,0.484,3.801
                    c0.921,0.999,2.735,0.511,3.916,0.541c2.458,0.062,5.879-0.252,7.838,0.955c2.778,1.711,1.841,5.232,3.892,7.045
                    c1.521,1.343,4.398,1.19,6.269,1.213c3.456,0.04,6.994-0.81,10.422-0.013c4.054,0.943,3.542,4.783,3.542,8.306
                    c0,1.853,0.202,3.885,0.039,5.715C348.216,324.441,347.917,325.058,347.381,325.533z"
              />
            </g>
            <g id="Argentina_1_">
              <g
                id="Argentina"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="282.811" cy="376.349" r="2.197" />
                <circle fill="#aaaaaa" cx="282.811" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="282.811" cy="399.732" r="2.197" />
                <circle fill="#aaaaaa" cx="282.811" cy="407.529" r="2.197" />
                <circle fill="#aaaaaa" cx="275.017" cy="368.556" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="376.349" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="384.144" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="391.938" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="399.732" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="407.529" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="415.323" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="360.759" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="368.556" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="376.349" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="384.144" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="391.938" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="399.732" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="407.529" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="415.323" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="423.12" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="462.092" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="384.144" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="391.938" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="399.732" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="407.529" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="415.323" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="423.12" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M261.625,430.912c0,1.216-0.983,2.196-2.198,2.196c-1.213,0-2.197-0.98-2.197-2.196
                        c0-1.214,0.984-2.195,2.197-2.195C260.642,428.717,261.625,429.698,261.625,430.912z"
                />
                <path
                  fill="#aaaaaa"
                  d="M261.625,438.71c0,1.21-0.983,2.194-2.198,2.194c-1.213,0-2.197-0.984-2.197-2.194
                        c0-1.215,0.984-2.199,2.197-2.199C260.642,436.511,261.625,437.495,261.625,438.71z"
                />
                <circle fill="#aaaaaa" cx="259.427" cy="454.296" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="462.092" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="415.323" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="423.12" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M253.829,430.912c0,1.216-0.982,2.196-2.196,2.196c-1.213,0-2.198-0.98-2.198-2.196
                        c0-1.214,0.985-2.195,2.198-2.195C252.847,428.717,253.829,429.698,253.829,430.912z"
                />
                <path
                  fill="#aaaaaa"
                  d="M253.829,438.71c0,1.21-0.982,2.194-2.196,2.194c-1.213,0-2.198-0.984-2.198-2.194
                        c0-1.215,0.985-2.199,2.198-2.199C252.847,436.511,253.829,437.495,253.829,438.71z"
                />
                <circle fill="#aaaaaa" cx="251.632" cy="446.503" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="454.296" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M285.1,410.385c0.578-3.22,1.521-7.049,0.851-10.509
                    c-0.491-2.533-2.411-3.398-4.801-3.46c-1.209-0.03-1.647,0.483-2.54-0.76c-0.604-0.842-0.686-2.182-0.559-3.17
                    c0.385-3.002,2.407-3.962,4.976-4.93c2.96-1.115,2.861-2.424,2.235-5.338c-0.51-2.377,1.627-4.131,0.93-6.131
                    c-1.044-2.994-4.751-1.812-6.717-3.407c-2.441-1.982-0.071-5.665-3.083-7.3c-1.33-0.722-3.083-0.611-4.314-1.451
                    c-1.596-1.088-1.094-2.743-1.946-4.183c-1.602-2.705-5.12-2.123-5.914,0.824c-0.865,3.21,0.053,6.878,0.049,10.146
                    c-0.003,2.84,0.72,6.591-0.37,9.092c-1.221,2.804-5.592,0.089-7.337,3.196c-1.096,1.95-0.459,5.531-0.459,7.716
                    c0,4.696,0.001,9.393,0,14.089c0,2.373,0.976,6.516-2.333,6.907c-1.913,0.227-4.915,1.248-5.176,3.533
                    c-0.251,2.193,0.768,4.153,0.319,6.374c-0.391,1.935-0.532,3.873-0.433,5.866c0.278,5.558-0.427,11.103-0.395,16.658
                    c0.017,2.904-0.256,5.882,0.414,8.738c0.329,1.405,0.892,2.766,1.845,3.866c1.21,1.397,2.985,0.316,4.366,1.354
                    c1.078,0.81,0.815,2.372,1.265,3.541c1.53,3.982,4.579,4.731,8.494,4.735c2.778,0.003,7.47-0.562,7.448-4.3
                    c-0.015-2.534-2.826-4.624-4.568-6.112c-2.04-1.741-3.396-3.853-4.911-6.072c-0.808-1.183-3.061-3.034-2.869-4.622
                    c0.119-0.991,1.734-2.294,2.414-3.226c1.161-1.591,1.961-2.597,2.222-4.548c0.259-1.929,0.394-4.032,1.635-5.629
                    c1.315-1.693,3.43-2.964,5.096-4.324c1.697-1.387,1.723-3.055,2.905-4.711c1.289-1.806,3.41-2.675,4.74-4.353
                    c1.116-1.407,1.937-3.079,3.188-4.376C282.888,412.949,284.778,412.179,285.1,410.385z"
              />
            </g>
            <g id="Uruguay_1_">
              <g
                id="Uruguay"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="290.607" cy="391.938" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="399.732" r="2.198" />
                <circle fill="#aaaaaa" cx="282.811" cy="391.938" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M293.433,392.553c0.213,2.408,1.365,7.529-0.428,9.542
                    c-0.957,1.074-3.208,0.769-4.14-0.379c-0.86-1.061-0.587-3.009-1.295-4.161c-1.272-2.073-2.816-1.852-4.825-2.477
                    c-1.319-0.41-2.782-1.303-2.659-2.874c0.187-2.392,2.272-3.374,4.461-3.558C287.702,388.383,293.066,388.423,293.433,392.553z"
              />
            </g>
            <g id="Paraguay_1_">
              <g
                id="Paraguay"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="290.607" cy="368.556" r="2.198" />
                <circle fill="#aaaaaa" cx="290.607" cy="376.349" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M285.008,352.966c0,1.211-0.983,2.195-2.195,2.195c-1.215,0-2.199-0.984-2.199-2.195
                        c0-1.213,0.984-2.199,2.199-2.199C284.024,350.767,285.008,351.753,285.008,352.966z"
                />
                <circle fill="#aaaaaa" cx="282.811" cy="360.759" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M285.008,368.556c0,1.211-0.983,2.195-2.195,2.195c-1.215,0-2.199-0.984-2.199-2.195
                        c0-1.214,0.984-2.199,2.199-2.199C284.024,366.356,285.008,367.342,285.008,368.556z"
                />
                <circle fill="#aaaaaa" cx="275.017" cy="360.759" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M279.84,354.755c0.36-1.893,0.455-6.543,3.963-5.249
                    c5.192,1.914-1.774,13.106,4.259,15.045c2.113,0.679,4.223,0.112,5.054,2.587c0.766,2.282-0.103,4.906,0.15,7.256
                    c0.235,2.183,0.724,6.506-3.337,5.161c-2.369-0.785-1.439-4.67-3.399-6.296c-1.034-0.857-2.725-0.961-3.93-1.55
                    c-1.241-0.606-1.952-1.353-2.621-2.741c-0.783-1.626-1.084-4.239-2.806-5.074c-1.575-0.764-3.08,0.018-4.176-1.491
                    C269.313,357.327,279.194,358.146,279.84,354.755z"
              />
            </g>
            <g id="Bolivia_1_">
              <g
                id="Bolivia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="282.811" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="275.017" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="345.169" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M264.179,333.175c1.774,1.665,5.627,1.376,7.921,1.376
                    c3.313,0,5.144-0.308,5.828,3.172c0.337,1.708,0.704,3.292,2.547,3.958c1.489,0.538,3.223,0.167,4.198,1.638
                    c1.636,2.466,0.473,4.068-1.898,4.666c-2.11,0.532-3.664,1.111-4.333,3.401c-0.995,3.407-1.82,4.707-5.672,4.499
                    c-2.236-0.121-6.696,0.64-7.962-1.246c-0.953-1.418-0.176-2.777-0.7-4.263c-0.48-1.361-2.174-1.767-3.343-1.986
                    c-3.28-0.617-4.314-2.078-4.5-5.352c-0.18-3.168-0.396-6.131-0.148-9.257c0.175-2.209-0.614-7.233,2.816-7.213
                    C262.892,326.593,262.015,331.144,264.179,333.175z"
              />
            </g>
            <g id="Chile_1_">
              <g
                id="Chile"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="259.427" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="360.759" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="368.556" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="376.349" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="376.349" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="391.938" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="399.732" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="407.529" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="462.092" r="2.197" />
                <circle fill="#aaaaaa" cx="243.837" cy="415.323" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="423.12" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M246.035,430.912c0,1.216-0.983,2.196-2.198,2.196c-1.213,0-2.197-0.98-2.197-2.196
                        c0-1.214,0.984-2.195,2.197-2.195C245.052,428.717,246.035,429.698,246.035,430.912z"
                />
                <path
                  fill="#aaaaaa"
                  d="M246.035,438.71c0,1.21-0.983,2.194-2.198,2.194c-1.213,0-2.197-0.984-2.197-2.194
                        c0-1.215,0.984-2.199,2.197-2.199C245.052,436.511,246.035,437.495,246.035,438.71z"
                />
                <circle fill="#aaaaaa" cx="243.837" cy="446.503" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="454.296" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M254.004,460.484c0.814,1.373,0.41,3.637-1.183,4.347
                    c-2.146,0.955-5.503-0.05-7-1.656c-2.404-2.58-4.831-6.227-5.015-9.829c-0.105-2.072-0.058-4.165-0.086-6.239
                    c-0.079-5.744-0.158-11.488-0.237-17.232c-0.061-4.406-0.121-8.812-0.182-13.218c-0.032-2.382,0.767-4.655,2.166-6.583
                    c1.017-1.399,2.646-1.772,3.804-2.882c1.032-0.988,1.133-2.458,1.146-3.793c0.012-1.241,0.075-2.485,0.113-3.727
                    c0.153-4.992,0.304-9.984,0.456-14.977c0.076-2.495,0.152-4.991,0.229-7.487c0.065-2.146-0.45-4.419,1.902-5.513
                    c2.176-1.012,5.538,0.726,5.918-2.611c0.232-2.051,0.119-4.204,0.178-6.266c0.07-2.449,0.14-4.897,0.21-7.347
                    c0.047-1.672-0.898-5.485,1.457-5.89c1.748-0.298,4.004,0.69,4.456,2.488c0.77,3.056,0.164,7.094,0.221,10.252
                    c0,4.353,0.323,8.742,0.045,13.062c-0.059,0.915-0.931,3.078-1.688,3.688c-1.474,1.185-3.723-0.133-5.111,1.009
                    c-1.396,1.149-0.975,3.598-0.999,5.155c-0.038,2.454-0.093,4.908-0.137,7.363c-0.087,4.903-0.137,9.81,0.063,14.711
                    c0.133,3.23-2.019,2.697-4.548,3.533c-3.568,1.18-3.376,5.491-3.223,8.388c0.139,2.611-0.018,5.272-0.025,7.888
                    c-0.021,6.163-0.041,12.327-0.061,18.49c-0.008,2.577-0.215,5.229-0.146,7.781c0.046,1.672,1.268,4.475,2.885,5.258
                    C251.203,459.417,252.861,458.558,254.004,460.484z"
              />
            </g>
            <g id="Peru_1_">
              <g
                id="Peru"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="251.632" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="329.579" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="243.837" cy="306.196" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="329.579" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="236.042" cy="313.989" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="329.579" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M235.503,334.285c-1.333-1.151-2.078-2.721-2.697-4.334
                    c-0.756-1.966-0.301-3.54-0.207-5.604c0.13-2.845,0.374-5.622,0.27-8.47c-0.062-1.687-0.076-4.193,1.85-4.896
                    c1.553-0.566,4.224,0.196,5.266-1.469c0.616-0.987,0.253-2.434,0.519-3.504c0.683-2.751,3.895-3.102,6.259-3.153
                    c2.06-0.045,6.683-0.288,7.446,2.208c0.443,1.449,0.22,3.601-1.362,4.191c-1.057,0.395-2.469,0.736-3.596,0.885
                    c-2.684,0.354-2.354,2.882-2.384,4.912c-0.034,2.225,0.147,4.222,0.365,6.47c0.144,1.484,0.349,3.347,1.861,4.146
                    c1.491,0.789,4.156,0.579,5.012,2.344c0.431,0.889,0.082,2.449,0.091,3.413c0.031,3.482,0.47,7.146,0.356,10.67
                    c-0.065,2.054,0.67,5.269-1.933,5.953c-1.107,0.292-2.918,0.26-3.834-0.509c-1.221-1.023-2.254-2.59-3.309-3.791
                    c-1.371-1.562-2.992-2.872-4.367-4.427C239.472,337.471,237.358,335.888,235.503,334.285z"
              />
            </g>
            <g id="French_Guiana_1_">
              <g
                id="French_Guiana"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="298.401" cy="290.606" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M301.48,290.664c0.104-2.327-2.178-3.285-4.161-2.624
                    c-1.011,0.337-1.825,0.934-1.964,1.979C294.816,294.066,301.318,294.255,301.48,290.664z"
              />
            </g>
            <g id="Surinam_1_">
              <g
                id="Surinam"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="290.607" cy="290.606" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M291.226,293.64c2.38-0.532,2.833-3.026,1.304-4.747
                    c-1.794-2.018-5.832-0.524-4.943,2.37C288.012,292.65,289.764,293.967,291.226,293.64z"
              />
            </g>
            <g id="Guyana_1_">
              <g
                id="Guyana"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M285.008,282.812c0,1.212-0.983,2.195-2.195,2.195c-1.215,0-2.199-0.983-2.199-2.195
                        c0-1.213,0.984-2.199,2.199-2.199C284.024,280.613,285.008,281.6,285.008,282.812z"
                />
                <circle fill="#aaaaaa" cx="282.811" cy="290.606" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M281.28,280.225c1.35-0.781,3.058-0.251,3.826,1.015
                    c1.622,2.672,0.731,6.705,0.617,9.645c-0.092,2.363-2.048,4.098-4.334,2.469c-1.269-0.904-1.226-2.658-1.15-4.045
                    c0.124-2.28-1.523-7.452,0.938-9.021C281.211,280.265,281.246,280.244,281.28,280.225z"
              />
            </g>
            <g id="Venezuela_1_">
              <g
                id="Venezuela"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="275.017" cy="275.017" r="2.198" />
                <circle fill="#aaaaaa" cx="275.017" cy="282.812" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="275.017" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="282.812" r="2.198" />
                <circle fill="#aaaaaa" cx="267.222" cy="290.606" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="275.017" r="2.198" />
                <circle fill="#aaaaaa" cx="259.427" cy="282.812" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="275.017" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M261.915,286.381c-1.152-0.813-2.858-0.232-4.079-1.077
                    c-1.015-0.703-1.493-1.973-1.929-3.073c-0.658-1.663-0.379-3.841-2.552-4.354c-2.251-0.53-4.512,0.491-4.757-2.743
                    c-0.312-4.12,5.851-3.482,8.426-3.58c4.687-0.177,9.375-0.295,14.064-0.295c1.884,0,3.881-0.385,5.354,0.652
                    c2.8,1.969,1.959,7.907,1.656,10.848c-0.129,1.246-0.914,2.385-2.005,3.088c-1.354,0.873-3.207,0.219-4.484,0.998
                    c-0.871,0.531-1.113,1.704-1.295,2.617c-0.42,2.107-0.569,3.982-3.216,4.047c-2.288,0.056-2.971-2.24-3.618-4.05
                    C263.125,288.468,262.835,287.03,261.915,286.381z"
              />
            </g>
            <g id="Barbados_1_">
              <circle
                id="Barbados"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
                fill="#aaaaaa"
                cx="275.017"
                cy="267.221"
                r="2.198"
              />
              <path
                fill="none"
                stroke="none"
                d="M276.625,269.812c1.972-0.793,1.558-3.506,0.228-4.782
                    c-1.677-1.608-5.304-0.477-5.003,2.104c0.254,2.182,2.467,3.417,4.584,2.748C276.5,269.859,276.563,269.837,276.625,269.812z"
              />
            </g>
            <g id="St_Lucia_1_">
              <circle
                id="St_Lucia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
                fill="#aaaaaa"
                cx="267.222"
                cy="267.221"
                r="2.198"
              />
              <path
                fill="none"
                stroke="none"
                d="M266.255,269.605c2.404,0.654,4.43-1.192,3.293-3.635
                    c-1.171-2.511-4.924-2.314-5.041,0.774C264.454,268.132,264.774,269.202,266.255,269.605z"
              />
            </g>
            <g id="Aruba_1_">
              <g
                id="Aruba"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="251.632" cy="267.224" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M253.42,269.984c-2.903,1.133-6.504-1.878-4.115-4.73
                    C252.452,261.496,257.479,268.4,253.42,269.984z"
              />
            </g>
            <g id="Colombia_1_">
              <g
                id="Colombia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="259.427" cy="290.606" r="2.198" />
                <circle fill="#aaaaaa" cx="251.632" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="251.632" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="243.837" cy="275.017" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="282.812" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="290.606" r="2.198" />
                <circle fill="#aaaaaa" cx="243.837" cy="298.399" r="2.198" />
                <circle fill="#aaaaaa" cx="236.042" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="236.042" cy="298.399" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M232.842,294.515c0.37-4.025-1.063-8.373,0.111-12.33
                    c0.408-1.375,1.192-2.425,2.709-2.53c0.908-0.063,2.003,0.65,2.861,0.399c1.037-0.304,1.334-1.618,1.476-2.556
                    c0.259-1.707,0.379-3.983,2.106-4.961c2.497-1.412,4.401,0.458,5.021,2.86c0.27,1.044,0.034,2.056,0.796,2.907
                    c1.569,1.753,4.424,1.202,6.01,3.136c1.869,2.277,0.238,4.945,3.916,5.791c1.087,0.25,2.158,0.093,3.034,0.84
                    c1.77,1.509,1.412,4.98-0.683,5.997c-1.699,0.826-4.408,0.281-5.23,2.443c-0.498,1.312-0.107,2.977-1.09,4.152
                    c-1.301,1.558-5.416,0.667-7.193,0.689c-3.28,0.042-6.561-0.086-9.838-0.22c-1.628-0.066-3.315-0.067-3.85-2.001
                    C232.609,297.73,232.71,295.949,232.842,294.515z"
              />
            </g>
            <g id="Ecuador_1_">
              <g
                id="Ecuador"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="236.042" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="313.989" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M231.358,301.175c0.668,1.417,2.035,1.563,3.49,1.709
                    c1.704,0.17,3.206,0.431,3.909,2.216c0.394,0.999,0.311,2.53-0.386,3.396c-1.427,1.774-4.362,0.523-5.975,1.821
                    c-1.759,1.415-0.361,4.104-1.265,5.82c-1.022,1.941-4.463,1.519-5.634-0.097c-0.899-1.239-0.461-3.302-0.65-4.777
                    c-0.345-2.685-0.436-5.441-0.34-8.143c0.086-2.432-0.029-7.847,3.579-8.007C230.985,294.984,230.56,299.478,231.358,301.175z"
              />
            </g>
            <g id="Panama_1_">
              <g
                id="Panama"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="236.042" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="228.249" cy="282.812" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M237.092,271.665c1.875,0.912,2.043,3.649,1.382,5.435
                    c-1.01,2.724-3.956,0.435-5.972,1.441c-2.47,1.233-0.606,4.185-1.279,6.093c-0.53,1.504-2.399,1.388-3.887,1.375
                    c-2.956-0.024-2.941-2.643-2.643-4.913c0.159-1.206,0.349-2.382,0.417-3.598c0.074-1.329-0.525-3.068,0.268-4.263
                    c0.958-1.441,3.218-1.464,4.718-1.637C232.277,271.347,234.985,270.64,237.092,271.665z"
              />
            </g>
            <g id="Costa_Rica_1_">
              <g
                id="Costa_Rica"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="220.454" cy="275.017" r="2.198" />
                <circle fill="#aaaaaa" cx="212.659" cy="275.017" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M216.474,272.384c2.276,0.13,7.244-1.238,6.929,2.738
                    c-0.088,1.112-0.672,2.536-1.901,2.769c-1.216,0.229-2.147-0.332-3.277-0.382c-1.199-0.053-2.103,0.29-3.129,0.506
                    c-1.627,0.342-4.025,1.076-4.776-0.856c-0.525-1.354-0.683-3.631,0.646-4.605C212.438,271.474,214.839,272.289,216.474,272.384z"
              />
            </g>
            <g id="Nicaragua_1_">
              <g
                id="Nicaragua"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="220.454" cy="267.224" r="2.198" />
                <circle fill="#aaaaaa" cx="212.659" cy="267.224" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M216.23,264.391c1.883,0.188,4.96-0.872,6.344,0.521
                    c1.963,1.977,0.465,4.969-1.999,5.271c-1.982,0.244-4.265-0.627-6.156,0.049c-1.939,0.693-4.518,0.509-4.918-1.972
                    C208.868,264.336,213.466,264.113,216.23,264.391z"
              />
            </g>
            <g id="Honduras_1_">
              <g
                id="Honduras"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="220.454" cy="259.426" r="2.198" />
                <circle fill="#aaaaaa" cx="212.659" cy="259.426" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M223.52,260.054c0.354-2.068-2.15-3.727-4.187-3.784
                    c-2.73-0.077-6.956-1.126-9.142,1.075c-1.851,1.865,0.198,4.652,2.032,5.264c1.791,0.599,4.57,0.38,6.625,0.275
                    c1.475-0.075,3.09-0.255,4.05-1.501C223.249,260.93,223.445,260.482,223.52,260.054z"
              />
            </g>
            <g id="El_Salvador_1_">
              <g
                id="El_Salvador"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="204.864" cy="267.224" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M207.724,267.884c0.366-2.654-2.937-5.025-4.878-2.646
                    c-1.268,1.556-0.903,4.444,1.184,4.994C205.791,270.695,207.488,269.59,207.724,267.884z"
              />
            </g>
            <g id="Guatemala_1_">
              <g
                id="Guatemala"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="204.864" cy="259.426" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M207.974,259.759c0.378-3.974-6.504-4.685-6.375-0.5
                    c0.053,1.735,1.445,2.624,3.094,2.902C206.644,262.49,207.781,261.777,207.974,259.759z"
              />
            </g>
            <g id="Belize_2_">
              <g
                id="Belize"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="204.864" cy="251.633" r="2.198" />
              </g>
              <path
                id="Belize_1_"
                fill="none"
                stroke="none"
                d="M208.099,251.383
                    c-0.153-1.847-2.082-2.747-3.756-2.497c-0.773,0.115-1.74,0.497-2.17,1.202C199.67,254.196,208.546,256.797,208.099,251.383z"
              />
            </g>
            <g id="Mexico_1_">
              <g
                id="Mexico"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M214.856,243.837c0,1.213-0.985,2.196-2.197,2.196c-1.215,0-2.197-0.983-2.197-2.196
                        c0-1.216,0.982-2.195,2.197-2.195C213.871,241.642,214.856,242.621,214.856,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M207.062,243.837c0,1.213-0.984,2.196-2.198,2.196s-2.197-0.983-2.197-2.196
                        c0-1.216,0.983-2.195,2.197-2.195S207.062,242.621,207.062,243.837z"
                />
                <circle fill="#aaaaaa" cx="197.068" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="197.068" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="189.274" cy="251.633" r="2.198" />
                <circle fill="#aaaaaa" cx="189.274" cy="259.426" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M183.676,243.837c0,1.213-0.982,2.196-2.196,2.196c-1.213,0-2.198-0.983-2.198-2.196
                        c0-1.216,0.985-2.195,2.198-2.195C182.693,241.642,183.676,242.621,183.676,243.837z"
                />
                <circle fill="#aaaaaa" cx="181.479" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="181.479" cy="259.426" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M175.882,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.213,0-2.197-0.983-2.197-2.196
                        c0-1.216,0.984-2.195,2.197-2.195C174.897,241.642,175.882,242.621,175.882,243.837z"
                />
                <circle fill="#aaaaaa" cx="173.684" cy="251.633" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M168.086,243.837c0,1.213-0.982,2.196-2.196,2.196c-1.213,0-2.198-0.983-2.198-2.196
                        c0-1.216,0.985-2.195,2.198-2.195C167.104,241.642,168.086,242.621,168.086,243.837z"
                />
                <circle fill="#aaaaaa" cx="165.889" cy="251.633" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M183.676,236.044c0,1.21-0.982,2.194-2.196,2.194c-1.213,0-2.198-0.984-2.198-2.194
                        c0-1.215,0.985-2.201,2.198-2.201C182.693,233.843,183.676,234.829,183.676,236.044z"
                />
                <circle fill="#aaaaaa" cx="173.684" cy="228.246" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M175.882,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.213,0-2.197-0.984-2.197-2.194
                        c0-1.215,0.984-2.201,2.197-2.201C174.897,233.843,175.882,234.829,175.882,236.044z"
                />
                <circle fill="#aaaaaa" cx="165.889" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="165.889" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M168.086,236.044c0,1.21-0.982,2.194-2.196,2.194c-1.213,0-2.198-0.984-2.198-2.194
                        c0-1.215,0.985-2.201,2.198-2.201C167.104,233.843,168.086,234.829,168.086,236.044z"
                />
                <circle fill="#aaaaaa" cx="158.094" cy="220.454" r="2.198" />
                <circle fill="#aaaaaa" cx="158.094" cy="228.246" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M160.292,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.213,0-2.197-0.984-2.197-2.194
                        c0-1.215,0.984-2.201,2.197-2.201C159.308,233.843,160.292,234.829,160.292,236.044z"
                />
                <circle fill="#aaaaaa" cx="150.3" cy="220.454" r="2.198" />
                <circle fill="#aaaaaa" cx="150.3" cy="228.246" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M152.498,236.044c0,1.21-0.984,2.194-2.197,2.194c-1.214,0-2.198-0.984-2.198-2.194
                        c0-1.215,0.984-2.201,2.198-2.201C151.514,233.843,152.498,234.829,152.498,236.044z"
                />
                <circle fill="#aaaaaa" cx="142.506" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="142.506" cy="228.246" r="2.197" />
                <circle fill="#aaaaaa" cx="134.71" cy="212.659" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M161.431,242.76c-1.391-3.096-4.422-2.556-7.227-2.493
                    c-2.826,0.062-6.07-1.041-7.646-3.551c-0.728-1.157-1.393-2.5-2.621-3.219c-1.427-0.834-2.715-0.674-3.754-2.201
                    c-1.743-2.563-0.786-6.364-1.881-9.041c-1.192-2.914-3.991-4.133-6.208-6.324c-4.182-4.134,2.417-9.326,5.496-4.037
                    c1.497,2.571,0.641,5.123,4.351,5.482c5.811,0.564,12.142,0.219,17.991,0.009c2.176-0.078,6.529-0.75,8.13,0.875
                    c1.849,1.878,0.094,5.521,2.334,7.125c2.36,1.689,5.454-1.464,6.527,2.504c0.811,3.002,0.001,6.149,4.014,5.297
                    c3.2-0.68,3.524,1.414,3.662,4.328c0.198,4.181,0.328,9.014,5.116,9.974c2.88,0.578,8.238,0.478,10.342-2.009
                    c1.186-1.4,1.941-3.35,3.452-4.451c1.941-1.414,3.59-0.304,5.757-0.31c2.567-0.008,6.599-0.35,6.5,3.5
                    c-0.098,3.784-4.893,2.153-7.32,2.573c-2.311,0.399-5.891-0.245-7.388,1.922c-1.002,1.45-1.366,3.026-1.459,4.838
                    c-0.1,1.935,0.5,3.619,0.5,5.54c0,2.821-1.947,3.833-4.535,3.963c-3.872,0.193-7.801,0.508-11.678,0.331
                    c-2.024-0.093-3.857-0.483-5.163-1.961c-1.62-1.836-0.607-4.481-3.122-5.786c-1.963-1.018-4.532-0.755-6.665-0.753
                    c-3.888,0.003-6.239,0.359-6.877-3.978C161.69,248.409,162.479,245.092,161.431,242.76z"
              />
            </g>
            <g id="Cuba_1_">
              <g
                id="Cuba"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M246.035,243.837c0,1.213-0.983,2.196-2.198,2.196c-1.213,0-2.197-0.983-2.197-2.196
                        c0-1.216,0.984-2.195,2.197-2.195C245.052,241.642,246.035,242.621,246.035,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M238.239,243.837c0,1.213-0.982,2.196-2.196,2.196s-2.198-0.983-2.198-2.196
                        c0-1.216,0.984-2.195,2.198-2.195S238.239,242.621,238.239,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M230.446,243.837c0,1.213-0.985,2.196-2.197,2.196c-1.215,0-2.197-0.983-2.197-2.196
                        c0-1.216,0.982-2.195,2.197-2.195C229.461,241.642,230.446,242.621,230.446,243.837z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M241.256,240.28c2.788,0.062,5.771,0.783,5.343,4.271
                    c-0.39,3.18-3.728,2.263-5.896,2.268c-2.981,0.006-5.957,0.232-8.938,0.232c-1.507,0-4.245,0.517-5.54-0.289
                    c-2.103-1.308-1.531-4.026,0.107-5.468c1.959-1.724,4.754-0.738,7.085-0.831c1.985-0.078,3.926-0.04,5.939-0.146
                    C239.946,240.287,240.596,240.266,241.256,240.28z"
              />
            </g>
            <g id="Turks_and_Caicos_1_">
              <g
                id="Turks_and_Caicos"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M253.829,243.837c0,1.213-0.982,2.196-2.196,2.196c-1.213,0-2.198-0.983-2.198-2.196
                        c0-1.216,0.985-2.195,2.198-2.195C252.847,241.642,253.829,242.621,253.829,243.837z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M252.265,246.718c2.762-0.294,3.196-3.528,1.32-5.23
                    c-1.875-1.701-5.662-0.637-5.109,2.27C248.804,245.484,250.484,246.907,252.265,246.718z"
              />
            </g>
            <g id="Jamaica_1_">
              <g
                id="Jamaica"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="236.042" cy="251.633" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M238.14,254.259c-1.508,1.215-3.985,0.855-4.924-0.705
                    c-1.34-2.229,0.113-5.651,2.943-5.065C238.553,248.984,240.312,252.509,238.14,254.259z"
              />
            </g>
            <g id="Haiti_1_">
              <g
                id="Haiti"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="243.837" cy="251.633" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M243.894,254.673c-0.361,0.055-0.73,0.048-1.093-0.033
                    c-2.606-0.587-2.479-4.568-0.325-5.521c1.885-0.832,4.023,0.009,4.313,2.165C247.004,252.88,245.54,254.421,243.894,254.673z"
              />
            </g>
            <g id="Dominican_Republic_1_">
              <g
                id="Dominican_Republic"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="251.632" cy="251.633" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M252.903,248.836c-3.436-1.947-5.703,3.029-3.101,5.251
                    C253.317,257.087,256.302,250.762,252.903,248.836z"
              />
            </g>
            <g id="Puerto_Rico_1_">
              <g
                id="Puerto_Rico"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="259.427" cy="251.633" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M261.569,254.189c-1.563,1.371-4.426,0.398-5.082-1.412
                    c-0.827-2.278,1.374-4.932,3.795-3.896C262.235,249.718,263.3,252.672,261.569,254.189z"
              />
            </g>
            <g id="Virgin_Islands_1_">
              <g
                id="Virgin_Islands"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="267.222" cy="251.633" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M266.386,255.069c3.703,0.951,5.382-5.506,1.379-6.518
                    C262.999,247.347,262.868,254.165,266.386,255.069z"
              />
            </g>
          </g>
          <g id="Australia_1">
            <g id="Australia_2_">
              <g
                id="Australia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="836.243" cy="368.556" r="2.197" />
                <circle fill="#aaaaaa" cx="836.243" cy="376.349" r="2.197" />
                <circle fill="#aaaaaa" cx="836.243" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="828.448" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="368.556" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="376.349" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="384.144" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="391.938" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="399.732" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="352.763" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="368.556" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="376.349" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="384.144" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="391.938" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="399.732" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="407.529" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="415.323" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M815.058,337.376c0,1.211-0.984,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.214,0.985-2.199,2.196-2.199C814.073,335.177,815.058,336.162,815.058,337.376z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,345.169c0,1.214-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.195,2.196-2.195C814.073,342.974,815.058,343.956,815.058,345.169z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,352.966c0,1.211-0.984,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.213,0.985-2.199,2.196-2.199C814.073,350.767,815.058,351.753,815.058,352.966z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,360.759c0,1.215-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.982-2.196-2.197
                        c0-1.214,0.985-2.195,2.196-2.195C814.073,358.563,815.058,359.545,815.058,360.759z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,368.556c0,1.211-0.984,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.214,0.985-2.199,2.196-2.199C814.073,366.356,815.058,367.342,815.058,368.556z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,376.349c0,1.213-0.984,2.196-2.199,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        s0.985-2.198,2.196-2.198C814.073,374.15,815.058,375.136,815.058,376.349z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,384.144c0,1.213-0.984,2.195-2.199,2.195c-1.211,0-2.196-0.982-2.196-2.195
                        c0-1.215,0.985-2.197,2.196-2.197C814.073,381.946,815.058,382.929,815.058,384.144z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,391.938c0,1.212-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.213,0.985-2.197,2.196-2.197C814.073,389.741,815.058,390.726,815.058,391.938z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,399.732c0,1.214-0.984,2.196-2.199,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.213,0.985-2.196,2.196-2.196C814.073,397.536,815.058,398.52,815.058,399.732z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,407.529c0,1.212-0.984,2.196-2.199,2.196c-1.211,0-2.196-0.984-2.196-2.196
                        c0-1.214,0.985-2.198,2.196-2.198C814.073,405.331,815.058,406.315,815.058,407.529z"
                />
                <circle fill="#aaaaaa" cx="805.065" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="352.966" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="368.556" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="376.349" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="384.144" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="391.938" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="399.732" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M799.468,345.169c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.195,2.196-2.195C798.482,342.974,799.468,343.956,799.468,345.169z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,352.966c0,1.211-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.213,0.985-2.199,2.196-2.199C798.482,350.767,799.468,351.753,799.468,352.966z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,360.759c0,1.215-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.982-2.196-2.197
                        c0-1.214,0.985-2.195,2.196-2.195C798.482,358.563,799.468,359.545,799.468,360.759z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,368.556c0,1.211-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.214,0.985-2.199,2.196-2.199C798.482,366.356,799.468,367.342,799.468,368.556z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,376.349c0,1.213-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        s0.985-2.198,2.196-2.198C798.482,374.15,799.468,375.136,799.468,376.349z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,384.144c0,1.213-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.982-2.196-2.195
                        c0-1.215,0.985-2.197,2.196-2.197C798.482,381.946,799.468,382.929,799.468,384.144z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,391.938c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.213,0.985-2.197,2.196-2.197C798.482,389.741,799.468,390.726,799.468,391.938z"
                />
                <circle fill="#aaaaaa" cx="789.475" cy="337.376" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="352.966" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="368.556" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="376.349" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="384.144" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="391.938" r="2.196" />
                <circle fill="#aaaaaa" cx="781.68" cy="329.579" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="352.966" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="360.759" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="368.556" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="376.349" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="773.885" cy="337.376" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="352.966" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="368.556" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="376.349" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="384.144" r="2.196" />
                <circle fill="#aaaaaa" cx="766.09" cy="337.376" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="345.169" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="352.966" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="360.759" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="368.556" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="376.349" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="384.144" r="2.198" />
                <circle fill="#aaaaaa" cx="758.295" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="352.966" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="368.556" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="376.349" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="384.144" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="391.938" r="2.196" />
                <circle fill="#aaaaaa" cx="750.5" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="352.966" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="360.759" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="368.556" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="376.349" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="391.938" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M744.904,360.759c0,1.215-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.982-2.196-2.197
                        c0-1.214,0.985-2.195,2.196-2.195C743.919,358.563,744.904,359.545,744.904,360.759z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,368.556c0,1.211-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.214,0.985-2.199,2.196-2.199C743.919,366.356,744.904,367.342,744.904,368.556z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,376.349c0,1.213-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        s0.985-2.198,2.196-2.198C743.919,374.15,744.904,375.136,744.904,376.349z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,384.144c0,1.213-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.982-2.196-2.195
                        c0-1.215,0.985-2.197,2.196-2.197C743.919,381.946,744.904,382.929,744.904,384.144z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,391.938c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.213,0.985-2.197,2.196-2.197C743.919,389.741,744.904,390.726,744.904,391.938z"
                />
                <circle fill="#aaaaaa" cx="734.912" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="368.556" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="376.349" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M780.85,326.897c2.417-0.67,3.395,0.873,4.813,2.438
                    c1.71,1.887,2.883,2.695,5.241,3.737c2.237,0.989,2.894,2.065,4.062,4.012c1.855,3.091,5.782,3.803,9.383,3.299
                    c3.795-0.531,3.198-5.586,6.737-6.762c2.627-0.874,5.807,0.393,7.347,2.677c0.769,1.14,0.467,2.188,1.032,3.166
                    c0.904,1.568,2.965,2.19,3.843,3.871c0.866,1.662,1.254,4.415,1.346,6.299c0.139,2.852-0.012,4.713,2.388,6.581
                    c2.783,2.167,7.137,2.494,9.838,5.229c6.007,6.087,3.291,16.434,2.719,23.902c-0.617,8.062-6.551,14.562-9.06,21.975
                    c-1.136,3.355-2.18,5.826-4.808,8.506c-5.714,5.826-8.222,1.063-12.854-2.962c-2.705-2.351-4.332-5.437-7.492-7.361
                    c-1.66-1.011-3.146-1.888-4.478-3.424c-3.935-4.537-9.577-3.029-14.912-5.229c-4.187-1.726-7.703-6.567-12.079-7.493
                    c-2.522-0.534-5.241,0.156-7.312,1.276c-2.537,1.372-3.969,4.119-6.639,5.338c-2.902,1.323-6.304,0.756-9.423,0.911
                    c-2.429,0.121-5.898,0.482-8.2-0.243c-5.898-1.86-4.502-9.756-8.112-13.695c-0.725-0.791-2.14-1.342-2.819-2.104
                    c-3.415-3.831-3.587-10.904-2.776-15.657c0.43-2.524,1.303-5.041,3.006-6.997c2.637-3.028,5.593-1.856,9.207-2.359
                    c4.979-0.692,4.978-5.088,5.58-9.076c0.778-5.153,3.68-7.615,8.921-7.617c2.957-0.001,4.348,0.202,6.188-2.264
                    c1.604-2.149,1.992-3.913,4.812-4.499c2.576-0.536,5.439,0.572,7.884-0.109C777.133,331.453,778.038,327.677,780.85,326.897z"
              />
            </g>
            <g id="New_Caledonia_1_">
              <g
                id="New_Caledonia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="867.423" cy="360.759" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M867.35,364.481c6.246,0.201,4.19-7.433-0.796-6.842
                    C862.018,358.177,862.964,364.341,867.35,364.481z"
              />
            </g>
            <g
              id="Vanuatu"
              onMouseMove={(e) => this.showTooltip(e)}
              onMouseOut={this.hideTooltip.bind(this)}
            >
              <g id="Vanuatu_1_">
                <circle fill="#aaaaaa" cx="867.423" cy="352.759" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M867.35,355.893c6.246,0.201,4.19-7.433-0.796-6.842
                    C862.018,349.588,862.964,355.752,867.35,355.893z"
              />
            </g>
            <g
              id="Solomon_Islands"
              onMouseMove={(e) => this.showTooltip(e)}
              onMouseOut={this.hideTooltip.bind(this)}
            >
              <g id="Solomon_Islands_1_">
                <circle fill="#aaaaaa" cx="859.629" cy="329.579" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M859.562,333.533c6.246,0.201,4.19-8.433-0.796-7.842
                    C854.229,326.229,855.176,333.393,859.562,333.533z"
              />
            </g>
            <g id="Fiji_1_">
              <g
                id="Fiji"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="906.398" cy="352.962" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M909.796,351.995c-0.977-3.659-7.625-2.218-6.747,1.802
                    c0.981,4.494,7.687,1.929,6.758-1.759C909.804,352.024,909.8,352.01,909.796,351.995z"
              />
            </g>
            <g
              id="Samoa"
              onMouseMove={(e) => this.showTooltip(e)}
              onMouseOut={this.hideTooltip.bind(this)}
            >
              <g id="Samoa_1_">
                <circle fill="#aaaaaa" cx="921.985" cy="352.962" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M925.255,351.995c-0.977-3.659-7.625-2.218-6.747,1.802
                    c0.981,4.494,7.687,1.929,6.758-1.759C925.263,352.024,925.259,352.01,925.255,351.995z"
              />
            </g>
            <g
              id="Tonga"
              onMouseMove={(e) => this.showTooltip(e)}
              onMouseOut={this.hideTooltip.bind(this)}
            >
              <g id="Tonga_1_">
                <circle fill="#aaaaaa" cx="914.191" cy="360.759" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M910.674,361.81c0.981,4.494,7.591,2.074,6.758-1.759
                    S909.796,357.79,910.674,361.81z"
              />
            </g>
            <g id="New_Zealand_1_">
              <g
                id="New_Zealand"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="906.397" cy="407.529" r="2.198" />
                <circle fill="#aaaaaa" cx="898.601" cy="399.732" r="2.196" />
                <circle fill="#aaaaaa" cx="898.601" cy="407.529" r="2.196" />
                <circle fill="#aaaaaa" cx="898.601" cy="415.323" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="391.938" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="399.732" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="407.529" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="415.323" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="423.12" r="2.196" />
                <circle fill="#aaaaaa" cx="883.013" cy="423.12" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M885.211,430.912c0,1.216-0.984,2.196-2.199,2.196c-1.212,0-2.196-0.98-2.196-2.196
                        c0-1.214,0.984-2.195,2.196-2.195C884.227,428.717,885.211,429.698,885.211,430.912z"
                />
                <circle fill="#aaaaaa" cx="875.219" cy="423.12" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M877.415,430.912c0,1.216-0.982,2.196-2.197,2.196c-1.214,0-2.195-0.98-2.195-2.196
                        c0-1.214,0.981-2.195,2.195-2.195C876.433,428.717,877.415,429.698,877.415,430.912z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M887.099,399.196c0,5.415,2.36,15.528-3.567,18.794
                    c-3.385,1.865-9.327-0.717-12.17,1.841c-2.412,2.172-1.066,7.759-0.857,10.516c0.272,3.598,1.886,6.422,6.032,6.793
                    c2.892,0.259,5.792-0.339,8.08-1.93c3.48-2.42,7.713-6.129,10.435-9.383c2.549-3.046,5.451-5.76,8.2-8.619
                    c2.646-2.753,8.283-6.779,7.52-11.256c-0.503-2.948-5.119-4.783-6.825-7.438c-1.389-2.16-4.089-3.23-5.886-5.284
                    c-2.109-2.411-3.589-5.835-7.676-5.104C885.714,388.963,887.099,395.835,887.099,399.196z"
              />
            </g>
            <g id="Papua_New_Guinea_1_">
              <g
                id="Papua_New_Guinea"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="851.833" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="844.038" cy="321.786" r="2.196" />
                <circle fill="#aaaaaa" cx="836.243" cy="313.989" r="2.197" />
                <circle fill="#aaaaaa" cx="828.448" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="329.579" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="321.786" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M815.058,313.989c0,1.215-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.982-2.196-2.197
                        s0.985-2.195,2.196-2.195C814.073,311.794,815.058,312.774,815.058,313.989z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,321.786c0,1.211-0.984,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.215,0.985-2.199,2.196-2.199C814.073,319.587,815.058,320.571,815.058,321.786z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M833.1,310.551c1.402,0,3.081-0.249,4.336,0.375
                    c1.657,0.824,2.496,2.837,3.97,3.921c3.519,2.59,8.356,0.396,11.777,2.916c3.287,2.421,2.841,6.768-0.949,8.211
                    c-2.308,0.879-6.464,1.592-8.862,0.732c-2.25-0.808-3.274-3.6-5.015-5.062c-2.375-1.997-7.119-3.582-10.258-2.259
                    c-4.42,1.863,0.99,3.758,2.333,5.161c3.098,3.237,4.432,8.805-1.508,9.175c-2.704,0.169-3.395-2.437-5.072-4.129
                    c-2.34-2.359-5.302-3.226-8.396-3.994c-1.372-0.341-3.233-0.497-4.395-1.336c-3.049-2.203-1.677-8.434-1.396-11.484
                    c0.397-4.316,5.061-2.411,8.188-2.412C822.919,310.363,828.018,310.551,833.1,310.551z"
              />
            </g>
          </g>
          <g id="Asia">
            <g id="Mongolia_1_">
              <g
                id="Mongolia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="734.912" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="727.116" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="719.322" cy="158.094" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M713.725,150.299c0,1.213-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.984-2.196-2.197
                        c0-1.214,0.985-2.198,2.196-2.198C712.739,148.102,713.725,149.085,713.725,150.299z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,158.094c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.214,0.985-2.197,2.196-2.197C712.739,155.897,713.725,156.879,713.725,158.094z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="695.937" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="688.142" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="680.347" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="672.552" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="727.116" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="719.322" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="173.683" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M713.725,165.887c0,1.215-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.983-2.196-2.198
                        c0-1.212,0.985-2.197,2.196-2.197C712.739,163.69,713.725,164.675,713.725,165.887z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,173.683c0,1.214-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.213,0.985-2.198,2.196-2.198C712.739,171.485,713.725,172.47,713.725,173.683z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="695.937" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="688.142" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="680.347" cy="165.887" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M705.42,147.392c-2.759-0.662-4.596-1.625-5.857-4.261
                    c-1.169-2.442-2.161-5.364-5.726-3.551c-1.873,0.953-1.582,4.579-1.551,6.278c0.041,2.253,1.541,5.94-0.224,7.8
                    c-1.168,1.23-4.87,1.599-6.452,1.087c-2.081-0.674-2.026-3.356-2.439-5.098c-0.639-2.699-2.618-2.27-5.073-2.264
                    c-2.782,0.006-6.753-1.792-8.415,1.55c-0.799,1.607-0.416,4.073-0.416,5.95c0,2.054-0.59,4.335,1.206,5.463
                    c1.78,1.117,4.22-0.017,5.459,1.871c0.719,1.094,0.77,2.512,1.131,3.74c0.626,2.129,2.144,2.77,4.151,3.177
                    c3.173,0.644,2.843,2.854,4.095,5.495c1.189,2.51,3.658,2.104,5.958,1.892c3.915-0.361,8.04,0.196,11.996,0.196
                    c3.524,0,7.049,0,10.572,0c3.433,0,6.864,0,10.297,0c1.796,0,4.771,0.48,5.787-1.524c0.94-1.853,0.362-4.071,2.385-5.614
                    c1.354-1.033,3.412,0.174,4.659-0.749c2.188-1.619-0.212-6.658,0.174-8.909c0.171-0.999,0.935-1.879,0.796-3.037
                    c-0.237-1.984-2.585-2.003-4.087-1.898c-3.286,0.231-6.541-0.503-9.831-0.257c-2.919,0.218-7.415,0.807-9.295-2.135
                    c-1.217-1.904,0.5-4.715-2.625-5.206C709.912,147.045,707.56,147.906,705.42,147.392z"
              />
            </g>
            <g id="China_1_">
              <g
                id="China"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="781.68" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="773.885" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="766.09" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="758.295" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="750.5" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="158.094" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M744.904,158.094c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.214,0.985-2.197,2.196-2.197C743.919,155.897,744.904,156.879,744.904,158.094z"
                />
                <circle fill="#aaaaaa" cx="664.758" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="781.68" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="773.885" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="766.09" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="181.478" r="2.198" />
                <circle fill="#aaaaaa" cx="758.295" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="750.5" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M752.697,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.212,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.984-2.196,2.196-2.196C751.713,202.667,752.697,203.647,752.697,204.863z"
                />
                <circle fill="#aaaaaa" cx="750.5" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M744.904,165.887c0,1.215-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.983-2.196-2.198
                        c0-1.212,0.985-2.197,2.196-2.197C743.919,163.69,744.904,164.675,744.904,165.887z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,173.683c0,1.214-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.213,0.985-2.198,2.196-2.198C743.919,171.485,744.904,172.47,744.904,173.683z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,181.478c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.212,0.985-2.197,2.196-2.197C743.919,179.28,744.904,180.265,744.904,181.478z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,189.274c0,1.213-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.214,0.985-2.2,2.196-2.2C743.919,187.074,744.904,188.061,744.904,189.274z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,197.07c0,1.21-0.985,2.194-2.199,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.214,0.985-2.2,2.196-2.2C743.919,194.87,744.904,195.856,744.904,197.07z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,204.863c0,1.214-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C743.919,202.667,744.904,203.647,744.904,204.863z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,212.659c0,1.211-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.986-2.196-2.197
                        c0-1.214,0.985-2.197,2.196-2.197C743.919,210.462,744.904,211.445,744.904,212.659z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,220.454c0,1.211-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.215,0.985-2.2,2.196-2.2C743.919,218.254,744.904,219.239,744.904,220.454z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,228.246c0,1.214-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        s0.985-2.195,2.196-2.195C743.919,226.051,744.904,227.032,744.904,228.246z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,236.044c0,1.21-0.985,2.194-2.199,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C743.919,233.843,744.904,234.829,744.904,236.044z"
                />
                <circle fill="#aaaaaa" cx="734.912" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M737.107,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C736.126,202.667,737.107,203.647,737.107,204.863z"
                />
                <circle fill="#aaaaaa" cx="734.912" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M737.107,236.044c0,1.21-0.981,2.194-2.196,2.194c-1.214,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201C736.126,233.843,737.107,234.829,737.107,236.044z"
                />
                <circle fill="#aaaaaa" cx="727.116" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M729.313,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C728.329,202.667,729.313,203.647,729.313,204.863z"
                />
                <circle fill="#aaaaaa" cx="727.116" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M729.313,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C728.329,233.843,729.313,234.829,729.313,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M729.313,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C728.329,241.642,729.313,242.621,729.313,243.837z"
                />
                <circle fill="#aaaaaa" cx="719.322" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M721.518,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C720.536,202.667,721.518,203.647,721.518,204.863z"
                />
                <circle fill="#aaaaaa" cx="719.322" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M721.518,236.044c0,1.21-0.981,2.194-2.196,2.194c-1.214,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201C720.536,233.843,721.518,234.829,721.518,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M721.518,243.837c0,1.213-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195C720.536,241.642,721.518,242.621,721.518,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,181.478c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.212,0.985-2.197,2.196-2.197C712.739,179.28,713.725,180.265,713.725,181.478z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,189.274c0,1.213-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.214,0.985-2.2,2.196-2.2C712.739,187.074,713.725,188.061,713.725,189.274z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,197.07c0,1.21-0.985,2.194-2.199,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.214,0.985-2.2,2.196-2.2C712.739,194.87,713.725,195.856,713.725,197.07z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,204.863c0,1.214-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C712.739,202.667,713.725,203.647,713.725,204.863z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,212.659c0,1.211-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.986-2.196-2.197
                        c0-1.214,0.985-2.197,2.196-2.197C712.739,210.462,713.725,211.445,713.725,212.659z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,220.454c0,1.211-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.215,0.985-2.2,2.196-2.2C712.739,218.254,713.725,219.239,713.725,220.454z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,228.246c0,1.214-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        s0.985-2.195,2.196-2.195C712.739,226.051,713.725,227.032,713.725,228.246z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,236.044c0,1.21-0.985,2.194-2.199,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C712.739,233.843,713.725,234.829,713.725,236.044z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M705.928,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C704.946,202.667,705.928,203.647,705.928,204.863z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M705.928,236.044c0,1.21-0.981,2.194-2.196,2.194c-1.214,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201C704.946,233.843,705.928,234.829,705.928,236.044z"
                />
                <circle fill="#aaaaaa" cx="695.937" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M698.134,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.212,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.984-2.196,2.196-2.196C697.149,202.667,698.134,203.647,698.134,204.863z"
                />
                <circle fill="#aaaaaa" cx="695.937" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="688.142" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M690.338,204.863c0,1.214-0.982,2.196-2.196,2.196s-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196S690.338,203.647,690.338,204.863z"
                />
                <circle fill="#aaaaaa" cx="688.142" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="680.347" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M682.544,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.212,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.984-2.196,2.196-2.196C681.56,202.667,682.544,203.647,682.544,204.863z"
                />
                <circle fill="#aaaaaa" cx="680.347" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="672.552" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M674.748,204.863c0,1.214-0.982,2.196-2.196,2.196s-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196S674.748,203.647,674.748,204.863z"
                />
                <circle fill="#aaaaaa" cx="672.552" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M666.954,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C665.973,202.667,666.954,203.647,666.954,204.863z"
                />
                <circle fill="#aaaaaa" cx="664.758" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="656.963" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M659.16,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C658.176,202.667,659.16,203.647,659.16,204.863z"
                />
                <circle fill="#aaaaaa" cx="656.963" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="649.168" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M651.364,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C650.383,202.667,651.364,203.647,651.364,204.863z"
                />
                <circle fill="#aaaaaa" cx="649.168" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="641.373" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="633.579" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="197.07" r="2.196" />
                <circle fill="#aaaaaa" cx="656.963" cy="220.454" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M638.565,186.389c-1.894-0.104-4.88-0.826-6.589,0.315
                    c-1.634,1.092-1.293,3.298-1.377,5.014c-0.113,2.314-1.563,5.267,0.365,7.249c1.16,1.192,3.28,1.184,4.812,1.229
                    c2.939,0.09,7.033-1.183,9.113,1.564c1.847,2.44,1.452,5.764,1.398,8.626c-0.036,1.971-0.496,4.708,1.958,5.338
                    c1.977,0.507,4.147-0.781,5.183,1.434c0.726,1.552,0.083,3.603,1.018,5.027c1.083,1.651,3.738,1.361,5.467,1.365
                    c2.579,0.006,5.158,0,7.738,0c7.259,0,14.519,0,21.777,0c2.51,0,9.257-1.294,10.649,1.062c1.125,1.902,0.262,5.232,0.188,7.317
                    c-0.088,2.457-0.098,5.255,2.186,6.714c2.513,1.604,5.921,0.752,8.68,0.521c2.856-0.24,4.801-0.485,4.969,2.887
                    c0.179,3.577,2.336,5.208,5.774,5.198c3.409-0.009,5.85,0.901,7.975-2.291c1.83-2.75,1.23-5.114,5.239-5.526
                    c2.75-0.282,6.047,0.802,8.642-0.418c1.857-0.873,1.573-3.62,2.698-5.129c1.011-1.355,2.767-1.514,4.263-1.966
                    c3.563-1.077,3.131-3.962,3.213-7.038c0.092-3.474,0.184-6.947,0.276-10.421c0.099-3.672,0.194-7.345,0.292-11.017
                    c0.062-2.356-0.516-4.716-0.233-7.071c0.355-2.974,1.405-3.527,4.044-3.776c4.318-0.408,2.881-6.894,7.235-7.784
                    c1.204-0.246,2.41-0.874,3.209-1.822c1.022-1.214,0.556-2.295,1.119-3.563c1.396-3.144,6.149-1.909,7.021-5.672
                    c0.497-2.146,0.795-3.103,2.906-4.107c1.279-0.608,3.511-0.895,4.438-2.053c1.166-1.459,0.164-3.139-0.031-4.712
                    c-0.358-2.889,1.78-6.792-2.408-7.479c-3.216-0.527-9.445,1.271-11.212-2.512c-0.614-1.317-0.951-2.402-1.668-3.673
                    c-1.236-2.194-2.892-1.632-4.669-2.755c-1.633-1.032-2.393-3.275-3.344-4.858c-0.721-1.201-0.936-2.337-2.441-2.726
                    c-1.162-0.3-2.965-0.052-4.171,0.004c-1.989,0.092-5.696,0.189-6.796,2.074c-1.182,2.027-0.537,5.732-0.537,8.092
                    c0,1.286,0.491,3.289-0.352,4.415c-0.644,0.857-1.759,0.845-2.778,1.069c-5.508,1.21-5.677,5.489-4.907,10.424
                    c0.373,2.39,1.793,5.174-1.463,5.426c-2.392,0.185-4.659-0.725-5.341,2.164c-0.521,2.201-0.426,4.263-2.769,5.212
                    c-1.355,0.549-2.77,0.387-4.198,0.355c-3.227-0.071-6.453-0.142-9.679-0.213c-7.26-0.16-14.233,0.213-21.382,0.285
                    c-2.478,0.025-4.659,0.779-7.171,0.167c-3.496-0.852-2.697-4.555-4.494-6.935c-1.625-2.15-4.562-0.153-5.968-3.028
                    c-0.711-1.454-0.617-3.608-1.649-4.815c-1.5-1.753-4.345-0.894-5.888-2.489c-1.265-1.307-0.849-3.533-1.832-4.996
                    c-1.555-2.312-5.1-1.551-5.463,1.125c-0.437,3.213,0.47,5.503-3.667,5.667c-1.463,0.058-2.511-0.09-3.579,1.042
                    c-1.137,1.204-0.603,2.225-1.078,3.801c-0.938,3.105-4.519,2.41-6.369,4.26c-2.407,2.406-0.482,7.696-0.725,10.625
                    C645.824,186.933,641.829,186.568,638.565,186.389z"
              />
            </g>
            <g id="North_Korea_1_">
              <g
                id="North_Korea"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="773.885" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="766.09" cy="189.274" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M774.766,178.551c2.016,0.784,2.394,4.798,2.5,6.867
                    c0.128,2.458,0.25,7.288-3.62,6.929c-1.739-0.161-3.178-0.418-4.917-0.287c-2.111,0.16-4.536,0.604-5.207-1.867
                    c-0.606-2.238,1.145-3.736,3.278-4.062c2.618-0.399,3.305-1.925,4.048-4.5C771.362,179.845,772.561,177.694,774.766,178.551z"
              />
            </g>
            <g id="Japan_1_">
              <g
                id="Japan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M815.058,181.478c0,1.214-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.212,0.985-2.197,2.196-2.197C814.073,179.28,815.058,180.265,815.058,181.478z"
                />
                <circle fill="#aaaaaa" cx="805.065" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M807.261,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C806.279,202.667,807.261,203.647,807.261,204.863z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,197.07c0,1.21-0.985,2.194-2.199,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.214,0.985-2.2,2.196-2.2C798.482,194.87,799.468,195.856,799.468,197.07z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,204.863c0,1.214-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C798.482,202.667,799.468,203.647,799.468,204.863z"
                />
                <path
                  fill="#aaaaaa"
                  d="M791.671,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C790.689,202.667,791.671,203.647,791.671,204.863z"
                />
                <path
                  fill="#aaaaaa"
                  d="M783.877,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.212,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.984-2.196,2.196-2.196C782.893,202.667,783.877,203.647,783.877,204.863z"
                />
                <circle fill="#aaaaaa" cx="781.68" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="773.885" cy="228.246" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M805.611,209.164c1.476-0.434,2.513-1.485,3.072-3.802
                    c0.748-3.092,0.127-6.294,0.27-9.425c0.163-3.594,0.736-10.076,5.007-11.224c1.659-0.445,3.454-0.327,3.647-2.672
                    c0.129-1.571-0.89-3.145-2.422-3.778c-1.73-0.715-3.431,0.79-5.015-0.315c-1.098-0.766-1.786-2.582-2.038-3.821
                    c-0.392-1.919-1.056-3.495-3.201-3.743c-2.849-0.33-3.693,3.3-4.018,5.67c-0.423,3.092,0.469,6.197,0.147,9.294
                    c-0.267,2.571,0.233,5.927-2.676,6.999c-1.166,0.43-2.955,0.213-3.888,1.143c-1.002,0.998-0.56,2.693-0.97,3.967
                    c-0.717,2.225-2.424,2.985-4.62,3.247c-4.123,0.49-10.015-0.847-10.827,4.801c-0.604,4.192,0.365,8.512-0.77,12.64
                    c-0.317,1.156-0.543,2.859-1.209,3.849c-0.996,1.48-3.144,1.904-4.338,3.23c-1.417,1.572-2.379,3.415-1.041,5.365
                    c1.204,1.755,3.843,2.513,5.641,1.126c1.704-1.314,1.584-4.043,3.108-5.455c1.338-1.238,3.474-1.326,4.865-2.525
                    c1.514-1.306,1.361-3.069,1.427-4.992c0.081-2.352-0.301-5.326,0.804-7.512c1.192-2.362,4.087-2.179,6.358-2.179
                    c2.952,0,5.897,0.147,8.838,0.324C803.265,209.466,804.551,209.475,805.611,209.164z"
              />
            </g>
            <g id="Taiwan_1_">
              <g
                id="Taiwan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M752.697,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.212,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.984-2.201,2.196-2.201C751.713,233.843,752.697,234.829,752.697,236.044z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M747.969,234.786c-0.906,1.441-0.848,2.986,0.755,3.806
                    c1.94,0.993,4.899-0.302,4.932-2.691C753.695,232.957,749.341,232.604,747.969,234.786z"
              />
            </g>
            <g id="Macau_1_">
              <g
                id="Macau"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="727.116" cy="251.633" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M725.512,254.002c3.68,2.8,6.684-4.725,2.391-5.398
                    C724.467,248.064,722.768,251.913,725.512,254.002z"
              />
            </g>
            <g id="South_Korea_1_">
              <g
                id="South_Korea"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="773.885" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M768.285,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.982-2.196,2.196-2.196C767.304,202.667,768.285,203.647,768.285,204.863z"
                />
                <circle fill="#aaaaaa" cx="766.09" cy="197.07" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M771.266,193.884c2.942,0,6.293-0.525,6.334,3.334
                    c0.047,4.386-5.048,2.449-6.675,5.324c-0.801,1.415-0.212,3.397-1.362,4.646c-2.268,2.462-6.679,1.151-6.552-2.324
                    c0.064-1.773,0.303-2.918-0.076-4.76c-0.363-1.766-0.714-3.481,0.554-4.994C765.077,193.215,769.041,193.884,771.266,193.884z"
              />
            </g>
            <g id="India_1_">
              <g
                id="India"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="688.142" cy="228.246" r="2.196" />
                <circle fill="#aaaaaa" cx="680.347" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M682.544,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.212,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.984-2.201,2.196-2.201C681.56,233.843,682.544,234.829,682.544,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M666.954,236.044c0,1.21-0.981,2.194-2.196,2.194c-1.214,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201C665.973,233.843,666.954,234.829,666.954,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M666.954,243.837c0,1.213-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195C665.973,241.642,666.954,242.621,666.954,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M659.16,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C658.176,233.843,659.16,234.829,659.16,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M659.16,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C658.176,241.642,659.16,242.621,659.16,243.837z"
                />
                <circle fill="#aaaaaa" cx="656.963" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="649.168" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M651.364,236.044c0,1.21-0.981,2.194-2.196,2.194c-1.214,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201C650.383,233.843,651.364,234.829,651.364,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M651.364,243.837c0,1.213-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195C650.383,241.642,651.364,242.621,651.364,243.837z"
                />
                <circle fill="#aaaaaa" cx="649.168" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="259.426" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="267.224" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M643.57,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C642.586,202.667,643.57,203.647,643.57,204.863z"
                />
                <circle fill="#aaaaaa" cx="641.373" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M643.57,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C642.586,233.843,643.57,234.829,643.57,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M643.57,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C642.586,241.642,643.57,242.621,643.57,243.837z"
                />
                <circle fill="#aaaaaa" cx="641.373" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="267.224" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="275.017" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M635.774,204.863c0,1.214-0.982,2.196-2.196,2.196s-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196S635.774,203.647,635.774,204.863z"
                />
                <circle fill="#aaaaaa" cx="633.579" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M635.774,236.044c0,1.21-0.982,2.194-2.196,2.194s-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201S635.774,234.829,635.774,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M635.774,243.837c0,1.213-0.982,2.196-2.196,2.196s-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195S635.774,242.621,635.774,243.837z"
                />
                <circle fill="#aaaaaa" cx="633.579" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="259.426" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="267.224" r="2.196" />
                <circle fill="#aaaaaa" cx="625.783" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M627.98,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C626.996,233.843,627.98,234.829,627.98,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M627.98,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C626.996,241.642,627.98,242.621,627.98,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M620.185,243.837c0,1.213-0.982,2.196-2.196,2.196s-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195S620.185,242.621,620.185,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M620.185,236.044c0,1.21-0.982,2.194-2.196,2.194s-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201S620.185,234.829,620.185,236.044z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M679.271,239.312c-0.529-0.204-1.024-0.592-1.453-1.145
                    c-1.064-1.374-1.095-3.106-2.591-4.188c-3.641-2.633-7.119,1.062-7.037,4.791c0.059,2.654,0.018,4.839-1.399,7.177
                    c-1.521,2.51-4.084,4.093-5.585,6.581c-0.768,1.274-1.279,1.588-2.702,2.227c-1.29,0.579-2.297,1.322-3.284,2.425
                    c-3.389,3.785-0.244,8.797-3.278,12.899c-1.295,1.751-3.861,1.188-5.217,2.468c-1.983,1.872-1.486,6.149-5.126,6.171
                    c-3.335,0.02-6.334-4.42-8.258-6.602c-2.401-2.722-2.865-4.889-3.395-8.402c-0.323-2.147-0.4-4.889-0.51-7.32
                    c-0.176-3.894-0.04-8.348-5.005-8.343c-2.057,0.002-4.407,0.244-6.369-0.12c-4.95-0.921-4.034-7.087-3.802-10.806
                    c0.323-5.169,5.295-2.792,7.719-6.029c0.756-1.01,0.527-2.88,0.875-3.915c0.919-2.729,3.657-1.255,5.552-2.275
                    c2.199-1.186,2.346-5.498,2.526-7.688c0.097-1.164-0.554-2.201-0.676-3.332c-0.142-1.312,0.105-2.741,0.104-4.065
                    c-0.005-2.357-0.521-5.876,1.626-7.478c2.449-1.828,5.191-0.484,7.946-0.458c2.987,0.028,4.643,1.218,4.833,4.334
                    c0.184,3.014-0.254,6.008-0.491,9.006c-0.296,3.729-1.246,7.958,3.208,9.422c2.062,0.678,4.756,0.762,4.783,3.572
                    c0.015,1.499-0.238,3.1,1.173,3.967c2.955,1.815,6.94,0.627,10.159,0.5c3.625-0.144,8.237,0.406,11.592-1.324
                    c1.767-0.911,1.655-2.005,1.91-3.812c0.453-3.21,3.937-2.36,6.5-2.332c2.081,0.023,7.078-0.565,7.651,2.35
                    c0.321,1.634-0.993,2.552-2.258,3.228c-2.312,1.236-5.104-0.027-5.57,3.495C683.147,236.364,682.804,240.675,679.271,239.312z"
              />
            </g>
            <g id="Sri_Lanka_1_">
              <g
                id="Sri_Lanka"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="649.168" cy="282.812" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M650.599,286.021c2.689-1.02,2.132-4.179,0.151-5.669
                    c-2.245-1.688-5.716,0.963-4.704,3.637C646.73,285.795,648.813,286.697,650.599,286.021z"
              />
            </g>
            <g id="Nepal_1_">
              <g
                id="Nepal"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="664.758" cy="228.246" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="656.963" cy="228.246" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M650.646,217.845c0.729,0.516,1.656,2.451,2.077,3.244
                    c1.471,2.769,1.596,4.383,5.375,4.129c1.606-0.107,3.033,0.806,4.681,0.637c1.371-0.141,2.382-0.993,3.674-0.213
                    c1.44,0.869,1.7,2.68,0.949,4.104c-1.059,2.008-3.536,1.576-5.432,1.103c-0.988-0.246-2.023-0.687-3.054-0.432
                    c-1.245,0.308-0.998,1-2.488,0.723c-1.992-0.372-2.793-2.075-2.996-3.921c-0.362-3.291-2.069-3.098-4.842-3.658
                    C644.031,222.637,646.442,214.865,650.646,217.845z"
              />
            </g>
            <g id="Bhutan_1_">
              <g
                id="Bhutan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="672.552" cy="228.246" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M670.729,230.485c2.799,2.211,5.6-1.043,3.713-3.768
                    C672.128,223.375,667.092,227.61,670.729,230.485z"
              />
            </g>
            <g id="Bangladesh_1_">
              <g
                id="Bangladesh"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M682.544,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.212,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.984-2.195,2.196-2.195C681.56,241.642,682.544,242.621,682.544,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M674.748,236.044c0,1.21-0.982,2.194-2.196,2.194s-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201S674.748,234.829,674.748,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M674.748,243.837c0,1.213-0.982,2.196-2.196,2.196s-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195S674.748,242.621,674.748,243.837z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M678.52,241.223c-1.457-0.046-2.317-1.438-2.796-2.649
                    c-0.26-0.655-0.332-1.188-0.33-1.878c0.003-0.704-0.237-1.653-0.732-2.177c-0.913-0.965-2.38-1.27-3.509-0.584
                    c-1.005,0.61-1.278,1.909-1.221,3.032c0.08,1.573,0.562,3.256,0.019,4.817c-0.545,1.563-0.881,3.248,0.564,4.353
                    c1.682,1.283,3.971,1.122,5.963,1.044c1.993-0.078,3.747-0.021,5.547-0.986c1.486-0.797,1.665-2.45,0.797-3.795
                    c-0.424-0.656-1.085-1.437-1.905-1.502C680.105,240.833,679.31,241.247,678.52,241.223z"
              />
            </g>
            <g id="Burma_1_">
              <g
                id="Burma"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="695.937" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M698.134,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.212,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.984-2.201,2.196-2.201C697.149,233.843,698.134,234.829,698.134,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M698.134,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.212,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.984-2.195,2.196-2.195C697.149,241.642,698.134,242.621,698.134,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M690.338,236.044c0,1.21-0.982,2.194-2.196,2.194s-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201S690.338,234.829,690.338,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M690.338,243.837c0,1.213-0.982,2.196-2.196,2.196s-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195S690.338,242.621,690.338,243.837z"
                />
                <circle fill="#aaaaaa" cx="688.142" cy="251.633" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M691.866,231.038c-1.445,1.404-3.879,1.496-5.514,2.638
                    c-2.59,1.808-1.708,5.054-1.421,7.709c0.405,3.741-4.467,15.8,3.834,14c4.445-0.964,0.229-7.395,4.287-8.675
                    c1.176-0.371,2.695,0.336,3.889,0.017c2.438-0.653,2.557-3.504,2.512-5.575c-0.058-2.651-0.283-5.325-0.491-7.968
                    c-0.156-1.97,0.677-4.856-0.413-6.587c-1.04-1.648-3.627-2.313-4.939-0.683C692.441,227.364,693.288,229.655,691.866,231.038z"
              />
            </g>
            <g id="Laos_1_">
              <g
                id="Laos"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M713.725,259.426c0,1.214-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        s0.985-2.194,2.196-2.194C712.739,257.231,713.725,258.212,713.725,259.426z"
                />
                <path
                  fill="#aaaaaa"
                  d="M705.928,243.837c0,1.213-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195C704.946,241.642,705.928,242.621,705.928,243.837z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="251.633" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M705.724,241.73c1.158,1.139,1.042,2.09,1.042,3.784
                    c0,3.069-1.065,9.681,3.324,10.546c1.396,0.274,2.598,0.17,3.555,1.482c1.822,2.5-0.608,6.597-3.424,4.587
                    c-2.507-1.789-2.158-5.543-5.252-6.958c-1.265-0.578-3.184-0.42-4.036-1.788c-1.176-1.886,0.325-3.551,0.278-5.434
                    c-0.043-1.709-1.039-3.611-0.471-5.315C701.431,240.566,704.211,240.243,705.724,241.73z"
              />
            </g>
            <g id="Vietnam_1_">
              <g
                id="Vietnam"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="719.322" cy="259.426" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="267.224" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M713.725,243.837c0,1.213-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C712.739,241.642,713.725,242.621,713.725,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,251.633c0,1.212-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.984-2.196-2.196
                        c0-1.213,0.985-2.2,2.196-2.2C712.739,249.433,713.725,250.42,713.725,251.633z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,275.017c0,1.213-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.982-2.196-2.195
                        c0-1.215,0.985-2.196,2.196-2.196C712.739,272.82,713.725,273.802,713.725,275.017z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M710.6,240.553c3.211-0.877,4.274,2.854,4.219,5.307
                    c-0.064,2.868-0.374,6.539,2.117,8.662c1.068,0.91,2.624,0.802,3.704,1.672c0.959,0.772,1.385,2.131,1.807,3.251
                    c0.805,2.137,1.222,4.763,1.112,7.072c-0.177,3.737-2.942,4.477-5.077,7.193c-1.923,2.449-3.591,3.785-6.587,4.346
                    c-2.416,0.452-3.879-0.506-3.463-3.504c0.449-3.241,5.073-2.011,6.526-4.642c1.878-3.402,1.926-8.051,0.873-11.709
                    c-0.49-1.703-0.93-2.595-2.676-3.088c-1.479-0.417-3.033-0.193-3.979-1.686c-1.222-1.93-0.042-4.536-0.108-6.641
                    C708.996,244.533,707.148,241.495,710.6,240.553z"
              />
            </g>
            <g id="Thailand_1_">
              <g
                id="Thailand"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="703.732" cy="259.426" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="282.812" r="2.196" />
                <circle fill="#aaaaaa" cx="695.937" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="267.224" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="275.017" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M693.831,248.996c1.229-1.033,3.572-0.917,4.525,0.464
                    c1.02,1.477,0.618,3.829,1.699,5.469c1.176,1.782,3.492,1.088,5.107,2.149c1.152,0.756,2.415,2.955,1.318,4.312
                    c-1.548,1.915-4.609,1.305-6.149,3.37c-1.521,2.041-1.584,5.196-1.404,7.624c0.198,2.674,2.482,5.294,4.666,6.714
                    c2.074,1.349,5.341,4.146,2.175,6.495c-1.327,0.985-2.891,0.72-3.883-0.12c-1.76-1.49-1.307-4.543-2.705-6.205
                    c-1.399-1.664-3.901-0.245-5.52-1.396c-1.905-1.356-1.396-4.19-1.396-6.2c0-3.251,1.025-6.633,0.827-9.837
                    c-0.158-2.544-0.328-5.046-0.396-7.635C692.653,252.527,692.373,250.224,693.831,248.996z"
              />
            </g>
            <g id="Cambodia_1_">
              <g
                id="Cambodia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M713.725,267.224c0,1.21-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.985-2.196-2.195
                        c0-1.215,0.985-2.199,2.196-2.199C712.739,265.024,713.725,266.009,713.725,267.224z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="267.224" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M701.6,265.219c-1.058,0.941-1.259,3.167-0.175,4.212
                    c1.936,1.867,4.756,0.637,7.111,0.828c2.001,0.162,4.987,0.194,5.743-2.26c0.415-1.347-0.467-3.334-1.807-3.822
                    c-1.857-0.678-3.854,0.103-5.739,0.172C705.021,264.413,703.048,263.93,701.6,265.219z"
              />
            </g>
            <g id="Indonesia_1_">
              <g
                id="Indonesia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="805.065" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="321.786" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M799.468,306.196c0,1.212-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.983-2.196-2.195
                        c0-1.215,0.985-2.2,2.196-2.2C798.482,303.996,799.468,304.981,799.468,306.196z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,313.989c0,1.215-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.982-2.196-2.197
                        s0.985-2.195,2.196-2.195C798.482,311.794,799.468,312.774,799.468,313.989z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,321.786c0,1.211-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.215,0.985-2.199,2.196-2.199C798.482,319.587,799.468,320.571,799.468,321.786z"
                />
                <circle fill="#aaaaaa" cx="789.475" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="321.786" r="2.196" />
                <circle fill="#aaaaaa" cx="781.68" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="773.885" cy="298.399" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="766.09" cy="298.399" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="306.196" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="313.989" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="321.786" r="2.198" />
                <circle fill="#aaaaaa" cx="758.295" cy="298.399" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="321.786" r="2.196" />
                <circle fill="#aaaaaa" cx="750.5" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="313.989" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="329.579" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M744.904,290.606c0,1.214-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.981-2.196-2.195
                        c0-1.215,0.985-2.195,2.196-2.195C743.919,288.411,744.904,289.392,744.904,290.606z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,298.399c0,1.215-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.981-2.196-2.196
                        s0.985-2.195,2.196-2.195C743.919,296.204,744.904,297.185,744.904,298.399z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,306.196c0,1.212-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.983-2.196-2.195
                        c0-1.215,0.985-2.2,2.196-2.2C743.919,303.996,744.904,304.981,744.904,306.196z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,321.786c0,1.211-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.984-2.196-2.195
                        c0-1.215,0.985-2.199,2.196-2.199C743.919,319.587,744.904,320.571,744.904,321.786z"
                />
                <circle fill="#aaaaaa" cx="734.912" cy="298.399" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="321.786" r="2.196" />
                <circle fill="#aaaaaa" cx="727.116" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="719.322" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="321.786" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M713.725,306.196c0,1.212-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.983-2.196-2.195
                        c0-1.215,0.985-2.2,2.196-2.2C712.739,303.996,713.725,304.981,713.725,306.196z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,313.989c0,1.215-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.982-2.196-2.197
                        s0.985-2.195,2.196-2.195C712.739,311.794,713.725,312.774,713.725,313.989z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="298.399" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="695.937" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="688.142" cy="290.606" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M697.588,287.987c2.526,1.6,0.632,4.242,2.64,6.354
                    c1.971,2.072,4.76,0.164,6.299,2.852c1.268,2.212,0.969,4.384,3.778,5.392c2.123,0.762,5.187,1.438,7.422,1.258
                    c4.523-0.366,2.751-4.123,5.618-6.944c4.015-3.947,9.366-0.25,13.779-2.553c1.571-0.82,1.612-1.846,2.078-3.344
                    c2.464-7.934,9.001,1.488,12.905,2.401c4.662,1.091,10.171,1.315,15.011,1.315c2.563,0,6.342-1,8.694,0.444
                    c2.035,1.249,2.124,4.279,4.306,5.304c2.938,1.379,8.31,0.183,11.564,0.659c4.718,0.69,11.174-0.86,15.363,1.953
                    c2.915,1.958,1.327,6.001,1.236,8.894c-0.072,2.293-1.241,4.175-0.754,6.384c0.352,1.596,1.255,3.793,0.275,5.387
                    c-2.394,3.894-9.725,1.78-13.281,1.568c-6.017-0.358-12.502-0.438-18.431,0.148c-3.075,0.304-16.56-1.646-20.243,1.015
                    c-2.125,1.535-2,8.535-9.5,5.66c-3.472-1.331-6.912-3.906-10.815-5.079c-3.535-1.062-7.378-0.759-11.017-0.93
                    c-11.633-0.546-15.379-10.367-23.01-17.059c-3.152-2.764-6.583-6.099-9.57-9.365c-2.332-2.548-6.633-5.82-7.443-9.312
                    C683.065,284.235,694.426,285.985,697.588,287.987z"
              />
            </g>
            <g id="Singapore_1_">
              <g
                id="Singapore"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M713.725,298.399c0,1.215-0.985,2.196-2.199,2.196c-1.211,0-2.196-0.981-2.196-2.196
                        s0.985-2.195,2.196-2.195C712.739,296.204,713.725,297.185,713.725,298.399z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M710.376,301.139c4.561,1.357,5.288-6.211,1.736-6.149
                    c-2.851,0.049-5.397,4.852-1.978,6.07C710.216,301.089,710.297,301.115,710.376,301.139z"
              />
            </g>
            <g id="Malaysia__1">
              <g
                id="Malaysia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M729.314,290.606c0,1.214-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.981-2.196-2.195
                        c0-1.215,0.985-2.195,2.196-2.195C728.329,288.411,729.314,289.392,729.314,290.606z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,282.812c0,1.212-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.983-2.196-2.195
                        c0-1.213,0.985-2.199,2.196-2.199C743.919,280.613,744.904,281.6,744.904,282.812z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,290.606c0,1.214-0.985,2.195-2.199,2.195c-1.211,0-2.196-0.981-2.196-2.195
                        c0-1.215,0.985-2.195,2.196-2.195C712.739,288.411,713.725,289.392,713.725,290.606z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="290.606" r="2.196" />
                <circle fill="#aaaaaa" cx="695.937" cy="282.812" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M696.757,280.056c1.502,0.455,1.951,2.2,2.463,3.575
                    c0.9,2.419,2.822,3.586,5.38,3.753c1.813,0.118,3.674,0,5.492,0c4.494,0,8.987-0.114,13.481-0.153
                    c3.814-0.033,6.33-0.863,9.881-2.172c2.78-1.024,4.048-2.401,6.102-4.255c1.776-1.603,4.368-1.766,5.839,0.289
                    c2.2,3.072-1.595,4.93-4.294,4.791c-2.84-0.146-5.088-0.013-7.651,1.217c-1.268,0.607-2.352,1.234-2.876,2.591
                    c-0.664,1.718-0.033,3.447-2.483,3.884c-2.396,0.428-4.916-1.009-7.171-1.593c-2.002-0.52-4.48-0.33-6.448,0.276
                    c-1.106,0.341-1.903,1.077-3.044,1.291c-1.618,0.305-3.396,0.124-5.024,0.307c-1.341,0.15-3.277,0.41-4.423-0.523
                    c-1.586-1.293-1.229-3.956-2.038-5.62c-1.022-2.099-2.854-1.53-4.749-2.126C691.518,284.434,692.645,278.812,696.757,280.056z"
              />
            </g>
            <g id="Brunei_1_">
              <g
                id="Brunei"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="734.912" cy="290.606" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M732.733,289.23c-1.058,1.521-0.819,3.024,0.861,3.866
                    c1.623,0.813,3.543,0.334,4.298-1.393C739.274,288.545,734.694,286.404,732.733,289.23z"
              />
            </g>
            <g id="Philippines_1_">
              <g
                id="Philippines"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="766.09" cy="267.224" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="275.017" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="282.812" r="2.198" />
                <circle fill="#aaaaaa" cx="758.295" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="259.426" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="267.224" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="275.017" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="282.812" r="2.196" />
                <circle fill="#aaaaaa" cx="750.5" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="267.224" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M746.75,267.671c0.036,0.148,0.076,0.298,0.12,0.446
                    c0.677,2.283,1.613,2.286,3.592,2.85c2.835,0.807,3.638,3.016,3.638,5.752c0,3.233-0.478,7.787,2.996,9.543
                    c2.029,1.026,5.288,0.897,7.504,0.789c5.369-0.263,6.132-5.837,5.999-10.175c-0.137-4.47,0.393-11.199-3.941-14.001
                    c-1.887-1.22-3.428-0.339-4.471-2.773c-0.678-1.583-0.758-3.519-0.754-5.218c0.008-3.106,0.701-5.731-2.411-7.13
                    c-2.622-1.178-7.169-1.253-9.589,0.423c-3.085,2.136-2.5,6.058-2.5,9.537C746.933,260.94,745.964,264.433,746.75,267.671z"
              />
            </g>
            <g id="Timor_Leste">
              <circle
                id="Timor_Leste_1_"
                fill="#aaaaaa"
                cx="758.295"
                cy="329.579"
                r="2.196"
              />
              <path
                fill="none"
                stroke="none"
                d="M756.554,332.193c2.173,1.504,4.97,0.486,5.224-2.243c0.247-2.668-3.215-4.145-5.253-2.701
                    C754.943,328.368,754.949,331.083,756.554,332.193z"
              />
            </g>
          </g>
          <g id="Middle_East">
            <g id="Yemen_1_">
              <g
                id="Yemen"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="571.22" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="563.425" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="259.426" r="2.196" />
                <circle fill="#aaaaaa" cx="555.63" cy="259.426" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M560.531,254.399c0.189-1.146-0.165-2.461,0.043-3.563
                    c0.346-1.834,1.462-2.285,3.208-2.558c2.457-0.384,6.627-0.685,8.761,0.911c1.94,1.45,2.657,8.693,1.812,11.2
                    c-1.142,3.386-8.227,1.994-11.25,1.994c-2.291,0-7.521,0.997-9.518-0.044c-1.481-0.771-1.835-2.803-1.228-4.256
                    c1.522-3.644,6.142,0.093,7.833-2.725C560.372,255.061,560.475,254.737,560.531,254.399z"
              />
            </g>
            <g id="Oman_1_">
              <g
                id="Oman"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M589.007,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.984-2.195,2.195-2.195C588.022,241.642,589.007,242.621,589.007,243.837z"
                />
                <circle fill="#aaaaaa" cx="579.015" cy="251.633" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M585.183,240.663c-0.468,0.205-0.853,0.545-1.086,1.049
                    c-0.844,1.821-0.354,3.514-1.922,4.991c-1.559,1.468-3.825,1.104-5.305,2.438c-1.021,0.922-1.091,3.264-0.209,4.333
                    c0.924,1.121,3.306,1.718,4.668,1.035c0.939-0.471,1.706-2.296,2.166-3.165c0.461-0.872,1.095-1.148,1.772-1.812
                    c2.006-1.964,8.286-4.361,4.581-7.881C588.794,240.648,586.604,240.037,585.183,240.663z"
              />
            </g>
            <g id="United_Arab_Emirates_1_">
              <g
                id="United_Arab_Emirates"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M581.211,243.837c0,1.213-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195C580.229,241.642,581.211,242.621,581.211,243.837z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M578.626,246.879c4.797,0.404,4.203-6.801-0.235-6.043
                    C575.216,241.377,575.251,246.595,578.626,246.879z"
              />
            </g>
            <g id="Saudi_Arabia">
              <g
                id="Saudia_Arabia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M573.417,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C572.433,241.642,573.417,242.621,573.417,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M565.621,236.044c0,1.21-0.981,2.194-2.196,2.194c-1.214,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201C564.64,233.843,565.621,234.829,565.621,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M565.621,243.837c0,1.213-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195C564.64,241.642,565.621,242.621,565.621,243.837z"
                />
                <circle fill="#aaaaaa" cx="555.63" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M557.827,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C556.843,233.843,557.827,234.829,557.827,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M557.827,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C556.843,241.642,557.827,242.621,557.827,243.837z"
                />
                <circle fill="#aaaaaa" cx="555.63" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="547.835" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M550.031,236.044c0,1.21-0.982,2.194-2.196,2.194s-2.195-0.984-2.195-2.194
                        c0-1.215,0.981-2.201,2.195-2.201S550.031,234.829,550.031,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M550.031,243.837c0,1.213-0.982,2.196-2.196,2.196s-2.195-0.983-2.195-2.196
                        c0-1.216,0.981-2.195,2.195-2.195S550.031,242.621,550.031,243.837z"
                />
                <circle fill="#aaaaaa" cx="547.835" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M542.237,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C541.253,233.843,542.237,234.829,542.237,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M542.237,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C541.253,241.642,542.237,242.621,542.237,243.837z"
                />
                <circle fill="#aaaaaa" cx="532.246" cy="228.246" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M536.452,238.241c-0.299-2.033-1.132-5.02-3.071-6.118
                    c-1.036-0.586-2.052-0.315-3.069-1.098c-1.406-1.081-2.108-2.69-1.193-4.393c1.31-2.434,3.121-1.762,5.439-2.131
                    c1.554-0.247,2.313-1.055,2.544-2.75c0.187-1.371,0.292-2.528,1.438-3.486c1.719-1.437,2.431-0.293,4.307-0.004
                    c2.144,0.33,3.805-1.344,6.003-0.759c2.121,0.564,2.246,3.539,2.879,5.127c0.937,2.355,2.964,1.788,4.902,2.723
                    c1.397,0.674,1.915,1.658,2.216,3.182c0.278,1.406,0.497,2.488,1.688,3.362c1.275,0.936,2.792,0.627,4.071,1.363
                    c2.44,1.404,1.901,4.169,3.997,5.818c0.957,0.754,2.162,0.984,3.219,1.583c3.461,1.961,1.827,6.76-2.098,6.351
                    c-1.173-0.122-2.434-0.645-3.498-0.759c-2.565-0.275-6.017,0.658-7.266,3.129c-0.917,1.814-0.002,4.643-2.611,5.252
                    c-1.995,0.464-4.081-0.185-6.032,0.244c-1.525,0.335-2.996,0.943-4.599,0.225c-0.843-0.378-1.531-1.497-1.9-2.341
                    c-0.807-1.848-0.23-3.383-2.308-4.501c-1.926-1.036-3.598-2.161-4.239-4.31C536.717,242.096,536.732,240.142,536.452,238.241z"
              />
            </g>
            <g id="Israel__1">
              <g
                id="Israel"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M526.647,220.454c0,1.211-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.984-2.197-2.195
                        c0-1.215,0.986-2.2,2.197-2.2C525.663,218.254,526.647,219.239,526.647,220.454z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M526.592,218.677c0.037,0.083,0.072,0.171,0.104,0.264
                    c0.363,1.06,0.661,3.265-0.527,3.916c-2.496,1.368-5.898-0.705-4.542-3.54C522.522,217.442,525.574,216.411,526.592,218.677z"
              />
            </g>
            <g id="Jordan__1">
              <g
                id="Jordan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="540.04" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="220.454" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M536.961,213.098c0.311-1.128,0.588-2.31,1.668-3.077
                    c1.628-1.157,3.751-0.96,4.223,1.235c0.328,1.528-0.284,3.061-1.28,4.162c-0.999,1.104-2.186,0.997-3.378,1.685
                    c-0.934,0.538-1.913,1.724-2.348,2.778c-1.035,2.51-1.565,3.602-4.492,3.121c-2.327-0.382-3.384-2.738-1.498-4.375
                    c1.265-1.099,3.152-1.066,4.503-2.062C535.658,215.606,536.521,214.697,536.961,213.098z"
              />
            </g>
            <g id="Qatar_1_">
              <g
                id="Qatar"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M573.417,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C572.433,233.843,573.417,234.829,573.417,236.044z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M567.98,234.888c-1.055,3.301,4.863,5.618,6.344,2.693
                    c0.529-1.048,0.25-2.253-0.359-3.198C572.731,232.469,568.751,232.477,567.98,234.888z"
              />
            </g>
            <g id="Bahrain_1_">
              <g
                id="Bahrain"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="563.425" cy="228.246" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M566.724,227.404c-0.296-2.678-4.81-3.583-6.023-1.112
                    c-1.355,2.759,0.574,5.541,3.527,5.099C566.134,231.104,566.922,229.201,566.724,227.404z"
              />
            </g>
            <g id="Iraq_1_">
              <g
                id="Iraq"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M557.827,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C556.843,202.667,557.827,203.647,557.827,204.863z"
                />
                <circle fill="#aaaaaa" cx="555.63" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="220.454" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M550.031,204.863c0,1.214-0.982,2.196-2.196,2.196s-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196S550.031,203.647,550.031,204.863z"
                />
                <circle fill="#aaaaaa" cx="547.835" cy="212.659" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M546.731,215.526c-0.42-0.194-0.802-0.498-1.117-0.985
                    c-1.269-1.959-0.392-4.882-0.9-7.067c-0.314-1.351-0.942-2.461-0.139-3.765c1.003-1.627,3.009-2.004,4.737-1.541
                    c1.023,0.274,1.915,0.821,3.043,0.598c1.019-0.203,1.747-0.818,2.868-0.882c1.259-0.071,2.178-0.01,2.973,1
                    c0.862,1.096,0.458,2.563,0.524,4c0.199,4.288,0.242,8.647,0.253,12.972c0.01,3.584-4.44,5.385-5.974,1.759
                    c-0.484-1.145-0.688-2.383-1.033-3.571C551.123,215.131,548.568,216.378,546.731,215.526z"
              />
            </g>
            <g id="Lebanon_1_">
              <g
                id="Lebanon"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="532.246" cy="212.659" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M534.629,210.472c-1.982-2.326-6.031-0.904-5.41,2.195
                    c0.501,2.495,2.889,3.93,4.973,2.064C535.213,213.817,535.537,211.535,534.629,210.472z"
              />
            </g>
            <g id="Kuwait_1_">
              <g
                id="Kuwait"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="563.425" cy="220.454" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M565.314,218.315c1.205,1.185,1.606,3.404-0.001,4.541
                    c-1.512,1.068-3.484,0.569-4.449-0.977C558.99,218.879,562.867,215.909,565.314,218.315z"
              />
            </g>
            <g id="Syria_1_">
              <g
                id="Syria"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M542.237,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C541.253,202.667,542.237,203.647,542.237,204.863z"
                />
                <path
                  fill="#aaaaaa"
                  d="M534.441,204.863c0,1.214-0.982,2.196-2.196,2.196c-1.215,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.98-2.196,2.195-2.196C533.459,202.667,534.441,203.647,534.441,204.863z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M534.977,202.388c0.877,0.321,1.765,0.497,2.802,0.273
                    c0.925-0.199,1.802-0.793,2.695-0.777c2.102,0.037,2.996,2.819,2.034,4.402c-1.209,1.991-3.259,2.041-5.412,1.72
                    c-1.841-0.275-4.532,0.27-6.281-0.338c-1.048-0.365-1.834-1.249-1.966-2.284C528.325,201.292,532.35,201.422,534.977,202.388z"
              />
            </g>
            <g id="Cyprus_1_">
              <g
                id="Cyprus"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M526.647,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.197-0.982-2.197-2.196
                        c0-1.216,0.986-2.196,2.197-2.196C525.663,202.667,526.647,203.647,526.647,204.863z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M526.945,207.036c1.726-3.035-1.739-7.04-4.783-4.704
                    c-1.573,1.207-1.163,4.204,0.44,5.148C523.893,208.24,526.128,208.474,526.945,207.036z"
              />
            </g>
            <g id="Iran_1_">
              <g
                id="Iran"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M596.801,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.212,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.984-2.196,2.196-2.196C595.816,202.667,596.801,203.647,596.801,204.863z"
                />
                <circle fill="#aaaaaa" cx="594.604" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="228.246" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="579.015" cy="197.07" r="2.196" />
                <circle fill="#aaaaaa" cx="571.22" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="563.425" cy="197.07" r="2.196" />
                <circle fill="#aaaaaa" cx="555.63" cy="197.07" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M589.007,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.984-2.196,2.195-2.196C588.022,202.667,589.007,203.647,589.007,204.863z"
                />
                <circle fill="#aaaaaa" cx="586.81" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M581.211,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C580.229,202.667,581.211,203.647,581.211,204.863z"
                />
                <circle fill="#aaaaaa" cx="579.015" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M573.417,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C572.433,202.667,573.417,203.647,573.417,204.863z"
                />
                <circle fill="#aaaaaa" cx="571.22" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="220.454" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M565.621,204.863c0,1.214-0.981,2.196-2.196,2.196c-1.214,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196C564.64,202.667,565.621,203.647,565.621,204.863z"
                />
                <circle fill="#aaaaaa" cx="563.425" cy="212.659" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M580.828,231.505c-0.394-0.02-0.785-0.051-1.172-0.093
                    c-2.894-0.317-4.647-3.274-5.927-5.533c-1.214-2.142-4.119-1.883-5.286-3.839c-1.079-1.808-1.005-4.391-2.852-5.743
                    c-1.513-1.107-3.77-0.315-4.729-2.26c-0.971-1.968-0.426-4.551-0.758-6.653c-0.339-2.137-0.102-4.036-1.284-5.848
                    c-1.305-1.999-3.459-0.919-5.194-2.059c-1.708-1.121-1.611-4.3,0.396-5.122c1.132-0.463,3.43,0.067,4.671,0.066
                    c1.667-0.001,3.334-0.038,5.002-0.038c3.908,0,7.644-0.634,11.497-0.625c3.451,0.008,7.19-0.366,10.558,0.223
                    c2.746,0.478,3.604,0.606,4.144,3.432c0.34,1.779,0.138,4.236,2.454,4.599c1.484,0.232,3.032-0.466,4.29,0.646
                    c1.902,1.682,1.21,5.52,1.211,7.758c0.001,3.3,0.002,6.599,0.002,9.898c0,2.587,0.709,6.375-0.067,8.854
                    c-1.03,3.291-5.247,2.174-7.765,2.13C587.029,231.245,583.834,231.661,580.828,231.505z"
              />
            </g>
            <g id="Pakistan_1_">
              <g
                id="Pakistan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M627.98,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C626.996,202.667,627.98,203.647,627.98,204.863z"
                />
                <circle fill="#aaaaaa" cx="625.783" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="617.989" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="228.246" r="2.196" />
                <circle fill="#aaaaaa" cx="610.193" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="228.246" r="2.197" />
                <circle fill="#aaaaaa" cx="602.399" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="228.246" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M621.289,228.307c0.028-0.082,0.056-0.165,0.082-0.25
                    c0.367-1.173,0.121-2.901,1.083-3.79c1.704-1.574,4.988,0.16,6.256-2.243c0.626-1.188,0.1-2.752-0.121-3.977
                    c-0.504-2.803-0.011-6.084,0.135-8.913c0.089-1.713,0.904-4.636-0.119-6.156c-1.437-2.136-4.363-1.643-5.604,0.181
                    c-1.224,1.8-0.143,4.093-2.125,5.503c-1.62,1.154-4.536,0.469-5.503,2.541c-1.013,2.173,0.01,5.804-3.553,5.932
                    c-1.643,0.059-2.951,0.477-4.511,0.688c-1.978,0.268-3.93-0.886-5.906-0.267c-1.926,0.603-2.464,3.232-2.221,4.974
                    c0.283,2.029,0.051,3.99,0.041,6.078c-0.014,2.76,2.24,2.941,4.601,3.255c3.202,0.426,6.688,0.119,9.89,0.272
                    c1.582,0.076,3.723-0.296,5.169-0.966C620.192,230.561,620.843,229.61,621.289,228.307z"
              />
            </g>
            <g id="Afghanistan_1_">
              <g
                id="Afghanistan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="617.989" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M620.185,204.863c0,1.214-0.982,2.196-2.196,2.196s-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196S620.185,203.647,620.185,204.863z"
                />
                <path
                  fill="#aaaaaa"
                  d="M612.391,204.863c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.982-2.196-2.196
                        c0-1.216,0.985-2.196,2.196-2.196C611.406,202.667,612.391,203.647,612.391,204.863z"
                />
                <circle fill="#aaaaaa" cx="610.193" cy="212.659" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M604.595,204.863c0,1.214-0.982,2.196-2.196,2.196s-2.195-0.982-2.195-2.196
                        c0-1.216,0.981-2.196,2.195-2.196S604.595,203.647,604.595,204.863z"
                />
                <circle fill="#aaaaaa" cx="602.399" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="610.193" cy="197.07" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M606.629,196.786c0.84-2.44,2.844-2.724,5.247-2.777
                    c2.354-0.053,4.82-0.645,7.095,0.247c2.276,0.892,1.805,4.191,2,6.256c0.177,1.869,0.807,4.509-0.532,6.087
                    c-0.673,0.795-1.737,1.593-2.718,1.785c-1.113,0.218-2.308-0.124-3.342,0.402c-2.245,1.146-0.598,4.166-2.003,5.854
                    c-1.491,1.791-5.098,0.994-7.247,0.994c-1.498,0-4.127,0.552-5.124-0.653c-0.92-1.111-0.84-3.274-0.649-4.59
                    c0.255-1.768,0.203-3.606,0.118-5.487c-0.143-3.164,2.162-2.866,4.33-3.269C606.107,201.206,606.015,198.578,606.629,196.786z"
              />
            </g>
            <g id="Turkmenistan_1_">
              <g
                id="Turkmenistan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="602.399" cy="197.07" r="2.196" />
                <circle fill="#aaaaaa" cx="594.604" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="579.015" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="189.274" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M576.819,179.441c0.786-0.561,1.879-0.741,3.019-0.863
                    c2.186-0.233,5.092-0.168,7.262,0.14c3.116,0.442,2.778,3.524,4.125,5.749c1.315,2.175,3.941,1.28,5.832,3.329
                    c1.096,1.188,1.177,2.689,1.835,4.082c0.804,1.699,1.185,1.412,3.039,1.84c2.464,0.569,4.464,3.294,2.586,5.566
                    c-1.357,1.644-3.512,0.246-5.251,0.101c-1.84-0.153-3.084,1.152-4.923,0.63c-1.226-0.349-2.741-1.279-3.116-2.575
                    c-0.609-2.103-0.64-3.673-3.003-4.541c-1.793-0.657-3.873-0.64-5.705-0.769c-1.458-0.103-2.896,0.424-4.357,0.113
                    c-3.033-0.646-2.279-3.025-2.155-5.523c0.089-1.781-0.609-3.592-0.312-5.365C575.848,180.441,576.255,179.843,576.819,179.441z"
              />
            </g>
            <g id="Azerbaijan_1_">
              <g
                id="Azerbaijan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="563.425" cy="189.274" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M563.771,192.383c4.854-0.032,3.595-8.341-1.307-5.966
                    C559.352,187.926,560.265,192.406,563.771,192.383z"
              />
            </g>
            <g id="Kazakhstan_1_">
              <g
                id="Kazakhstan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="656.963" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="649.168" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="641.373" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="649.168" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="641.373" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="633.579" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="625.783" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="633.579" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="625.783" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="617.989" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="610.193" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="602.399" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="594.604" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="579.015" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="571.22" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="563.425" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="555.63" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="633.579" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="625.783" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="617.989" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="610.193" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="602.399" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="594.604" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="142.504" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M552.341,158.84c-0.02-0.812,0.29-2.11,0.885-2.705
                    c1.504-1.502,3.892-0.896,5.446-2.05c2.21-1.642,1.185-5.315,3.733-6.646c2.174-1.135,7.434-0.839,9.943-0.555
                    c1.769,0.2,3.542-0.106,5.312,0.019c1.692,0.12,3.317,0.936,5.004,0.924c2.25-0.016,6.105-0.297,7.675-2.003
                    c1.383-1.503,1.479-3.381,1.435-5.341c-0.085-3.751-1.169-9.126,4.293-8.9c2.768,0.114,5.468,0.292,8.321,0.148
                    c3.303-0.166,6.634,0.2,9.898,0.033c1.846-0.095,3.95-0.41,5.44,0.924c1.875,1.677,0.802,4.514,2.462,6.148
                    c1.744,1.717,6.015,1.381,7.533-0.453c1.531-1.849-0.713-5.608,2.027-6.907c2.031-0.963,4.533,0.766,5.074,2.646
                    c0.793,2.753-0.222,5.879,0.062,8.596c0.208,1.992,0.233,3.604,2.256,4.127c2.599,0.672,4.825,1.105,7.474,0.532
                    c1.731-0.375,2.902-0.167,4.652,0.007c1.968,0.196,3.763-0.96,5.623-0.38c1.688,0.526,2.923,2.034,3.474,3.658
                    c0.709,2.091,0.354,5.508-0.206,7.597c-0.835,3.112-2.911,2.915-5.529,3.496c-4.706,1.044-1.189,3.385-3.348,6.317
                    c-1.322,1.796-3.506,0.923-5.086,2.085c-1.885,1.387-1.359,4.286-3.034,5.789c-1.786,1.603-6.231,1.38-8.529,1.438
                    c-3.74,0.095-7.496,0-11.24,0c-2.541,0-6.857-1.346-8.921,0.731c-1.94,1.952-0.069,5.326-3.385,6.262
                    c-2.025,0.572-3.297-1.781-3.913-3.266c-0.376-0.905-0.867-2.15-1.637-2.803c-1.327-1.125-2.972-1.181-4.321-2.109
                    c-1.343-0.924-2.254-2.86-2.872-4.308c-1.469-3.443-4.716-3.257-8.23-3.257c-2.011,0-6.177-0.869-7.621,1.078
                    c-1.362,1.838,0.96,3.294,0.001,5.279c-1.615,3.343-6.42,0.307-8.162,4.135c-0.891,1.956,0.046,4.521-2.481,5.501
                    c-5.069,1.967-4.49-4.19-4.224-6.73c0.179-1.693-0.018-3.597-0.288-5.194c-0.458-2.699-0.793-2.52-3.169-3.124
                    c-2.125-0.541-2.941-0.456-3.565-2.695c-0.617-2.214-0.372-4.368-2.723-5.572c-1.595-0.816-4.565,0.358-5.41-1.782
                    C552.388,159.325,552.347,159.091,552.341,158.84z"
              />
            </g>
            <g id="Uzbekistan_1_">
              <g
                id="Uzbekistan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="617.989" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="610.193" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="602.399" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="594.604" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="173.683" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M584.283,172.244c-0.033,0.092-0.065,0.188-0.096,0.29
                    c-0.697,2.384,0.633,3.719,2.836,4.335c1.641,0.459,2.485,0.321,3.336,1.958c0.574,1.104,0.734,2.339,1.399,3.416
                    c1.681,2.719,5.429,2.221,6.885,4.896c1.256,2.307,0.582,4.912,3.956,5.245c2.273,0.225,6.143,0.596,8.336,0.008
                    c3.063-0.82,2.15-5.121,4.569-6.645c1.587-0.999,3.093-0.618,4.53-2.212c1.354-1.499,1.774-3.375-0.157-4.613
                    c-2.135-1.369-4.734,0.521-5.111,2.628c-0.277,1.547,0.267,2.206-1.042,3.296c-1.872,1.56-4.417,1.523-6.382,0.102
                    c-1.791-1.296-1.136-2.619-1.9-4.271c-1.245-2.69-5.273-2.345-6.884-4.749c-1.033-1.542-1.54-4.163-3.08-5.086
                    c-1.982-1.188-4.19,0.172-6.264,0.181C587.438,171.03,585.068,170.072,584.283,172.244z"
              />
            </g>
            <g id="Tajikistan_1_">
              <g
                id="Tajikistan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="625.783" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="617.989" cy="189.274" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M625.109,186.393c1.439-0.185,2.937,0.124,3.619,1.695
                    c0.601,1.381-0.041,2.185-0.397,3.461c-0.325,1.162-0.271,1.703,0.006,2.95c0.435,1.958,1.188,4.425-1.108,5.519
                    c-2.579,1.229-4.575-0.729-4.877-3.216c-0.286-2.37-0.498-3.114-2.963-3.901c-1.464-0.469-3.33-0.705-3.941-2.359
                    C613.381,184.954,622.323,186.75,625.109,186.393z"
              />
            </g>
            <g id="Kyrgyzstan_1_">
              <g
                id="Kyrgyzstan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="641.373" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="633.579" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="625.783" cy="181.478" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M628.594,178.958c-0.784-0.017-1.583-0.052-2.426-0.095
                    c-1.759-0.089-3.624,0.895-3.402,3.022c0.478,4.59,12.375,2.8,16.005,2.999c1.663,0.091,4.537,0.079,5.462-1.577
                    c0.91-1.633,0.42-4.376-1.762-4.836c-1.126-0.238-2.436,0.409-3.644,0.391c-1.697-0.024-3.373-0.39-5.074-0.163
                    C631.907,178.944,630.285,178.994,628.594,178.958z"
              />
            </g>
            <g id="Armenia_1_">
              <g
                id="Armenia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="555.63" cy="189.274" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M554.68,192.013c2.368,0.437,5.365-1.213,3.748-4.302
                    c-1.282-2.444-6.284-1.262-6.213,1.56C552.256,190.894,553.372,191.771,554.68,192.013z"
              />
            </g>
          </g>
          <g id="Europe">
            <g
              id="Georgia"
              onMouseMove={(e) => this.showTooltip(e)}
              onMouseOut={this.hideTooltip.bind(this)}
            >
              <path
                fill="none"
                stroke="none"
                d="M554.1,178.217c-2.233-0.101-5.1,0.052-7.318,0.44
                    c-2.079,0.364-3.053,1.561-2.311,3.689c0.937,2.687,4.62,1.955,6.796,1.871c2.385-0.092,5.328,1.648,7.101-0.783
                    C560.751,180.165,556.87,178.343,554.1,178.217z"
              />
              <g id="Georgia">
                <circle fill="#aaaaaa" cx="555.63" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="547.835" cy="181.478" r="2.196" />
              </g>
            </g>
            <g id="Russia_1_">
              <path
                fill="none"
                stroke="none"
                d="M558.637,165.717c0.531,2.119,0.387,5.457,0.129,7.667
                    c-0.299,2.572-2.517,3.857-5.003,3.508c-4.106-0.578-9.587,1.325-9.631-4.744c-0.031-4.283,0.567-8.466,0.434-12.756
                    c-0.056-1.789,0.393-4.718-1.312-5.926c-2.361-1.674-6.29,1.15-8.91,0.734c-2.297-0.364-5.149-1.921-5.746-4.348
                    c-0.419-1.704-0.685-3.371-2.559-3.998c-1.522-0.509-3.146-0.399-4.05-1.886c-0.822-1.352-0.304-3.184-1.097-4.548
                    c-1.777-3.053-5.251-0.292-6.878-3.928c-1.321-2.953-0.387-6.507-0.414-9.609c-0.033-3.77-0.065-7.539-0.099-11.308
                    c-0.025-2.926-0.051-5.853-0.076-8.779c-0.015-1.666,1.029-5.027-0.356-6.375c-1.429-1.39-3.77,0.061-5.443-0.49
                    c-3.872-1.275-1.776-10.818-1.776-13.798c0-6.165-0.58-12.585-0.994-18.764c-0.382-5.697-0.97-12.508-3.269-17.772
                    c-1.007-2.305-2.862-3.223-4.683-4.839c-2.066-1.835-3.011-3.305-5.763-1.751c-2.48,1.401-3.939,2.122-7.032,2.631
                    c-3.565,0.587-7.479,1.643-10.086-1.528c-1.364-1.659-1.915-3.414-3.608-4.884c-2.116-1.837-7.389-5.074-3.622-7.763
                    c2.362-1.687,6.134-0.883,8.812-1.574c4.736-1.222,5.754-6.785,9.725-8.526c1.839-0.807,5.379-0.326,7.389-0.236
                    c4.292,0.192,9.677-0.335,13.823,0.756c3.282,0.864,8.713,4.768,4.988,8.249c-1.453,1.358-4.266,1.152-5.498,2.689
                    c-1.173,1.462-0.683,3.969-0.683,5.812c0,5.226,0.176,10.353,0.744,15.493c0.486,4.4-0.303,13.726,5.006,14.507
                    c2.064,0.304,4.323-0.468,6.257,0.432c2.067,0.961,2.375,3.017,3.104,4.873c1.521,3.872-1.223,9.236,4.354,9.441
                    c4.188,0.154,8.411-0.008,12.601-0.013c4.772-0.005,9.545-0.011,14.318-0.016c1.755-0.002,4.364,0.5,6.014-0.292
                    c1.736-0.833,1.601-1.93,2.423-3.55c1.048-2.065,2.221-2.253,3.924-3.369c2.444-1.602,2.492-4.858,4.46-6.803
                    c1.117-1.105,2.375-1.085,3.655-1.81c1.954-1.105,2.843-3.107,4.38-4.643c0.926-0.925,1.996-1.55,2.511-2.764
                    c0.459-1.083,0.253-2.582,0.628-3.54c0.985-2.522,3.761-3.797,5.735-5.022c1.234-0.766,2.489-0.975,2.648-2.657
                    c0.372-3.981-6.213-8.587-8.761-10.816c-4.153-3.635-8.655-6.361-14.25-6.951c-2.479-0.261-4.923-0.72-7.547-0.75
                    c-5.023-0.058-5.771-2.729-9.092-5.873c-0.791-0.749-1.725-1.175-2.555-1.871c-1.405-1.177-3.302-2.984-2.819-5.014
                    c1.374-5.785,16.255-3.243,20.206-3.243c7.108,0,14.217,0,21.325,0c4.903,0,11.807-1.554,14.926,2.611
                    c1.459,1.948,1.856,3.371,4.361,3.777c2.187,0.354,3.659,0.374,5.701,1.306c4.895,2.233,5.885,7.928,7.98,12.599
                    c1.579,3.518,2.38,7.243,3.764,10.705c1.019,2.551,2.725,4.999,3.937,7.611c2.108,4.544,5.107,5.631,9.843,6.24
                    c6.311,0.812,13.383,1.477,19.72,0.657c2.94-0.38,4.367-1.925,6.256-3.937c1.602-1.705,3.326-2.471,5.576-2.913
                    c2.101-0.414,4.858,0.125,6.661-1.218c1.679-1.251,1.796-3.197,3.195-4.7c2.647-2.845,8.247-1.988,12.062-1.988
                    c1.878,0,4.664,0.512,6.432-0.05c2.204-0.7,3.604-3.094,1.637-4.769c-1.639-1.396-5.271-0.885-7.318-1.182
                    c-2.448-0.354-3.75-1.148-5.438-3.244c-2.06-2.557-4.681-2.701-7.251-5.069c-3.19-2.942-1.626-5.501,1.292-7.407
                    c3.132-2.045,4.937-6.044,8.342-7.598c5.584-2.548,5.022,4.636,9.312,6.068c2.19,0.731,3.717,0.473,5.181,2.562
                    c1.542,2.203,0.849,3.816,3.994,4.45c5.058,1.021,8.625-0.672,11,4.994c2.475,5.902,4.417,9.359,11.074,10.005
                    c2.841,0.275,6.025-0.647,8.696,0.427c3.251,1.307,4.945,3.656,5.674,6.999c0.508,2.326,0.451,5.383,2.695,6.8
                    c1.932,1.219,5.193,0.91,7.401,0.958c5.638,0.123,11.275,0.246,16.914,0.369c3.842,0.083,7.68,0.257,11.521,0.358
                    c2.716,0.072,6.971,0.928,9.565-0.058c3.524-1.339,1.688-6.046,2.137-8.893c0.697-4.397,3.492-6.118,7.733-6.015
                    c3.848,0.094,8.39-0.228,12.157,0.55c1.812,0.374,3.171,0.796,4.432,2.308c1.361,1.633,1.331,3.599,3.568,4.45
                    c3.635,1.383,7.703,0.055,11.111,1.924c4.742,2.602,7.269,8.012,9.598,12.65c2.595,5.166,5.47,6.337,11.111,7.237
                    c10.389,1.656,20.898,0.65,31.343,0.292c4.104-0.14,8.57,1.102,12.517-0.022c3.822-1.089,7.03-3.572,10.638-5.145
                    c2.426-1.058,6.847-1.917,9.614-1.151c6.343,1.756,3.406,12.572,6.507,17.458c2.735,4.311,7.239,3.899,10.679,6.937
                    c3.482,3.076,1.384,8.425-1.214,11.106c-3.124,3.225-6.298,5.774-10.575,7.255c-4.887,1.692-7.857,6.388-12.701,8.38
                    c-4.643,1.909-10.324-0.569-15.084,0.551c-3.099,0.729-3.888,3.651-6.166,5.642c-4.729,4.129-11.322,0.11-16.626,1.608
                    c-3.351,0.947-3.271,4.708-4.176,7.538c-1.893,5.919-3.148,13.085-8.312,17.217c-4.854,3.886-11.061,2.369-14.958-2.243
                    c-1.479-1.75-3.467-3.699-3.306-6c0.173-2.468,2.163-2.803,3.426-4.593c3.164-4.489,3.522-10.142-1.481-12.962
                    c-2.768-1.56-6.722-1.452-10.062-1.444c-3.661,0.009-7.281-0.378-10.933-0.25c-3.944,0.138-7.684,2.427-10.144,5.744
                    c-1.83,2.468-1.224,4.728-1.396,7.517c-0.163,2.63-0.896,5.716,1.45,7.689c3.06,2.574,5.285,2,6.33,6.585
                    c0.795,3.489,0.271,7.065,0.854,10.527c0.522,3.11,0.168,6.236,0.039,9.362c-0.111,2.691-0.951,5.911-4,6.333
                    c-3.349,0.463-3.837-2.901-4.343-5.299c-0.582-2.767-4.02-3.425-6.299-2.423c-3.611,1.587-1.611,6.984-6.199,8.042
                    c-1.258,0.29-2.576,0.071-3.284,1.347c-0.581,1.049,0.098,2.527-0.334,3.663c-1.117,2.938-4.783,2.162-7.372,2.162
                    c-1.679,0-4.608,0.81-6.025-0.523c-2.29-2.154-0.974-6.455-0.977-9.142c-0.003-3.492,1.563-3.872,4.487-4.51
                    c4.385-0.957,4.511-8.509,3.514-11.927c-1.871-6.415-7.455-3.442-12.238-4.59c-3.58-0.86-3.355-2.739-4.423-5.842
                    c-0.784-2.279-1.841-1.695-4.044-2.262c-4.586-1.182-1.843-6.812-5.961-7.833c-2.868-0.71-7.662-0.843-10.547-0.116
                    c-4.396,1.108-4.266,7.129-3.813,10.68c0.232,1.825,0.241,3.598-1.656,4.508c-2.342,1.122-4.485,0.88-6.985,0.891
                    c-3.184,0.014-6.324-0.267-9.562-0.32c-2.067,0-4.138-0.067-6.205-0.101c-1.288-0.021-3.453,0.43-4.49-0.521
                    c-1.487-1.365-0.443-4.2-1.058-5.899c-0.697-1.929-1.847-1.034-3.312-1.067c-1.634-0.036-3.203-0.747-4.822-0.747
                    c-1.099,0-2.09,0.507-3.192,0.479c-3.508-0.088-3.267-4.542-4.745-6.67c-1.521-2.189-6.379-1.337-7.642,0.866
                    c-1.441,2.514-0.748,6.238-0.637,8.981c0.109,2.681-1.431,6.075-4.459,4.129c-1.284-0.825-1.523-2.033-1.709-3.463
                    c-0.129-0.998,0.189-3.06-0.542-3.791c-1.407-1.408-5.868,0.228-7.79,0.125c-3.471-0.186-8.578-2.142-9.001,2.831
                    c-0.185,2.18-0.238,4.57-2.998,4.502c-2.78-0.069-3.083-3.07-3.996-5.108c-0.915-2.042-3.424-2.654-5.428-2.944
                    c-2.319-0.335-4.05,0.837-6.245,0.719c-2.186-0.118-4.056-0.472-6.243-0.33c-1.806,0.117-3.987-0.287-4.873-2.118
                    c-0.891-1.839,0.437-3.702,0.626-5.553c0.201-1.965,0.261-4.531-1.018-6c-1.408-1.618-5.747-2.665-7.598-1.259
                    c-2.172,1.65,0.072,5.426-2.39,6.921c-2.788,1.693-4.21-1.055-5.271-3.231c-0.723-1.481-1.067-3.1-2.728-3.794
                    c-1.561-0.653-9.622-0.47-12.006-0.47c-4.656,0-9.733-0.23-14.331,0.334c-5.002,0.616-3.399,8.136-3.272,11.261
                    c0.207,5.06-6.632,4.108-9.896,4.072c-4.754-0.053-9.51-1.146-14.26-0.349c-2.17,0.364-4.417,1.134-5.769,2.977
                    c-1.86,2.535-1.161,5.158-4.979,5.575c-3.041,0.332-4.769,3.698-3.885,6.497C552.519,164.404,557.613,161.633,558.637,165.717z"
              />
              <g
                id="Russia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="929.781" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="921.987" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="921.987" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="914.191" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="914.191" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="914.191" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="906.397" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="906.397" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="906.397" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="906.397" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="906.397" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="898.601" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="898.601" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="898.601" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="898.601" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="898.601" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="890.808" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="883.013" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="883.013" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="883.013" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="883.013" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="883.013" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="883.013" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="875.219" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="875.219" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="875.219" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="875.219" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="875.219" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="875.219" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="867.423" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="867.423" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="867.423" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="867.423" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="867.423" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="867.423" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="859.628" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="859.628" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="859.628" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="859.628" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="859.628" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="859.628" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="859.628" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="859.628" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="859.628" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="851.833" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="851.833" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="851.833" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="851.833" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="851.833" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="851.833" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="851.833" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="851.833" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="851.833" cy="150.299" r="2.198" />
                <circle fill="#aaaaaa" cx="844.038" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="844.038" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="844.038" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="844.038" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="844.038" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="844.038" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="844.038" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="836.243" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="836.243" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="836.243" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="836.243" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="836.243" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="836.243" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="836.243" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="828.448" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="828.448" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="820.655" cy="119.119" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M815.058,48.966c0,1.212-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.198,2.196-2.198C814.073,46.769,815.058,47.751,815.058,48.966z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,56.761c0,1.212-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.198,2.196-2.198C814.073,54.563,815.058,55.546,815.058,56.761z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,64.556c0,1.212-0.984,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.215,0.985-2.198,2.196-2.198C814.073,62.358,815.058,63.341,815.058,64.556z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,72.351c0,1.212-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.214,0.985-2.198,2.196-2.198C814.073,70.153,815.058,71.137,815.058,72.351z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,80.146c0,1.212-0.984,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.214,0.985-2.198,2.196-2.198C814.073,77.948,815.058,78.932,815.058,80.146z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,87.939c0,1.214-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C814.073,85.742,815.058,86.727,815.058,87.939z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,95.734c0,1.214-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C814.073,93.537,815.058,94.521,815.058,95.734z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,103.529c0,1.214-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C814.073,101.332,815.058,102.316,815.058,103.529z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,111.324c0,1.214-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C814.073,109.126,815.058,110.111,815.058,111.324z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,119.119c0,1.214-0.984,2.198-2.199,2.198c-1.211,0-2.196-0.983-2.196-2.198
                        c0-1.213,0.985-2.198,2.196-2.198C814.073,116.921,815.058,117.906,815.058,119.119z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,150.299c0,1.213-0.984,2.197-2.199,2.197c-1.211,0-2.196-0.984-2.196-2.197
                        c0-1.214,0.985-2.198,2.196-2.198C814.073,148.102,815.058,149.085,815.058,150.299z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,158.094c0,1.213-0.984,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.214,0.985-2.197,2.196-2.197C814.073,155.897,815.058,156.879,815.058,158.094z"
                />
                <circle fill="#aaaaaa" cx="805.065" cy="48.966" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="805.065" cy="158.094" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M799.468,48.966c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.198,2.196-2.198C798.482,46.769,799.468,47.751,799.468,48.966z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,56.761c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.198,2.196-2.198C798.482,54.563,799.468,55.546,799.468,56.761z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,72.351c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.214,0.985-2.198,2.196-2.198C798.482,70.153,799.468,71.137,799.468,72.351z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,80.146c0,1.212-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.214,0.985-2.198,2.196-2.198C798.482,77.948,799.468,78.932,799.468,80.146z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,87.939c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C798.482,85.742,799.468,86.727,799.468,87.939z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,95.734c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C798.482,93.537,799.468,94.521,799.468,95.734z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,103.529c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C798.482,101.332,799.468,102.316,799.468,103.529z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,111.324c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C798.482,109.126,799.468,110.111,799.468,111.324z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,119.119c0,1.214-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.983-2.196-2.198
                        c0-1.213,0.985-2.198,2.196-2.198C798.482,116.921,799.468,117.906,799.468,119.119z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,126.914c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.214,0.985-2.197,2.196-2.197C798.482,124.717,799.468,125.7,799.468,126.914z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,134.709c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.214,0.985-2.198,2.196-2.198C798.482,132.511,799.468,133.495,799.468,134.709z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,142.504c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.197,2.196-2.197C798.482,140.307,799.468,141.29,799.468,142.504z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,150.299c0,1.213-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.984-2.196-2.197
                        c0-1.214,0.985-2.198,2.196-2.198C798.482,148.102,799.468,149.085,799.468,150.299z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,158.094c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.214,0.985-2.197,2.196-2.197C798.482,155.897,799.468,156.879,799.468,158.094z"
                />
                <circle fill="#aaaaaa" cx="789.475" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="781.68" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="773.885" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="773.885" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="766.09" cy="64.556" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="72.351" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="80.146" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="87.939" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="95.734" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="103.529" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="126.914" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="134.709" r="2.198" />
                <circle fill="#aaaaaa" cx="766.09" cy="142.504" r="2.198" />
                <circle fill="#aaaaaa" cx="758.295" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="758.295" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="750.5" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="750.5" cy="134.709" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M744.904,64.556c0,1.212-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.215,0.985-2.198,2.196-2.198C743.919,62.358,744.904,63.341,744.904,64.556z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,72.351c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.214,0.985-2.198,2.196-2.198C743.919,70.153,744.904,71.137,744.904,72.351z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,80.146c0,1.212-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.214,0.985-2.198,2.196-2.198C743.919,77.948,744.904,78.932,744.904,80.146z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,87.939c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C743.919,85.742,744.904,86.727,744.904,87.939z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,95.734c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C743.919,93.537,744.904,94.521,744.904,95.734z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,103.529c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C743.919,101.332,744.904,102.316,744.904,103.529z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,111.324c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C743.919,109.126,744.904,110.111,744.904,111.324z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,119.119c0,1.214-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.983-2.196-2.198
                        c0-1.213,0.985-2.198,2.196-2.198C743.919,116.921,744.904,117.906,744.904,119.119z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,126.914c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.214,0.985-2.197,2.196-2.197C743.919,124.717,744.904,125.7,744.904,126.914z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,134.709c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.214,0.985-2.198,2.196-2.198C743.919,132.511,744.904,133.495,744.904,134.709z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,142.504c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.197,2.196-2.197C743.919,140.307,744.904,141.29,744.904,142.504z"
                />
                <path
                  fill="#aaaaaa"
                  d="M744.904,150.299c0,1.213-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.984-2.196-2.197
                        c0-1.214,0.985-2.198,2.196-2.198C743.919,148.102,744.904,149.085,744.904,150.299z"
                />
                <circle fill="#aaaaaa" cx="734.912" cy="48.966" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="734.912" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="727.116" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="727.116" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="719.322" cy="48.966" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="719.322" cy="150.299" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M713.725,33.376c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C712.739,31.178,713.725,32.163,713.725,33.376z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,41.171c0,1.215-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.982-2.196-2.197
                        c0-1.213,0.985-2.197,2.196-2.197C712.739,38.974,713.725,39.958,713.725,41.171z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,48.966c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.198,2.196-2.198C712.739,46.769,713.725,47.751,713.725,48.966z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,56.761c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.198,2.196-2.198C712.739,54.563,713.725,55.546,713.725,56.761z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,64.556c0,1.212-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.215,0.985-2.198,2.196-2.198C712.739,62.358,713.725,63.341,713.725,64.556z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,72.351c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.214,0.985-2.198,2.196-2.198C712.739,70.153,713.725,71.137,713.725,72.351z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,80.146c0,1.212-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.985-2.196-2.198
                        c0-1.214,0.985-2.198,2.196-2.198C712.739,77.948,713.725,78.932,713.725,80.146z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,87.939c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C712.739,85.742,713.725,86.727,713.725,87.939z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,95.734c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C712.739,93.537,713.725,94.521,713.725,95.734z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,103.529c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C712.739,101.332,713.725,102.316,713.725,103.529z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,111.324c0,1.214-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.983-2.196-2.197
                        c0-1.213,0.985-2.198,2.196-2.198C712.739,109.126,713.725,110.111,713.725,111.324z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,119.119c0,1.214-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.983-2.196-2.198
                        c0-1.213,0.985-2.198,2.196-2.198C712.739,116.921,713.725,117.906,713.725,119.119z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,126.914c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.214,0.985-2.197,2.196-2.197C712.739,124.717,713.725,125.7,713.725,126.914z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,134.709c0,1.213-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.214,0.985-2.198,2.196-2.198C712.739,132.511,713.725,133.495,713.725,134.709z"
                />
                <path
                  fill="#aaaaaa"
                  d="M713.725,142.504c0,1.212-0.985,2.197-2.199,2.197c-1.211,0-2.196-0.985-2.196-2.197
                        c0-1.215,0.985-2.197,2.196-2.197C712.739,140.307,713.725,141.29,713.725,142.504z"
                />
                <circle fill="#aaaaaa" cx="703.732" cy="33.376" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="41.171" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="48.966" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="703.732" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="695.937" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="695.937" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="688.142" cy="17.786" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="25.581" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="33.376" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="48.966" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="688.142" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="680.347" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="680.347" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="672.552" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="672.552" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="56.761" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="664.758" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="656.963" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="656.963" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="649.168" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="649.168" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="641.373" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="641.373" cy="142.504" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M815.058,165.887c0,1.215-0.984,2.198-2.199,2.198c-1.211,0-2.196-0.983-2.196-2.198
                        c0-1.212,0.985-2.197,2.196-2.197C814.073,163.69,815.058,164.675,815.058,165.887z"
                />
                <path
                  fill="#aaaaaa"
                  d="M815.058,173.683c0,1.214-0.984,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.213,0.985-2.198,2.196-2.198C814.073,171.485,815.058,172.47,815.058,173.683z"
                />
                <circle fill="#aaaaaa" cx="805.065" cy="165.887" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M799.468,165.887c0,1.215-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.983-2.196-2.198
                        c0-1.212,0.985-2.197,2.196-2.197C798.482,163.69,799.468,164.675,799.468,165.887z"
                />
                <path
                  fill="#aaaaaa"
                  d="M799.468,173.683c0,1.214-0.985,2.198-2.199,2.198c-1.211,0-2.196-0.984-2.196-2.198
                        c0-1.213,0.985-2.198,2.196-2.198C798.482,171.485,799.468,172.47,799.468,173.683z"
                />
                <circle fill="#aaaaaa" cx="789.475" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="789.475" cy="181.478" r="2.196" />
                <circle fill="#aaaaaa" cx="781.68" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="781.68" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="547.835" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="633.579" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="625.783" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="625.783" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="617.989" cy="48.966" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="25.581" r="2.196" />
                <circle fill="#aaaaaa" cx="610.195" cy="25.581" r="2.196" />
                <circle fill="#aaaaaa" cx="594.605" cy="17.786" r="2.196" />
                <circle fill="#aaaaaa" cx="594.605" cy="25.581" r="2.196" />
                <circle fill="#aaaaaa" cx="586.81" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="579.015" cy="25.581" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="25.581" r="2.196" />
                <circle fill="#aaaaaa" cx="555.63" cy="17.786" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="547.835" cy="17.786" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="617.989" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="610.193" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="610.193" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="602.399" cy="48.966" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="602.399" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="594.604" cy="48.966" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="594.604" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="56.761" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="64.556" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="586.81" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="579.015" cy="64.556" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="579.015" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="571.22" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="563.425" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="555.63" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="547.835" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="142.504" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M526.647,87.939c0,1.214-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.983-2.197-2.197
                        c0-1.213,0.986-2.198,2.197-2.198C525.663,85.742,526.647,86.727,526.647,87.939z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,95.734c0,1.214-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.983-2.197-2.197
                        c0-1.213,0.986-2.198,2.197-2.198C525.663,93.537,526.647,94.521,526.647,95.734z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,103.529c0,1.214-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.983-2.197-2.197
                        c0-1.213,0.986-2.198,2.197-2.198C525.663,101.332,526.647,102.316,526.647,103.529z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,111.324c0,1.214-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.983-2.197-2.197
                        c0-1.213,0.986-2.198,2.197-2.198C525.663,109.126,526.647,110.111,526.647,111.324z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,119.119c0,1.214-0.984,2.198-2.198,2.198c-1.211,0-2.197-0.983-2.197-2.198
                        c0-1.213,0.986-2.198,2.197-2.198C525.663,116.921,526.647,117.906,526.647,119.119z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,126.914c0,1.213-0.984,2.198-2.198,2.198c-1.211,0-2.197-0.984-2.197-2.198
                        c0-1.214,0.986-2.197,2.197-2.197C525.663,124.717,526.647,125.7,526.647,126.914z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,134.709c0,1.213-0.984,2.198-2.198,2.198c-1.211,0-2.197-0.984-2.197-2.198
                        c0-1.214,0.986-2.198,2.197-2.198C525.663,132.511,526.647,133.495,526.647,134.709z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,142.504c0,1.212-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.985-2.197-2.197
                        c0-1.215,0.986-2.197,2.197-2.197C525.663,140.307,526.647,141.29,526.647,142.504z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="72.351" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="25.581" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="25.581" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="33.376" r="2.196" />
                <circle fill="#aaaaaa" cx="485.478" cy="25.581" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="41.171" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="33.376" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="41.171" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="33.376" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="72.351" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="95.734" r="2.196" />
              </g>
            </g>
            <g
              id="Faroe_Islands"
              onMouseMove={(e) => this.showTooltip(e)}
              onMouseOut={this.hideTooltip.bind(this)}
            >
              <circle fill="#aaaaaa" cx="415.325" cy="111.324" r="2.196" />
            </g>
            <g id="Turkey_1_">
              <g
                id="Turkey"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="547.835" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="197.07" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="197.07" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M526.647,189.274c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.197-0.983-2.197-2.196
                        c0-1.214,0.986-2.2,2.197-2.2C525.663,187.074,526.647,188.061,526.647,189.274z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,197.07c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.197-0.984-2.197-2.194
                        c0-1.214,0.986-2.2,2.197-2.2C525.663,194.87,526.647,195.856,526.647,197.07z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="189.274" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="197.07" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M519.429,186.384c5.43,0,10.855-0.207,16.283-0.293
                    c3.189-0.051,6.369-0.646,9.563-0.411c2.492,0.183,4.566,0.463,5.171,3.07c0.631,2.721,0.293,5.544,0.153,8.301
                    c-0.167,3.296-2.626,2.856-5.333,3c-3.203,0.17-6.317-0.116-9.484-0.329c-3.574-0.239-7.257,0.162-10.841,0.162
                    c-4.261,0-8.293,0.667-12.5,0.667c-3.086,0-5.799-0.081-6.518-3.454c-0.499-2.336-0.708-6.248,0.134-8.419
                    C507.761,184.29,515.6,186.384,519.429,186.384z"
              />
            </g>
            <g id="Greece_1_">
              <g
                id="Greece"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="197.07" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M502.815,199.756c1.163-0.765,1.521-2.508,1.212-3.795
                    c-0.619-2.576-3.56-1.679-5.425-1.41c-1.936,0.278-3.712-0.915-5.625-0.719c-1.768,0.181-2.834,1.578-2.704,3.316
                    c0.332,4.432,5.96,2.269,8.497,2.771C500.099,200.184,501.571,200.573,502.815,199.756z"
              />
            </g>
            <g id="Macedonia_x2C__FYR">
              <g
                id="Macedonia_FYR"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="189.274" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M504.433,188.717c-0.047-3.149-5.386-3.646-6.192-0.651
                    c-0.609,2.266,1.32,4.752,3.697,4.322C503.641,192.08,504.454,190.177,504.433,188.717z"
              />
            </g>
            <g id="Albania__1">
              <g
                id="Albania"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="493.272" cy="189.274" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M493.729,192.346c2.49-0.669,3.962-3.844,1.457-5.638
                    c-2.073-1.483-5.81,0.116-5.586,2.848C489.764,191.536,491.668,192.9,493.729,192.346z"
              />
            </g>
            <g id="Bulgaria_1_">
              <g
                id="Bulgaria"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="508.862" cy="181.478" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M510.766,184.025c3.384-1.968-0.542-8.13-3.973-4.967
                    C504.166,181.48,507.719,185.797,510.766,184.025z"
              />
            </g>
            <g id="Belarus_1_">
              <g
                id="Belarus"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="150.299" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M511.441,151.893c1.231-2.846,1.121-5.25,4.476-6.252
                    c2.076-0.621,3.968-0.856,3.524-3.59c-0.513-3.156-4.077-3.005-6.556-2.726c-2.471,0.279-5.976-0.413-7.121,2.397
                    c-0.731,1.793,2.173,4.861-0.665,5.833c-2.582,0.884-4.438-1.415-6.629,0.899c-1.836,1.938-0.333,4.148,1.722,4.797
                    c2.148,0.678,4.526,0.358,6.74,0.467C508.873,153.813,510.536,153.986,511.441,151.893z"
              />
            </g>
            <g id="Romania_1_">
              <g
                id="Romania"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="508.862" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="165.887" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M511.54,163.427c0.426,0.446,0.582,1.19,0.307,2.38
                    c-0.322,1.388-2.193,4.272-0.917,5.573c2.14,2.181,0.515,6.225-2.685,5.055c-2.92-1.067-2.282-4.208-4.141-6.158
                    c-1.874-1.967-4.954-0.351-5.881-3.521c-1.25-4.275,3.755-3.463,6.262-3.288C506.142,163.586,510.193,162.015,511.54,163.427z"
              />
            </g>
            <g id="Kosovo__1">
              <g
                id="Kosovo"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="181.478" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M503.059,184.009c3.691-2.498-2.551-8.424-4.558-3.665
                    C497.325,183.13,500.656,185.634,503.059,184.009z"
              />
            </g>
            <g id="Yugoslavia_1_">
              <g
                id="Yugoslavia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="493.272" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="181.478" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M490.101,173.217c0.282-1.791,2.438-3.215,4.143-2.43
                    c1.521,0.701,2.041,2.72,2.022,4.264c-0.015,1.205-0.442,2.168-0.5,3.333c-0.089,1.766,0.994,3.211-0.044,4.958
                    c-0.889,1.493-2.535,1.927-4.091,1.173c-1.75-0.85-1.973-3.064-1.69-4.773c0.161-0.974,0.569-1.744,0.555-2.743
                    C490.476,175.702,489.89,174.552,490.101,173.217z"
              />
            </g>
            <g id="Bosnia_1_">
              <g
                id="Bosnia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="485.478" cy="181.478" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M484.881,184.417c1.222,0.42,2.517,0.146,3.187-1.44
                    c1.137-2.687-2.517-6.376-4.905-3.915C481.625,180.646,482.897,183.735,484.881,184.417z"
              />
            </g>
            <g id="Italy_1_">
              <g
                id="Italy"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="454.296" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="477.682" cy="189.274" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="180.926" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="197.07" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="462.092" cy="173.683" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M467.224,184.258c1.532,1.759,2.86,0.063,4.467,1.17
                    c1.335,0.92,1.442,3.657,1.185,5.063c-0.581,3.177-2.642,1.557-4.738,2.94c-1.343,0.887-2.343,3.197-1.804,4.771
                    c0.823,2.402,3.537,2.186,5.6,2.384c1.673,0.162,3.268,0.662,4.997,0.464c3.003-0.344,4.359-2.021,4.617-4.928
                    c0.155-1.753-0.266-3.427-0.357-5.172c-0.114-2.19,0.04-4.406-0.39-6.572c-0.308-1.55,0.365-4.103-0.789-5.371
                    c-1.65-1.814-4.527-0.315-5.935-2.604c-0.922-1.5-0.944-3.666-2.289-4.886c-1.983-1.801-5.178-1.12-7.604-1.048
                    c-3.287,0.097-7.102-0.301-10.3,0.389c-3.371,0.728-3.78,4.547-0.509,5.602c2.235,0.72,4.809,0.07,7.056,0.089
                    c2.274,0.02,6.267-0.143,6.553,3.019c0.099,1.085-0.701,1.984-0.525,3.101C466.553,183.281,466.834,183.81,467.224,184.258z"
              />
            </g>
            <g id="Croatia_1_">
              <g
                id="Croatia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="485.478" cy="173.683" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M485.269,176.598c2.068-0.238,4.156-2.601,2.557-4.982
                    c-2.096-3.122-6.428,0.082-5.305,3.182C483.042,176.236,484.152,176.727,485.269,176.598z"
              />
            </g>
            <g id="Slovenia_1_">
              <g
                id="Slovenia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="477.682" cy="173.683" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M474.456,172.721c-0.326,1.626,0.712,3.512,2.474,3.834
                    c2.255,0.413,4.276-1.893,3.678-4.106c-0.591-2.182-3.655-2.686-5.252-1.288C474.863,171.593,474.573,172.14,474.456,172.721z"
              />
            </g>
            <g id="Hungary_1_">
              <g
                id="Hungary"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="493.272" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="485.478" cy="165.887" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M490.93,168.884c1.74,0,3.816,0.054,4.962-1.459
                    c0.875-1.157,1.081-2.964-0.188-3.926c-1.451-1.101-4.04-0.243-5.699-0.318c-1.944-0.087-4.55-0.808-6.288,0.393
                    c-1.587,1.096-1.97,3.357-0.337,4.594C485.327,169.644,488.691,168.884,490.93,168.884z"
              />
            </g>
            <g id="Austria_1_">
              <g
                id="Austria"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="477.682" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="165.887" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M477.595,162.935c-1.182-0.01-2.469,0.347-3.541,0.425
                    c-0.957,0.07-2.002-0.294-2.96-0.362c-1.745-0.124-3.934-0.075-4.33,2.058c-0.515,2.767,1.945,3.982,4.417,3.609
                    c1.471-0.222,2.308-0.43,3.911-0.17c1.508,0.244,3.105,0.661,4.421-0.398c1.242-1,1.604-2.587,0.682-3.928
                    C479.541,163.22,478.606,162.943,477.595,162.935z"
              />
            </g>
            <g id="Monaco_1_">
              <g
                id="Monaco"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="454.296" cy="181.478" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M454.43,184.551c2.248,0.097,4.012-2.954,2.904-4.922
                    c-1.257-2.233-6.031-1.171-6.117,1.459C451.16,182.829,452.72,184.478,454.43,184.551z"
              />
            </g>
            <g id="Czech_Republic_1_">
              <g
                id="Czech_Republic"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="477.682" cy="158.094" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M478.43,161.05c4.439-1.067,1.478-8.031-2.484-5.908
                    C472.752,156.853,475.007,161.873,478.43,161.05z"
              />
            </g>
            <g id="Slovakia_1_">
              <g
                id="Slovakia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="485.478" cy="158.094" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M487.642,155.501c-3.006-2.63-7.49,1.517-4.807,4.68
                    c1.3,1.532,3.445,1.539,4.803,0.069C488.804,158.987,489.126,156.8,487.642,155.501z"
              />
            </g>
            <g id="Poland_1_">
              <g
                id="Poland"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="493.272" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="485.478" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="150.299" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M496.229,141.625c0.637,1.491,0.142,2.659-0.001,4.146
                    c-0.3,3.116-0.299,6.345-0.299,9.483c0,1.208,0.473,2.938-0.106,4.146c-0.814,1.698-2.823,2.161-4.353,1.11
                    c-2.286-1.571-0.426-5.503-3.126-7.003c-0.985-0.548-2.604-0.267-3.756-0.289c-2.113-0.04-4.402,0.612-6.559,0.2
                    c-3.047-0.582-3.796-1.714-2.999-4.585c0.604-2.182-0.94-4.644-0.593-6.877c0.472-3.023,4.566-2.647,6.998-2.562
                    c2.016,0.071,4.031,0.163,6.048,0.198C490.203,139.638,494.821,138.329,496.229,141.625z"
              />
            </g>
            <g id="Germany_1_">
              <g
                id="Germany"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="469.887" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="462.092" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="454.296" cy="150.299" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M467.301,140.711c1.174-2.554,3.916-1.823,4.894,0.38
                    c0.973,2.194,0.617,4.795,0.735,7.125c0.147,2.922-0.175,5.76-0.167,8.667c0.011,3.946-1.438,4.167-5,4.167
                    c-2.534,0-6.796,0.913-8.207-1.454c-1.231-2.066-0.311-4.526-2.761-5.917c-1.266-0.718-2.791,0.064-4.208-0.454
                    c-2.646-0.968-1.643-4.892,0.4-5.944c3.217-1.656,7.472,0.984,10.876,0C467.501,146.231,466.077,143.374,467.301,140.711z"
              />
            </g>
            <g id="Netherlands_1_">
              <g
                id="Netherlands"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="446.504" cy="150.299" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M448.101,147.709c-2.221-1.336-5.367,0.435-4.783,3.243
                    c0.382,1.838,2.111,2.977,3.945,2.602c1.754-0.358,2.624-1.873,2.176-3.676C449.3,149.321,448.675,148.056,448.101,147.709z"
              />
            </g>
            <g id="Luxembourg_1_">
              <g
                id="Luxembourg"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="454.296" cy="158.094" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M452.051,160.551c1.685,1.418,3.853,0.719,4.842-1.138
                    c1.191-2.236-1.035-5.012-3.517-4.529C450.845,155.377,450.215,159.005,452.051,160.551z"
              />
            </g>
            <g id="Switzerland_1_">
              <g
                id="Switzerland"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="462.092" cy="165.887" r="2.196" />
                <circle fill="#aaaaaa" cx="454.296" cy="165.887" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M451.291,165.866c0.236,2.894,3.381,3.003,5.602,2.777
                    c1.915-0.194,7.007,1.125,8.167-1.125c0.979-1.897-0.446-4.253-2.404-4.829c-1.525-0.448-2.851,0.376-4.329,0.516
                    c-2.083,0.197-4.095-0.926-6.034,0.551C451.545,164.325,451.229,165.089,451.291,165.866z"
              />
            </g>
            <g id="France_1_">
              <g
                id="France"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="446.504" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="446.504" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="446.504" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="438.707" cy="158.094" r="2.198" />
                <circle fill="#aaaaaa" cx="438.707" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="430.917" cy="165.887" r="2.198" />
                <circle fill="#aaaaaa" cx="423.12" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="438.707" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="430.917" cy="173.683" r="2.198" />
                <circle fill="#aaaaaa" cx="438.707" cy="181.478" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M434.138,161.263c1.333-2.242,0.584-6.115,3.959-6.379
                    c1.312-0.103,2.283,0.614,3.074,1.604c1.084,1.356,0.582,2.283,0.74,4.022c0.231,2.541,2.127,1.651,4.186,2.037
                    c3.535,0.663,3.466,4.779,3.5,7.809c0.041,3.708,0.095,7.599-0.5,11.195c-0.588,3.553-4.513,3.614-7.663,3.5
                    c-2.097-0.075-4.25,0.099-5.333-1.962c-0.857-1.634-0.178-3.969-1.629-5.311c-1.817-1.68-5.429,0.001-6.725-2.708
                    c-0.682-1.425-0.417-3.333-1.457-4.598c-1.708-2.076-8.05-0.646-7.005-5.28c0.818-3.626,3.992-2.729,6.495-2.097
                    C427.938,163.64,432.833,163.457,434.138,161.263z"
              />
            </g>
            <g id="Spain_1_">
              <g
                id="Spain"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="430.914" cy="189.274" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M440.904,189.274c0,1.213-0.982,2.196-2.195,2.196c-1.214,0-2.2-0.983-2.2-2.196
                        c0-1.214,0.986-2.2,2.2-2.2C439.922,187.074,440.904,188.061,440.904,189.274z"
                />
                <circle fill="#aaaaaa" cx="430.914" cy="181.478" r="2.197" />
                <circle fill="#aaaaaa" cx="430.914" cy="197.07" r="2.197" />
                <circle fill="#aaaaaa" cx="423.117" cy="181.478" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M425.314,189.274c0,1.213-0.982,2.196-2.195,2.196c-1.215,0-2.2-0.983-2.2-2.196
                        c0-1.214,0.985-2.2,2.2-2.2C424.332,187.074,425.314,188.061,425.314,189.274z"
                />
                <path
                  fill="#aaaaaa"
                  d="M425.314,197.07c0,1.21-0.982,2.194-2.195,2.194c-1.215,0-2.2-0.984-2.2-2.194c0-1.214,0.985-2.2,2.2-2.2
                        C424.332,194.87,425.314,195.856,425.314,197.07z"
                />
                <circle fill="#aaaaaa" cx="415.324" cy="181.478" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M412.216,179.82c-0.088,0.146-0.16,0.296-0.215,0.453
                    c-1.991,5.647,6.229,3.115,7.43,6.068c0.635,1.561,0.384,3.673,0.646,5.352c0.318,2.031-0.127,4.107,0.191,6.144
                    c0.366,2.339,2.597,2.44,4.773,2.158c1.993-0.259,3.637-0.236,5.623-0.158c1.567,0.062,2.824-0.181,3.66-1.66
                    c0.651-1.152,0.877-2.653,1.839-3.612c1.222-1.218,3.245-1.177,4.438-2.389c1.002-1.018,1.822-3.158,0.96-4.426
                    c-1.557-2.285-5.678-1.394-7.155-3.757c-1.138-1.819-0.188-4.516-2.849-5.237c-1.752-0.475-3.474,0.394-5.224,0.265
                    c-1.083-0.08-2.006-0.699-3.057-0.855c-1.967-0.292-4.089-0.394-6.063-0.586C415.612,177.422,413.116,178.319,412.216,179.82z"
              />
            </g>
            <g id="Portugal_1_">
              <g
                id="Portugal"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="415.324" cy="189.274" r="2.198" />
                <circle fill="#aaaaaa" cx="415.324" cy="197.07" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M412.493,195.036c0.021-0.392,0.045-0.757,0.053-1.077
                    c0.063-2.322-1.187-7.242,2.385-7.574c3.52-0.327,3.258,3.909,3.167,6.306c-0.095,2.478,0.914,7.261-2.668,7.693
                    C412.234,200.769,412.356,197.431,412.493,195.036z"
              />
            </g>
            <g id="United_Kingdom_1_">
              <g
                id="United_Kingdom"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="438.71" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="430.915" cy="119.119" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M440.907,119.119c0,1.214-0.98,2.198-2.196,2.198c-1.214,0-2.195-0.983-2.195-2.198
                        c0-1.213,0.981-2.198,2.195-2.198C439.927,116.921,440.907,117.906,440.907,119.119z"
                />
                <circle fill="#aaaaaa" cx="430.915" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="430.915" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="430.915" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="430.915" cy="150.299" r="2.196" />
                <circle fill="#aaaaaa" cx="423.12" cy="126.914" r="2.197" />
                <circle fill="#aaaaaa" cx="423.12" cy="134.709" r="2.197" />
                <circle fill="#aaaaaa" cx="423.12" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="423.12" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="415.325" cy="134.709" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M421.727,124.138c-2.473,2.119-5.504,3.854-7.455,6.381
                    c-1.501,1.944-3.621,5.484-0.164,6.99c1.573,0.685,4.199,0.375,5.273,1.903c0.882,1.253,0.658,4.733,0.582,6.228
                    c-0.145,2.838-1.643,5.7,1.232,7.765c2.068,1.486,5.105,1.45,7.51,1.083c2.86-0.437,4.826-1.265,5.984-4.145
                    c0.808-2.007,0.745-3.167,2.839-4.014c1.526-0.618,3.609-0.803,4.479-2.407c0.418-0.771,0.726-2.209,0.295-3.203
                    c-1.288-2.973-5.562-1.44-6.332-5.457c-0.554-2.888-1.482-6.149-0.657-9.072c1.086-3.85,4.606-2.23,6.403-5.018
                    c0.833-1.293,0.452-4.144-0.516-5.263c-1.151-1.331-3.517-1.355-5.106-1.357c-3.338-0.005-5.926-0.65-8.085,2.137
                    C426.069,119.193,424.142,122.068,421.727,124.138z"
              />
            </g>
            <g id="Ireland_1_">
              <g
                id="Ireland"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="415.325" cy="142.504" r="2.196" />
                <circle fill="#aaaaaa" cx="407.53" cy="142.504" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M417.812,145.263c1.349-1.566,0.702-3.579-0.688-4.864
                    c-1.94-1.796-4.964-0.814-7.336-0.863c-1.891-0.04-5.129-0.615-5.526,2.011c-0.209,1.382,0.08,3.112,1.125,4.09
                    c1.3,1.216,2.762,0.635,4.219,0.247C411.939,145.263,416.1,147.252,417.812,145.263z"
              />
            </g>
            <g id="Belgium_1_">
              <g
                id="Belgium"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="446.504" cy="158.094" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M448.394,160.134c-2.531,2.599-5.222-0.187-4.506-3.209
                    C444.91,152.603,451.695,156.745,448.394,160.134z"
              />
            </g>
            <g id="Iceland_1_">
              <g
                id="Iceland"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="391.939" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="384.144" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="384.144" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="376.349" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="376.349" cy="103.529" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M395.11,94.626c-1.414-4.819-9.961-2.57-13.511-2.743
                    c-3.698-0.18-8.964-1.47-9.322,3.853c-0.202,3.009-0.752,7.497,1.315,9.922c2.617,3.071,6.704,1.502,10,1.005
                    c2.98-0.449,3.115-1.173,4.812-3.474C390.262,100.671,396.481,99.304,395.11,94.626z"
              />
            </g>
            <g id="Norway_1_">
              <g
                id="Norway"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="80.146" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="485.478" cy="80.146" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="87.939" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="462.092" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="454.296" cy="111.324" r="2.198" />
                <circle fill="#aaaaaa" cx="454.296" cy="119.119" r="2.198" />
                <circle fill="#aaaaaa" cx="454.296" cy="126.914" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M504.013,79.994c-0.017-0.098-0.037-0.199-0.062-0.304
                    c-0.791-3.388-3.912-3.044-6.71-3.124c-4.599-0.132-10.66-2.104-15.053,0.253c-1.885,1.012-3.319,2.741-4.805,4.242
                    c-3.695,3.735-7.391,7.471-11.086,11.207c-3.356,3.393-6.713,6.785-10.069,10.178c-4.881,4.934-6.523,7.861-6.024,14.82
                    c0.223,3.11-1.159,10.164,1.6,12.429c2.351,1.925,4.824-0.078,7.462,0.022c2.288,0.087,6.522,1.428,6.008-2.6
                    c-0.142-1.106-0.906-1.939-0.943-3.14c-0.065-2.127,1.145-4.151,0.898-6.277c-0.117-1.011-0.509-1.787-0.542-2.789
                    c-0.045-1.381,0.383-2.914,0.671-4.256c0.171-0.794-0.12-2.137,0.222-2.82c0.834-1.674,2.828-0.863,4.306-1.044
                    c1.906-0.233,2.973-1.65,3.047-3.573c0.062-1.626-1.145-3.277-0.762-4.896c0.181-0.768,0.892-1.339,1.046-2.095
                    c0.174-0.847-0.344-1.5-0.195-2.268c0.395-2.043,2.805-2.286,4.434-2.648c4.123-0.918,8.113,0.104,12.252-0.099
                    c2.327-0.114,5.706,0.562,6.389-2.279c0.591-2.461-0.343-4.807,2.785-5.315C500.945,83.282,504.534,83.057,504.013,79.994z"
              />
            </g>
            <g id="Denmark_1_">
              <g
                id="Denmark"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="462.092" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="142.504" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M465.321,134.216c0.317,1.325,0.025,2.956-0.093,4.123
                    c-0.137,1.342,0.037,2.836-0.131,4.211c-0.318,2.612-3.485,4.277-5.537,2.375c-2.027-1.881-0.587-5.646-0.462-8.042
                    c0.088-1.696-0.605-3.248,0.881-4.477c1.479-1.223,4.028-0.719,4.982,0.893C465.127,133.579,465.243,133.888,465.321,134.216z"
              />
            </g>
            <g id="Sweden_1_">
              <g
                id="Sweden"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="485.478" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="126.914" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M476.053,137.222c3.75,1.791,4.917-1.511,5.212-4.509
                    c0.271-2.749-0.025-5.638,1.793-7.834c1.578-1.906,5.222-2.17,5.374-4.997c0.194-3.623-1.685-3.894-4.507-4.824
                    c-2.049-0.675-2.389-2.124-2.643-4.043c-0.715-5.403,6.975-2.552,7.492-7.957c0.191-2.005-1.398-3.398-0.929-5.461
                    c0.352-1.54,1.157-2.741-0.24-4.134c-1.933-1.926-5.66-0.998-8.075-1.093c-1.539-0.061-3.604,0.597-4.541,1.894
                    c-1.263,1.746-0.257,4.973-0.33,7.002c-0.116,3.267-0.909,5.982-4.332,6.829c-2.889,0.715-3.253,2.377-2.901,5.312
                    c0.188,1.564-0.226,2.455-0.413,4c-0.204,1.682,0.628,3.395,0.586,4.978c-0.061,2.277-1.367,3.678-0.483,6.017
                    c0.684,1.808,2.459,2.532,4.187,3.152c1.261,0.453,2.299,0.328,2.947,1.761C474.863,134.675,474.433,136.448,476.053,137.222z"
              />
            </g>
            <g id="Finland_1_">
              <g
                id="Finland"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="508.862" cy="119.119" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="119.119" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="111.324" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="87.939" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="95.734" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="103.529" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="111.324" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="95.734" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="103.529" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="111.324" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M497.982,120.545c1.087,1.466,2.397,1.414,3.986,1.668
                    c2.386,0.383,8.457,1.566,9.845-1.08c1.08-2.059,0.044-5.058-0.048-7.238c-0.122-2.914,0.199-5.681,0.649-8.51
                    c0.217-1.364,0.189-3.334-1-4.29c-1.849-1.485-4.747,0.333-6.54-1.887c-1.148-1.421-1.062-3.777-1.062-5.491
                    c0.001-2.116,1.129-5.296-0.094-7.238c-0.967-1.535-1.812-1.931-3.463-1.422c-2.176,0.671-1.949,2.394-2.507,4.182
                    c-0.943,3.027-3.097,2.476-5.44,3.621c-2.703,1.32-1.667,3.23-1.143,5.499c0.311,1.341-0.506,2.616-0.777,3.883
                    c-0.362,1.691,0.349,2.607,0.584,4.23c0.189,1.306-0.424,2.539-0.671,3.792c-0.612,3.101,1.108,3.882,3.443,4.99
                    C496.154,116.397,496.475,118.513,497.982,120.545z"
              />
            </g>
            <g id="Estonia_1_">
              <g
                id="Estonia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="508.862" cy="126.914" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="126.914" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M507.428,124.009c-1.35,0.308-2.149,0.457-3.441,0.215
                    c-2.159-0.404-6.345-0.945-6.568,2.211c-0.299,4.207,4.497,3.19,7.182,2.949c2.319-0.208,5.692,1.525,6.877-1.117
                    C512.841,125.223,510.291,123.355,507.428,124.009z"
              />
            </g>
            <g id="Lithuania_1_">
              <g
                id="Lithuania"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="142.504" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="134.709" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M494.765,132.218c1.227,0.768,1.033,2.354,1.705,3.448
                    c0.569,0.928,1.902,2.518,2.837,3.14c1.02,0.678,2.555,0.652,3.431,1.444c2.233,2.021,1.035,5.148-1.806,5.3
                    c-3.98,0.212-2.392-3.275-3.501-5.629c-1.228-2.605-3.771-1.123-5.711-2.484C488.501,135.175,491.391,130.106,494.765,132.218z"
              />
            </g>
            <g id="Latvia_1_">
              <g
                id="Latvia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="508.862" cy="134.709" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="134.709" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M498.232,134.707c0.055,0.754,0.423,1.547,1.195,2.267
                    c1.586,1.479,4.979,0.311,6.957,0.445c1.721,0.118,4.616,0.817,5.591-1.161c0.91-1.848-0.281-3.386-1.914-4.219
                    c-1.514-0.771-2.847-0.269-4.403-0.058c-1.418,0.192-1.848-0.216-3.13-0.465C500.058,131.035,498.089,132.745,498.232,134.707z"
              />
            </g>
            <g
              id="Serbia"
              onMouseMove={(e) => this.showTooltip(e)}
              onMouseOut={this.hideTooltip.bind(this)}
            >
              <circle
                id="Serbia_1"
                fill="#aaaaaa"
                cx="501.067"
                cy="173.683"
                r="2.197"
              />
              <path
                fill="none"
                stroke="none"
                d="M503.058,171.471c0.733,0.619,1.111,1.687,0.698,3.292
                    c-0.62,2.405-3.744,2.729-5.197,0.895C495.493,171.786,500.864,169.622,503.058,171.471z"
              />
            </g>
            <g id="Ukraine_1_">
              <g
                id="Ukraine"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="540.04" cy="165.887" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="165.887" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M526.647,150.299c0,1.213-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.984-2.197-2.197
                        c0-1.214,0.986-2.198,2.197-2.198C525.663,148.102,526.647,149.085,526.647,150.299z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,158.094c0,1.213-0.984,2.198-2.198,2.198c-1.211,0-2.197-0.985-2.197-2.198
                        c0-1.214,0.986-2.197,2.197-2.197C525.663,155.897,526.647,156.879,526.647,158.094z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,165.887c0,1.215-0.984,2.198-2.198,2.198c-1.211,0-2.197-0.983-2.197-2.198
                        c0-1.212,0.986-2.197,2.197-2.197C525.663,163.69,526.647,164.675,526.647,165.887z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,173.683c0,1.214-0.984,2.198-2.198,2.198c-1.211,0-2.197-0.984-2.197-2.198
                        c0-1.213,0.986-2.198,2.197-2.198C525.663,171.485,526.647,172.47,526.647,173.683z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="158.094" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="158.094" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="150.299" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="173.683" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="173.683" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="158.094" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M512.062,155.18c-1.403,0.527-3.683-0.105-5.299-0.025
                    c-1.477,0.073-2.962-0.109-4.44-0.077c-2.586,0.056-5.852,0.598-4.728,3.978c0.828,2.492,3.917,2.566,6.12,2.486
                    c2.921-0.106,6.091-0.032,8.995-0.546c1.888-0.334,4.433-0.326,6.266,0.26c2.728,0.872,2.42,6.662,0.585,8.096
                    c-1.351,1.056-3.036,0.433-4.293,1.466c-1.243,1.022-2.011,2.638-1.323,4.192c0.828,1.873,2.547,2.041,4.438,2.041
                    c4.5,0.001,8.794-0.696,13.247-0.272c2.986,0.284,7.761,1.798,10.288-0.53c1.454-1.339,1.072-3.32,0.719-5.073
                    c-0.418-2.069,0.493-3.821,0.457-5.85c-0.027-1.562-0.289-2.955-0.174-4.553c0.159-2.203,0.446-6.367-2.864-6.102
                    c-1.075,0.086-2.099,0.827-3.264,0.9c-1.537,0.097-3.051-0.297-4.554-0.556c-1.439-0.249-2.379,0.107-3.533-1.163
                    c-0.951-1.045-1.269-2.59-1.613-3.914c-0.863-3.315-3.188-2.78-6.144-3.007c-2.189-0.168-5.694-0.666-6.816,1.831
                    C513.312,150.585,514.391,154.305,512.062,155.18z"
              />
            </g>
            <g id="Moldova_1_">
              <circle
                id="Moldova"
                fill="#aaaaaa"
                cx="516.657"
                cy="165.887"
                r="2.197"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              />
              <path
                fill="none"
                stroke="none"
                d="M518.886,164.099c1.474,1.901,0.361,4.032-1.823,4.609
                    c-2.312,0.611-4.616-1.82-3.265-4.11C514.787,162.923,517.653,162.509,518.886,164.099z"
              />
            </g>
          </g>
          <g id="Africa">
            <g id="Comoros_1_">
              <g
                id="Comoros"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="547.834" cy="337.376" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M547.833,340.071c-1.486,0-2.696-1.209-2.696-2.695c0-1.488,1.21-2.699,2.696-2.699
                    c1.488,0,2.698,1.211,2.698,2.699C550.531,338.862,549.321,340.071,547.833,340.071z"
              />
            </g>
            <g id="Zimbabwe__1">
              <g
                id="Zimbabwe"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M526.647,345.169c0,1.214-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.983-2.197-2.197
                        c0-1.213,0.986-2.195,2.197-2.195C525.663,342.974,526.647,343.956,526.647,345.169z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,352.966c0,1.211-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.984-2.197-2.195
                        c0-1.213,0.986-2.199,2.197-2.199C525.663,350.767,526.647,351.753,526.647,352.966z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,360.759c0,1.215-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.982-2.197-2.197
                        c0-1.214,0.986-2.195,2.197-2.195C525.663,358.563,526.647,359.545,526.647,360.759z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="352.966" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="360.759" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="352.966" r="2.196" />
              </g>
              <path
                id="Zimbabwe_Path"
                fill="none"
                stroke="none"
                d="M525.743,342.733c0.542,0.293,0.977,0.703,1.232,1.28
                    c0.327,0.737,0.528,1.528,0.675,2.319c0.349,1.873-0.329,3.485-0.385,5.384c-0.075,2.618-0.044,5.238-0.021,7.857
                    c0.014,1.609,0.321,3.203-1.442,3.833c-2.486,0.89-5.331-0.023-7.88-0.023c-5.443,0-4.324-0.487-4.324-5.977
                    c0-1.4-1.436-1.391-2.482-1.293c-1.778,0.167-3.792,0.506-4.965-1.208c-0.945-1.382-0.794-4.14,0.846-4.929
                    c1.565-0.754,3.58-0.094,5.229-0.094c2.07,0,1.25-2.763,1.026-3.984c-0.75-4.098,4.109-4.147,6.693-3.972
                    C521.519,342.034,524.113,341.852,525.743,342.733z"
              />
            </g>
            <g id="Mozambique_1">
              <g
                id="Mozambique"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="540.04" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="352.966" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="368.556" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M526.647,337.376c0,1.211-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.984-2.197-2.195
                        c0-1.214,0.986-2.199,2.197-2.199C525.663,335.177,526.647,336.162,526.647,337.376z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,368.556c0,1.211-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.984-2.197-2.195
                        c0-1.214,0.986-2.199,2.197-2.199C525.663,366.356,526.647,367.342,526.647,368.556z"
                />
              </g>
              <path
                id="Mozambique_Path"
                fill="none"
                stroke="none"
                d="M543.368,334.69c-2.636-2.647-7.316-0.814-6.609,3.125
                    c0.195,1.088,0.828,3.621-0.992,3.621c-2.436,0-5.767,0.568-7.637-1.473c-0.66-0.719-0.761-1.805-0.886-2.734
                    c-0.21-1.571-0.47-3.465-2.334-3.479c-1.378-0.011-3.727-0.132-4.155,1.576c-0.426,1.703,0.334,3.745,1.847,4.663
                    c1.526,0.925,3.594,0.805,4.981,2.108c3.357,3.153,0.932,16.73,0.932,21.316c0,3.177-3.916,1.145-5.643,2.207
                    c-1.871,1.15-1.939,4.183-0.524,5.706c0.742,0.799,1.853,0.598,2.856,0.676c1.788,0.14,3.577,0.432,5.373,0.45
                    c3.69,0.038,5.021-2.174,5.021-5.706c0-5.06-2.93-17.614,3.051-17.614c0.528,0,1.059-0.021,1.584-0.086
                    c4.067-0.499,4.855-3.542,4.632-7.159c-0.109-1.771,0.067-4.566-0.731-6.144C543.934,335.351,543.673,334.997,543.368,334.69z"
              />
            </g>
            <g id="South_Africa__1">
              <g
                id="South_Africa"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="516.657" cy="368.556" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="368.556" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="376.349" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="391.938" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="376.349" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="391.938" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="376.349" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="384.144" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="391.938" r="2.196" />
                <circle fill="#aaaaaa" cx="485.478" cy="384.144" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="391.938" r="2.197" />
              </g>
              <path
                id="South_Africa_Path_1_"
                fill="none"
                stroke="none"
                d="M511.969,379.463c-1.338,1.114-7.287-1.415-7.287,1.248
                    c0,6.881-1.515,7.84,5.508,7.84c4.935,0,1.265-3.135,4.271-6.604c3.005-3.468,6.438,0.111,6.438,2.902
                    c0,10.39-14.082,11.301-21.619,11.343c-4.574,0.024-9.083-0.451-13.63-0.864c-3.849-0.35-4.369-1.029-4.399-5.049
                    c-0.016-2.086,0.1-4.309,0.42-6.372c0.235-1.517,2.161-2.856,3.622-3.024c0.948-0.108,4.475,1.035,4.475-0.83
                    c0-5.462-1.619-7.168,3.996-7.168c2.483,0,4.957,0.288,7.445,0.233c0.948-0.021,2.604,0.115,3.44-0.444
                    c1.339-0.896,0.334-3.5,0.775-4.877c0.183-0.569,0.813-1.275,1.32-1.685c1.717-1.39,4.813-0.979,6.904-1.012
                    c1.218-0.019,4.301-0.408,5.207,0.701c4.115,5.036-2.172,6.083-4.895,6.083c-1.729,0-2.828,0.604-2.635,2.432
                    c0.121,1.143,1.53,4.311,0.68,5.115C511.993,379.442,511.98,379.453,511.969,379.463z"
              />
            </g>
            <g id="Lesotho__1">
              <g
                id="Lesotho"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="508.862" cy="384.144" r="2.196" />
              </g>
              <path
                id="Lesotho_Path"
                fill="none"
                stroke="none"
                d="M508.862,386.839c-1.486,0-2.696-1.209-2.696-2.695
                    c0-1.487,1.21-2.697,2.696-2.697s2.695,1.21,2.695,2.697C511.558,385.63,510.349,386.839,508.862,386.839z"
              />
            </g>
            <g id="Botswana_1">
              <g
                id="Botswana"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="508.862" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="352.966" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="360.759" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="368.556" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="352.966" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="368.556" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M496.434,349.406c-0.926,0.107-1.838,0.319-2.771,0.376
                    c-0.776,0.048-1.606-0.106-2.292,0.349c-0.429,0.285-1.414,1.124-1.587,1.604c-0.305,0.847-0.02,2.329-0.019,3.237
                    c0.002,2.828-0.345,5.668-0.15,8.508c0.107,1.571,0.207,3.164,0.121,4.732c-0.062,1.139,0.252,2.171,1.338,2.775
                    c1.352,0.753,2.976,0.748,4.479,0.738c1.233-0.007,2.448,0.175,3.688,0.149c1.985-0.041,4.492-0.5,4.69-2.909
                    c0.117-1.427-0.388-3.167,0.998-4.021c1.564-0.964,3.595-0.662,5.065-1.729c1.15-0.835,2.296-2.288,1.622-3.685
                    c-0.649-1.345-2.662-1.688-3.866-2.268c-1.248-0.601-3.06-1.636-3.406-3.033c-0.358-1.442-0.428-3.562-1.967-4.311
                    C500.715,349.113,498.213,349.199,496.434,349.406z"
              />
            </g>
            <g id="Namibia_1">
              <g
                id="Namibia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="485.478" cy="352.966" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="360.759" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="368.556" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="376.349" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="352.966" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="368.556" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M483.685,349.589c-2.052,0.138-4.096,0.193-6.169,0.295
                    c-0.885,0.043-1.413,0.103-1.996,0.771c-0.89,1.021-1.234,2.154-1.425,3.555c-0.533,3.928,0.013,8.066,0.004,12.028
                    c-0.002,0.859,0.029,1.697,0.184,2.548c0.175,0.961,0.456,1.947,0.949,2.797c0.718,1.236,2.501,2.289,3.616,3.136
                    c1.072,0.813,1.957,1.578,2.873,2.521c0.411,0.424,0.869,0.78,1.357,1.082c0.973,0.601,1.909,1.196,3.188,1.062
                    c2.243-0.235,2.083-4.056,2.083-5.833c-0.018-1.22-0.034-2.439-0.052-3.659c-0.056-3.982-0.112-7.965-0.169-11.947
                    c-0.024-1.764-0.066-3.527-0.074-5.291c-0.005-0.964,0.354-1.809-0.486-2.49c-0.976-0.792-2.461-0.674-3.63-0.592
                    C483.854,349.577,483.769,349.583,483.685,349.589z"
              />
            </g>
            <g id="Swaziland_1">
              <g
                id="Swaziland"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="516.657" cy="376.349" r="2.197" />
              </g>
              <path
                id="Swaziland_Path"
                fill="none"
                stroke="none"
                d="M516.655,379.045c-1.486,0-2.695-1.21-2.695-2.696
                    c0-1.488,1.209-2.698,2.695-2.698c1.488,0,2.699,1.21,2.699,2.698C519.354,377.835,518.144,379.045,516.655,379.045z"
              />
            </g>
            <g id="Madagascar_1">
              <g
                id="Madagascar"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="571.22" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="563.425" cy="337.376" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="352.966" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="360.759" r="2.196" />
                <circle fill="#aaaaaa" cx="555.63" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="352.966" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="360.759" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="368.556" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M563.496,333.169c-0.354,0.062-0.703,0.145-1.047,0.25
                    c-3.155,0.966-2.651,3.033-4.226,5.419c-1.149,1.742-2.619,1.434-4.212,2.589c-1.29,0.935-1.367,2.745-1.575,4.184
                    c-0.74,5.117-0.536,9.875-1.023,14.986c-0.225,2.363,0.473,4.867,1.053,7.154c0.374,1.478,0.636,3.241,2.093,3.841
                    c4.227,1.739,4.901-4.104,6.915-5.634c1.354-1.028,3.371-0.563,4.755-1.74c1.693-1.439,1.564-4.92,1.839-6.995
                    c0.421-3.194,1.201-6.215,1.198-9.468c-0.002-1.898-0.091-3.22,1.129-4.762c1.526-1.931,6.452-4.475,5.606-7.301
                    c-0.752-2.517-4.274-2.642-6.403-2.642C567.613,333.051,565.485,332.814,563.496,333.169z"
              />
            </g>
            <g id="Tanzania_1">
              <g
                id="Tanzania"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="540.04" cy="313.989" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="329.579" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="321.786" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M526.647,306.196c0,1.212-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.983-2.197-2.195
                        c0-1.215,0.986-2.2,2.197-2.2C525.663,303.996,526.647,304.981,526.647,306.196z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,313.989c0,1.215-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.982-2.197-2.197
                        s0.986-2.195,2.197-2.195C525.663,311.794,526.647,312.774,526.647,313.989z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,321.786c0,1.211-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.984-2.197-2.195
                        c0-1.215,0.986-2.199,2.197-2.199C525.663,319.587,526.647,320.571,526.647,321.786z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M533.238,303.539c0.985,0.406,1.729,1.271,2.028,3.014
                    c0.362,2.107,1.377,3.652,3.502,4.323c1.762,0.557,3.667-0.09,4.623,1.963c1.47,3.154,0.208,7.702,0.208,11.046
                    c0,1.846,1.017,6.023,0.099,7.627c-0.972,1.699-4.871,1.702-6.148,0.46c-2.253-2.191-1.437-5.434-4.95-6.421
                    c-2.994-0.842-6.66,0.479-9.46-1.084c-2.35-1.312-1.902-3.612-1.736-5.879c0.111-1.525-0.709-2.921-0.567-4.438
                    c0.114-1.219,0.645-2.496,0.764-3.76c0.134-1.417,0.003-2.911,0-4.337c-0.007-3.156,2.541-2.913,5.001-2.668
                    C528.796,303.604,531.427,302.794,533.238,303.539z"
              />
            </g>
            <g id="Malawi_1">
              <g
                id="Malawi"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="532.246" cy="329.579" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="337.376" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M532.27,326.718c1.513-0.052,2.235,0.566,2.672,2.166
                    c0.676,2.477,0.317,5.737,0.324,8.334c0.008,3.204-3.997,4.966-5.765,1.854c-1.116-1.964-0.732-4.707-0.735-6.863
                    C528.763,329.507,528.939,326.832,532.27,326.718z"
              />
            </g>
            <g id="Zambia-1">
              <g
                id="Zambia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M526.647,329.579c0,1.215-0.984,2.197-2.198,2.197c-1.211,0-2.197-0.982-2.197-2.197
                        c0-1.213,0.986-2.195,2.197-2.195C525.663,327.384,526.647,328.366,526.647,329.579z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="329.579" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="337.376" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="345.169" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M506.1,333.718c1.975,0,4.638,0.964,6.402-0.397c1.77-1.365,0.247-3.621,1.467-5.303
                    c0.709-0.977,2.372-1.416,3.465-1.679c2.479-0.599,6.274-0.917,8.499,0.634c1.658,1.156,2.911,3.168,1.045,4.791
                    c-2.002,1.741-5.961-0.79-7.503,1.626c-1.436,2.25,1.355,5.72-1.936,6.838c-2.37,0.805-5.138,0.222-5.439,3.657
                    c-0.208,2.372-0.722,4.636-3.667,4.629c-2.488-0.006-5.798-0.382-8.333-0.796c-2.649-0.433-2.667-3.255-2.667-5.663
                    c0-1.272-0.209-2.759,0.038-4.003C498.334,333.7,502.539,333.718,506.1,333.718z"
              />
            </g>
            <g id="Angola-1">
              <g
                id="Angola"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="329.579" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="321.786" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="329.579" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="337.376" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="485.478" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="329.579" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="345.169" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="321.786" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="329.579" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="337.376" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="345.169" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="337.376" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="345.169" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M480.604,347.886c-3.727-0.143-12.918,2.947-14.599-1.774
                    c-0.86-2.416-0.938-5.48-0.497-7.985c0.268-1.521,0.774-2.993,1.917-4.079c1.438-1.368,2.474-1.055,4.191-1.433
                    c3.292-0.725,2.81-4.479,2.687-7.051c-0.071-1.477-0.259-3.105,0.29-4.521c0.985-2.54,4.448-2.348,6.662-2.27
                    c2.298,0.081,4.597,0.102,6.896,0.109c1.656,0.005,4.792-0.53,6.26,0.171c3.004,1.437,1.556,6.838,5.166,7.597
                    c1.278,0.269,2.137-0.524,3.438,0.335c1.279,0.846,1.501,2.104,1.252,3.565c-0.501,2.943-4.37,1.73-6.258,3.313
                    c-3.286,2.756-1.732,7.046-1.688,10.602c0.045,3.742-3.469,3.919-6.388,3.919C486.806,348.385,483.72,348.005,480.604,347.886z"
              />
            </g>
            <g id="Democratic_Republic_of_Congo_1_">
              <g
                id="Democratic_Republic_of_Congo"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="516.657" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="290.606" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="298.399" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="321.786" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="329.579" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="313.989" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="321.786" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="290.606" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="298.399" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="313.989" r="2.196" />
                <circle fill="#aaaaaa" cx="485.478" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="313.989" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="313.989" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M480.242,309.769c1.363-0.51,2.097-1.589,2.231-3.01c0.128-1.34,0.099-2.73,1.394-3.4
                    c1.525-0.789,3.418-0.147,4.738-1.482c1.207-1.221,1.597-2.752,1.616-4.421c0.021-1.853,0.254-3.712,0.252-5.571
                    c-0.002-1.671-0.476-3.642,1.428-4.36c1.534-0.58,3.31,0.012,4.896-0.144c4.984-0.487,9.861-0.52,14.839-0.329
                    c2.228,0.086,5.564-0.226,7.117,1.791c1.407,1.826,0.356,3.922-1.622,4.635c-1.185,0.427-3.03,0.59-3.779,1.75
                    c-0.904,1.399-1.01,3.381-1.09,4.991c-0.149,3.038,0.004,6.124,0.004,9.167c0,2.439-0.778,6.642,1.236,8.129
                    c2.051,1.515,6.206,0.867,6.356,4.231c0.196,4.419-4.876,1.949-6.786,3.537c-1.812,1.506-0.725,4.458-1.984,6.214
                    c-1.547,2.154-4.556,0.434-5.289-1.634c-0.468-1.318-0.647-3.171-1.771-4.144c-1.412-1.224-3.237-0.085-4.722-1.247
                    c-1.406-1.101-1.077-2.168-1.592-3.619c-1.041-2.936-3.116-3.964-5.944-3.932c-3.666,0.042-7.326-0.06-10.994,0.121
                    c-1.98,0.098-5.864,0.562-6.495-2.128c-0.521-2.222,2.199-4.175,4.052-4.626C479.007,310.125,479.667,309.984,480.242,309.769z"
              />
            </g>
            <g id="Burundi_1">
              <g
                id="Burundi"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="516.657" cy="313.989" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M515.995,316.777c1.986,0.468,4.338-0.777,3.224-3.973
                    c-1.051-3.014-5.633-1.948-5.581,1.161C513.664,315.529,514.759,316.485,515.995,316.777z"
              />
            </g>
            <g id="Rwanda-1">
              <g
                id="Rwanda"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="516.657" cy="306.196" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M518.391,308.847c-1.378,0.845-3.676,0.332-4.478-1.153c-1.3-2.409,1.518-5.541,3.98-4.268
                    C520.032,304.532,520.589,307.499,518.391,308.847z"
              />
            </g>
            <g id="Kenya-1">
              <g
                id="Kenya"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="547.835" cy="298.399" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="547.835" cy="290.606" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="290.606" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="298.399" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M535.1,287.551c-2.593,0-5.433-0.996-6.213,2.062
                    c-0.552,2.161-0.073,4.643,0.213,6.814c0.174,1.322-0.264,3.104,0.659,4.293c1.295,1.669,3.279,0.577,4.809,1.492
                    c1.238,0.74,1.568,2.822,2.04,4.054c0.587,1.532,1.194,2.366,2.802,2.723c2.348,0.521,3.069-1.186,4.152-2.983
                    c0.927-1.538,1.949-2.601,3.543-3.533c2.811-1.645,4.336-2.024,4.606-5.516c0.173-2.243,0.164-5.065-0.634-7.208
                    c-0.985-2.643-4.061-2.563-6.311-2.359C541.604,287.675,538.25,287.551,535.1,287.551z"
              />
            </g>
            <g id="Uganda-1">
              <g
                id="Uganda"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M526.647,290.606c0,1.214-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.981-2.197-2.195
                        c0-1.215,0.986-2.195,2.197-2.195C525.663,288.411,526.647,289.392,526.647,290.606z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,298.399c0,1.215-0.984,2.196-2.198,2.196c-1.211,0-2.197-0.981-2.197-2.196
                        s0.986-2.195,2.197-2.195C525.663,296.204,526.647,297.185,526.647,298.399z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="298.399" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M527.23,289.923c0.679,2.593,0.582,5.975,0.202,8.618
                    c-0.523,3.646-5.446,2.692-7.982,2.694c-1.527,0.001-4.149,0.025-5.142-1.389c-0.699-0.996-0.574-2.489,0.185-3.398
                    c1.052-1.258,2.506-0.792,3.939-1.189c1.866-0.518,2.717-1.133,2.834-3.04c0.1-1.626,0.199-3.962,1.938-4.832
                    C525.04,286.469,526.782,288.22,527.23,289.923z"
              />
            </g>
            <g id="Congo-1">
              <g
                id="Congo"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="485.478" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="306.196" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="313.989" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M485.124,287.341c0.313-0.034,0.659-0.025,1.042,0.031
                    c2.43,0.363,2.688,2.634,2.511,4.753c-0.11,1.313-0.394,2.542-0.304,3.854c0.09,1.331,0.537,3.219-0.486,4.322
                    c-1.077,1.16-3.12,0.963-4.506,1.486c-0.985,0.373-1.628,1.207-2.036,2.186c-0.875,2.102-0.5,4.58-3.121,5.062
                    c-2.591,0.478-3.772,0.644-4.844,3.406c-0.68,1.752-0.546,4.481-2.663,5.066c-2.605,0.719-4.202-1.519-4.118-3.875
                    c0.085-2.375,2.297-2.592,4.131-3.038c4.019-0.979,2.728-5.509,5.856-7.272c1.852-1.046,4.163-1.066,4.825-3.54
                    c0.513-1.914,0.791-4.234,0.781-6.217C482.182,291.06,482.425,287.631,485.124,287.341z"
              />
            </g>
            <g id="Gabon-1">
              <g
                id="Gabon"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="477.682" cy="298.399" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="298.399" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="306.196" r="2.197" />
                <circle fill="#aaaaaa" cx="462.092" cy="306.196" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M466.136,300.257c0.911-1.819,0.73-3.863,3.021-4.615
                    c1.761-0.578,3.721,0.247,5.562-0.101c1.602-0.302,4.072-0.47,5.147,1.115c0.752,1.109,0.667,2.872-0.392,3.71
                    c-1.144,0.904-2.822,0.888-3.938,1.895c-0.785,0.708-1.738,1.451-2.073,2.521c-0.43,1.375-0.4,3.116-1.864,3.979
                    c-1.968,1.159-4.732,0.753-6.875,0.747c-1.076-0.003-1.952,0.38-3,0.375c-1.664-0.008-3.113-0.949-3.312-2.679
                    c-0.604-5.26,5.807-3.323,7.53-6.574C466.01,300.505,466.074,300.381,466.136,300.257z"
              />
            </g>
            <g id="Cameroon-1">
              <g
                id="Cameroon"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="477.682" cy="267.224" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="275.017" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="290.606" r="2.196" />
                <circle fill="#aaaaaa" cx="469.887" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="290.606" r="2.197" />
                <circle fill="#aaaaaa" cx="462.092" cy="290.606" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M463.099,287.384c1.051-0.258,1.824-0.169,2.591-0.912
                    c1.409-1.366,0.585-2.997,1.16-4.713c0.641-1.912,2.101-1.68,3.646-2.338c1.193-0.509,2.572-1.693,3.223-2.825
                    c1.569-2.731,0.767-6.327,0.907-9.313c0.062-1.315,0.053-2.31,1.16-2.988c0.991-0.608,1.982-0.48,2.938,0.053
                    c2.28,1.271,2.249,4.401,2.249,6.787c0,2.396,0.12,5.181-1.847,6.723c-1.938,1.517-5.432,1.919-6.133,4.624
                    c-0.346,1.334-0.446,3.574,0.854,4.479c1.359,0.947,3.153-0.268,4.624,0.327c2.095,0.847,3.35,4.246,1.244,5.693
                    c-3.056,2.099-7.87,0.491-11.416,0.817c-10.452,0.961-9.457-2.858-9.061-4.039C459.802,288.083,461.56,287.761,463.099,287.384z"
              />
            </g>
            <g id="Central_African_Republic-1">
              <g
                id="Central_African_Republic"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="508.862" cy="282.812" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="493.272" cy="275.017" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="282.812" r="2.196" />
                <circle fill="#aaaaaa" cx="485.478" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="282.812" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M489.278,278.266c1.141-1.087,1.126-1.791,1.195-3.257
                    c0.064-1.365,0.214-2.025,1.5-2.719c1.598-0.861,4.014-0.697,5.755-0.445c1.864,0.27,4.551-0.368,5.851,1.501
                    c1.041,1.498,0.366,4.409,1.864,5.569c0.981,0.76,2.397,0.066,3.53,0.372c3.312,0.897,4.411,6.573,0.132,6.472
                    c-2.477-0.06-4.962,0-7.439,0c-4.751,0-9.503,0-14.254,0c-3.199,0-6.398,0-9.598,0c-1.212,0-2.161-0.265-2.747-1.613
                    c-1.89-4.347,5.837-4.541,8.147-4.714C485.146,279.287,487.726,279.745,489.278,278.266z"
              />
            </g>
            <g id="Somalia-1">
              <g
                id="Somalia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="571.22" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="571.22" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="563.425" cy="275.017" r="2.196" />
                <circle fill="#aaaaaa" cx="563.425" cy="282.812" r="2.196" />
                <circle fill="#aaaaaa" cx="555.63" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="555.63" cy="290.606" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M560.16,283.69c0.021-0.082,0.041-0.166,0.059-0.251
                    c0.335-1.549,0.389-4.232-1.194-5.183c-2.252-1.352-5.703,0.928-6.472-2.676c-0.593-2.78,1.677-3.644,3.995-3.841
                    c3.342-0.284,6.495-0.706,9.897-0.356c1.624,0.167,4.087,0.079,5.617,0.704c3.927,1.604,1.854,5.944,2.409,9.095
                    c0.865,4.908-3.196,5.795-7.368,5.875c-2.951,0.057-6.01-0.418-7.93,2.367c-0.769,1.114-0.788,3.105-1.871,3.925
                    c-0.865,0.654-2.745,0.502-3.579-0.092c-1.104-0.788-1.576-2.661-1.047-3.909c0.604-1.421,1.93-1.479,3.254-1.798
                    C558.108,287.025,559.61,285.822,560.16,283.69z"
              />
            </g>
            <g id="Ethiopia-1">
              <g
                id="Ethiopia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="555.63" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="547.835" cy="275.017" r="2.196" />
                <circle fill="#aaaaaa" cx="547.835" cy="282.812" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="267.224" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="540.04" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="532.246" cy="267.224" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="275.017" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="282.812" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M530.157,264.796c1.196-0.895,2.948-1.095,4.677-1.206
                    c2.235-0.145,6.317-0.225,7.725,1.97c1.155,1.8-0.443,4.648,1.702,5.935c1.684,1.01,4.151-0.106,5.605,1.287
                    c1.289,1.235,0.452,3.258,1.267,4.778c1.45,2.706,5.157,0.707,6.837,2.938c1.721,2.286-0.283,5.014-2.56,5.728
                    c-2.634,0.826-5.61-0.111-8.265-0.351c-0.283-0.025-0.594,0.004-0.879,0.009c-4.011-0.028-8.021-0.057-12.031-0.085
                    c-2.016-0.015-3.812,0.422-4.745-1.527c-0.872-1.822-0.306-4.24-0.479-6.179c-0.225-2.52-0.308-5.023-0.308-7.59
                    c0-0.945-0.123-1.981-0.018-2.918C528.837,266.252,529.38,265.377,530.157,264.796z"
              />
            </g>
            <g id="Sudan-1">
              <g
                id="Sudan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M534.441,243.837c0,1.213-0.982,2.196-2.196,2.196c-1.215,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.98-2.195,2.195-2.195C533.459,241.642,534.441,242.621,534.441,243.837z"
                />
                <circle fill="#aaaaaa" cx="532.246" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="532.246" cy="259.426" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M526.647,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.197-0.983-2.197-2.196
                        c0-1.216,0.986-2.195,2.197-2.195C525.663,241.642,526.647,242.621,526.647,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,251.633c0,1.212-0.984,2.196-2.198,2.196c-1.211,0-2.197-0.984-2.197-2.196
                        c0-1.213,0.986-2.2,2.197-2.2C525.663,249.433,526.647,250.42,526.647,251.633z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,259.426c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.197-0.982-2.197-2.196
                        s0.986-2.194,2.197-2.194C525.663,257.231,526.647,258.212,526.647,259.426z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,267.224c0,1.21-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.985-2.197-2.195
                        c0-1.215,0.986-2.199,2.197-2.199C525.663,265.024,526.647,266.009,526.647,267.224z"
                />
                <path
                  fill="#aaaaaa"
                  d="M518.854,243.837c0,1.213-0.985,2.196-2.199,2.196c-1.211,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.984-2.195,2.195-2.195C517.869,241.642,518.854,242.621,518.854,243.837z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="267.224" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M511.058,243.837c0,1.213-0.981,2.196-2.195,2.196c-1.215,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.981-2.195,2.196-2.195C510.076,241.642,511.058,242.621,511.058,243.837z"
                />
                <circle fill="#aaaaaa" cx="508.862" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="259.426" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="267.224" r="2.196" />
                <circle fill="#aaaaaa" cx="501.067" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="267.224" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M524.687,240.884c2.953,0.074,8.279-1.226,10.466,1.182
                    c1.776,1.956,0.447,7.177,0.447,9.555c0,2.535-0.004,5.07,0,7.605c0.005,3.246-1.54,2.947-4.334,3.649
                    c-4.894,1.229-3.834,1.582-3.999,6.39c-0.044,1.29-7.174,0.905-14.168,0.869c-6.23-0.033-11.678,0.801-12.803,0.156
                    c-3.916-2.244-1.174-4.47-1.846-7.786c-0.348-1.714-1.38-3.21-0.107-4.852c1.174-1.514,3.442-1.547,4.99-2.484
                    c1.731-1.051,2.865-3.273,2.94-5.285c0.129-3.456-2.125-8.979,3.177-9.008C514.529,240.847,519.608,240.756,524.687,240.884z"
              />
            </g>
            <g id="Eritrea-1">
              <g
                id="Eritrea"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="547.835" cy="267.224" r="2.196" />
                <circle fill="#aaaaaa" cx="540.04" cy="259.426" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M543.808,260.339c1.616,2.483,5.618,2.549,6.967,5.535
                    c1.404,3.106-2.091,5.53-4.633,3.792c-2.212-1.512-2.244-4.551-4.214-6.161c-1.38-1.129-2.95-1.093-4.161-2.582
                    c-1.332-1.64-0.692-3.972,1.497-4.43C541.552,256.014,542.738,258.693,543.808,260.339z"
              />
            </g>
            <g id="Djibouti-1">
              <g
                id="Djibouti"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="555.63" cy="267.224" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M558.2,268.524c1.549-4.517-5.496-5.786-5.593-1.371
                    C552.546,269.952,557.178,271.503,558.2,268.524z"
              />
            </g>
            <g id="Chad-1">
              <g
                id="Chad"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="251.633" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M495.468,243.837c0,1.213-0.981,2.196-2.195,2.196c-1.215,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.981-2.195,2.196-2.195C494.486,241.642,495.468,242.621,495.468,243.837z"
                />
                <circle fill="#aaaaaa" cx="493.272" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="259.426" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="267.224" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M487.675,243.837c0,1.213-0.985,2.196-2.199,2.196c-1.211,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.984-2.195,2.195-2.195C486.689,241.642,487.675,242.621,487.675,243.837z"
                />
                <circle fill="#aaaaaa" cx="485.478" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="267.224" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="477.682" cy="259.426" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M496.531,257.94c-1.296,2.798,0.167,5.797-0.153,8.908
                    c-0.442,4.292-3.84,2.536-6.248,4.691c-1.627,1.454-1.094,4.273-2.909,5.619c-1.216,0.901-3.67,0.47-4.38-0.941
                    c-0.574-1.141-0.614-2.582-0.749-3.827c-0.252-2.349-0.075-4.644-0.771-6.882c-0.909-2.928-2.629-2.376-4.914-3.524
                    c-1.781-0.895-2.466-3.667-0.787-5.17c1.69-1.514,4.387,0.359,5.899-1.576c1.736-2.223,0.453-6.826,0.579-9.477
                    c0.081-1.712,0.244-3.905,1.967-4.767c1.537-0.768,3.402-0.236,5.036-0.236c2.232,0,4.791-1.024,6.32,1.162
                    c1.162,1.662,1.145,3.98,2.608,5.47c1.312,1.335,3.24,0.842,4.685,1.986c1.359,1.077,1.92,2.814,0.559,4.152
                    c-1.621,1.592-4.593,1.44-6.011,3.231C496.955,257.149,496.715,257.543,496.531,257.94z"
              />
            </g>
            <g id="Nigeria-1">
              <g
                id="Nigeria"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="469.887" cy="267.224" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="462.092" cy="267.224" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="275.017" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="282.812" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M456.494,267.224c0,1.21-0.982,2.195-2.195,2.195c-1.214,0-2.2-0.985-2.2-2.195
                        c0-1.215,0.986-2.199,2.2-2.199C455.512,265.024,456.494,266.009,456.494,267.224z"
                />
                <circle fill="#aaaaaa" cx="454.296" cy="275.017" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M456.494,282.812c0,1.212-0.982,2.195-2.195,2.195c-1.214,0-2.2-0.983-2.2-2.195
                        c0-1.213,0.986-2.199,2.2-2.199C455.512,280.613,456.494,281.6,456.494,282.812z"
                />
                <circle fill="#aaaaaa" cx="446.504" cy="267.224" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M464.348,263.509c2.062,0.094,4.256-0.146,6.249,0.515
                    c2.756,0.914,2.754,4.383,2.877,6.958c0.092,1.922-0.215,4.299-1.423,5.868c-1.561,2.028-4.448,0.645-5.854,2.544
                    c-0.68,0.917-0.771,1.569-0.848,2.74c-0.268,4.119-3.6,4.075-6.985,4.033c-2.755-0.033-7.285-0.311-7.265-4.033
                    c0.016-2.808,0.621-5.511,0.625-8.25c0.002-1.564-0.768-2.553-2.188-3.104c-1.514-0.587-3.725-0.059-5.038-0.928
                    c-1.688-1.117-1.413-3.352-0.339-4.831c1.357-1.87,4.039-1.281,6.098-1.34C454.951,263.547,459.65,263.295,464.348,263.509z"
              />
            </g>
            <g id="Niger-1">
              <g
                id="Niger"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M479.878,243.837c0,1.213-0.981,2.196-2.195,2.196c-1.215,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.981-2.195,2.196-2.195C478.896,241.642,479.878,242.621,479.878,243.837z"
                />
                <circle fill="#aaaaaa" cx="477.682" cy="251.633" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M472.084,243.837c0,1.213-0.984,2.196-2.198,2.196c-1.211,0-2.196-0.983-2.196-2.196
                        c0-1.216,0.985-2.195,2.196-2.195C471.1,241.642,472.084,242.621,472.084,243.837z"
                />
                <circle fill="#aaaaaa" cx="469.887" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="259.426" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M464.288,243.837c0,1.213-0.982,2.196-2.196,2.196c-1.215,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.98-2.195,2.195-2.195C463.306,241.642,464.288,242.621,464.288,243.837z"
                />
                <circle fill="#aaaaaa" cx="462.092" cy="251.633" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="259.426" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M456.494,251.633c0,1.212-0.982,2.196-2.195,2.196c-1.214,0-2.2-0.984-2.2-2.196
                        c0-1.213,0.986-2.2,2.2-2.2C455.512,249.433,456.494,250.42,456.494,251.633z"
                />
                <circle fill="#aaaaaa" cx="454.296" cy="259.426" r="2.198" />
                <circle fill="#aaaaaa" cx="446.504" cy="259.426" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M458.776,247.127c0.028-0.067,0.056-0.139,0.081-0.214
                    c0.754-2.23-0.825-4.466,1.549-6.022c2.175-1.426,5.324-0.84,7.761-0.84c2.293,0,4.547,0.232,6.765,0.338
                    c1.341,0.063,3.064-0.224,4.175,0.626c1.779,1.361,1.771,3.648,1.491,5.671c-0.29,2.098,0.861,4.86-0.052,6.786
                    c-1.216,2.565-5.539,0.048-7.293,2.502c-1.305,1.825,0.598,4.466-1.452,5.94c-1.481,1.066-4.952,0.121-6.697,0.097
                    c-5.837-0.082-11.679-0.249-17.513,0.039c-1.445,0.071-3.045,0.377-3.826-0.828c-0.521-0.806-0.564-3.218,0.034-3.958
                    c1.798-2.225,5.962-0.102,7.096-3.286c0.862-2.427,0.116-4.146,3.036-5.093C455.793,248.281,457.958,249.065,458.776,247.127z"
              />
            </g>
            <g id="Benin-1">
              <g
                id="Benin"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="446.504" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="446.504" cy="282.812" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M443.97,272.764c2.161-2.452,5.466-0.428,5.629,2.287
                    c0.096,1.598-0.492,2.385-0.666,3.833c-0.197,1.646,0.828,3.475,0.401,5.141c-0.538,2.103-3.003,2.372-4.59,1.255
                    c-2.08-1.464-0.646-4.107-0.694-6.137C444.006,277.258,442.539,274.387,443.97,272.764z"
              />
            </g>
            <g id="Togo-1">
              <g
                id="Togo"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M440.904,282.812c0,1.212-0.982,2.195-2.195,2.195c-1.214,0-2.2-0.983-2.2-2.195
                        c0-1.213,0.986-2.199,2.2-2.199C439.922,280.613,440.904,281.6,440.904,282.812z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M440.345,285.264c-2.483,1.686-5.931-1.286-4.197-3.961
                    C438.928,277.014,443.849,282.886,440.345,285.264z"
              />
            </g>
            <g id="Ghana-1">
              <g
                id="Ghana"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="438.707" cy="275.017" r="2.198" />
                <circle fill="#aaaaaa" cx="430.914" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="430.914" cy="282.812" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M433.664,272.965c0.155,0.072,0.324,0.133,0.516,0.171
                    c1.081,0.217,2.41-0.256,3.419-0.585c2.742-0.895,5.376,1.538,3.837,4.464c-1.444,2.745-5.527,0.351-6.963,3.281
                    c-0.712,1.453-0.667,3.915-2.036,4.974c-1.235,0.956-3.611,0.57-4.451-0.747c-1.162-1.823,0.801-4.161,0.655-6.152
                    c-0.115-1.586-1.221-3.107-0.481-4.707c0.642-1.388,2.617-1.918,3.994-1.503C432.77,272.347,433.147,272.725,433.664,272.965z"
              />
            </g>
            <g id="Cote_D_x27_Ivoire-1">
              <g
                id="Cote_Ivoire"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="423.117" cy="275.017" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M425.314,282.812c0,1.212-0.982,2.195-2.195,2.195c-1.215,0-2.2-0.983-2.2-2.195
                        c0-1.213,0.985-2.199,2.2-2.199C424.332,280.613,425.314,281.6,425.314,282.812z"
                />
                <circle fill="#aaaaaa" cx="423.117" cy="290.606" r="2.198" />
                <circle fill="#aaaaaa" cx="415.324" cy="282.812" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M419.599,277.213c0.573-2.163,0.054-4.517,2.805-5.042
                    c1.827-0.35,3.612,0.698,4.032,2.543c0.63,2.771-0.897,5.422-0.671,8.171c0.201,2.438,1.643,5.577,0.854,7.996
                    c-1.197,3.682-6.009,3.011-6.803-0.468c-0.503-2.204-0.749-4.507-3.564-4.675c-2.005-0.119-4.16-0.22-3.82-3.021
                    C412.981,278.193,418.557,281.14,419.599,277.213z"
              />
            </g>
            <g id="Liberia-1">
              <g
                id="Liberia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="415.324" cy="290.606" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M417.724,292.358c-4.325,3.674-7.892-2.861-2.975-4.387
                    C417.151,287.227,419.989,290.433,417.724,292.358z"
              />
            </g>
            <g id="Burkina_Faso-1">
              <g
                id="Burkina_Faso"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M440.904,267.224c0,1.21-0.982,2.195-2.195,2.195c-1.214,0-2.2-0.985-2.2-2.195
                        c0-1.215,0.986-2.199,2.2-2.199C439.922,265.024,440.904,266.009,440.904,267.224z"
                />
                <circle fill="#aaaaaa" cx="430.914" cy="267.224" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M439.432,270.425c-1.356,0.478-3.119,0.026-4.5-0.04
                    c-1.376-0.066-2.799,0.148-4.167,0c-1.444-0.156-2.662-0.426-3-2c-0.914-4.264,3.394-3.925,6.21-4.128
                    c2.41-0.173,7.062-1.013,7.734,2.307C442.044,268.22,440.992,269.875,439.432,270.425z"
              />
            </g>
            <g id="Mali-1">
              <g
                id="Mali"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="446.504" cy="251.633" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M440.904,243.837c0,1.213-0.982,2.196-2.195,2.196c-1.214,0-2.2-0.983-2.2-2.196
                        c0-1.216,0.986-2.195,2.2-2.195C439.922,241.642,440.904,242.621,440.904,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M440.904,251.633c0,1.212-0.982,2.196-2.195,2.196c-1.214,0-2.2-0.984-2.2-2.196
                        c0-1.213,0.986-2.2,2.2-2.2C439.922,249.433,440.904,250.42,440.904,251.633z"
                />
                <circle fill="#aaaaaa" cx="438.707" cy="259.426" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M433.111,236.044c0,1.21-0.985,2.194-2.199,2.194c-1.213,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.982-2.201,2.195-2.201C432.126,233.843,433.111,234.829,433.111,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M433.111,243.837c0,1.213-0.985,2.196-2.199,2.196c-1.213,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.982-2.195,2.195-2.195C432.126,241.642,433.111,242.621,433.111,243.837z"
                />
                <circle fill="#aaaaaa" cx="430.914" cy="251.633" r="2.197" />
                <circle fill="#aaaaaa" cx="430.914" cy="259.426" r="2.197" />
                <circle fill="#aaaaaa" cx="423.117" cy="259.426" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M425.314,267.224c0,1.21-0.982,2.195-2.195,2.195c-1.215,0-2.2-0.985-2.2-2.195
                        c0-1.215,0.985-2.199,2.2-2.199C424.332,265.024,425.314,266.009,425.314,267.224z"
                />
                <circle fill="#aaaaaa" cx="415.324" cy="259.426" r="2.198" />
                <circle fill="#aaaaaa" cx="407.529" cy="259.426" r="2.198" />
                <circle fill="#aaaaaa" cx="407.529" cy="267.224" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M427.897,254.174c0.075-0.497,0.08-1.088,0.038-1.784
                    c-0.302-4.994-0.165-9.823-0.171-14.798c-0.002-1.658-0.13-3.372,1.591-4.263c3.326-1.723,4.442,1.425,4.771,3.947
                    c0.559,4.298,4.148,1.765,6.471,3.982c2.006,1.916,1.27,5.036,3.943,6.466c1.476,0.788,3.187,0.359,4.334,1.868
                    c0.985,1.296,0.839,2.968-0.297,4.126c-1.533,1.563-4.107,0.755-5.765,2.025c-2.206,1.691,0.044,5.122-2.46,6.44
                    c-1.243,0.654-3.522,0.442-5.088,0.367c-2.314-0.111-5.466-0.661-7.55,0.638c-2.446,1.525-0.589,4.756-2.247,6.54
                    c-1.57,1.689-4.358,0.38-5.418-1.257c-1.085-1.675-0.563-4.403-2.748-5.254c-1.749-0.681-3.998-0.714-5.079,0.746
                    c-1.196,1.616-1.301,3.557-2.345,5.031c-1.188,1.679-3.156,1.707-4.543,0.26c-1.308-1.366-0.808-2.828-0.896-4.526
                    c-0.148-2.863-1.245-7.438,2.53-8.475c2.64-0.725,6.206-0.203,9-0.203c3.055,0,5.982,0.669,9.052,0.382
                    C426.899,256.258,427.684,255.6,427.897,254.174z"
              />
            </g>
            <g id="Republic_of_Guinea-1">
              <g
                id="Republic_of_Guinea"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="415.324" cy="267.224" r="2.198" />
                <circle fill="#aaaaaa" cx="415.324" cy="275.017" r="2.198" />
                <circle fill="#aaaaaa" cx="407.529" cy="275.017" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M411.879,269.378c0.576-1.958,0.352-4.229,2.789-4.725
                    c3.933-0.799,3.597,4.288,3.597,6.731c0,2.535,0.527,6.397-2.504,6.999c-1.381,0.274-2.738-0.213-4.001-0.336
                    c-1.427-0.139-2.897,0.142-4.326,0.004c-2.317-0.223-4.386-3.669-2.598-5.376C406.971,270.639,410.826,272.948,411.879,269.378z"
              />
            </g>
            <g id="Sierra_Leone-1">
              <g
                id="Sierra_Leone"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="407.529" cy="282.812" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M408.784,280.195c2.323,0.954,1.637,6.062-1.288,5.603
                    c-0.97-0.152-1.612-0.374-2.171-1.161C403.771,282.443,406.222,279.145,408.784,280.195z"
              />
            </g>
            <g id="Guinea_Bissau-1">
              <g
                id="Guinea_Bissau"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="399.734" cy="275.017" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M397.563,277.536c-1.523-1.486-1.002-3.556,0.443-4.773
                    c0.805-0.678,1.437-1.094,2.564-0.731c1.687,0.543,2.802,3.057,1.545,4.541C401.116,277.753,398.837,278.779,397.563,277.536z"
              />
            </g>
            <g id="Cape_Verde_1_">
              <g
                id="Cape_Verde"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="384.144" cy="267.224" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M384.079,264.316c1.669-0.139,2.927,1.642,2.63,3.207
                    c-0.174,0.919-0.151,1.542-1.094,2.023C381.453,271.675,379.818,264.67,384.079,264.316z"
              />
            </g>
            <g id="Gambia-1">
              <g
                id="Gambia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="391.939" cy="267.224" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M393.677,264.846c0.948,1.127,0.817,3.401-0.055,4.372
                    c-1.279,1.424-3.604,0.844-4.356-0.848c-0.788-1.773,1.181-4.284,3.097-4.178C392.929,264.224,393.362,264.471,393.677,264.846z"
              />
            </g>
            <g id="Senegal-1">
              <g
                id="Senegal"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="399.734" cy="259.426" r="2.198" />
                <circle fill="#aaaaaa" cx="391.939" cy="259.426" r="2.198" />
                <circle fill="#aaaaaa" cx="399.734" cy="267.224" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M396.671,256.423c1.932,0.64,3.619-0.51,5.111,1.285
                    c1.121,1.348,0.823,2.736,0.929,4.39c0.104,1.62,0.51,3.32,0.305,4.938c-0.202,1.591-1.112,3.194-2.803,3.509
                    c-1.475,0.273-3.175-0.265-3.624-1.829c-0.524-1.827,1.324-4.466-0.716-5.771c-1.156-0.739-3.38-0.207-4.696-0.309
                    c-2.617-0.203-3.624-3.005-2.567-5.191C390.079,254.4,394.129,255.581,396.671,256.423z"
              />
            </g>
            <g id="Mauritania-1">
              <g
                id="Mauritania"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="423.117" cy="228.246" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M425.314,236.044c0,1.21-0.982,2.194-2.195,2.194c-1.215,0-2.2-0.984-2.2-2.194
                        c0-1.215,0.985-2.201,2.2-2.201C424.332,233.843,425.314,234.829,425.314,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M425.314,243.837c0,1.213-0.982,2.196-2.195,2.196c-1.215,0-2.2-0.983-2.2-2.196
                        c0-1.216,0.985-2.195,2.2-2.195C424.332,241.642,425.314,242.621,425.314,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M425.314,251.633c0,1.212-0.982,2.196-2.195,2.196c-1.215,0-2.2-0.984-2.2-2.196
                        c0-1.213,0.985-2.2,2.2-2.2C424.332,249.433,425.314,250.42,425.314,251.633z"
                />
                <path
                  fill="#aaaaaa"
                  d="M417.521,236.044c0,1.21-0.985,2.194-2.198,2.194c-1.212,0-2.197-0.984-2.197-2.194
                        c0-1.215,0.985-2.201,2.197-2.201C416.536,233.843,417.521,234.829,417.521,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M417.521,243.837c0,1.213-0.985,2.196-2.198,2.196c-1.212,0-2.197-0.983-2.197-2.196
                        c0-1.216,0.985-2.195,2.197-2.195C416.536,241.642,417.521,242.621,417.521,243.837z"
                />
                <circle fill="#aaaaaa" cx="415.324" cy="251.633" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M409.727,243.837c0,1.213-0.983,2.196-2.197,2.196c-1.215,0-2.198-0.983-2.198-2.196
                        c0-1.216,0.983-2.195,2.198-2.195C408.743,241.642,409.727,242.621,409.727,243.837z"
                />
                <circle fill="#aaaaaa" cx="407.529" cy="251.633" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M401.932,243.837c0,1.213-0.985,2.196-2.198,2.196c-1.212,0-2.197-0.983-2.197-2.196
                        c0-1.216,0.985-2.195,2.197-2.195C400.946,241.642,401.932,242.621,401.932,243.837z"
                />
                <circle fill="#aaaaaa" cx="399.734" cy="251.633" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M404.562,240.051c1.365,0,3.609,0.465,4.878,0.017
                    c3.21-1.134,1.66-5.431,4.649-6.807c1.366-0.629,3.262-0.017,4.42-0.73c1.086-0.669,1.236-2.39,1.59-3.488
                    c0.809-2.516,1.812-4.363,4.659-3.029c1.812,0.85,2.269,2.65,2.356,4.521c0.146,3.066-0.689,6.076-0.683,9.134
                    c0.009,4.033,0.671,8.209-0.229,12.192c-0.917,4.06-4.912,3.346-8.233,2.986c-2.741-0.297-5.425-1.094-8.37-0.963
                    c-2.86,0.127-5.771,0.167-8.671,0.167c-0.989,0-2.804,0.272-3.545-0.455c-1.692-1.66-0.398-6.061-0.581-8.173
                    c-0.136-1.583-0.451-3.472,1.062-4.431C399.393,240.022,402.805,240.051,404.562,240.051z"
              />
            </g>
            <g id="Western_Sahara-1">
              <g
                id="Western_Sahara"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="415.324" cy="228.246" r="2.198" />
                <circle fill="#aaaaaa" cx="407.529" cy="228.246" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M409.727,236.044c0,1.21-0.983,2.194-2.197,2.194c-1.215,0-2.198-0.984-2.198-2.194
                        c0-1.215,0.983-2.201,2.198-2.201C408.743,233.843,409.727,234.829,409.727,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M401.932,236.044c0,1.21-0.985,2.194-2.198,2.194c-1.212,0-2.197-0.984-2.197-2.194
                        c0-1.215,0.985-2.201,2.197-2.201C400.946,233.843,401.932,234.829,401.932,236.044z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M403.812,229.596c0.585-1.117,0.716-2.639,1.626-3.559
                    c1.257-1.271,2.636-0.638,4.185-0.654c2.486-0.026,6.609-1.56,8.279,1.116c1.346,2.156-0.388,5.1-2.802,5.385
                    c-1.744,0.206-2.938-0.35-4.086,1.301c-0.805,1.157-0.386,2.705-0.914,4.038c-1.194,3.014-4.434,1.355-6.679,1.481
                    c-2.051,0.116-5.414,1.182-6.554-1.277c-0.8-1.726,0.257-3.733,1.807-4.62C400.435,231.8,402.776,231.573,403.812,229.596z"
              />
            </g>
            <g id="Morocco-1">
              <g
                id="Morocco"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M425.314,204.863c0,1.214-0.982,2.196-2.195,2.196c-1.215,0-2.2-0.982-2.2-2.196
                        c0-1.216,0.985-2.196,2.2-2.196C424.332,202.667,425.314,203.647,425.314,204.863z"
                />
                <circle fill="#aaaaaa" cx="415.324" cy="212.659" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M440.904,204.863c0,1.214-0.982,2.196-2.195,2.196c-1.214,0-2.2-0.982-2.2-2.196
                        c0-1.216,0.986-2.196,2.2-2.196C439.922,202.667,440.904,203.647,440.904,204.863z"
                />
                <circle fill="#aaaaaa" cx="438.707" cy="212.659" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M433.111,204.863c0,1.214-0.985,2.196-2.199,2.196c-1.213,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.982-2.196,2.195-2.196C432.126,202.667,433.111,203.647,433.111,204.863z"
                />
                <circle fill="#aaaaaa" cx="430.914" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="423.117" cy="212.659" r="2.198" />
                <circle fill="#aaaaaa" cx="415.324" cy="220.454" r="2.198" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M413.413,210.241c1.265-1.519,3.704-1.439,4.977-3.069
                    c1.909-2.445,1.598-4.959,5.201-5.612c2.909-0.528,6.162-0.176,9.138-0.176c2.811,0,6.24-0.783,8.078,1.663
                    c1.702,2.267,1.38,6.94,1.126,9.68c-0.374,4.04-5.592,3.657-8.796,3.657c-2.825,0-5.624-0.119-8.412,0.004
                    c-1.562,0.068-3.306-0.182-4.464,0.942c-1.856,1.802-2.22,6.37-5.494,6.054c-2.519-0.243-2.255-3.614-2.162-5.456
                    C412.72,215.617,411.825,212.149,413.413,210.241z"
              />
            </g>
            <g id="Algeria-1">
              <g
                id="Algeria"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="462.092" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="462.092" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M464.288,236.044c0,1.21-0.982,2.194-2.196,2.194c-1.215,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.98-2.201,2.195-2.201C463.306,233.843,464.288,234.829,464.288,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M456.494,204.863c0,1.214-0.982,2.196-2.195,2.196c-1.214,0-2.2-0.982-2.2-2.196
                        c0-1.216,0.986-2.196,2.2-2.196C455.512,202.667,456.494,203.647,456.494,204.863z"
                />
                <circle fill="#aaaaaa" cx="454.296" cy="212.659" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M456.494,220.454c0,1.211-0.982,2.195-2.195,2.195c-1.214,0-2.2-0.984-2.2-2.195
                        c0-1.215,0.986-2.2,2.2-2.2C455.512,218.254,456.494,219.239,456.494,220.454z"
                />
                <circle fill="#aaaaaa" cx="454.296" cy="228.246" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M456.494,236.044c0,1.21-0.982,2.194-2.195,2.194c-1.214,0-2.2-0.984-2.2-2.194
                        c0-1.215,0.986-2.201,2.2-2.201C455.512,233.843,456.494,234.829,456.494,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M456.494,243.837c0,1.213-0.982,2.196-2.195,2.196c-1.214,0-2.2-0.983-2.2-2.196
                        c0-1.216,0.986-2.195,2.2-2.195C455.512,241.642,456.494,242.621,456.494,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M448.701,204.863c0,1.214-0.985,2.196-2.199,2.196c-1.213,0-2.195-0.982-2.195-2.196
                        c0-1.216,0.982-2.196,2.195-2.196C447.716,202.667,448.701,203.647,448.701,204.863z"
                />
                <circle fill="#aaaaaa" cx="446.504" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="446.504" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="446.504" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M448.701,236.044c0,1.21-0.985,2.194-2.199,2.194c-1.213,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.982-2.201,2.195-2.201C447.716,233.843,448.701,234.829,448.701,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M448.701,243.837c0,1.213-0.985,2.196-2.199,2.196c-1.213,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.982-2.195,2.195-2.195C447.716,241.642,448.701,242.621,448.701,243.837z"
                />
                <path
                  fill="#aaaaaa"
                  d="M440.904,220.454c0,1.211-0.982,2.195-2.195,2.195c-1.214,0-2.2-0.984-2.2-2.195
                        c0-1.215,0.986-2.2,2.2-2.2C439.922,218.254,440.904,219.239,440.904,220.454z"
                />
                <circle fill="#aaaaaa" cx="438.707" cy="228.246" r="2.198" />
                <path
                  fill="#aaaaaa"
                  d="M440.904,236.044c0,1.21-0.982,2.194-2.195,2.194c-1.214,0-2.2-0.984-2.2-2.194
                        c0-1.215,0.986-2.201,2.2-2.201C439.922,233.843,440.904,234.829,440.904,236.044z"
                />
                <circle fill="#aaaaaa" cx="430.914" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="430.914" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M425.314,220.454c0,1.211-0.982,2.195-2.195,2.195c-1.215,0-2.2-0.984-2.2-2.195
                        c0-1.215,0.985-2.2,2.2-2.2C424.332,218.254,425.314,219.239,425.314,220.454z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M433.987,232.676c-1.546-1.387-3.998-1.178-5.397-2.779
                    c-1.202-1.378-0.199-3.022-1.388-4.403c-1.725-2.004-5.596-1.047-6.713-3.78c-0.559-1.365-0.061-3.256,1.28-3.991
                    c1.404-0.771,3.923,0.158,5.503,0.226c2.514,0.106,5.101-0.252,7.616-0.336c2.405-0.08,6.811,0.532,8.089-2.22
                    c0.801-1.724,0.964-4.483,1.068-6.373c0.105-1.932-0.812-4.097-0.023-5.963c0.621-1.47,2.731-2.229,4.158-2.398
                    c2.599-0.308,7.833-0.039,9.048,2.898c1.146,2.77-0.075,6.732,0.537,9.828c0.262,1.326,0.557,3.174,1.833,3.67
                    c1.218,0.474,2.896-0.18,4.129,0.367c1.923,0.852,1.573,3.983,1.65,5.665c0.143,3.102,0.029,5.959-0.335,8.99
                    c-0.235,1.958,0.554,5.065-1.353,6.437c-1.766,1.271-4.103-0.071-5.547,1.923c-1.088,1.503-0.64,3.701-1.711,5.236
                    c-1.351,1.935-3.334,1.356-5.291,1.022c-1.989-0.34-4.584,0.863-6.339-0.439c-1.845-1.368-1.266-4.289-2.525-5.979
                    c-1.265-1.696-3.196-0.741-4.825-1.717C435.404,237.333,435.704,234.218,433.987,232.676z"
              />
            </g>
            <g id="Tunisia-1">
              <g
                id="Tunisia"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <g>
                  <path
                    fill="#aaaaaa"
                    d="M464.288,204.863c0,1.214-0.982,2.196-2.196,2.196c-1.215,0-2.195-0.982-2.195-2.196
                            c0-1.216,0.98-2.196,2.195-2.196C463.306,202.667,464.288,203.647,464.288,204.863z"
                  />
                  <circle fill="#aaaaaa" cx="462.092" cy="212.659" r="2.196" />
                </g>
                <circle fill="#aaaaaa" cx="469.887" cy="212.659" r="2.197" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M461.529,201.718c2.614-0.599,3.103,1.786,3.767,3.715
                    c0.973,2.82,1.746,3.732,4.804,4.285c2.203,0.398,4.315,3.265,2.29,5.157c-1.781,1.662-6.115,0.763-8.587,0.88
                    c-1.563,0.074-2.78,0.471-3.865-0.718c-0.926-1.015-1.07-2.583-1.005-3.986c0.069-1.481,0.437-2.804,0.5-4.296
                    C459.515,204.834,459.071,202.28,461.529,201.718z"
              />
            </g>
            <g id="Libya-1">
              <g
                id="Libya"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="501.067" cy="212.659" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="501.067" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M503.265,236.044c0,1.21-0.985,2.194-2.199,2.194c-1.211,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.984-2.201,2.195-2.201C502.279,233.843,503.265,234.829,503.265,236.044z"
                />
                <path
                  fill="#aaaaaa"
                  d="M503.265,243.837c0,1.213-0.985,2.196-2.199,2.196c-1.211,0-2.195-0.983-2.195-2.196
                        c0-1.216,0.984-2.195,2.195-2.195C502.279,241.642,503.265,242.621,503.265,243.837z"
                />
                <circle fill="#aaaaaa" cx="493.272" cy="212.659" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="493.272" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M495.468,236.044c0,1.21-0.981,2.194-2.195,2.194c-1.215,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.981-2.201,2.196-2.201C494.486,233.843,495.468,234.829,495.468,236.044z"
                />
                <circle fill="#aaaaaa" cx="485.478" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="485.478" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M487.675,236.044c0,1.21-0.985,2.194-2.199,2.194c-1.211,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.984-2.201,2.195-2.201C486.689,233.843,487.675,234.829,487.675,236.044z"
                />
                <circle fill="#aaaaaa" cx="477.682" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="477.682" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M479.878,236.044c0,1.21-0.981,2.194-2.195,2.194c-1.215,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.981-2.201,2.196-2.201C478.896,233.843,479.878,234.829,479.878,236.044z"
                />
                <circle fill="#aaaaaa" cx="469.887" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="469.887" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M472.084,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.985-2.201,2.196-2.201C471.1,233.843,472.084,234.829,472.084,236.044z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M470.335,238.697c-3.144-0.468-3.168-1.772-3.436-5.071
                    c-0.335-4.128,0.106-8.242,0.176-12.369c0.031-1.789-0.351-2.669,1.463-3.437c1.64-0.692,3.604-0.536,5.342-0.638
                    c2.665-0.158,5.357-0.299,8.044-0.299c1.542,0,3,0.18,4.342-0.629c2.508-1.511,2.845-5.184,5.339-6.996
                    c2.104-1.529,7.624-1.93,10.17-1.041c3.334,1.164,2.991,5.498,2.991,8.45c0,8.147,0.671,16.336-0.278,24.456
                    c-0.23,1.976-0.421,5.158-2.587,5.594c-2.058,0.412-3.31-1.018-4.008-2.706c-0.656-1.585-1.097-3.451-2.798-4.252
                    c-2.309-1.087-4.835-0.637-7.252-0.794c-2.811-0.182-5.602,0.057-8.41-0.083c-2.678-0.133-5.63,0.176-8.4-0.101
                    C470.784,238.758,470.552,238.729,470.335,238.697z"
              />
            </g>
            <g id="Egypt_1">
              <g
                id="Egypt"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M526.647,228.246c0,1.214-0.984,2.196-2.198,2.196c-1.211,0-2.197-0.982-2.197-2.196
                        s0.986-2.195,2.197-2.195C525.663,226.051,526.647,227.032,526.647,228.246z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,236.044c0,1.21-0.984,2.194-2.198,2.194c-1.211,0-2.197-0.984-2.197-2.194
                        c0-1.215,0.986-2.201,2.197-2.201C525.663,233.843,526.647,234.829,526.647,236.044z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="220.454" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="228.246" r="2.197" />
                <path
                  fill="#aaaaaa"
                  d="M518.854,236.044c0,1.21-0.985,2.194-2.199,2.194c-1.211,0-2.195-0.984-2.195-2.194
                        c0-1.215,0.984-2.201,2.195-2.201C517.869,233.843,518.854,234.829,518.854,236.044z"
                />
                <circle fill="#aaaaaa" cx="508.862" cy="220.454" r="2.196" />
                <circle fill="#aaaaaa" cx="508.862" cy="228.246" r="2.196" />
                <path
                  fill="#aaaaaa"
                  d="M511.058,236.044c0,1.21-0.981,2.194-2.195,2.194c-1.215,0-2.196-0.984-2.196-2.194
                        c0-1.215,0.981-2.201,2.196-2.201C510.076,233.843,511.058,234.829,511.058,236.044z"
                />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M518.059,217.259c2.022,1.28,1.508,5.232,2.837,7.029
                    c1.804,2.441,5.112-0.124,6.248,3.385c0.798,2.465,0.392,5.338,0.289,7.878c-0.121,2.999-1.979,3.935-4.721,3.794
                    c-2.885-0.147-5.751,0.195-8.609,0.017c-2.575-0.159-6.983,1.202-7.977-1.893c-0.729-2.271,0.225-4.779,0.14-7.085
                    c-0.097-2.638-0.22-5.287-0.337-7.832c-0.195-4.239,1.702-5.03,5.582-5.937C513.354,216.186,516.425,216.224,518.059,217.259z"
              />
            </g>
            <g id="Mauritius_1">
              <g
                id="Mauritius"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="579.015" cy="352.697" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M580.547,355.645c2.689-1.02,2.132-4.179,0.151-5.669
                    c-2.245-1.688-5.716,0.963-4.704,3.637C576.679,355.418,578.762,356.32,580.547,355.645z"
              />
            </g>
            <g id="Equatorial_Guinea">
              <g
                id="Equatorial_Guinea-1"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <circle fill="#aaaaaa" cx="462.092" cy="298.399" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M464.716,296.325c-1.042-0.857-2.928-1.368-3.948-1.211
                    c-1.991,0.309-2.74,3.017-2.169,4.645c0.502,1.432,3.355,2.127,4.633,1.637c1.304-0.5,2.645-2.808,2.154-4.196
                    C465.274,296.882,465.034,296.587,464.716,296.325z"
              />
            </g>
            <g id="Sao_Tome_and_Principe">
              <circle
                id="Sao_Tome_and_Principe"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
                fill="#aaaaaa"
                cx="454.298"
                cy="306.196"
                r="2.196"
              />
              <path
                fill="none"
                stroke="none"
                d="M457.266,306.219C457.266,307.023,457.266,306.621,457.266,306.219
                    C457.266,305.815,457.266,305.412,457.266,306.219c0,1.495-1.068,2.635-2.542,2.8c-0.665,0.074-1.363-0.049-1.947-0.381
                    c-0.311-0.178-0.555-0.425-0.87-0.582c-0.807-0.399-0.826-1.342-0.636-2.177c0.233-1.026,0.762-2.571,2.011-2.661
                    c0.842-0.061,1.566-0.076,2.321,0.343C456.581,304.104,457.266,305.077,457.266,306.219z"
              />
            </g>
            <g id="South_Sudan_1_">
              <g
                id="South_Sudan"
                onMouseMove={(e) => this.showTooltip(e)}
                onMouseOut={this.hideTooltip.bind(this)}
              >
                <path
                  fill="#aaaaaa"
                  d="M526.647,275.017c0,1.213-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.982-2.197-2.195
                        c0-1.215,0.986-2.196,2.197-2.196C525.663,272.82,526.647,273.802,526.647,275.017z"
                />
                <path
                  fill="#aaaaaa"
                  d="M526.647,282.812c0,1.212-0.984,2.195-2.198,2.195c-1.211,0-2.197-0.983-2.197-2.195
                        c0-1.213,0.986-2.199,2.197-2.199C525.663,280.613,526.647,281.6,526.647,282.812z"
                />
                <circle fill="#aaaaaa" cx="516.657" cy="275.017" r="2.197" />
                <circle fill="#aaaaaa" cx="516.657" cy="282.812" r="2.197" />
                <circle fill="#aaaaaa" cx="508.862" cy="275.017" r="2.196" />
              </g>
              <path
                fill="none"
                stroke="none"
                d="M521.1,271.885c1.616,0,3.826-0.516,5.255,0.484
                    c1.181,0.826,1.248,2.957,1.245,5.016c-0.004,2.089-0.089,4.108-0.167,5.333c-0.231,3.599-2.947,3.271-6,3.167
                    c-1.829-0.062-5.549,0.358-7.05-0.617c-1.801-1.171-0.55-4.727-1.909-6.429c-1.735-2.174-4.735,0.479-6.171-1.524
                    c-2.419-3.376,1.448-5.641,4.499-5.844C514.258,271.241,517.656,271.885,521.1,271.885z"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
