/* eslint-disable no-unsafe-optional-chaining */
// TODO - lint
/* eslint-disable react/no-deprecated, no-param-reassign, radix, radix, no-param-reassign, class-methods-use-this, consistent-return, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, camelcase, camelcase, import/no-default-export */
// General Imports
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addInfluencerToBoard,
  getAuctionInfluencers,
  unTrash,
} from "../../../actions/auctionsActions";
import { useShareInviteInfluencers } from "../../../api/actions/auctionActions";
import { CheckForAutoAgents } from "../../../api/actions/listActions";
import NoImage from "../../../images/svgs/placeholder.svg";
import { CopyToClipboard } from "../../../ui/CopyToClipboard";
import { FormFooterButtonGroup } from "../../../ui/FormFooterButtonGroup";
import { AddAgentDialog } from "../agency/AddAgentDialog";
import { AgentSelect } from "../agency/AgentSelect";
import { AgentSuggestionsDialog } from "../agency/AgentSuggestionsDialog";
import CampaignMonthSelector from "../campaign_components/CampaignManager/CampaignMonthSelector";
import CheckBox from "../general_components/CheckBox";
import { InfoToolTip } from "../general_components/InfoToolTip";
// Actions imports
// import { formatBigNumbers } from "../utilities/general";

class InviteToCampaignInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      saving: false,
      loading: true,
      inCampaign: [],
      opens: [],
      openInviteModalWithUrl: false,
      showAgentSuggestionsDialog: false,
      autoSelectedAgents: null,
      inviteMonth: null,
    };
  }

  allowSkipInvite =
    this.props.user?.userDetails?.sidebar_data?.allowed_skip_social_invite;

  componentWillMount() {
    if (!this.props.needToUntrash) {
      getAuctionInfluencers(this.props.campaignId, (response) => {
        if (response && response.length > 0) {
          this.setState({ inCampaign: response });
        }
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  changeAllPrices(price) {
    const { influencers } = this.props;
    influencers.forEach((i) => {
      if (
        !i?.tasks_details?.tasks?.some((a) =>
          a.bundle_id ? false : a.additonal_price > 0,
        ) &&
        !i?.tasks_details?.bundles_attributes?.some((b) => b.price > 0)
      ) {
        i.invite_price = price;
      }
    });
    this.setState({ updated: true });
  }

  onInvite = () => {
    if (this.state.saving) return;

    const { inCampaign } = this.state;
    let influencers;
    if (this.props.needToUntrash) {
      influencers = this.props.influencers.map((x) => {
        return {
          tasks_modifications: { excluded: [], new: [] },
          influencer_id: x.influencer_id,
          agent_id: x.agent_id,
          upfront_payment: x.upfront_payment ? x.upfront_payment : null,
          price: this.tasksPriceAlreadyEdited(x)
            ? this.getTotalTasksAndBundlesPrice(x)
            : x.invite_price
            ? parseInt(x.invite_price)
            : 0,
          send_social_invite: x.send_social_invite !== false,
          from_next_month:
            this.state.inviteMonth &&
            this.state.inviteMonth !== moment().format("YYYY-MM"),
        };
      });
    } else {
      influencers = this.props.influencers.map((x) => {
        return {
          tasks_modifications: { excluded: [], new: [] },
          influencer_id: x.id,
          agent_id: x.agent_id,
          upfront_payment: x.upfront_payment ? x.upfront_payment : null,
          price: this.tasksPriceAlreadyEdited(x)
            ? this.getTotalTasksAndBundlesPrice(x)
            : x.invite_price
            ? parseInt(x.invite_price)
            : null,
          send_social_invite: x.send_social_invite !== false,
          from_next_month:
            this.state.inviteMonth &&
            this.state.inviteMonth !== moment().format("YYYY-MM"),
        };
      });
    }
    if (influencers.find((x) => !x.invite_price && !x.invite_price === 0)) {
      return;
    }

    if (influencers && influencers.length > 0) {
      influencers = influencers.filter(
        (x) => !inCampaign.includes(x.influencer_id),
      );
      if (influencers && influencers.length > 0) {
        this.setState({ saving: true });
        if (this.props.needToUntrash) {
          this.props.unTrash(
            this.props.campaignId,
            influencers[0],
            this.props.board.month,
            () => {
              this.setState({ saving: false }, () => {
                if (this.props.callback) {
                  this.props.callback();
                }
              });
              this.props.closeMenu(true);
            },
          );
        } else {
          this.props.addInfluencerToBoard(
            this.props.campaignId,
            influencers,
            this.props.board.month,
            async () => {
              const res = await this.props.shareInviteInfluencers({
                auction_id: this.props.campaignId,
              });
              const url = res.data;
              this.setState(
                { saving: false, openInviteModalWithUrl: url },
                () => {
                  if (this.props.callback) {
                    this.props.callback();
                  }
                },
              );
            },
          );
        }
      } else {
        this.props.closeMenu(true);
      }
    } else {
      this.props.closeMenu(true);
    }
  };

  handleDeleteAgency = (id) => {
    this.props.influencers.forEach((x) => {
      if (x.agent_id === id) {
        x.agent_id = null;
      }
    });
    this.setState({});
  };

  closeAgentSuggestionsDialog = () => {
    this.setState({ showAgentSuggestionsDialog: false });
  };

  checkForAutoAgentsOrInvite = () => {
    if (this.props.user?.userDetails?.sidebar_data?.allowed_influencer_agent) {
      this.setState({ saving: true });
      const influencersList = this.props.influencers
        .map((inf) => {
          return inf.influencer_id || inf.id;
        })
        .filter((r) => !!r);
      if (!influencersList?.length) return this.onInvite();
      this.props.CheckForAutoAgents(influencersList, (response) => {
        const influencers_with_agents = response?.data;
        if (influencers_with_agents?.length) {
          // prepare influencers
          const suggestedInfluencers = influencers_with_agents
            .map((iwa) => {
              if (iwa.agents?.length) {
                const influ = this.props.influencers.find((i) => {
                  const inf_id = i?.id ?? i?.influencer_id;
                  if (inf_id) {
                    return (
                      Number(inf_id) === Number(iwa.influencer_id) &&
                      (!i.agent_id ||
                        Number(iwa.agents[0].agent_id) !== Number(i.agent_id))
                    );
                  }
                  return null;
                });
                if (influ) {
                  return {
                    ...iwa,
                    name: influ?.name ?? "Unknown",
                    picture_url: influ?.picture_url,
                    prev_agent_id: influ.agent_id,
                  };
                }
              }
              return null;
            })
            .filter((x) => x);
          if (suggestedInfluencers?.length) {
            this.setState({
              autoSelectedAgents: suggestedInfluencers,
              showAgentSuggestionsDialog: true,
            });
          } else {
            this.setState(
              { showAgentSuggestionsDialog: false, saving: false },
              () => {
                this.onInvite();
              },
            );
          }
        } else {
          this.setState(
            { showAgentSuggestionsDialog: false, saving: false },
            () => {
              this.onInvite();
            },
          );
        }
        this.setState({ saving: false });
      });
    } else {
      this.onInvite();
    }
  };

  updateAgentsForInfluencersAndSubmit = (selectedAgents) => {
    this.props.influencers.forEach((x) => {
      const agent = selectedAgents.find((a) => {
        const infId = x.influencer_id || x.id;
        if (infId) {
          return String(infId) === String(a.influencer);
        }
        return false;
      });
      if (agent && agent.agent) {
        x.agent_id = agent.agent;
      }
    });
    this.setState({ showAgentSuggestionsDialog: false }, this.onInvite);
  };

  render() {
    const { influencers } = this.props;
    return (
      <>
        <div
          style={{ width: 750, marginLeft: "calc(100% - 970px)" }}
          className="import-from-list-container animated fadeInRight faster"
          id="invite-to-campaign"
        >
          {this.state.loading ? (
            <div className="spinner" style={{ marginTop: 100 }}>
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          ) : (
            <div>
              <div style={{ fontSize: 22 }}>Invite influencers</div>
              <div
                className="import-list-close-button"
                onClick={() => this.props.closeMenu(false)}
              >
                <span className="moi-ximage" />
              </div>
              <div
                className="import-from-list-spacer"
                style={{ marginTop: 20 }}
              />

              {
                /* Influencers Sections */

                <div style={{ background: "white" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 14,
                      gap: 20,
                    }}
                  >
                    <span
                      style={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      Add influencers to:{" "}
                    </span>
                    {!this.props.board?.board?.one_month_campaign && (
                      <CampaignMonthSelector
                        allMonths={false}
                        onChange={(selectedOption) => {
                          this.setState({
                            inviteMonth: selectedOption,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      width: 700,
                      background: "#F6F6F6",
                      padding: 14,
                      marginLeft: 10,
                      paddingTop: 10,
                    }}
                  >
                    <div>
                      <div className="inner-toolbar-search-input">
                        <div
                          className="moi-search-bold"
                          style={{
                            marginTop: 7,
                            fontSize: "large",
                          }}
                        />
                        <input
                          onChange={(e) =>
                            this.setState({
                              search: e.target.value.toLowerCase(),
                            })
                          }
                          placeholder="Search Influencer"
                          style={{ height: 34, width: 280 }}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: "14px",
                          marginLeft: "-7px",
                          fontWeight: 600,
                        }}
                      >
                        Change all price
                      </span>
                      <Flex>
                        <div
                          style={{
                            marginLeft: 3,
                            fontSize: 15,
                            color: "var(--main-baby-blue-color)",
                            zIndex: 2,
                            paddingTop: 8,
                          }}
                        >
                          {window.translate(
                            "",
                            `money_symbol_${this.props.currency}`,
                          )}
                        </div>
                        <input
                          onChange={(e) => this.changeAllPrices(e.target.value)}
                          type="number"
                          style={{
                            width: 130,
                            color: "var(--main-baby-blue-color)",
                            border: "1px solid #EAE8E8",
                            borderRadius: 6,
                            paddingLeft: 20,
                            paddingRight: 10,
                            marginLeft: -19,
                            height: 34,
                            marginTop: 5,
                          }}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div
                    className="import-list-section no-infinite"
                    style={{ height: "calc(50vh - 120px)" }}
                  >
                    {influencers.map((x) => this.renderInfluencer(x))}
                  </div>

                  <div className="import-from-list-buttons-row">
                    <Button
                      style={{ paddingTop: 0 }}
                      isLoading={this.state.saving}
                      isDisabled={this.state.saving}
                      onClick={this.checkForAutoAgentsOrInvite}
                    >
                      {this.props.needToUntrash ? "ReInvite" : "Invite"}
                    </Button>
                    <button
                      className={`btn btn-primry ${
                        this.state.saving && "disabled"
                      }`}
                      onClick={() => this.props.closeMenu(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                /* End Influencers Sections */
              }
            </div>
          )}
        </div>
        {this.state.showAddAgentDialog && (
          <AddAgentDialog
            onDelete={this.handleDeleteAgency}
            agentDetails={this.state.showAddAgentDialog.details}
            onClose={this.closeAddAgentDialog}
          />
        )}
        {this.state.showAgentSuggestionsDialog && (
          <AgentSuggestionsDialog
            agentsData={this.state.autoSelectedAgents}
            onApprove={(selectedAgents) =>
              this.updateAgentsForInfluencersAndSubmit(selectedAgents)
            }
            onClose={this.closeAgentSuggestionsDialog}
          />
        )}
        <Modal
          size="lg"
          isOpen={this.state.openInviteModalWithUrl}
          onClose={this.handleCloseInviteInfluencerModal}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invite influencer</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Text fontSize="lg">
                  The influencers has been invited to your campaign.
                </Text>
              </Box>
              <Box py="3">
                <Text>
                  You can send them the link below to respond to your
                  invitation.
                </Text>
              </Box>
              <CopyToClipboard
                my="3"
                value={this.state.openInviteModalWithUrl}
              />
              <FormFooterButtonGroup py="3">
                <Button
                  autoFocus
                  type="button"
                  onClick={this.handleCloseInviteInfluencerModal}
                  tabIndex={1}
                >
                  Ok
                </Button>
              </FormFooterButtonGroup>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  handleCloseInviteInfluencerModal = () => {
    this.setState({ openInviteModalWithUrl: false });
    this.props.closeMenu(true);
  };

  openAdvanced = (id) => {
    this.state.opens.addOrRemove(id);
    this.forceUpdate();
  };

  CustomOption = ({ option }) => {
    return (
      <div className="custom-option" key={option.value}>
        <span style={{ flex: 1 }}>{option.label}</span>
        <i className="fal fa-trash-alt" />
      </div>
    );
  };

  openAddAgentDialog = (id, details) => {
    this.setState({ showAddAgentDialog: { list: id, details } });
  };

  closeAddAgentDialog = (agentId) => {
    if (agentId) {
      const inf = this.props.influencers.find(
        (x) => x.id === this.state.showAddAgentDialog,
      );
      if (inf) {
        inf.agent_id = agentId;
      }
    }
    this.setState({ showAddAgentDialog: false });
  };

  getTotalTasksAndBundlesPrice = (data) => {
    return (
      data?.tasks_details?.tasks?.reduce(
        (acc, curr) => acc + (curr.bundle_id ? 0 : curr.additonal_price ?? 0),
        0,
      ) +
      data?.tasks_details?.bundles_attributes?.reduce(
        (acc, curr) => acc + (curr.price ?? 0),
        0,
      )
    );
  };

  tasksPriceAlreadyEdited = (data) =>
    data?.tasks_details?.tasks?.some((a) => a?.additonal_price !== null) ||
    data?.tasks_details?.bundles_attributes?.some((b) => b?.price !== null);

  renderInfluencer(data) {
    const { search } = this.state;
    if (!data?.name) data.name = "Unknown";
    if (!data?.name?.toLowerCase().includes(search) || data.hidden) return;
    const id = data.id || data.influencer_id;
    const isOpen = this.state.opens.includes(id);
    const disabled = this.state.inCampaign.includes(id);
    // const instagramData =
    //   data.instagram_data && data.instagram_data.username
    //     ? data.instagram_data
    //     : null;
    // const twitterData =
    //   data.twitter_data && data.twitter_data.username
    //     ? data.twitter_data
    //     : null;
    // const youtubeData =
    //   data.youtube_data && data.youtube_data.username
    //     ? data.youtube_data
    //     : null;
    // const tiktokData =
    //   data.tiktok_data && data.tiktok_data.username ? data.tiktok_data : null;
    if (!data?.tasks_details?.tasks) {
      return;
    }

    return (
      <div title={disabled ? "Already invited" : ""} key={id}>
        <div
          className={`import-list-row ${disabled && "disabled"}`}
          key={`import-list-influencer${data.id}`}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <div
            className="import-list-row-details"
            style={{ display: "block", padding: 0, width: 700 }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 10,
                borderRadius: 10,
                border: "1px solid  #EAE8E8",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 40, height: 40, marginTop: "-10px" }}>
                  <img
                    className="avatar"
                    src={
                      data.picture_url
                        ? data.picture_url.replace(".jpg", "_small.jpg")
                        : NoImage
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{ marginLeft: "9px" }}
                      className={`humanz-indicator influencer-app-indicator ${
                        data.user_registered ? "active" : ""
                      }`}
                    >
                      <Tooltip
                        placement={"top"}
                        overlay={`Humanz App ${
                          data.user_registered ? "" : "not"
                        } installed`}
                      >
                        <div className="fas fa-mobile-alt" />
                      </Tooltip>
                    </div>
                    <strong
                      className="import-list-influencer-name"
                      style={{
                        width: "120px",
                        padding: 2,
                        alignItems: "center",
                      }}
                      title={data.name}
                    >
                      {data.name}
                    </strong>
                  </div>

                  <div>
                    {!data.user_registered && this.allowSkipInvite ? (
                      <div
                        style={{
                          display: "flex",
                          padding: "4px 5px",
                          background: "#FAFAFA",
                          alignItems: "center",
                          borderRadius: "6px",
                          justifyContent: "center",
                          alignContent: "center",
                          width: 145,
                        }}
                      >
                        <CheckBox
                          checked={data.send_social_invite !== false}
                          onChange={() => {
                            data.send_social_invite =
                              data.send_social_invite === false;
                            this.forceUpdate();
                          }}
                          label={""}
                        />
                        <span style={{ marginTop: 2, fontSize: 13 }}>
                          Social Invite{" "}
                          <InfoToolTip
                            placement="bottom"
                            trigger={["hover"]}
                            overlay={
                              "Send Campaign Invite Message on Social Networks"
                            }
                          />
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* <div
                className="list-socials"
                style={{
                  height: "auto",
                  width: 130,
                  marginLeft: 5,
                  textAlign: "center",
                }}
              >
                {instagramData && (
                  <div
                    className="list-social-followers"
                    style={{ fontSize: 11, height: 15, width: 55 }}
                  >
                    <li className="fa fa-instagram" />
                    {formatBigNumbers(instagramData.followers)}
                  </div>
                )}
                {twitterData && (
                  <div
                    className="list-social-followers"
                    style={{ fontSize: 11, height: 15, width: 55 }}
                  >
                    <li className="fa fa-twitter" />
                    {formatBigNumbers(twitterData.followers)}
                  </div>
                )}
                {youtubeData && (
                  <div
                    className="list-social-followers"
                    style={{ fontSize: 11, height: 15, width: 55 }}
                  >
                    <li className="fa fa-youtube-play" />
                    {formatBigNumbers(youtubeData.followers)}
                  </div>
                )}
                {tiktokData && (
                  <div
                    className="list-social-followers"
                    style={{ fontSize: 11, height: 15, width: 55 }}
                  >
                    <li className="fa-brands fa-tiktok" />
                    {formatBigNumbers(tiktokData.followers)}
                  </div>
                )}
              </div> */}
              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  marginLeft: 15,
                }}
              >
                <div style={{ height: "auto", marginBottom: -5 }}>
                  {/* <div
                    className="moi-pencil-edit-button"
                    onClick={() => this.props.openEditTasksDialog(data)}
                  /> */}
                  <Button
                    variant={"small-action"}
                    onClick={() => this.props.openEditTasksDialog(data)}
                    style={{
                      height: "20px",
                      padding: "4px 2px",
                      marginBottom: "5px",
                    }}
                  >
                    <strong>Edit tasks</strong>
                    <i
                      className="fa-solid fa-pen-to-square"
                      style={{ marginLeft: 2 }}
                    />
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: 140,
                    }}
                  >
                    <div
                      className="list-social-followers"
                      style={{
                        fontSize: 11,
                        height: 15,
                        width: "fit-content",
                        padding: "0px 5px",
                        background: "#EBEBEB",
                        marginTop: 2,
                        marginBottom: 2,
                        color: "#555",
                      }}
                    >
                      <li className="fa fa-instagram" />
                      {
                        data?.tasks_details?.tasks.filter(
                          (x) => x.post_type === "ig_post",
                        ).length
                      }
                    </div>
                    <div
                      className="list-social-followers"
                      style={{
                        fontSize: 11,
                        height: 15,
                        width: "fit-content",
                        padding: "0px 5px",
                        background: "#EBEBEB",
                        marginTop: 2,
                        marginBottom: 2,
                        textTransform: "none",
                        color: "#555",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <li className="fa moi-stories" />
                        {
                          data?.tasks_details?.tasks.filter(
                            (x) => x.post_type === "ig_story" && !x.subtask_of,
                          ).length
                        }
                      </div>
                    </div>
                    <div
                      className="list-social-followers"
                      style={{
                        fontSize: 11,
                        height: 15,
                        width: "fit-content",
                        padding: "0px 5px",
                        background: "#EBEBEB",
                        marginTop: 2,
                        marginBottom: 2,
                        color: "#555",
                      }}
                    >
                      <li className="fa fa-twitter" />
                      {
                        data?.tasks_details?.tasks.filter(
                          (x) => x.post_type === "tw_tweet",
                        ).length
                      }
                    </div>
                    <div
                      className="list-social-followers"
                      style={{
                        fontSize: 11,
                        height: 15,
                        width: "fit-content",
                        padding: "0px 5px",
                        background: "#EBEBEB",
                        marginTop: 2,
                        marginBottom: 2,
                        color: "#555",
                      }}
                    >
                      <li className="fa fa-youtube-play" />
                      {
                        data?.tasks_details?.tasks.filter(
                          (x) => x.post_type === "yt_video",
                        ).length
                      }
                    </div>
                    {this.props.user.userDetails.sidebar_data
                      .can_view_tiktok && (
                      <div
                        className="list-social-followers"
                        style={{
                          fontSize: 11,
                          height: 15,
                          width: "fit-content",
                          padding: "0px 5px",
                          background: "#EBEBEB",
                          marginTop: 2,
                          marginBottom: 2,
                          color: "#555",
                        }}
                      >
                        <li className="fa-brands fa-tiktok" />
                        {
                          data?.tasks_details?.tasks.filter(
                            (x) => x.post_type === "tk_video",
                          ).length
                        }
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  height: 52,
                  width: 110,
                  marginLeft: 15,
                }}
              >
                {disabled ? (
                  <div />
                ) : (
                  <>
                    {/* {!this.props.user.userDetails.sidebar_data
                      .allowed_upfront_payment_invites || isOpen ? null : (
                      <div style={{ fontSize: 13, marginTop: 5 }}>
                        <a
                          onClick={() => {
                            if (!disabled) this.openAdvanced(id);
                          }}
                        >
                          Price
                        </a>
                      </div>
                    )} */}
                    <span
                      style={{
                        fontSize: "13px",
                        marginLeft: "-7px",
                        fontWeight: 600,
                      }}
                    >
                      Price
                    </span>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginLeft: 3,
                          fontSize: 15,
                          color: "var(--main-baby-blue-color)",
                          zIndex: 2,
                          paddingTop: 10,
                        }}
                      >
                        {window.translate(
                          "",
                          `money_symbol_${this.props.currency}`,
                        )}
                      </div>
                      <Tooltip
                        overlay={
                          "Some tasks already have a price. If you want to edit the price, edit the tasks."
                        }
                        trigger={
                          this.tasksPriceAlreadyEdited(data) ? ["hover"] : []
                        }
                      >
                        <div>
                          <input
                            value={data.invite_price ? data.invite_price : ""}
                            placeholder={
                              this.tasksPriceAlreadyEdited(data)
                                ? this.getTotalTasksAndBundlesPrice(data) ?? ""
                                : ""
                            }
                            disabled={this.tasksPriceAlreadyEdited(data)}
                            onChange={(e) => {
                              data.invite_price = e.target.value;
                              this.setState({ updated: true });
                            }}
                            type="number"
                            style={{
                              width: 110,
                              color: "var(--main-baby-blue-color)",
                              border: "1px solid #EAE8E8",
                              borderRadius: 6,
                              paddingLeft: 20,
                              paddingRight: 10,
                              marginLeft: -19,
                              height: 34,
                              marginTop: 5,
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>

              {this.props.user?.userDetails?.sidebar_data
                ?.allowed_influencer_agent ? (
                <div
                  style={{
                    display: "flex",
                    padding: "7px 10px 7px 4px",
                    background: "#efefef",
                    marginBottom: "-32px",
                    borderRadius: "6px",
                  }}
                >
                  <Tooltip overlay={"Has agent"}>
                    <Flex justifyContent={"center"} alignItems={"center"}>
                      <CheckBox
                        checked={isOpen}
                        onChange={() => {
                          if (!disabled) this.openAdvanced(id);
                        }}
                        label={""}
                      />
                      <i
                        className="fa-solid fa-user-tie"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </Flex>
                  </Tooltip>
                </div>
              ) : null}
            </div>
            {isOpen && (
              <div
                style={{
                  borderTop: "1px solid #EAE8E8",
                  padding: 14,
                  display: "flex",
                  background: "#FAFAFA",
                }}
              >
                {this.props.user.userDetails.sidebar_data
                  .allowed_influencer_agent ? (
                  <div>
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: 600,
                      }}
                    >
                      Agency
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <div style={{ width: 200, marginRight: 10 }}>
                        <AgentSelect
                          editAgency={(details) =>
                            this.openAddAgentDialog(id, details)
                          }
                          value={data.agent_id}
                          onChange={(value) => {
                            data.agent_id = value;
                            this.setState({});
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: 9,
                          border: "1px solid #EAE8E8",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "20px",
                          borderRadius: "4px",
                        }}
                      >
                        <i
                          className="fas fa-plus"
                          onClick={() => this.openAddAgentDialog(id)}
                          style={{
                            cursor: "pointer",
                            color: "grey",
                            fontWeight: "bold",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* {this.props.user.userDetails.sidebar_data
                  .allowed_upfront_payment_invites && (
                  <div>
                    <strong className="label-title">Upfront payment</strong>
                    <div style={{ display: "flex", marginLeft: 3 }}>
                      <div
                        style={{
                          marginLeft: 3,
                          fontSize: 15,
                          color: "var(--main-baby-blue-color)",
                          zIndex: 2,
                          paddingTop: 8,
                        }}
                      >
                        {window.translate(
                          "",
                          `money_symbol_${this.props.currency}`,
                        )}
                      </div>
                      <input
                        value={data.upfront_payment ? data.upfront_payment : ""}
                        onChange={(e) => {
                          data.upfront_payment = e.target.value;
                          this.setState({});
                        }}
                        type="number"
                        style={{
                          width: 110,
                          color: "var(--main-baby-blue-color)",
                          border: "1px solid #aaaaaa",
                          borderRadius: 30,
                          paddingLeft: 20,
                          paddingRight: 10,
                          marginLeft: -19,
                          height: 28,
                          marginTop: 4,
                        }}
                      />
                    </div>
                  </div>
                )} */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default (props) => {
  const lists = useSelector((state) => state.listsReducer);
  const user = useSelector((state) => state.userReducer);
  const board = useSelector((state) => state.boardReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_BOARD_MONTH", payload: true });
    };
  }, []);
  const addInfluencerToBoardCb = useCallback(
    (board_id, influencers, month, callback) => {
      dispatch(addInfluencerToBoard(board_id, influencers, month, callback));
    },
    [dispatch],
  );
  const checkForAutoAgents = useCallback(
    (influencers, callback) => {
      dispatch(CheckForAutoAgents(influencers, callback));
    },
    [dispatch],
  );

  const unTrashCb = useCallback(
    (board_id, influencers, month, callback) => {
      dispatch(unTrash(board_id, influencers, month, callback));
    },
    [dispatch],
  );
  const shareInviteInfluencers = useShareInviteInfluencers();

  return (
    <InviteToCampaignInner
      lists={lists}
      user={user}
      addInfluencerToBoard={addInfluencerToBoardCb}
      board={board}
      CheckForAutoAgents={checkForAutoAgents}
      unTrash={unTrashCb}
      shareInviteInfluencers={shareInviteInfluencers.mutateAsync}
      {...props}
    />
  );
};
