import { Box, Flex, Text } from "@chakra-ui/react";
import Tooltip from "rc-tooltip";
import { useSelector } from "react-redux";

const AddTaskPanel = ({
  onToggleTask,
  bundleId,
}: {
  onToggleTask: any;
  bundleId?: string;
}) => {
  const user = useSelector((state) => state.userReducer);
  const showTikTok = user.userDetails.sidebar_data.can_view_tiktok;
  return (
    <Box
      className={`add_task_row ${bundleId ? "add_task_row_bundle" : ""}`}
      style={{
        borderRadius: "12px",
        minHeight: bundleId ? 80 : 140,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box className="add-task-container">
        <Flex
          style={{
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Flex mb={2} justifyContent="center" alignItems={"center"}>
            <Text color="brand.500" className="fa fa-plus" mr={1} />
            <Text fontWeight={"500"} color={"black"}>
              Add a new task
            </Text>
          </Flex>
          <Box mb={3}>
            <p className="grey-subtitle">
              Click on the relevant icon for adding a new task
            </p>
          </Box>

          <Flex>
            <Tooltip placement={"top"} overlay={"Instagram post"}>
              <div
                style={{
                  fontSize: "18px",
                  width: 40,
                  height: 40,
                }}
                className="fa fa-instagram colored circle-icon clickable"
                title={"Instagram Post"}
                onClick={() => onToggleTask(null, "ig_post", bundleId)}
              />
            </Tooltip>
            <Tooltip placement={"top"} overlay={"Instagram Story"}>
              <div
                className="fa moi-stories colored circle-icon clickable"
                style={{
                  fontSize: "18px",
                }}
                title={"Instagram Story"}
                onClick={() => onToggleTask(null, "ig_story", bundleId)}
              />
            </Tooltip>
            <Tooltip placement={"top"} overlay={"YouTube Video"}>
              <div
                className="fa fa-youtube-play colored circle-icon clickable"
                style={{
                  fontSize: "18px",
                }}
                title={"YouTube Video"}
                onClick={() => onToggleTask(null, "yt_video", bundleId)}
              />
            </Tooltip>
            <Tooltip placement={"top"} overlay={"Twitter Post"}>
              <div
                className="fa fa-twitter colored circle-icon clickable"
                style={{
                  fontSize: "18px",
                }}
                title={"Twitter Post"}
                onClick={() => onToggleTask(null, "tw_tweet", bundleId)}
              />
            </Tooltip>
            {showTikTok && (
              <Tooltip placement={"top"} overlay={"Tiktok video"}>
                <div
                  className="fa moi-tik-tok circle-icon  clickable"
                  style={{
                    fontSize: "18px",
                  }}
                  title={"Tiktok video"}
                  onClick={() => onToggleTask(null, "tk_video", bundleId)}
                />
              </Tooltip>
            )}
            <Tooltip placement={"top"} overlay={"Content only"}>
              <div
                className="far fa-images circle-icon clickable"
                title={"Content only"}
                style={{
                  fontSize: "18px",
                }}
                onClick={() =>
                  onToggleTask(null, "influencer_content", bundleId)
                }
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default AddTaskPanel;
