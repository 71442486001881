/* eslint-disable no-nested-ternary */
import { Box, Button, Text } from "@chakra-ui/react";
import Dialog from "rc-dialog";
import Tooltip from "rc-tooltip";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteAuctionsRoiSlides,
  getAuctionsRoiSlides,
  postAuctionsRoiSlides,
} from "src/api/actions/auctionActions";
import { isValidEmail } from "src/components/feed_components/utilities/general";
import presentationBtn from "src/images/presentation.png";
import { InfiniteLoader } from "../../../general_components/InfiniteLoader";

type ReportDetails = {
  generation_status: string;
  id: number;
  title: string;
  email: string;
  created_at: string;
  url: {
    google_slide_url: string;
    pdf_url: string;
    pptx_url: string;
  };
};
interface PresentationPopupProps {
  onClose: () => void;
  auctionId: number;
  generateSlidesData: () => Promise<string>;
}

const PresentationPopup: React.FC<PresentationPopupProps> = ({
  onClose,
  auctionId,
  generateSlidesData,
}) => {
  const [presentations, setPresentations] = useState<ReportDetails[]>([]);
  const [presentationTitle, setPresentationTitle] = useState("");
  const [presentationEmail, setPresentationEmail] = useState("");
  const [error, setError] = useState(undefined as string | undefined);
  const user = useSelector((store: any) => store.userReducer);
  const [loading, setLoading] = useState(false);
  const [loadingPresentation, setLoadingPresentation] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(null);

  const formatCreatedAt = (created_at: string) => {
    return new Date(created_at).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const getPresentations = (callback: any) => {
    getAuctionsRoiSlides({ auction_id: auctionId })
      .then((res) => {
        const presentationsData = res
          ? (res?.data?.reports?.map((report: any) => ({
              generation_status: report.generation_status,
              id: report.id,
              title: report.title,
              email: report.email,
              created_at: formatCreatedAt(report.created_at),
              url: {
                google_slide_url: report.urls.google_slides_url,
                pdf_url: report.urls.pdf_url,
                pptx_url: report.urls.pptx_url,
              },
            })) as ReportDetails[])
          : [];
        setPresentations(presentationsData);
      })
      .catch(() => {
        setPresentations([]);
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  };

  const deleteButton = (isLoading: any, onClick: any) => {
    return (
      <Button
        className="button-container"
        background="transparent"
        isLoading={isLoading}
        onClick={onClick}
        style={{
          minWidth: "0px",
        }}
      >
        <i
          className="fa-regular fa-trash"
          style={{
            color: "#808080",
            backgroundColor: "white",
          }}
        ></i>
      </Button>
    );
  };

  useEffect(() => {
    setPresentationEmail(user?.userDetails?.email || "");
  }, [user]);

  useEffect(() => {
    setLoadingPresentation(true);
    getPresentations(() => {
      setLoadingPresentation(false);
    });
  }, [auctionId]);

  const handleDownloadButtonClick = (url: string) => {
    window.open(url, "_blank");
  };

  const handleDeleteReport = (reportId: any) => {
    setLoadingDelete(reportId);
    deleteAuctionsRoiSlides({ report_id: reportId })
      .then(() => {
        getPresentations(() => {
          setLoadingDelete(null);
        });
      })
      .finally(() => {
        setLoadingDelete(null);
      });
  };

  const handleCreateButtonClick = async () => {
    if (!presentationTitle) {
      setError("Whoops! Title can not be empty");
    } else if (presentationEmail && !isValidEmail(presentationEmail)) {
      setError("Whoops! Email field is not valid");
    } else {
      setLoading(true);
      const payload = await generateSlidesData();
      if (payload === "null") {
        toast.error("No data found to create presentation");
        setLoading(false);
      } else {
        postAuctionsRoiSlides({
          auction_id: auctionId,
          payload,
          title: presentationTitle,
          email: presentationEmail || user.userDetails.email,
        })
          .then(() => {
            setError(undefined);
            getPresentations(() => {
              toast.success(
                "The presentation is in preparation. Please wait a few minutes",
              );
            });
          })
          .catch((err) => {
            toast.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPresentationTitle(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPresentationEmail(e.target.value);
  };
  return (
    <Dialog className="popup-container" onClose={onClose} visible={true}>
      <div style={{ height: "678px" }}>
        <div className="popup-title">Create slides presentation</div>
        <button
          className="close-button"
          style={{ border: "none" }}
          onClick={onClose}
        >
          <i
            className="fa-duotone fa-xmark-large"
            color="#00000080"
            style={{ width: "16px", height: "16px" }}
          ></i>
        </button>
        <div className="popup-content">
          <div className="popup-label">Create new presentation</div>
          <div className="popup-box">
            <div className="popup-left" style={{ paddingTop: "5px" }}>
              <div className="popup-sublabel">Title</div>
              <div className="popup-input">
                <input
                  type="text"
                  placeholder="Presentation title"
                  value={presentationTitle}
                  onChange={handleTitleChange}
                />
              </div>
            </div>
            <div className="popup-right">
              <div className="popup-sublabel">
                Email
                <Tooltip
                  overlay={
                    <>
                      <div>
                        {
                          "The presentation will be sent to your email when ready."
                        }
                      </div>
                      <div>
                        {
                          "if the Email field is empty, the presentation will be sent to the email you are currently logged in."
                        }
                      </div>
                    </>
                  }
                  trigger={["hover"]}
                  placement={"top"}
                >
                  <i
                    style={{ marginLeft: 4, fontSize: 16, color: "#BFBFBF" }}
                    className="fa-solid fa-info-circle"
                  />
                </Tooltip>
              </div>
              <div className="popup-input">
                <input
                  type="email"
                  placeholder="Type email"
                  defaultValue={user.userDetails.email}
                  value={presentationEmail}
                  onChange={handleEmailChange}
                />
              </div>
            </div>
          </div>
          {error && (
            <div className="popup-frame">
              <i className="error-mark" />
              <span className="error-text" style={{ marginRight: "27px" }}>
                {error}
              </span>
            </div>
          )}

          <Button
            className="create-button"
            isLoading={loading}
            onClick={handleCreateButtonClick}
          >
            <div className="create-button-text">Create</div>
          </Button>
        </div>
        <div className="preview-frame">
          <div className="preview-border">
            <div className="my-presentations">My presentations</div>
            {loadingPresentation ? (
              <Box style={{ marginTop: "100px" }}>
                <InfiniteLoader />
              </Box>
            ) : (
              <>
                {presentations?.length ? (
                  <>
                    <div className="presentation-frame">
                      {presentations.map((presentation) => (
                        <div
                          key={presentation.id}
                          className="frame-each-presentation"
                          style={{
                            backgroundColor:
                              presentation.generation_status === "completed"
                                ? "white"
                                : null,
                            gap:
                              presentation.generation_status === "completed"
                                ? "7px"
                                : "71px",
                          }}
                        >
                          <div className="presentation-left">
                            <div className="presentation-title">
                              {/* eslint-disable-next-line no-nested-ternary */}
                              <div className="image-container">
                                <img
                                  src={presentationBtn}
                                  style={{
                                    display: "flex",
                                    opacity:
                                      presentation.generation_status ===
                                      "completed"
                                        ? null
                                        : "0.5",
                                  }}
                                />
                              </div>

                              <Tooltip
                                overlay={presentation.title}
                                trigger={["hover"]}
                                placement={"top"}
                              >
                                <div
                                  className="title"
                                  style={{
                                    opacity:
                                      presentation.generation_status ===
                                      "completed"
                                        ? null
                                        : "0.5",
                                  }}
                                >
                                  {presentation.title}
                                </div>
                              </Tooltip>
                            </div>
                            <div
                              className="date"
                              style={{
                                opacity:
                                  presentation.generation_status === "completed"
                                    ? null
                                    : "0.5",
                              }}
                            >
                              {" "}
                              {presentation.created_at}
                            </div>
                          </div>
                          {presentation.generation_status === "completed" ? (
                            <div className="download-trash-buttons-frame">
                              <div className="download-buttons-frame">
                                {presentation?.url?.google_slide_url && (
                                  <Button
                                    className="each-download-buttons"
                                    onClick={() =>
                                      handleDownloadButtonClick(
                                        presentation.url.google_slide_url,
                                      )
                                    }
                                  >
                                    <div
                                      className="download-type"
                                      style={{ paddingRight: "3px" }}
                                    >
                                      <i
                                        className="fa-regular fa-arrow-up-right-from-square"
                                        style={{
                                          color: "#249FF0",
                                          fontSize: "11px",
                                        }}
                                      ></i>
                                      <div className="image-container">
                                        <img
                                          src={presentationBtn}
                                          style={{
                                            display: "flex",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Button>
                                )}
                                {presentation?.url?.pdf_url && (
                                  <Button
                                    style={{ paddingLeft: "8px" }}
                                    className="each-download-buttons"
                                    onClick={() =>
                                      handleDownloadButtonClick(
                                        presentation.url.pdf_url,
                                      )
                                    }
                                  >
                                    <div className="download-type">
                                      <i
                                        className="fa-regular fa-arrow-up-right-from-square"
                                        style={{
                                          color: "#249FF0",
                                          fontSize: "11px",
                                        }}
                                      ></i>
                                      <i
                                        className="fa-duotone fa-file-pdf"
                                        style={{
                                          color: "#808080",
                                        }}
                                      ></i>
                                    </div>
                                  </Button>
                                )}
                                {presentation?.url?.pptx_url && (
                                  <Button
                                    className="each-download-buttons"
                                    onClick={() =>
                                      handleDownloadButtonClick(
                                        presentation.url.pptx_url,
                                      )
                                    }
                                  >
                                    <div
                                      className="download-type"
                                      style={{ paddingRight: "3px" }}
                                    >
                                      <i
                                        className="fa-regular fa-arrow-up-right-from-square"
                                        style={{
                                          color: "#249FF0",
                                          fontSize: "11px",
                                        }}
                                      ></i>
                                      <i
                                        className="fa-duotone fa-file-powerpoint"
                                        style={{ color: "#FF5722" }}
                                      ></i>
                                    </div>
                                  </Button>
                                )}
                              </div>
                              <div>
                                {deleteButton(
                                  loadingDelete === presentation?.id,
                                  () => handleDeleteReport(presentation?.id),
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="presentation-right">
                              {presentation.generation_status === "enqueued" ? (
                                <div className="preparing-text">Preparing</div>
                              ) : presentation.generation_status ===
                                "started" ? (
                                <div
                                  className="preparing-text"
                                  style={{ paddingLeft: "6px" }}
                                >
                                  Pending
                                </div>
                              ) : presentation.generation_status === "error" ? (
                                <Text
                                  className="preparing-text"
                                  sx={{
                                    color: "red.500",
                                    paddingLeft: "25px",
                                  }}
                                >
                                  Error
                                </Text>
                              ) : (
                                <></>
                              )}
                              <div>
                                {deleteButton(
                                  loadingDelete === presentation?.id,
                                  () => handleDeleteReport(presentation?.id),
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="presentations-preview-content">
                    <i className="fa-solid fa-up-long"></i>
                    <div className="text-frame">
                      <div className="text">Create presentation</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PresentationPopup;
