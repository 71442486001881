// TODO - lint
/* eslint-disable import/no-default-export, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, eqeqeq */
import React from "react";
import CheckBox from "./CheckBox";

export default class SortBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updated: true,
    };
  }

  onSort(row, social) {
    if (row.selected && !social) {
      row.selected = false;
    } else {
      this.props.options.data.forEach((x) => {
        x.selected = false;
      });
      row.selected = true;
    }
    if (social) {
      row.social = social;
    }
    this.setState({ open: false });
    this.props.onSort(row, "option");
  }

  onChangeOrder(order) {
    const selected = this.props.options.data.find((x) => x.selected);
    if (selected) {
      this.props.options.order = order;
      this.props.onSort(selected, "order");
    }
  }

  renderSortOption = (row, index) => {
    const { socials } = this.props.options;
    const audience = row.type == "audience_category";
    return (
      <div className="sort-row" key={`sort${index}`}>
        <CheckBox
          checked={row.selected}
          onChange={this.onSort.bind(this, row)}
          label={row.label}
        />
        {socials.length > 1 && (
          <>
            <div className="search-input-spacer" style={{ minHeight: 22 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontSize: 15,
                marginTop: 4,
                width: 85,
                marginLeft: 2,
                marginRight: 10,
                cursor: "pointer",
              }}
            >
              {socials.includes("instagram") && (
                <div
                  className="fa fa-instagram"
                  onClick={() => this.onSort(row, "instagram")}
                  style={{
                    color:
                      row.social === "instagram"
                        ? "var(--main-baby-blue-color)"
                        : "#bbbbbb",
                  }}
                />
              )}
              {socials.includes("twitter") && (
                <div
                  className="fa fa-twitter"
                  onClick={() => this.onSort(row, "twitter")}
                  style={{
                    color:
                      row.social === "twitter"
                        ? "var(--main-baby-blue-color)"
                        : "#bbbbbb",
                  }}
                />
              )}
              {!audience && socials.includes("tiktok") ? (
                <div onClick={() => this.onSort(row, "tiktok")}>
                  <div
                    className={"fa-brands fa-tiktok"}
                    style={{
                      color:
                        row.social === "tiktok"
                          ? "var(--main-baby-blue-color)"
                          : "#bbbbbb",
                    }}
                  />
                </div>
              ) : (
                <div style={{ width: 15 }} />
              )}
              {!audience && socials.includes("youtube") ? (
                <div
                  className="fa fa-youtube-play"
                  onClick={() => this.onSort(row, "youtube")}
                  style={{
                    color:
                      row.social === "youtube"
                        ? "var(--main-baby-blue-color)"
                        : "#bbbbbb",
                  }}
                />
              ) : (
                <div style={{ width: 15 }} />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  render() {
    const options = this.props.options.data;
    if (!options || options.length === 0) return null;
    const selected = options.find((x) => x.selected);
    const { socials } = this.props.options;
    const audience = selected && selected.type === "audience_category";
    return (
      <div className="sort-warpper">
        <div
          className={selected ? "true" : "false"}
          style={{ display: "flex", cursor: "pointer" }}
        >
          <label>sort: </label>
          <div
            onClick={() => this.setState({ open: !this.state.open })}
            style={
              socials.length > 0 ? {} : { paddingRight: 10, marginRight: -10 }
            }
            className="sort_selected"
          >
            {selected ? selected.label : "Sort By..."}
          </div>
          {selected && socials.length > 1 && (
            <>
              <div className="search-input-spacer" style={{ minHeight: 22 }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontSize: 15,
                  marginTop: 4,
                  width: 85,
                  marginLeft: 2,
                  marginRight: 10,
                }}
              >
                {socials.includes("instagram") && (
                  <div
                    className="fa fa-instagram"
                    onClick={() => this.onSort(selected, "instagram")}
                    style={{
                      color:
                        selected.social === "instagram"
                          ? "var(--main-baby-blue-color)"
                          : "#bbbbbb",
                    }}
                  />
                )}
                {socials.includes("twitter") && (
                  <div
                    className="fa fa-twitter"
                    onClick={() => this.onSort(selected, "twitter")}
                    style={{
                      color:
                        selected.social === "twitter"
                          ? "var(--main-baby-blue-color)"
                          : "#bbbbbb",
                    }}
                  />
                )}
                {!audience && socials.includes("tiktok") ? (
                  <div onClick={() => this.onSort(selected, "tiktok")}>
                    <div
                      className={"fa-brands fa-tiktok"}
                      style={{
                        color:
                          selected.social === "tiktok"
                            ? "var(--main-baby-blue-color)"
                            : "#bbbbbb",
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ width: 15 }} />
                )}
                {!audience && socials.includes("youtube") ? (
                  <div
                    className="fa fa-youtube-play"
                    onClick={() => this.onSort(selected, "youtube")}
                    style={{
                      color:
                        selected.social === "youtube"
                          ? "var(--main-baby-blue-color)"
                          : "#bbbbbb",
                    }}
                  />
                ) : (
                  <div style={{ width: 15 }} />
                )}
              </div>
            </>
          )}
          <i
            onClick={() => this.setState({ open: !this.state.open })}
            className={
              this.state.open ? "fas fa-sort-up true " : "fas fa-sort-down"
            }
          />
        </div>
        {this.state.open && (
          <div className="sorts-container">
            {/* <div className="sort-row"><div style={{ color: this.props.options.order === "asc" ? "var(--main-baby-blue-color)" : "#aaaaaa", display: 'flex', cursor: 'pointer' }} onClick={() => this.onChangeOrder("asc")} ><div style={{ marginTop: 3, transform: 'rotate(180deg)' }} className="moi-sort-arrow" />A-Z</div>
                        <div style={{ color: this.props.options.order === "desc" ? "var(--main-baby-blue-color)" : "#aaaaaa", display: 'flex', marginLeft: 25, cursor: 'pointer' }} onClick={() => this.onChangeOrder("desc")} ><div className="moi-sort-arrow" />A-Z</div></div> */}
            {options.map(this.renderSortOption)}
          </div>
        )}
      </div>
    );
  }
}

SortBox.defaultProps = {
  options: { data: [], order: null },
  onSort: () => {},
  active: false,
};
