// TODO - lint
/* eslint-disable import/no-default-export, class-methods-use-this, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, react/no-direct-mutation-state, react/no-direct-mutation-state, camelcase, camelcase, camelcase, camelcase, react/no-string-refs, consistent-return, no-nested-ternary */
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  deleteReminder,
  saveReminder,
} from "../../../../actions/auctionsActions";
import { TaskTypes } from "../../utilities/providers";

export default class CampaignReminderMenu extends React.PureComponent {
  MIN_DATE = moment();

  state = {
    editReminder: { id: -1 },
    saving: [],
    taskFilter: null,
  };

  getDefaultReminder = (id, data) => {
    let time;
    let extra_reminder_text = "";
    let is_reminded = false;
    if (data) {
      time = data.reminde_at;
      extra_reminder_text = data.extra_reminder_text
        ? data.extra_reminder_text
        : "";
      is_reminded = data.is_reminded;
    } else {
      time = new Date();
      time.setDate(time.getDate() + 1);
    }
    time = moment(time);
    return {
      id,
      reminde_at: time,
      extra_reminder_text,
      is_reminded,
      hour: time.format("HH"),
      minutes: time.format("mm"),
    };
  };

  show = (influencer) => {
    this.setState({ influencer });
  };

  close = () => {
    this.setState({
      taskFilter: null,
      influencer: null,
      editReminder: { id: -1 },
    });
  };

  delete = (id) => {
    if (this.state.saving.includes(id)) return;
    this.state.saving.push(id);
    this.forceUpdate();

    deleteReminder(
      this.props.boardId,
      this.state.influencer.influencer_id,
      id,
      (success) => this.callback(success, id, {}),
    );
  };

  callback = (success, id, data) => {
    this.state.saving.splice(
      this.state.saving.findIndex((x) => x === id),
      1,
    );
    if (success) {
      const taskIndx = this.state.influencer.tasks_details.tasks.findIndex(
        (x) => x.id === id,
      );
      if (taskIndx > -1) {
        this.state.influencer.tasks[taskIndx].reminder_data = data;
      }
    }
    this.state.editReminder.id = {};
    this.forceUpdate();
  };

  save = () => {
    const reminder = { ...this.state.editReminder };
    const { id } = reminder;
    if (this.state.saving.includes(id)) return;
    if (!reminder.reminde_at || !reminder.hour || !reminder.minutes) return;

    const remind_at = reminder.reminde_at.toDate();
    remind_at.setHours(reminder.hour);
    remind_at.setMinutes(reminder.minutes);
    reminder.reminde_at = remind_at.toISOString();
    this.state.saving.push(id);
    this.forceUpdate();

    saveReminder(
      this.props.boardId,
      this.state.influencer.influencer_id,
      reminder.id,
      reminder,
      (success) => this.callback(success, id, reminder),
    );
  };

  render() {
    if (!this.state.influencer) return null;
    const { influencer } = this.state;
    const tasks = influencer.tasks_details.tasks.filter((x) => !x.subtask_of);
    return (
      <div className="black-background">
        <div
          className="import-from-list-container animated fadeInRight faster"
          id="add_reminder_menu"
        >
          <div
            style={{
              marginBottom: 20,
              borderBottom: "1px solid #ddd",
              padding: 10,
            }}
          >
            <div style={{ fontSize: 22 }}>Add Task Reminder</div>
            <div className="import-list-close-button" onClick={this.close}>
              <span className="moi-ximage" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
                alignItems: "flex-end",
              }}
            >
              <div>
                <img
                  src={influencer.picture_url}
                  style={{
                    height: 35,
                    width: 35,
                    borderRadius: "50%",
                    marginRight: 10,
                  }}
                />
                <strong>{influencer.name}</strong>
              </div>
              <div>
                <strong className="label-title">Task</strong>
                <div
                  style={{ width: 200, marginLeft: 5 }}
                  className="searchable-input"
                >
                  <Select
                    ref="stateSelect"
                    placeholder={"All"}
                    onChange={(value) => this.setState({ taskFilter: value })}
                    value={this.state.taskFilter}
                    options={TaskTypes}
                    simpleValue
                    clearable={true}
                    name="selected-state"
                    searchable={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {tasks.map(this.renderTask)}
        </div>
      </div>
    );
  }

  renderTask = (task, idx) => {
    if (this.state.taskFilter && this.state.taskFilter !== task.post_type) {
      return;
    }
    const taskType = TaskTypes.find((x) => x.value === task.post_type);
    const reminderData = task.reminder_data;
    const saving = this.state.saving.includes(task.id);
    return (
      <div
        style={{ padding: 10, borderBottom: "1px solid #ddd" }}
        key={task.id}
      >
        <div style={{ display: "flex", color: "black", alignItems: "center" }}>
          <div className="task-num-circle">{idx + 1}</div>
          {/* <i className={taskType.icon} style={{ fontSize: 17, marginRight: 10 }} /> */}
          <div style={{ color: "black", fontSize: 16 }}>{taskType.label}</div>
        </div>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          {this.state.editReminder.id === task.id ? (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <strong className="label-title">Date</strong>
                  <div style={{ marginLeft: 5 }} className="searchable-input">
                    <DatePicker
                      minDate={this.MIN_DATE}
                      selected={this.state.editReminder.reminde_at}
                      placeholderText="Remind Date"
                      dateFormat={window.time.short_date}
                      onChange={(date) => {
                        this.setState({
                          editReminder: {
                            ...this.state.editReminder,
                            reminde_at: date,
                          },
                        });
                      }}
                      className="input-line"
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 15 }}>
                  <strong className="label-title">Time</strong>
                  <div
                    style={{
                      marginLeft: 5,
                      width: 90,
                      fontSize: 15,
                      color: "#333",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                    className="searchable-input"
                  >
                    <input
                      value={this.state.editReminder.hour}
                      maxLength={2}
                      className="input-line"
                      style={{ textAlign: "center" }}
                      onChange={(event) => {
                        this.setState({
                          editReminder: {
                            ...this.state.editReminder,
                            hour: event.target.value,
                          },
                        });
                      }}
                    />
                    <strong>:</strong>
                    <input
                      value={this.state.editReminder.minutes}
                      maxLength={2}
                      className="input-line"
                      style={{ textAlign: "center" }}
                      onChange={(event) => {
                        this.setState({
                          editReminder: {
                            ...this.state.editReminder,
                            minutes: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 10 }}>
                <strong className="label-title">Extra Message</strong>
                <div style={{ marginLeft: 5 }}>
                  <input
                    placeholder="Leave empty for default"
                    defaultValue={this.state.editReminder.extra_reminder_text}
                    onChange={(e) =>
                      this.setState({
                        editReminder: {
                          ...this.state.editReminder,
                          extra_reminder_text: e.target.value,
                        },
                      })
                    }
                    className="input-line"
                  />
                </div>
              </div>
              {this.state.editReminder.is_reminded && (
                <strong style={{ marginTop: 10 }}>
                  This reminder already sent. Saving it will create a new
                  reminder
                </strong>
              )}
              {!saving && (
                <button
                  onClick={this.save}
                  className="btn btn-primary"
                  style={{ width: "70%", marginTop: 10 }}
                >
                  SAVE
                </button>
              )}
            </div>
          ) : reminderData.reminde_at ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <div>
                  <strong>Remind Date</strong>
                  <div>
                    {moment(reminderData.reminde_at).format(
                      window.time.hours_minutes,
                    )}
                  </div>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <strong>Message</strong>
                  <div>{reminderData.extra_reminder_text}</div>
                </div>
                <div>
                  <strong>Sent</strong>
                  <div>{reminderData.is_reminded ? "YES" : "NO"}</div>
                </div>
              </div>
              {!saving && (
                <div style={{ marginTop: 15 }}>
                  <button
                    onClick={() =>
                      this.setState({
                        editReminder: this.getDefaultReminder(
                          task.id,
                          reminderData,
                        ),
                      })
                    }
                    className="btn btn-primary"
                    style={{ width: "33%", marginRight: "2%" }}
                  >
                    EDIT
                  </button>
                  <button
                    onClick={() => this.delete(task.id)}
                    className="btn btn-primary"
                    style={{
                      marginLeft: "2%",
                      width: "33%",
                      borderColor: "#f15a24",
                      background: "#f15a24",
                    }}
                  >
                    DELETE
                  </button>
                </div>
              )}
            </div>
          ) : (
            !saving && (
              <button
                onClick={() =>
                  this.setState({
                    editReminder: this.getDefaultReminder(task.id),
                  })
                }
                className="btn btn-primary"
                style={{ width: "70%" }}
              >
                ADD REMINDER
              </button>
            )
          )}
          {saving && <div className="send-buttons-loader" />}
        </div>
      </div>
    );
  };
}
