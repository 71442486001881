// TODO - lint
/* eslint-disable default-param-last, no-param-reassign, import/no-default-export */
const brandReportsReducer = (
  state = {
    brand_reports: [],
  },
  action,
) => {
  switch (action.type) {
    case "GET_BRAND_REPORTS":
      state = {
        ...state,
        brand_reports: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};
export default brandReportsReducer;
