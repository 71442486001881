import {
  Box,
  CircularProgress,
  Flex,
  Image,
  Text,
  ThemingProps,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import {
  DropzoneOptions,
  FileError,
  useDropzone,
} from "@humanz/react-dropzone";
import { useCallback, useState } from "react";
import { uploadMedia } from "src/actions/mediaActions";
import { useBoolean } from "src/hooks/coreHooks";

export enum FileTypes {
  image,
  video,
  document,
}

export enum UploadNumber {
  multiple,
  single,
}

export const uploadFileSizeValidator = (file: File, sizeInMB = 5) => {
  if (file.size && file.size / 1000000 > sizeInMB) {
    return {
      code: "file-to-big",
      message: `File too large. Please select a file under ${sizeInMB} MB and try again.`,
    };
  }

  return null;
};

interface Props extends ThemingProps {
  onFileUploaded: (fileUrl: string, fileType?: FileTypes) => void;
  placeholder?: string;
  uploadPath?: "invoice" | string;
  previewFileUrl?: string;
  preview?: React.ReactNode;
  readonly?: boolean;
  disabled?: boolean;
  fileType?: Array<FileTypes> | FileTypes;
  uploadNumber?: UploadNumber;
  onStartUpload?: () => void;
  shareMode?: boolean;
  iconSize?: string;
  styleImg?: React.CSSProperties;
  showText?: boolean;
  circle?: boolean;
  references?: boolean;
  deleteFile?: any;
  externalUploadMedia?: (
    media: any,
    type: any,
    uploadType: any,
    progress: any,
    callback: any,
  ) => void;
  validator?: <T extends File>(file: T) => FileError | FileError[];
}

export const FileUpload = ({
  onFileUploaded,
  placeholder,
  uploadPath,
  previewFileUrl,
  preview,
  disabled,
  readonly,
  fileType,
  styleImg,
  showText,
  circle,
  iconSize,
  references,
  deleteFile,
  uploadNumber = UploadNumber.single,
  onStartUpload,
  externalUploadMedia,
  validator,
  ...otherProps
}: Props) => {
  const fileTypes = Array.isArray(fileType)
    ? fileType
    : ([fileType].filter((x) => x !== undefined) as FileTypes[]);
  const fileAccept =
    fileTypes.length === 0
      ? undefined
      : fileTypes.reduce(
          (agg, x) => ({
            ...agg,
            ...{
              [FileTypes.image]: {
                "image/*": [".png", ".gif", ".jpeg", ".jpg"],
              },
              [FileTypes.video]: { "video/*": [".mp4"] },
              [FileTypes.document]: {
                "application/pdf": [],
                "application/msword": [],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [],
              },
            }[x],
          }),
          {},
        );
  const fileText =
    fileTypes.length === 0
      ? "file"
      : fileTypes
          .map(
            (x) =>
              ({
                [FileTypes.image]: "image",
                [FileTypes.video]: "video",
                [FileTypes.document]: "document",
              }[x]),
          )
          .join(" / ");

  const {
    container: containerStyles,
    previewContainer: previewContainerStyles,
    mediaContainer: mediaContainerStyles,
    imageMedia: imageMediaStyles,
    uploadContainer: uploadContainerStyles,
    uploadText: uploadTextStyles,
    guideContainer: guideContainerStyles,
  } = useMultiStyleConfig("FileUpload", otherProps);
  const [progress, setProgress] = useState<number>();
  const [isHoverActive, setIsHoverActive] = useBoolean(false);

  const handleDropAccepted = useCallback<
    NonNullable<DropzoneOptions["onDropAccepted"]>
  >(
    (files) => {
      if (onStartUpload) onStartUpload();
      setProgress(0);
      files.forEach((file) => {
        const fileTypeForBE =
          {
            "application/pdf": "pdf",
            "application/msword": "doc",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              "doc",
          }[file.type] ??
          {
            image: "image",
            video: "video",
          }[file.type.split("/")[0]];

        (externalUploadMedia || uploadMedia)(
          file,
          fileTypeForBE,
          uploadPath,
          setProgress,
          (
            _: never,
            uploadedFileMimeType: string,
            uploadedFileUrl: string | false,
          ) => {
            if (uploadedFileUrl !== false) {
              const uploadedFileMimeTypeWithoutExt =
                uploadedFileMimeType.split("/")[0];
              const uploadedFileType = {
                image: FileTypes.image,
                video: FileTypes.video,
                application: FileTypes.document,
              }[uploadedFileMimeTypeWithoutExt];
              onFileUploaded(uploadedFileUrl, uploadedFileType);
            }
            setProgress(undefined);
          },
        );
      });
    },
    [onFileUploaded, onStartUpload, uploadPath, externalUploadMedia],
  );
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDropAccepted: handleDropAccepted,
    accept: fileAccept,
    disabled: disabled || readonly,
    multiple: uploadNumber === UploadNumber.multiple,
    noClick: (!!previewFileUrl || !!preview) && deleteFile,
    validator,
  });

  const rejected =
    isDragReject || (!isDragAccept && fileRejections?.length > 0);

  return (
    <Box
      __css={containerStyles}
      onMouseOver={setIsHoverActive.on}
      onMouseOut={setIsHoverActive.off}
      {...getRootProps()}

      // sx={{ width: "180px !important", height: "180px !important" }}
    >
      <input {...getInputProps()} />
      {(previewFileUrl !== undefined || preview !== undefined) && (
        <Box
          __css={previewContainerStyles}
          aria-readonly={readonly ? "true" : undefined}
          onClick={() => {
            if (deleteFile) {
              deleteFile();
            }
          }}
        >
          {previewFileUrl !== undefined && fileType === FileTypes.image && (
            <Box __css={mediaContainerStyles}>
              <Image
                __css={imageMediaStyles}
                src={previewFileUrl}
                style={styleImg}
              />
            </Box>
          )}
          {previewFileUrl === undefined && preview}
          {(previewFileUrl !== undefined || preview !== undefined) && (
            <Flex
              __css={guideContainerStyles}
              data-hover={isHoverActive ? true : undefined}
              data-drag-accept={isDragAccept ? true : undefined}
              data-drag-reject={rejected ? true : undefined}
              style={styleImg}
            >
              {deleteFile ? (
                <Text>
                  <i className="far fa-trash fa-lg" />
                </Text>
              ) : (
                <Text
                  textAlign="center"
                  color={(rejected || isDragAccept) && "white"}
                >
                  {(isDragAccept && "Drop file here") ||
                    (isDragReject && "File type not supported") ||
                    fileRejections?.[0]?.errors?.[0]?.message ||
                    `Click or drag ${fileText}`}
                </Text>
              )}
            </Flex>
          )}
          {progress !== undefined && (
            <CircularProgress
              value={progress}
              // size={square ? "20px" : "40px"}
            />
          )}
        </Box>
      )}
      {previewFileUrl === undefined && preview === undefined && (
        <Box
          __css={
            circle
              ? {
                  ...uploadContainerStyles,
                  minWidth: 10,
                  borderRadius: references ? "6px" : "50%",
                  borderWidth: references ? "1px" : "3px",
                }
              : { ...uploadContainerStyles, ...styleImg }
          }
          data-drag-accept={isDragAccept ? true : undefined}
          data-drag-reject={rejected ? true : undefined}
        >
          {progress !== undefined ? (
            <CircularProgress
              value={progress}
              size={references ? "20px" : "40px"}
            />
          ) : (
            <>
              <Box sx={{ marginTop: circle ? "4px" : "" }}>
                <svg
                  width={iconSize || "66"}
                  height={iconSize || "67"}
                  viewBox="0 0 66 67"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M46.6835 32.9108C47.812 32.9108 48.7268 31.9852 48.7268 30.8435V11.0267L53.4118 15.7669C54.2097 16.5742 55.5035 16.5742 56.3014 15.7669C57.0994 14.9595 57.0994 13.6506 56.3014 12.8432L48.1283 4.57401C47.3304 3.76667 46.0367 3.76667 45.2387 4.57401L37.0656 12.8432C36.2677 13.6506 36.2677 14.9595 37.0656 15.7669C37.8636 16.5742 39.1573 16.5742 39.9553 15.7669L44.6403 11.0267V30.8435C44.6403 31.9852 45.5551 32.9108 46.6835 32.9108Z"
                    fill="#249FF0"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.0618 3.96851L32.9054 3.96851C26.6166 3.96847 21.6881 3.96844 17.8427 4.49153C13.9067 5.02693 10.8009 6.14421 8.3631 8.61068C5.92531 11.0771 4.82102 14.2195 4.29184 18.2018C3.77484 22.0924 3.77487 27.0789 3.7749 33.4418V33.7581C3.77487 40.121 3.77484 45.1074 4.29184 48.998C4.82102 52.9803 5.92531 56.1227 8.3631 58.5892C10.8009 61.0556 13.9067 62.1729 17.8427 62.7083C21.6881 63.2314 26.6166 63.2314 32.9055 63.2313H33.2181C39.507 63.2314 44.4354 63.2314 48.2808 62.7083C52.2168 62.1729 55.3227 61.0556 57.7604 58.5892C60.1982 56.1227 61.3025 52.9803 61.8317 48.998C62.3487 45.1074 62.3487 40.1209 62.3486 33.7581V33.5999C62.3486 32.4582 61.4338 31.5326 60.3054 31.5326C59.1769 31.5326 58.2621 32.4582 58.2621 33.5999C58.2621 40.1553 58.2577 44.8638 57.7816 48.4471C57.7628 48.5885 57.7433 48.7276 57.7232 48.8645L50.1658 41.9828C46.6257 38.7593 41.3538 38.4383 37.4571 41.209L36.6447 41.7866C35.2908 42.7494 33.4488 42.5879 32.2786 41.404L20.5919 29.5798C17.4986 26.4501 12.5368 26.2829 9.24463 29.1975L7.8641 30.4196C7.87649 25.4794 7.94656 21.7281 8.34194 18.7527C8.81019 15.2289 9.70077 13.1045 11.2527 11.5343C12.8047 9.96409 14.9044 9.06303 18.3872 8.58927C21.9288 8.10751 26.5826 8.10312 33.0618 8.10312C34.1902 8.10312 35.105 7.17756 35.105 6.03582C35.105 4.89407 34.1902 3.96851 33.0618 3.96851ZM8.34194 48.4471C8.81019 51.9709 9.70077 54.0953 11.2527 55.6655C12.8047 57.2357 14.9044 58.1368 18.3872 58.6106C21.9288 59.0923 26.5826 59.0967 33.0618 59.0967C39.5409 59.0967 44.1947 59.0923 47.7363 58.6106C51.2192 58.1368 53.3189 57.2357 54.8708 55.6655C55.5343 54.9943 56.0769 54.2217 56.5173 53.2818C56.4113 53.2163 56.3098 53.14 56.2141 53.0529L47.4321 45.0561C45.308 43.1219 42.1448 42.9293 39.8069 44.5918L38.9945 45.1694C36.0158 47.2874 31.9634 46.9323 29.389 44.3276L17.7023 32.5034C16.1303 30.9129 13.6087 30.8279 11.9356 32.3091L7.86248 35.915C7.8695 41.2859 7.92379 45.3004 8.34194 48.4471Z"
                    fill="#249FF0"
                  />
                </svg>
              </Box>
              {showText !== false && (
                <Box __css={uploadTextStyles}>
                  <Text
                    color={(rejected || isDragAccept) && "white"}
                    maxW={"500px"}
                  >
                    {(isDragAccept && "Drop file here") ||
                      (isDragReject && "File type not supported") ||
                      fileRejections?.[0]?.errors?.[0]?.message ||
                      (placeholder ?? `Upload ${fileText}`)}
                  </Text>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
