import React from "react";
import { Trans, TranslationProps, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";

interface OwnProps {
  courses: any;
  navigateToCourse: any;
}

type Props = OwnProps;

class AcademyCoursesInner extends React.Component<
  Props,
  never,
  TranslationProps
> {
  render() {
    return (
      <>
        <div className="courses">
          {this.props.courses ? (
            this.props.courses.map((course: any) => {
              return (
                <div
                  className={"single-course"}
                  key={course.id}
                  onClick={() =>
                    this.props.navigateToCourse(
                      course.summary_per_language[0]?.id,
                    )
                  }
                >
                  <div className="course-info">
                    <div className="subscription">
                      <Trans>{course.subscription_status}</Trans>
                    </div>
                    <div className="chapters-num">{course.num_chapters}</div>
                    <div className="course-by">
                      <div className="owner-name">{course?.owner?.name}</div>
                      <div className="owner-img">
                        <img src={course?.owner?.image} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="course-image">
                    <img src={course.image} alt="" />
                  </div>
                  <div className="course-name">
                    <Trans>{course.eng_name}</Trans>
                  </div>
                  <div className="course-desc">
                    <ReactMarkdown>
                      {course.summary_per_language[0]?.description}
                    </ReactMarkdown>
                  </div>
                </div>
              );
            })
          ) : (
            <h3>No courses found.</h3>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    courses: state.academyReducer.courses,
  };
};

const mapDispatchToProps = () => {
  return {};
};
export const AcademyCourses = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AcademyCoursesInner));
