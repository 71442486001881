import { Button } from "@chakra-ui/react";

const AddTaskButton = (props: any) => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      onClick={handleClick}
      sx={{
        display: "flex",
        padding: "7px 12px 6px 12px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "20px",
        background: "var(--white, #FFF)",
        boxShadow:
          "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
        color: "#249FF0",
        marginBottom: "3px",
        fontSize: "12px",
        transition: "background-color 0.3s",
        "&:hover": {
          background: "#E7F3FB",
          color: "#249FF0",
          textDecoration: "none",
        },
      }}
    >
      <i className="fa-sharp fa-solid fa-plus"></i>
      <div
        style={{
          marginLeft: "2px",
          marginRight: "5px",

          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
        }}
      >
        Add Task
      </div>
      <i
        className="fa-light fa-square-check fa-xl"
        style={{ color: "#249FF0" }}
      ></i>
    </Button>
  );
};

export default AddTaskButton;
