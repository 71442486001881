import { Box } from "@chakra-ui/react";

const DashboardPaper = (props: any) => {
  return (
    <Box
      sx={{
        backgroundColor: props?.noBackground ? "transparent" : "white",
        width: props.width || 224,
        borderRadius: 7,
        placeItems: "center",
        alignContent: "center",
        height: props?.height || "100%",
        boxShadow: props?.noBackground
          ? "none"
          : "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
      }}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default DashboardPaper;
