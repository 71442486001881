import { Avatar } from "@chakra-ui/react";
import NoImage from "../../images/svgs/placeholder.svg";

interface OwnProps {
  notBlurable?: boolean;
  image?: string;
  size?: string;
}

export default function HumanzImageIcon(props: OwnProps) {
  return (
    <Avatar
      backgroundColor={"#fafafa"}
      icon={<img src={NoImage} />}
      size={props.size || "xl"}
      className={`image-icon ${props.notBlurable ? "" : "blurable"}`}
      src={props.image}
    />
  );
}
