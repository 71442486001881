import { Box, Flex, Select, Spinner, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { getBrandsList } from "src/api/actions/auctionActions";
import { getDashboardData } from "src/api/actions/dashboardActions";
import { HomepageDashboard } from "src/api/types";
import { get12Months } from "src/global";
import DashboardChart from "./DashboardChart";
import DashboardPaper from "./DashboardPaper";
import DashboardSingleTotalLine from "./DashboardSingleTotalLine";
import DashboardTiles from "./DashboardTiles";
import DashboardTopInfluencers from "./DashboardTopInfluencers";

const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [months, setMonths] = useState([]);
  const [dashboardData, setDashboardData] = useState<HomepageDashboard>(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [brandsList, setBrandsList] =
    useState<{ name: string; value?: number }[]>(undefined);
  const [selectedBrand, setSelectedBrand] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    setLoading(true);
    if (selectedMonth)
      getDashboardData({
        month: selectedMonth,
        brand_id: selectedBrand,
      })
        .then((res) => {
          setDashboardData(res.data);
        })
        .catch(() => {
          setDashboardData(null);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [selectedMonth, selectedBrand]);

  useEffect(() => {
    setMonths(get12Months());

    getBrandsList()
      .then((res) => {
        setBrandsList(
          res.data.map((item) => ({
            name: item.brand_name,
            value: item.brand_id,
          })),
        );
      })
      .catch(() => {
        setBrandsList([]);
      });
  }, []);

  useEffect(() => {
    if (months.length) {
      setSelectedMonth(months[months.length - 1].value);
    }
  }, [months]);

  return (
    <Box style={{ marginTop: 60, marginBottom: 60 }}>
      <Box className={"container"} style={{ maxWidth: 970 }}>
        <Flex mt={10} mb={10} justifyContent="space-between">
          <Box
            display="flex"
            gap={4}
            alignContent="flex-end"
            alignItems="flex-end"
          >
            <Text sx={{ fontSize: 32 }}>Dashboard</Text>
          </Box>
          <Flex gap={"20px"}>
            <Box
              style={{
                borderRadius: 100,
                background: "#E9ECEF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <Select
                placeholder="All brands"
                variant="filled"
                icon={!brandsList ? <Spinner /> : undefined}
                onChange={(o) => {
                  setSelectedBrand(Number(o.target.value) || undefined);
                }}
                style={{
                  borderRadius: 100,
                  background: "white",
                  boxShadow: "0px 1.33469px 2.33571px 0px rgba(0, 0, 0, 0.17)",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "180px",
                }}
              >
                {brandsList?.map((brand, index) => (
                  <option
                    selected={selectedBrand === brand.value}
                    key={index}
                    value={brand.value}
                  >
                    {brand.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Box
              style={{
                borderRadius: 100,
                background: "#E9ECEF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                width: "180px",
              }}
            >
              <Select
                variant="filled"
                onChange={(o) => {
                  setSelectedMonth(o.target.value);
                }}
                style={{
                  borderRadius: 100,
                  background: "white",
                  boxShadow: "0px 1.33469px 2.33571px 0px rgba(0, 0, 0, 0.17)",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                {months.map((month, index) => (
                  <option
                    selected={selectedMonth === month.value}
                    key={index}
                    value={month.value}
                  >
                    {month.name}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
        </Flex>
        <Box>
          <DashboardTiles
            loading={loading}
            type="main"
            dashboardData={dashboardData}
          />
        </Box>
        <Flex mt={4} gap={4}>
          <Box flex={2}>
            <DashboardPaper width={"auto"}>
              <DashboardChart
                data={dashboardData?.sales_daily}
                loading={loading}
              />
            </DashboardPaper>
          </Box>
          <Box flex={2}>
            <DashboardPaper
              width={"100%"}
              style={{
                borderRadius: "8px",
                padding: 8,
                background: "rgba(235, 235, 235, 0.40)",
              }}
            >
              <DashboardTiles
                loading={loading}
                type="tasks"
                dashboardData={dashboardData}
              />
            </DashboardPaper>
          </Box>
        </Flex>
        <Flex mt={4} gap={4}>
          <Box flex={2}>
            <DashboardTopInfluencers
              selectedMonth={selectedMonth}
              selectedBrandId={selectedBrand}
            />
          </Box>
          <Box flex={2}>
            <DashboardPaper
              width={"100%"}
              style={{
                borderRadius: "8px",
                padding: 8,
                background: "rgba(235, 235, 235, 0.40)",
                gap: 10,
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <DashboardSingleTotalLine
                value={dashboardData?.total_bids?.value}
                title1={"Total"}
                title2={"BIDS"}
                loading={loading}
              />
              <DashboardSingleTotalLine
                value={dashboardData?.total_influencers_invited?.value}
                title1={"Total influencers"}
                title2={"INVITED"}
                loading={loading}
              />
              <DashboardSingleTotalLine
                value={dashboardData?.total_influencers_selected?.value}
                title1={"Total influencers"}
                title2={"SELECTED"}
                loading={loading}
              />
            </DashboardPaper>
          </Box>
        </Flex>
        <Box
          style={{
            textAlign: "center",
            fontSize: 14,
            color: "#999999",
            marginTop: 20,
          }}
        >
          Dashboard data is updated once an hour. Last updated:{" "}
          {moment(dashboardData?.last_update).format(
            window.time.date_with_hour,
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardPage;
