// TODO - lint
// General Imports
import { Button } from "@chakra-ui/react";
import moment from "moment";
import Tabs from "rc-tabs";
// Components Import
// Actions imports
import ScrollableInkTabBar from "rc-tabs/es/ScrollableInkTabBar";
import TabContent from "rc-tabs/es/TabContent";
import TabPane from "rc-tabs/es/TabPane";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDisputes, getInvoices } from "../../../actions/paymentActions";
import { UpcomingPayments } from "./upcomingPayments";

class BillingTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      disputes: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getInvoices((invoices) => {
      if (invoices) {
        this.setState({ invoices });
      }
    });
    this.setState({ disputesLoading: true });
    this.props.getDisputes((disputes) => {
      if (disputes) {
        this.setState({ disputesLoading: false, disputes });
      }
    });
  }

  render() {
    const { invoices, disputes } = this.state;
    return (
      <div className="create-tab-content payments" style={{ marginTop: 30 }}>
        <Tabs
          className={"text-left"}
          defaultActiveKey="1"
          onChange={() => {}}
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent />}
        >
          <TabPane tab="Upcoming payments" key="1">
            <div className="payments-overview-section">
              <UpcomingPayments />
            </div>
          </TabPane>
          <TabPane tab="Billing history" key="2">
            <div className="payments-overview-section">
              <div className="campaigns-title">
                <span>Billing history</span>
              </div>
            </div>
            <div className="invoice-top-bar">
              <div>date billed</div>
              <div>transaction id</div>
              <div>payment method</div>
              <div>amount billed</div>
            </div>
            {invoices && invoices.length > 0 ? (
              invoices.map((x) => this.renderInvoices(x))
            ) : (
              <div className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
              </div>
            )}

            <div className="create-buttons-row">
              <button
                className="btn btn-primry"
                onClick={() => this.props.navigate(-1)}
              >
                Back
              </button>
              <button
                className="btn btn-primry"
                onClick={() => this.setState({ currentTab: "3" })}
              >
                Save
              </button>
            </div>
          </TabPane>
          <TabPane tab="Disputes history" key="3">
            <div className="payments-overview-section">
              <div className="campaigns-title">
                <span>Disputes history</span>
              </div>
              <hr />
            </div>
            {disputes && disputes.length > 0 ? (
              disputes.map((x) => this.renderDisputes(x))
            ) : this.state.disputesLoading ? (
              <div className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
              </div>
            ) : (
              "You currently don't have any disputes."
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }

  renderInvoices(invoice) {
    const time = moment(invoice.charge_time);
    return (
      <div className="invoice-row" key={invoice.transaction_id}>
        <div className={"invoice-row-details"} style={{ width: "100%" }}>
          <div>{time.format("LL")}</div>
          <div className="invoice-transaction-id">
            <span>{invoice.transaction_id}</span>
          </div>
          <div>{invoice.credit_card_issuer}</div>
          <div style={{ width: "calc(25% - 20px)" }}>
            {window.translate("", `money_symbol_${invoice.currency}`)}
            {invoice.amount}
          </div>
          <a
            href={invoice.invoice_url}
            target="_blank"
            download
            style={{
              width: 20,
              color: "var(--main-baby-blue-color)",
              fontSize: 20,
              marginTop: 2,
              borderBottom: "4px solid #249ff0",
              paddingLeft: 1,
              cursor: "pointer",
              height: 27,
            }}
            className="fa fa-arrow-down"
            rel="noreferrer"
          />
        </div>
      </div>
    );
  }

  triggerCollapse(item) {
    item.collapsed = !item.collapsed;
    this.setState({});
  }

  goToDispute = (influ) => {
    this.props.navigate(
      `/campaign/${influ.auction_id}/payments?i=${influ.influencer_id}${
        influ.month_relevance ? "&month=" + influ.month_relevance : ""
      }`,
    );
  };

  renderDisputes(dispute) {
    return (
      <div key={dispute.auction_id}>
        <div
          className="clickable collapse-item collapse-title"
          onClick={() => this.triggerCollapse(dispute)}
        >
          <span className="collapseTrigger">
            <i
              className={`fa${
                dispute.collapsed ? " fa-chevron-right" : " fa-chevron-down"
              }`}
            />
          </span>
          <span>{dispute.auction_title}</span>
        </div>
        <div
          className={`collapse-content${
            !dispute.collapsed ? " collapsed" : ""
          }`}
        >
          {dispute.disputed_influencers && dispute.disputed_influencers.length
            ? dispute.disputed_influencers.map((influ, j) => {
                influ.response_time = influ.response_time
                  ? influ.response_time
                  : "";
                influ.creation_time = influ.creation_time
                  ? influ.creation_time
                  : "";
                return (
                  <div key={j}>
                    <div
                      className={
                        "clickable collapse-item collapse-title inner-item"
                      }
                      onClick={() => this.triggerCollapse(influ)}
                    >
                      <span className="collapseTrigger">
                        <i className="fa fa-chevron-down" />
                      </span>
                      <span
                        style={{
                          maxWidth: "90%",
                          display: "inline-block",
                          fontSize: 13,
                          color: "#333333",
                        }}
                      >
                        {influ.name || "Dispute"}
                      </span>
                      <div
                        className={`pull-right status ${
                          influ.decision ? "resolved" : ""
                        }`}
                      >
                        {influ.decision ? influ.decision : "Pending"}
                      </div>
                    </div>
                    <div
                      className={`collapse-content${
                        influ.collapsed ? " collapsed" : ""
                      }`}
                    >
                      <div className={"collapse-item subitem"}>
                        <div>
                          <i>{influ.custom_message}</i>
                        </div>
                        <div>
                          <strong>Dispute ID:</strong> {influ.dispute_id}
                        </div>
                        <div>
                          <strong>Created at: </strong>
                          {influ.creation_time
                            ? moment(influ.creation_time).format(
                                window.time.short_date,
                              )
                            : ""}
                        </div>
                        {influ.response_time && (
                          <div>
                            <strong>Resolved at:</strong>{" "}
                            {influ.response_time
                              ? influ.response_time
                              : " Unresolved"}
                          </div>
                        )}
                        <div className={"dispute-response"}>
                          {" "}
                          {
                            <div>
                              {influ.response ? influ.response : " Unresolved"}
                            </div>
                          }
                        </div>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.goToDispute(influ);
                          }}
                          rightIcon={<i className="far fa-external-link" />}
                          mt={2}
                          type="button"
                          variant={"small-action-white"}
                        >
                          Show dispute in campaign
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoices: (callback) => {
      dispatch(getInvoices(callback));
    },
    getDisputes: (callback) => {
      dispatch(getDisputes(callback));
    },
  };
};

const BillingTabConnected = connect(null, mapDispatchToProps)(BillingTab);

export default (props) => {
  const navigate = useNavigate();

  return <BillingTabConnected {...props} navigate={navigate} />;
};
