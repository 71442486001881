/* eslint-disable no-nested-ternary */
import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Link,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Clipboard from "react-clipboard.js";
import { StagesAccordion } from "../StagesAccordion";

interface Props {
  isLoading: boolean;
  nextStep: any;
  platform: any;
  appId: number;
}

export const Manual = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const accordElem = useRef(null);
  const { appId } = props;
  const toast = useToast();
  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    if (accordElem?.current?.children[currentStep]) {
      accordElem.current.children[currentStep].scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [setCurrentStep, currentStep]);

  const initTag = `<script>
    function lHzpAsy(u, c) {
        var d = document, t = 'script',
            o = d.createElement(t),
            s = d.getElementsByTagName(t)[0];
        o.src = u;
        if (c) { o.addEventListener('load', function (e) { c(null, e); }, false); }
        s.parentNode.insertBefore(o, s);
    }
    lHzpAsy("https://assets.humanz.com/${appId}/humanz-gtm.js", function () {
        try {
            var hmz = new window.hzi("${appId}");
        } catch (e) { }
    });
</script>
`;

  const purchaseTag = `<script>
  function lHzpAsy(u, c) {
      var d = document, t = 'script',
          o = d.createElement(t),
          s = d.getElementsByTagName(t)[0];
      o.src = u;
      if (c) { o.addEventListener('load', function (e) { c(null, e); }, false); }
      s.parentNode.insertBefore(o, s);
  }
  lHzpAsy("https://assets.humanz.com/${appId}/humanz-gtm.js", function () {
      try {
          var hmz = new window.hzi("${appId}");
          var h = window.hzp;
          var products = h_products || [];
          var coupon = h_coupon || "";
          var orderId = h_order_id || ""
          if (h) {
              h.gtmPurchaseKey(products,coupon,orderId);
          }
      } catch (e) { }
  });
</script>
`;
  const steps: any = [
    {
      id: 0,
      title: "Create the required variables",
      body: (
        <Box>
          <div className="preview-description">
            <Box className={"small-step"}>
              <p>
                In this manual we are going to trigger the Humanz Pixel events
                manually. We will need you to create the variables and push the
                relevant events.
                <br />
                <br />
                <Alert status="info">
                  <AlertIcon />
                  <span>
                    It is recommended to use Google Tag Manager for all of your
                    tracking codes. See -{" "}
                  </span>
                  <Link
                    href="https://support.google.com/tagmanager/answer/6103696?hl=en"
                    isExternal
                  >
                    Google official reference &nbsp;
                    <i className="far fa-external-link" />
                  </Link>
                </Alert>
                <br />
              </p>
            </Box>
            <Box className={"small-step"}>
              Please create those following variables on your{" "}
              <strong>Javascript global scope</strong>, make sure they will be
              accessible to the <strong>window</strong> object before the humanz
              script is being called.
              <br />
              <br />
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>h_products</strong>
                </p>
                <p>
                  <span>
                    An array of products with{" "}
                    <Link
                      href="https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#purchases"
                      isExternal
                    >
                      this structure&nbsp;
                      <i className="far fa-external-link" />
                    </Link>{" "}
                    or like the example below
                  </span>
                </p>
              </Box>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>h_order_id</strong>
                </p>
                <p>
                  <span>
                    A string of the order ID/transaction ID/confirmation ID.
                    Must be unique.
                  </span>
                </p>
              </Box>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>h_coupon</strong>
                </p>
                <p>
                  <span>
                    A string of the coupon being used (can be empty string or
                    null).
                  </span>
                </p>
              </Box>
            </Box>
            <Alert status="warning">
              <AlertIcon />
              <span>
                You can also directly assign those variables inside the Humanz
                script if you don&apos;t want to create extra variables, but we
                think it can keep things neet.
              </span>
            </Alert>

            <Box className="small-step">
              <p>
                <strong>
                  Example for a super simple and abstracted implementation in
                  PHP:
                </strong>
              </p>
              <pre>
                {`// some php code...
?>
<head>
  <script> 
  var h_products = <?=$my_products?>;
  var h_coupon = <?=$my_coupon?>;
  var h_order_id = <?=$my_order_id?>;
  </script>
</head>
<body>
  <!-- Humanz scripts can be called from here on... -->
<?php // more php code`}
              </pre>
            </Box>
          </div>
          <Box>
            <p>
              <strong>Example for an array of products:</strong>
            </p>
            <p>
              <pre>
                {`[{                           
   // List of productFieldObjects.
   'name': 'Triblend Android T-Shirt',// Name or ID is required.
   'id': '12345',
   'price': '15.25',
   'brand': 'Google',
   'category': 'Apparel',
   'variant': 'Gray',
   'quantity': 1,
   'coupon': '10OFF' // Optional fields may be omitted or set to empty string.
   },
   {
   'name': 'Donut Friday Scented T-Shirt',
   'id': '67890',
   'price': '33.75',
   'brand': 'Google',
   'category': 'Apparel',
   'variant': 'Black',
   'quantity': 1
}]`}
              </pre>
            </p>
          </Box>
        </Box>
      ),
    },
    {
      id: 1,
      title: "Create Page Init tag",
      body: (
        <>
          <Flex>
            <div className="preview-description">
              <p>
                In order to detect the potential client, we need to have this
                tag loaded on all pages.
                <br />
              </p>
              <Divider mb={2} />
              <Box>
                <p>You can copy and paste this following code.</p>
              </Box>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>
                    Make sure this script is loaded on all of your website
                    pages:
                  </strong>
                </p>
                <p>
                  <pre style={{ position: "relative" }}>
                    {" "}
                    <Clipboard
                      style={{
                        background: "transparent",
                        border: "none",
                        right: 0,
                        top: 5,
                        position: "absolute",
                      }}
                      data-clipboard-text={initTag}
                      onClick={() =>
                        toast({
                          title: "Code copied to your clipboard",
                          status: "success",
                          duration: 1000,
                          isClosable: false,
                        })
                      }
                    >
                      <Tooltip hasArrow label="Copy to clipboard">
                        <i className="far fa-copy copy-btn" />
                      </Tooltip>
                    </Clipboard>
                    {initTag}
                  </pre>
                </p>
              </Box>
            </div>
          </Flex>
        </>
      ),
    },
    {
      id: 3,
      title: "Create Purchase tag",
      body: (
        <>
          <Flex>
            <div className="preview-description">
              <Divider mb={2} />
              <p>
                This tag should be triggered with a purchase event on your
                website along with your cart items.
                <br />
              </p>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>
                    Make sure you don&apos;t have any errors on your code when
                    extracting the variables. It&apos;s recommended to wrap your
                    code in a try-catch block, and if the data is missing just
                    send null or an empty string instead.
                  </strong>
                </p>
              </Box>
              <Divider mb={2} />
              <Box>
                <p>
                  <strong>Copy and paste the following code:</strong>
                </p>
                <p>
                  <pre style={{ position: "relative" }}>
                    {" "}
                    <Clipboard
                      style={{
                        background: "transparent",
                        border: "none",
                        right: 0,
                        top: 5,
                        position: "absolute",
                      }}
                      data-clipboard-text={purchaseTag}
                      onClick={() =>
                        toast({
                          title: "Code copied to your clipboard",
                          status: "success",
                          duration: 1000,
                          isClosable: false,
                        })
                      }
                    >
                      <Tooltip hasArrow label="Copy to clipboard">
                        <i className="far fa-copy copy-btn" />
                      </Tooltip>
                    </Clipboard>
                    {purchaseTag}
                  </pre>
                </p>
                <Alert status="warning">
                  <AlertIcon />
                  <span>
                    As mentioned, you can also directly assign those variables
                    (h_products, h_order_id, h_coupon) inside this script if you
                    don&apos;t want to create extra variables.
                  </span>
                </Alert>
              </Box>
            </div>
          </Flex>
        </>
      ),
    },
  ];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <StagesAccordion
      currentStep={currentStep}
      isLoading={props.isLoading}
      nextStep={nextStep}
      prevStep={prevStep}
      steps={steps}
      completeStage={props.nextStep}
      description={
        <>
          This part will walk you through installing Humanz Pixel manually on a
          custom built system. <br /> Please follow the instructions below
        </>
      }
      eventValidation={true}
      setStep={(step: any) => () => setCurrentStep(step.id)}
    />
  );
};
