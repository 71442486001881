import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import ConfirmActionDialog from "../../dialogs/ConfirmActionDialog";
import "./side-menu.css";

const SideMenu = (props: any) => {
  const [isModalChangeNameOpen, setIsModalChangeNameOpen] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleTemplateNameChange = (event: any) => {
    setNewTemplateName(event.target.value);
  };
  const openModal = () => {
    setIsModalChangeNameOpen(true);
  };

  const handleNext = () => {
    setIsModalChangeNameOpen(false);
    props.setTemplateName(newTemplateName);
  };
  const handleClose = () => {
    props.setShowTodoList(false);
  };
  const ToDoActions = [
    // Conditionally show this element only when step === "create"
    props.tasks.length === 0
      ? {
          action: () => {
            if (props.tasks.length === 0) {
              props.setStep("import-template");
            }
          },
          text: "Import template",
          icon: "fa-thin fa-clipboard-list",
        }
      : null,
    props.tasks.length !== 0
      ? {
          action: () => {
            setShowConfirmDelete(true);
          },
          text: "Delete all",
          icon: "fa-thin fa-trash-can",
        }
      : null,

    {
      action: () => {
        props.handleMarkAllAsDone();
      },
      text: props.tasks.length === 0 ? "Create New" : "Mark all tasks as done",
      icon:
        props.tasks.length === 0
          ? "fa-thin fa-square-check"
          : "fa-thin fa-square-check",
    },
  ];
  const handleMoveBack = () => {
    if (props.step === "start-from-template") {
      props.setStep("import-template");
      props.setTasks([]);
    } else {
      props.setStep("start");
    }
  };
  return (
    <>
      {props.isOpen && (
        <Box className="import-from-todolist-container animated fadeInRight faster">
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "white",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {props.step === "start-from-template" ||
              props.step === "import-template" ? (
                <Button
                  onClick={handleMoveBack}
                  style={{ background: "white", color: "black" }}
                >
                  <i
                    style={{ marginRight: "5px", marginLeft: "10px" }}
                    className="far fa-arrow-left fa-lg"
                  ></i>
                </Button>
              ) : (
                <HumanzActionsMenu buttontype="dots" actions={ToDoActions} />
              )}
              <Box>
                {props.step === "new-template" ? (
                  <div>
                    <span
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: 15,
                        fontWeight: 600,
                        color:
                          props.step === "start-from-template"
                            ? "#249FF0"
                            : "inherit",
                      }}
                      onClick={openModal}
                    >
                      <Box>
                        <span
                          style={{ color: "grey" }}
                        >{`${props.header}`}</span>
                        &nbsp;&nbsp;
                        <i className="fa-thin fa-pen"></i>
                      </Box>
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: 600,
                      color:
                        props.step === "start-from-template"
                          ? "#249FF0"
                          : "inherit",
                    }}
                  >
                    {props.header}
                  </div>
                )}
              </Box>
            </Box>
            <div
              className="filter-close-button"
              onClick={handleClose}
              style={{ marginRight: "20px" }}
            >
              <span className="moi-ximage" />
            </div>
          </Box>
          {props.children}
        </Box>
      )}
      <>
        {isModalChangeNameOpen && (
          <Modal
            size="sm"
            isOpen={true}
            onClose={() => {
              setIsModalChangeNameOpen(false);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Template Name</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                display="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
              >
                <Input
                  onChange={handleTemplateNameChange}
                  sx={{
                    border: "none",
                    padding: "0",
                    borderBottom: "1px solid gray",
                    borderRadius: "0",
                    "&:focus": {
                      boxShadow: "none",
                    },
                  }}
                  placeholder="Template Name"
                />
                <Button
                  mt={4}
                  onClick={handleNext}
                  isDisabled={
                    newTemplateName?.trim() === ""
                    // disableAddTemplate(templateName)
                  }
                >
                  Next
                </Button>
              </ModalBody>
            </ModalContent>
            <div>Modal Content</div>
          </Modal>
        )}
        {showConfirmDelete && (
          <ConfirmActionDialog
            actionTitle="Delete All"
            handleClose={() => setShowConfirmDelete(false)}
            actionDescription="Are you sure you want to delete all tasks?"
            confirm={() => {
              props.handleDeleteAll();
              setShowConfirmDelete(false);
            }}
          />
        )}
      </>
    </>
  );
};

export default SideMenu;
