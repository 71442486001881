// General Imports
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createShadowInfluencer,
  editShadowInfluencer,
} from "src/api/actions/listActions";
import { ShadowInfluencer, ShadowInfluencerWithId } from "src/api/types";
import { FileTypes, FileUpload } from "src/ui/FileUpload";

interface Props {
  existingInfluencer?: ShadowInfluencerWithId;
  menu?: boolean;
  onBack?: (refresh?: boolean) => void;
}

export const CreateExternalInfluencer: React.FC<Props> = ({
  existingInfluencer,
  menu,
  onBack,
}) => {
  const [name, setName] = useState<string>(existingInfluencer?.name ?? "");
  const [saving, setSaving] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const navigate = useNavigate();

  const handleGoBack = useCallback<(refresh?: boolean) => void>(
    (refresh?: boolean) => {
      if (onBack) onBack(refresh);
      else navigate(-1);
    },
    [navigate, onBack],
  );

  const handleFormSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const target = e.target as HTMLFormElement;
      const influencerName = (target.influencerName as HTMLInputElement).value;
      const influencerNote = (target.influencerNote as HTMLInputElement).value;

      const influencer: ShadowInfluencer = {
        name: influencerName,
        note: influencerNote,
        image: imageUrl || existingInfluencer?.image || null,
      };

      setSaving(true);

      if (existingInfluencer?.influencer_id) {
        editShadowInfluencer(existingInfluencer.influencer_id, influencer)
          .then(() => {
            handleGoBack(true);
          })
          .catch(() => {
            toast.error("Failed to edit influencer.");
          });
      } else {
        createShadowInfluencer(influencer)
          .then(() => {
            handleGoBack(true);
          })
          .catch(() => {
            toast.error("Failed to create influencer.");
          });
      }
    },
    [handleGoBack, existingInfluencer, imageUrl],
  );

  const handleTitleChange = useCallback<
    React.FormEventHandler<HTMLInputElement>
  >((e) => {
    const titleValue = e.currentTarget.value;
    const titleLimited = titleValue.substring(0, 50);
    setName(titleLimited);
  }, []);

  const handleFileUploaded = useCallback<(newImageUrl: string) => void>(
    (newImageUrl) => {
      setImageUrl(newImageUrl);
    },
    [],
  );

  return (
    <>
      <div className="create-external-influencer">
        <div>
          <form onSubmit={handleFormSubmit}>
            <div style={{ display: "flex" }}>
              <div
                className="new-list-inputs"
                style={menu ? { marginLeft: 5, marginRight: 5 } : {}}
              >
                <div className="campaigns-title">
                  <span>
                    {existingInfluencer.influencer_id
                      ? "Edit External Influencer"
                      : "Create New External Influencer"}
                  </span>
                </div>

                {/* Description Section */}
                <div
                  className="create-description-form"
                  style={{ marginTop: 30 }}
                >
                  <div className="input-row">
                    <strong className="label-title">Influencer Name</strong>
                    <div>
                      <input
                        name="influencerName"
                        className="input-line"
                        value={name}
                        onChange={handleTitleChange}
                        autoFocus
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <strong className="label-title">Note</strong>
                    <div>
                      <input
                        name="influencerNote"
                        className="input-line"
                        defaultValue={existingInfluencer?.note ?? ""}
                        placeholder="Describe your new list"
                      />
                    </div>
                  </div>
                  <div
                    className="create-buttons-row"
                    style={{ paddingTop: 20 }}
                  >
                    <button
                      type="button"
                      className="btn btn-primry"
                      onClick={() => handleGoBack()}
                    >
                      BACK
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primry"
                      disabled={!name || saving}
                    >
                      {existingInfluencer.influencer_id ? "Save" : "Create"}
                    </button>
                  </div>
                </div>
                {/* End Description Section */}
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "70px",
                  flex: "0 0 200px",
                  height: "200px",
                }}
              >
                <FileUpload
                  placeholder="Profile picture"
                  variant="round"
                  fileType={FileTypes.image}
                  previewFileUrl={
                    existingInfluencer.image
                      ? existingInfluencer.image
                      : imageUrl
                  }
                  onFileUploaded={handleFileUploaded}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
