// TODO - lint
/* eslint-disable import/no-default-export, react/no-deprecated, class-methods-use-this */
// General Imports
import React from "react";
import { useSearchParams } from "react-router-dom";

class PeachRedirectPageInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ccAuthRedirectPage: false, ccAuthRedirectData: undefined };
  }

  componentWillMount() {
    const { searchParams } = this.props;
    if (searchParams.has("transactionId") && searchParams.has("id")) {
      let redirectTo = sessionStorage.getItem("PAYMENTS_PAGE");
      if (redirectTo) {
        redirectTo = JSON.parse(redirectTo);
        sessionStorage.setItem(
          "PEACH_PAYMENTS_DETAILS",
          JSON.stringify({
            transactionId: searchParams.get("transactionId"),
            id: searchParams.get("id"),
          }),
        );
        window.location = redirectTo.page;
      } else {
        window.location = "/home";
      }
    } else {
      let params = sessionStorage.getItem("PEACH_PARAMS");
      if (params) {
        params = JSON.parse(params);
        this.setState({ ...params });
        sessionStorage.removeItem("PEACH_PARAMS");
        window.setTimeout(this.goToPeach.bind(this), 1000);
      } else {
        window.location = "/home";
      }
    }
  }

  goToPeach = () => {
    if (this.state.ccAuthRedirectPage) {
      const form = document.getElementById("peach3DS_Form");
      if (form) {
        form.submit();
      }
    }
  };

  renderRedirectParam = (param) => {
    return <input type="hidden" name={param.name} value={param.value} />;
  };

  render() {
    if (!this.state.ccAuthRedirectPage) return <div></div>;
    return (
      <div style={{ fontSize: 17 }}>
        <form
          id="peach3DS_Form"
          action={this.state.ccAuthRedirectData.url}
          method="POST"
        >
          {this.state.ccAuthRedirectData.parameters.map(
            this.renderRedirectParam,
          )}
          <input type="submit" value="If you are not redirected, click here" />
        </form>
      </div>
    );
  }
}

export default (props) => {
  const [searchParams] = useSearchParams();

  return <PeachRedirectPageInner {...props} searchParams={searchParams} />;
};
