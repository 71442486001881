// TODO - lint
/* eslint-disable class-methods-use-this, no-nested-ternary, @typescript-eslint/ban-ts-comment, @typescript-eslint/no-use-before-define, @typescript-eslint/ban-ts-comment, @typescript-eslint/no-use-before-define, import/no-default-export */
import React, { Component } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getTipaltiLink } from "../../actions/utilsActions";

interface OwnProps {
  props: any;
  getTipaltiLink: any;
}

type Props = OwnProps & {
  params: any;
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{
  iframe_link: false;
  error?: string;
}>;

class TipaltiIframe extends Component<Props, State> {
  readonly state: State = {
    iframe_link: false,
  };

  componentDidMount(): void {
    this.props.getTipaltiLink(this.props.params.guid, (data: any) => {
      if (data && data.url) {
        this.setState({ iframe_link: data.url });
      } else if (data && !data.url) {
        this.setState({ error: "This form has already been filled." });
      } else {
        this.setState({
          error:
            "Something is wrong on our side. Please contact us at info@humanz.ai",
        });
      }
    });
  }

  iframeChanged = (event: any) => {
    console.log(event);
    if (
      event &&
      event.url &&
      event.url.match(
        "https\\:\\/\\/www\\.humanz\\.ai\\/tipalti\\/success\\?request_id=.*",
      )
    ) {
      alert(
        "Thank you, your information was updated successfully. You can now close this page.",
      );
      window.close();
    }
  };

  render() {
    return (
      <>
        {this.state.error ? (
          <div
            // @ts-ignore
            style={style.messageStyle}
          >
            {this.state.error}
          </div>
        ) : this.state.iframe_link ? (
          <iframe
            style={{
              minHeight: "100%",
              minWidth: "100%",
              width: "100%",
              height: window.innerHeight - 6,
              border: "none",
              margin: "none",
            }}
            src={this.state.iframe_link}
            onChange={this.iframeChanged}
          />
        ) : (
          <div
            // @ts-ignore
            style={style.messageStyle}
          >
            <strong>
              Please wait, it might take a few seconds to display the page
            </strong>
          </div>
        )}
      </>
    );
  }
}
const style = {
  messageStyle: {
    border: "1px solid #f3f3f3",
    backgroundColor: "#fafafa",
    padding: "10px",
    textAlign: "center",
    margin: "0 auto",
  },
};
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => ({
  getTipaltiLink: (guid: string, callback: any) =>
    dispatch(getTipaltiLink(guid, callback)),
});

const TipaltiIframeConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TipaltiIframe);

export default ((props: any) => {
  const params = useParams();

  return <TipaltiIframeConnected {...props} params={params} />;
}) as React.FC<OwnProps>;
