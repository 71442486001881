import { FormikProps } from "formik";
import Tooltip from "rc-tooltip";
import { useSelector } from "react-redux";
import { PostTypes } from "src/models/Api";

type Props = {
  handleAddTask: any;
  formik: FormikProps<any>;
};

const AddTasks = ({ handleAddTask, formik }: Props) => {
  const user = useSelector((store: any) => store.userReducer);
  return (
    <div
      style={{
        marginTop: 24,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ justifyContent: "center" }}>
        <div className={"add_task_row"} style={{ borderRadius: "12px" }}>
          <strong>
            {formik.values.task_stages.length > 0
              ? "Add another task"
              : "Add task"}{" "}
          </strong>
          <div className="grey-subtitle">
            Click on the relevant icon for adding a new task
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip placement={"top"} overlay={"Instagram post"}>
              <div
                className="fa fa-instagram colored circle-icon clickable"
                style={{
                  fontSize: "18px",
                  width: 40,
                  height: 40,
                }}
                title={"Instagram Post"}
                onClick={() => handleAddTask(true, PostTypes.IgPost)}
              />
            </Tooltip>
            <Tooltip placement={"top"} overlay={"Instagram Story"}>
              <div
                className="fa moi-stories colored circle-icon clickable"
                style={{
                  background: "white",
                  color: "black",
                  fontSize: "18px",
                  width: 40,
                  height: 40,
                }}
                title={"Instagram Story"}
                onClick={() => handleAddTask(true, PostTypes.IgStory)}
              />
            </Tooltip>
            <Tooltip placement={"top"} overlay={"YouTube Video"}>
              <div
                className="fa fa-youtube-play colored circle-icon clickable"
                style={{
                  background: "white",
                  fontSize: "18px",
                  width: 40,
                  height: 40,
                }}
                title={"YouTube Video"}
                onClick={() => handleAddTask(true, PostTypes.YtVideo)}
              />
            </Tooltip>
            <Tooltip placement={"top"} overlay={"Twitter Post"}>
              <div
                className="fa fa-twitter colored circle-icon clickable"
                style={{
                  background: "white",
                  fontSize: "18px",
                  width: 40,
                  height: 40,
                }}
                title={"Twitter Post"}
                onClick={() => handleAddTask(true, PostTypes.TwTweet)}
              />
            </Tooltip>
            {user?.userDetails?.sidebar_data?.can_view_tiktok && (
              <Tooltip placement={"top"} overlay={"Tiktok video"}>
                <div
                  className="fa moi-tik-tok circle-icon  clickable"
                  style={{
                    background: "white",
                    color: "black",
                    fontSize: "18px",
                    width: 40,
                    height: 40,
                  }}
                  title={"Tiktok video"}
                  onClick={() => handleAddTask(true, PostTypes.TkVideo)}
                />
              </Tooltip>
            )}
            <Tooltip placement={"top"} overlay={"Content only"}>
              <div
                className="far fa-images circle-icon clickable"
                title={"Content only"}
                style={{
                  marginTop: 0,
                  background: "white",
                  color: "black",
                  fontSize: "18px",
                  width: 40,
                  height: 40,
                }}
                onClick={() => handleAddTask(true, PostTypes.InfluencerContent)}
              />
            </Tooltip>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {typeof formik.errors.task_stages === "string" &&
          formik.touched.task_stages === undefined ? (
            <span
              className="error-text"
              style={{
                background: "rgba(245, 101, 101, 0.12)",
                display: "flex",
                alignItems: "center",
                width: "230px",
                padding: "4px 2px",
                paddingLeft: "6px",
                borderRadius: "6px",
                marginTop: "16px",
              }}
            >
              <i
                className="error-mark"
                style={{ margin: 0, marginRight: "5px" }}
              ></i>
              {formik.errors.task_stages}
            </span>
          ) : (
            <span className="error-text">&nbsp;</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddTasks;
