import { Avatar, Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";

import moment from "moment";
import Dialog from "rc-dialog";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getAuctionGoalsData } from "src/actions/auctionsActions";
import { AuctionDataExtended } from "src/api/ExtendedTypes";
import {
  deleteMoiAuctionAuction_idTargetsDelete,
  postMoiAuctionAuction_idTargetsCreate,
  postMoiAuctionAuction_idTargetsUpdate,
} from "src/api/services";
import {
  InfluencerInManageAuctionData,
  Target,
  TargetMetrics,
  TargetStep,
  TargetTypes,
} from "src/api/types";
import { RootState } from "src/declarations/react-redux";
import { getTargetHeader } from "src/helpers/getTargetHeader";
import CampaignMonthSelector from "../CampaignMonthSelector";
import CampaignTargetAccordion from "./CampaignTargetAccordion";
import CampaignViewTarget from "./CampaignViewTarget";

interface ICampaignTargetsModal {
  influencer: InfluencerInManageAuctionData;
  isOpen?: boolean;
  setIsOpen?: any;
  targetList?: Target[];
  board?: AuctionDataExtended;
  handleMonthChange?: (month: string) => void;
  isCreate?: boolean;
  loading?: boolean;
  selectedMonth?: string;
  setIsLoading?: any;
}

export interface FormValue<T> {
  value: T;
  error: string;
}

export interface FormGoalStep extends Omit<TargetStep, "target_value"> {
  target_value: FormValue<number>;
}

export interface FormAuctionGoal extends Omit<Target, "end_time" | "steps"> {
  end_time?: FormValue<string>;
  steps?: FormGoalStep[];
}

export interface AuctionGoals {
  [key: string]: FormAuctionGoal;
}

const CampaignTargetsModal = ({
  influencer,
  isOpen,
  setIsOpen,
  board,
  handleMonthChange,
  isCreate,
  targetList,
  selectedMonth,
  loading,
  setIsLoading,
}: ICampaignTargetsModal) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [targets, setTargets] = useState<number[]>([]);
  const [auctionGoals, setAuctionGoals] = useState<AuctionGoals>({});
  const [isViewTargetEditing, setIsViewTargetEditing] = useState<number[]>([]);
  const [editAuctionGoals, setEditAuctionGoals] = useState<AuctionGoals>({});

  const isCampaignEnded = moment().isAfter(board.campaign_end_time);
  const isPastMonth =
    moment(selectedMonth).endOf("month").isBefore(moment()) &&
    !board.one_month_campaign;

  const monthPickerOptions = () => {
    const startTime = board.states?.approval?.influencers.find(
      (influ) => influ.influencer_id === influencer.influencer_id,
    ).approved_at;
    const endTime = board.campaign_end_time;

    const start = moment(startTime);
    const end = moment(endTime);
    const options = [];

    const now = moment();

    for (
      let date = start.clone();
      date.isBefore(end) || date.isSame(end, "month");
      date.add(1, "month")
    ) {
      if (date.isSame(now, "month") || date.isAfter(now, "month")) {
        options.push({
          option: date.format("MMMM YYYY"),
          value: date.endOf("month").format("YYYY-MM-DD"),
        });
      }
    }

    return options;
  };

  useEffect(() => {
    if (targets.length === 0 || targets[targets.length - 1] === undefined)
      return;

    setAuctionGoals((prev) => {
      const targetId = targets[targets.length - 1];
      if (prev[targetId]) {
        return prev;
      }

      return {
        ...prev,
        [targetId]: {
          steps: [{ reward: "", target_value: { value: "", error: "" } }],
        },
      };
    });
  }, [targets]);

  useEffect(() => {
    const editAuctionGoals = {} as AuctionGoals;
    targetList?.map((target, index) => {
      editAuctionGoals[index + 1] = {
        ...target,
        end_time: { value: target?.end_time, error: "" },
        steps: target?.steps.map((step) => {
          return {
            ...step,
            target_value: { value: step?.target_value, error: "" },
          };
        }),
      };
    });

    setEditAuctionGoals(editAuctionGoals);
  }, [targetList]);

  const auctionId = params.id;

  const handleTargetCreate = (
    auctionGoal: FormAuctionGoal,
    influencerId: number,
  ) => {
    if (!auctionGoal.steps) return;
    const endTime = auctionGoal?.end_time?.value;

    const isOneMonthCampaign = board.one_month_campaign;

    const isRenewable = () => {
      if (isOneMonthCampaign) return;

      return auctionGoal.target_type === TargetTypes.monthly;
    };

    const targetType = isOneMonthCampaign
      ? TargetTypes.one_time
      : TargetTypes.monthly;

    return postMoiAuctionAuction_idTargetsCreate(Number(auctionId), {
      auction_id: Number(auctionId),
      target_type: targetType,
      target_metric: auctionGoal.target_metric,
      renew: isRenewable(),
      influencer_id: influencerId,
      steps: auctionGoal.steps.map((step) =>
        step.reward === 0
          ? {
              target_value: step.target_value?.value,
              month_relevance:
                targetType === TargetTypes.monthly
                  ? boardReducer?.month
                  : undefined,
            }
          : {
              ...step,
              target_value: step.target_value?.value,
              month_relevance:
                targetType === TargetTypes.monthly
                  ? boardReducer?.month
                  : undefined,
            },
      ),
      end_time: targetType === TargetTypes.one_time ? endTime : undefined,
    });
  };

  const handleTargetUpdate = (
    goal: FormAuctionGoal & {
      add_steps?: TargetStep[];
      delete_steps?: number[];
      edit_steps?: TargetStep[];
    },
  ) => {
    const endTime = goal?.end_time?.value;
    const isOneMonthCampaign = board.one_month_campaign;
    if (!endTime && isOneMonthCampaign) return;

    const isRenewable = () => {
      if (isOneMonthCampaign) return false;
      return goal.target_type === TargetTypes.monthly;
    };

    const targetType = isOneMonthCampaign
      ? TargetTypes.one_time
      : TargetTypes.monthly;

    postMoiAuctionAuction_idTargetsUpdate(Number(auctionId), {
      auction_id: Number(auctionId),
      target_type: targetType,
      target_metric: goal.target_metric,
      renew: isRenewable(),
      id: goal.id,
      add_steps: goal.add_steps,
      delete_steps: goal.delete_steps,
      edit_steps: goal.edit_steps,
      end_time: targetType === TargetTypes.one_time ? endTime : undefined,
    });
  };

  const handleTargetChange = (
    targetId: number,
    stepIndex: number,
    reward?: number,
    targetValue?: FormValue<number>,
    targetType?: string,
    repeatType?: string,
    dueDate?: FormValue<string>,
    isEdit?: boolean,
    renew?: boolean,
  ) => {
    if (!targetId || stepIndex < 0) return;

    (isEdit ? setEditAuctionGoals : setAuctionGoals)((prev) => {
      const updatedSteps = prev[targetId]?.steps || [];

      const newSteps = updatedSteps.map((step, index) =>
        index === stepIndex
          ? { ...step, reward, target_value: targetValue }
          : step,
      );

      if (!updatedSteps[stepIndex]) {
        newSteps[stepIndex] = { reward, target_value: targetValue };
      }

      return {
        ...prev,
        [targetId]: targetType
          ? {
              ...prev[targetId],
              target_metric: targetType,
              target_type: repeatType,
              end_time: { value: dueDate.value, error: dueDate.error },
              renew,
            }
          : {
              ...prev[targetId],
              steps: newSteps,
            },
      };
    });
  };

  const handleDeleteSteps = (
    clearAll: boolean,
    stepIndex: number | null,
    target: number,
    idEdit?: boolean,
  ) => {
    (idEdit ? setEditAuctionGoals : setAuctionGoals)((prev) => {
      let filteredSteps = [];

      if (clearAll) {
        filteredSteps = prev[target]?.steps?.slice(0, 1);
      } else {
        filteredSteps = prev[target]?.steps?.filter(
          (_step, index) => index !== stepIndex - 1,
        );
      }

      return {
        ...prev,
        [target]: { ...prev[target], steps: filteredSteps },
      };
    });
  };

  const handleAddTarget = () => {
    setTargets((prev) => [...prev, (prev[Number(prev?.length - 1)] || 0) + 1]);
  };

  const handleDeleteTarget = (
    e: MouseEvent<HTMLButtonElement>,
    target: number,
    targetId?: number,
    isEdit?: boolean,
  ) => {
    e.stopPropagation();
    // ask for confirmation
    if (targetId && !window.confirm("Are you sure you want to delete this?"))
      return;

    const deleteTarget = () => {
      isEdit &&
        setIsViewTargetEditing((prev) => prev.filter((t) => t !== target - 1));
      !isEdit && setTargets((prev) => prev.filter((el) => el !== target));
      (isEdit ? setEditAuctionGoals : setAuctionGoals)((prev) => {
        const newGoalSteps = { ...prev };
        delete newGoalSteps[target];
        setIsLoading(false);
        return newGoalSteps;
      });
    };

    setIsLoading(true);
    if (targetId) {
      deleteMoiAuctionAuction_idTargetsDelete(Number(auctionId), {
        target_id: targetId,
      }).then((res) => {
        closeModal();
        return res && deleteTarget();
      });
    } else {
      deleteTarget();
    }
  };

  const checkErrors = (goals: FormAuctionGoal[]) => {
    const hasErrors = goals.some((target) => {
      const stepsHaveErrors = target.steps?.some((step, stepIndex) => {
        const currentStepValue = step.target_value?.value;
        const previousStepValue =
          stepIndex > 0
            ? target.steps[stepIndex - 1].target_value.value
            : undefined;

        return (
          currentStepValue === 0 ||
          !currentStepValue ||
          (previousStepValue && previousStepValue >= currentStepValue)
        );
      });

      const dateHasErrors =
        !target.end_time?.value &&
        target.target_type === TargetTypes.one_time &&
        !(board.summary.pixel_enabled || !board.one_month_campaign);

      return stepsHaveErrors || dateHasErrors;
    });

    return hasErrors;
  };

  const checkGoalsChanges = (goals: FormAuctionGoal[]) => {
    if (targetList && goals) {
      const updates: {
        addedSteps: TargetStep[];
        deletedSteps: number[];
        updatedSteps: TargetStep[];
        renew: boolean | null;
        end_time?: FormValue<string>;
      }[] = [];

      goals?.forEach((goal, index) => {
        const targetListSteps = targetList[index]?.steps || [];
        const goalStepIds = goal.steps.map((step) => step.id);

        if (!updates[index]) {
          updates[index] = {
            addedSteps: [],
            deletedSteps: [],
            updatedSteps: [],
            renew: null,
            end_time: undefined,
          };
        }
        if (goal.end_time?.value !== targetList[index].end_time) {
          updates[index].end_time = goal.end_time;
        }

        if (
          goal.renew !== targetList[index].renew &&
          targetList[index].renew !== null
        ) {
          updates[index].renew = goal.renew;
        }

        const filteredSteps = targetListSteps.filter(
          (listStep) => !goalStepIds.includes(listStep.id),
        );

        filteredSteps.forEach((filteredStep) => {
          updates[index].deletedSteps.push(filteredStep.id);
        });

        goal.steps.forEach((step, inx) => {
          const targetListStep = targetListSteps[inx];
          if (!step.id) {
            updates[index].addedSteps.push({
              ...step,
              target_value: step.target_value.value,
              month_relevance: moment(board.current_manage_month).format(
                "YYYY-MM-01",
              ),
            });
          }

          if (
            targetListStep &&
            targetListStep.id !==
              updates[index].deletedSteps[
                updates[index].deletedSteps.length - 1
              ] &&
            (step.target_value.value !== targetListStep.target_value ||
              step.reward !== targetListStep.reward)
          ) {
            updates[index].updatedSteps.push({
              ...step,
              target_value: step.target_value.value,
              reward: step.reward,
              month_relevance: moment(board.current_manage_month).format(
                "YYYY-MM-01",
              ),
            });
          }
        });
      });

      return updates;
    }
  };

  const handleSaveAllChanges = () => {
    const newGoals = Object.values(auctionGoals);
    const editedGoals = Object.values(editAuctionGoals);
    const allGoals = [
      ...editedGoals.map((x, index) => ({ ...x, index })),
      ...newGoals.map((x, index) => ({ ...x, index })),
    ];
    if (checkErrors(allGoals)) {
      allGoals?.forEach((target, _index) => {
        const isEditing = target.id ? true : false;
        target.steps?.map((step, stepIndex) => {
          const currentStepValue = step.target_value?.value;
          const previousStepValue =
            stepIndex > 0
              ? target.steps[stepIndex - 1].target_value.value
              : undefined;

          let error;

          if (step.target_value?.value === 0 || !step.target_value?.value) {
            error = "Kpi can't be empty";
          } else if (previousStepValue >= currentStepValue) {
            error = "KPI should exceed the last step";
          }

          return handleTargetChange(
            target.index + 1,
            stepIndex,
            step.reward,
            {
              value: step.target_value?.value,
              error,
            },
            undefined,
            undefined,
            undefined,
            isEditing,
          );
        });

        const emptyDate =
          !target.end_time?.value &&
          target.target_type === TargetTypes.one_time;
        emptyDate &&
          handleTargetChange(
            target.index + 1,
            0,
            undefined,
            undefined,
            target.target_metric,
            target.target_type,
            { value: target.end_time?.value, error: "Needs to set due date" },
            isEditing,
          );
      });
    } else {
      const editGoalsExecute: any = [];
      const newGoalsExecute = [];
      if (editedGoals?.length) {
        const updates = checkGoalsChanges(editedGoals);
        editedGoals?.forEach((goal, index) => {
          if (
            !updates[index].addedSteps.length &&
            !updates[index].updatedSteps.length &&
            !updates[index].deletedSteps.length &&
            updates[index].renew === null &&
            updates[index].end_time === undefined
          ) {
            return;
          }

          editGoalsExecute.push({
            ...goal,
            add_steps: updates[index].addedSteps,
            edit_steps: updates[index].updatedSteps,
            delete_steps: updates[index].deletedSteps,
            renew: updates[index].renew,
          });
        });
      }
      if (newGoals?.length && newGoals[0].steps) {
        Object.values(newGoals).forEach((goal) => newGoalsExecute.push(goal));
      }
      if (newGoalsExecute.length || editGoalsExecute.length) {
        setIsLoading(true);
        Promise.all([
          ...editGoalsExecute.map((goal: any) => handleTargetUpdate(goal)),
          ...Object.values(newGoals).map((goal) =>
            handleTargetCreate(goal, influencer.influencer_id),
          ),
        ])
          .then(() => {
            toast.success("Targets saved successfully");
          })
          .catch(() => {
            toast.error("Failed to save targets");
          })
          .finally(() => {
            closeModal();
          });
      }
    }
  };

  const closeModal = () => {
    setAuctionGoals({});
    setEditAuctionGoals({});
    setTargets([]);
    setIsViewTargetEditing([]);
    setIsOpen(false);
    const timer = setTimeout(() => {
      dispatch(
        getAuctionGoalsData(
          auctionId,
          undefined,
          board?.one_month_campaign
            ? undefined
            : selectedMonth || board.current_manage_month,
        ),
      );
      clearTimeout(timer);
    }, 100);
  };

  const boardReducer = useSelector((state: RootState) => state.boardReducer);

  const influencerTargets =
    (boardReducer?.board_targets?.influencers?.[
      influencer?.influencer_id
    ] as Target[]) || [];

  const canAddTargets = useMemo(() => {
    if (loading || isPastMonth || isCampaignEnded) return false;
    if (!Object.keys(auctionGoals)?.length && !influencerTargets?.length)
      return true;
    const isCurrentMonth = moment(boardReducer?.month).isSame(
      moment(),
      "month",
    );
    if (!board.one_month_campaign && !isCurrentMonth) return false;
    let targetsOptionsNumber = Object.keys(TargetMetrics).length;
    // shold not see sales value if pixel is not enabled or it is not a recurring campaign
    if (!board?.summary?.pixel_enabled || board.one_month_campaign) {
      targetsOptionsNumber--;
    }
    // Should not see clicks if short link is not enabled
    if (!board?.summary?.short_link) {
      targetsOptionsNumber--;
    }
    const currentSelectedTargetsNumber =
      Number(
        Object.values(auctionGoals)?.filter((a: any) => !!a?.target_metric)
          ?.length,
      ) + Number(influencerTargets?.length);
    return currentSelectedTargetsNumber < targetsOptionsNumber;
  }, [
    board?.summary?.pixel_enabled,
    board.one_month_campaign,
    board?.summary?.short_link,
    auctionGoals,
    influencerTargets,
    boardReducer?.month,
    editAuctionGoals,
    loading,
  ]);

  return (
    influencer && (
      <Dialog
        style={{
          width: "826px",
        }}
        animation="slide-fade"
        title={
          <Box className="target-modal__header">
            <Avatar
              src={influencer.picture_url}
              className="target-modal-header__avatar"
            />
            <Box sx={{ marginRight: "33px" }}>
              <Box as="p" className="target-modal-header__name">
                {influencer.name}
              </Box>
              {!board.one_month_campaign && (
                <Box as="p" className="target-modal-header__month">
                  {`${moment(selectedMonth).format("MMMM YYYY")} Targets`}
                </Box>
              )}
            </Box>
            {board.one_month_campaign || isCreate ? (
              <Box className="target-modal-header__info">
                <Box
                  className="fa-solid fa-circle-exclamation"
                  sx={{ color: "#249FF0" }}
                />
                <Box as="p" className="target-modal-header-info__text">
                  After saving, the new KPI goals you’ve set will be sent to the
                  influencer
                </Box>
              </Box>
            ) : (
              <CampaignMonthSelector
                months={board.available_months}
                allMonths={true}
                onChange={handleMonthChange}
              />
            )}
          </Box>
        }
        closeIcon={
          <Box
            as="div"
            className="fa-solid fa-xmark target-modal__close-icon"
          />
        }
        visible={isOpen}
        onClose={closeModal}
      >
        <Box
          sx={{
            minHeight: "527px",
            padding: "9px",
            display: "grid",
            gridTemplateRows:
              !isPastMonth && isCampaignEnded
                ? "auto minmax(100px, 1fr) 38px"
                : "auto minmax(20px, 1fr) 38px",
          }}
        >
          <Box className="target-modal__targets">
            <Box className="target-modal__title">
              <Box className="fa-solid fa-bullseye-arrow target-modal__target-icon" />
              <Box as="p" className="target-modal__text">
                Influencer KPI targets
              </Box>
            </Box>
            <>
              {targetList &&
                Object.values(editAuctionGoals)?.map((target, index) => {
                  const targetNumber = Number(
                    Object.keys(editAuctionGoals)[index],
                  );
                  return isViewTargetEditing.includes(index) ? (
                    <Box className={`${loading ? "disabled" : null}`}>
                      <CampaignTargetAccordion
                        key={target.id}
                        targetNumber={targetNumber}
                        handleDeleteTarget={(e) =>
                          handleDeleteTarget(e, targetNumber, target.id, true)
                        }
                        handleTargetChange={handleTargetChange}
                        handleDeleteSteps={handleDeleteSteps}
                        auctionGoals={editAuctionGoals || {}}
                        isRecurringCampaign={
                          board?.summary?.pixel_enabled ||
                          !board.one_month_campaign
                        }
                        isView
                        currency={board.summary?.currency}
                        board={board}
                        monthPickerOptions={monthPickerOptions()}
                      />
                    </Box>
                  ) : (
                    <CampaignViewTarget
                      key={target.id}
                      title={getTargetHeader(target.target_metric)}
                      headerIcon={getTargetHeader(target.target_metric, true)}
                      target={target}
                      handleStartEditing={() => {
                        setIsViewTargetEditing((prev) => [...prev, index]);
                      }}
                      currency={board.summary?.currency}
                    />
                  );
                })}
              {targets.map((target) => {
                return (
                  <CampaignTargetAccordion
                    key={target}
                    targetNumber={target}
                    handleDeleteTarget={(e) =>
                      handleDeleteTarget(e, target, undefined, false)
                    }
                    handleTargetChange={handleTargetChange}
                    handleDeleteSteps={handleDeleteSteps}
                    auctionGoals={auctionGoals}
                    isRecurringCampaign={
                      board?.summary?.pixel_enabled || !board.one_month_campaign
                    }
                    currency={board.summary?.currency}
                    board={board}
                    existingTargetsLength={
                      Object.values(editAuctionGoals).length
                    }
                    monthPickerOptions={monthPickerOptions()}
                    editTargets={
                      targetList ? Object.values(editAuctionGoals) : null
                    }
                    influencerId={influencer.influencer_id}
                  />
                );
              })}
            </>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateRows:
                !isPastMonth && isCampaignEnded ? "minmax(100px, 1fr)" : "none",
              height: "auto",
            }}
          >
            {!loading && (
              <Box
                className="target-modal__targets-container"
                flexDirection={"column"}
                gap={isPastMonth || isCampaignEnded ? 15 : 0}
              >
                <Button
                  className="target-modal__add-button cancel"
                  onClick={handleAddTarget}
                  isDisabled={!canAddTargets}
                >
                  <Box
                    className="fa-solid fa-bullseye-arrow"
                    sx={{ color: "#249FF0", fontSize: "20px" }}
                  />
                  <Box as="p" className="target-modal-add-button__text">
                    Add Target
                  </Box>
                </Button>
                <Box opacity={0.8} fontSize={12}>
                  {isPastMonth && !isCampaignEnded ? (
                    <p>New targets can only be added for the current month.</p>
                  ) : null}
                  {isCampaignEnded ? (
                    <p>New targets can only be added in active campaigns.</p>
                  ) : null}
                </Box>
              </Box>
            )}
            {loading ? (
              <Flex
                m={6}
                height={"100%"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Spinner />
              </Flex>
            ) : null}
          </Box>
          {
            <Box
              className={`target-modal__buttons ${loading ? "disabled" : null}`}
            >
              <Button
                className="target-modal__cancel-button cancel"
                onClick={closeModal}
                isDisabled={loading}
              >
                <Text>Cancel</Text>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  handleSaveAllChanges();
                }}
                isLoading={loading}
                isDisabled={
                  isCampaignEnded ||
                  isPastMonth ||
                  (!targetList && targets.length === 0) ||
                  (targetList &&
                    Object.values(editAuctionGoals).length === 0 &&
                    targets.length === 0)
                }
                leftIcon={<Box className="fa-solid fa-floppy-disk" />}
              >
                <Text>Save all changes</Text>
              </Button>
            </Box>
          }
        </Box>
      </Dialog>
    )
  );
};

export default CampaignTargetsModal;
