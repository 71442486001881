import { useRef, useState, useEffect, RefObject } from "react";

// type useState<S = undefined>() = [S | undefined, Dispatch<SetStateAction<S | undefined>>];

export const useBoolean: (s: boolean) => [
  boolean,
  {
    set: (s: boolean) => void;
    toggle: () => void;
    on: () => void;
    off: () => void;
  },
] = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);

  const updateValue = useRef({
    set(newValue: boolean) {
      setValue(newValue);
    },
    toggle() {
      setValue((oldValue) => !oldValue);
    },
    on() {
      setValue(true);
    },
    off() {
      setValue(false);
    },
  });

  return [value, updateValue.current];
};

type WindowOptions = {
  enabled?: boolean;
};

export const useWindowEventListener = <KW extends keyof WindowEventMap>(
  eventName: KW,
  handler: (event: WindowEventMap[KW]) => void,
  { enabled = true }: WindowOptions = {},
) => {
  // Create a ref that stores handler
  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    // Define the listening target
    const targetElement = window;
    if (!(targetElement && enabled && targetElement.addEventListener)) {
      return () => {};
    }

    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener: typeof handler = (event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event);
      }
    };

    targetElement.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, enabled, handler]);
};

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
  {
    mouseEvent = "mousedown",
    ...windowOptions
  }: WindowOptions & { mouseEvent?: "mousedown" | "mouseup" } = {},
) => {
  useWindowEventListener(
    mouseEvent,
    (event) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    },
    windowOptions,
  );
};

export const useDebounce = <T>(
  debouncedValue: T,
  setDebouncedValue: (newValue: T) => void,
  delay?: number,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(debouncedValue);

  useEffect(() => {
    setValue(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, setDebouncedValue, delay]);

  return [value, setValue];
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
