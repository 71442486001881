import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { Moment } from "moment";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RequestError } from "src/api/config";
// eslint-disable-next-line import-access/jsdoc
import { useGetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id } from "src/api/hooks";
// eslint-disable-next-line import-access/jsdoc
import { useBoolean } from "src/hooks/coreHooks";

export const useFbPosts = (
  {
    influencerId,
    since,
    until,
  }: {
    influencerId: number;
    since: Moment;
    until: Moment;
  },
  options?: { enabled?: boolean },
) => {
  const params = useParams();
  const [isRefreshing, setIsRefreshing] = useBoolean(false);
  const auctionId = Number(params.id);

  const { key } =
    useGetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id.info(
      influencerId,
      auctionId,
      {
        since: since.toISOString(),
        until: until.toISOString(),
      },
    );

  const res = useInfiniteQuery({
    queryKey: key,
    queryFn: ({ pageParam }) => {
      const ret = useGetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id
        .info(influencerId, auctionId, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          refresh: isRefreshing,
          since: since.toISOString(),
          until: until.toISOString(),
          last_seen: pageParam ? String(pageParam) : null,
        })
        .fun();
      return ret;
    },
    initialPageParam: 0,
    enabled: options?.enabled ?? true,
    getNextPageParam: (lastPage: any) =>
      lastPage.data.length === 0
        ? undefined
        : lastPage.data[lastPage.data.length - 1].media_creation_time,
  });

  const queryClient = useQueryClient();

  const refresh = useCallback(() => {
    setIsRefreshing.on();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    queryClient.removeQueries;
  }, [queryClient, setIsRefreshing]);

  useEffect(() => {
    if (isRefreshing && res.data) {
      setIsRefreshing.off();
    }
  }, [isRefreshing, res.data, setIsRefreshing]);

  return {
    ...res,
    isMissingPermissions:
      (res.error as RequestError | undefined)?.status === 412,
    data: res.data?.pages.map((x) => x.data).flat(),
    refresh,
    isRefreshing,
  };
};
