/* eslint-disable no-nested-ternary */
import { Box, Flex, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { useMemo } from "react";
import Infinite from "react-infinite";
import { useSelector } from "react-redux";
import { DAYS_EXPIRE_BID } from "src/actions/commonActionsConf";
import {
  AuctionQuestionWithAnswer,
  InfluencerInManageAuctionData,
} from "src/api/types";
import NoDataMessage from "../../general_components/NoDataMessage";
import { CampaignInfluencerLine } from "./CampaignInfluencerRow";
import { manageColumns } from "./CampaignManagerInterfaces";

export const CampaignManagerInfluencers = (props: {
  column: any;
  selectedColumn: any;
  openAlarmDialog: any;
  openChatsAndContent: any;
  influencersSelected: any;
  sendToTrash: any;
  share: any;
  showCost: any;
  negotiate: any;
  setNegotiate: any;
  waitingNotes: any;
  setShowPaymentsDialog?: any;
  negotiateInfluencerBid: any;
  selectInfluencer: any;
  toggleInviteMenu?: any;
  setEditTasksData: any;
  openInfluencerSegmentations: any;
  getInfluencerData: any;
  acceptInfluencerBid: any;
  changeInfluencerNoteOnBoard: any;
  responseForInfluencerCounterOffer: any;
  updateBoardDetails: any;
  board: any;
  user: any;
  influencerRating: any;
  setOpenAttachLinks: any;
  questions: any;
  updateColumns: any;
  nextMonthMode: boolean;
  setSelectedBid?: any;
  useWindowAsScrollContainer: any;
  lastMonth: boolean;
  openTargetsModal: any;
  month: string;
}) => {
  const skeleton = () => (
    <Box padding="6" borderRadius={6} boxShadow="lg" bg="white" mb={"10px"}>
      <Flex>
        <SkeletonCircle mr={20} size="20" />
        <SkeletonText
          flex={1}
          mt="4"
          noOfLines={3}
          spacing="4"
          skeletonHeight="2"
        />
      </Flex>
    </Box>
  );

  const unreadChats: any = useSelector((store) => store.inboxReducer.new_chats);
  const boardUnreadChats = unreadChats
    ? unreadChats[props?.board?.board_id]
    : undefined;

  const influencers = props.column?.influencers;
  return useMemo(() => {
    const renderQuestionsToolTip = (
      influQuestions: AuctionQuestionWithAnswer[],
    ) => {
      return (
        <>
          <div className="edit-tags-popup-title">
            <div />
            <span>Questions and Answers</span>
            <div />
            {/* <div className="far fa-times" onClick={() => this.setState({ editableTag: {} })} /> */}
          </div>
          <div className="questions-popup-inner">
            {influQuestions?.map((q: any, idx: number) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  marginBottom: 15,
                  marginLeft: 5,
                  borderBottom: "1px solid #ccc",
                  paddingBottom: 5,
                }}
              >
                <div style={{ marginRight: 3 }} className="task-num-circle">
                  {idx + 1}
                </div>
                <div style={{ width: 320 }}>
                  <div className="q-title">
                    <strong className="label-title">{q.question}</strong>
                  </div>
                  <div className="q-answer">{q.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </>
      );
    };

    return (
      <div className="campaign-influencers">
        <Infinite
          elementHeight={120}
          className={`campaign-influencers-table-container ${
            props.nextMonthMode ? "plan-next-month-influencers" : null
          }`}
          useWindowAsScrollContainer={props.useWindowAsScrollContainer}
          containerHeight={
            props.useWindowAsScrollContainer
              ? undefined
              : window.innerHeight - 400
          }
          timeScrollStateLastsForAfterUserScrolls={0}
          onInfiniteLoad={() => {}}
          isInfiniteLoading={false}
        >
          {props.column ? (
            influencers?.length ? (
              influencers.map(
                (inf: InfluencerInManageAuctionData, idx: number) => (
                  <CampaignInfluencerLine
                    DAYS_EXPIRE_BID={DAYS_EXPIRE_BID}
                    key={`${inf.influencer_id}_${idx}`}
                    influencer={inf}
                    selectedColumn={props.selectedColumn}
                    openAlarmDialog={props.openAlarmDialog}
                    openChatsAndContent={props.openChatsAndContent}
                    renderQuestionsToolTip={renderQuestionsToolTip}
                    selectedInfluencers={props.influencersSelected}
                    sendToTrash={props.sendToTrash}
                    share={props.share}
                    showCost={props.showCost}
                    negotiate={props.negotiate}
                    setNegotiate={props.setNegotiate}
                    waitingNotes={props.waitingNotes}
                    setShowPaymentsDialog={props.setShowPaymentsDialog}
                    negotiateInfluencerBid={props.negotiateInfluencerBid}
                    selectInfluencer={props.selectInfluencer}
                    toggleInviteMenu={props.toggleInviteMenu}
                    setEditTasksData={props.setEditTasksData}
                    setOpenAttachLinks={props.setOpenAttachLinks}
                    openInfluencerSegmentations={
                      props.openInfluencerSegmentations
                    }
                    getInfluencerData={props.getInfluencerData}
                    acceptInfluencerBid={props.acceptInfluencerBid}
                    changeInfluencerNoteOnBoard={
                      props.changeInfluencerNoteOnBoard
                    }
                    updateBoardDetails={props.updateBoardDetails}
                    board={props.board}
                    user={props.user}
                    influencerRating={props.influencerRating}
                    updateColumns={props.updateColumns}
                    responseForInfluencerCounterOffer={
                      props.responseForInfluencerCounterOffer
                    }
                    unreadChatsInfluencers={boardUnreadChats}
                    nextMonthMode={props.nextMonthMode}
                    setSelectedBid={props.setSelectedBid}
                    lastMonth={props.lastMonth}
                    openTargetsModal={props.openTargetsModal}
                    month={props.month}
                  />
                ),
              )
            ) : (
              <>
                <NoDataMessage showIcon={true}>
                  No influencers in {manageColumns[props.selectedColumn].label}
                </NoDataMessage>
              </>
            )
          ) : (
            [0, 1, 2, 3, 4].map((a: any) => (
              <div key={a} className="influencer">
                {skeleton()}
              </div>
            ))
          )}
        </Infinite>
      </div>
    );
  }, [
    influencers,
    props.acceptInfluencerBid,
    props.board,
    props.changeInfluencerNoteOnBoard,
    props.column,
    props.getInfluencerData,
    props.influencerRating,
    props.influencersSelected,
    props.negotiate,
    props.negotiateInfluencerBid,
    props.openAlarmDialog,
    props.openChatsAndContent,
    props.openInfluencerSegmentations,
    props.selectInfluencer,
    props.selectedColumn,
    props.sendToTrash,
    props.setEditTasksData,
    props.setNegotiate,
    props.setOpenAttachLinks,
    props.setShowPaymentsDialog,
    props.share,
    props.showCost,
    props.toggleInviteMenu,
    props.updateBoardDetails,
    props.user,
    props.waitingNotes,
    props.updateColumns,
    props.responseForInfluencerCounterOffer,
    props.nextMonthMode,
    props.setSelectedBid,
    props.useWindowAsScrollContainer,
    boardUnreadChats,
    props.month,
  ]);
};
