// Bug with axios on CI, import AxiosRequestConfig
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from "axios";
import { toast } from "react-toastify";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";

const academyBaseUrl = () => `${getBackendUri()}/academy`;
export class AcademyActions {
  static getCourses =
    (last_seen_time: string, filter: string, callback: any) =>
    (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .get(
          `${academyBaseUrl()}/courses?last_seen_time=${last_seen_time}&filter=${filter}`,
          {
            withCredentials: true,
            headers: {
              "X-MoiBackendAuthorization": getSavedJwtToken() ?? false,
            },
          },
        )
        .then((response) => {
          dispatch({ type: "ACADEMY_GET_COURSES", payload: response.data });
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
          if (callback) callback(response);
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to get courses",
            "system_notifications_failure_get_courses",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static getCourseTranslation =
    (translation_id: number, callback: any) => (dispatch: any) => {
      if (!translation_id) return;
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .get(`${academyBaseUrl()}/courses/${translation_id}`, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          if (response.data) response.data.id = Number(translation_id);
          dispatch({ type: "GET_COURSE_TRANSLATION", payload: response.data });
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
          if (callback) {
            callback(response);
          }
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to get course ",
            "system_notifications_failure_get_courses",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static subsribeToCourseTranslation =
    (translation_id: number, data: any, callback: any) => (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .post(`${academyBaseUrl()}/courses/${translation_id}`, data, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          if (response.data) response.data.id = Number(translation_id);
          // dispatch({type: "GET_COURSE_TRANSLATION", payload: response.data});
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
          if (callback) {
            callback(response);
          }
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to get course ",
            "system_notifications_failure_get_courses",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static getLesson =
    (chapter_id: number, lesson_ordinal_num: number, callback: any) =>
    (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .get(
          `${academyBaseUrl()}/chapters/${chapter_id}/lesson/${lesson_ordinal_num}`,
          {
            withCredentials: true,
            headers: {
              "X-MoiBackendAuthorization": getSavedJwtToken() ?? false,
            },
          },
        )
        .then((response) => {
          if (callback) callback(response);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to get lesson",
            "system_notifications_failure_get_lesson",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static completeLesson =
    (chapter_id: number, lesson_ordinal_num: number, callback: any) =>
    (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .put(
          `${academyBaseUrl()}/chapters/${chapter_id}/lesson/${lesson_ordinal_num}`,
          {},
          {
            withCredentials: true,
            headers: {
              "X-MoiBackendAuthorization": getSavedJwtToken() ?? false,
            },
          },
        )
        .then((response) => {
          if (callback) callback(response);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to complete lesson",
            "system_notifications_failure_get_lesson",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static saveLesson =
    (
      chapter_id: number,
      lesson_ordinal_num: number,
      data: any,
      callback: any,
    ) =>
    (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .post(
          `${academyBaseUrl()}/chapters/${chapter_id}/lesson/${lesson_ordinal_num}`,
          data,
          {
            withCredentials: true,
            headers: {
              "X-MoiBackendAuthorization": getSavedJwtToken() ?? false,
            },
          },
        )
        .then((response) => {
          if (callback) callback(response);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to get lesson",
            "system_notifications_failure_get_lesson",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static getQuizQuestions =
    (quiz_id: number, callback: any) => (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .get(`${academyBaseUrl()}/quiz/${quiz_id}`, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback) callback(response);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to get quiz",
            "system_notifications_failure_get_quiz",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };

  static sendQuiz =
    (quiz_id: number, data: any, callback: any) => (dispatch: any) => {
      dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
      axios
        .post(`${academyBaseUrl()}/quiz/${quiz_id}`, data, {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() ?? false },
        })
        .then((response) => {
          if (callback) callback(response.data);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        })
        .catch((err) => {
          console.log(err);
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to submit quiz",
            "system_notifications_failure_get_quiz",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        });
    };
}
