/* eslint-disable no-param-reassign */
import Tooltip from "rc-tooltip";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addBoardTag,
  deleteTagInBoard,
  editTagInBoard,
} from "src/actions/auctionsActions";
import {
  InfluencerInManageAuctionData,
  ManageAuctionData,
} from "src/api/types";
import uuidv4 from "uuid/v4";
import { tagColors } from "../../utilities/general";
import { CampaignTagExtended } from "./CampaignManagerInterfaces";

interface OwnProps {
  board: ManageAuctionData;
  influencer?: InfluencerInManageAuctionData;
  selectedInfluencers: any[];
  boardTags: CampaignTagExtended[];
  influencerId: number;
  onUpdate: any;
}

interface EditableTag {
  temp_id?: string;
  influencer_id?: number;
  tag?: CampaignTagExtended;
  edit?: boolean;
}

export const CampaignInfluencerTags = ({
  board,
  selectedInfluencers,
  boardTags,
  influencerId,
  onUpdate,
}: OwnProps) => {
  const dispatch = useDispatch();
  const [editableTag, setEditableTag] = useState<EditableTag>({});
  const [tagInputOpen, setTagInputOpen] = useState<boolean>(false);
  let tags = boardTags;

  const onUpdateTags = () => {
    dispatch({ type: "BOARD_TAGS_UPDATED", payload: tags });
    onUpdate();
  };

  const onEditTagToggle = (id: number) => {
    if (editableTag.influencer_id === id) {
      setTagInputOpen(false);
      setEditableTag({});
    } else {
      const newEditableTag = { ...editableTag };
      newEditableTag.influencer_id = id;
      setEditableTag(newEditableTag);
    }
  };

  const addNewTag = () => {
    const tagE: CampaignTagExtended = editableTag.tag;
    const tmptag = { ...editableTag };
    tagE.temp_id = uuidv4();

    if (boardTags) boardTags.push(tagE);
    else tags = [tagE];

    addBoardTag(board.summary.id, tagE, (id: number) => {
      if (id) {
        tags.find((x) => x.temp_id === tagE.temp_id).id = id;
      }
    });
    tmptag.tag = {};
    setTagInputOpen(false);
    setEditableTag(tmptag);
    onUpdateTags();
  };

  const deleteTag = (tagId: number) => {
    tags.splice(
      tags.findIndex((x) => x.id === tagId),
      1,
    );
    setTagInputOpen(false);
    deleteTagInBoard(board.summary.id, tagId, () => {
      boardTags = tags;
      onUpdateTags();
    });
  };

  const editTag = () => {
    const { tag } = editableTag;
    if (!tag) return;
    const index = tags.findIndex((x) => x.id === tag.id);
    if (index > -1) {
      tags[index] = tag;
    }
    editTagInBoard(board.summary.id, tag.id, tag, () => {
      setTagInputOpen(false);
      setEditableTag({});
      onUpdateTags();
    });
  };

  const handleKeyPress = (e: any) => {
    if (e.nativeEvent.keyCode === 13) {
      addNewTag();
    }
  };

  const renderCreateOrEditTag = () => {
    return (
      editableTag.tag && (
        <>
          <input
            className="new-tag-input"
            defaultValue={editableTag.tag?.label}
            onChange={(e) => {
              const tmpTag = { ...editableTag };
              tmpTag.tag.label = e?.target?.value?.trim()?.substring(0, 49);
              setEditableTag(tmpTag);
            }}
            onKeyPress={handleKeyPress}
          />
          <div className="tag-color-pick">
            {tagColors.map((x) => (
              <div
                key={x}
                style={{ background: x }}
                onClick={() => {
                  const tmpTag = { ...editableTag };
                  tmpTag.tag.color = x;
                  setEditableTag(tmpTag);
                }}
              >
                {editableTag.tag?.color === x && <i className="fas fa-check" />}
              </div>
            ))}
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              className="btn btn-primary add-tag-btn"
              onClick={() => (editableTag.edit ? editTag() : addNewTag())}
            >
              {editableTag.edit ? "Save" : "Create"}
            </button>
            {editableTag.edit && (
              <button
                className="btn btn-primary add-tag-btn"
                style={{ background: "#cf513d", marginLeft: 50 }}
                onClick={() => deleteTag(editableTag.tag.id)}
              >
                Delete
              </button>
            )}
          </div>
        </>
      )
    );
  };
  const addOrRemoveInfluencerInTag = (tagId: number) => {
    const tag = boardTags.find((x) => x.id === tagId);
    if (!tag) return;
    if (influencerId === -1) {
      if (tag.influencers && tag.influencers.length > 0) {
        tag.influencers = [];
      } else {
        tag.influencers = selectedInfluencers;
      }
    } else if (tag.influencers) tag.influencers.addOrRemove(influencerId);
    else tag.influencers = [influencerId];
    editTagInBoard(board.summary.id, tagId, tag, () => {});
    onUpdateTags();
    setEditableTag({ tag });
  };

  const renderTagsPopup = () => {
    return (
      <>
        <div className="edit-tags-popup-title">
          {editableTag && tagInputOpen ? (
            <div
              className="far fa-arrow-left"
              onClick={() => {
                setEditableTag({ ...editableTag });
                setTagInputOpen(false);
                onUpdateTags();
              }}
            />
          ) : (
            <div />
          )}
          <span>Tags</span>
          <div
            className="far fa-times"
            onClick={() => {
              setEditableTag({ tag: {} });
              onUpdateTags();
            }}
          />
        </div>

        <div style={{ padding: "5px 10px", width: 280 }}>
          {tagInputOpen ? (
            renderCreateOrEditTag()
          ) : (
            <>
              {boardTags.map((x) => {
                const check = x.influencers.includes(influencerId);
                return (
                  <div className="inf-tags-container" key={x.temp_id || x.id}>
                    <div
                      style={{ backgroundColor: x.color }}
                      onClick={() => addOrRemoveInfluencerInTag(x.id)}
                    >
                      <div title={x.label} className="campaign-tag" dir="auto">
                        {x.label}
                      </div>
                      {check && <div className="fas fa-check" />}
                    </div>
                    <div
                      className="fal fa-pen"
                      onClick={() => {
                        setEditableTag({
                          ...editableTag,
                          tag: { ...x },
                          edit: true,
                        });
                        setTagInputOpen(true);
                        onUpdateTags();
                      }}
                    />
                  </div>
                );
              })}

              <div
                className="new-tag-button"
                onClick={() => {
                  setTagInputOpen(true);
                  setEditableTag({
                    ...editableTag,
                    tag: {
                      label: "",
                      color: tagColors[0],
                      influencers:
                        influencerId === -1
                          ? selectedInfluencers
                          : [influencerId],
                    },
                    edit: false,
                  });
                  onUpdateTags();
                }}
              >
                Add new tag
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {" "}
      <Tooltip
        visible={editableTag.influencer_id === -1}
        onVisibleChange={() => onEditTagToggle(-1)}
        trigger={["click"]}
        placement="top"
        overlayClassName={"edit-popup"}
        overlay={renderTagsPopup()}
      >
        <div className="tags-icon fa fa-tags" aria-label="Tags" />
      </Tooltip>
    </>
  );
};
