import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const TabsTheme: ComponentMultiStyleConfig = {
  parts: ["root", "tab", "tablist", "tabpanels", "tabpanel"],
  baseStyle: {
    root: {
      display: "flex",
      flex: "1 1",
      flexDir: "column",
    },
    tab: {
      display: "flex",
      flexDir: "column",
      backgroundColor: "transparent",
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      _focus: {
        boxShadow: "none",
      },
    },
    tablist: {},
    tabpanels: {
      display: "flex",
      overflow: "hidden",
    },
    tabpanel: {
      display: "flex",
      flexDir: "column",
      flex: 1,
      _hidden: {
        display: "none",
      },
    },
  },
};
