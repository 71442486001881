import "rc-tabs/assets/index.css";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";
import { connect } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { getListSegmentation } from "../../../actions/premiumActions";
import { getListSegmentationDataShared } from "../../../actions/shareActions";
// Css imports
import "../../../css/influencer-segmentations.css";
import { InstagramListSocialSummary } from "../../../models/Api";
import { DottedMap } from "../DottedMap";
import {
  formatBigNumbers,
  month,
  numberWithCommas,
} from "../utilities/general";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
    label,
    fontSize,
    data,
  } = props;
  let length = value > 50 ? 100 - value : value;
  length += 30;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 25) * cos;
  const my = cy + (outerRadius + length) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 18;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={"white"}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 15}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        style={{
          fontFamily: "openSansHebrewBold",
          fontSize: fontSize === "small" ? "13px" : "12px",
        }}
        textAnchor={textAnchor}
        fill={fill}
      >{`${label}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        style={{
          fontSize: fontSize === "small" ? 12 : 17,
          fontFamily: "openSansHebrewBold",
        }}
        textAnchor={textAnchor}
        fill={fill}
      >
        {`${data ? formatBigNumbers(data) : `${value.toFixed(2)}%`}`}
      </text>
    </g>
  );
};

const InstagramCubes: React.FC<{
  data: InstagramListSocialSummary["video_views"];
}> = (props) => {
  const { data } = props;

  return (
    <div style={{ paddingBottom: 10 }}>
      <div className="lists-cubes video">
        <div
          className="list-cube"
          style={{
            marginLeft: -5,
            backgroundColor: "#17a086",
            backgroundImage: 'url("/images/influencers.png")',
          }}
        >
          <strong>
            Avg Feed video views <br />
            (influencers {data?.feed?.amount_based || 0})
          </strong>
          <div>{formatBigNumbers(data?.feed?.avg_views || 0)}</div>
        </div>
        <div
          className="list-cube"
          style={{
            backgroundColor: "#e28c37",
            backgroundImage: 'url("/images/impressions.png")',
          }}
        >
          <strong>
            Avg IGTV views <br />
            (influencers {data?.igtv?.amount_based || 0})
          </strong>
          <div>{formatBigNumbers(data?.igtv?.avg_views || 0)}</div>
        </div>
        <div
          className="list-cube"
          style={{
            backgroundColor: "#e05d6f",
            backgroundImage: 'url("/images/engagement.png")',
            backgroundPosition: "left bottom",
          }}
        >
          <strong>
            Avg Reels views
            <br />
            (influencers {data?.reels?.amount_based || 0})
          </strong>
          <div>{formatBigNumbers(data?.reels?.avg_views || 0)}</div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  listReducer: any;
  listId: any;
  shared: boolean;
  sharedParameters: any;
  getListSegmentationDataShared: any;
  getListSegmentationData: any;
  loaders: any;
  cubesToDisplay: string[];
  campaign: boolean;
  toggleEditKpis(): void;
  onShowMore(show: boolean): void;
}

type ViewMode = "kpis" | "data" | "ig_views";

const SummaryCubeRow = ({
  network,
  valueProperty,
  basedOnProperty,
  icon,
  currency,
  format = formatBigNumbers,
}: {
  network: any;
  valueProperty: any;
  basedOnProperty?: any;
  icon:
    | "fa-instagram"
    | "fa-twitter"
    | "moi-tik-tok"
    | "fa-youtube-play"
    | "moi-stories"
    | "moi-reels";
  currency?: string;
  format?: typeof numberWithCommas | typeof formatBigNumbers;
}) => {
  const value: any = (network && network[valueProperty]) || 0;
  return value ? (
    <div className="list-cube-value" style={{ display: "flex" }}>
      <div className={`fa ${icon}`} style={{ marginTop: 3 }} />
      {currency || ""}
      {format(value)}{" "}
      {basedOnProperty && network[basedOnProperty] && (
        <Tooltip
          overlay={`Based on ${network[basedOnProperty]} instagram accounts from the list`}
          placement="top"
        >
          <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
            ({network[basedOnProperty]}
            <span
              style={{ fontSize: 10, marginLeft: 2, marginRight: 0 }}
              className="fas fa-user"
            ></span>
            )
          </div>
        </Tooltip>
      )}
    </div>
  ) : null;
};

class ListSummaryDataInner extends React.Component<Props> {
  INIT_VIEWS = {
    gender: "pie",
    ages: "pie",
    ages_gender: "all",
  };

  state: any = {
    selectedSocial: "",
    loaded: false,
    id: -1,
    instagram: null,
    twitter: null,
    youtube: null,
    tiktok: null,
    views: this.INIT_VIEWS,
    showPosts: {
      instagram: true,
      twitter: true,
      youtube: true,
    },
    loadingSeg: false,
    marksCenter: null,
    viewMode: "kpis" as ViewMode,
  };

  componentDidMount() {
    if (this.props.listReducer.list && this.props.listId) {
      if (this.props.listReducer.list.segmentation) {
        this.attachData(this.props.listReducer.list.segmentation);
      } else {
        this.getListSeg();
      }
    }
    if (
      this.props.sharedParameters?.shared_data?.includes("views") &&
      this.props.sharedParameters?.shared_data?.length === 1
    ) {
      this.setState({ viewMode: "ig_views" });
    }
  }

  getListSeg = () => {
    this.setState({ loadingSeg: true });
    if (this.props.shared) {
      if (this.props.sharedParameters.show_segementation) {
        this.props.getListSegmentationDataShared((data: any) => {
          this.attachData(data);
        });
      }
    } else {
      this.props.getListSegmentationData(this.props.listId, (data: any) => {
        this.attachData(data);
      });
    }
  };

  currencyLabel() {
    const { list } = this.props.listReducer;
    if (!list) {
      return "";
    }
    return `money_symbol_${list.list_summary_data.currency}`;
  }

  // Frequencies cubes
  getFrequenceisData(selectedSocial: any) {
    if (
      !this.props.loaders.frequencies &&
      this.props.listReducer.list.frequencies &&
      this.props.listReducer.list.frequencies[selectedSocial]
    ) {
      const data = this.props.listReducer.list.frequencies[selectedSocial];
      return {
        unique_followers: data.unique_followers,
        data: [
          { value: (data.once / data.unique_followers) * 100, fill: "#91cff7" },
          {
            value: (data.twice / data.unique_followers) * 100,
            fill: "#c9a070",
          },
          {
            value: (data.three_times_or_more / data.unique_followers) * 100,
            fill: "#3baea8",
          },
        ],
      };
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getCenter(locations: any) {
    let latTmp = 0;
    let lonTmp = 0;
    for (let i = locations.length - 1; i >= 0; i -= 1) {
      latTmp += locations[i].lat;
      lonTmp += locations[i].lng;
    }
    const avrgLat = latTmp / locations.length;
    const avrgLon = lonTmp / locations.length;
    return { lat: avrgLat, lng: avrgLon };
  }

  // eslint-disable-next-line class-methods-use-this
  addDataToAggMonths(agg: any, provider: any, monthFn: any) {
    if (monthFn) {
      if (!agg.posts) {
        // eslint-disable-next-line no-param-reassign
        agg.posts = monthFn.posts ? monthFn.posts : 0;
        // eslint-disable-next-line no-param-reassign
        agg.views = monthFn.views
          ? [{ views: monthFn.views, posts: monthFn.posts }]
          : [];
        // eslint-disable-next-line no-param-reassign
        agg.likes = monthFn.posts ? monthFn.likes * monthFn.posts : 0;
        // eslint-disable-next-line no-param-reassign
        agg.retweets = monthFn.posts ? monthFn.retweets * monthFn.posts : 0;
        // eslint-disable-next-line no-param-reassign
        agg.total = monthFn.posts ? monthFn.total * monthFn.posts : 0;
        // eslint-disable-next-line no-param-reassign
        agg.comments = monthFn.posts ? monthFn.comments * monthFn.posts : 0;
      } else {
        // eslint-disable-next-line no-param-reassign
        agg.posts += monthFn.posts ? monthFn.posts : 0;
        // eslint-disable-next-line no-param-reassign
        agg.likes += monthFn.posts ? monthFn.likes * monthFn.posts : 0;
        // eslint-disable-next-line no-param-reassign
        agg.retweets += monthFn.posts ? monthFn.retweets * monthFn.posts : 0;
        // eslint-disable-next-line no-param-reassign
        agg.total += monthFn.posts ? monthFn.total * monthFn.posts : 0;
        // eslint-disable-next-line no-param-reassign
        agg.comments += monthFn.posts ? monthFn.comments * monthFn.posts : 0;
        if (monthFn.views)
          agg.views.push({ views: monthFn.views, posts: monthFn.posts });
      }
    } else {
      agg.push({
        label: "Avg. Likes",
        name: `Posts ${agg.posts}`,
        data: (agg.likes / agg.posts).toFixed(2),
        value: (agg.likes / agg.total) * 100,
        fill: "#2c639f",
      });
      if (provider === "twitter") {
        agg.push({
          label: "Avg. Retweets",
          name: "",
          data: (agg.retweets / agg.posts).toFixed(2),
          value: (agg.retweets / agg.total) * 100,
          fill: "rgb(94, 159, 214)",
        });
      } else {
        agg.push({
          label: "Avg. Comments",
          name: "",
          data: (agg.comments / agg.posts).toFixed(2),
          value: (agg.comments / agg.total) * 100,
          fill: "rgb(94, 159, 214)",
        });
      }
      if (agg.views && agg.views.length > 0) {
        const mviews = agg.views.reduce((a: any, b: any) => {
          return a + b.views * b.posts;
        }, 0);
        const mposts = agg.views.reduce((a: any, b: any) => {
          return a + b.posts;
        }, 0);
        // eslint-disable-next-line no-param-reassign
        agg.views = mviews / mposts;
      } else {
        // eslint-disable-next-line no-param-reassign
        agg.views = 0;
      }
    }
    return agg;
  }

  addSegmentation = (
    data: any,
    providerData: any,
    provider: any,
    postsData: any,
  ) => {
    // eslint-disable-next-line no-param-reassign
    providerData.segmentation = this.presentSegmentation(data);
    // eslint-disable-next-line no-param-reassign
    if (data.general_data) providerData.general_data = data.general_data;
    if (postsData && postsData.length > 0) {
      // eslint-disable-next-line no-param-reassign
      providerData.posts = postsData;
    }
    // eslint-disable-next-line no-param-reassign
    providerData.additonalAudienceCategories =
      data.additonal_audience_categories;
    // eslint-disable-next-line react/no-direct-mutation-state
    (this.state as any)[provider] = providerData;
  };

  addDataToInfluencer = (data: any, postsData: any, provider: any) => {
    if (!data) return;
    const providerData = {};
    this.setState({ selectedSocial: provider }, () => {
      this.addSegmentation(data, providerData, provider, postsData);
      this.addActivity(data, providerData, provider);
      this.setState({});
    });
  };

  addActivity = (data: any, providerData: any, provider: any) => {
    const monthsData: { [key: number]: any } = { 1: [], 3: [], 6: [] };
    const influencerActivityData = data.activity_data;
    let selectedMonth = "";
    let center: any = this.state.marksCenter;
    if (
      influencerActivityData !== null &&
      influencerActivityData !== undefined
    ) {
      const marks =
        influencerActivityData.locations === null ||
        influencerActivityData.locations === undefined
          ? []
          : influencerActivityData.locations.map((l: any, index: any) => ({
              id: index,
              lat: l.lat,
              lng: l.lon,
            }));
      if (marks.length > 0) center = this.getCenter(marks);
      const tags =
        // eslint-disable-next-line no-nested-ternary
        influencerActivityData.top_tags === null ||
        influencerActivityData.top_tags === undefined
          ? []
          : influencerActivityData.top_tags.length < 1
          ? []
          : influencerActivityData.top_tags;

      let monthlyActivity = influencerActivityData.monthly_activity;
      if (monthlyActivity !== null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        monthlyActivity = monthlyActivity.sort(function (a: any, b: any) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return new Date(b.day) - new Date(a.day);
        });

        const labels = monthlyActivity.map(
          (m: any) => month[new Date(m.day).getMonth()],
        );

        const likes = monthlyActivity.map((m: any) => m.likes);
        const comments = monthlyActivity.map((m: any) => m.comments);
        const posts = monthlyActivity.map((m: any) => m.posts);
        const retweets = monthlyActivity.map((m: any) => m.retweets);
        const views = monthlyActivity.map((m: any) => m.views);
        let i;

        for (i = 0; i < labels.length; i += 1) {
          const monthData: any = [];
          const total =
            (likes[i] || 0) + (comments[i] || 0) + (retweets[i] || 0);
          monthData.label = labels[i] || 0;
          monthData.posts = posts[i] || 0;
          monthData.views = views[i] || 0;
          monthData.likes = likes[i] || 0;
          monthData.comments = comments[i] || 0;
          monthData.retweets = retweets[i] || 0;
          monthData.total = total;

          monthData.push({
            label: "Avg. Likes",
            name: `Posts ${posts[i]}`,
            data: likes[i],
            value: (likes[i] / total) * 100,
            fill: "#2c639f",
          });
          if (provider === "twitter") {
            monthData.push({
              label: "Avg. Retweets",
              name: "",
              data: retweets[i],
              value: (retweets[i] / total) * 100,
              fill: "rgb(94, 159, 214)",
            });
          } else {
            monthData.push({
              label: "Avg. Comments",
              name: "",
              data: comments[i],
              value: (comments[i] / total) * 100,
              fill: "rgb(94, 159, 214)",
            });
          }

          if (i < 3) {
            monthsData[3] = this.addDataToAggMonths(
              monthsData[3],
              provider,
              monthData,
            );
          }

          monthsData[6] = this.addDataToAggMonths(
            monthsData[6],
            provider,
            monthData,
          );

          monthsData[1].push(monthData);
        }

        if (monthsData[3].posts) {
          monthsData[3] = this.addDataToAggMonths(
            monthsData[3],
            provider,
            null,
          );
        }

        if (monthsData[6].posts) {
          monthsData[6] = this.addDataToAggMonths(
            monthsData[6],
            provider,
            null,
          );
        }

        // eslint-disable-next-line prefer-destructuring
        selectedMonth = labels[0];
      }

      // eslint-disable-next-line no-param-reassign
      providerData.activity_data = {
        marksLocations: marks,
        marksCenter: center,
        topTags: tags,
        monthsData,
        selectedMonth,
        selectedPostsTimes: 3,
      };
    }
  };

  attachData = (data: any) => {
    if (data) {
      let postsData: any = {
        instagram: [],
        twitter: [],
        youtube: [],
        tiktok: [],
      };
      if (data.posts) {
        postsData = data.posts;
      }
      if (data.segmentation) {
        // eslint-disable-next-line no-param-reassign
        data = data.segmentation;
      }
      const stateToChange = {
        idFromCallback: data.influencer_id,
        loadingSeg: true,
        selectedSocial: "",
      };
      this.setState(stateToChange, () => {
        // Last here will be first to be selected if has data
        this.addDataToInfluencer(data.youtube, postsData.youtube, "youtube");
        this.addDataToInfluencer(data.twitter, postsData.twitter, "twitter");
        this.addDataToInfluencer(data.tiktok, postsData.tiktok, "tiktok");
        this.addDataToInfluencer(
          data.instagram,
          postsData.instagram,
          "instagram",
        );
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  presentSegmentation = (data: any) => {
    const followersSegmentation = data.segmentation;
    const gendersData = [];
    let followersCountries = [];
    let followersCategories = [];
    const followersAges: { all: any[]; females: any[]; males: any[] } = {
      all: [],
      females: [],
      males: [],
    };
    let fraudPercentagesPieData: any = [];
    const segmentationStatus = data.segmentation_status || null;

    const colors = ["#3476bd", "#c9ad36", "#17a086", "#e05d6f", "white"];

    if (!followersSegmentation) {
      return undefined;
    }

    if (followersSegmentation.gender_segmentation) {
      gendersData.push({
        label: window.translate("", "followers_male_label"),
        name: "",
        value: followersSegmentation.gender_segmentation.male,
        fill: colors[1],
        fontSize: "small",
      });
      gendersData.push({
        name: "",
        label: window.translate("", "followers_female_label"),
        value: followersSegmentation.gender_segmentation.female,
        fill: colors[3],
        fontSize: "small",
      });
    }
    const countries = followersSegmentation.countries_segmentation;
    if (countries) {
      followersCountries = countries.map((c: any) => {
        return { name: c.eng_name.replace(" ", "_"), data: c.percentage };
      });
      followersCountries = followersCountries.sort(function (a: any, b: any) {
        return b.data - a.data;
      });
    }
    const categories = followersSegmentation.top_categories;
    if (categories) {
      followersCategories = categories.map((c: any) => {
        return { name: c.category_name, data: c.percentage };
      });
    }
    const ages = followersSegmentation.age_segmentation;
    if (ages) {
      followersAges.all.push({
        label: window.translate("", "children_label"),
        name: "",
        value: ages.children,
        fill: colors[0],
        fontSize: "small",
      });
      followersAges.all.push({
        label: window.translate("", "teens_label"),
        name: "",
        value: ages.teens,
        fill: colors[1],
        fontSize: "small",
      });
      followersAges.all.push({
        label: window.translate("", "adults_label"),
        name: "",
        value: ages.adults,
        fill: colors[2],
        fontSize: "small",
      });
      followersAges.all.push({
        label: window.translate("", "elderly_label"),
        name: "",
        value: ages.elderly,
        fill: colors[3],
        fontSize: "small",
      });
    }
    const ageByGender = followersSegmentation.age_by_gender_segmentation;
    if (ageByGender) {
      const { females } = ageByGender;
      followersAges.females.push({
        label: window.translate("", "children_label"),
        value: females.children,
        fill: colors[0],
        fontSize: "small",
      });
      followersAges.females.push({
        label: window.translate("", "teens_label"),
        value: females.teens,
        fill: colors[1],
        fontSize: "small",
      });
      followersAges.females.push({
        label: window.translate("", "adults_label"),
        value: females.adults,
        fill: colors[2],
        fontSize: "small",
      });
      followersAges.females.push({
        label: window.translate("", "elderly_label"),
        value: females.elderly,
        fill: colors[3],
        fontSize: "small",
      });
      const { males } = ageByGender;
      followersAges.males.push({
        label: window.translate("", "children_label"),
        value: males.children,
        fill: colors[0],
        fontSize: "small",
      });
      followersAges.males.push({
        label: window.translate("", "teens_label"),
        value: males.teens,
        fill: colors[1],
        fontSize: "small",
      });
      followersAges.males.push({
        label: window.translate("", "adults_label"),
        value: males.adults,
        fill: colors[2],
        fontSize: "small",
      });
      followersAges.males.push({
        label: window.translate("", "elderly_label"),
        value: males.elderly,
        fill: colors[3],
        fontSize: "small",
      });
    }
    const fraudPercentages = followersSegmentation.fraud_percentage;
    if (fraudPercentages) {
      fraudPercentagesPieData = [
        {
          label: "Suspicious",
          value: fraudPercentages,
          fill: "var(--main-baby-blue-color)",
        },
        {
          name: "Organic",
          label: "Organic",
          value: 100 - fraudPercentages,
          fill: "#ececec",
        },
      ];
      fraudPercentagesPieData.fraud_percentage = fraudPercentages;
    }
    const fraudExamples = followersSegmentation.fraud_examples
      ? followersSegmentation.fraud_examples
      : [];
    return {
      gendersData,
      followersAges,
      followersCountries,
      fraudPercentagesPieData,
      followersCategories,
      fraudExamples,
      followersDataReturned: true,
      segmentation_status: segmentationStatus,
    };
  };

  renderKPIS() {
    const data = this.props.listReducer.list.list_summary_data;
    const social =
      this.props.shared &&
      this.props.sharedParameters?.select_social_networks?.length > 0
        ? this.props.sharedParameters.select_social_networks
        : ["all"];
    let instagram;
    let twitter;
    let youtube;
    let tiktok;

    if (social.includes("all") || social.includes("instagram")) {
      instagram = data.instagram;
    }
    if (social.includes("all") || social.includes("twitter")) {
      twitter = data.twitter;
    }
    if (social.includes("all") || social.includes("youtube")) {
      youtube = data.youtube;
    }
    if (social.includes("all") || social.includes("tiktok")) {
      tiktok = data.tiktok;
    }

    return (
      <div style={{ paddingBottom: 10 }}>
        <div className="lists-cubes">
          {this.props.cubesToDisplay.indexOf("influencers") > -1 && (
            <div
              className="list-cube"
              style={{
                marginLeft: -5,
                backgroundColor: "#17a086",
                backgroundImage: 'url("/images/influencers.png")',
              }}
            >
              <strong>
                Influencers
                <br />
                (Total {data.influencers_count})
              </strong>
              {instagram && (
                <div className="list-cube-value">
                  <div className="fa fa-instagram" />
                  {instagram.influencers_count}
                </div>
              )}
              {twitter && (
                <div className="list-cube-value">
                  <div className="fa fa-twitter" />
                  {twitter.influencers_count}
                </div>
              )}
              {youtube && (
                <div className="list-cube-value">
                  <div className="fa fa-youtube-play" />
                  {youtube.influencers_count}
                </div>
              )}
              {tiktok && (
                <div className="list-cube-value">
                  <div className="fa moi-tik-tok" />
                  {tiktok.influencers_count}
                </div>
              )}
            </div>
          )}
          {this.props.cubesToDisplay.indexOf("followers") > -1 && (
            <div
              className="list-cube"
              style={{
                backgroundColor: "#3476bd",
                backgroundImage: 'url("/images/followers.png")',
              }}
            >
              <strong>Followers</strong>
              {instagram && (
                <div className="list-cube-value">
                  <div className="fa fa-instagram" />
                  {formatBigNumbers(instagram.sum_followers)}
                </div>
              )}
              {twitter && (
                <div className="list-cube-value">
                  <div className="fa fa-twitter" />
                  {formatBigNumbers(twitter.sum_followers)}
                </div>
              )}
              {youtube && (
                <div className="list-cube-value">
                  <div className="fa fa-youtube-play" />
                  {formatBigNumbers(youtube.sum_followers)}
                </div>
              )}
              {tiktok && (
                <div className="list-cube-value">
                  <div className="fa moi-tik-tok" />
                  {formatBigNumbers(tiktok.sum_followers)}
                </div>
              )}
            </div>
          )}
          {this.props.cubesToDisplay.indexOf("impressions") > -1 && (
            <div
              className="list-cube"
              style={{
                backgroundColor: "#e28c37",
                backgroundImage: 'url("/images/impressions.png")',
              }}
            >
              <strong>Estimated Impressions</strong>
              <SummaryCubeRow
                network={instagram}
                icon={"fa-instagram"}
                valueProperty={"sum_impressions"}
                basedOnProperty={"impressions_based_on"}
              />
              <SummaryCubeRow
                network={instagram?.video_views?.reels}
                valueProperty={"avg_views"}
                basedOnProperty={"amount_based"}
                icon={"moi-reels"}
              />
              <SummaryCubeRow
                network={instagram?.estimated_story_views}
                valueProperty={"avg_views"}
                basedOnProperty={"amount_based"}
                icon={"moi-stories"}
              />
              <SummaryCubeRow
                network={twitter}
                icon={"fa-twitter"}
                valueProperty={"sum_impressions"}
              />
              <SummaryCubeRow
                icon="fa-youtube-play"
                network={youtube}
                valueProperty={"sum_impressions"}
              />
              <SummaryCubeRow
                icon="moi-tik-tok"
                network={tiktok}
                valueProperty={"sum_impressions"}
              />
            </div>
          )}

          {this.props.cubesToDisplay.indexOf("engagement") > -1 && (
            <div
              className="list-cube"
              style={{
                backgroundColor: "#e05d6f",
                backgroundImage: 'url("/images/engagement.png")',
                backgroundPosition: "left bottom",
              }}
            >
              <strong>Estimated Engagement</strong>
              <SummaryCubeRow
                network={instagram}
                icon={"fa-instagram"}
                valueProperty={"total_engagement"}
                basedOnProperty={"engagements_based_on"}
              />
              <SummaryCubeRow
                network={twitter}
                icon={"fa-twitter"}
                valueProperty={"total_engagement"}
              />
              <SummaryCubeRow
                network={youtube}
                icon={"fa-youtube-play"}
                valueProperty={"total_engagement"}
              />
              <SummaryCubeRow
                network={tiktok}
                icon={"moi-tik-tok"}
                valueProperty={"total_engagement"}
              />
            </div>
          )}
          {this.props.cubesToDisplay.indexOf("est_budget") > -1 && (
            <div className="list-cube" style={{ backgroundColor: "#3f4e61" }}>
              <strong>Estimated Budget</strong>
              {!!instagram?.total_est_price && (
                <div className="list-cube-value">
                  <div className="fa fa-instagram" />
                  {window.translate("", this.currencyLabel())}
                  {numberWithCommas(instagram.total_est_price)}
                </div>
              )}
              {!!twitter?.total_est_price && (
                <div className="list-cube-value">
                  <div className="fa fa-twitter" />
                  {window.translate("", this.currencyLabel())}
                  {numberWithCommas(twitter.total_est_price)}
                </div>
              )}
              {!!youtube?.total_est_price && (
                <div className="list-cube-value">
                  <div className="fa fa-youtube-play" />
                  {window.translate("", this.currencyLabel())}
                  {numberWithCommas(youtube.total_est_price)}
                </div>
              )}
              {!!tiktok?.total_est_price && (
                <div className="list-cube-value">
                  <div className="fa moi-tik-tok" />
                  {window.translate("", this.currencyLabel())}
                  {numberWithCommas(tiktok.total_est_price)}
                </div>
              )}
            </div>
          )}
          {this.props.cubesToDisplay.indexOf("cpe") > -1 && (
            <div className="list-cube" style={{ backgroundColor: "#c9ad36" }}>
              <strong>Cost per engagement</strong>
              <SummaryCubeRow
                network={instagram}
                icon={"fa-instagram"}
                valueProperty={"est_cpe"}
                currency={window.translate("", this.currencyLabel())}
                format={numberWithCommas}
              />
              <SummaryCubeRow
                network={twitter}
                icon={"fa-twitter"}
                valueProperty={"est_cpe"}
                currency={window.translate("", this.currencyLabel())}
                format={numberWithCommas}
              />
              <SummaryCubeRow
                network={youtube}
                icon={"fa-youtube-play"}
                valueProperty={"est_cpe"}
                currency={window.translate("", this.currencyLabel())}
                format={numberWithCommas}
              />
              <SummaryCubeRow
                network={tiktok}
                icon={"moi-tik-tok"}
                valueProperty={"est_cpe"}
                currency={window.translate("", this.currencyLabel())}
                format={numberWithCommas}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  onMouseMove = (evt: any) => {
    const aContainer = document.createElement("div");
    aContainer.setAttribute("id", "dot-map-tooltip");
    document.getElementById("root")?.appendChild(aContainer);

    const tooltip = document.getElementById("dot-map-tooltip");
    let text: any;
    text = evt.target.id ? evt.target.id : evt.target.parentElement.id;
    if (!evt.target.parentElement.classList.contains("selected-country"))
      return;
    const data = (this.state as any)[
      this.state.selectedSocial
    ].segmentation.followersCountries.find((x: any) => x.name === text);

    text = text.replace("_", " ");
    if (tooltip) {
      // tooltip.innerHTML = text + " #" + ((this.state as any)[this.state.selectedSocial].segmentation.followersCountries.indexOf(data) + 1);
      tooltip.innerHTML = `${text} - ${data.data}%`;
      tooltip.style.display = "block";
      const minus = 0;
      tooltip.style.left = `${evt.clientX - minus}px`;
      tooltip.style.top = `${evt.clientY - 35}px`;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  onMouseOut = () => {
    const tooltip = document.getElementById("dot-map-tooltip");
    const root = document.getElementById("root");
    if (root && tooltip) {
      root?.removeChild(tooltip);
      tooltip.style.display = "none";
    }
  };

  renderSegmentationsData = () => {
    if (
      !(this.state as any)[this.state.selectedSocial] ||
      !(this.state as any)[this.state.selectedSocial].segmentation
    ) {
      return <div className="no-seg-data">We are working on that ...</div>;
    }
    const { views } = this.state;
    const {
      followersCountries,
      fraudPercentagesPieData,
      gendersData,
      followersAges,
    } = (this.state as any)[this.state.selectedSocial].segmentation;
    const isPartial =
      (this.state as any)[this.state.selectedSocial].segmentation &&
      (this.state as any)[this.state.selectedSocial].segmentation
        .segmentation_status &&
      (this.state as any)[this.state.selectedSocial].segmentation
        .segmentation_status.is_partial;
    const missingUsers =
      ((this.state as any)[this.state.selectedSocial].segmentation &&
        (this.state as any)[this.state.selectedSocial].segmentation
          .segmentation_status &&
        (this.state as any)[this.state.selectedSocial].segmentation
          .segmentation_status.number_of_users_missing) ||
      0;
    const totalUsers =
      ((this.state as any)[this.state.selectedSocial].segmentation &&
        (this.state as any)[this.state.selectedSocial].segmentation
          .segmentation_status &&
        (this.state as any)[this.state.selectedSocial].segmentation
          .segmentation_status.number_of_users) ||
      0;
    return (
      <>
        {isPartial && (
          <div className="warning-message">
            <i className="fas fa-exclamation-triangle" /> showing a partial list
            of audience data ({missingUsers}/{totalUsers} missing)
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          {gendersData && gendersData.length > 0 ? (
            <div className="segm-title">Gender Split</div>
          ) : (
            <div />
          )}
          {followersAges.all && followersAges.all.length > 0 ? (
            <div className="segm-title">Ages % </div>
          ) : (
            <div />
          )}
          {followersAges.all && followersAges.all.length > 0 ? (
            <div
              className="segm-switch"
              style={{ position: "initial", margin: 0 }}
            >
              <div
                className={` ${views.ages_gender === "all"}`}
                onClick={() => {
                  views.ages_gender = "all";
                  this.setState({ views });
                }}
              >
                All
              </div>
              <div
                className={`fa fa-male ${views.ages_gender === "males"}`}
                onClick={() => {
                  views.ages_gender = "males";
                  this.setState({ views });
                }}
              />
              <div
                className={`fa fa-female ${views.ages_gender === "females"}`}
                onClick={() => {
                  views.ages_gender = "females";
                  this.setState({ views });
                }}
              />
            </div>
          ) : (
            <div />
          )}
        </div>

        <div className="influ-segm-data" style={{ paddingBottom: 20 }}>
          {gendersData && gendersData.length > 0 && (
            <div>
              <div style={{ marginTop: -20 }}>
                <PieChart width={400} height={300}>
                  <Pie
                    startAngle={90}
                    endAngle={450}
                    activeIndex={[0, 1]}
                    activeShape={renderActiveShape}
                    data={gendersData}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={57}
                    outerRadius={57}
                    dataKey={"value"}
                  />
                </PieChart>
              </div>
            </div>
          )}

          {followersAges.all && followersAges.all.length > 0 ? (
            <div>
              <PieChart width={400} height={300}>
                <Pie
                  activeIndex={[0, 1, 2, 3]}
                  activeShape={renderActiveShape}
                  data={followersAges[views.ages_gender]}
                  cx={"50%"}
                  cy={"50%"}
                  minAngle={30}
                  innerRadius={57}
                  outerRadius={57}
                  dataKey={"value"}
                />
              </PieChart>
            </div>
          ) : null}

          <div className="seg-spacer" />

          {followersCountries.length > 0 ? (
            <div className="dotted-map-container">
              <div className="segm-title">Top Countries %</div>
              <DottedMap
                selected={followersCountries.map((x: any) => x.name)}
                onMouseMove={this.onMouseMove}
                onMouseOut={this.onMouseOut}
              />
            </div>
          ) : null}

          {fraudPercentagesPieData &&
          fraudPercentagesPieData.fraud_percentage ? (
            <div className="seg-fraud-donut" style={{ flex: 1 }}>
              <div className="segm-title">Suspicious followers</div>
              <ResponsiveContainer width={"100%"} height={200}>
                <PieChart width={800} height={400}>
                  <circle
                    cx={"50%"}
                    cy={"50%"}
                    r={"27%"}
                    fill={"white"}
                    stroke="none"
                  />
                  <text
                    className="donut-center-text"
                    x={"50%"}
                    y={80}
                    fill={"black"}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    {fraudPercentagesPieData.fraud_percentage}%
                  </text>
                  <text
                    className="donut-center-text-smaller"
                    x={"50%"}
                    y={110}
                    fill={"#b7b4b7"}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    Suspicious
                  </text>
                  <text
                    className="donut-center-text-smaller"
                    x={"50%"}
                    y={130}
                    fill={"#b7b4b7"}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    Followers
                  </text>

                  <Pie
                    activeIndex={[0, 1]}
                    data={fraudPercentagesPieData}
                    startAngle={90}
                    endAngle={450}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={"92%"}
                    outerRadius={"100%"}
                    fill="#ececec"
                    dataKey={"value"}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  renderSection() {
    switch (this.state.viewMode) {
      case "kpis":
        return <section className="list-section">{this.renderKPIS()}</section>;

      case "data":
        return (
          <section className="list-section">
            <div
              className="list-summary-title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <span>Followers data</span>
              <div style={{ display: "flex", color: "black" }}>
                {!!this.state.instagram && (
                  <div className="filter-social">
                    <div
                      style={{ height: 40, paddingTop: 8, cursor: "pointer" }}
                      className={`filter-social-cube ${
                        this.state.selectedSocial === "instagram"
                      }`}
                      onClick={() =>
                        this.setState({ selectedSocial: "instagram" })
                      }
                    >
                      <li className="fa fa-instagram" />
                    </div>
                  </div>
                )}
                {!!this.state.twitter && (
                  <div className="filter-social">
                    <div
                      style={{ height: 40, paddingTop: 8, cursor: "pointer" }}
                      className={`filter-social-cube ${
                        this.state.selectedSocial === "twitter"
                      }`}
                      onClick={() =>
                        this.setState({ selectedSocial: "twitter" })
                      }
                    >
                      <li className="fa fa-twitter" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {this.renderSegmentationsData()}
          </section>
        );
      case "ig_views":
        return (
          <section className="list-section">
            <InstagramCubes
              data={
                this.props.listReducer.list.list_summary_data?.instagram
                  ?.video_views
              }
            />
          </section>
        );
      default:
        break;
    }
    return null;
  }

  selectSection = (section: ViewMode) => {
    if (section === this.state.viewMode) {
      if (section === "kpis") {
        this.props.toggleEditKpis();
      } else {
        this.setState({ viewMode: "kpis" });
      }
    } else {
      this.setState({ viewMode: section });
    }
    if (section === "data") {
      this.props.onShowMore(this.state.viewMode !== "data");
    }
  };

  render() {
    return (
      <div className={"list-summary"}>
        {this.renderSection()}

        <div className="list-help-btns">
          {(!this.props.shared && !this.props.campaign) ||
          this.state.viewMode !== "kpis" ? (
            <Tooltip
              placement="right"
              trigger={["hover"]}
              overlay={
                <strong>
                  {this.state.viewMode === "kpis" ? "Edit Kpis" : "Kpis"}
                </strong>
              }
            >
              <button
                className="btn btn-primary"
                onClick={() => this.selectSection("kpis")}
              >
                <i
                  className={`${
                    this.state.viewMode === "kpis"
                      ? "fas fa-pen"
                      : "fas fa-chevron-left"
                  }`}
                ></i>
              </button>
            </Tooltip>
          ) : null}
          {(this.props.shared &&
            !this.props.sharedParameters?.shared_data?.includes("views")) ||
          !this.props.listReducer?.list?.list_summary_data?.instagram
            ?.video_views ? null : (
            <Tooltip
              placement="right"
              trigger={["hover"]}
              overlay={<strong>Instagram video views</strong>}
            >
              <button
                className={`btn btn-primary ${
                  this.state.viewMode === "ig_views"
                }`}
                onClick={() => this.selectSection("ig_views")}
              >
                <i className="moi-insteye"></i>
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    listReducer: state.listsReducer,
    loaders: state.loadersReducer,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getListSegmentationData: (listId: any, callback: any) => {
      return dispatch(getListSegmentation(listId, callback));
    },
    getListSegmentationDataShared: (callback: any) => {
      return dispatch(getListSegmentationDataShared(callback));
    },
  };
};

export const ListSummaryData = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true,
  },
)(ListSummaryDataInner);
