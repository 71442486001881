import { useGoogleLogin } from "@react-oauth/google";
import GoogleImage from "./btn_google_signin_dark_normal_web.png";

export const GoogleButton = (props: {
  tokenCallback(token: string | false): void;
}) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => props.tokenCallback(tokenResponse.code),
    onError: () => props.tokenCallback(false),
    scope:
      "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly",
    flow: "auth-code",
    redirect_uri: `https://www.humanz.ai/app-redirect/google-signin`,
  });

  return (
    <div style={{ textAlign: "center" }}>
      <button
        onClick={() => login()}
        className="btn"
        style={{ background: "transparent" }}
      >
        <img src={GoogleImage} />
      </button>
    </div>
  );
};
