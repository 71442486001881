// TODO - lint
/* eslint-disable no-unused-vars, import/no-default-export */
import React from "react";
import { connect } from "react-redux";
import { MessageDialog } from "../../../dialogs/MessageDialog";

class RemoveAmbassadorDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log(this.props.influencer);
  }

  deleteInfluencer(influencer) {
    this.props.deleteInfluencers({
      instagram: influencer.instagram_username,
      tiktok: influencer.tiktok_username,
      youtube: influencer.youtube_username,
      twitter: influencer.twitter_username,
    });
    this.props.handleClose();
  }

  render() {
    return (
      <MessageDialog
        handleClose={() => {
          this.props.handleClose();
        }}
        icon="fas fa-user-circle"
      >
        <div style={{ textAlign: "center", padding: 20 }}>
          <strong>Delete Ambassador</strong>
          <p style={{ marginTop: 15 }}>
            You can always stop tracking an ambassador, please note that payment
            for the service is made until the end of the period even if you have
            chosen to stop tracking an ambassador in the middle of the period.
          </p>
          <div
            className="label-title"
            style={{ color: "gray", fontWeight: "bold", marginTop: 30 }}
          >
            ARE YOU SURE YOU WANT TO DELETE AN AMBASSADOR:
          </div>
          <div
            className="userName"
            style={{ textAlign: "left", color: "var(--main-baby-blue-color)" }}
          >
            @{this.props.influencer.name}
          </div>
          <div
            style={{ paddingBottom: 5, paddingTop: 10, textAlign: "center" }}
          >
            <button
              onClick={() => this.deleteInfluencer(this.props.influencer)}
              className="btn btn-primry"
              style={{
                background: "var(--main-baby-blue-color)",
                color: "white",
                borderRadius: "40px",
                width: 100,
                marginTop: 15,
              }}
            >
              DELETE
            </button>
          </div>
        </div>
      </MessageDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveAmbassadorDialog);
