import { Button, Checkbox, useCheckboxGroup } from "@chakra-ui/react";

export const CheckboxButton: typeof Checkbox = (props) => {
  const group = useCheckboxGroup();
  const isChecked =
    props.isChecked ||
    (group?.value != null &&
      props.value != null &&
      group.value.indexOf(props.value) !== -1);

  return (
    <Button variant={isChecked ? undefined : "outline"} as="label" m={0}>
      <Checkbox variant="inside-button" {...props} />
    </Button>
  );
};
