/* eslint-disable no-nested-ternary */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Alert, Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addUsersToLists } from "src/actions/premiumActions";
import { writeLog } from "src/actions/utilsActions";
import { postSimilarListInfluecersById } from "src/api/actions/influencersActions";
import { EmbeddingSearchType, InfluencerShallowData } from "src/api/types";
import { HumanzSideMenu } from "src/ui/humanz/HumanzSideMenu";
import { InfiniteLoader } from "../../general_components/InfiniteLoader";
import { LookalikeCard } from "../Lookalikes/LookalikeCard";
import { SmartText } from "../SmartText";

type Props = {
  listId: number;
  visible: boolean;
  setVisible: any;
  openAddToList: any;
  openInfluencer: any;
  refreshList: any;
};

const missingNotification = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        id="Vector"
        opacity="0.5"
        d="M33 15.75V18C33 25.0711 33 28.6066 30.8033 30.8033C28.6066 33 25.0711 33 18 33C10.9289 33 7.3934 33 5.1967 30.8033C3 28.6066 3 25.0711 3 18C3 10.9289 3 7.3934 5.1967 5.1967C7.3934 3 10.9289 3 18 3H20.25"
        stroke="#249FF0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        id="Vector_2"
        d="M10.5 21H24"
        stroke="#249FF0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        id="Vector_3"
        d="M10.5 26.25H19.5"
        stroke="#249FF0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        id="Vector_4"
        d="M33 3L25.5 10.5M25.5 2.99997L33 10.4999"
        stroke="#249FF0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </g>
  </svg>
);

export const ListSuggestions = ({
  listId,
  visible,
  setVisible,
  openInfluencer,
  refreshList,
}: Props) => {
  const [hasError, setHasError] = useState(null);
  const [influencers, setInfluencers] = useState<InfluencerShallowData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (visible) {
      postSimilarListInfluecersById(listId, {
        search_type: EmbeddingSearchType.all,
      })
        .then((res) => {
          let influs = [];
          if (res?.data) {
            if (
              res?.data?.by_ai?.error &&
              !res?.data?.by_ai?.influencers &&
              !res.data?.by_goes_with?.influencers
            ) {
              setHasError(res.data.by_ai.error);
            } else if (res.data?.by_ai?.influencers?.length) {
              influs = influs.concat(res.data?.by_ai?.influencers);
            }
            if (
              res.data?.by_goes_with?.error &&
              !res.data?.by_goes_with?.influencers &&
              !res?.data?.by_ai?.influencers
            ) {
              setHasError(res.data.by_goes_with.error);
            } else if (res.data?.by_goes_with?.influencers?.length) {
              influs = influs.concat(res.data.by_goes_with.influencers);
            }
            setInfluencers(influs);
            writeLog({
              message: "User requested list ai suggestions",
              event_name: "list_id",
              event_value_number: listId,
            });
          }
        })
        .catch(() => {
          writeLog({
            message: "Failed getting list ai suggestions",
            event_name: "list_id",
            log_level: "error",
            event_value_number: listId,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [listId, visible]);

  const addToListCallback = (id) => {
    const influs = influencers.filter(
      (influ) => Number(influ.id) !== Number(id),
    );
    console.log(influs);
    setInfluencers(influs);
    refreshList();
  };

  return (
    <HumanzSideMenu
      setVisible={setVisible}
      visible={visible}
      title={
        <Box>
          <Text color={"brand.500"} fontWeight="bold">
            Humanz Ai{" "}
            <span style={{ fontWeight: 400, color: "#333333" }}>
              Suggestions
            </span>
          </Text>
        </Box>
      }
    >
      <Box
        style={{
          overflowY: "auto",
          height: "80vh",
          paddingBottom: 50,
          paddingRight: 5,
          paddingLeft: 5,
        }}
      >
        {isLoading ? (
          <InfiniteLoader />
        ) : hasError ? (
          <Alert status="error">Error: {hasError}</Alert>
        ) : !isLoading && influencers?.length ? (
          influencers.map((influ) => (
            <Box
              style={{
                display: "flex",
                borderBottom: "1px solid #F3F3F3",
                paddingBottom: 5,
                paddingTop: 5,
              }}
              key={influ.id}
            >
              <Box style={{ flex: 2 }}>
                <LookalikeCard
                  openInfluencer={() => openInfluencer(influ.id)}
                  openAddToList={() => {
                    dispatch(
                      addUsersToLists(
                        [{ list_id: listId, influencers: [influ.id] }],
                        () => {
                          addToListCallback(influ.id);
                        },
                      ),
                    );
                  }}
                  variant="list"
                  influencer={influ}
                />
              </Box>
              <Box
                style={{
                  flex: 3,
                  overflow: "auto",
                  borderRadius: 12,
                  background: "#F5FBFF",
                  padding: "10px 15px",
                  border: "1px solid rgba(36, 159, 240, 0.60)",
                  marginLeft: 10,
                  marginTop: 5,
                  marginBottom: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: influ?.instagram_data?.text_description
                    ? "flex-start"
                    : "center",
                }}
              >
                <Box>
                  {influ?.instagram_data?.text_description ? (
                    <SmartText
                      social={"instagram"}
                      text={influ.instagram_data.text_description}
                      length={140}
                    />
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      {missingNotification}
                      <div style={{ color: "gray" }}>No information yet</div>
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Alert status="info">No influencers to show</Alert>
        )}
      </Box>
    </HumanzSideMenu>
  );
};
