import React from "react";
// import ReactMarkdown from "react-markdown";
// import raw from "./test2.html";
// import parse from "html-react-parser";

export const LicensesWebPage = () => {
  // const [data, setData] = React.useState("");
  React.useState(() => {
    fetch("/wl.html")
      .then((r) => r.text())
      .then((text) => {
        document.getElementById("data").innerHTML = text;
      });
  }, []);

  return (
    <div style={{ margin: 10 }}>
      <div style={{ textAlign: "center", marginBottom: 20 }}>
        <h2>Third Party Notices</h2>
        <h4 style={{ textTransform: "uppercase" }}>
          The following sets forth attribution notices for third party software
          that may be contained in portions of this Humanz Product
        </h4>
      </div>
      <div id="data"></div>
    </div>
  );
};
