import { Button } from "@chakra-ui/react";
import React from "react";
import { Trans, TranslationProps, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  getCampaignNotesSummary,
  getShareBoardDetails,
} from "../../../actions/shareActions";
import { getCountry } from "../../../actions/userActions";
import {
  InfluencerInManageAuctionData,
  ManageAuctionData,
  ManageAuctionState,
  ShareNotesSummary,
} from "../../../api/types";
import DefaultImage from "../../../images/svgs/placeholder.svg";
import { formatBigNumbers } from "../utilities/general";
import CampaignMonthSelector from "./CampaignManager/CampaignMonthSelector";
import CampaignPreviewDialog from "./dialogs/CampaignPreviewDialog";

interface OwnProps {
  id: string | number;
  showCosts: boolean;

  openInfluencerSegmentations: any;
  closeInfluencerSegmentations: any;
}

type Props = OwnProps & { navigate: NavigateFunction } & ReturnType<
    typeof mapStateToProps
  > &
  ReturnType<typeof mapDispatchToProps>;

type SocialNetworkName = "instagram" | "youtube" | "twitter" | "tiktok";

class CampaignInfluencersSharedInner extends React.Component<
  Props,
  any,
  TranslationProps
> {
  state: any = {
    boardId: -1,
    openProposals: -1,
    columns: [],
    loading: true,
    waitingNotes: {},
    selectedMonth: null,
  };

  columns: any = [
    { label: "bids", name: "bids" },
    { label: "invites", name: "invite" },
    { label: "approval", name: "approval" },
    { label: "publish", name: "publish" },
    { label: "trash", name: "trash" },
  ];

  componentDidMount() {
    const boardId = this.props.id;

    this.props.getShareBoardDetails(null, () => {
      this.setState({ loading: false, boardId });
    });
    const lang = sessionStorage.getItem("i18nextLng");
    const navLang = navigator.language;
    if (!lang) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getCountry((country) => {
        this.setState({ country });
        if (country === "il" && navLang !== "he") {
          sessionStorage.setItem("i18nextLng", "he");
          window.location.reload();
        }
      });
    }

    getCampaignNotesSummary((data: ShareNotesSummary) => {
      if (data?.type_and_notes_summary) {
        const waitingNotes: any = {};
        data.type_and_notes_summary.forEach((note) => {
          if (note?.notes_summary && note.note_type) {
            const ids = note.notes_summary
              .filter((x) => !x.seen)
              .map((x) => x.item_id);
            if (ids.length) {
              // eslint-disable-next-line radix
              const infId = parseInt(
                note.note_type.replace("campaign_content_approval_", ""),
              );
              waitingNotes[infId] = ids;
            }
          }
        });
        this.setState({ waitingNotes });
      }
    });
  }

  onMonthChange = (month: string) => {
    const boardId = this.props.id;

    this.setState({ selectedMonth: month, loading: true });
    this.props.getShareBoardDetails(month, () => {
      this.setState({ loading: false, boardId });
      this.props.updateCurrentMonth(month);
    });
  };

  handleClose = () => {
    this.setState({ openProposals: -1 });
  };

  getConcatColumn(data: ManageAuctionData, selectedColumn: number) {
    const column: any = { notifications: 0, influencers: [] };
    const columndata = data
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data.states[this.columns[selectedColumn].name]
      : null;
    if (columndata) {
      column.influencers = columndata.influencers;
    }
    if (selectedColumn === 2) {
      column.influencers = column.influencers.sort(function (
        a: InfluencerInManageAuctionData,
        b: InfluencerInManageAuctionData,
      ) {
        let tasksA = -1;
        if (a.tasks_details.tasks) {
          if (a.tasks_details.tasks.every((t) => t.completed)) {
            tasksA = -2;
          } else if (
            a.tasks_details.tasks.find(
              (x) => !x.completed && x.proposal && !x.proposal.shared_approved,
            )
          ) {
            tasksA = 2;
          } else if (
            a.tasks_details.tasks.find(
              (x) => !x.completed && x.proposal && !x.proposal.approved,
            )
          ) {
            tasksA = 1;
          } else if (
            a.tasks_details.tasks.find(
              (x) => x.completed || (x.proposal && x.proposal.approved),
            )
          ) {
            tasksA = 0;
          }
        }
        let tasksB = -1;
        if (b.tasks_details.tasks) {
          if (b.tasks_details.tasks.every((t) => t.completed)) {
            tasksB = -2;
          } else if (
            b.tasks_details.tasks.find(
              (x) => !x.completed && x.proposal && !x.proposal.shared_approved,
            )
          ) {
            tasksB = 2;
          } else if (
            b.tasks_details.tasks.find(
              (x) => !x.completed && x.proposal && !x.proposal.approved,
            )
          ) {
            tasksB = 1;
          } else if (
            b.tasks_details.tasks.find(
              (x) => x.completed || (x.proposal && x.proposal.approved),
            )
          ) {
            tasksB = 0;
          }
        }

        if (tasksA === tasksB) {
          if (a.name && b.name && a.name > b.name) {
            return 1;
          }
          return -1;
        }
        if (tasksB > tasksA) {
          return 1;
        }
        return -1;
      });
    }
    return column;
  }

  openChatsAndContent = (influencer: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.waitingNotes[influencer.influencer_id] = [];
    if (window.innerWidth < 800) {
      this.props.navigate(
        `${window.location.pathname}/${influencer.influencer_id}`,
      );
    } else {
      this.setState({ openProposals: influencer.influencer_id });
    }
  };

  render() {
    const data = this.props.board;
    const column = this.getConcatColumn(data, 2);

    return (
      <div>
        {this.state.openProposals > -1 && (
          <CampaignPreviewDialog
            waitingNotes={this.state.waitingNotes}
            newOnly={false}
            allowedUploadContent={false}
            columns={[
              this.getConcatColumn(data, 0),
              this.getConcatColumn(data, 1),
              this.getConcatColumn(data, 2),
              this.getConcatColumn(data, 3),
              this.getConcatColumn(data, 4),
            ]}
            updateColumns={() => {}}
            handleClose={this.handleClose}
            influencer_id={this.state.openProposals}
            isShowingModal={true}
            boardId={this.state.boardId}
            shareActive={this.props.board?.summary?.shared_campaign}
            share={true}
          />
        )}
        <div className="main-middle-container" style={{ paddingTop: 100 }}>
          {this.state.loading || !data ? (
            <div className="logo-loader">
              <div className="loader" />
              <img src={"/images/humanz-logo.png"} />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {this.renderCampaignRow(column)}
                {!data?.one_month_campaign && data.current_manage_month ? (
                  <div>
                    <CampaignMonthSelector
                      months={this.props.board?.available_months}
                      allMonths={true}
                      onChange={this.onMonthChange}
                    />
                  </div>
                ) : null}
              </div>
              <div className="share-influencer-container">
                {column.influencers?.map(this.renderInfluencerCube)}
                <div style={{ height: 60, width: 280 }} />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  renderCampaignRow(column: ManageAuctionState) {
    const data = this.props.board;
    const influencers = column.influencers ? column.influencers : [];
    return (
      <div className="campaign-details-container" style={{ width: "auto" }}>
        <img
          className="blurable"
          src={data.summary?.image ? data.summary.image : DefaultImage}
        />
        <div className="campaign-details-rows">
          <div className="campaign-details-row">
            <strong className="campaign-name blurable">
              {data.summary?.name}
            </strong>
          </div>

          <div className="campaign-details-rows">
            <div style={{ textAlign: "left", display: "inline-block" }}>
              <div
                style={{
                  fontSize: 18,
                  marginTop: 8,
                  marginBottom: 8,
                  textAlign: "center",
                }}
              >
                {formatBigNumbers(influencers.length)}
              </div>
              <div style={{ width: 70, textAlign: "center" }}>
                <strong className="list-influencer-details-desc">
                  <Trans>influencers</Trans>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderTagOnRow = (t: any, idx: any) => {
    return (
      <div style={{ background: t.color, height: 18 }} key={idx} dir="auto">
        {t.label}
      </div>
    );
  };

  renderInfluencerCube = (
    influencer: InfluencerInManageAuctionData,
  ): React.ReactNode => {
    if (!influencer || influencer.dispute) return null;
    const id = influencer.influencer_id as Exclude<
      typeof influencer.influencer_id,
      undefined
    >;
    const proposals = influencer.tasks_details
      ? influencer.tasks_details.tasks.map((x) => x.proposal).filter((x) => x)
      : [];
    const newProposalsLength = proposals.filter(
      (x) => x && x.shared_approved === null && x.approved === null,
    ).length;
    const waitingNotes = (this.state.waitingNotes as any)[id];
    const tags = this.props.board.tags
      ? this.props.board.tags.filter((x) => x.influencers?.includes(id))
      : [];
    const isAllCompleted = influencer.tasks_details.tasks.every(
      (task) => task.completed,
    );
    return (
      <div key={id} className="influencer-cube-container">
        <div className={"influencer-cube"}>
          {newProposalsLength > 0 || waitingNotes?.length ? (
            <div
              className="inf-cube-badge"
              onClick={() => this.openChatsAndContent(influencer)}
            >
              {newProposalsLength || ""}
            </div>
          ) : null}
          {influencer.picture_url ? (
            <img src={influencer.picture_url} alt="presentation" />
          ) : (
            <img
              className="no-image"
              // eslint-disable-next-line global-require
              src={require("../../../images/no-image.png")}
            />
          )}
          <strong className="inf-name">{influencer.name}</strong>
          {this.renderFollowersNumbers(influencer)}
          {tags?.length ? (
            <div className="tags-row" style={{ marginTop: 5, marginBottom: 0 }}>
              {tags.map(this.renderTagOnRow)}
            </div>
          ) : null}
          {isAllCompleted ? (
            <Button
              marginTop={3}
              colorScheme="green"
              rightIcon={<i className="fa fa-check"></i>}
            >
              <Trans>completed</Trans>
            </Button>
          ) : (
            <Button
              marginTop={3}
              variant={"outline"}
              className={`${newProposalsLength ? " new" : ""}${
                proposals.length > 0 ? " all" : " false"
              }`}
              onClick={() => this.openChatsAndContent(influencer)}
            >
              {this.renderContentText(newProposalsLength, proposals.length)}
            </Button>
          )}
        </div>
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  private renderContentText(
    newProposalsLength: number,
    proposalsLength: number,
  ): React.ReactNode {
    if (newProposalsLength) {
      return <Trans>approve_content</Trans>;
    }
    if (proposalsLength) {
      return <Trans>view_content</Trans>;
    }
    return <Trans>waiting_for_content</Trans>;
  }

  // eslint-disable-next-line class-methods-use-this
  renderFollowersNumbers(influencer: InfluencerInManageAuctionData) {
    const socialNetworks = influencer.social_network_data;
    if (!socialNetworks) return null;
    let counter = 0;
    Object.keys(socialNetworks).forEach((x: any) => {
      const socialName: SocialNetworkName = x;
      if (socialNetworks[socialName]?.followers) {
        counter += 1;
      }
    });
    let format = "0.00a";
    if (counter > 3) {
      format = "0a";
    } else if (counter > 2) {
      format = "0.0a";
    }
    return (
      <div style={{ display: "flex", marginRight: -5, marginTop: 3 }}>
        {socialNetworks.instagram && (
          <div className="influencer-followers" style={{ marginLeft: 0 }}>
            <a
              aria-label={socialNetworks.instagram.username}
              href={`https://www.instagram.com/${socialNetworks.instagram.username}`}
              target="_blank"
              style={{ color: "#333" }}
              rel="noreferrer"
            >
              <strong>
                <li className="fa fa-instagram" />{" "}
                {formatBigNumbers(
                  socialNetworks.instagram.followers as number,
                  {
                    format,
                    minForFormat: counter > 2 ? 0 : undefined,
                  },
                )}
              </strong>
            </a>
          </div>
        )}
        {socialNetworks.twitter && (
          <div className="influencer-followers">
            <a
              aria-label={socialNetworks.twitter.username}
              href={`https://www.twitter.com/${socialNetworks.twitter.username}`}
              target="_blank"
              style={{ color: "#333" }}
              rel="noreferrer"
            >
              <strong>
                <li className="fa fa-twitter" />{" "}
                {formatBigNumbers(socialNetworks.twitter.followers as number, {
                  format,
                  minForFormat: counter > 2 ? 0 : undefined,
                })}
              </strong>
            </a>
          </div>
        )}
        {socialNetworks.tiktok && (
          <div className="influencer-followers">
            <a
              aria-label={socialNetworks.tiktok.username}
              href={`https://www.tiktok.com/@${socialNetworks.tiktok.username}`}
              target="_blank"
              style={{ color: "#333" }}
              rel="noreferrer"
            >
              <strong>
                <li className="fa-brands fa-tiktok" />{" "}
                {formatBigNumbers(socialNetworks.tiktok.followers as number, {
                  format,
                  minForFormat: counter > 2 ? 0 : undefined,
                })}
              </strong>
            </a>
          </div>
        )}
        {socialNetworks.youtube && (
          <div className="influencer-followers">
            <a
              aria-label={socialNetworks.youtube.username}
              href={`https://www.youtube.com/channel/${socialNetworks.youtube.username}`}
              target="_blank"
              style={{ color: "#333" }}
              rel="noreferrer"
            >
              <strong>
                <li className="fa fa-youtube-play" />{" "}
                {formatBigNumbers(socialNetworks.youtube.followers as number, {
                  format,
                  minForFormat: counter > 2 ? 0 : undefined,
                })}
              </strong>
            </a>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    board: state.boardReducer.board as ManageAuctionData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getShareBoardDetails: (month: string | null, callback: any) => {
      dispatch(getShareBoardDetails(month, callback));
    },
    updateCurrentMonth: (month: string) => {
      dispatch({ type: "UPDATE_BOARD_MONTH", payload: month });
    },
  };
};

const CampaignInfluencersSharedConnected: any = connect(
  mapStateToProps,
  mapDispatchToProps,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
)(withTranslation()(CampaignInfluencersSharedInner));

export const CampaignInfluencersShared = ((props) => {
  const navigate = useNavigate();

  return <CampaignInfluencersSharedConnected {...props} navigate={navigate} />;
}) as React.FC<OwnProps>;
