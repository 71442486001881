import Dialog from "rc-dialog";
import { PureComponent } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Pie, PieChart, Tooltip } from "recharts";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Values from "values.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-cycle
import { CustomTooltip, renderActiveShape } from "./RoiUtils";

interface OwnProps {
  color: any;
  data: any;
  auctionsData: any;
  show: boolean;
}

type Props = OwnProps;

type State = {
  show: boolean;
  chartData?: any;
  active?: any;
};

export class DistributionPieChart extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
      chartData: null,
    };
  }

  componentDidMount() {
    if (!this.props.data) return;
    const colors = new Values(this.props.color);
    const chartData = this.props.data
      .map((d: any) => {
        let label = d.label || "";
        if (this.props.auctionsData) {
          label = this.props.auctionsData.find(
            (x: any) => x.id === d.auction_id,
          )?.title;
        }
        const fullLabel = label;
        if (label?.length > 10) {
          label = `${label.substring(0, 10)}...`;
        }
        const p = d.percentage;
        return {
          label,
          name: "",
          value: d.percentage,
          fill: colors
            .tint(p / 50)
            .shade(p / 50)
            .rgbString(),
          fontSize: "small",
          fullLabel,
        };
      })
      .sort((a: any, b: any) => {
        return a.value > b.value;
      });
    const active = chartData.map((_: any, idx: any) => idx);
    this.setState({ chartData, show: true, active });
  }

  render() {
    return (
      this.state.show && (
        <Dialog
          className="pie-dialog"
          visible={true}
          style={{ marginTop: 50, height: 500, width: 700 }}
          animation="slide-fade"
          maskAnimation="fade"
          onClose={() => this.setState({ show: false })}
        >
          <div
            style={{
              alignContent: "center",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <strong
              style={{
                color: this.props.color,
                display: "block",
                textAlign: "left",
                marginLeft: 20,
                marginBottom: -10,
              }}
            >
              {(this.props as any).label}
            </strong>
            {this.state.chartData && (
              <PieChart width={650} height={500}>
                <Pie
                  startAngle={90}
                  endAngle={450}
                  activeIndex={this.state.active}
                  activeShape={renderActiveShape}
                  data={this.state.chartData}
                  // innerRadius={210}
                  // outerRadius={210}
                  paddingAngle={5}
                  dataKey={"value"}
                />
                <Tooltip
                  content={<CustomTooltip active={true} payload={false} />}
                />
              </PieChart>
            )}
          </div>
        </Dialog>
      )
    );
  }
}
