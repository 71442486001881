import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

interface NavigateBoxProps {
  icon: string;
  text: string;
  status: "Not finished" | "Ready" | "default" | "Active";
  size?: string;
  onClick: () => void;
}

const NavigateBox: React.FC<NavigateBoxProps> = ({
  icon,
  text,
  status,
  onClick,
}) => {
  const [boxColor, setBoxColor] = useState("#EBEBEB");
  const [textColor, setTextColor] = useState("#808080");

  useEffect(() => {
    // Reset the style for inactive tabs
    if (status === "Not finished") {
      setBoxColor("none");
      setTextColor("#DBAD00");
    } else if (status === "Ready") {
      setBoxColor("none");
      setTextColor("#00A99D");
    } else if (status === "default") {
      setBoxColor("none");
      setTextColor("#808080");
    } else if (status === "Active") {
      setBoxColor("#F5FAFD");
      setTextColor("#249FF0");
    }
  }, [status]);

  let statusIcon = null;
  if (status) {
    if (status === "Not finished") {
      statusIcon = (
        <i
          className="fa-duotone fa-triangle-exclamation"
          style={{ color: "#DBAD00", marginRight: "2px" }}
        />
      );
    } else if (status === "Ready") {
      statusIcon = (
        <i
          className="fa-duotone fa-circle-check"
          style={{ color: "#00A99D", marginRight: "2px" }}
        />
      );
    }
  }

  return (
    <Box
      minW="80px"
      h="50px"
      borderRadius={"6px"}
      bg={boxColor}
      display="flex"
      cursor={"pointer"}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color={textColor}
      padding={"2px"}
      paddingBottom={"7px"}
      onClick={() => {
        onClick();
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <i className={icon} style={{ fontSize: "16px", lineHeight: 1 }} />
      </Box>

      <div
        style={{
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "3px",
          fontWeight: 600,
        }}
      >
        {statusIcon}
        <span>{text}</span>
      </div>
    </Box>
  );
};

export default NavigateBox;
