import axios, { AxiosInstance } from "axios";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Lock from "js-lock";
import { getSavedJwtToken } from "./commonActionsConf";
import { clearUser, renewRefreshToken, setTokens } from "./userActions";

// lock mechanisem
const lock = new Lock("TokenLock");
// count failed tokens
let failedTokens = 0;

export const setAxiosInterceptor = (
  axiosInstance: AxiosInstance,
  onFulfilled: any,
) => {
  axiosInstance.interceptors.response.use(onFulfilled, async (err) => {
    const config = err?.config || err?.response?.config;
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 403) &&
      config &&
      // eslint-disable-next-line no-underscore-dangle
      !config.__isRetryRequest
    ) {
      const lockResponse = await lock.lock(async () => {
        // check token on err.config  against global token
        // if same
        if (
          getSavedJwtToken() === config.headers["X-MoiBackendAuthorization"]
        ) {
          try {
            const success = await renewRefreshToken();
            const jwtTok = success.headers["x-moibackendauthorization"];
            const jwtRefreshTok = success.headers["x-moibackendrefreshtoken"];
            setTokens(jwtTok, jwtRefreshTok);
            // eslint-disable-next-line no-underscore-dangle, no-param-reassign
            config.__isRetryRequest = true;
            // eslint-disable-next-line no-param-reassign
            config.headers["X-MoiBackendAuthorization"] = jwtTok;
            failedTokens = 0;
            return await axios(config);
          } catch (error) {
            console.log("Bad token");
            failedTokens++;
            if (failedTokens > 2) {
              // logout
              clearUser();
            }

            throw error;
          }
        } else {
          // else just do retry with new header
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          config.__isRetryRequest = true;
          // eslint-disable-next-line no-param-reassign
          config.headers["X-MoiBackendAuthorization"] = getSavedJwtToken();
          return axios(config);
        }
      }, 10000);
      return lockResponse;
    }
    throw err;
  });
};

setAxiosInterceptor(axios, undefined);
