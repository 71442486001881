/* eslint-disable no-nested-ternary */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { StagesAccordion } from "../StagesAccordion";

interface Props {
  isLoading: boolean;
  nextStep: any;
  platform: any;
  purchaseEvent: any;
  website: string;
  checkEvent: any;
}

export const PurchaseEventScreen = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isIniting, setIsIniting] = useState(false);
  const [couponUsed] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [brand] = useState(null);

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const steps: any = [
    {
      id: 0,
      title: "Check for a purchase event",
      body: (
        <Flex>
          <div className="preview-description">
            <Box>
              <div>
                <Flex justifyContent={"space-between"}>
                  <span>
                    We need you to make an example purchase.
                    <br /> Please make a purchase and come back to this page.{" "}
                    <br />
                    If you want us to validate the purchase with a coupon,
                    please make sure you make a purchase with one
                    <br />
                    <br />
                  </span>
                  <span>
                    <i
                      style={{ fontSize: 40 }}
                      className="fal fa-shopping-cart"
                    />
                  </span>
                </Flex>
                <Alert status="info">
                  <AlertIcon />
                  <span>
                    This step is required in order to validate your purchases.{" "}
                    <br />
                    If you are measuring something different than a purchase
                    like sign-ups - you should send it just like a purchase
                    event with a unique <strong>transaction_id</strong>.
                  </span>
                </Alert>
                <Center>
                  <Link
                    href={props.website}
                    target={"_blank"}
                    onClick={() => setCurrentStep(1)}
                  >
                    <Button m={5} size="lg" colorScheme={"green"}>
                      Purchase on my website &nbsp;
                      <i className="far fa-external-link" />
                    </Button>
                  </Link>
                </Center>
              </div>
            </Box>
          </div>
        </Flex>
      ),
    },
    {
      id: 1,
      title: "Validate purchase event",
      body: (
        <>
          <Flex>
            <div className="preview-description">
              <Box>
                <div>
                  Please make sure you have completed a purchase. <br />
                  You will need to enter your <strong>order ID</strong> ( or
                  confirmation ID, transaction ID, etc.) below. <br />
                  Click on the button to validate the event.
                  <br />
                  <br />
                  <Alert status="info">
                    <AlertIcon />
                    <span>
                      Order ID is required for us to check the validity of this
                      event.
                      <br />
                    </span>
                  </Alert>
                  <br />
                  <Center>
                    <Box width={300}>
                      <Flex alignItems="baseline">
                        <InputGroup m={1}>
                          <InputLeftAddon>Order ID *</InputLeftAddon>
                          <Input
                            required
                            onChange={(e: any) =>
                              setTransactionId(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Flex>
                      {/* <Flex alignItems="baseline">
                        <InputGroup m={1}>
                          <InputLeftAddon>Coupon Used</InputLeftAddon>
                          <Input
                            onChange={(e: any) => setCouponUsed(e.target.value)}
                            value={couponUsed}
                          />
                        </InputGroup>
                      </Flex> */}
                      {/* <Flex alignItems="baseline">
                        <InputGroup m={1}>
                          <InputLeftAddon>Brand</InputLeftAddon>
                          <Input
                            onChange={(e: any) => setBrand(e.target.value)}
                            value={brand}
                          />
                        </InputGroup>
                      </Flex> */}
                    </Box>
                    <Button
                      colorScheme={"green"}
                      variant={
                        props.purchaseEvent?.is_valid ? "outline" : "solid"
                      }
                      size="lg"
                      isDisabled={props.isLoading || !transactionId}
                      isLoading={props.isLoading}
                      rightIcon={<i className="far fa-play" />}
                      m={5}
                      onClick={() => {
                        setIsIniting(true);
                        props.checkEvent(
                          "purchase",
                          {
                            transaction_id: transactionId,
                            coupon_used: couponUsed,
                            brand,
                          },
                          (data: any) => {
                            if (data?.is_valid) {
                              setIsIniting(false);
                              setCurrentStep(2);
                            }
                          },
                        );
                      }}
                    >
                      Check for purchase event
                    </Button>
                  </Center>
                </div>
              </Box>
              {isIniting && (
                <Box>
                  {props.purchaseEvent?.is_valid ? (
                    <>
                      <Box mt={5}>Please proceed to check the next event.</Box>
                    </>
                  ) : props.isLoading ? (
                    <>
                      <Alert status="info">
                        <Spinner />{" "}
                        <span>&nbsp;Checking for purchase event...</span>
                      </Alert>
                    </>
                  ) : (
                    <Alert status="error">
                      <AlertIcon />{" "}
                      <span>
                        We didn&apos;t find the purchase event. Please try
                        again.
                      </span>
                    </Alert>
                  )}{" "}
                </Box>
              )}
            </div>
          </Flex>
        </>
      ),
    },
    {
      id: 2,
      completed: false,
      title: "Make sure the details are correct",
      body: (
        <>
          <Alert status="success" justifyContent={"center"} mb={5}>
            <AlertIcon />
            <span>Purchase event Found!</span>
          </Alert>
          <Box>
            <Heading as="h3" size="lg" mb={2}>
              Purchase Information
            </Heading>
            <Box
              border={"1px solid #f1f1f1"}
              borderRadius={9}
              p={2}
              mb={3}
              maxWidth={600}
            >
              <Flex>
                <strong style={{ flex: 1, marginRight: 3 }}>
                  Transaction ID:
                </strong>
                <Tag flex={2} minWidth={100}>
                  {props?.purchaseEvent?.cart?.transaction_id}
                </Tag>
              </Flex>
              <Divider mb={1} mt={1} />
              <Flex>
                <strong style={{ flex: 1, marginRight: 3 }}>Event ID:</strong>
                <Tag flex={2} minWidth={100}>
                  {props?.purchaseEvent?.event_id}
                </Tag>
              </Flex>
              <Divider mb={1} mt={1} />
              <Flex>
                <strong style={{ flex: 1, marginRight: 3 }}>
                  Cart Total Price:
                </strong>
                <Tag flex={2} minWidth={100}>
                  {props?.purchaseEvent?.cart?.cart_total_price}
                </Tag>
              </Flex>
              <Divider mb={1} mt={1} />
              <Flex>
                <strong style={{ flex: 1, marginRight: 3 }}>Source URL:</strong>
                <Tag flex={2} minWidth={100}>
                  {props?.purchaseEvent?.source_url}
                </Tag>
              </Flex>
              <Divider mb={1} mt={1} />
              <Flex>
                <strong style={{ flex: 1, marginRight: 3 }}>Origin:</strong>
                <Tag flex={2} minWidth={100}>
                  {props?.purchaseEvent?.source_origin}
                </Tag>
              </Flex>
            </Box>
          </Box>
          {props?.purchaseEvent?.cart?.purchased_items && (
            <Box>
              <Heading as="h3" size="lg" mb={2}>
                Cart items
              </Heading>
              <TableContainer border={"1px solid #f1f1f1"} borderRadius={9}>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>SKU</Th>
                      <Th>Item</Th>
                      <Th isNumeric>Quantity</Th>
                      <Th>Brand</Th>
                      <Th>Coupon</Th>
                      <Th isNumeric>Price</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {props?.purchaseEvent?.cart?.purchased_items.map(
                      (item: any) => (
                        <Tr key={item.sku}>
                          <Td>{item.sku}</Td>
                          <Td>{item.item_name}</Td>
                          <Td isNumeric>{item.item_quantity}</Td>
                          <Td>{item.brand}</Td>
                          <Td>{item.coupon_used}</Td>
                          <Td isNumeric>{item.price}</Td>
                        </Tr>
                      ),
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Alert status="warning" mt={5}>
            <AlertIcon />
            <p>
              <span>
                Please validate that the following details are correct before
                you complete this stage!
              </span>
              <br />
            </p>
          </Alert>
        </>
      ),
    },
  ];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <StagesAccordion
      currentStep={currentStep}
      isLoading={props.isLoading}
      nextStep={nextStep}
      prevStep={prevStep}
      completeStage={props.nextStep}
      steps={steps}
      description={
        <>
          We will now check for the events that are being sent from the pixel.{" "}
          <br /> Please follow the instructions below
        </>
      }
      eventValidation={currentStep === 0 || props.purchaseEvent?.is_valid}
      setStep={(step: any) => () => setCurrentStep(step.id)}
    />
  );
};
