import {
  Input,
  InputGroup,
  InputLeftElement,
  useControllableState,
} from "@chakra-ui/react";
import React, { useState, useCallback, useEffect } from "react";
import { usePrevious } from "src/hooks/coreHooks";

interface Props
  extends Omit<
    React.ComponentProps<typeof Input>,
    "value" | "defaultValue" | "onChange"
  > {
  currencyLabel: string;
  defaultValue?: number;
  value?: number;
  onValueChange?: (newValue: number) => void;
}

export const MoneyInput: React.FC<Props> = ({
  currencyLabel,
  defaultValue,
  value,
  onValueChange,
  autoFocus,
  ...otherProps
}) => {
  const [internalNumberValue, setInternalNumberValue] = useControllableState({
    defaultValue: defaultValue ?? 0,
    value,
    onChange: onValueChange,
  });
  const [internalValue, setInternalValue] = useState(
    internalNumberValue.toString(),
  );

  useEffect(() => {
    setInternalValue(internalNumberValue.toString());
  }, [internalNumberValue]);

  const prevInternalValue = usePrevious(internalValue);

  useEffect(() => {
    const newInternalNumberValue = Number(internalValue);
    if (newInternalNumberValue !== Number(prevInternalValue)) {
      setInternalNumberValue(newInternalNumberValue);
    }
  }, [internalValue, prevInternalValue, setInternalNumberValue]);

  const handleInputChange = useCallback<
    NonNullable<React.ComponentProps<typeof Input>["onChange"]>
  >(
    (e) => {
      const newValue = e.target.value
        .replace(/[^0-9.]/g, "")
        .replace(/([0-9]+)(\..{0,2}).*/g, "$1$2");
      const indexOfDot = newValue.indexOf(".");
      const newValueLimited =
        indexOfDot !== -1
          ? newValue.substring(0, Math.min(indexOfDot, 9)) +
            newValue.substring(indexOfDot)
          : newValue.substring(0, 9);
      if (!Number.isNaN(newValueLimited)) {
        setInternalValue(newValueLimited || "0");
      }
    },
    [setInternalValue],
  );

  return (
    <InputGroup {...otherProps}>
      <InputLeftElement pointerEvents="none">{currencyLabel}</InputLeftElement>
      <Input
        autoFocus={autoFocus}
        value={internalValue}
        onChange={handleInputChange}
      />
    </InputGroup>
  );
};
