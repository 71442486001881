import { useRef, useState } from "react";

interface OwnProps {
  onSearch: any;
}

export const SearchBar = (props: OwnProps) => {
  const [searchClicked, setSearchClicked] = useState(false);
  const searchRef = useRef<HTMLInputElement>();
  const active = searchRef?.current?.value?.length;
  return (
    <div
      className={`humanz-search ${active ? "active" : ""}`}
      onClick={() => {
        if (!active) {
          setSearchClicked(searchClicked);
        }
        if (!searchClicked) {
          searchRef.current.focus();
        }
      }}
    >
      <div
        className="moi-search-bold"
        aria-label="Search"
        style={{
          zIndex: 2,
        }}
      />
      <input
        type="search"
        className={`form-control  campaign-manage-search ${
          searchClicked ? "clicked" : ""
        }`}
        onBlur={() => {
          if (!active) setSearchClicked(false);
        }}
        onChange={(e) => {
          props.onSearch(e);
        }}
        onFocus={() => setSearchClicked(true)}
        ref={searchRef}
      />
    </div>
  );
};
