import { toast } from "react-toastify";
import { getShareKeys } from "src/actions/shareActions";
import {
  getInvoices,
  postInvoices,
  postSharesRoiProducts_sales_roi,
  postInvoicesGenerate_upload_path,
} from "src/api/services";

type PostProductsSalesROISharedParams = Parameters<
  typeof postSharesRoiProducts_sales_roi
>;

export const postProductsSalesROIShared = (
  requestBody: PostProductsSalesROISharedParams[0],
  queryParams: PostProductsSalesROISharedParams[1],
) => postSharesRoiProducts_sales_roi(requestBody, queryParams, getShareKeys());

export const getShareInvoice = () => getInvoices(getShareKeys());

type PostInvoices = Parameters<typeof postInvoices>;
export const saveShareInvoice = async (requestBody: PostInvoices[0]) => {
  try {
    const data = await postInvoices(requestBody, getShareKeys());
    toast.success("Invoice upload successfully");
    return data;
  } catch (e) {
    toast.error("Failed to upload invoice");
    return false;
  }
};

type PostInvoicesGenerateUploadPathParams = Parameters<
  typeof postInvoicesGenerate_upload_path
>;
export const getUploadShareInvoices = (
  requestBody: PostInvoicesGenerateUploadPathParams[0],
) => postInvoicesGenerate_upload_path(requestBody, getShareKeys());
