// TODO - lint
/* eslint-disable import/no-default-export */
import React from "react";
import Dialog from "rc-dialog";
import { connect } from "react-redux";
import { editInfluencerNote } from "../../../actions/premiumActions";

class EditInfluencerNoteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      note: "",
      attachments: [],
    };
  }

  componentDidMount() {
    this.setState({ note: this.props.note });
  }

  submitForm = () => {
    if (this.props.handleSubmit) {
      this.props.handleSubmit(this.state.note);
    } else {
      this.setState({ loading: true }, () => {
        this.props.editInfluencerNote(
          this.props.influencerId,
          this.state.note,
          () => {
            this.setState({ loading: false });
            this.props.updateNote(this.state.note);
            this.props.handleClose();
          },
        );
      });
    }
  };

  render() {
    return (
      <Dialog
        onClose={this.props.handleClose}
        className="support-dialog"
        visible={true}
        style={{ width: "700px", margin: "100px auto" }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div className="support-dialog-inner">
          <div className="edit-tasks-header">Influencer note</div>

          {!this.state.loading && (
            <div className="input-row" style={{ marginTop: 20 }}>
              <label htmlFor="message">Write your note:</label>
              <br />
              <textarea
                style={{ minHeight: 200, border: "1px solid #249ff0" }}
                className={"input-line"}
                required
                onChange={(e) => this.setState({ note: e.target.value })}
                defaultValue={this.state.note}
                name="message"
                id="message"
                cols="30"
                rows="40"
              />
            </div>
          )}
          <div className="clearfix" />
          {this.state.loading && <div>Saving your note....</div>}

          <div
            className="preview-footer-buttons"
            style={{ marginTop: 0, justifyContent: "center" }}
          >
            <button
              disabled={this.state.loading}
              className={"btn btn-pirmary "}
              style={{ width: "auto" }}
              type="submit"
              onClick={() => {
                this.submitForm();
              }}
            >
              Submit
            </button>
            <button
              className={"btn btn-pirmary cancel "}
              style={{ width: "auto" }}
              onClick={() => this.props.handleClose()}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    editInfluencerNote: (influencerId, note, callback) => {
      dispatch(editInfluencerNote(influencerId, note, callback));
    },
  };
};

export default connect(null, mapDispatchToProps)(EditInfluencerNoteDialog);
