/* eslint-disable no-nested-ternary */
// TODO - lint
/* eslint-disable import/named, no-unused-vars, eqeqeq, eqeqeq, react/no-deprecated, class-methods-use-this, no-unused-vars, class-methods-use-this, radix, radix, no-unused-vars, import/no-default-export */
// General Imports
import moment from "moment";
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Actions imports
import { getBoardDetails } from "../../../actions/auctionsActions";
import { ListSection } from "../lists_components/ListSection";
import InfluencersSearch from "../search_components/InfluencersSearch";
import CampaignManager from "./CampaignManager/CampaignManager";
import CampaignPayments from "./Payments/CampaignPayments";

// Css's import

class CampaignNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: -1,
      currentTab: "",
      listId: -1,
      loading: true,
      section: "",
      hasRoi: false,
      name: "",
      oldView: false,
      pixel_enabled: false,
      influencers: null,
      month: null,
      disputeInfluencer: null,
    };

    this.TABS = ["brief", "discovery", "list", "manage", "report", "payments"];
  }

  loadBoard = () => {
    if (!this.props.id) return;
    if (this.props.id === "undefined") {
      this.props.navigate("/campaigns");
      return;
    }
    // btnPayments?.classList.add("hidden");
    window.pixel_enabled = undefined;
    const btnShare = document.getElementById("share-campaign-btn");
    if (btnShare) {
      btnShare.classList.remove("true");
    }
    const { id } = this.props;
    this.setState({ loading: true, id, section: this.props.section });

    let currentTab;
    if (this.props.section) {
      currentTab = this.TABS.indexOf(
        this.props.section.toLowerCase(),
      ).toString();
    }
    if (!id) return;
    this.props.getBoardDetails(
      id,
      this.state.month
        ? this.state.month
        : this.props?.board?.month
        ? moment(this.props?.board?.month).format("YYYY-MM-DD")
        : null,
      (data) => {
        if (!window.location.href.includes("/campaign/")) return;
        if (!data) return;
        if (!currentTab) {
          if (!data) return;
          currentTab = "2";
          if (
            data.states.approval ||
            data.states.bids ||
            data.states.invite ||
            data.states.publish ||
            data.states.trash
          ) {
            currentTab = "3";
          }
        }
        if (
          !this.props.id ||
          this.props.id != id ||
          this.props.id != data.summary.id
        ) {
          this.setState({ board: data.states.approval });
          return;
        }

        if (!this.state.currentTab) this.onChange(currentTab);
        const hasRoi =
          data.summary.completed_tasks || data.summary.pixel_enabled;
        const pixelEnabled = data.summary.pixel_enabled;
        this.setState({
          loading: false,
          listId: data.summary.campaign_list_id,
          hasRoi,
          name: data.summary.name,
          pixel_enabled: pixelEnabled,
        });
        if (btnShare && data.summary.shared_campaign) {
          btnShare.classList.add("true");
        }
        window.pixel_enabled = pixelEnabled;
      },
    );
  };

  triggerMonthChange = (month) => {
    this.setState({ month }, () => {
      this.props.updateCurrentMonth(month);
      this.loadBoard();
    });
  };

  componentWillMount() {
    this.setState({ section: this.props.section }, () => {
      this.loadBoard();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.tab !== "campaign") return;
    if (this.props.id !== this.state.id) {
      if (this.state.id === -1) return;
      this.loadBoard();
    } else if (
      this.props.section &&
      this.props.section !== this.state.section
    ) {
      this.onChange(this.TABS.indexOf(this.props.section).toString());
    }
  }

  onChange(e) {
    if (e === "0") {
      this.props.navigate(`/edit-campaign/${this.props.id}`);
    } else if (e === "4") {
      this.props.navigate(`/roi/${this.props.id}`);
    } else {
      const newTab = this.TABS[parseInt(e)];
      this.props.navigate(
        `/campaign/${this.props.id}/${newTab}${
          this.state.currentTab !== e && newTab === "payments"
            ? window.location.search
            : ""
        }`,
        { replace: !this.state.currentTab },
      );
      this.setState({ currentTab: e, section: newTab });
    }
  }

  openShareCampaign = (type) => {
    this.props.toggleShareCampain(
      this.props.id,
      this.state.name,
      type,
      this.props.share_guid,
    );
  };

  render() {
    const { currentTab } = this.state;
    return (
      <>
        <div className="campaign-nav">
          <div
            id="share-campaign"
            onClick={this.openShareCampaign}
            style={{ display: "none" }}
          />
          {this.state.loading ? (
            <div className="logo-loader">
              <div className="loader" />
              <img src={"/images/humanz-logo.png"} />
            </div>
          ) : (
            <div>
              <div className="section-top-bar"></div>
              <Tabs
                renderTabBar={() => <InkTabBar />}
                renderTabContent={() => <TabContent />}
                activeKey={currentTab}
                onChange={(e) => this.onChange(e)}
                className="campaign-top-bar"
              >
                <TabPane
                  tab={<span>BRIEF</span>}
                  key="0"
                  onClick={() =>
                    this.props.navigate(`/edit-campaign/${this.props.id}`)
                  }
                ></TabPane>
                <TabPane tab={<span>DISCOVER</span>} key="1"></TabPane>
                <TabPane tab={<span>LIST</span>} key="2"></TabPane>
                <TabPane tab={<span>MANAGE</span>} key="3"></TabPane>
                <TabPane tab={<span>REPORT</span>} key="4"></TabPane>

                <TabPane tab={<span>PAYMENTS</span>} key="5"></TabPane>
              </Tabs>

              <div className="campaign-nav-inner">
                {currentTab === "1" && (
                  <InfluencersSearch
                    closeAddToList={this.props.closeAddToList}
                    openAddToList={this.props.openAddToList}
                    campaign={this.props.id}
                    loaded={this.props.influencersLoaded}
                    openInfluencerSegmentations={
                      this.props.openInfluencerSegmentations
                    }
                    closeInfluencerSegmentations={
                      this.props.closeInfluencerSegmentations
                    }
                  />
                )}
                {/* {console.log(this.state.listId)} */}
                {currentTab === "2" && (
                  <ListSection
                    openShareCampaign={this.openShareCampaign}
                    listsLoaded={this.props.listsLoaded}
                    campaign={this.props.id}
                    id={this.state.listId}
                    loaded={this.props.influencersLoaded}
                    openInfluencerSegmentations={
                      this.props.openInfluencerSegmentations
                    }
                    closeInfluencerSegmentations={
                      this.props.closeInfluencerSegmentations
                    }
                  />
                )}
                {currentTab === "3" && (
                  <>
                    <CampaignManager
                      id={this.props.id}
                      closeChatDialog={this.props.closeChatDialog}
                      showCost={true}
                      openAddToList={this.props.openAddToList}
                      openInfluencerSegmentations={
                        this.props.openInfluencerSegmentations
                      }
                      closeInfluencerSegmentations={
                        this.props.closeInfluencerSegmentations
                      }
                      openChat={this.props.openChat}
                      triggerMonthChange={this.triggerMonthChange}
                      month={this.state.month}
                    />
                  </>
                )}
                {currentTab === "5" && (
                  <>
                    <CampaignPayments
                      id={this.props.id}
                      closeChatDialog={this.props.closeChatDialog}
                      showCost={true}
                      openInfluencerSegmentations={
                        this.props.openInfluencerSegmentations
                      }
                      closeInfluencerSegmentations={
                        this.props.closeInfluencerSegmentations
                      }
                      influencers={this.influencers}
                      disputeInfluencer={this.state.disputeInfluencer}
                      setDisputeInfluencer={(val) =>
                        this.setState({ disputeInfluencer: val })
                      }
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    board: state.boardReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBoardDetails: (id, month, callback) => {
      dispatch(getBoardDetails(id, month, callback));
    },
    updateCurrentMonth: (month) => {
      dispatch({ type: "UPDATE_BOARD_MONTH", payload: month });
    },
  };
};

const CampaignNavigationConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignNavigation);

export default (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_BOARD_MONTH", payload: null });
    };
  }, []);
  return <CampaignNavigationConnected {...props} navigate={navigate} />;
};
