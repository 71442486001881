import { Box, Flex, Tag } from "@chakra-ui/react";
import { useState } from "react";
import { Lite } from "./Lite";
import { Shopify } from "./Shopify";

type Props = {
  isLoading: boolean;
  appId: string;
  platform: any;
  nextStep: any;
};

export const ShopifyWizard = ({
  isLoading,
  appId,
  platform,
  nextStep,
}: Props) => {
  const [shopifyFlow, setShopifyFlow] = useState(null);

  return (
    <>
      {shopifyFlow === null && (
        <Flex sx={{ justifyContent: "center" }}>
          <Box
            className="platformButton"
            onClick={() => setShopifyFlow("appstore")}
            style={{ justifyContent: "center" }}
          >
            <Flex
              style={{
                flexDirection: "column",
                alignItems: "center",
                gap: 20,
                textAlign: "center",
              }}
            >
              <Tag className="platform-tag-top">Recommended</Tag>
              <Box>
                <div className="imagewrapper">
                  <i
                    className="fab fa-shopify"
                    style={{ fontSize: 65, color: "#96BF48" }}
                  />
                </div>
              </Box>
              <Box>
                <strong className="platform-name">
                  Install from App Store{" "}
                </strong>
                <Box>
                  <Tag className="platform-tag">Simple installation</Tag>
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box
            className="platformButton"
            onClick={() => setShopifyFlow("manual")}
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              flexFlow: "column",
              textAlign: "center",
            }}
          >
            <Flex
              style={{ flexDirection: "column", alignItems: "center", gap: 20 }}
            >
              <div className="imagewrapper">
                <i
                  className="fa-duotone fa-solid fa-screwdriver-wrench"
                  style={{ fontSize: 50, color: "#329ac0" }}
                />
              </div>
              <Box>
                <strong className="platform-name">Manual installation</strong>
                <Box>
                  <Tag className="platform-tag">Advanced installation</Tag>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      )}
      {shopifyFlow === "manual" && (
        <Shopify
          isLoading={isLoading}
          appId={appId}
          nextStep={() => nextStep()}
        />
      )}
      {shopifyFlow === "appstore" && (
        <Lite
          isLoading={isLoading}
          nextStep={() => nextStep()}
          platform={platform}
        />
      )}
    </>
  );
};
