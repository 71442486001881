import axios from "axios";
import { toast } from "react-toastify";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";

const getInsightUrlBase = () => `${getBackendUri()}/moi/standalone_roi`;

export const getSharedInsight = (insightId, callback) => {
  return () => {
    axios
      .get(`${getInsightUrlBase()}/${insightId}/share`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        callback();
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get shared list data",
          "system_notifications_failure_insight_share_get",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const shareInsight = (insightId, sharedData, callback) => {
  return (dispatch) => {
    axios
      .put(`${getInsightUrlBase()}/${insightId}/share`, sharedData, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "SHARE_INSIGHT_FULFILLED",
          payload: {
            list_id: insightId,
            share_notes: sharedData.share_comments,
            shared_link: response.data.shared_link,
          },
        });
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        callback();
        dispatch({ type: "SHARE_INSIGHT_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to share list",
          "system_notifications_failure_insight_share",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const disableShareInsight = (insightId, callback) => {
  return (dispatch) => {
    axios
      .delete(`${getInsightUrlBase()}/${insightId}/share`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        dispatch({
          type: "DISABLE_SHARE_INSIGHT_FULFILLED",
          payload: { list_id: insightId },
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        callback();
        dispatch({ type: "DISABLE_SHARE_INSIGHT_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to disable shared list",
          "system_notifications_failure_insight_share_disable",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};
