import {
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { addUpfrontPayment } from "src/api/actions/auctionActions";
import { Form } from "src/ui/Form";
import { FormFooterButtonGroup } from "src/ui/FormFooterButtonGroup";
import { MoneyInput } from "src/ui/MoneyInput";
import {
  CampaignPaymentsContext,
  CampaignPaymentsContextType,
} from "../CampaignPaymentsContext";

type Props = {
  onClose: any;
  currencyLabel?: any;
  influencerId: number;
  auctionId: number;
  paymentId: string;
  maxPrice: number;
};

const AddAmountToPayModal = (props: Props) => {
  const context = React.useContext<CampaignPaymentsContextType>(
    CampaignPaymentsContext,
  );
  const { getPaymentData } = context;
  const currencySymbol = window.translate(
    "",
    `money_symbol_${props.currencyLabel}`,
  );

  const [amount, setAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleAmountChange = useCallback<
    NonNullable<React.ComponentProps<typeof MoneyInput>["onValueChange"]>
  >(
    (newValue) => {
      setAmount(newValue);
    },
    [setAmount],
  );

  const isValid = amount > 0 && amount <= props.maxPrice;

  const handleFormSubmit = () => {
    setIsLoading(true);
    addUpfrontPayment(props.auctionId, props.influencerId, {
      id: props.paymentId,
      upfront_price: amount,
    })
      .then(() => {
        getPaymentData();
        props.onClose();
      })
      .catch(() => {
        toast.error("Failed to add upfront payment");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Modal size="xl" isOpen={true} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add upfront to pay</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            <Container pt={6} mb={6}>
              <Text fontSize="xl" pb={3}>
                Add Upfront Payment
              </Text>
              <Form onSubmit={handleFormSubmit}>
                <Flex alignItems="center" py={3}>
                  <FormControl>
                    <Flex align="baseline">
                      <FormLabel flex={1}>Upfront to pay</FormLabel>
                      <MoneyInput
                        flex={1}
                        autoFocus
                        currencyLabel={currencySymbol}
                        value={amount}
                        onValueChange={handleAmountChange}
                      />
                    </Flex>
                  </FormControl>
                </Flex>
                <FormFooterButtonGroup py={3}>
                  <Button
                    isDisabled={!isValid}
                    isLoading={isLoading}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button type="button" onClick={props.onClose}>
                    Cancel
                  </Button>
                </FormFooterButtonGroup>
              </Form>
            </Container>
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddAmountToPayModal;
