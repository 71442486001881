// TODO - lint
/* eslint-disable import/no-default-export, no-unused-vars, no-shadow, no-shadow, camelcase, camelcase, camelcase, consistent-return, no-param-reassign, no-shadow, react/no-string-refs, no-shadow, react/no-string-refs */
import React from "react";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import Tooltip from "rc-tooltip";
import SliderStages from "../utilities/SliderStages";
import CheckBox from "../general_components/CheckBox";

export default class SearchItem extends React.Component {
  render() {
    const {
      item,
      index,
      filteredTags,
      updateState,
      updateSearchField,
      updateTreeField,
      onChangeTags,
      handleTagsChange,
      filterBySearch,
      valueCityChange,
      citiesOptions,
      selectedSocials,
    } = this.props;

    if (item.type === "tags-choice") item.selected = filteredTags;
    let enabled = true;
    if (item.restricted_social_networks && selectedSocials) {
      enabled = !!(
        selectedSocials.includes("all") ||
        item.restricted_social_networks.find((x) => selectedSocials.includes(x))
      );
    }
    return (
      <div
        style={{ marginTop: 10 }}
        aria-label={enabled ? null : item.label_tooltip}
      >
        <div disabled={!enabled}>
          <div
            className="small-title pointer"
            onClick={() => {
              item.isOpen = !item.isOpen;
              updateState();
            }}
          >
            <div style={{ alignItems: "center" }}>
              <strong className="upper-case">{item.label}</strong>
              {item.label_tooltip ? (
                <Tooltip
                  overlay={item.label_tooltip}
                  trigger={["hover"]}
                  placement={"top"}
                >
                  <i
                    style={{ marginLeft: 5, fontSize: 14 }}
                    className="fa-solid fa-info-circle"
                  />
                </Tooltip>
              ) : null}
            </div>
            <div
              className={`search-collapse row-collapse fas ${
                item.isOpen ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            />
          </div>
          {item.isOpen && (
            <div>
              {item.type === "checkbox" && (
                <div className="checkbox-container">
                  {item.values.map((x, index) => {
                    const checked = item.isMulti
                      ? item.selected.indexOf(index) > -1
                      : item.selected === index;
                    let label = x;
                    if (item.labels) {
                      label = item.labels[index];
                    }
                    let tooltip = null;
                    if (item.tooltips) {
                      tooltip = item.tooltips[index];
                    }
                    return (
                      <CheckBox
                        key={`checkbox${x}${index}`}
                        style={{ marginTop: 5 }}
                        checked={checked}
                        onChange={() => updateSearchField(item.name, index)}
                        label={label}
                        tooltip={tooltip}
                      />
                    );
                  })}
                </div>
              )}

              {item.type === "tree" && (
                <div style={{ paddingTop: 10 }}>
                  <div style={{ marginTop: 10, marginRight: -10 }}>
                    <div>
                      <input
                        className="input-line"
                        value={item.search}
                        onChange={(e) => {
                          item.search = e.target.value.toLowerCase();
                          updateState();
                        }}
                        placeholder={"Search location"}
                        style={{ paddingRight: 35 }}
                      />
                      <i
                        className={`fas fa-caret-${
                          item.open || item.search ? "up" : "down"
                        }`}
                        onClick={() => {
                          if (item.search) return;
                          item.open = !item.open;
                          updateState();
                        }}
                        style={{
                          position: "absolute",
                          marginLeft: 260,
                          fontSize: 20,
                          marginTop: -25,
                          cursor: "pointer",
                        }}
                      ></i>
                    </div>
                    {(item.search || item.open) &&
                      item.values.map((p, index) => {
                        let { childs } = p;
                        let { labels } = p;
                        let { open } = p;
                        if (
                          item.search &&
                          !p.name.toLowerCase().includes(item.search)
                        ) {
                          // if there are labels we will search by labels because this is what the user sees
                          if (labels) {
                            const new_childs = [];
                            labels = labels.filter((x, idx) => {
                              const searchResult = x
                                .toLowerCase()
                                .includes(item.search);
                              if (searchResult) {
                                new_childs.push(childs[idx]);
                              }
                              return searchResult;
                            });
                            childs = new_childs;
                          } else {
                            childs = childs.filter((x) =>
                              x.toLowerCase().includes(item.search),
                            );
                          }

                          if (childs.length === 0) return;

                          open = true;
                        }
                        return (
                          <div
                            className="checkbox-container"
                            key={`${p.name}_select_${index}`}
                          >
                            {childs.length > 0 && (
                              <i
                                className={`far fa-${open ? "minus" : "plus"}`}
                                onClick={() => {
                                  if (open !== p.open) return;
                                  p.open = !p.open;
                                  updateState();
                                }}
                                style={{
                                  marginTop: 9,
                                  position: "absolute",
                                  cursor: "pointer",
                                  width: 20,
                                  height: 20,
                                  zIndex: 99,
                                }}
                              ></i>
                            )}
                            <CheckBox
                              style={{ marginTop: 5, marginLeft: 20 }}
                              checked={p.selected.includes("all")}
                              onChange={() => updateTreeField(p)}
                              label={p.name}
                            />
                            {open && childs.length > 0 && (
                              <div className="districs">
                                {childs.map((c, index) => {
                                  const checked =
                                    p.selected.includes(c) ||
                                    p.selected.includes("all");
                                  let label = c;
                                  if (labels) {
                                    label = labels[index];
                                  }
                                  return (
                                    <CheckBox
                                      style={{ marginTop: 5 }}
                                      checked={checked}
                                      onChange={() => updateTreeField(p, c)}
                                      label={label}
                                      key={`${c}_select_${index}`}
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}

              {item.type === "text" && (
                <div className="input-text-container" style={{ marginTop: 10 }}>
                  <input
                    className="input-line"
                    placeholder={item.placeholder}
                    value={item.value}
                    onChange={(e) => filterBySearch(e.target.value, item.name)}
                  />
                </div>
              )}
              {item.type === "number" && (
                <div className="input-text-container" style={{ marginTop: 10 }}>
                  <input
                    className="input-line"
                    placeholder={item.placeholder}
                    type="text"
                    value={item.value}
                    onChange={(e) => {
                      const { value } = e.target;
                      const onlyDigits = value
                        .replace(/\..*/g, "")
                        .replace(/[^0-9]*/g, "");
                      if (onlyDigits !== item.value) {
                        filterBySearch(onlyDigits, item.name);
                      }
                    }}
                  />
                </div>
              )}
              {item.type === "tags" && (
                <div
                  className="custom-height"
                  style={{ borderBottom: "1px solid #bbbbbb" }}
                >
                  <TagsInput
                    value={item.tags}
                    onChange={(tags) => handleTagsChange(item, tags)}
                    inputValue={item.tag}
                    onChangeInput={(tagInput) => {
                      item.tag = tagInput;
                      updateState();
                    }}
                    addOnBlur={true}
                    inputProps={{
                      placeholder: item.placeholder,
                      className: "tasks-tags-input main-tags",
                    }}
                  />
                </div>
              )}
              {item.type === "multiple_field" && (
                <div className="input-muti-container" style={{ marginTop: 10 }}>
                  <Select.Creatable
                    className="searchable-input"
                    options={item.values[0]}
                    multi={false}
                    placeholder={item.labels[0]}
                    simpleValue
                    name="selected-state"
                    clearable={false}
                    disabled={false}
                    value={item.selected[0]}
                    onChange={(selectedField) => {
                      updateSearchField(item.name, [
                        selectedField,
                        item.selected[1],
                      ]);
                    }}
                  />
                  <Select.Creatable
                    className="searchable-input"
                    options={item.values[1]}
                    multi={false}
                    placeholder={item.labels[0]}
                    simpleValue
                    name="selected-state"
                    clearable={false}
                    disabled={false}
                    value={item.selected[1]}
                    onChange={(selectedField) => {
                      updateSearchField(item.name, [
                        item.selected[0],
                        selectedField,
                      ]);
                    }}
                  />
                </div>
              )}
              {item.type === "slider" && (
                <div
                  className="slider-container"
                  style={{ marginTop: 20, height: 50 }}
                >
                  <SliderStages
                    domain={[0, 100]}
                    defaultValues={item.selected}
                    ref={"slider"}
                    onChange={(value) => updateSearchField(item.name, value)}
                  />
                </div>
              )}
              {item.type === "city-search" && (
                <div style={{ marginTop: 10 }} className="searchable-input">
                  <Select
                    placeholder={item.placeholder}
                    value={item.value}
                    simpleValue
                    onChange={(value) => {
                      updateSearchField(item.name, value);
                    }}
                    onInputChange={valueCityChange}
                    options={citiesOptions}
                    defaultOptions
                  />
                </div>
              )}

              {item.type === "tags-choice" && (
                <>
                  <div className="selected_list react-tagsinput">
                    {item.tags &&
                      filteredTags &&
                      filteredTags.map((id, i) => (
                        <span className="react-tagsinput-tag" key={i}>
                          {item.tags.find((tg) => tg.id === id).label}
                          <a
                            className="react-tagsinput-remove"
                            onClick={() => {
                              filteredTags.addOrRemove(id);
                              item.selected =
                                item.selected && item.selected.length
                                  ? item.selected.filter((item) => item !== id)
                                  : [];
                              updateState(onChangeTags);
                            }}
                          />
                        </span>
                      ))}
                  </div>
                  {item.tags && (
                    <Select
                      className="searchable-input"
                      ref="_tg"
                      multi={true}
                      options={item.tags.map((i) => {
                        return { value: i.id, label: i.label };
                      })}
                      clearable={true}
                      onChange={(e) => {
                        if (
                          item.selected &&
                          item.selected.indexOf(e[0].value) === -1
                        ) {
                          item.selected.push(e[0].value);
                        }
                        updateState(onChangeTags);
                      }}
                      searchable={true}
                    />
                  )}
                </>
              )}
            </div>
          )}
          <div className="filter-refine-tab-spacer" />
        </div>
      </div>
    );
  }
}
