import React from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "rc-tooltip";
import Select from "react-select";
import { resetMimicUser } from "../../actions/userActions";
import { UserProfileImageDialog } from "./general_components/UserProfileImageDialog";

const getIsMimicUser = () => {
  return localStorage.getItem("mimic-moi-jwt-token");
};

const getIsCurrentMimicSession = () => {
  return localStorage.getItem("mimic-moi-mimicing-user");
};

const IsMimUser = getIsMimicUser();
const MimSession = getIsCurrentMimicSession();

let selectedChild = localStorage.getItem("child_account");

export const getSelectedChild = () => selectedChild;

const hideMimicBanner = () => {
  document.getElementById("mimic-banner").style.display = "none";
};

export const TopUserDetails = (props) => {
  const navigate = useNavigate();
  const userDetails = props.userDetails ? props.userDetails : null;

  if (!userDetails) return <div></div>;

  const onChangeChild = (value) => {
    if (value) localStorage.setItem("child_account", value);
    else localStorage.removeItem("child_account");
    selectedChild = value;
    window.location.reload();
  };

  const renderOptions = (childUsers) => {
    const defaultName = "All Users";
    const options = [{ value: null, label: "All Users" }];
    // for style calculation, maybe we can move to fix.
    let maxLength = defaultName.length;
    childUsers.forEach((user) => {
      if (user.name.length > maxLength) {
        maxLength = user.name.length;
      }
      options.push({ value: user.id, label: user.name });
    });
    // so it won't be long enough
    if (maxLength > 20) {
      maxLength = 20;
    }

    return (
      <div style={{ display: "flex", marginTop: -5, alignItems: "center" }}>
        <strong>View</strong>
        <Select
          style={{
            width: `${8 * maxLength + 100}px`,
            marginLeft: 10,
            marginRight: 20,
          }}
          options={options}
          placeholder={"All Users"}
          value={selectedChild}
          onChange={(v) => onChangeChild(v)}
          simpleValue
          clearable={false}
          name="selected-state"
          disabled={false}
          searchable={true}
        />
      </div>
    );
  };
  if (props.shared) {
    return (
      <div className="top-bar-container">
        <div className="top-user-details">
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: 15 }}>{userDetails.name}</div>
            <div className="top-user-pic-container">
              <img className="top-user-pic" src={userDetails.image} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  const children =
    userDetails && userDetails.child_users && userDetails.child_users.length > 0
      ? userDetails.child_users
      : null;
  const isBlurUser = localStorage.getItem("h-blur-user");
  return (
    <div className="top-bar-container">
      <div className="top-user-details">
        <div style={{ display: "flex", alignItems: "center" }}>
          {children && renderOptions(children)}
          {userDetails &&
          userDetails.sidebar_data &&
          userDetails.sidebar_data.allowed_blur ? (
            <div
              className="top-icon <fa-sharp fa-solid fa-eye"
              onClick={() => {
                if (isBlurUser) {
                  localStorage.removeItem("h-blur-user");
                } else {
                  localStorage.setItem("h-blur-user", "true");
                }
                window.location.reload();
              }}
              style={{
                color: isBlurUser ? "red" : "",
              }}
            />
          ) : null}
          {!!MimSession && (
            <div className="mimic-banner" id="mimic-banner">
              <i className="far fa-exclamation-triangle" />
              MIMICING USER {userDetails.email} ({userDetails.user_id})
              <i
                className="far fa-times"
                style={{ cursor: "pointer" }}
                onClick={hideMimicBanner}
              />
            </div>
          )}
          {!!MimSession && (
            <Tooltip
              placement="top"
              trigger={["hover", "click"]}
              overlay={<span>{"Stop Mimic Session"}</span>}
            >
              <div
                className="top-icon fa fa-user-secret"
                style={{ color: "red" }}
                onClick={() => {
                  resetMimicUser();
                }}
              ></div>
            </Tooltip>
          )}

          {!!IsMimUser && (
            <Tooltip
              placement="top"
              trigger={["hover", "click"]}
              overlay={<span>{"Start Mimic Session"}</span>}
            >
              <div
                className="top-icon fa fa-user-secret"
                onClick={() => props.openMimicDialog()}
              ></div>
            </Tooltip>
          )}
          <div
            className="top-icon fa-sharp fa-solid fa-gear"
            onClick={() => navigate("/edit-details/account")}
          ></div>
          <i
            className="top-icon fas fa-question-circle"
            onClick={() => props.toggleSupportDialog(true)}
          ></i>
          <div
            className="top-icon fa-sharp fa-solid fa-bell"
            onClick={() => props.openNotifications()}
          >
            {props.notifCount ? (
              <div
                className="indication-dot"
                style={{
                  position: "absolute",
                  marginTop: -22,
                  marginLeft: 8,
                }}
              />
            ) : null}
          </div>
          <div
            className="top-icon fa-sharp fa-regular fa-arrow-right-from-bracket"
            onClick={() => props.logout()}
          ></div>
          <UserProfileImageDialog />
        </div>
      </div>
    </div>
  );
};
