// TODO - lint
/* eslint-disable import/no-default-export, react/no-deprecated */
import React from "react";

interface Props {
  onClickOutside(): void;
  container: any;
  children: any;
}

export default class WatchClickOutside extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    document.body.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    // remember to remove all events to avoid memory leaks
    document.body.removeEventListener("click", this.handleClick);
  }

  handleClick(event: any) {
    // get container that we'll wait to be clicked outside
    const { container, onClickOutside } = this.props; // get click outside callback
    const { target } = event; // get direct click event target

    // if there is no proper callback - no point of checking
    if (typeof onClickOutside !== "function") {
      return;
    }

    // if target is container - container was not clicked outside
    // if container contains clicked target - click was not outside of it
    if (target !== container && !container.contains(target)) {
      onClickOutside(); // clicked outside - fire callback
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
