import { useMemo } from "react";
import { DeletePrompt } from "./DeletePrompt";
import { usePrompt } from "./usePromt";

export const useDeletePrompt = () => {
  const prompt = usePrompt();

  return useMemo(
    () => ({
      askToDelete: (
        params?: Omit<
          Parameters<typeof prompt.open>[0],
          "onClose" | "title" | "titleIconClassName" | "body"
        > & { text?: string },
      ) => {
        return new Promise<boolean>((res) => {
          const close = prompt.open({
            onClose: () => res(false),
            titleIconClassName: "fas fa-trash-can",
            body: (
              <DeletePrompt
                onYes={() => {
                  close();
                  res(true);
                }}
                onNo={() => {
                  close();
                  res(false);
                }}
                text={params?.text}
              />
            ),
            ...params,
          });
        });
      },
    }),
    [prompt],
  );
};
