/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
// TODO - lint
/* eslint-disable no-unused-vars,  */
import RCTooltip from "rc-tooltip";
import { useState } from "react";
import { Pie, PieChart, Sector } from "recharts";
import Values from "values.js";
import { formatBigNumbers, numberWithCommas } from "../../../utilities/general";
import { DistributionPieChart } from "./DistributionPieChart";

export const getInitState = () => {
  return {
    id: -1,
    loaded: false,
    summaryData: null,
    influencersData: null,
    posts: [],
    selectedPosts: { instagram: [], twitter: [], youtube: [], tiktok: [] },
    openInfluencer: -1,
    selectedSocial: "instagram",
    byBudgetToggle: false,
    allContents: [],
    currentTab: "1",
    shareComments: { kpi: "", tasks: "", funnel: "", segmentation: "" },
    openComments: ["kpi", "tasks", "funnel", "segmentation"],
    showFilters: false,
    newMode: false,
    openShareInsight: false,
    selectedInsight: null,
    fields: {},
    tagsFilter: [],
    isAmbassador: false,
    isAmbassadorUnfinished: false,
    frequenciesLoader: false,
    selected_package: null,
    paymentData: {
      packages: [],
      summary: {},
    },
    customTitle: null,
    paymentsdialogData: null,
    tabs: [],
    influencers: [],
    edit_fields: {},
    notesSummary: {},
    allowFilters: true,
    shareDatesContent: true,
    reachFrequency: null,
    reachFrequencyLoader: false,
  };
};

export const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value,
    label,
    fontSize,
  } = props;
  let length = value > 50 ? 100 - value : value;
  length += 30;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + length) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 18;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={"white"}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle + 3}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 15}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        style={{ fontFamily: "openSansHebrewBold", fontSize: 11 }}
        textAnchor={textAnchor}
        fill={fill}
      >{`${label}`}</text>
      {payload.hidePercentage ? null : (
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          style={{
            fontSize: fontSize === "small" ? 11 : 20,
            fontFamily: "openSansHebrewBold",
          }}
          textAnchor={textAnchor}
          fill={fill}
        >
          {`${value.toFixed(2)}%`}
        </text>
      )}
    </g>
  );
};

export const processSegmentationsData = (segmentation) => {
  let followersCountries = [];
  let followersCities = [];
  let gendersData = [];
  let agesData = [];

  const colors = ["#3476bd", "#c9ad36", "#17a086", "#e05d6f", "white"];
  if (segmentation) {
    if (segmentation.gender_segmentation) {
      gendersData = [
        {
          label: window.translate("", "followers_male_label"),
          name: "",
          value: segmentation.gender_segmentation.male,
          fill: "#606060",
          fontSize: "small",
        },
        {
          name: "",
          label: window.translate("", "followers_female_label"),
          value: segmentation.gender_segmentation.female,
          fill: "#f89e20",
          fontSize: "small",
        },
      ];
    }

    const ages = segmentation.age_segmentation;
    if (ages) {
      agesData = [
        {
          name: window.translate("", "children_label"),
          data: parseFloat(ages.children.toFixed(2)),
          fill: colors[2],
          fontSize: "small",
        },
        {
          name: window.translate("", "teens_label"),
          data: parseFloat(ages.teens.toFixed(2)),
          fill: colors[3],
          fontSize: "small",
        },
        {
          name: window.translate("", "adults_label"),
          data: parseFloat(ages.adults.toFixed(2)),
          fill: colors[1],
          fontSize: "small",
        },
        {
          name: window.translate("", "elderly_label"),
          data: parseFloat(ages.elderly.toFixed(2)),
          fill: colors[0],
          fontSize: "small",
        },
      ];
    }
    const cities = segmentation.cities_segmentation;
    if (cities) {
      followersCities = cities.map((c, index) => {
        return {
          name: c.eng_name,
          data: c.percentage,
          fill: colors[0],
          fontSize: "small",
        };
      });
    }
    const countries = segmentation.countries_segmentation;
    if (countries) {
      followersCountries = countries.map((c, index) => {
        return {
          name: c.eng_name,
          data: c.percentage,
          fill: colors[index],
          fontSize: "small",
        };
      });
    }
    return {
      gendersData,
      agesData,
      followersCountries,
      followersCities,
      followersDataReturned: true,
    };
  }
};

export const processYouTubeOriginalInsights = (data) => {
  const followersCountries = [];
  const followersCities = [];
  let gendersData = [];
  let malesAge = [];
  let femalesAge = [];

  const colors = ["#3476bd", "#c9ad36", "#17a086", "#e05d6f", "white"];
  if (data) {
    if (data.males && data.females) {
      gendersData = [
        {
          label: window.translate("", "followers_male_label"),
          name: "",
          value: data.males.reduce((a, b) => {
            return a + b.percentage;
          }, 0),
          fill: "#606060",
          fontSize: "small",
        },
        {
          name: "",
          label: window.translate("", "followers_female_label"),
          value: data.females.reduce((a, b) => {
            return a + b.percentage;
          }, 0),
          fill: "#f89e20",
          fontSize: "small",
        },
      ];
    }

    if (data.males) {
      malesAge = data.males.map((a, idx) => {
        return {
          name: a.age_group,
          data: a.percentage,
          fill: colors[idx],
          fontSize: "small",
        };
      });
    }

    if (data.females) {
      femalesAge = data.females.map((a, idx) => {
        return {
          name: a.age_group,
          data: a.percentage,
          fill: colors[idx],
          fontSize: "small",
        };
      });
    }

    return { gendersData, malesAge, femalesAge, followersDataReturned: true };
  }
};

export const processClicksStatisticsData = (segmentation, auctions) => {
  let devices = [];
  let clicks_per_auction = [];
  const clicksDates = {
    total: 0,
    lastMonth: 0,
    lastWeek: 0,
    yestrday: 0,
    today: 0,
  };

  let countries = [];
  if (segmentation) {
    let total;
    if (segmentation.devices) {
      total = segmentation.devices.reduce(function (a, b) {
        return a + b.clicks;
      }, 0);
      segmentation.devices = segmentation.devices.sort(function (a, b) {
        return b.clicks > a.clicks;
      });
      devices = segmentation.devices.map((x) => {
        return {
          name:
            x.name === "Unknown"
              ? "Computer"
              : x.name === "AndroidOS"
              ? "Android"
              : x.name,
          data: (x.clicks / total) * 100,
        };
      });
    }
    if (segmentation.countries_segmentation) {
      total = segmentation.countries_segmentation.reduce(function (a, b) {
        return a + b.clicks;
      }, 0);
      segmentation.countries_segmentation.total = total;
    }
    if (segmentation.clicks_per_auction && auctions) {
      clicks_per_auction = segmentation.clicks_per_auction
        .map((x) => {
          const name = auctions.find((a) => a.id == x.name)?.title || x.name;
          return { ...x, name };
        })
        .sort((a, b) => {
          return (b.clicks || 0) - (a.clicks || 0);
        });
    }

    const clicks = segmentation.clicks_per_day;
    if (clicks) {
      const now = new Date();
      const dayInMili = 24 * 3600 * 1000;
      total = clicks.reduce(function (a, b) {
        return a + b.clicks;
      }, 0);
      const lastMonth = clicks
        .filter((x) => {
          const date = new Date(x.name);
          return !(now - date > dayInMili * 31);
        })
        .reduce(function (a, b) {
          return a + b.clicks;
        }, 0);

      const lastWeek = clicks
        .filter((x) => {
          const date = new Date(x.name);
          return !(now - date > dayInMili * 8);
        })
        .reduce(function (a, b) {
          return a + b.clicks;
        }, 0);
      const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
      yesterday.setHours(0, 0, 0, 0);
      const yestrdayCount = clicks
        .filter((x) => {
          const date = new Date(x.name);
          date.setHours(0, 0, 0, 0);
          return yesterday - date === 0;
        })
        .reduce(function (a, b) {
          return a + b.clicks;
        }, 0);

      now.setHours(0, 0, 0, 0);
      const todayCount = clicks
        .filter((x) => {
          const date = new Date(x.name);
          date.setHours(0, 0, 0, 0);
          return now - date === 0;
        })
        .reduce(function (a, b) {
          return a + b.clicks;
        }, 0);

      clicksDates.total = numberWithCommas(total);
      clicksDates.lastMonth = numberWithCommas(lastMonth);
      clicksDates.lastWeek = numberWithCommas(lastWeek);
      clicksDates.yestrday = numberWithCommas(yestrdayCount);
      clicksDates.today = numberWithCommas(todayCount);
    }
    countries = segmentation.countries_segmentation;
    countries = countries
      ? countries.slice(0, 5).map((x) => {
          x.data = (x.clicks / total) * 100;
          return x;
        })
      : [];
    if (countries.length > 1) {
      countries = countries.sort(function (a, b) {
        a = a.clicks;
        b = b.clicks;
        return b - a;
      });
    }
  }

  return {
    devices,
    clicksDates,
    countries,
    devicesDataReturned: true,
    clicks_per_auction,
  };
};

export const roiCharts = (segmentation, isInfluencer) => {
  segmentation = segmentation || {};
  const { gendersData, agesData, followersCountries, followersCities } =
    segmentation;
  const hasData =
    gendersData || agesData || followersCities || followersCountries;
  if (hasData) {
    return (
      <div style={{ padding: "10px", overflow: "hidden" }}>
        {/* Engagement Statistics */}
        <div
          className={isInfluencer ? " influencer-roi" : ""}
          style={{ display: "flex" }}
        >
          {gendersData?.length ? (
            <div className="roi-eng-cube" style={{ marginLeft: 0, width: 275 }}>
              <div className="cube-title">Genders</div>
              <PieChart width={270} height={200}>
                <Pie
                  startAngle={90}
                  endAngle={450}
                  activeIndex={[0, 1, 2]}
                  activeShape={renderActiveShape}
                  data={gendersData}
                  cx={"50%"}
                  cy={"50%"}
                  innerRadius={45}
                  outerRadius={45}
                  dataKey={"value"}
                />
              </PieChart>
            </div>
          ) : null}

          {agesData?.length ? (
            <div className="roi-data-cube">
              <div className="cube-title">Ages %</div>
              {agesData.map((x, index) => {
                return (
                  <div className="line-data" key={index}>
                    <div className="line-horizontal-indicator" />
                    <RCTooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={<span>{x.data}%</span>}
                    >
                      <div
                        className="line-horizontal-active"
                        style={{ width: 170 * (x.data / 100) }}
                      />
                    </RCTooltip>
                    <div
                      className="small-title"
                      style={{ marginLeft: 0, paddingTop: 10 }}
                    >
                      <strong title={x.name}>{x.name}</strong>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {followersCountries?.length ? (
            <div className="roi-data-cube">
              <div className="cube-title">Countries %</div>
              {followersCountries.map((x, index) => {
                return (
                  <div className="line-data" key={index}>
                    <div className="line-horizontal-indicator" />
                    <RCTooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={<span>{x.data}%</span>}
                    >
                      <div
                        className="line-horizontal-active"
                        style={{ width: 170 * (x.data / 100) }}
                      />
                    </RCTooltip>
                    <div
                      className="small-title"
                      style={{ marginLeft: 0, paddingTop: 10 }}
                    >
                      <strong title={x.name}>{x.name}</strong>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {followersCities?.length ? (
            <div className="roi-data-cube">
              <div className="cube-title">Cities %</div>
              {followersCities.map((x, index) => {
                return (
                  <div className="line-data" key={index}>
                    <div className="line-horizontal-indicator" />
                    <RCTooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={<span>{x.data}%</span>}
                    >
                      <div
                        className="line-horizontal-active"
                        style={{ width: 170 * (x.data / 100) }}
                      />
                    </RCTooltip>
                    <div
                      className="small-title"
                      style={{ marginLeft: 0, paddingTop: 10 }}
                    >
                      <strong title={x.name}>{x.name}</strong>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};

export const clicksPerCampaigns = (clicks) => {
  if (!clicks) return null;

  return (
    <div className="roi-data-cube">
      <div className="cube-title" style={{ marginBottom: 5 }}>
        Total clicks per campaign
      </div>
      {clicks.map((c, i) => (
        <div key={i}>
          <div className="cube-row">
            <div className="cube-row-name" style={{ width: 120 }}>
              {c.name}
            </div>
            <div className="cube-row-value">
              {formatBigNumbers(c.clicks || 0)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const conversionsCharts = (
  clicksSegmentations,
  countPerLink,
  isInfluencer,
  fromMultiReport,
) => {
  const { devices, countries, clicksDates } = clicksSegmentations;
  const width = isInfluencer ? 260 : 170;
  if (!countPerLink?.length || (isInfluencer && !clicksDates?.total)) {
    return null;
  }
  return (
    <div className={isInfluencer ? " influencer-roi" : ""}>
      <div style={{ padding: "10px" }}>
        {/* Clicks Statistics */}
        <div style={{ display: "flex" }}>
          {devices?.length ? (
            <div className="roi-data-cube">
              <div className="cube-title">Devices</div>
              {devices.map((x, index) => {
                return (
                  <div className="line-data" key={index}>
                    <div
                      className="line-horizontal-indicator"
                      style={{ width }}
                    />
                    <RCTooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={<span>{x.data.toFixed(2)}%</span>}
                    >
                      <div
                        className="line-horizontal-active"
                        style={{ width: width * (x.data / 100) }}
                      />
                    </RCTooltip>
                    <div
                      className="small-title"
                      style={{ marginLeft: 0, paddingTop: 10 }}
                    >
                      <strong>{x.name}</strong>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {fromMultiReport &&
            clicksPerCampaigns(clicksSegmentations.clicks_per_auction)}

          {!fromMultiReport && clicksDates ? (
            <div className="roi-data-cube">
              <div className="cube-title" style={{ marginBottom: 5 }}>
                Summary
              </div>
              <div>
                <div className="cube-row">
                  <div
                    className="cube-row-name"
                    style={{ width: isInfluencer ? 180 : 120 }}
                  >
                    Total Clicks
                  </div>
                  <div className="cube-row-value">{clicksDates.total}</div>
                </div>
                <div className="cube-row">
                  <div
                    className="cube-row-name"
                    style={{ width: isInfluencer ? 180 : 120 }}
                  >
                    Last 30 days
                  </div>
                  <div className="cube-row-value">{clicksDates.lastMonth}</div>
                </div>
                <div className="cube-row">
                  <div
                    className="cube-row-name"
                    style={{ width: isInfluencer ? 180 : 120 }}
                  >
                    Last 7 days
                  </div>
                  <div className="cube-row-value">{clicksDates.lastWeek}</div>
                </div>
                <div className="cube-row">
                  <div
                    className="cube-row-name"
                    style={{ width: isInfluencer ? 180 : 120 }}
                  >
                    Yesterday
                  </div>
                  <div className="cube-row-value">{clicksDates.yestrday}</div>
                </div>
                <div className="cube-row">
                  <div
                    className="cube-row-name"
                    style={{ width: isInfluencer ? 180 : 120 }}
                  >
                    Today
                  </div>
                  <div className="cube-row-value">{clicksDates.today}</div>
                </div>
              </div>
            </div>
          ) : null}

          {countries?.length ? (
            <div className="roi-data-cube">
              <div className="cube-title">Top 5 Countries</div>
              {countries.map((x, index) => {
                return (
                  <div className="line-data" key={index}>
                    <div
                      className="line-horizontal-indicator"
                      style={{ width }}
                    />
                    <RCTooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={<span>{x.data.toFixed(2)}%</span>}
                    >
                      <div
                        className="line-horizontal-active"
                        style={{ width: width * (x.data / 100) }}
                      />
                    </RCTooltip>
                    <div
                      className="small-title"
                      style={{ marginLeft: 0, paddingTop: 10 }}
                    >
                      <strong>{x.name ? x.name : "Unknown"}</strong>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {!isInfluencer && (
            <div className="roi-data-cube">
              {countPerLink && countPerLink.length > 0 && (
                <div style={{ marginBottom: 5 }} className="cube-title">
                  Most Clicked Links
                </div>
              )}
              <div style={{ overflow: "auto", height: "213px" }}>
                {countPerLink?.length
                  ? countPerLink.map((x, index) => (
                      <div key={index} className="cube-row">
                        <div className="cube-row-name blurable">
                          {x.name ? x.name : "Unknown"}
                        </div>
                        <div className="cube-row-value">
                          {x.clicks ? numberWithCommas(x.clicks) : 0}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const youtubeCharts = (data) => {
  const { femalesAge, followersDataReturned, gendersData, malesAge } = data;
  if (!followersDataReturned) return null;
  return (
    <div
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        display: "flex",
      }}
    >
      <div className="roi-eng-cube" style={{ marginLeft: 0, width: 275 }}>
        <div className="cube-title">Genders</div>
        <PieChart width={270} height={200}>
          <Pie
            startAngle={90}
            endAngle={450}
            activeIndex={[0, 1, 2]}
            activeShape={renderActiveShape}
            data={gendersData}
            cx={"50%"}
            cy={"50%"}
            innerRadius={45}
            outerRadius={45}
            dataKey={"value"}
          />
        </PieChart>
      </div>

      <div className="roi-data-cube">
        <div className="cube-title">Male Ages (%)</div>
        {malesAge.map((x, index) => {
          return (
            <div className="line-data" key={index}>
              <div className="line-horizontal-indicator" />
              <RCTooltip
                placement="top"
                trigger={["hover"]}
                overlay={<span>{x.data}%</span>}
              >
                <div
                  className="line-horizontal-active"
                  style={{ width: 170 * (x.data / 100) }}
                />
              </RCTooltip>
              <div
                className="small-title"
                style={{ marginLeft: 0, paddingTop: 10 }}
              >
                <strong title={x.name}>{x.name}</strong>
              </div>
            </div>
          );
        })}
      </div>
      <div className="roi-data-cube">
        <div className="cube-title">Female Ages (%)</div>
        {femalesAge.map((x, index) => {
          return (
            <div className="line-data" key={index}>
              <div className="line-horizontal-indicator" />
              <RCTooltip
                placement="top"
                trigger={["hover"]}
                overlay={<span>{x.data}%</span>}
              >
                <div
                  className="line-horizontal-active"
                  style={{ width: 170 * (x.data / 100) }}
                />
              </RCTooltip>
              <div
                className="small-title"
                style={{ marginLeft: 0, paddingTop: 10 }}
              >
                <strong title={x.name}>{x.name}</strong>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RenderDistributionChart = (props) => {
  const { data, color, label, auctionsData } = props;
  const [showPie, setShowPie] = useState(false);
  if (!data) return null;
  const colors = new Values(color);
  const chartData = data
    .map((d, idx) => {
      let label = d.label || "";
      if (auctionsData) {
        label = auctionsData.find((x) => x.id === d.auction_id)?.title;
      }
      const fullLabel = label;
      if (label?.length > 30) {
        label = `${label.substring(0, 30)}...`;
      }
      const p = d.percentage;
      return {
        label,
        value: d.percentage,
        fill: colors
          .tint(p / 50)
          .shade(p / 50)
          .rgbString(),
        fontSize: "small",
        fullLabel,
      };
    })
    .sort((a, b) => {
      return b.value - a.value;
    });
  return (
    <div className="roi-eng-cube" style={{ marginLeft: 0, width: "32%" }}>
      <div className="flex" style={{ justifyContent: "space-between" }}>
        <strong
          style={{
            color,
            display: "block",
            textAlign: "left",
            marginLeft: 20,
            marginBottom: -10,
          }}
        >
          {label}
        </strong>
        <button
          style={{
            borderRadius: 38,
            height: 28,
            marginTop: -5,
            marginRight: 5,
            padding: "5px 7px",
          }}
          className="btn btn-sm btn-primary"
          onClick={() => {
            setShowPie(!showPie);
          }}
        >
          <i className="fas fa-chart-pie" />
        </button>
      </div>
      <div
        style={{
          padding: "0 20px",
          overflowY: "auto",
          height: "180px",
          marginTop: 15,
          width: "98%",
        }}
      >
        {chartData.map((d, i) => {
          d.fill2 = convertHexToRGBA(d.fill, 70);
          return (
            <RCTooltip
              placement={"left"}
              key={i}
              overlay={
                <div>
                  <text className="blurable">{d.fullLabel}</text> - {d.value}%
                </div>
              }
            >
              <div className={"single-vertical-chart-line"}>
                <div
                  className={"chart-line-label"}
                  style={{
                    color,
                    display: "block",
                    textAlign: "left",
                    fontSize: 13,
                  }}
                >
                  <strong className="blurable">{d.label}</strong>
                </div>
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className={"chart-percentage"} style={{ color }}>
                    {d.value}%
                  </div>
                  <div className={"outer-chart-line"}>
                    <div
                      className={"inner-chart-line"}
                      style={{
                        borderRadius: 18,
                        width: `${String(d.value ? d.value : 1)}%`,
                        height: "100%",
                        background: `linear-gradient(45deg , ${d.fill2}, ${d.fill})`,
                        fontSize: 14,
                        lineHeight: "18px",
                        animation: "slideInLeft 1.2s",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </RCTooltip>
          );
        })}
      </div>
      {showPie && renderPiDialog(data, color, label, auctionsData)}
    </div>
  );
};

export const renderPiDialog = (data, color, label, auctionsData, show) => {
  if (!data) return null;
  return (
    <DistributionPieChart
      data={data}
      color={color}
      label={label}
      auctionsData={auctionsData}
      show={show}
    />
  );
};

export const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ background: "white", height: 40 }}
      >
        <div className="label">
          <p className="blurable">{payload[0].payload.fullLabel}</p> :{" "}
          {payload[0].value}%
        </div>
      </div>
    );
  }

  return null;
};

const convertHexToRGBA = (hex, opacity) => {
  let r = 0;
  let g = 0;
  let b = 0;
  // detect RGB
  if (hex.substring(0, 3) === "rgb") {
    let rgb_vals = hex
      .replace("rgb(", "")
      .replace("rgba(", "")
      .replace(")", "");
    rgb_vals = rgb_vals.split(",");
    [r, g, b] = rgb_vals;
  } else {
    let tempHex = hex.replace("#", "");
    if (tempHex.length === 3) {
      tempHex += tempHex;
    }
    r = parseInt(tempHex.substring(0, 2), 16);
    g = parseInt(tempHex.substring(2, 4), 16);
    b = parseInt(tempHex.substring(4, 6), 16);
  }
  return `rgba(${r},${g},${b},${opacity / 100})`;
};
