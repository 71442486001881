// TODO - lint
/* eslint-disable prefer-regex-literals, prefer-regex-literals, prefer-regex-literals, prefer-regex-literals, global-require, react/no-direct-mutation-state, react/no-deprecated, no-param-reassign, global-require, no-param-reassign, no-cond-assign, no-shadow, no-prototype-builtins, react/no-direct-mutation-state, class-methods-use-this, react/no-direct-mutation-state, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, no-underscore-dangle, react/no-direct-mutation-state, class-methods-use-this, react/no-string-refs, no-return-assign, no-underscore-dangle, no-return-assign, no-underscore-dangle, no-return-assign, no-underscore-dangle, no-return-assign, no-underscore-dangle, react/no-string-refs, camelcase, import/no-default-export */
// General Imports
import produce from "immer";
import React from "react";
import Cards from "react-credit-cards";
import ReactPhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import Select from "react-select";
import {
  finalizePeachTokenize,
  tokenizeCreditCard,
  tokenizeYaadCreditCard,
} from "../../../actions/paymentActions";
// Components Import
// Actions imports
import {
  editUserBillingAddress,
  getUserBillingAddress,
} from "../../../actions/userActions";
import Logo from "../../../images/humanz-logo.png";
import {
  Currencies,
  CURRENCY_TO_COUNTRY,
  OnlyNumbersRegex,
  usStates,
} from "../utilities/general";
import { YaadCreditCard } from "./YaadCreditCard";

// import {  numberWithCommas } from '../utilities/general';

class CreditCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardDetails: {
        number: "",
        name: "",
        expiry: "",
        cvc: "",
        currency: "",
        focused: null,
      },
      cardAddress: {
        address: "",
        address_line_2: "",
        city: "",
        country: "",
        zip: "",
        phone: "",
        tax_number: "",
      },
      loadingAddress: true,
      card_saving: false,
      error: "",
      ccAuthRedirectPage: false,
      ccAuthRedirectData: null,
    };

    this.cc_regexes = {
      number: new RegExp("^\\d{8,19}$"),
      name: new RegExp("^.{2,32}$"),
      expiry: new RegExp("^(0[1-9]|1[0-2])/?([0-9]{4}|[0-9]{2})$"),
      cvv: new RegExp("^\\d{3}$"),
    };
    this.countries = require("../utilities/countries.json");
    this.countries = this.countries.data;

    this.PRICE_PER_MONTH = {
      USD: "1,500",
      NIS: "5,500",
      GBP: "1,150",
      EUR: "1,350",
      ZAR: "19,950",
      AUD: "2,200",
      NZD: "2,300",
      TRY: "8,500",
    };
  }

  setCurrency(country) {
    const currency = CURRENCY_TO_COUNTRY[country] || "USD";
    this.state.cardDetails.currency = currency;
    this.setState({});
  }

  componentWillMount() {
    this.props.getUserBillingAddress((data) => {
      if (data) {
        const details = this.props.user.userDetails;
        if (!data.country) {
          data.country = details.company_details.country;
        }

        this.setCurrency(data.country);

        if (!data.phone) {
          const codeCountries = require("../utilities/countries-codes.json");
          let code = data.country
            ? codeCountries.find((x) => x.code === data.country)
            : null;
          code = code ? code.dialCode : "972";
          data.phone = `+${code}`;
        }
        this.setState({ cardAddress: data, loadingAddress: false });
      }
    });
  }

  componentDidMount() {
    let queryParams;
    if ((queryParams = sessionStorage.getItem("PEACH_PAYMENTS_DETAILS"))) {
      queryParams = JSON.parse(queryParams);
      const humanzTransactionId = queryParams.transactionId;
      const peachId = queryParams.id;
      if (humanzTransactionId && peachId) {
        this.props.finalizePeachTokenize(
          humanzTransactionId,
          peachId,
          (response) => {
            this.setState({
              ccAuthRedirectPage: false,
              ccAuthRedirectData: undefined,
            });
            this.handleTokenizationResponse(response);
          },
        );
      }
      sessionStorage.removeItem("PEACH_PAYMENTS_DETAILS");
      sessionStorage.removeItem("PAYMENTS_PAGE");
    }
  }

  goBack = () => {
    this.props.goBack();
  };

  tokenizeCreditCard = (yaadRedirect) => {
    if (this.state.card_saving) return;
    this.setState({ card_saving: true, error: "" });

    this.props.editUserBillingAddress(this.state.cardAddress, (data) => {
      if (data) {
        this.setState({ cardAddress: data });
        if (yaadRedirect) {
          this.props.tokenizeYaadCreditCard(
            yaadRedirect,
            this.props.user.userDetails.user_id,
            (data) => {
              if (data) {
                this.handleTokenizationResponse(data);
              } else {
                this.setState({
                  card_saving: false,
                  error: "failed_save_billing_address",
                });
                this.setCurrency(
                  this.props.user.userDetails.company_details.country,
                );
              }
            },
          );
        } else {
          this.props.tokenizeCreditCard(
            this.props.user.userDetails,
            this.state.cardDetails,
            this.state.cardAddress,
            (response) => {
              this.handleTokenizationResponse(response);
            },
          );
        }
      } else {
        this.setState({
          card_saving: false,
          error: "failed_save_billing_address",
        });
        this.setCurrency(this.props.user.userDetails.company_details.country);
      }
    });
  };

  handleTokenizationResponse(response) {
    if (response) {
      if (
        response.hasOwnProperty("redirect") &&
        undefined !== response.redirect
      ) {
        sessionStorage.setItem(
          "PEACH_PARAMS",
          JSON.stringify({
            ccAuthRedirectPage: true,
            ccAuthRedirectData: response.redirect,
          }),
        );
        sessionStorage.setItem(
          "PAYMENTS_PAGE",
          JSON.stringify({
            page: window.location.pathname,
            state: this.props.state,
          }),
        );
        window.location = "/payments/peach";
        return;
      }
      if (this.props.onSuccess) this.props.onSuccess(response);
    } else {
      this.setState({ error: "failed_saving_card", card_saving: false });
    }
  }

  appendSlashIfNeeded = (e) => {
    let str = e.target.value;
    if (str.length === 2 && !str.includes("/")) {
      str += "/";
    }
    this.state.cardDetails.expiry = str.substring(0, 5);
    this.setState({});
  };

  allowSavingCreditCardClassNames = () => {
    let ans = "btn btn-primry ";
    if (
      !this.validCard() ||
      !this.isBillingAddressValid() ||
      this.state.card_saving
    ) {
      ans += "disabled";
    }
    return ans;
  };

  getPaymentProviderLogoByCountry(country) {
    switch (country) {
      case "Australia":
      case "New Zealand":
        return "/images/payment_providers/pin_logo.png";
      case "South Africa":
        return "/images/payment_providers/peach_logo.png";
      default:
        return "/images/payment_providers/yaad_logo_2.png";
    }
  }

  changeBillingState = (value) => {
    this.setState(
      produce((draftState) => {
        draftState.cardAddress.state = value;
      }),
    );
  };

  changeBillingCountry(value) {
    this.state.cardAddress.country = value;
    if (value !== "United States") {
      delete this.state.cardAddress.state;
    }

    this.setCurrency(value);
  }

  validCard = () => {
    if (
      this._cardNumberInput &&
      this._cardNumberInput.value &&
      this._cardNameInput &&
      this._cardNameInput.value &&
      this._cardExpiryInput &&
      this._cardExpiryInput.value &&
      this._cardCvvInput &&
      this._cardCvvInput.value
    ) {
      return (
        this.cc_regexes.number.test(this._cardNumberInput.value) &&
        this.cc_regexes.name.test(this._cardNameInput.value) &&
        this.cc_regexes.expiry.test(this._cardExpiryInput.value) &&
        this.cc_regexes.cvv.test(this._cardCvvInput.value)
      );
    }

    return false;
  };

  isBillingAddressValid = () => {
    const address = this.state.cardAddress;
    if (
      address.address &&
      address.city &&
      address.country &&
      (address.country !== "United States" || address.state) &&
      address.phone &&
      address.phone.length > 3 &&
      address.tax_number &&
      address.zip
    ) {
      return true;
    }
    return false;
  };

  cardBlur = () => {
    this.state.cardDetails.focused = null;
    this.setState({});
  };

  renderRedirectParam = (param) => {
    return <input type="hidden" name={param.name} value={param.value} />;
  };

  render() {
    const { cardDetails, cardAddress, loadingAddress } = this.state;

    if (
      this.props.searchParams &&
      this.props.searchParams.has("transactionId") &&
      this.props.searchParams.has("id")
    ) {
      return (
        <div className="logo-loader">
          <div className="loader" />
          <img src={Logo} />
        </div>
      );
    }

    return (
      <div
        className="create-tab-content"
        style={{ background: "#F5F5F5", padding: 20, width: "100%" }}
      >
        {!loadingAddress && (
          <div className="payments-method-section">
            <div className="campaigns-title">
              <span>Payment Address</span>
            </div>
            <div style={{ marginTop: 25 }}>
              <div className="input-row">
                <strong className="label-title">Address Line</strong>
                <div>
                  <input
                    className="input-line"
                    style={{ height: 35 }}
                    value={cardAddress.address}
                    onChange={(e) => {
                      cardAddress.address = e.target.value;
                      this.setState({});
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="input-row" style={{ width: "45%" }}>
                  <strong className="label-title">
                    Address Line 2{" "}
                    <span className="optional-title">(Optional)</span>
                  </strong>
                  <div>
                    <input
                      className="input-line"
                      style={{ height: 35 }}
                      value={cardAddress.address_line_2}
                      onChange={(e) => {
                        cardAddress.address_line_2 = e.target.value;
                        this.setState({});
                      }}
                    />
                  </div>
                </div>
                <div className="input-row" style={{ width: "45%" }}>
                  <strong className="label-title">ZIP</strong>
                  <div>
                    <input
                      className="input-line"
                      style={{ height: 35 }}
                      value={cardAddress.zip}
                      onChange={(e) => {
                        cardAddress.zip = e.target.value;
                        this.setState({});
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10%",
                }}
              >
                <div className="input-row" style={{ flex: 1 }}>
                  <strong className="label-title">City</strong>
                  <div className="searchable-input">
                    <div>
                      <input
                        className="input-line"
                        style={{ height: 35 }}
                        value={cardAddress.city}
                        onChange={(e) => {
                          cardAddress.city = e.target.value;
                          this.setState({});
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-row" style={{ flex: 1 }}>
                  <strong className="label-title">Country</strong>
                  <div className="searchable-input">
                    <Select
                      ref="stateSelect"
                      options={this.countries}
                      placeholder={""}
                      value={cardAddress.country}
                      simpleValue
                      clearable={false}
                      onChange={(value) => this.changeBillingCountry(value)}
                      name="selected-state"
                      disabled={false}
                      searchable={true}
                    />
                  </div>
                </div>
                {cardAddress.country === "United States" ? (
                  <div className="input-row" style={{ flex: 1 }}>
                    <strong className="label-title">State</strong>
                    <div className="searchable-input">
                      <Select
                        ref="stateSelect"
                        options={usStates}
                        placeholder={""}
                        value={cardAddress.state}
                        simpleValue
                        clearable={false}
                        onChange={this.changeBillingState}
                        name="selected-state"
                        disabled={false}
                        searchable={true}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="input-row" style={{ width: "45%" }}>
                  <strong className="label-title">PHONE NUMBER</strong>
                  <ReactPhoneInput
                    inputStyle={{ width: "100%", paddingLeft: 45 }}
                    inputClass="input-line"
                    value={cardAddress.phone}
                    defaudefaultCountry={cardAddress.country}
                    onChange={(e) => {
                      cardAddress.phone = e;
                      this.setState({});
                    }}
                  />
                </div>
                <div className="input-row" style={{ width: "45%" }}>
                  <strong className="label-title">TAX / VAT NUMBER</strong>
                  <div>
                    <input
                      className="input-line"
                      style={{ height: 35 }}
                      value={cardAddress.tax_number}
                      onChange={(e) => {
                        cardAddress.tax_number = e.target.value;
                        this.setState({});
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.error === "failed_save_billing_address" && (
              <div className="error-text">
                Failed to save your billing address. Please Check your details
                and try again
              </div>
            )}
          </div>
        )}

        <div className="payments-method-section">
          <div className="campaigns-title">
            <span>Payment Method</span>
          </div>
          <div style={{ display: "flex", marginTop: 25 }}>
            {cardAddress.country &&
            cardAddress.country.toLowerCase() !== "south africa" ? (
              <YaadCreditCard
                onRedirect={(url) => {
                  this.tokenizeCreditCard(url);
                }}
                cardAddress={cardAddress}
                cardDetails={cardDetails}
              />
            ) : (
              <>
                <div style={{ width: "60%", marginRight: "7%" }}>
                  <div className="input-row">
                    <strong className="label-title">card number</strong>
                    <div>
                      <input
                        type={""}
                        ref={(d) => (this._cardNumberInput = d)}
                        className="input-line"
                        style={{ height: 35 }}
                        onFocus={() => {
                          cardDetails.focused = "number";
                          this.setState({});
                        }}
                        onBlur={this.cardBlur}
                        value={cardDetails.number}
                        onChange={(e) => {
                          if (OnlyNumbersRegex.test(e.target.value)) {
                            cardDetails.number = e?.target?.value?.trim();
                          }
                          this.setState({});
                        }}
                      />
                    </div>
                  </div>

                  <div className="input-row">
                    <strong className="label-title">card name</strong>
                    <div>
                      <input
                        ref={(c) => (this._cardNameInput = c)}
                        className="input-line"
                        style={{ height: 35 }}
                        onFocus={() => {
                          cardDetails.focused = "name";
                          this.setState({});
                        }}
                        onBlur={this.cardBlur}
                        defaultValue={cardDetails.name}
                        onChange={(e) => {
                          cardDetails.name = e.target.value;
                          this.setState({});
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="input-row" style={{ width: "45%" }}>
                      <strong className="label-title">Expiry Date</strong>
                      <div>
                        <input
                          ref={(b) => (this._cardExpiryInput = b)}
                          className="input-line"
                          style={{ height: 35 }}
                          placeholder="MM/YY"
                          onFocus={() => {
                            cardDetails.focused = "expiry";
                            this.setState({});
                          }}
                          onBlur={this.cardBlur}
                          value={cardDetails.expiry}
                          onChange={this.appendSlashIfNeeded}
                        />
                      </div>
                    </div>

                    <div
                      className="input-row"
                      style={{ width: "45%", marginLeft: "10%" }}
                    >
                      <strong className="label-title">cvv code</strong>
                      <div>
                        <input
                          ref={(a) => (this._cardCvvInput = a)}
                          className="input-line"
                          style={{ height: 35 }}
                          onFocus={() => {
                            cardDetails.focused = "cvc";
                            this.setState({});
                          }}
                          onBlur={this.cardBlur}
                          value={cardDetails.cvc}
                          onChange={(e) => {
                            cardDetails.cvc = e.target.value.substring(0, 4);
                            this.setState({});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Cards
                    number={cardDetails.number}
                    name={cardDetails.name}
                    expiry={cardDetails.expiry}
                    cvc={cardDetails.cvc}
                    focused={cardDetails.focused}
                  />
                </div>
              </>
            )}
          </div>
          {this.props.selectedPackage === "premium" ? (
            <div style={{ marginTop: 20, display: "flex" }}>
              <div className="input-row">
                <strong className="label-title">PAYMENT CURRENCY</strong>
                <div>
                  <div style={{ width: 200 }} className={"searchable-input "}>
                    <Select
                      ref="stateSelect"
                      options={Currencies.filter((x) => {
                        if (
                          cardAddress.country !== "South Africa" &&
                          x.value === "ZAR"
                        ) {
                          return false;
                        }
                        return true;
                      })}
                      placeholder={""}
                      value={cardDetails.currency}
                      simpleValue
                      clearable={false}
                      onChange={(value) => {
                        cardDetails.currency = value;
                        this.setState({});
                      }}
                      name="selected-state"
                      disabled={false}
                      searchable={true}
                    />
                  </div>
                </div>
              </div>

              <div className="payment-price-container">
                Price Per Month{" "}
                {window.translate("", `money_symbol_${cardDetails.currency}`)}{" "}
                {this.PRICE_PER_MONTH[cardDetails.currency]}
              </div>

              {this.getPaymentLogosDiv(this.props.selectedPackage)}
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {this.getPaymentLogosDiv(this.props.selectedPackage)}
            </div>
          )}
          {this.state.error === "failed_saving_card" && (
            <div className="error-text">
              Failed to add card. Please check your card details and try again.
            </div>
          )}
        </div>
        {this.props.showButtons && (
          <div className="create-buttons-row">
            <button className="btn btn-primry" onClick={this.goBack}>
              Back
            </button>
            <button
              className={this.allowSavingCreditCardClassNames()}
              onClick={() => this.tokenizeCreditCard()}
            >
              Save
            </button>
          </div>
        )}
      </div>
    );
  }

  getPaymentLogosDiv = (packageSelected) => {
    return (
      this.state.cardDetails.currency !== "NIS" && (
        <div id={packageSelected === "premium" ? "payment-provider-logos" : ""}>
          <img
            className="payment-provider-logo"
            src={this.getPaymentProviderLogoByCountry(
              this.state.cardAddress.country,
            )}
          />
          <img
            className="payment-provider-logo"
            src={"/images/payment_providers/visa2.png"}
            style={{ maxWidth: 60, marginLeft: 15 }}
          />
          <img
            className="payment-provider-logo"
            src={"/images/payment_providers/mastercard_logo.png"}
          />
        </div>
      )
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tokenizeCreditCard: (
      userDetails,
      cardDetails,
      billingAddress,
      callback,
    ) => {
      dispatch(
        tokenizeCreditCard(userDetails, cardDetails, billingAddress, callback),
      );
    },
    finalizePeachTokenize: (humanzTransactionId, peachId, callback) => {
      dispatch(finalizePeachTokenize(humanzTransactionId, peachId, callback));
    },
    getUserBillingAddress: (callback) => {
      dispatch(getUserBillingAddress(callback));
    },
    editUserBillingAddress: (data, callback) => {
      dispatch(editUserBillingAddress(data, callback));
    },
    tokenizeYaadCreditCard: (redirectUrl, adv_id, callback) => {
      dispatch(tokenizeYaadCreditCard(redirectUrl, adv_id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CreditCardForm);
