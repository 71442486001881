// import { sampleSize } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AttachLinksDialogTabStoriesControlled } from "./AttachLinksDialogTabStoriesControlled";
import { useFbStories } from "./IgStoriesHooks";

export const AttachLinksDialogTabStories = ({
  selectedStories,
  fbApi,
  data,
  onDataChange,
}: Exclude<
  React.ComponentProps<typeof AttachLinksDialogTabStoriesControlled>,
  "linked"
> & {
  fbApi?: {
    influencerId: number;
    onMissingPermission: () => void;
  };
}) => {
  const [range, setRange] = useState({
    since: moment().startOf("day"),
    until: moment().endOf("day"),
    caption: null,
  });

  const res = useFbStories(
    {
      influencerId: fbApi?.influencerId ?? 1,
      since: range.since,
      until: range.until,
      caption: range.caption,
    },
    {
      enabled: !!fbApi,
    },
  );

  useEffect(() => {
    if (fbApi && res.isMissingPermissions) {
      fbApi.onMissingPermission();
    }
  }, [fbApi, res.isMissingPermissions]);

  return (
    <AttachLinksDialogTabStoriesControlled
      linked={
        fbApi
          ? {
              range,
              onRangeChange: setRange,
              onRequestRefresh: res.refresh,
              paginatedStories: {
                data:
                  res.data?.map((x) => ({
                    id: x.media_id,
                    data: {
                      mediaType: x.video_url ? "video" : "image",
                      mediaUrl: x.video_url ?? x.image_url,
                      saved: 0,
                      shares: 0,
                      reach: 0,
                      taps_back: 0,
                      taps_forward: 0,
                      swipes_forward: 0,
                      media_creation_time: null,
                    },
                  })) ?? [],
                isFetching: res.isLoading,
                fetchMore: res.fetchNextPage,
                hasMore: res.hasNextPage ?? false,
                isRefreshing: res.isRefreshing,
              },
            }
          : undefined
      }
      selectedStories={selectedStories}
      data={data}
      onDataChange={onDataChange}
    />
  );
};
