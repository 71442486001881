/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Button } from "@chakra-ui/react";
import Dialog from "rc-dialog";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
// eslint-disable-next-line import-access/jsdoc
import { getMoiUpdate_center } from "src/api/services";
import { VersionUpdate } from "src/api/types";

type Props = {
  handleClose: any;
};

const UpdatesDialog = (props: Props) => {
  const divRef = useRef(null);

  const [updates, setUpdates] = useState<VersionUpdate[]>([]);
  const [selectedUpdateIndex, setSelectedUpdateIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const getUpdates = () => {
    const lastUpdate = updates.length ? updates[updates.length - 1].id : null;
    setIsLoading(true);
    getMoiUpdate_center({ from_update: lastUpdate })
      .then((res) => {
        if (res.data && res.data?.length) {
          let allUpdates = [...updates];
          allUpdates = allUpdates.concat(res.data);
          setUpdates(allUpdates);
        }
      })
      .catch((e) => {
        console.log("Unable to get updates", e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const nextUpdate = () => {
    setSelectedUpdateIndex(selectedUpdateIndex + 1);
  };

  const prevUpdate = () => {
    setSelectedUpdateIndex(selectedUpdateIndex - 1);
  };

  useEffect(() => {
    getUpdates();
  }, []);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (selectedUpdateIndex + 1 === updates.length) {
      getUpdates();
    }
  }, [selectedUpdateIndex]);

  return (
    <Dialog
      onClose={props.handleClose}
      className="updates-dialog"
      style={{ width: 800 }}
      visible={true}
      animation="slide-fade"
      maskAnimation="fade"
    >
      <div>
        <div className="edit-tasks-header">Updates</div>
        <div
          ref={divRef}
          style={{ height: "80vh", overflowY: "auto", padding: 10 }}
        >
          {isLoading && !updates?.length ? (
            <div
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                className="spinner small"
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
              </div>
            </div>
          ) : (
            <Box
              sx={{
                minHeight: "calc(100% - 60px)",
                background: "#fafafa",
                borderRadius: 12,
                padding: 15,
                margin: "12px 0",
              }}
            >
              {updates?.length ? (
                <ReactMarkdown allowDangerousHtml={true}>
                  {updates[selectedUpdateIndex] &&
                  updates[selectedUpdateIndex].markdown?.length
                    ? updates[selectedUpdateIndex].markdown
                    : "This update seems to be empty..."}
                </ReactMarkdown>
              ) : (
                "No updates yet..."
              )}
            </Box>
          )}
          {isLoading && !updates?.length ? null : (
            <div
              className="footer-buttons center"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                style={{ marginLeft: 10, alignSelf: "flex-start" }}
                leftIcon={<i className="fa fa-arrow-left" />}
                className={`${isLoading && "disabled"}`}
                onClick={nextUpdate}
                isLoading={isLoading}
                isDisabled={selectedUpdateIndex >= updates.length - 1}
              >
                <span>Older update</span>
              </Button>

              {selectedUpdateIndex > 0 ? (
                <Button
                  variant={"outline"}
                  rightIcon={<i className="fa fa-arrow-right" />}
                  style={{ marginLeft: 10, alignSelf: "flex-end" }}
                  className={`${isLoading && "disabled"}`}
                  onClick={prevUpdate}
                >
                  <span>Newer update</span>
                </Button>
              ) : (
                <div />
              )}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpdatesDialog;
