import {
  ButtonGroup,
  CheckboxGroup,
  CheckboxGroupProps,
  Divider,
} from "@chakra-ui/react";
import React from "react";

export const CheckboxButtonGroup = ({
  children,
  ...otherProps
}: CheckboxGroupProps) => {
  const childrenArray = React.Children.toArray(children);

  const lastIndex = childrenArray.length - 1;
  const childrenWithDivider = childrenArray.reduce(
    (agg, child, index) =>
      [...(agg as (typeof child)[]), child].concat(
        index !== lastIndex ? (
          <Divider
            key={index}
            orientation="vertical"
            borderColor="brand.500"
            h="initial"
            opacity="initial"
          />
        ) : (
          []
        ),
      ),
    [],
  );
  return (
    <CheckboxGroup {...otherProps}>
      <ButtonGroup
        isAttached
        __css={{
          display: "flex",
          alignItems: "strech",
          "> *:nth-child(odd):first-of-type:not(:last-of-type)": {
            borderEndRadius: 0,
            borderEndWidth: 0,
          },
          "> *:nth-child(odd):not(:first-of-type):not(:last-of-type)": {
            borderRadius: 0,
            borderStartWidth: 0,
            borderEndWidth: 0,
          },
          "> *:nth-child(odd):not(:first-of-type):last-of-type": {
            borderStartRadius: 0,
            borderStartWidth: 0,
          },
        }}
      >
        {childrenWithDivider}
      </ButtonGroup>
    </CheckboxGroup>
  );
};
