/* eslint-disable no-param-reassign */
import { Box, Button, Flex } from "@chakra-ui/react";
import moment from "moment";
import momenttz from "moment-timezone";
import DatePicker from "react-datepicker";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Toggle from "react-toggle";
import NoDataMessage from "src/components/feed_components/general_components/NoDataMessage";
import HumanzRangePicker from "src/ui/humanz/HumanzRangePicker";
import uuidv4 from "uuid/v4";
import SelectDebounced from "../../CampaignPhases/SelectDebounced";
import AccordionUI from "../FormUI/AccordionUI";
import AddButton from "../FormUI/AddButton";
import FormCard from "../FormUI/FormCard";
import Indicator from "../FormUI/Indicator";
import IndicatorInput from "../FormUI/IndicatorInput";
import IndicatorSelect from "../FormUI/IndicatorSelect";

type Props = {
  state: any;
  setState: any;
  errorFields: any;
  sales: any;
  payments: any;
  CURRENCIES_LIST: any;
  addOrRemoveError: any;
  isSurvey: any;
  editMode: any;
  isShare: any;
  shortLinkAvaliable: any;
  selectedWebsite: any;
  shouldDisableTogglePixel: any;
  renderNotesBtn: any;
  fields: any;
  websites: any;
  onChangeTabShowErrors: any;
  children?: any;
};

const BreifSales = (props: Props) => {
  const handleCommissionBlur = (commission: any) => {
    if (commission >= 50) {
      props.setState({ showConfirmCommissionDialog: true });
    }
  };

  const addSuggestionLink = () => {
    props.state.fields.sales.suggestion_links.push({
      uuid: uuidv4(),
      link: "",
      description: "",
      is_deleted: false,
      new: true,
    });
    props.setState({ updated: true });
  };

  const removeSuggestionLink = (uuid: any) => {
    const index = props.state.fields.sales.suggestion_links.findIndex(
      (x: any) => x.uuid === uuid,
    );
    if (props.state.fields.sales.suggestion_links[index].new) {
      props.state.fields.sales.suggestion_links.splice(index, 1);
    } else {
      props.state.fields.sales.suggestion_links[index].is_deleted = true;
    }
    props.setState({ updated: true });
  };
  const handleBrandsChange = (newValue: any) => {
    if (newValue) {
      if (typeof newValue === "string") {
        props.state.fields.sales.brands_included = newValue.split(",");
      } else {
        props.state.fields.sales.brands_included = newValue.map(
          (x: any) => x.value,
        );
      }
    } else {
      props.state.fields.sales.brands_included = [];
    }
    props.setState({});
  };
  const handleSkusChange = (newValue: any) => {
    const skusIncOrEx = props.state.isSkusIncluded
      ? "skus_included"
      : "skus_excluded";
    if (newValue) {
      if (typeof newValue === "string") {
        props.state.fields.sales[skusIncOrEx] = newValue.split(",");
      } else {
        props.state.fields.sales[skusIncOrEx] = newValue.map(
          (x: any) => x.value,
        );
      }
    } else {
      props.state.fields.sales[skusIncOrEx] = [];
    }
    props.setState({});
  };

  if (!props?.websites?.length) {
    return (
      <FormCard>
        <div className="products-overview-section">
          <div className="create-campaign-title">
            <span>Sales</span>
          </div>
          <Box mt={3}>
            <NoDataMessage showIcon={true}>
              <p style={{ fontSize: 18 }}>
                You don&apos;t have any Humanz pixel tracking codes installed on
                any of your websites. <br />
                Please contact us in order to add a new tracking pixel.
              </p>
            </NoDataMessage>
          </Box>
          <Box mt={5}>
            <Button
              variant={""}
              leftIcon={<i className="fas fa-arrow-left" />}
              onClick={() => props.setState({ currentTab: "type_tab" })}
            >
              Back to type selection
            </Button>
          </Box>
        </div>
      </FormCard>
    );
  }
  return (
    <FormCard>
      <div className="products-overview-section">
        <div className="create-campaign-title">
          <span>Sales</span>
          {props.renderNotesBtn(7)}
        </div>
        <AccordionUI header={`Pixel setup`} style={{ marginTop: "24px" }}>
          {props.shouldDisableTogglePixel ? (
            <div className={"alert-info"} style={{ margin: "0px" }}>
              <i className="fa fa-info-circle" /> Some fields can&apos;t be
              edited after sales have started
            </div>
          ) : null}
          <div className="campaign-fields">
            {props.fields?.sales?.sales_enabled ? (
              <>
                {!props.isShare ? (
                  <div
                    aria-label={
                      props.shouldDisableTogglePixel
                        ? "Can't be edited once sales have started."
                        : "You won't be able to edit this field after sales start"
                    }
                  >
                    <IndicatorSelect
                      header={"Website link"}
                      description="Please Enter Your Website Link On Which Humanz Pixel Is
                    Installed"
                      disabled={
                        props.shouldDisableTogglePixel &&
                        !!props.fields?.sales?.pixel_app_id
                      }
                      options={props.websites}
                      placeholder={"Select website to track"}
                      defaultValue={props.selectedWebsite}
                      clearable={true}
                      labelKey={"website_url"}
                      valueKey={"app_id"}
                      onChange={(value: any) => {
                        props.fields.sales.pixel_app_id = value?.app_id || null;
                        props.setState({ updated: true }, () =>
                          props.onChangeTabShowErrors(props.state.currentTab),
                        );
                      }}
                      name="selected-state-pixel-website"
                      searchable={true}
                    />
                    {props.errorFields.includes("app_id") && (
                      <div>
                        <i className="error-mark" />
                        <span className="error-text">
                          Whoops! Please choose a website
                        </span>
                      </div>
                    )}
                    {(props.errorFields.includes("match_website_sales") ||
                      props.errorFields.includes("match_website")) && (
                      <div>
                        <i className="error-mark" />
                        <span className="error-text">
                          Whoops! Website must match{" "}
                          <span
                            className="link"
                            onClick={() =>
                              props.setState({ currentTab: "overview_tab" })
                            }
                          >
                            &quot;redirect to&quot; link on overview tab
                          </span>{" "}
                        </span>
                      </div>
                    )}
                  </div>
                ) : null}

                <Indicator
                  header="Enable pixel UTM links"
                  description="Use Google Analytics UTMs for better tracking"
                >
                  <div className="toggle-input">
                    <label>
                      <div className="toggle-label">
                        <div className="fa fa-times" />
                        <div className="fa fa-check" />
                      </div>
                      <div>
                        <Toggle
                          checked={props.fields?.sales?.link_google_utms}
                          icons={false}
                          onChange={() => {
                            props.fields.sales.link_google_utms =
                              !props.fields.sales.link_google_utms;
                            if (
                              props.fields.sales.link_google_utms &&
                              (!props.sales.utm_codes ||
                                !props.sales.utm_codes?.utm_source?.length ||
                                !props.sales.utm_codes?.utm_medium?.length)
                            ) {
                              props.sales.utm_codes = {
                                utm_source: "humanz",
                                utm_medium: "influencer",
                              };
                            }
                            props.setState({});
                          }}
                        />
                      </div>
                    </label>
                  </div>
                  {props.fields?.sales?.link_google_utms && (
                    <>
                      <div className="flex">
                        <div>
                          <strong className="input-title">UTM Source</strong>
                          <input
                            defaultValue={props.sales?.utm_codes?.utm_source}
                            required
                            onChange={(e) => {
                              if (
                                !props.sales.utm_codes ||
                                !props.sales.utm_codes?.utm_source?.length
                              ) {
                                props.sales.utm_codes = {
                                  utm_source: "humanz",
                                  utm_medium: "influencer",
                                };
                              }
                              props.sales.utm_codes.utm_source =
                                e?.target?.value?.trim();

                              props.setState({ updated: true });
                            }}
                            className="input-box"
                            type="text"
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            marginLeft: 10,
                          }}
                        >
                          <strong className="input-title">UTM Medium</strong>
                          <input
                            defaultValue={props?.sales.utm_codes?.utm_medium}
                            required
                            onChange={(e) => {
                              if (
                                !props.sales.utm_codes ||
                                !props.sales.utm_codes?.utm_medium?.length
                              ) {
                                props.sales.utm_codes = {
                                  utm_source: "humanz",
                                  utm_medium: "influencer",
                                };
                              }
                              props.sales.utm_codes.utm_medium =
                                e?.target?.value?.trim();
                              props.setState({ updated: true });
                            }}
                            className="input-box"
                            type="text"
                          />
                        </div>
                      </div>
                      {props.errorFields.includes("utm_codes") && (
                        <div>
                          <i className="error-mark" />
                          <span className="error-text">
                            UTM Source and Medium can&apos;t be empty
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </Indicator>
                <Indicator
                  header="Sales tracking dates"
                  description="These dates refer particularly to sales tracking"
                >
                  <div style={{ margin: 10 }} className="flex">
                    <div
                      style={{ position: "relative" }}
                      aria-label={
                        props.shouldDisableTogglePixel
                          ? "Can't be edited once sales have started."
                          : "You won't be able to edit this field after sales start"
                      }
                    >
                      <span className="datepicker-subtitle">Start time</span>
                      <div
                        onClick={() =>
                          props.shouldDisableTogglePixel
                            ? null
                            : props.setState({ showDatePicker: true })
                        }
                      >
                        <div
                          className="datepicker-box"
                          style={{ width: "210px" }}
                        >
                          <DatePicker
                            readOnly={true}
                            className="borderless-datepicker"
                            isClearable={false}
                            placeholderText="DD/MM/YYYY"
                            dateFormat="DD/MM/YYYY"
                            selected={props.fields?.sales?.sales_start_time}
                            onChange={(date: any) => {
                              props.fields.sales.sales_start_time = date;
                              props.fields.tasks.campaign_dates.start = date;
                              props.setState({ updated: true }, () => {
                                props.addOrRemoveError(
                                  "sales_start_time",
                                  "remove",
                                );
                              });
                            }}
                            disabled={
                              props.shouldDisableTogglePixel &&
                              props.fields?.sales?.sales_start_time !== null
                            }
                          />
                          <i
                            className="fa-duotone fa-calendar-days fa-lg"
                            style={{ color: "grey" }}
                          />
                        </div>
                      </div>

                      {props.errorFields.includes("sales_start_time") && (
                        <div>
                          <i className="error-mark" />
                          <span className="error-text">
                            Whoops! This field can not be empty
                          </span>
                        </div>
                      )}
                    </div>
                    <div style={{ position: "relative", marginLeft: 16 }}>
                      <span className="datepicker-subtitle">End time</span>
                      <div
                        onClick={() => props.setState({ showDatePicker: true })}
                      >
                        <div
                          className="datepicker-box"
                          style={{ width: "210px" }}
                        >
                          <DatePicker
                            isClearable={false}
                            readOnly={true}
                            placeholderText="DD/MM/YYYY"
                            dateFormat="DD/MM/YYYY"
                            minDate={props.fields?.sales?.sales_start_time}
                            selected={props.fields?.sales?.sales_end_time}
                            onChange={(date: any) => {
                              props.fields.sales.sales_end_time = date;
                              props.fields.tasks.campaign_dates.end = date;
                              props.setState({ updated: true }, () => {
                                props.addOrRemoveError("sales_end_time");
                              });
                            }}
                            disabled={props.isShare}
                            className="borderless-datepicker"
                          />
                          <i
                            className="fa-duotone fa-calendar-days fa-lg"
                            style={{ color: "grey" }}
                          />
                        </div>
                      </div>
                      {props.errorFields.includes("sales_end_time") && (
                        <div>
                          <i className="error-mark" />
                          <span className="error-text">
                            Whoops! This field can not be empty or negative
                          </span>
                        </div>
                      )}
                    </div>
                    <HumanzRangePicker
                      buttonStyle={{
                        placeSelf: "flex-end",
                        marginLeft: 45,
                      }}
                      open={props.state.showDatePicker}
                      buttonText={"Select range"}
                      forceMinDate={props.shouldDisableTogglePixel}
                      onClose={() => {
                        props.setState({ showDatePicker: false });
                      }}
                      minDate={
                        props.shouldDisableTogglePixel &&
                        props.fields?.sales?.sales_start_time !== null
                          ? moment(props.fields.sales.sales_start_time).toDate()
                          : null
                      }
                      startDate={
                        props.fields?.sales?.sales_start_time
                          ? moment(props.fields.sales.sales_start_time).toDate()
                          : null
                      }
                      endDate={
                        props.fields?.sales?.sales_end_time
                          ? moment(props.fields.sales.sales_end_time).toDate()
                          : null
                      }
                      onChange={(res: any) => {
                        if (res && res.startDate && res.endDate) {
                          props.fields.sales.sales_start_time = moment(
                            res.startDate,
                          ).startOf("day");
                          props.fields.tasks.campaign_dates.start =
                            props.fields.sales.sales_start_time;
                          props.fields.sales.sales_end_time = moment(
                            res.endDate,
                          ).endOf("day");
                          props.fields.tasks.campaign_dates.end =
                            props.fields.sales.sales_end_time;
                        }
                        props.setState({ showDatePicker: false });
                        props.addOrRemoveError("sales_start_time", "remove");
                        props.addOrRemoveError("sales_end_time", "remove");
                      }}
                    />
                  </div>
                </Indicator>

                <Indicator
                  header="Brands and SKUS"
                  description="Track only sales that includes those brands/skus"
                >
                  <div
                    aria-label={
                      props.shouldDisableTogglePixel
                        ? "Can't be edited once sales have started."
                        : "You won't be able to edit those props.fields after sales start"
                    }
                  />
                  {props.fields?.sales?.pixel_app_id ? (
                    <div className="flex">
                      <div
                        style={{
                          position: "relative",
                          width: 300,
                          zIndex: 1,
                        }}
                      >
                        <strong className="input-title">Brands included</strong>
                        <div
                          style={{
                            transform: "translateY(4px)",
                            marginTop: "10px",
                          }}
                        >
                          <SelectDebounced
                            handleChange={handleBrandsChange}
                            selectedValues={props.fields.sales.brands_included}
                            appId={props.fields.sales.pixel_app_id}
                            options={props.state.brands}
                            disabled={
                              props.shouldDisableTogglePixel ||
                              props.fields.sales.skus_included?.length > 0 ||
                              props.fields.sales.skus_excluded?.length > 0
                            }
                            type="brand"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          marginLeft: 16,
                          width: 300,
                        }}
                      >
                        <Flex>
                          <strong className="input-title" style={{ flex: 1 }}>
                            Skus
                          </strong>

                          <div className="toggle-input brands">
                            <label
                              className={
                                props.shouldDisableTogglePixel
                                  ? "disabled"
                                  : null
                              }
                            >
                              <div className="toggle-label">
                                <span>Exclude</span>
                                <span>Include</span>
                              </div>
                              <Toggle
                                disabled={
                                  props.shouldDisableTogglePixel ||
                                  props.fields.sales.brands_included?.length > 0
                                }
                                checked={props.state.isSkusIncluded}
                                icons={false}
                                onChange={() => {
                                  props.setState({
                                    isSkusIncluded: !props.state.isSkusIncluded,
                                  });
                                  handleSkusChange([]);
                                }}
                              />
                            </label>
                          </div>
                        </Flex>
                        <div>
                          <SelectDebounced
                            handleChange={handleSkusChange}
                            selectedValues={
                              props.fields.sales[
                                props.state.isSkusIncluded
                                  ? "skus_included"
                                  : "skus_excluded"
                              ]
                            }
                            disabled={
                              props.shouldDisableTogglePixel ||
                              props.fields.sales.brands_included?.length > 0
                            }
                            appId={props.fields.sales.pixel_app_id}
                            options={props.state.skus}
                            type="sku"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span className="optional-title">
                      You must select a website link for this option to be
                      available
                    </span>
                  )}
                  {props.errorFields.includes("brands_skus") && (
                    <div>
                      <i className="error-mark" />
                      <span className="error-text">
                        Brands / SKU collide with a different phase
                      </span>
                    </div>
                  )}
                </Indicator>
              </>
            ) : null}
          </div>
        </AccordionUI>
        <AccordionUI header={`Affiliation terms`}>
          <div className="campaign-fields">
            <div
              aria-label={
                props.shouldDisableTogglePixel
                  ? "Can't be edited once sales have started."
                  : "You won't be able to edit this field after sales start"
              }
            >
              <IndicatorInput
                header="Commission percentage (%)"
                description="The Commission For The Sales That Will Be Added To The
                  Influencers Payment"
                value={
                  props.sales?.commission_percentage ||
                  props.payments?.commission_percentage ||
                  null
                }
                onChange={(e) => {
                  props.sales.commission_percentage =
                    e && e.length ? Number(e) : null;
                  props.setState({ updated: true }, () => {
                    props.addOrRemoveError("commission_percentage");
                  });
                }}
                className="input-box"
                type="number"
                min={0.1}
                max={100}
                step={0.1}
                disabled={
                  props.shouldDisableTogglePixel &&
                  props.sales?.commission_percentage >= 0
                }
                onBlur={() =>
                  handleCommissionBlur(props.sales?.commission_percentage)
                }
              />
              {props.errorFields.includes("commission_percentage") && (
                <div>
                  <i className="error-mark" />
                  <span className="error-text">
                    Whoops! This field can not be empty, bigger than 100 or
                    negative
                  </span>
                </div>
              )}
            </div>
            <div
              aria-label={
                props.shouldDisableTogglePixel
                  ? "Can't be edited once sales have started."
                  : "You won't be able to edit this field after sales start"
              }
            >
              <IndicatorInput
                header="Net sales percentage (%)"
                description="The net sales that will be visible to the influencers"
                defaultValue={
                  props.sales?.net_sales_percentage ||
                  props.payments?.net_sales_percentage ||
                  null
                }
                onChange={(e) => {
                  props.sales.net_sales_percentage = e;
                  props.setState({ updated: true }, () => {
                    props.addOrRemoveError("net_sales_percentage");
                  });
                }}
                disabled={
                  props.shouldDisableTogglePixel &&
                  props.sales?.net_sales_percentage
                }
                min={0.1}
                max={100}
                step={0.1}
                className="input-box"
                type="number"
              />
              {props.errorFields.includes("net_sales_percentage") && (
                <div>
                  <i className="error-mark" />
                  <span className="error-text">
                    Whoops! This field can not be empty, bigger than 100 or
                    negative
                  </span>
                </div>
              )}
            </div>
            <div>
              <div
                className={`${!props.sales?.sales_timezone && "input-error"}`}
              >
                <IndicatorSelect
                  header={"Sales timezone"}
                  options={momenttz.tz
                    .names()
                    .filter(
                      (x) =>
                        !["America/Ciudad_Juarez", "Europe/Kyiv"].includes(x),
                    )
                    .map((x) => ({ label: x, value: x }))}
                  placeholder={""}
                  defaultValue={props.sales?.sales_timezone}
                  simpleValue
                  clearable={false}
                  onChange={(value: any) => {
                    props.sales.sales_timezone = value;
                    props.addOrRemoveError("sales_timezone", "remove");
                    props.setState({ updated: true });
                  }}
                  name="select-timezone"
                  disabled={props.isShare}
                  searchable={true}
                />
              </div>
              {!props.sales?.sales_timezone && (
                <div>
                  <span className="error-text">
                    Whoops! This field can not be empty
                  </span>
                </div>
              )}
            </div>

            <div>
              <div
                className={`${
                  props.errorFields.includes("sales_currency") && "input-error"
                }`}
              >
                <IndicatorSelect
                  header={"Sales currency"}
                  options={props.CURRENCIES_LIST}
                  placeholder={""}
                  defaultValue={props.sales?.currency}
                  simpleValue
                  clearable={false}
                  onChange={(value: any) => {
                    props.sales.currency = value;
                    props.addOrRemoveError("currency", "remove");
                    props.setState({ updated: true });
                  }}
                  name="selected-state"
                  disabled={props.isShare}
                  searchable={true}
                />
              </div>
              {props.errorFields.includes("sales_currency") && (
                <div>
                  <span className="error-text">
                    Whoops! This field can not be empty
                  </span>
                </div>
              )}
            </div>
            <div
              style={{ marginTop: 25, width: 450 }}
              aria-label={
                props.shortLinkAvaliable
                  ? null
                  : 'You must first create a campaign short link under "Overview" Tab '
              }
            >
              <div className={props.shortLinkAvaliable ? "" : "disabled"}>
                <Indicator
                  header="Allow Influencers Links"
                  description="  Allow influencers to generate their own links for products"
                >
                  <div className="toggle-input">
                    <label>
                      <div className="toggle-label">
                        <div className="fa fa-times" />
                        <div className="fa fa-check" />
                      </div>
                      <Toggle
                        checked={props.sales?.allow_influencers_links}
                        icons={false}
                        onChange={() => {
                          if (props.isShare) return;
                          props.sales.allow_influencers_links =
                            !props.sales.allow_influencers_links;
                          props.setState({});
                        }}
                      />
                    </label>
                  </div>
                </Indicator>
              </div>
            </div>

            {props.sales?.allow_influencers_links ? (
              <>
                {/* <div style={{ marginTop: 25 }}>
                  <strong className="label-title">Suggestion Links</strong>
                </div> */}
                <Indicator header="Suggestion Links">
                  <div>
                    {props.sales?.suggestion_links?.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          gap: 286,
                          color: "#808080",
                          marginBottom: "4px",
                          marginTop: "12px",
                        }}
                      >
                        <span>Link</span>
                        <span>Description</span>
                      </div>
                    )}

                    {props.sales.suggestion_links
                      .filter((x: any) => !x.is_deleted)
                      .map((link: any, idx: number) => {
                        return (
                          <div
                            key={link.uuid || idx}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                              marginBottom: "10px",
                              color: "#808080",
                            }}
                          >
                            <input
                              defaultValue={link.link || ""}
                              onChange={(e) => {
                                link.link = e.target.value;
                              }}
                              className="input-box"
                              placeholder="Enter Your Link"
                              style={{
                                width: "300px",
                                height: "38px",
                              }}
                            />
                            <input
                              defaultValue={link.description || ""}
                              onChange={(e) => {
                                link.description = e.target.value;
                              }}
                              className="input-box"
                              placeholder="Enter Link Description"
                              style={{ width: "300px", height: "38px" }}
                            />
                            <div
                              style={{ width: 20, cursor: "pointer" }}
                              onClick={() => removeSuggestionLink(link.uuid)}
                            >
                              <div className="far fa-trash fa-lg" />
                            </div>
                          </div>
                        );
                      })}

                    <AddButton
                      size="sm"
                      type="Link"
                      onClick={addSuggestionLink}
                    />
                  </div>
                </Indicator>
                <Indicator
                  header="Allow Create Links"
                  description="Allow influencers to generate thier own links"
                >
                  <div>
                    <div className="toggle-input">
                      <label>
                        <div className="toggle-label">
                          <div className="fa fa-times" />
                          <div className="fa fa-check" />
                        </div>
                        <Toggle
                          checked={props.sales?.allow_influencers_create_links}
                          icons={false}
                          onChange={() => {
                            if (props.isShare) return;
                            props.sales.allow_influencers_create_links =
                              !props.sales.allow_influencers_create_links;
                            props.setState({});
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </Indicator>
              </>
            ) : null}
          </div>
        </AccordionUI>
      </div>
      {props?.children}
    </FormCard>
  );
};

export default BreifSales;
