// TODO - lint
/* eslint-disable import/order, react/no-deprecated, react/no-deprecated, class-methods-use-this, prefer-destructuring, no-underscore-dangle, no-shadow, consistent-return, consistent-return, consistent-return, consistent-return, no-underscore-dangle, class-methods-use-this, react/no-direct-mutation-state, react/no-direct-mutation-state, class-methods-use-this, react/jsx-no-target-blank, react/jsx-no-target-blank, react/jsx-no-target-blank, no-nested-ternary, no-return-assign, no-underscore-dangle, camelcase, camelcase, camelcase, camelcase, no-param-reassign, camelcase, no-useless-escape, react/jsx-no-target-blank, react/jsx-no-target-blank, import/no-default-export */
import {
  Avatar,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import moment from "moment";
import React from "react";
import ReactLinkify from "react-linkify";
import Recorder from "react-mp3-recorder";
import { connect } from "react-redux";
import Textarea from "react-textarea-autosize";
import uuidv4 from "uuid/v4";
import {
  changeStarChat,
  exitChat,
  getInfluencerChat,
  retrySendMessage,
  sendFileMessage,
  sendNewMessage,
  updateChatMessages,
} from "../../../actions/inboxActions";
import { DeleteChatMessage } from "../../../api/actions/chatActions";
import { MessageDialog } from "../dialogs/MessageDialog";
import NoDataMessage from "../general_components/NoDataMessage";

class ChatSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChatCampaignId: props.selectedChatCampaignId,
      selectedChatUserId: props.selectedChatUserId,
      chatLoading: false,
      scrollDown: true,
      innerChat: {},
      textAreaHeight: 32,
      replyImage: null,
      imageToSend: null,
      sending: false,
      showEmojiPicker: false,
      message: "",
      chatDetails: {},
      audioBlob: null,
    };
    this.system_message_to_label = {
      1: "system_message_bid_on_auction_",
      2: "system_message_selected_for_campaign_",
      3: "system_message_selected_for_express_campaign_",
      4: "system_message_proposal_created_",
      5: "system_message_proposal_approved_",
      6: "system_message_proposal_declined_",
      7: "system_message_post_linked_",
    };

    this.columns = [
      { label: "BIDS", name: "bids" },
      { label: "INVITES", name: "wait_influencer" },
      {
        label: "APPROVAL",
        name: "wait_approval",
      },
      { label: "PUBLISH", name: "done" },
      { label: "TRASHED", name: "trash" },
    ];
  }

  componentWillMount() {
    this.initChatTimers(true);
    this.initChatDetails();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.selectedChatCampaignId !== this.state.selectedChatCampaignId ||
      newProps.selectedChatUserId !== this.state.selectedChatUserId
    ) {
      this.setState(
        {
          selectedChatCampaignId: newProps.selectedChatCampaignId,
          selectedChatUserId: newProps.selectedChatUserId,
          chatDetails: {},
        },
        () => {
          this.initChatDetails();
        },
      );
    }
  }

  initChatDetails() {
    if (this.state.selectedChatUserId) {
      getInfluencerChat(
        this.state.selectedChatUserId,
        this.state.selectedChatCampaignId,
        (res) => {
          if (res && res.influencers && res.influencers.length === 1) {
            this.setState({ chatDetails: res.influencers[0] });
          }
        },
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scrollDown !== this.state.scrollDown) return;

    if (this.state.scrollDown === true) {
      this.handleScrollDown();
    }
  }

  handleScrollDown() {
    window.setTimeout(() => {
      const objDiv = document.getElementById("chat_container");
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 500);
  }

  componentWillUnmount() {
    this.initChatTimers(false);
    this.props.exitChat(
      this.props.selectedChatUserId,
      this.props.selectedChatCampaignId,
    );
  }

  initChatTimers(start) {
    if (this.unSeenTimer) {
      clearInterval(this.unSeenTimer);
    }
    // if (this.reloadChatTimer) {
    //     clearInterval(this.reloadChatTimer)
    // }
    if (start) {
      this.unSeenTimer = setInterval(this.checkUnsendMessages.bind(this), 4000);
      // this.reloadChatTimer = setInterval(this.getLastMessages.bind(this), 10000);
    }
  }

  checkUnsendMessages() {
    const { selectedChatUserId, selectedChatCampaignId } = this.state;
    const messages = this.props.inbox.chat_messages.filter(
      (x) => x.sending && new Date() - x.creation_time > 3500,
    );
    if (messages.length > 0) {
      this.props.retrySendMessage(
        selectedChatUserId,
        messages.map((x) => {
          return {
            message_guid: x.id,
            message: x.message,
            message_content_type: "text",
            campaign_id: selectedChatCampaignId,
            reply_to_id: x.reply_to_id,
            reply_object_type: x.reply_to_type,
          };
        }),
      );
    }
  }

  getLastMessages() {
    const { selectedChatUserId, selectedChatCampaignId } = this.state;
    let message = this.props.inbox.chat_messages.filter((x) => !x.mine);
    if (message.length > 0) {
      message = message[0];
      if (message && message.creation_time) {
        this.props.updateChatMessages(
          selectedChatUserId,
          selectedChatCampaignId,
          new Date(message.creation_time).toISOString(),
          null,
          30,
          null,
          "desc",
        );
      }
    }
  }

  handleKeyPress = (e) => {
    if (e.nativeEvent.keyCode === 13 && !e.nativeEvent.shiftKey) {
      this.sendNewMessage(e);
    }
  };

  sendNewMessage = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (this.state.file) {
      this.sendFileMessage();
      return;
    }
    const value = this.state.message?.trim();
    if (value === "" || value === null || value === undefined) return;

    const campaignId =
      this.state.selectedChatCampaignId === -1
        ? null
        : this.state.selectedChatCampaignId;
    const message = {
      id: uuidv4(),
      message: value,
      creation_time: new Date(),
      mine: true,
      seen: false,
      seen_at: null,
      is_system_message: false,
      message_content_type: "text",
      reply_to_id: this.state.replyImage
        ? this.state.replyImage.upload_id
        : null,
      reply_to_type: this.state.replyImage ? "task_upload" : null,
      reply_to_content: this.state.replyImage
        ? this.state.replyImage.upload_url
        : null,
      reply_message_time: null,
      sending: true,
    };
    this.props.inbox.chat_messages.push(message);

    this.setState(
      {
        scrollDown: true,
        replyImage: null,
        message: "",
        isSendingRecord: false,
      },
      () => {
        sendNewMessage(
          this.state.selectedChatUserId,
          campaignId,
          value,
          "NORMAL",
          message.id,
          message.reply_to_id,
          message.reply_to_type,
          message.reply_to_content,
          null,
          this.updateChat.bind(this),
        );
      },
    );
  };

  updateChat() {
    let from;
    if (this.props.inbox.chat_messages.length > 0) {
      from =
        this.props.inbox.chat_messages[
          this.props.inbox.chat_messages.length - 1
        ].creation_time;
    } else {
      from = new Date();
      from.setDate(from.getDate() - 1);
    }
    const date = new Date();
    date.setDate(date.getDate() + 1 / 24);
    // this.props.updateChatMessages(this.state.selectedChatId,from,date,10,null,"desc");
    this._newMessageContent.focus();

    this.handleScrollDown();
  }

  getMoreMessages = () => {
    const campaignId =
      this.state.selectedChatCampaignId === -1
        ? null
        : this.state.selectedChatCampaignId;

    const time = this.props.inbox.chat_messages[0].creation_time;
    const objDiv = document.getElementById("chat_container");
    const currentScrollHeihgt = objDiv.scrollHeight;
    this.setState({ scrollDown: false, updateMessagesLoader: true }, () => {
      this.props.updateChatMessages(
        this.state.selectedChatUserId,
        campaignId,
        null,
        time,
        10,
        null,
        "desc",
        () => {
          const objDiv = document.getElementById("chat_container");
          objDiv.scrollTop = objDiv.scrollHeight - currentScrollHeihgt;
          this.setState({ updateMessagesLoader: false });
        },
      );
    });
  };

  checkLoadMore() {
    if (!this.state.chatDetails.user_id) return;
    if (
      !this.props.inbox.chat_messages ||
      this.props.inbox.chat_messages.length < 10
    ) {
      return false;
    }
    let firstMessageTime = this.props.inbox.chat_messages.first_message_time;
    if (!firstMessageTime) {
      return false;
    }
    firstMessageTime = new Date(firstMessageTime);

    if (
      firstMessageTime.getTime() ===
      new Date(this.props.inbox.chat_messages[0].creation_time).getTime()
    ) {
      return false;
    }
    return true;
  }

  getStatusLabel(column) {
    const selected = this.columns.find((x) => x.name === column);

    if (selected) return selected.label;
    return "";
  }

  handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop >
      50
    ) {
      if (this.state.scrollDown) {
        this.setState({ scrollDown: false });
      }
    } else if (!this.state.scrollDown) {
      this.setState({ scrollDown: true });
    }
  };

  replyOnImage = (replyImage) => {
    this._newMessageContent.focus();
    this.setState({ replyImage });
  };

  clickOnReply = (message) => {
    if (
      this.props.onReplyClick &&
      (message.reply_to_type === "task_upload" ||
        message.reply_object_type === "task_upload")
    ) {
      this.props.onReplyClick(message.reply_to_id);
    }
  };

  openSegmentatios = () => {
    if (this.props.openSegmentatios) this.props.openSegmentatios();
  };

  sendFileMessage = (callback) => {
    if (this.state.sending) {
      return;
    }
    const { file } = this.state;
    const id = uuidv4();
    this.setState({ sending: true });

    let type = "pdf";
    if (file.type.includes("image")) {
      type = "image";
    } else if (file.type.includes("video")) {
      type = "video";
    } else if (file.type.includes("audio")) {
      type = "audio";
      file.filename = "temp_audio.wav";
    }

    sendFileMessage(
      this.state.selectedChatUserId,
      this.state.selectedChatCampaignId,
      file,
      type,
      id,
      "normal",
      () => {
        this.setState({ file: null, imageToSend: null, sending: false }, () => {
          if (callback) callback();
        });
      },
    );
  };

  openFilePicker = () => {
    document.getElementById("load-image").click();
  };

  addFile = (e) => {
    try {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      this.setState({ file, imageToSend: { src: url } });
    } catch (e) {
      console.log(e);
      alert("Failed to upload file, please try again.");
    }
  };

  addEmoji = (e) => {
    const emoji = e.native;
    this.setState({
      message: this.state.message + emoji,
    });
  };

  toggleShowEmojis = () => {
    this.setState({ showEmojiPicker: !this.state.showEmojiPicker });
  };

  onStarChat = () => {
    const id = this.state.selectedChatUserId;
    const campaignId = this.state.selectedChatCampaignId;
    const starred = this.state.chatDetails.is_starred;
    this.props.changeStarChat(id, campaignId, starred, (r) => {
      if (!r) {
        this.state.chatDetails.is_starred = starred;
        this.setState({});
      }
    });
    this.state.chatDetails.is_starred = !starred;
    this.setState({});
  };

  handleRecordError = (err) => {
    console.log(err);
    alert(
      "Something went wrong while recording, please hold the microphone icon while recording and make sure you have approved your browser the ability to use your microphone.",
    );
  };

  handleRecordComplete = (blob) => {
    this.setState({ isSendingRecord: true, isRecording: false }, () => {
      const file = ChatSection.blobToFile(blob, "record.mp3");
      this.setState({
        audioBlob: window.URL.createObjectURL(new Blob([blob])),
      });
      this.setState({ file });
    });
  };

  static blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {
      lastModified: new Date(),
      type: "audio",
    });
  }

  deleteChatMessage = (message) => {
    this.setState({ chatLoading: true });
    this.props.deleteChatMessage(
      message.message_guid ?? message.event_guid,
      (res) => {
        if (!res) {
          console.log("Failed to delete message", message);
        }
        this.setState({ messageForDeletion: null, chatLoading: false });
      },
    );
  };

  renderDeleteMessageDialog = () => {
    return (
      this.state.messageForDeletion && (
        <MessageDialog
          handleClose={() => {
            this.setState({ messageForDeletion: false });
          }}
          icon="moi-trash"
        >
          <div style={{ textAlign: "center", padding: 20 }}>
            <strong>Notice</strong>
            <p style={{ marginTop: 15 }}>
              This message will be deleted for you and the influencer. <br />
              Are you sure you want to delete this message?
            </p>
            <Button
              mr={2}
              onClick={() =>
                this.deleteChatMessage(this.state.messageForDeletion)
              }
              disabled={this.state.chatLoading}
              isLoading={this.state.chatLoading}
            >
              Delete
            </Button>
            <Button
              onClick={() => this.setState({ messageForDeletion: false })}
              variant="outline"
              isDisabled={this.state.chatLoading}
            >
              Cancel
            </Button>
          </div>
        </MessageDialog>
      )
    );
  };

  render() {
    const { chatDetails } = this.state;
    const socialData =
      chatDetails && chatDetails.influencer_social_network_data
        ? chatDetails.influencer_social_network_data
        : {};
    const disabled =
      chatDetails?.campaign_column === "trash" || this.props.disabled;

    return (
      <div
        className="inbox-right-side"
        style={{
          width: this.props.containerWidth,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="chat-header" style={{ height: 65, paddingTop: 10 }}>
          <div style={{ display: "flex" }}>
            <Avatar
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                marginRight: 5,
              }}
              src={
                this.state.chatDetails.picture_url ||
                this.props.selectedChatUser?.picture_url ||
                undefined
              }
            />
            <div>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                className="clickable"
              >
                <span onClick={this.openSegmentatios}>
                  {this.state.chatDetails.name ||
                    this.props.selectedChatUser?.name}
                </span>
                <i
                  onClick={() => this.onStarChat(chatDetails)}
                  className={`${
                    this.state.chatDetails.is_starred ? "fas" : "fal"
                  } fa-star`}
                ></i>
              </div>

              <div className="chat-header-social-links">
                {socialData.instagram && (
                  <a
                    className={"fa fa-instagram cube-button "}
                    href={`https://www.instagram.com/${socialData.instagram.username}/`}
                    target="_blank"
                    title={socialData.instagram.username}
                  />
                )}
                {socialData.twitter && (
                  <a
                    className={"fa fa-twitter cube-button "}
                    href={`https://www.twitter.com/${socialData.twitter.username}`}
                    target="_blank"
                    title={socialData.twitter.username}
                  />
                )}
                {socialData.youtube && (
                  <a
                    className={"fa fa-youtube-play cube-button "}
                    href={`https://www.youtube.com/channel/${socialData.youtube.social_id}`}
                    target="_blank"
                    title={socialData.youtube.username}
                  />
                )}

                <span style={{ color: "#aaaaaa", fontSize: 14 }}>
                  {`${this.getStatusLabel(
                    this.state.chatDetails.campaign_column,
                  )}`}
                </span>
              </div>
            </div>
          </div>
        </div>
        {!disabled && (
          <div
            className="chat-container clickable"
            id="chat_container"
            onScroll={this.handleScroll}
            style={{
              height:
                this.props.chatContainerHeight -
                (this.state.replyImage ? 130 : this.state.textAreaHeight),
            }}
          >
            {!this.props.chatLoading && this.checkLoadMore() && (
              <div className="load-more-container">
                <div
                  className={`chat-message load-more ${
                    this.state.updateMessagesLoader ? "disabled" : ""
                  }`}
                  onClick={this.getMoreMessages}
                >
                  Load Earlier Messages
                </div>
              </div>
            )}

            {this.props.inbox.chat_messages.map(this.renderMessages)}
          </div>
        )}
        {this.props.selectedChatCampaignId &&
        this.props?.selectedChatUser &&
        !this.props?.selectedChatUser?.user_registered ? (
          <NoDataMessage
            style={{
              fontSize: 18,
              textAlign: "center",
              marginTop: 10,
              alignContent: "center",
            }}
          >
            Chat is not available.
            <br />
            The user is not registered.
          </NoDataMessage>
        ) : (
          <div>
            {disabled ? (
              <div
                style={{
                  zIndex: 5,
                  textAlign: "center",
                  fontSize: 15,
                  marginBottom: -5,
                }}
              >
                <strong className="error-text">The Chat Is Closed</strong>
              </div>
            ) : null}
            <div className="chat-sends clickable" disabled={disabled}>
              {this.state.replyImage && (
                <div className="chat-reply-image">
                  <img src={this.state.replyImage.upload_url} />
                  <i
                    className="fal fa-times"
                    onClick={() => this.setState({ replyImage: null })}
                  />
                </div>
              )}
              <div>
                <span
                  onClick={this.openFilePicker}
                  className={`clickable far fa-file-plus ${!!this.state.file}`}
                  style={{ marginRight: 5 }}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="load-image"
                  accept="image/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={this.addFile}
                />
                <span
                  className={`far fa-laugh chat-emoji-btn ${this.state.showEmojiPicker}`}
                  onClick={this.toggleShowEmojis}
                ></span>
                {this.state.showEmojiPicker && (
                  <div className="chat-emoji-container">
                    <Picker
                      onSelect={this.addEmoji}
                      title=""
                      showPreview={false}
                    />
                  </div>
                )}
                <span
                  className={`record ${
                    this.state.isRecording ? "recording" : ""
                  }`}
                >
                  <Recorder
                    onMouseDown={() => this.setState({ isRecording: true })}
                    onMouseUp={() => this.setState({ isRecording: true })}
                    onRecordingComplete={this.handleRecordComplete}
                    onRecordingError={this.handleRecordError}
                    recorderParams={{ width: 10, height: 10 }}
                  />
                </span>
                {this.state.file && this.state.imageToSend ? (
                  <div className="chat-send-image">
                    {this.state.file.type.includes("image") && (
                      <img src={this.state.imageToSend.src} />
                    )}
                    <span className="filename">{this.state.file.name}</span>
                  </div>
                ) : this.state.file && this.state.isSendingRecord ? (
                  <div
                    className={"recording-approve"}
                    style={{ display: "inline-flex" }}
                  >
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        this.setState({ file: null, isSendingRecord: false })
                      }
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                    {
                      <audio
                        src={this.state.audioBlob}
                        className={"audio"}
                        controls
                      ></audio>
                    }
                  </div>
                ) : (
                  <Textarea
                    onFocus={() => this.setState({ showEmojiPicker: false })}
                    value={this.state.message}
                    onChange={(e) => this.setState({ message: e.target.value })}
                    className="clickable"
                    placeholder="Type your message..."
                    minRows={1}
                    maxRows={7}
                    onHeightChange={(e) => this.setState({ textAreaHeight: e })}
                    dir="auto"
                    id="chat-textarea"
                    inputRef={(a) => (this._newMessageContent = a)}
                    onKeyPress={this.handleKeyPress}
                  />
                )}
                {this.state.sending ? (
                  <div style={{ width: 100 }}>
                    <div className="send-buttons-loader"></div>
                  </div>
                ) : (
                  <span
                    onClick={this.sendNewMessage}
                    className={`clickable fa fa-paper-plane ${
                      !!this.state.message?.trim() +
                      (this.state.isSendingRecord ? " record_send" : "")
                    }`}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderMessages = (message, index) => {
    const width = "50%";
    let additonalMessageClass = message.is_system_message
      ? "system"
      : message.mine;
    if (message.is_system_message) {
      additonalMessageClass = "system";
      const auction_message_label =
        this.system_message_to_label[message.system_message_type];
      const auction_title = message.system_message_auction_title;
      const length = 14;
      const trimmedString =
        this.props.selectedChatTitle?.length || 0 > length
          ? `${auction_title.substring(0, length - 3)}...`
          : auction_title;
      message.message = `"${trimmedString}" - ${window.translate(
        "",
        `${auction_message_label}adv`,
      )}`;
      // textColor = 'white';
    }
    const is_deleted = message?.mark_deleted;

    let name = "";
    if (
      message.message_content_type === "pdf" ||
      message.message_content_type === "doc"
    ) {
      name = message.message.replace(/^.*[\\\/]/, "");
      name = name.substring(name.indexOf("_") + 1);
    }
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          padding: "0px 10px",
          justifyContent:
            additonalMessageClass === true ? "flex-end" : "flex-start",
        }}
        key={message.id}
      >
        <div
          className={`chat-message ${additonalMessageClass} ${
            is_deleted ? "deleted-message" : ""
          }`}
          key={`message${index}`}
          style={{ maxWidth: width }}
        >
          <div className="time" dir={"rtl"}>
            {moment(message.creation_time).format(
              `${window.time.short_date} HH:mm`,
            )}
            {message.mine && message.seen && <div className="moi-two-vi" />}
            {message.mine && message.sending && (
              <div className="far fa-clock" />
            )}
          </div>
          {is_deleted ? (
            <div dir="auto">
              This message was deleted by{" "}
              {message.mine ? "you" : "the influener"}
            </div>
          ) : (
            <>
              {!is_deleted && (
                <Popover>
                  <PopoverTrigger>
                    <div className="message-options">
                      <i className="far fa-chevron-down"></i>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent width="auto" style={{ boxShadow: "none" }}>
                    <PopoverArrow />
                    <PopoverBody width="auto" boxShadow={"none"} p={1}>
                      <Button
                        className="message-option-action"
                        variant="ghost-non-focusable"
                        color={"#333"}
                        outline={"none"}
                        boxShadow={"none"}
                        size={"sm"}
                        focus={false}
                        onClick={() => {
                          this.setState({ messageForDeletion: message });
                        }}
                      >
                        Delete message
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
              {message.reply_to_content && (
                <div
                  className="reply-container"
                  onClick={() => this.clickOnReply(message)}
                >
                  <div>Task media</div>
                  <img src={message.reply_to_content} />
                </div>
              )}

              {message.message_content_type === "text" && (
                <ReactLinkify>
                  <div dir="auto">{message.message}</div>
                </ReactLinkify>
              )}
              {message.message_content_type === "image" && (
                <a href={message.message} target="_blank">
                  <img src={message.message} />
                </a>
              )}
              {(message.message_content_type === "pdf" ||
                message.message_content_type === "doc") && (
                <a href={message.message} target="_blank">
                  <i className="far fa-file-alt message-file" />
                  {name}
                </a>
              )}
              {message.message_content_type === "video" && (
                <video controls>
                  <source src={message.message}></source>
                </video>
              )}
              {message.message_content_type === "audio" && (
                <audio className={"audio"} controls>
                  <source src={message.message}></source>
                </audio>
              )}
            </>
          )}
        </div>
        {this.renderDeleteMessageDialog()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    inbox: state.inboxReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateChatMessages: (
      id,
      campaignId,
      from,
      to,
      amount,
      offset,
      order,
      callback,
    ) => {
      dispatch(
        updateChatMessages(
          id,
          campaignId,
          from,
          to,
          amount,
          offset,
          order,
          callback,
        ),
      );
    },
    retrySendMessage: (id, data, callback) => {
      dispatch(retrySendMessage(id, data, callback));
    },
    exitChat: (id, campaignId) => {
      dispatch(exitChat(id, campaignId));
    },
    changeStarChat: (userId, campaignId, starred, callback) => {
      dispatch(changeStarChat(userId, campaignId, starred, callback));
    },
    deleteChatMessage: (guid, callback) => {
      dispatch(DeleteChatMessage(guid, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChatSection);
