import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteTodoItem,
  getTodoItems,
  setTodoItemCompleted,
} from "src/api/actions/auctionActions";
// eslint-disable-next-line import-access/jsdoc
import { AuctionToDoItem } from "src/api/types";
import StartTodo from "./components/StartPage/StartTodo";
import CreateTodoList from "./components/Task/CreateTodoList";
import BuildNewTemplate from "./components/Template/BuildNewTemplate";
import ImportTodoTemplate from "./components/Template/ImportTodoTemplate";
import Template from "./components/Template/Template";
import SideMenu from "./SideMenu";

type Step =
  | "start"
  | "creat-todo"
  | "import-template"
  | "start-from-template"
  | "new-template";

const ManageToDo = (props: any) => {
  const user = useSelector((state) => state.userReducer);
  const [header, setHeader] = useState("My To Do List");
  const [tasks, setTasks] = useState<AuctionToDoItem[]>([]);
  const [templateId, setTemplateId] = useState();
  const [step, setStep] = useState("start" as Step);
  const [templateName, setTemplateName] = useState("");
  const [template, setTemplate] = useState();

  const params = useParams();
  const campaignId = params?.id;

  useEffect(() => {
    if (campaignId) {
      getTodoItems(Number(campaignId))
        .then((res) => {
          if (res?.data.items) {
            setTasks(res.data.items);
            if (res.data.items.length > 0) {
              setStep("creat-todo");
            }
          } else {
            setStep("start");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [campaignId]);

  const handleMarkAllAsDone = () => {
    tasks.forEach((task) => {
      if (!task.completed) {
        setTodoItemCompleted(Number(campaignId), task.id, { completed: true });
      }
    });
    const updatedTasks: AuctionToDoItem[] = tasks.map((task) => ({
      ...task,
      completed: true,
    }));
    setTasks(updatedTasks);
    setStep("creat-todo");
  };

  const handleDeleteAll = () => {
    tasks.forEach((task) => {
      deleteTodoItem(Number(campaignId), task.id)
        .then(() => {})
        .catch(() => {});
    });
    setTasks([]);
  };

  const mainComponent = useMemo(() => {
    switch (step) {
      case "start":
        setHeader("My To Do List");
        return <StartTodo step={step} setStep={setStep} />;
      case "creat-todo":
        setHeader("To Do");
        return (
          <CreateTodoList
            step={step}
            setStep={setStep}
            tasks={tasks}
            setTasks={setTasks}
            handleUpdateTodoButton={props.handleUpdateTodoButton}
          />
        );
      case "import-template":
        setHeader("Choose your tasks template");
        return (
          <ImportTodoTemplate
            step={step}
            setStep={setStep}
            templateTasks={tasks}
            setTemplateTasks={setTasks}
            setHeader={setHeader}
            setTemplateId={setTemplateId}
            setTemplateName={setTemplateName}
            setTemplate={setTemplate}
            userId={user.id}
          />
        );
      case "new-template":
        setHeader(templateName);
        return (
          <BuildNewTemplate
            step={step}
            setStep={setStep}
            templateName={templateName}
            setHeader={setHeader}
          />
        );
      case "start-from-template":
        return (
          <Template
            step={step}
            setStep={setStep}
            templateTasks={tasks}
            setTasks={setTasks}
            templateId={templateId}
            template={template}
            userId={user.id}
          />
        );
      default:
        return <StartTodo />;
    }
  }, [
    step,
    tasks,
    props.handleUpdateTodoButton,
    templateName,
    templateId,
    template,
    user.id,
  ]);

  return (
    <SideMenu
      isOpen={props.isOpen}
      setShowTodoList={props.setShowTodoList}
      header={header}
      setHeader={setHeader}
      setTemplateName={setTemplateName}
      templateName={templateName}
      setStep={setStep}
      step={step}
      handleMarkAllAsDone={handleMarkAllAsDone}
      handleDeleteAll={handleDeleteAll}
      tasks={tasks}
      setTasks={setTasks}
    >
      {mainComponent}
    </SideMenu>
  );
};

export default ManageToDo;
