/* eslint-disable no-nested-ternary */

/* eslint-disable import-access/jsdoc */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getMoiAgentAgent_id } from "src/api/services";
import { AgentDetails } from "src/components/feed_components/agency/AgentSelect";
import { InfiniteLoader } from "src/components/feed_components/general_components/InfiniteLoader";

type Props = {
  agent: number;
  onClose: any;
};

const AgentDetailsModal = (props: Props) => {
  const [agentDetails, setAgentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getMoiAgentAgent_id(props.agent)
      .then((res) => {
        setAgentDetails(res.data);
      })
      .catch(() => {
        alert("Error getting details");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Modal size={"sm"} isOpen={true} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight={"700px"}>
        <ModalHeader>Agent details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {agentDetails ? (
            <AgentDetails details={agentDetails} />
          ) : loading ? (
            <InfiniteLoader />
          ) : (
            "Error getting details"
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AgentDetailsModal;
