import {
  Accordion,
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  usePrevious,
} from "@chakra-ui/react";
import produce from "immer";
import moment from "moment";
import { useCallback, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import InfiniteScroll from "react-infinite-scroller";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CallbackOfType } from "src/declarations/UtilityTypes";
import { useBoolean } from "src/hooks/coreHooks";
import { Form } from "src/ui/Form";
import { FormFooterButtonGroup } from "src/ui/FormFooterButtonGroup";
import { InstagramPostEmbedJs } from "./InstagramPostEmbedJs";

const ratioOfPosts = 9 / 16;
const PostsPerRow = 3;
const paddingBetweenPosts = 3;

const widthOfPosts = `${
  (196 - paddingBetweenPosts * (PostsPerRow - 1)) / PostsPerRow / 3
}rem`; // 5 in row
// const widthOfPosts = 44; // 4 in row

export enum PostType {
  manual,
  linked,
}

export enum TaskStatuses {
  inProgress,
  completed,
}

type PostData = {
  embed_link: string;
  media_type: string;
  fb_media_id?: number;
  influencer_id?: number;
  media_creation_time?: string;
};

type Props = {
  linked?: {
    range: {
      since: moment.Moment;
      until: moment.Moment;
    };
    onRangeChange: (newRange: {
      since: moment.Moment;
      until: moment.Moment;
    }) => void;
    paginatedPosts: {
      data: {
        id: string;
        data: PostData;
      }[];
      hasMore: boolean;
      fetchMore: () => void;
      isFetching: boolean;
      isRefreshing: boolean;
    };
    onRequestRefresh: () => void;
  };
  influencer: any;
  data: any;
  selectPost: any;
  tasks: any;
};

const PostItem = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { postData, selectPost, handleClose, tasks } = props;
  const alreadySelected = !!tasks.find((p: any) =>
    p.linked_media ? postData.embed_link.includes(p.linked_media) : false,
  );
  return (
    <Box
      display="inline-flex"
      borderRadius="xl"
      overflow="hidden"
      pos="relative"
      borderStyle="solid"
      borderColor={"gray.300"}
      borderWidth={"thin"}
      onClick={() => {
        if (alreadySelected) return;
        selectPost(postData.embed_link, postData.fb_media_id);
        handleClose();
      }}
      cursor={"pointer"}
    >
      <div className="insta-post-embed">
        {
          <Box>
            {postData?.embed_link && (
              <InstagramPostEmbedJs postLink={postData.embed_link} />
            )}
          </Box>
        }
        <Flex
          pointerEvents="none"
          pos="absolute"
          inset="0"
          justifyContent="end"
          alignItems="end"
          p="3"
        >
          <Flex pointerEvents="initial" gap="2">
            <div className="select-post">
              {alreadySelected ? (
                <Button isDisabled>
                  <i className="fas fa-check-circle"></i>&nbsp;Post already
                  attached
                </Button>
              ) : (
                <Button>
                  <i className="far fa-check-circle"></i>&nbsp;Select this post
                </Button>
              )}
            </div>
          </Flex>
        </Flex>
      </div>
    </Box>
  );
};

const TaskRow = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  data,
  linked,
  influencer,
  selectPost,
  tasks,
}: {
  num: number;
  data: any;
  tasks: any;
  linked: Props["linked"];
  influencer: any;
  selectPost: any;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [toPropagateData, setToPropagateData] = useBoolean(false);
  const prevToPropagateData = usePrevious(toPropagateData);

  useEffect(() => {
    if (toPropagateData && toPropagateData !== prevToPropagateData) {
      setToPropagateData.off();
    }
  }, [data, prevToPropagateData, setToPropagateData, toPropagateData, tasks]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleStartDateChange = useCallback<
    CallbackOfType<typeof ReactDatePicker, "onChange">
  >(
    (d) => {
      if (linked) {
        linked.onRangeChange(
          produce(linked.range, (draftRange) => {
            draftRange.since = d!.startOf("day");
          }),
        );
      }
    },
    [linked],
  );

  const handleEndDateChange = useCallback<
    CallbackOfType<typeof ReactDatePicker, "onChange">
  >(
    (d) => {
      if (linked) {
        linked.onRangeChange(
          produce(linked.range, (draftRange) => {
            draftRange.until = d!.endOf("day");
          }),
        );
      }
    },
    [linked],
  );

  const isPostsDisabled = linked === undefined;

  const handleLoadMore = useCallback(() => {
    if (!isPostsDisabled && !linked.paginatedPosts.isFetching) {
      linked.paginatedPosts.fetchMore();
    }
  }, [isPostsDisabled, linked?.paginatedPosts]);

  if (!data) return null;
  const hasAPI =
    influencer.social_network_data?.instagram?.verification_type === "api";
  return (
    <>
      {hasAPI ? (
        <Button
          className={`browse-posts`}
          onClick={onOpen}
          style={{ textAlign: "center", marginLeft: 6, height: "100%" }}
        >
          <span>
            <i className="fa fa-instagram" /> Browse posts
          </span>
        </Button>
      ) : (
        <div className="btn browse-posts">
          {influencer?.social_network_data?.instagram?.username && (
            <a
              href={`https://www.instagram.com/${influencer?.social_network_data?.instagram?.username}`}
              className="href"
              target={"_blank"}
              rel="noreferrer"
            >
              Jump to profile{" "}
              <i className="fa-regular fa-up-right-from-square" />
            </a>
          )}
        </div>
      )}

      {isOpen && (
        <Modal
          size="4xl"
          isOpen={isOpen}
          onClose={handleClose}
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select post</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflow="hidden" display="flex">
              <Flex flexDir="column" flex="1">
                {isPostsDisabled ? (
                  <Text fontSize="l" padding={6}>
                    The influencer didn&#39;t connect his Facebook business
                    account. <br />
                    You can look for posts in his Instagram profile. <br />
                    <br />
                    {influencer?.social_network_data?.instagram?.username && (
                      <a
                        href={`https://www.instagram.com/${influencer?.social_network_data?.instagram?.username}`}
                        className="href"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Jump to profile{" "}
                        <i className="fa-regular fa-up-right-from-square" />
                      </a>
                    )}
                  </Text>
                ) : null}
                {linked ? (
                  <Flex overflow="hidden" flexDir="column">
                    <Flex pb="2">
                      <Flex flexDir="column">
                        <Text>From</Text>
                        <ReactDatePicker
                          dateFormat={window.time.short_date}
                          className="input-line"
                          selected={linked.range.since}
                          onChange={handleStartDateChange}
                          maxDate={linked.range.until}
                        />
                      </Flex>
                      <Flex flexDir="column" px={3}>
                        <Text>To</Text>
                        <ReactDatePicker
                          dateFormat={window.time.short_date}
                          className="input-line"
                          selected={linked.range.until}
                          onChange={handleEndDateChange}
                          minDate={linked.range.since}
                          maxDate={moment()}
                        />
                      </Flex>
                    </Flex>
                    <Flex
                      flex={1}
                      overflow="hidden"
                      flexDir="column"
                      alignItems="strech"
                    >
                      <Box flex={1} overflowY="scroll">
                        <InfiniteScroll
                          loadMore={handleLoadMore}
                          hasMore={linked?.paginatedPosts?.hasMore || false}
                          style={{ minHeight: 500 }}
                          loader={
                            <AspectRatio
                              w={widthOfPosts}
                              ratio={ratioOfPosts}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box>
                                <Spinner />
                              </Box>
                            </AspectRatio>
                          }
                          useWindow={false}
                        >
                          {linked?.paginatedPosts?.data.map((linkedPost) => {
                            return (
                              <Box
                                key={linkedPost?.id}
                                display="inline-flex"
                                pe={paddingBetweenPosts}
                                pb={paddingBetweenPosts}
                              >
                                <PostItem
                                  postData={linkedPost?.data}
                                  selectPost={selectPost}
                                  handleClose={handleClose}
                                  tasks={tasks}
                                />
                              </Box>
                            );
                          })}
                        </InfiniteScroll>
                      </Box>
                    </Flex>
                    {linked.paginatedPosts.data.length === 0 &&
                    !linked.paginatedPosts.isFetching ? (
                      <Text pt="2">
                        No Posts were found in the selected dates, try different
                        dates
                      </Text>
                    ) : null}
                    <Flex alignItems="baseline" py="2">
                      <Heading size="sm">
                        Can’t find influencer’s latest Posts?
                      </Heading>
                      <Button
                        variant="link"
                        onClick={linked.onRequestRefresh}
                        isLoading={linked.paginatedPosts.isRefreshing}
                      >
                        Refresh now
                      </Button>
                    </Flex>
                  </Flex>
                ) : null}
                <Form pb="3">
                  <FormFooterButtonGroup>
                    <Button type="button" onClick={handleClose}>
                      Cancel
                    </Button>
                  </FormFooterButtonGroup>
                </Form>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export const AttachLinksDialogTabPostsControlled = ({
  linked,
  data,
  influencer,
  selectPost,
  tasks,
}: Props) => {
  // if (!data) return null;
  return (
    <Accordion allowToggle>
      <TaskRow
        num={1}
        data={data}
        linked={linked}
        influencer={influencer}
        selectPost={selectPost}
        tasks={tasks}
      />
    </Accordion>
  );
};
