import { Box, FormLabel, IconButton } from "@material-ui/core";
import { useEffect, useState } from "react";
import { digitsRegExp, numberWithCurrency } from "../../../utilities/general";
import AddButton from "../../CreateCampaignSection/FormUI/AddButton";
import CampaignTargetSelect from "./CampaignTargetSelect";
import { FormGoalStep, FormValue } from "./CampaignTargetsModal";

interface ICampaignTargetStep {
  stepIndex: number;
  step: FormGoalStep;
  target: number;
  handleAddButton: () => void;
  handleDeleteStep: () => void;
  isView: boolean;
  isCompleted: boolean;
  handleTargetChange?: (
    targetId: number,
    step: number,
    reward: number,
    targetValue: FormValue<number>,
    targetType?: string,
    repeatType?: string,
    dueDate?: FormValue<string>,
    isEdit?: boolean,
  ) => void;
  currentKpi: number;
  steps: FormGoalStep[];
  isLocked: boolean;
  error: string;
  isEdit: boolean;
  isHiddenIndicator: boolean;
  currency: string;
  currentTarget?: any;
}

const CampaignTargetStep = ({
  stepIndex,
  step,
  handleAddButton,
  handleDeleteStep,
  isView,
  isCompleted,
  handleTargetChange,
  target,
  isLocked,
  error,
  isEdit,
  isHiddenIndicator,
  currency,
  currentTarget,
}: Partial<ICampaignTargetStep>) => {
  const [targetValue, setTargetValue] = useState("");
  const [reward, setReward] = useState(step?.reward || "");

  useEffect(() => {
    if (step) {
      setTargetValue(String(step?.target_value?.value));
      if (step?.reward) {
        setReward(String(step?.reward));
      }
    }
  }, [target]);

  return (
    <>
      {stepIndex === 0 ? (
        <Box className="campaign-target-step__add">
          <AddButton onClick={() => handleAddButton()} type="Step" size="sm" />
        </Box>
      ) : (
        <Box
          className={
            stepIndex
              ? `campaign-target-step ${
                  isCompleted && "campaign-target-step_completed"
                } ${isLocked && "campaign-target-step_locked"}`
              : "campaign-target-step__single"
          }
        >
          {stepIndex && (
            <Box
              className={`campaign-target-step__header ${
                isLocked && "campaign-target-step__header_locked"
              }`}
            >
              <Box
                component="p"
                className="campaign-target-step__title"
              >{`Step ${stepIndex}`}</Box>
              {isView && !isCompleted && !isLocked && (
                <Box className="campaign-view-target__progressbar-container">
                  <Box
                    className="campaign-view-target__progressbar"
                    sx={{
                      width: "100px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "2px",
                    }}
                  >
                    <Box
                      className="campaign-view-target-progressbar__scale"
                      sx={{
                        width:
                          currentTarget.current_metric_value >=
                          step.target_value.value
                            ? "100%"
                            : `${
                                (currentTarget.current_metric_value /
                                  step.target_value.value) *
                                100
                              }%`,
                        maxWidth: "100px",
                      }}
                    />
                  </Box>
                </Box>
              )}
              {isLocked && (
                <Box className="campaign-target-step__header-text_locked">
                  <Box component="i" className="fa-solid fa-lock-keyhole" />
                  <Box component="p">Completed</Box>
                </Box>
              )}
              {isView && isCompleted && (
                <Box className="fa-solid fa-check fa-xs campaign-target-step__check-icon" />
              )}
              {!isView && stepIndex >= 2 && !isCompleted && (
                <IconButton size="small" onClick={handleDeleteStep}>
                  <Box component="i" className="fa-solid fa-trash" />
                </IconButton>
              )}
            </Box>
          )}
          {isView ? (
            <Box className="campaign-target__steps">
              <Box className="campaign-target__step">
                <FormLabel className="target-select__label">
                  <Box component="p" className="target-select__label">
                    KPI count
                  </Box>
                </FormLabel>
                <Box component="p" className="target-select__text">
                  {String(step.target_value.value)}
                </Box>
              </Box>
              {!!step.reward && (
                <Box className="campaign-target__step">
                  <FormLabel className="target-select__label">
                    <Box component="p" className="target-select__label">
                      Money reward
                    </Box>
                  </FormLabel>
                  <Box component="p" className="target-select__text">
                    {numberWithCurrency(step.reward, currency)}
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              className={
                stepIndex
                  ? "campaign-target__steps"
                  : `campaign-target-step__single ${isLocked && "disabled"}`
              }
            >
              <CampaignTargetSelect
                label="KPI count"
                handleTextfieldChange={(value) => {
                  if (digitsRegExp.test(value)) {
                    handleTargetChange?.(
                      target,
                      stepIndex > 0 ? stepIndex - 1 : 0,
                      Number(reward),
                      { value: Number(value), error: "" },
                      undefined,
                      undefined,
                      undefined,
                      isEdit,
                    );
                    setTargetValue(value);
                  }
                }}
                value={targetValue}
                defaultValue={targetValue}
                placeHolder="Type amount"
                borderLine={false}
                isRequired
                error={error}
                isHidden={isHiddenIndicator}
              />
              <CampaignTargetSelect
                label="Add money reward"
                handleTextfieldChange={(value) => {
                  if (digitsRegExp.test(value)) {
                    handleTargetChange?.(
                      target,
                      stepIndex > 0 ? stepIndex - 1 : 0,
                      Number(value),
                      { value: Number(targetValue), error: "" },
                      undefined,
                      undefined,
                      undefined,
                      isEdit,
                    );
                    setReward(value);
                  }
                }}
                value={reward}
                placeHolder={`Price in ${currency}`}
                borderLine={stepIndex && false}
                isHidden={isHiddenIndicator}
                defaultValue={reward}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default CampaignTargetStep;
