import { Box, Input, Text } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";

type Props = {
  header: string;
  value?: string;
  onChange: (newValue: string) => void;
  onBlur?: (e?: any) => void;
  placeholder?: string;
  description?: string;
  required?: true;
  width?: string;
  height?: string;
  disabled?: boolean;
  optional?: boolean;
  defaultValue?: string;
  type?: string;
  className?: string;
  min?: number;
  max?: number;
  step?: number;
  onPaste?: any;
  error?: boolean;
  helperText?: string;
  hiddenIndicator?: boolean;
};

const IndicatorInput = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [error, setError] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e: any) => {
    setIsFocused(false);
    if (props.required && props.value === "") {
      // setError(true);
    } else {
      // setError(false);
    }
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {!props.hiddenIndicator && (
          <Box
            sx={{
              background: isFocused ? "#249FF0" : "#EBEBEB",
              height: "auto",
              marginRight: "8px",
              width: "1px",
            }}
          />
        )}
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "5px",
            }}
          >
            <Box>
              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  width: "auto",
                  padding: 0,
                  color: "#222325",
                }}
              >
                {props.header}
                {props.optional && (
                  <Text
                    as="span"
                    sx={{
                      fontSize: "12px",
                      marginLeft: "8px",
                      fontWeight: 300,
                      color: "#AAAAAA",
                      fontStyle: "italic",
                    }}
                  >
                    Optional
                  </Text>
                )}
              </Text>
            </Box>
            <Box>
              {props.description && (
                <Text
                  sx={{
                    fontSize: "12px",
                    marginLeft: "8px",
                    fontWeight: 400,
                    color: "#AAAAAA",
                  }}
                >
                  {props.description}
                </Text>
              )}
            </Box>
          </Box>
          <Input
            placeholder={props.value ? props.value : props.placeholder}
            min={props.min || undefined}
            max={props.max || undefined}
            step={props.max || undefined}
            value={props?.value}
            defaultValue={props.defaultValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            background="white"
            focusBorderColor="#249FF0"
            borderWidth="0.5px"
            width={props.width ? props.width : "130px"}
            height={props.height ? props.height : "34px"}
            sx={{ "::placeholder": { color: "#AAAAAA" } }}
            disabled={props.disabled}
            type={props.type || "text"}
            onPaste={props.onPaste}
            borderColor={props.error ? "#f56565" : "inherit"}
          />
        </Box>
      </Box>
      {/* if we want to add an error massage replace false with "error" */}
      {props.error && !isFocused && (
        <div>
          <i className="error-mark" />
          <span className="error-text">
            {props.helperText
              ? props.helperText
              : "Whoops! This field can not be empty"}
          </span>
        </div>
      )}
    </>
  );
};

export default IndicatorInput;
