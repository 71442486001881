import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { MetaTag } from "src/api/ExtendedTypes";
import { addOrRemove } from "src/utility";

const initialState = {
  filter: {
    search: "",
    tags: [] as MetaTag[],
  },
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setFilterSearch(draftState, { payload: newSearch }: PayloadAction<string>) {
      draftState.filter.search = newSearch;
    },
    addOrRemoveFilterTag(draftState, { payload: tag }: PayloadAction<MetaTag>) {
      draftState.filter.tags = addOrRemove(
        current(draftState).filter.tags,
        [tag],
        (obj) => obj.id,
      );
    },
    resetFilter(draftState) {
      draftState.filter = initialState.filter;
    },
  },
});

export const { reducer: campaignReducer, actions: campaignActions } =
  campaignSlice;
