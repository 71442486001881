// TODO - lint
/* eslint-disable react/no-deprecated, camelcase, camelcase, no-param-reassign, no-param-reassign, no-nested-ternary, no-param-reassign, no-param-reassign, no-restricted-globals, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, import/no-default-export */
// General Imports
import React from "react";
import Dialog from "rc-dialog";
import { connect } from "react-redux";

// Components Import
import { TaskTypes } from "../utilities/providers";
import { editListKpis } from "../../../actions/premiumActions";

class EditListKpisDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: TaskTypes.filter((x) => x.value !== "influencer_content").map(
        (x) => x,
      ),
      saving: false,
    };
    this.state.tasks.splice(1, 0, {
      label: "Instagram Reel",
      value: "ig_reel",
      icon: "fa moi-reels",
    });
  }

  componentWillMount() {
    let { tasks } = this.state;
    const tasks_data = this.props.tasks;
    tasks = tasks.map((t) => {
      const data = tasks_data[t.value];
      t.amount = data ? data.amount : 0;
      t.views_percentage = data
        ? data.views_percentage
          ? `${data.views_percentage.toString()}%`
          : "0%"
        : "0%";
      t.engagement_percentage = data
        ? `${data.engagement_percentage.toString()}%`
        : "0%";
      return t;
    });

    this.setState({ tasks });
  }

  changeInput = (e, field, position, precentage) => {
    const value = e?.target?.value?.trim();
    if (precentage && /^\d+(\.\d+)?%$/.test(value)) {
      field[position] = value;
      this.setState({});
    } else if (!isNaN(value)) {
      field[position] = value;
      this.setState({});
    }
  };

  onBlur = (field, position, precentage) => {
    const value = field[position];
    if (!value) {
      field[position] = "1";
    } else {
      const validation = /^\d+\.?\d?\d?%?$/;
      if (!validation.test(value)) field[position] = "1";
    }

    if (precentage) {
      if (value === "0%" || value === "0") {
        field[position] = "1%";
      } else if (!value.includes("%")) {
        field[position] += "%";
      }
    }
    this.setState({});
  };

  onSave = () => {
    this.setState({ saving: true });
    if (this.state.saving) return;

    const kpis = {};
    this.state.tasks.forEach((t) => {
      kpis[t.value] = {
        amount: t.amount,
        views_percentage: t.views_percentage.replace("%", ""),
        engagement_percentage: t.engagement_percentage.replace("%", ""),
      };
    });

    this.props.editListKpis(this.props.listId, kpis, () => {
      this.props.handleClose();
    });
  };

  render() {
    let { tasks } = this.state;
    if (!this.props.showTikTok) {
      tasks = tasks.filter((x) => x.value !== "tk_video");
    }
    return (
      <Dialog
        onClose={this.props.handleClose}
        className="edit-tasks-dialog"
        visible={true}
        style={{ marginTop: 50 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <>
          <div className="edit-tasks-header">Edit Kpis</div>

          <div className="edit-list-kpis">
            {tasks.map((t, idx) => this.renderTaskCubes(t, idx))}
          </div>

          <div style={{ marginLeft: 30, color: "#aaa", fontSize: 13 }}>
            Tasks - Avrege tasks for each influencer in the category <br />
            Impressions - A precentage of the system category Impressions to
            display <br />
            Engagements - A precentage of the system category Engagements to
            display <br />
          </div>

          <div
            style={{ paddingBottom: 15, paddingTop: 15, textAlign: "center" }}
          >
            <button
              className={`attach-links-btn btn btn-primary cancel ${
                this.state.saving && "disabled"
              }`}
              onClick={() => this.props.handleClose()}
            >
              <span>Cancel</span>
            </button>
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn btn btn-primary ${
                this.state.saving && "disabled"
              }`}
              onClick={this.onSave}
            >
              <span>Save</span>
            </button>
          </div>
        </>
      </Dialog>
    );
  }

  renderTaskCubes = (task, idx) => {
    return (
      <div key={idx}>
        <div className={task.icon}></div>
        <div>{task.label}:</div>
        <div>
          <div>
            <div>Tasks:</div>
            <input
              value={task.amount}
              onBlur={() => this.onBlur(task, "amount", false)}
              onChange={(e) => this.changeInput(e, task, "amount", false)}
              className="input-line"
            ></input>
          </div>

          <div>
            <div>
              {["ig_post", "tw_tweet"].includes(task.value)
                ? "Impressions"
                : "Views"}
              :
            </div>
            <input
              value={task.views_percentage}
              onBlur={() => this.onBlur(task, "views_percentage", true)}
              onChange={(e) =>
                this.changeInput(e, task, "views_percentage", true)
              }
              className="input-line"
            ></input>
          </div>

          {task.value !== "ig_story" && (
            <div>
              <div>Engagements:</div>
              <input
                value={task.engagement_percentage}
                onBlur={() => this.onBlur(task, "engagement_percentage", true)}
                onChange={(e) =>
                  this.changeInput(e, task, "engagement_percentage", true)
                }
                className="input-line"
              ></input>
            </div>
          )}
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    editListKpis: (id, kpis, callback) => {
      dispatch(editListKpis(id, kpis, callback));
    },
  };
};

export default connect(null, mapDispatchToProps)(EditListKpisDialog);
