import {
  Box,
  BoxProps,
  Button,
  chakra,
  Input,
  Select,
  Table,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { InfiniteLoader } from "../InfiniteLoader";

interface Props<Data extends object> {
  containerProps?: BoxProps;
  columns: ColumnDef<Data, any>[];
  data: any;
  pageSizeAmount: number;
  thStyle?: React.CSSProperties;
  showInputFilter?: boolean;
  containerClassName?: string;
  defaultSorting?: SortingState;
  tableContainerProps?: TableContainerProps;
  isInfinit?: boolean;
  handleInfinitLoad?: any;
  isPagingLoading?: boolean;
  rowHeight?: number;
  containerHeight?: number;
}

// todo: fix pagination
const DataTable = <Data extends object>({
  containerProps,
  columns,
  data,
  pageSizeAmount,
  thStyle,
  showInputFilter,
  containerClassName,
  defaultSorting,
  tableContainerProps,
  isInfinit,
  handleInfinitLoad,
  isPagingLoading,
}: Props<Data>) => {
  const [sorting, setSorting] = React.useState<SortingState>(
    defaultSorting || [],
  );
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageSize: pageSizeAmount,
    pageIndex: 0,
  });

  const table = useReactTable({
    columns,
    data,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
    },
  });

  const renderRow = (row: any) => {
    return (
      <Tr key={row.id}>
        {row.getVisibleCells().map((cell: any) => {
          const { meta }: any = cell.column.columnDef;
          return (
            <Td className="lefty" key={cell.id} isNumeric={meta?.isNumeric}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
      </Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table variant="simple">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const { meta }: any = header.column.columnDef;
                const sort = header.column.getIsSorted();
                return (
                  <Th
                    style={thStyle}
                    className="lefty"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}

                    {/* <Text> {header.column.columnDef.input_name}</Text> */}
                    <chakra.span pl="4">
                      {sort ? (
                        <span
                          className={`fa fa-arrow-${
                            sort === "desc" ? "down" : "up"
                          }`}
                        />
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
          {showInputFilter &&
            table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const { meta }: any = header.column.columnDef;
                  return (
                    <Th key={header.id} ml="2" mt="0" mb="0" p="2">
                      {meta?.selectInputData ? (
                        <Select
                          textAlign={"center"}
                          placeholder={meta.selectInputData.placeHolder}
                          onChange={(e) => {
                            meta.selectInputData.onSelectChange(e.target.value);
                          }}
                        >
                          {meta.selectInputData.options.map(
                            (o: { label: any; value: any }, idx: number) => (
                              <option key={idx} value={o.value}>
                                {o.label}
                              </option>
                            ),
                          )}
                        </Select>
                      ) : (
                        <Input
                          borderColor="#b3bec9"
                          onChange={(e) =>
                            meta?.onTextFilterChange(e.target.value)
                          }
                        />
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
        </Thead>
        <Tbody>{table.getRowModel().rows.map((row) => renderRow(row))}</Tbody>
      </Table>
    );
  };
  return (
    <Box {...(containerProps || {})} className={containerClassName}>
      <TableContainer {...(tableContainerProps || {})}>
        {renderTable()}
      </TableContainer>
      {isInfinit && handleInfinitLoad && (
        <>
          {isPagingLoading ? (
            <div
              style={{
                position: "fixed",
                bottom: 10,
                margin: "0 auto",
                left: 0,
                right: 0,
              }}
            >
              <InfiniteLoader />
            </div>
          ) : (
            <Button style={{ margin: "20px auto" }} onClick={handleInfinitLoad}>
              Load more
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default DataTable;
