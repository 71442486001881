export type TabStatus = "Not finished" | "Ready" | "default" | "Active";

export enum Tab {
  Type = "type_tab",
  Overview = "overview_tab",
  Sales = "sales_tab",
  Questions = "questions_tab",
  Product = "product_tab",
  Payments = "payments_tab",
  Tasks = "tasks_tab",
  DoDonts = "dos_and_donts_tab",
}

export const getTabs = (isAffiliation = false) => {
  const tabs: Tab[] = [
    Tab.Type,
    Tab.Overview,
    Tab.Tasks,
    Tab.Questions,
    Tab.Product,
    Tab.Payments,
    Tab.DoDonts,
  ];
  if (isAffiliation) {
    return [...tabs.slice(0, 2), Tab.Sales, ...tabs.slice(2)];
  }
  return tabs;
};
