// TODO - lint
/* eslint-disable import/no-default-export, react/no-deprecated, consistent-return, no-param-reassign, no-nested-ternary, no-param-reassign, no-unused-vars, camelcase, no-param-reassign, camelcase, camelcase, no-param-reassign, no-unused-vars, no-param-reassign, class-methods-use-this, no-param-reassign, camelcase, camelcase, camelcase, no-param-reassign, no-param-reassign */
// General Imports
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import React from "react";
import Infinite from "react-infinite";
import CampaignLinksSideInfluencerRow from "./CampaignLinksSideInfluencerRow";

// Actions imports
const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim;

export default class CampaignLinksSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      links: [],
      loading: true,
      saving: [],
      currentInfluencer: null,
      isOpenToEdit: null,
    };
  }

  getShortLinks(callback) {
    this.setState({ loading: true });
    this.props.getBoardShortLinks(this.props.boardId, (data) => {
      if (data && data.links) {
        this.setState({ links: data.links });
      }
      this.setState({ loading: false });
      if (callback) callback();
    });
  }

  componentWillMount() {
    this.getShortLinks();
  }

  getUserName = (id) => {
    const influ = this.props.influencers.influencers.find(
      (x) => x.influencer_id === id,
    );
    if (influ?.social_network_data?.instagram?.username)
      return influ.social_network_data.instagram.username;
    if (influ?.social_network_data?.tiktok?.username)
      return influ.social_network_data.tiktok.username;
    if (influ?.social_network_data?.twitter?.username)
      return influ.social_network_data.twitter.username;
    // if (influ?.social_network_data?.youtube?.username)
    //   return influ.social_network_data.youtube.username;
    return influ.name?.replaceAll(" ", "_") || "influencer";
  };

  saveInfLink = (inf) => {
    const infId = inf.influencer_id;

    if (this.state.saving.includes(infId)) return;

    const data = {
      long_link: inf.new_long_link?.replace(
        "${influencer_name}",
        this.getUserName(infId),
      ),
      short_link: this.props.shortLink,
      link_type: "campaign",
    };

    if (
      !data.long_link &&
      !this.state.links.find((x) => x.influencer_id === infId)
    ) {
      return;
    }
    if (
      data.short_link === data.long_link ||
      data.long_link.includes(".humanz.ai")
    ) {
      return;
    }

    if (!data.long_link) {
      data.long_link = this.props.longLink;
    }

    this.state.saving.push(infId);
    this.props.editInfluencerShortLink(
      this.props.boardId,
      infId,
      data,
      (success) => {
        this.state.saving.addOrRemove(infId);
        if (success) {
          const link = this.state.links.find((x) => x.influencer_id === infId);
          if (link) {
            link.long_link = data.long_link;
          } else {
            this.state.links.push({
              influencer_id: infId,
              long_link: data.long_link,
              extra_links: link ? link.extra_links : [],
              created_by_influencer: false,
            });
            this.setState({});
          }
        }
        this.getShortLinks();
      },
    );
  };

  renderLinkAlert(l) {
    const linkWebsite = l?.long_link?.match(domainRegex);
    const campaignWebsite = this.props?.longLink?.match(domainRegex);
    return l?.long_link?.length &&
      linkWebsite &&
      campaignWebsite &&
      linkWebsite[0] !== campaignWebsite[0] ? (
      <Alert variant="top-accent" status="warning" marginTop="4">
        <AlertIcon />
        <AlertDescription>
          <AlertTitle>
            This link domain is different from your sales website -{" "}
            <strong>{campaignWebsite[0]}</strong>
          </AlertTitle>
          Please notice that this link will not track any sales.
        </AlertDescription>
      </Alert>
    ) : null;
  }

  renderRow(data, idx) {
    return (
      <CampaignLinksSideInfluencerRow
        data={data}
        idx={idx}
        key={idx}
        shortLink={this.props.shortLink}
        search={this.state.search}
        links={this.state.links}
        saveInfLink={this.saveInfLink}
        renderLinkAlert={this.renderLinkAlert.bind(this)}
        saveExtraInfLink={this.saveExtraInfLink.bind(this)}
        addExtraLink={this.addExtraLink.bind(this)}
        deleteExtraLink={this.deleteExtraLink.bind(this)}
        currentInfluencer={this.state.currentInfluencer}
        setState={this.setState.bind(this)}
        isExtraLoading={this.state.isExtraLoading}
        isOpenToEdit={this.state.isOpenToEdit}
      />
    );
  }

  saveExtraInfLink = (data, influ) => {
    const infId = influ.influencer_id;
    const newData = {
      short_link:
        data.short_link || `${this.props.shortLink}/${infId}/${data.extra_id}`,
      long_link: data.long_link.replace(
        "${influencer_name}",
        this.getUserName(infId),
      ),
      description: data.description,
      extra_id: data.extra_id,
      link_type: "campaign",
    };
    if (newData.long_link.includes(".humanz.ai")) {
      return;
    }
    this.props.editInfluencerShortLink(
      this.props.boardId,
      infId,
      newData,
      (success) => {
        data.draft = 0;
        this.setState({ currentInfluencer: null }, () => {
          this.getShortLinks();
        });
      },
    );
  };

  findMaxId = (arr) => {
    let max = 0;
    arr.forEach((e) => {
      max = e.extra_id > max ? e.extra_id : max;
    });
    return max;
  };

  addExtraLink = (data) => {
    if (!data.extra_links) data.extra_links = [];
    const extra_id = data.extra_links?.length
      ? this.findMaxId(data.extra_links) + 1
      : 1;
    data.extra_links.push({
      extra_id,
      short_link: `${this.props.shortLink}/${data.influencer_id}/${extra_id}`,
      long_link: "",
      description: "",
      draft: 1,
      created_by_influencer: false,
    });
    this.setState({});
  };

  deleteExtraLink = (data, link) => {
    if (link.draft === 1) {
      data.extra_links = data.extra_links.filter(
        (el) => el.extra_id !== link.extra_id,
      );
      this.setState({});
      return;
    }
    this.props.deleteInfluencerShortLink(
      this.props.boardId,
      data.influencer_id,
      { extra_id: link.extra_id },
      () => {
        this.setState({ isExtraLoading: true });
        this.getShortLinks(() => {
          data.extra_links = data.extra_links.filter(
            (el) => link.extra_id !== el.extra_id,
          );
          this.setState({ isExtraLoading: false });
        });
      },
    );
  };

  render() {
    const influencers = this.props.influencers
      ? this.props.influencers.influencers
      : [];
    return (
      <div
        style={{ width: 750, marginLeft: "calc(100% - 970px)" }}
        className="import-from-list-container animated fadeInRight faster"
        id="campaign_links_menu"
      >
        {this.state.loading ? (
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        ) : (
          <div>
            <div style={{ fontSize: 22 }}>Campaign Links</div>
            <div
              className="import-list-close-button"
              onClick={() => this.props.handleClose()}
            >
              <span className="moi-ximage" />
            </div>
            <a
              target="_blank"
              href={this.props.longLink}
              style={{
                fontSize: 16,
                marginTop: 5,
                display: "block",
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              title={this.props.longLink}
              rel="noreferrer"
            >
              {this.props.longLink}
            </a>
            <div
              className="import-from-list-spacer"
              style={{ marginTop: 20, marginBottom: 20 }}
            />
            {influencers?.length === 0 ? (
              <div style={{ textAlign: "center", fontSize: 17 }}>
                No influencers in progress (approval/publish) yet
              </div>
            ) : (
              <div>
                <div className="import-list-influencers-row">
                  <div
                    className="inner-toolbar-search-input"
                    style={{ width: 173 }}
                  >
                    <div
                      className="moi-search-bold"
                      style={{ fontSize: 12, marginTop: 4 }}
                    />
                    <input
                      onChange={(e) => {
                        console.log(e);
                        this.setState({
                          search: e?.target?.value?.toLowerCase(),
                        });
                      }}
                      style={{ height: 20, width: 114 }}
                      className="form-control"
                    />
                  </div>
                  <strong
                    className="small-title"
                    style={{ marginLeft: 11, flex: 4, textAlign: "left" }}
                  >
                    Link
                  </strong>
                  <strong className="small-title" style={{ flex: 2 }}>
                    Copy Link
                  </strong>
                  <strong className="small-title" style={{ flex: 1 }}>
                    Edit Links
                  </strong>
                  <strong className="small-title" style={{ flex: 1 }}>
                    Links #
                  </strong>
                </div>
                {/* <div
                  className="import-list-section no-infinite"
                  style={{ marginTop: 10, height: "calc(100vh - 300px)" }}
                > */}
                <Infinite
                  elementHeight={66}
                  containerHeight={window.innerHeight - 300}
                  className="import-list-section"
                  useWindowAsScrollContainer={false}
                  timeScrollStateLastsForAfterUserScrolls={0}
                  onInfiniteLoad={() => {}}
                  isInfiniteLoading={false}
                >
                  {influencers?.map((x, idx) => this.renderRow(x, idx))}
                </Infinite>
                {/* </div> */}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
