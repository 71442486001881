import React from "react";
import { connect } from "react-redux";
import Tooltip from "rc-tooltip";
import { Link, useNavigate } from "react-router-dom";
import { formattedDate } from "../utilities/general";
import {
  getAllReports,
  deleteStandaloneReport,
  toggleReport,
} from "../../../actions/brandReportsActions";
import { MessageDialog } from "../dialogs/MessageDialog";
import { BrandReportDescDialog } from "./BrandReportDescDialog";
import Logo from "../../../images/humanz-logo.png";

class BrandReportInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      isLoaded: true,
      selectedReport: false,
      showDeleteDialog: false,
      showToggleDialog: false,
      showPaymentsDialog: false,
    };
  }

  loadBrandReport() {
    if (
      !this.props.brandReportsReducer.brand_reports ||
      !this.props.brandReportsReducer.brand_reports.length
    ) {
      this.setState({ isLoaded: false });
    }
    this.props.getBrandReports((response) => {
      if (!response || !response.length) {
        this.setState({ isLoaded: true, showPaymentsDialog: true });
      } else {
        this.setState({ isLoaded: true });
      }
    });
  }

  UNSAFE_componentWillMount() {
    this.loadBrandReport();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.user.userDetails &&
      nextProps.user.userDetails.sidebar_data &&
      nextProps.user.userDetails.sidebar_data.allowed_brand_report !== null
    ) {
      if (!nextProps.user.userDetails.sidebar_data.allowed_brand_report) {
        this.props.navigate("/home");
      }
    }
  }

  onDeleteReport = (id) => {
    this.props.deleteStandaloneReport(id, () => {
      this.setState({ showDeleteDialog: false });
      this.loadBrandReport();
    });
  };

  showDeleteDialog = (report) => {
    this.setState({ showDeleteDialog: true, selectedReport: report });
  };

  onToggleReport = (report, state) => {
    this.props.toggleReport(report, state, () => {
      this.setState({ showToggleDialog: false });
      this.loadBrandReport();
    });
  };

  showToggleDialog = (report) => {
    this.setState({ showToggleDialog: true, selectedReport: report });
  };

  edit = (report) => {
    this.props.navigate(`edit-brand-report/${report.id}`);
  };

  renderBrandReportRow = (report, i) => {
    const name =
      report.brand_report_title === report.instagram_page
        ? report.brand_report_title
        : `${report.brand_report_title} - ${report.instagram_page}`;
    return (
      <div key={i}>
        <div
          className={`campaign-details-container brand-report-row ${
            report.stop_reason === "payment_failure" ? "payment-failed" : ""
          }`}
          style={
            report.enabled ? {} : { background: "rgba(255, 255, 255, 0.12)" }
          }
        >
          {report.stop_reason === "payment_failure" ? (
            <Tooltip
              placement="top"
              trigger={["hover"]}
              overlay={
                <span>
                  {
                    "Payment failure. Please contact us or check your billing settings."
                  }
                </span>
              }
            >
              <div className="position-relative">
                <i className="fa fa-warning text-danger" />
                <div className="stop_error payment_failure">
                  Payment Failure
                </div>
              </div>
            </Tooltip>
          ) : (
            ""
          )}
          <img className="blurable brand-report-row-img" src={report.image} />
          <div className="campaign-details-rows">
            <div className="campaign-details-row">
              <strong
                onClick={() => {
                  // eslint-disable-next-line no-param-reassign
                  report.isOpen = !report.isOpen;
                  this.setState({ updated: true });
                }}
                className="clickable campaign-name blurable"
              >
                {name}
              </strong>
            </div>
            <div style={{ textAlign: "center", display: "inline-block" }}>
              <div style={{ fontSize: 18, marginTop: 8, marginBottom: 8 }}>
                {formattedDate(report.creation_time)}
              </div>
              <div style={{ width: 150 }}>
                <strong className="list-influencer-details-desc">
                  Created
                </strong>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                display: "inline-block",
                marginLeft: 30,
              }}
            >
              <div style={{ fontSize: 18, marginTop: 8, marginBottom: 8 }}>
                {report.reports.length}
              </div>
              <div style={{ width: 150 }}>
                <strong className="list-influencer-details-desc">
                  PDF reports
                </strong>
              </div>
            </div>
          </div>

          <div className="list-influencer-actions">
            <div style={{ display: "flex" }}>
              <Tooltip
                placement="top"
                trigger={["hover"]}
                overlay={<span>{"Edit report"}</span>}
              >
                <Link to={`edit-brand-report/${report.id}`}>
                  <div className="moi-pencil-edit-button" />
                </Link>
              </Tooltip>
              <Tooltip
                placement="top"
                trigger={["hover"]}
                overlay={
                  <span>
                    {report.enabled ? "Stop the report" : "Activate the report"}
                  </span>
                }
              >
                <div
                  className="fa fa-hand-paper"
                  style={
                    report.enabled ? { color: "#aaaaaa" } : { color: "red" }
                  }
                  onClick={() => this.showToggleDialog(report)}
                />
              </Tooltip>
              <Tooltip
                placement="top"
                trigger={["hover"]}
                overlay={<span>{"Delete report"}</span>}
              >
                <div
                  className="moi-trash"
                  onClick={() => this.showDeleteDialog(report)}
                ></div>
              </Tooltip>
            </div>
          </div>
          <div
            onClick={() => {
              // eslint-disable-next-line no-param-reassign
              report.isOpen = !report.isOpen;
              this.setState({ updated: true });
            }}
            className={"clickable collapse-icon "}
          >
            <Tooltip
              placement="top"
              trigger={["hover"]}
              overlay={<span>{"Show all PDF reports"}</span>}
            >
              <i
                className={`fa ${
                  report.isOpen ? "fa-chevron-down" : "fa-chevron-right"
                }`}
              />
            </Tooltip>
          </div>
        </div>
        <div
          className={`${report.isOpen ? "opened" : "closed"} second-report-row`}
        >
          <div className="second-report-row-inner">
            {report.reports.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  download
                  rel="noreferrer"
                >
                  <div
                    className={`pdficon ${item.is_initial ? "selected" : ""}`}
                  >
                    <i className="fa fa-file-alt"></i>
                    <p>{formattedDate(item.creation_time)}</p>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        {this.state.showDeleteDialog && (
          <MessageDialog
            handleClose={() => {
              this.setState({ showDeleteDialog: false });
            }}
            icon="moi-trash"
          >
            <div style={{ textAlign: "center", padding: 20 }}>
              <strong>Notice</strong>
              <p style={{ marginTop: 15 }}>
                Deleteing a report is irreversible.
              </p>
              <button
                onClick={() =>
                  this.onDeleteReport(this.state.selectedReport.id)
                }
                className="btn btn-primry"
                style={{
                  background: "var(--main-baby-blue-color)",
                  color: "white",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
              >
                DELETE
              </button>
            </div>
          </MessageDialog>
        )}
        {this.state.showToggleDialog && (
          <MessageDialog
            handleClose={() => {
              this.setState({ showToggleDialog: false });
            }}
            icon="moi-trash"
          >
            <div style={{ textAlign: "center", padding: 20 }}>
              <strong>Notice</strong>
              <p style={{ marginTop: 15 }}>
                Are you sure you want to{" "}
                {this.state.selectedReport.enabled ? "stop" : "activate"} this
                report?
              </p>
              <button
                onClick={() =>
                  this.onToggleReport(
                    this.state.selectedReport,
                    !this.state.selectedReport.enabled,
                  )
                }
                className="btn btn-primry"
                style={{
                  background: "var(--main-baby-blue-color)",
                  color: "white",
                  borderRadius: "40px",
                  width: 100,
                  marginTop: 15,
                }}
              >
                {this.state.selectedReport.enabled ? "STOP" : "ACTIVATE"}
              </button>
            </div>
          </MessageDialog>
        )}
      </div>
    );
  };

  renderBrandList() {
    const { brandReportsReducer } = this.props;
    return (
      <div className="brand-report-section-main">
        <div className="section-middle">
          <div style={{ width: "var(--main-inner-size)", margin: "0 auto" }}>
            <div className="toolbar" style={{ marginTop: 10 }}>
              <div className="brand-report-title">Brand list</div>
              <div className="flex">
                <div className="inner-toolbar-search-input">
                  <div className="moi-search-bold" />
                  <input
                    onChange={(e) =>
                      this.setState({ search: e.target.value.toLowerCase() })
                    }
                    className="form-control"
                  />
                </div>
                <div
                  className="create-buttons-row main-brand-buttons-right"
                  style={{ paddingTop: 20 }}
                >
                  <Link to="/edit-brand-report">
                    <button className={"btn btn-primry"}>
                      <span
                        className="moi-plus"
                        style={{
                          color: "var(--main-baby-blue-color)",
                          fontSize: 11,
                        }}
                      />{" "}
                      Add new
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="brand-report-details-container campaigns-rows">
              {brandReportsReducer.brand_reports
                .filter((item) =>
                  (item.brand_report_title ? item.brand_report_title : "")
                    .toLowerCase()
                    .includes(this.state.search),
                )
                .map(this.renderBrandReportRow)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="logo-loader">
          <div className="loader" />
          <img src={Logo} />
        </div>
      );
    }
    return (
      <>
        {this.state.showPaymentsDialog && (
          <BrandReportDescDialog
            handleClose={() => this.setState({ showPaymentsDialog: false })}
          />
        )}
        {this.renderBrandList()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    brandReportsReducer: state.brandReportsReducer,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrandReports: (callback) => {
      dispatch(getAllReports(callback));
    },
    deleteStandaloneReport: (id, callback) => {
      dispatch(deleteStandaloneReport(id, callback));
    },
    toggleReport: (report, state, callback) => {
      dispatch(toggleReport(report, state, callback));
    },
  };
};

const BrandReportConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandReportInner);

export const BrandReport = (props) => {
  const navigate = useNavigate();

  return <BrandReportConnected {...props} navigate={navigate} />;
};
