interface OwnProps {
  content: any;
}

export default function HumanzPage({ content }: OwnProps) {
  return (
    <div className="humanz-page">
      <div className="humanz-container">
        <div className="humanz-content">{content}</div>
      </div>
    </div>
  );
}
