import { PureComponent } from "react";
import { connect } from "react-redux";
import {
  acceptEnterpriseInvite,
  declineEnterpriseInvite,
  getEnterpriseInvite,
} from "../../../actions/enterpriseActions";
import { MessageDialog } from "../dialogs/MessageDialog";

interface OwnProps {
  user: any;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{
  inviteData: any;
  loading: boolean;
  isAccepted: boolean;
  openMessageDialog: boolean;
}>;

class EnterpriseInviteInner extends PureComponent<Props, State> {
  readonly state: State = {
    inviteData: null,
    loading: false,
    isAccepted: false,
    openMessageDialog: true,
  };

  inviteID = this.props.user
    ? this.props.user.userDetails.waiting_enterprise_invite
    : false;

  componentDidMount(): void {
    this.init();
  }

  init() {
    if (this.props.user && this.inviteID) {
      this.props.getEnterpriseInvite(this.inviteID, (data: any) => {
        this.setState({ inviteData: data, openMessageDialog: true });
      });
    }
  }

  accept = () => {
    this.setState({ loading: true });
    this.props.acceptEnterpriseInvite(this.inviteID, () => {
      this.setState({ isAccepted: true, loading: false });
    });
  };

  decline = () => {
    this.setState({ loading: true });
    this.props.declineEnterpriseInvite(this.inviteID, () => {
      this.setState({ openMessageDialog: false });
    });
  };

  componentDidUpdate(): void {
    if (!this.inviteID) {
      this.inviteID = this.props.user
        ? this.props.user.userDetails.waiting_enterprise_invite
        : false;
      this.init();
    }
  }

  render() {
    const invite = this.state.inviteData;
    return this.state.openMessageDialog &&
      invite &&
      invite.invite_status === "waiting_response" ? (
      <MessageDialog
        disableClose={true}
        handleClose={() => {}}
        image={invite.image}
        icon="moi-trash"
      >
        <div style={{ textAlign: "center", padding: 20 }}>
          {this.state.loading ? (
            <>Please wait...</>
          ) : (
            <>
              {!this.state.isAccepted ? (
                <>
                  <strong>
                    {invite.enterprise_name} has invited you to collaborate on
                    their Humanz account
                  </strong>
                  <p style={{ marginTop: 15 }}>
                    The campaigns and lists that they share with you will also
                    be available on your profile.
                    <br />
                    <br />
                    <button
                      className={"btn btn-primary"}
                      onClick={this.accept}
                      style={{ marginRight: 6 }}
                    >
                      Accept
                    </button>
                    <button className={"btn btn-cancel"} onClick={this.decline}>
                      Decline
                    </button>
                  </p>
                </>
              ) : (
                <>
                  <strong>Please refresh the page</strong>
                  <p style={{ marginTop: 15 }}>
                    You will need to refresh the page in order to see{" "}
                    {invite.enterprise_name} content.
                  </p>
                  <button
                    className={"btn btn-primary"}
                    onClick={() => {
                      window.location.reload();
                    }}
                    style={{ marginRight: 6 }}
                  >
                    Refresh
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </MessageDialog>
    ) : null;
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => ({
  getEnterpriseInvite: (id: string, callback: any) =>
    dispatch(getEnterpriseInvite(id, callback)),
  acceptEnterpriseInvite: (id: string, callback: any) =>
    dispatch(acceptEnterpriseInvite(id, callback)),
  declineEnterpriseInvite: (id: string, callback: any) =>
    dispatch(declineEnterpriseInvite(id, callback)),
});

export const EnterpriseInvite = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterpriseInviteInner);
