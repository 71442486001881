import axios from "axios";
import { toast } from "react-toastify";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";
import { getUserSideDetails } from "./userActions";

const getInsightsUrlBase = () => `${getBackendUri()}/moi/insights`;
const getReportsUrlBase = () => `${getBackendUri()}/moi/standalone_roi`;

export const getStandaloneReports = (callback) => {
  return (dispatch) => {
    axios
      .get(getInsightsUrlBase(), {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "GET_STANDALONE_REPORTS", payload: response.data });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get reports",
          "system_notifications_failure_get_standalone_reports",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false);
        console.error(err);
      });
  };
};

export const getStandaloneReport = (id, callback) => {
  return (dispatch) => {
    axios
      .get(`${getReportsUrlBase()}/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "GET_STANDALONE_REPORT", payload: response.data });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get report",
          "system_notifications_failure_get_standalone_report",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false);
        console.error(err);
      });
  };
};

export const createStandaloneReport = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(getReportsUrlBase(), data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
        dispatch(getUserSideDetails());
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.bad_rows) {
          callback(false, err.response.data.bad_rows);
        } else {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to create report",
            "system_notifications_failure_create_standalone_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const editStandaloneReport = (id, data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${getReportsUrlBase()}/${id}`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.bad_rows) {
          callback(false, err.response.data.bad_rows);
        } else {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to edit report",
            "system_notifications_failure_edit_standalone_report",
          );
          toast.error(notificationOpts.message);
          if (callback) callback(false, null);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const deleteStandaloneReport = (id, callback) => {
  return () => {
    axios
      .delete(`${getReportsUrlBase()}/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit report",
          "system_notifications_failure_edit_standalone_report",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(false);
      });
  };
};

export const calculateFrequencies = (influencerIds, callback) => {
  return (dispatch) => {
    axios
      .post(
        `${getBackendUri()}/moi/frequenices`,
        { influencers_ids: influencerIds },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response.data);
      })
      .catch((err) => {
        console.log(err);
        if (callback) callback(null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};
