import produce from "immer";
import moment, { Moment } from "moment";
import Tooltip from "rc-tooltip";
import React, { useCallback, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { TaskStage } from "src/api/ExtendedTypes";
import { useBoolean } from "src/hooks/coreHooks";
import { PostSubTypes } from "src/models/Api";
import TaskReferencesPopup from "../../../../dialogs/TaskReferencesPopup";
import { SubTypes } from "../../../../utilities/general";
import {
  get_post_type_icon,
  get_post_type_label,
  TaskTypes,
} from "../../../../utilities/providers";
import Indicator from "../../FormUI/Indicator";
import IndicatorSelect from "../../FormUI/IndicatorSelect";
import IndicatorTags from "../../FormUI/IndicatorTags";
import IndicatorTextarea from "../../FormUI/IndicatorTextarea";

type ValidationKeys = keyof Pick<
  Props["data"],
  "content_due_date" | "due_date"
>;

type Props = {
  data: TaskStage;
  onDataChange?: (data: Props["data"]) => void;
  touched?: {
    [key in ValidationKeys]?: boolean | any;
  };
  errors?: {
    [key in ValidationKeys]?: string | any;
  };
  onDataTouched?: (key: ValidationKeys) => void;
  campaign_dates: {
    start?: Moment;
    end?: Moment;
  };
  isShare?: boolean;
  editMode?: boolean;
  onDelete?: () => void;
  onDuplicate?: () => void;
};

export const BriefTaskRow: React.FC<Props> = ({
  campaign_dates,
  isShare,
  editMode,
  data,
  onDataChange,
  touched,
  onDataTouched,
  errors,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isReferenceVisible, setIsReferenceVisible] = useBoolean(false);
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const setData = useCallback<(newData: Props["data"]) => void>(
    (newData) => {
      const newDataWithAttributes = produce(newData, (draftData) => {
        draftData.changed = true;
      });
      onDataChange(newDataWithAttributes);
    },
    [onDataChange],
  );

  const handleTaskTypeChange = useCallback<(taskType: any) => void>(
    (taskType) => {
      const newData = produce(data, (draftData) => {
        draftData.post_type = taskType.value;
        if (taskType.value !== "ig_post") {
          draftData.post_sub_type = "";
        }
      });
      setData(newData);
    },
    [data, setData],
  );

  const handleTaskSubTypeChange = useCallback<(value: PostSubTypes) => void>(
    (value) => {
      const newData = produce(data, (draftData) => {
        draftData.post_sub_type = value;
      });
      setData(newData);
    },
    [data, setData],
  );

  const tagInputWrapperRef = useRef<HTMLDivElement>(null);

  const handleTagsChange = useCallback<(tags: TaskStage["tags"]) => void>(
    (tags) => {
      if (isShare) {
        return;
      }
      const newData = produce(data, (draftData) => {
        draftData.tags = tags;
      });
      setData(newData);

      if (tagInputWrapperRef.current) {
        window.setTimeout(() => {
          if (tagInputWrapperRef.current) {
            tagInputWrapperRef.current.scrollTop =
              tagInputWrapperRef.current.scrollHeight;
          }
        }, 1);
      }
    },
    [data, setData, isShare],
  );

  const [tagInput, setTagInput] = useState("");

  const handleTagInputChange = useCallback<(tag: string) => void>(
    (tag) => {
      if (isShare) {
        return;
      }
      const reg = /^[#?|@]?[^\s!@#$%^&*()=+/,[{\]};:'"?><]+$/;
      const newTag = tag.replace(" ", "_");
      if (
        newTag === "" ||
        newTag === "#" ||
        newTag === "@" ||
        ((newTag.startsWith("#") || newTag.startsWith("@")) && reg.test(newTag))
      ) {
        setTagInput(newTag);
      }
    },
    [isShare, setTagInput],
  );

  const handleReferencesChange = useCallback<(newTask: TaskStage) => void>(
    (newTask) => {
      const newData = produce(data, (draftData) => {
        draftData.references = newTask.references;
      });
      setData(newData);
    },
    [data, setData],
  );

  const handleOnChangeTextarea = useCallback<(value: string) => void>(
    (value) => {
      const newData = produce(data, (draftData) => {
        draftData.description = value;
      });

      setData(newData);
    },
    [data, setData],
  );

  const handleTaskStageContentDueDateChange = useCallback(
    (date: Moment) => {
      const newData = produce(data, (draftData) => {
        draftData.content_due_date =
          date?.utc(true).hours(12).minutes(0).seconds(0) ?? undefined;
      });
      setData(newData);
    },
    [data, setData],
  );

  const handleTaskStageDueDateChange = useCallback(
    (date: Moment) => {
      const newData = produce(data, (draftData) => {
        draftData.due_date =
          date?.utc(true).hours(12).minutes(0).seconds(0) ?? undefined;
      });
      setData(newData);
    },
    [data, setData],
  );

  const isCampaignDatesEntered =
    isShare || !!(campaign_dates.start && campaign_dates.end);

  const taskOptions = userDetails?.sidebar_data?.can_view_tiktok
    ? TaskTypes
    : TaskTypes.filter((x) => x.value !== "tk_video");

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <div style={{ display: "flex", gap: 24 }}>
            <div style={{ gap: 24, flexDirection: "column", display: "flex" }}>
              <div style={{ display: "flex", gap: 24 }}>
                <IndicatorSelect
                  header="Task type"
                  options={taskOptions}
                  defaultValue={() => get_post_type_label(data.post_type)}
                  clearable={false}
                  onChange={handleTaskTypeChange as any}
                  name="selected-state"
                  searchable={!isShare}
                  disabled={isShare || (editMode && !data.new)}
                  editMode={editMode}
                  width="200px"
                  icon={`${get_post_type_icon(data.post_type)} fa-lg colored`}
                />

                {data.post_type === "ig_post" && (
                  <div style={{ paddingTop: 4 }}>
                    <div style={{ marginLeft: 5, marginTop: -4 }}>
                      <IndicatorSelect
                        header="Sub Type"
                        options={SubTypes}
                        defaultValue={
                          (data.post_sub_type as any) || SubTypes[0]
                        }
                        clearable={false}
                        simpleValue
                        onChange={handleTaskSubTypeChange as any}
                        name="selected-state"
                        searchable={!isShare}
                        width="200px"
                        disabled={isShare || (editMode && !data.new)}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div ref={tagInputWrapperRef}>
                <IndicatorTags
                  header="Tags, Handles"
                  value={data.tags}
                  onChange={handleTagsChange}
                  inputValue={tagInput}
                  onChangeInput={handleTagInputChange}
                />
              </div>
            </div>
            <Indicator header="References">
              <div>
                <TaskReferencesPopup
                  task={data}
                  onClose={() => {
                    setIsReferenceVisible.off();
                    console.log(isReferenceVisible);
                  }}
                  onTaskChanged={handleReferencesChange as any}
                  isShare={isShare}
                />
              </div>
            </Indicator>
          </div>
          <div style={{ gap: 24, flexDirection: "column", display: "flex" }}>
            <IndicatorTextarea
              header="Task Description"
              value={data.description}
              placeholder="Task description"
              onChange={handleOnChangeTextarea}
              disabled={isShare}
            />

            <Indicator>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 180,
                    marginTop: 2,
                    position: "relative",
                  }}
                >
                  <span className="datepicker-subtitle">Approval Due Date</span>
                  <Tooltip
                    placement="top"
                    overlay="Fill campaign dates first"
                    destroyTooltipOnHide
                    trigger={!isCampaignDatesEntered ? ["hover"] : []}
                  >
                    <div
                      className="datepicker-box "
                      style={{ marginTop: 8, width: "210px" }}
                    >
                      <DatePicker
                        dateFormat={window.time.short_date}
                        className="borderless-datepicker"
                        isClearable={!isShare}
                        placeholderText="DD/MM/YY"
                        selected={
                          data.content_due_date as React.ComponentProps<
                            typeof DatePicker
                          >["selected"]
                        }
                        minDate={campaign_dates.start ?? moment()}
                        maxDate={
                          (data.due_date ??
                            campaign_dates.end) as React.ComponentProps<
                            typeof DatePicker
                          >["maxDate"]
                        }
                        onBlur={() => {
                          onDataTouched("content_due_date");
                        }}
                        onChange={handleTaskStageContentDueDateChange}
                        disabled={!isCampaignDatesEntered || isShare}
                        popperPlacement="top-start"
                      />
                      <i
                        className="fa-duotone fa-calendar-days fa-lg"
                        style={{ color: "grey" }}
                      ></i>
                    </div>
                  </Tooltip>
                  {!touched.content_due_date && errors.content_due_date ? (
                    <div>
                      <i className="error-mark" />
                      <span className="error-text">
                        {errors.content_due_date}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <i />
                      <span className="error-text">&nbsp;</span>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    marginLeft: 46,
                    width: 210,
                    marginTop: 2,
                    position: "relative",
                  }}
                >
                  <span className="datepicker-subtitle">Publish Due Date</span>
                  <Tooltip
                    placement="top"
                    overlay="Fill campaign dates first"
                    destroyTooltipOnHide
                    trigger={!isCampaignDatesEntered ? ["hover"] : []}
                  >
                    <div style={{ marginTop: 8 }} className="datepicker-box">
                      <DatePicker
                        dateFormat={window.time.short_date}
                        className="borderless-datepicker"
                        isClearable={!isShare}
                        placeholderText="DD/MM/YY"
                        selected={
                          data.due_date as React.ComponentProps<
                            typeof DatePicker
                          >["selected"]
                        }
                        minDate={
                          (data.content_due_date ??
                            campaign_dates.start) as React.ComponentProps<
                            typeof DatePicker
                          >["minDate"]
                        }
                        maxDate={
                          campaign_dates.end ?? campaign_dates.start ?? moment()
                        }
                        onBlur={() => {
                          onDataTouched("due_date");
                        }}
                        onChange={handleTaskStageDueDateChange}
                        disabled={!isCampaignDatesEntered || isShare}
                        popperPlacement="top-start"
                      />
                      <i
                        className="fa-duotone fa-calendar-days fa-lg"
                        style={{ color: "grey" }}
                      ></i>
                    </div>
                  </Tooltip>
                  {!touched.due_date && errors.due_date ? (
                    <div>
                      <i className="error-mark" />
                      <span className="error-text">{errors.due_date}</span>
                    </div>
                  ) : (
                    <div>
                      <i />
                      <span className="error-text">&nbsp;</span>
                    </div>
                  )}
                </div>
              </div>
            </Indicator>
          </div>
        </div>
      </div>
    </div>
  );
};
