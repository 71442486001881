/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Tooltip as ChakraTooltip,
  Flex,
  Tag,
  Text,
} from "@chakra-ui/react";
import produce from "immer";
import moment from "moment";
import Dialog from "rc-dialog";
import Tooltip from "rc-tooltip";
import React from "react";
import { connect } from "react-redux";
import { BundledTasks, LocalTask, MixedTasks } from "src/api/ExtendedTypes";
import {
  BundleAttributes,
  InfluencerInManageAuctionData,
  PostTypes,
  TaskManageData,
} from "src/api/types";
import { EditTasksContext } from "src/components/feed_components/dialogs/EditTasksDialog/EditTasksContext";
import ManageModalHeader from "src/components/feed_components/dialogs/EditTasksDialog/ManageModalHeader";
import { InfoToolTip } from "src/components/feed_components/general_components/InfoToolTip";
import NoDataMessage from "src/components/feed_components/general_components/NoDataMessage";
import { createMixedTasks } from "src/helpers/ManageDataHelper";
import {
  AllSocialIcons,
  getSocialMediaLinkByContentType,
  getSocialNetworkByPostType,
} from "src/helpers/SocialNetworksHelper";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import uuidv4 from "uuid/v4";
import {
  attachLinkMedia,
  changeStoriesCount,
  revertLinkedMedia,
  revertLinkedStory,
} from "../../../../../actions/auctionsActions";
import { MessageDialog } from "../../../dialogs/MessageDialog";
import { numberWithCurrency } from "../../../utilities/general";
import Indicator from "../../CreateCampaignSection/FormUI/Indicator";
import InputDatePicker from "../../CreateCampaignSection/FormUI/InputDatePicker";
import SingleTaskRow from "../../Tasks/SingleTaskRow";
import { AttachLinksDialogTabPosts } from "./AttachLinksDialogTabPosts";
import { AttachLinksDialogTabStories } from "./AttachLinksDialogTabStories";
import {
  StoryType,
  TaskStatuses,
} from "./AttachLinksDialogTabStoriesControlled";
import {
  AttachLinksProps,
  ManualPostInput,
  SaveData,
  tasksAttachLinksArray,
} from "./AttachLinksHelpers";

const TWITTER_ANALYZE_DAYS = 3;

class AttachLinksDialogInner extends React.Component<AttachLinksProps> {
  state: {
    loading: boolean;
    errors: {
      task_id: number;
      already_attached_to_influencer: number;
      bad_reason: string;
    }[];
    taskToRevert: TaskManageData | null;
    uploadDate: string;
    mixedTasks: any;
    selectedFilter: any;
  } = {
    loading: false,
    uploadDate: "",
    errors: [],
    taskToRevert: null,
    mixedTasks: { single: [], bundles: [] },
    selectedFilter: "All",
  };

  attachInput: Array<any> = [];

  manualPostInputs: ManualPostInput[] = [
    { name: "Comments", field: "comments" },
    { name: "Likes", field: "likes" },
    { name: "Retweets", field: "retweets", social: "twitter" },
    { name: "Impressions", field: "impressions" },
    { name: "Reach", field: "reach" },
    { name: "Views", field: "views" },
    { name: "Upload Date", field: "media_creation_time" },
  ];

  totalTasks = () => {
    if (
      this.state.mixedTasks?.single?.length ||
      this.state.mixedTasks?.bundles?.length
    ) {
      return (
        this.state.mixedTasks.single.length +
        this.state.mixedTasks?.bundles?.reduce(
          (acc: number, bundle: BundleAttributes) => acc + bundle.tasks.length,
          0,
        )
      );
    }
    return 0;
  };

  // eslint-disable-next-line class-methods-use-this
  addInvites(
    influencer: InfluencerInManageAuctionData,
    tasks: Array<TaskManageData>,
  ) {
    if (influencer?.tasks_invites && influencer?.tasks_invites?.length > 0) {
      const taskIds = tasks?.map((x) => x.id);
      // eslint-disable-next-line no-restricted-syntax
      for (const taskInvite of influencer.tasks_invites.filter(
        (x) => !taskIds.includes(x.id),
      )) {
        // eslint-disable-next-line no-continue
        if (taskInvite?.price_offered_state?.includes("rejected")) continue;
        tasks?.push(taskInvite as TaskManageData);
      }
    }
  }

  componentDidMount() {
    this.updateData();
  }

  // eslint-disable-next-line class-methods-use-this
  filterTasks(mixedTasks: MixedTasks, filters?: any) {
    // todo: More filters
    const filterFn = (t: any) => {
      let filtersToApply = t.post_type !== PostTypes.influencer_content;
      if (filters?.postType) {
        filtersToApply = filtersToApply && t.post_type === filters.postType;
      }
      return filtersToApply;
    };
    mixedTasks?.bundles?.forEach((bundle: any) => {
      bundle.filteredTasks = bundle.tasks.filter(filterFn);
    });
    mixedTasks.filteredSingle = mixedTasks.single.filter(filterFn);
    return mixedTasks;
  }

  updateData() {
    this.attachInput = [];
    const { influencer } = this.props.data;
    const tasks = [...(influencer?.tasks_details?.tasks ?? [])];
    if (tasks) {
      this.addInvites(influencer, tasks);
      if (tasks) {
        this.attachInput = tasksAttachLinksArray(tasks);
      }
    }
    this.applyPostTypeFilter();
  }

  // addPicture(accepted: any, id: any, extra: any) {
  //   const { type } = accepted[0];
  //   if (type.includes("video")) {
  //     let story: StoryInput | undefined;
  //     if (extra) {
  //       const storyId = uuidv4();
  //       const newData: StoryInput = {
  //         id: storyId as any,
  //         views: "",
  //         e: {},
  //         story_id: storyId,
  //         url: null,
  //         type: "ig_story",
  //         mime_type: type,
  //         subtask_of: id,
  //         new: true,
  //         progress: 0,
  //         uploading: true,
  //         media_creation_time: "",
  //       };
  //       this.attachInput.push(newData);
  //       console.log("newData", newData);
  //       story = this.attachInput.find(
  //         (s: any) => s.id === storyId,
  //       ) as StoryInput;
  //       this.setState({ updated: true });
  //     } else {
  //       story = this.attachInput.find((s: any) => s.id === id) as StoryInput;
  //       console.log("story", story);
  //       if (story) {
  //         story.progress = 0;
  //         story.uploading = true;
  //         story.mime_type = type;
  //       }
  //       this.setState({ updated: true });
  //     }
  //     addPicture(
  //       accepted,
  //       "task",
  //       (file_url: string) => {
  //         if (story) {
  //           story.uploading = false;
  //           if (file_url) {
  //             story.url = file_url;
  //           } else {
  //             story.failed = true;
  //           }
  //         }
  //         this.setState({});
  //       },
  //       (progress: number) => {
  //         if (story) {
  //           story.progress = progress;
  //           this.setState({});
  //         }
  //       },
  //       "video",
  //     );
  //   } else {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(accepted[0]);
  //     reader.onload = () => {
  //       if (extra) {
  //         const storyId = uuidv4();
  //         const newData: StoryInput = {
  //           id: storyId as any,
  //           views: "",
  //           e: {},
  //           story_id: storyId,
  //           url: null,
  //           base64: reader.result,
  //           type: "ig_story",
  //           mime_type: type,
  //           subtask_of: id,
  //           new: true,
  //           progress: 100,
  //           uploading: false,
  //           media_creation_time: "",
  //         };
  //         this.attachInput.push(newData);

  //         this.setState({ updated: true });
  //       } else {
  //         const story = this.attachInput.find(
  //           (s: any) => s.id === id,
  //         ) as StoryInput;
  //         if (story) {
  //           story.base64 = reader.result;
  //           story.mime_type = type;
  //         }
  //         this.setState({ updated: true });
  //       }
  //     };
  //     reader.onerror = function (error) {
  //       console.log("Error: ", error);
  //     };
  //   }
  // }

  onSaveLinks() {
    const { influencer, boardId } = this.props.data;
    const tasks = [...(influencer?.tasks_details?.tasks ?? [])];
    const { influencer_id: influencerId } = influencer;
    const tasksWithoutStoriesArray: SaveData[] = [];
    let storiesArray: any = [];

    for (let i = 0; i < this.attachInput.length; i += 1) {
      const data: SaveData = {};
      // Post metadata
      data.media_uid = this.attachInput[i].media_uid;
      data.task_id = this.attachInput[i].id;

      const originalPost = tasks.find((t: any) => t.id === data.task_id);
      data.post_type = originalPost?.post_type;
      if (
        !data.post_type ||
        data.post_type === PostTypes.ig_story ||
        data.post_type === PostTypes.influencer_content
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // Save boosted - backend detect save only when the uri is null, else it will try to create a new link
      if (this.attachInput[i].boosted_impressions)
        data.boosted_impressions = this.attachInput[i].boosted_impressions;
      if (data.post_uri && data.boosted_impressions !== null) {
        data.post_uri = null;
      }

      // Manual post data
      data.manual_post_stats = this.attachInput[i].manual_post_stats;
      if (data.manual_post_stats && !data.manual_post_stats.views) {
        data.manual_post_stats.views = null;
      }

      // Internal media id
      if (this.attachInput[i].internal_media_id) {
        data.internal_media_id = this.attachInput[i].internal_media_id;
        data.media_uid = this.attachInput[i].media_uid;
      }

      data.post_uri =
        (this.attachInput[i].value && this.attachInput[i].value.length) ||
        !data.media_uid
          ? this.attachInput[i].value
          : getSocialMediaLinkByContentType(
              data.post_type as PostTypes,
              data.media_uid,
            );
      if (!data?.media_uid) {
        data.media_uid = null;
      }
      // Save only if the media_uid has changed or if it's a new post
      if (
        data?.media_uid !== originalPost?.linked_media ||
        !!(!data?.media_uid && data.post_uri) ||
        !!this.attachInput[i].edited
      ) {
        tasksWithoutStoriesArray.push(data);
      }
    }

    const attachInputStories = this.attachInput.filter(
      (p) => p.post_type === PostTypes.ig_story,
    );
    for (let i = 0; i < attachInputStories.length; i += 1) {
      const story = attachInputStories[i];
      if (story.uploading) return;
      if (!story.views) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (!story.story_id) {
        story.story_id = uuidv4();
      }
      const storyData = {
        count: story.views,
        influencer_id: influencerId,
        task_id: story.subtask_of ? story.subtask_of : story.id,
        upload_image: story.base64
          ? {
              data: (story.base64 as string).split(",")[1],
              mime_type: story.mime_type,
            }
          : null,
        image_url: story.url,
        id: story.story_id,
        engagements: story.story_engagements
          ? // eslint-disable-next-line radix
            parseInt(story.story_engagements as string)
          : null,
        is_subtask: !!story.subtask_of,
        reach: story.engagemnts?.story_reach,
        taps_back: story.engagemnts?.story_taps_back,
        taps_forward: story.engagemnts?.story_taps_forward,
        swipes_forward: story.engagemnts?.story_swipes_forward,
        media_creation_time: story.engagemnts?.story_media_creation_time,
      };

      if (
        !storiesArray.find(
          // eslint-disable-next-line eqeqeq
          (x: any) => x.task_id == storyData.task_id && !storyData.is_subtask,
        )
      ) {
        storiesArray.push(storyData);
      }
    }

    if (storiesArray.length > 0) {
      this.setState({ loading: true });
      storiesArray = storiesArray.filter(
        (x: any) => x.count !== null && x.count !== undefined && x.count !== "",
      );
      storiesArray = storiesArray.filter(
        (item: any, pos: number, self: any[]) => {
          return (
            self.findIndex(
              (x) =>
                (item.task_id === x.task_id && !item.subtask_of) ||
                (item.image_url && item.image_url === x.image_url) ||
                item.id === x.id,
            ) === pos
          );
        },
      );
      this.props.changeStoriesCount(
        influencerId,
        boardId,
        storiesArray,
        this.storiesSaveCallback,
      );
    }

    if (tasksWithoutStoriesArray.length > 0) {
      this.setState({ loading: true }, () => {
        this.props.onDataChange(this.props.data);
        this.props.attachLinkMedia(
          boardId,
          influencerId,
          tasksWithoutStoriesArray,
          this.saveCallback,
        );
      });
    } else {
      this.setState({});
    }
  }

  storiesSaveCallback = (tasks: Array<TaskManageData>) => {
    const influencer = this.props.board.states.approval.influencers.find(
      (x: any) => x.influencer_id === this.props.data.influencer.influencer_id,
    );
    if (tasks && influencer) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.props.data.influencer.tasks_details.tasks = tasks.sort(
          (a, b) => a.id - b.id,
        );
        this.updateData();
      } catch (e) {
        this.close();
      }
    } else {
      this.close();
    }
  };

  saveCallback = (errors: any) => {
    if (errors) {
      this.setState({ loading: false, errors: [errors] });
    } else {
      this.setState({ loading: false });
      this.close();
    }
  };

  close = () => {
    this.props.handleClose(this.props.data.influencer.influencer_id);
  };

  revertLinkedMedia = (task: TaskManageData) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.props.revertLinkedMedia(
      this.props.data.boardId,
      this.props.data.influencer.influencer_id,
      task.id,
      (success: boolean) => {
        if (success) {
          const t = this.props.data.influencer?.tasks_details?.tasks?.find(
            (x) => x.id === task.id,
          );
          if (t) {
            t.completed = false;
            t.completed_at = undefined;
            t.linked_media = null;
            t.linked_media_id = null;
          }
          this.updateData();
        }
        this.setState({ loading: false, taskToRevert: null });
      },
    );
  };

  revertLinkedStory = async (task: TaskManageData) => {
    this.setState({ loading: true });
    return new Promise((res, rej) => {
      this.props.revertLinkedStory(
        this.props.data.boardId,
        this.props.data.influencer.influencer_id,
        task.id,
        (success: boolean) => {
          if (success) {
            if (this.props.data.influencer?.tasks_details?.tasks) {
              const index =
                this.props.data.influencer?.tasks_details?.tasks?.findIndex(
                  (x) => x.id === task.id,
                );
              if (index > -1) {
                if (
                  this.props.data.influencer?.tasks_details?.tasks[index]
                    .subtask_of
                ) {
                  this.props.data.influencer?.tasks_details?.tasks.splice(
                    index,
                    1,
                  );
                } else if (
                  !task.subtask_of &&
                  this.props.data.influencer.tasks_details?.tasks.some(
                    (t) => t.subtask_of === task.id,
                  )
                ) {
                  const subTaskIndex =
                    this.props.data.influencer.tasks_details?.tasks.findIndex(
                      (t) => t.subtask_of === task.id,
                    );
                  const subTask = {
                    ...this.props.data.influencer.tasks_details?.tasks[
                      subTaskIndex
                    ],
                  };
                  subTask.id = task.id;
                  subTask.subtask_of = null;
                  this.props.data.influencer.tasks_details.tasks.splice(
                    subTaskIndex,
                    1,
                  );
                  this.props.data.influencer.tasks_details.tasks[index] =
                    subTask;
                } else {
                  this.props.data.influencer.tasks_details.tasks[
                    index
                  ].completed = false;
                  this.props.data.influencer.tasks_details.tasks[
                    index
                  ].completed_at = undefined;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  this.props.data.influencer.tasks_details.tasks[
                    index
                  ].engagemnts = null;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  this.props.data.influencer.tasks_details.tasks[
                    index
                  ].linked_media = null;
                  this.props.data.influencer.tasks_details.tasks[
                    index
                  ].linked_media_id = null;
                  this.props.data.influencer.tasks_details.tasks[
                    index
                  ].linked_media_video_url = null;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  this.props.data.influencer.tasks_details.tasks[index].url =
                    null;
                }
              }
            }
            this.updateData();
          }
          this.setState({ loading: false, taskToRevert: undefined }, () => {
            if (success) {
              res();
            } else {
              rej();
            }
          });
        },
      );
    });
  };

  revertLinkedTask = () => {
    if (!this.state.taskToRevert || this.state.loading) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.state.taskToRevert.post_type === "ig_story") {
      this.revertLinkedStory(this.state.taskToRevert);
    } else {
      this.revertLinkedMedia(this.state.taskToRevert);
    }
  };

  renderInnerTaskAccordion = (task: LocalTask) => {
    return task.post_type === PostTypes.ig_story
      ? this.renderStoriesSection(task)
      : this.renderLinksSection(task);
  };

  applyPostTypeFilter = () => {
    const { influencer } = this.props.data;
    const tasks = [...(influencer?.tasks_details?.tasks ?? [])];
    this.setState({
      loading: false,
      mixedTasks: this.filterTasks(
        createMixedTasks(influencer, tasks),
        this.state.selectedFilter,
      ),
    });
  };

  tasksFilter = [
    {
      text: "All",
      action: () =>
        this.setState(
          { selectedFilter: { text: "All post types" } },
          this.applyPostTypeFilter,
        ),
    },
    {
      text: "Instagram posts",
      icon: AllSocialIcons[PostTypes.ig_post],
      action: () =>
        this.setState(
          { selectedFilter: { postType: "ig_post", text: "Instagram posts" } },
          this.applyPostTypeFilter,
        ),
    },
    {
      text: "Instagram stories",
      icon: AllSocialIcons[PostTypes.ig_story],
      action: () =>
        this.setState(
          {
            selectedFilter: { postType: "ig_story", text: "Instagram stories" },
          },
          this.applyPostTypeFilter,
        ),
    },
    {
      text: "Twitter tweets",
      icon: AllSocialIcons[PostTypes.tw_tweet],
      action: () =>
        this.setState(
          { selectedFilter: { postType: "tw_tweet", text: "Twitter tweets" } },
          this.applyPostTypeFilter,
        ),
    },
    {
      text: "YouTube videos",
      icon: AllSocialIcons[PostTypes.yt_video],
      action: () =>
        this.setState(
          { selectedFilter: { postType: "yt_video", text: "YouTube videos" } },
          this.applyPostTypeFilter,
        ),
    },
    {
      text: "TikTok videos",
      icon: AllSocialIcons[PostTypes.yt_video],
      action: () =>
        this.setState(
          { selectedFilter: { postType: "tk_video", text: "TikTok videos" } },
          this.applyPostTypeFilter,
        ),
    },
  ];

  render() {
    const { influencer } = this.props.data;
    if (!influencer?.tasks_details?.tasks) return null;
    const tasks = this.state.mixedTasks;

    const totalTasks = this.totalTasks();
    this.addInvites(influencer, influencer?.tasks_details?.tasks);
    return (
      <>
        <EditTasksContext.Provider
          value={{
            influencer,
            mixedTasks: null,
            setMixedTasks: null,
          }}
        >
          <Dialog
            onClose={() => this.props.handleClose()}
            visible={true}
            style={{ marginTop: 20 }}
            className="edit-tasks-dialog"
            animation="slide-fade"
            maskAnimation="fade"
          >
            <div>
              <ManageModalHeader
                image={influencer?.picture_url}
                name={influencer?.name}
                month={this.props.board.month}
                label={"Attach Links"}
              />

              {/* <Box paddingX={6} paddingTop={6}>
              <HumanzDismissableAlert
                status="info"
                dismissable={true}
                open={true}
                icon={<i className="fa fa-link" />}
                title="Attach Links"
                description="You have to define which social network you choose, and then set
              the post link. in the story definition, you must specify multiple
              views and upload an image"
              />
            </Box> */}

              <Box overflow={"hidden"}>
                <Box className="animated fast-animation fadeIn">
                  <div
                    className="edit-tasks-container"
                    style={{ padding: "0 20px" }}
                  >
                    <Flex
                      p={4}
                      justifyContent={"space-between"}
                      alignContent="center"
                      alignItems={"center"}
                    >
                      <Text fontWeight={500} fontSize={14} textAlign={"left"}>
                        Influencer tasks {totalTasks ? `(${totalTasks})` : ""}
                      </Text>
                      <Box>
                        <HumanzActionsMenu
                          buttontype="button"
                          leftIcon={
                            <Text
                              className="fas fa-filter"
                              style={{
                                color:
                                  this.state.selectedFilter === "All"
                                    ? "#808080"
                                    : "var(--brand-500)",
                              }}
                            />
                          }
                          variant="small-action-white"
                          borderRadius={20}
                          actions={this.tasksFilter}
                          buttontext={
                            <span
                              style={{
                                color:
                                  this.state.selectedFilter === "All"
                                    ? "#808080"
                                    : "var(--brand-500)",
                              }}
                            >
                              {this.state?.selectedFilter?.text}
                            </span>
                          }
                        />
                      </Box>
                    </Flex>
                    {tasks?.bundles?.map((bundle: BundledTasks, bi: number) =>
                      bundle?.filteredTasks.length ? (
                        <Accordion
                          key={bundle?.bundle_id}
                          allowToggle
                          defaultIndex={[0]}
                        >
                          <AccordionItem className={"tasks-bundle"}>
                            {({ isExpanded }) => (
                              <>
                                <AccordionButton
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "2px 6px",
                                    alignItems: "center",
                                    borderRadius: "12px",
                                    width: "100%",
                                    gap: 2,
                                  }}
                                >
                                  <Flex
                                    gap={2}
                                    justifyContent={"center"}
                                    alignItems="center"
                                  >
                                    <Tag variant={"outline"} colorScheme="gray">
                                      Bundle{" "}
                                      {bundle?.index !== undefined
                                        ? bundle.index + 1
                                        : null}
                                    </Tag>
                                    <Tag>
                                      {numberWithCurrency(
                                        Number(bundle.price) || 0,
                                        this.props.board.board?.summary
                                          ?.currency,
                                      )}
                                    </Tag>
                                    <Text color={"#808080"} fontSize={14}>
                                      <InfoToolTip
                                        iconStyle={{ marginRight: "5px" }}
                                        overlay={
                                          "Bundled tasks should be completed together - and will be paid together."
                                        }
                                      />
                                      Tasks bundle ({bundle.tasks?.length})
                                    </Text>
                                  </Flex>
                                  <Flex
                                    style={{
                                      marginRight: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    {bundle.complete_at_end_of_month && (
                                      <Tooltip
                                        overlay={
                                          "Influencer will receive the payment at the end of the month, regardless of tasks completion"
                                        }
                                      >
                                        <Tag colorScheme={"purple"}>
                                          <i
                                            className="fa-duotone fa-money-check-dollar"
                                            style={{ marginRight: 2 }}
                                          />
                                          Guaranteed payment
                                        </Tag>
                                      </Tooltip>
                                    )}
                                    {bundle.renews && (
                                      <Tag colorScheme={"blue"} mr={2}>
                                        <i
                                          className="far fa-arrows-spin"
                                          style={{ marginRight: 5 }}
                                        />
                                        Recurring
                                      </Tag>
                                    )}
                                    <i
                                      style={{ fontSize: 16 }}
                                      className={`fa-regular ${
                                        isExpanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } fa-lg`}
                                    />
                                  </Flex>
                                </AccordionButton>
                                <AccordionPanel p={0} pt={2}>
                                  {bundle?.filteredTasks?.map(
                                    (task: LocalTask, i: number) => (
                                      <SingleTaskRow
                                        bundleIndex={bi}
                                        key={i}
                                        task={task}
                                        index={i}
                                        attachMode={this.renderInnerTaskAccordion(
                                          task,
                                        )}
                                        editable={false}
                                      />
                                    ),
                                  )}
                                </AccordionPanel>
                              </>
                            )}
                          </AccordionItem>
                        </Accordion>
                      ) : null,
                    )}
                  </div>
                  <div
                    className="edit-tasks-container"
                    style={{ padding: "0 20px" }}
                  >
                    {tasks?.filteredSingle
                      ?.filter((t: LocalTask) => !t.subtask_of)
                      ?.map((task: LocalTask, index: number) => (
                        <SingleTaskRow
                          key={index}
                          task={task}
                          index={index}
                          isOffer={index === -1}
                          attachMode={this.renderInnerTaskAccordion(task)}
                          editable={false}
                        />
                      ))}
                  </div>
                  {!tasks?.filteredSingle?.length &&
                    !tasks?.bundles?.reduce(
                      (acc: number, bundle: BundleAttributes) =>
                        acc + bundle.filteredTasks.length,
                      0,
                    ) && (
                      <NoDataMessage
                        style={{ filter: "grayscale(1)", margin: 20 }}
                      >
                        <img src={"/images/no_tasks.png"} />
                        <Text>No tasks were found</Text>
                      </NoDataMessage>
                    )}
                </Box>
              </Box>

              {
                <Box
                  style={{
                    paddingBottom: 15,
                    paddingTop: 15,
                    marginTop: 5,
                    textAlign: "center",
                    position: "sticky",
                    bottom: 0,
                    background: "white",
                  }}
                >
                  {influencer?.tasks_details?.tasks?.map((t: any) =>
                    this.renderError(t),
                  )}

                  <button
                    style={{ marginLeft: 10 }}
                    className={`attach-links-btn share-save-btn btn btn-primary ${
                      this.state.loading && "disabled"
                    }`}
                    onClick={() => this.onSaveLinks()}
                  >
                    <span>Save</span>
                  </button>
                  <button
                    style={{ marginLeft: 10 }}
                    className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
                      this.state.loading && "disabled"
                    }`}
                    onClick={this.close}
                  >
                    <span>Close</span>
                  </button>
                </Box>
              }
            </div>
          </Dialog>
          {!!this.state.taskToRevert && (
            <MessageDialog
              handleClose={() => this.setState({ taskToRevert: null })}
              icon="moi-trash"
            >
              <div style={{ textAlign: "center", padding: 20 }}>
                <strong>Notice</strong>
                <p style={{ marginTop: 15 }}>
                  Are you sure you want to <strong>revert</strong> the content
                  attached to the task?
                </p>
                <button
                  onClick={() => this.setState({ taskToRevert: null })}
                  className={`btn btn-primary cancel ${
                    this.state.loading ? "disable" : ""
                  }`}
                  style={{
                    borderRadius: "40px",
                    width: 100,
                    marginTop: 15,
                    marginRight: 30,
                  }}
                >
                  NO
                </button>
                <button
                  onClick={this.revertLinkedTask}
                  className={`btn btn-primary ${
                    this.state.loading ? "disable" : ""
                  }`}
                  style={{
                    background: "var(--main-baby-blue-color)",
                    color: "white",
                    borderRadius: "40px",
                    width: 100,
                    marginTop: 15,
                  }}
                >
                  Yes
                </button>
              </div>
            </MessageDialog>
          )}
        </EditTasksContext.Provider>
      </>
    );
  }

  renderManualPostInput = (
    mpi: ManualPostInput,
    t: TaskManageData,
    input: any,
    i: number,
    date?: boolean,
  ) => {
    const isIgPostOrTweet =
      t.post_type === "tw_tweet" ||
      (t.post_type === "ig_post" &&
        (t.post_sub_type === "post" || !t.post_sub_type));

    const hasManualStats =
      input?.manual_post_stats &&
      ((mpi && !!input?.manual_post_stats[mpi?.field]) ||
        (input.manual_post_stats?.network_specific &&
          mpi?.social &&
          input?.manual_post_stats?.network_specific[mpi.social][mpi.field]));

    // Don't Show impressions if it's something else than ig post or tweet
    if (
      mpi?.field === "impressions" &&
      ((!hasManualStats && !isIgPostOrTweet && !input?.forceImpressions) ||
        input?.forceViews ||
        input?.manual_post_stats?.views)
    )
      return null;

    // Don't Show views if it's an ig post or tweet
    if (
      mpi?.field === "views" &&
      ((!hasManualStats && isIgPostOrTweet && !input?.forceViews) ||
        input?.forceImpressions ||
        (!isIgPostOrTweet && input?.manual_post_stats?.impressions))
    )
      return null;
    return input?.media_uid ? (
      <div key={i} className="flex input-box">
        <label
          className={"attach-links-label"}
          htmlFor={`${mpi.field}_${input?.id}`}
        >
          {`${mpi.name} `}
          {mpi.tooltip && (
            <Tooltip
              placement="top"
              trigger={["hover", "click"]}
              overlay={<span>{mpi.tooltip}</span>}
            >
              <i className="fa-solid fa-info-circle" />
            </Tooltip>
          )}
        </label>
        {!date ? (
          <input
            disabled={mpi.disabled}
            defaultValue={
              input &&
              input?.manual_post_stats &&
              mpi.social &&
              input.manual_post_stats.network_specific &&
              input.manual_post_stats.network_specific[mpi.social]
                ? input.manual_post_stats.network_specific[mpi.social][
                    mpi.field
                  ] || 0
                : input?.manual_post_stats &&
                  input?.manual_post_stats[mpi.field]
                ? input?.manual_post_stats[mpi.field] || 0
                : 0
            }
            id={`${mpi.field}_${input?.id}`}
            type={"number"}
            className={`${!mpi.disabled ? "" : "disabled"}`}
            placeholder={mpi.name}
            onChange={(e) => {
              const { target } = e;
              target.value = parseInt(target.value, 10).toString();
              if (input) {
                if (!input.manual_post_stats) input.manual_post_stats = {};
                if (mpi.social) {
                  if (!input.manual_post_stats.network_specific)
                    input.manual_post_stats.network_specific = {};
                  if (!input.manual_post_stats.network_specific[mpi.social])
                    input.manual_post_stats.network_specific[mpi.social] = {};
                  input.manual_post_stats.network_specific[mpi.social][
                    mpi.field
                  ] = parseInt(e.target.value, 10);
                } else {
                  input.manual_post_stats[mpi.field] = parseInt(
                    e.target.value,
                    10,
                  );
                }
                input.edited = true;
              }
              this.setState({});
            }}
          />
        ) : (
          <div className={`input-date ${!mpi.disabled ? "" : "disabled"}`}>
            <InputDatePicker
              style={{
                textAlign: "start",
                paddingLeft: 0,
                marginLeft: 0,
                fontSize: 14,
                alignContent: "center",
              }}
              onChange={(d) => {
                input.manual_post_stats.comments =
                  input.manual_post_stats.comments || 0;
                const dateString = d.target.value;
                input.manual_post_stats.media_creation_time =
                  moment(dateString).toISOString();
                input.edited = true;
                this.setState({
                  uploadDate: moment(dateString).toISOString(),
                });
              }}
              value={
                input.manual_post_stats.media_creation_time
                  ? moment(input.manual_post_stats.media_creation_time).format(
                      window.time.short_date,
                    )
                  : null
              }
            />
          </div>
        )}
      </div>
    ) : null;
  };

  renderTwitterWarning = (task: TaskManageData) => {
    const over = moment()
      .add(TWITTER_ANALYZE_DAYS, "days")
      .isAfter(task.completed_at);
    if (over) {
      return null;
    } else
      return (
        <Alert status="info" style={{ marginTop: 10 }}>
          <AlertIcon />
          <AlertDescription>
            Twitter tweets are analyzed once, {TWITTER_ANALYZE_DAYS} days after
            they are completed.
          </AlertDescription>
        </Alert>
      );
  };

  renderLinksSection(task: LocalTask) {
    const t = task;
    const idx = this.attachInput.findIndex((x) => x.id === t.id);
    const { influencer } = this.props.data;
    const socialNetwork = getSocialNetworkByPostType(t.post_type);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const input = this.attachInput[idx];
    const isApi =
      influencer?.social_network_data[socialNetwork]?.verification_type ===
      "api";
    const isManualPostInput = !isApi;
    const postUrl = t.completed
      ? getSocialMediaLinkByContentType(t.post_type, t.linked_media)
      : "";
    let canDelete = false;
    let timeToDelete = "";
    const showBoosted =
      isApi && ["ig_post", "tw_tweet"].includes(t.post_type ?? "");
    if (t.completed && t.completed_at) {
      const now = new Date();
      const end = new Date(t.completed_at);
      end.setDate(end.getDate() + 3);
      canDelete = now < end;
      if (canDelete) {
        timeToDelete = (Math.abs(end.getTime() - now.getTime()) / 36e5).toFixed(
          0,
        );
      }
      if (input?.boosted_impressions) {
        canDelete = false;
      }
    }
    return (
      <>
        <Flex
          alignItems="center"
          flex="1"
          justifyContent="flex-start"
          overflow="hidden"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            overflow="hidden"
            marginBottom={2}
          >
            {t.description ? (
              <Indicator header={"Task Description"}>
                <Text className="ellipsis" textAlign="start">
                  <ChakraTooltip label={t.description} placement="bottom-start">
                    {t.description}
                  </ChakraTooltip>
                </Text>
              </Indicator>
            ) : (
              <div />
            )}
          </Flex>
        </Flex>

        {t.completed ? (
          <Indicator header={"Post link"} style={{ margin: "5px 0" }}>
            <div className="flex">
              <div
                className="flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <a
                  className={"attach-links-link input-box"}
                  style={{ padding: "0 7px" }}
                  href={postUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {postUrl} <i className="fa-solid fa-external-link" />
                </a>
              </div>

              <Tooltip
                overlay={
                  canDelete
                    ? `You have ${timeToDelete} hours to revert`
                    : "You can no longer revert as it has been 72 hours."
                }
              >
                <div
                  onClick={() =>
                    this.setState({
                      taskToRevert: canDelete ? t : null,
                    })
                  }
                  className={
                    canDelete && !this.state.loading
                      ? "revert-link"
                      : "revert-link revert-disabled"
                  }
                  style={{ textAlign: "center", width: 120 }}
                >
                  <i
                    className={`far ${canDelete ? "fa-history" : "fa-lock"}`}
                    style={{
                      color: canDelete ? "#ffffff" : "#f15b23",
                      fontSize: 19,
                    }}
                  />
                  <span> {canDelete ? `Revert` : "Done"}</span>
                </div>
              </Tooltip>
            </div>
          </Indicator>
        ) : (
          <Indicator header={"Post link"}>
            <Flex>
              <div className={"input-box attach-links-link"}>
                <input
                  id={`post_link_${input?.id}`}
                  style={{
                    paddingRight: t.post_type === "ig_post" ? 125 : 0,
                    minWidth: 400,
                    paddingLeft: 4,
                  }}
                  className=""
                  placeholder="Enter post link"
                  value={input?.value || null}
                  onChange={(e) => {
                    input.value = e.target.value;
                    this.setState({});
                  }}
                />
              </div>
              {t.post_type === "ig_post" &&
                this.renderPostAttachment(t, input, [])}
            </Flex>
            {t.post_type === "tw_tweet" && this.renderTwitterWarning(t)}
          </Indicator>
        )}

        {isManualPostInput && (
          <div className="manual-post-stats">
            {this.manualPostInputs.map((mpi: ManualPostInput, i: number) => {
              return socialNetwork === mpi.social || !mpi.social
                ? this.renderManualPostInput(
                    mpi,
                    t,
                    input,
                    i,
                    mpi.field === "media_creation_time",
                  )
                : null;
            })}
            {t.completed ? (
              <>
                {t.post_type === "ig_post" &&
                !input?.forceViews &&
                !input?.manual_post_stats?.views &&
                (t.post_sub_type === "post" || !t.post_sub_type) ? (
                  <Button
                    variant={"link"}
                    onClick={() => {
                      input.forceViews = !input.forceViews;

                      this.setState({});
                    }}
                  >
                    <Text mr={1}>Switch to views</Text>
                    <i className="fa-solid fa-shuffle" />
                  </Button>
                ) : null}
                {t.post_type === "ig_post" &&
                !input?.forceImpressions &&
                !input?.manual_post_stats?.impressions &&
                !input?.manual_post_stats?.views &&
                t.post_sub_type === "reel" ? (
                  <Button
                    variant={"link"}
                    onClick={() => {
                      input.forceImpressions = !input.forceImpressions;
                      this.setState({});
                    }}
                  >
                    Switch to impressions
                  </Button>
                ) : null}
              </>
            ) : null}
            {t?.engagemnts?.manual_post_stats_last_update_time ? (
              <div className="last-update-stats">
                Last updated:{" "}
                {moment(
                  t?.engagemnts?.manual_post_stats_last_update_time,
                ).format(window.time.short_date)}
              </div>
            ) : null}
          </div>
        )}

        {showBoosted && postUrl && (
          <Indicator header={"Boosted Impressions"}>
            <Flex>
              <div className={"input-box attach-links-link"}>
                <input
                  disabled={!postUrl}
                  defaultValue={
                    input && input.boosted_impressions
                      ? input.boosted_impressions
                      : 0
                  }
                  id={`post_boosted_${input?.id}`}
                  type={"number"}
                  className={`${postUrl ? "" : "disabled"}`}
                  placeholder="Boosted impressions"
                  onChange={(e) => {
                    const { target } = e;
                    target.value = parseInt(target.value, 10).toString();
                    if (input) {
                      input.boosted_impressions = parseInt(e.target.value, 10);
                    }
                    input.edited = true;
                    this.setState({});
                  }}
                />
              </div>
            </Flex>
          </Indicator>
        )}
      </>
    );
  }

  renderError = (task: TaskManageData) => {
    const error = this.state?.errors?.find((x) => x.task_id === task.id);
    if (!error) return null;
    let infName = "";
    if (error.already_attached_to_influencer) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const inf = this.props.board?.states?.approval?.influencers?.find(
        (x: any) => x.influencer_id === error.already_attached_to_influencer,
      );
      if (inf) {
        if (inf.name) infName = inf.name;
      }
    }
    return (
      <Box m={5}>
        <Alert status={"error"}>
          <AlertDescription>
            {" "}
            {window.translate(
              "Something is wrong with the link.",
              error.bad_reason,
            )}
            {infName ? (
              <span>
                {" "}
                with influencer: <strong>{infName}</strong>
              </span>
            ) : null}
          </AlertDescription>
        </Alert>
      </Box>
    );
  };

  onStoriesDataChange = async (
    newData: any,
    data: any,
    tasks: any,
    influencerId: number,
    boardId: number,
  ) => {
    const allStories = newData.tasks
      .map((t: any) =>
        t.stories.map((s: any, i: number) => ({
          story: s,
          taskId: t.id,
          firstInTask: i === 0,
        })),
      )
      .flat();
    const tasksToRevert =
      tasks.filter(
        (t: any) =>
          (t.linked_media_id || t.linked_media) &&
          !allStories.some(
            (s: any) =>
              (s.story.type === StoryType.linked &&
                t.fb_id === s.story.linkedStoryId) ||
              (s.story.type === StoryType.manual &&
                t.linked_media_id === s.story.id.split("_")[1]),
          ),
      ) || [];
    const storiesArray = allStories.map((s: any) => {
      const isNew = !tasks.some(
        (t: any) =>
          t.fb_id === s.story.linkedStoryId ||
          (s.story.type === StoryType.manual &&
            t.linked_media_id === s.story.id.split("_")[1]),
      );

      // s.story.type === StoryType.linked ||
      // .stories.filter((s2: any) => s2.type === StoryType.manual)
      // .map((s2: any) =>
      //   s2.type === StoryType.manual ? s2.id : "unreachable",
      // )
      // .indexOf(s.story.id) === -1;

      const isSubtask = !s.firstInTask || (isNew && !s.firstInTask);
      let taskId;
      let storyId;
      if (s.firstInTask && s.story.type === StoryType.linked) {
        taskId = Number(s.taskId);
        storyId =
          tasks.find((t: any) => t.fb_id === s.story.linkedStoryId)
            ?.linked_media_id ?? uuidv4();
      } else if (isNew || s.story.type === StoryType.linked) {
        taskId = Number(s.taskId);
        storyId =
          tasks.find((t: any) => t.fb_id === s.story.linkedStoryId)
            ?.linked_media_id ?? uuidv4();
      } else {
        const idSplitted = s.story.id?.split("_");
        taskId =
          s.story.type === StoryType.manual
            ? Number(s.taskId)
            : Number(idSplitted[0]);
        // eslint-disable-next-line prefer-destructuring
        storyId = idSplitted[1];
      }
      const subtaskId =
        s.story.type === StoryType.linked
          ? tasks.find((t: any) => t.fb_id === s.story.linkedStoryId)?.id
          : s.story.type === StoryType.manual
          ? Number(s.story.id?.split("_")[0])
          : taskId;

      return {
        id: storyId,
        sub_task_of: isSubtask ? taskId : null,
        influencer_id: influencerId,
        is_subtask: isSubtask,
        task_id: !isSubtask ? taskId : subtaskId ?? null,
        ...(s.story.type === StoryType.linked
          ? {
              fb_id: s.story.linkedStoryId,
            }
          : undefined),
        ...(s.story.type === StoryType.manual
          ? {
              count: s.story.manualData.views,
              engagements: s.story.manualData.engagements,
              shares: s.story.manualData.shares,
              reach: s.story.manualData.reach,
              taps_back: s.story.manualData.taps_back,
              taps_forward: s.story.manualData.taps_forward,
              swipes_forward: s.story.manualData.swipes_forward,
              image_url: s.story.manualData.mediaUrl,
              media_creation_time: s.story.manualData.media_creation_time,
            }
          : undefined),
      };
    });
    const subTasksTorevert = tasksToRevert.filter((t) => t.subtask_of);
    const mainTasksToRevert = tasksToRevert.filter((t) => !t.subtask_of);

    await Promise.all(
      subTasksTorevert.map((t: any) => this.revertLinkedStory(t)),
    );
    await Promise.all(
      mainTasksToRevert.map((t: any) => this.revertLinkedStory(t)),
    );

    if (storiesArray.length) {
      this.props.changeStoriesCount(
        influencerId,
        boardId,
        storiesArray,
        this.storiesSaveCallback,
      );
    }
  };

  renderStoriesSection(task: TaskManageData) {
    const { influencer, boardId } = this.props.data;
    const tasks =
      influencer?.tasks_details?.tasks?.filter(
        (t) =>
          t.post_type === "ig_story" &&
          (task.id === t.id || t.subtask_of === task.id),
      ) ?? []; // Filter original tasks data to get only stories and their subtasks
    const { influencer_id: influencerId } = influencer;
    const data: any = {
      tasks: tasks
        .filter((t) => !t.subtask_of) // get stories that are not subtasks
        .map((t) => ({
          id: t.id!.toString(),
          description: t.description,
          due_date: t.due_date,
          status: t.completed
            ? TaskStatuses.completed
            : TaskStatuses.inProgress,
          stories: (t.linked_media ? [t] : ([] as TaskManageData[])) // main task
            .concat(tasks.filter((st) => st.subtask_of === t.id)) // subtasks
            .map((taskForStory, i) => {
              const end = new Date(t.completed_at);
              end.setDate(end.getDate() + 3);
              const notDeletable = new Date() > end && i === 0;
              if (taskForStory.fb_id) {
                return {
                  notDeletable,
                  type: StoryType.linked,
                  linkedStoryId: taskForStory.fb_id,
                };
              }

              return {
                id: `${taskForStory.id}_${taskForStory.linked_media_id}`,
                notDeletable,
                type: StoryType.manual,
                manualData: {
                  mediaUrl: taskForStory.linked_media!,
                  mediaType:
                    taskForStory.mime_type === "video" ? "video" : "image",
                  views: taskForStory.engagemnts?.views,
                  engagements: taskForStory.engagemnts?.story_engagements,
                  saved: taskForStory.engagemnts?.story_engagements,
                  shares: taskForStory.engagemnts?.story_engagements,
                  reach: taskForStory.engagemnts?.story_reach,
                  taps_back: taskForStory.engagemnts?.story_taps_back,
                  taps_forward: taskForStory.engagemnts?.story_taps_forward,
                  swipes_forward: taskForStory.engagemnts?.story_swipes_forward,
                  media_creation_time:
                    taskForStory.engagemnts?.story_media_creation_time,
                },
              };
            }),
        })),
    };

    return (
      <AttachLinksDialogTabStories
        fbApi={
          influencer.social_network_data?.instagram?.verification_type === "api"
            ? {
                influencerId,
                onMissingPermission: () => {
                  this.props.onDataChange(
                    produce(this.props.data, (draftData: any) => {
                      draftData.influencer.social_network_data!.instagram!.verification_type =
                        "api_incomplete";
                    }),
                  );
                },
              }
            : undefined
        }
        selectedStories={tasks
          .filter((x) => x.linked_media && x.fb_id)
          .map((x) => ({
            id: x.fb_id!,
            data: {
              mediaType: x.linked_media_video_url ? "video" : "image",
              mediaUrl: x.linked_media_video_url ?? x.linked_media!,
              saved: 0,
              shares: 0,
              reach: x.engagemnts?.story_reach,
              taps_back: x.engagemnts?.story_taps_back,
              taps_forward: x.engagemnts?.story_taps_forward,
              swipes_forward: x.engagemnts?.story_swipes_forward,
              media_creation_time: x.engagemnts?.story_media_creation_time,
            },
          }))}
        data={data}
        onDataChange={async (newData: any) => {
          return this.onStoriesDataChange(
            newData,
            data,
            tasks,
            influencerId,
            boardId,
          );
        }}
      />
    );
  }

  renderPostAttachment(
    task: TaskManageData,
    input: any,
    tasks: Array<TaskManageData>,
  ) {
    const { influencer } = this.props.data;
    const { influencer_id: influencerId } = influencer;
    return (
      <>
        <AttachLinksDialogTabPosts
          influencer={influencer}
          fbApi={
            influencer.social_network_data?.instagram?.verification_type ===
            "api"
              ? {
                  influencerId,
                  onMissingPermission: () => {
                    this.props.onDataChange(
                      produce(this.props.data, (draftData: any) => {
                        draftData.influencer.social_network_data!.instagram!.verification_type =
                          "api_incomplete";
                      }),
                    );
                  },
                }
              : undefined
          }
          data={task}
          tasks={tasks}
          selectPost={(post_link: string, internal_media_id: number) => {
            input.value = post_link;
            input.internal_media_id = internal_media_id;
            const urlFreg = post_link.match(/([^/]+)\/?$/);
            input.media_uid = urlFreg && urlFreg[1] ? urlFreg[1] : undefined;
            this.setState({});
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    board: state.boardReducer.board,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    attachLinkMedia: (
      board_id: number,
      influencer_id: number,
      data: any,
      callback: any,
    ) => {
      dispatch(attachLinkMedia(board_id, influencer_id, data, callback));
    },
    changeStoriesCount: (
      influencer_id: number,
      board_id: number,
      stories: any,
      callback: any,
    ) => {
      dispatch(changeStoriesCount(influencer_id, board_id, stories, callback));
    },
    revertLinkedMedia: (
      board_id: any,
      influencer_id: any,
      task_id: any,
      callback: any,
    ) => {
      dispatch(revertLinkedMedia(board_id, influencer_id, task_id, callback));
    },
    revertLinkedStory: (
      board_id: any,
      influencer_id: any,
      task_id: any,
      callback: any,
    ) => {
      dispatch(revertLinkedStory(board_id, influencer_id, task_id, callback));
    },
  };
};
export const AttachLinksDialogWithManageData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachLinksDialogInner);
