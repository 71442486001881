import type { ComponentSingleStyleConfig } from "@chakra-ui/react";

export const ContainerTheme: ComponentSingleStyleConfig = {
  variants: {
    card: {
      p: 3,
      bg: "white",
      borderRadius: "lg",
      boxShadow: "base",
      maxWidth: "initial",
    },
  },
};
