// TODO - lint
/* eslint-disable import/no-default-export */
import Dialog from "rc-dialog";
import { useState } from "react";
import { SubTypes } from "src/components/feed_components/utilities/general";

import {
  TaskTypes,
  get_post_type_icon,
  get_post_type_label,
} from "src/components/feed_components/utilities/providers";
import IndicatorSelect from "../../CreateCampaignSection/FormUI/IndicatorSelect";

interface Props {
  cancelLabel?: string;
  handleClose(): void;
  confirm(): void;
  confirmLabel?: string;
  post: any;
  influencer: any;
}

const NewTaskDialog = (props: Props) => {
  const [subType, setSubType] = useState(SubTypes[0]);

  const handleTaskSubTypeChange = (value: any) => {
    setSubType(value);
  };
  return (
    <Dialog
      onClose={props.handleClose}
      visible={true}
      animation="slide-fade"
      maskAnimation="fade"
    >
      <div style={{ padding: 5, marginBottom: "10px" }}>
        <img
          className="avatar"
          style={{
            height: "30px",
            width: "30px",
            margin: 0,
            marginRight: "10px",
          }}
          src={props.influencer?.picture_url}
        />
        {props.influencer.name}
      </div>
      <div>
        <div style={{ display: "flex", gap: 24 }}>
          <IndicatorSelect
            header="Task type"
            options={TaskTypes}
            defaultValue={() => get_post_type_label(props.post.post_type)}
            clearable={false}
            onChange={() => console.log("disable")}
            name="selected-state"
            width="200px"
            disabled={true}
            icon={`${get_post_type_icon(props.post.post_type)} fa-lg colored`}
          />
          {props.post.post_type === "ig_post" && (
            <div style={{ paddingTop: 4 }}>
              <div style={{ marginLeft: 5, marginTop: -4 }}>
                <IndicatorSelect
                  header="Sub Type"
                  options={SubTypes}
                  defaultValue={subType}
                  clearable={false}
                  simpleValue
                  onChange={handleTaskSubTypeChange as any}
                  name="selected-state"
                  width="200px"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="confirm-dialog-buttons-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <button
          className="btn cancel-confirm-dialog-button"
          onClick={props.handleClose}
          style={{ marginRight: "20px" }}
        >
          {props.cancelLabel !== undefined && props.cancelLabel !== null
            ? props.cancelLabel
            : window.translate("Cancel", "action_dialog_cancel")}
        </button>
        <button
          className="btn btn-primary accept-confirm-dialog-button"
          onClick={props.confirm}
        >
          {props.confirmLabel !== undefined && props.confirmLabel !== null
            ? props.confirmLabel
            : window.translate("Confirm", "action_dialog_confirm")}
        </button>
      </div>
    </Dialog>
  );
};

export default NewTaskDialog;
