import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../store";
import {
  getBackendUri,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";

const sharesUrlBase = () => `${getBackendUri()}/shares`;
const shareCampaignBaseUrl = () => `${sharesUrlBase()}/campaign`;
const listSharesUrlBase = () => `${sharesUrlBase()}/lists`;
const roiSharesUrlBase = () => `${shareCampaignBaseUrl()}/roi`;
const standaloneRoiSharesUrlBase = () => `${sharesUrlBase()}/standalone_roi`;

let authKey = "";
let authKeyPassword = "";

export const setShareKey = (key, password) => {
  authKey = key;
  authKeyPassword = password;
};

export const getShareKeys = () => {
  return { "auth-key": authKey, "auth-key-password": authKeyPassword };
};

export const getSharedList = (callback) => {
  return (dispatch) => {
    axios
      .get(listSharesUrlBase(), {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        dispatch({ type: "FETCH_LIST_FULFILLED", payload: response.data.list });
        if (response.data.owner_details) {
          dispatch({
            type: "SET_SHARED_USER_DETAILS",
            payload: response.data.owner_details,
          });
        }
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Your shared link is no longer avaliable",
            "system_notifications_no_shared_link",
          );
          toast.error(notificationOpts.message);
        }
        dispatch({ type: "SHARE_LIST_GET_LIST_REJECTED", payload: err });
        // callback();
        console.log(err);
      });
  };
};

export const getSharedListFrequencies = (callback) => {
  return (dispatch) => {
    dispatch({ type: "SHOW_LIST_FREQUENCIES_LOADER_FULLFILED", payload: true });
    axios
      .get(`${listSharesUrlBase()}/frequenices`, {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_LIST_FREQUENCIES_FULLFILED",
          payload: response.data,
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_LIST_FREQUENCIES_REJECTED", payload: err });
      });
  };
};

export const getInfluencerDataSharedList = (
  influencerId,
  postsFiler,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${listSharesUrlBase()}/get_influencer_data/${influencerId}`,
        postsFiler,
        {
          withCredentials: true,
          headers: {
            "auth-key": authKey,
            "auth-key-password": authKeyPassword,
          },
        },
      )
      .then((response) => {
        dispatch({
          type: "SHARE_INFLUENCER_DATA_FULFILLED",
          payload: response.data,
        });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        dispatch({ type: "SHARE_INFLUENCER_DATA_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback();
        }
        console.log(err);
      });
  };
};

export const getInfluencerDataSharedCampaign = (
  influencerId,
  postsFiler,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${shareCampaignBaseUrl()}/get_influencer_data/${influencerId}`,
        postsFiler,
        {
          withCredentials: true,
          headers: {
            "auth-key": authKey,
            "auth-key-password": authKeyPassword,
          },
        },
      )
      .then((response) => {
        dispatch({
          type: "SHARE_INFLUENCER_DATA_FULFILLED",
          payload: response.data,
        });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        dispatch({ type: "SHARE_INFLUENCER_DATA_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback();
        }
        console.log(err);
      });
  };
};

export const listUserResponse = (influencerId, approve, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${listSharesUrlBase()}/${influencerId}/response`,
        { approve },
        {
          withCredentials: true,
          headers: {
            "auth-key": authKey,
            "auth-key-password": authKeyPassword,
          },
        },
      )
      .then((response) => {
        dispatch({
          type: "SHARE_LIST_INFLUENCER_RESPONSE_FULFILLED",
          payload: {
            influencerId,
            response: approve,
            summary: response.data,
          },
        });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        dispatch(getSharedListFrequencies(authKey));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({
          type: "SHARE_LIST_INFLUENCER_RESPONSE_REJECTED",
          payload: err,
        });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "This Operation failed. Please try again",
          "system_notifications_general_failure",
        );
        toast.error(notificationOpts.message);
        if (callback) {
          callback();
        }
      });
  };
};

export const listUserNote = (influencerId, note, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${listSharesUrlBase()}/${influencerId}/note`,
        { note },
        {
          withCredentials: true,
          headers: {
            "auth-key": authKey,
            "auth-key-password": authKeyPassword,
          },
        },
      )
      .then(() => {
        dispatch({
          type: "SHARE_LIST_INFLUENCER_NOTE_FULFILLED",
          payload: { influencerId, note },
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "SHARE_LIST_INFLUENCER_NOTE_REJECTED", payload: err });
        if (callback) {
          callback();
        }
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "This Operation failed. Please try again",
          "system_notifications_general_failure",
        );
        toast.error(notificationOpts.message);
      });
  };
};

export const getSharedRoi = (storyOnly, filters, callback) => {
  return (dispatch) => {
    axios
      .post(
        `${roiSharesUrlBase()}`,
        { ...filters },
        {
          withCredentials: true,
          headers: {
            "auth-key": authKey,
            "auth-key-password": authKeyPassword,
          },
        },
      )
      .then((response) => {
        dispatch({ type: "GET_AUCTION_ROI", payload: response.data.roi });
        callback(response.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Your shared roi is no longer avaliable",
            "system_notifications_no_shared_roi",
          );
          toast.error(notificationOpts.message);
        }
        dispatch({ type: "GET_AUCTION_ROI_REJECTED", payload: err });
        console.log(err);
        // dispatch(setToastMessage(I18n.t("system_notifications_failure_get_campaign"),"error"));
      });
  };
};

export const getSharedMultiReport = (password, callback) => {
  if (password) {
    authKeyPassword = password;
  }
  return (dispatch) => {
    axios
      .get(`${sharesUrlBase()}/multi_reports?full_roi=`, {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        dispatch({ type: "GET_AUCTION_ROI", payload: response.data.roi });
        callback(response.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Your shared roi is no longer available",
            "system_notifications_no_shared_roi",
          );
          toast.error(notificationOpts.message);
        }
        dispatch({ type: "GET_AUCTION_ROI_REJECTED", payload: err });
        console.log(err);
        // dispatch(setToastMessage(I18n.t("system_notifications_failure_get_campaign"),"error"));
      });
  };
};

export const getMultiShareDetails = (authKeyInner, callback) => {
  return () => {
    axios
      .get(`${sharesUrlBase()}/multi_reports/share_details`, {
        withCredentials: true,
        headers: { "auth-key": authKeyInner },
      })
      .then((response) => {
        if (callback) callback(response.data);
      })
      .catch(() => {
        if (callback) callback(false);
      });
  };
};

export const getSharedStandaloneRoi = (callback) => {
  return (dispatch) => {
    axios
      .get(standaloneRoiSharesUrlBase(), {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        dispatch({ type: "GET_AUCTION_ROI", payload: response.data.roi });
        callback(response.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Your shared roi is no longer available",
            "system_notifications_no_shared_roi",
          );
          toast.error(notificationOpts.message);
        }
        dispatch({ type: "GET_AUCTION_ROI_REJECTED", payload: err });
        console.log(err);
        // dispatch(setToastMessage(I18n.t("system_notifications_failure_get_campaign"),"error"));
      });
  };
};

export const getShareDetails = (callback) => {
  axios
    .get(shareCampaignBaseUrl(), {
      withCredentials: true,
      headers: { "auth-key": authKey, "auth-key-password": authKeyPassword },
    })
    .then((response) => {
      if (response.data.owner_details) {
        store.dispatch({
          type: "SET_SHARED_USER_DETAILS",
          payload: response.data.owner_details,
        });
      }
      callback(response.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const getShareBrief = (callback) => {
  axios
    .get(`${shareCampaignBaseUrl()}/brief`, {
      withCredentials: true,
      headers: { "auth-key": authKey, "auth-key-password": authKeyPassword },
    })
    .then((response) => {
      callback(response.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const getShareBoardDetails = (month, callback) => {
  return (dispatch) => {
    axios
      .get(`${shareCampaignBaseUrl()}/manage`, {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
        params: { month },
      })
      .then((response) => {
        const { data } = response;
        data.waiting_for_update = false;
        data.last_update_time = new Date();
        dispatch({ type: "GET_BOARD_DATA_FULLFILLED", payload: data });
        if (callback) callback(response.data);
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get board",
          "system_notifications_failure_get_inf_in_board",
        );
        toast.error(notificationOpts.message);
        if (callback) callback();
        console.error(err);
      });
  };
};

export const responseToShareProposal = (proposalId, response, callback) => {
  axios
    .post(
      `${shareCampaignBaseUrl()}/manage/proposal_feedback/${proposalId}`,
      response,
      {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      },
    )
    .then(() => {
      callback(response.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const getShareGeneral = (authKeyInner, callback) => {
  axios
    .get(`${shareCampaignBaseUrl()}/share_details`, {
      withCredentials: true,
      headers: { "auth-key": authKeyInner },
    })
    .then((response) => {
      if (callback) callback(response.data);
    })
    .catch(() => {
      if (callback) callback(false);
    });
};

export const getSharedProposalsHistory = (influencerId, callback) => {
  axios
    .post(`${shareCampaignBaseUrl()}/proposals_history/${influencerId}`, null, {
      withCredentials: true,
      headers: { "auth-key": authKey, "auth-key-password": authKeyPassword },
    })
    .then((response) => {
      callback(response.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const searchOnSharedList = (searchParams, callback) => {
  return (dispatch) => {
    const url = `${listSharesUrlBase()}/search`;
    axios
      .post(url, searchParams, {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        dispatch({ type: "SET_SEARCH_PARAMETERS", payload: searchParams });
        callback(response);
      })
      .catch((err) => {
        dispatch({ type: "SEARCH_INFLUENCERS_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to get influencers";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getSharedBrandReportTopTags = (
  authKeyInner,
  compareBy,
  callback,
) => {
  return () => {
    let url = `${sharesUrlBase()}/brand_report/top_words`;
    if (compareBy) {
      url += `?compare_by=${compareBy}`;
    }
    axios
      .get(url, {
        withCredentials: true,
        headers: { "auth-key": authKeyInner },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch(() => {
        callback(false);
      });
  };
};

export const getSharedAmbassadorReport = (authKeyInner, callback) => {
  return () => {
    axios
      .get(`${getBackendUri()}/shares/ambassador_report`, {
        withCredentials: true,
        headers: { "auth-key": authKeyInner },
      })
      .then((response) => {
        if (callback) callback(response.data);
      })
      .catch(() => {
        if (callback) callback(false);
      });
  };
};

export const sharedCalculateFrequencies = (influencerIds, callback) => {
  return (dispatch) => {
    axios
      .post(
        `${getBackendUri()}/shares/ambassador_report/frequenices`,
        { influencers_ids: influencerIds },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response.data);
      })
      .catch((err) => {
        console.log(err);
        if (callback) callback(null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getListSegmentationDataShared = (callback) => {
  return (dispatch) => {
    axios
      .get(`${listSharesUrlBase()}/get_list_segmentation_data`, {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        if (callback) callback(response.data);
        dispatch({
          type: "GET_SHARED_LIST_SEGMENTATION_FULLFILED",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (callback) callback(null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getShareNotes = (objectType, itemType, itemId, callback) => {
  let url = "";
  if (objectType === "list") {
    url = `${getBackendUri()}/shares/lists/notes/`;
  } else if (objectType === "campaign") {
    url = `${getBackendUri()}/shares/campaign/notes/`;
  }
  url += `${itemType}/notes/${itemId}`;
  return (dispatch) => {
    axios
      .get(url, {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        if (callback) callback(response.data);
        dispatch({ type: "GET_SHARE_NOTES_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (callback) callback(null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const addShareNotesFromShare = (
  objectType,
  itemType,
  itemId,
  message,
  callback,
) => {
  let url = "";
  if (objectType === "list") {
    url = `${getBackendUri()}/shares/lists/notes/`;
  } else if (objectType === "campaign") {
    url = `${getBackendUri()}/shares/campaign/notes/`;
  }
  url += `${itemType}/notes/${itemId}`;
  return (dispatch) => {
    axios
      .post(url, message, {
        withCredentials: true,
        headers: {
          "auth-key": authKey,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        if (callback) callback(response.data);
        dispatch({ type: "ADD_SHARE_NOTES_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (callback) callback(null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getListNotesSummary = (callback) => {
  axios
    .get(`${getBackendUri()}/shares/lists/notes`, {
      withCredentials: true,
      headers: { "auth-key": authKey, "auth-key-password": authKeyPassword },
    })
    .then((response) => {
      if (callback) callback(response.data);
    })
    .catch((err) => {
      console.log(err);
      if (callback) callback(null);
    });
};

export const getCampaignNotesSummary = (callback) => {
  axios
    .get(`${getBackendUri()}/shares/campaign/notes`, {
      withCredentials: true,
      headers: { "auth-key": authKey, "auth-key-password": authKeyPassword },
    })
    .then((response) => {
      if (callback) callback(response.data);
    })
    .catch((err) => {
      console.log(err);
      if (callback) callback(null);
    });
};

export const getTrackerShare = (authKeyInner, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER" });
    axios
      .request(`${getBackendUri()}/shares/campaign/tracker`, {
        data: {},
        withCredentials: true,
        headers: {
          "auth-key": authKeyInner,
          "auth-key-password": authKeyPassword,
        },
      })
      .then((response) => {
        if (callback) callback(response.data);
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
      })
      .finally(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER" });
      });
  };
};

export const searchIGUser = (username, agentFk) => {
  console.log(authKey);
  return axios.post(
    `${getBackendUri()}/socialLogin/external_register/business_discovery`,
    { username },
    {
      params: { agent_fk: agentFk },
      withCredentials: true,
      headers: { "auth-key": authKey, "auth-key-password": authKeyPassword },
    },
  );
};
