// TODO - lint
/* eslint-disable react/no-deprecated, no-param-reassign, class-methods-use-this, no-unused-vars, no-unused-vars, no-unused-vars, no-param-reassign, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-string-refs, react/no-direct-mutation-state, consistent-return, react/no-direct-mutation-state, no-param-reassign, react/no-string-refs, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-direct-mutation-state, react/no-unescaped-entities, no-unused-vars, import/no-default-export */
// General Imports
import React from "react";
import Dialog from "rc-dialog";
import DatePicker from "react-datepicker";
import Toggle from "react-toggle";
import Clipboard from "react-clipboard.js";
import { connect } from "react-redux";
import Textarea from "react-textarea-autosize";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import Tooltip from "rc-tooltip";

// Components Import

// Functions import
import moment from "moment";
import { uniqueId } from "recharts/es6/util/DataUtils";
import {
  updateShareAmbassador,
  getSharedAmbassador,
  shareAmbassador,
} from "../../../actions/shareAmbassadorROIActions";
import { getAmbassador } from "../../../actions/ambassadorReportsActions";
import { Currencies } from "../utilities/general";

const filterMap = [
  {
    type: "date-range",
    label: "Date",
    isOpen: true,
    filteredData: "dateTime",
  },
  {
    type: "ambassador_usernames",
    label: "Ambassadors",
    isOpen: true,
  },
  {
    type: "tags",
    label: "Hashtags",
    isOpen: true,
    filteredData: "hashtags",
  },
  {
    type: "tags",
    label: "Mentions",
    isOpen: true,
    filteredData: "mentions",
  },
  {
    type: "kpis",
    label: "KPI's",
    isOpen: false,
  },
];

class ShareAmbassadorRoiDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareRoi: false,
      loading: false,
      changed: false,
      generalOpen: true,
      commentsOpen: false,
      showPassword: false,
    };
  }

  updateSharesList() {
    this.props.getSharedAmbassador(
      this.props.insight.auction_id || this.props.insight.id,
      (response) => {
        const newState = { loading: false };
        newState.shares = response;
        newState.selectedShare = null;
        this.setState(newState);
      },
    );
  }

  componentWillMount() {
    this.setState({ loading: true }, this.updateSharesList);
    if (!this.props.ambassadorReport) {
      this.props.getAmbassador(this.props.insight.id, (data) => {
        if (!data.influencers) data.influencers = [];
        this.setState({ ambassadorReport: data });
      });
    } else {
      this.setState({ ambassadorReport: this.props.ambassadorReport });
    }
  }

  getShareDataToSend(data) {
    if (data) return data;
    const dataToSend = {
      share_enabled: true,
      sharing_end_time: null,
      shared_guid: null,
      custom_title: "",
      password: "",
      link: "",
      comments: {
        kpi: "",
        tasks: "",
        funnel: "",
        segmentation: "",
      },
      kpis: {
        budget: null,
        conversions: null,
        currency: null,
        engagement: null,
        impressions: null,
      },
      filter: {
        influencers: [],
        hashtags: [],
        mentions: [],
        time_filter: {
          from: "",
          to: "",
          amount: 1,
          offset: 0,
        },
      },
    };
    return dataToSend;
  }

  getInfluencerIds() {
    return this.state.ambassadorReport &&
      this.state.ambassadorReport.influencers
      ? this.state.ambassadorReport.influencers.map((i) => i.id)
      : [];
  }

  getInfluencerHashtags() {
    return this.state.ambassadorReport && this.state.ambassadorReport.hashtags
      ? this.state.ambassadorReport.hashtags
      : [];
  }

  getInfluencersMentions() {
    return this.state.ambassadorReport && this.state.ambassadorReport.mentions
      ? this.state.ambassadorReport.mentions
      : [];
  }

  onChangeShare() {
    this.setState({ loading: true });
    const reportId = this.props.insight.auction_id || this.props.insight.id;
    if (this.state.isCreate) {
      // on first time default parameters taken from share for first time sharing
      this.props.putSharedAuctionRoi(
        reportId,
        this.state.selectedShare,
        (data) => {
          this.setState(
            {
              loading: false,
              selectedShare: null,
              isCreate: false,
            },
            this.updateSharesList,
          );
        },
      );
    } else {
      this.props.updateShareAmbassador(
        reportId,
        this.state.selectedShare.shared_guid,
        this.state.selectedShare,
        (data) => {
          this.setState({
            loading: false,
            selectedShare: null,
            isEdit: false,
            isCreate: false,
          });
        },
      );
    }
    this.setState({ changed: true, selectedShare: null });
  }

  handleUpdate() {
    if (this.state.selectedShare) {
      this.setState({ loading: true });
      const shareGuid = this.state.selectedShare.shared_guid;
      const dataToSend = this.getShareDataToSend(this.state.selectedShare);
      this.props.updateShareAmbassador(
        this.props.insight.auction_id,
        shareGuid,
        dataToSend,
        (data) => {
          this.props.handleClose(true);
        },
      );
      this.setState({ changed: true, selectedShare: null });
    } else {
      this.props.handleClose(true);
    }
  }

  editSingleShare(singleShare) {
    if (!singleShare.filter) {
      singleShare.filter = {
        influencers: this.getInfluencerIds(),
        hashtags: this.getInfluencerHashtags(),
        mentions: this.getInfluencersMentions(),
        time_filter: {
          from: "",
          to: "",
          amount: 1,
          offset: 0,
        },
      };
    }
    this.setState({ selectedShare: singleShare, isEdit: true });
  }

  handleTagsChange = (item, tags) => {
    const newState = { ...this.state };
    newState.selectedShare.filter[item.filteredData] = tags.map((x) =>
      x.replace("#", "").replace("@", ""),
    );
    this.setState(newState);
  };

  renderShareList() {
    return (
      <div className={"share-table"}>
        <div className={"share-lines-wrapper"}>
          {this.state.shares && this.state.shares.length ? (
            <div className="share-line-titles flex">
              <div className="flex1"></div>
              <div className="flex2">Title</div>
              <div className="flex2">Date</div>
              <div className="flex2">Actions</div>
              <div
                className="flex2"
                style={{ textAlign: "right", marginLeft: -12, marginRight: 12 }}
              >
                Active
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.shares && this.state.shares.length ? (
            this.state.shares.map((singleShare, i) => (
              <div
                className={`flex single-share-line ${
                  moment(singleShare.sharing_end_time) < moment()
                    ? "expired"
                    : ""
                }`}
                key={`share_${i}`}
              >
                <i className="fas fa-file-chart-line flex1"></i>
                <div className={"flex2"}>
                  {singleShare.custom_title
                    ? singleShare.custom_title
                    : this.state.ambassadorReport.name}
                </div>
                <div className={"flex2"}>
                  {moment(singleShare.sharing_end_time).format(
                    window.time.short_date,
                  )}
                </div>
                <div className={"flex2"}>
                  <Tooltip
                    placement="top"
                    trigger={["hover"]}
                    overlay={<span>Edit</span>}
                  >
                    <i
                      className="soft-btn far fa-edit"
                      onClick={() => this.editSingleShare(singleShare)}
                    />
                  </Tooltip>
                  <Clipboard
                    style={{ background: "transparent", border: "none" }}
                    data-clipboard-text={singleShare.link}
                  >
                    <Tooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={<span>Copy link</span>}
                    >
                      <i className="soft-btn far fa-copy copy-btn" />
                    </Tooltip>
                  </Clipboard>
                </div>

                <div className={"flex2 text-right"}>
                  <Toggle
                    className={
                      !singleShare.share_enabled ||
                      moment(singleShare.sharing_end_time) < moment()
                        ? "off"
                        : ""
                    }
                    defaultChecked={
                      moment(singleShare.sharing_end_time) > moment()
                        ? singleShare.share_enabled
                        : false
                    }
                    icons={false}
                    onChange={() => {
                      this.state.selectedShare = singleShare;
                      this.state.selectedShare.share_enabled =
                        !singleShare.share_enabled;
                      this.setState({});
                      this.onChangeShare();
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <div style={{ marginTop: 20 }}>No shares created yet.</div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        maskClosable={false}
        onClose={() => this.props.handleClose(this.state.changed)}
        className="share-dialog "
        visible={true}
        style={{ marginTop: 100 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">Share Ambassador Report</div>

          {/* modal header */}
          <div style={{ fontSize: 16, fontWeight: 300, marginTop: 20 }}>
            Share Ambassador Report
            {!this.state.isCreate && !this.state.isEdit && (
              <button
                onClick={() => {
                  this.setState({
                    isEdit: false,
                    isCreate: true,
                    selectedShare: this.getShareDataToSend(),
                  });
                }}
                className={"pull-right btn btn-primry"}
              >
                <span
                  className="moi-plus"
                  style={{
                    color: "var(--main-baby-blue-color)",
                    fontSize: 11,
                  }}
                />{" "}
                Add new
              </button>
            )}
            {(this.state.isCreate || this.state.isEdit) && (
              <button
                style={{ marginRight: 2 }}
                onClick={() => {
                  this.setState({
                    isEdit: false,
                    isCreate: false,
                    selectedShare: null,
                  });
                }}
                className={"pull-right btn btn-primry"}
              >
                <i
                  className="far fa-arrow-alt-left"
                  style={{
                    color: "var(--main-baby-blue-color)",
                    fontSize: 11,
                  }}
                />{" "}
                Back
              </button>
            )}
          </div>

          {/* modal content */}
          {this.state.loading ? (
            <div
              className="spinner small"
              style={{ marginTop: 40, marginBottom: 0 }}
            >
              <div className="rect1"></div>
              <div className="rect2"></div>
              <div className="rect3"></div>
              <div className="rect4"></div>
              <div className="rect5"></div>
            </div>
          ) : (
            <div>
              {!this.state.isEdit &&
                !this.state.isCreate &&
                this.renderShareList()}
              {(this.state.isEdit || this.state.isCreate) &&
                this.renderSingleShare()}
            </div>
          )}

          {/* modal footer buttons */}
          <div
            style={{
              paddingBottom: 5,
              paddingTop: 10,
              marginTop: 40,
              textAlign: "center",
            }}
          >
            {this.state.isEdit && (
              <button
                style={{ marginLeft: 10 }}
                className={`attach-links-btn share-save-btn btn btn-primary ${
                  this.state.loading && "disabled"
                }`}
                onClick={() => this.handleUpdate()}
              >
                <span>Save</span>
              </button>
            )}
            {this.state.isCreate && (
              <button
                style={{ marginLeft: 10 }}
                className={`attach-links-btn share-save-btn btn btn-primary ${
                  this.state.loading && "disabled"
                }`}
                onClick={() => this.onChangeShare()}
              >
                <span>Create</span>
              </button>
            )}
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={() => this.props.handleClose(this.state.changed)}
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  renderKpis() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Impressions</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={this.state.selectedShare.kpis.impressions}
                onChange={(e) => {
                  this.state.selectedShare.kpis.impressions = e.target.value;
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Engagements</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={this.state.selectedShare.kpis.engagement}
                onChange={(e) => {
                  this.state.selectedShare.kpis.engagement = e.target.value;
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Conversions</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={this.state.selectedShare.kpis.conversions}
                onChange={(e) => {
                  this.state.selectedShare.kpis.conversions = e.target.value;
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Total Budget</strong>
            <div className="blurable-large">
              <input
                className={"input-line "}
                type="number"
                value={this.state.selectedShare.kpis.budget}
                onChange={(e) => {
                  this.state.selectedShare.kpis.budget = e.target.value;
                  this.setState({ updated: true });
                }}
              />
            </div>
          </div>
          <div className="input-row" style={{ width: "17%" }}>
            <strong className="label-title">Currency</strong>
            <div className="blurable-large">
              <div className="searchable-input" style={{ marginTop: -6 }}>
                <Select
                  ref="stateSelect"
                  options={Currencies}
                  placeholder={""}
                  value={this.state.selectedShare.kpis.currency}
                  simpleValue
                  clearable={false}
                  onChange={(value) => {
                    this.state.selectedShare.kpis.currency = value;
                    this.setState({ updated: true });
                  }}
                  name="selected-state"
                  disabled={false}
                  searchable={true}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderSingleShare = () => {
    if (!this.state.selectedShare || !this.state.ambassadorReport) return false;
    const disabledByTime =
      moment(this.state.selectedShare.sharing_end_time) < moment(new Date());
    const { ambassadorReport } = this.state;
    const getInfluencerUsername = (influencers, id) => {
      const infl = influencers.find((inf) => inf.id === id);
      if (infl) {
        const name = infl.name
          ? infl.name
          : `@${
              infl.instagram_username
                ? infl.instagram_username
                : infl.twitter_username
            }`;
        return <>{name}</>;
      }
    };
    return (
      <div style={{ marginTop: 20 }}>
        {this.state.isEdit && (
          <>
            <div className="flex">
              <div className="flex1 toggle-input">
                <strong className="label-title" style={{ marginBottom: 7 }}>
                  Share report
                </strong>
                <label>
                  <div className="toggle-label">
                    <div className="fa fa-times" />
                    <div className="fa fa-check" />
                  </div>
                  <Toggle
                    style={{ marginLeft: 15 }}
                    defaultChecked={this.state.selectedShare.share_enabled}
                    icons={false}
                    onChange={() => {
                      this.state.selectedShare.share_enabled =
                        !this.state.selectedShare.share_enabled;
                      this.onChangeShare();
                    }}
                  />
                </label>
              </div>
              {this.state.selectedShare.link && (
                <div className="flex2">
                  <strong className="label-title">LINK</strong>
                  <div style={{ display: "flex" }}>
                    <input
                      value={this.state.selectedShare.link}
                      className="input-line"
                      readOnly={true}
                    />
                    <Clipboard
                      style={{ background: "transparent", border: "none" }}
                      data-clipboard-text={this.state.selectedShare.link}
                    >
                      <i className="far fa-copy copy-btn" />
                    </Clipboard>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <div className="campaigns-title">
            <span style={{ fontSize: 16 }}>Sharing until</span>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <div
                style={{ width: 200, marginLeft: 5 }}
                className="searchable-input"
              >
                <DatePicker
                  error={true}
                  selected={
                    this.state.selectedShare.sharing_end_time
                      ? moment(this.state.selectedShare.sharing_end_time)
                      : null
                  }
                  isClearable={true}
                  placeholderText="Share end date"
                  dateFormat={window.time.date_with_hour}
                  onChange={(date) => {
                    this.setState({
                      updated: true,
                      selectedShare: {
                        ...this.state.selectedShare,
                        sharing_end_time: date,
                      },
                    });
                  }}
                  className={`input-line ${
                    disabledByTime ? "disabled-by-time" : ""
                  }`}
                />
              </div>
            </div>
          </div>

          {filterMap.map((item, j) => (
            <div key={j}>
              <div
                onClick={() => {
                  item.isOpen = !item.isOpen;
                  this.setState({ updated: true });
                }}
              >
                <div className="campaigns-title">
                  <span style={{ fontSize: 16 }}>{item.label}</span>
                </div>
                <div
                  className={`row-collapse ${
                    item.isOpen ? "moi-minus" : "moi-plus"
                  }`}
                />
              </div>

              {item.isOpen && (
                <div className="inner-filter-content">
                  {item.type === "ambassador_usernames" && (
                    <>
                      <div className="selected_list react-tagsinput">
                        {this.state.selectedShare.filter.influencers &&
                          this.state.selectedShare.filter.influencers.map(
                            (influencer, i) => (
                              <span className="react-tagsinput-tag" key={i}>
                                {getInfluencerUsername(
                                  ambassadorReport.influencers,
                                  influencer,
                                )}
                                <a
                                  className="react-tagsinput-remove"
                                  onClick={() => {
                                    this.state.selectedShare.filter.influencers.addOrRemove(
                                      influencer,
                                    );
                                    this.setState({});
                                  }}
                                />
                              </span>
                            ),
                          )}
                      </div>
                      {ambassadorReport.influencers && (
                        <Select
                          className="searchable-input"
                          ref="srch_input"
                          multi={true}
                          options={ambassadorReport.influencers.map((i) => {
                            return {
                              value: i.id,
                              label: i.name
                                ? i.name
                                : `@${
                                    i.instagram_username
                                      ? i.instagram_username
                                      : i.twitter_username
                                  }`,
                            };
                          })}
                          clearable={true}
                          onChange={(e) => {
                            if (
                              this.state.selectedShare.filter.influencers.indexOf(
                                e[0].value,
                              ) === -1
                            ) {
                              this.state.selectedShare.filter.influencers.push(
                                e[0].value,
                              );
                              this.setState({});
                            }
                          }}
                          name="srch_input"
                          searchable={true}
                        />
                      )}
                    </>
                  )}
                  {item.type === "tags" && (
                    <div>
                      <div style={{ borderBottom: "1px solid #bbbbbb" }}>
                        <form autoComplete={"off"}>
                          <TagsInput
                            value={
                              this.state.selectedShare.filter[item.filteredData]
                            }
                            onChange={(e) => {
                              this.handleTagsChange(item, e);
                            }}
                            addOnBlur={true}
                            inputProps={{
                              placeholder:
                                item.filteredData === "hashtags" ? "#" : "@",
                              className: "tasks-tags-input main-tags",
                              autoComplete: "new-password",
                              name: uniqueId(),
                            }}
                          />
                        </form>
                      </div>
                    </div>
                  )}
                  {item.type === "date-range" && (
                    <div className={"flex"} style={{ width: 460 }}>
                      <DatePicker
                        dateFormat={window.time.date_with_hour}
                        className="input-line"
                        isClearable={false}
                        placeholderText="from"
                        selected={
                          this.state.selectedShare.filter.time_filter.from
                            ? moment(
                                this.state.selectedShare.filter.time_filter
                                  .from,
                              )
                            : null
                        }
                        onChange={(date) => {
                          this.state.selectedShare.filter.time_filter.from =
                            date;
                          this.setState({});
                        }}
                      />
                      <span
                        style={{
                          marginTop: 7,
                          fontSize: 19,
                          padding: "4px 10px",
                          color: "#555",
                          background: "gainsboro",
                          borderRadius: "50%",
                          width: 35,
                          height: 35,
                          margin: "2px 7px",
                        }}
                      >
                        to
                      </span>
                      <DatePicker
                        dateFormat={window.time.date_with_hour}
                        className="input-line"
                        isClearable={false}
                        placeholderText="to"
                        selected={
                          this.state.selectedShare.filter.time_filter.to
                            ? moment(
                                this.state.selectedShare.filter.time_filter.to,
                              )
                            : null
                        }
                        onChange={(date) => {
                          this.state.selectedShare.filter.time_filter.to = date;
                          this.setState({});
                        }}
                      />
                    </div>
                  )}
                  {item.type === "kpis" && <>{this.renderKpis()}</>}
                </div>
              )}
              <div
                className="filter-refine-tab-spacer"
                style={{ marginBottom: 20 }}
              />
            </div>
          ))}

          {/* Password */}
          <div style={{ width: 400, marginLeft: 5, position: "relative" }}>
            <div className="campaigns-title">
              <span style={{ fontSize: 16 }}>Password</span>
            </div>
            <div>
              <input
                autoComplete={"new-password"}
                style={{ paddingRight: 80 }}
                defaultValue={this.state.selectedShare.password}
                onChange={(e) => {
                  this.state.selectedShare.password = e.target.value;
                  this.setState({});
                }}
                className="input-line"
                type={this.state.showPassword ? "normal" : "password"}
              />
              {this.state.selectedShare.password && (
                <div
                  onClick={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                  className="btn btn-primary"
                  style={{
                    position: "absolute",
                    padding: "5px 10px",
                    marginTop: -35,
                    marginLeft: "89%",
                  }}
                >
                  {this.state.showPassword ? "HIDE" : "SHOW"}
                </div>
              )}
            </div>
          </div>

          {/* Custom title */}
          <div
            style={{ width: 400, marginLeft: 5 }}
            className="searchable-input"
          >
            <div className="campaigns-title">
              <span style={{ fontSize: 16 }}>Custom title</span>
            </div>
            <input
              className="input-line"
              placeholder={"Custom Title"}
              defaultValue={this.state.selectedShare.custom_title}
              onChange={(e) => {
                this.state.selectedShare.custom_title = e.target.value;
                this.setState({});
              }}
            />
          </div>
        </div>

        {/* comments */}
        <div
          onClick={() => {
            this.setState({
              updated: true,
              commentsOpen: !this.state.commentsOpen,
            });
          }}
        >
          <div className="campaigns-title">
            <span style={{ fontSize: 16 }}>COMMENTS</span>
          </div>
          <div
            className={`row-collapse ${
              this.state.commentsOpen ? "moi-minus" : "moi-plus"
            }`}
          />
        </div>
        {this.state.commentsOpen && this.renderComments()}
      </div>
    );
  };

  renderComments() {
    const { comments } = this.state.selectedShare;
    return (
      <div>
        <div className="campaigns-title">
          <span style={{ fontSize: 16 }}>Comments</span>
        </div>
        <div className="optinal-title" style={{ marginTop: 20 }}>
          For your convenience you can add comments in any part of the report,
          please note that your comments will be exposed to all report viewers.
        </div>

        <div style={{ marginTop: 25 }}>
          <strong className="label-title">KPI'S COMMENTS</strong>
          <div>
            <Textarea
              className={"input-line"}
              defaultValue={comments.kpi}
              onChange={(e) => {
                comments.kpi = e.target.value;
                this.setState({});
              }}
              minRows={1}
              maxRows={5}
            />
          </div>
        </div>

        <div style={{ marginTop: 15 }}>
          <strong className="label-title">Tasks COMMENTS</strong>
          <div>
            <Textarea
              className={"input-line"}
              defaultValue={comments.tasks}
              onChange={(e) => {
                comments.tasks = e.target.value;
                this.setState({});
              }}
              minRows={1}
              maxRows={5}
            />
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <strong className="label-title">funnel COMMENTS</strong>
          <div>
            <Textarea
              className={"input-line"}
              defaultValue={comments.funnel}
              onChange={(e) => {
                comments.funnel = e.target.value;
                this.setState({});
              }}
              minRows={1}
              maxRows={5}
            />
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <strong className="label-title">segmentations COMMENTS</strong>
          <div>
            <Textarea
              className={"input-line"}
              defaultValue={comments.segmentation}
              onChange={(e) => {
                comments.segmentation = e.target.value;
                this.setState({});
              }}
              minRows={1}
              maxRows={5}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSharedAmbassador: (id, callback) => {
      dispatch(getSharedAmbassador(id, callback));
    },
    getAmbassador: (id, callback) => {
      dispatch(getAmbassador(id, callback));
    },
    putSharedAuctionRoi: (id, data, callback) => {
      dispatch(shareAmbassador(id, data, callback));
    },
    updateShareAmbassador: (id, guid, data, callback) => {
      dispatch(updateShareAmbassador(id, guid, data, callback));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareAmbassadorRoiDialog);
