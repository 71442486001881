// TODO - lint
/* eslint-disable no-unused-vars, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, camelcase, camelcase, camelcase, no-plusplus, no-plusplus, no-plusplus, no-plusplus, no-plusplus, no-continue, no-unused-vars, no-unused-vars, consistent-return, no-param-reassign, no-param-reassign, no-param-reassign, consistent-return, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-unused-vars, no-unused-vars, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, import/no-default-export */
// General Imports
import Dropzone from "@humanz/react-dropzone";
import Dialog from "rc-dialog";
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tooltip from "rc-tooltip";
import React from "react";
import ReactImageMagnify from "react-image-magnify";
import { connect } from "react-redux";
// Actions imports
import TagsInput from "react-tagsinput";
import uuidv4 from "uuid/v4";
import {
  addLinkToAmbassador,
  addStoriesToAmbassador,
} from "../../../../../actions/ambassadorReportsActions";
import {
  attachLinkMedia,
  changeStoriesCount,
} from "../../../../../actions/auctionsActions";

class AmbassadorAttachLinksDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      links: [],
      activeTab: "ig_post",
      attachInput: {
        ig_post: [],
        ig_story: [],
        tw_tweet: [],
        yt_video: [],
        tk_video: [],
      },
    };
  }

  onLoad() {
    const { influencer } = this.props;
    if (!influencer.instagram_username) {
      this.setState({ activeTab: "tw_tweet" });
    }
    if (influencer.manual_attached_medias) {
      this.parseMediaData(influencer.manual_attached_medias);
    }
  }

  componentDidMount() {
    this.onLoad();
  }

  parseMediaData(data) {
    const newState = { ...this.state };
    data.forEach((media) => {
      if (media.post_type) {
        if (!media.tags) media.tags = [];
        if (!media.mentions) media.mentions = [];
        if (media.count) media.views = media.count;
        if (media.image_url) media.url = media.image_url;
        newState.attachInput[media.post_type].push(media);
      }
    });
    this.setState({ newState });
  }

  addPicture(accepted, id, extra) {
    const reader = new FileReader();
    const { type } = accepted[0];
    reader.readAsDataURL(accepted[0]);
    reader.onload = () => {
      if (extra) {
        const story_id = uuidv4();
        const newData = {
          id: story_id,
          views: "",
          e: {},
          story_id,
          url: null,
          base64: reader.result,
          type: "ig_story",
          mime_type: type,
          new: true,
        };
        this.state.attachInput.ig_story.push(newData);

        this.setState({ updated: true });
      } else {
        const story = this.state.attachInput.ig_story.find((s) => s.id === id);
        story.base64 = reader.result;
        story.mime_type = type;
        this.setState({ updated: true });
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  addLink(type) {
    this.state.attachInput[type].push({
      id: uuidv4(),
      post_type: type,
      post_uri: "",
      tags: [],
      mentions: [],
    });
    this.setState({});
  }

  onSaveLinks() {
    const { influencer, ambassadorId } = this.props;
    const array = [];
    let storiesArray = [];
    let i;
    for (i = 0; i < this.state.attachInput.ig_post.length; i++) {
      const data = this.state.attachInput.ig_post[i];
      data.post_uri =
        this.state.attachInput.ig_post[i].value ||
        this.state.attachInput.ig_post[i].post_uri;
      data.post_type = "ig_post";
      if (data.post_uri) array.push(data);
    }
    for (i = 0; i < this.state.attachInput.tw_tweet.length; i++) {
      const data = this.state.attachInput.tw_tweet[i];
      data.post_uri =
        this.state.attachInput.tw_tweet[i].value ||
        this.state.attachInput.tw_tweet[i].post_uri;
      data.post_type = "tw_tweet";
      if (data.post_uri) array.push(data);
    }
    for (i = 0; i < this.state.attachInput.tk_video.length; i++) {
      const data = {};
      data.post_uri = this.state.attachInput.tk_video[i].value;
      data.post_type = "tk_video";
      if (data.post_uri) array.push(data);
    }
    for (i = 0; i < this.state.attachInput.yt_video.length; i++) {
      const data = {};
      data.post_uri = this.state.attachInput.yt_video[i].value;
      data.post_type = "yt_video";
      if (data.post_uri) array.push(data);
    }
    for (i = 0; i < this.state.attachInput.ig_story.length; i++) {
      const story = this.state.attachInput.ig_story[i];
      if (!story.views) {
        continue;
      }
      const data = {
        count: story.views,
        influencer_id: influencer.id,
        upload_image: story.base64
          ? { data: story.base64.split(",")[1], mime_type: story.mime_type }
          : null,
        image_url: story.url,
        id: story.id,
        tags: story.tags,
        mentions: story.mentions,
        engagements: story.engagements,
      };
      storiesArray.push(data);
    }

    // todo: Fix calling api twice and handleClose is fired twice
    if (storiesArray.length > 0) {
      this.setState({ loading: true });
      storiesArray = storiesArray.filter(
        (x) => x.count !== null && x.count !== undefined && x.count !== "",
      );
      this.props.addStoriesToAmbassador(
        ambassadorId,
        influencer.id,
        storiesArray,
        () => this.props.handleClose(true),
      );
    }
    if (array.length > 0) {
      this.setState({ loading: true });
      this.props.addLinkToAmbassador(ambassadorId, influencer.id, array, () =>
        this.props.handleClose(true),
      );
    }
  }

  render() {
    const instagramLinks = this.state.attachInput.ig_post;
    const { influencer } = this.props;
    const storiesLinks = this.state.attachInput.ig_story;
    const twitterLinks = this.state.attachInput.tw_tweet;
    return (
      <Dialog
        onClose={() => this.props.handleClose()}
        visible={true}
        style={{ marginTop: 20, width: 720 }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <div>
          <div className="edit-tasks-header">Attach Links</div>
          <div style={{ color: "#aaaaaa", marginTop: 25, marginBottom: 15 }}>
            You have to define which social network you choose, and then set the
            post link. in the story definition, you must specify multiple views
            and upload an image
            {this.state.activeTab !== "ig_story" && (
              <div className="alert alert-warning">
                <i className="fa fa-warning" />️ Humanz cannot provide accurate
                data for posts older than 30 days.
              </div>
            )}
          </div>

          <div>
            <Tabs
              renderTabBar={() => <InkTabBar />}
              renderTabContent={() => <TabContent />}
              activeKey={this.state.activeTab}
              onChange={(e) => {
                this.setState({ activeTab: e });
              }}
              className="attach-tasks-tabs"
            >
              <TabPane
                tab={<span>Instagram Posts</span>}
                key="ig_post"
                disabled={!influencer.instagram_username}
              >
                {!!instagramLinks.length &&
                  this.renderLinksSection(instagramLinks, "ig_post")}
              </TabPane>
              <TabPane
                tab={<span>Instagram Stories</span>}
                key="ig_story"
                disabled={!influencer.instagram_username}
              >
                {!!storiesLinks.length &&
                  this.renderStoriesSection(storiesLinks)}
              </TabPane>
              <TabPane
                tab={<span>Twitter Tweets</span>}
                key="tw_tweet"
                disabled={!influencer.twitter_username}
              >
                {!!twitterLinks.length &&
                  this.renderLinksSection(twitterLinks, "tw_tweet")}
              </TabPane>
              {/* <TabPane tab={<span>YouTube Videos</span>} key="videos"> */}
              {/*    {this.renderLinksSection(youtubeLinks, "youtube")} */}
              {/* </TabPane> */}
              {/* <TabPane tab={<span>Tiktok Videos</span>} key="tk_videos"> */}
              {/*    {this.renderLinksSection(tiktokLinks, "tiktok")} */}
              {/* </TabPane> */}
            </Tabs>
            <button
              style={{ marginTop: 10 }}
              className={"btn btn-success"}
              onClick={() => this.addLink(this.state.activeTab)}
            >
              <i className="fa fa-plus" /> Add new link
            </button>
          </div>

          <div
            style={{
              paddingBottom: 5,
              paddingTop: 10,
              marginTop: 5,
              textAlign: "center",
            }}
          >
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn share-save-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={() => this.onSaveLinks()}
            >
              <span>Save</span>
            </button>
            <button
              style={{ marginLeft: 10 }}
              className={`attach-links-btn cancel share-cancel-btn btn btn-primary ${
                this.state.loading && "disabled"
              }`}
              onClick={() => this.props.handleClose(this.state.changed)}
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  renderLinksSection(links, type) {
    return (
      <div style={{ padding: "5px 10px" }}>
        {links.map((t, idx) => {
          if (t.is_deleted) return;
          return (
            <div
              className={"ambassador-link"}
              key={t.id}
              style={{
                marginBottom: 15,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Tooltip
                placement="top"
                trigger={["hover"]}
                overlay={<span>{"Remove link"}</span>}
              >
                <div
                  onClick={() => {
                    t.is_deleted = true;
                    t.views = -1;
                    this.setState({});
                  }}
                  style={{ cursor: "pointer", marginTop: 9 }}
                  className="task-num-circle"
                >
                  <i className="fa fa-trash" />
                </div>
              </Tooltip>

              <input
                disabled={!!t.linked_at}
                style={{ marginTop: 10 }}
                value={t.post_uri}
                className="input-line"
                placeholder="Enter post link"
                onChange={(e) => {
                  t.post_uri = e.target.value;
                  this.setState({});
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderStoriesSection(links) {
    return (
      <div style={{ padding: "5px 10px" }}>
        {links.map((t, idx) => {
          if (t.is_deleted) return;
          return (
            <div
              key={t.id}
              style={{
                marginBottom: 15,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Tooltip
                placement="top"
                trigger={["hover"]}
                overlay={<span>{"Remove link"}</span>}
              >
                <div
                  onClick={() => {
                    t.is_deleted = true;
                    t.views = -1;
                    this.setState({});
                  }}
                  style={{ cursor: "pointer", marginTop: 9 }}
                  className="task-num-circle"
                >
                  <i className="fa fa-trash" />
                </div>
              </Tooltip>
              {this.renderInstagramStoryInputs(t, idx)}
            </div>
          );
        })}
      </div>
    );
  }

  handleTagsChange = (item, tags) => {
    tags.forEach((t, i) => {
      // remove # and @ from hashtags and mentions
      tags[i] = t.replace(/#/g, "").replace(/@/g, "");
    });
    item.tags = tags;
    this.setState({});
  };

  handleMentionsChange = (item, mentions) => {
    mentions.forEach((t, i) => {
      // remove # and @ from hashtags and mentions
      mentions[i] = t.replace(/#/g, "").replace(/@/g, "");
    });
    item.mentions = mentions;
    this.setState({});
  };

  renderInstagramStoryInputs = (t, idx) => {
    const input = this.state.attachInput.ig_story.find((x) => x.id === t.id);
    return (
      <div key={t.id}>
        <div className="attach-ig-story-container ambassador-story">
          <div className="flex">
            <div
              className="flex1"
              style={{ margin: "0 5px", alignSelf: "center" }}
            >
              <Dropzone
                accept={{ "image/jpeg": [], "image/png": [] }}
                style={{ borderRadius: 0 }}
                onDropAccepted={(accepted) =>
                  this.addPicture(accepted, t.id, false)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="small-upload-image-dropzone story-dropzone"
                  >
                    <input {...getInputProps()} />
                    {input && (input.base64 || input.url) ? (
                      <div
                        style={{
                          backgroundImage: t.media_id ? t.post_uri : "",
                          backgroundSize: "cover",
                          marginTop: 10,
                        }}
                      >
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "story",
                              width: 125,
                              height: 210,
                              src: input.base64 ? input.base64 : input.url,
                              marginTop: -10,
                            },
                            largeImage: {
                              src: input.base64 ? input.base64 : input.url,
                              width: 600,
                              height: 1500,
                            },
                          }}
                          enlargedImageContainerStyle={{
                            zIndex: 999,
                            marginLeft: 5,
                            border: "1px solid black",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="inner-small-upload-dropzone">
                        <div className="moi-right-arrow" />
                      </div>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
            <div
              className="flex2 text-left"
              style={{ padding: "10px 0 5px 0" }}
            >
              <label htmlFor={`views_${t.id}`}>Views Count</label>
              <input
                id={`views_${t.id}`}
                className="input-line"
                placeholder="Views Count"
                value={input.views}
                onChange={(e) => {
                  input.views = e.target.value;
                  this.setState({});
                }}
                type="number"
              />
              <label htmlFor={`engagements_${t.id}`}>Engagements</label>
              <input
                defaultValue={0}
                id={`engagements_${t.id}`}
                className="input-line"
                placeholder="Engagements"
                value={input.engagements}
                onChange={(e) => {
                  input.engagements = e.target.value;
                  this.setState({});
                }}
                type="number"
              />
              <div>
                <label htmlFor={`tags_${t.id}`}>Tags</label>
                <div
                  className={"story-input"}
                  style={{ borderBottom: "1px solid #bbbbbb" }}
                >
                  <TagsInput
                    value={t.tags}
                    onChange={(e) => {
                      this.handleTagsChange(t, e);
                    }}
                    addOnBlur={true}
                    inputProps={{
                      placeholder: "#",
                      className: "tasks-tags-input main-tags",
                    }}
                  />
                </div>
              </div>
              <div>
                <label htmlFor={`mentions_${t.id}`}>Mentions</label>
                <div
                  className={"story-input"}
                  style={{ borderBottom: "1px solid #bbbbbb" }}
                >
                  <TagsInput
                    value={t.mentions}
                    onChange={(e) => {
                      this.handleMentionsChange(t, e);
                    }}
                    addOnBlur={true}
                    inputProps={{
                      placeholder: "@",
                      className: "tasks-tags-input main-tags",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    attachLinkMedia: (board_id, influencer_id, data, callback) => {
      dispatch(attachLinkMedia(board_id, influencer_id, data, callback));
    },
    changeStoriesCount: (influencer_id, board_id, stories, callback) => {
      dispatch(changeStoriesCount(influencer_id, board_id, stories, callback));
    },
    addLinkToAmbassador: (report_id, group_id, links, callback) => {
      dispatch(addLinkToAmbassador(report_id, group_id, links, callback));
    },
    addStoriesToAmbassador: (report_id, group_id, links, callback) => {
      dispatch(addStoriesToAmbassador(report_id, group_id, links, callback));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AmbassadorAttachLinksDialog);
