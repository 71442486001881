// TODO - lint
/* eslint-disable default-param-last, no-param-reassign, vars-on-top, no-var, no-param-reassign, no-param-reassign, vars-on-top, no-var, no-redeclare, no-param-reassign, no-param-reassign, no-param-reassign, vars-on-top, no-var, no-param-reassign, no-param-reassign, no-param-reassign, eqeqeq, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, vars-on-top, no-var, block-scoped-var, block-scoped-var, no-plusplus, block-scoped-var, block-scoped-var, no-param-reassign, block-scoped-var, no-param-reassign, no-shadow, no-plusplus, no-param-reassign, no-param-reassign, no-plusplus, vars-on-top, no-var, no-redeclare, block-scoped-var, block-scoped-var, block-scoped-var, no-plusplus, block-scoped-var, no-param-reassign, block-scoped-var, no-param-reassign, no-param-reassign, vars-on-top, no-var, no-param-reassign, vars-on-top, no-var, no-redeclare, no-param-reassign, no-param-reassign, no-param-reassign, import/no-default-export */
const listsReducer = (
  state = {
    lists: [],
    list: [],
    archived_lists: [],
  },
  action,
) => {
  var listId;
  var listItem;
  switch (action.type) {
    case "FETCH_LISTS_FULFILLED":
      state = {
        ...state,
        lists:
          // eslint-disable-next-line no-nested-ternary
          action.payload && action.payload.normal
            ? action.payload.normal
            : action.payload && action.payload.length
            ? action.payload
            : [],
      };
      break;
    case "SWITCH_HIDE_USER_FROM_LIST_FULFILLED":
    case "SWITCH_HIDE_USER_FROM_LIST_REJECTED":
      var ids = action.payload.influencers.map((x) => x.influencer_id);
      state.list.influencers_data.forEach((i) => {
        if (ids.includes(i.id)) i.hidden = !i.hidden;
      });
      state = {
        ...state,
      };
      break;
    case "DELETE_USER_FROM_LIST_FULFILLED":
      var ids = action.payload.influencers.map((x) => x.influencer_id);
      state.list.influencers_data = state.list.influencers_data.filter(
        (x) => !ids.includes(x.id),
      );
      state = {
        ...state,
      };
      break;
    case "FETCH_LIST_FULFILLED":
      state = {
        ...state,
        list: action.payload ? action.payload : [],
      };
      break;
    case "CREATE_LIST_FULFILLED":
      var { lists } = state;
      lists.splice(0, 0, action.payload);
      state = {
        ...state,
        lists,
      };
      break;
    case "DELETE_LIST_FULFILLED":
      listItem = state.lists.find((x) => x.id === action.payload.list_id);
      if (listItem === undefined) break;
      state.lists.splice(state.lists.indexOf(listItem), 1);
      break;
    case "RENAME_LIST_FULFILLED":
      listItem = state.lists.find((x) => x.id === action.payload.list_id);
      listItem.name = action.payload.name;
      break;
    case "GET_LIST_FREQUENCIES_FULLFILED":
      state.list.frequencies = action.payload;
      state = {
        ...state,
      };
      break;
    case "GET_LIST_SEGMENTATION_FULLFILED":
      if (
        state.list &&
        state.list.list_summary_data &&
        state.list.list_summary_data.id == action.payload.listId
      ) {
        state.list.segmentation = action.payload.data;
        state = {
          ...state,
        };
      }
      break;
    case "GET_SHARED_LIST_SEGMENTATION_FULLFILED":
      if (state.list) {
        state.list.segmentation = action.payload;
        state = {
          ...state,
        };
      }
      break;
    case "UPDATE_LIST_GENERAL_DETAILS":
      state.list.list_summary_data = action.payload;
      state = {
        ...state,
      };
      break;
    case "ADD_USERS_TO_LIST_FULFILLED":
      listId = action.payload.id;
      for (var i = 0; i < state.lists.length; i++) {
        if (state.lists[i].id === listId) {
          state.lists[i] = action.payload;
          break;
        }
      }
      state = {
        ...state,
      };
      break;
    case "LIST_FAVORITE_TOGGLE":
      listId = action.payload.list_id;
      for (let i = 0; i < state.lists.length; i++) {
        if (state.lists[i].id === listId) {
          state.lists[i].is_favorite = !state.lists[i].is_favorite;
          break;
        }
      }
      state = {
        ...state,
      };
      break;
    case "ADD_USERS_TO_LISTS_FULFILLED":
      for (let j = 0; j < action.payload.length; j++) {
        for (var i = 0; i < state.lists.length; i++) {
          if (state.lists[i].id === action.payload[j].id) {
            state.lists[i] = action.payload[j];
            break;
          }
        }
      }
      state = {
        ...state,
      };
      break;
    case "SHARE_LIST_INFLUENCER_RESPONSE_FULFILLED":
      state.list.list_summary_data = action.payload.summary;
      var user = state.list.influencers_data.find(
        (x) => x.id === action.payload.influencerId,
      );
      if (user) user.shared_response_approved = action.payload.response;
      state = {
        ...state,
      };
      break;
    case "SHARE_LIST_INFLUENCER_NOTE_FULFILLED":
      var user = state.list.influencers_data.find(
        (x) => x.id === action.payload.influencerId,
      );
      user.shared_response_note = action.payload.note;
      state = {
        ...state,
      };
      break;
    case "ARCHIVE_LIST_FULLFILLED":
      if (action.payload.toArchive) {
        listItem = state.lists.findIndex((x) => x.id === action.payload.id);
        if (listItem < 0) break;
        state.lists.splice(listItem, 1);
      } else {
        listItem = state.archived_lists.findIndex(
          (x) => x.id === action.payload.id,
        );
        if (listItem < 0) break;
        state.archived_lists.splice(listItem, 1);
      }
      state = {
        ...state,
      };
      break;
    case "FETCH_ARCHIVED_LISTS_FULFILLED":
      state = {
        ...state,
        archived_lists: action.payload ? action.payload : [],
      };
      break;
    default:
      break;
  }
  return state;
};
export default listsReducer;
