import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const PopoverTheme: ComponentMultiStyleConfig = {
  parts: ["header", "closeButton", "body", "dialog"],
  baseStyle: {
    dialog: {
      bg: "transparent",
    },
    closeButton: {
      top: 0,
      insetEnd: 0,
      boxSize: "32px",
      color: "inherit",
      padding: "0",
      lineHeight: "inherit",
      cursor: "pointer",
      backgroundColor: "transparent",
      textTransform: "none",
      overflow: "visible",
      outline: "none",
      border: "0",
      _focus: {
        shadow: "none",
      },
      svg: {
        display: "none",
      },
      _after: {
        color: "inherit",
        content: '"×"',
        fontSize: "3xl",
        fontWeight: "700",
      },
    },
    header: {
      px: 4,
      py: 2,
      fontSize: "small",
      bg: "brand.500",
      borderTopRightRadius: "md",
      borderTopLeftRadius: "md",
      color: "white",
    },
    body: {
      bg: "#FAFAFA",
      borderBottomRightRadius: "md",
      borderBottomLeftRadius: "md",
    },
  },
};
