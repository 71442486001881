/* eslint-disable no-nested-ternary */
import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { InfluencerInManageAuctionData } from "src/api/types";
import { formatBigNumbers } from "../../utilities/general";
import { Columns } from "../CampaignManager/CampaignManagerInterfaces";
import { CampaignInfluencerPriceModelContent } from "./CampaignInfluencerPriceModelContent";
import ShowBreakdownIcon from "./show-breakdown.svg";

type Props = React.ComponentProps<
  typeof CampaignInfluencerPriceModelContent
> & {
  enableActions?: {
    addExtraPayout: boolean;
    boardId: number;
    auctionId: number;
    enableAgentAdd?: boolean;
    onUploadInvoice?: () => void;
  };
  influencerData: InfluencerInManageAuctionData;
  bidMoreThanMax?: boolean;
  isTotalWithTax?: boolean;
  selectedColumn: Columns;
  nextMonthMode?: boolean;
  month?: string;
};

export const CampaignInfluencerPrice: React.FC<Props> = ({
  influencerData,
  isTotalWithTax,
  enableActions,
  selectedColumn,
  nextMonthMode,
  month,
}) => {
  const { price_details: priceDetails } = influencerData;
  const currencyLabel = window.translate(
    "",
    `money_symbol_${priceDetails.currency}`,
  );

  const {
    isOpen: isBreakdownOpen,
    onOpen: setBreakdownOpen,
    onClose: setBreakdownClose,
  } = useDisclosure();
  const { onOpen: setUploadInvoiceOpenInner } = useDisclosure();
  const { onOpen: setAddAgentOpenInner } = useDisclosure();
  const setUploadInvoiceOpen = useCallback(() => {
    setBreakdownClose();
    setUploadInvoiceOpenInner();
  }, [setBreakdownClose, setUploadInvoiceOpenInner]);
  const setAddAgentOpen = useCallback(() => {
    setBreakdownClose();
    setAddAgentOpenInner();
  }, [setBreakdownClose, setAddAgentOpenInner]);

  const navigate = useNavigate();

  const showPayments =
    selectedColumn !== Columns.bids && selectedColumn !== Columns.invite;
  return (
    <Flex flexDir="column" alignItems="center">
      <Modal isOpen={isBreakdownOpen} onClose={setBreakdownClose}>
        <ModalOverlay />
        <CampaignInfluencerPriceModelContent
          enableActions={
            enableActions
              ? {
                  addExtraPayout: enableActions.addExtraPayout,
                  auctionId: enableActions.boardId,
                  onAgentAdd: setAddAgentOpen,
                  enableAgentAdd: enableActions.enableAgentAdd,
                  onUploadInvoice: enableActions.onUploadInvoice
                    ? setUploadInvoiceOpen
                    : undefined,
                }
              : undefined
          }
          influencerData={influencerData}
          currencyLabel={currencyLabel}
          isTotalWithTax={isTotalWithTax}
        />
      </Modal>

      {nextMonthMode ? null : (
        <ButtonGroup isAttached>
          {showPayments &&
            (!enableActions?.boardId ? (
              <IconButton
                aria-label="Show price breakdown"
                onClick={setBreakdownOpen}
                tabIndex={-1}
                icon={<img src={ShowBreakdownIcon} width={20} height="auto" />}
                variant="ghost-non-focusable"
                size="sm"
              />
            ) : (
              <Button
                variant="small-action-white"
                onClick={
                  !enableActions?.boardId
                    ? setBreakdownOpen
                    : () =>
                        navigate(
                          `/campaign/${enableActions.boardId}/payments?i=${
                            influencerData.influencer_id
                          }&month=${moment(month).utc().format("YYYY-MM")}`,
                        )
                }
                color="brand.500"
                fontSize={10}
                pr={2}
                pl={2}
                mt={1}
                mb={1}
                rightIcon={<i className="fas fa-arrow-circle-right" />}
              >
                Payments
              </Button>
            ))}
        </ButtonGroup>
      )}
      <Text
        fontSize="xl"
        className="blurable"
      >{`${currencyLabel} ${formatBigNumbers(
        !enableActions?.boardId
          ? isTotalWithTax
            ? priceDetails.calculated_fields.total
            : priceDetails.calculated_fields.total_tax_free
          : showPayments
          ? priceDetails.current_month_recurring_price
          : priceDetails.price,
        {
          minForFormat: 100000,
        },
      )}`}</Text>
    </Flex>
  );
};
