const CheckBoxTask = (props: any) => {
  let checked = false;
  if (props.todoState === "All" && props.task.completed) {
    checked = true;
  } else if (props.todoState === "Done") {
    checked = props.task.completed;
  } else {
    checked = props.checked || props.task.completed;
  }

  const handleCheckbox = () => {
    props.setChecked(!checked);
    if (props.taskBox?.current?.className && props.todoState !== "All") {
      // eslint-disable-next-line no-param-reassign
      props.taskBox.current.className = "slideOutRight";
      setTimeout(() => {
        props.setCompleted(props.task.id, !props.task.completed);
      }, 700);
    } else {
      props.setCompleted(props.task.id, !props.task.completed);
    }
  };
  return (
    <label
      style={{ padding: 0, marginRight: 20, marginLeft: 20 }}
      key={props.index}
    >
      <input
        type="checkbox"
        className="checkbox-round"
        checked={checked}
        onChange={handleCheckbox}
      />
    </label>
  );
};

export default CheckBoxTask;
